import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { ACTION_NAMES } from "../util/APP_CONSTANS";

const styles: any = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ModalDialog(props) {
  const {
    title,
    content1,
    content2,
    button1Name,
    button2Name,
    button2Disabled,
    button1Action,
    button2Action,
    opened,
    onClose
  } = props;


  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        onClose={
          onClose
        }
        aria-labelledby="customized-dialog-title"
        open={opened}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          {title}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {content1}
          </Typography>
          <Typography gutterBottom>
            {content2}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              button1Action();
            }}
            color="primary"
          >
            {button1Name}
          </Button>
          <Button
            disabled={button2Disabled}
            autoFocus
            onClick={() => {
              if (!button2Disabled) {
                button2Action();
              }
            }}
            color="primary"
          >
            {button2Name}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}