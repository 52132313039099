import ArrowDrawable from "./ArrowDrawable";
import { Circle } from "react-konva";
import React from "react";

export default class CircleDrawable extends ArrowDrawable {
  drawableType = 'CircleDrawable';
  constructor(startx, starty, color, weight, opacity) {
    super(startx, starty, color, weight, opacity);
    this.x = startx;
    this.y = starty;
    this.weight = weight;
  }
  render() {
    const dx = this.startx - this.x;
    const dy = this.starty - this.y;
    const radius = Math.sqrt(dx * dx + dy * dy);
    return (
      <Circle
        radius={radius}
        x={this.startx}
        y={this.starty}
        stroke={this.color}
        strokeWidth={this.weight}
        fill={this.fill}
        opacity={this.opacity}
      />
    );
  }
}