import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_18_2 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 198.5 230.2"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M145.5,139.4c0.4,0.8,1.1,1.5,1.2,2.3c0.3,2.6,0.3,5.2,0.5,7.7c0,0.5,0.1,1.1,0.1,1.9c2-1.7,1-3.8,1.5-5.5
	c2.2,1,2.1,3.1,2.3,5c0.2,0,0.3,0,0.5,0c0.2-0.6,0.6-1.2,0.5-1.7c-0.2-1.1-0.6-2.1-1.1-3.1c-0.3-0.6-0.4-1,0.1-1.4
	c2-1.6,4-3.1,6-4.7c0.2-0.2,0.6-0.3,0.9-0.4c1.7-0.7,1.9-0.5,2.1,1.2c0.1,0.6,0.1,1.2,0.3,1.8c1,3.3,0.9,6.5,0,9.8
	c-0.1,0.4,0.1,0.8,0.1,1.3c0.3-0.3,0.9-0.5,1-0.8c0.5-1.5,0.8-3,1.3-4.5c0.1-0.4,0.4-0.8,0.6-1.2c0.1,0,0.2,0.1,0.3,0.1
	c0,1.2,0,2.5,0,3.7c0,0.4,0.3,0.8,0.4,1.2c0.4-0.3,0.8-0.6,1.1-1c0.3-0.4,0.4-1,0.8-1.2c1.2-0.4,2.4-0.7,3.7-1
	c0.1,0,0.3,0.2,0.4,0.3c0.7,1.2-0.1,3.4-1.4,3.9c-0.7,0.3-1.5,0.5-2.2,0.9c-0.3,0.2-0.5,0.6-0.7,0.9c0.4,0.2,0.8,0.6,1.1,0.6
	c1.2-0.3,2.4-0.6,3.6-1c2-0.7,3.9-1.4,6-2.1c-1.9,3.1-4.2,5.8-7.4,7.6c0.6,0.9,1.1,1,1.7,0.5c1.2-0.8,2.5-1.6,3.7-2.4
	c0.5-0.3,1-0.5,1.5-0.8c0.1,0.1,0.1,0.2,0.2,0.3c-0.3,0.3-0.7,0.6-0.9,1c-0.2,0.4-0.3,1-0.2,1.4c0.1,0.2,0.7,0.5,1,0.5
	c1,0,2.1-0.1,3.1,0c-0.8,0.3-1.8,0.5-2.5,1.1c-2,1.5-3.9,3-5.7,4.8c-2.3,2.3-4.8,4.3-7.7,5.6c-3.3,1.6-6.6,3.2-10.3,3.8
	c-2.6,0.4-4.8-0.4-6.8-2.2c-0.7-0.6-1.9-0.6-2.9-0.8c-0.1,0-0.5,0.5-0.5,0.7c0,0.3,0.3,0.7,0.5,0.9c0.7,0.3,1.4,0.6,2.1,0.7
	c1,0.1,1,0.6,0.7,1.4c-0.5,1.1-2.6,2.2-4.1,2c-0.2,0-0.5-0.1-0.7-0.1c-2-0.3-3-2.5-1.9-4.2c0.2-0.3,0.6-0.3,0.8-0.5
	c0.3-0.2,0.8-0.5,0.8-0.7c0-0.4-0.3-0.8-0.6-1.1c-0.2-0.3-0.6-0.4-0.9-0.6c-2.8-2.3-3.6-5.5-3.7-8.8c-0.2-3.3,0.1-6.8,2-9.7
	c1.8-2.7,2.9-5.7,4.2-8.6c0.8-1.7,1.7-3.3,2.6-4.9C145.2,139.5,145.3,139.4,145.5,139.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M134.7,116.8c1.5-1.3,2.6-2.3,3.9-3.3c0.6,1.4,0,3.6-0.9,4.4c-0.5,0.4-0.9,0.8-1.3,1.2
	c-0.2,0.3-0.2,0.8-0.4,1.1c0.5,0,1.1,0.2,1.4-0.1c0.9-0.6,1.6-1.5,2.5-2c2.8-1.9,5.9-3.2,9.2-3.9c0.2,0,0.4,0,0.7,0.1
	c-0.9,2-1.9,3.8-3.3,5.3c-1.3,1.4-2.9,2.7-3.5,4.7c0.1,0.1,0.3,0.3,0.4,0.4c0.9-0.7,1.8-1.5,2.6-2.2c0.6-0.6,1.1-1.3,1.7-1.8
	c0.3-0.3,0.9-0.3,1.5-0.6c-1,3.8-4.5,4.8-6.6,7.4c0.4,0,0.9,0.2,1.2,0c0.9-0.4,1.7-0.8,2.5-1.4c2.5-1.9,5.3-2.7,8.3-3.2
	c0.5-0.1,1.1,0.2,1.8,0.4c-2.6,1.9-4.9,3.6-7.2,5.4c-2.3,1.8-4.6,3.6-6.9,5.3c-3.5,2.5-7.2,4.6-11.1,6.3c-3,1.4-6.1,1-9.2,0.4
	c-1.2-0.2-1.5,0-1.3,1.1c0.2,1.5-1.8,3.3-3.4,3c-0.7-0.1-1.4-0.3-2.1-0.4c-0.2,0-0.4-0.1-0.5-0.3c-1-1-0.7-3.3,0.5-4
	c0.3-0.2,0.8-0.1,1.2-0.1c0.5,0,0.9-0.1,1.4-0.1c-0.1-0.5,0-1.2-0.3-1.6c-2.4-4.2-4-8.7-2.8-13.6c0.7-2.7,1.9-5.2,2.9-7.8
	c0.4-1,0.8-1.9,1.1-2.9c0.9-2.7,1.8-5.5,2.7-8.3c0.3-0.8,0.7-1,1.2-0.3c0.7,1.1,1.5,2.3,1.9,3.5c0.8,2.9,1.1,5.8,0.7,8.8
	c-0.1,0.4,0,0.9,0.2,1.3c0.1,0.3,0.5,0.6,0.7,0.8c0.3-0.4,0.9-0.7,0.9-1.1c0.1-2.2,0-4.4-0.1-6.5c0-0.3,0.1-0.7,0.3-1
	c1.4-2,3.4-3.5,5.6-4.5c1.5-0.7,2.2-0.4,2.4,1.3c0.2,2.2,0.3,4.5-0.5,6.6C134.7,115.4,134.7,116,134.7,116.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M26.9,183c1.4,3.5,3.9,6.4,6.2,9.3c2.3,2.9,5.2,5.4,7.8,8c-4.3-0.1-7.8-2.4-11.1-5.2c-0.1,0.2-0.3,0.4-0.4,0.6
	c0.2,0.3,0.3,0.7,0.6,1c2.8,3.1,6.4,4.5,10.4,5.3c0.6,0.1,1.3-0.3,2-0.5c0-0.1,0-0.3,0-0.4c2.4,1.8,4.9,3.5,7.3,5.3
	c0,0.1-0.1,0.2-0.1,0.3c-0.8,0-1.5-0.1-2.3,0c-0.4,0.1-0.8,0.4-1.3,0.7c0.4,0.3,0.8,0.7,1.3,0.8c0.6,0.1,1.3,0.2,1.9,0
	c2.3-0.7,3.9,0.7,5.7,1.7c0.3,0.2,0.6,0.6,0.6,0.9c0.1,1.6,0.1,3.2,0.2,4.8c0.1,0.7-0.2,0.9-0.9,1.1c-2.1,0.4-4.2,0.2-6.3-0.1
	c-2-0.3-3.9-0.6-5.7-1.5c-0.2-0.1-0.3-0.2-0.4-0.3c1.3-0.7,2.5-1.3,3.8-2c0.2-0.1,0.5-0.1,0.7,0c2.3,1.3,4.9,1.4,7.4,1.5
	c0.3,0,0.7-0.3,1-0.5c-0.3-0.2-0.6-0.6-0.9-0.6c-4.2,0.1-7.7-1.7-11.3-3.5c-0.8-0.4-1.5-1-2.2-1.6c-1.4-1.1-2.9-2-4.5-2.7
	c-3.6-1.4-6.9-3.5-9.7-6.2c-0.6-0.5-1.1-1.2-1.6-1.8c-3.2-4.5-6.1-9.1-6.7-14.7c-0.5-3.9-1.3-7.7-1.8-11.6c3.6,2.3,7.7,3,11.4,4.6
	c2.6,1.1,5,2.5,7.5,3.9c1.5,0.8,3.1,1.6,4.6,2.5c1.1,0.6,2.2,1.2,3.1,2c1.9,1.7,4,3.4,5.5,5.5c2,2.7,3.5,5.8,5.1,8.8
	c0.8,1.4,1.3,3,1.9,4.5c0.2,0.4,0.6,0.7,1.1,1.2c-0.3,1.4-0.6,3-1,4.8c-0.7-0.4-1.4-0.7-2.1-1.1c-0.1-0.1-0.3-0.3-0.3-0.4
	c0.2-1-0.3-1-1-0.9c-1.3,0.1-3.4-2-3.2-3.3c0.5-3.6-0.8-6.8-2.7-9.7c0,0,0-0.1-0.1-0.1c-0.4-0.3-0.9-0.6-1.3-0.9
	c0,0.6-0.1,1.2,0.1,1.6c0.4,1,1.1,2,1.4,3c0.6,2,1,4.1,1.4,6.1c-0.2,0.1-0.3,0.2-0.5,0.3c-3.1-2.4-6.2-4.7-9.3-7.1
	c0.2-0.1,0.3-0.2,0.5-0.2c0-1.1,0.2-2.2,0-3.3c-0.3-1.9-0.7-3.8-1.2-5.7c-0.1-0.4-0.7-0.6-1-0.9c-0.2,0.4-0.5,0.9-0.5,1.2
	c0.3,1.8,0.8,3.7,1.1,5.5c0.1,0.5,0,1.1-0.1,1.6c-0.1,0.1-0.2,0.1-0.4,0.2c-3.1-3.9-6.1-7.8-9.2-11.7
	C27.3,182.8,27.1,182.9,26.9,183z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M94.4,54.3c1.3-0.4,1.7-1.3,1.6-2.4c-0.1-1.5-0.4-3-0.5-4.5c0-0.4,0.1-1,0.3-1.3c0.9-1.7,2.6-2.4,4.1-3.2
	c0.1,0.1,0.2,0.2,0.3,0.3c-0.3,0.6-0.7,1.2-0.8,1.9c-0.2,1.1-0.4,2.2-0.4,3.4c0,2.9,0.3,5.9,0.1,8.8c-0.2,2.5-0.8,5-1.6,7.4
	c-0.8,2.3-2.2,4.3-4.2,5.9c-1.1,0.9-2.1,2-3.2,2.9c-0.6,0.5-1.3,0.9-2,1.2c0.7-2.3,1.6-4.4,1.1-6.8c-1.4,2.3-0.9,5.6-3.6,7.5
	c-0.2-1-0.3-1.8-0.4-2.6c-0.2,0-0.3,0-0.5,0c-0.1,0.6-0.2,1.2-0.3,2c-1.2-0.8-2.2-1.5-3.3-2.2c-0.3-0.2-0.5-0.5-0.8-0.7
	c-0.2-0.1-0.6-0.1-0.9-0.1c0,0.3-0.1,0.7,0.1,0.9c0.7,1.1,1.5,2.1,2.5,3c-3.2-1.3-6.8-2.3-8.8-5.8c-1-1.7-2.2-3.3-3.1-5.1
	c-1.6-3-2.2-6.3-2.5-9.7c-0.2-2.4,0.1-4.9,0.3-7.4c0-0.4,0.3-0.8,0.5-1.5c0.7,0.6,1.3,1.1,1.8,1.6c1.3,1.4,3.2,2.4,3.8,4.4
	c0.4,1.3,0.5,2.7,0.9,4c0.4,1.6,1,3.1,1.5,4.7c0.1,0.2,0.3,0.4,0.5,0.6c0.1,0,0.3,0,0.4,0c0-0.7,0.1-1.4,0-2c-0.4-1.5-1-3-1.4-4.4
	c-0.1-0.3-0.2-0.8,0-0.9c1-0.7,0.5-1.5,0.2-2.2c-0.7-1.3-1.2-2.7-0.8-4.2c0.1-0.4,0.3-0.8,0.5-1.5c0.6,0.9,1.1,1.4,1.3,2
	c0.7,1.8,1.2,3.6,1.9,5.4c0.6,1.5,1.2,3.2,2.7,4.1c0.3,0.2,0.7,0.1,1.1,0.1c0-0.3,0-0.7-0.1-1c-1.7-2.6-2.3-5.6-3-8.5
	c-0.3-1.2,0.2-2.9,0.9-4c1-1.6,2.4-3,3.7-4.4c0.1-0.2,0.9,0,1,0.2c0.9,1.5,1.7,2.9,2.4,4.5c0.7,1.6,1.2,3.2,1.8,4.9
	c1.3-0.7,1.6-1.5,0.9-3.1c-0.5-1-0.3-1.7,0.8-2.1C94.3,46.9,93.7,50.7,94.4,54.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M82.7,212c-5.3,3.1-11.1,3.9-17.2,3.7c0.2-0.3,0.3-0.6,0.4-0.6c1.7-0.5,2.3-2.3,3.6-3.3c1.3-1.1,2.6-2.3,4.1-3
	c1.1-0.5,2.5-0.4,3.7-0.5c2.7-0.1,5.3-0.2,8-0.3c0.3,0,0.7-0.4,1-0.6c-0.3-0.2-0.6-0.6-0.9-0.6c-1.9-0.1-3.8,0-5.8-0.2
	c2-1.1,4-2.2,6-3.4c1.1-0.6,2.2-1.3,3.2-2.1c0.5-0.4,0.7-1,1-1.5c-0.1-0.1-0.3-0.2-0.4-0.4c-0.5,0.3-1,0.5-1.5,0.8
	c-3.5,2.2-7,4.4-11.1,5.7c-2.6,0.8-5.1,1.9-7.2,3.7c-0.6,0.5-1.1,1.2-1.7,1.7c0.1-1.7,0.9-2.8,2.1-3.8c1.2-0.9,1.3-1.1,1.2-2
	c-2.9,1.3-4.8,3.4-5.2,6.5c-0.3,2.5-2.3,3.1-4,4.1c-0.3,0.2-0.6,0.4-0.9,0.7c-1,0.9-2.1,1.8-3.1,2.8c-0.1,0-0.3,0-0.4,0
	c-0.1-1.8-0.4-3.6-0.4-5.5c0-1,0.2-2,0.7-2.9c0.6-1.1,1.5-2.2,2.5-2.9c1.8-1.3,3.7-2.6,5.7-3.5c2.1-1,4.4-1.8,6.7-2.5
	c2.5-0.7,5.1-1.1,7.6-1.8c2.6-0.7,5.2-1.4,7.7-2.2c2-0.7,3.9-1.7,5.8-2.5c0.3-0.1,0.6-0.3,1.1-0.4c-0.6,2.4-1.3,4.7-1.7,7
	c-0.3,1.5-0.3,3.1-0.3,4.6c-0.1,1.6-0.9,2.9-1.8,4.2c-0.4,0.6-0.6,1.3-1.1,1.8c-2.8,2.7-5.8,5-9.5,6.5c-3.5,1.4-7.1,1.6-10.7,2
	c-1.7,0.2-3.5,0.5-5.2,0.4c-1.9,0-3.8-0.3-5.9-0.6c2.3-2.5,4.5-4,7.7-4c2.9,0.1,5.8-0.3,8.7-0.8c2-0.4,4-1.2,5.9-2
	c0.8-0.3,1.4-1.1,2-1.6C83,212.4,82.9,212.2,82.7,212z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M121,192.6c-0.9,1.1-1.6,2-2.4,2.8c-1.2,1.3-1.8,2.8-2.2,4.5c-0.6,2.2-1.1,4.4-1.8,6.5
	c-0.9,2.6-2.9,4.2-5.2,5.6c-4,2.5-8.2,4.3-12.9,4.9c-2.5,0.3-5.1,0-8-0.6c2.5-2.4,4.2-5.2,7.5-6.3c0.3-0.1,0.6,0,0.9,0.1
	c2.6,1.2,5.3,1.3,8.1,1.1c0.2,0,0.5,0,0.7-0.2c0.3-0.2,0.4-0.5,0.7-0.7c-0.3-0.2-0.6-0.5-0.9-0.5c-1.9-0.2-3.9-0.2-5.8-0.4
	c-0.4,0-0.8-0.2-1.4-0.4c0.3-0.3,0.5-0.5,0.7-0.6c1.3-0.7,2.7-1.4,4-2.2c0.5-0.3,0.9-0.3,1.5,0c1.5,0.9,4.1,0.6,5.4-0.6
	c0.2-0.2,0.4-0.5,0.4-1.1c-1.6,0.2-3.2,0.5-4.8,0.7c0-0.1-0.1-0.2-0.1-0.3c0.1-0.2,0.2-0.3,0.4-0.5c1.5-1.5,3-3,4.5-4.5
	c0.5-0.5,1-0.9,1.5-1.4c0.2-0.3,0.2-0.7,0.2-1.1c-0.4,0-0.9-0.1-1.1,0c-0.8,0.6-1.5,1.3-2.1,2.1c-1.9,2.4-4.3,4.1-7.1,5.5
	c0.4-2.9,2.5-4.6,4.1-6.6c-0.1-0.2-0.3-0.3-0.4-0.5c-0.6,0.2-1.2,0.4-1.6,0.8c-2.2,1.8-3.4,4.1-4,6.8c-0.2,0.8-0.5,1.2-1.2,1.5
	c-1.4,0.6-2.8,1.2-4.2,1.9c0.2-1.8,0.3-3.7,0.5-5.5c0.1-0.7,0.3-1.7,0.8-2c3.2-2.5,6.4-5,10.3-6.2c4.7-1.4,9.4-2.6,14.3-2.6
	C120.4,192.6,120.5,192.6,121,192.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M104.3,216.5c1.6-0.9,3.1-1.7,4.6-2.5c0.2-0.1,0.4-0.3,0.6-0.3c2.4,0.1,4.3-1.4,6.4-2.2c0.4-0.1,0.9-0.1,1.3,0
	c1.3,0.4,2.7,0.9,4,1.2c0.4,0.1,1,0.1,1.4-0.1c0.3-0.2,0.5-0.7,0.8-1c-0.4-0.1-0.7-0.3-1.1-0.4c-1.1-0.2-2.3-0.4-3.4-0.7
	c0-0.1,0-0.3,0-0.4c0.8-0.4,1.5-1.1,2.3-1.3c2.6-0.6,5.2-1.8,8-1.3c0.3,0,0.7,0.1,0.9-0.1c0.4-0.2,0.7-0.6,1-0.9
	c-0.3-0.2-0.7-0.6-1-0.6c-1.7-0.1-3.5,0-5.2,0c1.6-2.7,4.8-4.4,5.9-7.7c-2,0.8-3.1,2.7-4.8,3.9c-0.1-0.1-0.3-0.1-0.4-0.2
	c0.4-1.3,0.8-2.7,1.1-4.1c-1.1,0-1.8,0.7-2,1.6c-0.3,1.2-0.4,2.4-0.3,3.5c0.1,0.8-0.2,1.2-0.6,1.7c-2.3,2.6-5.4,4-8.5,5.5
	c-0.5,0.2-1,0.4-1.8,0.7c0.2-0.4,0.3-0.6,0.5-0.8c1.5-1.6,2-3.6,2.6-5.6c0.3-0.9,0.8-1.9,1.5-2.6c3.9-4.1,8.6-6.9,13.9-8.8
	c2.3-0.8,4.7-0.9,7.4-0.6c-0.4,0.5-0.6,0.8-0.9,1.1c-1.8,1.8-2.5,4.1-3,6.5c-0.4,2.2-1.1,4.3-1.8,6.4c-0.8,2.2-2.2,3.9-4.1,5.1
	c-2.6,1.6-4.8,3.7-7.8,4.5c-4.1,1.1-8.2,1.5-12.4,1.1c-1.1-0.1-2.3-0.1-3.4-0.3C105.4,217.1,104.9,216.7,104.3,216.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M44.2,211.9c-3,0.7-6.1,1.6-9.1,2.1c-1.9,0.4-3.8,0.4-5.7,0.5c-3.3,0.1-6.6,0.1-9.9,0.2c-2.2,0-4.2-0.8-6.2-1.6
	c-4.2-1.8-5.5-2.8-7.9-6.5c1.3-0.7,2.6-1.4,3.9-2.2c1-0.6,2-1.1,3-1.8c2.2-1.5,4.6-2.6,7.1-3.4c1.1-0.4,2.2-0.8,3.4-1.1
	c0.4-0.1,1.1,0,1.4,0.3c2.3,2.6,5.1,4.6,8.1,6.2c0.1,0.1,0.2,0.2,0.4,0.4c-2.2,0.2-4.2,0.5-6-0.9c-1.6-1.2-3.5-1.2-5.4-1.3
	c-0.2,0-0.4,0.3-0.6,0.4c0.1,0.2,0.2,0.4,0.4,0.5c0.5,0.2,1.1,0.4,1.7,0.6c1.1,0.4,2.2,0.9,3.3,1.3c0,0.2-0.1,0.3-0.1,0.5
	c-0.8,0.1-1.5,0.2-2.3,0.3c-2.1,0.2-4.1,0.5-6.2,0.6c-1.4,0.1-2.8-0.3-4.1-0.3c-0.4,0-0.8,0.2-1.1,0.3c0.2,0.4,0.3,1.1,0.5,1.2
	c0.9,0.3,1.9,0.6,2.8,0.5c3.1-0.2,6.2-0.3,9.3-0.7c2.8-0.3,5.6-1,8.4-1.5c0.3,0,0.6-0.1,0.8-0.3c0.6-0.6,1-0.3,1.8,0.4
	c-1.6,1-3,2-4.5,2.8c-1.4,0.7-2.9,1.1-4.3,1.7c-0.3,0.1-0.6,0.4-0.8,0.7c0.3,0.2,0.6,0.5,1,0.5c1.6,0.2,3.1-0.4,4.4-1.3
	c1.7-1.1,3.4-2.2,5.1-3.3c0.5-0.3,0.9-0.5,1.5,0c1.1,0.8,2.1,1.7,3.3,2.4c0.9,0.5,1.8,0.9,2.8,1.4C44.2,211.6,44.2,211.8,44.2,211.9
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M54.1,127.5c0.2-1.7,1.4-2.4,2.4-3.3c0.5-0.4,0.9-0.9,1.4-1.3c0.3-0.3,0.7-0.4,1.1-0.6c2.4-0.7,4.8-1.4,7.2-2
	c2.1-0.5,4.2-0.8,6.3-1.1c0.3,0,0.7,0.3,0.9,0.5c0.8,0.7,1.7,1.4,2.5,2.2c0.2,0.2,0.5,0.7,0.4,0.9c-2.3,5-3.8,10.5-7.4,14.8
	c-0.4,0.5-0.8,0.9-1.3,1.3c-0.8,0.6-1.7,1-2.5,0.4c-3-2.1-6.3-3.8-8.7-6.7C55.3,131,54.5,129.2,54.1,127.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M80,110.4c-0.9,0.3-2.2,0.8-3.5,1.1c-0.4,0.1-0.9,0.1-1.2-0.1c-2.9-2-5.8-3.9-8.6-6c-1.9-1.5-3.6-3.2-5.3-4.9
	c-0.7-0.7-0.5-1.8,0.5-2.5c2.4-1.4,4.8-2.8,7.3-4c3.2-1.4,6.6-1.7,10-1.7c2.3,0,3.5,2,3.2,3.8C82,99,82,102,81.8,105
	c-0.1,1.5-0.2,3-0.2,4.5C81.6,110.5,81.3,110.8,80,110.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M149.7,208.5c0.6,0.1,1.2,0.2,1.9,0.2c0.5,0,1,0,1.5-0.1c0.2,0,0.4-0.3,0.6-0.5c-0.2-0.2-0.3-0.4-0.6-0.5
	c-1.9-0.6-3.8-0.8-5.8-0.5c-0.7,0.1-1.5,0-2.2-0.2c-1.4-0.3-2.8-0.7-4.5-1.2c1.2-0.5,2.1-1,3.1-1.3c1-0.3,2.1-0.3,3.1-0.6
	c0.3-0.1,0.5-0.5,0.8-0.7c-0.3-0.1-0.6-0.4-0.9-0.4c-2.7-0.2-5.4,0.3-7.3,2.3c-1.1,1.1-2.3,1-3.6,1.3c0.5-1.9,0.8-3.7,1.4-5.4
	c0.2-0.6,1.2-1.1,2-1.3c3.1-0.9,6.3-0.9,9.2,0.7c2.1,1.1,4,2.6,6.1,3.6c2.4,1.2,3.7,3.2,5.1,5.3c0.7,1.1,1.6,2,2.9,2.5
	c0.8,0.3,1.5,1,2.3,1.5c-0.9,1.2-2.2,1.7-3.4,1.9c-3.2,0.6-6.4,0.7-9.6,0.2c-4.6-0.7-9.2-1.4-13.8-1.9c-1.8-0.2-3.7,0.2-5.6,0.2
	c-0.8,0-1.5-0.1-2.3-0.2c0-0.2,0-0.3-0.1-0.5c0.5-0.4,1-0.8,1.5-1.3c0.5-0.5,1.2-0.9,1.5-1.5c1.2-2.2,3.2-2.8,5.5-2.9
	c0.9-0.1,1.9,0,2.7,0c0.4,1.4,0.7,2.6,1.3,3.7c0.7,1.4,1.9,2.1,3.5,2.1c0.3,0,0.6-0.3,0.9-0.5c-0.2-0.2-0.5-0.5-0.7-0.5
	c-1.8-0.4-2.8-1.8-2.7-4.2c0.5,0.2,1,0.3,1.4,0.5c2.8,0.9,5.6,1.8,8.4,2.6c0.3,0.1,0.7,0.2,1.1,0.1c0.2,0,0.4-0.3,0.6-0.4
	c-0.2-0.2-0.3-0.5-0.5-0.6c-1.6-0.5-3.2-1-4.9-1.5C149.7,208.6,149.7,208.5,149.7,208.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M90.2,92.3c2.9,0.2,5.8,0.5,8.4,2c2.2,1.2,3.8,3,4.2,5.5c0.1,0.8-0.4,1.7-0.9,2.5c-1.4,2.1-3.3,3.7-5.5,4.9
	c-3.2,1.7-6.3,3.3-9.5,4.9c-0.3,0.2-0.8-0.1-1.2-0.2c-0.4-0.1-0.7-0.5-1.1-0.5c-1.3,0-1.3-0.8-1.3-1.8c0.2-4,0.4-8,0.6-12
	c0.1-1.2,0.4-2.4,0.9-3.5c0.2-0.6,1-1.1,1.6-1.2C87.7,92.5,89,92.5,90.2,92.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M79.2,141.2c-0.7,0-1.4,0.1-2.1,0c-2.5-0.2-3.5-1.7-3.4-4.1c0.1-1.3,0.1-2.7,0.2-4c0-0.4,0.2-0.8,0.3-1.1
	c1.3-2.8,2.6-5.5,3.8-8.2c0.2-0.5,0.5-0.7,1.2-0.7c1.3-0.1,2.6-0.4,3.9-0.6c0.6-0.1,0.9-0.1,1.4,0.4c1.5,1.8,3.1,3.5,4.8,5.1
	c1.6,1.6,3.8,2.8,4.5,5.3c0.4,1.6,0,3-1.3,4.1c-2.3,1.8-5,2.5-7.6,3.2c-1.8,0.5-3.6,0.7-5.5,1C79.3,141.4,79.2,141.3,79.2,141.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M37.5,46.6c0.6,0.1,0.9,0.1,1.2,0.1c2.9,0.2,4.6,1.6,4.4,4.5c-0.2,2.9-0.9,5.9-1.4,8.8c-0.2,1-0.5,1.9-0.7,2.9
	c-0.2,0.7-0.5,1.1-1.4,1.1c-1.8-0.1-3.7,0.1-5.3,1.2c-0.3,0.2-0.9,0.2-1.2,0c-3.8-3-7-6.6-9.7-10.6c-1.2-1.8-0.7-2.9,1-4
	c3.5-2.2,7.5-3.1,11.4-3.9C36.5,46.5,37.2,46.6,37.5,46.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M32.2,66.8c-0.5,1.3-0.9,2.5-1.3,3.7c-0.1,0.3-0.5,0.6-0.8,0.6c-3.1,0.3-6.2,0.6-9.3,0.6
	c-3.1,0-6.2-0.4-9.2-0.6c-1.8-0.1-2.7-0.7-2.6-2.5c0.3-4.9,1.2-9.6,5.8-12.6c0.8-0.5,1.6-0.8,2.6-0.3c2.3,1.1,4.6,2.1,6.9,3.1
	c0.2,0.1,0.4,0.1,0.5,0.3C26.7,62.4,29.7,64.5,32.2,66.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M73.8,113.1c-0.3,1.2-0.6,2.4-0.8,3.6c-0.1,0.6-0.3,0.9-0.9,1c-4.2,1-8.4,2-12.7,3c-0.8,0.2-1.6,0.4-2.4,0.6
	c-2.5,0.6-4.2-0.3-4.6-2.9c-0.5-3.5-0.6-7.1,0.9-10.4c0.4-0.9,1.1-1.8,1.9-2.5c1-0.9,2.1-1,3.4-0.6c1.6,0.5,3.3,0.6,4.9,0.9
	c0.5,0.1,1,0.5,1.4,0.8c2.8,1.8,5.5,3.6,8.2,5.5c0.4,0.3,0.6,0.7,0.9,1.1C74,113.1,73.9,113.1,73.8,113.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1}
              fill={this.state.color17 ? this.state.color17 : 'transparent'} onClick={() => {
          this.setState({ color17: color, opacity17: opacity });
        }} d="M137.5,27.5c-2.7-1.4-5.1-0.4-7.7-0.3c-0.2-0.7-0.4-1.5-0.8-2.3c-0.9-2.1-2-4.2-2.8-6.3
	c-0.5-1.5-0.8-3.1-0.9-4.7c0-0.5,0.7-1.4,1.3-1.6c5.5-1.9,11-2.1,16.5,0c1.7,0.6,2.2,1.8,1.6,3.5C143.1,20.1,140.2,23.8,137.5,27.5z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M124.1,30.9c-4.3-0.7-8.6-1.4-12.9-2.2c-0.8-0.2-1.6-0.7-2.4-1c-0.9-0.4-1.2-1.1-0.9-2
	c1.6-4.7,3.9-8.8,8.6-11.1c2-1,3.3-0.9,5.1,0.6c2,1.7,2.9,3.8,4,5.9c0.9,1.8,1.5,3.9,2.2,5.8c0.3,0.8,0.3,1.5-0.7,1.9
	c-0.4,0.2-0.7,0.5-0.9,0.9c-0.4,0.9-1,1.3-2,0.9C124.1,30.7,124.1,30.8,124.1,30.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M39.9,77.1c-0.1,4.3,0.4,8.7-0.5,13.1c-0.3,1.3-1.2,2.4-2.4,2.5c-1.3,0.2-2.5,0.3-3.8,0.5
	c-3.1,0.4-6.2,0.5-9.2-0.3c-2.1-0.5-2.9-1.8-2.3-3.8c0.6-1.9,1.5-3.6,3.2-4.9c2.1-1.7,4-3.6,6-5.5c0.9-0.9,1.8-1.8,2.8-2.6
	c0.1-0.1,0.4-0.2,0.6-0.2C36.1,76.4,38,76.7,39.9,77.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }} d="M133.6,39.4c1-0.3,2-0.5,2.8-0.9c0.6-0.2,0.9-0.2,1.3,0.3c1.8,1.9,4.1,3.1,6.3,4.4c1.6,0.9,3,2.3,4.4,3.5
	c0.3,0.2,0.6,0.4,0.9,0.6c2.2,1.3,2.5,3,0.5,4.7c-3.6,3.1-7.8,5-12.6,5c-1.2,0-2.5-1-2.8-2.3c-0.3-1-0.5-2.1-0.9-3.1
	c-1-2.4-0.9-4.9-0.5-7.4C133.4,42.6,133.5,40.9,133.6,39.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }} d="M85.7,121.5c0.8-1.2,1.5-2.3,2.2-3.3c0.1-0.2,0.5-0.2,0.7-0.2c2,0.1,4.1,0.1,6,0.4c3.3,0.5,6.7,1.2,10,1.8
	c0.8,0.2,1.6,0.4,2.4,0.8c1,0.5,1.2,1.5,0.9,2.4c-0.8,2.7-2.2,5.1-4.2,7c-1.1,1-2.2,2.1-3.4,2.9c-0.4,0.3-1.3,0.3-1.8,0
	c-1.3-0.6-2.4-1.3-3.6-2.1c-0.2-0.1-0.3-0.4-0.5-0.6c-0.7-0.7-1.3-1.6-2.2-2.2C89.6,126.6,87.6,124,85.7,121.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }} d="M49,48.6c3.9,0,7,1.8,9.3,4.8c1.1,1.4,1.9,3,2.8,4.6c0.6,1,0,1.9-0.8,2.5c-0.5,0.4-1.2,0.7-1.8,0.9
	c-3.7,1.1-7.2,2.6-10.4,4.6c-0.4,0.3-0.9,0.6-1.4,0.7c-0.3,0.1-1,0-1.1-0.3c-0.5-1-1.5-1.3-2.4-1.6c-0.8-0.2-0.9-0.5-0.7-1.4
	c0.8-3.2,1.5-6.4,2.3-9.6c0.3-1.3,0.9-2.6,1.5-3.8C46.8,49,47.9,48.6,49,48.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity23 ? this.state.opacity23 : 1}
              fill={this.state.color23 ? this.state.color23 : 'transparent'} onClick={() => {
          this.setState({ color23: color, opacity23: opacity });
        }} d="M30.8,72.7c0.4,0.7,0.9,1.5,1.4,2.4c-1.2,1.2-2.4,2.4-3.7,3.5c-2.4,2.2-4.7,4.4-7.3,6.3c-1.6,1.2-3.6,2-5.5,2.8
	c-0.5,0.2-1.3,0-1.7-0.3c-3-1.9-4-4.9-4.4-8.2c-0.3-2.7,1.2-4.3,3.8-5.1c3.2-1,6.5-0.5,9.7-0.8C25.6,73.2,28.2,73,30.8,72.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }} d="M124.9,32.4c0.4,1.5,0.8,2.9,1.2,4.5c-1.2,0.9-2.5,2-3.8,3c-2.7,2-5.4,4.1-8.1,6.1c-1.2,0.9-2.7,0.7-3.9-0.2
	c-2.1-1.5-3.2-3.6-3.6-6.1c-0.3-1.6-1.1-3.2-0.6-4.9c0.3-1,0.8-1.8,1.8-2.1c1.2-0.4,2.4-0.5,3.6-0.9c2.7-0.9,5.3-0.1,7.9,0.2
	C121.2,32.1,123.1,32.2,124.9,32.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1}
              fill={this.state.color25 ? this.state.color25 : 'transparent'} onClick={() => {
          this.setState({ color25: color, opacity25: opacity });
        }} d="M87.4,113.8c3-1.6,5.9-3.1,8.7-4.8c1.8-1.1,3.5-2.4,5.3-3.7c1.3-1,2.8-1.7,4.5-1.3c0.5,0.1,1.1,0.6,1.5,1.1
	c1.7,2.6,2.4,5.4,2.2,8.5c-0.1,1.3-0.2,2.6-0.4,3.9c-0.2,0.9-0.9,1.3-1.9,1.4c-2.5,0.4-4.9-0.6-7.3-0.9c-3.5-0.4-7-0.9-10.5-1.3
	c-0.8-0.1-1.5-0.2-1.5-1.2C87.9,114.8,87.6,114.2,87.4,113.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1}
              fill={this.state.color26 ? this.state.color26 : 'transparent'} onClick={() => {
          this.setState({ color26: color, opacity26: opacity });
        }} d="M139.3,28.3c2-3.4,4.2-6.9,6.3-10.4c0.9-1.5,3.4-2.4,5-1.7c0.7,0.3,1.5,0.7,2,1.3c2.6,2.7,4.7,5.7,5.2,9.5
	c0.2,1.7-0.2,2.5-1.9,2.9c-3,0.8-6,1.6-9,2.2c-1.7,0.4-3.5,0.4-5.2,0.6c-0.3,0-0.7-0.2-0.8-0.5C140.4,31,139.8,29.7,139.3,28.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M111.3,165.7c-1.2,2.4-3.3,3.5-5.6,4.1c-2.3,0.6-4.7,1-7,1.4c-1.3,0.2-2.2-0.4-2.7-1.6
	c-0.2-0.5-0.5-1.1-0.8-1.6c-2.3-3.4-2-7-0.3-10.3c1.3-2.5,4.8-4.3,8.5-1.3C106.5,159,109,162.2,111.3,165.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity28 ? this.state.opacity28 : 1}
              fill={this.state.color28 ? this.state.color28 : 'transparent'} onClick={() => {
          this.setState({ color28: color, opacity28: opacity });
        }} d="M138.7,37.6c0.5-0.8,1-1.2,1.2-1.8c0.4-1.6,1.7-1.8,3-1.9c3.7-0.2,7.3-1.1,10.9-2.2c2-0.6,5.1,1.3,5.2,3.5
	c0.2,3.5-0.8,6.8-2.8,9.7c-1.7,2.6-4.4,2.2-6.7,0.4c-1.4-1.1-3-2.1-4.5-3.2C142.9,40.7,140.8,39.1,138.7,37.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity29 ? this.state.opacity29 : 1}
              fill={this.state.color29 ? this.state.color29 : 'transparent'} onClick={() => {
          this.setState({ color29: color, opacity29: opacity });
        }} d="M57.5,193c-0.9,1.1-1.7,2.1-2.6,3.2c-0.7-0.7-1.1-1.1-0.8-2.2c0.3-0.8-0.2-1.9-0.3-2.8c0-0.5,0.1-1.1,0.3-1.6
	c1.1-1.9,2.3-3.7,3.3-5.6c0.2-0.3-0.2-0.9-0.3-1.3c-0.3,0.2-0.8,0.4-1,0.7c-0.8,1.3-1.4,2.6-2.2,3.9c-0.2,0.3-0.5,0.6-0.9,1.1
	c-0.3-0.8-0.5-1.4-0.7-2.1c-0.4-1.3-0.8-2.5-1.1-3.8c-0.2-1.1-0.1-2.2-0.1-3.2c0-0.6,0.3-1.2,0.4-1.7c0-0.2-0.3-0.6-0.5-0.7
	c-0.1-0.1-0.6,0.2-0.7,0.3c-0.6,1.5-1.5,2.9-0.9,4.7c0.9,3.1,1.6,6.3,2.3,9.4c-1-2.1-2.5-3.8-4.4-5.2c-0.5-0.4-0.8-1.1-1.1-1.8
	c-0.5-1.1-0.8-2.3-1.2-3.5c-0.2-0.6-0.4-1.1,0.1-1.8c0.6-1,0.7-2.2,1.3-3.2c1.1-2,2.3-3.9,3.5-5.8c0.2-0.3,0.6-0.6,1.1-0.9
	c0.5,1.5,1,2.8,1.5,4.1c1,2.6,2.5,5,4.5,7c2.3,2.3,3.7,5,3.7,8.4c0,1.4-0.4,2.6-0.9,3.9c-1,2.8-1.8,5.6-2.7,8.4
	c-0.1,0.2-0.1,0.4-0.2,0.7c-0.2-0.4-0.3-0.7-0.4-0.9c-1.2-1.7-1.2-3.2,0.4-4.8C57.7,195.3,58.3,194.3,57.5,193z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity30 ? this.state.opacity30 : 1}
              fill={this.state.color30 ? this.state.color30 : 'transparent'} onClick={() => {
          this.setState({ color30: color, opacity30: opacity });
        }} d="M131.7,41.4c-0.1,2.4-0.3,4.8-0.4,7.3c0,1.2,0.2,2.4,0.1,3.6c0,0.9-0.2,1.8-0.4,2.7c-0.2,1-0.9,1.2-1.8,1.1
	c-4.1-0.5-7.9-1.9-11.5-3.9c-1.3-0.7-1.7-1.8-1.5-3.3c0.3-2.6,2.1-4,4-5.4c2.3-1.7,4.5-3.4,6.9-4.9c0.4-0.3,1.1-0.2,1.6-0.1
	c0.9,0.2,1.7,0.5,2.6,0.9c0.2,0.1,0.5,0.4,0.5,0.6C131.8,40.4,131.8,40.9,131.7,41.4C131.7,41.4,131.7,41.4,131.7,41.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity31 ? this.state.opacity31 : 1}
              fill={this.state.color31 ? this.state.color31 : 'transparent'} onClick={() => {
          this.setState({ color31: color, opacity31: opacity });
        }} d="M41.5,76.5c1.5-0.9,3.1-1.8,4.7-2.7c1.1,0.5,2.4,1.1,3.7,1.8c1.8,0.9,3.6,1.8,5.3,2.9c2.8,1.8,3.4,3.6,1.1,6.2
	c-2.5,2.8-5.6,5-9.4,6.2c-0.8,0.2-1.6,0.3-2.4,0.4c-0.3,0-0.9-0.2-1-0.5c-0.9-1.3-1.9-2.6-1.8-4.4c0.1-2.9,0.1-5.8,0.1-8.7
	C41.7,77.3,41.5,76.9,41.5,76.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1}
              fill={this.state.color32 ? this.state.color32 : 'transparent'} onClick={() => {
          this.setState({ color32: color, opacity32: opacity });
        }} d="M58.6,78.1c-0.8-0.2-1.6-0.4-2.3-0.7c-3-1.5-5.9-3-8.8-4.5c-0.7-0.4-1-0.8-0.5-1.6c0.3-0.5,0.2-1.2,0.1-1.8
	c-0.1-0.7,0-1.1,0.7-1.6c3.1-1.9,6.2-3.5,9.7-4.6c1.5-0.5,5.3,0.4,6.2,1.6c0.3,0.3,0.4,0.9,0.4,1.3c-0.3,3.2-0.8,6.4-2.1,9.4
	C61.3,76.8,59.8,78,58.6,78.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity33 ? this.state.opacity33 : 1}
              fill={this.state.color33 ? this.state.color33 : 'transparent'} onClick={() => {
          this.setState({ color33: color, opacity33: opacity });
        }} d="M31.2,134.7c-1.1,1.1-2,1.9-2.7,2.8c-1.6,2-4.1,2.6-6.4,3.3c-1.3,0.4-2.9-0.1-4.3-0.3c-0.2,0-0.5-0.3-0.7-0.6
	c-1.8-2.9-2.5-6.1-2.6-9.5c-0.1-3.5,2.5-5.1,5.8-4.1c3.6,1.1,6.2,3.5,9,5.6C30.4,132.7,30.8,134,31.2,134.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M115.2,186.3c-1.4,0-2.2-0.9-3.1-1.7c-1.6-1.6-2.7-3.6-3.2-5.8c-0.5-2-1.3-4-2-6c-0.3-1-0.2-1.7,1-1.9
	c0.2,0,0.4-0.1,0.6-0.2c2.2-1.7,4.3-0.8,6.2,0.5c1.5,1,3,2.1,4.4,3.3c0.6,0.5,1,1.3,1.4,1.9c0.2,0.2,0.3,0.5,0.6,0.6
	c2.9,1.6,3.2,4,0.5,6c-1.5,1.1-3.1,1.9-4.7,2.8C116.4,186.1,115.8,186.2,115.2,186.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity35 ? this.state.opacity35 : 1}
              fill={this.state.color35 ? this.state.color35 : 'transparent'} onClick={() => {
          this.setState({ color35: color, opacity35: opacity });
        }} d="M105,171.7c1.2,4,2.4,7.8,3.4,11.7c0.2,0.9,0.1,1.9,0.1,2.9c0.1,1.4-0.6,2.1-1.8,2.5c-2.3,0.9-4.6,1.3-7.1,0.8
	c-0.9-0.2-1.4-0.7-1.9-1.5c-1.2-2.2-1.1-4.5-0.8-6.8c0.2-1.4,0.4-2.8,0.7-4.1c0.1-0.6,0.5-1.1,0.9-1.6c0.3-0.4,0.8-0.6,1-1
	C100.6,172.1,102.9,172.3,105,171.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1}
              fill={this.state.color36 ? this.state.color36 : 'transparent'} onClick={() => {
          this.setState({ color36: color, opacity36: opacity });
        }} d="M160.1,206.6c1.3-0.7,2.4-1.4,3.6-1.9c0.3-0.2,0.9,0,1.3,0.2c1.6,0.5,2.6,0.4,3.5-0.8c-1.1-0.1-2.1-0.3-3.3-0.4
	c1.2-1.8,3.8-2.1,3.6-4.8c-2.7,2-5.3,3.9-7.9,5.7c-0.1-0.1-0.2-0.1-0.4-0.2c0.3-1.5,0.6-3,1-4.7c-2,1.4-2.1,3.6-2.7,5.2
	c-1.9-1.2-3.6-2.4-5.5-3.7c2.7-3.2,6.3-4.5,10.2-4.9c3.7-0.4,7.4-0.3,11.1-0.5c0.3,0,0.5,0,0.9,0.1c-0.6,2-1.2,3.9-1.9,5.8
	c-0.3,0.8-0.8,1.6-1.4,2.2c-2.3,2.5-4.6,5-8.1,5.8c-1.1,0.2-1.9,0.5-2.6-0.8C161.2,208,160.6,207.3,160.1,206.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity37 ? this.state.opacity37 : 1}
              fill={this.state.color37 ? this.state.color37 : 'transparent'} onClick={() => {
          this.setState({ color37: color, opacity37: opacity });
        }} d="M62.9,17.9c-2.1,1.1-4,2.1-6,3.1c-2.9,1.5-4.4,4.2-6.2,6.8c-1.8,2.8-3.9,3-5.8,0.3c-1.1-1.6-1.8-3.5-2.4-5.4
	c-0.4-1.1,0.5-2.6,2-3.2c4-1.9,8.3-3.1,12.6-3.9c1.6-0.3,3,0.4,4.3,1.3C61.8,17.2,62.3,17.5,62.9,17.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity38 ? this.state.opacity38 : 1}
              fill={this.state.color38 ? this.state.color38 : 'transparent'} onClick={() => {
          this.setState({ color38: color, opacity38: opacity });
        }} d="M75,12.9c-0.1,0.9,0,2.2-0.4,3.3c-0.3,0.7-1.4,1.2-2.2,1.6c-2.7,1.2-5.3,0.5-7.9-0.4c-1.3-0.5-2.4-1.5-3.6-2.4
	c-0.2-0.1-0.3-0.7-0.1-1c0.9-2.1,1.8-4.2,2.9-6.2c0.6-1,1.4-2,2.3-2.7c2-1.7,4.6-1.2,6.3,0.9C73.7,8,74.9,10.1,75,12.9z M73.1,16.1
	c-0.6-0.4-1-0.8-1.3-0.8c-0.3,0-0.6,0.5-0.9,0.8c0.3,0.3,0.6,0.8,1,0.8C72.1,17,72.5,16.5,73.1,16.1z M74.7,14.3
	c-0.5-0.3-0.9-0.8-1.2-0.7c-0.3,0.1-0.8,0.6-0.8,0.8c0,0.3,0.5,0.8,0.8,0.8C73.9,15.2,74.2,14.8,74.7,14.3z M73.8,11
	c-0.5-0.3-0.7-0.7-1-0.7c-0.3,0-0.6,0.4-0.9,0.6c0.3,0.2,0.5,0.6,0.8,0.7C73,11.7,73.3,11.3,73.8,11z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity39 ? this.state.opacity39 : 1}
              fill={this.state.color39 ? this.state.color39 : 'transparent'} onClick={() => {
          this.setState({ color39: color, opacity39: opacity });
        }} d="M139.2,74.3c-0.1,2.3-0.5,4.8-1.9,6.9c-0.3,0.5-1,0.8-1.6,1.1c0,0-0.1,0-0.1,0c-2.2,1.3-4.4,0.6-6.5-0.3
	c-0.9-0.4-1.9-0.8-2.8-1.4c-1.4-1-2.1-3.9-1.1-5.3c0.5-0.8,1.3-1.4,2.1-1.9c1.8-1.1,3.5-2.2,5.4-3c1.3-0.5,2.8-0.6,4.2-0.6
	c1.4,0,1.7,1.4,2,2.5C139.1,73,139.1,73.6,139.2,74.3z M133.3,80.1c-0.3,0.5-0.7,0.8-0.7,1c0,0.3,0.4,0.5,0.6,0.8
	c0.2-0.3,0.6-0.6,0.7-0.9C133.9,80.8,133.6,80.5,133.3,80.1z M136.1,80.6c-0.4-0.3-0.7-0.7-0.9-0.7c-0.2,0-0.6,0.4-0.6,0.7
	c0,0.3,0.4,0.6,0.6,0.7C135.4,81.3,135.7,80.9,136.1,80.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity40 ? this.state.opacity40 : 1}
              fill={this.state.color40 ? this.state.color40 : 'transparent'} onClick={() => {
          this.setState({ color40: color, opacity40: opacity });
        }} d="M177.1,65.3c0,0.2,0,0.3,0,0.5c-0.1,1.3-0.1,2.6-1.9,2.9c-0.4,0.1-0.9,0.3-1.3,0.6c-0.9,0.8-1.7,0.6-2.7,0.2
	c-0.8-0.3-1.7-0.4-2.6-0.6c-1.5-0.3-2.7-1-3.3-2.6c-0.2-0.6-0.6-1.1-1-1.6c-0.6-0.7-0.9-1.5-0.2-2.3c1.7-2,3.4-4,5.1-6
	c0.4-0.4,0.9-0.7,1.4-0.9c2.1-0.8,3-0.5,4.1,1.6C176.1,59.8,177.2,62.4,177.1,65.3z M172.3,68c-0.4-0.3-0.6-0.5-0.9-0.8
	c-0.2,0.3-0.6,0.5-0.6,0.8c0,0.2,0.4,0.7,0.6,0.7C171.7,68.8,172,68.4,172.3,68z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity41 ? this.state.opacity41 : 1}
              fill={this.state.color41 ? this.state.color41 : 'transparent'} onClick={() => {
          this.setState({ color41: color, opacity41: opacity });
        }} d="M19.3,159.8c-2.7-0.1-3.1-0.5-3.8-3c-1-3.3-0.9-6.7-0.8-10.1c0.1-2.6,2.1-4.4,4.5-4.5c1,0,1.8,0.4,2.2,1.4
	c0.9,2.3,1.7,4.5,1.7,7c0,2.3,0.4,4.7,0.7,7c0.2,1.4,0,1.7-1.4,1.9C21.3,159.6,20.3,159.7,19.3,159.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity42 ? this.state.opacity42 : 1}
              fill={this.state.color42 ? this.state.color42 : 'transparent'} onClick={() => {
          this.setState({ color42: color, opacity42: opacity });
        }} d="M9.9,156.6c-1.1,0-2.2,0-3.3,0c-0.3,0-0.7-0.2-0.9-0.5c-0.9-1.2-1.5-2.4-1.3-4.1c0.6-3.4,1.7-6.6,3.5-9.6
	c0.9-1.4,1.9-2.8,3.9-2.8c0.5,0,0.9-0.3,1.4-0.4c1-0.3,1.7,0.1,2.3,0.9c0.5,0.8-0.1,1.3-0.5,1.9c-1,1.6-2.1,3.3-2,5.4
	c0.1,2.4,0.1,4.9,0.2,7.3c0.1,1.3-1,1.5-1.8,1.9c-0.5,0.2-1,0.1-1.5,0.2C9.9,156.7,9.9,156.6,9.9,156.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity43 ? this.state.opacity43 : 1}
              fill={this.state.color43 ? this.state.color43 : 'transparent'} onClick={() => {
          this.setState({ color43: color, opacity43: opacity });
        }} d="M111.9,168.4c0.7-1.9,2.4-2,3.8-2.4c1.8-0.4,3.6-0.4,5.4-0.6c0.9-0.1,1.8,0,2.3,1c0.1,0.3,0.9,0.4,1.3,0.3
	c1.7-0.2,3,0.8,4.2,1.7c0.6,0.4,0.9,1.5,0.9,2.3c-0.1,2.2-1.2,4.1-2.4,5.9c-0.1,0.2-0.6,0.3-0.9,0.2c-2.3-0.4-4.2-1.5-5.9-3.1
	C118,171.5,115.5,169.1,111.9,168.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity44 ? this.state.opacity44 : 1}
              fill={this.state.color44 ? this.state.color44 : 'transparent'} onClick={() => {
          this.setState({ color44: color, opacity44: opacity });
        }} d="M28.3,140c0.2-0.2,0.4-0.4,0.6-0.6c1.5-2.3,3.5-1.6,5.5-0.9c1.1,0.4,2.3,0.6,3.2,1.2c1.5,0.9,2.9,1.9,4.2,3.1
	c3.3,3.1,2.9,3.3,0.7,6.7c-0.6,0.9-1.6,1.6-2.5,2.4c-0.2,0.1-0.8,0-1-0.2c-1.3-1.6-2.9-2.7-4.4-3.9c-1.9-1.6-3.4-3.9-5-5.8
	C29.1,141.3,28.8,140.7,28.3,140z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity45 ? this.state.opacity45 : 1}
              fill={this.state.color45 ? this.state.color45 : 'transparent'} onClick={() => {
          this.setState({ color45: color, opacity45: opacity });
        }} d="M174.2,84c-0.3-3.2-0.7-6.3-1-9.5c-0.1-0.9-0.1-1.9,0.1-2.8c0.1-0.5,0.8-0.9,1.3-1.3c2.3-1.6,4.5-0.7,5.5,1.5
	c0.9,1.9,1.3,4,1.7,6c0.3,1.2,0.3,2.4,0.4,3.7c0,1.3-0.5,2.4-1.8,2.8c-1.8,0.5-3.6,1-5.4,1.4c-0.7,0.2-1.1-0.2-1.1-0.9
	c0-0.3,0.1-0.6,0.1-1C174.1,84,174.1,84,174.2,84z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity46 ? this.state.opacity46 : 1}
              fill={this.state.color46 ? this.state.color46 : 'transparent'} onClick={() => {
          this.setState({ color46: color, opacity46: opacity });
        }} d="M165.7,69.4c-0.9,2.1-1.7,4.2-2.7,6.3c-1.2,2.7-2.6,5.3-3.9,7.9c-0.4,0.9-1.1,1.1-2,0.8
	c-1.4-0.5-2.9-0.8-4.2-1.5c-1.3-0.7-1.4-1.3-0.8-2.7c1.5-3.6,3.6-6.7,6.7-9.2c1.1-0.9,2.4-1.5,3.6-2.3
	C163.6,68.1,164.6,68.4,165.7,69.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity47 ? this.state.opacity47 : 1}
              fill={this.state.color47 ? this.state.color47 : 'transparent'} onClick={() => {
          this.setState({ color47: color, opacity47: opacity });
        }} d="M145.5,139.4c0.4,0.8,1.1,1.5,1.2,2.3c0.3,2.6,0.3,5.2,0.5,7.7c0,0.5,0.1,1.1,0.1,1.9c2-1.7,1-3.8,1.5-5.5
	c2.2,1,2.1,3.1,2.3,5c0.2,0,0.3,0,0.5,0c0.2-0.6,0.6-1.2,0.5-1.7c-0.2-1.1-0.6-2.1-1.1-3.1c-0.3-0.6-0.4-1,0.1-1.4
	c2-1.6,4-3.1,6-4.7c0.2-0.2,0.6-0.3,0.9-0.4c1.7-0.7,1.9-0.5,2.1,1.2c0.1,0.6,0.1,1.2,0.3,1.8c1,3.3,0.9,6.5,0,9.8
	c-0.1,0.4,0.1,0.8,0.1,1.3c0.3-0.3,0.9-0.5,1-0.8c0.5-1.5,0.8-3,1.3-4.5c0.1-0.4,0.4-0.8,0.6-1.2c0.1,0,0.2,0.1,0.3,0.1
	c0,1.2,0,2.5,0,3.7c0,0.4,0.3,0.8,0.4,1.2c0.4-0.3,0.8-0.6,1.1-1c0.3-0.4,0.4-1,0.8-1.2c1.2-0.4,2.4-0.7,3.7-1
	c0.1,0,0.3,0.2,0.4,0.3c0.7,1.2-0.1,3.4-1.4,3.9c-0.7,0.3-1.5,0.5-2.2,0.9c-0.3,0.2-0.5,0.6-0.7,0.9c0.4,0.2,0.8,0.6,1.1,0.6
	c1.2-0.3,2.4-0.6,3.6-1c2-0.7,3.9-1.4,6-2.1c-1.9,3.1-4.2,5.8-7.4,7.6c0.6,0.9,1.1,1,1.7,0.5c1.2-0.8,2.5-1.6,3.7-2.4
	c0.5-0.3,1-0.5,1.5-0.8c0.1,0.1,0.1,0.2,0.2,0.3c-0.3,0.3-0.7,0.6-0.9,1c-0.2,0.4-0.3,1-0.2,1.4c0.1,0.2,0.7,0.5,1,0.5
	c1,0,2.1-0.1,3.1,0c-0.8,0.3-1.8,0.5-2.5,1.1c-2,1.5-3.9,3-5.7,4.8c-2.3,2.3-4.8,4.3-7.7,5.6c-3.3,1.6-6.6,3.2-10.3,3.8
	c-2.6,0.4-4.8-0.4-6.8-2.2c-0.7-0.6-1.9-0.6-2.9-0.8c-0.1,0-0.5,0.5-0.5,0.7c0,0.3,0.3,0.7,0.5,0.9c0.7,0.3,1.4,0.6,2.1,0.7
	c1,0.1,1,0.6,0.7,1.4c-0.5,1.1-2.6,2.2-4.1,2c-0.2,0-0.5-0.1-0.7-0.1c-2-0.3-3-2.5-1.9-4.2c0.2-0.3,0.6-0.3,0.8-0.5
	c0.3-0.2,0.8-0.5,0.8-0.7c0-0.4-0.3-0.8-0.6-1.1c-0.2-0.3-0.6-0.4-0.9-0.6c-2.8-2.3-3.6-5.5-3.7-8.8c-0.2-3.3,0.1-6.8,2-9.7
	c1.8-2.7,2.9-5.7,4.2-8.6c0.8-1.7,1.7-3.3,2.6-4.9C145.2,139.5,145.3,139.4,145.5,139.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity48 ? this.state.opacity48 : 1}
              fill={this.state.color48 ? this.state.color48 : 'transparent'} onClick={() => {
          this.setState({ color48: color, opacity48: opacity });
        }} d="M134.7,116.8c1.5-1.3,2.6-2.3,3.9-3.3c0.6,1.4,0,3.6-0.9,4.4c-0.5,0.4-0.9,0.8-1.3,1.2
	c-0.2,0.3-0.2,0.8-0.4,1.1c0.5,0,1.1,0.2,1.4-0.1c0.9-0.6,1.6-1.5,2.5-2c2.8-1.9,5.9-3.2,9.2-3.9c0.2,0,0.4,0,0.7,0.1
	c-0.9,2-1.9,3.8-3.3,5.3c-1.3,1.4-2.9,2.7-3.5,4.7c0.1,0.1,0.3,0.3,0.4,0.4c0.9-0.7,1.8-1.5,2.6-2.2c0.6-0.6,1.1-1.3,1.7-1.8
	c0.3-0.3,0.9-0.3,1.5-0.6c-1,3.8-4.5,4.8-6.6,7.4c0.4,0,0.9,0.2,1.2,0c0.9-0.4,1.7-0.8,2.5-1.4c2.5-1.9,5.3-2.7,8.3-3.2
	c0.5-0.1,1.1,0.2,1.8,0.4c-2.6,1.9-4.9,3.6-7.2,5.4c-2.3,1.8-4.6,3.6-6.9,5.3c-3.5,2.5-7.2,4.6-11.1,6.3c-3,1.4-6.1,1-9.2,0.4
	c-1.2-0.2-1.5,0-1.3,1.1c0.2,1.5-1.8,3.3-3.4,3c-0.7-0.1-1.4-0.3-2.1-0.4c-0.2,0-0.4-0.1-0.5-0.3c-1-1-0.7-3.3,0.5-4
	c0.3-0.2,0.8-0.1,1.2-0.1c0.5,0,0.9-0.1,1.4-0.1c-0.1-0.5,0-1.2-0.3-1.6c-2.4-4.2-4-8.7-2.8-13.6c0.7-2.7,1.9-5.2,2.9-7.8
	c0.4-1,0.8-1.9,1.1-2.9c0.9-2.7,1.8-5.5,2.7-8.3c0.3-0.8,0.7-1,1.2-0.3c0.7,1.1,1.5,2.3,1.9,3.5c0.8,2.9,1.1,5.8,0.7,8.8
	c-0.1,0.4,0,0.9,0.2,1.3c0.1,0.3,0.5,0.6,0.7,0.8c0.3-0.4,0.9-0.7,0.9-1.1c0.1-2.2,0-4.4-0.1-6.5c0-0.3,0.1-0.7,0.3-1
	c1.4-2,3.4-3.5,5.6-4.5c1.5-0.7,2.2-0.4,2.4,1.3c0.2,2.2,0.3,4.5-0.5,6.6C134.7,115.4,134.7,116,134.7,116.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity49 ? this.state.opacity49 : 1}
              fill={this.state.color49 ? this.state.color49 : 'transparent'} onClick={() => {
          this.setState({ color49: color, opacity49: opacity });
        }} d="M26.9,183c1.4,3.5,3.9,6.4,6.2,9.3c2.3,2.9,5.2,5.4,7.8,8c-4.3-0.1-7.8-2.4-11.1-5.2c-0.1,0.2-0.3,0.4-0.4,0.6
	c0.2,0.3,0.3,0.7,0.6,1c2.8,3.1,6.4,4.5,10.4,5.3c0.6,0.1,1.3-0.3,2-0.5c0-0.1,0-0.3,0-0.4c2.4,1.8,4.9,3.5,7.3,5.3
	c0,0.1-0.1,0.2-0.1,0.3c-0.8,0-1.5-0.1-2.3,0c-0.4,0.1-0.8,0.4-1.3,0.7c0.4,0.3,0.8,0.7,1.3,0.8c0.6,0.1,1.3,0.2,1.9,0
	c2.3-0.7,3.9,0.7,5.7,1.7c0.3,0.2,0.6,0.6,0.6,0.9c0.1,1.6,0.1,3.2,0.2,4.8c0.1,0.7-0.2,0.9-0.9,1.1c-2.1,0.4-4.2,0.2-6.3-0.1
	c-2-0.3-3.9-0.6-5.7-1.5c-0.2-0.1-0.3-0.2-0.4-0.3c1.3-0.7,2.5-1.3,3.8-2c0.2-0.1,0.5-0.1,0.7,0c2.3,1.3,4.9,1.4,7.4,1.5
	c0.3,0,0.7-0.3,1-0.5c-0.3-0.2-0.6-0.6-0.9-0.6c-4.2,0.1-7.7-1.7-11.3-3.5c-0.8-0.4-1.5-1-2.2-1.6c-1.4-1.1-2.9-2-4.5-2.7
	c-3.6-1.4-6.9-3.5-9.7-6.2c-0.6-0.5-1.1-1.2-1.6-1.8c-3.2-4.5-6.1-9.1-6.7-14.7c-0.5-3.9-1.3-7.7-1.8-11.6c3.6,2.3,7.7,3,11.4,4.6
	c2.6,1.1,5,2.5,7.5,3.9c1.5,0.8,3.1,1.6,4.6,2.5c1.1,0.6,2.2,1.2,3.1,2c1.9,1.7,4,3.4,5.5,5.5c2,2.7,3.5,5.8,5.1,8.8
	c0.8,1.4,1.3,3,1.9,4.5c0.2,0.4,0.6,0.7,1.1,1.2c-0.3,1.4-0.6,3-1,4.8c-0.7-0.4-1.4-0.7-2.1-1.1c-0.1-0.1-0.3-0.3-0.3-0.4
	c0.2-1-0.3-1-1-0.9c-1.3,0.1-3.4-2-3.2-3.3c0.5-3.6-0.8-6.8-2.7-9.7c0,0,0-0.1-0.1-0.1c-0.4-0.3-0.9-0.6-1.3-0.9
	c0,0.6-0.1,1.2,0.1,1.6c0.4,1,1.1,2,1.4,3c0.6,2,1,4.1,1.4,6.1c-0.2,0.1-0.3,0.2-0.5,0.3c-3.1-2.4-6.2-4.7-9.3-7.1
	c0.2-0.1,0.3-0.2,0.5-0.2c0-1.1,0.2-2.2,0-3.3c-0.3-1.9-0.7-3.8-1.2-5.7c-0.1-0.4-0.7-0.6-1-0.9c-0.2,0.4-0.5,0.9-0.5,1.2
	c0.3,1.8,0.8,3.7,1.1,5.5c0.1,0.5,0,1.1-0.1,1.6c-0.1,0.1-0.2,0.1-0.4,0.2c-3.1-3.9-6.1-7.8-9.2-11.7
	C27.3,182.8,27.1,182.9,26.9,183z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity50 ? this.state.opacity50 : 1}
              fill={this.state.color50 ? this.state.color50 : 'transparent'} onClick={() => {
          this.setState({ color50: color, opacity50: opacity });
        }} d="M94.4,54.3c1.3-0.4,1.7-1.3,1.6-2.4c-0.1-1.5-0.4-3-0.5-4.5c0-0.4,0.1-1,0.3-1.3c0.9-1.7,2.6-2.4,4.1-3.2
	c0.1,0.1,0.2,0.2,0.3,0.3c-0.3,0.6-0.7,1.2-0.8,1.9c-0.2,1.1-0.4,2.2-0.4,3.4c0,2.9,0.3,5.9,0.1,8.8c-0.2,2.5-0.8,5-1.6,7.4
	c-0.8,2.3-2.2,4.3-4.2,5.9c-1.1,0.9-2.1,2-3.2,2.9c-0.6,0.5-1.3,0.9-2,1.2c0.7-2.3,1.6-4.4,1.1-6.8c-1.4,2.3-0.9,5.6-3.6,7.5
	c-0.2-1-0.3-1.8-0.4-2.6c-0.2,0-0.3,0-0.5,0c-0.1,0.6-0.2,1.2-0.3,2c-1.2-0.8-2.2-1.5-3.3-2.2c-0.3-0.2-0.5-0.5-0.8-0.7
	c-0.2-0.1-0.6-0.1-0.9-0.1c0,0.3-0.1,0.7,0.1,0.9c0.7,1.1,1.5,2.1,2.5,3c-3.2-1.3-6.8-2.3-8.8-5.8c-1-1.7-2.2-3.3-3.1-5.1
	c-1.6-3-2.2-6.3-2.5-9.7c-0.2-2.4,0.1-4.9,0.3-7.4c0-0.4,0.3-0.8,0.5-1.5c0.7,0.6,1.3,1.1,1.8,1.6c1.3,1.4,3.2,2.4,3.8,4.4
	c0.4,1.3,0.5,2.7,0.9,4c0.4,1.6,1,3.1,1.5,4.7c0.1,0.2,0.3,0.4,0.5,0.6c0.1,0,0.3,0,0.4,0c0-0.7,0.1-1.4,0-2c-0.4-1.5-1-3-1.4-4.4
	c-0.1-0.3-0.2-0.8,0-0.9c1-0.7,0.5-1.5,0.2-2.2c-0.7-1.3-1.2-2.7-0.8-4.2c0.1-0.4,0.3-0.8,0.5-1.5c0.6,0.9,1.1,1.4,1.3,2
	c0.7,1.8,1.2,3.6,1.9,5.4c0.6,1.5,1.2,3.2,2.7,4.1c0.3,0.2,0.7,0.1,1.1,0.1c0-0.3,0-0.7-0.1-1c-1.7-2.6-2.3-5.6-3-8.5
	c-0.3-1.2,0.2-2.9,0.9-4c1-1.6,2.4-3,3.7-4.4c0.1-0.2,0.9,0,1,0.2c0.9,1.5,1.7,2.9,2.4,4.5c0.7,1.6,1.2,3.2,1.8,4.9
	c1.3-0.7,1.6-1.5,0.9-3.1c-0.5-1-0.3-1.7,0.8-2.1C94.3,46.9,93.7,50.7,94.4,54.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity51 ? this.state.opacity51 : 1}
              fill={this.state.color51 ? this.state.color51 : 'transparent'} onClick={() => {
          this.setState({ color51: color, opacity51: opacity });
        }} d="M82.7,212c-5.3,3.1-11.1,3.9-17.2,3.7c0.2-0.3,0.3-0.6,0.4-0.6c1.7-0.5,2.3-2.3,3.6-3.3c1.3-1.1,2.6-2.3,4.1-3
	c1.1-0.5,2.5-0.4,3.7-0.5c2.7-0.1,5.3-0.2,8-0.3c0.3,0,0.7-0.4,1-0.6c-0.3-0.2-0.6-0.6-0.9-0.6c-1.9-0.1-3.8,0-5.8-0.2
	c2-1.1,4-2.2,6-3.4c1.1-0.6,2.2-1.3,3.2-2.1c0.5-0.4,0.7-1,1-1.5c-0.1-0.1-0.3-0.2-0.4-0.4c-0.5,0.3-1,0.5-1.5,0.8
	c-3.5,2.2-7,4.4-11.1,5.7c-2.6,0.8-5.1,1.9-7.2,3.7c-0.6,0.5-1.1,1.2-1.7,1.7c0.1-1.7,0.9-2.8,2.1-3.8c1.2-0.9,1.3-1.1,1.2-2
	c-2.9,1.3-4.8,3.4-5.2,6.5c-0.3,2.5-2.3,3.1-4,4.1c-0.3,0.2-0.6,0.4-0.9,0.7c-1,0.9-2.1,1.8-3.1,2.8c-0.1,0-0.3,0-0.4,0
	c-0.1-1.8-0.4-3.6-0.4-5.5c0-1,0.2-2,0.7-2.9c0.6-1.1,1.5-2.2,2.5-2.9c1.8-1.3,3.7-2.6,5.7-3.5c2.1-1,4.4-1.8,6.7-2.5
	c2.5-0.7,5.1-1.1,7.6-1.8c2.6-0.7,5.2-1.4,7.7-2.2c2-0.7,3.9-1.7,5.8-2.5c0.3-0.1,0.6-0.3,1.1-0.4c-0.6,2.4-1.3,4.7-1.7,7
	c-0.3,1.5-0.3,3.1-0.3,4.6c-0.1,1.6-0.9,2.9-1.8,4.2c-0.4,0.6-0.6,1.3-1.1,1.8c-2.8,2.7-5.8,5-9.5,6.5c-3.5,1.4-7.1,1.6-10.7,2
	c-1.7,0.2-3.5,0.5-5.2,0.4c-1.9,0-3.8-0.3-5.9-0.6c2.3-2.5,4.5-4,7.7-4c2.9,0.1,5.8-0.3,8.7-0.8c2-0.4,4-1.2,5.9-2
	c0.8-0.3,1.4-1.1,2-1.6C83,212.4,82.9,212.2,82.7,212z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity52 ? this.state.opacity52 : 1}
              fill={this.state.color52 ? this.state.color52 : 'transparent'} onClick={() => {
          this.setState({ color52: color, opacity52: opacity });
        }} d="M121,192.6c-0.9,1.1-1.6,2-2.4,2.8c-1.2,1.3-1.8,2.8-2.2,4.5c-0.6,2.2-1.1,4.4-1.8,6.5
	c-0.9,2.6-2.9,4.2-5.2,5.6c-4,2.5-8.2,4.3-12.9,4.9c-2.5,0.3-5.1,0-8-0.6c2.5-2.4,4.2-5.2,7.5-6.3c0.3-0.1,0.6,0,0.9,0.1
	c2.6,1.2,5.3,1.3,8.1,1.1c0.2,0,0.5,0,0.7-0.2c0.3-0.2,0.4-0.5,0.7-0.7c-0.3-0.2-0.6-0.5-0.9-0.5c-1.9-0.2-3.9-0.2-5.8-0.4
	c-0.4,0-0.8-0.2-1.4-0.4c0.3-0.3,0.5-0.5,0.7-0.6c1.3-0.7,2.7-1.4,4-2.2c0.5-0.3,0.9-0.3,1.5,0c1.5,0.9,4.1,0.6,5.4-0.6
	c0.2-0.2,0.4-0.5,0.4-1.1c-1.6,0.2-3.2,0.5-4.8,0.7c0-0.1-0.1-0.2-0.1-0.3c0.1-0.2,0.2-0.3,0.4-0.5c1.5-1.5,3-3,4.5-4.5
	c0.5-0.5,1-0.9,1.5-1.4c0.2-0.3,0.2-0.7,0.2-1.1c-0.4,0-0.9-0.1-1.1,0c-0.8,0.6-1.5,1.3-2.1,2.1c-1.9,2.4-4.3,4.1-7.1,5.5
	c0.4-2.9,2.5-4.6,4.1-6.6c-0.1-0.2-0.3-0.3-0.4-0.5c-0.6,0.2-1.2,0.4-1.6,0.8c-2.2,1.8-3.4,4.1-4,6.8c-0.2,0.8-0.5,1.2-1.2,1.5
	c-1.4,0.6-2.8,1.2-4.2,1.9c0.2-1.8,0.3-3.7,0.5-5.5c0.1-0.7,0.3-1.7,0.8-2c3.2-2.5,6.4-5,10.3-6.2c4.7-1.4,9.4-2.6,14.3-2.6
	C120.4,192.6,120.5,192.6,121,192.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1}
              fill={this.state.color53 ? this.state.color53 : 'transparent'} onClick={() => {
          this.setState({ color53: color, opacity53: opacity });
        }} d="M104.3,216.5c1.6-0.9,3.1-1.7,4.6-2.5c0.2-0.1,0.4-0.3,0.6-0.3c2.4,0.1,4.3-1.4,6.4-2.2c0.4-0.1,0.9-0.1,1.3,0
	c1.3,0.4,2.7,0.9,4,1.2c0.4,0.1,1,0.1,1.4-0.1c0.3-0.2,0.5-0.7,0.8-1c-0.4-0.1-0.7-0.3-1.1-0.4c-1.1-0.2-2.3-0.4-3.4-0.7
	c0-0.1,0-0.3,0-0.4c0.8-0.4,1.5-1.1,2.3-1.3c2.6-0.6,5.2-1.8,8-1.3c0.3,0,0.7,0.1,0.9-0.1c0.4-0.2,0.7-0.6,1-0.9
	c-0.3-0.2-0.7-0.6-1-0.6c-1.7-0.1-3.5,0-5.2,0c1.6-2.7,4.8-4.4,5.9-7.7c-2,0.8-3.1,2.7-4.8,3.9c-0.1-0.1-0.3-0.1-0.4-0.2
	c0.4-1.3,0.8-2.7,1.1-4.1c-1.1,0-1.8,0.7-2,1.6c-0.3,1.2-0.4,2.4-0.3,3.5c0.1,0.8-0.2,1.2-0.6,1.7c-2.3,2.6-5.4,4-8.5,5.5
	c-0.5,0.2-1,0.4-1.8,0.7c0.2-0.4,0.3-0.6,0.5-0.8c1.5-1.6,2-3.6,2.6-5.6c0.3-0.9,0.8-1.9,1.5-2.6c3.9-4.1,8.6-6.9,13.9-8.8
	c2.3-0.8,4.7-0.9,7.4-0.6c-0.4,0.5-0.6,0.8-0.9,1.1c-1.8,1.8-2.5,4.1-3,6.5c-0.4,2.2-1.1,4.3-1.8,6.4c-0.8,2.2-2.2,3.9-4.1,5.1
	c-2.6,1.6-4.8,3.7-7.8,4.5c-4.1,1.1-8.2,1.5-12.4,1.1c-1.1-0.1-2.3-0.1-3.4-0.3C105.4,217.1,104.9,216.7,104.3,216.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity54 ? this.state.opacity54 : 1}
              fill={this.state.color54 ? this.state.color54 : 'transparent'} onClick={() => {
          this.setState({ color54: color, opacity54: opacity });
        }} d="M44.2,211.9c-3,0.7-6.1,1.6-9.1,2.1c-1.9,0.4-3.8,0.4-5.7,0.5c-3.3,0.1-6.6,0.1-9.9,0.2c-2.2,0-4.2-0.8-6.2-1.6
	c-4.2-1.8-5.5-2.8-7.9-6.5c1.3-0.7,2.6-1.4,3.9-2.2c1-0.6,2-1.1,3-1.8c2.2-1.5,4.6-2.6,7.1-3.4c1.1-0.4,2.2-0.8,3.4-1.1
	c0.4-0.1,1.1,0,1.4,0.3c2.3,2.6,5.1,4.6,8.1,6.2c0.1,0.1,0.2,0.2,0.4,0.4c-2.2,0.2-4.2,0.5-6-0.9c-1.6-1.2-3.5-1.2-5.4-1.3
	c-0.2,0-0.4,0.3-0.6,0.4c0.1,0.2,0.2,0.4,0.4,0.5c0.5,0.2,1.1,0.4,1.7,0.6c1.1,0.4,2.2,0.9,3.3,1.3c0,0.2-0.1,0.3-0.1,0.5
	c-0.8,0.1-1.5,0.2-2.3,0.3c-2.1,0.2-4.1,0.5-6.2,0.6c-1.4,0.1-2.8-0.3-4.1-0.3c-0.4,0-0.8,0.2-1.1,0.3c0.2,0.4,0.3,1.1,0.5,1.2
	c0.9,0.3,1.9,0.6,2.8,0.5c3.1-0.2,6.2-0.3,9.3-0.7c2.8-0.3,5.6-1,8.4-1.5c0.3,0,0.6-0.1,0.8-0.3c0.6-0.6,1-0.3,1.8,0.4
	c-1.6,1-3,2-4.5,2.8c-1.4,0.7-2.9,1.1-4.3,1.7c-0.3,0.1-0.6,0.4-0.8,0.7c0.3,0.2,0.6,0.5,1,0.5c1.6,0.2,3.1-0.4,4.4-1.3
	c1.7-1.1,3.4-2.2,5.1-3.3c0.5-0.3,0.9-0.5,1.5,0c1.1,0.8,2.1,1.7,3.3,2.4c0.9,0.5,1.8,0.9,2.8,1.4C44.2,211.6,44.2,211.8,44.2,211.9
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity55 ? this.state.opacity55 : 1}
              fill={this.state.color55 ? this.state.color55 : 'transparent'} onClick={() => {
          this.setState({ color55: color, opacity55: opacity });
        }} d="M54.1,127.5c0.2-1.7,1.4-2.4,2.4-3.3c0.5-0.4,0.9-0.9,1.4-1.3c0.3-0.3,0.7-0.4,1.1-0.6c2.4-0.7,4.8-1.4,7.2-2
	c2.1-0.5,4.2-0.8,6.3-1.1c0.3,0,0.7,0.3,0.9,0.5c0.8,0.7,1.7,1.4,2.5,2.2c0.2,0.2,0.5,0.7,0.4,0.9c-2.3,5-3.8,10.5-7.4,14.8
	c-0.4,0.5-0.8,0.9-1.3,1.3c-0.8,0.6-1.7,1-2.5,0.4c-3-2.1-6.3-3.8-8.7-6.7C55.3,131,54.5,129.2,54.1,127.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity56 ? this.state.opacity56 : 1}
              fill={this.state.color56 ? this.state.color56 : 'transparent'} onClick={() => {
          this.setState({ color56: color, opacity56: opacity });
        }} d="M80,110.4c-0.9,0.3-2.2,0.8-3.5,1.1c-0.4,0.1-0.9,0.1-1.2-0.1c-2.9-2-5.8-3.9-8.6-6c-1.9-1.5-3.6-3.2-5.3-4.9
	c-0.7-0.7-0.5-1.8,0.5-2.5c2.4-1.4,4.8-2.8,7.3-4c3.2-1.4,6.6-1.7,10-1.7c2.3,0,3.5,2,3.2,3.8C82,99,82,102,81.8,105
	c-0.1,1.5-0.2,3-0.2,4.5C81.6,110.5,81.3,110.8,80,110.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity57 ? this.state.opacity57 : 1}
              fill={this.state.color57 ? this.state.color57 : 'transparent'} onClick={() => {
          this.setState({ color57: color, opacity57: opacity });
        }} d="M149.7,208.5c0.6,0.1,1.2,0.2,1.9,0.2c0.5,0,1,0,1.5-0.1c0.2,0,0.4-0.3,0.6-0.5c-0.2-0.2-0.3-0.4-0.6-0.5
	c-1.9-0.6-3.8-0.8-5.8-0.5c-0.7,0.1-1.5,0-2.2-0.2c-1.4-0.3-2.8-0.7-4.5-1.2c1.2-0.5,2.1-1,3.1-1.3c1-0.3,2.1-0.3,3.1-0.6
	c0.3-0.1,0.5-0.5,0.8-0.7c-0.3-0.1-0.6-0.4-0.9-0.4c-2.7-0.2-5.4,0.3-7.3,2.3c-1.1,1.1-2.3,1-3.6,1.3c0.5-1.9,0.8-3.7,1.4-5.4
	c0.2-0.6,1.2-1.1,2-1.3c3.1-0.9,6.3-0.9,9.2,0.7c2.1,1.1,4,2.6,6.1,3.6c2.4,1.2,3.7,3.2,5.1,5.3c0.7,1.1,1.6,2,2.9,2.5
	c0.8,0.3,1.5,1,2.3,1.5c-0.9,1.2-2.2,1.7-3.4,1.9c-3.2,0.6-6.4,0.7-9.6,0.2c-4.6-0.7-9.2-1.4-13.8-1.9c-1.8-0.2-3.7,0.2-5.6,0.2
	c-0.8,0-1.5-0.1-2.3-0.2c0-0.2,0-0.3-0.1-0.5c0.5-0.4,1-0.8,1.5-1.3c0.5-0.5,1.2-0.9,1.5-1.5c1.2-2.2,3.2-2.8,5.5-2.9
	c0.9-0.1,1.9,0,2.7,0c0.4,1.4,0.7,2.6,1.3,3.7c0.7,1.4,1.9,2.1,3.5,2.1c0.3,0,0.6-0.3,0.9-0.5c-0.2-0.2-0.5-0.5-0.7-0.5
	c-1.8-0.4-2.8-1.8-2.7-4.2c0.5,0.2,1,0.3,1.4,0.5c2.8,0.9,5.6,1.8,8.4,2.6c0.3,0.1,0.7,0.2,1.1,0.1c0.2,0,0.4-0.3,0.6-0.4
	c-0.2-0.2-0.3-0.5-0.5-0.6c-1.6-0.5-3.2-1-4.9-1.5C149.7,208.6,149.7,208.5,149.7,208.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity58 ? this.state.opacity58 : 1}
              fill={this.state.color58 ? this.state.color58 : 'transparent'} onClick={() => {
          this.setState({ color58: color, opacity58: opacity });
        }} d="M90.2,92.3c2.9,0.2,5.8,0.5,8.4,2c2.2,1.2,3.8,3,4.2,5.5c0.1,0.8-0.4,1.7-0.9,2.5c-1.4,2.1-3.3,3.7-5.5,4.9
	c-3.2,1.7-6.3,3.3-9.5,4.9c-0.3,0.2-0.8-0.1-1.2-0.2c-0.4-0.1-0.7-0.5-1.1-0.5c-1.3,0-1.3-0.8-1.3-1.8c0.2-4,0.4-8,0.6-12
	c0.1-1.2,0.4-2.4,0.9-3.5c0.2-0.6,1-1.1,1.6-1.2C87.7,92.5,89,92.5,90.2,92.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity59 ? this.state.opacity59 : 1}
              fill={this.state.color59 ? this.state.color59 : 'transparent'} onClick={() => {
          this.setState({ color59: color, opacity59: opacity });
        }} d="M79.2,141.2c-0.7,0-1.4,0.1-2.1,0c-2.5-0.2-3.5-1.7-3.4-4.1c0.1-1.3,0.1-2.7,0.2-4c0-0.4,0.2-0.8,0.3-1.1
	c1.3-2.8,2.6-5.5,3.8-8.2c0.2-0.5,0.5-0.7,1.2-0.7c1.3-0.1,2.6-0.4,3.9-0.6c0.6-0.1,0.9-0.1,1.4,0.4c1.5,1.8,3.1,3.5,4.8,5.1
	c1.6,1.6,3.8,2.8,4.5,5.3c0.4,1.6,0,3-1.3,4.1c-2.3,1.8-5,2.5-7.6,3.2c-1.8,0.5-3.6,0.7-5.5,1C79.3,141.4,79.2,141.3,79.2,141.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity60 ? this.state.opacity60 : 1}
              fill={this.state.color60 ? this.state.color60 : 'transparent'} onClick={() => {
          this.setState({ color60: color, opacity60: opacity });
        }} d="M37.5,46.6c0.6,0.1,0.9,0.1,1.2,0.1c2.9,0.2,4.6,1.6,4.4,4.5c-0.2,2.9-0.9,5.9-1.4,8.8c-0.2,1-0.5,1.9-0.7,2.9
	c-0.2,0.7-0.5,1.1-1.4,1.1c-1.8-0.1-3.7,0.1-5.3,1.2c-0.3,0.2-0.9,0.2-1.2,0c-3.8-3-7-6.6-9.7-10.6c-1.2-1.8-0.7-2.9,1-4
	c3.5-2.2,7.5-3.1,11.4-3.9C36.5,46.5,37.2,46.6,37.5,46.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity61 ? this.state.opacity61 : 1}
              fill={this.state.color61 ? this.state.color61 : 'transparent'} onClick={() => {
          this.setState({ color61: color, opacity61: opacity });
        }} d="M32.2,66.8c-0.5,1.3-0.9,2.5-1.3,3.7c-0.1,0.3-0.5,0.6-0.8,0.6c-3.1,0.3-6.2,0.6-9.3,0.6
	c-3.1,0-6.2-0.4-9.2-0.6c-1.8-0.1-2.7-0.7-2.6-2.5c0.3-4.9,1.2-9.6,5.8-12.6c0.8-0.5,1.6-0.8,2.6-0.3c2.3,1.1,4.6,2.1,6.9,3.1
	c0.2,0.1,0.4,0.1,0.5,0.3C26.7,62.4,29.7,64.5,32.2,66.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity62 ? this.state.opacity62 : 1}
              fill={this.state.color62 ? this.state.color62 : 'transparent'} onClick={() => {
          this.setState({ color62: color, opacity62: opacity });
        }} d="M73.8,113.1c-0.3,1.2-0.6,2.4-0.8,3.6c-0.1,0.6-0.3,0.9-0.9,1c-4.2,1-8.4,2-12.7,3c-0.8,0.2-1.6,0.4-2.4,0.6
	c-2.5,0.6-4.2-0.3-4.6-2.9c-0.5-3.5-0.6-7.1,0.9-10.4c0.4-0.9,1.1-1.8,1.9-2.5c1-0.9,2.1-1,3.4-0.6c1.6,0.5,3.3,0.6,4.9,0.9
	c0.5,0.1,1,0.5,1.4,0.8c2.8,1.8,5.5,3.6,8.2,5.5c0.4,0.3,0.6,0.7,0.9,1.1C74,113.1,73.9,113.1,73.8,113.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity63 ? this.state.opacity63 : 1}
              fill={this.state.color63 ? this.state.color63 : 'transparent'} onClick={() => {
          this.setState({ color63: color, opacity63: opacity });
        }} d="M137.5,27.5c-2.7-1.4-5.1-0.4-7.7-0.3c-0.2-0.7-0.4-1.5-0.8-2.3c-0.9-2.1-2-4.2-2.8-6.3
	c-0.5-1.5-0.8-3.1-0.9-4.7c0-0.5,0.7-1.4,1.3-1.6c5.5-1.9,11-2.1,16.5,0c1.7,0.6,2.2,1.8,1.6,3.5C143.1,20.1,140.2,23.8,137.5,27.5z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity64 ? this.state.opacity64 : 1}
              fill={this.state.color64 ? this.state.color64 : 'transparent'} onClick={() => {
          this.setState({ color64: color, opacity64: opacity });
        }} d="M124.1,30.9c-4.3-0.7-8.6-1.4-12.9-2.2c-0.8-0.2-1.6-0.7-2.4-1c-0.9-0.4-1.2-1.1-0.9-2
	c1.6-4.7,3.9-8.8,8.6-11.1c2-1,3.3-0.9,5.1,0.6c2,1.7,2.9,3.8,4,5.9c0.9,1.8,1.5,3.9,2.2,5.8c0.3,0.8,0.3,1.5-0.7,1.9
	c-0.4,0.2-0.7,0.5-0.9,0.9c-0.4,0.9-1,1.3-2,0.9C124.1,30.7,124.1,30.8,124.1,30.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity65 ? this.state.opacity65 : 1}
              fill={this.state.color65 ? this.state.color65 : 'transparent'} onClick={() => {
          this.setState({ color65: color, opacity65: opacity });
        }} d="M39.9,77.1c-0.1,4.3,0.4,8.7-0.5,13.1c-0.3,1.3-1.2,2.4-2.4,2.5c-1.3,0.2-2.5,0.3-3.8,0.5
	c-3.1,0.4-6.2,0.5-9.2-0.3c-2.1-0.5-2.9-1.8-2.3-3.8c0.6-1.9,1.5-3.6,3.2-4.9c2.1-1.7,4-3.6,6-5.5c0.9-0.9,1.8-1.8,2.8-2.6
	c0.1-0.1,0.4-0.2,0.6-0.2C36.1,76.4,38,76.7,39.9,77.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity66 ? this.state.opacity66 : 1}
              fill={this.state.color66 ? this.state.color66 : 'transparent'} onClick={() => {
          this.setState({ color66: color, opacity66: opacity });
        }} d="M133.6,39.4c1-0.3,2-0.5,2.8-0.9c0.6-0.2,0.9-0.2,1.3,0.3c1.8,1.9,4.1,3.1,6.3,4.4c1.6,0.9,3,2.3,4.4,3.5
	c0.3,0.2,0.6,0.4,0.9,0.6c2.2,1.3,2.5,3,0.5,4.7c-3.6,3.1-7.8,5-12.6,5c-1.2,0-2.5-1-2.8-2.3c-0.3-1-0.5-2.1-0.9-3.1
	c-1-2.4-0.9-4.9-0.5-7.4C133.4,42.6,133.5,40.9,133.6,39.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity67 ? this.state.opacity67 : 1}
              fill={this.state.color67 ? this.state.color67 : 'transparent'} onClick={() => {
          this.setState({ color67: color, opacity67: opacity });
        }} d="M85.7,121.5c0.8-1.2,1.5-2.3,2.2-3.3c0.1-0.2,0.5-0.2,0.7-0.2c2,0.1,4.1,0.1,6,0.4c3.3,0.5,6.7,1.2,10,1.8
	c0.8,0.2,1.6,0.4,2.4,0.8c1,0.5,1.2,1.5,0.9,2.4c-0.8,2.7-2.2,5.1-4.2,7c-1.1,1-2.2,2.1-3.4,2.9c-0.4,0.3-1.3,0.3-1.8,0
	c-1.3-0.6-2.4-1.3-3.6-2.1c-0.2-0.1-0.3-0.4-0.5-0.6c-0.7-0.7-1.3-1.6-2.2-2.2C89.6,126.6,87.6,124,85.7,121.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity68 ? this.state.opacity68 : 1}
              fill={this.state.color68 ? this.state.color68 : 'transparent'} onClick={() => {
          this.setState({ color68: color, opacity68: opacity });
        }} d="M49,48.6c3.9,0,7,1.8,9.3,4.8c1.1,1.4,1.9,3,2.8,4.6c0.6,1,0,1.9-0.8,2.5c-0.5,0.4-1.2,0.7-1.8,0.9
	c-3.7,1.1-7.2,2.6-10.4,4.6c-0.4,0.3-0.9,0.6-1.4,0.7c-0.3,0.1-1,0-1.1-0.3c-0.5-1-1.5-1.3-2.4-1.6c-0.8-0.2-0.9-0.5-0.7-1.4
	c0.8-3.2,1.5-6.4,2.3-9.6c0.3-1.3,0.9-2.6,1.5-3.8C46.8,49,47.9,48.6,49,48.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity69 ? this.state.opacity69 : 1}
              fill={this.state.color69 ? this.state.color69 : 'transparent'} onClick={() => {
          this.setState({ color69: color, opacity69: opacity });
        }} d="M30.8,72.7c0.4,0.7,0.9,1.5,1.4,2.4c-1.2,1.2-2.4,2.4-3.7,3.5c-2.4,2.2-4.7,4.4-7.3,6.3c-1.6,1.2-3.6,2-5.5,2.8
	c-0.5,0.2-1.3,0-1.7-0.3c-3-1.9-4-4.9-4.4-8.2c-0.3-2.7,1.2-4.3,3.8-5.1c3.2-1,6.5-0.5,9.7-0.8C25.6,73.2,28.2,73,30.8,72.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity70 ? this.state.opacity70 : 1}
              fill={this.state.color70 ? this.state.color70 : 'transparent'} onClick={() => {
          this.setState({ color70: color, opacity70: opacity });
        }} d="M124.9,32.4c0.4,1.5,0.8,2.9,1.2,4.5c-1.2,0.9-2.5,2-3.8,3c-2.7,2-5.4,4.1-8.1,6.1c-1.2,0.9-2.7,0.7-3.9-0.2
	c-2.1-1.5-3.2-3.6-3.6-6.1c-0.3-1.6-1.1-3.2-0.6-4.9c0.3-1,0.8-1.8,1.8-2.1c1.2-0.4,2.4-0.5,3.6-0.9c2.7-0.9,5.3-0.1,7.9,0.2
	C121.2,32.1,123.1,32.2,124.9,32.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity71 ? this.state.opacity71 : 1}
              fill={this.state.color71 ? this.state.color71 : 'transparent'} onClick={() => {
          this.setState({ color71: color, opacity71: opacity });
        }} d="M87.4,113.8c3-1.6,5.9-3.1,8.7-4.8c1.8-1.1,3.5-2.4,5.3-3.7c1.3-1,2.8-1.7,4.5-1.3c0.5,0.1,1.1,0.6,1.5,1.1
	c1.7,2.6,2.4,5.4,2.2,8.5c-0.1,1.3-0.2,2.6-0.4,3.9c-0.2,0.9-0.9,1.3-1.9,1.4c-2.5,0.4-4.9-0.6-7.3-0.9c-3.5-0.4-7-0.9-10.5-1.3
	c-0.8-0.1-1.5-0.2-1.5-1.2C87.9,114.8,87.6,114.2,87.4,113.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity72 ? this.state.opacity72 : 1}
              fill={this.state.color72 ? this.state.color72 : 'transparent'} onClick={() => {
          this.setState({ color72: color, opacity72: opacity });
        }} d="M139.3,28.3c2-3.4,4.2-6.9,6.3-10.4c0.9-1.5,3.4-2.4,5-1.7c0.7,0.3,1.5,0.7,2,1.3c2.6,2.7,4.7,5.7,5.2,9.5
	c0.2,1.7-0.2,2.5-1.9,2.9c-3,0.8-6,1.6-9,2.2c-1.7,0.4-3.5,0.4-5.2,0.6c-0.3,0-0.7-0.2-0.8-0.5C140.4,31,139.8,29.7,139.3,28.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity73 ? this.state.opacity73 : 1}
              fill={this.state.color73 ? this.state.color73 : 'transparent'} onClick={() => {
          this.setState({ color73: color, opacity73: opacity });
        }} d="M111.3,165.7c-1.2,2.4-3.3,3.5-5.6,4.1c-2.3,0.6-4.7,1-7,1.4c-1.3,0.2-2.2-0.4-2.7-1.6
	c-0.2-0.5-0.5-1.1-0.8-1.6c-2.3-3.4-2-7-0.3-10.3c1.3-2.5,4.8-4.3,8.5-1.3C106.5,159,109,162.2,111.3,165.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity74 ? this.state.opacity74 : 1}
              fill={this.state.color74 ? this.state.color74 : 'transparent'} onClick={() => {
          this.setState({ color74: color, opacity74: opacity });
        }} d="M138.7,37.6c0.5-0.8,1-1.2,1.2-1.8c0.4-1.6,1.7-1.8,3-1.9c3.7-0.2,7.3-1.1,10.9-2.2c2-0.6,5.1,1.3,5.2,3.5
	c0.2,3.5-0.8,6.8-2.8,9.7c-1.7,2.6-4.4,2.2-6.7,0.4c-1.4-1.1-3-2.1-4.5-3.2C142.9,40.7,140.8,39.1,138.7,37.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity75 ? this.state.opacity75 : 1}
              fill={this.state.color75 ? this.state.color75 : 'transparent'} onClick={() => {
          this.setState({ color75: color, opacity75: opacity });
        }} d="M57.5,193c-0.9,1.1-1.7,2.1-2.6,3.2c-0.7-0.7-1.1-1.1-0.8-2.2c0.3-0.8-0.2-1.9-0.3-2.8c0-0.5,0.1-1.1,0.3-1.6
	c1.1-1.9,2.3-3.7,3.3-5.6c0.2-0.3-0.2-0.9-0.3-1.3c-0.3,0.2-0.8,0.4-1,0.7c-0.8,1.3-1.4,2.6-2.2,3.9c-0.2,0.3-0.5,0.6-0.9,1.1
	c-0.3-0.8-0.5-1.4-0.7-2.1c-0.4-1.3-0.8-2.5-1.1-3.8c-0.2-1.1-0.1-2.2-0.1-3.2c0-0.6,0.3-1.2,0.4-1.7c0-0.2-0.3-0.6-0.5-0.7
	c-0.1-0.1-0.6,0.2-0.7,0.3c-0.6,1.5-1.5,2.9-0.9,4.7c0.9,3.1,1.6,6.3,2.3,9.4c-1-2.1-2.5-3.8-4.4-5.2c-0.5-0.4-0.8-1.1-1.1-1.8
	c-0.5-1.1-0.8-2.3-1.2-3.5c-0.2-0.6-0.4-1.1,0.1-1.8c0.6-1,0.7-2.2,1.3-3.2c1.1-2,2.3-3.9,3.5-5.8c0.2-0.3,0.6-0.6,1.1-0.9
	c0.5,1.5,1,2.8,1.5,4.1c1,2.6,2.5,5,4.5,7c2.3,2.3,3.7,5,3.7,8.4c0,1.4-0.4,2.6-0.9,3.9c-1,2.8-1.8,5.6-2.7,8.4
	c-0.1,0.2-0.1,0.4-0.2,0.7c-0.2-0.4-0.3-0.7-0.4-0.9c-1.2-1.7-1.2-3.2,0.4-4.8C57.7,195.3,58.3,194.3,57.5,193z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity76 ? this.state.opacity76 : 1}
              fill={this.state.color76 ? this.state.color76 : 'transparent'} onClick={() => {
          this.setState({ color76: color, opacity76: opacity });
        }} d="M131.7,41.4c-0.1,2.4-0.3,4.8-0.4,7.3c0,1.2,0.2,2.4,0.1,3.6c0,0.9-0.2,1.8-0.4,2.7c-0.2,1-0.9,1.2-1.8,1.1
	c-4.1-0.5-7.9-1.9-11.5-3.9c-1.3-0.7-1.7-1.8-1.5-3.3c0.3-2.6,2.1-4,4-5.4c2.3-1.7,4.5-3.4,6.9-4.9c0.4-0.3,1.1-0.2,1.6-0.1
	c0.9,0.2,1.7,0.5,2.6,0.9c0.2,0.1,0.5,0.4,0.5,0.6C131.8,40.4,131.8,40.9,131.7,41.4C131.7,41.4,131.7,41.4,131.7,41.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity77 ? this.state.opacity77 : 1}
              fill={this.state.color77 ? this.state.color77 : 'transparent'} onClick={() => {
          this.setState({ color77: color, opacity77: opacity });
        }} d="M41.5,76.5c1.5-0.9,3.1-1.8,4.7-2.7c1.1,0.5,2.4,1.1,3.7,1.8c1.8,0.9,3.6,1.8,5.3,2.9c2.8,1.8,3.4,3.6,1.1,6.2
	c-2.5,2.8-5.6,5-9.4,6.2c-0.8,0.2-1.6,0.3-2.4,0.4c-0.3,0-0.9-0.2-1-0.5c-0.9-1.3-1.9-2.6-1.8-4.4c0.1-2.9,0.1-5.8,0.1-8.7
	C41.7,77.3,41.5,76.9,41.5,76.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity78 ? this.state.opacity78 : 1}
              fill={this.state.color78 ? this.state.color78 : 'transparent'} onClick={() => {
          this.setState({ color78: color, opacity78: opacity });
        }} d="M58.6,78.1c-0.8-0.2-1.6-0.4-2.3-0.7c-3-1.5-5.9-3-8.8-4.5c-0.7-0.4-1-0.8-0.5-1.6c0.3-0.5,0.2-1.2,0.1-1.8
	c-0.1-0.7,0-1.1,0.7-1.6c3.1-1.9,6.2-3.5,9.7-4.6c1.5-0.5,5.3,0.4,6.2,1.6c0.3,0.3,0.4,0.9,0.4,1.3c-0.3,3.2-0.8,6.4-2.1,9.4
	C61.3,76.8,59.8,78,58.6,78.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity79 ? this.state.opacity79 : 1}
              fill={this.state.color79 ? this.state.color79 : 'transparent'} onClick={() => {
          this.setState({ color79: color, opacity79: opacity });
        }} d="M31.2,134.7c-1.1,1.1-2,1.9-2.7,2.8c-1.6,2-4.1,2.6-6.4,3.3c-1.3,0.4-2.9-0.1-4.3-0.3c-0.2,0-0.5-0.3-0.7-0.6
	c-1.8-2.9-2.5-6.1-2.6-9.5c-0.1-3.5,2.5-5.1,5.8-4.1c3.6,1.1,6.2,3.5,9,5.6C30.4,132.7,30.8,134,31.2,134.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity80 ? this.state.opacity80 : 1}
              fill={this.state.color80 ? this.state.color80 : 'transparent'} onClick={() => {
          this.setState({ color80: color, opacity80: opacity });
        }} d="M115.2,186.3c-1.4,0-2.2-0.9-3.1-1.7c-1.6-1.6-2.7-3.6-3.2-5.8c-0.5-2-1.3-4-2-6c-0.3-1-0.2-1.7,1-1.9
	c0.2,0,0.4-0.1,0.6-0.2c2.2-1.7,4.3-0.8,6.2,0.5c1.5,1,3,2.1,4.4,3.3c0.6,0.5,1,1.3,1.4,1.9c0.2,0.2,0.3,0.5,0.6,0.6
	c2.9,1.6,3.2,4,0.5,6c-1.5,1.1-3.1,1.9-4.7,2.8C116.4,186.1,115.8,186.2,115.2,186.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity81 ? this.state.opacity81 : 1}
              fill={this.state.color81 ? this.state.color81 : 'transparent'} onClick={() => {
          this.setState({ color81: color, opacity81: opacity });
        }} d="M105,171.7c1.2,4,2.4,7.8,3.4,11.7c0.2,0.9,0.1,1.9,0.1,2.9c0.1,1.4-0.6,2.1-1.8,2.5c-2.3,0.9-4.6,1.3-7.1,0.8
	c-0.9-0.2-1.4-0.7-1.9-1.5c-1.2-2.2-1.1-4.5-0.8-6.8c0.2-1.4,0.4-2.8,0.7-4.1c0.1-0.6,0.5-1.1,0.9-1.6c0.3-0.4,0.8-0.6,1-1
	C100.6,172.1,102.9,172.3,105,171.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity82 ? this.state.opacity82 : 1}
              fill={this.state.color82 ? this.state.color82 : 'transparent'} onClick={() => {
          this.setState({ color82: color, opacity82: opacity });
        }} d="M160.1,206.6c1.3-0.7,2.4-1.4,3.6-1.9c0.3-0.2,0.9,0,1.3,0.2c1.6,0.5,2.6,0.4,3.5-0.8c-1.1-0.1-2.1-0.3-3.3-0.4
	c1.2-1.8,3.8-2.1,3.6-4.8c-2.7,2-5.3,3.9-7.9,5.7c-0.1-0.1-0.2-0.1-0.4-0.2c0.3-1.5,0.6-3,1-4.7c-2,1.4-2.1,3.6-2.7,5.2
	c-1.9-1.2-3.6-2.4-5.5-3.7c2.7-3.2,6.3-4.5,10.2-4.9c3.7-0.4,7.4-0.3,11.1-0.5c0.3,0,0.5,0,0.9,0.1c-0.6,2-1.2,3.9-1.9,5.8
	c-0.3,0.8-0.8,1.6-1.4,2.2c-2.3,2.5-4.6,5-8.1,5.8c-1.1,0.2-1.9,0.5-2.6-0.8C161.2,208,160.6,207.3,160.1,206.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity83 ? this.state.opacity83 : 1}
              fill={this.state.color83 ? this.state.color83 : 'transparent'} onClick={() => {
          this.setState({ color83: color, opacity83: opacity });
        }} d="M62.9,17.9c-2.1,1.1-4,2.1-6,3.1c-2.9,1.5-4.4,4.2-6.2,6.8c-1.8,2.8-3.9,3-5.8,0.3c-1.1-1.6-1.8-3.5-2.4-5.4
	c-0.4-1.1,0.5-2.6,2-3.2c4-1.9,8.3-3.1,12.6-3.9c1.6-0.3,3,0.4,4.3,1.3C61.8,17.2,62.3,17.5,62.9,17.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity84 ? this.state.opacity84 : 1}
              fill={this.state.color84 ? this.state.color84 : 'transparent'} onClick={() => {
          this.setState({ color84: color, opacity84: opacity });
        }} d="M75,12.9c-0.1,0.9,0,2.2-0.4,3.3c-0.3,0.7-1.4,1.2-2.2,1.6c-2.7,1.2-5.3,0.5-7.9-0.4c-1.3-0.5-2.4-1.5-3.6-2.4
	c-0.2-0.1-0.3-0.7-0.1-1c0.9-2.1,1.8-4.2,2.9-6.2c0.6-1,1.4-2,2.3-2.7c2-1.7,4.6-1.2,6.3,0.9C73.7,8,74.9,10.1,75,12.9z M73.1,16.1
	c-0.6-0.4-1-0.8-1.3-0.8c-0.3,0-0.6,0.5-0.9,0.8c0.3,0.3,0.6,0.8,1,0.8C72.1,17,72.5,16.5,73.1,16.1z M74.7,14.3
	c-0.5-0.3-0.9-0.8-1.2-0.7c-0.3,0.1-0.8,0.6-0.8,0.8c0,0.3,0.5,0.8,0.8,0.8C73.9,15.2,74.2,14.8,74.7,14.3z M73.8,11
	c-0.5-0.3-0.7-0.7-1-0.7c-0.3,0-0.6,0.4-0.9,0.6c0.3,0.2,0.5,0.6,0.8,0.7C73,11.7,73.3,11.3,73.8,11z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity85 ? this.state.opacity85 : 1}
              fill={this.state.color85 ? this.state.color85 : 'transparent'} onClick={() => {
          this.setState({ color85: color, opacity85: opacity });
        }} d="M139.2,74.3c-0.1,2.3-0.5,4.8-1.9,6.9c-0.3,0.5-1,0.8-1.6,1.1c0,0-0.1,0-0.1,0c-2.2,1.3-4.4,0.6-6.5-0.3
	c-0.9-0.4-1.9-0.8-2.8-1.4c-1.4-1-2.1-3.9-1.1-5.3c0.5-0.8,1.3-1.4,2.1-1.9c1.8-1.1,3.5-2.2,5.4-3c1.3-0.5,2.8-0.6,4.2-0.6
	c1.4,0,1.7,1.4,2,2.5C139.1,73,139.1,73.6,139.2,74.3z M133.3,80.1c-0.3,0.5-0.7,0.8-0.7,1c0,0.3,0.4,0.5,0.6,0.8
	c0.2-0.3,0.6-0.6,0.7-0.9C133.9,80.8,133.6,80.5,133.3,80.1z M136.1,80.6c-0.4-0.3-0.7-0.7-0.9-0.7c-0.2,0-0.6,0.4-0.6,0.7
	c0,0.3,0.4,0.6,0.6,0.7C135.4,81.3,135.7,80.9,136.1,80.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity86 ? this.state.opacity86 : 1}
              fill={this.state.color86 ? this.state.color86 : 'transparent'} onClick={() => {
          this.setState({ color86: color, opacity86: opacity });
        }} d="M177.1,65.3c0,0.2,0,0.3,0,0.5c-0.1,1.3-0.1,2.6-1.9,2.9c-0.4,0.1-0.9,0.3-1.3,0.6c-0.9,0.8-1.7,0.6-2.7,0.2
	c-0.8-0.3-1.7-0.4-2.6-0.6c-1.5-0.3-2.7-1-3.3-2.6c-0.2-0.6-0.6-1.1-1-1.6c-0.6-0.7-0.9-1.5-0.2-2.3c1.7-2,3.4-4,5.1-6
	c0.4-0.4,0.9-0.7,1.4-0.9c2.1-0.8,3-0.5,4.1,1.6C176.1,59.8,177.2,62.4,177.1,65.3z M172.3,68c-0.4-0.3-0.6-0.5-0.9-0.8
	c-0.2,0.3-0.6,0.5-0.6,0.8c0,0.2,0.4,0.7,0.6,0.7C171.7,68.8,172,68.4,172.3,68z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity87 ? this.state.opacity87 : 1}
              fill={this.state.color87 ? this.state.color87 : 'transparent'} onClick={() => {
          this.setState({ color87: color, opacity87: opacity });
        }} d="M19.3,159.8c-2.7-0.1-3.1-0.5-3.8-3c-1-3.3-0.9-6.7-0.8-10.1c0.1-2.6,2.1-4.4,4.5-4.5c1,0,1.8,0.4,2.2,1.4
	c0.9,2.3,1.7,4.5,1.7,7c0,2.3,0.4,4.7,0.7,7c0.2,1.4,0,1.7-1.4,1.9C21.3,159.6,20.3,159.7,19.3,159.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity88 ? this.state.opacity88 : 1}
              fill={this.state.color88 ? this.state.color88 : 'transparent'} onClick={() => {
          this.setState({ color88: color, opacity88: opacity });
        }} d="M9.9,156.6c-1.1,0-2.2,0-3.3,0c-0.3,0-0.7-0.2-0.9-0.5c-0.9-1.2-1.5-2.4-1.3-4.1c0.6-3.4,1.7-6.6,3.5-9.6
	c0.9-1.4,1.9-2.8,3.9-2.8c0.5,0,0.9-0.3,1.4-0.4c1-0.3,1.7,0.1,2.3,0.9c0.5,0.8-0.1,1.3-0.5,1.9c-1,1.6-2.1,3.3-2,5.4
	c0.1,2.4,0.1,4.9,0.2,7.3c0.1,1.3-1,1.5-1.8,1.9c-0.5,0.2-1,0.1-1.5,0.2C9.9,156.7,9.9,156.6,9.9,156.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity89 ? this.state.opacity89 : 1}
              fill={this.state.color89 ? this.state.color89 : 'transparent'} onClick={() => {
          this.setState({ color89: color, opacity89: opacity });
        }} d="M111.9,168.4c0.7-1.9,2.4-2,3.8-2.4c1.8-0.4,3.6-0.4,5.4-0.6c0.9-0.1,1.8,0,2.3,1c0.1,0.3,0.9,0.4,1.3,0.3
	c1.7-0.2,3,0.8,4.2,1.7c0.6,0.4,0.9,1.5,0.9,2.3c-0.1,2.2-1.2,4.1-2.4,5.9c-0.1,0.2-0.6,0.3-0.9,0.2c-2.3-0.4-4.2-1.5-5.9-3.1
	C118,171.5,115.5,169.1,111.9,168.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity90 ? this.state.opacity90 : 1}
              fill={this.state.color90 ? this.state.color90 : 'transparent'} onClick={() => {
          this.setState({ color90: color, opacity90: opacity });
        }} d="M28.3,140c0.2-0.2,0.4-0.4,0.6-0.6c1.5-2.3,3.5-1.6,5.5-0.9c1.1,0.4,2.3,0.6,3.2,1.2c1.5,0.9,2.9,1.9,4.2,3.1
	c3.3,3.1,2.9,3.3,0.7,6.7c-0.6,0.9-1.6,1.6-2.5,2.4c-0.2,0.1-0.8,0-1-0.2c-1.3-1.6-2.9-2.7-4.4-3.9c-1.9-1.6-3.4-3.9-5-5.8
	C29.1,141.3,28.8,140.7,28.3,140z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity91 ? this.state.opacity91 : 1}
              fill={this.state.color91 ? this.state.color91 : 'transparent'} onClick={() => {
          this.setState({ color91: color, opacity91: opacity });
        }} d="M174.2,84c-0.3-3.2-0.7-6.3-1-9.5c-0.1-0.9-0.1-1.9,0.1-2.8c0.1-0.5,0.8-0.9,1.3-1.3c2.3-1.6,4.5-0.7,5.5,1.5
	c0.9,1.9,1.3,4,1.7,6c0.3,1.2,0.3,2.4,0.4,3.7c0,1.3-0.5,2.4-1.8,2.8c-1.8,0.5-3.6,1-5.4,1.4c-0.7,0.2-1.1-0.2-1.1-0.9
	c0-0.3,0.1-0.6,0.1-1C174.1,84,174.1,84,174.2,84z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity92 ? this.state.opacity92 : 1}
              fill={this.state.color92 ? this.state.color92 : 'transparent'} onClick={() => {
          this.setState({ color92: color, opacity92: opacity });
        }} d="M165.7,69.4c-0.9,2.1-1.7,4.2-2.7,6.3c-1.2,2.7-2.6,5.3-3.9,7.9c-0.4,0.9-1.1,1.1-2,0.8
	c-1.4-0.5-2.9-0.8-4.2-1.5c-1.3-0.7-1.4-1.3-0.8-2.7c1.5-3.6,3.6-6.7,6.7-9.2c1.1-0.9,2.4-1.5,3.6-2.3
	C163.6,68.1,164.6,68.4,165.7,69.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity93 ? this.state.opacity93 : 1}
              fill={this.state.color93 ? this.state.color93 : 'transparent'} onClick={() => {
          this.setState({ color93: color, opacity93: opacity });
        }} d="M98.7,172.9c-0.7,0.9-1.4,1.4-1.8,2.1c-0.4,0.9-0.6,1.9-0.9,2.8c-1.1,2.9-0.7,5.9-1.1,8.9
	c-0.2,1.1-0.6,1.6-1.7,1.5c-1.9-0.1-3.8,0.2-5.5-0.8c-0.5-0.3-1.2-1-1.2-1.5c-0.1-1.8-0.4-3.8,0.3-5.4c0.6-1.7,2.1-3.2,3.5-4.5
	c1.2-1.2,2.8-2,4.1-3C95.7,172,97,172.9,98.7,172.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity94 ? this.state.opacity94 : 1}
              fill={this.state.color94 ? this.state.color94 : 'transparent'} onClick={() => {
          this.setState({ color94: color, opacity94: opacity });
        }} d="M166.2,86.4c-0.7-0.1-1.4-0.1-2.1-0.2c-1-0.2-1.4-0.7-1.3-1.9c0.5-3.7,1.7-7.3,2.9-10.8
	c0.2-0.6,0.4-1.1,0.6-1.7c0.7-1.6,1.3-2.1,3-1.5c2.3,0.8,2.6,0.6,2.4,3.3c-0.3,3-0.5,6-1,8.9c-0.2,1.2-0.8,2.4-1.6,3.3
	c-0.6,0.6-1.8,0.6-2.7,0.8C166.2,86.5,166.2,86.4,166.2,86.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity95 ? this.state.opacity95 : 1}
              fill={this.state.color95 ? this.state.color95 : 'transparent'} onClick={() => {
          this.setState({ color95: color, opacity95: opacity });
        }} d="M32.6,133.2c5.4-2.9,11.9-2.2,16.3,1.8c2,1.8,1.7,4.6-0.6,6c-0.3,0.2-0.9,0.2-1.3,0c-1.6-0.7-3.2-1.6-4.8-2.3
	c-2.3-1-4.6-2.2-7.3-2.2c-1.1,0-2.2,0-2-1.7C33.1,134.3,32.8,133.7,32.6,133.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity96 ? this.state.opacity96 : 1}
              fill={this.state.color96 ? this.state.color96 : 'transparent'} onClick={() => {
          this.setState({ color96: color, opacity96: opacity });
        }} d="M30.6,157.2c-1,0-1.5-0.4-1.9-1.2c-0.6-1.2-1.4-2.3-2.2-3.4c-1.3-2-1.9-4.2-2.4-6.5c-0.2-1-0.5-2.1-0.9-3.1
	c-0.3-0.7-0.2-1.1,0.5-1.3c0.4-0.1,0.9-0.3,1.2-0.5c1-0.6,1.8-0.3,2.4,0.5c1.8,2.5,3.6,4.9,5.2,7.5c0.9,1.5,1.3,3.3,2,5
	c0.2,0.7,0.1,1.3-0.7,1.5C32.8,156.3,31.7,156.8,30.6,157.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity97 ? this.state.opacity97 : 1}
              fill={this.state.color97 ? this.state.color97 : 'transparent'} onClick={() => {
          this.setState({ color97: color, opacity97: opacity });
        }} d="M75,28.9c-0.1,0.8-0.2,1.3-0.2,1.8c-0.1,2.6-0.2,2.7-2.8,3.2c-1.3,0.3-2.6,0.6-3.9,0.8
	c-1.4,0.2-1.8-0.2-1.7-1.6c0.1-0.5,0.1-1.1,0-1.7c-0.4-2.1-0.2-4,0.6-6c0.6-1.5,1.1-3,1.7-4.5c0.5-1.2,2.4-1.5,3.1-0.5
	c0.7,1.1,1.4,2.2,1.8,3.4C74.3,25.6,74.6,27.4,75,28.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity98 ? this.state.opacity98 : 1}
              fill={this.state.color98 ? this.state.color98 : 'transparent'} onClick={() => {
          this.setState({ color98: color, opacity98: opacity });
        }} d="M38.6,75.2c-1.2-0.3-2.8-0.7-4.3-1.3c-1.7-0.7-2.2-3-1.2-4.8c1.6-2.9,4.1-4.1,7.4-3.5c0.4,0.1,0.7,0.2,1.1,0.2
	c1.7-0.1,2.5,1.1,3.2,2.3c1.1,1.9,0.5,3.6-0.9,5C42.5,74.4,40.8,75,38.6,75.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity99 ? this.state.opacity99 : 1}
              fill={this.state.color99 ? this.state.color99 : 'transparent'} onClick={() => {
          this.setState({ color99: color, opacity99: opacity });
        }} d="M134,84.7c3.2-1.7,5.7-1.1,8.3,1.8c2.4,2.8,1.9,5.8,1.4,8.9c-0.1,0.5-0.6,1-1,1.2c-1.3,0.5-2.7,0.8-4.1,1.1
	c-0.7,0.2-1-0.1-1-0.8c0-0.2-0.1-0.3,0-0.5c0.7-3-1.2-5.3-2.2-7.9C134.8,87.3,134.4,86,134,84.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity100 ? this.state.opacity100 : 1}
              fill={this.state.color100 ? this.state.color100 : 'transparent'} onClick={() => {
          this.setState({ color100: color, opacity100: opacity });
        }} d="M163.2,66.7c-2.6,1.8-5.1,3.6-7.5,5.3c-0.6,0.3-1.2,0.4-1.7,0.8c-1,0.7-1.9,1.4-2.9,2.1
	c-0.8,0.6-1.6,1.2-2.6,0.6c-1.1-0.7-1.7-2.1-1.2-3.1c1.4-2.9,3.1-5.7,6.4-6.9c1.2-0.5,2.4-0.9,3.7-1.3c0.5-0.1,1-0.1,1.5,0
	c1.1,0.4,2.1,0.8,3.1,1.3C162.5,65.7,162.8,66.3,163.2,66.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity101 ? this.state.opacity101 : 1}
              fill={this.state.color101 ? this.state.color101 : 'transparent'} onClick={() => {
          this.setState({ color101: color, opacity101: opacity });
        }} d="M67.1,20c-1.2,3.5-2.3,7.1-3.7,10.5c-1.3,3.3-4.1,3.4-7,2.4c-0.4-0.2-0.8-0.5-1.2-0.7c-1.3-0.8-1.3-1.8-0.4-3.1
	c2.3-3.3,5.7-5.3,8.5-8c0.3-0.3,0.5-0.5,0.7-0.8C65.2,18.9,65.2,18.9,67.1,20z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity102 ? this.state.opacity102 : 1}
              fill={this.state.color102 ? this.state.color102 : 'transparent'} onClick={() => {
          this.setState({ color102: color, opacity102: opacity });
        }} d="M126.8,82.7c-0.4,0.7-0.8,1.3-1.3,2c-2.2,2.8-4.7,5.4-7.4,7.8c-1.3,1.1-1.5,1.1-2.8-0.1c-1-0.9-2-1.8-2.7-2.9
	c-1.2-1.8-0.9-2.7,0.9-3.9c3.1-2,6.5-3,10.2-3.2c1-0.1,2,0,3.1,0C126.8,82.5,126.8,82.6,126.8,82.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity103 ? this.state.opacity103 : 1}
              fill={this.state.color103 ? this.state.color103 : 'transparent'} onClick={() => {
          this.setState({ color103: color, opacity103: opacity });
        }} d="M134.6,28.1c1.7,0,3.8,1.8,4.3,3.7c0.4,1.6-0.6,3.9-2.2,4.7c-2.4,1.1-4.9,1.6-7.4,0.2c-2.4-1.4-2.7-3.9-1.8-6
	c0.1-0.3,0.5-0.5,0.8-0.7C130.1,28.6,132.3,28.1,134.6,28.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity104 ? this.state.opacity104 : 1}
              fill={this.state.color104 ? this.state.color104 : 'transparent'} onClick={() => {
          this.setState({ color104: color, opacity104: opacity });
        }} d="M129.1,83.6c3.6,0.5,4.7,3.7,4.2,6.9c-0.3,2.4-1.6,4.6-2.6,6.8c-0.3,0.6-1.3,1.2-1.9,1.2
	c-1.8,0-3.5-0.4-5.7-0.6c1.3-2.6,2.5-4.8,3.5-7.1C127.5,88.5,128.2,86.1,129.1,83.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity105 ? this.state.opacity105 : 1}
              fill={this.state.color105 ? this.state.color105 : 'transparent'} onClick={() => {
          this.setState({ color105: color, opacity105: opacity });
        }} d="M123.9,80.8c-1.9,0-3.8,0.7-5.8,0.1c-2.1-0.7-4.2-0.6-6.3-0.2c-2.1,0.5-2.9-0.1-2.7-2.3c0.2-2.5,0.6-3,2.9-3.7
	c2.9-0.9,5.7-0.6,8.5,0.2c0.3,0.1,0.6,0.2,0.9,0.3c1.2,0.1,1.7,0.6,1.7,1.9C123.1,78.3,123.6,79.5,123.9,80.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity106 ? this.state.opacity106 : 1}
              fill={this.state.color106 ? this.state.color106 : 'transparent'} onClick={() => {
          this.setState({ color106: color, opacity106: opacity });
        }} d="M181.4,66.8c1.7,0.2,3.5,0.3,5.2,0.8c0.7,0.2,1.5,1,1.7,1.7c0.9,2.3,1.7,4.7,2.4,7.1c0.6,2.1-1.1,4.3-3.2,4.4
	c-0.3,0-0.6-0.5-0.8-0.8c-0.8-1.7-1.4-3.4-2.2-5.2c-0.1-0.3-0.3-0.6-0.5-0.8c-1.8-1.8-2.8-4.3-5.1-5.5c-0.2-0.1-0.4-0.6-0.3-0.8
	c0.2-0.3,0.5-0.7,0.8-0.7c0.7-0.1,1.4,0,2,0C181.4,66.9,181.4,66.8,181.4,66.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity107 ? this.state.opacity107 : 1}
              fill={this.state.color107 ? this.state.color107 : 'transparent'} onClick={() => {
          this.setState({ color107: color, opacity107: opacity });
        }} d="M73.7,19.6c1.8-1.6,2.4-1.7,4.3-0.5c1.3,0.8,2.6,1.7,3.8,2.6c1.8,1.4,2.5,3.5,3,5.6c0.3,1.1,0.8,2.1,0,3.2
	c-0.9,1.2-3.5,2.5-4.9,2.1c-0.3-0.1-0.6-0.6-0.7-1c-0.2-1.3-0.4-2.6-0.5-3.9c-0.1-1.6-0.5-3-1.8-4.1c-0.6-0.5-1.1-1.1-1.6-1.8
	C74.7,21.1,74.2,20.3,73.7,19.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity108 ? this.state.opacity108 : 1}
              fill={this.state.color108 ? this.state.color108 : 'transparent'} onClick={() => {
          this.setState({ color108: color, opacity108: opacity });
        }} d="M130.5,164.8c-2.6,0.7-5.1-0.1-7.6-0.5c-2.9-0.5-6-0.4-8.9,0.6c-0.4,0.2-1,0.3-1.4,0.1c-0.7-0.3-1.1-1-0.4-1.6
	c0.8-0.7,1.7-1.4,2.6-2c1.3-0.7,2.7-1.5,4.1-1.9c1.3-0.4,2.8-0.5,4.1-0.3c2.3,0.3,4.5,0.9,6.7,1.3c1,0.2,1.2,0.7,1.1,1.6
	C130.8,163.1,130.7,164,130.5,164.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity109 ? this.state.opacity109 : 1}
              fill={this.state.color109 ? this.state.color109 : 'transparent'} onClick={() => {
          this.setState({ color109: color, opacity109: opacity });
        }} d="M80.6,121.2c-2.7,0.1-5.2-1.6-5.7-3.8c-0.3-1.7,0.4-2.7,1.8-3.6c1-0.6,1.8-1.4,2.9-1.5c0.6-0.1,1.3,0.1,1.9,0.2
	c0.2,0,0.5,0.1,0.7,0.1c0.5,0,1.2-0.2,1.5,0c0.8,0.7,1.5,1.6,2,2.5c0.8,1.7,0.4,3.4-0.9,4.4C83.4,120.8,82.7,121.1,80.6,121.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity110 ? this.state.opacity110 : 1}
              fill={this.state.color110 ? this.state.color110 : 'transparent'} onClick={() => {
          this.setState({ color110: color, opacity110: opacity });
        }} d="M71.7,200.6c-2-6.2-2.5-12.6-3-19c-0.7-7.8-0.4-15.6-0.7-23.4c-0.2-4.8,0.4-9.6,1.9-14.3c0.2,0,0.5,0,0.8,0
	c0.3,0,0.5,0,0.9,0c-0.5,2.4-1.1,4.6-1.5,6.9c-0.8,4.6-1.1,9.3-0.8,14c0.3,3.6,0.3,7.2,0.4,10.9c0.2,7.3,0.6,14.6,2.5,21.7
	c0.2,0.6,0.2,1.3,0.4,1.9C72.9,200.1,72.7,200.6,71.7,200.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity111 ? this.state.opacity111 : 1}
              fill={this.state.color111 ? this.state.color111 : 'transparent'} onClick={() => {
          this.setState({ color111: color, opacity111: opacity });
        }} d="M113.5,55.9c-2.1,3.6-4.3,7.1-6.2,10.8C104,73.3,101.2,80,98.9,87c-0.4,1.3-0.9,2.6-1.4,3.8
	c-0.1,0.3-0.6,0.4-0.9,0.6c-0.2-0.3-0.6-0.7-0.5-1c0.6-2.2,1.3-4.4,2.1-6.6c0.9-2.4,1.8-4.8,2.8-7.1c0.6-1.4,1.2-2.8,1.9-4.1
	c1.1-2.5,2.1-5.1,3.3-7.5c0.8-1.5,1.9-2.9,2.8-4.3c1.2-2,2.4-3.9,3.7-6C113,55.3,113.3,55.6,113.5,55.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity112 ? this.state.opacity112 : 1}
              fill={this.state.color112 ? this.state.color112 : 'transparent'} onClick={() => {
          this.setState({ color112: color, opacity112: opacity });
        }} d="M43.9,14.6c-0.1-1.6,0.4-2.6,1.7-3.4c2.6-1.5,5.4-1.9,8.2-2.1c0.7,0,1.5,0.5,2.1,0.9c1,0.7,2,1.5,2.8,2.3
	c0.2,0.2,0.3,1,0.1,1.3c-0.2,0.3-0.7,0.5-1.1,0.6c-0.9,0.1-1.7,0.2-2.6,0.2c-0.3,0-0.8,0.1-1,0c-2.6-1.3-5.3-0.7-8-0.3
	C45.5,14.3,44.7,14.4,43.9,14.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity113 ? this.state.opacity113 : 1}
              fill={this.state.color113 ? this.state.color113 : 'transparent'} onClick={() => {
          this.setState({ color113: color, opacity113: opacity });
        }} d="M42.6,141c2.2,0.9,3.7,1.9,4.7,4.1c2,4.5,4.4,8.8,6.8,13.2c2.4,4.2,4.9,8.3,7.4,12.4c1.5,2.4,3.2,4.7,4.8,7.1
	c0.4,0.6,0.4,1.5,0.6,2.3c-0.1,0-0.2,0.1-0.3,0.1c-1.5-2.3-3.1-4.5-4.6-6.8c-2-3-3.9-6-5.7-9.1c-1.9-3.2-3.5-6.4-5.3-9.6
	c-1.5-2.7-3.2-5.3-4.4-8.1c-0.9-1.9-1.8-3.6-3.5-4.9C42.8,141.5,42.8,141.3,42.6,141z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity114 ? this.state.opacity114 : 1}
              fill={this.state.color114 ? this.state.color114 : 'transparent'} onClick={() => {
          this.setState({ color114: color, opacity114: opacity });
        }} d="M91.7,168.8c0.1,0,0.5-0.1,0.8-0.1c1,0,1.4,0.6,1.7,1.4c0.3,0.7,0.1,1.1-0.4,1.5c-1.4,1-3,1.8-4.3,2.9
	c-1.7,1.4-3.3,3-4.2,5.1c-0.3,0.6-0.6,1-1.2,0.8c-0.4-0.2-1.1-0.8-1-1.1c0.1-1.4,0.1-2.9,0.8-4c1.2-1.9,2.8-3.6,4.4-5.3
	C89.2,169.1,90.3,168.8,91.7,168.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity115 ? this.state.opacity115 : 1}
              fill={this.state.color115 ? this.state.color115 : 'transparent'} onClick={() => {
          this.setState({ color115: color, opacity115: opacity });
        }} d="M89.2,19c-2-0.2-3.6,0.3-5.2-0.7c-1.4-0.9-3-0.9-4.6-0.8c-0.3,0-0.7,0.1-0.9,0c-0.6-0.3-1.7,0-1.6-1.2
	c0.1-1.8,0.5-2.3,1.9-2.4c1.5-0.1,3-0.4,4.5-0.3c1.4,0.1,2.7,0.5,4.1,0.8c0.2,0.1,0.5,0.3,0.6,0.5C88.3,16.2,88.7,17.4,89.2,19z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity116 ? this.state.opacity116 : 1}
              fill={this.state.color116 ? this.state.color116 : 'transparent'} onClick={() => {
          this.setState({ color116: color, opacity116: opacity });
        }} d="M29.3,129.2c1-2.4,3-3.1,5.3-3.2c2.7-0.1,5.1,0.9,7.5,2.1c0.7,0.4,1.2,1.2,1.7,1.8c-0.1,0.1-0.1,0.2-0.2,0.4
	c-0.5-0.1-1-0.1-1.4-0.3c-2.6-0.8-5.2-0.4-7.7,0.6c-0.7,0.3-1.4,0.6-2.1,0.9c-0.2,0.1-0.6,0-0.7-0.1C30.9,130.7,30.1,130,29.3,129.2
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity117 ? this.state.opacity117 : 1}
              fill={this.state.color117 ? this.state.color117 : 'transparent'} onClick={() => {
          this.setState({ color117: color, opacity117: opacity });
        }} d="M80.6,146.3c0,0.3,0.1,0.6,0.1,0.8c-0.3,3.8-0.6,7.7-0.9,11.5c-0.1,1.4-0.2,2.7-0.3,4.1
	c-0.4,4.3-0.7,8.6-1.9,12.7c-0.9,3.1-1.6,6.2-2.6,9.2c-0.6,1.9-1.7,3.7-2.5,5.6c-0.8-1.4-0.7-2.3,0-3.4c0.8-1.3,1.5-2.7,1.8-4.1
	c1.2-4.3,2.3-8.5,3.2-12.9c0.8-3.9,1.3-7.9,1.7-11.9c0.4-3.7,0.5-7.5,0.8-11.2c0-0.1,0.1-0.2,0.1-0.3
	C80.4,146.3,80.5,146.3,80.6,146.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity118 ? this.state.opacity118 : 1}
              fill={this.state.color118 ? this.state.color118 : 'transparent'} onClick={() => {
          this.setState({ color118: color, opacity118: opacity });
        }} d="M162.3,90.9c0.9,0.9,0.4,1.7-0.2,2.5c-2.5,3.3-5.1,6.6-7.5,10.1c-1.8,2.6-3.3,5.4-5.1,8.1
	c-0.4,0.6-1.2,1-2.1,1.6C148.2,109.7,159.1,93.5,162.3,90.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity119 ? this.state.opacity119 : 1}
              fill={this.state.color119 ? this.state.color119 : 'transparent'} onClick={() => {
          this.setState({ color119: color, opacity119: opacity });
        }} d="M191,72.5c-0.4-1.3-0.9-2.5-1.2-3.7c-0.4-1.3-1.1-2.2-2.5-2.3c-2.3-0.3-4.6-0.6-6.8-0.9c-0.3,0-0.7,0.2-1,0.2
	c-0.3-0.1-0.6-0.3-0.7-0.6c-0.1-0.1,0.3-0.6,0.5-0.6c3.7-0.8,7.4-1.6,10.7,1.2c1.1,0.9,2.3,1.6,3.4,2.6c1.3,1.2,1.1,2.3-0.3,3.2
	C192.3,71.9,191.7,72.2,191,72.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity120 ? this.state.opacity120 : 1}
              fill={this.state.color120 ? this.state.color120 : 'transparent'} onClick={() => {
          this.setState({ color120: color, opacity120: opacity });
        }} d="M12.2,191.8c2.8,0.5,5.4,1.7,8.5,1.4c0.6,0.9,1.3,2,2.1,3.2c-2.6,1-5,2.3-7.7,0.2
	C13.1,195,11.7,193.4,12.2,191.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity121 ? this.state.opacity121 : 1}
              fill={this.state.color121 ? this.state.color121 : 'transparent'} onClick={() => {
          this.setState({ color121: color, opacity121: opacity });
        }} d="M38.5,125.2c-0.3-0.2-0.7-0.4-0.7-0.6c-1.3-4.2-2.7-8.3-3.6-12.6c-0.7-3-0.6-6.2-1-9.4
	c-0.1-1.2-0.3-2.4-0.5-3.7c1.1,0.1,1.5,0.6,1.6,1.7c0.1,2,0.3,4,0.7,5.9c0.6,3.6,1.2,7.2,1.9,10.8c0.5,2.6,1.2,5.1,1.9,7.6
	C38.6,125,38.5,125.1,38.5,125.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity122 ? this.state.opacity122 : 1}
              fill={this.state.color122 ? this.state.color122 : 'transparent'} onClick={() => {
          this.setState({ color122: color, opacity122: opacity });
        }} d="M66.6,183c0.8,0.9,0.8,1.8,0.2,2.9c-1.8,3.5-3.7,6.9-5.2,10.5c-1.4,3.4-2.8,6.9-3.1,10.6c0,0.4-0.4,0.8-0.8,1.3
	C56.8,205.3,63.6,186.3,66.6,183z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity123 ? this.state.opacity123 : 1}
              fill={this.state.color123 ? this.state.color123 : 'transparent'} onClick={() => {
          this.setState({ color123: color, opacity123: opacity });
        }} d="M121.3,91.9c0.3,0.4,0.5,0.8,0.8,1.3c-4,5-7.9,10-10.8,16c-0.4-0.9-0.8-1.6-0.2-2.4c2-3.1,4.1-6.2,5.9-9.4
	C118,95.2,119.5,93.5,121.3,91.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity124 ? this.state.opacity124 : 1}
              fill={this.state.color124 ? this.state.color124 : 'transparent'} onClick={() => {
          this.setState({ color124: color, opacity124: opacity });
        }} d="M128.2,158.8c-0.2-0.4-0.5-0.8-0.5-1.1c0.8-5.3,2.9-10,5.8-14.5c0.1-0.1,0.2-0.2,0.3-0.3
	c0.3,0.2,0.6,0.3,0.8,0.4c-2,5.1-4,10.2-6.1,15.3C128.4,158.7,128.3,158.7,128.2,158.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity125 ? this.state.opacity125 : 1}
              fill={this.state.color125 ? this.state.color125 : 'transparent'} onClick={() => {
          this.setState({ color125: color, opacity125: opacity });
        }} d="M44.6,182.9c-1.4-0.6-1.9-1.7-2.4-3.1c-1.1-3-2.4-6-3.6-9c-1.2-2.9-2.5-5.7-3.7-8.6c-0.1-0.3,0.1-0.7,0.4-1
	C38.9,168.3,42,175.5,44.6,182.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity126 ? this.state.opacity126 : 1}
              fill={this.state.color126 ? this.state.color126 : 'transparent'} onClick={() => {
          this.setState({ color126: color, opacity126: opacity });
        }} d="M93.3,156.5c0.6-2.6,1-5.2,1.7-7.7c1.2-3.9,2.6-7.7,3.8-11.6c0.4-1.2,1-2.1,2.6-2.2c-3.6,6.9-5.6,14.2-7.5,21.7
	C93.7,156.6,93.5,156.6,93.3,156.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity127 ? this.state.opacity127 : 1}
              fill={this.state.color127 ? this.state.color127 : 'transparent'} onClick={() => {
          this.setState({ color127: color, opacity127: opacity });
        }} d="M147.8,67.9c0.3-0.9,0.4-2,0.9-2.7c1.9-2.6,4.3-4.3,7.7-3.9c1.4,0.1,2.7,0.4,4.1,0.7c0.3,0.1,0.5,0.6,0.8,0.9
	c-0.4,0-0.8,0.1-1.1,0c-2-0.6-4.1-0.8-5.8,0.3c-2,1.2-3.7,2.8-5.5,4.3c-0.2,0.2-0.3,0.5-0.5,0.8C148.2,68.1,148,68,147.8,67.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity128 ? this.state.opacity128 : 1}
              fill={this.state.color128 ? this.state.color128 : 'transparent'} onClick={() => {
          this.setState({ color128: color, opacity128: opacity });
        }} d="M145.8,90.6c-0.2-4.3-3.2-6.6-6.4-8.7c0.3-1.9,0.9-2,2.3-1.2c2.9,1.8,4.7,4.6,5.5,7.9
	C147.5,89.6,147.2,90.4,145.8,90.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity129 ? this.state.opacity129 : 1}
              fill={this.state.color129 ? this.state.color129 : 'transparent'} onClick={() => {
          this.setState({ color129: color, opacity129: opacity });
        }} d="M140.4,180.2c-1.2,3-3.4,4.9-5.7,6.9c-1.5,1.3-3.3,2.5-4.4,4.1c-1,1.5-2.5,1.6-3.6,2.6
	c-0.1-0.1-0.2-0.2-0.3-0.3c1.1-1.2,2.1-2.4,3.3-3.5c1.2-1.2,2.5-2.2,3.8-3.3c1.2-1.1,2.4-2.2,3.6-3.3
	C138.2,182.3,139.1,180.9,140.4,180.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity130 ? this.state.opacity130 : 1}
              fill={this.state.color130 ? this.state.color130 : 'transparent'} onClick={() => {
          this.setState({ color130: color, opacity130: opacity });
        }} d="M14.1,137.5c-2.1,0.6-4.6,0.3-6,2.4c-1.2,1.8-2.4,3.7-3.5,5.5c-1.1-2.1,0.5-3.7,1.3-5.6c0.9-2,2.6-2.4,4.6-2.6
	c1.2-0.1,2.3-0.2,3.5-0.3C14.1,137.1,14.1,137.3,14.1,137.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity131 ? this.state.opacity131 : 1}
              fill={this.state.color131 ? this.state.color131 : 'transparent'} onClick={() => {
          this.setState({ color131: color, opacity131: opacity });
        }} d="M84,76.7c1.5-0.1,2.9-0.2,4.3-0.3c0.7,1.1,0.7,2.6-0.1,3.4c-0.7,0.7-2.2,0.6-3.6-0.2
	C83.7,79.2,83.5,78.3,84,76.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity132 ? this.state.opacity132 : 1}
              fill={this.state.color132 ? this.state.color132 : 'transparent'} onClick={() => {
          this.setState({ color132: color, opacity132: opacity });
        }} d="M82.7,19.6c0.7,0.3,1.5,0.4,2.1,0.8c1.2,0.8,2.3,1.7,3.4,2.6c0.6,0.5,0.8,1.2,0.5,2c-0.3,0.8-1,1-1.7,0.9
	c-0.3,0-0.8-0.3-0.8-0.6c-0.7-2.2-1.8-4-3.6-5.4C82.6,19.8,82.7,19.7,82.7,19.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity133 ? this.state.opacity133 : 1}
              fill={this.state.color133 ? this.state.color133 : 'transparent'} onClick={() => {
          this.setState({ color133: color, opacity133: opacity });
        }} d="M108.5,160c-0.1-0.4-0.3-0.9-0.3-1.3c0.6-3.1,1.7-6,3.2-8.8c0.5-1,1.1-1.9,1.7-2.9c0.2-0.3,0.4-0.6,0.7-0.9
	c0.1,0,0.2,0.1,0.4,0.1c-0.1,0.3-0.1,0.7-0.2,0.9c-1.3,2.9-2.7,5.7-3.9,8.6c-0.5,1.3-0.7,2.8-1.1,4.1C108.8,160,108.6,160,108.5,160
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity134 ? this.state.opacity134 : 1}
              fill={this.state.color134 ? this.state.color134 : 'transparent'} onClick={() => {
          this.setState({ color134: color, opacity134: opacity });
        }} d="M111.3,161.9c1.6-3.6,8.4-6.8,11.5-4C118.1,157.5,114.8,160.1,111.3,161.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity135 ? this.state.opacity135 : 1}
              fill={this.state.color135 ? this.state.color135 : 'transparent'} onClick={() => {
          this.setState({ color135: color, opacity135: opacity });
        }} d="M126.2,72.2c-0.9,0.7-1.6,1.3-2.4,1.8c-0.2,0.1-0.5,0.2-0.8,0.1c-1.3-0.3-2.6-0.7-4-1.1
	C120.2,71.4,124,71,126.2,72.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity136 ? this.state.opacity136 : 1}
              fill={this.state.color136 ? this.state.color136 : 'transparent'} onClick={() => {
          this.setState({ color136: color, opacity136: opacity });
        }} d="M57.7,37.4c0.4,0,0.7,0.1,1.1,0.1c-0.8,3.8-1.6,7.5-2.4,11.2c-0.1,0-0.2,0-0.4,0c0-0.4-0.1-0.7-0.1-1.1
	C56.5,44.2,57,40.9,57.7,37.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity137 ? this.state.opacity137 : 1}
              fill={this.state.color137 ? this.state.color137 : 'transparent'} onClick={() => {
          this.setState({ color137: color, opacity137: opacity });
        }} d="M76.3,191.3c1,2.1,2,4.1,2.9,6.2c0.2,0.3,0.2,1.1,0,1.2c-0.7,0.4-0.9-0.2-1.1-0.7c-0.9-2-1.8-4-2.7-6
	C75.6,191.8,75.9,191.6,76.3,191.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity138 ? this.state.opacity138 : 1}
              fill={this.state.color138 ? this.state.color138 : 'transparent'} onClick={() => {
          this.setState({ color138: color, opacity138: opacity });
        }}
              d="M120.1,191.2c0.3-2.3,1.4-3.2,3.2-2.9c-0.4,1-0.7,2-1.1,2.9C121.4,191.2,120.8,191.2,120.1,191.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity139 ? this.state.opacity139 : 1}
              fill={this.state.color139 ? this.state.color139 : 'transparent'} onClick={() => {
          this.setState({ color139: color, opacity139: opacity });
        }} d="M87.9,90.7c-0.3,0.1-0.7,0.1-1.2,0.2c1.1-1.7,0.5-3.5,0.2-5.2c-0.1-0.7-0.4-1.4-0.5-2.2c-0.1-0.5,0.1-1,0.2-1.5
	c0.2,0,0.4,0,0.5,0C87.3,85,87.6,87.8,87.9,90.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity140 ? this.state.opacity140 : 1}
              fill={this.state.color140 ? this.state.color140 : 'transparent'} onClick={() => {
          this.setState({ color140: color, opacity140: opacity });
        }} d="M67.9,201.7c0.3-1.5,0.6-3,0.8-4.4c0.1,0,0.2,0,0.3,0c0.2,0.8,0.4,1.7,0.6,2.5C68.7,200,69.8,201.9,67.9,201.7z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity141 ? this.state.opacity141 : 1}
              fill={this.state.color141 ? this.state.color141 : 'transparent'} onClick={() => {
          this.setState({ color141: color, opacity141: opacity });
        }} d="M85,155.6c-0.7,2.7-1.9,5.2-3.4,7.7c-0.8-1.5,0.5-2.3,0.9-3.3c0.6-1.4,1.4-2.7,2.2-4
	C84.7,155.8,84.9,155.7,85,155.6L85,155.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity142 ? this.state.opacity142 : 1}
              fill={this.state.color142 ? this.state.color142 : 'transparent'} onClick={() => {
          this.setState({ color142: color, opacity142: opacity });
        }}
              d="M89.9,192.6c0.4,0,0.8,0,1.2,0c0.5,2.7,0.5,2.7-1.2,3C89.9,194.5,89.9,193.6,89.9,192.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity143 ? this.state.opacity143 : 1}
              fill={this.state.color143 ? this.state.color143 : 'transparent'} onClick={() => {
          this.setState({ color143: color, opacity143: opacity });
        }} d="M71.6,177.8c0.8-2.5,2.6-4.4,4.1-6.5C74.8,173.8,72.8,175.6,71.6,177.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity144 ? this.state.opacity144 : 1}
              fill={this.state.color144 ? this.state.color144 : 'transparent'} onClick={() => {
          this.setState({ color144: color, opacity144: opacity });
        }} d="M103.6,194.7c-0.7,0.3-1.2,0.5-1.8,0.8c0-0.8,0.1-1.4,0.1-2c0.5,0,1-0.1,1.6-0.1
	C103.5,193.7,103.5,194.2,103.6,194.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity145 ? this.state.opacity145 : 1}
              fill={this.state.color145 ? this.state.color145 : 'transparent'} onClick={() => {
          this.setState({ color145: color, opacity145: opacity });
        }} d="M86.3,151.2c0.5-2.1,1-4.2,1.4-6.4c0.2,0.1,0.3,0.2,0.3,0.2c-0.4,1.9-0.9,3.8-1.4,5.7
	C86.7,150.8,86.5,151,86.3,151.2C86.3,151.1,86.3,151.2,86.3,151.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity146 ? this.state.opacity146 : 1}
              fill={this.state.color146 ? this.state.color146 : 'transparent'} onClick={() => {
          this.setState({ color146: color, opacity146: opacity });
        }}
              d="M85,155.6c0.4-1.5,0.9-3,1.3-4.4c0,0,0,0,0,0C85.9,152.6,85.4,154.1,85,155.6C85,155.6,85,155.6,85,155.6z" />
      </SvgIcon>
    )
  }
};