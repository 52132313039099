import _ from 'lodash';

const drawables = (state = [], action) => {
  let pageDrawState;
  switch (action.type) {
    case 'SHOW_DRAWABLES':
      return {
        ...state,
        color: state['color'] ? state['color'] : 'black',
        drawableType: action.show ? state['drawableType'] || 'FreePathDrawable' : state['drawableType'],
        showDrawables: action.show,
        weight: state['weight'] ? state['weight'] : 2,
        fromMenu: action.fromMenu
      }
    case 'SHOW_LINE':
      return {
        ...state,
        drawableType: 'LineDrawable',
        enabledSVGClick: false,
        fromMenu: action.fromMenu
      }
    case 'SHOW_CURVE':
      return {
        ...state,
        drawableType: 'FreePathDrawable',
        enabledSVGClick: false,
        fromMenu: action.fromMenu
      }
    case 'SHOW_CIRCLE':
      return {
        ...state,
        drawableType: 'CircleDrawable',
        enabledSVGClick: false,
        fromMenu: action.fromMenu
      }
    case 'SHOW_POINT':
      return {
        ...state,
        drawableType: 'PointDrawable',
        showPointId: action.showPointId,
        pageY: action.pageY
      }
    case 'SHOW_POLYGON':
      return {
        ...state,
        drawableType: 'PolygonDrawable',
        enabledSVGClick: false,
        previousEnabledSVGClick: state['enabledSVGClick'],
        showPolygonId: action.showPolygonId
      }
    case 'SHOW_CURVE_POLYGON':
      return {
        ...state,
        showCurvePolygonId: action.showCurvePolygonId,
        dragImgEnabled: false
      }
    case 'SHOW_ARROW':
      return {
        ...state,
        drawableType: 'ArrowDrawable',
        enabledSVGClick: false,
        fromMenu: action.fromMenu
      }
    case 'SHOW_RECT':
      return {
        ...state,
        drawableType: 'RectangleDrawable',
        enabledSVGClick: false,
        fromMenu: action.fromMenu
      }
    case 'SHOW_TEXT':
      return {
        ...state,
        drawableType: 'TextBox',
        showTextId: action.showTextId,
        pageY: action.pageY
      }
    case 'SHOW_FILL':
      return {
        ...state,
        showedFill: action.showedFill
      }
    case 'SHOW_ELLIPSE':
      return {
        ...state,
        drawableType: 'EllipseDrawable',
        enabledSVGClick: false,
        fromMenu: action.fromMenu
      }
    case 'SHOW_ERASE':
      return {
        ...state,
        drawableType: 'EraseDrawable',
        fromMenu: action.fromMenu
      }
    case 'CHANGE_COLOR':
      return {
        ...state,
        color: action.color,
        fromMenu: action.fromMenu
      }
    case 'SET_OPACITY':
      return {
        ...state,
        opacity: action.opacity
      }
    case 'CHANGE_WEIGHT':
      return {
        ...state,
        weight: action.weight,
        fromMenu: action.fromMenu
      }
    case 'CHANGE_CLIENT':
      return {
        ...state,
        client: action.client
      }
    case 'DRAG_IMG_ENABLE':
      return {
        ...state,
        dragImgEnabled: action.dragImgEnabled
      }
    case 'SAVE_CHANGING':
      return {
        ...state,
        saveChanging: action.saveChanging
      }
    case 'SAVE_CHANGING_DEV_MODE':
      return {
        ...state,
        saveChangingDevMode: action.saveChangingDevMode
      }
    case 'ADD_PAGE_DRAW_STATE':
      if (!state['pageDrawState']) {
        state['pageDrawState'] = [];
      }
      if (state['undoPageDrawState']) {
        state['undoPageDrawState'] = [];
      }
      pageDrawState = [...state['pageDrawState']];
      pageDrawState.push(action.pageDrawStateItem);
      return {
        ...state,
        pageDrawState: pageDrawState,
        drawableType: action.drawType ? action.drawType : state['drawableType'],
        enabledSVGClick: action.drawType ? state['previousEnabledSVGClick'] : state['enabledSVGClick']
      }
    case 'UPDATE_PAGE_DRAW_STATE':
      pageDrawState = [...state['pageDrawState']];
      const pageDrawStateItem = action.pageDrawStateItem;
      const pageDrawStateItemName = pageDrawStateItem.name;
      pageDrawState = pageDrawState.map((item) => {
        if (_.get(item, `${pageDrawStateItemName}.id`) === _.get(pageDrawStateItem, `${pageDrawStateItemName}.id`)) {
          return pageDrawStateItem;
        } else {
          return item;
        }
      })
      return {
        ...state,
        pageDrawState: pageDrawState
      }
    case 'UNDO':
      const lastPageDrawStateItem = state['pageDrawState'].pop();
      if (!state['undoPageDrawState']) {
        state['undoPageDrawState'] = [];
      }
      state['undoPageDrawState'].push(lastPageDrawStateItem);
      return {
        ...state,
        removePageDrawStateItem: lastPageDrawStateItem,
        addPageDrawStateItem: null
      }
    case 'REDO':
      const lastUndoPageDrawStateItem = state['undoPageDrawState'].pop();
      if (!state['undoPageDrawState']) {
        state['undoPageDrawState'] = [];
      }
      state['pageDrawState'].push(lastUndoPageDrawStateItem);
      return {
        ...state,
        removePageDrawStateItem: null,
        addPageDrawStateItem: lastUndoPageDrawStateItem
      }
    case 'PAGE_CHANGED_STATE':
      const pageChangedStateItem = action.pageChangedStateItem;
      if (!state['pageChangedState'] || _.isEmpty(pageChangedStateItem)) {
        state['pageChangedState'] = {};
      }
      if (_.isEmpty(pageChangedStateItem)) {
        state['pageDrawState'] = [];
        state['undoPageDrawState'] = [];
      }
      const pageChangedState = { ...state['pageChangedState'], ...pageChangedStateItem };
      let savedChanged = true;
      for (let prop in pageChangedState) {
        if (pageChangedState.hasOwnProperty(prop)) {
          if (!pageChangedState[prop]) {
            savedChanged = false;
            break;
          }
        }
      }
      savedChanged = savedChanged || _.isEmpty(pageChangedState);
      return {
        ...state,
        savedChanged,
        pageChangedState: pageChangedState
      }
    case 'PAGE_CHANGED_STATE_TEXT':
      const pageChangedStateText = action.pageChangedStateText;
      return {
        ...state,
        pageChangedStateText: pageChangedStateText
      }
    case 'COPY_SELECTED_SHAPE':
      const copiedSelectedShape = action.copiedSelectedShape;
      return {
        ...state,
        copiedSelectedShape
      }
    case 'PAGE_SELECTED_SHAPE':
      const pageSelectedShape = action.pageSelectedShape;
      return {
        ...state,
        pageSelectedShape
      }
    case 'DRAGGABLE_IMAGE_MOVED':
      const isDraggableImageMoved = action.isDraggableImageMoved;
      return {
        ...state,
        isDraggableImageMoved
      }
    case 'SET_MASK_TEXT':
      const maskText = action.maskText;
      return {
        ...state,
        maskText
      }
    case 'SET_MASK_IMAGES':
      const maskImages = action.maskImages;
      return {
        ...state,
        maskImages
      }
    case 'SET_PAGE_EDITABLE':
      const pageEditable = action.pageEditable;
      let showDrawables = state['showDrawables'];
      if (!pageEditable) {
        showDrawables = false;
      }
      return {
        ...state,
        pageEditable,
        showDrawables
      }
    case 'SET_PAGE_TO_TASK_CONTENT_CHANGE':
      const pageToTaskContentChanged = action.pageToTaskContentChanged;
      return {
        ...state,
        pageToTaskContentChanged
      }
    case 'SHOW_DIVIDER':
      const showedDivider = action.showedDivider;
      return {
        ...state,
        showedDivider,
        pageY: action.pageY
      }
    case 'SHOW_ADVANCED_TOOLBAR':
      const showedAdvancedToolbar = action.showedAdvancedToolbar;
      return {
        ...state,
        showedAdvancedToolbar
      }
    case 'SHOW_RULER':
      const showedRuler = action.showedRuler;
      return {
        ...state,
        showedRuler,
        pageY: action.pageY
      }
    case 'SHOW_RULER_TRIANGLE':
      const showedRulerTriangle = action.showedRulerTriangle;
      return {
        ...state,
        showedRulerTriangle,
        pageY: action.pageY
      }
    case 'SHOW_PROTRACTOR':
      const showedProtractor = action.showedProtractor;
      return {
        ...state,
        showedProtractor,
        pageY: action.pageY
      }
    case 'SET_MICROPHONE':
      const showedMicrophone = action.showedMicrophone;
      return {
        ...state,
        showedMicrophone
      }
    case 'SVG_IMAGE_CHANGE':
      const isSVGImageChanged = action.isSVGImageChanged;
      return {
        ...state,
        isSVGImageChanged
      }
    case 'ENABLE_SVG_CLICK':
      const enabledSVGClick = action.enabledSVGClick;
      return {
        ...state,
        enabledSVGClick
      }
    case 'SET_SVG_CLICK_EDITABLE':
      const SVGClickEditable = action.SVGClickEditable;
      return {
        ...state,
        SVGClickEditable
      }
    default:
      return { ...state };
  }
}

export default drawables;
