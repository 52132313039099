import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_5_3 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 278.6 259.4"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M104.9,112.4c0.5,0.3,0.9,0.6,1.4,0.8c0.3,0.1,0.6,0.1,0.9,0.1c0-0.3-0.1-0.5-0.1-0.8c0-0.2-0.1-0.3-0.2-0.6
	c0.5,0.1,0.9,0.3,1.6,0.5c-0.3-0.6-0.5-0.9-0.7-1.2c1.4,0.9,2.8,1.3,3.7-0.8c-0.2-0.7-0.5-1.5-0.8-2.5c0.9,0.2,1.7,0.4,2.4,0.5
	c0.4,0,0.8-0.1,1.2-0.2c-0.1-0.4-0.2-0.8-0.4-1.3c0.3,0.1,0.6,0.1,0.9,0.2c0.5,0.1,1,0.4,1.5,0.4c0.9,0.1,1.9,0.1,1.3-1.3
	c-0.1-0.2,0-0.5,0.1-0.7c0.4-1,0.8-1.9,1.3-3c0.3,0.5,0.6,0.9,0.9,1.3c0.1,0,0.2,0,0.3,0c0-0.3,0.1-0.7,0.2-1
	c0.1-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.5,0.1,0.7,0.2c0.3,0.2,0.6,0.3,0.9,0.5c0-0.3,0-0.7,0-1c0-0.2,0-0.4,0.1-0.7
	c0.3,0.3,0.6,0.5,1.1,1c-0.2-2.5,1.8-3.2,3-4.4c0.1,0.9,0.3,1.7,0.5,2.8c1-1.1,1.8-1.9,2.6-2.8c0.6,0.9,1.1,1.8,1.7,2.5
	c0.2,0.2,0.8,0.3,1.1,0.2c1.3-0.4,2.6-1,3.9-1.5c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0.4-0.3,0.9-0.1,1.3
	c0.1,0.2,0.8,0.4,1.1,0.3c1.1-0.2,2.3-0.6,3.4-0.9c0.3-0.1,0.6-0.2,1.3-0.4c-0.5,0.9-0.9,1.4-1.2,2c-0.1,0.3-0.1,0.7,0,0.9
	c0.1,0.2,0.6,0.2,0.9,0.1c0.7-0.2,1.5-0.5,2.4-0.6c-0.7,0.6-1.3,1.1-2,1.7c-0.6,0.5-0.7,0.9-0.1,1.4c1.2-0.2,2.3-0.3,3.8-0.5
	c-0.6,0.5-1,0.8-1.3,1.1c-0.2,0.3-0.6,0.8-0.5,1c0.1,0.3,0.6,0.6,1,0.6c0.7,0,1.3-0.1,2-0.2c0.4,0,0.8,0,1.6,0
	c-0.8,0.6-1.3,0.9-1.8,1.3c-0.3,0.3-0.4,0.7-0.6,1.1c0.4,0.2,0.9,0.4,1.4,0.5c0.2,0.1,0.5,0,0.8,0.2c-0.3,0.2-0.6,0.4-0.8,0.7
	c-0.2,0.3-0.4,0.8-0.2,1c0.6,0.6,1.3,1.1,2,1.6c-0.8,1-0.7,1.6,0.6,2.3c0.5,0.3,1,0.7,1.7,1.2c-0.6,0.3-0.9,0.5-1.2,0.6
	c-1.5,0.4-1.6,0.6-0.8,1.9c0.3,0.6,0.8,1.1,1.3,1.8c-0.9-0.1-1.6-0.2-2.7-0.3c0.3,1,0.6,1.7,0.8,2.5c-1.2-0.3-1.3,0.3-0.9,1.3
	c0.1,0.3,0,0.7,0.1,1.1c-0.6-0.3-1-0.6-1.7-0.9c0.1,0.8,0.2,1.4,0.2,2c-0.5-0.3-0.9-0.6-1.4-0.9c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.1,0.2-0.3,0.5-0.3,0.7c0.5,1.4-0.9,1.4-1.5,2c-0.1,0.1-0.4,0-0.6,0.1c-0.2,0.1-0.6,0-0.7,0.2c-0.8,1.4-1.8,0.7-2.8,0.3
	c-0.4-0.1-0.8-0.2-1.5-0.4c0.3,0.7,0.5,1.1,0.7,1.6c-0.7-0.4-1.2-0.7-1.8-0.9c-1-0.5-1.2-0.4-1.2,0.7c0,0.2,0,0.4-0.1,0.8
	c-0.7-0.3-1.3-0.6-1.9-0.9c-0.5-0.2-1-0.4-1.2,0.4c-0.1,0.2-0.3,0.5-0.5,0.8c-0.4-0.2-0.8-0.5-1.1-0.7c-0.3-0.2-0.7-0.4-1-0.7
	c-1,0.8-1.9,1.5-3,2.4c-0.2-0.1-0.7-0.4-1.2-0.5c-0.7-0.1-1.4,0-2.1,0c-0.4,0-0.9,0.1-1.2-0.1c-0.9-0.3-1.4-0.2-1.1,0.7
	c-0.7,0.2-1.4,0.3-2.3,0.6c-0.4-0.2-1.2-0.9-1.9,0.4c0.1-1,0.1-1.6,0.2-2.3c-0.1,0-0.2-0.1-0.3-0.1c0.5-0.4,1.1-0.8,1.6-1.2
	c0.3-0.2,0.5-0.4,0.7-0.6c-0.2-0.2-0.5-0.5-0.7-0.7c-0.1-0.1-0.3-0.2-0.3-0.2c0.4-0.5,1-0.9,1-1.4c0-0.4-0.8-0.8-1.3-1.3
	c0.3-0.3,0.7-0.8,0.9-1.1c0-0.7-0.1-1.2,0-1.7c0.1-0.8-0.9-3.2-1.7-3.3c-1-0.2-1.9,0-3.1,0c0.2-0.4,0.5-0.8,0.8-1.2
	c0-0.1-0.1-0.3-0.1-0.4c-0.8,0.1-1.6,0.3-2.5,0.4c-0.3-1.6-0.6-1.6-2.1-0.6c-0.5,0.3-1.4-0.2-2.1-0.3c-0.3-0.1-0.6-0.2-1-0.4
	c0.8,2.2,1.3,2.6,2.2,1.6c0.6,0.4,1.1,1.7,1.8,0.1c0.3,0.6,0.5,1,0.7,1.6c0.4-0.2,0.7-0.5,1.1-0.8c-0.2,1.5,0.6,1.6,1.7,1.1
	c0,0.1,0,0.2,0,0.3c-0.7,0.3-1.4,0.6-2.1,0.9c0,1,0.4,1.3,1.3,0.7c1.1-0.6,2.1-1.3,3.2-1.9c1.3,1.7,0.9,2.8-1.1,3.2
	c-0.8,0.1-1.6,0.4-2.5,0.6c0.2,1.1,0.8,1.2,1.8,0.8c0.6-0.2,1.3-0.3,2-0.4c-0.7,1.6-0.7,1.7,0.6,2.3c-0.5,0.6-1.3,1.2-0.3,1.8
	c-0.1,1.2-2.7,1.1-1.1,2.8c-1.9,2-5.7,3.2-7.4,2.2c1.1,0.2,1.3-1.6,2.8-0.7c-0.1-0.4,0-0.8-0.2-1.1c-0.8-1.1-1.7-2-3.2-2
	c-0.1,0.1-0.1,0.2-0.2,0.4c0.6,0.4,1.2,0.7,1.8,1.1c-1.3,1.9-2.9,2.4-5,1.5c-1.3-0.6-2.5-0.9-3.9,0c-0.2,0.1-0.7,0.1-1-0.1
	c-0.7-0.4-1.3-0.9-2.1-1.5c0.3-0.2,0.6-0.4,1-0.5c2.7-0.8,4.4-2.8,6-4.8c1.8-2.2,1.8-5.4,0.1-7.7c0.2-0.1,0.4-0.2,0.6-0.3
	c-0.1-0.3-0.3-0.6-0.5-1.1c0.5-0.1,1-0.2,1.6-0.3c-0.5-0.8-1-1.5-1.5-2.2c0.2-0.1,0.4-0.3,0.7-0.5c-0.4-0.4-0.7-0.7-1-1
	C104.7,112.5,104.8,112.4,104.9,112.4z M107.3,123.4c0,0.1,0,0.3,0,0.4c-0.3,0.5-0.7,1.1-1.1,1.6c-0.8,1-0.7,2.1,0.1,3
	c0.9,0.9,1.8,0.9,2.8,0c0.1-0.1,0.3-0.2,0.5-0.3c1.7-1,1.7-3.3,0-4.2C109,123.5,108.1,123.5,107.3,123.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M118,140.8c0.5-0.7,1.1-1.3,1.6-2c0.2-0.3,0.1-0.7,0.1-1c-0.3,0.1-0.6,0.3-0.9,0.4c-0.8,0.4-1.7,0.7-2.6,0.9
	c1.6-1.3,3.3-2.7,5-4.1c0-0.2-0.2-0.7-0.3-1.6c0.8,0.4,1.2,0.7,1.9,1c-0.1-0.6-0.1-1-0.2-1.6c0.9,0.7,1.6,1.2,2.4,1.8
	c0.1-0.1,0.2-0.2,0.3-0.3c-0.2-0.5-0.4-1-0.7-1.7c0.8,0.3,1.4,0.5,2.1,0.7C125,137.3,122,139.6,118,140.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M143.8,129.1c0.6,0.3,1.1,0.5,1.7,0.8c0-0.6,0-1.2,0-1.8c0.8,0.4,1.4,2.2,0.9,3.2c-1.3,2.4-2.7,4.6-5.3,5.8
	c-0.1,0.1-0.3,0-0.5,0.1c0.4-1.3,1.8-2.3,0.4-3.9c-0.2,0.3-0.4,0.6-0.4,0.9c0,1-0.1,1.8-1.4,1.3c-0.1,0-0.3,0.1-0.4,0.1
	c0.7-1.4,1.3-2.9,2.3-4.2c0.5-0.7,1.3-2,2.7-0.5C143.7,130.3,143.8,129.8,143.8,129.1z" />
        <path d="M107.3,123.4c0.8,0.2,1.7,0.1,2.4,0.5c1.7,0.9,1.6,3.2,0,4.2c-0.2,0.1-0.3,0.2-0.5,0.3c-1,0.9-1.9,0.9-2.8,0
	c-0.9-0.9-0.9-2-0.1-3c0.4-0.5,0.7-1.1,1.1-1.6C107.3,123.6,107.3,123.5,107.3,123.4z M106.7,127.1c-0.1,0.2-0.1,0.3-0.2,0.5
	c0.5,0.1,1,0.5,1.4,0.3c1.5-0.5,2.3-1.8,2-2.8c-0.2-0.8-0.8-0.9-1.2-0.7c0.2,0.7,0.6,1.5,0.4,1.9
	C108.7,127.2,107.7,127.4,106.7,127.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M106.7,127.1c1,0.3,2,0.1,2.5-0.8c0.2-0.4-0.2-1.2-0.4-1.9c0.5-0.2,1,0,1.2,0.7c0.3,1-0.6,2.2-2,2.8
	c-0.4,0.1-0.9-0.2-1.4-0.3C106.5,127.4,106.6,127.3,106.7,127.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M133.1,33.2c-1.4,0.2-1.4,0.2-1.3,1.7c-1.4-0.1-1.3,1.1-1.3,2.3c-0.3-0.6-0.4-0.9-0.6-1c-0.8-0.5-0.6-1,0-1.5
	c0.6-0.5,0.5-2.2-0.2-2.6c-0.1-0.1-0.5-0.1-0.5,0c-0.2,0.3-0.4,0.6-0.4,0.9c0,0.3,0.3,0.5,0.4,0.8c-1.3,0.7-1.4,0.8-0.8,2
	c-1.1,0-1.6,0.6-1.2,1.7c-1,0.1-2.2-0.2-2.3,1.4c-1.5-0.9-2,0.5-2.9,1.1c-0.6-0.7-1.2-1.1-2-0.4c-0.1,0.1-0.4,0-0.7,0
	c-0.3,0-0.6-0.1-0.8,0c-0.1,0.1-0.2,0.6-0.1,0.7c0.2,0.2,0.7,0.6,0.8,0.5c0.8-0.4,1.5-0.3,2.3,0.1c0.2,0.1,0.9-0.5,1.4-0.7
	c0.4-0.2,0.8-0.2,1.2-0.4c0.7-0.3,1.3-0.7,2.1-1.2c1,0.4,1,0.4,2.4-1.7c-0.1,2.2,1.3,3.6,2.4,5.2c-0.4,0.3-0.7,0.6-0.9,0.8
	c0.9,0.9,1.7,1.8,2.6,2.7c-0.6,0.2-0.9,0.4-1.5,0.6c0.6,0.7,1.1,1.3,1.8,2.2c-0.9,0-1.5,0-1.9,0c0.2,1,0.3,1.9,0.5,2.8
	c0,0.2,0,0.5-0.1,0.5c-1.3,0.4-0.7,1.6-1.2,2.4c-0.2,0-0.6,0.1-0.9,0.2c-0.1,1.1-0.1,2.2-0.2,3.3c0,0.8,0,1.5-1,1.8
	c-0.4,0.1-0.5,0.9-0.8,1.4c-1-1-1.6-0.8-2,0.4c-0.2,0.5-0.6,1-0.8,1.5c-0.1,0.2-0.1,0.5-0.1,0.8c0.3-0.1,0.7-0.1,0.9-0.2
	c0.4-0.5,0.6-1,0.9-1.7c0.2,0.3,0.5,0.5,0.5,0.7c-0.1,1-0.3,2.1-0.6,3.1c-0.1,0.3-0.4,0.5-0.6,0.6c-1.5,0.8-2.4,3.6-1.5,5
	c0.1,0.1,0.2,0.2,0.4,0.3c0.8-1.6,2-2.5,3.8-1.6c0.9,0.5,1.4,2.2,0.9,3.2c-0.2,0.5-0.9,0.8-1.3,1.2c-0.1-0.1-0.2-0.2-0.3-0.3
	c0.2-1.1,0.4-2.3,0.6-3.4c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3,0.3-0.7,0.5-0.8,0.8c-0.2,0.7-0.4,1.5-0.4,2.3c-0.1,0.9-0.5,1-1.2,0.9
	c-0.5-0.1-1-0.4-1.6-0.5c-0.7-0.2-1.5-0.5-2.3-0.5c-1,0-1.2-0.4-1.2-1.3c0.1-0.6,0.1-1.2,0.2-1.8c0-0.3,0.1-0.7,0-0.9
	c-1.7-2-0.2-4.4-0.9-6.7c2.1,0.7,2.9-0.8,4-1.8c-0.7-0.4-0.7-0.4-2.2,1c-1.4-1-1.6-1-2.4,0.6c-0.9-0.5-1.8-1.1-2.7-1.6
	c-0.1,0.1-0.3,0.2-0.4,0.4c0.3,0.5,0.6,1.4,1,1.5c2.4,0.4,1.9,2.2,1.8,3.7c-0.1,1.6-0.4,3.1-1.9,4.2c-0.3,0.2-0.4,1.1-0.2,1.5
	c0.3,1-0.4,2.6-1.4,3c-0.7,0.2-1.3,0.5-1.9,0.9c-0.9,0.5-1.8,0.4-2.3-0.3c-0.4-0.6-0.1-1.7,0.6-2.2c0.3-0.2,0.6-0.3,0.9-0.5
	c0.2-0.1,0.5-0.3,0.8-0.5c-1.6-0.5-2.9,0.6-3.7,2.8c-0.6-1.1-0.3-2.1,0.8-2.7c0.7-0.4,1.3-0.8,1.9-1.3c0.2-0.1,0.3-0.5,0.2-0.8
	c0-0.7-0.6-1.3,0.1-2c0.1-0.1-0.2-0.5-0.4-0.8c-0.4-0.6-1-1.2-1.2-1.8c-0.4-1.4-0.5-2.9-0.8-4.3c-0.1-0.3-0.3-0.5-0.5-0.8
	c-0.2-0.3-0.5-0.7-0.7-1c-0.1-0.2-0.2-0.5-0.2-0.5c-1.6,0-1.5-1.6-2.2-2.4c0-0.1-0.1-0.1-0.2-0.1c-0.9,0.9-0.7,2.6,0.5,3.1
	c0.6,0.2,0.9,0.4,0.9,1.1c0,0.2,0.5,0.5,0.9,0.8c0,0.1-0.2,0.5-0.2,0.8c-0.1,0.6-0.6,1.2,0.4,1.7c0.4,0.2,0.4,1.1,0.5,1.7
	c0.2,0.9,0.3,1.9,1.4,2.3c-0.1,0.5-0.1,1-0.3,1.6c-0.9,0.1-1.9,0.1-2.9,0.2c-0.2,0-0.4,0-0.5,0.1c-1,1.7-2.8,0.8-4.2,1.1
	c-0.7,0.2-1.5-0.5-2.5-0.8c0.4-0.4,0.6-0.7,0.8-0.9c0.7-0.6,1.4-1.2,1.5-2.2c0-0.2,0.2-0.5,0.4-0.7c1.5-1.2,1.4-2.6,1.2-4.2
	c0.1-0.8,0.5-1.6,0.2-2.3c-0.6-1.7-1.5-3.2-2.3-4.8c-0.7,0.3-1.1,0.5-0.7,1.3c0.7,1.3,1.3,2.7,2,4.1c-0.5,0.1-0.9,0.2-1.2,0.2
	c0.3,1.2,0.6,2.3,1,3.6c-1.1-0.2-1.5,0-1.4,1.1c0,0.9-0.4,1.9-0.9,2.8c-0.2,0.4-0.8,0.5-1.3,0.7c-1.2,0.6-2.5,1.2-3.7,1.9
	c0,0.1,0.1,0.3,0.1,0.4c1-0.2,2-0.4,3-0.6c0.4,0.2,1.1,0.3,1.4,0.6c0.4,0.6,0.8,1.3,0.9,2c0.1,0.6-0.7,0.7-2,0.4
	c0.1-0.4,0.4-0.8,0.4-1.2c0-0.3-0.2-0.6-0.3-1c-0.3,0.2-0.8,0.4-0.9,0.7c-0.4,1.8-0.4,1.8-2.3,1.5c0.1-0.3,0.3-0.6,0.4-0.9
	c-0.1-0.1-0.1-0.2-0.2-0.3c-0.4,0.2-0.7,0.5-1.1,0.6c-0.6,0.3-1.2,0.5-1.8,0.7c-0.2-0.6-0.5-1.1-0.6-1.7c-0.1-0.6-0.2-1.3,0.7-1.5
	c0.2,0,0.3-0.3,0.5-0.5c-0.2-0.1-0.4-0.3-0.5-0.3c-1.5,0.1-2.1-0.8-2.3-2.2c-1,0.1-1.4-0.4-1.5-1.4c-0.1-0.8-0.2-1.6-1.2-2.1
	c-0.7-0.4-0.9-1-0.7-2c0.3-1.4,0.1-3,0.4-4.4c0.2-1,1-2,1.5-2.9c0.4-0.7,0.4-0.7-0.6-1.4c1.3-0.8,2.5-1.6,3.7-2.4
	c1.1-0.8,2.2-1.6,3.2-2.4c0.2-0.1-0.1-0.8-0.2-1.4c-0.1,0.1,0.1,0,0.1,0c-0.1-1.3,1.1-1.4,1.9-2c0.2-0.1,0.4-0.4,0.4-0.5
	c-0.1-0.2-0.3-0.4-0.6-0.5c-0.2-0.1-0.5-0.1-0.9-0.2c2-1.8,3.8-3.6,5.7-5.4c-0.4-0.1-0.6-0.2-0.9-0.3c0.4-0.4,0.9-0.7,1.3-1.1
	c0.9-0.8,2.2-0.9,2.9-0.1c0.2,0.2,0.1,0.7,0,1c-0.4,0.8-0.1,1,0.7,1c0.7,0,1.3,0.1,2-0.1c0.3-0.1,0.5-0.6,0.8-0.9
	c-0.3-0.1-0.6-0.3-0.9-0.3c-0.3,0-0.6,0.1-1.1,0.1c0.1-1.2-0.2-2.2-1.5-2.5c0.4-1.1,0.6-2.1-0.6-2.6c-0.1-0.7,1.6-1.2,0.1-1.8
	c0.1-1,0.3-2.1,0.4-3c0.4-0.8,0.8-1.6,1.1-2.4c0.5-1.3-0.9-1.5-1.4-2.5c0.5-0.3,1-0.5,1.5-0.8c0.2,0.5,0.4,0.9,0.6,1.3
	c0.1,0.2,0.5,0.4,0.6,0.4c0.2-0.1,0.4-0.4,0.4-0.6c0-0.5-0.2-1.1-0.3-1.6c-0.3-1.2-0.7-2.3-1.1-3.5c-0.1-0.4-0.1-0.8-0.1-1.4
	c1,0.1,1.9,0,2.8,0.2c1,0.3,1.9,0.8,2.9,1.1c0.3,0.1,0.6,0,0.9,0c0-0.3-0.1-0.5-0.1-0.8c0-0.5,0-1,0.2-1.4c0.1-0.2,0.8-0.3,1.1-0.1
	c1.1,0.5,1.8,0.3,2.3-0.8c0.2-0.5,2.2-0.7,2.7-0.4c0.2,0.2,0.6,0.3,0.8,0.2c0.9-0.3,1.7-0.6,2.5-1c0.1,0.1,0.2,0.1,0.2,0.2
	c0.3,0.6,0.5,1.2,0.8,1.8c0.5-0.3,1.1-0.7,1.6-1c0.9-0.5,1.9-0.9,2.9-1.3c0.8-0.3,1.1,0.2,1.1,0.9c0,0.9-0.2,1.9-0.2,2.7
	c-0.5,0-1,0-1.6,0c0-0.3,0.1-0.7,0.2-1.3c-0.6,0-1.1,0-1.6,0c-0.5,0.1-1.1,0.2-1.8,0.3c0.1,0.3,0.2,0.9,0.3,0.9c0.5,0,1-0.1,1.6-0.2
	c-0.1,0.2-0.3,0.5-0.5,0.9c0.5,0.3,1,0.6,1.6,1c0.2,0.7,0.5,1.4,1.7,0.7c-0.2,0.3-0.4,0.5-0.7,1c0.3,0.1,0.6,0.2,0.9,0.3
	c0.3,0.1,0.7,0.1,1,0.1c-0.2,0.3-0.4,0.5-0.6,0.8c-0.1,0.1-0.2,0.2-0.3,0.3c0.8,0.6,1.6,1.2,2.5,1.9c-0.9,0.7-1.2,1.3-0.1,1.9
	c0.6,0.4,0.8,1-0.2,1.3c-0.8,0.3-1.3,0.9-0.6,1.6C132.8,30.3,132.4,31.9,133.1,33.2z M124.3,29.8c0.5,0,0.8,0,1-0.1
	c2.3-0.5,3.8-1.8,3.5-4.1c-0.1-0.6,0-1.1,0-1.8c-0.9-0.5-2.9-0.4-3.9,0.5c-1.8,1.5-2.2,3.2-1.4,5.9c-0.3,0.1-0.5,0.2-0.8,0.3
	c-0.5,0.2-1,0.4-1.4,0.7c0.3,0.4,0.5,0.9,0.8,1.2c0.8,0.8,1,1.8,0.3,2.5c-0.8,0.8-1.6,0.6-2.2,0.2c-0.1-0.6-0.1-1-0.2-1.3
	c-0.5,0.4-1.1,0.8-1.4,1.3c-0.3,0.4-0.2,1-0.3,1.5c0.9-0.2,1.5-0.5,2-0.4c1.2,0.2,2.2-0.1,3-0.9c1.4,0.8,2.6,0.8,3.4-0.2
	c0.4-0.5,1-1.2,0.5-2.3c-0.5,0.7-0.7,1.2-1.1,1.6c-0.2,0.3-0.6,0.5-0.9,0.5c-0.5-0.1-1.2-0.3-1.3-0.7c-0.2-0.6-0.1-1.4,0.1-1.9
	C124.6,31.4,125.4,30.9,124.3,29.8z M117.6,31.2c0.8-0.3,2-0.8,3.1-1.4c0.2-0.1,0.3-0.7,0.2-1c-0.5-1.7-1.4-3.1-3.3-3.4
	c-1.8-0.3-3,0.6-4.2,1.7c-0.2,0.2-0.4,0.6-0.3,0.8C113.4,29.6,115.4,31.2,117.6,31.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M94.8,46.7c-0.3,0.1-0.6,0.3-1.1,0.6c0.7,0.3,0.9,0.4,1.2,0.6c-0.5,0.2-1,0.5-1.5,0.7c0,0.1,0.1,0.2,0.1,0.3
	c0.5,0.1,1,0.1,1.5,0.2c0,0.1,0,0.2,0.1,0.3c-1.3,0.7-2.5,1.5-3.8,2.2c-1.2,0.6-2.4,2.7-2.6,4c-0.2,1-0.9,1.8-1.3,2.7
	c-0.2,0.4-0.1,0.8-0.1,1.2c0,0.2,0,0.4,0,0.6c-0.1,1.3-0.4,2.7-0.3,3.9c0.1,0.7,1,1.3,1.5,1.9c0.3,0.3,0.8,0.8,0.8,1
	c-0.4,1.2,0.4,1.6,1.2,2.2c0.2,0.2,0.4,0.4,0.5,0.7c-2-0.3-3.5-1.3-4.5-3c-0.9-1.5-1.8-3-2.7-4.6c0.2-0.1,0.5-0.4,0.8-0.6
	c-0.3-0.3-0.5-0.6-0.8-0.9c-0.2-0.3-0.5-0.5-0.8-0.7c-0.3-0.2-0.5-0.5-0.8-0.7c1.1-1.8-1.2-2-1.4-3.3c0.5-0.2,1.1-0.5,1.7-0.8
	c-1.1-1.8-2.2-3.4-3.2-4.9c0.6-0.2,1.2-0.5,2-0.9c-0.9-0.7-1.6-1.2-2.3-1.8c0.3-0.4,0.5-0.6,0.7-0.9c-0.4-0.3-0.8-0.6-1.2-0.9
	c0.1-0.2,0.2-0.3,0.3-0.3c1.8-1,1.8-1.1,1-3c-0.3-0.8-0.8-1.6-1.2-2.6c0.2-0.1,0.6-0.2,1.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.4
	c-0.6-0.3-1.1-0.6-1.8-1c0.6-0.4,1-0.6,1.5-1c-1-1.3-2.1-2.5-3.1-3.8c0.5-0.4,1.1-0.8,1.7-1.2c0-0.1-0.1-0.2-0.1-0.3
	c-0.3,0-0.6-0.1-1.2-0.1c0.7-0.5,1.2-0.8,1.8-1.2c-0.2-0.3-0.4-0.6-0.6-0.8c0.8-0.9,1.5-1.7,2.3-2.5c0.1-0.1,0.2-0.1,0.3-0.2
	c0.7-0.3,1.5-0.6,2.2-1.2c0.3,0.2,0.6,0.3,0.9,0.5c0.6,0.3,1.1,0.3,1.5-0.3c0.4-0.6,0.7-0.9,1.3-0.1c0.2,0.3,0.8,0.5,1.1,0.4
	c1-0.3,1.4,0.2,1.8,0.9c0.4,0.6,0.8,1.2,1.8,0.6c0.3,1.3,0.6,2.5,1,3.7c0.2,0.5,0.7,1,1.1,1.5c0.1-0.1,0.2-0.1,0.2-0.2
	c0.3,0.9,0.7,1.8,0.9,2.7c0.1,1,0,2,0,3.1c0.4,0,0.7,0.1,1.1,0.1C94.5,41.3,93.8,43.9,94.8,46.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M126.7,24.2c1.3-0.3,1,0.9,1.2,1.5c0.4,1.5-0.5,2.4-3.5,3.6c-0.1-0.3-0.2-0.6-0.3-1
	C126.9,28.3,128.3,27.2,126.7,24.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M125.4,24.8c0.9,0.5,1.3,1.1,1,2c-0.3,0.8-0.8,0.9-1.6,0.6c-0.9-0.3-0.5-0.8-0.3-1.3
	C124.8,25.7,125.1,25.3,125.4,24.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M119.5,28.6c-0.3,1.8-2.3,2.3-3.7,1.2c-0.6-0.5-1-1.1-1.4-1.8c-0.1-0.2-0.1-0.6,0-0.7c0.5-0.4,1-0.8,1.6-1.2
	c-0.4,1.1-0.9,2.2,0,3.1C117.3,30.3,118.4,29.4,119.5,28.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M117.6,29.3c-0.5-0.8-1-1.2-0.9-1.5c0.1-0.5,0.5-1.4,0.9-1.4c0.7,0,1.4,0.5,1.2,1.3
	C118.7,28.2,118.2,28.6,117.6,29.3z" />
      </SvgIcon>
    )
  }
};