import ArrowDrawable from "./ArrowDrawable";
import { Rect } from "react-konva";
import React from "react";

export default class RectangleDrawable extends ArrowDrawable {
  drawableType = 'RectangleDrawable';

  constructor(startx, starty, color, weight, rotation = 0, opacity = false) {
    super(startx, starty, color, weight, opacity);
    this.x = startx;
    this.y = starty;
    this.color = color;
    this.weight = weight;
    this.rotation = rotation
  }

  render() {
    const dx = this.x - this.startx;
    const dy = this.y - this.starty;
    return (
      <Rect
        x={this.startx}
        y={this.starty}
        width={dx}
        height={dy}
        fill={this.fill}
        stroke={this.color}
        strokeWidth={this.weight}
        rotation={this.rotation}
        opacity={this.opacity}
      />
    );
  }
}