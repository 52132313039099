import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_33_1 extends SVG {

  render() {
    let { color,  opacity = false } = this.props;
    const borderColor = '#636464';
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 466.1 159.4"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M154.8,87.5c-0.5,1-1,1.9-1.5,3.1c-3.4-0.4-6.9-1-9.8-3.3c-0.9-0.7-1.9-1.4-2.8-2.2c-0.9-0.8-1-1.7-0.1-2.6
	c1-0.9,2.1-1.7,3.2-2.4c0.4-0.2,1.3-0.1,1.5,0.2c2.1,3.3,5.4,4.9,8.7,6.6C154.3,87.2,154.5,87.3,154.8,87.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M160.7,93c-2-0.4-3.8-0.9-5.4-1.9c-0.9-0.6-1.1-1.2-0.5-2.1c1.2-1.5,2.3-3.2,4.3-3.8c2-0.6,3.9,0,5.2,1.7
	c0.2,0.3,0.4,0.7,0.7,0.9c1.8,1.4,1.3,2.6-0.7,3.9C163.4,92.4,162,92.5,160.7,93z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M166.2,88.4c2.7-1.1,5.1-1.9,7.3-2.9c1.3-0.6,2.5-1.5,3.8-2.2c0.3-0.2,0.6-0.4,0.9-0.6c2-1.3,4.3-0.7,5.3,1.5
	c0.2,0.3,0,1.2-0.2,1.3c-1.5,1-3.1,2-4.8,2.9c-3.5,1.7-7.3,0.9-11,1C167.1,89.3,166.8,88.8,166.2,88.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M154.5,91.9c-3.3,1-6.5,2.1-9.9,2.8c-1.7,0.4-3.5-0.3-4.9-1.5c-0.4-0.3-0.6-0.9-0.6-1.4
	c0.1-1.3,0.2-2.5,0.6-3.8c0.3-1.1,1.1-1.3,2.2-0.8c1.2,0.6,2.3,1.4,3.5,2c2.6,1.2,5.3,1.8,8.2,1.8c0.3,0,0.6,0.2,0.9,0.2
	C154.6,91.6,154.5,91.8,154.5,91.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M177.7,79.9c-2.8,4-6.8,6-11.2,7.4c-1.2,0.4-1.3-0.7-2-1.3c1.6-2.1,3-4.3,5.2-5.8c0.2-0.1,0.4-0.3,0.5-0.5
	C172.7,76.9,175.5,78.1,177.7,79.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M144,96.3c0.6-0.3,1-0.6,1.5-0.7c2.9-1,5.8-1.9,8.7-3c1.3-0.5,2.3,0.1,3.5,0.6c-0.5,0.7-0.9,1.5-1.5,2.1
	c-1.5,1.5-3.1,3-4.7,4.4c-1,0.9-2.2,0.8-3.3,0.1C146.7,98.9,145.2,97.9,144,96.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M161.1,93.6c0,2.4,0.1,4.7,0,6.9c-0.1,1.7-1.6,2.5-3,2.6c-1.2,0-2.4-0.3-3.6-0.6c-1.1-0.3-1.5-1.2-1.1-2.1
	c0.4-1,1-1.9,1.7-2.7c1-1.3,2-2.5,3.1-3.8c0.3-0.3,0.8-0.5,1.2-0.5C159.9,93.4,160.6,93.6,161.1,93.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M157.7,84.8c-0.2,0.2-0.3,0.2-0.4,0.3c-0.7,0.5-1.7,1.6-2.2,1.5c-1.5-0.4-2.8-1.3-4.2-2.2
	c-0.7-0.5-1.2-1.2-1.9-1.8c-1.1-1.1-1.5-2.4-1.6-3.9c0-0.2,0-0.4,0.1-0.6c0.5-0.9,3.9-2,4.8-1.5c0.2,0.1,0.4,0.3,0.4,0.5
	C153.5,80.2,155.4,82.7,157.7,84.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M161.9,93.7c1-0.4,1.9-0.7,2.9-1.2c1.1,1.7,2.3,3.5,3.3,5.3c0.4,0.7,0.6,1.6,0.7,2.4c0.2,1.5-0.3,2.3-1.8,2.6
	c-0.7,0.1-1.3,0.2-2,0.2c-2.1,0-2.9-0.6-3-2.8C161.9,98,161.9,95.8,161.9,93.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M166.5,90.2c2.1,0,4,0,5.9,0c2.8,0.1,5.6,0.2,8.4,0.2c0.8,0,0.8,0.5,0.7,1.1c-0.3,2.8-3.2,4.8-5.8,3.7
	c-1.6-0.6-3-1.5-4.5-2.4C169.6,92,168.1,91.1,166.5,90.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M165.3,92.2c0.5-1.2,1.5-0.9,2.1-0.5c2.4,1.4,4.7,2.8,7,4.4c1.5,1.1,1.5,1.8,0.3,3.2c-0.3,0.3-0.5,0.6-0.8,0.9
	c-0.8,0.9-2,1.1-2.9,0.3c-0.9-0.8-1.8-1.7-2.3-2.9c-0.7-1.5-1.8-2.9-2.7-4.4C165.8,92.8,165.6,92.5,165.3,92.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M164.2,85.2c-0.8-0.3-1.5-0.6-2.2-0.9c-0.6-0.2-0.8-1.7-0.5-2.7c0.6-1.7,1.2-3.4,1.9-5c0.1-0.3,0.6-0.7,0.9-0.6
	c1.4,0.2,2.7,0.5,4.1,0.8c0.9,0.2,0.6,1,0.4,1.5C167.5,80.9,166.2,83.4,164.2,85.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M158.3,75.5c3.4,0,2.6,0,2.6,2.8c0,1.3,0,2.6-0.1,3.9c0,0.6-0.1,1.5-0.5,1.7c-0.8,0.6-1.7,0.5-2.5-0.3
	c-1.8-1.9-1.9-4.4-2.3-6.7c-0.1-0.3,0.6-1,1.1-1.2C157,75.4,157.7,75.5,158.3,75.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M150.3,106.3c1.1,0.8,2.3,1.4,3.3,2.3c0.7,0.6,1.1,1.5,1.8,2.5c-1.9-0.6-3.6-0.9-5.1-1.7
	c-2.6-1.2-2.9-3.7-3.1-6.4c0.3,0.2,0.5,0.2,0.6,0.4c1.3,1.5,3.1,1.7,4.8,2.1c1.2,0.3,2.1,1,2.5,2.2c0.3,0.8,1.3,1.5,0.6,2.8
	C154.5,108.1,152.6,106.8,150.3,106.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M168.7,110.1c-0.2-0.3-0.4-0.6-0.6-0.8c-1.3-1.5-2.1-1.7-3.9-1c-2,0.8-4,1.5-5.3,3.4c-0.1,0-0.2-0.1-0.3-0.1
	c0.2-0.6,0.2-1.2,0.5-1.7c1.4-2.9,6-5,9.1-4.3c0.7,0.1,1,0.5,1,1.2c0,1.1,0,2.1,0,3.2C169,110.1,168.9,110.1,168.7,110.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M158.3,114.1c-1,0.4-1.4-0.1-1.4-0.9c-0.1-2.7-0.1-5.5-0.1-8.2c0-0.6,0.3-1.2,1-0.9
	C158,107.5,157.2,110.8,158.3,114.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1}
              fill={this.state.color17 ? this.state.color17 : 'transparent'} onClick={() => {
          this.setState({ color17: color, opacity17: opacity });
        }} d="M338.9,89c-0.5,1.1-1,2-1.5,3c-3.2-0.2-6.2-1-9-2.6c-1.3-0.7-2.5-1.8-3.7-2.8c-0.9-0.7-0.9-1.7-0.1-2.5
	c1-0.9,2.2-1.7,3.3-2.5c0.6-0.4,1.2-0.3,1.6,0.4c1.9,3.2,5.1,4.6,8.1,6.3C338.1,88.6,338.5,88.8,338.9,89z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M344.6,94.3c-1.7-0.1-3.6-0.6-5.2-1.7c-0.8-0.5-1.1-1.2-0.4-2c1.3-1.6,2.4-3.3,4.5-3.9c1.5-0.4,2.9-0.1,4,0.8
	c1,0.8,1.8,1.8,2.5,2.9c0.2,0.3,0.1,1.2-0.2,1.6C348.8,93.6,347.1,94.3,344.6,94.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M350.7,89.8c2.3-0.9,4.6-1.7,6.8-2.6c1.4-0.6,2.7-1.5,4-2.3c0.3-0.2,0.5-0.4,0.8-0.6c2-1.3,4.2-0.7,5.3,1.4
	c0.2,0.4,0,1.2-0.3,1.4c-1.5,1-2.9,2-4.6,2.8c-2.5,1.2-5.3,1.4-8,1c-0.9-0.1-1.8,0.1-2.7,0c-0.5,0-0.9-0.4-1.4-0.6
	C350.6,90.2,350.6,90,350.7,89.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }} d="M338.8,93.5c-3.3,1-6.7,2.1-10.1,2.9c-1.7,0.4-3.4-0.3-4.8-1.5c-0.4-0.3-0.7-0.9-0.6-1.4
	c0.1-1.3,0.3-2.6,0.6-3.9c0.3-1,1.1-1.2,2.1-0.7c1.2,0.6,2.3,1.4,3.5,2c2.6,1.2,5.4,1.9,8.3,1.8c0.3,0,0.6,0.2,0.9,0.3
	C338.8,93.2,338.8,93.3,338.8,93.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }} d="M361.8,81.4c-1.3,2-3,3.6-5,4.7c-1.7,0.9-3.4,1.7-5.1,2.5c-1.8,0.7-1.9,0.7-2.9-1c1.9-3.1,4.4-5.6,7.4-7.6
	c0.4-0.3,1.1-0.3,1.7-0.3C359.4,79.7,360.6,80.5,361.8,81.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }} d="M341.8,94.9c-1.9,2.5-3.6,5.1-6.6,6.6c-0.8,0.4-1.6,0.5-2.5,0c-1.7-0.9-3.2-2-4.7-3.8c1.2-0.5,2.3-0.9,3.3-1.3
	c2-0.7,4-1.1,5.9-1.9C338.8,94,340.2,93.6,341.8,94.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity23 ? this.state.opacity23 : 1}
              fill={this.state.color23 ? this.state.color23 : 'transparent'} onClick={() => {
          this.setState({ color23: color, opacity23: opacity });
        }} d="M345.9,95.3c1.2-0.5,2-0.8,3-1.2c1.1,1.7,2.3,3.5,3.3,5.3c0.4,0.8,0.6,1.8,0.7,2.8c0.2,1-0.3,1.8-1.3,2
	c-1.4,0.2-2.8,0.3-4.1,0.2c-0.5-0.1-1.2-1-1.2-1.5C346.1,100.3,346.1,97.8,345.9,95.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }} d="M341.8,86.4c-0.2,0.2-0.3,0.2-0.4,0.3c-1.5,2.3-3.4,1.6-5,0.3c-2-1.6-4.3-3.1-4.8-5.9c-0.2-1,0-1.7,0.9-2
	c1.1-0.4,2.3-0.6,3.5-0.9c0.2,0,0.7,0.3,0.8,0.5C337.6,81.8,339.5,84.2,341.8,86.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1}
              fill={this.state.color25 ? this.state.color25 : 'transparent'} onClick={() => {
          this.setState({ color25: color, opacity25: opacity });
        }} d="M345.5,95.3c-0.1,2.4-0.2,4.7-0.4,7.1c-0.1,1.5-1.8,2.4-2.9,2.4c-1.3,0-2.5-0.3-3.7-0.6c-1-0.3-1.4-1.1-1-2.1
	c0.5-1,1.1-2,1.8-2.9c0.8-1.1,1.9-2.1,2.6-3.2c0.5-0.8,1.1-1.2,2-0.9C344.4,95.2,344.8,95.2,345.5,95.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1}
              fill={this.state.color26 ? this.state.color26 : 'transparent'} onClick={() => {
          this.setState({ color26: color, opacity26: opacity });
        }} d="M350.8,91.8c3.1,0,6.1,0,9.1,0c1.7,0,3.3,0,5,0.2c0.2,0,0.6,0.6,0.6,1c-0.1,2.8-3.2,4.9-5.7,3.8
	c-2.2-1-4.3-2.3-6.5-3.4c-0.8-0.4-1.5-0.8-2.3-1.2C350.9,92.1,350.8,91.9,350.8,91.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M349.4,93.8c0.5-1.2,1.5-0.9,2.1-0.5c2.4,1.4,4.7,2.8,7,4.4c1.5,1.1,1.5,1.8,0.3,3.2c-0.3,0.3-0.6,0.7-0.9,1
	c-0.8,0.8-1.9,1-2.7,0.3c-1-0.8-1.8-1.7-2.4-3c-0.7-1.5-1.7-2.9-2.6-4.3C350,94.5,349.7,94.1,349.4,93.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity28 ? this.state.opacity28 : 1}
              fill={this.state.color28 ? this.state.color28 : 'transparent'} onClick={() => {
          this.setState({ color28: color, opacity28: opacity });
        }} d="M348.3,86.8c-0.8-0.3-1.5-0.6-2.2-0.9c-0.7-0.2-0.9-1.7-0.5-2.7c0.6-1.6,1.2-3.3,1.8-4.9
	c0.1-0.3,0.6-0.7,0.9-0.7c1.4,0.2,2.7,0.5,4.1,0.8c0.9,0.2,0.6,0.9,0.4,1.5C351.6,82.5,350.3,84.9,348.3,86.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity29 ? this.state.opacity29 : 1}
              fill={this.state.color29 ? this.state.color29 : 'transparent'} onClick={() => {
          this.setState({ color29: color, opacity29: opacity });
        }} d="M342.6,77.1c2.4-0.1,2.5,0,2.4,2.3c-0.1,1.6,0,3.2-0.2,4.9c0,0.5-0.3,1.1-0.6,1.3c-0.7,0.4-1.5,0.7-2.1-0.1
	c-0.6-0.8-1.2-1.7-1.6-2.5c-0.3-0.6-0.4-1.3-0.6-1.9C339.2,78.1,338.8,77,342.6,77.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity30 ? this.state.opacity30 : 1}
              fill={this.state.color30 ? this.state.color30 : 'transparent'} onClick={() => {
          this.setState({ color30: color, opacity30: opacity });
        }} d="M339.7,112c-1.1-2.3-3-3.5-5.2-4.1c1.9,1.2,3.9,2.3,4.7,4.6c-6.1-1.4-8.3-3.5-7.8-7.8c0.3,0.2,0.6,0.3,0.8,0.5
	c1.2,1.4,2.9,1.5,4.5,1.9c1.2,0.3,2.1,1,2.5,2.2C339.5,110,340.6,110.7,339.7,112z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity31 ? this.state.opacity31 : 1}
              fill={this.state.color31 ? this.state.color31 : 'transparent'} onClick={() => {
          this.setState({ color31: color, opacity31: opacity });
        }} d="M352.9,111.7c-0.1-0.2-0.3-0.3-0.4-0.5c-1.4-1.8-2.2-2-4.3-1.2c-2,0.7-3.9,1.5-5.2,3.3c-0.1,0-0.2-0.1-0.3-0.1
	c0.1-0.5,0.1-1,0.3-1.4c1-1.9,2.6-3.4,4.5-4.1c1.5-0.5,3.2-0.4,4.8-0.5c0.6,0,1,0.4,1,1.1c0,1.1,0,2.2,0,3.3
	C353.1,111.6,353,111.7,352.9,111.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1}
              fill={this.state.color32 ? this.state.color32 : 'transparent'} onClick={() => {
          this.setState({ color32: color, opacity32: opacity });
        }} d="M342.4,115.7c-0.9,0.3-1.4,0-1.4-0.8c-0.1-2.8-0.1-5.5-0.1-8.3c0-0.6,0.3-1.2,1-0.9
	C342.1,109.1,341.3,112.4,342.4,115.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity33 ? this.state.opacity33 : 1}
              fill={this.state.color33 ? this.state.color33 : 'transparent'} onClick={() => {
          this.setState({ color33: color, opacity33: opacity });
        }} d="M292.9,88.5c-0.5,1-1,2-1.5,3c-3.4-0.4-6.9-1-9.8-3.2c-1-0.7-2-1.4-2.9-2.2c-0.8-0.7-0.9-1.7-0.1-2.4
	c1-0.9,2.1-1.7,3.3-2.4c0.4-0.2,1.3-0.1,1.5,0.2c2.1,3.3,5.4,4.9,8.7,6.6C292.4,88.1,292.6,88.3,292.9,88.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M298.7,93.7c-1.9-0.1-3.7-0.6-5.3-1.7c-0.9-0.6-1.1-1.2-0.4-2.1c1.2-1.5,2.3-3.2,4.3-3.8c2-0.6,3.9,0,5.2,1.7
	c0.2,0.3,0.4,0.7,0.7,0.9c1.7,1.5,1.3,2.5-0.6,3.8c-1.1,0.7-2.5,0.9-3.8,1.4C298.8,93.9,298.7,93.8,298.7,93.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity35 ? this.state.opacity35 : 1}
              fill={this.state.color35 ? this.state.color35 : 'transparent'} onClick={() => {
          this.setState({ color35: color, opacity35: opacity });
        }} d="M304.6,89.2c2.3-0.9,4.6-1.7,6.9-2.7c1.4-0.6,2.6-1.5,3.9-2.3c0.2-0.1,0.4-0.3,0.7-0.5c2.1-1.4,4.2-1,5.4,1.3
	c0.2,0.4,0.1,1.1-0.2,1.4c-1.4,1.6-3.3,2.2-5.2,3c-3.3,1.4-6.8,0.6-10.2,0.8c-0.4,0-0.9-0.4-1.3-0.6
	C304.5,89.5,304.6,89.4,304.6,89.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1}
              fill={this.state.color36 ? this.state.color36 : 'transparent'} onClick={() => {
          this.setState({ color36: color, opacity36: opacity });
        }} d="M292.7,92.9c-3.3,1-6.5,2.1-9.8,2.8c-1.7,0.4-3.5-0.3-4.9-1.4c-0.3-0.3-0.7-0.8-0.6-1.2
	c0.1-1.4,0.3-2.8,0.7-4.1c0.3-0.9,1.2-1.1,2.1-0.6c1.2,0.7,2.3,1.4,3.5,2c2.6,1.3,5.4,1.9,8.3,1.9c0.3,0,0.6,0.2,0.9,0.3
	C292.7,92.6,292.7,92.7,292.7,92.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity37 ? this.state.opacity37 : 1}
              fill={this.state.color37 ? this.state.color37 : 'transparent'} onClick={() => {
          this.setState({ color37: color, opacity37: opacity });
        }} d="M302.7,86.9c1.8-2.9,4.2-5.1,6.8-7.3c1-0.8,2-0.8,3-0.5c1.1,0.3,2.2,0.9,3.3,1.4c-1.4,2.2-3.1,3.8-5.2,4.9
	c-1.6,0.9-3.3,1.7-5.1,2.4C303.8,88.7,303.8,88.6,302.7,86.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity38 ? this.state.opacity38 : 1}
              fill={this.state.color38 ? this.state.color38 : 'transparent'} onClick={() => {
          this.setState({ color38: color, opacity38: opacity });
        }} d="M295.8,94.3c-2,2.7-3.9,5.5-7.1,6.9c-1.1,0.5-2-0.2-2.9-0.7c-0.9-0.6-1.8-1.3-2.7-2c-1.1-0.9-1-1.4,0.4-1.9
	c2.4-0.8,4.8-1.5,7.1-2.3c0.3-0.1,0.6-0.1,0.8-0.3C292.8,93.1,294.3,93.1,295.8,94.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity39 ? this.state.opacity39 : 1}
              fill={this.state.color39 ? this.state.color39 : 'transparent'} onClick={() => {
          this.setState({ color39: color, opacity39: opacity });
        }} d="M299.5,94.7c-0.1,2.4-0.2,4.9-0.5,7.3c-0.1,1.2-1.5,2.2-2.8,2.2c-1.3,0-2.6-0.4-3.9-0.7c-1-0.2-1.3-1.1-0.9-2
	c0.5-1,1.1-2,1.8-2.9c0.9-1.1,1.9-2.1,2.7-3.3c0.5-0.7,0.9-1.1,1.8-0.8C298.3,94.6,298.8,94.6,299.5,94.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity40 ? this.state.opacity40 : 1}
              fill={this.state.color40 ? this.state.color40 : 'transparent'} onClick={() => {
          this.setState({ color40: color, opacity40: opacity });
        }} d="M302.9,93.5c1.2,1.9,2.4,3.6,3.4,5.4c0.4,0.8,0.5,1.8,0.7,2.8c0.1,1-0.4,1.7-1.3,1.9c-1.4,0.2-2.8,0.3-4.1,0.2
	c-0.4,0-0.9-0.6-1.1-1c-0.3-0.6-0.4-1.2-0.5-1.8c-0.1-1.8,0-3.6,0-5.5c0-0.7,0.2-1.1,1-1.2C301.6,94.2,302.1,93.8,302.9,93.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity41 ? this.state.opacity41 : 1}
              fill={this.state.color41 ? this.state.color41 : 'transparent'} onClick={() => {
          this.setState({ color41: color, opacity41: opacity });
        }} d="M295.7,85.7c-0.1,0.2-0.1,0.2-0.1,0.3c-0.8,0.6-1.7,1.8-2.3,1.6c-1.5-0.4-2.9-1.3-4.2-2.1
	c-0.7-0.5-1.3-1.3-1.9-1.9c-1.1-1.1-1.5-2.4-1.6-3.9c0-0.2,0-0.5,0.2-0.7c0.4-0.6,3.6-2,4.5-1.5c0.2,0.2,0.6,0.3,0.7,0.6
	C291.6,81.2,293.4,83.6,295.7,85.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity42 ? this.state.opacity42 : 1}
              fill={this.state.color42 ? this.state.color42 : 'transparent'} onClick={() => {
          this.setState({ color42: color, opacity42: opacity });
        }} d="M304.7,91.2c3.9,0,7.6,0,11.3,0c0.8,0,1.6,0.2,2.4,0.1c0.9-0.1,1.1,0.3,1,1.1c-0.3,2.7-3.1,4.6-5.7,3.7
	c-1-0.3-2-0.8-2.9-1.4C308.9,93.7,306.8,92.5,304.7,91.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity43 ? this.state.opacity43 : 1}
              fill={this.state.color43 ? this.state.color43 : 'transparent'} onClick={() => {
          this.setState({ color43: color, opacity43: opacity });
        }} d="M303.4,93.1c0.5-1.2,1.5-0.9,2.1-0.5c2.4,1.4,4.7,2.8,7,4.4c1.5,1.1,1.5,1.8,0.3,3.2c-0.4,0.5-0.8,1.2-1.3,1.3
	c-0.7,0.2-1.8,0.3-2.3-0.1c-1-0.7-1.8-1.7-2.4-2.9c-0.8-1.7-2-3.3-3.1-5C303.6,93.4,303.5,93.3,303.4,93.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity44 ? this.state.opacity44 : 1}
              fill={this.state.color44 ? this.state.color44 : 'transparent'} onClick={() => {
          this.setState({ color44: color, opacity44: opacity });
        }} d="M302.2,86.3c-0.5-0.3-0.9-0.6-1.4-0.8c-0.4-0.2-1.3-0.4-1.3-0.6c-0.1-0.8,0-1.7,0.2-2.4
	c0.5-1.5,1.2-2.9,1.6-4.4c0.2-0.9,0.6-1.2,1.5-1c1.2,0.3,2.4,0.5,3.6,0.8c1,0.2,0.7,1,0.4,1.5C305.6,81.9,304.3,84.3,302.2,86.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity45 ? this.state.opacity45 : 1}
              fill={this.state.color45 ? this.state.color45 : 'transparent'} onClick={() => {
          this.setState({ color45: color, opacity45: opacity });
        }} d="M296.4,76.5c3.2,0,2.7-0.1,2.5,2.9c-0.1,1.5,0,3-0.1,4.5c0,0.4-0.2,0.9-0.5,1c-0.6,0.2-1.3,0.8-2,0.1
	c-0.8-0.8-1.6-1.5-1.9-2.7c-0.2-1.2-0.7-2.3-0.9-3.5c-0.4-1.8,0.1-2.3,1.9-2.4C295.7,76.5,296,76.5,296.4,76.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity46 ? this.state.opacity46 : 1}
              fill={this.state.color46 ? this.state.color46 : 'transparent'} onClick={() => {
          this.setState({ color46: color, opacity46: opacity });
        }} d="M288.4,107.2c1.9,1.2,3.9,2.2,4.8,4.7c-1.7-0.5-3.4-0.9-4.9-1.6c-2.1-1-3.2-3.6-2.9-6.3
	c0.3,0.2,0.6,0.3,0.8,0.5c1.2,1.5,3,1.5,4.6,1.9c1.1,0.3,1.9,1,2.3,2.1c0.3,0.9,1.4,1.6,0.6,2.9
	C292.6,109.1,290.8,107.8,288.4,107.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity47 ? this.state.opacity47 : 1}
              fill={this.state.color47 ? this.state.color47 : 'transparent'} onClick={() => {
          this.setState({ color47: color, opacity47: opacity });
        }} d="M307,111.3c-0.5-0.6-0.7-0.9-0.9-1.2c-1.3-1.4-2.1-1.6-3.9-0.9c-2,0.8-4,1.5-5.3,3.4c-0.1,0-0.2-0.1-0.3-0.1
	c0.1-0.5,0.2-1.1,0.4-1.5c1.4-2.9,6-5.1,9.1-4.5c0.7,0.2,1.1,0.5,1.1,1.3C307.1,108.9,307.1,109.9,307,111.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity48 ? this.state.opacity48 : 1}
              fill={this.state.color48 ? this.state.color48 : 'transparent'} onClick={() => {
          this.setState({ color48: color, opacity48: opacity });
        }} d="M295.8,105.1c0.2,3.4-0.5,6.7,0.5,10c-0.8,0.4-1.4,0.1-1.4-0.8c-0.1-2.7-0.1-5.5-0.1-8.2
	C294.9,105.5,295.1,104.9,295.8,105.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity49 ? this.state.opacity49 : 1}
              fill={this.state.color49 ? this.state.color49 : 'transparent'} onClick={() => {
          this.setState({ color49: color, opacity49: opacity });
        }} d="M108.8,87.4c-0.5,1-1,2-1.5,3.1c-4.8-0.4-9.2-2-12.7-5.5c-0.8-0.7-0.8-1.7,0-2.4c1-0.9,2.2-1.7,3.3-2.5
	c0.6-0.4,1.2-0.3,1.6,0.4c1.9,3.2,5.1,4.7,8.2,6.3C108,87.1,108.4,87.2,108.8,87.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity50 ? this.state.opacity50 : 1}
              fill={this.state.color50 ? this.state.color50 : 'transparent'} onClick={() => {
          this.setState({ color50: color, opacity50: opacity });
        }} d="M114.7,93c-2-0.4-3.8-0.9-5.4-1.9c-0.9-0.6-1.1-1.2-0.5-2.1c1.2-1.5,2.3-3.2,4.3-3.8c2-0.6,3.9,0,5.2,1.7
	c0.2,0.3,0.4,0.7,0.7,0.9c1.8,1.5,1.2,2.6-0.7,3.9C117.4,92.4,115.9,92.5,114.7,93z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity51 ? this.state.opacity51 : 1}
              fill={this.state.color51 ? this.state.color51 : 'transparent'} onClick={() => {
          this.setState({ color51: color, opacity51: opacity });
        }} d="M120.6,88.2c2.3-0.9,4.6-1.7,6.9-2.7c1.3-0.6,2.5-1.5,3.8-2.2c0.2-0.1,0.4-0.3,0.7-0.5c2.2-1.5,4.1-1,5.4,1.3
	c0.2,0.4,0.1,1.2-0.2,1.4c-1.1,0.9-2.3,1.7-3.6,2.3c-2.8,1.4-5.8,2-9,1.5c-0.9-0.1-1.9,0.1-2.9,0c-0.5,0-0.9-0.4-1.3-0.6
	C120.5,88.6,120.5,88.4,120.6,88.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity52 ? this.state.opacity52 : 1}
              fill={this.state.color52 ? this.state.color52 : 'transparent'} onClick={() => {
          this.setState({ color52: color, opacity52: opacity });
        }} d="M108.6,91.9c-3.3,1-6.7,2.1-10.1,2.9c-1.6,0.4-3.3-0.4-4.7-1.4c-0.3-0.2-0.7-0.8-0.6-1.1
	c0.1-1.4,0.3-2.9,0.7-4.3c0.3-1,1.2-1.1,2.1-0.6c1.2,0.7,2.4,1.5,3.6,2.1c2.5,1.2,5.2,1.8,8.1,1.8c0.3,0,0.6,0.1,0.9,0.2
	C108.6,91.6,108.6,91.7,108.6,91.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1}
              fill={this.state.color53 ? this.state.color53 : 'transparent'} onClick={() => {
          this.setState({ color53: color, opacity53: opacity });
        }} d="M131.6,79.9c-1.3,2-3,3.6-5,4.6c-1.7,0.9-3.4,1.7-5.2,2.4c-1.8,0.7-1.8,0.6-2.9-1c1.6-2,3-4.2,5.1-5.8
	c0.2-0.1,0.4-0.3,0.6-0.5C126.6,77.5,129.1,77.6,131.6,79.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity54 ? this.state.opacity54 : 1}
              fill={this.state.color54 ? this.state.color54 : 'transparent'} onClick={() => {
          this.setState({ color54: color, opacity54: opacity });
        }} d="M111.7,93.2c-1.9,2.5-3.6,4.9-6.2,6.5c-1.2,0.7-2.3,0.8-3.4,0.1c-1.4-1-2.8-2.1-4.1-3.1c0-0.2,0.1-0.4,0.1-0.6
	c1-0.4,2.1-0.8,3.1-1.2c2.2-0.8,4.5-1.5,6.7-2.3C109.2,92.3,109.3,92.2,111.7,93.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity55 ? this.state.opacity55 : 1}
              fill={this.state.color55 ? this.state.color55 : 'transparent'} onClick={() => {
          this.setState({ color55: color, opacity55: opacity });
        }} d="M118.8,92.5c1.1,1.7,2.2,3.4,3.3,5.2c0.4,0.6,0.5,1.4,0.7,2.1c0.4,1.8-0.2,2.7-2.1,3c-0.5,0.1-1.1,0.1-1.7,0.1
	c-2.1,0-2.9-0.6-3.1-2.7c-0.1-2-0.1-4,0-6c0-0.3,0.4-0.7,0.7-0.9C117.3,93.1,118,92.9,118.8,92.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity56 ? this.state.opacity56 : 1}
              fill={this.state.color56 ? this.state.color56 : 'transparent'} onClick={() => {
          this.setState({ color56: color, opacity56: opacity });
        }} d="M111.7,84.8c-0.2,0.2-0.3,0.2-0.3,0.3c-1.5,2.1-3.2,1.7-4.9,0.4c-2.1-1.6-4.4-3.1-4.9-6.1
	c-0.2-0.9,0-1.6,0.9-1.9c0.8-0.3,1.6-0.7,2.4-0.9c1-0.2,1.8-0.1,2.1,1.1C107.6,80.6,109.5,82.7,111.7,84.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity57 ? this.state.opacity57 : 1}
              fill={this.state.color57 ? this.state.color57 : 'transparent'} onClick={() => {
          this.setState({ color57: color, opacity57: opacity });
        }} d="M115,93.6c0,2.4,0.1,4.7,0,6.9c-0.1,1.7-1.6,2.6-3,2.6c-1.2,0-2.5-0.3-3.7-0.7c-1-0.2-1.4-1.1-1-2
	c0.5-1,1.1-2,1.8-2.9c0.9-1.2,2-2.3,2.8-3.5c0.4-0.6,0.8-0.9,1.6-0.7C114,93.6,114.6,93.6,115,93.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity58 ? this.state.opacity58 : 1}
              fill={this.state.color58 ? this.state.color58 : 'transparent'} onClick={() => {
          this.setState({ color58: color, opacity58: opacity });
        }} d="M120.6,90.2c3.8,0,7.6,0,11.4,0c0.8,0,1.6,0.2,2.4,0.1c0.9-0.1,1.1,0.4,1,1.1c-0.4,2.8-3.2,4.7-5.8,3.7
	c-2.3-1-4.4-2.3-6.6-3.5C122.2,91.2,121.4,90.7,120.6,90.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity59 ? this.state.opacity59 : 1}
              fill={this.state.color59 ? this.state.color59 : 'transparent'} onClick={() => {
          this.setState({ color59: color, opacity59: opacity });
        }} d="M119.3,92.2c0.5-1.2,1.4-0.9,2-0.6c2.4,1.4,4.8,2.8,7.1,4.4c1.5,1.1,1.5,1.8,0.3,3.2c-0.3,0.3-0.5,0.6-0.8,0.9
	c-0.8,0.9-2,1.1-2.9,0.3c-0.9-0.8-1.8-1.7-2.3-2.9c-0.7-1.5-1.8-2.9-2.7-4.4C119.8,92.8,119.5,92.5,119.3,92.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity60 ? this.state.opacity60 : 1}
              fill={this.state.color60 ? this.state.color60 : 'transparent'} onClick={() => {
          this.setState({ color60: color, opacity60: opacity });
        }} d="M118,85.3c-0.5-0.3-0.9-0.6-1.3-0.8c-0.5-0.2-1.3-0.4-1.4-0.7c-0.1-0.7,0-1.6,0.2-2.3c0.5-1.6,1.2-3,1.6-4.6
	c0.2-0.7,0.5-1,1.2-0.9c1.3,0.3,2.6,0.5,3.9,0.8c1,0.3,0.6,1,0.4,1.6C121.4,80.9,120.1,83.4,118,85.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity61 ? this.state.opacity61 : 1}
              fill={this.state.color61 ? this.state.color61 : 'transparent'} onClick={() => {
          this.setState({ color61: color, opacity61: opacity });
        }} d="M112.2,75.5c2.8-0.1,2.9,0,2.6,2.8c-0.1,1.3,0,2.5-0.1,3.8c-0.1,0.6-0.1,1.5-0.5,1.9c-0.8,0.6-1.7,0.5-2.5-0.4
	c-1.8-1.9-1.9-4.4-2.3-6.7c-0.1-0.3,0.7-1,1.2-1.2C111,75.4,111.7,75.5,112.2,75.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity62 ? this.state.opacity62 : 1}
              fill={this.state.color62 ? this.state.color62 : 'transparent'} onClick={() => {
          this.setState({ color62: color, opacity62: opacity });
        }} d="M109.6,110.3c-1.1-2.3-3-3.5-5.2-4.1c1.9,1.2,3.9,2.2,4.7,4.5c-5.3-0.6-8-3.2-7.8-7.8c0.2,0.1,0.4,0.1,0.5,0.3
	c1.3,1.5,3,1.8,4.8,2.1c1.3,0.3,2.1,1.1,2.6,2.3c0.3,0.8,0.7,1.6,1,2.4C109.9,110.1,109.8,110.2,109.6,110.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity63 ? this.state.opacity63 : 1}
              fill={this.state.color63 ? this.state.color63 : 'transparent'} onClick={() => {
          this.setState({ color63: color, opacity63: opacity });
        }} d="M122.9,110.4c-0.4-0.5-0.5-0.7-0.7-0.9c-1.3-1.7-2.1-1.8-4.1-1.1c-2,0.7-4,1.6-5.3,3.4c-0.1,0-0.2-0.1-0.3-0.1
	c0.1-0.5,0.1-1,0.3-1.4c1-1.9,2.5-3.3,4.4-4c1.5-0.6,3.2-0.4,4.8-0.5c0.7,0,1.1,0.5,1.1,1.3C123,107.9,123,109,122.9,110.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity64 ? this.state.opacity64 : 1}
              fill={this.state.color64 ? this.state.color64 : 'transparent'} onClick={() => {
          this.setState({ color64: color, opacity64: opacity });
        }} d="M111.7,104.2c0,1.8,0,3.4,0,5.1c0,1.6,0.1,3.1,0.2,4.9c-0.5,0.2-1.1,0-1.1-0.8c-0.1-2.8-0.1-5.6-0.1-8.4
	C110.8,104.2,110.9,104.2,111.7,104.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity65 ? this.state.opacity65 : 1}
              fill={this.state.color65 ? this.state.color65 : 'transparent'} onClick={() => {
          this.setState({ color65: color, opacity65: opacity });
        }} d="M200.8,88.5c-0.5,1-1,1.9-1.5,2.9c-3.1-0.2-6.2-1-9-2.6c-1.3-0.7-2.5-1.8-3.7-2.8c-0.9-0.7-0.9-1.7-0.1-2.5
	c1-0.9,2.1-1.7,3.3-2.4c0.3-0.2,1.3,0,1.5,0.3c2.1,3.2,5.3,4.8,8.5,6.5c0.2,0.1,0.5,0.3,0.7,0.4C200.7,88.4,200.7,88.5,200.8,88.5z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity66 ? this.state.opacity66 : 1}
              fill={this.state.color66 ? this.state.color66 : 'transparent'} onClick={() => {
          this.setState({ color66: color, opacity66: opacity });
        }} d="M206.8,93.9c-2.1-0.3-3.9-0.9-5.6-2c-0.8-0.5-1-1.1-0.4-1.9c1.2-1.6,2.3-3.3,4.4-3.9c2-0.6,3.9,0,5.2,1.7
	c0.2,0.3,0.4,0.7,0.7,0.9c1.7,1.4,1.3,2.5-0.7,3.9C209.4,93.4,208,93.5,206.8,93.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity67 ? this.state.opacity67 : 1}
              fill={this.state.color67 ? this.state.color67 : 'transparent'} onClick={() => {
          this.setState({ color67: color, opacity67: opacity });
        }} d="M212.2,89.3c2.7-1,5.1-1.9,7.4-2.9c1.3-0.5,2.4-1.4,3.6-2.1c0.3-0.2,0.5-0.4,0.8-0.6c2.1-1.4,4.2-0.9,5.4,1.3
	c0.2,0.3,0.1,1.2-0.2,1.3c-1.5,1-3,2-4.6,2.9c-3.6,1.8-7.5,0.9-11.2,1C213.1,90.3,212.8,89.8,212.2,89.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity68 ? this.state.opacity68 : 1}
              fill={this.state.color68 ? this.state.color68 : 'transparent'} onClick={() => {
          this.setState({ color68: color, opacity68: opacity });
        }} d="M200.6,92.9c-3.3,1-6.6,2.1-10,2.9c-1.6,0.4-3.3-0.4-4.7-1.4c-0.3-0.3-0.7-0.8-0.6-1.2c0.1-1.4,0.2-2.8,0.6-4.1
	c0.3-0.9,1.1-1.3,2-0.7c1.2,0.7,2.3,1.4,3.5,2c2.6,1.3,5.4,1.9,8.4,1.9c0.3,0,0.5,0.2,0.8,0.2C200.6,92.6,200.6,92.7,200.6,92.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity69 ? this.state.opacity69 : 1}
              fill={this.state.color69 ? this.state.color69 : 'transparent'} onClick={() => {
          this.setState({ color69: color, opacity69: opacity });
        }} d="M210.7,87c1.8-2.9,4.2-5.1,6.7-7.2c1-0.9,2.1-0.9,3.2-0.5c1.1,0.3,2.1,0.9,3.2,1.4c-1.3,2-2.8,3.6-4.7,4.6
	c-1.8,1-3.7,1.9-5.6,2.7C211.8,88.7,211.7,88.6,210.7,87z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity70 ? this.state.opacity70 : 1}
              fill={this.state.color70 ? this.state.color70 : 'transparent'} onClick={() => {
          this.setState({ color70: color, opacity70: opacity });
        }} d="M203.7,94.3c-1.9,2.6-3.8,5.3-6.9,6.8c-1.1,0.5-2.1,0-2.9-0.5c-1-0.6-2-1.4-2.9-2.2c-0.9-0.8-0.8-1.4,0.4-1.8
	c2.4-0.8,4.8-1.5,7.1-2.3c0.3-0.1,0.6-0.1,0.9-0.3C200.8,93.1,202.3,93,203.7,94.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity71 ? this.state.opacity71 : 1}
              fill={this.state.color71 ? this.state.color71 : 'transparent'} onClick={() => {
          this.setState({ color71: color, opacity71: opacity });
        }} d="M207.4,94.6c-0.1,2.4-0.2,4.7-0.3,7c-0.1,1.2-1,2-2,2.3c-1.8,0.6-3.7,0.2-5.3-0.8c-0.3-0.2-0.6-1.1-0.4-1.4
	c0.5-1.1,1.2-2.2,1.9-3.1c0.8-1.1,1.9-2.1,2.7-3.3c0.5-0.8,1-1,1.8-0.8C206.2,94.6,206.7,94.6,207.4,94.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity72 ? this.state.opacity72 : 1}
              fill={this.state.color72 ? this.state.color72 : 'transparent'} onClick={() => {
          this.setState({ color72: color, opacity72: opacity });
        }} d="M208,94.6c1.1-0.4,1.9-0.8,2.9-1.1c1.1,1.8,2.3,3.6,3.4,5.4c0.4,0.8,0.5,1.8,0.6,2.8c0.1,1-0.4,1.7-1.3,1.8
	c-1.3,0.2-2.7,0.2-4,0.2c-0.4,0-0.8-0.5-1-0.8c-0.3-0.5-0.5-1.1-0.5-1.7C208,99,208,96.8,208,94.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity73 ? this.state.opacity73 : 1}
              fill={this.state.color73 ? this.state.color73 : 'transparent'} onClick={() => {
          this.setState({ color73: color, opacity73: opacity });
        }} d="M203.7,85.7c-0.2,0.2-0.3,0.2-0.3,0.3c-2.3,1.9-2.4,2.1-5,0.3c-2.2-1.5-4.4-3.1-4.9-6.1c-0.1-0.8,0-1.4,0.8-1.7
	c0.9-0.4,1.8-0.8,2.8-1.1c0.8-0.2,1.7-0.1,1.9,1C199.6,81.5,201.5,83.7,203.7,85.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity74 ? this.state.opacity74 : 1}
              fill={this.state.color74 ? this.state.color74 : 'transparent'} onClick={() => {
          this.setState({ color74: color, opacity74: opacity });
        }} d="M212.8,91.3c4.8,0,9.7,0,14.5,0c0.5,3.4-2.6,6-5.6,4.9c-1.8-0.7-3.4-1.8-5.1-2.7C215.2,92.8,214,92,212.8,91.3z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity75 ? this.state.opacity75 : 1}
              fill={this.state.color75 ? this.state.color75 : 'transparent'} onClick={() => {
          this.setState({ color75: color, opacity75: opacity });
        }} d="M211.4,93.1c0.5-1.2,1.5-0.9,2.1-0.5c2.4,1.4,4.7,2.8,6.9,4.4c1.5,1.1,1.5,1.8,0.3,3.2
	c-0.4,0.5-0.8,1.2-1.3,1.3c-0.7,0.2-1.7,0.3-2.3-0.1c-1-0.7-1.8-1.7-2.4-2.9c-0.8-1.7-2-3.3-3.1-5C211.6,93.4,211.5,93.3,211.4,93.1
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity76 ? this.state.opacity76 : 1}
              fill={this.state.color76 ? this.state.color76 : 'transparent'} onClick={() => {
          this.setState({ color76: color, opacity76: opacity });
        }} d="M210.1,86.2c-0.7-0.3-1.4-0.6-2.1-0.8c-0.7-0.3-0.9-1.7-0.5-2.7c0.6-1.7,1.2-3.4,1.9-5c0.1-0.3,0.6-0.6,0.8-0.6
	c1.4,0.2,2.8,0.5,4.2,0.9c0.8,0.2,0.6,0.8,0.4,1.4C214.1,81.2,211.7,84.8,210.1,86.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity77 ? this.state.opacity77 : 1}
              fill={this.state.color77 ? this.state.color77 : 'transparent'} onClick={() => {
          this.setState({ color77: color, opacity77: opacity });
        }} d="M204.3,76.5c2.7-0.1,2.8-0.1,2.6,2.4c-0.1,1.7-0.1,3.3-0.2,5c0,0.4-0.2,0.9-0.5,1c-0.6,0.2-1.3,0.8-2,0.1
	c-0.8-0.8-1.6-1.5-1.9-2.7c-0.2-1.1-0.6-2.2-0.9-3.3c-0.4-1.9,0-2.5,2-2.5C203.7,76.5,204,76.5,204.3,76.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity78 ? this.state.opacity78 : 1}
              fill={this.state.color78 ? this.state.color78 : 'transparent'} onClick={() => {
          this.setState({ color78: color, opacity78: opacity });
        }} d="M196.3,107.2c1.9,1.2,4,2.3,4.8,4.5c-5.5-0.7-7.9-3-7.9-7.6c0.1,0,0.2-0.1,0.2-0.1c1.4,1.5,3.4,1.9,5.3,2.5
	c0.9,0.3,1.8,1.1,2.3,1.9c0.5,0.8,1.5,1.6,0.7,2.9C200.5,109.1,198.7,107.8,196.3,107.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity79 ? this.state.opacity79 : 1}
              fill={this.state.color79 ? this.state.color79 : 'transparent'} onClick={() => {
          this.setState({ color79: color, opacity79: opacity });
        }} d="M204.5,112.5c0.2-0.5,0.2-1.2,0.5-1.6c1.2-1.3,2.4-2.9,4-3.7c1.5-0.7,3.4-0.5,5.1-0.7c0.7,0,1.1,0.4,1,1.2
	c0,1.1,0,2.2,0,3.5c-0.8-0.8-1.4-1.5-2.1-2c-0.4-0.3-1.2-0.6-1.6-0.4c-2.4,0.8-4.9,1.5-6.5,3.8C204.8,112.6,204.6,112.6,204.5,112.5
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity80 ? this.state.opacity80 : 1}
              fill={this.state.color80 ? this.state.color80 : 'transparent'} onClick={() => {
          this.setState({ color80: color, opacity80: opacity });
        }} d="M203.8,105.1c0.2,3.4-0.5,6.7,0.5,10c-0.9,0.4-1.4-0.1-1.4-0.9c-0.1-2.7-0.1-5.5,0-8.2
	C202.8,105.4,203.1,104.9,203.8,105.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity81 ? this.state.opacity81 : 1}
              fill={this.state.color81 ? this.state.color81 : 'transparent'} onClick={() => {
          this.setState({ color81: color, opacity81: opacity });
        }} d="M246.9,88.4c-0.5,1.1-1,2-1.5,3c-3.1-0.2-6.2-1-8.9-2.6c-1.4-0.8-2.6-1.9-3.9-3c-0.7-0.6-0.8-1.6-0.1-2.2
	c1.1-1,2.3-1.8,3.5-2.6c0.3-0.2,1.2,0,1.4,0.3c2.1,3.4,5.5,5,8.8,6.7C246.4,88.2,246.6,88.3,246.9,88.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity82 ? this.state.opacity82 : 1}
              fill={this.state.color82 ? this.state.color82 : 'transparent'} onClick={() => {
          this.setState({ color82: color, opacity82: opacity });
        }} d="M252.9,93.7c-2.2-0.1-4-0.6-5.6-1.7c-0.9-0.6-1.1-1.2-0.4-2.1c1.2-1.5,2.3-3.1,4.2-3.7c2.1-0.6,4,0,5.4,1.8
	c0.1,0.2,0.2,0.3,0.3,0.5c0.5,0.8,1.7,1.3,1.2,2.4c-0.6,1.4-1.8,2.2-3.2,2.6C254.2,93.6,253.4,93.6,252.9,93.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity83 ? this.state.opacity83 : 1}
              fill={this.state.color83 ? this.state.color83 : 'transparent'} onClick={() => {
          this.setState({ color83: color, opacity83: opacity });
        }} d="M258.2,89.3c2.7-1,5.1-1.9,7.4-2.9c1.3-0.5,2.4-1.4,3.6-2.1c0.3-0.2,0.5-0.4,0.8-0.6c2.1-1.4,4.2-0.9,5.4,1.3
	c0.2,0.3,0.1,1-0.1,1.4c-1.2,1.5-3,2.1-4.7,2.9c-3.6,1.6-7.4,0.9-11.1,0.9C259.1,90.3,258.8,89.8,258.2,89.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity84 ? this.state.opacity84 : 1}
              fill={this.state.color84 ? this.state.color84 : 'transparent'} onClick={() => {
          this.setState({ color84: color, opacity84: opacity });
        }} d="M246.7,92.9c-3.3,1-6.7,2.1-10,2.9c-1.6,0.4-3.3-0.4-4.7-1.4c-0.3-0.2-0.6-0.8-0.6-1.1c0.1-1.4,0.3-2.9,0.7-4.3
	c0.3-1,1.2-1.1,2.1-0.6c1.2,0.7,2.4,1.5,3.7,2.1c2.5,1.1,5.2,1.7,8,1.7c0.3,0,0.6,0.1,0.9,0.2C246.6,92.5,246.6,92.7,246.7,92.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity85 ? this.state.opacity85 : 1}
              fill={this.state.color85 ? this.state.color85 : 'transparent'} onClick={() => {
          this.setState({ color85: color, opacity85: opacity });
        }} d="M269.9,80.6c-2.9,4.1-6.9,6.2-11.2,7.6c-1.3,0.4-1.5-0.7-2-1.3c1.8-2.9,4.2-5.2,6.8-7.3c1-0.8,2-0.8,3-0.5
	C267.7,79.5,268.7,80.1,269.9,80.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity86 ? this.state.opacity86 : 1}
              fill={this.state.color86 ? this.state.color86 : 'transparent'} onClick={() => {
          this.setState({ color86: color, opacity86: opacity });
        }} d="M249.8,94.2c-2,2.7-3.8,5.4-7,6.8c-0.4,0.2-1,0.3-1.4,0.2c-2.1-0.7-3.7-2.1-5.4-4c0.7-0.3,1.1-0.6,1.6-0.8
	c2.9-1,5.8-1.8,8.6-2.9C247.6,93,248.6,93.6,249.8,94.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity87 ? this.state.opacity87 : 1}
              fill={this.state.color87 ? this.state.color87 : 'transparent'} onClick={() => {
          this.setState({ color87: color, opacity87: opacity });
        }} d="M253.4,94.7c-0.1,2.4-0.2,4.7-0.3,7c-0.1,1.3-1.1,2.1-2.2,2.4c-1.7,0.4-3.4,0.1-5-0.8c-0.4-0.2-0.7-1.2-0.5-1.6
	c0.4-1,1-2,1.7-2.8c1-1.3,2.1-2.6,3.1-3.9c0.2-0.3,0.7-0.4,1.1-0.4C251.9,94.4,252.6,94.5,253.4,94.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity88 ? this.state.opacity88 : 1}
              fill={this.state.color88 ? this.state.color88 : 'transparent'} onClick={() => {
          this.setState({ color88: color, opacity88: opacity });
        }} d="M254,94.7c1-0.4,1.9-0.8,2.8-1.1c1.5,2.3,3.4,4.2,4,7c0.4,2.2-0.2,3.1-2.3,3.4c-0.4,0-0.8,0.1-1.2,0.1
	c-2.3,0.1-3.1-0.5-3.2-2.8C253.9,99,254,96.8,254,94.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity89 ? this.state.opacity89 : 1}
              fill={this.state.color89 ? this.state.color89 : 'transparent'} onClick={() => {
          this.setState({ color89: color, opacity89: opacity });
        }} d="M249.8,85.7c-0.2,0.2-0.3,0.3-0.3,0.3c-0.9,0.4-1.1,1.9-2.3,1.6c-0.9-0.2-1.9-0.6-2.6-1.2
	c-2.1-1.6-4.5-3.1-4.9-6.1c-0.1-0.9,0-1.6,0.9-1.8c1.2-0.4,2.4-0.7,3.6-0.9c0.2,0,0.8,0.4,0.8,0.7C245.6,81.3,247.5,83.6,249.8,85.7
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity90 ? this.state.opacity90 : 1}
              fill={this.state.color90 ? this.state.color90 : 'transparent'} onClick={() => {
          this.setState({ color90: color, opacity90: opacity });
        }} d="M273.3,91.3c0.4,3.5-2.6,6.1-5.6,4.9c-1.8-0.7-3.4-1.8-5.1-2.7c-1.3-0.7-2.5-1.5-3.8-2.2
	C263.6,91.3,268.4,91.3,273.3,91.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity91 ? this.state.opacity91 : 1}
              fill={this.state.color91 ? this.state.color91 : 'transparent'} onClick={() => {
          this.setState({ color91: color, opacity91: opacity });
        }} d="M257.4,93.2c0.5-1.2,1.4-1,2.1-0.6c2.4,1.4,4.7,2.8,7,4.4c1.6,1.1,1.6,1.8,0.3,3.3c-0.3,0.3-0.5,0.6-0.8,0.9
	c-0.7,0.8-1.9,1-2.7,0.3c-1-0.8-1.9-1.7-2.5-3c-0.7-1.5-1.8-2.9-2.7-4.4C257.9,93.8,257.6,93.5,257.4,93.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity92 ? this.state.opacity92 : 1}
              fill={this.state.color92 ? this.state.color92 : 'transparent'} onClick={() => {
          this.setState({ color92: color, opacity92: opacity });
        }} d="M256.1,86.3c-0.5-0.3-0.9-0.6-1.3-0.8c-0.5-0.3-1.3-0.4-1.4-0.7c-0.1-0.7,0-1.6,0.2-2.3c0.5-1.6,1.2-3,1.6-4.6
	c0.2-0.7,0.5-1,1.2-0.9c1.2,0.3,2.5,0.5,3.7,0.8c0.9,0.2,0.8,0.9,0.5,1.5C259.6,81.9,258.2,84.3,256.1,86.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity93 ? this.state.opacity93 : 1}
              fill={this.state.color93 ? this.state.color93 : 'transparent'} onClick={() => {
          this.setState({ color93: color, opacity93: opacity });
        }} d="M250.4,76.5c2.6-0.1,2.7,0,2.5,2.4c-0.1,1.7-0.1,3.4-0.2,5.1c0,0.9-1.4,1.8-2.1,1.3c-0.8-0.6-1.6-1.5-2-2.4
	c-0.6-1.2-0.8-2.6-1.1-3.9c-0.4-1.9,0-2.4,1.9-2.5C249.8,76.5,250.1,76.5,250.4,76.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity94 ? this.state.opacity94 : 1}
              fill={this.state.color94 ? this.state.color94 : 'transparent'} onClick={() => {
          this.setState({ color94: color, opacity94: opacity });
        }} d="M247.1,111.8c-1.4-0.4-2.8-0.7-4.1-1.2c-2.1-0.7-3.2-2.3-3.6-4.4c-0.1-0.7-0.2-1.4-0.3-2.3
	c1.1,0.7,1.9,1.4,2.9,1.8c0.9,0.4,1.9,0.5,2.9,0.7c1.7,0.5,3.2,2.9,2.9,5c-1.3-2.3-3.1-3.7-5.4-4.2
	C244.3,108.4,246.3,109.4,247.1,111.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity95 ? this.state.opacity95 : 1}
              fill={this.state.color95 ? this.state.color95 : 'transparent'} onClick={() => {
          this.setState({ color95: color, opacity95: opacity });
        }} d="M260.8,111.1c-0.2-0.3-0.4-0.5-0.6-0.8c-1.3-1.6-2.1-1.8-4-1c-1,0.4-2,0.8-2.9,1.3c-0.9,0.5-1.7,1.3-2.8,2.2
	c0.2-0.8,0.3-1.4,0.5-1.8c1.4-2.9,5.9-5,9.1-4.4c0.7,0.1,1.1,0.5,1.1,1.3c-0.1,1.1,0,2.1,0,3.2C261,111.1,260.9,111.1,260.8,111.1z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity96 ? this.state.opacity96 : 1}
              fill={this.state.color96 ? this.state.color96 : 'transparent'} onClick={() => {
          this.setState({ color96: color, opacity96: opacity });
        }} d="M249.8,104.8c0.2,3.7-0.5,7,0.5,10.2c-0.8,0.4-1.4,0.1-1.4-0.8c-0.1-2.8-0.1-5.6,0-8.4
	C248.9,105.6,249.4,105.3,249.8,104.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity97 ? this.state.opacity97 : 1}
              fill={this.state.color97 ? this.state.color97 : 'transparent'} onClick={() => {
          this.setState({ color97: color, opacity97: opacity });
        }} d="M16.7,87.5c-0.5,1-1,1.9-1.5,3c-2.4,0.2-4.8-0.6-7-1.6c-2-0.9-3.8-2.4-5.6-3.7c-1-0.8-1.1-1.8-0.1-2.6
	c1-0.9,2.1-1.7,3.2-2.4C6.1,80,7,80.1,7.2,80.4c2.1,3.4,5.6,5,8.9,6.7C16.3,87.2,16.5,87.3,16.7,87.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity98 ? this.state.opacity98 : 1}
              fill={this.state.color98 ? this.state.color98 : 'transparent'} onClick={() => {
          this.setState({ color98: color, opacity98: opacity });
        }} d="M22.6,93c-2-0.4-3.8-0.9-5.4-1.9c-0.9-0.6-1.1-1.2-0.4-2.1c1.2-1.5,2.3-3.2,4.3-3.8c2-0.6,3.9,0,5.2,1.7
	c0.2,0.3,0.4,0.7,0.7,0.9c1.7,1.4,1.4,2.4-0.7,3.9C25.3,92.4,23.9,92.5,22.6,93z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity99 ? this.state.opacity99 : 1}
              fill={this.state.color99 ? this.state.color99 : 'transparent'} onClick={() => {
          this.setState({ color99: color, opacity99: opacity });
        }} d="M28.5,88.2c2.3-0.9,4.6-1.7,6.8-2.6c1.4-0.6,2.7-1.5,4-2.3c0.3-0.2,0.5-0.4,0.8-0.6c2-1.3,4.2-0.8,5.3,1.4
	c0.2,0.3,0.1,1.2-0.2,1.3c-1.5,1-3,2.1-4.6,2.9c-3.6,1.8-7.5,0.9-11.2,1c-0.3,0-0.6-0.4-0.9-0.7C28.4,88.5,28.4,88.4,28.5,88.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity100 ? this.state.opacity100 : 1}
              fill={this.state.color100 ? this.state.color100 : 'transparent'} onClick={() => {
          this.setState({ color100: color, opacity100: opacity });
        }} d="M16.5,91.9c-3.3,1-6.7,2.1-10.1,2.9c-1.6,0.4-3.3-0.3-4.7-1.4c-0.4-0.3-0.7-1-0.7-1.6c0.1-1.2,0.2-2.5,0.6-3.6
	C2,87,2.8,86.8,3.9,87.4C5,88,6.1,88.7,7.3,89.3c2.6,1.3,5.4,1.9,8.4,1.9c0.3,0,0.6,0.2,0.8,0.3C16.5,91.6,16.5,91.8,16.5,91.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity101 ? this.state.opacity101 : 1}
              fill={this.state.color101 ? this.state.color101 : 'transparent'} onClick={() => {
          this.setState({ color101: color, opacity101: opacity });
        }} d="M39.7,79.6c-1.5,2.3-3.2,3.9-5.4,5c-1.6,0.9-3.3,1.6-5,2.3c-1.7,0.7-1.7,0.6-2.9-1c1.9-3.1,4.5-5.6,7.5-7.7
	c0.5-0.4,1.5-0.3,2.2-0.2C37.4,78.5,38.5,79.1,39.7,79.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity102 ? this.state.opacity102 : 1}
              fill={this.state.color102 ? this.state.color102 : 'transparent'} onClick={() => {
          this.setState({ color102: color, opacity102: opacity });
        }} d="M19.6,93.3c-1.9,2.5-3.7,5.1-6.6,6.7c-1.3,0.7-2.3,0.1-3.3-0.5c-1-0.6-1.9-1.4-2.8-2.1c-1-0.8-0.9-1.4,0.4-1.8
	c2.4-0.8,4.8-1.5,7.2-2.3c0.2-0.1,0.5-0.1,0.7-0.2C16.5,92.3,18,92,19.6,93.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity103 ? this.state.opacity103 : 1}
              fill={this.state.color103 ? this.state.color103 : 'transparent'} onClick={() => {
          this.setState({ color103: color, opacity103: opacity });
        }} d="M23.3,93.7c-0.1,2.4-0.2,4.7-0.3,7c-0.1,1.4-1.1,2.3-2.3,2.3c-1.6,0.1-3.2-0.3-4.8-0.7
	c-0.8-0.2-0.9-1.1-0.6-1.7c0.5-1,1.1-2,1.8-2.9c0.9-1.2,2-2.4,2.9-3.6c0.4-0.5,0.7-0.8,1.4-0.6C21.9,93.5,22.5,93.6,23.3,93.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity104 ? this.state.opacity104 : 1}
              fill={this.state.color104 ? this.state.color104 : 'transparent'} onClick={() => {
          this.setState({ color104: color, opacity104: opacity });
        }} d="M23.8,93.7c1.1-0.4,1.9-0.7,2.8-1.1c1.4,2.2,3.4,4.1,4,6.9c0.4,2.2-0.1,3.1-2.3,3.4c-3.6,0.5-4.5-0.4-4.5-4
	C23.8,97.1,23.8,95.3,23.8,93.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity105 ? this.state.opacity105 : 1}
              fill={this.state.color105 ? this.state.color105 : 'transparent'} onClick={() => {
          this.setState({ color105: color, opacity105: opacity });
        }} d="M19.6,84.8c-0.2,0.2-0.3,0.2-0.3,0.3c-1.5,2.2-3.2,1.7-4.9,0.4c-2.1-1.6-4.4-3.1-4.9-6.1
	c-0.1-0.9,0-1.5,0.8-1.9c0.9-0.4,1.8-0.8,2.7-1c0.8-0.2,1.7-0.1,1.9,1C15.5,80.5,17.5,82.7,19.6,84.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity106 ? this.state.opacity106 : 1}
              fill={this.state.color106 ? this.state.color106 : 'transparent'} onClick={() => {
          this.setState({ color106: color, opacity106: opacity });
        }} d="M28.7,90.3c4.8,0,9.6,0,14.4,0c0.7,3.2-2.6,6.1-5.5,4.9c-2.3-0.9-4.4-2.3-6.6-3.5
	C30.2,91.3,29.4,90.8,28.7,90.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity107 ? this.state.opacity107 : 1}
              fill={this.state.color107 ? this.state.color107 : 'transparent'} onClick={() => {
          this.setState({ color107: color, opacity107: opacity });
        }} d="M27.2,92.3c0.5-1.3,1.5-1,2.2-0.7c2.4,1.4,4.7,2.8,7,4.4c1.6,1.1,1.5,1.8,0.3,3.3c-1.9,2.2-3,2.2-4.8-0.2
	C30.1,96.9,29.1,94.2,27.2,92.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity108 ? this.state.opacity108 : 1}
              fill={this.state.color108 ? this.state.color108 : 'transparent'} onClick={() => {
          this.setState({ color108: color, opacity108: opacity });
        }} d="M26,85.2c-0.7-0.3-1.4-0.6-2.1-0.8c-0.7-0.3-0.9-1.8-0.5-2.8c0.6-1.6,1.1-3.2,1.8-4.8c0.1-0.3,0.7-0.7,0.9-0.7
	c1.4,0.2,2.7,0.5,4.1,0.8c0.8,0.2,0.6,0.8,0.4,1.4C30,80.3,27.6,83.8,26,85.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity109 ? this.state.opacity109 : 1}
              fill={this.state.color109 ? this.state.color109 : 'transparent'} onClick={() => {
          this.setState({ color109: color, opacity109: opacity });
        }} d="M20.2,75.5c2.7-0.1,2.7-0.1,2.6,2.3c-0.1,1.7-0.1,3.4-0.2,5.1c0,0.9-1.4,1.8-2.1,1.3c-0.8-0.6-1.6-1.4-2-2.4
	c-0.6-1.2-0.8-2.5-1.1-3.8c-0.5-2,0-2.6,2-2.6C19.7,75.5,19.9,75.5,20.2,75.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity110 ? this.state.opacity110 : 1}
              fill={this.state.color110 ? this.state.color110 : 'transparent'} onClick={() => {
          this.setState({ color110: color, opacity110: opacity });
        }} d="M17.5,110.3c-1.1-2.2-3-3.5-5.3-4.1c1.9,1.2,4,2.3,4.8,4.6c-1.3-0.4-2.6-0.7-3.9-1.1c-2.8-0.8-4.2-3.7-3.9-6.8
	c0.8,0.6,1.5,1.2,2.3,1.6c0.9,0.4,2,0.6,3,0.8c1.2,0.3,2,1,2.4,2.1c0.4,0.8,0.7,1.7,1,2.5C17.8,110.1,17.7,110.2,17.5,110.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity111 ? this.state.opacity111 : 1}
              fill={this.state.color111 ? this.state.color111 : 'transparent'} onClick={() => {
          this.setState({ color111: color, opacity111: opacity });
        }} d="M20.3,111.5c0.4-0.8,0.6-1.7,1.1-2.3c0.9-1,1.9-2.2,3.1-2.8c1.8-0.9,3.8-1,5.8-0.6c0.3,0,0.6,0.5,0.6,0.7
	c0.1,1.2,0,2.4,0,3.8c-0.9-0.8-1.4-1.5-2.1-1.9c-0.5-0.3-1.3-0.5-1.9-0.3c-2.3,0.8-4.7,1.5-6.3,3.7
	C20.6,111.6,20.4,111.5,20.3,111.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity112 ? this.state.opacity112 : 1}
              fill={this.state.color112 ? this.state.color112 : 'transparent'} onClick={() => {
          this.setState({ color112: color, opacity112: opacity });
        }} d="M19.8,104.3c0,3.3,0,6.5,0,10c-0.5,0.2-1.1-0.1-1.1-0.9c-0.1-2.8-0.1-5.6,0-8.4c0-0.3,0.5-0.6,0.8-0.9
	C19.6,104.1,19.7,104.2,19.8,104.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity113 ? this.state.opacity113 : 1}
              fill={this.state.color113 ? this.state.color113 : 'transparent'} onClick={() => {
          this.setState({ color113: color, opacity113: opacity });
        }} d="M62.7,87.4c-0.5,1-1,2-1.5,3.1c-3.5-0.5-7-1-9.9-3.3c-0.9-0.7-1.9-1.3-2.8-2.1c-0.9-0.8-1-1.7,0-2.6
	c1-0.9,2.1-1.6,3.2-2.4c0.6-0.4,1.2-0.3,1.6,0.4c1.9,3.2,5.1,4.6,8.1,6.3C61.9,87,62.3,87.2,62.7,87.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity114 ? this.state.opacity114 : 1}
              fill={this.state.color114 ? this.state.color114 : 'transparent'} onClick={() => {
          this.setState({ color114: color, opacity114: opacity });
        }} d="M68.6,92.7c-2-0.1-3.8-0.6-5.4-1.7c-0.8-0.6-1-1.2-0.4-2c1.2-1.5,2.3-3.2,4.3-3.8c2.1-0.6,4,0,5.3,1.7
	c0.2,0.3,0.4,0.7,0.7,0.9c1.6,1.4,1.3,2.3-0.6,3.7c-1.1,0.8-2.5,0.9-3.8,1.4C68.6,92.9,68.6,92.8,68.6,92.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity115 ? this.state.opacity115 : 1}
              fill={this.state.color115 ? this.state.color115 : 'transparent'} onClick={() => {
          this.setState({ color115: color, opacity115: opacity });
        }} d="M74.5,88.2c2.3-0.9,4.6-1.7,6.9-2.7c1.3-0.6,2.5-1.5,3.8-2.2c0.3-0.2,0.5-0.4,0.8-0.6c2.1-1.4,4.1-0.9,5.3,1.3
	c0.3,0.6,0.3,1-0.2,1.5c-1.4,1.5-3.3,2.2-5.1,3c-3.5,1.5-7,0.7-10.6,0.8c-0.4,0-0.7-0.5-1.1-0.7C74.4,88.5,74.5,88.3,74.5,88.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity116 ? this.state.opacity116 : 1}
              fill={this.state.color116 ? this.state.color116 : 'transparent'} onClick={() => {
          this.setState({ color116: color, opacity116: opacity });
        }} d="M62.6,91.9c-3.4,1-6.7,2.2-10.2,2.8c-1.4,0.2-3.1-0.8-4.6-1.4c-0.4-0.2-0.7-0.9-0.7-1.4
	c0.1-1.3,0.3-2.6,0.6-3.9c0.3-1,1.1-1.2,2.1-0.7c1.2,0.6,2.2,1.3,3.4,1.9c2.6,1.3,5.4,1.9,8.4,1.9c0.3,0,0.6,0.2,0.9,0.3
	C62.6,91.6,62.6,91.8,62.6,91.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity117 ? this.state.opacity117 : 1}
              fill={this.state.color117 ? this.state.color117 : 'transparent'} onClick={() => {
          this.setState({ color117: color, opacity117: opacity });
        }} d="M72.4,85.6c2.1-2.1,4.2-4.2,6.3-6.3c1.7-1.7,2.9-1.6,5.2-0.6c0.6,0.3,1.2,0.7,1.8,1c-1.3,2.1-3,3.7-5,4.8
	c-1.7,0.9-3.4,1.7-5.1,2.5C73.7,87.7,73.6,87.6,72.4,85.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity118 ? this.state.opacity118 : 1}
              fill={this.state.color118 ? this.state.color118 : 'transparent'} onClick={() => {
          this.setState({ color118: color, opacity118: opacity });
        }} d="M65.7,93.2c-1.8,2.5-3.6,4.9-6.2,6.5c-1.2,0.7-2.2,0.8-3.4,0.1c-1.5-0.9-3-1.9-4.2-3.6c0.6-0.3,1.1-0.6,1.6-0.7
	c2.9-1,5.7-1.9,8.6-2.9C63.3,92.2,63.3,92.2,65.7,93.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity119 ? this.state.opacity119 : 1}
              fill={this.state.color119 ? this.state.color119 : 'transparent'} onClick={() => {
          this.setState({ color119: color, opacity119: opacity });
        }} d="M69.3,93.7c-0.1,2.4-0.2,4.9-0.5,7.3c-0.1,1.2-1.5,2.3-2.9,2.2c-1.3-0.1-2.5-0.4-3.7-0.7c-1-0.3-1.4-1.1-1-2
	c0.5-1,1.1-2,1.8-2.9c0.9-1.2,1.9-2.2,2.8-3.4c0.5-0.7,0.8-1,1.6-0.8C68,93.6,68.6,93.6,69.3,93.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity120 ? this.state.opacity120 : 1}
              fill={this.state.color120 ? this.state.color120 : 'transparent'} onClick={() => {
          this.setState({ color120: color, opacity120: opacity });
        }} d="M72.7,92.5c1.1,1.8,2.3,3.6,3.4,5.4c0.4,0.8,0.6,1.8,0.7,2.8c0.2,1-0.4,1.8-1.4,1.9c-1.4,0.2-2.8,0.2-4.1,0.2
	c-0.3,0-0.8-0.5-1-0.9c-0.3-0.6-0.5-1.3-0.5-2c-0.1-1.7,0-3.5,0-5.2c0-0.8,0.2-1.2,1.1-1.4C71.5,93.2,72,92.8,72.7,92.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity121 ? this.state.opacity121 : 1}
              fill={this.state.color121 ? this.state.color121 : 'transparent'} onClick={() => {
          this.setState({ color121: color, opacity121: opacity });
        }} d="M65.6,84.7c-0.1,0.2-0.1,0.2-0.1,0.2c-0.8,0.6-1.7,1.8-2.3,1.6c-1.5-0.4-2.9-1.3-4.2-2.1
	c-0.7-0.5-1.3-1.3-2-1.9c-1.1-1.1-1.5-2.4-1.6-3.9c0-0.2,0.1-0.5,0.2-0.7c0.6-0.8,3.5-1.8,4.5-1.5c0.3,0.1,0.6,0.3,0.7,0.6
	C61.5,80.2,63.3,82.6,65.6,84.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity122 ? this.state.opacity122 : 1}
              fill={this.state.color122 ? this.state.color122 : 'transparent'} onClick={() => {
          this.setState({ color122: color, opacity122: opacity });
        }} d="M74.7,90.3c4.8,0,9.6,0,14.4,0c0.5,1.3,0,2.1-0.5,3c-1.3,2.2-2.8,2.9-5.2,1.8c-2.2-1-4.3-2.2-6.4-3.4
	C76.2,91.3,75.5,90.8,74.7,90.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity123 ? this.state.opacity123 : 1}
              fill={this.state.color123 ? this.state.color123 : 'transparent'} onClick={() => {
          this.setState({ color123: color, opacity123: opacity });
        }} d="M73.3,92.2c0.5-1.2,1.5-0.9,2.1-0.6c2.4,1.4,4.7,2.8,7,4.4c1.6,1.2,1.6,1.9,0.3,3.4c-0.2,0.3-0.5,0.5-0.7,0.8
	c-0.8,0.8-1.9,1.1-2.8,0.3c-1-0.8-1.8-1.7-2.4-2.9c-0.7-1.6-1.8-3-2.7-4.5C73.7,92.8,73.5,92.5,73.3,92.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity124 ? this.state.opacity124 : 1}
              fill={this.state.color124 ? this.state.color124 : 'transparent'} onClick={() => {
          this.setState({ color124: color, opacity124: opacity });
        }} d="M72,85.3c-0.5-0.3-0.9-0.6-1.3-0.8c-0.5-0.3-1.3-0.4-1.4-0.7c-0.1-0.7,0-1.6,0.2-2.3c0.5-1.6,1.2-3,1.6-4.6
	c0.2-0.7,0.5-1,1.2-0.9c1.3,0.3,2.6,0.5,3.9,0.8c0.9,0.2,0.6,0.9,0.4,1.5C75.4,80.9,74.1,83.3,72,85.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity125 ? this.state.opacity125 : 1}
              fill={this.state.color125 ? this.state.color125 : 'transparent'} onClick={() => {
          this.setState({ color125: color, opacity125: opacity });
        }} d="M66.3,75.5c3.2,0,2.6,0,2.5,2.7c0,1.3,0,2.6-0.1,3.9c-0.1,0.6-0.2,1.5-0.6,1.8c-0.8,0.7-1.7,0.5-2.5-0.4
	c-1.7-1.9-1.9-4.4-2.3-6.7c-0.1-0.3,0.7-1,1.2-1.2C65,75.4,65.7,75.5,66.3,75.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity126 ? this.state.opacity126 : 1}
              fill={this.state.color126 ? this.state.color126 : 'transparent'} onClick={() => {
          this.setState({ color126: color, opacity126: opacity });
        }} d="M58.3,106.2c1.9,1.2,3.9,2.3,4.8,4.7c-1.6-0.5-3.2-0.8-4.7-1.5c-2.1-0.9-3.4-3.6-3.1-6.4
	c0.3,0.2,0.6,0.3,0.8,0.5c1.2,1.4,2.9,1.5,4.5,1.9c1.2,0.3,2,1.1,2.5,2.2c0.3,0.8,0.7,1.6,0.8,2.6C62.4,108.1,60.6,106.8,58.3,106.2
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity127 ? this.state.opacity127 : 1}
              fill={this.state.color127 ? this.state.color127 : 'transparent'} onClick={() => {
          this.setState({ color127: color, opacity127: opacity });
        }} d="M76.9,110.3c-0.4-0.5-0.5-0.7-0.7-0.9c-1.3-1.7-2.2-1.9-4.1-1.1c-1,0.4-2,0.8-2.9,1.3c-0.9,0.5-1.7,1.3-2.8,2.2
	c0.2-0.8,0.2-1.5,0.6-1.8c1.2-1.3,2.4-3,3.9-3.7c1.5-0.7,3.5-0.5,5.3-0.6c0.3,0,0.8,0.6,0.8,0.9C77.1,107.8,76.9,108.9,76.9,110.3z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity128 ? this.state.opacity128 : 1}
              fill={this.state.color128 ? this.state.color128 : 'transparent'} onClick={() => {
          this.setState({ color128: color, opacity128: opacity });
        }} d="M66.2,114.1c-0.9,0.3-1.4-0.1-1.4-0.8c-0.1-2.8-0.1-5.5,0-8.3c0-0.8,0.2-0.8,1-0.8
	C65.9,107.5,65.1,110.8,66.2,114.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity129 ? this.state.opacity129 : 1}
              fill={this.state.color129 ? this.state.color129 : 'transparent'} onClick={() => {
          this.setState({ color129: color, opacity129: opacity });
        }} d="M385.3,90.2c-0.6,1-1,1.9-1.6,2.8c-4.7-0.4-9.1-1.7-12.9-4.8c-1-0.8-1.1-1.6-0.1-2.5c1-0.8,2.1-1.5,3.3-2.1
	c0.4-0.2,1.4,0,1.6,0.3c2.1,2.9,5.3,4.1,8.3,5.6C384.4,89.8,384.9,90,385.3,90.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity130 ? this.state.opacity130 : 1}
              fill={this.state.color130 ? this.state.color130 : 'transparent'} onClick={() => {
          this.setState({ color130: color, opacity130: opacity });
        }} d="M391.3,95.3c-1.8-0.6-3.6-1.1-5.4-1.9c-1.2-0.5-1.2-1-0.4-2c1.2-1.4,2.5-2.9,4.5-3.3c2.1-0.4,3.8,0.1,5.2,1.6
	c0.2,0.3,0.4,0.6,0.7,0.8c1.4,1.5,1.4,2.4-0.5,3.4C394.2,94.6,392.8,94.9,391.3,95.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity131 ? this.state.opacity131 : 1}
              fill={this.state.color131 ? this.state.color131 : 'transparent'} onClick={() => {
          this.setState({ color131: color, opacity131: opacity });
        }} d="M397.4,90.9c2.1-0.7,4.2-1.3,6.3-2.2c1.7-0.7,3.3-1.6,4.9-2.4c0.4-0.2,0.7-0.5,1.1-0.7c1.6-0.8,4.2,0.1,5,1.7
	c0.1,0.3-0.1,0.9-0.3,1.2c-1.5,1.4-3.4,2-5.3,2.7c-3.5,1.3-7.2,0.6-10.7,0.7c-0.3,0-0.7-0.4-1.1-0.7C397.3,91.2,397.4,91,397.4,90.9
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity132 ? this.state.opacity132 : 1}
              fill={this.state.color132 ? this.state.color132 : 'transparent'} onClick={() => {
          this.setState({ color132: color, opacity132: opacity });
        }} d="M385.1,94.3c-3.5,0.9-6.9,2-10.4,2.6c-1.4,0.2-3.1-0.7-4.5-1.2c-0.4-0.1-0.8-0.9-0.8-1.3
	c0.1-1.2,0.3-2.5,0.7-3.6c0.3-0.9,1.2-1,2.1-0.6c1.2,0.6,2.4,1.3,3.6,1.8c2.7,1.2,5.5,1.7,8.5,1.7c0.3,0,0.6,0.1,0.8,0.2
	C385.1,94,385.1,94.1,385.1,94.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity133 ? this.state.opacity133 : 1}
              fill={this.state.color133 ? this.state.color133 : 'transparent'} onClick={() => {
          this.setState({ color133: color, opacity133: opacity });
        }} d="M395.4,88.9c1.9-2.7,4.4-4.9,7.2-6.6c0.7-0.4,1.8-0.5,2.7-0.4c1.2,0.2,2.3,0.8,3.6,1.3c-1.5,2-3.1,3.4-5.1,4.4
	c-1.8,0.9-3.7,1.6-5.6,2.4C396.9,90.4,396,90.2,395.4,88.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity134 ? this.state.opacity134 : 1}
              fill={this.state.color134 ? this.state.color134 : 'transparent'} onClick={() => {
          this.setState({ color134: color, opacity134: opacity });
        }} d="M374.3,98.3c0.6-0.3,0.9-0.6,1.3-0.7c3.2-0.9,6.3-1.9,9.5-2.7c0.9-0.2,2,0.2,3,0.3c0,0.1,0.1,0.3,0.1,0.4
	c-0.5,0.6-1,1.3-1.6,1.9c-1.6,1.4-3.3,2.8-5,4.1c-0.8,0.6-1.8,0.6-2.8,0.1C377.2,100.8,375.7,99.8,374.3,98.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity135 ? this.state.opacity135 : 1}
              fill={this.state.color135 ? this.state.color135 : 'transparent'} onClick={() => {
          this.setState({ color135: color, opacity135: opacity });
        }} d="M388.3,87.8c-0.3,0.2-0.3,0.3-0.4,0.3c-0.7,0.5-1.6,1.5-2.2,1.3c-1.5-0.4-2.9-1.2-4.3-2
	c-0.7-0.4-1.2-1.1-1.9-1.6c-1.1-0.9-1.5-2.1-1.7-3.5c0-0.2,0.1-0.6,0.3-0.7c1.3-0.9,2.7-1.5,4.3-1.3c0.3,0,0.8,0.4,0.9,0.6
	C384.1,83.8,386,85.9,388.3,87.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity136 ? this.state.opacity136 : 1}
              fill={this.state.color136 ? this.state.color136 : 'transparent'} onClick={() => {
          this.setState({ color136: color, opacity136: opacity });
        }} d="M392.6,95.9c1-0.4,1.9-0.7,2.9-1c1.1,1.5,2.3,3.1,3.4,4.7c0.3,0.4,0.4,0.9,0.6,1.4c0.6,2.2,0,3.1-2.2,3.3
	c-0.6,0.1-1.3,0.1-1.9,0.1c-1.8-0.1-2.6-0.7-2.7-2.5C392.5,99.9,392.6,97.8,392.6,95.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity137 ? this.state.opacity137 : 1}
              fill={this.state.color137 ? this.state.color137 : 'transparent'} onClick={() => {
          this.setState({ color137: color, opacity137: opacity });
        }} d="M392,95.9c-0.1,2.2-0.1,4.3-0.3,6.4c-0.1,1.2-1.4,2.2-2.9,2.2c-1.3,0-2.7-0.3-4-0.6c-1.1-0.2-1.4-1.1-0.9-2
	c0.5-0.9,1.1-1.6,1.7-2.4c0.9-1,1.9-1.8,2.7-2.9c0.6-0.8,1.2-1.2,2.2-0.9C390.9,95.8,391.4,95.8,392,95.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity138 ? this.state.opacity138 : 1}
              fill={this.state.color138 ? this.state.color138 : 'transparent'} onClick={() => {
          this.setState({ color138: color, opacity138: opacity });
        }} d="M397.6,92.9c4.6,0,9.3,0,14,0c1.1,0,1.1,0.6,0.9,1.3c-0.7,2.5-3.3,4-5.9,3.1c-1.9-0.7-3.6-1.6-5.4-2.5
	C400,94.2,398.7,93.5,397.6,92.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity139 ? this.state.opacity139 : 1}
              fill={this.state.color139 ? this.state.color139 : 'transparent'} onClick={() => {
          this.setState({ color139: color, opacity139: opacity });
        }} d="M396.1,94.5c0.6-1.1,1.5-0.8,2.1-0.5c2.3,1.1,4.5,2.3,6.7,3.6c0.7,0.4,1.7,1.7,1.5,1.9
	c-0.6,1.1-1.5,2.1-2.6,2.8c-1,0.6-2.1-0.1-2.8-1c-1.4-1.8-2.6-3.7-3.9-5.5C396.8,95.4,396.5,95,396.1,94.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity140 ? this.state.opacity140 : 1}
              fill={this.state.color140 ? this.state.color140 : 'transparent'} onClick={() => {
          this.setState({ color140: color, opacity140: opacity });
        }} d="M395,88.4c-0.7-0.3-1.2-0.6-1.6-0.8c-1.3-0.4-1.7-1.4-1.1-2.7c0.7-1.4,1.2-2.9,1.8-4.3c0.1-0.3,0.7-0.7,0.9-0.7
	c1.4,0.2,2.8,0.4,4.2,0.8c0.9,0.2,0.6,1,0.3,1.5c-1.1,1.7-2.3,3.4-3.4,5C395.7,87.6,395.3,88,395,88.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity141 ? this.state.opacity141 : 1}
              fill={this.state.color141 ? this.state.color141 : 'transparent'} onClick={() => {
          this.setState({ color141: color, opacity141: opacity });
        }} d="M389,79.4c2.8,0,2.8-0.2,2.4,2.9c-0.1,1,0,2.1-0.1,3.1c0,0.6-0.1,1.5-0.4,1.7c-0.7,0.4-1.5,0.7-2.4-0.1
	c-2-1.7-2.2-4.1-2.6-6.3c-0.1-0.3,0.7-0.9,1.1-1.1C387.6,79.3,388.4,79.4,389,79.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity142 ? this.state.opacity142 : 1}
              fill={this.state.color142 ? this.state.color142 : 'transparent'} onClick={() => {
          this.setState({ color142: color, opacity142: opacity });
        }} d="M380.9,107.4c1.9,1,3.8,2,4.9,4.5c-2.2-0.9-4.4-1.4-6.2-2.5c-1.7-1-2-3-2.1-4.9c1.2,0.6,2.3,1.2,3.4,1.7
	c0.8,0.3,1.6,0.3,2.4,0.5c1.7,0.4,3.3,2.7,3,4.5C385,109,383.2,107.9,380.9,107.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity143 ? this.state.opacity143 : 1}
              fill={this.state.color143 ? this.state.color143 : 'transparent'} onClick={() => {
          this.setState({ color143: color, opacity143: opacity });
        }} d="M399.6,110.8c-0.2-0.2-0.4-0.5-0.6-0.7c-1.3-1.4-2.2-1.5-3.9-0.9c-2,0.7-4.1,1.3-5.5,3.1
	c-0.1,0-0.2-0.1-0.3-0.1c0.1-0.4,0.1-1,0.3-1.3c1.6-2,3.2-3.9,6-4c1.1,0,2.3-0.2,3.4-0.2c0.7,0,1.1,0.4,1,1.2c0,0.9,0,1.9,0,2.8
	C399.8,110.8,399.7,110.8,399.6,110.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity144 ? this.state.opacity144 : 1}
              fill={this.state.color144 ? this.state.color144 : 'transparent'} onClick={() => {
          this.setState({ color144: color, opacity144: opacity });
        }} d="M388.4,105.4c0.2,3-0.6,6.1,0.5,9c-0.9,0.4-1.5,0.1-1.5-0.7c-0.1-2.5-0.1-5-0.1-7.5
	C387.4,105.5,387.8,105.2,388.4,105.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity145 ? this.state.opacity145 : 1}
              fill={this.state.color145 ? this.state.color145 : 'transparent'} onClick={() => {
          this.setState({ color145: color, opacity145: opacity });
        }} d="M431.1,92.2c-0.5,1-0.9,1.9-1.4,3c-3.3-0.4-6.5-1.1-9.3-3.3c-0.9-0.7-1.8-1.4-2.7-2.2c-0.9-0.8-0.9-1.7-0.1-2.5
	c0.9-0.9,1.9-1.6,2.9-2.4c0.6-0.4,1.2-0.5,1.6,0.3c1.8,3.2,4.9,4.7,7.9,6.4c0.2,0.1,0.4,0.2,0.6,0.3C430.9,92,431,92.1,431.1,92.2z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity146 ? this.state.opacity146 : 1}
              fill={this.state.color146 ? this.state.color146 : 'transparent'} onClick={() => {
          this.setState({ color146: color, opacity146: opacity });
        }} d="M436.9,97.4c-2.2-0.1-3.8-0.6-5.3-1.7c-0.8-0.6-1.1-1.2-0.4-2.1c1.1-1.5,2.1-3.1,4.1-3.7c1.9-0.6,3.6,0,4.9,1.5
	c0.2,0.3,0.4,0.5,0.5,0.8c0.5,0.7,1.6,1.3,1.1,2.3c-0.5,1.3-1.6,2.2-3,2.6C438.1,97.3,437.3,97.3,436.9,97.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity147 ? this.state.opacity147 : 1}
              fill={this.state.color147 ? this.state.color147 : 'transparent'} onClick={() => {
          this.setState({ color147: color, opacity147: opacity });
        }} d="M442.3,92.9c2-0.8,4.1-1.6,6.1-2.5c1.4-0.7,2.7-1.6,4.1-2.4c0.2-0.1,0.4-0.4,0.7-0.5c1.9-1.3,4.3-0.6,5.2,1.6
	c0.1,0.3,0,1-0.2,1.1c-1.4,1-2.8,2.1-4.4,2.8c-2.4,1.2-5,1.4-7.7,1c-0.8-0.1-1.7,0-2.5,0.1C442.7,94.1,442.3,93.8,442.3,92.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity148 ? this.state.opacity148 : 1}
              fill={this.state.color148 ? this.state.color148 : 'transparent'} onClick={() => {
          this.setState({ color148: color, opacity148: opacity });
        }} d="M430.9,96.6c-3,0.9-6,1.9-9,2.7c-1.8,0.5-3.5-0.3-4.9-1.3c-0.4-0.3-0.7-1.1-0.7-1.6c0.1-1.2,0.3-2.5,0.6-3.6
	c0.3-1,1.1-1.3,2.1-0.7c1.2,0.7,2.2,1.5,3.5,2.1c2.4,1.1,4.9,1.8,7.6,1.7c0.3,0,0.6,0.2,0.9,0.3C431,96.3,431,96.4,430.9,96.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity149 ? this.state.opacity149 : 1}
              fill={this.state.color149 ? this.state.color149 : 'transparent'} onClick={() => {
          this.setState({ color149: color, opacity149: opacity });
        }} d="M452.9,84.3c-2.7,4.1-6.4,6.1-10.5,7.6c-1.4,0.5-1.4-0.7-1.9-1.3c1.6-2.9,3.9-5.1,6.4-7.3
	c0.9-0.8,1.9-0.8,2.9-0.5C450.8,83.2,451.8,83.8,452.9,84.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity150 ? this.state.opacity150 : 1}
              fill={this.state.color150 ? this.state.color150 : 'transparent'} onClick={() => {
          this.setState({ color150: color, opacity150: opacity });
        }} d="M433.9,98c-1.8,2.5-3.5,5.1-6.3,6.7c-1.2,0.6-2.1,0.1-3-0.4c-1-0.7-1.9-1.4-2.8-2.3c-0.9-0.8-0.7-1.3,0.4-1.7
	c2.3-0.8,4.6-1.6,6.9-2.4c0.2-0.1,0.4-0.1,0.6-0.2C431,97,432.4,96.6,433.9,98z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity151 ? this.state.opacity151 : 1}
              fill={this.state.color151 ? this.state.color151 : 'transparent'} onClick={() => {
          this.setState({ color151: color, opacity151: opacity });
        }} d="M440.6,97.2c1,1.8,2.2,3.5,3.1,5.3c0.4,0.9,0.6,1.9,0.7,2.9c0.1,1-0.4,1.7-1.3,1.9c-1.2,0.2-2.5,0.2-3.8,0.2
	c-0.3,0-0.8-0.5-1-0.9c-0.3-0.6-0.5-1.3-0.5-2c-0.1-2.1,0-4.2,0-6.2C438.8,97.9,439.7,97.6,440.6,97.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity152 ? this.state.opacity152 : 1}
              fill={this.state.color152 ? this.state.color152 : 'transparent'} onClick={() => {
          this.setState({ color152: color, opacity152: opacity });
        }} d="M433.9,89.5c-0.2,0.2-0.3,0.2-0.3,0.3c-0.6,0.5-1.3,1.5-1.8,1.5c-1,0-2.1-0.6-2.9-1.2c-1.9-1.6-4.1-3.1-4.5-6
	c-0.1-0.9,0-1.6,0.9-2c0.7-0.3,1.4-0.7,2.2-0.9c1-0.3,1.8-0.1,2.1,1.1C430,85.2,431.9,87.3,433.9,89.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity153 ? this.state.opacity153 : 1}
              fill={this.state.color153 ? this.state.color153 : 'transparent'} onClick={() => {
          this.setState({ color153: color, opacity153: opacity });
        }} d="M437.1,98.3c0,2.4,0.1,4.6,0,6.8c-0.1,1.7-1.5,2.8-3.2,2.7c-1.1-0.1-2.2-0.4-3.2-0.7c-0.8-0.2-1.2-0.9-0.9-1.7
	c0.4-1.1,1-2.1,1.6-3c0.9-1.3,1.9-2.6,3-3.9c0.2-0.3,0.6-0.5,0.9-0.5C435.9,98.1,436.5,98.2,437.1,98.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity154 ? this.state.opacity154 : 1}
              fill={this.state.color154 ? this.state.color154 : 'transparent'} onClick={() => {
          this.setState({ color154: color, opacity154: opacity });
        }} d="M442.5,95c4.2,0,8.6,0,12.9,0c0.7,0,0.9,0.3,0.8,1c-0.1,2.9-3,4.9-5.7,3.8c-2.1-0.9-4-2.2-6-3.4
	C443.8,95.9,443.1,95.4,442.5,95z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity155 ? this.state.opacity155 : 1}
              fill={this.state.color155 ? this.state.color155 : 'transparent'} onClick={() => {
          this.setState({ color155: color, opacity155: opacity });
        }} d="M441.1,96.7c0.4-1.2,1.4-0.8,2-0.4c2.2,1.4,4.4,2.8,6.5,4.4c1.4,1.1,1.4,1.7,0.4,3.1c-0.3,0.4-0.6,0.8-0.9,1.1
	c-1.1,1.1-2.6,0.9-3.6-0.7C444.1,101.8,442.6,99.3,441.1,96.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity156 ? this.state.opacity156 : 1}
              fill={this.state.color156 ? this.state.color156 : 'transparent'} onClick={() => {
          this.setState({ color156: color, opacity156: opacity });
        }} d="M439.9,90c-0.5-0.3-0.9-0.6-1.3-0.8c-1.3-0.5-1.6-1.5-1.2-2.8c0.6-1.7,1.1-3.4,1.8-5c0.1-0.3,0.6-0.7,0.9-0.6
	c1.3,0.2,2.6,0.5,3.8,0.8c0.8,0.2,0.6,0.8,0.4,1.4C443.2,85.5,441.9,87.9,439.9,90z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity157 ? this.state.opacity157 : 1}
              fill={this.state.color157 ? this.state.color157 : 'transparent'} onClick={() => {
          this.setState({ color157: color, opacity157: opacity });
        }} d="M436.7,81.9c0,1.9,0,3.8,0,5.7c0,0.4-0.3,0.9-0.6,1.1c-0.6,0.3-1.3,0.7-1.8-0.1c-0.6-0.8-1.2-1.6-1.6-2.4
	c-0.5-1.3-0.8-2.7-1.1-4.1c-0.2-1.1,0.2-1.7,1.3-1.8c0.7-0.1,1.4-0.1,2.1-0.2C436.8,80.1,436.9,80.3,436.7,81.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity158 ? this.state.opacity158 : 1}
              fill={this.state.color158 ? this.state.color158 : 'transparent'} onClick={() => {
          this.setState({ color158: color, opacity158: opacity });
        }} d="M432.1,114.8c-1.2-2-2.8-3.3-5.1-3.9c1.8,1.2,3.7,2.3,4.4,4.5c-5.3-0.9-7.9-3.6-7.3-7.6
	c0.9,0.6,1.9,1.3,2.9,1.7c0.7,0.3,1.5,0.4,2.3,0.6c0.4,0.1,0.9,0.3,1.1,0.6C431.4,111.9,432.2,113.2,432.1,114.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity159 ? this.state.opacity159 : 1}
              fill={this.state.color159 ? this.state.color159 : 'transparent'} onClick={() => {
          this.setState({ color159: color, opacity159: opacity });
        }} d="M444.5,115c-0.4-0.6-0.6-0.8-0.8-1.1c-1.2-1.5-1.9-1.7-3.8-0.9c-3.3,1.4-3.4,1.5-5.3,3.2c0.1-0.5,0-1.1,0.3-1.4
	c1.2-1.4,2.4-3,3.9-3.9c1.3-0.7,3.1-0.5,4.6-0.6c0.7,0,1.2,0.4,1.1,1.2C444.6,112.6,444.6,113.6,444.5,115z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity160 ? this.state.opacity160 : 1}
              fill={this.state.color160 ? this.state.color160 : 'transparent'} onClick={() => {
          this.setState({ color160: color, opacity160: opacity });
        }} d="M434.1,108.9c0,3.3,0,6.5,0,10c-0.5,0.2-0.9-0.1-1-0.8c-0.1-0.8-0.3-1.6-0.3-2.4c0-2,0.1-4,0.2-6
	c0-0.4,0.5-0.7,0.7-1.1C433.8,108.7,434,108.8,434.1,108.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity161 ? this.state.opacity161 : 1}
              fill={this.state.color161 ? this.state.color161 : 'transparent'} onClick={() => {
          this.setState({ color161: color, opacity161: opacity });
        }} d="M28.3,153.3c3,1.7,5.9-0.1,8.9,0.3c0-0.1,0-0.2,0-0.4c-1-0.1-2.1-0.3-3.3-0.4c0.4-0.3,0.7-0.5,1-0.7
	c0-0.1-0.1-0.2-0.1-0.3c-2.2,0.4-4.5,0.9-6.7,1.3c-2.8,0.5-5.6,1.2-8.5,1.3c-5.1,0.2-8-4.2-6.2-9c0.7-1.9,1.6-3.8,2.5-5.7
	c0.6-1.3,0.7-2.7,0.6-4.1c-0.1-1.3-0.2-2.7,0-3.9c0.8-3.9,4.1-5.8,7.1-6c2.9-0.1,5.6,0.9,7.2,3.7c0.6,1,1.1,2,1.8,2.9
	c0.5,0.7,1.1,1.4,1.8,1.8c3,2.1,6.1,4.2,8.3,7.1c1.6,2.1,2.4,4.7,2.7,7.3c0.2,2.3-1.2,3.9-3.2,4.9c-3.8,1.9-7.7,2.6-11.8,0.9
	c-0.7-0.3-1.4-0.4-2.2-0.6C28.3,153.6,28.3,153.5,28.3,153.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity162 ? this.state.opacity162 : 1}
              fill={this.state.color162 ? this.state.color162 : 'transparent'} onClick={() => {
          this.setState({ color162: color, opacity162: opacity });
        }} d="M34.3,118.4c0.6,0,1.1-0.1,1.6-0.1c1.2,0,2.4,0.1,3.5,0.2c0.4,0,0.7,0.4,1.1,0.5c-0.3,0.3-0.5,0.6-0.8,1
	c1.6,1,3.5,1.7,4.6,3.4c-0.3,0.2-0.5,0.4-0.7,0.5c-1.9,0.6-3.9,1.1-5.8,1.7c-0.2,0.1-0.7,0.3-0.7,0.3c0.2,0.9-0.4,0.7-0.9,0.7
	c-0.8,0-1.5-0.2-2.3,0.3c-1,0.6-4.1-0.7-4.5-1.8c-0.2-0.5-0.1-1.6,0.2-1.8c0.7-0.4,1.6-0.6,2.4-0.4c0.8,0.2,1.4,0.9,2.2,1.2
	c0.6,0.3,1.3,0.3,2,0.4c0-0.1,0-0.2,0.1-0.3c-0.6-0.2-1.3-0.3-1.9-0.5c-0.6-0.3-1.1-0.7-1.7-1c0.1-0.2,0.1-0.3,0.2-0.5
	c2,0.5,4,1.1,6,1.6c0-0.1,0.1-0.2,0.1-0.3c-1.1-0.3-2.2-0.6-3.3-1c0-0.1,0-0.2,0-0.3c1.4,0.1,2.8,0.2,4.3,0.3
	c-1-0.9-0.7-0.6-4.8-0.6c-1.6,0-3.2,0.3-5,0.5c0.3-0.6,0.5-1.1,0.7-1.5c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3,0.5-0.6,1-0.9,1.4
	c-0.2,0.3-0.5,0.8-0.8,0.9c-0.2,0.1-0.7-0.3-0.9-0.6c-0.8-0.9-0.6-1.9,0-2.9c1-1.7,4.7-2.9,6.6-2.1
	C34.5,117.5,34.4,117.9,34.3,118.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity163 ? this.state.opacity163 : 1}
              fill={this.state.color163 ? this.state.color163 : 'transparent'} onClick={() => {
          this.setState({ color163: color, opacity163: opacity });
        }} d="M24.1,116.8c-1.3,2.7-1.8,5.3-0.2,7.9c-1,0.6-1.5,0.6-1.9-0.6c-0.9-2-0.6-4,0.1-5.9
	C22.4,117.3,22.9,116.8,24.1,116.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity164 ? this.state.opacity164 : 1}
              fill={this.state.color164 ? this.state.color164 : 'transparent'} onClick={() => {
          this.setState({ color164: color, opacity164: opacity });
        }} d="M351.2,153.6c1.9,0.2,3.6,0.8,4.8,0.3c1.5-0.6,3.1,0.4,4.6-0.6c-1.3-0.2-2.3-0.3-3.6-0.4
	c0.5-0.3,0.8-0.5,1.1-0.6c0-0.1,0-0.2,0-0.3c-1.4,0.2-2.9,0.3-4.3,0.5c-3,0.6-5.9,1.3-8.9,1.8c-1.1,0.2-2.3,0.2-3.5-0.1
	c-5.1-1-6.2-5.4-4.7-9.1c0.7-1.8,1.5-3.6,2.3-5.3c0.7-1.4,0.7-2.9,0.7-4.4c0-1.5-0.1-3,0.2-4.4c0.7-3.3,4.1-5.3,6.9-5.4
	c2.8-0.1,5.6,0.9,7.2,3.7c0.6,1,1.1,2,1.8,3c0.5,0.7,1.1,1.4,1.8,1.9c3.1,2.1,6.2,4.2,8.4,7.2c1.6,2.1,2.4,4.7,2.7,7.3
	c0.2,2-1,3.5-2.7,4.5c-3.8,2.2-7.8,3-12.1,1.4c-0.4-0.2-0.8-0.2-1.3-0.4C352.2,154.1,351.9,153.9,351.2,153.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity165 ? this.state.opacity165 : 1}
              fill={this.state.color165 ? this.state.color165 : 'transparent'} onClick={() => {
          this.setState({ color165: color, opacity165: opacity });
        }} d="M353.3,122.3c0.3-0.7,0.5-1.2,0.7-1.7c-0.1-0.1-0.2-0.1-0.2-0.2c-0.3,0.5-0.6,0.9-0.9,1.4
	c-0.2,0.3-0.5,0.8-0.8,0.9c-0.2,0.1-0.7-0.3-0.9-0.6c-0.7-0.9-0.6-1.9,0-2.9c1-1.7,4.7-2.9,6.6-2.1c-0.1,0.3-0.1,0.6-0.3,1.1
	c0.5,0,0.9,0.1,1.3,0.1c1.3,0,2.6,0,3.9,0.1c0.3,0,0.6,0.3,1,0.5c-0.2,0.3-0.5,0.6-0.7,0.8c1.5,1,3.1,2.1,4.6,3.2
	c0,0.1-0.1,0.3-0.1,0.4c-0.5,0.2-1,0.5-1.5,0.6c-1.8,0.5-3.6,1-5.4,1.5c-0.1,0-0.2,0-0.2,0.1c-0.3,1.5-1.5,0.6-2.2,0.7
	c-0.1,0-0.4-0.1-0.5,0c-1.7,1.3-3.1,0.2-4.4-0.6c-1-0.6-1.2-1.6-0.7-2.4c0.4-0.7,2-1.1,2.7-0.7c0.5,0.3,0.9,0.7,1.4,0.9
	c0.9,0.4,1.8,0.6,2.7,0.9c0-0.1,0.1-0.2,0.1-0.3c-1.2-0.5-2.4-1-3.6-1.5c0-0.2,0.1-0.3,0.1-0.5c2.1-0.2,3.9,1.4,6.1,1.1
	c-1.1-0.3-2.2-0.7-3.3-1c0-0.1,0-0.2,0-0.3c1.4,0.1,2.8,0.2,4.1,0.3c0-0.1,0-0.2,0-0.3C359.9,121.4,356.8,121.7,353.3,122.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity166 ? this.state.opacity166 : 1}
              fill={this.state.color166 ? this.state.color166 : 'transparent'} onClick={() => {
          this.setState({ color166: color, opacity166: opacity });
        }} d="M347.3,116.9c-1.3,2.6-1.7,5.2-0.2,7.7c-1.3,0.8-1.4,0.7-1.9-0.5c-0.9-2-0.6-4,0.1-5.9
	C345.6,117.2,346.2,116.8,347.3,116.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity167 ? this.state.opacity167 : 1}
              fill={this.state.color167 ? this.state.color167 : 'transparent'} onClick={() => {
          this.setState({ color167: color, opacity167: opacity });
        }} d="M305.5,153.3c3,1.7,5.9-0.1,8.8,0.3c0-0.1,0-0.2,0-0.4c-1-0.1-2-0.2-3.2-0.4c0.4-0.3,0.7-0.5,1-0.7
	c0-0.1-0.1-0.2-0.1-0.4c-2.2,0.4-4.4,0.9-6.7,1.2c-2.9,0.5-5.7,1.2-8.6,1.3c-5,0.2-8-4.3-6.2-9c0.7-1.9,1.5-3.8,2.4-5.6
	c0.7-1.4,0.7-2.8,0.6-4.3c0-1.6,0-3.2,0.3-4.7c0.8-3.1,4.2-5.1,6.9-5.1c2.9,0,5.6,1,7.2,3.8c0.5,1,1.1,2,1.7,2.9
	c0.5,0.7,1.1,1.4,1.8,1.9c3,2.1,6,4.2,8.3,7c1.7,2.1,2.5,4.8,2.8,7.4c0.2,2.3-1.2,3.9-3.2,4.9c-3.8,1.9-7.7,2.6-11.8,1
	c-0.4-0.1-0.8-0.2-1.1-0.3c-0.3-0.1-0.7-0.2-1-0.4C305.4,153.6,305.5,153.5,305.5,153.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity168 ? this.state.opacity168 : 1}
              fill={this.state.color168 ? this.state.color168 : 'transparent'} onClick={() => {
          this.setState({ color168: color, opacity168: opacity });
        }} d="M313.6,124.3c-1.3-0.5-2.5-1-3.9-1.6c1.4-1,2.3,0.1,3.3,0.3c1,0.2,2.1,0.5,3.1,0.7c0-0.1,0.1-0.2,0.1-0.3
	c-1.1-0.3-2.2-0.7-3.3-1c0-0.1,0-0.2,0-0.3c1.4,0.1,2.8,0.2,4.2,0.3c0-0.1,0-0.2,0-0.3c-3.2-0.7-6.4-0.4-9.8,0.3
	c0.3-0.7,0.5-1.1,0.7-1.6c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3,0.4-0.6,0.9-0.9,1.3c-0.3,0.4-0.5,0.7-0.8,1.1c-0.3-0.3-0.7-0.5-1-0.8
	c-0.7-0.9-0.5-1.9,0.1-2.8c1-1.7,4.7-2.9,6.6-2.1c-0.1,0.3-0.2,0.6-0.3,1.1c0.5,0,0.9,0.1,1.3,0.1c1.3,0,2.6,0,3.9,0.1
	c0.3,0,0.6,0.4,1,0.5c-0.2,0.3-0.5,0.5-0.8,0.9c0.8,0.5,1.7,0.9,2.4,1.5c0.8,0.6,1.5,1.2,2.4,2c-0.6,0.3-0.9,0.5-1.2,0.6
	c-1.9,0.5-3.8,1-5.7,1.6c-0.2,0-0.4,0.1-0.4,0.1c-0.1,1.5-1.2,0.6-1.8,0.7c-0.4,0.1-0.7,0.2-1.1,0.3c-0.4,0.1-0.9,0.2-1.3,0.1
	c-0.9-0.2-1.8-0.5-2.5-1c-1.1-0.6-1.5-1.8-0.9-2.6c0.5-0.8,2.1-1,3-0.4C310.8,123.8,311.9,124.6,313.6,124.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity169 ? this.state.opacity169 : 1}
              fill={this.state.color169 ? this.state.color169 : 'transparent'} onClick={() => {
          this.setState({ color169: color, opacity169: opacity });
        }} d="M301.3,116.8c-1.4,2.7-1.7,5.2-0.2,7.9c-0.9,0.6-1.4,0.6-1.9-0.6c-0.9-2-0.6-4,0.1-5.9
	C299.5,117.3,300.1,116.7,301.3,116.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity170 ? this.state.opacity170 : 1}
              fill={this.state.color170 ? this.state.color170 : 'transparent'} onClick={() => {
          this.setState({ color170: color, opacity170: opacity });
        }} d="M212.3,153.3c3,1.7,5.9-0.1,8.9,0.3c0-0.1,0-0.2,0-0.4c-1-0.1-2.1-0.3-3.3-0.4c0.5-0.3,0.8-0.5,1-0.7
	c0-0.1,0-0.2,0-0.3c-1.5,0.2-2.9,0.3-4.4,0.5c-2.6,0.5-5.2,1.3-7.8,1.7c-1.5,0.2-3.2,0.3-4.8,0c-4.9-1-5.9-5.3-4.5-9
	c0.7-1.8,1.5-3.6,2.4-5.4c0.7-1.4,0.7-2.9,0.7-4.4c0-1.7,0-3.5,0.5-5.1c0.9-2.9,4.4-4.8,6.8-4.7c2.9,0,5.6,1,7.1,3.8
	c0.5,1,1.1,2,1.7,2.9c0.5,0.7,1.1,1.4,1.8,1.9c3.1,2.1,6.1,4.2,8.4,7.2c1.6,2.1,2.4,4.7,2.7,7.3c0.2,2.1-1.1,3.7-2.9,4.7
	c-3.8,2.1-7.8,2.9-12,1.2c-0.7-0.3-1.5-0.5-2.3-0.7C212.3,153.6,212.3,153.5,212.3,153.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity171 ? this.state.opacity171 : 1}
              fill={this.state.color171 ? this.state.color171 : 'transparent'} onClick={() => {
          this.setState({ color171: color, opacity171: opacity });
        }} d="M220.4,124.3c-1.3-0.6-2.5-1.1-3.7-1.6c0-0.1,0.1-0.3,0.1-0.4c2.1-0.3,3.9,1.4,6,1c-1.1-0.3-2.1-0.7-3.2-1
	c0-0.1,0-0.2,0-0.3c1.4,0.1,2.8,0.2,4.3,0.3c-1-0.7-1-0.8-4.8-0.7c-1.6,0-3.2,0.3-5,0.5c0.2-0.5,0.4-1,0.6-1.5
	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3,0.4-0.6,0.9-0.9,1.3c-0.3,0.4-0.6,0.7-0.8,1c-0.3-0.3-0.7-0.5-1-0.8c-0.7-1-0.5-2.5,0.6-3.5
	c1.6-1.4,3.5-1.7,5.5-1.7c0.2,0,0.4,0.2,0.6,0.3c-0.1,0.4-0.2,0.7-0.3,1.2c0.6,0,1,0,1.5,0c1.2,0,2.4,0.1,3.7,0.2
	c0.3,0,0.7,0.3,1,0.5c-0.2,0.3-0.5,0.6-0.7,0.9c1.5,1,3.1,2.1,4.9,3.4c-0.7,0.4-0.9,0.6-1.3,0.7c-1.9,0.5-3.8,1.1-5.7,1.6
	c-0.2,0-0.4,0.1-0.4,0.1c-0.3,1.5-1.4,0.5-2.1,0.7c-0.2,0.1-0.5,0.1-0.7,0.1c-0.5,0.1-1.1,0.4-1.5,0.3c-0.8-0.2-1.7-0.5-2.4-0.9
	c-1.2-0.7-1.5-1.8-0.9-2.6c0.5-0.8,2.1-1,3-0.4C217.6,123.8,218.8,124.6,220.4,124.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity172 ? this.state.opacity172 : 1}
              fill={this.state.color172 ? this.state.color172 : 'transparent'} onClick={() => {
          this.setState({ color172: color, opacity172: opacity });
        }} d="M207.9,124.6c-1.3,0.7-1.4,0.6-1.9-0.5c-0.9-2-0.7-4,0.1-5.9c0.3-0.8,0.9-1.5,2.1-1.4
	C206.7,119.4,206.4,122,207.9,124.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity173 ? this.state.opacity173 : 1}
              fill={this.state.color173 ? this.state.color173 : 'transparent'} onClick={() => {
          this.setState({ color173: color, opacity173: opacity });
        }} d="M74.1,153.3c3,1.7,5.9-0.1,8.9,0.3c0-0.1,0-0.2,0-0.4c-1-0.1-2-0.3-3.3-0.4c0.5-0.3,0.7-0.5,1-0.7
	c0-0.1,0-0.2-0.1-0.3c-1.5,0.2-2.9,0.3-4.4,0.5c-3,0.6-5.9,1.3-8.9,1.8c-1.2,0.2-2.6,0.1-3.8-0.2c-4.6-1.1-5.7-5-4.5-8.7
	c0.6-1.9,1.5-3.8,2.4-5.6c0.7-1.4,0.7-2.8,0.7-4.3c0-1.7,0-3.5,0.4-5.1c0.8-3,4.4-4.9,6.8-4.8c2.9,0.1,5.6,1,7.1,3.8
	c0.5,1,1.1,2,1.7,2.9c0.5,0.7,1.1,1.4,1.8,1.9c3.1,2.1,6.1,4.2,8.4,7.2c1.6,2.1,2.5,4.7,2.7,7.3c0.2,2.3-1.3,3.9-3.4,5
	c-3.8,1.9-7.6,2.5-11.7,0.8c-0.7-0.3-1.4-0.4-2.1-0.6C74.1,153.7,74.1,153.5,74.1,153.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity174 ? this.state.opacity174 : 1}
              fill={this.state.color174 ? this.state.color174 : 'transparent'} onClick={() => {
          this.setState({ color174: color, opacity174: opacity });
        }} d="M84.7,123.3c-1.1-0.3-2.1-0.6-3.2-0.9c0-0.1,0-0.2,0.1-0.3c1.4,0.1,2.8,0.2,4.3,0.3c-0.9-0.7-0.9-0.8-4.7-0.7
	c-1.6,0-3.2,0.3-5.1,0.5c0.2-0.5,0.4-1,0.6-1.5c-0.1-0.1-0.2-0.1-0.2-0.2c-0.3,0.5-0.6,1-0.9,1.5c-0.2,0.3-0.4,0.7-0.7,0.8
	c-0.2,0.1-0.6-0.3-0.8-0.5c-0.9-1-0.8-2.7,0.5-3.7c1.5-1.3,3.4-1.6,5.4-1.7c0.2,0,0.4,0.2,0.7,0.3c-0.1,0.3-0.2,0.6-0.3,1.1
	c0.5,0,0.9,0.1,1.3,0.1c1.3,0,2.6,0,3.9,0.1c0.3,0,0.7,0.3,1,0.5c-0.2,0.3-0.5,0.6-0.7,0.9c1.5,1,3.1,2.1,4.8,3.4
	c-0.6,0.4-0.8,0.6-1.1,0.7c-1.9,0.6-3.9,1.1-5.8,1.6c-0.2,0.1-0.5,0.1-0.5,0.2c0,1.3-0.9,0.7-1.5,0.7c-0.6,0-1,0-1.6,0.4
	c-1.1,0.7-4.4-0.8-4.7-2.1c-0.1-0.5,0-1.4,0.3-1.6c0.7-0.3,1.6-0.5,2.4-0.4c0.5,0,0.9,0.7,1.4,0.9c0.9,0.4,1.8,0.6,2.7,0.9
	c0-0.1,0.1-0.2,0.1-0.3c-1.2-0.5-2.4-1-3.6-1.5c0-0.1,0.1-0.3,0.1-0.4C80.7,121.9,82.5,123.7,84.7,123.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity175 ? this.state.opacity175 : 1}
              fill={this.state.color175 ? this.state.color175 : 'transparent'} onClick={() => {
          this.setState({ color175: color, opacity175: opacity });
        }} d="M69.7,124.6c-1.3,0.7-1.4,0.6-1.9-0.6c-0.9-2-0.6-4,0.1-5.9c0.3-0.8,0.8-1.4,2-1.3
	C68.6,119.4,68.1,122,69.7,124.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity176 ? this.state.opacity176 : 1}
              fill={this.state.color176 ? this.state.color176 : 'transparent'} onClick={() => {
          this.setState({ color176: color, opacity176: opacity });
        }} d="M120.7,153.7c1.5,0.1,3,0.4,4.5,0.4c1.5,0,2.9-0.4,4.4-0.8c-1-0.1-2.1-0.2-3.3-0.4c0.4-0.3,0.7-0.5,1-0.7
	c0-0.1,0-0.2,0-0.2c-1.4,0.2-2.9,0.2-4.3,0.5c-3,0.6-6,1.4-9,1.8c-2.5,0.4-5.1,0.1-7-1.9c-2-2-2.1-4.5-1.3-6.9c0.7-2,1.6-4,2.6-5.9
	c0.7-1.4,0.7-2.7,0.5-4.2c-0.3-3.1-0.1-6,2.8-7.9c1.4-0.9,3-1.8,4.6-1.8c2.8,0,5.5,0.9,7,3.7c0.6,1,1.1,2,1.8,2.9
	c0.5,0.7,1.1,1.4,1.8,1.9c3.1,2.1,6.1,4.2,8.4,7.2c1.6,2.1,2.5,4.7,2.7,7.3c0.2,2.3-1.3,3.9-3.4,5c-3.8,1.9-7.6,2.5-11.7,0.8
	c-0.4-0.2-0.8-0.2-1.1-0.3C121.3,154,121,153.9,120.7,153.7C120.7,153.7,120.7,153.7,120.7,153.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity177 ? this.state.opacity177 : 1}
              fill={this.state.color177 ? this.state.color177 : 'transparent'} onClick={() => {
          this.setState({ color177: color, opacity177: opacity });
        }} d="M136.8,123.5c-0.4,0.2-0.7,0.5-1,0.5c-1.9,0.5-3.8,1-5.7,1.6c-0.2,0.1-0.5,0.1-0.5,0.2c0,1.3-0.9,0.7-1.5,0.7
	c-0.5,0-1-0.1-1.6,0.3c-1.1,0.8-4.4-0.8-4.7-2.1c-0.1-0.5,0.1-1.4,0.4-1.6c0.7-0.3,1.6-0.4,2.3-0.4c0.5,0,0.9,0.7,1.4,0.9
	c0.9,0.4,1.8,0.6,2.7,0.9c0-0.1,0.1-0.3,0.1-0.4c-0.6-0.2-1.3-0.3-1.9-0.6c-0.6-0.3-1.2-0.7-1.7-1.1c2.2-0.4,4,1.2,6.2,0.9
	c-1.1-0.3-2.1-0.6-3.2-1c0-0.1,0-0.2,0-0.3c1.4,0.1,2.7,0.2,4.2,0.3c-0.9-0.7-0.9-0.7-4.7-0.7c-1.6,0-3.2,0.3-5,0.5
	c0.2-0.6,0.4-1,0.6-1.5c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3,0.4-0.6,0.9-0.9,1.3c-0.3,0.4-0.6,0.7-0.8,1.1c-0.3-0.2-0.7-0.4-0.9-0.7
	c-0.8-1-0.6-2.5,0.5-3.5c1.5-1.4,3.5-1.7,5.5-1.7c0.2,0,0.4,0.2,0.6,0.3c-0.1,0.4-0.2,0.7-0.3,1.2c0.6,0,1,0,1.5,0
	c1.2,0,2.4,0,3.5,0.1c0.4,0,0.8,0.4,1.2,0.6c-0.3,0.3-0.5,0.6-0.9,0.9C133.7,121,135.6,121.7,136.8,123.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity178 ? this.state.opacity178 : 1}
              fill={this.state.color178 ? this.state.color178 : 'transparent'} onClick={() => {
          this.setState({ color178: color, opacity178: opacity });
        }} d="M116.3,124.7c-1.1,0.6-1.5,0.4-1.9-0.6c-0.9-2.1-0.6-4,0.1-6c0.3-0.8,0.9-1.3,2-1.4
	C115.2,119.4,114.8,122,116.3,124.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity179 ? this.state.opacity179 : 1}
              fill={this.state.color179 ? this.state.color179 : 'transparent'} onClick={() => {
          this.setState({ color179: color, opacity179: opacity });
        }} d="M445.6,153.7c1.5,0.1,3.2,0.7,4.4,0.3c1.5-0.6,3.1,0.3,4.6-0.6c-1.3-0.2-2.3-0.3-3.5-0.4c0.5-0.3,0.7-0.5,1-0.6
	c0-0.1,0-0.2,0-0.3c-1.4,0.2-2.8,0.2-4.2,0.5c-2.6,0.5-5.2,1.2-7.8,1.7c-1.4,0.2-2.9,0.4-4.3,0.1c-4.8-0.8-6.6-4.4-5.2-8.9
	c0.6-1.9,1.5-3.8,2.4-5.6c0.7-1.4,0.7-2.8,0.7-4.3c0-1.7,0-3.5,0.4-5.1c0.8-3,4.4-4.9,6.8-4.8c2.8,0,5.6,1,7.1,3.8
	c0.6,1,1.1,2,1.8,3c0.5,0.7,1.1,1.3,1.7,1.8c3.1,2.1,6.1,4.2,8.4,7.2c1.6,2.1,2.5,4.7,2.7,7.3c0.2,2.3-1.3,4-3.4,5
	c-3.8,1.9-7.6,2.5-11.7,0.8c-0.7-0.3-1.4-0.4-2.1-0.6C445.6,153.8,445.6,153.7,445.6,153.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity180 ? this.state.opacity180 : 1}
              fill={this.state.color180 ? this.state.color180 : 'transparent'} onClick={() => {
          this.setState({ color180: color, opacity180: opacity });
        }} d="M457.3,122c-3.2-0.6-6.3-0.4-9.8,0.3c0.3-0.7,0.5-1.2,0.7-1.7c-0.1-0.1-0.2-0.1-0.2-0.2
	c-0.3,0.5-0.6,0.9-0.9,1.4c-0.2,0.3-0.5,0.9-0.8,0.9c-0.3,0-0.7-0.4-1-0.7c-0.7-0.9-0.5-1.9,0-2.8c1-1.7,4.7-2.9,6.6-2.1
	c-0.1,0.3-0.1,0.6-0.2,1.2c0.5,0,0.9,0,1.4,0c1.2,0,2.4,0,3.7,0.1c0.4,0,0.7,0.3,1.1,0.5c-0.2,0.3-0.5,0.6-0.7,0.9
	c1.5,1.1,3.1,2.1,4.8,3.3c-0.5,0.4-0.7,0.6-1,0.7c-1.9,0.6-3.9,1.1-5.8,1.7c-0.3,0.1-0.4,0.5-0.7,0.6c-0.3,0.2-0.6,0.3-0.9,0.3
	c-0.8,0-1.5-0.2-2.3,0.3c-1,0.6-4.1-0.7-4.5-1.8c-0.2-0.5-0.1-1.6,0.2-1.8c0.7-0.4,1.6-0.5,2.4-0.4c0.5,0,0.9,0.7,1.4,0.9
	c0.9,0.4,1.8,0.6,2.7,0.9c0-0.1,0.1-0.2,0.1-0.3c-1.2-0.5-2.4-1-3.6-1.5c0-0.1,0.1-0.3,0.1-0.4c2.1-0.2,4,1.4,6.2,1
	c-1.1-0.3-2.2-0.6-3.3-1c0-0.1,0-0.2,0-0.3c1.4,0.1,2.8,0.2,4.2,0.3C457.3,122.3,457.3,122.1,457.3,122z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity181 ? this.state.opacity181 : 1}
              fill={this.state.color181 ? this.state.color181 : 'transparent'} onClick={() => {
          this.setState({ color181: color, opacity181: opacity });
        }} d="M441.1,124.7c-0.9,0.5-1.4,0.5-1.8-0.6c-0.9-2-0.6-4,0.1-5.9c0.3-0.8,0.9-1.5,2-1.4
	C440.1,120.2,439.7,121,441.1,124.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity182 ? this.state.opacity182 : 1}
              fill={this.state.color182 ? this.state.color182 : 'transparent'} onClick={() => {
          this.setState({ color182: color, opacity182: opacity });
        }} d="M403.9,151.7c-3.2,0.7-6.3,1.4-9.5,2c-1.9,0.3-3.8,0.6-5.7,0.6c-5,0-8-4.3-6.2-9c0.7-1.9,1.5-3.8,2.4-5.6
	c0.7-1.4,0.7-2.8,0.6-4.3c0-1.6,0-3.2,0.3-4.7c0.7-3.1,4.2-5.1,6.9-5.1c2.8,0,5.6,0.9,7.2,3.7c0.6,1,1.1,2,1.8,3
	c0.5,0.7,1.1,1.4,1.8,1.9c3,2.1,6,4.2,8.3,7c1.7,2.1,2.5,4.8,2.8,7.4c0.2,2.3-1.2,3.9-3.2,4.9c-3.8,1.9-7.7,2.6-11.8,1
	c-0.4-0.1-0.8-0.2-1.1-0.3c-0.3-0.1-0.7-0.2-1-0.5c1.5,0.1,2.9,0.4,4.4,0.4c1.5,0,3-0.4,4.4-0.8c-1-0.1-2-0.2-3.2-0.4
	c0.4-0.3,0.7-0.5,1-0.7C403.9,152,403.9,151.8,403.9,151.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity183 ? this.state.opacity183 : 1}
              fill={this.state.color183 ? this.state.color183 : 'transparent'} onClick={() => {
          this.setState({ color183: color, opacity183: opacity });
        }} d="M403.7,117.2c-0.1,0.3-0.2,0.6-0.3,1.2c0.6,0,1,0,1.4,0c1.2,0,2.4,0,3.7,0.1c0.4,0,0.7,0.3,1.1,0.5
	c-0.3,0.3-0.5,0.6-0.7,0.9c1.5,1.1,3.1,2.1,4.8,3.3c-0.6,0.4-0.8,0.6-1,0.7c-1.9,0.6-3.9,1.1-5.8,1.7c-0.3,0.1-0.4,0.5-0.7,0.6
	c-0.3,0.2-0.6,0.3-0.9,0.3c-0.8,0-1.5-0.2-2.3,0.3c-1,0.6-4.1-0.7-4.5-1.8c-0.2-0.5-0.1-1.6,0.2-1.8c0.7-0.4,1.6-0.5,2.4-0.4
	c0.5,0,0.9,0.7,1.4,0.9c0.9,0.3,1.8,0.6,2.8,0.6c-1.2-0.5-2.4-1-3.6-1.6c0-0.1,0.1-0.3,0.1-0.4c2.1-0.2,4,1.4,6.2,1
	c-1.1-0.3-2.2-0.6-3.3-1c0-0.1,0-0.2,0-0.3c1.4,0.1,2.8,0.2,4.2,0.3c0-0.1,0-0.2,0-0.3c-3.2-0.6-6.3-0.4-9.8,0.3
	c0.3-0.7,0.5-1.2,0.7-1.7c-0.1-0.1-0.2-0.1-0.2-0.2c-0.3,0.5-0.6,0.9-0.9,1.4c-0.2,0.3-0.5,0.9-0.8,0.9c-0.3,0-0.7-0.4-1-0.7
	c-0.7-0.9-0.5-1.9,0-2.8C398.1,117.7,401.8,116.4,403.7,117.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity184 ? this.state.opacity184 : 1}
              fill={this.state.color184 ? this.state.color184 : 'transparent'} onClick={() => {
          this.setState({ color184: color, opacity184: opacity });
        }} d="M393,124.7c-0.9,0.6-1.4,0.6-1.9-0.6c-0.9-2-0.6-4,0.1-5.9c0.4-1,1-1.4,2-1.2
	C391.8,119.5,391.5,122.1,393,124.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity185 ? this.state.opacity185 : 1}
              fill={this.state.color185 ? this.state.color185 : 'transparent'} onClick={() => {
          this.setState({ color185: color, opacity185: opacity });
        }} d="M258.6,153.3c3,1.7,5.9-0.1,8.9,0.3c0-0.1,0-0.3,0-0.4c-1.1-0.1-2.1-0.3-3.4-0.4c0.5-0.3,0.8-0.5,1.1-0.7
	c0-0.1,0-0.2,0-0.3c-1.5,0.2-2.9,0.3-4.4,0.5c-3,0.6-6,1.3-9,1.8c-2.3,0.4-4.7,0.1-6.6-1.5c-2.1-1.7-2.7-4.9-1.5-7.8
	c0.7-1.8,1.5-3.5,2.3-5.2c0.7-1.4,0.7-2.9,0.6-4.4c0-1.6,0-3.2,0.4-4.7c0.8-3,4.2-5,6.9-5c2.8,0,5.5,0.9,7.1,3.6
	c0.6,1,1.1,2,1.8,2.9c0.5,0.7,1.1,1.5,1.9,1.9c3,2.1,6.1,4.2,8.3,7.1c1.7,2.1,2.5,4.8,2.7,7.4c0.2,2.2-1.3,3.8-3.2,4.8
	c-3.8,1.9-7.7,2.6-11.8,0.9c-0.7-0.3-1.4-0.4-2.1-0.7C258.6,153.6,258.6,153.5,258.6,153.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity186 ? this.state.opacity186 : 1}
              fill={this.state.color186 ? this.state.color186 : 'transparent'} onClick={() => {
          this.setState({ color186: color, opacity186: opacity });
        }} d="M260.5,122.3c0.3-0.7,0.4-1.1,0.6-1.6c-0.1-0.1-0.2-0.1-0.2-0.2c-0.3,0.5-0.6,0.9-0.9,1.4
	c-0.2,0.3-0.4,0.8-0.8,0.9c-0.2,0.1-0.7-0.3-0.9-0.6c-0.8-1-0.7-2.4,0.4-3.5c1.6-1.5,3.6-1.8,5.6-1.8c0.2,0,0.3,0.2,0.6,0.3
	c-0.1,0.3-0.2,0.6-0.4,1.1c0.5,0,1,0.1,1.4,0.1c1.3,0,2.5,0,3.8,0.1c0.3,0,0.7,0.3,1,0.5c-0.2,0.3-0.5,0.6-0.7,0.9
	c1.5,1,3.1,2.1,4.9,3.4c-0.6,0.4-0.9,0.6-1.2,0.7c-1.9,0.5-3.8,1.1-5.7,1.6c-0.2,0-0.4,0.1-0.4,0.1c-0.3,1.5-1.4,0.6-2.1,0.7
	c-0.2,0-0.4-0.1-0.6,0c-1.9,1.3-3.3,0-4.7-0.9c-0.7-0.5-0.9-1.3-0.4-2.1c0.4-0.7,2-1,2.7-0.6c0.5,0.3,0.9,0.7,1.4,0.9
	c0.9,0.3,1.8,0.6,2.7,0.8c0-0.1,0.1-0.2,0.1-0.3c-1.2-0.5-2.4-1-3.6-1.5c0-0.1,0.1-0.3,0.1-0.4c2.1-0.3,3.9,1.4,6.1,1
	c-1.1-0.3-2.2-0.7-3.3-1c0-0.1,0-0.2,0-0.3c1.4,0.1,2.8,0.2,4.3,0.3C269,121.4,267.1,121.4,260.5,122.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity187 ? this.state.opacity187 : 1}
              fill={this.state.color187 ? this.state.color187 : 'transparent'} onClick={() => {
          this.setState({ color187: color, opacity187: opacity });
        }} d="M254.1,124.6c-1.3,0.7-1.4,0.6-1.9-0.5c-1-2-0.7-4,0.1-5.9c0.3-0.8,0.9-1.5,2-1.4
	C253,119.5,252.7,122,254.1,124.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity188 ? this.state.opacity188 : 1}
              fill={this.state.color188 ? this.state.color188 : 'transparent'} onClick={() => {
          this.setState({ color188: color, opacity188: opacity });
        }} d="M167.4,153.3c3,1.7,5.9-0.1,8.9,0.3c0-0.1,0-0.3,0-0.4c-1.1-0.1-2.1-0.3-3.4-0.4c0.5-0.3,0.8-0.5,1.1-0.7
	c0-0.1,0-0.2,0-0.3c-1.5,0.2-2.9,0.3-4.4,0.5c-3,0.6-6,1.4-9,1.8c-2.4,0.4-4.7,0.1-6.7-1.6c-2.1-1.8-2.6-4.9-1.4-7.7
	c0.7-1.8,1.5-3.5,2.3-5.2c0.7-1.4,0.7-2.9,0.6-4.4c0-1.5,0-3.1,0.3-4.6c0.8-3.1,4.2-5.1,6.9-5.1c2.8,0,5.5,0.9,7.1,3.6
	c0.6,1,1.1,2,1.8,2.9c0.5,0.7,1.1,1.5,1.9,1.9c3.1,2.1,6.1,4.2,8.4,7.2c1.6,2.1,2.5,4.7,2.6,7.3c0.1,2.2-1.3,3.8-3.2,4.8
	c-3.8,1.9-7.7,2.6-11.8,0.9c-0.7-0.3-1.4-0.4-2.1-0.7C167.4,153.6,167.4,153.5,167.4,153.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity189 ? this.state.opacity189 : 1}
              fill={this.state.color189 ? this.state.color189 : 'transparent'} onClick={() => {
          this.setState({ color189: color, opacity189: opacity });
        }} d="M169.2,122.4c0.3-0.7,0.5-1.2,0.7-1.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3,0.5-0.6,1-0.9,1.4
	c-0.2,0.3-0.5,0.8-0.8,0.9c-0.2,0.1-0.7-0.3-0.9-0.6c-0.7-0.9-0.6-1.9,0-2.9c1-1.7,4.7-2.9,6.6-2.1c-0.1,0.3-0.2,0.6-0.3,1.2
	c0.5,0,1,0,1.4,0c1.2,0,2.4,0,3.7,0.1c0.4,0,0.7,0.4,1.1,0.5c-0.3,0.3-0.5,0.6-0.8,1c1.6,1,3.5,1.7,4.6,3.5
	c-0.4,0.2-0.8,0.5-1.1,0.6c-1.9,0.5-3.7,1-5.6,1.5c-0.1,0-0.3,0-0.3,0.1c-0.4,1.5-1.5,0.7-2.3,0.7c-0.2,0-0.4-0.1-0.5,0
	c-1.6,1.3-3,0.2-4.3-0.6c-1-0.6-1.3-1.6-0.8-2.4c0.5-0.8,2-1,2.9-0.6c1,0.5,2,1,3,1.4c0.3,0.1,0.7,0,1.2,0c-1.4-0.6-2.6-1.1-3.8-1.6
	c0-0.1,0.1-0.3,0.1-0.4c2.1-0.3,3.9,1.4,6.1,1c-1.1-0.3-2.2-0.7-3.3-1c0-0.1,0-0.2,0-0.3c1.4,0.1,2.8,0.2,4.2,0.3c0-0.1,0-0.2,0-0.3
	C175.8,121.5,172.7,121.7,169.2,122.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity190 ? this.state.opacity190 : 1}
              fill={this.state.color190 ? this.state.color190 : 'transparent'} onClick={() => {
          this.setState({ color190: color, opacity190: opacity });
        }} d="M163.1,116.9c-1.3,2.6-1.7,5.1-0.2,7.7c-1.3,0.7-1.4,0.6-1.9-0.5c-1-2-0.6-4,0.1-5.9
	C161.5,117.2,162.1,116.8,163.1,116.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity191 ? this.state.opacity191 : 1}
              fill={this.state.color191 ? this.state.color191 : 'transparent'} onClick={() => {
          this.setState({ color191: color, opacity191: opacity });
        }} d="M141,17.7c-0.3-2-1.4-3.6-2.1-5.4c-0.7-1.8-1.9-3-3.4-4.2c-0.4,1.9-1.3,2.5-3.5,2.7c-1.2,0.1-2.7-0.8-3-2
	c-0.2-0.7-0.2-1.7,0.1-2.3c0.3-0.6,1.2-0.9,1.8-1.4c-2.2-1.1-6.1-1.6-8.9-1c0.8,2.3-1,3.4-2.7,3.8c-2.3,0.6-3.9-0.3-4.3-2.3
	c-2.9,0.9-6.5,4-7.5,6.4c0.5,1.4,1.2,2.5,1.2,3.6c0,1.1-0.6,2.2-1,3.4c1.6,0.4,2.9,1.6,3.7,3.2c0.9,1.6,1.3,3.3,0,5.1
	c1.8,0.6,3.4,1.1,5,1.6c0.5-2.1,1.6-3.4,3.4-3.8c1.4-0.3,2.7-0.2,3.6,1.2l0,0c1.1,1.9,2.6,1.9,4.4,1.2c0.5-0.2,1-0.1,1.5-0.3
	c2.8-0.9,5.7-1.5,8.3-2.7C140.6,22.8,141.6,22.2,141,17.7z M112.8,12.5c-0.6,0.3-1.2,0.4-1.8,0.4c-0.2,0-0.3-0.7-0.3-1.1
	c0,0,0,0,0,0c0-0.1,0-0.3,0.1-0.4c0-0.2,0.2-0.4,0.5-0.4c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0,0.1,0,0.2-0.1C112.6,11.7,112.9,12.5,112.8,12.5z M113.5,21.7
	c-0.1,0-0.5-0.9-0.3-1.2c0.2-0.5,0.7-1,1.4-0.3l0,0c0.3,0.4,0.6,0.7,0.9,1.1C114.7,21.4,114.1,21.6,113.5,21.7z M120.6,21.3
	c-1.9,0-3.8-1.8-3.8-3.5c0.1-1.5,2.3-3.2,4-3.2c1.8,0.1,3.4,1.5,3.4,2.9C124.3,20,122.5,21.3,120.6,21.3z M124.8,11
	c-0.3,0.6-1.2,1-1.9,1.5c-1.3-0.3-2.2-1.1-2.2-2.1c0-1,0.8-1.8,1.9-1.7C123.5,8.7,125.2,10.3,124.8,11z M128.8,25.4
	c-0.6,0.1-1.3,0.2-1.7-0.1c-0.4-0.3-0.7-1.1,0.2-1.6l0,0c0.9-0.5,2-0.1,2.2,0.7C129.4,24.8,129,25.4,128.8,25.4z M129.1,19
	c-0.8,0-1.4-0.3-1.5-1.2c-0.1-0.7,0.9-1.6,1.6-1.4c0.6,0.2,1.3,0.8,1.4,1.3C130.7,18.5,130,19,129.1,19z M135.3,12.6
	c0.2-0.4,0.7-1,1.2-0.2c0.5,0.2,1.1,0.5,0.6,1.1c-0.4,0.4-1.1,0.3-1.7,0.5C135.3,13.5,135.1,13,135.3,12.6z M140.1,20.4
	c-0.6,1.3-1.7,2.2-2.9,2.1c-1.7-0.1-3.5-1.2-3.3-3c0.3-2.1,2-3.9,3.9-3.5c0.7,0.2,1.3,1,2,1.5l0,0C140.7,18.4,140.5,19.4,140.1,20.4
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity192 ? this.state.opacity192 : 1}
              fill={this.state.color192 ? this.state.color192 : 'transparent'} onClick={() => {
          this.setState({ color192: color, opacity192: opacity });
        }} d="M108.6,26.9c-0.2,0.6-0.4,1.2-0.5,1.7c-0.1,0-0.3,0.1-0.4,0.1c0.5-1.5-0.1-1.3-1.1-1.5
	c-1.7-0.2-3.4-0.8-4.3-2.6c-0.1-0.2-0.3-0.6-0.2-0.8c0.5-1.5,1-2.9,1.6-4.3c0.1-0.3,0.7-0.7,1.1-0.7c3-0.4,6.2,2.1,6.7,5.1
	c0.3,2-0.2,2.7-2.3,2.9C108.9,26.9,108.7,26.9,108.6,26.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity193 ? this.state.opacity193 : 1}
              fill={this.state.color193 ? this.state.color193 : 'transparent'} onClick={() => {
          this.setState({ color193: color, opacity193: opacity });
        }} d="M132.1,10.4c-1.3,0-2.8-1.1-3-1.9c-0.4-1.5,0.8-3.1,2.3-2.8c1.2,0.3,2.4,1,3.3,1.9c0.7,0.6,0.1,2-0.9,2.4
	C133.2,10.2,132.5,10.3,132.1,10.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity194 ? this.state.opacity194 : 1}
              fill={this.state.color194 ? this.state.color194 : 'transparent'} onClick={() => {
          this.setState({ color194: color, opacity194: opacity });
        }} d="M123.4,26.2L123.4,26.2c0.3,0.5,0.5,1.1,1,1.9c-2.5,0.2-4.8,0.5-7.2,0.7c-0.2-1.8,1.1-2.5,2.1-3.3
	c1-0.7,2.1-0.4,3.1,0.1C122.8,25.9,123.1,26.1,123.4,26.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity195 ? this.state.opacity195 : 1}
              fill={this.state.color195 ? this.state.color195 : 'transparent'} onClick={() => {
          this.setState({ color195: color, opacity195: opacity });
        }} d="M117.9,7.7c-0.5-0.2-1.4-0.5-2-1c-0.7-0.5-0.5-1.4,0.4-1.7c1.5-0.4,3-0.8,4.5-1.1c0.3-0.1,0.8,0.2,0.8,0.4
	c0.1,0.6,0.1,1.3-0.1,1.7C120.7,7.2,119.5,7.6,117.9,7.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity196 ? this.state.opacity196 : 1}
              fill={this.state.color196 ? this.state.color196 : 'transparent'} onClick={() => {
          this.setState({ color196: color, opacity196: opacity });
        }} d="M106.9,12.8c1.8,1.2,2,4.6,0,5.6c-0.4,0.2-1.3,0-1.7-0.3c-0.3-0.2-0.5-1-0.3-1.4
	C105.4,15.3,106.2,14.1,106.9,12.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity197 ? this.state.opacity197 : 1}
              fill={this.state.color197 ? this.state.color197 : 'transparent'} onClick={() => {
          this.setState({ color197: color, opacity197: opacity });
        }} d="M118,30.5c0.1-0.5,0.1-0.8,0.2-1.1c0.1,0,0.3,0,0.4-0.1c0.2,0.8,0.6,1.7,0.4,2.4c-0.3,1.2-1.6,0.8-2.4,0.9
	c-0.6,0-1.5-0.3-1.8-0.8c-0.4-0.8,0.6-1.7,1.6-1.6C116.9,30.2,117.4,30.4,118,30.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity198 ? this.state.opacity198 : 1}
              fill={this.state.color198 ? this.state.color198 : 'transparent'} onClick={() => {
          this.setState({ color198: color, opacity198: opacity });
        }} d="M126.9,29.5c0.1-0.5,0.2-1,0.4-1.5c0.1,0,0.2,0.1,0.3,0.1c0.2,0.9,0.3,1.7,0.5,2.6c0.2,0.7-0.2,1.1-0.8,1.2
	c-0.5,0.1-1.1,0.2-1.5,0c-0.5-0.2-1.1-0.6-1.2-1c-0.2-0.8,0.5-1.2,1.2-1.3C126.2,29.4,126.6,29.5,126.9,29.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity199 ? this.state.opacity199 : 1}
              fill={this.state.color199 ? this.state.color199 : 'transparent'} onClick={() => {
          this.setState({ color199: color, opacity199: opacity });
        }} d="M135,27.6c0.1-0.6,0.1-1.1,0.2-1.5c0.1,0,0.1,0,0.2,0c0.2,0.9,0.4,1.7,0.6,2.6c0.2,0.8-0.2,1.1-0.9,1.3
	c-0.4,0.1-0.7,0.1-1.1,0c-0.8-0.1-1.6-0.2-1.5-1.3c0.1-0.9,0.8-1.3,1.8-1.2C134.6,27.5,134.8,27.6,135,27.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity200 ? this.state.opacity200 : 1}
              fill={this.state.color200 ? this.state.color200 : 'transparent'} onClick={() => {
          this.setState({ color200: color, opacity200: opacity });
        }} d="M366.6,68.1c-0.9-1-2.1-1-3.3-0.1c-0.8,0.6-0.8,1.7,0.2,3.1c-2.1,1.8-4.8,2-7,2.5c-0.7-0.6-1.2-1.5-1.6-1.4
	c-0.8,0.2-1.4,0.8-2.1,1.3c-0.2,0.1-0.4,0.5-0.6,0.5c-4.1-0.1-8.1-0.5-11.5-2.1c-0.2-1.2-0.2-2.3-0.7-2.9c-0.6-0.6-2.3,0.2-2.9,1.2
	c-2.4-1.3-4.9-2.3-6.7-4.4c-1.7-2-2.3-4.1-1.5-6.6c0.7-2.3,1.9-4.3,3.8-5.8c2.8-2.3,5.5-4.6,8.3-6.8c1-0.8,2-1.4,3.4-1.2
	c0.3,0,0.7-0.4,1-0.7c0.2-0.1,0.3-0.3,0.5-0.4c5-1.9,10.1-2.3,15.2-0.5c2.7,1,4.5,3,6.2,5.2c0.3,0.4,0.6,0.8,0.8,1.1
	c-1.1,0.6-2.1,1-2.9,1.6c-0.3,0.2-0.3,1.1-0.2,1.6c0.3,1.8,1.7,3,3.1,2.7c1.1-0.2,1.3-0.4,1.2-1.3c-0.4,0.3-1,0.9-1.5,0.9
	c-0.6,0-1.3-0.5-1.7-1c-1.1-1.5-1-2.8,0.3-3.4c1.1-0.5,2.1-0.2,2.6,1c1,2.8,1.4,5.6,0.9,8.6C369.2,64,368.4,66,366.6,68.1z
	 M354.1,61.3c-1.1-0.2-2-0.2-2.3,0.8c-0.1,0.3,0.2,1,0.4,1c0.6,0.1,1.2,0,1.8-0.1c-0.1-0.5-0.2-0.9-0.3-1.5
	C353.6,61.5,353.8,61.5,354.1,61.3z M349.9,67.6c-0.7-0.4-0.1-1.8-1.4-1.4c-0.4,0.1-1,1-0.9,1.2C348.2,68.3,349,67.9,349.9,67.6z
	 M367.1,62c-0.5,0.5-0.7,0.9-1,1.1c-0.2,0.1-0.6,0-0.9,0c0.1-0.4,0.2-0.9,0.3-1.3c-0.1-0.1-0.1-0.2-0.2-0.2
	c-0.3,0.3-0.8,0.7-0.9,1.1c-0.1,0.3,0.3,1.1,0.5,1c0.7-0.1,1.4-0.2,1.9-0.5C367.1,63.1,367,62.5,367.1,62z M361.2,50.9
	c-0.8,0.4-1.3,0.7-1.8,0.9c-0.1,0-0.4-0.3-0.4-0.4c0.3-0.4,0.6-0.8,1-1.2c-0.5,0-1.1,0.1-1.4,0.5c-0.2,0.3-0.1,1.1,0.1,1.3
	c0.3,0.3,1,0.3,1.4,0.1C360.5,51.9,360.8,51.4,361.2,50.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity201 ? this.state.opacity201 : 1}
              fill={this.state.color201 ? this.state.color201 : 'transparent'} onClick={() => {
          this.setState({ color201: color, opacity201: opacity });
        }} d="M363.3,69.6c0-1.3,0.7-2,1.7-1.8c0.3,0,0.9,0.8,0.9,0.9c-0.5,0.6-1.1,1.2-1.8,1.8
	C364.1,70.4,363.5,69.7,363.3,69.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity202 ? this.state.opacity202 : 1}
              fill={this.state.color202 ? this.state.color202 : 'transparent'} onClick={() => {
          this.setState({ color202: color, opacity202: opacity });
        }} d="M339.8,71.4c-0.3-0.1-0.6-0.2-0.8-0.4c-0.4-0.4-1.6-0.3-1.1-1.1c0.2-0.4,1.3-0.4,1.9-0.6
	c0.1,0.5,0.2,1.1,0.3,1.6C340.1,71,339.9,71.2,339.8,71.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity203 ? this.state.opacity203 : 1}
              fill={this.state.color203 ? this.state.color203 : 'transparent'} onClick={() => {
          this.setState({ color203: color, opacity203: opacity });
        }} d="M353.2,73.6c0.2-0.8,0.8-1.1,1.5-1c0.2,0,0.3,0.5,0.5,0.8c-0.6,0.2-1.2,0.4-1.9,0.7
	C353.3,74,353.3,73.8,353.2,73.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity204 ? this.state.opacity204 : 1}
              fill={this.state.color204 ? this.state.color204 : 'transparent'} onClick={() => {
          this.setState({ color204: color, opacity204: opacity });
        }} d="M323.1,48.3c-2.8,0.4-3.7,1.1-3.5,2.6c0.2,1.8,1.6,3.2,3,3.1c1-0.1,1.7-0.4,1.5-1.4c-0.5,0.3-1.1,0.9-1.6,0.9
	c-0.6,0-1.4-0.6-1.7-1.1c-0.4-0.7-0.7-1.7-0.5-2.4c0.2-0.5,1.2-1,1.9-1c0.5,0,1.2,0.7,1.4,1.3c1.1,2.8,1.2,5.7,0.9,8.7
	c-0.3,2.8-1.6,5-3.2,7.2c-1.1-0.9-2.7-0.8-3.6,0.1c-0.6,0.6-0.5,1.8,0.4,2.9c-2,1.9-4.8,1.9-7.2,2.7c-1.2-2-2.3-2-3.6-0.1
	c-0.1,0.2-0.4,0.3-0.7,0.3c-4-0.1-8-0.6-11.7-2.3c-0.1-0.9,1-2-0.2-2.7c-0.9-0.5-2.4,0.2-2.9,1.2c-2.4-1.3-4.9-2.3-6.7-4.4
	c-1.7-2-2.3-4.1-1.5-6.6c0.8-2.4,2.1-4.3,4-6c3-2.8,5.9-5.6,9.6-7.4c0.5-0.2,1.1-0.2,1.6-0.3c0.5-0.1,1-0.1,1.4-0.7
	c0.4-0.6,1.5-0.9,2.4-1.1c4-1,7.9-1.3,11.9-0.3c2.4,0.6,4.3,2,6,3.8C321.3,46.1,322.1,47.1,323.1,48.3z M308.4,59.3
	c-1,0-1.9,0-2.1,1.1c-0.2,0.9,0.6,1,1.2,1C309.6,61.5,308,60.1,308.4,59.3z M321.7,60.4c-0.1,0-0.3-0.1-0.4-0.1c0,1-0.4,1.2-1.8,1
	c0.2-0.4,0.4-0.8,0.5-1.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3,0.3-0.7,0.6-0.8,0.9c0,0.4,0.2,1.2,0.4,1.2c0.6,0.1,1.2-0.1,1.8-0.4
	C321.5,61.4,321.6,60.8,321.7,60.4z M304.2,66.1c0.1-0.2,0.2-0.4,0.3-0.6c-0.5-0.4-0.9-0.8-1.4-1.2c-0.3,0.4-0.6,0.9-1,1.3
	C302.8,65.8,303.5,66,304.2,66.1z M315.6,49.1c-0.6,0.3-1,0.6-1.5,0.8c-0.1,0-0.4-0.3-0.4-0.3c0.3-0.4,0.6-0.8,1.2-1.5
	c-0.7,0.3-1.3,0.4-1.6,0.7c-0.2,0.3-0.3,1,0,1.3c0.3,0.3,0.9,0.4,1.4,0.3C315.2,50.2,315.7,49.8,315.6,49.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity205 ? this.state.opacity205 : 1}
              fill={this.state.color205 ? this.state.color205 : 'transparent'} onClick={() => {
          this.setState({ color205: color, opacity205: opacity });
        }} d="M320.6,66.9c-0.3,0.3-0.6,0.7-0.9,1c-0.4,0.4-0.8,1-1.5,0.3c-0.5-0.5-0.3-1.7,0.3-2.1
	C319.4,65.5,320.1,65.8,320.6,66.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity206 ? this.state.opacity206 : 1}
              fill={this.state.color206 ? this.state.color206 : 'transparent'} onClick={() => {
          this.setState({ color206: color, opacity206: opacity });
        }} d="M294.8,69.8c-0.7-0.3-1.1-0.4-1.3-0.6c-0.4-0.3-0.7-0.7-1.1-1.1c0.7-0.2,1.4-0.4,2.1-0.5
	C294.5,68.2,294.6,68.8,294.8,69.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity207 ? this.state.opacity207 : 1}
              fill={this.state.color207 ? this.state.color207 : 'transparent'} onClick={() => {
          this.setState({ color207: color, opacity207: opacity });
        }}
              d="M307.6,72.2c0.4-1.2,0.9-1.6,1.7-1.4c0.2,0.1,0.3,0.6,0.5,0.9C309.2,71.9,308.5,72.1,307.6,72.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity208 ? this.state.opacity208 : 1}
              fill={this.state.color208 ? this.state.color208 : 'transparent'} onClick={() => {
          this.setState({ color208: color, opacity208: opacity });
        }} d="M414.9,54.3c0.1,0,0.3,0.1,0.3,0.1c1.2,4.1-0.3,10.6-3.2,13.6c-0.9-0.9-2.4-0.9-3.4,0c-0.7,0.7-0.6,1.7,0.3,2.9
	c-1.3,1.4-3.9,2.3-7.3,2.6c-1.1-1.9-2.1-1.9-3.5,0c-0.1,0.2-0.4,0.3-0.6,0.3c-4.1-0.1-8.1-0.6-11.8-2.3c0-1,1-2-0.2-2.8
	c-0.8-0.5-2.4,0.2-2.9,1.2c-2.4-1.3-4.9-2.3-6.7-4.4c-1.7-2-2.3-4.1-1.5-6.6c0.7-2.3,1.9-4.3,3.8-5.8c2.8-2.3,5.6-4.6,8.4-6.9
	c0.9-0.7,2-1.3,3.3-1.1c0.3,0,0.7-0.6,1-0.7c0.7-0.4,1.5-0.8,2.3-1c4.1-1.1,8.3-1.4,12.4-0.3c3.2,0.9,5.3,3.1,7.3,5.6
	c0.2,0.2,0.3,0.4,0.5,0.7c0.1,0.2,0.3,0.4,0.5,0.8c-0.6,0.1-1.2,0-1.5,0.2c-0.7,0.4-1.6,0.9-1.8,1.5c-0.4,1.3,0.3,2.5,1.3,3.4
	c0.8,0.7,1.7,0.6,2.5,0.2C414.6,55.4,414.7,54.7,414.9,54.3L414.9,54.3z M399.2,61.2c-1,0-2,0-2.1,1.2c0,0.3,0.8,0.9,1.2,0.9
	C400.3,63.4,399,62,399.2,61.2z M395.1,67.7c0.1-0.1,0.1-0.2,0.2-0.3c-0.4-0.4-0.8-1.3-1.1-1.2c-0.5,0-0.9,0.7-1.4,1.1
	c0.1,0.1,0.1,0.3,0.2,0.4C393.7,67.7,394.4,67.7,395.1,67.7z M410.9,62c0-0.1-0.1-0.2-0.1-0.3c-0.3,0.3-0.8,0.5-0.9,0.8
	c-0.1,0.4,0.3,1.3,0.4,1.3c0.7-0.1,1.4-0.3,2-0.6c0.1-0.1,0-0.7,0-1c-0.3,0.4-0.5,0.8-0.9,1c-0.2,0.1-0.6,0-1,0
	C410.7,62.8,410.8,62.4,410.9,62z M405.4,50.3c0-0.1,0-0.2,0-0.2c-0.5,0.2-1.1,0.3-1.3,0.7c-0.2,0.3-0.1,1.1,0.1,1.3
	c0.3,0.2,1,0.3,1.4,0.1c0.4-0.2,0.6-0.7,1-1.2c-0.7,0.4-1.2,0.6-1.7,0.8c-0.1,0-0.4-0.3-0.4-0.4C404.7,51,405,50.6,405.4,50.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity209 ? this.state.opacity209 : 1}
              fill={this.state.color209 ? this.state.color209 : 'transparent'} onClick={() => {
          this.setState({ color209: color, opacity209: opacity });
        }} d="M414.9,54.3c-0.1,0-0.3,0-0.4,0.1c-0.2,0.3-0.3,0.7-0.6,0.8c-0.8,0.4-1.5,0.2-2.1-0.4c-0.8-0.8-1.4-2.6-0.9-3.1
	c0.6-0.5,1.4-0.8,2.2-0.9c0.3-0.1,0.9,0.5,1,0.8C414.5,52.5,414.7,53.4,414.9,54.3C414.9,54.3,414.9,54.3,414.9,54.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity210 ? this.state.opacity210 : 1}
              fill={this.state.color210 ? this.state.color210 : 'transparent'} onClick={() => {
          this.setState({ color210: color, opacity210: opacity });
        }}
              d="M411,67.7c0.4,1-0.2,2.4-1.2,2.6c-0.3,0-0.9-0.5-1-0.8C408.4,68.3,409.3,67.6,411,67.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity211 ? this.state.opacity211 : 1}
              fill={this.state.color211 ? this.state.color211 : 'transparent'} onClick={() => {
          this.setState({ color211: color, opacity211: opacity });
        }} d="M385.2,71.5c-0.4-0.2-0.7-0.3-1-0.5c-0.4-0.3-0.7-0.7-1.1-1c0.7-0.2,1.4-0.4,2-0.7c0.1,0.5,0.3,0.9,0.3,1.4
	C385.5,70.9,385.3,71.1,385.2,71.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity212 ? this.state.opacity212 : 1}
              fill={this.state.color212 ? this.state.color212 : 'transparent'} onClick={() => {
          this.setState({ color212: color, opacity212: opacity });
        }} d="M398.6,73.7c0.2-0.8,0.8-1.1,1.6-1c0.2,0,0.3,0.5,0.5,0.8c-0.6,0.2-1.3,0.4-1.9,0.6
	C398.7,74,398.7,73.8,398.6,73.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity213 ? this.state.opacity213 : 1}
              fill={this.state.color213 ? this.state.color213 : 'transparent'} onClick={() => {
          this.setState({ color213: color, opacity213: opacity });
        }} d="M460.3,54c0.2,1.6,0.7,3.1,0.6,4.7c-0.1,3.5-1,6.7-3.5,9.3c-1-0.9-2.4-0.9-3.4,0c-0.8,0.7-0.7,1.7,0.3,3
	c-2.1,1.8-4.7,1.9-7.1,2.6c-0.2-0.1-0.2-0.1-0.3-0.2c-0.4-0.4-0.9-1.3-1.2-1.3c-0.8,0.2-1.5,0.8-2.2,1.2c-0.2,0.1-0.4,0.5-0.7,0.5
	c-4.1-0.1-8-0.6-11.8-2.3c-0.1-1,1.1-2.1-0.3-2.8c-0.9-0.5-2.4,0.2-2.8,1.2c-2.8-1.3-5.6-2.6-7.4-5.2c-1.1-1.5-1.5-3-1.1-4.8
	c0.6-3,2.2-5.4,4.5-7.3c2.7-2.2,5.3-4.4,8.1-6.5c0.8-0.6,2-0.7,3.1-1c0.4-0.1,0.7-0.5,1.1-0.7c0.4-0.3,0.9-0.5,1.3-0.7
	c4.8-1.6,9.7-1.9,14.5-0.1c2.6,1,4.4,3,6,5.1c0.3,0.4,0.6,0.9,1.1,1.5c-0.6,0.1-1.1,0-1.4,0.2c-0.7,0.4-1.7,0.9-1.9,1.5
	c-0.4,1.3,0.3,2.5,1.3,3.4c0.7,0.7,1.7,0.7,2.4,0.2C460,55.3,460.1,54.5,460.3,54L460.3,54z M444.7,61.2c-1,0-2,0-2.1,1.1
	c-0.1,0.9,0.7,1,1.3,1C445.9,63.2,444.2,61.9,444.7,61.2z M440.5,68c0.1-0.2,0.2-0.3,0.3-0.5c-0.7-0.2-0.1-1.7-1.4-1.2
	c-0.4,0.1-0.6,0.8-0.9,1.2C439.1,67.6,439.8,67.8,440.5,68z M456.3,62c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3,0.3-0.8,0.6-0.8,0.9
	c-0.1,0.4,0.3,1.2,0.4,1.2c0.6,0,1.3-0.1,1.8-0.4c0.2-0.1,0.2-0.8,0.3-1.3c-0.5,0.5-0.7,0.9-1.1,1.2c-0.2,0.1-0.6,0-0.9,0
	C456.1,62.8,456.2,62.4,456.3,62z M451.9,51c-0.1,0-0.1,0-0.2,0c-0.5,0.3-1,0.5-1.5,0.8c-0.1,0-0.4-0.4-0.4-0.5
	c0.3-0.4,0.6-0.7,1.2-1.3c-0.7,0.3-1.3,0.3-1.6,0.6c-0.2,0.3-0.3,1.1-0.1,1.3c0.4,0.3,1,0.4,1.5,0.3C451.3,52,451.6,51.4,451.9,51z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity214 ? this.state.opacity214 : 1}
              fill={this.state.color214 ? this.state.color214 : 'transparent'} onClick={() => {
          this.setState({ color214: color, opacity214: opacity });
        }} d="M460.3,54c-0.1,0.2-0.2,0.4-0.4,0.5c-0.4,0.3-0.7,0.8-1.1,0.9c-1.1,0.2-2.3-1-2.5-2.7c-0.2-1,0.5-1.7,1.7-2
	c0.9-0.2,1.4,0.5,1.7,1C460,52.4,460.1,53.3,460.3,54L460.3,54z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity215 ? this.state.opacity215 : 1}
              fill={this.state.color215 ? this.state.color215 : 'transparent'} onClick={() => {
          this.setState({ color215: color, opacity215: opacity });
        }} d="M456.3,67.6c0.5,1.1-0.2,2.6-1.2,2.8c-0.3,0-0.9-0.4-0.9-0.6c0.1-0.6,0.2-1.3,0.6-1.7
	C455.1,67.7,455.8,67.7,456.3,67.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity216 ? this.state.opacity216 : 1}
              fill={this.state.color216 ? this.state.color216 : 'transparent'} onClick={() => {
          this.setState({ color216: color, opacity216: opacity });
        }} d="M430.6,71.4c-0.4-0.2-0.7-0.2-1-0.4c-0.4-0.3-0.7-0.7-1-1.1c0.7-0.2,1.3-0.4,2-0.5c0.1,0.5,0.3,0.9,0.3,1.4
	C430.9,70.9,430.7,71.1,430.6,71.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity217 ? this.state.opacity217 : 1}
              fill={this.state.color217 ? this.state.color217 : 'transparent'} onClick={() => {
          this.setState({ color217: color, opacity217: opacity });
        }} d="M444,73.6c0.2-0.8,0.8-1.1,1.6-1c0.2,0,0.3,0.5,0.5,0.8c-0.6,0.2-1.3,0.4-1.9,0.6
	C444.1,73.9,444.1,73.8,444,73.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity218 ? this.state.opacity218 : 1}
              fill={this.state.color218 ? this.state.color218 : 'transparent'} onClick={() => {
          this.setState({ color218: color, opacity218: opacity });
        }} d="M141,46.9c-2.8,0.4-3.6,1.1-3.3,2.7c0.3,1.8,1.6,3.1,2.9,3c1-0.1,1.8-0.4,1.5-1.3c-0.5,0.3-1.1,0.9-1.6,0.9
	c-0.6-0.1-1.3-0.6-1.7-1.1c-1.2-1.7-0.7-3.1,1-3.5c1-0.2,1.6,0.4,1.8,1.1c2,5.7,1.7,11.2-2.2,16.1c-1.1-0.9-2.7-0.8-3.6,0.1
	c-0.6,0.6-0.5,1.8,0.4,2.9c-2,1.9-4.8,1.9-7.2,2.7c-1.2-2-2.3-2-3.6-0.1c-0.1,0.2-0.4,0.3-0.5,0.3c-4.1-0.1-8.1-0.5-11.8-2.3
	c0-1,1-2-0.2-2.7c-0.8-0.5-2.3,0.2-3,1.2c-2.4-1.2-5-2.3-6.8-4.5c-1.6-1.9-2.2-3.9-1.4-6.3c0.7-2.5,2.1-4.5,4-6.2
	c3-2.8,5.9-5.6,9.6-7.4c0.5-0.2,1-0.2,1.6-0.3c0.6-0.1,1,0,1.4-0.7c0.4-0.6,1.4-0.8,2.2-1.1c4.1-1.1,8.3-1.4,12.4-0.3
	c3.2,0.9,5.4,3.1,7.3,5.7C140.4,46,140.7,46.4,141,46.9z M126.5,57.9c-1,0-2,0-2.1,1.1c0,0.3,0.6,0.9,1,1
	C127.4,60.2,126.4,58.8,126.5,57.9z M122.3,64.6c0.1-0.1,0.1-0.3,0.2-0.4c-0.4-0.4-0.1-1.6-1.2-1.3c-0.4,0.1-0.6,0.8-0.9,1.3
	C121,64.4,121.6,64.5,122.3,64.6z M132.6,47c0-0.1,0-0.2,0-0.3c-0.5,0.2-1.1,0.3-1.3,0.7c-0.2,0.3-0.1,1.2,0.1,1.2
	c0.5,0.2,1.1,0.3,1.6,0.1c0.3-0.1,0.5-0.7,0.8-1.1c-0.7,0.4-1.1,0.6-1.6,0.8c-0.1,0-0.4-0.4-0.4-0.4C132,47.7,132.3,47.3,132.6,47z
	 M139.8,58.6c-0.5,0.7-0.6,1.1-0.9,1.3c-0.3,0.2-0.7,0.1-1,0.1c0.1-0.4,0.2-0.9,0.3-1.3c-0.1-0.1-0.1-0.1-0.2-0.2
	c-0.3,0.3-0.7,0.7-0.8,1c0,0.4,0.4,1.1,0.6,1.1c0.6,0,1.3-0.2,1.8-0.6C139.7,59.8,139.7,59.2,139.8,58.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity219 ? this.state.opacity219 : 1}
              fill={this.state.color219 ? this.state.color219 : 'transparent'} onClick={() => {
          this.setState({ color219: color, opacity219: opacity });
        }} d="M136,66.3c0-1.3,0.6-2,1.7-1.9c0.4,0,0.9,0.8,0.9,0.9c-0.5,0.7-1.1,1.3-1.8,1.8C136.8,67.1,136.2,66.4,136,66.3
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity220 ? this.state.opacity220 : 1}
              fill={this.state.color220 ? this.state.color220 : 'transparent'} onClick={() => {
          this.setState({ color220: color, opacity220: opacity });
        }} d="M112.5,68.2c-0.4-0.2-0.8-0.3-1-0.5c-0.4-0.3-1.5-0.3-1-1.1c0.3-0.4,1.4-0.7,1.9-0.5
	C113.3,66.5,112.7,67.3,112.5,68.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity221 ? this.state.opacity221 : 1}
              fill={this.state.color221 ? this.state.color221 : 'transparent'} onClick={() => {
          this.setState({ color221: color, opacity221: opacity });
        }}
              d="M125.8,70.8c0.3-1.2,0.9-1.6,1.7-1.4c0.2,0,0.3,0.6,0.5,0.9C127.3,70.4,126.6,70.6,125.8,70.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity222 ? this.state.opacity222 : 1}
              fill={this.state.color222 ? this.state.color222 : 'transparent'} onClick={() => {
          this.setState({ color222: color, opacity222: opacity });
        }} d="M233.2,50.4c0.2,1.5,0.6,3,0.6,4.5c-0.1,3.5-1,6.7-3.5,9.3c-1-0.8-2.6-0.8-3.5,0.1c-0.7,0.7-0.6,1.7,0.4,2.9
	c-2.1,1.9-4.8,2-7,2.6c-0.7-0.6-1.3-1.5-1.7-1.4c-0.8,0.2-1.4,0.8-2.1,1.3c-0.2,0.1-0.4,0.5-0.6,0.4c-4.1-0.1-8.1-0.5-11.8-2.3
	c0-1,1.1-2.1-0.3-2.8c-0.9-0.4-2.4,0.3-2.8,1.2c-2.8-1.3-5.6-2.6-7.5-5.3c-1-1.5-1.4-3-1-4.7c0.6-2.5,1.6-4.7,3.6-6.4
	c2.9-2.4,5.7-4.8,8.6-7.2c1-0.8,2.1-1.4,3.5-1.3c0.3,0,0.6-0.5,1-0.7c0.2-0.1,0.3-0.3,0.5-0.4c5.1-1.8,10.2-2.3,15.3-0.4
	c2.6,1,4.4,3,6.1,5.2c0.3,0.4,0.6,0.8,0.9,1.3c-0.8,0.2-1.5,0.3-2.2,0.6c-1,0.4-1.3,1.2-1.2,2.2c0.2,1.4,1.3,2.7,2.3,2.9
	C232.4,52.4,233.1,51.8,233.2,50.4L233.2,50.4z M217.5,57.4c-0.9,0.2-2,0.1-2,1.3c0,0.3,0.6,0.8,1,0.9
	C218.7,59.9,217.4,58.4,217.5,57.4z M229.3,58c-0.4,0.2-1,0.3-1,0.5c-0.1,0.5,0.1,1,0.2,1.5c0.7-0.2,1.4-0.3,2-0.6
	c0.2-0.1,0.1-0.7,0.1-1.3c-0.4,0.6-0.5,1.1-0.8,1.2c-0.3,0.2-0.7,0-1.1,0.1C229,59.1,229.1,58.7,229.3,58z M213.4,64.3
	c0.1-0.2,0.1-0.3,0.2-0.5c-0.6-0.3-0.1-1.8-1.4-1.2c-0.4,0.2-0.6,0.8-0.8,1.2C212,64,212.7,64.1,213.4,64.3z M223.7,46.6
	c0-0.1,0-0.1,0-0.2c-0.4,0.2-1,0.3-1.3,0.6c-0.2,0.3-0.2,1,0,1.3c0.2,0.3,0.8,0.4,1.3,0.3c0.6-0.1,1.2-0.5,1.1-1.1
	c-0.6,0.2-1.1,0.4-1.6,0.6c0,0-0.3-0.4-0.3-0.4C223.1,47.2,223.4,46.9,223.7,46.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity223 ? this.state.opacity223 : 1}
              fill={this.state.color223 ? this.state.color223 : 'transparent'} onClick={() => {
          this.setState({ color223: color, opacity223: opacity });
        }} d="M233.3,50.4c-0.1,0-0.3,0.1-0.3,0.1c-0.1,0.9-0.7,1.4-1.4,1.2c-0.6-0.1-1.3-0.6-1.7-1.1c-1.2-1.7-0.8-3,0.8-3.5
	c1-0.3,1.6,0.3,1.9,0.9C233,48.8,233,49.6,233.3,50.4C233.2,50.4,233.3,50.4,233.3,50.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity224 ? this.state.opacity224 : 1}
              fill={this.state.color224 ? this.state.color224 : 'transparent'} onClick={() => {
          this.setState({ color224: color, opacity224: opacity });
        }} d="M227,65.9c0-1.3,0.7-1.9,1.7-1.8c0.3,0,0.6,0.6,0.9,0.9c-0.6,0.6-1.2,1.2-1.8,1.7C227.8,66.7,227.2,66,227,65.9
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity225 ? this.state.opacity225 : 1}
              fill={this.state.color225 ? this.state.color225 : 'transparent'} onClick={() => {
          this.setState({ color225: color, opacity225: opacity });
        }} d="M203.6,67.8c-0.5-0.2-0.8-0.3-1.1-0.5c-0.4-0.3-0.7-0.7-1-1.1c0.7-0.2,1.3-0.4,2-0.5c0.1,0,0.3,0.8,0.4,1.2
	C203.9,67.1,203.7,67.4,203.6,67.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity226 ? this.state.opacity226 : 1}
              fill={this.state.color226 ? this.state.color226 : 'transparent'} onClick={() => {
          this.setState({ color226: color, opacity226: opacity });
        }} d="M216.8,69.6c0.6-0.2,1.2-0.5,1.8-0.6c0.1,0,0.3,0.5,0.4,0.8c-0.6,0.2-1.3,0.4-1.9,0.5
	C217,70.1,216.9,69.9,216.8,69.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity227 ? this.state.opacity227 : 1}
              fill={this.state.color227 ? this.state.color227 : 'transparent'} onClick={() => {
          this.setState({ color227: color, opacity227: opacity });
        }} d="M94.6,50.6c0.2,1.7,0.6,3.4,0.6,5.1c-0.1,3-0.9,5.9-2.9,8.3c-0.2,0.2-0.4,0.5-0.6,0.7c-0.9-1-2.3-1-3.4-0.1
	c-0.8,0.7-0.7,1.7,0.3,3c-2.1,1.8-4.8,2-7,2.6c-0.7-0.6-1.3-1.5-1.7-1.4c-0.8,0.2-1.4,0.9-2.1,1.3c-0.2,0.1-0.4,0.4-0.6,0.4
	c-2.9,0.3-9.2-0.9-11.8-2.4c-0.1-0.9,1.1-2-0.3-2.7c-0.9-0.4-2.3,0.2-2.8,1.2c-2.8-1.3-5.7-2.6-7.5-5.3c-1-1.4-1.4-2.9-1.1-4.6
	c0.6-3,2.1-5.4,4.4-7.3c2.7-2.2,5.3-4.5,8.1-6.5c0.9-0.6,2.1-0.7,3.2-1.1c0.4-0.1,0.7-0.5,1.1-0.8c0.2-0.1,0.4-0.3,0.6-0.4
	c5-1.8,10.1-2.3,15.2-0.5c2.7,1,4.5,3,6.2,5.2c0.3,0.4,0.6,0.8,1,1.4c-0.6,0-0.9,0.1-1.3,0.1c-1.4,0.2-2.2,1-2.2,2.2
	c0.1,1.6,1.4,3.2,2.7,3.3C94,52.6,94.5,52.2,94.6,50.6L94.6,50.6z M78.9,57.9c-1.1,0-2,0-2.2,1.2c-0.1,0.9,0.7,1,1.3,1
	C80.2,59.9,78.4,58.6,78.9,57.9z M74.7,64.7c0.1-0.2,0.1-0.3,0.2-0.5c-0.5-0.4-0.1-1.7-1.4-1.2c-0.4,0.2-0.6,0.8-0.9,1.3
	C73.3,64.4,74,64.5,74.7,64.7z M85.1,46.9c-0.8-0.3-1.4,0-1.6,0.9c-0.1,0.7,0.2,1.1,1,1.2c0.9,0.1,1.5-0.5,1.5-1.3
	c-0.4,0.2-0.9,0.5-1.4,0.7c-0.1,0-0.4-0.4-0.4-0.4C84.4,47.6,84.7,47.3,85.1,46.9z M90.6,58.7c-0.1-0.1-0.1-0.2-0.2-0.3
	c-0.3,0.3-0.8,0.5-0.8,0.8c-0.1,0.4,0.1,1.2,0.3,1.2c0.6,0.1,1.2,0.1,1.8-0.2c0.3-0.2,0.4-0.8,0.6-1.3c-0.6,0.5-0.9,0.8-1.3,1
	c-0.2,0.1-0.5-0.1-0.7-0.2C90.3,59.4,90.5,59,90.6,58.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity228 ? this.state.opacity228 : 1}
              fill={this.state.color228 ? this.state.color228 : 'transparent'} onClick={() => {
          this.setState({ color228: color, opacity228: opacity });
        }} d="M94.6,50.6c-0.1,0.2-0.3,0.5-0.4,0.7c-0.2,0.3-0.6,0.8-0.8,0.7c-0.7-0.1-1.5-0.2-1.9-0.6
	c-0.7-0.8-1.4-1.8-0.8-2.9c0.3-0.5,1.3-0.9,2-1c0.4,0,1,0.6,1.3,1.1C94.3,49.2,94.4,49.9,94.6,50.6C94.6,50.6,94.6,50.6,94.6,50.6z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity229 ? this.state.opacity229 : 1}
              fill={this.state.color229 ? this.state.color229 : 'transparent'} onClick={() => {
          this.setState({ color229: color, opacity229: opacity });
        }} d="M88.3,66.3c0-1.3,0.7-2,1.7-1.9c0.3,0,0.9,0.8,0.9,0.9c-0.5,0.7-1.1,1.3-1.8,1.8C89.2,67.1,88.5,66.4,88.3,66.3
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity230 ? this.state.opacity230 : 1}
              fill={this.state.color230 ? this.state.color230 : 'transparent'} onClick={() => {
          this.setState({ color230: color, opacity230: opacity });
        }} d="M65,68.3c-0.8-0.4-1.4-0.6-1.9-1c-0.2-0.1-0.1-0.9-0.1-0.9c0.7-0.1,1.4-0.1,2-0.2C65.1,66.8,65.1,67.5,65,68.3z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity231 ? this.state.opacity231 : 1}
              fill={this.state.color231 ? this.state.color231 : 'transparent'} onClick={() => {
          this.setState({ color231: color, opacity231: opacity });
        }} d="M78.2,70.3c0.3-0.7,0.8-1.1,1.7-0.9c0.2,0.1,0.3,0.6,0.5,0.8c-0.7,0.2-1.3,0.4-2,0.5
	C78.3,70.6,78.3,70.5,78.2,70.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity232 ? this.state.opacity232 : 1}
              fill={this.state.color232 ? this.state.color232 : 'transparent'} onClick={() => {
          this.setState({ color232: color, opacity232: opacity });
        }} d="M246.3,66.8c-2.5-1.3-5-2.3-6.8-4.5c-1.7-2-2.2-4-1.5-6.5c0.7-2.4,2.1-4.3,3.9-6c3-2.8,6-5.6,9.7-7.5
	c0.5-0.2,1.1-0.2,1.6-0.3c0.5-0.1,1.3,0.2,1.3-0.8c0-0.1,0.3-0.3,0.5-0.4c5-1.8,10.1-2.3,15.2-0.5c2.7,1,4.5,3,6.2,5.1
	c0.3,0.4,0.6,0.9,1,1.4c-2.7,0.4-3.5,1-3.4,2.5c0.2,1.7,1.6,3.2,2.9,3.1c0.9-0.1,1.8-0.3,1.6-1.4c-0.6,0.3-1.2,1-1.7,0.9
	c-0.6-0.1-1.4-0.7-1.7-1.2c-0.4-0.7-0.6-1.7-0.3-2.4c0.2-0.5,1.2-0.9,1.9-0.9c0.5,0,1.1,0.7,1.4,1.2c1.1,2.8,1.2,5.7,0.9,8.7
	c-0.3,2.9-1.7,5.1-3.2,7.3c-0.9-1-2.5-1-3.5,0c-0.7,0.7-0.6,1.7,0.3,3c-2.1,1.9-4.8,1.9-7,2.6c-0.7-0.6-1.3-1.5-1.7-1.4
	c-0.8,0.2-1.4,0.8-2.1,1.3c-0.2,0.1-0.4,0.4-0.6,0.4c-4.1-0.1-8-0.5-11.8-2.3c-0.1-1,1.1-2.1-0.3-2.8
	C248.3,65.1,246.9,65.8,246.3,66.8z M263,57.8c-1,0.1-2,0-2.1,1.2c-0.1,0.8,0.6,0.9,1.2,1C264.1,60.1,262.8,58.7,263,57.8z
	 M258.8,64.6c0.1-0.2,0.1-0.3,0.2-0.5c-0.6-0.3-0.1-1.7-1.4-1.2c-0.4,0.2-0.6,0.8-0.8,1.3C257.5,64.4,258.1,64.5,258.8,64.6z
	 M269.1,47c0-0.1,0-0.2,0-0.2c-0.5,0.2-1.1,0.3-1.3,0.6c-0.2,0.3-0.1,1.2,0.1,1.2c0.5,0.2,1.1,0.3,1.6,0.1c0.3-0.1,0.5-0.7,0.8-1.1
	c-0.7,0.3-1.2,0.6-1.7,0.8c-0.1,0-0.4-0.4-0.3-0.4C268.5,47.7,268.8,47.3,269.1,47z M274.7,58.7c-0.1-0.1-0.1-0.2-0.2-0.3
	c-0.3,0.3-0.8,0.5-0.8,0.8c-0.1,0.4,0.1,1.2,0.3,1.2c0.6,0.1,1.3,0,1.8-0.2c0.3-0.2,0.4-0.8,0.5-1.2c-0.1,0-0.2-0.1-0.3-0.1
	c-0.1,1.2-0.8,1.3-1.9,1.1C274.4,59.4,274.5,59.1,274.7,58.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity233 ? this.state.opacity233 : 1}
              fill={this.state.color233 ? this.state.color233 : 'transparent'} onClick={() => {
          this.setState({ color233: color, opacity233: opacity });
        }} d="M274.6,64.3c0.5,1.1-0.1,2.6-1.1,2.8c-0.3,0.1-0.9-0.3-0.9-0.5c0.1-0.6,0.2-1.4,0.5-1.9
	C273.4,64.4,274.1,64.4,274.6,64.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity234 ? this.state.opacity234 : 1}
              fill={this.state.color234 ? this.state.color234 : 'transparent'} onClick={() => {
          this.setState({ color234: color, opacity234: opacity });
        }} d="M249,68.2c-0.7-0.3-1.3-0.6-1.9-0.9c-0.1-0.1-0.2-0.4-0.2-0.6c0.6-0.2,1.3-0.5,1.9-0.6c0.1,0,0.4,0.7,0.5,1.1
	C249.4,67.4,249.2,67.7,249,68.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity235 ? this.state.opacity235 : 1}
              fill={this.state.color235 ? this.state.color235 : 'transparent'} onClick={() => {
          this.setState({ color235: color, opacity235: opacity });
        }} d="M262.2,70c0.6-0.2,1.2-0.5,1.8-0.6c0.1,0,0.3,0.6,0.4,0.8c-0.7,0.2-1.3,0.3-2,0.5
	C262.4,70.5,262.3,70.3,262.2,70z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity236 ? this.state.opacity236 : 1}
              fill={this.state.color236 ? this.state.color236 : 'transparent'} onClick={() => {
          this.setState({ color236: color, opacity236: opacity });
        }} d="M187.5,51.1c-0.5,0.3-1,0.9-1.5,0.9c-0.6,0-1.2-0.5-1.7-0.9c-0.3-0.3-0.5-0.8-0.7-1.3c-0.4-1.1,0-1.8,0.9-2.3
	c1.1-0.4,2-0.1,2.4,0.9c1,2.6,1.3,5.4,1,8.2c-0.2,3-1.5,5.6-3.3,7.9c-1-0.9-2.7-0.8-3.6,0.1c-0.7,0.7-0.5,1.8,0.4,2.9
	c-2.1,1.9-4.8,2-7,2.6c-0.7-0.6-1.3-1.5-1.6-1.4c-0.8,0.2-1.4,0.8-2.1,1.3c-0.2,0.2-0.5,0.4-0.7,0.4c-3.9-0.1-7.8-0.6-11.5-2.2
	c-0.1,0-0.2-0.1-0.3-0.2c0-0.9,1-2-0.2-2.7c-0.8-0.5-2.3,0.2-3,1.2c-2.4-1.3-4.9-2.3-6.7-4.4c-1.7-2-2.3-4.1-1.5-6.6
	c0.8-2.4,2.1-4.3,4-6c3-2.8,6-5.6,9.7-7.5c0.4-0.2,0.9-0.2,1.4-0.3c0.6-0.1,1.2,0.1,1.6-0.8c0.3-0.6,1.5-0.8,2.3-1
	c4-1.1,8-1.3,12.1-0.3c2.3,0.6,4.3,2,5.8,3.8c0.9,1,1.7,2,2.4,2.9c-1.1,0.6-2.2,1.1-3,1.8c-0.3,0.2-0.2,1.2,0,1.7
	c0.5,1.8,1.7,2.7,3.1,2.4C187.3,52.3,187.7,51.9,187.5,51.1z M171.7,57.8c-0.4,0.1-1.1,0.1-1.5,0.5c-0.3,0.2-0.2,0.9-0.2,1.3
	c0.6,0.1,1.3,0.2,1.9,0.2c0-0.4,0-0.8,0-1.2C171.8,58.5,171.8,58.3,171.7,57.8z M185.2,58.9c-0.1,0-0.2,0.1-0.3,0.1
	c-0.3,0.3-0.5,0.7-0.8,0.9c-0.2,0.1-0.6,0-0.9,0c0.1-0.4,0.2-0.8,0.3-1.2c-0.4,0-0.9,0.2-1,0.5c-0.1,0.4,0.1,1.2,0.2,1.2
	c0.7,0,1.4,0,2-0.3C185.1,59.9,185.1,59.3,185.2,58.9z M168.1,64.4c-0.5-0.7-0.8-1.5-1.3-1.5c-0.8-0.1-1.2,0.6-1.3,1.5
	C166.4,64.4,167.1,64.4,168.1,64.4z M179,47.7c-0.5,0.3-1,0.6-1.5,0.8c-0.1,0-0.4-0.4-0.4-0.4c0.3-0.4,0.6-0.7,1-1
	c-0.5-0.1-1,0-1.3,0.3c-0.2,0.3-0.3,1-0.1,1.3c0.3,0.3,0.9,0.4,1.3,0.4C178.7,48.8,179.2,48.3,179,47.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity237 ? this.state.opacity237 : 1}
              fill={this.state.color237 ? this.state.color237 : 'transparent'} onClick={() => {
          this.setState({ color237: color, opacity237: opacity });
        }} d="M181.4,66.3c0-1.3,0.6-2,1.7-1.9c0.3,0,1,0.8,0.9,0.8c-0.5,0.7-1.1,1.3-1.7,1.8
	C182.2,67.1,181.6,66.5,181.4,66.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity238 ? this.state.opacity238 : 1}
              fill={this.state.color238 ? this.state.color238 : 'transparent'} onClick={() => {
          this.setState({ color238: color, opacity238: opacity });
        }} d="M158.1,68.3c-0.9-0.4-1.4-0.7-1.9-1c-0.6-0.4-0.5-0.8,0.1-1c0.6-0.2,1.2-0.1,1.8-0.2
	C158.1,66.8,158.1,67.4,158.1,68.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity239 ? this.state.opacity239 : 1}
              fill={this.state.color239 ? this.state.color239 : 'transparent'} onClick={() => {
          this.setState({ color239: color, opacity239: opacity });
        }} d="M171.3,70.3c0.2-0.8,0.8-1.1,1.6-1c0.2,0,0.3,0.5,0.5,0.8c-0.6,0.2-1.3,0.4-1.9,0.6
	C171.4,70.6,171.3,70.5,171.3,70.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity240 ? this.state.opacity240 : 1}
              fill={this.state.color240 ? this.state.color240 : 'transparent'} onClick={() => {
          this.setState({ color240: color, opacity240: opacity });
        }} d="M46.1,45.7c-2.8,0.4-3.6,1-3.4,2.6c0.2,1.7,1.5,3.1,2.9,3c0.9-0.1,1.8-0.3,1.5-1.4c-0.5,0.3-1.1,0.9-1.6,0.9
	c-0.6,0-1.3-0.5-1.7-1c-1.2-1.7-0.8-3.2,0.9-3.5c1.2-0.2,1.7,0.5,2,1.5c1,2.8,1.1,5.7,0.8,8.7c-0.3,2.8-1.7,4.9-3.2,7.1
	c-0.9-1-2.5-0.9-3.5,0c-0.7,0.7-0.6,1.8,0.4,2.9c-2.1,1.9-4.8,2-7.3,2.7c-1-2-2.3-2-3.5-0.2c-0.1,0.2-0.4,0.4-0.6,0.4
	c-2.6,0.4-9.2-1-11.8-2.4c-0.1-0.9,1-2-0.3-2.7c-0.9-0.4-2.3,0.2-2.9,1.2c-2.4-1.3-5-2.3-6.8-4.5c-1.7-2-2.2-4-1.4-6.5
	c0.7-2.3,1.9-4.3,3.8-5.8c2.8-2.3,5.6-4.7,8.4-6.9c0.9-0.7,2-1.3,3.3-1.1c0.3,0,0.7-0.4,1-0.7c0.2-0.1,0.3-0.4,0.5-0.4
	c5-1.8,10-2.3,15.1-0.6c2.8,1,4.7,3.1,6.4,5.3C45.4,44.7,45.7,45.1,46.1,45.7z M31.6,56.9c-0.9-0.2-1.8-0.3-2.1,0.7
	c-0.1,0.3,0.3,0.9,0.5,1c0.5,0.1,1.1,0,1.7-0.1c-0.1-0.5-0.2-0.9-0.2-1.5C31.3,57,31.4,56.9,31.6,56.9z M27.6,63.1
	c-0.6-0.5-0.2-1.8-1.4-1.4c-0.4,0.1-1,1-0.9,1.2C25.9,63.7,26.7,63.4,27.6,63.1z M44.8,57.5c-0.5,0.5-0.7,0.9-1,1.1
	c-0.2,0.1-0.6,0-0.9,0c0.1-0.4,0.2-0.9,0.3-1.3c-0.1-0.1-0.1-0.2-0.2-0.2c-0.3,0.3-0.8,0.6-0.8,0.9c-0.1,0.4,0.3,1.2,0.4,1.2
	c0.7,0,1.4-0.2,1.9-0.5C44.7,58.6,44.7,58,44.8,57.5z M37.6,45.8c0-0.1,0-0.2,0-0.3c-0.5,0.2-1.1,0.3-1.3,0.7
	c-0.2,0.3-0.1,1.2,0.1,1.2c0.5,0.2,1.1,0.3,1.6,0.1c0.3-0.1,0.5-0.7,0.7-1C38,46.8,37.5,47,37,47.2c-0.1,0-0.3-0.4-0.3-0.4
	C37,46.4,37.3,46.1,37.6,45.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity241 ? this.state.opacity241 : 1}
              fill={this.state.color241 ? this.state.color241 : 'transparent'} onClick={() => {
          this.setState({ color241: color, opacity241: opacity });
        }}
              d="M41,65c0-1.3,0.7-2,1.7-1.8c0.3,0,0.9,0.9,0.9,0.9c-0.5,0.6-1.1,1.2-1.8,1.8C41.8,65.9,41.2,65.2,41,65z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity242 ? this.state.opacity242 : 1}
              fill={this.state.color242 ? this.state.color242 : 'transparent'} onClick={() => {
          this.setState({ color242: color, opacity242: opacity });
        }} d="M17.7,67.1c-0.9-0.4-1.4-0.7-1.9-1c-0.6-0.4-0.5-0.8,0.1-1c0.6-0.2,1.2-0.1,1.8-0.2
	C17.7,65.5,17.7,66.2,17.7,67.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity243 ? this.state.opacity243 : 1}
              fill={this.state.color243 ? this.state.color243 : 'transparent'} onClick={() => {
          this.setState({ color243: color, opacity243: opacity });
        }} d="M31,69.5c0.1-1.2,0.7-1.6,1.5-1.4c0.2,0,0.3,0.5,0.5,0.8C32.3,69.2,31.7,69.4,31,69.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity244 ? this.state.opacity244 : 1}
              fill={this.state.color244 ? this.state.color244 : 'transparent'} onClick={() => {
          this.setState({ color244: color, opacity244: opacity });
        }} d="M123.9,17.7c0.2,1.8-1.8,3.3-3.4,3.2c-1.6-0.1-3.3-1.7-3.3-3c0-1.3,2.3-3.1,3.8-2.9
	C122.6,15.1,124,16.4,123.9,17.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity245 ? this.state.opacity245 : 1}
              fill={this.state.color245 ? this.state.color245 : 'transparent'} onClick={() => {
          this.setState({ color245: color, opacity245: opacity });
        }} d="M139.7,17.5c0.4,1.1,0.5,2.1-0.1,3.1c-0.9,1.5-2.6,1.9-4.2,1c-1.3-0.8-1.5-2.3-0.5-3.9c1-1.5,2.4-1.8,3.9-0.8
	C139.1,17.1,139.4,17.4,139.7,17.5C139.7,17.6,139.7,17.5,139.7,17.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity246 ? this.state.opacity246 : 1}
              fill={this.state.color246 ? this.state.color246 : 'transparent'} onClick={() => {
          this.setState({ color246: color, opacity246: opacity });
        }} d="M122.9,12c-1.1-0.3-1.7-0.6-1.8-1.6c0-0.9,0.9-1.9,1.6-1.5c0.7,0.4,1.2,1.3,1.8,1.9
	C124,11.2,123.5,11.6,122.9,12z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity247 ? this.state.opacity247 : 1}
              fill={this.state.color247 ? this.state.color247 : 'transparent'} onClick={() => {
          this.setState({ color247: color, opacity247: opacity });
        }}
              d="M129.1,19c-0.5-0.5-1.1-1-1-1.2c0.2-0.5,0.7-1,1.1-1.1c0.3-0.1,1,0.6,1,1C130.2,18,129.6,18.4,129.1,19z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity248 ? this.state.opacity248 : 1}
              fill={this.state.color248 ? this.state.color248 : 'transparent'} onClick={() => {
          this.setState({ color248: color, opacity248: opacity });
        }} d="M127.2,23.8c0.2,0,0.4-0.1,0.6,0c0.4,0.3,0.8,0.6,1.2,0.9c-0.4,0.2-0.8,0.7-1.2,0.6c-0.3-0.1-0.5-0.7-0.7-1
	C127.1,24.1,127.2,23.9,127.2,23.8L127.2,23.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity249 ? this.state.opacity249 : 1}
              fill={this.state.color249 ? this.state.color249 : 'transparent'} onClick={() => {
          this.setState({ color249: color, opacity249: opacity });
        }} d="M136.5,12.4c0,0.3,0.2,0.8,0.1,1c-0.1,0.2-0.6,0.2-1,0.2c-0.1,0-0.2-0.5-0.2-0.6
	C135.8,12.8,136.2,12.6,136.5,12.4C136.5,12.5,136.5,12.4,136.5,12.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity250 ? this.state.opacity250 : 1}
              fill={this.state.color250 ? this.state.color250 : 'transparent'} onClick={() => {
          this.setState({ color250: color, opacity250: opacity });
        }} d="M114.4,20.2c0.2,0.3,0.4,0.5,0.9,1.1c-0.8,0.1-1.2,0.2-1.7,0.1c-0.1,0-0.3-0.6-0.3-0.6
	C113.7,20.5,114.1,20.4,114.4,20.2L114.4,20.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity251 ? this.state.opacity251 : 1}
              fill={this.state.color251 ? this.state.color251 : 'transparent'} onClick={() => {
          this.setState({ color251: color, opacity251: opacity });
        }}
              d="M112.5,12.1c0,0.4-0.5,0.7-0.9,0.7s-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8S112.5,11.3,112.5,12.1z" />
               <path stroke={borderColor} fillOpacity={this.state.opacity252 ? this.state.opacity252 : 1}
              fill={this.state.color252 ? this.state.color252 : 'transparent'} onClick={() => {
          this.setState({ color252: color, opacity252: opacity });
        }} d="M49.4,17.5c-0.3-2-1.4-3.6-2.1-5.4c-0.7-1.8-1.9-3-3.4-4.2c-0.4,1.9-1.3,2.5-3.5,2.7c-1.2,0.1-2.7-0.8-3-2
	c-0.2-0.7-0.2-1.7,0.1-2.3c0.3-0.6,1.2-0.9,1.8-1.4c-2.2-1.1-6.1-1.6-8.9-1c0.8,2.3-1,3.4-2.7,3.8c-2.3,0.6-3.9-0.3-4.3-2.3
	c-2.9,0.9-6.5,4-7.5,6.4c0.5,1.4,1.2,2.5,1.2,3.6c0,1.1-0.6,2.2-1,3.4c1.6,0.4,2.9,1.6,3.7,3.2c0.9,1.6,1.3,3.3,0,5.1
	c1.8,0.6,3.4,1.1,5,1.6c0.5-2.1,1.6-3.4,3.4-3.8c1.4-0.3,2.7-0.2,3.6,1.2l0,0c1.1,1.9,2.6,1.9,4.4,1.2c0.5-0.2,1-0.1,1.5-0.3
	c2.8-0.9,5.7-1.5,8.3-2.7C49.1,22.6,50,22,49.4,17.5z M21.2,12.3c-0.6,0.3-1.2,0.4-1.8,0.4c-0.2,0-0.3-0.7-0.3-1.1c0,0,0,0,0,0
	c0-0.1,0-0.3,0.1-0.4c0-0.2,0.2-0.4,0.5-0.4c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0,0.1,0,0.2-0.1C21,11.5,21.3,12.3,21.2,12.3z M21.9,21.4c-0.1,0-0.5-0.9-0.3-1.2
	c0.2-0.5,0.7-1,1.4-0.3l0,0c0.3,0.4,0.6,0.7,0.9,1.1C23.2,21.2,22.5,21.4,21.9,21.4z M29,21.1c-1.9,0-3.8-1.8-3.8-3.5
	c0.1-1.5,2.3-3.2,4-3.2c1.8,0.1,3.4,1.5,3.4,2.9C32.7,19.8,30.9,21.1,29,21.1z M33.3,10.8c-0.3,0.6-1.2,1-1.9,1.5
	c-1.3-0.3-2.2-1.1-2.2-2.1c0-1,0.8-1.8,1.9-1.7C31.9,8.5,33.6,10.1,33.3,10.8z M37.2,25.2c-0.6,0.1-1.3,0.2-1.7-0.1
	c-0.4-0.3-0.7-1.1,0.2-1.6l0,0c0.9-0.5,2-0.1,2.2,0.7C37.8,24.6,37.4,25.1,37.2,25.2z M37.5,18.8c-0.8,0-1.4-0.3-1.5-1.2
	c-0.1-0.7,0.9-1.6,1.6-1.4c0.6,0.2,1.3,0.8,1.4,1.3C39.1,18.3,38.4,18.8,37.5,18.8z M43.7,12.4c0.2-0.4,0.7-1,1.2-0.2
	c0.5,0.2,1.1,0.5,0.6,1.1c-0.4,0.4-1.1,0.3-1.7,0.5C43.7,13.3,43.5,12.7,43.7,12.4z M48.5,20.2c-0.6,1.3-1.7,2.2-2.9,2.1
	c-1.7-0.1-3.5-1.2-3.3-3c0.3-2.1,2-3.9,3.9-3.5c0.7,0.2,1.3,1,2,1.5l0,0C49.2,18.2,48.9,19.2,48.5,20.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity253 ? this.state.opacity253 : 1}
              fill={this.state.color253 ? this.state.color253 : 'transparent'} onClick={() => {
          this.setState({ color253: color, opacity253: opacity });
        }} d="M17,26.7c-0.2,0.6-0.4,1.2-0.5,1.7c-0.1,0-0.3,0.1-0.4,0.1c0.5-1.5-0.1-1.3-1.1-1.5c-1.7-0.2-3.4-0.8-4.3-2.6
	c-0.1-0.2-0.3-0.6-0.2-0.8c0.5-1.5,1-2.9,1.6-4.3c0.1-0.3,0.7-0.7,1.1-0.7c3-0.4,6.2,2.1,6.7,5.1c0.3,2-0.2,2.7-2.3,2.9
	C17.3,26.7,17.1,26.7,17,26.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity254 ? this.state.opacity254 : 1}
              fill={this.state.color254 ? this.state.color254 : 'transparent'} onClick={() => {
          this.setState({ color254: color, opacity254: opacity });
        }} d="M40.6,10.2c-1.3,0-2.8-1.1-3-1.9c-0.4-1.5,0.8-3.1,2.3-2.8c1.2,0.3,2.4,1,3.3,1.9c0.7,0.6,0.1,2-0.9,2.4
	C41.6,10,40.9,10.1,40.6,10.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity255 ? this.state.opacity255 : 1}
              fill={this.state.color255 ? this.state.color255 : 'transparent'} onClick={() => {
          this.setState({ color255: color, opacity255: opacity });
        }} d="M31.8,26L31.8,26c0.3,0.5,0.5,1.1,1,1.9c-2.5,0.2-4.8,0.5-7.2,0.7c-0.2-1.8,1.1-2.5,2.1-3.3
	c1-0.7,2.1-0.4,3.1,0.1C31.2,25.7,31.5,25.9,31.8,26z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity256 ? this.state.opacity256 : 1}
              fill={this.state.color256 ? this.state.color256 : 'transparent'} onClick={() => {
          this.setState({ color256: color, opacity256: opacity });
        }} d="M26.3,7.5c-0.5-0.2-1.4-0.5-2-1c-0.7-0.5-0.5-1.4,0.4-1.7c1.5-0.4,3-0.8,4.5-1.1C29.4,3.8,30,4,30,4.2
	c0.1,0.6,0.1,1.3-0.1,1.7C29.1,7,27.9,7.3,26.3,7.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity257 ? this.state.opacity257 : 1}
              fill={this.state.color257 ? this.state.color257 : 'transparent'} onClick={() => {
          this.setState({ color257: color, opacity257: opacity });
        }} d="M15.3,12.6c1.8,1.2,2,4.6,0,5.6c-0.4,0.2-1.3,0-1.7-0.3c-0.3-0.2-0.5-1-0.3-1.4C13.9,15.1,14.6,13.9,15.3,12.6z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity258 ? this.state.opacity258 : 1}
              fill={this.state.color258 ? this.state.color258 : 'transparent'} onClick={() => {
          this.setState({ color258: color, opacity258: opacity });
        }} d="M26.4,30.3c0.1-0.5,0.1-0.8,0.2-1.1c0.1,0,0.3,0,0.4-0.1c0.2,0.8,0.6,1.7,0.4,2.4c-0.3,1.2-1.6,0.8-2.4,0.9
	c-0.6,0-1.5-0.3-1.8-0.8c-0.4-0.8,0.6-1.7,1.6-1.6C25.4,30,25.8,30.2,26.4,30.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity259 ? this.state.opacity259 : 1}
              fill={this.state.color259 ? this.state.color259 : 'transparent'} onClick={() => {
          this.setState({ color259: color, opacity259: opacity });
        }} d="M35.4,29.2c0.1-0.5,0.2-1,0.4-1.5c0.1,0,0.2,0.1,0.3,0.1c0.2,0.9,0.3,1.7,0.5,2.6c0.2,0.7-0.2,1.1-0.8,1.2
	c-0.5,0.1-1.1,0.2-1.5,0c-0.5-0.2-1.1-0.6-1.2-1c-0.2-0.8,0.5-1.2,1.2-1.3C34.6,29.2,35,29.2,35.4,29.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity260 ? this.state.opacity260 : 1}
              fill={this.state.color260 ? this.state.color260 : 'transparent'} onClick={() => {
          this.setState({ color260: color, opacity260: opacity });
        }} d="M43.5,27.4c0.1-0.6,0.1-1.1,0.2-1.5c0.1,0,0.1,0,0.2,0c0.2,0.9,0.4,1.7,0.6,2.6c0.2,0.8-0.2,1.1-0.9,1.3
	c-0.4,0.1-0.7,0.1-1.1,0c-0.8-0.1-1.6-0.2-1.5-1.3c0.1-0.9,0.8-1.3,1.8-1.2C43,27.3,43.2,27.4,43.5,27.4z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity261 ? this.state.opacity261 : 1}
              fill={this.state.color261 ? this.state.color261 : 'transparent'} onClick={() => {
          this.setState({ color261: color, opacity261: opacity });
        }} d="M32.3,17.4c0.2,1.8-1.8,3.3-3.4,3.2c-1.6-0.1-3.3-1.7-3.3-3c0-1.3,2.3-3.1,3.8-2.9C31,14.9,32.4,16.2,32.3,17.4
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity262 ? this.state.opacity262 : 1}
              fill={this.state.color262 ? this.state.color262 : 'transparent'} onClick={() => {
          this.setState({ color262: color, opacity262: opacity });
        }} d="M48.1,17.3c0.4,1.1,0.5,2.1-0.1,3.1c-0.9,1.5-2.6,1.9-4.2,1c-1.3-0.8-1.5-2.3-0.5-3.9c1-1.5,2.4-1.8,3.9-0.8
	C47.5,16.9,47.8,17.1,48.1,17.3C48.1,17.4,48.1,17.3,48.1,17.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity263 ? this.state.opacity263 : 1}
              fill={this.state.color263 ? this.state.color263 : 'transparent'} onClick={() => {
          this.setState({ color263: color, opacity263: opacity });
        }} d="M31.3,11.8c-1.1-0.3-1.7-0.6-1.8-1.6c0-0.9,0.9-1.9,1.6-1.5c0.7,0.4,1.2,1.3,1.8,1.9
	C32.4,11,31.9,11.4,31.3,11.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity264 ? this.state.opacity264 : 1}
              fill={this.state.color264 ? this.state.color264 : 'transparent'} onClick={() => {
          this.setState({ color264: color, opacity264: opacity });
        }}
              d="M37.5,18.7c-0.5-0.5-1.1-1-1-1.2c0.2-0.5,0.7-1,1.1-1.1c0.3-0.1,1,0.6,1,1C38.7,17.8,38,18.2,37.5,18.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity265 ? this.state.opacity265 : 1}
              fill={this.state.color265 ? this.state.color265 : 'transparent'} onClick={() => {
          this.setState({ color265: color, opacity265: opacity });
        }} d="M35.6,23.6c0.2,0,0.4-0.1,0.6,0c0.4,0.3,0.8,0.6,1.2,0.9c-0.4,0.2-0.8,0.7-1.2,0.6c-0.3-0.1-0.5-0.7-0.7-1
	C35.5,23.9,35.6,23.7,35.6,23.6L35.6,23.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity266 ? this.state.opacity266 : 1}
              fill={this.state.color266 ? this.state.color266 : 'transparent'} onClick={() => {
          this.setState({ color266: color, opacity266: opacity });
        }} d="M44.9,12.2c0,0.3,0.2,0.8,0.1,1c-0.1,0.2-0.6,0.2-1,0.2c-0.1,0-0.2-0.5-0.2-0.6C44.2,12.6,44.6,12.4,44.9,12.2
	C45,12.2,44.9,12.2,44.9,12.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity267 ? this.state.opacity267 : 1}
              fill={this.state.color267 ? this.state.color267 : 'transparent'} onClick={() => {
          this.setState({ color267: color, opacity267: opacity });
        }} d="M22.9,19.9c0.2,0.3,0.4,0.5,0.9,1.1c-0.8,0.1-1.2,0.2-1.7,0.1c-0.1,0-0.3-0.6-0.3-0.6
	C22.1,20.3,22.5,20.1,22.9,19.9L22.9,19.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity268 ? this.state.opacity268 : 1}
              fill={this.state.color268 ? this.state.color268 : 'transparent'} onClick={() => {
          this.setState({ color268: color, opacity268: opacity });
        }}
              d="M20.9,11.9c0,0.4-0.5,0.7-0.9,0.7s-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8S20.9,11,20.9,11.9z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity269 ? this.state.opacity269 : 1}
              fill={this.state.color269 ? this.state.color269 : 'transparent'} onClick={() => {
          this.setState({ color269: color, opacity269: opacity });
        }} d="M95,17.8c-0.3-2-1.4-3.6-2.1-5.4c-0.7-1.8-1.9-3-3.4-4.2c-0.4,1.9-1.3,2.5-3.5,2.7c-1.2,0.1-2.7-0.8-3-2
	c-0.2-0.7-0.2-1.7,0.1-2.3c0.3-0.6,1.2-0.9,1.8-1.4c-2.2-1.1-6.1-1.6-8.9-1c0.8,2.3-1,3.4-2.7,3.8c-2.3,0.6-3.9-0.3-4.3-2.3
	c-2.9,0.9-6.5,4-7.5,6.4c0.5,1.4,1.2,2.5,1.2,3.6c0,1.1-0.6,2.2-1,3.4c1.6,0.4,2.9,1.6,3.7,3.2c0.9,1.6,1.3,3.3,0,5.1
	c1.8,0.6,3.4,1.1,5,1.6c0.5-2.1,1.6-3.4,3.4-3.8c1.4-0.3,2.7-0.2,3.6,1.2l0,0c1.1,1.9,2.6,1.9,4.4,1.2c0.5-0.2,1-0.1,1.5-0.3
	c2.8-0.9,5.7-1.5,8.3-2.7C94.7,22.8,95.6,22.2,95,17.8z M66.8,12.6C66.3,12.8,65.6,13,65,13c-0.2,0-0.3-0.7-0.3-1.1c0,0,0,0,0,0
	c0-0.1,0-0.3,0.1-0.4c0-0.2,0.2-0.4,0.5-0.4c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0,0.1,0,0.2-0.1C66.6,11.7,66.9,12.5,66.8,12.6z M67.5,21.7c-0.1,0-0.5-0.9-0.3-1.2
	c0.2-0.5,0.7-1,1.4-0.3l0,0c0.3,0.4,0.6,0.7,0.9,1.1C68.8,21.5,68.1,21.7,67.5,21.7z M74.7,21.3c-1.9,0-3.8-1.8-3.8-3.5
	c0.1-1.5,2.3-3.2,4-3.2c1.8,0.1,3.4,1.5,3.4,2.9C78.3,20,76.5,21.3,74.7,21.3z M78.9,11.1c-0.3,0.6-1.2,1-1.9,1.5
	c-1.3-0.3-2.2-1.1-2.2-2.1c0-1,0.8-1.8,1.9-1.7C77.5,8.7,79.2,10.4,78.9,11.1z M82.8,25.5c-0.6,0.1-1.3,0.2-1.7-0.1
	c-0.4-0.3-0.7-1.1,0.2-1.6l0,0c0.9-0.5,2-0.1,2.2,0.7C83.4,24.8,83,25.4,82.8,25.5z M83.1,19c-0.8,0-1.4-0.3-1.5-1.2
	c-0.1-0.7,0.9-1.6,1.6-1.4c0.6,0.2,1.3,0.8,1.4,1.3C84.7,18.6,84,19,83.1,19z M89.3,12.7c0.2-0.4,0.7-1,1.2-0.2
	c0.5,0.2,1.1,0.5,0.6,1.1c-0.4,0.4-1.1,0.3-1.7,0.5C89.3,13.5,89.2,13,89.3,12.7z M94.1,20.5c-0.6,1.3-1.7,2.2-2.9,2.1
	c-1.7-0.1-3.5-1.2-3.3-3c0.3-2.1,2-3.9,3.9-3.5c0.7,0.2,1.3,1,2,1.5l0,0C94.8,18.4,94.5,19.4,94.1,20.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity270 ? this.state.opacity270 : 1}
              fill={this.state.color270 ? this.state.color270 : 'transparent'} onClick={() => {
          this.setState({ color270: color, opacity270: opacity });
        }} d="M62.6,27c-0.2,0.6-0.4,1.2-0.5,1.7c-0.1,0-0.3,0.1-0.4,0.1c0.5-1.5-0.1-1.3-1.1-1.5c-1.7-0.2-3.4-0.8-4.3-2.6
	c-0.1-0.2-0.3-0.6-0.2-0.8c0.5-1.5,1-2.9,1.6-4.3c0.1-0.3,0.7-0.7,1.1-0.7c3-0.4,6.2,2.1,6.7,5.1c0.3,2-0.2,2.7-2.3,2.9
	C62.9,26.9,62.7,27,62.6,27z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity271 ? this.state.opacity271 : 1}
              fill={this.state.color271 ? this.state.color271 : 'transparent'} onClick={() => {
          this.setState({ color271: color, opacity271: opacity });
        }} d="M86.2,10.5c-1.3,0-2.8-1.1-3-1.9C82.8,7,84,5.4,85.5,5.7c1.2,0.3,2.4,1,3.3,1.9c0.7,0.6,0.1,2-0.9,2.4
	C87.3,10.3,86.5,10.4,86.2,10.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity272 ? this.state.opacity272 : 1}
              fill={this.state.color272 ? this.state.color272 : 'transparent'} onClick={() => {
          this.setState({ color272: color, opacity272: opacity });
        }} d="M77.4,26.3L77.4,26.3c0.3,0.5,0.5,1.1,1,1.9c-2.5,0.2-4.8,0.5-7.2,0.7c-0.2-1.8,1.1-2.5,2.1-3.3
	c1-0.7,2.1-0.4,3.1,0.1C76.8,25.9,77.1,26.1,77.4,26.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity273 ? this.state.opacity273 : 1}
              fill={this.state.color273 ? this.state.color273 : 'transparent'} onClick={() => {
          this.setState({ color273: color, opacity273: opacity });
        }} d="M71.9,7.8c-0.5-0.2-1.4-0.5-2-1c-0.7-0.5-0.5-1.4,0.4-1.7c1.5-0.4,3-0.8,4.5-1.1C75,4,75.6,4.3,75.6,4.5
	c0.1,0.6,0.1,1.3-0.1,1.7C74.8,7.2,73.6,7.6,71.9,7.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity274 ? this.state.opacity274 : 1}
              fill={this.state.color274 ? this.state.color274 : 'transparent'} onClick={() => {
          this.setState({ color274: color, opacity274: opacity });
        }} d="M60.9,12.8c1.8,1.2,2,4.6,0,5.6c-0.4,0.2-1.3,0-1.7-0.3c-0.3-0.2-0.5-1-0.3-1.4C59.5,15.4,60.2,14.1,60.9,12.8z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity275 ? this.state.opacity275 : 1}
              fill={this.state.color275 ? this.state.color275 : 'transparent'} onClick={() => {
          this.setState({ color275: color, opacity275: opacity });
        }} d="M72,30.5c0.1-0.5,0.1-0.8,0.2-1.1c0.1,0,0.3,0,0.4-0.1c0.2,0.8,0.6,1.7,0.4,2.4c-0.3,1.2-1.6,0.8-2.4,0.9
	c-0.6,0-1.5-0.3-1.8-0.8c-0.4-0.8,0.6-1.7,1.6-1.6C71,30.3,71.4,30.4,72,30.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity276 ? this.state.opacity276 : 1}
              fill={this.state.color276 ? this.state.color276 : 'transparent'} onClick={() => {
          this.setState({ color276: color, opacity276: opacity });
        }} d="M81,29.5c0.1-0.5,0.2-1,0.4-1.5c0.1,0,0.2,0.1,0.3,0.1c0.2,0.9,0.3,1.7,0.5,2.6c0.2,0.7-0.2,1.1-0.8,1.2
	c-0.5,0.1-1.1,0.2-1.5,0c-0.5-0.2-1.1-0.6-1.2-1c-0.2-0.8,0.5-1.2,1.2-1.3C80.2,29.4,80.6,29.5,81,29.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity277 ? this.state.opacity277 : 1}
              fill={this.state.color277 ? this.state.color277 : 'transparent'} onClick={() => {
          this.setState({ color277: color, opacity277: opacity });
        }} d="M89.1,27.7c0.1-0.6,0.1-1.1,0.2-1.5c0.1,0,0.1,0,0.2,0c0.2,0.9,0.4,1.7,0.6,2.6c0.2,0.8-0.2,1.1-0.9,1.3
	c-0.4,0.1-0.7,0.1-1.1,0c-0.8-0.1-1.6-0.2-1.5-1.3c0.1-0.9,0.8-1.3,1.8-1.2C88.6,27.6,88.8,27.6,89.1,27.7z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity278 ? this.state.opacity278 : 1}
              fill={this.state.color278 ? this.state.color278 : 'transparent'} onClick={() => {
          this.setState({ color278: color, opacity278: opacity });
        }} d="M77.9,17.7c0.2,1.8-1.8,3.3-3.4,3.2c-1.6-0.1-3.3-1.7-3.3-3c0-1.3,2.3-3.1,3.8-2.9C76.6,15.1,78,16.4,77.9,17.7
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity279 ? this.state.opacity279 : 1}
              fill={this.state.color279 ? this.state.color279 : 'transparent'} onClick={() => {
          this.setState({ color279: color, opacity279: opacity });
        }} d="M93.7,17.6c0.4,1.1,0.5,2.1-0.1,3.1c-0.9,1.5-2.6,1.9-4.2,1c-1.3-0.8-1.5-2.3-0.5-3.9c1-1.5,2.4-1.8,3.9-0.8
	C93.1,17.2,93.4,17.4,93.7,17.6C93.7,17.6,93.7,17.6,93.7,17.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity280 ? this.state.opacity280 : 1}
              fill={this.state.color280 ? this.state.color280 : 'transparent'} onClick={() => {
          this.setState({ color280: color, opacity280: opacity });
        }} d="M76.9,12.1c-1.1-0.3-1.7-0.6-1.8-1.6c0-0.9,0.9-1.9,1.6-1.5c0.7,0.4,1.2,1.3,1.8,1.9
	C78,11.3,77.5,11.7,76.9,12.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity281 ? this.state.opacity281 : 1}
              fill={this.state.color281 ? this.state.color281 : 'transparent'} onClick={() => {
          this.setState({ color281: color, opacity281: opacity });
        }}
              d="M83.1,19c-0.5-0.5-1.1-1-1-1.2c0.2-0.5,0.7-1,1.1-1.1c0.3-0.1,1,0.6,1,1C84.3,18,83.6,18.4,83.1,19z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity282 ? this.state.opacity282 : 1}
              fill={this.state.color282 ? this.state.color282 : 'transparent'} onClick={() => {
          this.setState({ color282: color, opacity282: opacity });
        }} d="M81.2,23.8c0.2,0,0.4-0.1,0.6,0c0.4,0.3,0.8,0.6,1.2,0.9c-0.4,0.2-0.8,0.7-1.2,0.6c-0.3-0.1-0.5-0.7-0.7-1
	C81.1,24.1,81.2,23.9,81.2,23.8L81.2,23.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity283 ? this.state.opacity283 : 1}
              fill={this.state.color283 ? this.state.color283 : 'transparent'} onClick={() => {
          this.setState({ color283: color, opacity283: opacity });
        }} d="M90.5,12.5c0,0.3,0.2,0.8,0.1,1c-0.1,0.2-0.6,0.2-1,0.2c-0.1,0-0.2-0.5-0.2-0.6C89.8,12.9,90.2,12.7,90.5,12.5
	C90.6,12.5,90.5,12.5,90.5,12.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity284 ? this.state.opacity284 : 1}
              fill={this.state.color284 ? this.state.color284 : 'transparent'} onClick={() => {
          this.setState({ color284: color, opacity284: opacity });
        }} d="M68.5,20.2c0.2,0.3,0.4,0.5,0.9,1.1c-0.8,0.1-1.2,0.2-1.7,0.1c-0.1,0-0.3-0.6-0.3-0.6
	C67.7,20.6,68.1,20.4,68.5,20.2L68.5,20.2z" />
        <path d="M66.6,11.4L66.6,11.4C66.6,11.4,66.6,11.4,66.6,11.4C66.5,11.3,66.5,11.3,66.6,11.4c0-0.1-0.1-0.1-0.1-0.1
	c-0.2-0.1-0.8-0.3-1.3-0.1c-0.6,0.2-0.5,1-0.5,1c0,0.4,0.1,0.9,0.3,0.9c0.6,0,1.2-0.2,1.8-0.4C66.9,12.5,66.7,11.8,66.6,11.4z
	 M66.4,12.5c-0.1,0.2-0.7,0.4-1,0.3c-0.2-0.1-0.3-0.4-0.4-0.6c0.1-0.4,0.2-0.7,0.4-0.8c0.4-0.2,0.9,0.1,1,0.2
	C66.4,11.9,66.5,12.3,66.4,12.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity285 ? this.state.opacity285 : 1}
              fill={this.state.color285 ? this.state.color285 : 'transparent'} onClick={() => {
          this.setState({ color285: color, opacity285: opacity });
        }}
              d="M66.6,12.2c0,0.4-0.5,0.7-0.9,0.7s-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8S66.5,11.3,66.6,12.2z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity286 ? this.state.opacity286 : 1}
              fill={this.state.color286 ? this.state.color286 : 'transparent'} onClick={() => {
          this.setState({ color286: color, opacity286: opacity });
        }} d="M185.9,17.7c-0.3-2-1.4-3.6-2.1-5.4c-0.7-1.8-1.9-3-3.4-4.2c-0.4,1.9-1.3,2.5-3.5,2.7c-1.2,0.1-2.7-0.8-3-2
	c-0.2-0.7-0.2-1.7,0.1-2.3c0.3-0.6,1.2-0.9,1.8-1.4c-2.2-1.1-6.1-1.6-8.9-1c0.8,2.3-1,3.4-2.7,3.8c-2.3,0.6-3.9-0.3-4.3-2.3
	c-2.9,0.9-6.5,4-7.5,6.4c0.5,1.4,1.2,2.5,1.2,3.6c0,1.1-0.6,2.2-1,3.4c1.6,0.4,2.9,1.6,3.7,3.2c0.9,1.6,1.3,3.3,0,5.1
	c1.8,0.6,3.4,1.1,5,1.6c0.5-2.1,1.6-3.4,3.4-3.8c1.4-0.3,2.7-0.2,3.6,1.2l0,0c1.1,1.9,2.6,1.9,4.4,1.2c0.5-0.2,1-0.1,1.5-0.3
	c2.8-0.9,5.7-1.5,8.3-2.7C185.6,22.8,186.5,22.2,185.9,17.7z M157.7,12.5c-0.6,0.3-1.2,0.4-1.8,0.4c-0.2,0-0.3-0.7-0.3-1.1
	c0,0,0,0,0,0c0-0.1,0-0.3,0.1-0.4c0-0.2,0.2-0.4,0.5-0.4c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0,0.1,0,0.2-0.1C157.5,11.7,157.8,12.5,157.7,12.5z M158.4,21.7
	c-0.1,0-0.5-0.9-0.3-1.2c0.2-0.5,0.7-1,1.4-0.3l0,0c0.3,0.4,0.6,0.7,0.9,1.1C159.7,21.4,159,21.6,158.4,21.7z M165.5,21.3
	c-1.9,0-3.8-1.8-3.8-3.5c0.1-1.5,2.3-3.2,4-3.2c1.8,0.1,3.4,1.5,3.4,2.9C169.2,20,167.4,21.3,165.5,21.3z M169.8,11
	c-0.3,0.6-1.2,1-1.9,1.5c-1.3-0.3-2.2-1.1-2.2-2.1c0-1,0.8-1.8,1.9-1.7C168.4,8.7,170.1,10.3,169.8,11z M173.7,25.4
	c-0.6,0.1-1.3,0.2-1.7-0.1c-0.4-0.3-0.7-1.1,0.2-1.6l0,0c0.9-0.5,2-0.1,2.2,0.7C174.3,24.8,173.9,25.4,173.7,25.4z M174,19
	c-0.8,0-1.4-0.3-1.5-1.2c-0.1-0.7,0.9-1.6,1.6-1.4c0.6,0.2,1.3,0.8,1.4,1.3C175.6,18.5,174.9,19,174,19z M180.2,12.6
	c0.2-0.4,0.7-1,1.2-0.2c0.5,0.2,1.1,0.5,0.6,1.1c-0.4,0.4-1.1,0.3-1.7,0.5C180.2,13.5,180.1,13,180.2,12.6z M185,20.4
	c-0.6,1.3-1.7,2.2-2.9,2.1c-1.7-0.1-3.5-1.2-3.3-3c0.3-2.1,2-3.9,3.9-3.5c0.7,0.2,1.3,1,2,1.5l0,0C185.7,18.4,185.4,19.4,185,20.4z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity287 ? this.state.opacity287 : 1}
              fill={this.state.color287 ? this.state.color287 : 'transparent'} onClick={() => {
          this.setState({ color287: color, opacity287: opacity });
        }} d="M153.5,26.9c-0.2,0.6-0.4,1.2-0.5,1.7c-0.1,0-0.3,0.1-0.4,0.1c0.5-1.5-0.1-1.3-1.1-1.5
	c-1.7-0.2-3.4-0.8-4.3-2.6c-0.1-0.2-0.3-0.6-0.2-0.8c0.5-1.5,1-2.9,1.6-4.3c0.1-0.3,0.7-0.7,1.1-0.7c3-0.4,6.2,2.1,6.7,5.1
	c0.3,2-0.2,2.7-2.3,2.9C153.8,26.9,153.6,26.9,153.5,26.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity288 ? this.state.opacity288 : 1}
              fill={this.state.color288 ? this.state.color288 : 'transparent'} onClick={() => {
          this.setState({ color288: color, opacity288: opacity });
        }} d="M177.1,10.4c-1.3,0-2.8-1.1-3-1.9c-0.4-1.5,0.8-3.1,2.3-2.8c1.2,0.3,2.4,1,3.3,1.9c0.7,0.6,0.1,2-0.9,2.4
	C178.2,10.2,177.4,10.3,177.1,10.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity289 ? this.state.opacity289 : 1}
              fill={this.state.color289 ? this.state.color289 : 'transparent'} onClick={() => {
          this.setState({ color289: color, opacity289: opacity });
        }} d="M168.3,26.2L168.3,26.2c0.3,0.5,0.5,1.1,1,1.9c-2.5,0.2-4.8,0.5-7.2,0.7c-0.2-1.8,1.1-2.5,2.1-3.3
	c1-0.7,2.1-0.4,3.1,0.1C167.7,25.9,168,26.1,168.3,26.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity290 ? this.state.opacity290 : 1}
              fill={this.state.color290 ? this.state.color290 : 'transparent'} onClick={() => {
          this.setState({ color290: color, opacity290: opacity });
        }} d="M162.8,7.7c-0.5-0.2-1.4-0.5-2-1c-0.7-0.5-0.5-1.4,0.4-1.7c1.5-0.4,3-0.8,4.5-1.1c0.3-0.1,0.8,0.2,0.8,0.4
	c0.1,0.6,0.1,1.3-0.1,1.7C165.6,7.2,164.5,7.6,162.8,7.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity291 ? this.state.opacity291 : 1}
              fill={this.state.color291 ? this.state.color291 : 'transparent'} onClick={() => {
          this.setState({ color291: color, opacity291: opacity });
        }} d="M151.8,12.8c1.8,1.2,2,4.6,0,5.6c-0.4,0.2-1.3,0-1.7-0.3c-0.3-0.2-0.5-1-0.3-1.4
	C150.4,15.3,151.1,14.1,151.8,12.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity292 ? this.state.opacity292 : 1}
              fill={this.state.color292 ? this.state.color292 : 'transparent'} onClick={() => {
          this.setState({ color292: color, opacity292: opacity });
        }} d="M162.9,30.5c0.1-0.5,0.1-0.8,0.2-1.1c0.1,0,0.3,0,0.4-0.1c0.2,0.8,0.6,1.7,0.4,2.4c-0.3,1.2-1.6,0.8-2.4,0.9
	c-0.6,0-1.5-0.3-1.8-0.8c-0.4-0.8,0.6-1.7,1.6-1.6C161.9,30.2,162.3,30.4,162.9,30.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity293 ? this.state.opacity293 : 1}
              fill={this.state.color293 ? this.state.color293 : 'transparent'} onClick={() => {
          this.setState({ color293: color, opacity293: opacity });
        }} d="M171.9,29.5c0.1-0.5,0.2-1,0.4-1.5c0.1,0,0.2,0.1,0.3,0.1c0.2,0.9,0.3,1.7,0.5,2.6c0.2,0.7-0.2,1.1-0.8,1.2
	c-0.5,0.1-1.1,0.2-1.5,0c-0.5-0.2-1.1-0.6-1.2-1c-0.2-0.8,0.5-1.2,1.2-1.3C171.1,29.4,171.5,29.5,171.9,29.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity294 ? this.state.opacity294 : 1}
              fill={this.state.color294 ? this.state.color294 : 'transparent'} onClick={() => {
          this.setState({ color294: color, opacity294: opacity });
        }} d="M180,27.6c0.1-0.6,0.1-1.1,0.2-1.5c0.1,0,0.1,0,0.2,0c0.2,0.9,0.4,1.7,0.6,2.6c0.2,0.8-0.2,1.1-0.9,1.3
	c-0.4,0.1-0.7,0.1-1.1,0c-0.8-0.1-1.6-0.2-1.5-1.3c0.1-0.9,0.8-1.3,1.8-1.2C179.5,27.5,179.7,27.6,180,27.6z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity295 ? this.state.opacity295 : 1}
              fill={this.state.color295 ? this.state.color295 : 'transparent'} onClick={() => {
          this.setState({ color295: color, opacity295: opacity });
        }} d="M168.8,17.7c0.2,1.8-1.8,3.3-3.4,3.2c-1.6-0.1-3.3-1.7-3.3-3c0-1.3,2.3-3.1,3.8-2.9
	C167.5,15.1,168.9,16.4,168.8,17.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity296 ? this.state.opacity296 : 1}
              fill={this.state.color296 ? this.state.color296 : 'transparent'} onClick={() => {
          this.setState({ color296: color, opacity296: opacity });
        }} d="M184.6,17.5c0.4,1.1,0.5,2.1-0.1,3.1c-0.9,1.5-2.6,1.9-4.2,1c-1.3-0.8-1.5-2.3-0.5-3.9c1-1.5,2.4-1.8,3.9-0.8
	C184,17.1,184.3,17.4,184.6,17.5C184.6,17.6,184.6,17.5,184.6,17.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity297 ? this.state.opacity297 : 1}
              fill={this.state.color297 ? this.state.color297 : 'transparent'} onClick={() => {
          this.setState({ color297: color, opacity297: opacity });
        }} d="M167.8,12c-1.1-0.3-1.7-0.6-1.8-1.6c0-0.9,0.9-1.9,1.6-1.5c0.7,0.4,1.2,1.3,1.8,1.9
	C168.9,11.2,168.4,11.6,167.8,12z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity298 ? this.state.opacity298 : 1}
              fill={this.state.color298 ? this.state.color298 : 'transparent'} onClick={() => {
          this.setState({ color298: color, opacity298: opacity });
        }}
              d="M174,19c-0.5-0.5-1.1-1-1-1.2c0.2-0.5,0.7-1,1.1-1.1c0.3-0.1,1,0.6,1,1C175.2,18,174.5,18.4,174,19z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity299 ? this.state.opacity299 : 1}
              fill={this.state.color299 ? this.state.color299 : 'transparent'} onClick={() => {
          this.setState({ color299: color, opacity299: opacity });
        }} d="M172.1,23.8c0.2,0,0.4-0.1,0.6,0c0.4,0.3,0.8,0.6,1.2,0.9c-0.4,0.2-0.8,0.7-1.2,0.6c-0.3-0.1-0.5-0.7-0.7-1
	C172,24.1,172.1,23.9,172.1,23.8L172.1,23.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity300 ? this.state.opacity300 : 1}
              fill={this.state.color300 ? this.state.color300 : 'transparent'} onClick={() => {
          this.setState({ color300: color, opacity300: opacity });
        }} d="M181.4,12.4c0,0.3,0.2,0.8,0.1,1c-0.1,0.2-0.6,0.2-1,0.2c-0.1,0-0.2-0.5-0.2-0.6
	C180.7,12.8,181.1,12.6,181.4,12.4C181.5,12.5,181.4,12.4,181.4,12.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity301 ? this.state.opacity301 : 1}
              fill={this.state.color301 ? this.state.color301 : 'transparent'} onClick={() => {
          this.setState({ color301: color, opacity301: opacity });
        }} d="M159.4,20.2c0.2,0.3,0.4,0.5,0.9,1.1c-0.8,0.1-1.2,0.2-1.7,0.1c-0.1,0-0.3-0.6-0.3-0.6
	C158.6,20.5,159,20.4,159.4,20.2L159.4,20.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity302 ? this.state.opacity302 : 1}
              fill={this.state.color302 ? this.state.color302 : 'transparent'} onClick={() => {
          this.setState({ color302: color, opacity302: opacity });
        }}
              d="M157.5,12.1c0,0.4-0.5,0.7-0.9,0.7s-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8S157.4,11.3,157.5,12.1z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity303 ? this.state.opacity303 : 1}
              fill={this.state.color303 ? this.state.color303 : 'transparent'} onClick={() => {
          this.setState({ color303: color, opacity303: opacity });
        }} d="M232.5,17.7c-0.3-2-1.4-3.6-2.1-5.4c-0.7-1.8-1.9-3-3.4-4.2c-0.4,1.9-1.3,2.5-3.5,2.7c-1.2,0.1-2.7-0.8-3-2
	c-0.2-0.7-0.2-1.7,0.1-2.3c0.3-0.6,1.2-0.9,1.8-1.4c-2.2-1.1-6.1-1.6-8.9-1c0.8,2.3-1,3.4-2.7,3.8c-2.3,0.6-3.9-0.3-4.3-2.3
	c-2.9,0.9-6.5,4-7.5,6.4c0.5,1.4,1.2,2.5,1.2,3.6c0,1.1-0.6,2.2-1,3.4c1.6,0.4,2.9,1.6,3.7,3.2c0.9,1.6,1.3,3.3,0,5.1
	c1.8,0.6,3.4,1.1,5,1.6c0.5-2.1,1.6-3.4,3.4-3.8c1.4-0.3,2.7-0.2,3.6,1.2l0,0c1.1,1.9,2.6,1.9,4.4,1.2c0.5-0.2,1-0.1,1.5-0.3
	c2.8-0.9,5.7-1.5,8.3-2.7C232.2,22.8,233.1,22.2,232.5,17.7z M204.3,12.5c-0.6,0.3-1.2,0.4-1.8,0.4c-0.2,0-0.3-0.7-0.3-1.1
	c0,0,0,0,0,0c0-0.1,0-0.3,0.1-0.4c0-0.2,0.2-0.4,0.5-0.4c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0,0.1,0,0.2-0.1C204.1,11.7,204.4,12.5,204.3,12.5z M205,21.7
	c-0.1,0-0.5-0.9-0.3-1.2c0.2-0.5,0.7-1,1.4-0.3l0,0c0.3,0.4,0.6,0.7,0.9,1.1C206.3,21.4,205.6,21.6,205,21.7z M212.2,21.3
	c-1.9,0-3.8-1.8-3.8-3.5c0.1-1.5,2.3-3.2,4-3.2c1.8,0.1,3.4,1.5,3.4,2.9C215.8,20,214,21.3,212.2,21.3z M216.4,11
	c-0.3,0.6-1.2,1-1.9,1.5c-1.3-0.3-2.2-1.1-2.2-2.1c0-1,0.8-1.8,1.9-1.7C215,8.7,216.7,10.3,216.4,11z M220.3,25.4
	c-0.6,0.1-1.3,0.2-1.7-0.1c-0.4-0.3-0.7-1.1,0.2-1.6l0,0c0.9-0.5,2-0.1,2.2,0.7C220.9,24.8,220.5,25.4,220.3,25.4z M220.6,19
	c-0.8,0-1.4-0.3-1.5-1.2c-0.1-0.7,0.9-1.6,1.6-1.4c0.6,0.2,1.3,0.8,1.4,1.3C222.2,18.5,221.5,19,220.6,19z M226.8,12.6
	c0.2-0.4,0.7-1,1.2-0.2c0.5,0.2,1.1,0.5,0.6,1.1c-0.4,0.4-1.1,0.3-1.7,0.5C226.8,13.5,226.7,13,226.8,12.6z M231.6,20.4
	c-0.6,1.3-1.7,2.2-2.9,2.1c-1.7-0.1-3.5-1.2-3.3-3c0.3-2.1,2-3.9,3.9-3.5c0.7,0.2,1.3,1,2,1.5l0,0C232.3,18.4,232,19.4,231.6,20.4z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity304 ? this.state.opacity304 : 1}
              fill={this.state.color304 ? this.state.color304 : 'transparent'} onClick={() => {
          this.setState({ color304: color, opacity304: opacity });
        }} d="M200.1,26.9c-0.2,0.6-0.4,1.2-0.5,1.7c-0.1,0-0.3,0.1-0.4,0.1c0.5-1.5-0.1-1.3-1.1-1.5
	c-1.7-0.2-3.4-0.8-4.3-2.6c-0.1-0.2-0.3-0.6-0.2-0.8c0.5-1.5,1-2.9,1.6-4.3c0.1-0.3,0.7-0.7,1.1-0.7c3-0.4,6.2,2.1,6.7,5.1
	c0.3,2-0.2,2.7-2.3,2.9C200.4,26.9,200.2,26.9,200.1,26.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity305 ? this.state.opacity305 : 1}
              fill={this.state.color305 ? this.state.color305 : 'transparent'} onClick={() => {
          this.setState({ color305: color, opacity305: opacity });
        }} d="M223.7,10.4c-1.3,0-2.8-1.1-3-1.9c-0.4-1.5,0.8-3.1,2.3-2.8c1.2,0.3,2.4,1,3.3,1.9c0.7,0.6,0.1,2-0.9,2.4
	C224.8,10.2,224,10.3,223.7,10.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity306 ? this.state.opacity306 : 1}
              fill={this.state.color306 ? this.state.color306 : 'transparent'} onClick={() => {
          this.setState({ color306: color, opacity306: opacity });
        }} d="M214.9,26.2L214.9,26.2c0.3,0.5,0.5,1.1,1,1.9c-2.5,0.2-4.8,0.5-7.2,0.7c-0.2-1.8,1.1-2.5,2.1-3.3
	c1-0.7,2.1-0.4,3.1,0.1C214.3,25.9,214.6,26.1,214.9,26.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity307 ? this.state.opacity307 : 1}
              fill={this.state.color307 ? this.state.color307 : 'transparent'} onClick={() => {
          this.setState({ color307: color, opacity307: opacity });
        }} d="M209.4,7.7c-0.5-0.2-1.4-0.5-2-1c-0.7-0.5-0.5-1.4,0.4-1.7c1.5-0.4,3-0.8,4.5-1.1c0.3-0.1,0.8,0.2,0.8,0.4
	c0.1,0.6,0.1,1.3-0.1,1.7C212.2,7.2,211.1,7.6,209.4,7.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity308 ? this.state.opacity308 : 1}
              fill={this.state.color308 ? this.state.color308 : 'transparent'} onClick={() => {
          this.setState({ color308: color, opacity308: opacity });
        }} d="M198.4,12.8c1.8,1.2,2,4.6,0,5.6c-0.4,0.2-1.3,0-1.7-0.3c-0.3-0.2-0.5-1-0.3-1.4
	C197,15.3,197.7,14.1,198.4,12.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity309 ? this.state.opacity309 : 1}
              fill={this.state.color309 ? this.state.color309 : 'transparent'} onClick={() => {
          this.setState({ color309: color, opacity309: opacity });
        }} d="M209.5,30.5c0.1-0.5,0.1-0.8,0.2-1.1c0.1,0,0.3,0,0.4-0.1c0.2,0.8,0.6,1.7,0.4,2.4c-0.3,1.2-1.6,0.8-2.4,0.9
	c-0.6,0-1.5-0.3-1.8-0.8c-0.4-0.8,0.6-1.7,1.6-1.6C208.5,30.2,208.9,30.4,209.5,30.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity310 ? this.state.opacity310 : 1}
              fill={this.state.color310 ? this.state.color310 : 'transparent'} onClick={() => {
          this.setState({ color310: color, opacity310: opacity });
        }} d="M218.5,29.5c0.1-0.5,0.2-1,0.4-1.5c0.1,0,0.2,0.1,0.3,0.1c0.2,0.9,0.3,1.7,0.5,2.6c0.2,0.7-0.2,1.1-0.8,1.2
	c-0.5,0.1-1.1,0.2-1.5,0c-0.5-0.2-1.1-0.6-1.2-1c-0.2-0.8,0.5-1.2,1.2-1.3C217.7,29.4,218.1,29.5,218.5,29.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity311 ? this.state.opacity311 : 1}
              fill={this.state.color311 ? this.state.color311 : 'transparent'} onClick={() => {
          this.setState({ color311: color, opacity311: opacity });
        }} d="M226.6,27.6c0.1-0.6,0.1-1.1,0.2-1.5c0.1,0,0.1,0,0.2,0c0.2,0.9,0.4,1.7,0.6,2.6c0.2,0.8-0.2,1.1-0.9,1.3
	c-0.4,0.1-0.7,0.1-1.1,0c-0.8-0.1-1.6-0.2-1.5-1.3c0.1-0.9,0.8-1.3,1.8-1.2C226.1,27.5,226.3,27.6,226.6,27.6z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity312 ? this.state.opacity312 : 1}
              fill={this.state.color312 ? this.state.color312 : 'transparent'} onClick={() => {
          this.setState({ color312: color, opacity312: opacity });
        }} d="M215.4,17.7c0.2,1.8-1.8,3.3-3.4,3.2c-1.6-0.1-3.3-1.7-3.3-3c0-1.3,2.3-3.1,3.8-2.9
	C214.1,15.1,215.5,16.4,215.4,17.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity313 ? this.state.opacity313 : 1}
              fill={this.state.color313 ? this.state.color313 : 'transparent'} onClick={() => {
          this.setState({ color313: color, opacity313: opacity });
        }} d="M231.2,17.5c0.4,1.1,0.5,2.1-0.1,3.1c-0.9,1.5-2.6,1.9-4.2,1c-1.3-0.8-1.5-2.3-0.5-3.9c1-1.5,2.4-1.8,3.9-0.8
	C230.6,17.1,230.9,17.4,231.2,17.5C231.2,17.6,231.2,17.5,231.2,17.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity314 ? this.state.opacity314 : 1}
              fill={this.state.color314 ? this.state.color314 : 'transparent'} onClick={() => {
          this.setState({ color314: color, opacity314: opacity });
        }} d="M214.4,12c-1.1-0.3-1.7-0.6-1.8-1.6c0-0.9,0.9-1.9,1.6-1.5c0.7,0.4,1.2,1.3,1.8,1.9
	C215.5,11.2,215,11.6,214.4,12z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity315 ? this.state.opacity315 : 1}
              fill={this.state.color315 ? this.state.color315 : 'transparent'} onClick={() => {
          this.setState({ color315: color, opacity315: opacity });
        }}
              d="M220.6,19c-0.5-0.5-1.1-1-1-1.2c0.2-0.5,0.7-1,1.1-1.1c0.3-0.1,1,0.6,1,1C221.8,18,221.1,18.4,220.6,19z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity316 ? this.state.opacity316 : 1}
              fill={this.state.color316 ? this.state.color316 : 'transparent'} onClick={() => {
          this.setState({ color316: color, opacity316: opacity });
        }} d="M218.7,23.8c0.2,0,0.4-0.1,0.6,0c0.4,0.3,0.8,0.6,1.2,0.9c-0.4,0.2-0.8,0.7-1.2,0.6c-0.3-0.1-0.5-0.7-0.7-1
	C218.6,24.1,218.7,23.9,218.7,23.8L218.7,23.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity317 ? this.state.opacity317 : 1}
              fill={this.state.color317 ? this.state.color317 : 'transparent'} onClick={() => {
          this.setState({ color317: color, opacity317: opacity });
        }} d="M228,12.4c0,0.3,0.2,0.8,0.1,1c-0.1,0.2-0.6,0.2-1,0.2c-0.1,0-0.2-0.5-0.2-0.6C227.3,12.8,227.7,12.6,228,12.4
	C228.1,12.5,228,12.4,228,12.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity318 ? this.state.opacity318 : 1}
              fill={this.state.color318 ? this.state.color318 : 'transparent'} onClick={() => {
          this.setState({ color318: color, opacity318: opacity });
        }} d="M206,20.2c0.2,0.3,0.4,0.5,0.9,1.1c-0.8,0.1-1.2,0.2-1.7,0.1c-0.1,0-0.3-0.6-0.3-0.6
	C205.2,20.5,205.6,20.4,206,20.2L206,20.2z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity319 ? this.state.opacity319 : 1}
              fill={this.state.color319 ? this.state.color319 : 'transparent'} onClick={() => {
          this.setState({ color319: color, opacity319: opacity });
        }}
              d="M204.1,12.1c0,0.4-0.5,0.7-0.9,0.7s-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8S204,11.3,204.1,12.1z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity320 ? this.state.opacity320 : 1}
              fill={this.state.color320 ? this.state.color320 : 'transparent'} onClick={() => {
          this.setState({ color320: color, opacity320: opacity });
        }} d="M278.1,19.7c-0.3-2-1.4-3.6-2.1-5.4c-0.7-1.8-1.9-3-3.4-4.2c-0.4,1.9-1.3,2.5-3.5,2.7c-1.2,0.1-2.7-0.8-3-2
	c-0.2-0.7-0.2-1.7,0.1-2.3c0.3-0.6,1.2-0.9,1.8-1.4c-2.2-1.1-6.1-1.6-8.9-1c0.8,2.3-1,3.4-2.7,3.8c-2.3,0.6-3.9-0.3-4.3-2.3
	c-2.9,0.9-6.5,4-7.5,6.4c0.5,1.4,1.2,2.5,1.2,3.6c0,1.1-0.6,2.2-1,3.4c1.6,0.4,2.9,1.6,3.7,3.2c0.9,1.6,1.3,3.3,0,5.1
	c1.8,0.6,3.4,1.1,5,1.6c0.5-2.1,1.6-3.4,3.4-3.8c1.4-0.3,2.7-0.2,3.6,1.2l0,0c1.1,1.9,2.6,1.9,4.4,1.2c0.5-0.2,1-0.1,1.5-0.3
	c2.8-0.9,5.7-1.5,8.3-2.7C277.7,24.8,278.7,24.2,278.1,19.7z M249.9,14.5c-0.6,0.3-1.2,0.4-1.8,0.4c-0.2,0-0.3-0.7-0.3-1.1
	c0,0,0,0,0,0c0-0.1,0-0.3,0.1-0.4c0-0.2,0.2-0.4,0.5-0.4c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0,0.1,0,0.2-0.1C249.7,13.7,250,14.5,249.9,14.5z M250.5,23.6
	c-0.1,0-0.5-0.9-0.3-1.2c0.2-0.5,0.7-1,1.4-0.3l0,0c0.3,0.4,0.6,0.7,0.9,1.1C251.8,23.4,251.2,23.6,250.5,23.6z M257.7,23.3
	c-1.9,0-3.8-1.8-3.7-3.5c0.1-1.5,2.3-3.2,4-3.2c1.8,0.1,3.4,1.5,3.4,2.9C261.4,22,259.6,23.3,257.7,23.3z M261.9,13
	c-0.3,0.6-1.2,1-1.9,1.5c-1.3-0.3-2.2-1.1-2.2-2.1c0-1,0.8-1.8,1.9-1.7C260.6,10.7,262.3,12.3,261.9,13z M265.8,27.4
	c-0.6,0.1-1.3,0.2-1.7-0.1c-0.4-0.3-0.7-1.1,0.2-1.6l0,0c0.9-0.5,2-0.1,2.2,0.7C266.5,26.8,266.1,27.3,265.8,27.4z M266.2,21
	c-0.8,0-1.4-0.3-1.5-1.2c-0.1-0.7,0.9-1.6,1.6-1.4c0.6,0.2,1.3,0.8,1.4,1.3C267.8,20.5,267.1,21,266.2,21z M272.4,14.6
	c0.2-0.4,0.7-1,1.2-0.2c0.5,0.2,1.1,0.5,0.6,1.1c-0.4,0.4-1.1,0.3-1.7,0.5C272.4,15.5,272.2,15,272.4,14.6z M277.1,22.4
	c-0.6,1.3-1.7,2.2-2.9,2.1c-1.7-0.1-3.5-1.2-3.3-3c0.3-2.1,2-3.9,3.9-3.5c0.7,0.2,1.3,1,2,1.5l0,0C277.8,20.4,277.6,21.4,277.1,22.4
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity321 ? this.state.opacity321 : 1}
              fill={this.state.color321 ? this.state.color321 : 'transparent'} onClick={() => {
          this.setState({ color321: color, opacity321: opacity });
        }} d="M245.7,28.9c-0.2,0.6-0.4,1.2-0.5,1.7c-0.1,0-0.3,0.1-0.4,0.1c0.5-1.5-0.1-1.3-1.1-1.5
	c-1.7-0.2-3.4-0.8-4.3-2.6c-0.1-0.2-0.3-0.6-0.2-0.8c0.5-1.5,1-2.9,1.6-4.3c0.1-0.3,0.7-0.7,1.1-0.7c3-0.4,6.2,2.1,6.7,5.1
	c0.3,2-0.2,2.7-2.3,2.9C246,28.9,245.7,28.9,245.7,28.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity322 ? this.state.opacity322 : 1}
              fill={this.state.color322 ? this.state.color322 : 'transparent'} onClick={() => {
          this.setState({ color322: color, opacity322: opacity });
        }} d="M269.2,12.4c-1.3,0-2.8-1.1-3-1.9c-0.4-1.5,0.8-3.1,2.3-2.8c1.2,0.3,2.4,1,3.3,1.9c0.7,0.6,0.1,2-0.9,2.4
	C270.3,12.2,269.6,12.3,269.2,12.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity323 ? this.state.opacity323 : 1}
              fill={this.state.color323 ? this.state.color323 : 'transparent'} onClick={() => {
          this.setState({ color323: color, opacity323: opacity });
        }} d="M260.4,28.2L260.4,28.2c0.3,0.5,0.5,1.1,1,1.9c-2.5,0.2-4.8,0.5-7.2,0.7c-0.2-1.8,1.1-2.5,2.1-3.3
	c1-0.7,2.1-0.4,3.1,0.1C259.9,27.9,260.1,28.1,260.4,28.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity324 ? this.state.opacity324 : 1}
              fill={this.state.color324 ? this.state.color324 : 'transparent'} onClick={() => {
          this.setState({ color324: color, opacity324: opacity });
        }} d="M255,9.7c-0.5-0.2-1.4-0.5-2-1c-0.7-0.5-0.5-1.4,0.4-1.7c1.5-0.4,3-0.8,4.5-1.1c0.3-0.1,0.8,0.2,0.8,0.4
	c0.1,0.6,0.1,1.3-0.1,1.7C257.8,9.2,256.6,9.5,255,9.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity325 ? this.state.opacity325 : 1}
              fill={this.state.color325 ? this.state.color325 : 'transparent'} onClick={() => {
          this.setState({ color325: color, opacity325: opacity });
        }} d="M244,14.8c1.8,1.2,2,4.6,0,5.6c-0.4,0.2-1.3,0-1.7-0.3c-0.3-0.2-0.5-1-0.3-1.4C242.5,17.3,243.3,16.1,244,14.8z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity326 ? this.state.opacity326 : 1}
              fill={this.state.color326 ? this.state.color326 : 'transparent'} onClick={() => {
          this.setState({ color326: color, opacity326: opacity });
        }} d="M255.1,32.5c0.1-0.5,0.1-0.8,0.2-1.1c0.1,0,0.3,0,0.4-0.1c0.2,0.8,0.6,1.7,0.4,2.4c-0.3,1.2-1.6,0.8-2.4,0.9
	c-0.6,0-1.5-0.3-1.8-0.8c-0.4-0.8,0.6-1.7,1.6-1.6C254,32.2,254.5,32.4,255.1,32.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity327 ? this.state.opacity327 : 1}
              fill={this.state.color327 ? this.state.color327 : 'transparent'} onClick={() => {
          this.setState({ color327: color, opacity327: opacity });
        }} d="M264,31.4c0.1-0.5,0.2-1,0.4-1.5c0.1,0,0.2,0.1,0.3,0.1c0.2,0.9,0.3,1.7,0.5,2.6c0.2,0.7-0.2,1.1-0.8,1.2
	c-0.5,0.1-1.1,0.2-1.5,0c-0.5-0.2-1.1-0.6-1.2-1c-0.2-0.8,0.5-1.2,1.2-1.3C263.3,31.4,263.7,31.4,264,31.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity328 ? this.state.opacity328 : 1}
              fill={this.state.color328 ? this.state.color328 : 'transparent'} onClick={() => {
          this.setState({ color328: color, opacity328: opacity });
        }} d="M272.1,29.6c0.1-0.6,0.1-1.1,0.2-1.5c0.1,0,0.1,0,0.2,0c0.2,0.9,0.4,1.7,0.6,2.6c0.2,0.8-0.2,1.1-0.9,1.3
	c-0.4,0.1-0.7,0.1-1.1,0c-0.8-0.1-1.6-0.2-1.5-1.3c0.1-0.9,0.8-1.3,1.8-1.2C271.7,29.5,271.9,29.6,272.1,29.6z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity329 ? this.state.opacity329 : 1}
              fill={this.state.color329 ? this.state.color329 : 'transparent'} onClick={() => {
          this.setState({ color329: color, opacity329: opacity });
        }} d="M261,19.6c0.2,1.8-1.8,3.3-3.4,3.2c-1.6-0.1-3.3-1.7-3.3-3c0-1.3,2.3-3.1,3.8-2.9
	C259.7,17.1,261.1,18.4,261,19.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity330 ? this.state.opacity330 : 1}
              fill={this.state.color330 ? this.state.color330 : 'transparent'} onClick={() => {
          this.setState({ color330: color, opacity330: opacity });
        }} d="M276.8,19.5c0.4,1.1,0.5,2.1-0.1,3.1c-0.9,1.5-2.6,1.9-4.2,1c-1.3-0.8-1.5-2.3-0.5-3.9c1-1.5,2.4-1.8,3.9-0.8
	C276.2,19.1,276.5,19.3,276.8,19.5C276.8,19.6,276.8,19.5,276.8,19.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity331 ? this.state.opacity331 : 1}
              fill={this.state.color331 ? this.state.color331 : 'transparent'} onClick={() => {
          this.setState({ color331: color, opacity331: opacity });
        }} d="M260,14c-1.1-0.3-1.7-0.6-1.8-1.6c0-0.9,0.9-1.9,1.6-1.5c0.7,0.4,1.2,1.3,1.8,1.9C261.1,13.2,260.5,13.6,260,14
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity332 ? this.state.opacity332 : 1}
              fill={this.state.color332 ? this.state.color332 : 'transparent'} onClick={() => {
          this.setState({ color332: color, opacity332: opacity });
        }}
              d="M266.2,20.9c-0.5-0.5-1.1-1-1-1.2c0.2-0.5,0.7-1,1.1-1.1c0.3-0.1,1,0.6,1,1C267.3,20,266.7,20.4,266.2,20.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity333 ? this.state.opacity333 : 1}
              fill={this.state.color333 ? this.state.color333 : 'transparent'} onClick={() => {
          this.setState({ color333: color, opacity333: opacity });
        }} d="M264.3,25.8c0.2,0,0.4-0.1,0.6,0c0.4,0.3,0.8,0.6,1.2,0.9c-0.4,0.2-0.8,0.7-1.2,0.6c-0.3-0.1-0.5-0.7-0.7-1
	C264.1,26.1,264.3,25.9,264.3,25.8L264.3,25.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity334 ? this.state.opacity334 : 1}
              fill={this.state.color334 ? this.state.color334 : 'transparent'} onClick={() => {
          this.setState({ color334: color, opacity334: opacity });
        }} d="M273.6,14.4c0,0.3,0.2,0.8,0.1,1c-0.1,0.2-0.6,0.2-1,0.2c-0.1,0-0.2-0.5-0.2-0.6
	C272.9,14.8,273.3,14.6,273.6,14.4C273.6,14.5,273.6,14.4,273.6,14.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity335 ? this.state.opacity335 : 1}
              fill={this.state.color335 ? this.state.color335 : 'transparent'} onClick={() => {
          this.setState({ color335: color, opacity335: opacity });
        }} d="M251.5,22.1c0.2,0.3,0.4,0.5,0.9,1.1c-0.8,0.1-1.2,0.2-1.7,0.1c-0.1,0-0.3-0.6-0.3-0.6
	C250.8,22.5,251.2,22.3,251.5,22.1L251.5,22.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity336 ? this.state.opacity336 : 1}
              fill={this.state.color336 ? this.state.color336 : 'transparent'} onClick={() => {
          this.setState({ color336: color, opacity336: opacity });
        }}
              d="M249.6,14.1c0,0.4-0.5,0.7-0.9,0.7s-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8S249.6,13.2,249.6,14.1z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity337 ? this.state.opacity337 : 1}
              fill={this.state.color337 ? this.state.color337 : 'transparent'} onClick={() => {
          this.setState({ color337: color, opacity337: opacity });
        }} d="M324,19.7c-0.3-2-1.4-3.6-2.1-5.4c-0.7-1.8-1.9-3-3.4-4.2c-0.4,1.9-1.3,2.5-3.5,2.7c-1.2,0.1-2.7-0.8-3-2
	c-0.2-0.7-0.2-1.7,0.1-2.3c0.3-0.6,1.2-0.9,1.8-1.4c-2.2-1.1-6.1-1.6-8.9-1c0.8,2.3-1,3.4-2.7,3.8c-2.3,0.6-3.9-0.3-4.3-2.3
	c-2.9,0.9-6.5,4-7.5,6.4c0.5,1.4,1.2,2.5,1.2,3.6c0,1.1-0.6,2.2-1,3.4c1.6,0.4,2.9,1.6,3.7,3.2c0.9,1.6,1.3,3.3,0,5.1
	c1.8,0.6,3.4,1.1,5,1.6c0.5-2.1,1.6-3.4,3.4-3.8c1.4-0.3,2.7-0.2,3.6,1.2l0,0c1.1,1.9,2.6,1.9,4.4,1.2c0.5-0.2,1-0.1,1.5-0.3
	c2.8-0.9,5.7-1.5,8.3-2.7C323.6,24.8,324.6,24.2,324,19.7z M295.8,14.5c-0.6,0.3-1.2,0.4-1.8,0.4c-0.2,0-0.3-0.7-0.3-1.1
	c0,0,0,0,0,0c0-0.1,0-0.3,0.1-0.4c0-0.2,0.2-0.4,0.5-0.4c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0,0.1,0,0.2-0.1C295.6,13.7,295.9,14.5,295.8,14.5z M296.4,23.6
	c-0.1,0-0.5-0.9-0.3-1.2c0.2-0.5,0.7-1,1.4-0.3l0,0c0.3,0.4,0.6,0.7,0.9,1.1C297.7,23.4,297.1,23.6,296.4,23.6z M303.6,23.3
	c-1.9,0-3.8-1.8-3.8-3.5c0.1-1.5,2.3-3.2,4-3.2c1.8,0.1,3.4,1.5,3.4,2.9C307.3,22,305.4,23.3,303.6,23.3z M307.8,13
	c-0.3,0.6-1.2,1-1.9,1.5c-1.3-0.3-2.2-1.1-2.2-2.1c0-1,0.8-1.8,1.9-1.7C306.5,10.7,308.2,12.3,307.8,13z M311.7,27.4
	c-0.6,0.1-1.3,0.2-1.7-0.1c-0.4-0.3-0.7-1.1,0.2-1.6l0,0c0.9-0.5,2-0.1,2.2,0.7C312.4,26.8,312,27.3,311.7,27.4z M312.1,21
	c-0.8,0-1.4-0.3-1.5-1.2c-0.1-0.7,0.9-1.6,1.6-1.4c0.6,0.2,1.3,0.8,1.4,1.3C313.7,20.5,313,21,312.1,21z M318.3,14.6
	c0.2-0.4,0.7-1,1.2-0.2c0.5,0.2,1.1,0.5,0.6,1.1c-0.4,0.4-1.1,0.3-1.7,0.5C318.3,15.5,318.1,15,318.3,14.6z M323,22.4
	c-0.6,1.3-1.7,2.2-2.9,2.1c-1.7-0.1-3.5-1.2-3.3-3c0.3-2.1,2-3.9,3.9-3.5c0.7,0.2,1.3,1,2,1.5l0,0C323.7,20.4,323.5,21.4,323,22.4z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity338 ? this.state.opacity338 : 1}
              fill={this.state.color338 ? this.state.color338 : 'transparent'} onClick={() => {
          this.setState({ color338: color, opacity338: opacity });
        }} d="M291.6,28.9c-0.2,0.6-0.4,1.2-0.5,1.7c-0.1,0-0.3,0.1-0.4,0.1c0.5-1.5-0.1-1.3-1.1-1.5
	c-1.7-0.2-3.4-0.8-4.3-2.6c-0.1-0.2-0.3-0.6-0.2-0.8c0.5-1.5,1-2.9,1.6-4.3c0.1-0.3,0.7-0.7,1.1-0.7c3-0.4,6.2,2.1,6.7,5.1
	c0.3,2-0.2,2.7-2.3,2.9C291.9,28.9,291.6,28.9,291.6,28.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity339 ? this.state.opacity339 : 1}
              fill={this.state.color339 ? this.state.color339 : 'transparent'} onClick={() => {
          this.setState({ color339: color, opacity339: opacity });
        }} d="M315.1,12.4c-1.3,0-2.8-1.1-3-1.9c-0.4-1.5,0.8-3.1,2.3-2.8c1.2,0.3,2.4,1,3.3,1.9c0.7,0.6,0.1,2-0.9,2.4
	C316.2,12.2,315.5,12.3,315.1,12.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity340 ? this.state.opacity340 : 1}
              fill={this.state.color340 ? this.state.color340 : 'transparent'} onClick={() => {
          this.setState({ color340: color, opacity340: opacity });
        }} d="M306.3,28.2L306.3,28.2c0.3,0.5,0.5,1.1,1,1.9c-2.5,0.2-4.8,0.5-7.2,0.7c-0.2-1.8,1.1-2.5,2.1-3.3
	c1-0.7,2.1-0.4,3.1,0.1C305.8,27.9,306,28.1,306.3,28.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity341 ? this.state.opacity341 : 1}
              fill={this.state.color341 ? this.state.color341 : 'transparent'} onClick={() => {
          this.setState({ color341: color, opacity341: opacity });
        }} d="M300.9,9.7c-0.5-0.2-1.4-0.5-2-1c-0.7-0.5-0.5-1.4,0.4-1.7c1.5-0.4,3-0.8,4.5-1.1c0.3-0.1,0.8,0.2,0.8,0.4
	c0.1,0.6,0.1,1.3-0.1,1.7C303.7,9.2,302.5,9.5,300.9,9.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity342 ? this.state.opacity342 : 1}
              fill={this.state.color342 ? this.state.color342 : 'transparent'} onClick={() => {
          this.setState({ color342: color, opacity342: opacity });
        }} d="M289.9,14.8c1.8,1.2,2,4.6,0,5.6c-0.4,0.2-1.3,0-1.7-0.3c-0.3-0.2-0.5-1-0.3-1.4
	C288.4,17.3,289.2,16.1,289.9,14.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity343 ? this.state.opacity343 : 1}
              fill={this.state.color343 ? this.state.color343 : 'transparent'} onClick={() => {
          this.setState({ color343: color, opacity343: opacity });
        }} d="M301,32.5c0.1-0.5,0.1-0.8,0.2-1.1c0.1,0,0.3,0,0.4-0.1c0.2,0.8,0.6,1.7,0.4,2.4c-0.3,1.2-1.6,0.8-2.4,0.9
	c-0.6,0-1.5-0.3-1.8-0.8c-0.4-0.8,0.6-1.7,1.6-1.6C299.9,32.2,300.4,32.4,301,32.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity344 ? this.state.opacity344 : 1}
              fill={this.state.color344 ? this.state.color344 : 'transparent'} onClick={() => {
          this.setState({ color344: color, opacity344: opacity });
        }} d="M309.9,31.4c0.1-0.5,0.2-1,0.4-1.5c0.1,0,0.2,0.1,0.3,0.1c0.2,0.9,0.3,1.7,0.5,2.6c0.2,0.7-0.2,1.1-0.8,1.2
	c-0.5,0.1-1.1,0.2-1.5,0c-0.5-0.2-1.1-0.6-1.2-1c-0.2-0.8,0.5-1.2,1.2-1.3C309.2,31.4,309.6,31.4,309.9,31.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity345 ? this.state.opacity345 : 1}
              fill={this.state.color345 ? this.state.color345 : 'transparent'} onClick={() => {
          this.setState({ color345: color, opacity345: opacity });
        }} d="M318,29.6c0.1-0.6,0.1-1.1,0.2-1.5c0.1,0,0.1,0,0.2,0c0.2,0.9,0.4,1.7,0.6,2.6c0.2,0.8-0.2,1.1-0.9,1.3
	c-0.4,0.1-0.7,0.1-1.1,0c-0.8-0.1-1.6-0.2-1.5-1.3c0.1-0.9,0.8-1.3,1.8-1.2C317.6,29.5,317.8,29.6,318,29.6z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity346 ? this.state.opacity346 : 1}
              fill={this.state.color346 ? this.state.color346 : 'transparent'} onClick={() => {
          this.setState({ color346: color, opacity346: opacity });
        }} d="M306.9,19.6c0.2,1.8-1.8,3.3-3.4,3.2c-1.6-0.1-3.3-1.7-3.3-3c0-1.3,2.3-3.1,3.8-2.9
	C305.6,17.1,307,18.4,306.9,19.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity347 ? this.state.opacity347 : 1}
              fill={this.state.color347 ? this.state.color347 : 'transparent'} onClick={() => {
          this.setState({ color347: color, opacity347: opacity });
        }} d="M322.7,19.5c0.4,1.1,0.5,2.1-0.1,3.1c-0.9,1.5-2.6,1.9-4.2,1c-1.3-0.8-1.5-2.3-0.5-3.9c1-1.5,2.4-1.8,3.9-0.8
	C322.1,19.1,322.4,19.3,322.7,19.5C322.7,19.6,322.7,19.5,322.7,19.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity348 ? this.state.opacity348 : 1}
              fill={this.state.color348 ? this.state.color348 : 'transparent'} onClick={() => {
          this.setState({ color348: color, opacity348: opacity });
        }} d="M305.9,14c-1.1-0.3-1.7-0.6-1.8-1.6c0-0.9,0.9-1.9,1.6-1.5c0.7,0.4,1.2,1.3,1.8,1.9
	C307,13.2,306.4,13.6,305.9,14z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity349 ? this.state.opacity349 : 1}
              fill={this.state.color349 ? this.state.color349 : 'transparent'} onClick={() => {
          this.setState({ color349: color, opacity349: opacity });
        }}
              d="M312.1,20.9c-0.5-0.5-1.1-1-1-1.2c0.2-0.5,0.7-1,1.1-1.1c0.3-0.1,1,0.6,1,1C313.2,20,312.6,20.4,312.1,20.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity350 ? this.state.opacity350 : 1}
              fill={this.state.color350 ? this.state.color350 : 'transparent'} onClick={() => {
          this.setState({ color350: color, opacity350: opacity });
        }} d="M310.2,25.8c0.2,0,0.4-0.1,0.6,0c0.4,0.3,0.8,0.6,1.2,0.9c-0.4,0.2-0.8,0.7-1.2,0.6c-0.3-0.1-0.5-0.7-0.7-1
	C310,26.1,310.2,25.9,310.2,25.8L310.2,25.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity351 ? this.state.opacity351 : 1}
              fill={this.state.color351 ? this.state.color351 : 'transparent'} onClick={() => {
          this.setState({ color351: color, opacity351: opacity });
        }} d="M319.5,14.4c0,0.3,0.2,0.8,0.1,1c-0.1,0.2-0.6,0.2-1,0.2c-0.1,0-0.2-0.5-0.2-0.6
	C318.8,14.8,319.1,14.6,319.5,14.4C319.5,14.5,319.5,14.4,319.5,14.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity352 ? this.state.opacity352 : 1}
              fill={this.state.color352 ? this.state.color352 : 'transparent'} onClick={() => {
          this.setState({ color352: color, opacity352: opacity });
        }} d="M297.4,22.1c0.2,0.3,0.4,0.5,0.9,1.1c-0.8,0.1-1.2,0.2-1.7,0.1c-0.1,0-0.3-0.6-0.3-0.6
	C296.7,22.5,297.1,22.3,297.4,22.1L297.4,22.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity353 ? this.state.opacity353 : 1}
              fill={this.state.color353 ? this.state.color353 : 'transparent'} onClick={() => {
          this.setState({ color353: color, opacity353: opacity });
        }}
              d="M295.5,14.1c0,0.4-0.5,0.7-0.9,0.7s-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8S295.5,13.2,295.5,14.1z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity354 ? this.state.opacity354 : 1}
              fill={this.state.color354 ? this.state.color354 : 'transparent'} onClick={() => {
          this.setState({ color354: color, opacity354: opacity });
        }} d="M367.8,19.7c-0.3-2-1.4-3.6-2-5.4c-0.7-1.8-1.9-3-3.4-4.2c-0.4,1.9-1.2,2.5-3.5,2.7c-1.1,0.1-2.6-0.8-3-1.9
	c-0.2-0.7-0.2-1.6,0.1-2.3c0.3-0.6,1.1-0.9,1.7-1.4c-2.1-1.1-6-1.6-8.7-1c0.8,2.2-1,3.3-2.6,3.7c-2.2,0.6-3.8-0.3-4.3-2.3
	c-2.8,0.9-6.4,3.9-7.4,6.3c0.5,1.4,1.2,2.5,1.2,3.5c0,1.1-0.6,2.1-1,3.3c1.6,0.4,2.8,1.6,3.7,3.1c0.9,1.6,1.2,3.2,0,5.1
	c1.8,0.6,3.3,1.1,4.9,1.6c0.5-2.1,1.6-3.3,3.3-3.8c1.3-0.3,2.7-0.2,3.6,1.2l0,0c1,1.8,2.6,1.9,4.3,1.2c0.5-0.2,1-0.1,1.5-0.3
	c2.7-0.9,5.6-1.5,8.2-2.7C367.4,24.7,368.4,24.1,367.8,19.7z M339.9,14.6c-0.5,0.3-1.2,0.4-1.8,0.4c-0.2,0-0.3-0.6-0.3-1.1
	c0,0,0,0,0,0c0-0.1,0-0.3,0.1-0.4c0-0.2,0.2-0.4,0.5-0.4c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0,0.1,0,0.2-0.1C339.8,13.8,340,14.5,339.9,14.6z M340.6,23.6
	c-0.1,0-0.5-0.9-0.3-1.2c0.2-0.5,0.7-1,1.3-0.3l0,0c0.3,0.4,0.6,0.7,0.9,1.1C341.9,23.4,341.2,23.6,340.6,23.6z M347.7,23.2
	c-1.9,0-3.8-1.8-3.7-3.4c0.1-1.5,2.3-3.2,4-3.1c1.8,0.1,3.4,1.5,3.4,2.9C351.3,22,349.5,23.2,347.7,23.2z M351.8,13.1
	c-0.3,0.6-1.2,1-1.8,1.4c-1.3-0.3-2.2-1.1-2.2-2.1c0-1,0.8-1.7,1.8-1.7C350.5,10.8,352.2,12.4,351.8,13.1z M355.7,27.3
	c-0.5,0.1-1.3,0.2-1.7-0.1c-0.4-0.3-0.7-1.1,0.2-1.6l0,0c0.9-0.5,2-0.1,2.1,0.7C356.3,26.7,355.9,27.3,355.7,27.3z M356,21
	c-0.8,0-1.4-0.3-1.4-1.2c-0.1-0.7,0.9-1.6,1.6-1.4c0.6,0.2,1.3,0.8,1.4,1.3C357.6,20.5,356.9,21,356,21z M362.2,14.7
	c0.2-0.4,0.7-1,1.2-0.2c0.5,0.2,1.1,0.5,0.6,1c-0.4,0.4-1.1,0.3-1.7,0.5C362.2,15.5,362,15,362.2,14.7z M366.8,22.4
	c-0.5,1.3-1.7,2.2-2.8,2.1c-1.7-0.1-3.4-1.2-3.2-3c0.3-2.1,2-3.8,3.8-3.4c0.7,0.2,1.3,1,1.9,1.5l0,0
	C367.5,20.4,367.3,21.4,366.8,22.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity355 ? this.state.opacity355 : 1}
              fill={this.state.color355 ? this.state.color355 : 'transparent'} onClick={() => {
          this.setState({ color355: color, opacity355: opacity });
        }} d="M335.8,28.8c-0.2,0.6-0.4,1.2-0.5,1.7c-0.1,0-0.3,0.1-0.4,0.1c0.5-1.5-0.1-1.3-1.1-1.5
	c-1.7-0.2-3.4-0.8-4.3-2.5c-0.1-0.2-0.3-0.6-0.2-0.8c0.5-1.4,1-2.9,1.6-4.3c0.1-0.3,0.7-0.6,1.1-0.7c3-0.4,6.2,2.1,6.6,5
	c0.3,2-0.2,2.7-2.2,2.8C336.1,28.8,335.8,28.8,335.8,28.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity356 ? this.state.opacity356 : 1}
              fill={this.state.color356 ? this.state.color356 : 'transparent'} onClick={() => {
          this.setState({ color356: color, opacity356: opacity });
        }} d="M359,12.5c-1.3,0-2.7-1-2.9-1.9c-0.4-1.5,0.8-3.1,2.3-2.8c1.2,0.3,2.4,1,3.3,1.8c0.7,0.6,0.1,2-0.9,2.4
	C360.1,12.3,359.4,12.4,359,12.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity357 ? this.state.opacity357 : 1}
              fill={this.state.color357 ? this.state.color357 : 'transparent'} onClick={() => {
          this.setState({ color357: color, opacity357: opacity });
        }} d="M350.4,28.1L350.4,28.1c0.3,0.5,0.5,1.1,1,1.9c-2.5,0.2-4.8,0.5-7.1,0.7c-0.2-1.8,1.1-2.5,2.1-3.2
	c1-0.7,2.1-0.4,3.1,0.1C349.8,27.8,350.1,28,350.4,28.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity358 ? this.state.opacity358 : 1}
              fill={this.state.color358 ? this.state.color358 : 'transparent'} onClick={() => {
          this.setState({ color358: color, opacity358: opacity });
        }} d="M345,9.9c-0.5-0.2-1.3-0.5-2-1c-0.7-0.5-0.5-1.4,0.4-1.6c1.5-0.4,3-0.8,4.5-1c0.3-0.1,0.8,0.2,0.8,0.4
	c0.1,0.5,0.1,1.2-0.1,1.6C347.8,9.3,346.6,9.7,345,9.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity359 ? this.state.opacity359 : 1}
              fill={this.state.color359 ? this.state.color359 : 'transparent'} onClick={() => {
          this.setState({ color359: color, opacity359: opacity });
        }} d="M334.1,14.8c1.8,1.2,1.9,4.5,0,5.5c-0.4,0.2-1.3,0-1.7-0.3c-0.3-0.2-0.5-1-0.3-1.4
	C332.7,17.3,333.4,16.1,334.1,14.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity360 ? this.state.opacity360 : 1}
              fill={this.state.color360 ? this.state.color360 : 'transparent'} onClick={() => {
          this.setState({ color360: color, opacity360: opacity });
        }} d="M345.1,32.3c0.1-0.4,0.1-0.8,0.2-1.1c0.1,0,0.3,0,0.4-0.1c0.2,0.8,0.6,1.6,0.4,2.4c-0.3,1.2-1.6,0.8-2.4,0.9
	c-0.6,0-1.5-0.3-1.7-0.8c-0.4-0.7,0.6-1.7,1.6-1.6C344,32.1,344.5,32.2,345.1,32.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity361 ? this.state.opacity361 : 1}
              fill={this.state.color361 ? this.state.color361 : 'transparent'} onClick={() => {
          this.setState({ color361: color, opacity361: opacity });
        }} d="M353.9,31.3c0.1-0.5,0.2-1,0.4-1.5c0.1,0,0.2,0.1,0.3,0.1c0.2,0.8,0.3,1.7,0.5,2.5c0.2,0.7-0.2,1.1-0.8,1.2
	c-0.5,0.1-1.1,0.2-1.5,0c-0.5-0.2-1.1-0.6-1.2-1c-0.2-0.8,0.5-1.2,1.2-1.3C353.1,31.2,353.5,31.3,353.9,31.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity362 ? this.state.opacity362 : 1}
              fill={this.state.color362 ? this.state.color362 : 'transparent'} onClick={() => {
          this.setState({ color362: color, opacity362: opacity });
        }} d="M361.9,29.5c0.1-0.6,0.1-1,0.2-1.5c0.1,0,0.1,0,0.2,0c0.2,0.9,0.4,1.7,0.6,2.6c0.2,0.7-0.2,1.1-0.9,1.2
	c-0.3,0.1-0.7,0.1-1.1,0c-0.8-0.1-1.6-0.2-1.5-1.3c0.1-0.9,0.8-1.3,1.7-1.2C361.4,29.4,361.6,29.4,361.9,29.5z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity363 ? this.state.opacity363 : 1}
              fill={this.state.color363 ? this.state.color363 : 'transparent'} onClick={() => {
          this.setState({ color363: color, opacity363: opacity });
        }} d="M350.9,19.6c0.2,1.8-1.8,3.3-3.4,3.2c-1.6-0.1-3.3-1.6-3.2-2.9c0-1.3,2.3-3,3.7-2.9
	C349.6,17.1,351,18.4,350.9,19.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity364 ? this.state.opacity364 : 1}
              fill={this.state.color364 ? this.state.color364 : 'transparent'} onClick={() => {
          this.setState({ color364: color, opacity364: opacity });
        }} d="M366.5,19.5c0.4,1,0.4,2-0.1,3.1c-0.9,1.5-2.5,1.9-4.1,1c-1.3-0.7-1.5-2.3-0.5-3.8c1-1.5,2.4-1.8,3.8-0.8
	C365.9,19.1,366.2,19.4,366.5,19.5C366.5,19.6,366.5,19.5,366.5,19.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity365 ? this.state.opacity365 : 1}
              fill={this.state.color365 ? this.state.color365 : 'transparent'} onClick={() => {
          this.setState({ color365: color, opacity365: opacity });
        }} d="M349.9,14.1c-1-0.3-1.7-0.6-1.7-1.6c0-0.9,0.9-1.8,1.5-1.5c0.7,0.4,1.2,1.3,1.8,1.9
	C351,13.3,350.5,13.7,349.9,14.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity366 ? this.state.opacity366 : 1}
              fill={this.state.color366 ? this.state.color366 : 'transparent'} onClick={() => {
          this.setState({ color366: color, opacity366: opacity });
        }}
              d="M356,20.9c-0.5-0.5-1.1-1-1-1.2c0.2-0.5,0.7-1,1.1-1.1c0.3-0.1,1,0.6,1,1C357.2,20,356.5,20.4,356,20.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity367 ? this.state.opacity367 : 1}
              fill={this.state.color367 ? this.state.color367 : 'transparent'} onClick={() => {
          this.setState({ color367: color, opacity367: opacity });
        }} d="M354.1,25.7c0.2,0,0.4-0.1,0.6,0c0.4,0.3,0.8,0.6,1.2,0.9c-0.4,0.2-0.8,0.6-1.1,0.6c-0.3-0.1-0.5-0.7-0.7-1
	C354,26,354.1,25.8,354.1,25.7L354.1,25.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity368 ? this.state.opacity368 : 1}
              fill={this.state.color368 ? this.state.color368 : 'transparent'} onClick={() => {
          this.setState({ color368: color, opacity368: opacity });
        }} d="M363.3,14.5c0,0.3,0.2,0.8,0.1,1c-0.1,0.2-0.6,0.2-1,0.2c-0.1,0-0.2-0.5-0.2-0.6
	C362.6,14.9,363,14.7,363.3,14.5C363.4,14.5,363.3,14.5,363.3,14.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity369 ? this.state.opacity369 : 1}
              fill={this.state.color369 ? this.state.color369 : 'transparent'} onClick={() => {
          this.setState({ color369: color, opacity369: opacity });
        }} d="M341.6,22.1c0.2,0.3,0.4,0.5,0.9,1.1c-0.8,0.1-1.2,0.2-1.7,0.1c-0.1,0-0.3-0.6-0.3-0.6
	C340.8,22.5,341.2,22.3,341.6,22.1L341.6,22.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity370 ? this.state.opacity370 : 1}
              fill={this.state.color370 ? this.state.color370 : 'transparent'} onClick={() => {
          this.setState({ color370: color, opacity370: opacity });
        }} d="M339.7,14.2c0,0.4-0.5,0.7-0.9,0.7c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8
	C339.2,13.3,339.7,13.3,339.7,14.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity371 ? this.state.opacity371 : 1}
              fill={this.state.color371 ? this.state.color371 : 'transparent'} onClick={() => {
          this.setState({ color371: color, opacity371: opacity });
        }} d="M411.5,20c-0.3-2-1.3-3.5-2-5.3c-0.7-1.8-1.9-3-3.3-4.1c-0.4,1.9-1.2,2.5-3.4,2.6c-1.1,0.1-2.6-0.8-2.9-1.9
	c-0.2-0.7-0.2-1.6,0.1-2.3c0.3-0.6,1.1-0.9,1.7-1.4c-2.1-1.1-6-1.6-8.7-1c0.8,2.2-1,3.3-2.6,3.7c-2.2,0.6-3.8-0.3-4.3-2.3
	c-2.8,0.9-6.4,3.9-7.4,6.2c0.5,1.4,1.2,2.4,1.2,3.5c0,1.1-0.6,2.1-1,3.3c1.6,0.4,2.8,1.5,3.7,3.1c0.9,1.6,1.2,3.2,0,5
	c1.8,0.6,3.3,1.1,4.9,1.6c0.4-2.1,1.5-3.3,3.3-3.7c1.3-0.3,2.7-0.2,3.5,1.2l0,0c1,1.8,2.5,1.8,4.3,1.2c0.5-0.2,1-0.1,1.5-0.3
	c2.7-0.9,5.6-1.5,8.1-2.7C411.2,24.9,412.1,24.4,411.5,20z M383.9,14.9c-0.5,0.2-1.2,0.4-1.7,0.4c-0.2,0-0.3-0.6-0.3-1.1
	c0,0,0,0,0,0c0-0.1,0-0.3,0.1-0.4c0-0.2,0.2-0.4,0.5-0.4c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0,0.1,0,0.2-0.1C383.7,14.1,384,14.8,383.9,14.9z M384.5,23.8
	c-0.1,0-0.5-0.9-0.3-1.2c0.2-0.5,0.7-1,1.3-0.3l0,0c0.3,0.4,0.6,0.7,0.9,1.1C385.8,23.6,385.2,23.8,384.5,23.8z M391.6,23.5
	c-1.9,0-3.8-1.8-3.7-3.4c0.1-1.5,2.2-3.2,3.9-3.1c1.7,0.1,3.4,1.5,3.3,2.8C395.2,22.2,393.4,23.5,391.6,23.5z M395.7,13.4
	c-0.3,0.6-1.2,1-1.8,1.4c-1.3-0.3-2.2-1.1-2.1-2c0-1,0.8-1.7,1.8-1.7C394.4,11.1,396,12.7,395.7,13.4z M399.5,27.5
	c-0.5,0.1-1.3,0.2-1.7-0.1c-0.4-0.3-0.7-1.1,0.2-1.6l0,0c0.9-0.4,1.9-0.1,2.1,0.7C400.1,26.9,399.8,27.5,399.5,27.5z M399.8,21.2
	c-0.8,0-1.4-0.3-1.4-1.2c-0.1-0.7,0.8-1.6,1.6-1.4c0.6,0.2,1.3,0.8,1.4,1.3C401.5,20.7,400.7,21.2,399.8,21.2z M406,15
	c0.2-0.4,0.7-1,1.2-0.2c0.5,0.2,1.1,0.5,0.6,1c-0.4,0.4-1.1,0.3-1.7,0.5C405.9,15.8,405.8,15.3,406,15z M410.6,22.6
	c-0.5,1.3-1.7,2.1-2.8,2.1c-1.7-0.1-3.4-1.2-3.2-3c0.3-2.1,1.9-3.8,3.8-3.4c0.7,0.2,1.3,1,1.9,1.5l0,0
	C411.3,20.6,411,21.6,410.6,22.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity372 ? this.state.opacity372 : 1}
              fill={this.state.color372 ? this.state.color372 : 'transparent'} onClick={() => {
          this.setState({ color372: color, opacity372: opacity });
        }} d="M379.8,29c-0.2,0.6-0.3,1.2-0.5,1.7c-0.1,0-0.3,0.1-0.4,0.1c0.5-1.5-0.1-1.3-1.1-1.4c-1.7-0.2-3.4-0.8-4.2-2.5
	c-0.1-0.2-0.3-0.5-0.2-0.8c0.5-1.4,1-2.9,1.6-4.2c0.1-0.3,0.7-0.6,1.1-0.7c3-0.4,6.1,2,6.6,5c0.3,2-0.2,2.6-2.2,2.8
	C380.1,29,379.8,29,379.8,29z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity373 ? this.state.opacity373 : 1}
              fill={this.state.color373 ? this.state.color373 : 'transparent'} onClick={() => {
          this.setState({ color373: color, opacity373: opacity });
        }} d="M402.8,12.8c-1.3,0-2.7-1-2.9-1.9c-0.4-1.5,0.8-3.1,2.3-2.7c1.2,0.3,2.3,1,3.3,1.8c0.7,0.6,0.1,2-0.9,2.4
	C403.9,12.7,403.2,12.7,402.8,12.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity374 ? this.state.opacity374 : 1}
              fill={this.state.color374 ? this.state.color374 : 'transparent'} onClick={() => {
          this.setState({ color374: color, opacity374: opacity });
        }} d="M394.2,28.3L394.2,28.3c0.2,0.5,0.5,1.1,1,1.9c-2.5,0.2-4.7,0.5-7,0.7c-0.2-1.8,1-2.5,2.1-3.2
	c1-0.7,2.1-0.4,3.1,0.1C393.7,28,393.9,28.1,394.2,28.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity375 ? this.state.opacity375 : 1}
              fill={this.state.color375 ? this.state.color375 : 'transparent'} onClick={() => {
          this.setState({ color375: color, opacity375: opacity });
        }} d="M388.9,10.2c-0.5-0.2-1.3-0.5-2-1c-0.7-0.5-0.5-1.4,0.4-1.6c1.5-0.4,2.9-0.7,4.4-1c0.3,0,0.8,0.2,0.8,0.4
	c0.1,0.5,0.1,1.2-0.1,1.6C391.7,9.7,390.5,10,388.9,10.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity376 ? this.state.opacity376 : 1}
              fill={this.state.color376 ? this.state.color376 : 'transparent'} onClick={() => {
          this.setState({ color376: color, opacity376: opacity });
        }} d="M378.1,15.1c1.8,1.2,1.9,4.5,0,5.4c-0.4,0.2-1.2,0-1.7-0.3c-0.3-0.2-0.4-1-0.3-1.4
	C376.7,17.6,377.4,16.4,378.1,15.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity377 ? this.state.opacity377 : 1}
              fill={this.state.color377 ? this.state.color377 : 'transparent'} onClick={() => {
          this.setState({ color377: color, opacity377: opacity });
        }} d="M389,32.5c0.1-0.4,0.1-0.8,0.2-1.1c0.1,0,0.3,0,0.4-0.1c0.2,0.8,0.6,1.6,0.4,2.3c-0.3,1.2-1.5,0.8-2.4,0.9
	c-0.6,0-1.5-0.3-1.7-0.8c-0.4-0.7,0.6-1.7,1.6-1.6C388,32.2,388.4,32.4,389,32.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity378 ? this.state.opacity378 : 1}
              fill={this.state.color378 ? this.state.color378 : 'transparent'} onClick={() => {
          this.setState({ color378: color, opacity378: opacity });
        }} d="M397.8,31.5c0.1-0.5,0.2-1,0.4-1.4c0.1,0,0.2,0.1,0.3,0.1c0.1,0.8,0.3,1.7,0.5,2.5c0.2,0.7-0.2,1-0.8,1.1
	c-0.5,0.1-1.1,0.1-1.5,0c-0.5-0.2-1.1-0.6-1.2-1c-0.2-0.8,0.5-1.1,1.2-1.3C397,31.4,397.4,31.5,397.8,31.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity379 ? this.state.opacity379 : 1}
              fill={this.state.color379 ? this.state.color379 : 'transparent'} onClick={() => {
          this.setState({ color379: color, opacity379: opacity });
        }} d="M405.7,29.7c0.1-0.6,0.1-1,0.2-1.5c0.1,0,0.1,0,0.2,0c0.2,0.8,0.4,1.7,0.6,2.5c0.2,0.7-0.2,1.1-0.9,1.2
	c-0.3,0.1-0.7,0.1-1,0c-0.7-0.1-1.6-0.2-1.5-1.3c0.1-0.9,0.8-1.3,1.7-1.2C405.2,29.6,405.4,29.6,405.7,29.7z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity380 ? this.state.opacity380 : 1}
              fill={this.state.color380 ? this.state.color380 : 'transparent'} onClick={() => {
          this.setState({ color380: color, opacity380: opacity });
        }} d="M394.8,19.9c0.2,1.7-1.8,3.3-3.3,3.1c-1.6-0.1-3.2-1.6-3.2-2.9c0-1.3,2.3-3,3.7-2.9
	C393.5,17.4,394.9,18.7,394.8,19.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity381 ? this.state.opacity381 : 1}
              fill={this.state.color381 ? this.state.color381 : 'transparent'} onClick={() => {
          this.setState({ color381: color, opacity381: opacity });
        }} d="M410.2,19.8c0.4,1,0.4,2-0.1,3c-0.8,1.4-2.5,1.9-4.1,0.9c-1.3-0.7-1.4-2.3-0.5-3.8c1-1.5,2.4-1.8,3.8-0.8
	C409.6,19.4,410,19.6,410.2,19.8C410.3,19.8,410.2,19.8,410.2,19.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity382 ? this.state.opacity382 : 1}
              fill={this.state.color382 ? this.state.color382 : 'transparent'} onClick={() => {
          this.setState({ color382: color, opacity382: opacity });
        }} d="M393.8,14.4c-1-0.3-1.7-0.6-1.7-1.6c0-0.8,0.9-1.8,1.5-1.5c0.7,0.4,1.2,1.2,1.8,1.9
	C394.9,13.6,394.3,14,393.8,14.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity383 ? this.state.opacity383 : 1}
              fill={this.state.color383 ? this.state.color383 : 'transparent'} onClick={() => {
          this.setState({ color383: color, opacity383: opacity });
        }}
              d="M399.8,21.2c-0.5-0.5-1.1-1-1-1.1c0.2-0.5,0.7-1,1.1-1.1c0.3-0.1,1,0.6,1,1C401,20.2,400.4,20.6,399.8,21.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity384 ? this.state.opacity384 : 1}
              fill={this.state.color384 ? this.state.color384 : 'transparent'} onClick={() => {
          this.setState({ color384: color, opacity384: opacity });
        }} d="M398,25.9c0.2,0,0.4-0.1,0.6,0c0.4,0.3,0.8,0.6,1.2,0.9c-0.4,0.2-0.8,0.6-1.1,0.6c-0.3-0.1-0.5-0.6-0.7-1
	C397.9,26.2,398,26,398,25.9L398,25.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity385 ? this.state.opacity385 : 1}
              fill={this.state.color385 ? this.state.color385 : 'transparent'} onClick={() => {
          this.setState({ color385: color, opacity385: opacity });
        }} d="M407.1,14.8c0,0.3,0.2,0.8,0.1,1c-0.1,0.2-0.6,0.2-0.9,0.2c-0.1,0-0.2-0.5-0.2-0.6
	C406.4,15.2,406.8,15,407.1,14.8C407.1,14.8,407.1,14.8,407.1,14.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity386 ? this.state.opacity386 : 1}
              fill={this.state.color386 ? this.state.color386 : 'transparent'} onClick={() => {
          this.setState({ color386: color, opacity386: opacity });
        }} d="M385.5,22.4c0.2,0.3,0.4,0.5,0.9,1.1c-0.8,0.1-1.2,0.2-1.6,0.1c-0.1,0-0.3-0.6-0.3-0.6
	C384.8,22.7,385.2,22.6,385.5,22.4L385.5,22.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity387 ? this.state.opacity387 : 1}
              fill={this.state.color387 ? this.state.color387 : 'transparent'} onClick={() => {
          this.setState({ color387: color, opacity387: opacity });
        }}
              d="M383.6,14.5c0,0.4-0.5,0.7-0.9,0.7s-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8S383.6,13.6,383.6,14.5z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity388 ? this.state.opacity388 : 1}
              fill={this.state.color388 ? this.state.color388 : 'transparent'} onClick={() => {
          this.setState({ color388: color, opacity388: opacity });
        }} d="M455.4,20.6c-0.3-1.9-1.3-3.4-2-5.1c-0.7-1.7-1.8-2.9-3.2-4c-0.4,1.8-1.2,2.4-3.3,2.6c-1.1,0.1-2.5-0.7-2.8-1.9
	c-0.2-0.7-0.2-1.6,0.1-2.2c0.3-0.6,1.1-0.9,1.7-1.3c-2.1-1.1-5.8-1.5-8.4-1c0.8,2.1-0.9,3.2-2.5,3.6c-2.1,0.5-3.7-0.3-4.1-2.2
	c-2.7,0.8-6.2,3.8-7.1,6c0.5,1.3,1.1,2.4,1.1,3.4c0,1-0.6,2.1-0.9,3.2c1.5,0.4,2.7,1.5,3.5,3c0.8,1.5,1.2,3.1,0,4.9
	c1.7,0.6,3.2,1,4.7,1.5c0.4-2,1.5-3.2,3.2-3.6c1.3-0.3,2.6-0.2,3.4,1.1l0,0c1,1.8,2.4,1.8,4.1,1.1c0.4-0.2,1-0.1,1.4-0.3
	c2.6-0.8,5.4-1.4,7.8-2.6C455.1,25.3,456,24.8,455.4,20.6z M428.8,15.6c-0.5,0.2-1.1,0.4-1.7,0.4c-0.2,0-0.3-0.6-0.2-1c0,0,0,0,0,0
	c0-0.1,0-0.3,0.1-0.4c0-0.2,0.2-0.4,0.4-0.4c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.2c0.1,0,0.1,0,0.2-0.1C428.6,14.9,428.8,15.6,428.8,15.6z M429.4,24.3c-0.1,0-0.4-0.9-0.3-1.2
	c0.2-0.5,0.7-1,1.3-0.2l0,0c0.3,0.4,0.6,0.7,0.9,1.1C430.6,24.1,430,24.3,429.4,24.3z M436.2,23.9c-1.8,0-3.6-1.7-3.5-3.3
	c0.1-1.4,2.2-3.1,3.8-3c1.7,0.1,3.3,1.4,3.2,2.7C439.6,22.7,437.9,23.9,436.2,23.9z M440.1,14.2c-0.3,0.6-1.2,0.9-1.8,1.4
	c-1.2-0.3-2.1-1.1-2.1-2c0-1,0.8-1.7,1.8-1.6C438.9,12,440.5,13.6,440.1,14.2z M443.9,27.8c-0.5,0.1-1.3,0.2-1.6-0.1
	c-0.4-0.3-0.7-1.1,0.2-1.5l0,0c0.9-0.4,1.9-0.1,2,0.7C444.5,27.2,444.1,27.8,443.9,27.8z M444.1,21.8c-0.7,0-1.3-0.3-1.4-1.2
	c-0.1-0.6,0.8-1.6,1.5-1.3c0.5,0.2,1.3,0.8,1.3,1.3C445.7,21.3,445,21.8,444.1,21.8z M450.1,15.7c0.2-0.4,0.6-0.9,1.1-0.2
	c0.5,0.2,1,0.5,0.5,1c-0.4,0.3-1.1,0.3-1.6,0.4C450.1,16.6,449.9,16.1,450.1,15.7z M454.5,23.1c-0.5,1.3-1.6,2.1-2.7,2
	c-1.6-0.1-3.3-1.2-3.1-2.9c0.3-2,1.9-3.7,3.7-3.3c0.7,0.2,1.2,0.9,1.8,1.4l0,0C455.2,21.2,454.9,22.2,454.5,23.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity389 ? this.state.opacity389 : 1}
              fill={this.state.color389 ? this.state.color389 : 'transparent'} onClick={() => {
          this.setState({ color389: color, opacity389: opacity });
        }} d="M424.8,29.3c-0.2,0.6-0.3,1.1-0.5,1.6c-0.1,0-0.3,0.1-0.4,0.1c0.5-1.4-0.1-1.3-1.1-1.4
	c-1.6-0.2-3.2-0.8-4.1-2.4c-0.1-0.2-0.3-0.5-0.2-0.7c0.5-1.4,0.9-2.8,1.5-4.1c0.1-0.3,0.7-0.6,1.1-0.7c2.9-0.4,5.9,2,6.3,4.8
	c0.3,1.9-0.2,2.6-2.1,2.7C425.1,29.2,424.8,29.3,424.8,29.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity390 ? this.state.opacity390 : 1}
              fill={this.state.color390 ? this.state.color390 : 'transparent'} onClick={() => {
          this.setState({ color390: color, opacity390: opacity });
        }} d="M447.1,13.7c-1.3,0-2.6-1-2.8-1.8c-0.4-1.4,0.8-3,2.2-2.7c1.1,0.2,2.3,1,3.2,1.8c0.6,0.6,0.1,1.9-0.9,2.3
	C448.1,13.5,447.4,13.6,447.1,13.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity391 ? this.state.opacity391 : 1}
              fill={this.state.color391 ? this.state.color391 : 'transparent'} onClick={() => {
          this.setState({ color391: color, opacity391: opacity });
        }} d="M438.7,28.6L438.7,28.6c0.2,0.5,0.5,1,0.9,1.8c-2.4,0.2-4.6,0.4-6.8,0.7c-0.2-1.7,1-2.4,2-3.1
	c0.9-0.6,2-0.4,3,0.1C438.2,28.3,438.5,28.4,438.7,28.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity392 ? this.state.opacity392 : 1}
              fill={this.state.color392 ? this.state.color392 : 'transparent'} onClick={() => {
          this.setState({ color392: color, opacity392: opacity });
        }} d="M433.6,11.1c-0.5-0.2-1.3-0.5-1.9-0.9c-0.7-0.5-0.5-1.3,0.3-1.6c1.4-0.4,2.8-0.7,4.3-1c0.2,0,0.8,0.2,0.8,0.4
	c0.1,0.5,0.1,1.2-0.1,1.6C436.3,10.6,435.1,11,433.6,11.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity393 ? this.state.opacity393 : 1}
              fill={this.state.color393 ? this.state.color393 : 'transparent'} onClick={() => {
          this.setState({ color393: color, opacity393: opacity });
        }} d="M423.2,15.9c1.7,1.1,1.8,4.3,0,5.3c-0.4,0.2-1.2,0-1.6-0.3c-0.3-0.2-0.4-1-0.3-1.3
	C421.8,18.3,422.5,17.1,423.2,15.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity394 ? this.state.opacity394 : 1}
              fill={this.state.color394 ? this.state.color394 : 'transparent'} onClick={() => {
          this.setState({ color394: color, opacity394: opacity });
        }} d="M433.7,32.6c0.1-0.4,0.1-0.7,0.2-1c0.1,0,0.2,0,0.4,0c0.1,0.8,0.5,1.6,0.4,2.3c-0.3,1.2-1.5,0.8-2.3,0.8
	c-0.5,0-1.4-0.3-1.7-0.7c-0.4-0.7,0.6-1.6,1.6-1.5C432.7,32.4,433.1,32.5,433.7,32.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity395 ? this.state.opacity395 : 1}
              fill={this.state.color395 ? this.state.color395 : 'transparent'} onClick={() => {
          this.setState({ color395: color, opacity395: opacity });
        }} d="M442.1,31.7c0.1-0.5,0.2-0.9,0.3-1.4c0.1,0,0.2,0.1,0.3,0.1c0.1,0.8,0.3,1.6,0.4,2.4c0.2,0.7-0.2,1-0.8,1.1
	c-0.5,0.1-1,0.1-1.4,0c-0.4-0.2-1-0.6-1.1-0.9c-0.2-0.8,0.5-1.1,1.1-1.2C441.4,31.6,441.8,31.7,442.1,31.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity396 ? this.state.opacity396 : 1}
              fill={this.state.color396 ? this.state.color396 : 'transparent'} onClick={() => {
          this.setState({ color396: color, opacity396: opacity });
        }} d="M449.8,29.9c0.1-0.6,0.1-1,0.2-1.4c0.1,0,0.1,0,0.2,0c0.2,0.8,0.4,1.6,0.6,2.5c0.2,0.7-0.2,1.1-0.9,1.2
	c-0.3,0.1-0.7,0.1-1,0c-0.7-0.1-1.6-0.2-1.4-1.2c0.1-0.9,0.8-1.2,1.7-1.1C449.4,29.8,449.5,29.9,449.8,29.9z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity397 ? this.state.opacity397 : 1}
              fill={this.state.color397 ? this.state.color397 : 'transparent'} onClick={() => {
          this.setState({ color397: color, opacity397: opacity });
        }} d="M439.3,20.5c0.2,1.7-1.7,3.1-3.2,3c-1.5-0.1-3.1-1.6-3.1-2.8c0-1.2,2.2-2.9,3.6-2.8
	C438,18.1,439.3,19.3,439.3,20.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity398 ? this.state.opacity398 : 1}
              fill={this.state.color398 ? this.state.color398 : 'transparent'} onClick={() => {
          this.setState({ color398: color, opacity398: opacity });
        }} d="M454.2,20.4c0.4,1,0.4,2-0.1,2.9c-0.8,1.4-2.4,1.8-3.9,0.9c-1.2-0.7-1.4-2.2-0.4-3.6c0.9-1.4,2.3-1.7,3.6-0.8
	C453.6,20,453.9,20.2,454.2,20.4C454.2,20.4,454.2,20.4,454.2,20.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity399 ? this.state.opacity399 : 1}
              fill={this.state.color399 ? this.state.color399 : 'transparent'} onClick={() => {
          this.setState({ color399: color, opacity399: opacity });
        }} d="M438.3,15.2c-1-0.3-1.7-0.6-1.7-1.5c0-0.8,0.9-1.8,1.5-1.4c0.7,0.4,1.2,1.2,1.7,1.8
	C439.4,14.4,438.8,14.8,438.3,15.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity400 ? this.state.opacity400 : 1}
              fill={this.state.color400 ? this.state.color400 : 'transparent'} onClick={() => {
          this.setState({ color400: color, opacity400: opacity });
        }} d="M444.2,21.7c-0.5-0.5-1.1-1-1-1.1c0.2-0.5,0.6-1,1.1-1.1c0.3-0.1,0.9,0.6,1,1C445.3,20.8,444.6,21.2,444.2,21.7
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity401 ? this.state.opacity401 : 1}
              fill={this.state.color401 ? this.state.color401 : 'transparent'} onClick={() => {
          this.setState({ color401: color, opacity401: opacity });
        }} d="M442.4,26.3c0.2,0,0.4-0.1,0.5,0c0.4,0.2,0.8,0.6,1.1,0.8c-0.4,0.2-0.8,0.6-1.1,0.6c-0.3,0-0.5-0.6-0.7-1
	C442.2,26.6,442.4,26.4,442.4,26.3L442.4,26.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity402 ? this.state.opacity402 : 1}
              fill={this.state.color402 ? this.state.color402 : 'transparent'} onClick={() => {
          this.setState({ color402: color, opacity402: opacity });
        }} d="M451.2,15.5c0,0.3,0.2,0.7,0.1,0.9c-0.1,0.2-0.6,0.2-0.9,0.2c-0.1,0-0.2-0.5-0.2-0.5
	C450.5,15.9,450.9,15.8,451.2,15.5C451.2,15.6,451.2,15.5,451.2,15.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity403 ? this.state.opacity403 : 1}
              fill={this.state.color403 ? this.state.color403 : 'transparent'} onClick={() => {
          this.setState({ color403: color, opacity403: opacity });
        }} d="M430.3,22.9c0.2,0.2,0.4,0.5,0.8,1c-0.7,0.1-1.2,0.2-1.6,0.1c-0.1,0-0.3-0.6-0.3-0.6
	C429.6,23.2,430,23.1,430.3,22.9L430.3,22.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity404 ? this.state.opacity404 : 1}
              fill={this.state.color404 ? this.state.color404 : 'transparent'} onClick={() => {
          this.setState({ color404: color, opacity404: opacity });
        }}
              d="M428.5,15.3c0,0.4-0.4,0.7-0.9,0.7c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8C428.1,14.4,428.5,14.4,428.5,15.3z"
        />
      </SvgIcon>
    )
  }
};