import React, {Component} from 'react';
import {Stage,Layer,Image} from "react-konva";
export default class URLImage extends React.Component<any> {
  state = {
    image: null,
    width: 0,
    height: 0
  };
  private image: any;
  private imageNode: any;
  constructor (props) {
    super (props);
    this.state.height = this.props.height;
    this.state.width = this.props.width;
  }
  componentDidMount() {
    this.loadImage();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }
  componentWillUnmount() {
    this.image.removeEventListener('load', this.handleLoad);
  }
  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.src = this.props.src;
    this.image.addEventListener('load', this.handleLoad);
  }
  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image,
      height: (this.state.width / this.image.width) * this.image.height
    });
    // if you keep same image object during source updates
    // you will have to update layer manually:
    // this.imageNode.getLayer().batchDraw();
  };
  render() {
    return (
      <Image
        x={this.props.x}
        y={this.props.y}
        width={this.state.width}
        height={this.state.height}
        image={this.state.image}
        ref={(node) => {
          this.imageNode = node;
        }}
      />
    );
  }
}