const others = (state = [], action) => {
  switch (action.type) {
    case 'MOUSE_MOVE':
      return {
        ...state,
        x: action.x,
        y: action.y
      }
    default:
      return { ...state };
  }
}

export default others;
