import React, { Component } from "react";
import { Image, Transformer } from 'react-konva';

// custom component that will handle loading image from url
// you may add more logic here to handle "loading" state
// or if loading is failed
// VERY IMPORTANT NOTES:
// at first we will set image state to null
// and then we will set it to native image instance when it is loaded

export default class URLImage extends Component<any, any> {
  image: any;
  initSetPos: boolean = false;
  private readonly trRef: any;
  private readonly shapeRef: any;

  constructor(props) {
    super(props);
    this.trRef = React.createRef();
    this.shapeRef = React.createRef();
    const opacity = this.props.defaultHidden ? 0 : 1;
    this.state = {
      image: null,
      isDragging: false,
      x: 50,
      y: 50,
      rotation: 0,
      opacity: opacity
    };
  }

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
    if (this.props.draggable && !this.initSetPos) {
      const { x, y, rotation } = this.props;
      if (x || y || rotation) {
        this.initSetPos = true;
        this.setState({
          x,
          y,
          rotation
        })
      }
    }
    if (this.props.changed && this.state.opacity === 0) {
      this.setState({
        opacity: 1
      })
    }
    if (this.props.isSelected) {
      if (this.trRef?.current?.nodes && this.shapeRef?.current) {
        this.trRef.current.nodes([this.shapeRef.current]);
      }
      this.trRef.current?.getLayer().batchDraw();
    }
  }

  componentWillUnmount() {
    this.image.removeEventListener('load', this.handleLoad);
  }

  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.src = this.props.src + "?t=" + new Date().getTime();
    this.image.crossOrigin = 'Anonymous';
    this.image.addEventListener('load', this.handleLoad);
  }

  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image
    });
    // if you keep same image object during source updates
    // you will have to update layer manually:
    // this.imageNode.getLayer().batchDraw();
  };

  render() {
    const { x, y, rotation, width, height, draggable, dragImgEnabled, rotatable, isSelected, id } = this.props;
    const transformable = draggable && dragImgEnabled && rotatable && isSelected;
    return (
      <>
        <Image
          opacity={this.state.opacity}
          draggable={draggable && dragImgEnabled}
          width={width}
          height={height}
          x={draggable ? this.state.x : x}
          y={draggable ? this.state.y : y}
          rotation={draggable ? this.state.rotation : rotation}
          image={this.state.image}
          ref={this.shapeRef}
          onDragStart={() => {
            this.setState({
              isDragging: true,
              opacity: 1
            });
          }}
          onDragEnd={e => {
            this.setState({
              isDragging: false,
              x: e.target.x(),
              y: e.target.y()
            }, () => {
              if (this.props.onDraggableImageMoved) {
                this.props.onDraggableImageMoved();
              }
            });
          }}
          onClick={(e) => {
            if (this.props.onClick) {
              this.props.onClick(e, id);
            }
          }}
          onMouseMove={(e) => {
            if (this.props.onMouseMove) {
              this.props.onMouseMove(e);
            }
          }}
          onMouseDown={(e) => {
            if (this.props.onMouseDown) {
              this.props.onMouseDown(e);
            }
          }}
          onMouseUp={(e) => {
            if (this.props.onMouseUp) {
              this.props.onMouseUp(e);
            }
          }}
          onTransformEnd={(e) => {
            const node = this.shapeRef.current;
            node.scaleX(1);
            node.scaleY(1);

            this.setState({
              x: node.x(),
              y: node.y(),
              rotation: node.rotation()
            })
          }}

        />
        {transformable && <Transformer
					enabledAnchors={[]}
					ref={this.trRef}
					boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
				/>
        }
      </>
    );
  }

}