import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_34_1 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 510.2 159.4"}
        style={{ width: "100%", height: "100%" }}
      >
        <path fill="transparent" d="M307,139.9c-0.9-2.6,0.5-4.7,1.4-7c1.9-4.5,5.7-7,9.9-8.9c3.2-1.5,6.2-3.3,7.6-6.9c0.3-0.8,0.5-1.7,0.7-2.5
	c0-0.2-0.1-0.7-0.3-0.8c-0.3-0.2-0.7-0.2-1-0.1c-1.1,0.4-2.3,1-3.4,1.4c-0.9,0.3-1.5,0.1-1.7-0.9c-0.1-0.7-0.2-1-1.1-0.6
	c-1.2,0.5-1.9,0.1-1.9-1.3c0-1.2,0.2-2.4,0.4-3.6c0.1-1,0.8-1.2,1.7-1c1,0.2,2,0.6,3.4,0.9c-0.9-1.1-1.6-2-2.3-3
	c-2.3-3.4-3.2-7.4-4.2-11.3c-1.1-4.3-1.2-8.6-0.3-12.9c0.6-2.8,1.5-5.5,2.8-8c2.9-5.6,7.3-9.9,12.9-12.8c1.5-0.8,3.3-0.9,5-1.4
	c1-0.3,1.9-0.7,2.9-0.8c2-0.1,4-0.2,6,0c1.6,0.1,3.2,0.8,4.8,0.9c5.5,0.4,8.8,4,11.9,7.9c2.3,2.8,3.7,6,4.5,9.5
	c0.9,4,0.4,7.8-1.2,11.7c-1.5,3.7-3.8,6.7-6.4,9.4c-2,2.1-4.3,4.1-6.8,5.5c-2.8,1.5-5.9,2.4-9,3.4c-2.4,0.8-4.8,1.4-7.2,2.1
	c-2,0.6-3.9,1.3-5.7,1.9c-0.1,0.6-0.2,1.1-0.3,1.7c0.9,0.6,1.8,1.2,2.7,2c0.3,0.2,0.3,0.8,0.5,1.2c-0.4-0.1-0.8-0.1-1.1-0.3
	c-0.7-0.4-1.4-1-2-1.5c-1.1-0.8-2-0.5-2.3,0.8c-0.4,2.4-1.4,4.4-3.1,6.1c-1,1-2.2,1.7-3.4,2.5c-1.3,0.8-2.7,1.4-4,2.1
	c-1,0.6-2.1,1.2-3,1.9c-1.3,1.1-2.7,2.1-3.6,3.4c-1.5,2.5-3.2,5.1-3,8.3c0,0.3-0.1,0.5-0.1,0.8C307.4,139.9,307.2,139.9,307,139.9z
	 M316.6,89.9c0.6,2.5,1.5,6.3,2.5,10.1c0.9,3.4,3.3,6.1,5.3,8.9c0.8,1.1,2.4,1.7,3.7,1.3c1.6-0.5,3.3-0.9,4.9-1.4
	c5.8-2,11.9-2.9,17.5-5.7c0.6-0.3,1.4-0.4,1.9-0.9c2.8-2.1,5.4-4.3,7.7-7c3.9-4.7,6.5-9.7,5.8-16c-0.3-3.1-1.2-6.1-2.9-8.7
	c-1.8-2.7-3.7-5.4-6.4-7.4c-0.5-0.4-0.9-0.9-1.4-1.1c-1.7-0.6-3.4-1.3-5.2-1.6c-2.9-0.5-5.8-0.8-8.7-1c-1.5-0.1-2.9,0.7-4.4,0.8
	c-3.2,0.3-5.9,1.7-8.3,3.8c-1.7,1.5-3.5,3-5.1,4.6c-1.3,1.4-2.5,2.9-3.5,4.5C317.3,77.9,316.7,83.2,316.6,89.9z M324.8,111.4
	c0-0.2,0.1-0.4,0.1-0.6c-2-0.8-3.9-1.6-6.2-2.5c-0.2,1.7-0.3,2.9-0.4,4.3C320.7,112.2,322.8,111.8,324.8,111.4z M325.2,112.4
	c-0.2-0.1-0.2-0.1-0.3-0.1c-0.9,0.2-1.8,0.5-2.7,0.6c-1.1,0.2-1.1,0.7-0.6,1.5C324.5,113.7,324.7,113.6,325.2,112.4z M326.3,111.7
	c0.4,0.5,0.8,1.1,1,1c0.7-0.2,1.4-0.6,2.1-1c-0.1-0.1-0.1-0.3-0.2-0.4C328.3,111.4,327.5,111.5,326.3,111.7z" />
        <path fill="transparent" d="M111.4,139.9c0.7-2.1,1.3-4.1,2.2-6.1c0.5-1.3,1.3-2.5,2.2-3.7c1.6-2.1,3.3-4.1,5-6.1c2.2-2.4,3.3-5.2,4-8.3
	c0.6-2.8,1.3-5.5,1.9-8.3c0.2-0.7,0.6-1.4-0.5-1.9c-0.4-0.2-0.3-1.3-0.5-2.1c-2.8-1-4.5,1.8-7,2.2c2-1.7,3.8-3.6,6.9-3.4
	c-0.9-1.5-1.6-2.7-2.4-4c-1.8-3.2-2.8-6.6-3-10.2c-0.1-2.1-0.3-4.1-0.3-6.2c0-1.7,0.3-3.3,0.7-5c0.4-2.2,1-4.4,1.6-6.6
	c0.3-1.1,0.9-2.1,1.4-3.1c2.9-5.4,6.6-10,12.1-13c1.3-0.7,2.5-1.5,3.9-2.1c1.3-0.6,2.8-0.9,4.2-1.3c1.6-0.3,3.1-0.6,4.7-0.8
	c1.1-0.1,2.2-0.2,3.2,0c2.7,0.4,5.6,0.2,8.1,1.5c1.4,0.7,2.8,1.5,3.8,2.6c2.4,2.6,4.6,5.3,6.7,8.1c0.6,0.8,0.9,2,1.2,3.1
	c0.6,2,1.3,4,1.6,6c0.3,3.2,0,6.4-1.6,9.3c-1.2,2.2-2.5,4.4-4.2,6.3c-3,3.5-6.5,6.5-10.6,8.8c-1.9,1.1-3.8,2.4-5.7,3.4
	c-2.2,1.1-4.5,2.2-6.9,3c-2.7,1-5.4,1.7-8.2,2.5c0.2,0.3,0.6,0.8,0.9,1.3c0.6,1,0.6,1.8-0.4,2.4c-1.3,0.8-2.7,1.6-4,2.3
	c-0.8,0.4-1.4,0.1-1.7-0.8c-0.4-1.2-1-2.4-1.6-3.6c-0.1-0.2-0.3-0.4-0.5-0.5c-0.1,0.2-0.4,0.4-0.4,0.6c-0.8,3.2-1.5,6.4-2.2,9.6
	c-0.7,3.2-1.9,6.2-4.3,8.4c-2.8,2.5-4.6,5.6-6.7,8.6c-1.4,2-1.9,4.5-2.4,6.9C112.2,139.9,111.8,139.9,111.4,139.9z M127.7,102.7
	c-0.4,0.8-0.7,1.6-1.1,2.3c0.1,0.1,0.2,0.2,0.3,0.3c0.8-0.2,1.7-0.4,2.5-0.8c0.3-0.1,0.6-0.6,0.6-0.8c-0.1-0.3-0.5-0.6-0.8-0.7
	C128.7,102.8,128.2,102.8,127.7,102.7c0.7,0,1.4,0,2,0.1c0.5,0.1,1,0.5,1.4,0.5c3.1,0.1,6.3,0.1,9.2-1c3.1-1.2,6.2-2.4,9.3-3.9
	c4.7-2.2,9.2-4.7,13.1-8.3c3.1-2.9,5.9-6.1,7.6-10c1.6-3.8,2.1-7.7,0.6-11.7c-0.3-0.7-0.5-1.5-0.7-2.3c-0.3-2.2-1.4-4.1-2.8-5.7
	c-1.1-1.3-2.2-2.6-3.4-3.8c-1.5-1.6-3.1-3.1-5.2-3.8c-1.8-0.6-3.8-1-5.7-1.3c-4.9-0.8-9.6,0.3-14,2.2c-4.7,2-8.9,5-11.8,9.4
	c-1.9,2.9-3.9,5.8-4.8,9.3c-0.4,1.4-0.7,2.8-0.9,4.2c-0.4,2.9-1.1,5.7-0.8,8.6c0.4,4.4,0.9,8.7,3.2,12.6
	C125.2,99.5,126.6,101,127.7,102.7z M136.1,107c-0.1-0.1-0.1-0.2-0.1-0.3c-0.6-1.4-3.5-2.8-4.9-2.2c-0.6,0.3-1.3,0.7-0.7,1.6
	c0.5,0.8,0.8,1.8,1.3,2.7c0.1,0.3,0.6,0.7,0.7,0.6c1.2-0.7,2.4-1.4,3.6-2.1C136.1,107.3,136.1,107.1,136.1,107z" />
        <path fill="transparent" d="M248.2,139.9c-0.5-2.5-0.3-5,0.1-7.5c0.8-4.9,3-9,6.7-12.2c1.8-1.6,3.5-3.2,5.8-4.1c0.5-0.2,1.1-0.8,1.3-1.3
	c0.3-0.8,0.5-1.8,0.2-2.5c-0.4-1-2.2-1.5-3.2-1.1c-0.8,0.3-1.6,0.7-2.5,0.9c1.1-1,2.3-1.7,3.8-1.9c0.5-0.1,1.1-0.6,1.4-1
	c0.1-0.2-0.3-0.9-0.7-1.2c-4.6-4.1-6.6-9.3-6.8-15.3c-0.2-5.1,0.5-9.9,2-14.7c1.4-4.5,3.5-8.6,6.8-12.1c3.1-3.3,6.7-5.9,11.4-6.7
	c1.5-0.2,2.9-0.7,4.4-1c3.8-0.7,7.5,0.3,11.2,1.1c0.7,0.2,1.5,0.2,2.2,0.3c0.5,0.1,1,0.1,1.4,0.3c5.9,3,10.6,7,12.5,13.7
	c1.1,3.9,0.6,7.6-0.7,11.4c-1.2,3.4-2.8,6.5-5.1,9.3c-2.8,3.4-6.1,6.3-10.2,8.1c-2.7,1.2-5.3,2.9-8.1,3.8c-4,1.3-7.7,3.6-12.1,3.7
	c-0.9,0-1.8,0.4-2.8,1c0.8,0.5,1.6,0.9,2.4,1.5c1.2,0.9,2.5,1.6,3.6,2.7c1.1,1.1,1,2.4-0.2,3.4c-1,0.9-2.3,1.4-3.5,2.1
	c-0.3,0.2-0.7,0.2-1,0.3c-2.6,0.6-2.6,0.6-3.2-2c-0.4-1.7-0.7-3.5-1.2-5.2c-1,1.8-2.2,3-4.1,3.9c-2,0.9-3.6,2.8-5.3,4.3
	c-1.1,1-2,2.2-2.7,3.4c-0.8,1.3-1.3,2.8-1.8,4.3c-1,2.9-1.5,5.8-1.2,8.9c0.1,0.6,0.2,1.1,0.3,1.7
	C248.8,139.9,248.5,139.9,248.2,139.9z M255.5,91.6c0,0.2,0,0.3,0,0.5c0,6.1,2,11.3,6.7,15.4c0.7,0.6,1.6,1.5,2.4,1.5
	c1.1,0.1,2.2,0.1,3.3,0c2.6-0.3,5.2-0.9,7.8-1.6c4.2-1,8.1-2.8,12-4.8c2.3-1.2,4.7-2.2,6.6-3.8c4.8-3.9,8.4-8.7,10.4-14.7
	c1.5-4.5,1.4-8.8-0.6-12.9c-1.6-3.4-4.2-6.1-7.3-8.2c-1.2-0.8-2.5-1.5-3.9-2c-1.6-0.5-3.2-0.8-4.9-1.1c-3.6-0.7-7.3-1.1-10.9-0.4
	c-5.5,1.1-10.1,3.7-13.8,8c-0.7,0.8-1.5,1.7-2,2.6c-1.1,2-2.2,4.1-3,6.2C256.3,81.2,255.2,86.3,255.5,91.6z M265.5,114.2
	c0.4,1.8,0.7,3.5,1.1,5.2c0.1,0.3,0.5,0.6,0.7,0.6c1.4-0.4,3-0.7,4.3-1.4c2-1,2.1-2.5,0.3-3.8c-1.5-1.1-3.2-2-4.8-2.9
	c-0.5-0.2-1.1,0-1.7,0c0,0.2-0.1,0.4-0.1,0.5c1.4,1,2.8,2,4.4,3.2C267.8,116.1,267,114.6,265.5,114.2z M265.6,111c0-0.1,0-0.2,0-0.4
	c-1-0.3-2-0.6-3.4-1c0.2,0.6,0.4,1.4,0.5,1.4C263.7,111.2,264.7,111.1,265.6,111z" />
        <path fill="transparent" d="M182.6,61.8c-0.4-0.5-0.8-0.4-1.5,0.1c-1.1,0.8-2.4,1.6-4,1.2c-0.4-0.1-0.7-0.5-1-0.8c0.4-0.1,0.8-0.4,1.1-0.3
	c1.8,0.4,3-0.7,4.2-1.5c0.1-0.1-0.1-1.1-0.4-1.4c-3.2-3.1-6.7-5.8-9.5-9.2c-2.2-2.6-4-5.7-5.1-8.9c-1.5-4.4-2.2-9-0.8-13.7
	c1-3.3,2-6.7,3.3-9.8c1.4-3.3,4-5.7,6.9-7.8c1.4-1,2.8-2.1,4.3-2.8c2.3-1,4.7-1.9,7.2-2.4c3.2-0.6,6.6-0.9,9.9-1.1
	c5.6-0.3,11,0.7,15.8,3.6c1.5,0.9,2.9,2.1,4,3.5c3.5,4.6,5.5,9.8,5.7,15.5c0.1,5.1-1.2,10-3.8,14.3c-1.2,2-3.1,3.6-4.8,5.3
	c-4.5,4.4-9.8,7.6-15.7,9.7c-2,0.7-4.1,1.1-6.1,1.5c-1.7,0.4-3.4,0.9-5.1,2.1c1.2,0.5,2.3,0.8,3.2,1.4c1.3,0.8,2.5,1.8,3.6,2.8
	c0.2,0.2,0.2,1.1-0.1,1.4c-1.1,1.6-2.2,3.1-3.9,4.1c-2.2,1.3-2.9,1-4.2-1.2c-0.9-1.4-1.4-3.1-2.1-4.8c-0.4,1-0.8,2.1-1.1,3.1
	c-0.2,0.8-0.3,1.7-0.5,2.6c-0.9,4-2.1,7.9-3.9,11.5c-1.4,2.8-2.7,5.7-3.1,8.9c-0.4,4.3-3,7.5-5.8,10.3c-1.8,1.8-4.1,3.2-6.3,4.5
	c-3.7,2.2-5.9,5.7-8.1,9.2c-1.2,1.8-2.4,3.7-3.6,5.5c-0.2,0.3-0.6,0.6-0.8,0.9c-0.1-0.1-0.2-0.1-0.3-0.2c2.8-4.3,5.4-8.8,8.4-13
	c0.9-1.3,2.6-2.2,4.1-3.1c3.1-1.7,5.7-3.9,8-6.6c2.2-2.5,3-5.5,3.6-8.7c0.5-2.7,1.7-5.3,2.8-7.8c1.9-4.2,3.3-8.5,4.2-13.1
	C181.3,65.2,182,63.6,182.6,61.8z M221.4,26.5c0-0.3,0-0.9-0.1-1.4c-0.7-3-1.2-6-2.6-8.8c-1.9-3.9-4.2-7.2-8.3-9.1
	c-4.1-1.9-8.4-2.6-12.7-2.5c-3.9,0-7.8,0.7-11.6,1.4c-2.1,0.4-4.3,1.1-6.1,2.2c-2.4,1.4-4.7,3.2-6.8,5.1c-1.3,1.1-2.2,2.7-3,4.2
	c-0.8,1.5-1.4,3.2-1.9,4.9c-0.9,2.9-2,5.9-2.2,8.9c-0.4,4.6,0.8,8.9,3.1,13.1c3,5.5,7.7,9.3,12.1,13.4c0.2,0.2,0.7,0.3,1.1,0.3
	c0.6,0.1,1.3,0.2,1.9,0.3c0.4,0,0.9,0.2,1.3,0c1.2-0.5,2.3-1.2,3.6-1.6c2.8-0.8,5.6-1.5,8.4-2.2c3.1-0.8,5.9-2.3,8.5-4.1
	c2.7-1.9,5.4-4,7.8-6.3c1.8-1.7,3.4-3.7,4.6-5.9C220.3,34.9,221.1,30.9,221.4,26.5z M185.4,63c-0.1,0.1-0.3,0.3-0.4,0.4
	c0.6,1.7,1.3,3.4,2.5,4.8c0.2,0.2,0.8,0.2,1.1,0.1c1.8-0.7,2.9-2.1,3.9-3.6c0.2-0.2,0.2-0.8,0-1c-1.3-1.6-3-2.7-5.1-3
	c-0.4-0.1-0.8,0.3-1.2,0.4c0.2,0.4,0.2,0.9,0.5,1c1.2,0.7,2.4,1.3,3.6,2c0.3,0.1,0.6,0.3,0.9,0.4c0,0.1,0,0.2,0,0.4
	c-0.5,0.1-1,0.3-1.4,0.2c-0.9-0.3-1.7-0.7-2.5-1.1C186.7,63.7,186,63.3,185.4,63z M183.1,59.1c-0.5,1.1-0.3,2,0.5,2.1
	c0.4,0.1,1.3-0.3,1.3-0.6C185,59.5,183.9,59.5,183.1,59.1z" />
        <path fill="transparent" d="M317.5,68.3c-1-0.5-2-1.1-3.3-1.8c-0.7,2.7-1.6,5.3-2.1,8c-0.8,4-1.3,8.1-3,11.9c-1.3,2.9-2.9,5.7-4.6,8.3
	c-2.5,3.8-5.3,7.3-7.8,11.1c-1.8,2.7-3.2,5.6-4.7,8.4c-0.1,0.2-0.2,0.4-0.3,0.6c-1.9,2.8-3.9,5.5-5.8,8.3c-0.1,0.1-0.2,0.1-0.4,0.1
	c0.8-1.4,1.4-3,2.3-4.3c3.1-4.3,5.3-9.1,8-13.6c1.3-2.1,2.8-4.1,4.4-6c2.8-3.4,4.5-7.4,6.7-11.2c2.9-4.9,3.5-10.5,4.5-15.9
	c0.3-1.6,0.9-3.1,1.5-4.8c-1.2,0.7-2.3,1.4-3.5,2.1c-1.9,1.1-2.1,1-3.2-1c-0.9-1.7-1.1-3.4,0.1-5.1c0.5-0.8,1.1-1.3,2.1-0.6
	c0.7,0.4,1.5,0.6,2.3,0.9c0.1-0.1,0.1-0.2,0.2-0.3c-0.9-0.7-1.7-1.5-2.7-2.2c-3.7-2.7-5.6-6.6-8-10.3c-2.2-3.5-3-7.3-2.8-11.4
	c0.1-3-0.1-6.1,0.4-9c0.5-2.6,1.5-5.1,2.9-7.4c1.8-2.9,3.9-5.7,6.3-8.2c1.6-1.7,3.9-2.9,6-4c2-1,4.3-1.8,6.5-2.5
	c3.2-1,6.5-1.4,9.9-1.4c5.3,0,10.1,1.8,14.4,4.8c1.3,0.9,2.5,1.8,3.8,2.6c1.9,1.2,3.3,2.9,4,5c0.8,2.4,1.8,4.8,2.1,7.2
	c0.7,5.1,0.5,10.2-2,14.9c-1.5,2.8-3.4,5.4-5.3,8c-0.7,1-1.7,2-2.8,2.8c-2.5,1.8-4.9,3.7-7.9,4.6c-2.2,0.7-4.4,1.3-6.6,2
	c-1.8,0.6-3.6,1.5-5.5,2.2c-1.6,0.6-3.3,1.2-4.9,1.7c-0.6,0.2-1.3,0.3-1.9,0.5c-0.8,0.3-1,2.2-0.3,2.8c0.4,0.4,0.9,0.8,1.3,1.3
	C317.7,67.9,317.6,68.1,317.5,68.3z M352.6,31.7c0.2-3.6-0.6-7-1.8-10.4c-0.8-2.5-2.1-4.5-4.3-6c-1.2-0.8-2.5-1.6-3.7-2.5
	c-3.3-2.4-7-4-11.1-4.4c-5.8-0.6-11.4,0.5-16.8,2.9c-2.5,1.1-5,2.2-6.9,4.2c-2.1,2.3-4.2,4.7-5.9,7.3c-2.1,3-3.5,6.4-3.4,10.3
	c0,1.4,0.1,2.8-0.1,4.2c-0.5,4.8,0.1,9.4,2.8,13.6c2.4,4,5,7.8,8.7,10.6c0.9,0.7,1.7,1.7,3,1.6c1.1-0.1,2.1-0.2,3.1-0.5
	c1.6-0.5,3.1-1.1,4.7-1.6c3.2-1.1,6.3-2.1,9.5-3.2c1.1-0.4,2.3-0.5,3.4-1c2.1-1,4.2-2,6.2-3.2c1.9-1.2,4-2.4,5.3-4.4
	c1.5-2.2,3.3-4.2,4.6-6.6C351.8,39.3,352.6,35.6,352.6,31.7z M310.6,64.8c-0.9-0.2-1.5-0.4-2.1-0.6c-1.2-0.4-1.8-0.2-1.8,1.1
	c0,1.3,0.4,2.5,0.8,4.1c1.7-1,3.1-1.8,4.6-2.7c-0.5-0.6-0.8-0.9-1-1.2c-1,0.2-1.9,1.3-3.1,0.2C308.8,65.5,309.6,65.2,310.6,64.8z
	 M312.5,64.3c1.3,1.2,1.7,1.2,2.9-0.1C314.4,64.2,313.6,64.3,312.5,64.3z" />
        <path fill="transparent" d="M251.9,69.5c-0.3,1.5-0.6,2.9-1,4.4c-0.4,1.4-0.9,2.9-1.4,4.3c-1.8,4.2-4.1,8.2-6.6,12c-2.1,3.2-3.9,6.7-5.9,10
	c-1.2,2-2.5,3.8-4.3,5.3c-4.5,3.7-7.6,8.4-8.4,14.4c-0.1,0.9-0.4,1.8-0.6,2.7c-0.1,0-0.3,0-0.4-0.1c0.1-1.5,0.1-3.1,0.5-4.5
	c0.9-3.5,2.1-7,4.9-9.6c1.6-1.5,3.4-3,4.8-4.7c1.5-1.9,2.8-4.1,4.1-6.2c2-3.3,3.9-6.6,5.9-9.8c2.6-4.2,5-8.6,6.3-13.4
	c0.7-2.4,1.6-4.8,0.9-7.4c-0.1-0.6-0.6-1.2-1.1-1.6c-0.6-0.5-1-1.1-0.7-1.9c0.3-0.8,0.1-1.2-0.4-1.8c-2.4-2.6-4.7-5.2-7-7.9
	c-2.6-3-4.1-6.6-4.9-10.4c-0.6-2.7-1.2-5.5-1.1-8.3c0.1-4.2-0.2-8.6,2.2-12.3c1.6-2.6,3.7-4.9,5.7-7.2c2.3-2.7,4.9-5,8.2-6.7
	c4.4-2.2,9-3.1,13.8-3.3c2.2-0.1,4.4,0,6.5,0.3c1.9,0.3,3.7,1,5.4,1.7c1.8,0.7,3.4,1.7,5.1,2.6c4.1,2.5,7,6,8,10.8
	c1,5,0.8,10-1,14.7c-1.3,3.5-2.6,7.1-4.9,10.2c-2.9,3.9-6.4,7.2-10.7,9.6c-5.2,2.9-10.8,5.1-16.4,7c-0.4,0.1-0.9,0.1-1.4,0.2
	c-0.5,0.1-1,0.3-1.5,0.5c0.3,0.4,0.5,0.8,0.9,1.1c0.9,0.6,1.9,1,2.8,1.6c1.5,1,1.5,4-0.1,5c-1.1,0.7-2.2,1.3-3.3,1.9
	c-0.9,0.4-1.2-0.2-1.5-0.9c-0.4-0.9-0.7-1.7-1.1-2.6C252.1,69.5,252,69.5,251.9,69.5z M251.1,62.7c0.8-0.2,1.5-0.5,2.1-0.5
	c3.3,0.1,6.2-1.1,9.1-2.3c3.3-1.3,6.5-2.8,9.7-4.4c4.3-2.2,7.9-5.3,10.7-9.2c3-4.1,4.9-8.8,6.2-13.8c0.7-2.8,0.8-5.7,0.4-8.5
	c-0.3-2-0.6-4-1.4-5.9c-1.3-3.1-3.8-5.3-6.7-7.1c-4.9-2.9-10.1-4.8-15.9-4.3c-1.9,0.2-3.7,0.5-5.6,0.8c-1.6,0.3-3.2,0.7-4.7,1.1
	c-2.8,0.8-5.4,2.2-7.5,4.1c-2.7,2.6-5.3,5.4-7.7,8.4c-1.5,1.9-2.7,4.1-2.9,6.7c-0.1,1.3,0,2.6-0.3,3.8c-0.9,3.7,0,7.2,0.8,10.8
	c0.9,4.1,2.4,7.9,5.3,11.1c1.6,1.8,3.1,3.8,5,5.3C249.3,59.9,250.2,61.1,251.1,62.7z M254.4,71.8c1-0.5,2-0.8,2.7-1.3
	c1.6-1.2,1.3-3-0.4-3.9c-0.8-0.4-1.5-1-2.3-1.5c-0.3-0.2-0.6-0.3-0.9-0.5c-0.1,0.1-0.2,0.2-0.3,0.4c1,1.1,2,2.1,3,3.2
	c-1.5,1.2-2-1-3.5-1.4C253.3,68.6,253.8,70,254.4,71.8z" />
        <path fill="transparent" d="M376.1,70.6c-0.1,0.5-0.2,1-0.3,1.4c-0.9,3.6-1.8,7.3-2.8,10.9c-0.9,3.5-2.6,6.6-4.6,9.5c-2.6,3.7-5.4,7.2-7.8,11
	c-1.8,2.8-2.9,6-4.5,9c-1.7,3.1-2.6,6.7-5.5,9.1c-0.1,0.1-0.2,0.1-0.4,0.1c0.5-0.7,1.1-1.4,1.5-2.2c1.8-3.8,3.6-7.7,5.4-11.5
	c1.8-3.5,3.7-6.9,6.2-10.1c3.8-4.8,7.5-9.7,8.9-15.8c0.8-3.3,1.6-6.7,2.4-10c0.2-0.7,0.4-1.5,0.7-2.2c0.3-0.8,0-1.3-0.6-1.9
	c-0.6-0.5-0.9-0.1-1.5,0.1c-1.4,0.5-4.4,0.7-5.5-1c2,0.4,3.9,0.9,5.9-0.2c-0.4-0.4-0.6-0.7-1-1c-3.3-2.3-5.6-5.4-7.3-9
	c-1.5-3-2.9-6-3.4-9.4c-0.5-3-0.9-6.1-1-9.1c-0.1-3.8,1.1-7.5,3-10.8c2-3.3,4-6.6,6.3-9.7c1.5-2.1,3.9-3.3,6.2-4.5
	c2.9-1.5,5.9-2.6,9.1-3c2.4-0.4,4.9-0.5,7.4-0.2c3.7,0.5,7.3,1.6,10.4,3.6c2.6,1.6,5.2,3.4,7.5,5.4c1.3,1.2,2.2,3,2.5,4.7
	c0.9,3.9,1.5,7.9,2,11.9c0.4,3-0.6,5.8-1.8,8.6c-1.3,2.8-2.7,5.5-4.8,7.8c-1.9,2.2-4,4.1-6.5,5.6c-2,1.1-3.8,2.6-5.8,3.5
	c-3.3,1.5-6.6,2.9-10,4.1c-1.8,0.7-3.8,1-5.7,1.5c-0.2,0.1-0.4,0.3-0.8,0.6c1,0.3,1.9,0.5,2.7,0.8c0.9,0.4,1.9,0.8,2.8,1.3
	c0.9,0.5,0.8,1.4,0.1,2c-0.8,0.7-1.8,1.2-2.8,1.8c-0.4,0.2-0.9,0.4-1.2,0.7c-1.4,0.9-2.9,0.5-3.7-0.9c-0.4-0.8-0.9-1.5-1.3-2.3
	C376.3,70.6,376.2,70.6,376.1,70.6z M375,66.3c1.4-1.4,2.9-1.7,4.6-0.6c0.3,0.2,0.9,0.2,1.3,0.1c2.1-0.5,4.3-0.9,6.3-1.7
	c5.5-2.1,10.9-4.3,15.6-8c1.7-1.3,3.3-2.8,4.7-4.4c1.7-2,3.2-4.1,4.3-6.6c1.2-2.8,2.7-5.7,2.4-8.8c-0.3-4-1.1-7.9-1.8-11.9
	c-0.5-3.1-2.7-5.3-5.1-7c-2.4-1.7-5.1-3-7.6-4.4c-3-1.7-6.4-2-9.6-2c-4.2,0-8.3,0.8-12.1,2.8c-3.2,1.7-6.4,3.4-8.3,6.6
	c-1.8,3-3.7,6.1-5.3,9.2c-1.6,3.2-2.4,6.6-2.2,10.2c0.2,3.5,0.5,7,1.8,10.2c1.3,3.2,2.7,6.4,4.3,9.4C369.7,62.4,372.4,64.3,375,66.3
	z M379.7,73.5c1.4-0.6,2.4-1,3.5-1.5c0.4-0.2,0.7-0.5,1.1-0.8c0.5-0.5,0.6-1.1-0.1-1.3c-1.7-0.6-3.4-1.1-5.2-1.7
	c0,0.1-0.1,0.1-0.1,0.2c0.8,0.9,1.6,1.8,2.6,2.8c-2.3,0.1-2.8-2.3-4.4-2.7c-0.1,0.1-0.2,0.2-0.3,0.3
	C377.7,70.3,378.7,71.9,379.7,73.5z M378.4,66.9c-0.1-0.1-0.3-0.8-0.6-0.7c-0.6,0-1.2,0.3-1.7,0.6c-0.1,0.1-0.1,0.4-0.2,0.7
	c0.3,0,0.5,0.2,0.7,0.1C377.2,67.4,377.7,67.2,378.4,66.9z" />
        <path fill="transparent" d="M403.4,104.6c-2-0.4-3.6,1.5-5.5,1.9c1.5-2,3.8-2.6,5.8-2.9c-1.6-3.6-3.5-7-4.7-10.7c-1.3-4-1.9-8.2-1.7-12.4
	c0.2-5.5,1.1-10.9,3.6-15.8c1.8-3.6,4.1-6.9,7.5-9.2c3.9-2.6,7.9-5,12.5-6.4c1.1-0.3,2.3-0.4,3.5-0.7c0.9-0.2,1.7-0.5,2.6-0.5
	c1.9,0,3.8,0.1,5.7,0.4c2.2,0.4,4.5,1,6.6,1.8c4.2,1.5,7.8,3.7,10,7.8c0.9,1.6,1.9,3.1,2.6,4.7c0.9,2.1,0.9,4.5,0.5,6.7
	c-0.3,2-0.5,4-1.1,5.8c-1.1,2.8-2.5,5.5-3.8,8.2c-0.9,1.9-2.3,3.6-4,5c-2.9,2.4-5.8,4.8-8.6,7.2c-2.1,1.8-4.7,2.6-7.2,3.7
	c-4.4,1.9-8.8,3.6-13.3,5.4c-0.2,0.1-0.4,0.2-0.7,0.3c1,0.9,1.9,1.8,2.9,2.7c1.4,1.4,1.4,3.2-0.3,4.2c-1.6,0.9-3.3,1.6-5,2.2
	c-1.1,0.4-1.9-0.1-2.2-1.2c-0.3-1.3-0.6-2.6-1-3.8c-0.1-0.5-0.5-1.2-0.8-1.2c-0.7,0-1.6-0.2-1.8,0.9c-0.4,1.8-1.1,3.4-2.3,4.8
	c-1,1.1-2,2.1-3.2,3c-1.9,1.3-4,2.3-5.8,3.7c-1.2,0.9-2.2,2.1-3,3.3c-1,1.5-1.8,3.1-2.6,4.7c-0.4,0.8-0.4,1.8-0.6,2.6
	c-0.1,0.3-0.3,0.6-0.4,0.9c-0.2-0.2-0.5-0.4-0.6-0.7c-0.5-1.5,0.3-2.7,0.9-3.8c1-1.8,2.1-3.5,3.2-5.3c1.3-2.1,3.6-3.1,5.6-4.3
	c3.5-2.1,6.6-4.6,7.6-8.9c0.1-0.5-0.1-1.2-0.3-1.8C403.8,106.2,403.6,105.5,403.4,104.6z M451.3,65.8c-0.3-1-0.7-2.9-1.5-4.6
	c-0.7-1.5-1.8-2.8-2.8-4.2c-0.6-0.8-1.2-1.8-2-2.3c-1.7-1.1-3.5-2.1-5.4-3c-5.5-2.5-11.2-3-17.1-1.8c-2.1,0.4-4,1.4-6,2.1
	c-3.4,1.3-6.4,3.3-9.1,5.6c-2.8,2.4-4.7,5.6-6.2,9.1c-1.8,4.4-2.5,9-2.7,13.6c-0.2,4.7,0.4,9.3,2.3,13.7c1.1,2.7,2.4,5.3,3.9,7.8
	c0.7,1.2,1.7,2.5,3.3,2.4c2-0.1,4-0.4,5.9-0.9c3-0.9,6-2.1,9-3.3c3.5-1.5,7.1-3,10.4-4.9c2.6-1.6,4.8-3.9,7.3-5.8
	c3.6-2.7,6-6.4,8-10.4C450.5,75.3,451.1,71.1,451.3,65.8z M408.9,108.2c0.4,1.5,0.8,3,1.3,4.4c0.1,0.2,0.6,0.6,0.7,0.5
	c1.7-0.6,3.4-1.2,4.9-2c1-0.5,0.9-1.5,0.1-2.4c-0.5-0.5-1-1.1-1.6-1.6c-1.3-1.1-3.2-2.2-5-1.2c0.4,2.1,2.7,2.3,3.8,3.7
	C412.2,110.3,411.9,110.2,408.9,108.2z M407.2,106.7c-0.6-0.5-1.1-0.9-1.6-1.3c-0.2-0.1-0.5-0.2-0.7-0.2c-0.1,0.2-0.2,0.5-0.2,0.7
	C405.1,106.9,405.9,107.2,407.2,106.7z" />
        <path fill="transparent" d="M96,111.3c0.3-2.5,1.5-4.6,2.8-6.6c1.5-2.2,3.3-4.3,4.9-6.4c2.6-3.4,5-6.9,6.4-11c0.7-1.8,1.2-3.7,1.8-5.6
	c0.9-3.3,0.5-6.6,0-9.8c-0.6-3.4-0.6-6.9-0.2-10.4c0-0.2,0.1-0.4,0-0.6c0-0.1-0.1-0.2-0.2-0.5c-0.5,0.4-0.9,0.8-1.3,1.1
	c-0.2,0.2-0.6,0.1-0.8,0.2c0-0.3,0-0.6,0.1-0.9c0.3-0.5,0.7-0.9,1.2-1.5c-1.6-1.6-3.3-3-4.8-4.6c-4.4-4.8-7.4-10.3-7.9-17
	c-0.1-1.7-0.4-3.4-0.3-5c0.3-4.3,1.7-8.3,4.1-11.9c2.6-3.9,5.3-7.6,9.5-9.9c4.7-2.7,9.8-4.3,15.3-4.8c4.4-0.4,8.7,0.3,12.9,2
	c3.5,1.4,6.5,3.3,8.4,6.5c1.2,1.9,2,4.1,2.6,6.3c1,3.7,1,7.5-0.2,11.2c-0.7,2.3-1.8,4.4-2.8,6.6c-0.4,0.8-0.9,1.5-1.3,2.3
	c-2,4-5.4,6.7-9,9.1c-2.8,1.9-5.7,3.6-8.7,5.2c-2.2,1.1-4.5,2-6.8,3c-1.5,0.6-3.1,1.2-4.5,1.7c0.8,0.6,1.7,1.2,2.5,2.1
	c0.8,1,0.5,2-0.6,2.7c-1.1,0.7-2.3,1.4-3.4,2.1c-0.7,0.5-1.4,0.3-1.6-0.4c-0.4-1-0.6-2.1-1.1-3.2c-0.1,0.8-0.2,1.5-0.2,2.3
	c0.2,2.4,0.4,4.8,0.7,7.1c0.8,4.7,0.1,9.1-1.5,13.6c-1.4,3.9-3.6,7.2-5.7,10.6c-1.2,2-3.1,3.7-4.6,5.6c-1.9,2.4-3.2,5.1-4.2,8
	c-0.2,0.5-0.5,0.9-0.8,1.4C96.3,111.5,96.1,111.4,96,111.3z M149.6,26.6c0-0.4,0-0.7,0-1.1c-0.1-4.9-1.3-9.5-5-12.9
	c-3.9-3.6-8.8-5.1-14-5.5c-4.7-0.4-9.3,0.4-13.6,2.3c-2.7,1.2-5.5,2.5-7.8,4.3c-3.3,2.5-5.6,5.9-7.6,9.6c-1.7,3-2.5,6.3-2.7,9.7
	c-0.2,3.5,0.4,6.9,1.4,10.3c0.8,2.7,2.1,5.2,3.9,7.5c1.8,2.3,3.7,4.5,5.7,6.6c0.6,0.7,1.6,1.5,2.4,1.6c3.9,0.2,7.6-0.7,11.1-2.4
	c3.1-1.5,6.2-3.1,9.2-4.8c5.5-3.1,10.3-7,13.2-12.7C147.7,35.1,149.8,31.2,149.6,26.6z M114.8,65.7c1.2-0.7,2.2-1.3,3.2-1.9
	c1-0.6,1.1-1.2,0.1-1.9c-0.6-0.5-1.3-0.9-2-1.2c-0.3-0.2-0.7-0.1-1.1-0.2c0.1,0.4,0,0.9,0.2,1.1c0.4,0.5,0.9,0.9,1.4,1.3
	c0,0.1-0.1,0.2-0.1,0.2c-0.7-0.3-1.4-0.6-2.3-0.9C114.3,63.4,114.5,64.5,114.8,65.7z" />
        <path fill="transparent" d="M195.5,104.5c0.9-0.4,1.7-0.9,2.6-1.3c0.7-0.3,1.5-0.4,2.3-0.7c1-0.3,1.1-0.7,0.4-1.4c-4-4-6-8.9-6.9-14.5
	c-0.6-3.8-0.5-7.5,0.1-11.3c0.2-1.7,0.2-3.4,0.7-4.9c1-3,2.1-6,4.1-8.5c2.7-3.3,5.9-6.2,9.4-8.5c1.5-1,3.2-1.6,5-2.2
	c1.9-0.6,3.9-1,5.9-1.4c0.3-0.1,0.6-0.1,0.9-0.1c4.3,0.1,8.7,0.2,13,1c2.6,0.5,4.5,2,6.3,3.9c2.2,2.4,3.7,5.2,5.3,7.9
	c1.5,2.6,1.8,5.3,1.8,8.1c0,4.5-1.7,8.4-4.2,12.1c-3.8,5.6-8.9,9.7-14.6,13c-3.3,1.9-6.7,3.7-10.5,4.8c-2,0.6-3.8,1.3-5.9,1.3
	c-0.5,0-1.1,0.3-1.7,0.7c0.4,0.2,0.8,0.5,1.3,0.6c1.7,0.5,2.8,1.7,3.6,3.2c0.2,0.4,0.2,1.4-0.1,1.6c-1.9,1.5-3.9,3-5.9,4.4
	c-0.1,0-0.7-0.5-0.8-0.8c-1-2.6-1.8-5.2-2.8-7.8c-0.2,0-0.3,0-0.5,0.1c-0.1,0.4-0.2,0.9-0.3,1.3c-0.4,1.1-0.7,2.3-1.4,3.2
	c-2.4,3-5.4,5.2-8.3,7.7c-2.7,2.4-5.2,5.1-7,8.2c-2,3.4-3.8,6.9-5.7,10.4c-0.3,0.6-0.5,1.2-0.8,1.7c1.6-5,3.5-9.9,6.7-14.2
	c2.7-3.7,6.1-6.8,9.6-9.8c2-1.7,3.7-3.7,5.3-5.7c0.6-0.7,0.6-1.9,0.7-3c0-0.1-0.4-0.5-0.6-0.5c-0.8,0-1.7,0-2.5,0.3
	c-1.2,0.4-2.4,0.9-3.6,1.3c-0.2,0.1-0.4,0-0.6,0C195.6,104.7,195.6,104.6,195.5,104.5z M224.3,50.8c-5.4,0.1-9.8,0.5-13.9,2.8
	c-4.2,2.3-7.7,5.5-10.6,9.1c-1.7,2.2-2.7,4.9-3.7,7.6c-1.3,3.6-1.2,7.5-1.2,11.2c0,6.6,1.7,12.7,6,17.9c0.8,1,1.6,1.1,2.7,0.7
	c1.4-0.6,2.3-0.4,3.1,0.8c0.5,0.7,0.9,0.8,1.6,0.6c0.9-0.2,1.9-0.4,2.8-0.6c1.7-0.4,3.4-0.5,5-1.1c8.3-2.9,16-7,22.1-13.6
	c4-4.4,7.2-9.3,7.1-15.6c0-2.2,0-4.4-1-6.5c-0.8-1.5-1.6-3.1-2.5-4.5c-1.3-1.8-2.7-3.6-4.2-5.2c-1.3-1.5-3-2.6-5-2.8
	C229.4,51.3,226.4,51,224.3,50.8z M210.8,106.4c-0.1,0.2-0.1,0.3-0.2,0.5c-1.2-0.4-2.4-0.8-3.6-1.2c0.5,1.6,1.1,3.3,1.7,5.1
	c1.4-0.8,2.6-1.4,3.8-2.2c1.5-1,1.6-2.5,0.2-3.5c-1.2-0.8-2.7-1.4-4.1-2c-0.2-0.1-0.7,0.1-0.8,0.3c-0.1,0.3-0.1,0.8,0.1,1.1
	c0.2,0.3,0.7,0.5,1,0.8C209.5,105.7,210.1,106,210.8,106.4z M202.6,102.2c1,0.2,1.8,0.4,2.6,0.5c0.3,0,0.7-0.3,0.8-0.5
	c0.1-0.2-0.2-0.7-0.4-0.9C204.7,100.6,203.8,100.9,202.6,102.2z" />
        <path fill="transparent" d="M33.8,70.3c2.6-0.1,4.4,1.4,6,3.1c1.4,1.5,1.3,2.8-0.5,3.7c-1.4,0.7-3,1-4.5,1.4c-0.3,0.1-1-0.3-1.1-0.6
	c-0.6-1.2-1.1-2.5-1.6-3.7c-0.3-0.7-0.4-1.5-1.6-1.5c-0.1,0.3-0.2,0.7-0.2,1.1c-0.2,2.7-1.3,5-2.9,7.2c-2.6,3.5-5.3,7-7.8,10.6
	c-1.6,2.4-2.5,5.1-2.8,7.9c-0.6,4.6-1.1,9.2-1.7,13.9c0,0.4-0.1,0.7-0.2,1.1c-0.1,0-0.3,0-0.4,0c-0.1-0.5-0.3-1-0.3-1.5
	c0-3.6,0.7-7.2,1.1-10.8c0.4-3.9,1.7-7.5,3.4-10.9c1.5-3.1,3.5-5.8,5.9-8.4c2.4-2.7,4.4-5.7,4.6-9.5c0.1-1.7-0.6-2.4-2.2-1.8
	c-1.2,0.4-2.3,1.2-3.5,1.7c-0.3,0.1-0.6,0.1-0.9,0.1c0.1-0.3,0-0.8,0.2-0.9c1.1-0.7,2.2-1.3,3.3-1.8c1-0.5,2-0.8,3.2-1.3
	c-0.5-0.7-0.9-1.5-1.4-2.2c-1.7-2.3-3.5-4.6-5.2-7c-2.7-3.9-5.2-8-6.1-12.8c-0.3-1.8-0.8-3.5-0.9-5.3c-0.1-1.3,0.1-2.7,0.2-4
	c0-0.4,0.2-0.8,0.2-1.2c0.4-6.3,2.5-11.9,7.2-16.1c2.9-2.6,6-5.4,10-6.3c2.8-0.7,5.7-1.2,8.6-1.5c4.6-0.5,9.1,0.3,13.3,2.1
	c4,1.6,7.4,4.2,9.9,7.9c3.7,5.3,5.1,11.2,4.4,17.6c-0.3,2.9-1.3,5.8-3.2,8.1c-1.9,2.4-3.6,4.9-5.7,7c-1.7,1.7-3.7,3.2-5.7,4.4
	c-3,1.9-6.2,3.6-9.4,5.2c-2.8,1.3-5.9,2.2-8.8,3.4c-1,0.4-1.9,0.9-2.9,1.3C33.8,70.1,33.8,70.2,33.8,70.3z M68.7,37.3
	c0-4.9-1.4-9.4-4.1-13.5c-2.4-3.6-5.8-6-9.7-7.6c-2.5-1-5.4-1.5-8.1-2c-4.7-0.9-9.3,0.2-13.8,1.6c-2.1,0.6-4,2.1-5.9,3.3
	c-4.3,2.9-7.5,6.6-8.8,11.8c-1.1,4.6-2,9-1.2,13.9c1,6.1,3.9,11.2,7.4,16c1.9,2.5,3.7,5.1,5.5,7.7c0.2,0.3,0.5,0.5,0.9,0.6
	c1.4,0.6,2.6,0,3.9-0.5c2.9-1.3,5.7-2.7,8.7-3.6c5.1-1.6,9.4-4.7,13.7-7.7c3.2-2.2,5.5-5.3,7.8-8.5C67.7,45.2,68.7,41.5,68.7,37.3z
	 M37,74.6c-0.1,0.1-0.2,0.3-0.2,0.4c-1.2-0.5-2.4-1-3.5-1.4c-0.1,0.1-0.2,0.2-0.2,0.3c0.4,1,0.8,2,1.3,2.9c0.2,0.3,0.8,0.6,1.2,0.6
	c1-0.2,2.1-0.6,3.1-1c1.1-0.4,1.4-1.1,0.6-2c-0.9-0.9-1.9-1.6-3-2.3c-0.9-0.6-1.8-1.1-3.1-0.3C34.5,72.7,35.8,73.6,37,74.6z
	 M31.3,72.1c0.5-0.5,0.8-0.9,1.1-1.2c-0.5-0.4-0.8-1-1.4-1.2c-0.3-0.1-0.8,0.5-1.2,0.8C30.3,71,30.7,71.5,31.3,72.1z" />
        <path fill="transparent" d="M62.7,98.6c-0.3-0.4-0.6-0.8-0.9-1.1c-2.6-2.5-5-5.2-6.1-8.7c-1.1-3.6-2.1-7.3-1.9-11.2c0.3-5.2,1.1-10.3,4.2-14.7
	c1.2-1.6,2.1-3.4,3.2-5.1c1.6-2.6,4.2-4.1,6.7-5.7c1.7-1.2,3.7-2,5.6-2.7c3-1,6.1-1.3,9.2-0.9c1.5,0.2,3,0.1,4.5,0.1
	c0.7,0,1.4,0.1,2.1,0.2c1.8,0.4,3.7,0.7,5.5,1.3c3.6,1.1,6.1,3.6,8.3,6.6c1.6,2.2,2.9,4.4,3.7,6.9c1.4,4.6,1.4,9.1,0.1,13.9
	c-1.5,5.3-4.7,9.4-8.4,13.3c-1.3,1.4-2.5,2.8-4,3.9c-2.5,1.8-5.3,3.2-7.9,4.9c-2.7,1.8-5.6,2.7-8.7,3.1c-2.1,0.3-4.3,0.4-6.5,0.3
	c-0.9,0-1.7-0.6-2.6-0.8c-0.4-0.1-0.8,0.1-1.2,0.3c1.5,1.5,3.3,2.7,2.1,5.2c-0.2-0.6-0.3-1.4-0.7-1.9c-0.7-0.8-1.6-1.5-2.4-2.2
	c-0.1-0.1-0.8,0.1-0.9,0.4c-0.5,0.8-1,1.7-1.4,2.6c-1.3,3.4-2.6,6.8-3.9,10.3c-0.9,2.5-1.7,5.1-2.7,7.6c-0.2,0.6-0.3,1.8-1.6,1.5
	c2.8-7.7,5.6-15.3,8.4-23.1c-0.2,0-0.5,0-0.8,0.1c-1.5,0.9-3,1.8-4.6,2.7c-0.7,0.4-1.6-0.2-1.9-1.2c-0.2-0.7-0.5-0.9-1.2-0.8
	c-0.9,0.1-1.2-0.3-1.1-1.2c0.1-1.2,0.2-2.4,0.1-3.6c-0.1-0.9,0.3-1.2,1.1-1.1c1.7,0.3,3.4,0.7,5.2,1c0.4,0.1,0.8,0.1,1.2,0.1
	C62.6,98.9,62.7,98.8,62.7,98.6z M106.7,70.6c0.1-2.8-0.3-6-1.9-8.8c-3-5.4-6.2-10.4-13.3-11.2c-2.3-0.2-4.6-0.9-7-1.1
	c-3.3-0.3-6.7-0.4-9.8,0.5c-4.2,1.3-7.9,3.7-11.1,6.7c-2.8,2.7-4.5,6.1-6.3,9.5c-0.4,0.7-0.7,1.4-0.9,2.2c-0.4,1.6-0.7,3.2-1,4.8
	c-1,5.6-0.3,10.9,1.8,16.1c1,2.4,2.5,4.2,4.2,6.1c1.6,1.8,3,3.6,5.5,4.6c2.5,1,4.8,1.9,7.5,1.7c3.4-0.2,6.7-0.6,9.8-2.1
	c5-2.5,9.9-5.3,13.6-9.6c1.8-2.1,3.7-4,5.1-6.3C105.3,79.9,106.8,75.7,106.7,70.6z M59.4,100.4c1.1,0,1.8,0,2.5,0
	c0.2,0,0.5-0.3,0.6-0.5c0.1-0.1-0.2-0.5-0.3-0.5c-1.7-0.3-3.3-0.6-5-0.8c-0.3,0-0.8,0.5-0.9,0.8c-0.1,1,0,2.1,0,3.4
	c1.9-0.4,3.5-0.8,5.2-1.2c0-0.2,0-0.3,0-0.5C60.8,100.9,60.2,100.7,59.4,100.4z M58.8,104.7c1.6-0.8,3.1-1.5,4.5-2.3
	c-0.1-0.2-0.2-0.4-0.2-0.5c-1.5,0.5-3.1,1-4.7,1.6C58.5,103.9,58.6,104.2,58.8,104.7z M65.8,102.9c0.5-0.7,0.8-1,1-1.4
	c-0.6-0.4-1.3-0.9-2-1.2c-0.1-0.1-0.6,0.5-0.9,0.8C64.6,101.6,65.1,102.2,65.8,102.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M316.6,89.9c0.1-6.7,0.7-11.9,3.5-16.7c1-1.6,2.2-3.1,3.5-4.5c1.6-1.7,3.4-3.1,5.1-4.6c2.4-2.1,5-3.5,8.3-3.8
	c1.5-0.1,3-0.9,4.4-0.8c2.9,0.1,5.9,0.5,8.7,1c1.8,0.3,3.5,1,5.2,1.6c0.5,0.2,0.9,0.7,1.4,1.1c2.7,2,4.6,4.6,6.4,7.4
	c1.7,2.6,2.6,5.6,2.9,8.7c0.7,6.3-1.9,11.4-5.8,16c-2.2,2.7-4.9,4.9-7.7,7c-0.6,0.4-1.3,0.5-1.9,0.9c-5.5,2.8-11.7,3.7-17.5,5.7
	c-1.6,0.6-3.3,1-4.9,1.4c-1.3,0.4-2.8-0.2-3.7-1.3c-2-2.8-4.4-5.4-5.3-8.9C318.1,96.2,317.2,92.4,316.6,89.9z M331.7,81
	c2.1-3,2.1-5.4,0.2-7.3c-2-1.9-6-2.1-8.2-0.3c-0.9,0.7-1.6,1.8-1.9,2.9c-0.6,1.8,0.2,3.4,1.5,4.8c-0.4,0.4-0.8,0.6-1,0.9
	c-2.2,2.5-1.6,6.2,1.3,7.8c2.6,1.5,5.4,1.4,8-0.2c1.6-1,2.3-2.5,2.4-4.3C333.9,83.5,333,82.1,331.7,81z M357.8,72.6
	c-0.1-0.1-0.2-0.1-0.3-0.1c-2.7-0.6-4.7,1-6.9,2.2c-0.1,0.1-0.2,0.3-0.2,0.5c0.3,1.1,0.5,2.1,0.8,3.2c0.9-0.4,1.6-0.8,2.5-1.3
	c0,4.6,0,9,0,13.5c1.4,0,2.6,0,4,0C357.8,84.5,357.8,78.6,357.8,72.6z M336.6,81.1c0,1.1,0,2.1,0,3.1c1.4,0,2.7,0,4.2,0
	c0,1.3,0,2.4,0,3.6c0,0.3,0.4,0.8,0.6,0.9c0.8,0.1,1.6,0,2.6,0c0-1.6,0-3,0-4.5c1.4,0,2.8,0,4.2,0c0-1.1,0-2,0-3.1
	c-1.4,0-2.8,0-4.2,0c0-1.6,0-3,0-4.4c-1.1,0-2.1,0-3.2,0c0,1.5,0,2.9,0,4.4C339.2,81.1,337.9,81.1,336.6,81.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M324.8,111.4c-2,0.4-4.1,0.8-6.5,1.2c0.1-1.4,0.2-2.6,0.4-4.3c2.3,0.9,4.2,1.7,6.2,2.5
	C324.9,111,324.8,111.2,324.8,111.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M325.2,112.4c-0.5,1.3-0.7,1.4-3.7,2c-0.4-0.8-0.4-1.3,0.6-1.5c0.9-0.2,1.8-0.4,2.7-0.6
	C324.9,112.2,325,112.3,325.2,112.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M326.3,111.7c1.2-0.2,2-0.3,2.9-0.4c0.1,0.1,0.1,0.3,0.2,0.4c-0.7,0.3-1.3,0.8-2.1,1
	C327.1,112.8,326.7,112.2,326.3,111.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M127.7,102.7c-1.2-1.6-2.5-3.2-3.5-4.9c-2.3-3.9-2.8-8.2-3.2-12.6c-0.3-3,0.4-5.8,0.8-8.6
	c0.2-1.4,0.6-2.8,0.9-4.2c0.9-3.5,2.8-6.4,4.8-9.3c2.9-4.4,7.1-7.4,11.8-9.4c4.4-1.9,9.1-3,14-2.2c1.9,0.3,3.8,0.7,5.7,1.3
	c2.1,0.7,3.7,2.2,5.2,3.8c1.2,1.3,2.3,2.5,3.4,3.8c1.4,1.7,2.5,3.5,2.8,5.7c0.1,0.8,0.4,1.5,0.7,2.3c1.5,4,1,7.9-0.6,11.7
	c-1.7,3.9-4.5,7.1-7.6,10c-3.9,3.6-8.4,6.1-13.1,8.3c-3,1.4-6.1,2.7-9.3,3.9c-3,1.1-6.1,1.1-9.2,1c-0.5,0-1-0.4-1.4-0.5
	C129.1,102.6,128.4,102.7,127.7,102.7L127.7,102.7z M165.9,64.3c-1,0-1.8,0.1-2.7,0c-0.9-0.1-1.4,0.2-2,1c-2,3-4,6-6.2,8.8
	c-0.9,1.2-0.9,2.5-0.7,3.9c2.5,0,4.9,0,7.5,0c0,1.5,0,3,0,4.4c1.4,0,2.7,0,4.1,0c0-1.5,0-2.9,0-4.3c0.8,0,1.5-0.1,2.1-0.1
	c0-1.2,0-2.3,0-3.5c-0.7,0-1.4,0-2.1,0C165.9,71,165.9,67.8,165.9,64.3z M125.3,81.2c2.4,1.5,4.8,1.9,7.5,1.2c2.8-0.7,4.7-3.2,4.8-6
	c0.1-2.8-1.5-4.9-4.2-5.8c-0.9-0.3-1.8-0.3-2.8-0.5c0.1-0.6,0.2-1.3,0.3-2.1c2.1,0,4.2,0,6.3,0c0-1.3,0-2.5,0-3.7
	c-3.2,0-6.4,0-9.6,0c-0.4,3.3-0.9,6.5-1.3,9.8c0.6-0.1,1-0.2,1.4-0.1c1.3,0,2.6,0,3.9,0.2c1.2,0.2,1.7,1.1,1.7,2.3
	c0,1.1-0.5,2-1.6,2.3c-0.9,0.2-1.9,0.2-2.8,0.1c-0.9-0.2-1.7-0.6-2.6-1C126,79,125.7,80,125.3,81.2z M152.5,76c0-1.1,0-2.1,0-3.1
	c-1.4,0-2.8,0-4.3,0c0-1.5,0-3,0-4.4c-0.8,0-1.4,0-2.1,0c-0.8-0.1-1,0.3-1,1c0.1,1.1,0,2.2,0,3.5c-1.5,0-2.8,0-4.2,0
	c0,1.1,0,2,0,3.1c1.4,0,2.8,0,4.2,0c0,0.9,0,1.6,0,2.4c0,2.3-0.4,2,2.1,2.1c0.8,0,1.1-0.2,1-1c-0.1-1.1,0-2.2,0-3.4
	C149.7,76,151.1,76,152.5,76z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M136.1,107c0,0.1-0.1,0.3-0.1,0.3c-1.2,0.7-2.4,1.4-3.6,2.1c-0.1,0.1-0.6-0.3-0.7-0.6c-0.5-0.9-0.7-1.9-1.3-2.7
	c-0.6-1,0.1-1.4,0.7-1.6c1.4-0.6,4.3,0.8,4.9,2.2C136,106.8,136.1,106.9,136.1,107z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M127.7,102.7c0.5,0.1,1,0.1,1.5,0.3c0.3,0.1,0.7,0.4,0.8,0.7c0.1,0.2-0.3,0.7-0.6,0.8c-0.8,0.3-1.6,0.5-2.5,0.8
	c-0.1-0.1-0.2-0.2-0.3-0.3C127,104.2,127.4,103.4,127.7,102.7C127.7,102.7,127.7,102.7,127.7,102.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M255.5,91.6c-0.3-5.3,0.8-10.4,2.7-15.3c0.8-2.1,1.9-4.2,3-6.2c0.5-1,1.3-1.8,2-2.6c3.7-4.3,8.3-6.9,13.8-8
	c3.7-0.7,7.3-0.3,10.9,0.4c1.6,0.3,3.3,0.6,4.9,1.1c1.4,0.5,2.7,1.2,3.9,2c3.1,2.1,5.7,4.8,7.3,8.2c2,4.2,2,8.5,0.6,12.9
	c-2,6-5.6,10.8-10.4,14.7c-1.9,1.6-4.3,2.7-6.6,3.8c-3.8,2-7.8,3.7-12,4.8c-2.6,0.6-5.2,1.3-7.8,1.6c-1,0.1-2.2,0-3.3,0
	c-0.8,0-1.7-0.9-2.4-1.5c-4.7-4.1-6.7-9.3-6.7-15.4C255.5,91.9,255.5,91.7,255.5,91.6z M302,81.6c-0.4-1.9-0.6-3.6-1.1-5.2
	c-0.8-2.3-2.3-3.9-4.9-4.1c-2.9-0.2-4.9,1.1-6,3.6c-1.7,3.8-1.6,7.7,0,11.5c1,2.2,2.7,3.6,5.2,3.6c2.6,0,4.6-1.3,5.5-3.7
	C301.3,85.4,301.6,83.4,302,81.6z M261.2,72.5c0,1.3,0,2.4,0,3.7c2.4,0,4.7,0,7.3,0c-1.2,2.5-2.4,4.8-3.5,7.1
	c-1.1,2.3-2.3,4.7-3.5,7.2c1.6,0,3,0,4.4,0c0.2-0.4,0.5-0.9,0.7-1.3c2.1-4.3,4.2-8.6,6.1-13c0.5-1.1,0.6-2.4,0.9-3.7
	C269.2,72.5,265.2,72.5,261.2,72.5z M286.5,84.2c0-1.1,0-2,0-3.1c-1.4,0-2.7,0-4.3,0c0-1.6,0-3,0-4.4c-1.1,0-2.1,0-3.1,0
	c0,1.5,0,2.9,0,4.4c-1.5,0-2.8,0-4.2,0c0,1.1,0,2,0,3.1c1.4,0,2.8,0,4.3,0c0,1.6,0,2.9,0,4.3c1.1,0,2.1,0,3.2,0c0-1.5,0-2.9,0-4.3
	C283.7,84.2,285,84.2,286.5,84.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M265.5,114.2c1.4,0.4,2.3,1.9,4.1,1.4c-1.6-1.2-3-2.2-4.4-3.2c0-0.2,0.1-0.4,0.1-0.5c0.6,0,1.3-0.2,1.7,0
	c1.7,0.9,3.3,1.8,4.8,2.9c1.8,1.3,1.7,2.8-0.3,3.8c-1.3,0.7-2.8,1-4.3,1.4c-0.2,0.1-0.7-0.3-0.7-0.6
	C266.2,117.7,265.9,116,265.5,114.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M265.6,111c-0.9,0-1.9,0.2-2.8,0.1c-0.2,0-0.3-0.8-0.5-1.4c1.4,0.4,2.4,0.7,3.4,1
	C265.6,110.8,265.6,110.9,265.6,111z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M221.4,26.5c-0.3,4.3-1,8.4-3,12c-1.2,2.2-2.8,4.2-4.6,5.9c-2.4,2.3-5.1,4.4-7.8,6.3c-2.6,1.8-5.4,3.3-8.5,4.1
	c-2.8,0.7-5.6,1.4-8.4,2.2c-1.2,0.4-2.4,1.1-3.6,1.6c-0.4,0.1-0.9,0-1.3,0c-0.6-0.1-1.3-0.2-1.9-0.3c-0.4-0.1-0.8-0.1-1.1-0.3
	c-4.4-4.1-9.2-7.9-12.1-13.4c-2.2-4.1-3.4-8.5-3.1-13.1c0.2-3,1.4-6,2.2-8.9c0.5-1.7,1.1-3.4,1.9-4.9c0.8-1.5,1.7-3.1,3-4.2
	c2.1-1.9,4.4-3.7,6.8-5.1c1.8-1.1,4-1.8,6.1-2.2c3.8-0.7,7.7-1.4,11.6-1.4c4.3,0,8.6,0.6,12.7,2.5c4.1,1.9,6.4,5.2,8.3,9.1
	c1.4,2.8,1.9,5.8,2.6,8.8C221.4,25.6,221.4,26.2,221.4,26.5z M182.9,27.1c2.1-2.8,2.1-5.5,0-7.3c-2.2-2-6.3-1.9-8.5,0.1
	c-2.1,1.9-2.1,4.5,0,7.6c-0.3,0.2-0.6,0.4-0.8,0.6c-2.6,2.8-1.6,7,1.9,8.4c1.6,0.7,3.4,0.8,5.1,0.3c2.2-0.5,3.7-1.8,4.3-4
	c0.6-2.2-0.1-4-1.8-5.5C183,27.3,182.9,27.1,182.9,27.1z M208.6,33c-0.1-0.1-0.1-0.2-0.2-0.4c0.4-0.3,0.8-0.6,1.1-0.9
	c1.2-1.7,2.7-3.3,3.6-5.2c1.5-3.3,0-6.8-3.1-7.8c-2.8-0.9-5.3-0.3-7.5,1.5c-0.2,0.2-0.5,0.6-0.4,0.9c0.4,1,0.9,1.9,1.3,2.8
	c1-0.6,1.9-1.2,2.8-1.6c0.9-0.4,1.9-0.4,2.7,0.4c0.8,0.7,0.8,1.8,0.4,2.7c-0.5,1-1,2-1.7,2.8c-1.5,1.6-3,3.3-4.6,4.7
	c-1.4,1.1-1.3,2.4-1.1,3.9c4.1,0,8.1,0,12.1,0c0-1.3,0-2.4,0-3.7C212.1,33,210.4,33,208.6,33z M191.8,27.2c-1.3,0-2.4,0-3.5,0
	c-0.3,0-0.7,0.4-0.8,0.6c-0.1,0.8,0,1.6,0,2.5c1.5,0,2.8,0,4.2,0c0,1.5,0,3,0,4.4c1.1,0,2.1,0,3.3,0c0-1.5,0-2.9,0-4.5
	c1.4,0,2.7,0,4.2,0c0-0.7-0.1-1.2,0-1.8c0.1-1-0.2-1.4-1.3-1.3c-1,0.1-2,0-3,0c0-1.6,0-2.9,0-4.3c-1.1,0-2.1,0-3.2,0
	C191.8,24.3,191.8,25.7,191.8,27.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M185.4,63c0.7,0.3,1.3,0.7,2,1c0.8,0.4,1.6,0.8,2.5,1.1c0.4,0.1,0.9-0.1,1.4-0.2c0-0.1,0-0.2,0-0.4
	c-0.3-0.1-0.6-0.3-0.9-0.4c-1.2-0.6-2.5-1.2-3.6-2c-0.3-0.2-0.3-0.7-0.5-1c0.4-0.1,0.8-0.5,1.2-0.4c2.1,0.4,3.7,1.4,5.1,3
	c0.2,0.2,0.2,0.8,0,1c-1,1.5-2.1,2.9-3.9,3.6c-0.3,0.1-1,0.1-1.1-0.1c-1.3-1.4-2-3.1-2.5-4.8C185.1,63.3,185.2,63.1,185.4,63z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }}
              d="M183.1,59.1c0.8,0.3,1.9,0.3,1.8,1.6c0,0.2-0.9,0.6-1.3,0.6C182.7,61.1,182.6,60.2,183.1,59.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M352.6,31.7c0,3.9-0.8,7.6-2.6,11c-1.3,2.3-3.1,4.4-4.6,6.6c-1.4,2-3.4,3.2-5.3,4.4c-2,1.2-4.1,2.3-6.2,3.2
	c-1.1,0.5-2.3,0.6-3.4,1c-3.2,1-6.3,2.1-9.5,3.2c-1.6,0.5-3.1,1.2-4.7,1.6c-1,0.3-2.1,0.4-3.1,0.5c-1.3,0.1-2-0.9-3-1.6
	c-3.8-2.8-6.3-6.7-8.7-10.6c-2.6-4.3-3.2-8.8-2.8-13.6c0.1-1.4,0.1-2.8,0.1-4.2c-0.1-3.8,1.3-7.2,3.4-10.3c1.8-2.6,3.8-5,5.9-7.3
	c1.9-2,4.4-3.2,6.9-4.2c5.4-2.3,11-3.4,16.8-2.9c4.1,0.4,7.8,2,11.1,4.4c1.2,0.9,2.4,1.6,3.7,2.5c2.2,1.5,3.5,3.5,4.3,6
	C352,24.7,352.8,28.1,352.6,31.7z M332.9,42c2.3,1.8,6.3,2.3,8.9,1c2.5-1.1,3.8-3.6,3.2-6.2c-0.3-1.2-1.2-2.3-1.9-3.6
	c0.1-0.1,0.3-0.4,0.5-0.7c1.7-2.3,0.9-5.6-1.6-6.9c-2.2-1.2-6.2-0.8-8.1,0.8c-0.2,0.2-0.4,0.6-0.3,0.9c0.3,1,0.7,1.9,1.1,2.9
	c0.5-0.3,0.8-0.6,1.2-0.8c0.9-0.3,1.8-0.6,2.7-0.8c0.7-0.1,1.4,0.2,1.6,1.1c0.1,0.9-0.3,1.5-1.1,1.7c-0.7,0.2-1.5,0.3-2.2,0.4
	c-0.5,0.1-0.9,0.1-1.4,0.1c0,1.2,0,2.3,0,3.4c1,0.1,1.9,0.1,2.8,0.2c1.7,0.2,2.6,1.1,2.5,2.3c-0.1,1.3-1.2,2.1-2.9,2
	c-0.3,0-0.6,0-0.9-0.1c-1-0.4-2-0.8-3-1.2C333.7,39.8,333.3,41,332.9,42z M306.2,35.6c0.8,0,1.5,0,2.2,0c2.2,0.1,3.3,1.1,3.1,2.6
	c-0.2,1.4-1.8,2.2-3.8,1.7c-1-0.3-2-0.8-3.1-1.2c-0.3,0.8-0.5,1.6-0.9,2.4c-0.4,0.8-0.1,1.2,0.6,1.5c2.8,1.3,5.6,1.6,8.4,0.2
	c2.2-1.1,3.5-3.6,2.8-6c-0.3-1.2-1.2-2.3-1.8-3.5c0.1-0.1,0.4-0.5,0.6-0.8c1.4-2.2,0.8-5.1-1.3-6.5c-2.4-1.6-6.9-1-8.9,1.1
	c0.4,1.1,0.8,2.1,1.3,3.3c0.5-0.3,0.8-0.6,1.2-0.8c1-0.3,2-0.7,3.1-0.7c0.4,0,1.1,0.7,1.2,1.1c0.1,0.4-0.3,1.2-0.7,1.4
	c-0.7,0.4-1.5,0.5-2.3,0.6c-0.5,0.1-1.1,0-1.7,0C306.2,33.3,306.2,34.4,306.2,35.6z M319.1,33.9c0,1.1,0,2,0,3.1c1.4,0,2.8,0,4.2,0
	c0,1.5,0,3,0,4.4c1.1,0,2.1,0,3.2,0c0-1.5,0-2.9,0-4.5c1.5,0,2.8,0,4.2,0c0-1.1,0-2,0-3.1c-1.4,0-2.8,0-4.2,0c0-1.5,0-2.9,0-4.3
	c-1.1,0-2.1,0-3.2,0c0,1.5,0,2.8,0,4.3C321.8,33.9,320.5,33.9,319.1,33.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M310.6,64.8c-1,0.4-1.8,0.7-2.7,1c1.3,1.1,2.1-0.1,3.1-0.2c0.2,0.3,0.5,0.6,1,1.2c-1.5,0.9-2.8,1.7-4.6,2.7
	c-0.3-1.7-0.7-2.9-0.8-4.1c0-1.3,0.6-1.5,1.8-1.1C309.1,64.5,309.7,64.6,310.6,64.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M312.5,64.3c1-0.1,1.8-0.1,2.9-0.1C314.3,65.5,313.9,65.5,312.5,64.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1}
              fill={this.state.color17 ? this.state.color17 : 'transparent'} onClick={() => {
          this.setState({ color17: color, opacity17: opacity });
        }} d="M251.1,62.7c-0.9-1.6-1.9-2.7-3.3-3.8c-1.9-1.4-3.4-3.5-5-5.3c-2.9-3.1-4.4-7-5.3-11.1
	c-0.8-3.5-1.6-7.1-0.8-10.8c0.3-1.2,0.2-2.5,0.3-3.8c0.2-2.6,1.3-4.8,2.9-6.7c2.4-3,5-5.7,7.7-8.4c2.1-2,4.7-3.3,7.5-4.1
	c1.6-0.5,3.1-0.9,4.7-1.1c1.8-0.3,3.7-0.6,5.6-0.8c5.8-0.5,11,1.4,15.9,4.3c2.9,1.7,5.4,3.9,6.7,7.1c0.8,1.8,1.1,3.9,1.4,5.9
	c0.4,2.9,0.3,5.7-0.4,8.5c-1.3,4.9-3.2,9.7-6.2,13.8c-2.8,3.9-6.4,7-10.7,9.2c-3.2,1.6-6.4,3.1-9.7,4.4c-2.9,1.1-5.8,2.4-9.1,2.3
	C252.6,62.2,251.9,62.5,251.1,62.7z M243.5,30.8c1.2,0.1,2.4,0.2,3.6,0.4c1.1,0.2,1.8,1,1.7,2.1c0,1.2-0.8,1.8-1.9,2
	c-0.5,0.1-1.1,0.2-1.6,0c-1.1-0.3-2.2-0.8-3.3-1.2c-0.3,0.8-0.6,1.6-0.9,2.4c-0.3,0.7-0.1,1.1,0.6,1.4c2.6,1.3,5.3,1.5,8,0.5
	c2.6-1,4-3.7,3.3-6.3c-0.3-1.2-1.2-2.2-1.9-3.4c2.8-2.9,1.3-7-1.9-8c-2.5-0.7-4.9-0.3-7.1,1.1c-0.2,0.2-0.5,0.6-0.4,0.8
	c0.3,1,0.7,2,1.1,2.9c0.9-0.5,1.7-1.1,2.6-1.2c0.9-0.1,1.8,0.1,2.7,0.3c0.2,0.1,0.4,1,0.2,1.4c-0.4,1-1.4,1.2-2.4,1.2
	c-0.7,0-1.5,0-2.2,0C243.5,28.7,243.5,29.8,243.5,30.8z M277.3,35.2c-0.1-0.2-0.2-0.3-0.3-0.5c0.4-0.3,0.9-0.5,1.3-0.8
	c1.6-1.6,3.1-3.3,3.8-5.6c1-3.1-0.2-6.2-2.9-7.3c-2.9-1.1-5.6-0.5-8,1.4c-0.2,0.2-0.4,0.6-0.3,0.9c0.4,1,0.8,1.9,1.3,2.9
	c0.5-0.4,0.9-0.8,1.3-1c0.8-0.4,1.7-0.8,2.5-0.9c1.4-0.1,2.1,0.7,2.2,2.1c0,1.5-0.7,2.6-1.6,3.6c-1.6,1.7-3.1,3.5-4.9,4.9
	c-1.4,1.2-1.3,2.4-1.1,3.9c4.1,0,8.1,0,12.2,0c0-0.9,0-1.7,0-2.5c0.1-0.9-0.3-1.2-1.2-1.2C280.2,35.2,278.7,35.2,277.3,35.2z
	 M263.7,32.4c1.5,0,2.9,0,4.2,0c0-1.1,0-2.1,0-3.1c-1.4,0-2.8,0-4.3,0c0-1.5,0-2.9,0-4.4c-1.1,0-2,0-3.1,0c0,1.5,0,2.9,0,4.4
	c-1.5,0-2.8,0-4.2,0c0,1.1,0,2,0,3.1c1.4,0,2.8,0,4.3,0c0,1.6,0,3,0,4.4c1.1,0,2.1,0,3.1,0C263.7,35.4,263.7,34,263.7,32.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M254.4,71.8c-0.6-1.8-1.1-3.2-1.7-5c1.5,0.4,1.9,2.6,3.5,1.4c-1-1.1-2-2.2-3-3.2c0.1-0.1,0.2-0.2,0.3-0.4
	c0.3,0.2,0.6,0.3,0.9,0.5c0.8,0.5,1.5,1,2.3,1.5c1.7,0.9,1.9,2.8,0.4,3.9C256.4,71,255.4,71.3,254.4,71.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M375,66.3c-2.6-2.1-5.3-3.9-6.9-6.8c-1.6-3-3-6.2-4.3-9.4c-1.3-3.3-1.5-6.8-1.8-10.2c-0.2-3.6,0.6-7,2.2-10.2
	c1.6-3.2,3.4-6.2,5.3-9.2c2-3.2,5.1-4.9,8.3-6.6c3.8-2,8-2.8,12.1-2.8c3.2,0,6.6,0.3,9.6,2c2.6,1.5,5.2,2.7,7.6,4.4
	c2.4,1.7,4.5,3.8,5.1,7c0.7,4,1.5,7.9,1.8,11.9c0.3,3.1-1.2,5.9-2.4,8.8c-1.1,2.5-2.6,4.6-4.3,6.6c-1.4,1.6-3,3.1-4.7,4.4
	c-4.6,3.7-10.1,5.8-15.6,8c-2,0.8-4.2,1.2-6.3,1.7c-0.4,0.1-0.9,0.1-1.3-0.1C377.8,64.6,376.3,64.9,375,66.3z M396,42.9
	c2.9,1.9,5.9,2.2,9,0.9c2.3-1,3.3-2.9,3.2-5.4c-0.1-1.7-0.9-2.9-2.2-4c0.2-0.3,0.4-0.5,0.6-0.8c1.9-2.5,1.1-5.9-1.7-7.2
	c-2.7-1.2-5.4-0.7-7.9,0.9c-0.2,0.1-0.4,0.6-0.3,0.8c0.3,1,0.7,1.9,1,2.7c1.3-0.5,2.4-1,3.7-1.3c0.9-0.2,1.8,0,2,1.2
	c0.2,1.1-0.6,1.6-1.5,1.8c-0.5,0.1-1,0.1-1.5,0.1c-0.5,0-1.1,0-1.6,0c0,1.3,0,2.4,0,3.5c1.2,0.1,2.3,0.1,3.4,0.3
	c1.3,0.2,2,1.2,1.9,2.2c0,1.1-0.8,1.8-2,2c-0.5,0.1-1.1,0.1-1.7-0.1c-1.1-0.3-2.1-0.7-3.2-1.2C396.8,40.5,396.4,41.7,396,42.9z
	 M379.8,36.2c-0.4,0-0.6-0.1-0.8-0.1c-1.2,0.2-1.5-0.3-1.4-1.5c0.1-2.1,0-4.2,0-6.2c0-2.4,0-2.4-2.4-2.4c-0.6,0-1.4,0.1-1.7,0.5
	c-2.5,3.4-4.9,6.7-7.1,10.2c-0.4,0.7-0.2,1.9-0.1,2.8c0,0.2,1.1,0.2,1.8,0.2c1.9,0,3.7,0,5.7,0c0,1.6,0,3,0,4.4c1.3,0,2.6,0,3.9,0
	c0-1.5,0-2.9,0-4.3c0.8,0,1.5-0.1,2.2-0.1C379.8,38.5,379.8,37.4,379.8,36.2z M382.1,37.8c1.5,0,2.8,0,4.3,0c0,1.5,0,3,0,4.4
	c1.1,0,2.1,0,3.2,0c0-1.5,0-2.9,0-4.5c1.5,0,2.8,0,4.2,0c0-1.1,0-2,0-3.1c-1.4,0-2.8,0-4.3,0c0-1.5,0-2.9,0-4.3c-1.1,0-2.1,0-3.2,0
	c0,1.5,0,2.9,0,4.4c-1.5,0-2.8,0-4.2,0C382.1,35.7,382.1,36.6,382.1,37.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }} d="M379.7,73.5c-1-1.5-2-3.1-3-4.7c0.1-0.1,0.2-0.2,0.3-0.3c1.7,0.4,2.1,2.9,4.4,2.7c-1-1.1-1.8-1.9-2.6-2.8
	c0-0.1,0.1-0.1,0.1-0.2c1.7,0.6,3.5,1.1,5.2,1.7c0.7,0.3,0.6,0.8,0.1,1.3c-0.3,0.3-0.7,0.6-1.1,0.8C382.1,72.5,381,72.9,379.7,73.5z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }} d="M378.4,66.9c-0.8,0.3-1.2,0.5-1.7,0.6c-0.2,0.1-0.5-0.1-0.7-0.1c0.1-0.2,0.1-0.6,0.2-0.7
	c0.5-0.3,1.1-0.5,1.7-0.6C378.1,66.1,378.3,66.8,378.4,66.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }} d="M451.3,65.8c-0.2,5.2-0.8,9.4-2.7,13.3c-2,4-4.4,7.6-8,10.4c-2.5,1.9-4.7,4.2-7.3,5.8
	c-3.3,1.9-6.8,3.4-10.4,4.9c-2.9,1.3-5.9,2.4-9,3.3c-1.9,0.6-3.9,0.8-5.9,0.9c-1.6,0.1-2.6-1.2-3.3-2.4c-1.4-2.5-2.7-5.1-3.9-7.8
	c-1.9-4.4-2.5-9-2.3-13.7c0.2-4.7,0.9-9.2,2.7-13.6c1.4-3.5,3.4-6.7,6.2-9.1c2.7-2.3,5.7-4.4,9.1-5.6c2-0.7,4-1.7,6-2.1
	c5.8-1.2,11.6-0.8,17.1,1.8c1.9,0.9,3.7,1.9,5.4,3c0.8,0.5,1.4,1.5,2,2.3c1,1.4,2.1,2.7,2.8,4.2C450.6,63,451,64.9,451.3,65.8z
	 M443.5,72.6c0-3.4,0-6.6,0-10c-0.8,0-1.6,0.1-2.3,0c-1.1-0.1-1.8,0.3-2.4,1.2c-1.9,2.9-3.8,5.7-5.9,8.4c-1,1.3-1,2.6-0.8,4.1
	c1.3,0,2.5,0,3.6,0c1.2,0,2.5,0,3.9,0c0,1.5,0,2.9,0,4.3c1.4,0,2.6,0,3.9,0c0-1.5,0-2.9,0-4.3c0.8,0,1.5-0.1,2.2-0.1
	c0-1.2,0-2.3,0-3.5C445.1,72.7,444.4,72.6,443.5,72.6z M410.1,76.9c-0.1-0.2-0.2-0.3-0.4-0.5c0.5-0.3,1.1-0.6,1.5-1
	c1.2-1.6,2.7-3.2,3.5-5.1c1.5-3.4-0.1-6.8-3.3-7.8c-2.7-0.8-5.1-0.1-7.3,1.6c-0.2,0.2-0.4,0.7-0.3,1c0.3,1,0.8,1.9,1.2,2.7
	c1.1-0.6,2.1-1.3,3.1-1.6c2.1-0.6,3.5,0.8,2.8,2.8c-0.4,1.1-1.1,2.2-1.8,3.2c-1.4,1.7-3,3.2-4.6,4.7c-1.3,1.1-1.3,2.3-1.1,3.8
	c4.1,0,8.1,0,12.2,0c0-1.3,0-2.4,0-3.7C413.7,76.9,411.9,76.9,410.1,76.9z M430.2,74.2c0-1.1,0-2,0-3.1c-1.4,0-2.8,0-4.3,0
	c0-1.6,0-2.9,0-4.3c-1.1,0-2,0-3.1,0c0,1.4,0,2.8,0,4.3c-1.5,0-2.8,0-4.2,0c0,1.1,0,2,0,3.1c1.4,0,2.8,0,4.3,0c0,1.6,0,3,0,4.4
	c1.1,0,2,0,3.1,0c0-1.5,0-2.9,0-4.4C427.5,74.2,428.8,74.2,430.2,74.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity23 ? this.state.opacity23 : 1}
              fill={this.state.color23 ? this.state.color23 : 'transparent'} onClick={() => {
          this.setState({ color23: color, opacity23: opacity });
        }} d="M408.9,108.2c3.1,2,3.3,2.1,4.3,1.5c-1.1-1.4-3.4-1.6-3.8-3.7c1.7-1,3.7,0.1,5,1.2c0.6,0.5,1,1.1,1.6,1.6
	c0.9,0.8,0.9,1.8-0.1,2.4c-1.6,0.8-3.3,1.4-4.9,2c-0.2,0.1-0.7-0.3-0.7-0.5C409.7,111.2,409.3,109.7,408.9,108.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }} d="M407.2,106.7c-1.3,0.4-2.1,0.2-2.4-0.8c-0.1-0.2,0.1-0.5,0.2-0.7c0.2,0.1,0.5,0.1,0.7,0.2
	C406.1,105.8,406.5,106.2,407.2,106.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1}
              fill={this.state.color25 ? this.state.color25 : 'transparent'} onClick={() => {
          this.setState({ color25: color, opacity25: opacity });
        }} d="M149.6,26.6c0.2,4.6-1.8,8.4-3.8,12.3c-3,5.8-7.7,9.7-13.2,12.7c-3,1.7-6.1,3.3-9.2,4.8
	c-3.5,1.7-7.2,2.5-11.1,2.4c-0.8,0-1.8-0.9-2.4-1.6c-2-2.1-3.9-4.4-5.7-6.6c-1.8-2.2-3.1-4.7-3.9-7.5c-1-3.3-1.6-6.8-1.4-10.3
	c0.2-3.4,1.1-6.6,2.7-9.7c2-3.7,4.3-7.1,7.6-9.6c2.3-1.8,5.1-3.1,7.8-4.3c4.3-1.9,8.9-2.7,13.6-2.3c5.2,0.4,10.1,1.9,14,5.5
	c3.7,3.4,4.9,8,5,12.9C149.6,25.9,149.6,26.3,149.6,26.6z M116.5,28.6c-0.4-1.8-0.6-3.7-1.2-5.4c-0.8-2.2-2.4-3.6-4.9-3.8
	c-2.7-0.2-4.7,1-5.8,3.4c-1.8,3.8-1.8,7.7-0.2,11.5c0.9,2.4,2.7,3.8,5.4,3.8c2.7,0,4.6-1.4,5.5-3.9C115.8,32.5,116,30.5,116.5,28.6z
	 M133.7,33.4c-0.2,0.6-0.4,1.5-0.8,2.3c-0.3,0.7,0,1.1,0.6,1.4c2.5,1.2,5.1,1.4,7.6,0.3c2.6-1.1,4-3.7,3.7-6.6
	c-0.3-2.5-2.1-4.4-4.9-5c-0.6-0.1-1.2-0.1-1.8-0.2c0-2.1,0-2.1,1.9-2.1c1.5,0,3,0,4.5,0c0-1.4,0-2.5,0-3.7c-3.2,0-6.3,0-9.5,0
	c-0.4,3.3-0.9,6.5-1.3,9.7c1.8,0,3.3-0.2,4.8,0c1.6,0.2,2.3,1.3,2.2,2.7c-0.1,1.3-1.2,2.3-2.7,2.1C136.5,34.2,135.2,33.7,133.7,33.4
	z M126.2,31.3c1.5,0,2.9,0,4.1,0c0-1.1,0-2,0-3c-1.4,0-2.7,0-4.2,0c0-1.5,0-2.9,0-4.4c-1.1,0-2.1,0-3.3,0c0,1.5,0,2.9,0,4.4
	c-1.5,0-2.8,0-4.1,0c0,1.1,0,2,0,3.1c1.4,0,2.8,0,4.2,0c0,1.5,0,2.9,0,4.4c0.8,0,1.4,0,2,0c0.9,0.1,1.2-0.2,1.2-1.1
	C126.1,33.7,126.2,32.6,126.2,31.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1}
              fill={this.state.color26 ? this.state.color26 : 'transparent'} onClick={() => {
          this.setState({ color26: color, opacity26: opacity });
        }} d="M114.8,65.7c-0.2-1.3-0.4-2.3-0.7-3.5c0.9,0.4,1.6,0.6,2.3,0.9c0-0.1,0.1-0.2,0.1-0.2c-0.5-0.4-1-0.8-1.4-1.3
	c-0.2-0.3-0.1-0.8-0.2-1.1c0.4,0.1,0.8,0,1.1,0.2c0.7,0.4,1.4,0.8,2,1.2c1,0.7,0.9,1.3-0.1,1.9C117,64.4,116,65,114.8,65.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M224.3,50.8c2.1,0.2,5.2,0.5,8.2,0.8c2.1,0.2,3.7,1.3,5,2.8c1.5,1.7,2.9,3.4,4.2,5.2c1,1.4,1.8,2.9,2.5,4.5
	c1,2,1,4.3,1,6.5c0.1,6.3-3,11.2-7.1,15.6c-6.1,6.6-13.7,10.7-22.1,13.6c-1.6,0.6-3.3,0.7-5,1.1c-0.9,0.2-1.9,0.4-2.8,0.6
	c-0.7,0.2-1.1,0.1-1.6-0.6c-0.8-1.2-1.7-1.4-3.1-0.8c-1.1,0.5-1.9,0.3-2.7-0.7c-4.4-5.2-6.1-11.2-6-17.9c0-3.8-0.1-7.6,1.2-11.2
	c0.9-2.6,2-5.4,3.7-7.6c2.9-3.7,6.4-6.8,10.6-9.1C214.4,51.3,218.8,50.9,224.3,50.8z M210,62c-2.7-0.1-5.2,0.3-7.4,2
	c-3.3,2.4-4.8,7.2-3.7,11.5c0.9,3.4,3.4,5.3,6.9,5.2c3.1-0.1,5.6-2.8,5.8-6.2c0.2-3.6-1.7-6.2-5-6.6c-0.8-0.1-1.7,0-2.6,0
	c1.6-1.9,3.7-2.2,6-2C210,64.4,210,63.2,210,62z M241.3,71.2c-0.3-1.7-0.5-3.6-1.1-5.2c-0.8-2.4-2.5-3.9-5.1-4
	c-2.8-0.1-4.7,1.2-5.8,3.6c-1.7,3.9-1.7,7.9,0.2,11.8c1,2,2.6,3.2,4.9,3.2c2.4,0,4.2-1,5.4-3.1C240.8,75.5,241.1,73.4,241.3,71.2z
	 M221.5,70.8c0-1.6,0-3,0-4.4c-1.1,0-2,0-3.1,0c0,1.5,0,2.9,0,4.4c-1.5,0-2.8,0-4.2,0c0,1.1,0,2,0,3.1c1.4,0,2.8,0,4.2,0
	c0,1.6,0,3,0,4.5c1.1,0,2,0,3.1,0c0-1.5,0-2.9,0-4.5c1.5,0,2.9,0,4.2,0c0-1.1,0-2,0-3.1C224.4,70.8,223.1,70.8,221.5,70.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity28 ? this.state.opacity28 : 1}
              fill={this.state.color28 ? this.state.color28 : 'transparent'} onClick={() => {
          this.setState({ color28: color, opacity28: opacity });
        }} d="M210.8,106.4c-0.6-0.4-1.2-0.7-1.9-1.1c-0.4-0.2-0.8-0.4-1-0.8c-0.2-0.3-0.2-0.7-0.1-1.1
	c0.1-0.2,0.6-0.4,0.8-0.3c1.4,0.6,2.8,1.1,4.1,2c1.4,1,1.3,2.5-0.2,3.5c-1.2,0.8-2.4,1.4-3.8,2.2c-0.6-1.8-1.2-3.5-1.7-5.1
	c1.2,0.4,2.4,0.8,3.6,1.2C210.6,106.7,210.7,106.6,210.8,106.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity29 ? this.state.opacity29 : 1}
              fill={this.state.color29 ? this.state.color29 : 'transparent'} onClick={() => {
          this.setState({ color29: color, opacity29: opacity });
        }} d="M202.6,102.2c1.2-1.3,2.1-1.5,3-0.8c0.2,0.2,0.5,0.6,0.4,0.9c-0.1,0.2-0.5,0.5-0.8,0.5
	C204.4,102.6,203.6,102.4,202.6,102.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity30 ? this.state.opacity30 : 1}
              fill={this.state.color30 ? this.state.color30 : 'transparent'} onClick={() => {
          this.setState({ color30: color, opacity30: opacity });
        }} d="M68.7,37.3c0,4.2-1,7.9-3.5,11.4c-2.3,3.2-4.6,6.3-7.8,8.5c-4.3,3-8.6,6-13.7,7.7c-3,1-5.8,2.4-8.7,3.6
	C33.6,69,32.4,69.6,31,69c-0.3-0.1-0.7-0.3-0.9-0.6c-1.9-2.5-3.7-5.1-5.5-7.7c-3.5-4.8-6.4-9.9-7.4-16c-0.8-4.8,0-9.3,1.2-13.9
	c1.3-5.2,4.5-8.9,8.8-11.8c1.9-1.2,3.8-2.7,5.9-3.3c4.5-1.3,9-2.4,13.8-1.6c2.7,0.5,5.6,0.9,8.1,2c3.9,1.6,7.4,4,9.7,7.6
	C67.3,27.9,68.7,32.4,68.7,37.3z M61.9,28.6c-4.1-0.1-7.6,0.9-9.7,4.5c-2.2,3.7-2.6,7.7-0.3,11.5c1.4,2.3,3.7,3,6.3,2.6
	c2.4-0.3,4-1.7,4.9-3.9c1.6-4.2-0.6-8.4-4.8-8.9c-0.7-0.1-1.5,0-2.3,0c1.5-1.9,3.7-2.1,5.9-2.1C61.9,31.1,61.9,30,61.9,28.6z
	 M21.1,45.6c2.4,1.8,5.9,2.2,8.6,1.2c2.5-1,4.1-3.4,3.5-6c-0.3-1.3-1.2-2.5-1.9-3.9c0,0,0.2-0.2,0.4-0.5c2-2.7,0.9-6.5-2.4-7.4
	c-2.5-0.7-4.8-0.3-7,1.1c-0.3,0.2-0.5,0.7-0.5,0.9c0.3,1,0.7,1.9,1.1,3c0.4-0.3,0.7-0.6,1.1-0.7c1-0.3,2-0.7,3.1-0.8
	c0.4,0,1.3,0.6,1.3,1c0.1,0.5-0.4,1.3-0.8,1.6c-0.5,0.4-1.3,0.4-1.9,0.5c-0.6,0.1-1.2,0-1.9,0c0,1.3,0,2.4,0,3.6
	c0.6,0,1.1-0.1,1.6,0c0.8,0.1,1.7,0.2,2.4,0.5c0.9,0.3,1.3,1.1,1.2,2.1c-0.1,0.9-0.7,1.6-1.6,1.7c-0.6,0.1-1.3,0.2-1.9,0.1
	c-1.1-0.3-2.2-0.8-3.4-1.2C21.9,43.4,21.5,44.5,21.1,45.6z M36.7,40.5c1.4,0,2.8,0,4.3,0c0,1.6,0,3,0,4.5c1.1,0,2.1,0,3.2,0
	c0-1.5,0-2.9,0-4.5c1.5,0,2.8,0,4.2,0c0-1.1,0-2,0-3.1c-1.4,0-2.8,0-4.2,0c0-1.5,0-2.9,0-4.3c-1.1,0-2.1,0-3.2,0c0,1.5,0,2.9,0,4.4
	c-1.5,0-2.8,0-4.2,0C36.7,38.5,36.7,39.5,36.7,40.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity31 ? this.state.opacity31 : 1}
              fill={this.state.color31 ? this.state.color31 : 'transparent'} onClick={() => {
          this.setState({ color31: color, opacity31: opacity });
        }} d="M37,74.6c-1.3-0.9-2.5-1.9-3.9-2.8c1.2-0.8,2.2-0.3,3.1,0.3c1.1,0.7,2.1,1.4,3,2.3c0.8,0.9,0.6,1.6-0.6,2
	c-1,0.4-2,0.7-3.1,1c-0.4,0.1-1-0.2-1.2-0.6c-0.5-0.9-0.9-1.9-1.3-2.9c0.1-0.1,0.2-0.2,0.2-0.3c1.2,0.5,2.4,1,3.5,1.4
	C36.9,74.8,37,74.7,37,74.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1}
              fill={this.state.color32 ? this.state.color32 : 'transparent'} onClick={() => {
          this.setState({ color32: color, opacity32: opacity });
        }} d="M31.3,72.1c-0.6-0.6-1-1.1-1.4-1.5c0.4-0.3,0.9-0.9,1.2-0.8c0.5,0.2,0.9,0.8,1.4,1.2
	C32.1,71.2,31.8,71.6,31.3,72.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity33 ? this.state.opacity33 : 1}
              fill={this.state.color33 ? this.state.color33 : 'transparent'} onClick={() => {
          this.setState({ color33: color, opacity33: opacity });
        }} d="M106.7,70.6c0.1,5.1-1.4,9.3-3.7,13.1c-1.4,2.3-3.4,4.2-5.1,6.3c-3.7,4.3-8.6,7.1-13.6,9.6
	c-3.1,1.5-6.4,1.9-9.8,2.1c-2.8,0.2-5-0.7-7.5-1.7c-2.5-1-3.9-2.8-5.5-4.6c-1.7-1.8-3.2-3.7-4.2-6.1c-2.1-5.2-2.8-10.6-1.8-16.1
	c0.3-1.6,0.6-3.2,1-4.8c0.2-0.8,0.5-1.5,0.9-2.2c1.8-3.4,3.5-6.8,6.3-9.5c3.2-3,6.9-5.4,11.1-6.7c3.2-1,6.5-0.9,9.8-0.5
	c2.4,0.2,4.7,0.9,7,1.1c7.1,0.7,10.4,5.7,13.3,11.2C106.4,64.7,106.7,67.8,106.7,70.6z M59.2,81.3c2.8,1.7,5.6,2.1,8.5,0.8
	c2.6-1.1,4-3.7,3.8-6.5c-0.3-2.7-2.2-4.6-5-5.2c-0.6-0.1-1.2-0.1-1.9-0.2c0.1-0.7,0.2-1.4,0.3-2.1c2.1,0,4.1,0,6.2,0
	c0-1.3,0-2.5,0-3.7c-3.2,0-6.3,0-9.5,0c-0.4,3.3-0.9,6.4-1.3,9.8c0.6-0.1,1-0.1,1.4-0.1c1.2,0,2.5-0.1,3.7,0.1
	c1.5,0.3,2.1,1.2,1.9,2.7c-0.1,1.2-1,2.2-2.3,2.1c-1.2,0-2.3-0.4-3.5-0.7c-0.4-0.1-0.7-0.3-1.1-0.6C60,79,59.6,80,59.2,81.3z
	 M89.7,74c1.7,0,3.2-0.1,4.6,0c1.7,0.2,2.5,1.2,2.4,2.7c-0.1,1.4-1.2,2.4-2.9,2.2c-1.4-0.1-2.7-0.6-4.1-1c-0.2,0.6-0.4,1.5-0.8,2.3
	c-0.3,0.8-0.2,1.2,0.6,1.5c2.5,1.2,5.1,1.4,7.6,0.3c2.6-1.2,4-3.8,3.6-6.8c-0.3-2.4-2.2-4.3-4.9-4.9c-0.6-0.1-1.2-0.1-1.7-0.2
	c-0.2-2.1-0.1-2.1,1.8-2.1c1.5,0,3,0,4.6,0c0-1.3,0-2.5,0-3.7c-3.2,0-6.3,0-9.5,0C90.6,67.6,90.1,70.8,89.7,74z M79,73
	c-1.5,0-2.8,0-4.2,0c0,0.8,0,1.4,0,2c-0.1,0.9,0.3,1.1,1.1,1.1c1-0.1,2,0,3.2,0c0,1.6,0,3,0,4.4c1.1,0,2,0,3.1,0c0-1.5,0-2.9,0-4.4
	c0.7,0,1.3,0,1.9,0c2.7,0,2.7,0,2.4-2.7c0-0.1-0.1-0.1-0.2-0.3c-1.3,0-2.6,0-4.1,0c0-1.3,0-2.4,0-3.6c0-0.3-0.4-0.8-0.6-0.9
	c-0.8-0.1-1.6,0-2.5,0C79,70,79,71.4,79,73z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M59.4,100.4c0.9,0.3,1.4,0.5,2,0.7c0,0.2,0,0.3,0,0.5c-1.6,0.4-3.3,0.8-5.2,1.2c0-1.3-0.1-2.3,0-3.4
	c0-0.3,0.6-0.8,0.9-0.8c1.7,0.2,3.4,0.5,5,0.8c0.1,0,0.4,0.4,0.3,0.5c-0.1,0.2-0.4,0.4-0.6,0.5C61.1,100.4,60.4,100.4,59.4,100.4z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity35 ? this.state.opacity35 : 1}
              fill={this.state.color35 ? this.state.color35 : 'transparent'} onClick={() => {
          this.setState({ color35: color, opacity35: opacity });
        }} d="M58.8,104.7c-0.2-0.4-0.3-0.8-0.5-1.2c1.6-0.6,3.2-1.1,4.7-1.6c0.1,0.2,0.2,0.4,0.2,0.5
	C61.8,103.1,60.4,103.9,58.8,104.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1}
              fill={this.state.color36 ? this.state.color36 : 'transparent'} onClick={() => {
          this.setState({ color36: color, opacity36: opacity });
        }} d="M65.8,102.9c-0.7-0.7-1.3-1.2-1.8-1.8c0.3-0.3,0.7-0.9,0.9-0.8c0.7,0.3,1.3,0.8,2,1.2
	C66.6,101.9,66.3,102.2,65.8,102.9z" />
        <path fill="transparent" d="M331.7,81c1.4,1.1,2.2,2.5,2.2,4.4c-0.1,1.9-0.8,3.4-2.4,4.3c-2.6,1.6-5.3,1.7-8,0.2c-2.9-1.6-3.4-5.3-1.3-7.8
	c0.3-0.3,0.6-0.5,1-0.9c-1.4-1.4-2.1-3-1.5-4.8c0.4-1.1,1.1-2.2,1.9-2.9c2.2-1.9,6.2-1.6,8.2,0.3C333.8,75.6,333.8,78,331.7,81z
	 M327.4,87.4c1.4,0,2.3-0.7,2.3-1.9c0-1.1-1.3-2.5-2.5-2.5c-1,0-2,1.1-2.1,2.3C325.2,86.5,326,87.4,327.4,87.4z M327.5,75.7
	c-1.1,0-1.8,0.6-1.9,1.6c0,0.9,0.9,1.9,1.9,2c0.8,0,1.7-0.9,1.7-1.8C329.3,76.4,328.6,75.7,327.5,75.7z" />
        <path fill="transparent" d="M357.8,72.6c0,5.9,0,11.9,0,18c-1.3,0-2.6,0-4,0c0-4.5,0-8.8,0-13.5c-0.9,0.5-1.6,0.8-2.5,1.3c-0.3-1.1-0.6-2.2-0.8-3.2
	c0-0.1,0.1-0.4,0.2-0.5c2.2-1.1,4.2-2.8,6.9-2.2C357.6,72.5,357.7,72.6,357.8,72.6z" />
        <path fill="transparent" d="M336.6,81.1c1.4,0,2.7,0,4.1,0c0-1.5,0-2.9,0-4.4c1.1,0,2.1,0,3.2,0c0,1.5,0,2.9,0,4.4c1.5,0,2.8,0,4.2,0c0,1.1,0,2,0,3.1
	c-1.4,0-2.7,0-4.2,0c0,1.5,0,2.9,0,4.5c-0.9,0-1.8,0.1-2.6,0c-0.3,0-0.6-0.5-0.6-0.9c-0.1-1.1,0-2.3,0-3.6c-1.5,0-2.8,0-4.2,0
	C336.6,83.1,336.6,82.2,336.6,81.1z" />
        <path fill="transparent" d="M165.9,64.3c0,3.5,0,6.7,0,10.1c0.7,0,1.4,0,2.1,0c0,1.2,0,2.3,0,3.5c-0.7,0-1.3,0.1-2.1,0.1c0,1.4,0,2.9,0,4.3
	c-1.4,0-2.7,0-4.1,0c0-1.4,0-2.8,0-4.4c-2.6,0-5,0-7.5,0c-0.2-1.5-0.2-2.7,0.7-3.9c2.1-2.9,4.2-5.9,6.2-8.8c0.5-0.8,1.1-1.1,2-1
	C164,64.4,164.9,64.3,165.9,64.3z M161.7,71.1c-0.9,1.2-1.6,2.2-2.4,3.3c0.9,0,1.6,0,2.4,0C161.7,73.3,161.7,72.4,161.7,71.1z" />
        <path fill="transparent" d="M125.3,81.2c0.4-1.2,0.7-2.3,1.1-3.4c0.9,0.4,1.7,0.8,2.6,1c0.9,0.2,1.9,0.2,2.8-0.1c1.1-0.3,1.6-1.2,1.6-2.3
	c0-1.2-0.6-2.1-1.7-2.3c-1.3-0.2-2.6-0.2-3.9-0.2c-0.4,0-0.8,0.1-1.4,0.1c0.4-3.3,0.9-6.5,1.3-9.8c3.2,0,6.3,0,9.6,0
	c0,1.2,0,2.4,0,3.7c-2.1,0-4.1,0-6.3,0c-0.1,0.8-0.2,1.4-0.3,2.1c1,0.2,1.9,0.2,2.8,0.5c2.7,0.8,4.2,3,4.2,5.8c-0.1,2.8-2,5.3-4.8,6
	C130.1,83.2,127.7,82.8,125.3,81.2z" />
        <path fill="transparent" d="M152.5,76c-1.4,0-2.7,0-4.2,0c0,1.2,0,2.3,0,3.4c0,0.8-0.3,1-1,1c-2.6-0.1-2.1,0.2-2.1-2.1c0-0.8,0-1.5,0-2.4
	c-1.5,0-2.8,0-4.2,0c0-1.1,0-2,0-3.1c1.4,0,2.7,0,4.2,0c0-1.2,0-2.4,0-3.5c0-0.8,0.2-1.1,1-1c0.6,0.1,1.3,0,2.1,0c0,1.5,0,2.9,0,4.4
	c1.5,0,2.8,0,4.3,0C152.5,73.9,152.5,74.9,152.5,76z" />
        <path fill="transparent" d="M302,81.6c-0.4,1.8-0.7,3.8-1.3,5.6c-0.9,2.4-2.9,3.7-5.5,3.7c-2.5,0-4.3-1.3-5.2-3.6c-1.6-3.8-1.7-7.7,0-11.5
	c1.1-2.6,3.2-3.8,6-3.6c2.6,0.2,4.2,1.8,4.9,4.1C301.4,77.9,301.6,79.7,302,81.6z M292.7,81.6c0.3,1.5,0.6,3,1,4.4
	c0.2,0.5,1,1.2,1.5,1.2c0.5,0,1.3-0.7,1.5-1.2c1.1-2.9,1.1-5.9,0-8.8c-0.2-0.6-1-1.3-1.5-1.3c-0.6,0-1.4,0.7-1.6,1.2
	C293.3,78.6,293.1,80.1,292.7,81.6z" />
        <path fill="transparent" d="M261.2,72.5c4,0,8,0,12.4,0c-0.3,1.3-0.4,2.6-0.9,3.7c-2,4.4-4.1,8.7-6.1,13c-0.2,0.5-0.5,0.9-0.7,1.3c-1.4,0-2.8,0-4.4,0
	c1.2-2.5,2.4-4.8,3.5-7.2c1.1-2.3,2.3-4.6,3.5-7.1c-2.5,0-4.9,0-7.3,0C261.2,75,261.2,73.8,261.2,72.5z" />
        <path fill="transparent" d="M286.5,84.2c-1.4,0-2.7,0-4.2,0c0,1.5,0,2.8,0,4.3c-1.1,0-2.1,0-3.2,0c0-1.4,0-2.8,0-4.3c-1.5,0-2.8,0-4.3,0
	c0-1.1,0-2.1,0-3.1c1.4,0,2.7,0,4.2,0c0-1.5,0-3,0-4.4c1.1,0,2,0,3.1,0c0,1.4,0,2.9,0,4.4c1.5,0,2.8,0,4.3,0
	C286.5,82.2,286.5,83.1,286.5,84.2z" />
        <path fill="transparent" d="M182.9,27.1c0.1,0.1,0.1,0.2,0.2,0.3c1.7,1.5,2.4,3.3,1.8,5.5c-0.6,2.2-2.2,3.5-4.3,4c-1.7,0.4-3.4,0.3-5.1-0.3
	c-3.5-1.4-4.4-5.7-1.9-8.4c0.2-0.3,0.6-0.4,0.8-0.6c-2.1-3.1-2.1-5.7,0-7.6c2.2-2,6.4-2.1,8.5-0.1C184.9,21.6,184.9,24.2,182.9,27.1
	z M178.6,33.5c1.4,0,2.3-0.7,2.3-1.9c0-1.1-1.3-2.4-2.5-2.5c-1,0-2,1.2-2,2.3C176.3,32.7,177.2,33.5,178.6,33.5z M180.4,23.5
	c0-1-0.7-1.6-1.8-1.6c-1.1,0-1.8,0.7-1.8,1.6c0,0.9,1.1,1.9,2,1.9C179.5,25.4,180.4,24.4,180.4,23.5z" />
        <path fill="transparent" d="M208.6,33c1.7,0,3.5,0,5.3,0c0,1.3,0,2.4,0,3.7c-4,0-8,0-12.1,0c-0.2-1.5-0.3-2.7,1.1-3.9c1.7-1.4,3.2-3,4.6-4.7
	c0.7-0.8,1.3-1.8,1.7-2.8c0.4-0.9,0.4-1.9-0.4-2.7c-0.8-0.7-1.8-0.8-2.7-0.4c-0.9,0.4-1.8,1-2.8,1.6c-0.4-0.9-0.9-1.8-1.3-2.8
	c-0.1-0.2,0.1-0.7,0.4-0.9c2.2-1.8,4.8-2.4,7.5-1.5c3.1,1,4.6,4.5,3.1,7.8c-0.9,1.9-2.4,3.5-3.6,5.2c-0.3,0.4-0.7,0.6-1.1,0.9
	C208.5,32.8,208.6,32.9,208.6,33z" />
        <path fill="transparent" d="M191.8,27.2c0-1.5,0-2.9,0-4.3c1.1,0,2.1,0,3.2,0c0,1.4,0,2.8,0,4.3c1.1,0,2.1,0.1,3,0c1-0.1,1.4,0.3,1.3,1.3
	c-0.1,0.5,0,1.1,0,1.8c-1.4,0-2.7,0-4.2,0c0,1.5,0,2.9,0,4.5c-1.1,0-2.2,0-3.3,0c0-1.4,0-2.9,0-4.4c-1.4,0-2.7,0-4.2,0
	c0-0.9-0.1-1.7,0-2.5c0-0.2,0.5-0.6,0.8-0.6C189.5,27.1,190.5,27.2,191.8,27.2z" />
        <path fill="transparent" d="M332.9,42c0.4-1.1,0.8-2.2,1.2-3.3c1.1,0.4,2,0.8,3,1.2c0.3,0.1,0.6,0.1,0.9,0.1c1.7,0.1,2.8-0.7,2.9-2
	c0.1-1.2-0.9-2.1-2.5-2.3c-0.9-0.1-1.8-0.1-2.8-0.2c0-1.1,0-2.2,0-3.4c0.4,0,0.9,0,1.4-0.1c0.7-0.1,1.5-0.2,2.2-0.4
	c0.8-0.2,1.2-0.9,1.1-1.7c-0.1-0.9-0.9-1.2-1.6-1.1c-0.9,0.1-1.8,0.4-2.7,0.8c-0.4,0.1-0.7,0.5-1.2,0.8c-0.4-1-0.8-2-1.1-2.9
	c-0.1-0.2,0.1-0.7,0.3-0.9c1.9-1.6,5.9-2,8.1-0.8c2.5,1.3,3.2,4.6,1.6,6.9c-0.2,0.3-0.4,0.6-0.5,0.7c0.7,1.2,1.6,2.3,1.9,3.6
	c0.6,2.6-0.7,5-3.2,6.2C339.2,44.3,335.2,43.9,332.9,42z" />
        <path fill="transparent" d="M306.2,35.6c0-1.2,0-2.3,0-3.5c0.6,0,1.1,0.1,1.7,0c0.8-0.1,1.6-0.2,2.3-0.6c0.4-0.2,0.8-1,0.7-1.4
	c-0.1-0.5-0.8-1.2-1.2-1.1c-1,0-2.1,0.4-3.1,0.7c-0.4,0.1-0.7,0.5-1.2,0.8c-0.4-1.2-0.9-2.3-1.3-3.3c2.1-2.1,6.5-2.6,8.9-1.1
	c2.2,1.4,2.8,4.3,1.3,6.5c-0.2,0.4-0.5,0.7-0.6,0.8c0.7,1.3,1.5,2.3,1.8,3.5c0.7,2.4-0.6,4.9-2.8,6c-2.8,1.4-5.6,1.2-8.4-0.2
	c-0.7-0.3-0.9-0.8-0.6-1.5c0.4-0.8,0.6-1.6,0.9-2.4c1.1,0.4,2.1,0.9,3.1,1.2c1.9,0.5,3.6-0.3,3.8-1.7c0.2-1.5-0.9-2.5-3.1-2.6
	C307.7,35.6,307.1,35.6,306.2,35.6z" />
        <path fill="transparent" d="M319.1,33.9c1.4,0,2.7,0,4.2,0c0-1.5,0-2.9,0-4.3c1.1,0,2.1,0,3.2,0c0,1.4,0,2.8,0,4.3c1.5,0,2.8,0,4.2,0c0,1.1,0,2,0,3.1
	c-1.4,0-2.7,0-4.2,0c0,1.5,0,3,0,4.5c-1.1,0-2.1,0-3.2,0c0-1.4,0-2.8,0-4.4c-1.5,0-2.8,0-4.2,0C319.1,36,319.1,35,319.1,33.9z" />
        <path fill="transparent" d="M243.5,30.8c0-1.1,0-2.1,0-3.4c0.7,0,1.5,0,2.2,0c1,0,2-0.2,2.4-1.2c0.2-0.4,0-1.3-0.2-1.4c-0.9-0.3-1.8-0.5-2.7-0.3
	c-0.9,0.1-1.6,0.8-2.6,1.2c-0.4-0.9-0.8-1.9-1.1-2.9c-0.1-0.2,0.2-0.7,0.4-0.8c2.2-1.4,4.6-1.8,7.1-1.1c3.2,0.9,4.7,5.1,1.9,8
	c0.7,1.2,1.5,2.2,1.9,3.4c0.7,2.6-0.7,5.3-3.3,6.3c-2.7,1.1-5.4,0.9-8-0.5c-0.6-0.3-0.9-0.7-0.6-1.4c0.4-0.8,0.6-1.7,0.9-2.4
	c1.2,0.4,2.2,0.9,3.3,1.2c0.5,0.1,1.1,0.1,1.6,0c1.1-0.2,1.8-0.8,1.9-2c0-1.2-0.7-1.9-1.7-2.1C245.9,31,244.7,31,243.5,30.8z" />
        <path fill="transparent" d="M277.3,35.2c1.5,0,2.9,0,4.4,0c0.9,0,1.2,0.3,1.2,1.2c-0.1,0.8,0,1.6,0,2.5c-4.1,0-8.1,0-12.2,0c-0.2-1.5-0.3-2.7,1.1-3.9
	c1.8-1.5,3.3-3.2,4.9-4.9c0.9-1,1.7-2.2,1.6-3.6c0-1.4-0.7-2.2-2.2-2.1c-0.9,0.1-1.7,0.5-2.5,0.9c-0.5,0.2-0.8,0.6-1.3,1
	c-0.5-1-0.9-1.9-1.3-2.9c-0.1-0.2,0.1-0.7,0.3-0.9c2.4-1.9,5.1-2.5,8-1.4c2.7,1,3.9,4.2,2.9,7.3c-0.7,2.3-2.2,3.9-3.8,5.6
	c-0.4,0.4-0.9,0.6-1.3,0.8C277.1,34.9,277.2,35,277.3,35.2z" />
        <path fill="transparent" d="M263.7,32.4c0,1.6,0,3,0,4.4c-1,0-2,0-3.1,0c0-1.4,0-2.9,0-4.4c-1.5,0-2.9,0-4.3,0c0-1.1,0-2,0-3.1c1.3,0,2.7,0,4.2,0
	c0-1.5,0-2.9,0-4.4c1.1,0,2,0,3.1,0c0,1.4,0,2.8,0,4.4c1.5,0,2.8,0,4.3,0c0,1,0,2,0,3.1C266.6,32.4,265.2,32.4,263.7,32.4z" />
        <path fill="transparent" d="M396,42.9c0.4-1.2,0.8-2.3,1.2-3.4c1.1,0.4,2.1,0.9,3.2,1.2c0.5,0.2,1.1,0.1,1.7,0.1c1.2-0.2,2-0.9,2-2c0-1.1-0.7-2-1.9-2.2
	c-1.1-0.2-2.2-0.2-3.4-0.3c0-1.1,0-2.2,0-3.5c0.6,0,1.1,0,1.6,0c0.5,0,1.1,0,1.5-0.1c0.9-0.3,1.7-0.7,1.5-1.8
	c-0.2-1.2-1.1-1.4-2-1.2c-1.2,0.3-2.4,0.8-3.7,1.3c-0.3-0.8-0.7-1.8-1-2.7c-0.1-0.2,0.1-0.7,0.3-0.8c2.5-1.6,5.1-2.1,7.9-0.9
	c2.8,1.2,3.6,4.7,1.7,7.2c-0.2,0.2-0.4,0.5-0.6,0.8c1.3,1.1,2.2,2.3,2.2,4c0.1,2.5-1,4.4-3.2,5.4C401.9,45.1,398.9,44.7,396,42.9z"
        />
        <path fill="transparent" d="M379.8,36.2c0,1.2,0,2.3,0,3.5c-0.7,0-1.4,0.1-2.2,0.1c0,1.4,0,2.8,0,4.3c-1.4,0-2.6,0-3.9,0c0-1.4,0-2.8,0-4.4
	c-2,0-3.8,0-5.7,0c-0.6,0-1.7-0.1-1.8-0.2c-0.1-0.9-0.3-2.1,0.1-2.8c2.3-3.5,4.7-6.9,7.1-10.2c0.3-0.4,1.1-0.4,1.7-0.5
	c2.4,0,2.4,0,2.4,2.4c0,2.1,0.1,4.2,0,6.2c0,1.1,0.2,1.7,1.4,1.5C379.2,36.1,379.5,36.2,379.8,36.2z M373.6,33.2
	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.7,1-1.4,1.9-2.2,3.1c1,0,1.6,0,2.4,0C373.6,35.1,373.6,34.1,373.6,33.2z" />
        <path fill="transparent" d="M382.1,37.8c0-1.1,0-2,0-3.1c1.3,0,2.7,0,4.2,0c0-1.5,0-2.9,0-4.4c1.1,0,2.1,0,3.2,0c0,1.4,0,2.7,0,4.3c1.5,0,2.9,0,4.3,0
	c0,1.1,0,2,0,3.1c-1.4,0-2.7,0-4.2,0c0,1.5,0,2.9,0,4.5c-1.1,0-2.1,0-3.2,0c0-1.4,0-2.8,0-4.4C384.9,37.8,383.6,37.8,382.1,37.8z" />
        <path fill="transparent" d="M443.5,72.6c0.9,0.1,1.6,0.1,2.3,0.2c0,1.2,0,2.3,0,3.5c-0.7,0-1.4,0.1-2.2,0.1c0,1.4,0,2.8,0,4.3c-1.3,0-2.6,0-3.9,0
	c0-1.4,0-2.8,0-4.3c-1.4,0-2.6,0-3.9,0c-1.2,0-2.4,0-3.6,0c-0.2-1.5-0.2-2.8,0.8-4.1c2.1-2.7,4-5.6,5.9-8.4c0.6-1,1.3-1.4,2.4-1.2
	c0.7,0.1,1.4,0,2.3,0C443.5,65.9,443.5,69.2,443.5,72.6z M437.2,72.4c2.5,0.5,3-0.1,2.1-2.9C438.6,70.5,437.9,71.5,437.2,72.4z" />
        <path fill="transparent" d="M410.1,76.9c1.8,0,3.5,0,5.4,0c0,1.3,0,2.4,0,3.7c-4.1,0-8.1,0-12.2,0c-0.3-1.5-0.2-2.7,1.1-3.8c1.7-1.4,3.2-3,4.6-4.7
	c0.8-0.9,1.5-2,1.8-3.2c0.7-2-0.8-3.5-2.8-2.8c-1.1,0.3-2,1-3.1,1.6c-0.4-0.8-0.8-1.7-1.2-2.7c-0.1-0.3,0.1-0.8,0.3-1
	c2.2-1.7,4.6-2.4,7.3-1.6c3.2,0.9,4.8,4.4,3.3,7.8c-0.8,1.9-2.2,3.4-3.5,5.1c-0.3,0.4-1,0.7-1.5,1C409.9,76.6,410,76.8,410.1,76.9z"
        />
        <path fill="transparent" d="M430.2,74.2c-1.4,0-2.7,0-4.2,0c0,1.5,0,3,0,4.4c-1.1,0-2,0-3.1,0c0-1.4,0-2.8,0-4.4c-1.5,0-2.9,0-4.3,0c0-1.1,0-2,0-3.1
	c1.4,0,2.7,0,4.2,0c0-1.5,0-2.9,0-4.3c1.1,0,2,0,3.1,0c0,1.4,0,2.7,0,4.3c1.5,0,2.9,0,4.3,0C430.2,72.2,430.2,73.1,430.2,74.2z" />
        <path fill="transparent" d="M116.5,28.6c-0.4,1.9-0.7,3.8-1.3,5.6c-0.9,2.5-2.8,3.9-5.5,3.9c-2.7,0-4.5-1.4-5.4-3.8c-1.5-3.8-1.6-7.7,0.2-11.5
	c1.1-2.4,3.1-3.6,5.8-3.4c2.5,0.2,4.1,1.6,4.9,3.8C115.8,24.9,116.1,26.8,116.5,28.6z M112.2,28.7c-0.3-1.4-0.4-2.8-0.8-4.1
	c-0.2-0.6-1-1.4-1.6-1.5c-0.5,0-1.4,0.7-1.7,1.3c-1.1,2.8-1.1,5.8,0,8.6c0.2,0.6,1.1,1.3,1.6,1.3c0.5,0,1.4-0.7,1.6-1.3
	C111.8,31.6,112,30.1,112.2,28.7z" />
        <path fill="transparent" d="M133.7,33.4c1.5,0.4,2.9,0.8,4.2,1c1.5,0.1,2.6-0.9,2.7-2.1c0.2-1.5-0.5-2.5-2.2-2.7c-1.5-0.2-3.1,0-4.8,0
	c0.4-3.2,0.8-6.4,1.3-9.7c3.2,0,6.3,0,9.5,0c0,1.2,0,2.3,0,3.7c-1.5,0-3,0-4.5,0c-1.9,0-1.9,0-1.9,2.1c0.6,0.1,1.2,0.1,1.8,0.2
	c2.8,0.6,4.7,2.5,4.9,5c0.3,2.9-1.1,5.5-3.7,6.6c-2.6,1.1-5.1,0.9-7.6-0.3c-0.6-0.3-0.9-0.7-0.6-1.4C133.2,34.9,133.5,34,133.7,33.4
	z" />
        <path fill="transparent" d="M126.2,31.3c0,1.3-0.1,2.4,0,3.5c0.1,0.9-0.3,1.2-1.2,1.1c-0.6-0.1-1.3,0-2,0c0-1.5,0-2.9,0-4.4c-1.4,0-2.8,0-4.2,0
	c0-1.1,0-2,0-3.1c1.3,0,2.6,0,4.1,0c0-1.5,0-2.9,0-4.4c1.1,0,2.1,0,3.3,0c0,1.4,0,2.8,0,4.4c1.5,0,2.8,0,4.2,0c0,1,0,1.9,0,3
	C129,31.3,127.7,31.3,126.2,31.3z" />
        <path fill="transparent" d="M210,62c0,1.3,0,2.4,0,3.7c-2.3-0.1-4.3,0.2-6,2c1,0,1.8-0.1,2.6,0c3.2,0.4,5.2,3,5,6.6c-0.2,3.4-2.7,6.1-5.8,6.2
	c-3.5,0.2-6-1.7-6.9-5.2c-1.1-4.4,0.4-9.1,3.7-11.5C204.8,62.3,207.3,61.8,210,62z M207.5,73.9c0-0.9-0.2-1.9-1.3-2.4
	c-1.1-0.5-2-0.2-2.8,0.6c-0.8,0.8-0.8,2.3,0,3.6c0.5,0.9,1.3,1.4,2.4,1.3C206.8,76.8,207.5,75.7,207.5,73.9z" />
        <path fill="transparent" d="M241.3,71.2c-0.2,2.2-0.5,4.4-1.5,6.3c-1.2,2.1-3,3.2-5.4,3.1c-2.3,0-4-1.2-4.9-3.2c-1.8-3.9-1.9-7.9-0.2-11.8
	c1.1-2.4,3-3.8,5.8-3.6c2.6,0.1,4.3,1.6,5.1,4C240.8,67.6,240.9,69.4,241.3,71.2z M237.1,71.3c-0.3-1.4-0.4-2.8-0.8-4.2
	c-0.2-0.8-0.8-1.7-1.7-1.5c-0.6,0.1-1.4,0.8-1.6,1.5c-1.1,2.8-1.1,5.6,0,8.4c0.2,0.6,1.1,1.4,1.6,1.4c0.6,0,1.4-0.8,1.6-1.4
	C236.7,74.1,236.8,72.7,237.1,71.3z" />
        <path fill="transparent" d="M221.5,70.8c1.6,0,2.9,0,4.3,0c0,1,0,2,0,3.1c-1.4,0-2.7,0-4.2,0c0,1.6,0,3,0,4.5c-1.1,0-2,0-3.1,0c0-1.4,0-2.9,0-4.5
	c-1.5,0-2.8,0-4.2,0c0-1.1,0-2,0-3.1c1.4,0,2.7,0,4.2,0c0-1.5,0-3,0-4.4c1.1,0,2,0,3.1,0C221.5,67.8,221.5,69.2,221.5,70.8z" />
        <path fill="transparent" d="M61.9,28.6c0,1.3,0,2.5,0,3.7c-2.2,0-4.3,0.2-5.9,2.1c0.8,0,1.6-0.1,2.3,0c4.2,0.5,6.4,4.7,4.8,8.9
	c-0.9,2.2-2.5,3.6-4.9,3.9c-2.6,0.4-4.9-0.3-6.3-2.6c-2.3-3.8-1.9-7.8,0.3-11.5C54.4,29.6,57.9,28.6,61.9,28.6z M54.7,40.3
	c0,2,1.1,3.4,2.6,3.4c1.3,0,2.2-1.2,2.2-2.9c0-1.6-1-2.7-2.4-2.7C55.8,38,54.7,39.1,54.7,40.3z" />
        <path fill="transparent" d="M21.1,45.6c0.4-1.1,0.8-2.2,1.2-3.3c1.2,0.4,2.3,0.9,3.4,1.2c0.6,0.1,1.3,0,1.9-0.1c0.9-0.2,1.5-0.8,1.6-1.7
	c0.1-1-0.3-1.8-1.2-2.1c-0.8-0.3-1.6-0.4-2.4-0.5c-0.5-0.1-1,0-1.6,0c0-1.2,0-2.3,0-3.6c0.6,0,1.3,0.1,1.9,0
	c0.7-0.1,1.4-0.1,1.9-0.5c0.5-0.3,0.9-1.1,0.8-1.6c-0.1-0.4-0.9-1.1-1.3-1c-1,0.1-2.1,0.4-3.1,0.8c-0.4,0.1-0.7,0.4-1.1,0.7
	c-0.4-1-0.8-2-1.1-3c-0.1-0.3,0.2-0.8,0.5-0.9c2.2-1.4,4.5-1.8,7-1.1c3.3,1,4.5,4.7,2.4,7.4c-0.2,0.3-0.4,0.5-0.4,0.5
	c0.7,1.4,1.6,2.6,1.9,3.9c0.5,2.6-1,5-3.5,6C27,47.8,23.5,47.4,21.1,45.6z" />
        <path fill="transparent" d="M36.7,40.5c0-1.1,0-2,0-3c1.4,0,2.7,0,4.2,0c0-1.5,0-2.9,0-4.4c1.1,0,2.1,0,3.2,0c0,1.4,0,2.8,0,4.3c1.5,0,2.8,0,4.2,0
	c0,1,0,2,0,3.1c-1.4,0-2.7,0-4.2,0c0,1.6,0,3,0,4.5c-1.1,0-2,0-3.2,0c0-1.5,0-2.9,0-4.5C39.5,40.5,38.1,40.5,36.7,40.5z" />
        <path fill="transparent" d="M59.2,81.3c0.4-1.2,0.8-2.3,1.2-3.6c0.4,0.2,0.7,0.5,1.1,0.6c1.1,0.3,2.3,0.7,3.5,0.7c1.3,0,2.2-1,2.3-2.1
	c0.1-1.5-0.5-2.4-1.9-2.7c-1.2-0.2-2.5-0.1-3.7-0.1c-0.4,0-0.8,0.1-1.4,0.1c0.4-3.3,0.9-6.5,1.3-9.8c3.2,0,6.3,0,9.5,0
	c0,1.2,0,2.4,0,3.7c-2.1,0-4.1,0-6.2,0c-0.1,0.8-0.2,1.4-0.3,2.1c0.7,0.1,1.3,0.1,1.9,0.2c2.9,0.6,4.8,2.5,5,5.2
	c0.3,2.8-1.2,5.4-3.8,6.5C64.8,83.3,62,82.9,59.2,81.3z" />
        <path fill="transparent" d="M89.7,74c0.4-3.2,0.9-6.4,1.3-9.6c3.2,0,6.3,0,9.5,0c0,1.2,0,2.4,0,3.7c-1.5,0-3.1,0-4.6,0c-1.9,0-2,0.1-1.8,2.1
	c0.6,0.1,1.2,0.1,1.7,0.2c2.7,0.5,4.6,2.4,4.9,4.9c0.4,3-1,5.6-3.6,6.8c-2.6,1.1-5.1,0.9-7.6-0.3c-0.7-0.3-0.9-0.8-0.6-1.5
	c0.3-0.8,0.6-1.6,0.8-2.3c1.4,0.4,2.8,0.9,4.1,1c1.6,0.2,2.8-0.9,2.9-2.2c0.1-1.5-0.7-2.5-2.4-2.7C92.9,73.9,91.4,74,89.7,74z" />
        <path fill="transparent" d="M79,73c0-1.6,0-3,0-4.5c0.9,0,1.7-0.1,2.5,0c0.3,0,0.6,0.6,0.6,0.9c0.1,1.1,0,2.3,0,3.6c1.5,0,2.8,0,4.1,0
	c0.1,0.2,0.2,0.3,0.2,0.3c0.3,2.7,0.3,2.7-2.4,2.7c-0.6,0-1.1,0-1.9,0c0,1.5,0,2.9,0,4.4c-1.1,0-2.1,0-3.1,0c0-1.4,0-2.8,0-4.4
	c-1.1,0-2.2,0-3.2,0c-0.8,0-1.2-0.2-1.1-1.1c0.1-0.6,0-1.3,0-2C76.2,73,77.5,73,79,73z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M327.4,87.4c-1.4,0-2.3-0.9-2.2-2.1c0-1.1,1.1-2.3,2.1-2.3c1.2,0.1,2.5,1.4,2.5,2.5
	C329.7,86.6,328.8,87.4,327.4,87.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M327.5,75.7c1.1,0,1.8,0.7,1.7,1.7c0,0.9-0.9,1.9-1.7,1.8c-1-0.1-1.9-1.1-1.9-2
	C325.7,76.3,326.4,75.7,327.5,75.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M161.7,71.1c0,1.3,0,2.3,0,3.3c-0.7,0-1.4,0-2.4,0C160.1,73.3,160.8,72.3,161.7,71.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M292.7,81.6c0.3-1.5,0.6-3,1.1-4.4c0.2-0.6,1-1.2,1.6-1.2c0.5,0,1.3,0.7,1.5,1.3c1.1,2.9,1,5.9,0,8.8
	c-0.2,0.6-1,1.2-1.5,1.2c-0.5,0-1.4-0.6-1.5-1.2C293.3,84.6,293.1,83.1,292.7,81.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M178.6,33.5c-1.4,0-2.2-0.8-2.2-2.1c0-1.1,1.1-2.3,2-2.3c1.2,0,2.5,1.4,2.5,2.5
	C180.8,32.8,179.9,33.5,178.6,33.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }}
              d="M180.4,23.5c0,0.9-0.9,1.9-1.6,1.9c-0.9,0-2-1-2-1.9c0-1,0.7-1.6,1.8-1.6C179.7,21.8,180.4,22.5,180.4,23.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }}
              d="M373.6,33.2c0,0.9,0,1.9,0,2.9c-0.8,0-1.5,0-2.4,0c0.8-1.1,1.5-2.1,2.2-3.1C373.5,33.1,373.5,33.1,373.6,33.2z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }} d="M437.2,72.4c0.7-1,1.4-1.9,2.1-2.9C440.2,72.3,439.7,73,437.2,72.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1}
              fill={this.state.color25 ? this.state.color25 : 'transparent'} onClick={() => {
          this.setState({ color25: color, opacity25: opacity });
        }} d="M112.2,28.7c-0.3,1.4-0.5,2.9-0.9,4.3c-0.2,0.6-1,1.3-1.6,1.3c-0.5,0-1.4-0.7-1.6-1.3c-1.1-2.8-1.1-5.8,0-8.6
	c0.2-0.6,1.1-1.4,1.7-1.3c0.6,0,1.3,0.8,1.6,1.5C111.8,25.9,112,27.3,112.2,28.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M207.5,73.9c0,1.8-0.7,2.9-1.9,3.1c-1.1,0.1-1.9-0.3-2.4-1.3c-0.7-1.3-0.7-2.8,0-3.6c0.8-0.8,1.8-1.1,2.8-0.6
	C207.3,72,207.5,73,207.5,73.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M237.1,71.3c-0.3,1.4-0.4,2.8-0.9,4.2c-0.2,0.6-1.1,1.4-1.6,1.4c-0.6,0-1.4-0.8-1.6-1.4c-1.1-2.8-1.1-5.6,0-8.4
	c0.2-0.6,1-1.3,1.6-1.5c0.9-0.2,1.5,0.7,1.7,1.5C236.7,68.5,236.8,69.9,237.1,71.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity30 ? this.state.opacity30 : 1}
              fill={this.state.color30 ? this.state.color30 : 'transparent'} onClick={() => {
          this.setState({ color30: color, opacity30: opacity });
        }} d="M54.7,40.3c0-1.2,1.1-2.3,2.4-2.3c1.4,0,2.4,1.1,2.4,2.7c0,1.7-0.9,2.9-2.2,2.9C55.8,43.7,54.7,42.3,54.7,40.3z" />
      </SvgIcon>
    )
  }
};