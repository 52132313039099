import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_7_1 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 434.4 525.6"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M332.6,413.7c2.6,1.9,4.5,4.2,7.1,5.6c0.1-0.1,0.2-0.2,0.4-0.3c-0.4-0.6-0.6-1.3-1.1-1.8
	c-2.9-2.9-5.9-5.7-9.4-8c-1.6-1.1-2.9-2.6-3.7-4.4c-0.8-1.8-1.8-3.5-1.5-5.5c0.3-1.8,0.7-3.6,1-5.5c0.4-2.6,0.9-5.3,1.1-7.9
	c0.1-1.6-0.1-3.2-0.2-4.8c-0.1-2.3-0.1-4.5-0.3-6.8c-0.2-2-0.6-3.9-1-5.9c-0.7-3.8-1.6-7.7-2.3-11.5c-0.3-1.9-0.3-3.9-0.5-5.9
	c-0.3-2.6-0.7-5.2-0.9-7.9c-0.2-2-0.1-4-0.1-6c-0.1-4,0.9-7.8,2.1-11.5c0.4-1.1-0.4-2-2.1-2.2c-0.6,2.8-1.2,5.7-1.9,8.5
	c-0.1,0.3-0.7,0.6-1,0.5c-2.5-0.6-5-1.3-7.4-2.1c-0.8-0.3-1.4-1.1-2.2-1.7c-0.6-0.5-1.3-1-1.9-1.4c-0.2-0.1-0.6,0-0.8,0.2
	c-0.1,0.1,0,0.6,0.1,0.7c1.5,1.3,3,2.7,4.6,3.8c2.3,1.5,4.9,2,7.6,1.9c0.2,0,0.4,0,0.8,0c0,0.6,0,1.1,0,1.6
	c-0.2,4.9,0.5,9.7,0.9,14.6c0.2,2.2,0.3,4.5,0.5,6.7c0.3,2.3,0.7,4.5,1.1,6.7c0.7,4,1.5,7.9,2.2,11.9c0.1,0.8-0.1,1.6-0.1,2.4
	c0,0.9,0,1.7,0.2,2.6c0.5,2.7,0.2,5.3-0.4,7.9c-0.7,3.3-1.5,6.5-1.7,9.8c-0.3,3.6,1.4,6.8,3.8,9.4c0.9,1,2,1.6,2.9,2.6
	c0.5,0.5,0.9,1.3,1.1,2c0.5,2.5,0.8,5,1.2,7.6c0.6,3.4-0.2,6.6-0.6,10c-0.4,3.3-1.8,6.3-2.4,9.6c-0.8,3.7-1.4,7.5-2,11.2
	c-0.4,2.4-0.7,4.7-0.9,7.1c-0.1,1,0.2,2,0.1,2.9c0,0.5-0.3,1.1-0.7,1.5c-2.1,2.4-4.6,4.4-5.7,7.5c-3-0.3-6-0.6-8.5-2.2
	c-2.9-1.8-5.6-3.8-8.5-5.6c-3.4-2-6.9-3.6-10.9-3.9c-1.7-0.1-3.4-0.6-5-0.9c0.5-1.6,0.9-3,1.4-4.5c0.1-0.3,0.4-0.6,0.6-0.9
	c0.8-1,1.7-1.8,2.3-3c1.3-2.4,4-2.3,6.8-0.7c2.2,1.4,3.9,2.1,5.7,2.1c-0.2-0.4-0.2-0.8-0.5-1c-1.8-1.1-3.6-2.3-5.5-3.1
	c-1.6-0.7-3.3-1-5.1-1.6c-0.6-3.6-0.9-7.4-0.1-11.2c0.3-1.4,0.1-2.8,0.3-4.2c0.4-2.7,0.8-5.4,1.2-8.2c0.5-3.1,1-6.3,1.4-9.4
	c0.4-3,0.7-6,0.9-9.1c0.1-0.6-0.2-1.3-0.2-2c1.3,1.6,2.1,3.7,4.4,4.2c-0.5-1.3-1.2-2.4-1.5-3.6c-0.5-1.9-1.3-3.4-2.6-4.9
	c-2.1-2.2-3.9-4.7-5.8-7.1c-1.1-1.6-1.6-3.4-0.8-5.3c1.1-2.6,1.5-5.3,2.1-8c0.3-1.2,0.1-2.6-0.2-3.9c-0.6-2.9-1.3-5.7-1.9-8.6
	c-0.3-1.1-0.7-2.1-0.9-3.2c-0.2-1.1-0.1-2.3-0.1-3.4c-0.1-1.3-0.2-2.6-0.3-3.9c0-0.2-0.1-0.4-0.1-0.6c0.1-4,0.3-7.9,0.4-11.9
	c0-1-0.1-1.9,0-2.9c0.2-2.3,0.4-4.7,0.9-7c0.5-2.9,1.3-5.7,1.9-8.6c0.2-0.9,0.2-1.9,0.3-2.8c0.2-2.6,0.4-5.2,0.7-7.8
	c0.1-1.2-0.8-1.1-1.4-1.3c-0.7-0.2-0.9,0.3-0.9,1c0,1.3-0.2,2.7-0.3,4.1c-0.1-0.1-0.3-0.1-0.4-0.2c-0.7-2.4-1.4-4.7-2-7.1
	c-0.8-3.2-0.7-6.4,0-9.5c0.2-0.8,0.7-0.7,1.3-0.4c1.6,0.6,3.3,1.3,4.9,1.8c3.5,1.1,6.9,2,10.6,2.4c2.9,0.3,5.7,0.9,8.6,1.4
	c1.3,0.2,2.5,0.2,3.8,0.3c4,0.2,8.1,0.5,12.1,0.4c3.8-0.1,7.6-0.7,11.4-1.1c1.9-0.2,3.9-0.2,5.8-0.5c4.3-0.6,8.7-1.1,12.9-2.1
	c3.5-0.8,6.9-2.1,10.3-3.2c1.7-0.5,1.9-0.5,2.3,1.3c0.6,2.3,1.2,4.6,1.5,6.9c0.2,1.9,0,3.8-0.1,5.7c0,0.5-0.1,0.9,0,1.4
	c0.9,3.2-0.5,6-1.4,8.9c-0.5,1.5-0.8,3-1.2,4.4c-0.1,0.2-0.1,0.5-0.2,0.5c-1.1,0.6-0.7,1.7-0.8,2.6c-0.2,2.1-0.4,4.3-0.4,6.4
	c0,1,0.3,2,0.2,2.9c-0.1,2.1-0.4,4.3-0.5,6.4c-0.1,1-0.1,1.9-0.2,2.9c0,4.2-0.1,8.4,0,12.6c0.1,3.9-0.6,7.7-1.5,11.4
	c-0.9,3.4-2.2,6.7-3.2,10.1c-0.7,2.4-0.4,4.9-0.3,7.4c0,0.3,0.7,0.5,1.1,0.8c0.7-3,1.3-5.6,1.8-8.3c0.2,0,0.3,0,0.5,0
	c0.3,1.6,0.7,3.2,0.9,4.9c0.2,1.2,0.2,2.4,0.2,3.6c0,0.7-0.3,1.4-0.6,2c-1,2-2.1,4-3.2,5.9c-0.3,0.6-1.1,0.9-1.5,1.4
	c-1.2,1.6-1.4,3.5-0.8,5.4c0.3,0.8,0.6,1.6,1.1,2.3c0.2,0.3,0.6,0.4,1.1,0.6c0.4-1.7-1.2-3.3,0.2-4.8c1.1-1.1,1.2-3,2.8-4
	c1.5,3.8,2.2,7.4,1.2,11.2c-0.9-0.5-1.6-0.9-2.4-1.3c-0.1,0.1-0.2,0.2-0.3,0.4c0.7,1.9,1.4,3.8,2.2,5.7c1.1,2.5,2.3,5,3.3,7.6
	c1.3,3.1,1.8,6.4,2.2,9.7c0.2,2,0.3,3.9,0.5,5.9c0.1,1.3,0.4,2.7,0.4,4c-0.1,3.4,0.2,6.7-0.8,10.1c-1.1,4-1.9,8-2.7,12.1
	c-0.3,1.3-0.2,2.8,0,4.2c0.1,0.6,0.7,1.3,1.3,1.5c1.1,0.4,1.1,1.2,1.1,2.1c-0.1,1.6-0.3,3.1-0.2,4.7c0.2,1.7-1.2,2.4-1.9,3.5
	c-1,1.4-2.4,1.8-4.1,1.9c-4.4,0.1-8.5-0.9-12.7-2c-3.5-1-6.7-2.8-9.9-4.7c-2.6-1.6-5.6-2.6-8.8-2.9c-1.6-0.2-3.1-0.4-4.7-0.5
	c-3.1-0.2-5.9,0.5-8.6,2c-0.4,0.2-1,0.3-1.3,0.1c-0.7-0.3-0.5-0.9-0.1-1.5c0.8-1.5,1.4-3.3,2.5-4.6c1.3-1.6,2.9-3,4.4-4.4
	c0.3-0.3,0.7-0.5,0.9-0.8c1.5-1.9,3-3.7,4.5-5.6c0.2-0.3,0.2-0.8,0.3-1.2c-2.1,0-3.2,1.6-4.6,2.7c-0.1,0-0.2-0.1-0.3-0.1
	c0.1-1.4,0.1-2.7,0.3-4.1c0.3-2.1,0.5-4.1,1-6.2c0.8-3.6,1.8-7.2,2.7-10.8c0.7-3,1.6-5.9,2-8.9c0.5-4-0.1-8.1-0.4-12.2
	C332.9,415.6,332.7,414.8,332.6,413.7z M304.9,304.1c0.4,4.2,0.7,8.3,1.2,12.4c0.3,2.6,1,5.2,3.2,7.1c0.4,0.3,1,0.5,1.6,0.5
	c1.2,0,2.5-0.4,3.7-0.3c1.7,0.1,3-1,4.4-1.5c0.3-0.1,0.5-0.7,0.5-1.1c0.1-1.1,0.2-2.1-0.4-3.2c-0.5-0.9-0.4-2.1-0.5-3.1
	c-0.1-1.3,0.1-2.7-0.4-3.7c-0.5-1-0.3-1.8-0.1-2.7c0.1-0.4,0.2-0.8,0.3-1.2c0.2-1.1-0.3-1.7-1.5-1.8c-0.1,1.2-0.4,2.4-0.3,3.6
	c0.2,2.5,0.6,5.1,0.9,7.6c0.1,0.8,0.4,1.5,0.3,2.3c0,1.5-1.3,2.3-2.1,3.3c-0.2,0.2-0.7,0.3-1.1,0.3c-1.1,0-2.3-0.3-3.4-0.2
	c-1.3,0.1-2-0.4-2.4-1.6c-0.8-3-1.2-6.1-1.2-9.3c0-1.4-0.4-2.7-0.7-4.1c-0.1-0.6-0.4-1.1-0.4-1.6
	C306.4,304.7,305.8,304.1,304.9,304.1z" />
        <path fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1} stroke={borderColor} fill={this.state.color2 ? this.state.color2 : 'transparent'}
              onClick={() => {
                this.setState({ color2: color, opacity2: opacity });
              }} d="M407.1,212.6c-1.7-0.4-3.4-1.1-5-1.1c-2.9,0-5.8,0.5-8.7,0.8c-2,0.2-3.8,0.9-5.5,2.2c-0.9,0.7-2.4,0.5-3.6,0.8
	c-4.1,0.9-7.9,2.3-11.4,4.7c-0.5,0.4-1.2,0.6-2,1c-0.6-2.8-1.1-5.4-1.7-8c-0.4-2.1-0.6-4.3-1.3-6.3c-1-2.9-1.4-6-3.1-8.6
	c-0.6-0.9-0.7-2-1.2-3c-1.2-2.4-3.2-4.3-5.2-6c-1.2-1-2.8-1.7-4.1-2.5c-0.1,0.1-0.3,0.3-0.4,0.4c0.2,0.3,0.3,0.8,0.6,1
	c1.3,1.1,2.7,2.1,4,3.2c1.7,1.5,2.7,3.5,3.6,5.5c1.4,2.9,2.2,6,2.9,9.2c0.5,2.7,0.9,5.5,1.6,8.2c0.5,2.1,1.2,4,1.8,6.1
	c0.2,0.7,0.5,1.4,0.5,2.2c0,0.4-0.2,1-0.6,1.3c-3.1,2.5-6.1,5-9.4,7.3c0.1-1.6,0.2-3,1-4.4c1-1.7,1.7-3.7,2.5-5.5
	c0.5-1.2,0.7-2.3,0.3-3.7c-0.4-1.4-1.3-1.6-2.3-1.8c-1.8-0.5-3.7-0.4-5.5,0.3c-1.9,0.8-3.5,2-4.7,3.7c-1.8,2.8-2.5,6-3.1,9.3
	c-0.1,0.4-0.4,0.9-0.7,1.2c-1.1,1.1-2.5,2-3.4,3.3c-1.2,1.5-3,1.7-4.5,2.7c-1.9,1.2-3.7,0.7-5.6,0.4c-0.8-0.1-1.6-0.2-2.3-0.3
	c-2.2-0.5-3.4-2.3-4.7-3.8c-2.4-2.6-5.2-4.3-8.8-4.7c-1.7-0.2-2.7,0.6-3.8,1.5c-0.4,0.4-1,0.6-1.2,1.1c-0.3,0.6-0.5,1.5-0.3,2.1
	c0.4,1.2,1.1,2.2,1.7,3.4c-1.6-0.3-3.1-0.7-4.7-0.8c-1.2-0.1-2.4,0.2-3.5,0.5c-0.4,0.1-0.9,0.8-1,1.3c-0.2,1.6,0,3.1,1,4.5
	c0.7,0.9,1,2.2,1.2,3.4c0.3,1.8,1.6,2.9,2.7,4.2c1.5,1.7,3.6,2.2,5.4,3.2c2.1,1.2,4,2.8,6.2,3.9c2.2,1.1,4.6,1.9,7.1,2.5
	c2.7,0.7,5.6,1,8.3,1.5c0.9,0.2,1.9,0.2,2.8,0.2c1.4,0,2.9-0.3,4.3-0.2c2.7,0.3,5.1-0.6,7.5-1.6c2.9-1.1,5.2-3.1,7.5-5.1
	c2.1-1.8,4.2-3.4,6.7-4.5c0.6-0.3,1.4-0.4,2.1-0.4c0.2,0,0.6,0.6,0.6,1c-0.1,2.6-0.2,5.3-0.5,7.9c-0.3,2.8,0.4,5.4,1,8.1
	c0.5,2.5,0.9,5,1.5,7.4c0.5,1.9,1.2,3.8,1.7,5.8c0.8,3.2,1.6,6.4,2.4,9.6c0.4,1.6,0.9,3.2,1.3,4.9c-4.9,1.6-9.6,3.3-14.4,4.7
	c-3.5,1-7.2,1.7-10.8,2.5c-1.8,0.4-3.7,0.5-5.6,0.7c-2.6,0.3-5.2,0.6-7.9,0.9c-1.8,0.2-3.6,0.4-5.4,0.5c-2.6,0.2-5.1,0.5-7.7,0.4
	c-2.2,0-4.4-0.4-6.6-0.6c-0.8-0.1-1.6,0.1-2.3,0c-2.6-0.3-5.3-0.6-7.9-1c-2.9-0.4-5.8-0.8-8.6-1.5c-2.7-0.6-5.3-1.6-7.9-2.5
	c-1.4-0.5-2.7-1.5-4-2.3c-0.4-0.2-0.9-0.4-1.5-0.7c0.1-0.3,0.1-0.7,0.2-1.1c0.9-3.3,1.9-6.5,2.6-9.9c0.5-2.3,0.4-4.7,0.5-7.1
	c0.1-1.8,0.3-3.7,0.2-5.5c0-2-0.3-3.9-0.5-5.9c-0.4-4-0.8-8-1.2-11.9c-0.3-3-0.5-5.9-0.7-8.9c-0.2-2.4-0.4-4.8-0.5-7.2
	c0-1.4,0.2-2.7,0.2-4.1c0-1.3-0.1-2.6-0.1-4c0-1.5,0.1-2.9,0.2-4.4c0.1-1,0.3-1.9,0.4-2.9c0.4-3.2,0.9-6.3,1.3-9.5
	c0.5-3.2,1-6.4,1.6-9.5c0.4-2,0.9-4,1.5-6c0.9-3,1.8-5.9,2.7-8.8c0.2-0.8,0.4-1.6,0.5-2.4c0.2-0.8-0.4-1.1-1-1.3
	c-0.7-0.2-0.8,0.3-1,0.8c-1.3,3.9-2.8,7.7-3.8,11.6c-1,3.5-1.9,7.1-2.2,10.8c-0.2,2.6-1.1,5.2-1.7,7.8c-0.2,0.8-0.3,1.5-0.4,2.3
	c-0.1,1.1-0.5,1.4-1.5,1c-1.1-0.4-2.2-0.9-3.3-1c-1.2-0.1-1.5-0.9-1.4-1.8c0-0.9,0.2-1.8,0.4-2.6c0.5-2.2,1.1-4.4,1.7-6.6
	c0.5-2.2,0.9-4.5,1.6-6.6c0.8-2.5,1.8-4.8,2.6-7.3c0.4-1.1,0.5-2.3,0.9-3.4c0.6-1.7,1.5-3.3,2-5c0.6-2,1-4.1,1.6-6.1
	c1.2-4.2,3.5-7.8,6.4-11c1.1-1.2,2.5-2,3.7-3c0.8-0.7,1.6-1.5,2.5-2.1c1-0.7,2.1-1.2,3.1-1.8c2.6-1.5,5.2-2.9,7.9-4.4
	c1.5-0.8,3-1.7,4.5-2.5c0.1,0.1,0.2,0.1,0.3,0.2c-0.2,0.4-0.4,0.9-0.7,1.2c-2.5,1.9-3.9,4.6-4.3,7.5c-0.3,2.5,1,4.7,2.9,6.5
	c3.7,3.4,7.9,3.4,12.4,2.1c2.8-0.8,5.4-2,7.8-3.6c2.5-1.6,5.1-3.1,6.9-5.5c0.2-0.3,0.5-0.5,0.8-0.7c2.6-1.4,4.6-3.4,6.3-5.6
	c1.2-1.6,2.3-3.5,2.9-5.4c0.5-1.6,0.1-3.4-0.9-4.9c-0.3-0.4-0.5-0.8-0.6-1.4c1.2,0.4,2.4,0.6,3.5,1.1c5.1,2.2,10.2,4.3,15.2,6.8
	c5.6,2.7,10.8,6.3,14.9,11.1c2.5,2.9,4.6,6.2,5.6,10c0.8,3.2,2,6.3,3.1,9.4c1.3,3.6,2.7,7.3,4.3,10.8c1.6,3.7,3.6,7.3,5.2,11
	c1.3,3.2,3.4,5.8,5.1,8.8C406.8,211.9,407,212.3,407.1,212.6z" />
        <path fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1} stroke={borderColor} fill={this.state.color3 ? this.state.color3 : 'transparent'}
              onClick={() => {
                this.setState({ color3: color, opacity3: opacity });
              }} d="M141,394.8c1.4,0.6,3.8,0.3,5.9,0c-0.6,0-1.2-0.1-1.9-0.1C143.7,394.7,142.3,394.7,141,394.8
	C141,394.8,141,394.8,141,394.8z" />
        <path fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1} stroke={borderColor} fill={this.state.color4 ? this.state.color4 : 'transparent'}
              onClick={() => {
                this.setState({ color4: color, opacity4: opacity });
              }} d="M86.5,235.4c-1.9-0.6-3.6-0.9-4.5-3.2c-1.1-2.9-1.8-5.8-2.8-8.7c-0.7-1.9-1.6-3.7-2.3-5.5c-1-2.6-1.9-5.2-3-7.7
	c-1.4-3.1-2.8-6.3-4.6-9.2c-2.1-3.3-5.1-6-8.6-8c-0.7-0.4-1.6-0.4-2.4-0.5c-0.2,0-0.5,0.2-0.6,0.4c0,0.1,0.2,0.4,0.3,0.5
	c1.3,0.9,2.6,1.7,3.8,2.6c2.6,2,4.7,4.5,6.1,7.4c1.5,3.1,3.2,6.1,4.4,9.2c2.1,5.4,4,10.8,6,16.2c0.7,1.8,1.2,3.7,2.3,5.3
	c1.1,1.7,3.1,2.4,5,2.9c2.7,0.6,5-0.3,7.4-1.5c0.5-0.2,0.9-0.5,1.4-0.8c0.7,1.2,1.2,2.5,2.1,3.5c1.7,2,3.6,3.9,5.6,5.6
	c1.2,1,2.6,2,4,2.4c1.4,0.4,3.1,0.4,4.6,0.1c1.5-0.3,2.9-1,4.4-1.6c0.3-0.1,0.7-0.3,0.9-0.2c0.9,0.8,1.8,0,2.6-0.1
	c0.7,0,1.5-0.3,2.2-0.4c0.8-0.1,0.9,0.4,0.9,1c-0.1,3.2-0.1,6.3-0.3,9.5c-0.2,3.6-0.3,7.2-1.4,10.6c-0.6,1.9-1.5,3.5-3.2,4.8
	c-1.7,1.4-2.9,3.3-4.3,5c-0.2,0.2-0.2,0.6-0.2,0.9c0.1,0.1,0.2,0.2,0.3,0.3c0.6-0.5,1.3-1,1.9-1.5c1-0.8,1.9-1.8,3-2.2
	c3.3-1.1,3.9-4,4.9-6.7c0.2-0.5,0.2-1,0.4-1.6c1.4,1.4,2.7,2.8,4,4.2c-0.9,0.7-2,1.6-3,2.5c-2,1.8-3.8,3.8-5.9,5.6
	c-2.9,2.5-6.2,4.6-9.8,6.1c-1,0.4-2.1,0.4-3.1,0.6c-0.4,0.1-0.7,0.3-1,0.5c0.7,0.3,1.1,0.6,1.6,0.6c0.7,0,1.5,0,2.2-0.2
	c1.7-0.5,3.4-0.8,4.9-1.6c2.9-1.7,5.8-3.6,8.5-5.6c2-1.6,3.8-3.5,5.7-5.3c0.7-0.6,1.4-1.1,2.1-1.6c0.2-0.1,0.6-0.1,0.8,0
	c1.3,0.6,2.5,1.3,3.6,1.9c-1.9,0.9-4,1.7-5.9,2.9c-4.3,2.7-8.6,5.5-12.7,8.5c-4,3-8.4,5.2-13,7c-1.7,0.7-3.6,1-5.4,1.3
	c-3.9,0.8-7.8,1.2-11.8,1c-2.4-0.1-4.7-0.5-7.1-0.8c-3.1-0.4-6.1-0.7-9.2-1.1c-3-0.4-6.1-0.9-9.1-1.6c-2.3-0.5-4.4-1.3-6.6-2
	c-0.8-0.3-1.8-0.5-1.9-1.7c0-0.1-0.2-0.2-0.3-0.3c-1.6-1.2-1.8-2.2-1.2-4.2c0.5-1.5,0.7-3.1,1.1-4.6c0.1-0.5,0.3-1.1,0.4-1.6
	c0.2-0.9,0.4-1.8,0.6-2.8c0.3-2.4,0.5-4.9,0.7-7.3c0.2-2.4,0.5-4.7,0.7-7.1c0.3-2.9,0.6-5.8,0.7-8.6c0.2-3.3,0.2-6.7,0.2-10
	c0-1.6-0.1-3.2-0.2-4.8c-0.1-2.2-0.1-4.3-0.3-6.5c-0.2-2.8-0.6-5.5-0.9-8.2c-0.1-1.1-0.1-2.2-0.3-3.3c-0.4-2.6-0.8-5.2-1.2-7.8
	c-0.3-2.3-0.5-4.7-0.8-7.1c-0.2-1.7-0.5-3.3-0.7-5c-0.2-1.5-0.3-3.1-0.3-4.7c0-3.4-0.1-6.8,0.3-10.2c0.5-4.1,1-8.2,2.5-12.2
	c1.2-3.3,2.2-6.7,3.5-10c1.8-4.4,3.3-9,6-13c1.1-1.6,1.9-3.5,2.7-5.3c0.6-1.4,1.2-1.9,2.7,0c0.8-1,1.5-1.9,2.1-2.8
	c2.2,1.6,4.3,3.4,6.6,4.7c2,1.1,4.4,1.6,6.6,2.4c2.3,0.8,4.6,1.3,6.8,2.2c2.3,0.9,4.5,1.9,6.7,3c3.2,1.5,5.9,3.7,8.5,6
	c1.1,1,2,2.4,3.7,2.7c0.2,0,0.4,0.2,0.5,0.4c1.5,3.4,3.1,6.7,4.6,10.1c1.2,2.7,2.3,5.4,3.3,8.1c1.1,3,1.8,6.1,2.9,9
	c1.6,4.1,1.3,8.6,2.3,12.8c0.5,2,0.3,4.2,0.4,6.2c-0.1,0.1-0.3,0.1-0.4,0.2c-1.5-1.9-3.2-3.6-4.6-5.6c-1.8-2.4-3.5-4.8-5.1-7.4
	c-1-1.5-1.7-3.2-2.5-4.7c-0.2-0.3-0.4-0.7-0.7-1c-1.2-1.6-1.7-3.3-2-5.3c-0.2-1.8-0.9-3.5-1.5-5.3c-0.1-0.4-0.9-0.5-1.7-0.9
	c0.5,2,0.9,3.6,1.3,5.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4-0.5-0.9-1-1.3-1.5c-0.2-0.3-0.4-0.6-0.6-0.9c-1.5-1.5-2.2-3.4-2.8-5.4
	c-0.4-1.3-1.3-2.4-1.6-3.7c-0.3-1.3-1-1.3-2.1-1.1c0.7,2,1.4,3.9,2,5.8c1.1,3.2,3.1,5.8,5.1,8.5c1.5,2,2.9,4.2,4.2,6.4
	c1.6,2.4,3,5,4.6,7.4c2,2.8,4.1,5.5,6.2,8.2c1,1.3,2.3,2.4,3.4,3.6c-1.2,0.2-2.4,0.2-3.5,0.7c-3,1.2-6,2.6-8.9,4.1
	c-2.1,1.1-4.2,2.4-6.1,3.8c-1.4,1-2.6,2.4-3.8,3.7c-2.4,2.3-4.8,4.5-7.1,6.9c-1.9,2-3.5,4.2-5.2,6.3
	C86.9,234.5,86.8,234.9,86.5,235.4z" />
        <path fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1} stroke={borderColor} fill={this.state.color5 ? this.state.color5 : 'transparent'}
              onClick={() => {
                this.setState({ color5: color, opacity5: opacity });
              }} d="M116.6,305c0.2-0.5,0.4-1.1,0.6-1.6c0.1-0.4,0.3-0.7,0.3-1.1c0-0.8,0.2-1.4,0.7-2.1c0.5-0.8,0.8-1.9,0.9-2.9
	c0-0.5-0.6-1-1-1.6c-1.9,3.6-2.7,7.3-3.5,11c-0.8,3.7-0.5,7.4-0.7,11.1c-0.1,1.6-0.2,3.3-0.2,4.9c0,2.9,0.3,5.9,0.2,8.8
	c-0.1,3-0.5,6-0.9,8.9c-0.2,1.2-0.8,2.3-1.4,3.5c-0.1,0.3-0.5,0.6-0.8,0.7c-4.3,1.7-8.6,3.4-13,5c-2.1,0.8-4.4,1.2-6.6,1.6
	c-3,0.6-6,1.2-9.1,1.4c-3.6,0.3-7.3,0.5-10.9,0.5c-3.3,0-6.6,0-9.9-0.5c-3.6-0.6-7.4-1.1-10.5-3.5c-0.9-0.7-1.3-1.3-1-2.6
	c0.5-3.1,0.9-6.4,0.8-9.6c-0.1-3.3-0.6-6.6-1.1-9.9c-0.6-3.8-1.3-7.6-1.9-11.4c-0.3-1.9-0.7-3.8-0.7-5.8c0.1-3.4,0.4-6.8,0.6-10.2
	c0.2-2.1,0.4-4.2,0.8-6.3c0.1-0.9,0.6-1.7,1-2.5c0.3-0.7,0.6-1.3,1-1.9c0.2-0.2,0.7-0.5,1-0.4c4.6,1.7,9.3,2.9,14.2,3.4
	c4.7,0.5,9.4,1.4,14.1,1.8c4,0.4,8,0.6,11.9,0.4c3.1-0.1,6.2-0.9,9.3-1.4c4.3-0.7,8.1-2.7,11.8-4.7c2-1.1,3.8-2.6,5.7-4
	c2.2-1.5,4.5-3.1,6.8-4.5c2.7-1.7,5.4-3.4,8.1-5.1c1.7-1.1,3.4-0.9,5.4-0.4c3.1,0.8,6.4,1.2,9.6,1.6c1.8,0.2,3.6,0.4,5.3,0.2
	c2.7-0.2,5.4-0.6,8.2-1c0.1,0.5,0.2,1.1,0.3,1.7c0.4,4.9-1,9.5-2.4,14.1c-0.9,3.1-2.5,5.7-4.3,8.2c-1.8,2.5-3.7,4.8-5.6,7.2
	c-0.7,0.9-1.6,1.7-2.3,2.6c-1,1.3-2.8,1.8-4.4,1.1c-1.3-0.6-2.6-1.4-3.9-2c-0.8-0.4-1.7-0.7-2.7-1c1.3-0.8,3.2,0,4.5-1.7
	c-1.2-0.2-2.1-0.6-3-0.6c-1.4,0.1-2.8,0.3-4.2,0.6c-3.2,0.8-6.3,1.6-9.4,2.5c-2.2,0.6-4.3,1.6-6.1,3.1c-0.3,0.3-0.8,0.5-1.2,0.7
	c-0.1-0.1-0.1-0.2-0.1-0.3c1.2-2,2.3-4.1,3.7-6c1.3-1.7,2.9-3.3,4.5-4.7c1.8-1.6,3.8-3.1,6.2-3.6c0.6-0.1,1.2-0.2,1.7-0.4
	c0.2-0.1,0.4-0.4,0.6-0.6c-0.2-0.2-0.4-0.5-0.6-0.5c-3-0.7-5.6,0.2-8.2,1.8c-2.5,1.6-4.5,3.7-6.2,6c-0.5,0.6-1,1.2-1.5,1.8
	C116.9,305.2,116.7,305.1,116.6,305z" />
        <path fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1} stroke={borderColor} fill={this.state.color6 ? this.state.color6 : 'transparent'}
              onClick={() => {
                this.setState({ color6: color, opacity6: opacity });
              }} d="M61.4,108.9c-1.4-3.3-2.8-6.6-4.2-9.9c-1-2.4-2-4.7-2.8-7.2c-0.4-1.4-0.5-2.9-0.6-4.3c-0.1-1.4-0.1-2.9,0-4.3
	c0.1-1.5,0.3-3.1,0.8-4.5c0.3-1,1.2-1.8,1.7-2.8c0.3-0.5,0.2-1.2,0.2-1.8c-0.5,0-1.1-0.2-1.6-0.1c-1.6,0.5-3.2,1.2-4.8,1.8
	c0.5-1.1,0.6-2.2,1.8-2.9c0.7-0.4,1-1.3,1.5-2c0.5-0.7,1-1.3,1.4-2c0.2-0.5,0.1-1.1,0.2-1.7c-0.5,0-0.9-0.1-1.4-0.1
	c-1.4,0-2.3,0.8-3.2,1.7c-0.1,0.1-0.3,0-0.2,0c0.3-1.6,0.4-3.2,1-4.7c0.6-1.6,1.3-3.3,2.4-4.5c1.3-1.4,3.2-2.3,4.9-3.2
	c0.8-0.5,1.3-1,1.1-1.8c-0.1-0.9-1-0.9-1.7-0.8c-1.3,0.1-2.5,0.3-3.8,0.4c-0.1-0.1-0.1-0.2-0.2-0.4c1.7-1.1,3.4-2.2,5.2-3.3
	c1.1-0.7,1.6-1.4,0.8-2.4c-1.6-2.2-0.8-4.4-0.3-6.7c0.1-0.5,0.3-0.9,0.4-1.5c0.5,1.3,0.9,2.5,1.4,3.6c0.3,0.7,0.8,1.4,1.2,2.2
	c0.2,0.4,0.1,1,0.3,1.5c0.3,0.9,0.5,1.9,0.9,2.7c0.1,0.2,0.6,0.3,0.9,0.4c0.1-0.4,0.4-0.7,0.3-1c-0.9-4.1,0.5-7.8,2.1-11.4
	c0-0.1,0.1-0.1,0.2-0.1c0.3,0.9,0.5,1.9,0.9,2.7c0.2,0.3,0.7,0.6,1.1,0.7c0.2,0,0.6-0.4,0.7-0.7c0.9-1.5,1.9-3,2.5-4.6
	c0.8-2.3,3.2-2.5,4.7-3.9c-1.2,1.9-1.1,3.8-0.7,5.8c0.1,0.8,0.6,0.9,1.2,0.8c1.6-0.3,3.1-0.9,4.3-2c1.1-1,2.3-1.5,3.7-1.8
	c1.5-0.3,2.9-1.1,4.4-1.5c2.2-0.6,4.2-1.6,6.6-1.3c2.9,0.4,5.9,0.5,8.6,1.9c1.5,0.7,3,1.4,4.4,2.1c0.7,0.3,1.2,0.9,1.9,1.2
	c0.7,0.3,1.4,0.7,2.2,0.8c6,0.2,11.1,2.4,15.2,6.9c1.6,1.7,3.3,3.4,4.9,5.2c2.1,2.6,3.3,5.5,3.5,8.9c0.2,3.1-0.2,6-1.1,9
	c-0.4,1.3-1.1,2.6-1.7,4c0-2.5,0.2-4.8-0.1-7c-0.2-2.3-1.4-4.3-3.4-6.3c-0.1,0.6-0.2,1.1-0.1,1.4c0.4,0.9,0.9,1.8,1.3,2.8
	c0.4,0.9,0.2,1.7-0.2,2.7c-0.9,2.1-2.6,3.6-4,5.3c-1.2,1.4-2.5,2.8-3.7,4.2c-0.3,0.3-0.6,0.6-0.9,0.9c0.3-1.2,0.6-2.4,0.9-3.5
	c-1.7-0.6-2.2,0.7-2.8,1.6c-2,2.6-3.7,5.2-5.8,7.7c-0.9,1.1-2.2,1.9-3.5,2.7c0.5-1.7,1.1-3.4,1.6-5.1c0.9-3.5,2.1-6.9,2.6-10.5
	c0.4-2.9-0.1-5.9-0.2-8.8c0-0.4-0.1-0.9-0.1-1.3c-0.1-1.1-0.5-2-1.9-2.2c0.2,1.3,0.3,2.6,0.5,3.8c0.4,3.7,0,7.4-0.9,11
	c-0.6,2.3-1.1,4.6-1.8,6.8c-0.2,0.7-1.2,1.1-2.1,1.7c-0.1-3.5,1.9-6.8,0.1-10c-0.2,0-0.4,0-0.7-0.1c-0.4,1.8-0.7,3.6-1.2,5.3
	c-0.4,1.4-1,2.6-1.5,4c-0.9,2.7-3.2,3.9-5.4,5.3c-1.1,0.7-2.3,1.4-3.5,1.8c-2.1,0.7-2.8,0.3-4.1-1.5c-1.5-2.1-3-4.1-4.7-6
	c-1.3-1.4-3.2-1.8-5.1-1.9c-2.2-0.2-4.3,0.2-6,1.6c-0.8,0.7-1.7,1.5-2,2.4c-1.1,2.6-1.4,5.5-1.2,8.3c0.1,2.3,1.4,4.2,2.9,5.9
	c0.9,1,1.8,2,2.8,3.2c-0.3,0.9-0.7,2.2-1,3.6c-0.4,2.4,0.7,4.4,2.4,6.1c0.2,0.2,0.4,0.6,0.5,1c0.1,0.8,0.2,1.6,0.3,2.4
	c0.2,1.7,0.4,3.3,0.7,5.2c-0.7-0.7-1.5-1.3-2-2.1c-1.3-1.9-2.6-3.8-3.1-6.2c-0.1-0.7-0.9-1.2-1.4-1.9c-0.2,0.1-0.3,0.1-0.5,0.2
	c0.1,0.8,0.2,1.6,0.3,2.4c0.2,1.7,0.6,3.3,0.7,5c0.2,3.2,1.2,6.1,2.9,8.7c0.6,0.9,1.4,1.6,2.1,2.5c-2.1-0.4-3.6-1.7-4.9-3.2
	c-0.7-0.8-1.6-1.5-1.8-2.8c-0.1-0.6-1.2-1.1-1.9-1.6c-0.2,0.3-0.6,0.6-0.6,0.8c0,1.7,0,3.4,0.2,5c0.1,0.9,0.6,1.8,0.9,2.8
	c-1.7-1.4-3.1-2.7-3.7-4.8c-0.3-1.1-0.8-2-2.2-2.2c-0.3,1.3,0.5,2.3,0.9,3.3c1,2.6,2.3,5.2,1.4,8.2c-0.3,1-0.8,2-1.3,2.9
	c-0.9,1.5-1.9,3-3,4.8c-0.7-0.8-1.5-1.6-2.2-2.5c-1.9-2.3-3.6-4.7-4.1-7.7c-0.4-2.2-0.7-4.5,0.2-6.8c1.1-3.1,2.2-6.3,3.1-9.6
	c0.6-2.3,0.2-4.6-1.5-6.4c-0.3-0.4-0.8-0.6-1.4-1C59.9,105.9,62.2,107.3,61.4,108.9z" />
        <path fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1} stroke={borderColor} fill={this.state.color7 ? this.state.color7 : 'transparent'}
              onClick={() => {
                this.setState({ color7: color, opacity7: opacity });
              }} d="M331.9,64.1c1.4,2,4.1,3,4.6,5.8c-2.9,1.1-5.9,1.3-8.9,0.6c-1.2-0.3-2.3-0.7-3.5-1c-2.1-0.5-3.1-2.2-4.4-3.7
	c-0.5-0.5-0.8-1.1-1.1-1.8c-0.6-1.1-1.4-1.5-2.9-1.3c0.7,2.6,2.5,4.4,4.2,6.4c-4.8,0.8-9.8-0.7-12.5-5c-0.8-1.3-1.1-2.7-0.7-4.3
	c0.3-1,0.2-2.1,0.3-3.2c0.1-1.5-0.1-1.7-2.2-2.1c-0.5,4.1-1.4,8.2,1.5,11.9c-1.4-0.5-2.8-0.9-3.3-2.6c-0.1-0.4-0.5-0.8-0.7-1.2
	c-0.3-0.6-0.8-1.1-1.4-0.6c-0.3,0.2-0.4,1-0.2,1.5c0.5,1.7,2.6,4.1,4.7,4.5c-3.7,2.1-4.5,5.8-5.6,9.3c-3.6-4.3-5.3-8.9-2.3-14.4
	c1.3,0.3,1.4-0.6,1.8-1.8c0.5-1.7,0.4-3.2-0.1-4.8c-0.1-0.3-0.1-0.8,0-1.1c1.2-2.7,2-5.7,4.5-7.7c0.2-0.2,0.3-0.6,0.5-0.9
	c2-2.6,4.5-4.5,8-4.7c0.9-0.1,1.7-0.3,2.8-0.6c-0.4,0.6-0.7,1-1,1.4c-0.3,0.4-0.9,0.8-0.1,1.2c0.6,0.3,1.4,1.1,2.1,0.1
	c1.8-2.7,4.6-3.8,7.2-5.3c3-1.7,6.1-2.7,9.4-3.2c4.8-0.7,9.6-0.8,13.9,1.9c1.8,1.1,3.4,2.5,5.1,3.7c0.2,0.2,0.5,0.5,0.5,0.8
	c0,1.1,0.7,1.2,1.6,1c0.5-1.5-0.2-2.7-1.1-4.1c0.6,0.1,1,0.3,1.4,0.3c1.1,0.2,2.1,0.5,3.2,0.6c3.3,0.5,6.6,0.7,9.6,2.4
	c1.6,0.9,3.2,1.8,4.4,3.2c2.1,2.4,3,5.4,3.6,8.4c0.2,1.1,0.5,2.2,0.5,3.3c0,2.9-0.6,5.7-1.7,8.4c-1,2.7-2.2,5.4-3.3,8.3
	c-0.7-1.2-0.7-2.9-2.8-3c0.2,0.9,0.3,1.8,0.7,2.6c1,2.6,1.2,6.8,0.3,9.7c-1.5-2.4-3.8-2.7-6.2-2.7c-2.5,0.1-4.6,1.2-6.4,2.6
	c-0.6,0.5-1.1,1.1-1.7,1.6c-0.2,0.1-0.4,0.3-0.6,0.3c-1.6,0-3.3,0-4.9,0c-0.3-1.9-0.7-3.8-1-5.7c1.3,0.4,2.5,0.8,3.6,1.1
	c0.4,0.1,0.8-0.2,1.2-0.3c-0.2-0.4-0.3-0.9-0.6-1.1c-1.2-0.6-2.5-1.1-3.7-1.6c-3.9-1.7-6.8-4.5-9-8.2c-1.1-1.9-2.4-3.7-4.4-4.8
	c-0.7-0.4-1.7-0.4-2.6-0.6C332,63.8,332,64,331.9,64.1z" />
        <path fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1} stroke={borderColor} fill={this.state.color8 ? this.state.color8 : 'transparent'}
              onClick={() => {
                this.setState({ color8: color, opacity8: opacity });
              }} d="M110.6,81.8c-0.7,1.7-1.2,3.4-2.1,4.8c-0.6,1-1.8,1.6-2.7,2.4c-0.4,0.3-1.4,0.4-0.9,1.2
	c0.4,0.6,1.2,1.3,2.1,0.8c0.9-0.5,1.8-1,2.7-1.6c0.8-0.5,1.7-1,2.4-1.6c1.3-1.3,2.3-0.9,3.7,0.2c2.5,1.9,5.5,2.7,8.5,3.2
	c0.9,0.1,1.7,0.4,2.7,0.6c0-0.6,0-1,0-1.4c-1-0.2-1.9-0.4-2.5-0.6c0.4-1.9,0.9-3.5,1.2-5.3c0.2-1.1-0.1-2.3-0.1-3.5
	c0-0.7-0.1-1.3-0.1-2c0-0.4,0-0.8,0-1.3c2.4-2.7,4.9-5.5,7.5-8.4c-0.5,2.8-1.6,5.1-3.5,7.2c-0.8,0.9-0.9,2.8-0.8,4.2
	c0.2,2.7,1.4,5.1,3.3,7.2c0.9,1,1.8,1.9,2.7,2.8c1.1,1.3,0.7,3.5-0.6,4.6c-2.1,1.8-4.6,2.6-7.2,3.2c-0.3,0.1-0.7,0-1.1,0
	c0.1,1.4,1,1.9,2.7,1.5c1.5-0.3,1.9,0.1,1.6,1.6c-0.4,2.2-0.7,4.4-1.3,6.6c-0.4,1.6-1.1,3-1.7,4.6c-0.1,0.2-0.4,0.4-0.6,0.5
	c-3.9,0.9-7.7,0.6-11.1-1.6c-0.1-0.1-0.2-0.2-0.4-0.4c0.6-0.9,2.3-0.7,2.1-2.2c-2.8-0.5-4.6,0.4-5.8,3c-0.4,1,0.2,2.4,1.2,2.5
	c0.2,0,0.6-0.5,0.7-0.8c0.2-0.4,0.3-0.9,0.5-1.6c3.6,2.8,7.5,3.4,11.9,3.3c-0.6,1.1-1.1,2.2-1.7,3.3c-0.4,0.8-0.8,1.7-1.3,2.5
	c-1.6,2.8-4,4.8-6.5,6.6c-1.4,1-2.8,2-4.4,2.5c-2.7,0.9-5.7,1.5-8.4,0.3c-1.6-0.7-2.8-2.2-4.2-3.4c-1.4-1.2-2.3-3-4.1-3.7
	c-0.4-0.2-0.8-0.2-1.5-0.4c0.2,0.7,0.3,1.2,0.5,1.5c1.2,1.5,2.4,3.1,3.8,4.5c1.2,1.2,2.5,2.2,3.7,3.4c0.2,0.2,0.3,0.8,0.2,1.1
	c-0.7,2.2-1.4,4.3-2.2,6.4c-0.4,1-1.6,1.8-1.9,2.9c-0.5,1.5-0.6,3.2-0.8,4.9c0,0.3,0.5,0.7,0.9,0.9c0.2,0.1,0.6-0.4,0.9-0.6
	c0.2-0.3,0.3-0.6,0.5-1c0.4-1.1,1.1-1.4,1.9-0.4c2.3,2.5,3.8,5.4,5.1,8.5c0,0.1,0,0.2-0.1,0.3c-1.5-1.1-2.9-2.2-4.4-3.3
	c-3-2.2-6.3-3.9-9.8-5.3c-2.7-1.1-5.5-2.1-8.3-3c-1.4-0.5-2.9-0.7-4.3-1.1c-2.6-0.8-4.7-2.6-6.8-4.2c-0.7-0.5-1.3-1.1-2.1-1.8
	c2.5-2.7,3.4-5.9,2.9-9.6c1.5,0.7,3,1.4,4.6,1.9c0.4,0.2,1-0.1,1.5-0.1c-0.2-0.4-0.3-1.2-0.6-1.3c-1.5-0.6-2-1.9-2.7-3.1
	c-0.5-1-0.8-2.2-1.1-3.2c-0.1,0.1-0.1,0.2-0.2,0.3c1.2,0.9,2.4,1.8,3.5,2.7c1.6,1.4,3.6,1.8,5.6,2.4c0.8,0.2,1.8-1.3,1.4-2.3
	c-0.1-0.4-0.3-0.7-0.5-1.2c-1.5,0.4-2.1-0.9-2.7-1.7c-1.5-2.1-2.3-4.5-2.7-7.4c0.6,0.6,0.9,1,1.3,1.4c1.4,1.3,2.8,2.7,5,2.6
	c0.4,0,1-0.3,1.2-0.6c0.1-0.3-0.1-1-0.4-1.2c-1.9-1.4-1.5-3.5-1.8-5.4c-0.1-0.7,0-1.4,0-2.2c2.3,0.2,3.3-1.6,4.6-3
	c0.2-0.3,0.4-1,0.2-1.2c-0.2-0.3-0.9-0.5-1.3-0.3c-1,0.5-2,1.1-2.9,1.7c-0.8,0.5-1.5,0.5-2.1-0.3c-1.1-1.3-1.2-2.7-0.8-4.3
	c0.3-1.1,0.6-2.3,1-3.3c0.3-0.9-0.2-1.3-0.7-1.7c-2.1-1.3-3.6-3.2-4.7-5.4c-0.5-0.9-0.4-2.1-0.5-3.2c-0.2-1.4-0.1-2.8,1.1-3.8
	c0.1-0.1,0.1-0.4,0.1-0.7c-0.1-2.3,1.4-3.6,3.2-4.6c2.9-1.6,5.5-0.3,7.4,1.7c1.1,1.2,1.9,2.6,2.8,3.9c0.8,1,1.5,2.1,2.5,2.9
	c0.5,0.4,1.5,0.5,2.2,0.6c2.4,0.3,4.6,0.1,6.6-1.5c1.5-1.3,3-2.5,4.7-3.6C108.4,82.3,109.5,82.1,110.6,81.8z M86.4,89.6
	c1.3,0.8,2.3,1.4,3.2,2c0.3-0.7,0.5-2-0.1-3.5c-1.5-3.6-4.6-4.9-8.2-6c0.4,1.2,1,1.7,1.9,2c1.6,0.6,3.1,1.4,3.9,2.8
	c-0.5,0.2-1.1,0.4-1.5,0.7c-0.6,0.4-1.1,0.9-1.5,1.5c-0.9,1.3-1.5,2.7-1,4.3c0.3,0.9,0.8,2,1.6,2.4c1.1,0.6,2.5,0.8,3.9,1.2
	c-0.2-1.4-1.1-1.7-2.1-2c-1.5-0.4-2-0.9-1.7-2.4C85,91.6,85.8,90.7,86.4,89.6z" />
        <path fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1} stroke={borderColor} fill={this.state.color9 ? this.state.color9 : 'transparent'}
              onClick={() => {
                this.setState({ color9: color, opacity9: opacity });
              }} d="M155.6,302.6c6.1-8.7,8.9-17.9,7.7-28.1c3-1.6,6.1-3.2,9.2-4.8c0,0,0.1-0.1,0.1-0.1c0.7-0.6,1.5-1.8,2.1-1.7
	c1.3,0.2,2.6,1,3.7,1.7c1.8,1.2,3.5,2.5,5.2,3.8c0.4,0.3,0.8,0.8,1.1,1.2c1.1,1.6,2.3,3.1,3.3,4.7c1.1,1.7,1,3.5,0.6,5.4
	c-0.6,3.3-1,6.6-1.7,9.9c-0.3,1.4-1,2.8-1.6,4.1c-0.2,0.4-1.1,0.5-1.4,1c-1.7,2.3-3.2,4.7-4.9,7c-0.7,1-1.5,1.9-2.2,2.9
	c-0.2,0.3-0.2,0.9-0.4,1.4c2.2,0.5,2.5-1.6,4-2.2c-0.3,1.5-0.5,3.1-0.9,4.6c-1.3,4.3-2.5,8.6-4.1,12.7c-1.4,3.7-3,7.4-4.7,10.9
	c-1.5,3.2-3.4,6.3-4.3,9.8c-0.6,2.5-1,5-1.3,7.5c-0.3,2.5-0.6,5.1-0.7,7.7c0,0.7,0.4,1.3,0.7,2c-1.8,1.2-3.7,2.5-5.8,3.7
	c-2,1.1-4.2,1.2-6.5,0.8c-1.6-0.3-3.2-0.4-4.8-0.6c-0.3,0-0.5-0.1-0.8-0.2c-1.6-0.7-1.7-1-1.1-2.6c0.9-2.2,1.5-4.4,1.6-6.8
	c0.1-2.7,0.6-5.3,0.6-8c0.1-1.9-0.2-3.8-0.4-5.7c-0.2-1.8-0.4-3.6-0.5-5.4c-0.2-3.1-0.3-6.2-0.5-9.3c-0.1-1.7-0.4-3.3-0.4-5
	c0-1.6,0.3-3.3,0.5-4.9c0.5-3.2,1.4-6.3,3.1-9.1c0.3-0.4,0.4-1,0.3-1.5c-0.3-2,1.5-2.8,2.4-4c1-1.4,2.7-1.4,4.3-1.5
	c0.5,0,1-0.3,1.5-0.4c2.2-0.7,4.5-1.5,6.7-2.2c0.5-0.1,1.1,0.1,1.6-0.1c0.4-0.1,0.8-0.6,1.1-0.9c-0.4-0.3-0.8-0.9-1.2-0.9
	c-3-0.5-5.7,0.8-8.4,1.9C157.9,302,156.9,302.2,155.6,302.6z" />
        <path fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1} stroke={borderColor} fill={this.state.color10 ? this.state.color10 : 'transparent'}
              onClick={() => {
                this.setState({ color10: color, opacity10: opacity });
              }} d="M98.8,352c0.3,4.1-1.5,7.4-3.2,10.9c-0.9,1.8-1.7,3.7-2.5,5.6c-1,2.2-2.1,4.3-2.9,6.5c-0.5,1.4-0.5,3-0.5,4.5
	c0,3.7-0.1,7.2-2.9,10.1c-1.5,1.6-2.7,3.6-4,5.4c-1.5,2.2-1.6,4.6-1,7.1c0.2,0.7,0.2,1.4,0.1,2c-0.4,4.2-1,8.4-1.4,12.6
	c-0.1,1.4,0,2.8,0,4.2c0,0.7-0.1,1.4-0.3,2c-0.2,0.4-0.8,0.8-1.2,0.8c-2.9,0.2-5.8,0.2-8.8,0.3c-1.1,0-2.2,0-3.3,0c-1,0-2,0.1-2.9,0
	c-2.1-0.2-4.2-0.5-6.3-0.8c-0.2,0-0.4-0.1-0.6-0.1c-1.7,0.4-2-0.9-2.3-2c-1-3.8-2-7.7-2.8-11.6c-0.4-1.8-0.4-3.6-0.6-5.4
	c-0.3-3.6,0.6-7.2,1.2-10.7c0.3-2,1.4-3.7,2.6-5.3c0.2-0.3,0.5-0.8,0.4-1.2c-0.3-2.8,0.4-5.5,1.2-8.1c0.6-1.9,1.1-3.8,1.6-5.6
	c0.1-0.3,0.3-0.6,0.5-1c0.2,0.8,0.3,1.6,0.7,2.2c0.2,0.4,0.7,0.6,1.1,0.9c0.1-0.4,0.3-0.8,0.4-1.2c0.8-3-0.7-5.8-1-8.7
	c-0.4-3.4-0.9-6.8-0.4-10.3c0-0.3,0.7-0.7,1-0.7c2,0.2,4,0.7,6,0.8c1.8,0.2,3.6,0.1,5.4,0.1c3.8-0.1,7.6-0.3,11.4-0.5
	c3.4-0.2,6.7-1.1,10-1.8C95.3,352.6,97,352.4,98.8,352z" />
        <path fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1} stroke={borderColor} fill={this.state.color11 ? this.state.color11 : 'transparent'}
              onClick={() => {
                this.setState({ color11: color, opacity11: opacity });
              }} d="M314.8,111c-2.3-0.1-4.7-0.1-7-0.2c-1.2-0.1-2.3-0.3-3.4-0.7c-1.4-0.5-1.7-1.9-2.1-3.1c-0.1-0.4,0.1-1,0.4-1.3
	c1.2-1.4,2.6-2.6,3.8-4c0.7-0.9,1.2-1.9,1.7-2.9c0.7-1.2,1.5-2.3,2.2-3.5c0.4-0.7,0.6-1.6,0.9-2.4c0.4-0.8,1-1.2,1.9-1.3
	c1.4-0.1,2.9,0,3.9,1c0.7,0.6,1,1.7,1.4,2.6c0.5,1.3,0.8,2.7,1.2,4c0.2,0.7,0.3,1.7,0.8,2.1c0.9,0.8,2.1,1.5,3.3,1.8
	c1.4,0.4,2.9,0.2,4.3,0.6c3,0.8,6,0.6,8.8-0.4c1.2-0.4,2.1-1.5,3.1-2.4c1.3-1.2,1.7-2.9,2.2-4.6c0.3-0.9,0.7-1.8,0.3-2.9
	c-0.1-0.2,0.2-0.7,0.4-0.7c1.1-0.3,2.2-0.7,3.4-1c0.2,1.5,0.3,3.2,0.8,4.7c0.3,0.9,1.3,1.5,2.2,2.5c0.3-0.6,0.5-0.9,0.5-1.2
	c0.3-3,1.7-5.5,3.3-7.9c0.6-0.9,1.1-1.8,1.7-2.7c1.4-2.2,3.3-3.8,5.6-4.9c1.1-0.5,2.4-0.7,3.6-0.7c1.3,0,2,1.4,2.4,3.1
	c0.7,3-1.1,4.9-2.5,7.1c-1.5,2.3-3.5,4.1-6.1,5.3c-1.4,0.6-1.3,0.8-0.2,1.7c0.2,0.1,0.3,0.4,0.3,0.6c-0.1,1.5,0,3-0.3,4.4
	c-0.2,0.8-1,1.7-1.8,2.2c-1.4,0.8-1.8,0.6-3-0.5c-0.6-0.6-1.4-1-2.1-1.5c-0.2,0.1-0.3,0.3-0.5,0.4c0.4,0.6,0.7,1.4,1.2,1.8
	c0.7,0.6,0.7,1.1,0.5,1.9c-0.4,1.9-0.7,3.9-1.3,5.8c-0.5,1.8-1.3,3.5-1.9,5.3c-0.2,0.6-0.2,1.3-0.5,1.9c-1.7,3.4-3.1,6.9-5.6,9.8
	c-1.4,1.6-2.5,3.6-4,5c-3.2,3.1-6.8,5.8-11,7.2c-2.9,1-6,1.1-9,0.2c-3.7-1.1-7.3-2.5-10.2-4.9c-2.8-2.3-5.5-4.6-7.9-7.3
	c-1.6-1.8-2.4-4.3-3.6-6.5c-1.2-2.1-1.6-4.4-1.8-6.7c-0.1-0.9,0.1-1.9,0.3-2.8c0.3-1.7,0.7-3.4,1.1-5.2c0.6-2.5,1.1-5,1.7-7.5
	c0.1-0.3,0.2-0.5,0.3-0.7c1.4,0.2,2.7,0.3,4.2,0.5c-0.6,0.7-1,1.5-1.7,1.9c-2.4,1.5-1.7,3.8-0.2,5.7c1.2,1.5,3,2,4.7,2.3
	c1.9,0.3,3.8,0.1,5.7,0.1c0.8,0,1.7,0,2.5,0c0.4,0,0.7-0.3,1.1-0.4C314.9,111.4,314.8,111.2,314.8,111z M328,117.6
	c-1,0.4-1.8,0.8-2.6,1.1c-0.9,0.4-1.9,0.8-2.8,1.1c-0.5,0.2-1,0.4-1.4,0.5c-3.1,0.6-6.3,0.9-9.5,0.8c-2.4-0.1-4.7-0.2-6.6-2
	c-0.5-0.5-1.5-0.7-2.3,0c0.5,0.3,0.8,0.6,1.2,0.8c1.4,0.7,2.7,1.5,4.2,2.1c1.2,0.4,2.8,0.2,2.7,2.2c0,0.1,0.1,0.1,0.2,0.2
	c1.2,2.3,2.9,3.8,5.4,4.7c2.5,0.8,4.8,0.5,6.9-0.8c2.9-1.8,4.4-4.8,5.4-8.2c0.9,0.6,1.7,1.3,2.6,1.9c0.1-0.1,0.3-0.3,0.4-0.4
	c-0.7-1.3-1.3-2.7-2-4c-0.6-1.3-1.5-1.8-3-1.5C327,116.5,327.4,116.8,328,117.6z M350.9,95.9c-0.1,0.1-0.2,0.2-0.3,0.2
	c0.1,0.2,0.2,0.6,0.4,0.7c0.5,0.4,1.1,0.8,1.7,1.1c1.6,0.9,4.9-0.4,5.1-2.2c0.1-1.1,1-2.4-0.2-3.5c-0.2-0.2-0.2-0.8-0.1-1.1
	c0.7-2.2,2.1-3.7,4.2-4.6c0.7-0.3,1.4-0.9,2.1-1.3c0-0.2-0.1-0.3-0.1-0.5c-0.6,0-1.3-0.2-1.8,0c-3.2,1.3-6,3.2-7.1,6.7
	c-0.3,0.8,0,1.2,0.7,1.3c0.7,0.1,0.8,0.4,0.7,1.1c-0.2,1.1-1.4,2.7-2.4,3c-0.2,0-0.4,0-0.6-0.1C352.4,96.5,351.6,96.2,350.9,95.9z"
        />
        <path fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1} stroke={borderColor} fill={this.state.color12 ? this.state.color12 : 'transparent'}
              onClick={() => {
                this.setState({ color12: color, opacity12: opacity });
              }} d="M394,216.2c-0.4-1.9-1.6-0.6-2.6-1.3c0.8-0.3,1.3-0.6,1.9-0.7c2.2-0.4,4.4-0.7,6.5-0.9c1.2-0.1,2.4-0.2,3.1,1.3
	c0.7,1.4,2,2.4,2.7,3.7c0.7,1.4,1,3,1.2,4.6c0.2,1.2,0,2.5,0.1,3.7c0.1,1-0.3,1.6-1.1,2.1c-2.5,1.5-4.5,3.6-6.4,5.8
	c-2.4,2.7-5.3,4.8-8.6,6.3c-2.8,1.2-5.5,2.5-8.5,3.1c-0.9,0.2-1.9,0.3-2.8,0.4c-2.5,0.3-5.1,0.6-7.6,0.9c-1.3,0.1-2.5,0.3-3.8,0.3
	c-2.4,0-4.6,0.5-6.7,1.9c-2.1,1.5-4.3,2.8-6.5,4.1c-2.2,1.4-4.4,2.8-6.7,4.2c-1.9,1.2-4,1.8-6.3,1.8c-2.7,0-5.4,0.2-8-0.3
	c-4.4-0.9-9.1-1.2-13.1-3.5c-0.3-0.2-0.6-0.4-1-0.7c2.2,0.1,4.3,0.3,6.3,0.4c0.4,0,0.8-0.4,1.1-0.6c-0.3-0.2-0.6-0.6-0.9-0.6
	c-2.5-0.5-5.1-0.9-7.6-1.4c-1.2-0.2-2.4-0.8-3.6-1c-2.2-0.5-3.9-1.8-5.6-3.2c-0.9-0.7-1.3-2-0.9-2.8c1.9,1.1,3.7,2.3,5.6,3.2
	c1.9,0.9,4,1.5,6.1,2.2c1.9,0.6,3.9,1.1,5.9,1.6c0.7,0.2,1.4-0.2,1.9-1.2c-1.3-0.4-2.6-0.7-3.9-1.1c-3.3-1.1-6.8-2-10-3.4
	c-2.3-1-4.4-2.6-6.6-4c-1.6-1.1-1.4-2.9-0.5-4.3c0.2-0.3,1-0.6,1.5-0.5c1.3,0.1,2.7,0.4,4,0.7c2.8,0.7,4.6,2.8,7,4.3
	c3.5,2.1,7.2,3.9,11.1,5c0.2,0.1,0.4,0,0.6,0c0.2-1.5-1.1-1.7-1.9-2c-2.7-1.4-5.5-2.5-8.2-3.9c-2.5-1.3-4.6-3.4-6.4-5.6
	c-0.2-0.3-0.2-0.7-0.3-1c-0.2-0.7-0.3-1.3-0.5-2c0.6-0.5,1.4-1.1,2-1.8c0.8-1.1,1.8-1,2.8-0.6c2.3,0.9,4.2,2.3,5.6,4.3
	c0.7,1,1.6,1.9,2.5,2.7c1.3,1.2,3.1,1.2,4.7,1.5c1.3,0.2,2.7,0.3,3.9,0.9c1.2,0.5,2.2,0.3,3.2-0.1c3.5-1.5,7-3,10.5-4.6
	c0.3-0.1,0.5-0.6,0.7-0.9c-0.1-0.1-0.2-0.3-0.3-0.4c-1.7,0.3-3.3,0.5-5.3,0.8c0.9-0.7,1.5-1.3,2.1-1.9c0.2-0.2,0.4-0.3,0.7-0.5
	c1.1-0.5,1.4-1.3,1.4-2.4c0-1.4,0.4-2.5,1.4-3.7c1.2-1.3,1.8-3,2.6-4.5c1.4-2.6,4.1-3.7,6.7-3.8c0.8,0,1.3,0.4,1,1.2
	c-0.5,1.5-0.9,3.1-1.8,4.5c-1.2,1.9-2.2,3.9-2.4,6.1c-0.1,1.1,0.1,2.3,0.5,3.3c0.7,2,0.7,3.8,0,5.8c-0.6,1.7-1,3.4-1.4,5.1
	c-0.1,0.3,0,0.6,0.1,0.8c0.3,0,0.7,0,0.8-0.1c0.7-0.7,1.4-1.4,1.9-2.2c0.5-1,0.9-2.2,1.1-3.3c0.2-1.2,0.1-2.5,0.3-3.7
	c0.1-0.5,0.3-1.1,0.6-1.3c4.6-2.8,8.4-6.6,12.6-9.8c2.3-1.8,4.9-3.3,7.7-4.2c1.2-0.4,2.2-1,3.4-1.2c2.2-0.4,4.5-0.6,6.7-0.9
	C391.7,216.5,392.8,216.3,394,216.2z" />
        <path fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1} stroke={borderColor} fill={this.state.color13 ? this.state.color13 : 'transparent'}
              onClick={() => {
                this.setState({ color13: color, opacity13: opacity });
              }} d="M178,215.3c0.9-0.4,1.5-0.7,2.2-1.1c0.2-0.1,0.4-0.4,0.6-0.6c-0.2-0.2-0.4-0.6-0.6-0.6c-0.9,0-1.8,0-2.6,0.3
	c-1.7,0.7-3.2,1.7-4.9,2.3c-2.5,0.9-5.2,1.5-8,2.4c0.3-1.6,0.6-2.7,0.7-3.9c0.1-1.4-0.5-1.9-1.8-2c-0.4,1.6-0.7,3.1-1.2,4.6
	c-0.2,0.5-0.6,1.1-1.1,1.4c-2.5,1.8-5.2,3.4-7.6,5.5c-2.3,1.9-4.1,4.3-6.3,6.4c-1.5,1.4-3.1,2.8-4.8,4c-2,1.4-4.2,2.5-6.3,3.7
	c-3.6,2-7.5,3.4-11.5,4.5c-1.5,0.4-3,0.5-4.6,0.6c-0.7,0.1-1.4,0-2-0.2c-1.8-0.7-3.3,0.2-4.7,1c-1.9,1.1-3.9,1.5-6,1.6
	c-1,0-2.1-0.4-2.9-1c-1.9-1.5-3.9-3-5.3-4.8c-1.9-2.4-3.3-5.2-4.9-7.9c-0.3-0.4-0.8-0.7-1.3-1.2c2-1.9,3.9-3.8,5.8-5.6
	c1.1-1.1,2.3-2.1,3.4-3.3c2.6-2.8,5.7-4.8,9-6.6c1.9-1,3.9-1.9,5.8-2.8c0.5-0.2,1-0.2,1.5-0.3c0.8,0.9,1.7,1.8,2.6,2.8
	c-1.4,1.4-2.9,2.8-4.3,4.2c0.1,0.1,0.1,0.3,0.2,0.4c0.9,0.1,1.9,0.2,2.5,0.3c0.7-0.6,1.1-1.1,1.5-1.4c1.2-0.9,2.3-2,3.6-2.7
	c2.2-1.2,4.5-2.1,7-2.2c3.3-0.2,6.6-0.4,9.8-0.9c3.7-0.7,7.5-1.6,10.3-4.5c0.7-0.7,1.3-1.5,2-2.2c4-3.8,8.7-4.7,14-3.7
	c2.3,0.5,3.8,1.8,5,3.5c-0.5,0.3-1,0.6-1.6,0.8c0.5,0.4,0.9,1,1.4,1.2c0.4,0.2,1.1,0.1,1.6,0.2c0.8,0.2,1.7,0.4,2.5,0.8
	c2.1,1,3.8,2.6,5.1,4.5c0.6,0.9,1,2,1.5,3c1,2.1,0.5,4.1-0.2,6.3C182.4,218.9,180.2,217.2,178,215.3z" />
        <path fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1} stroke={borderColor} fill={this.state.color14 ? this.state.color14 : 'transparent'}
              onClick={() => {
                this.setState({ color14: color, opacity14: opacity });
              }} d="M235.4,232.4c1.2,6.6,4.8,9.7,7.8,9.8c-0.6-0.6-0.9-1.1-1.4-1.5c-1.8-1.6-3.3-3.3-3.8-5.7
	c-0.2-1.1-0.1-2.3,0.1-3.3c0.2-0.9,1.2-1.1,2.1-1.1c1,0.1,1.3-0.4,1-1.4c-0.7,0.1-1.4,0.2-2.1,0.3c-2.2,0.3-3.6-1.2-5.1-2.4
	c-2-1.6-2.7-4-2.9-6.3c-0.1-1.4-0.3-3.1,1.1-4.3c1.8-1.5,3.2-1.4,3.9,0.9c0.9,3.3,3.3,4.7,6.4,5.4c1.5,0.3,3.1,0.7,4.4,1.5
	c2.2,1.3,4.2,2.9,6.2,4.5c0.8,0.6,1.2,1.7,1.7,2.6c0.5,0.8,0.6,2,2,1.9c0.7,0,1-0.2,0.7-0.9c-0.3-0.8-0.6-1.5-0.8-2.1
	c1.3-0.9,2.8-1.7,3.8-2.8c3.4-3.6,7.6-5.7,12.1-7.4c2.3-0.9,4.7-1.2,7.1-1.3c0.4,0,0.8,0.3,1.2,0.5c0.3,0.2,0.6,0.6,0.9,0.6
	c1.4,0.1,1.1,1,1.1,1.9c-0.1,1.9-0.4,3.7-0.4,5.6c0,3.7,0.1,7.3,0.1,11c0,1.6,0,3.2,0,4.9c-1.8,0.3-3.7,0.6-5.6,1
	c-2.4,0.5-4.8,0.6-7.3,0.3c-1.5-0.2-3.1,0.1-4.6,0.3c-1.5,0.1-3,0.3-4.5,0.5c0.1-0.8,0.2-1.4,0.3-2.1c-2.1-0.4-2.4-0.3-3.2,1.4
	c-1.1,2.3-2.9,4.2-5.2,5.3c-1.6,0.8-3.3,0.8-5.1,0.1c-0.9-0.3-1.8-0.5-2.8-0.5c0.2,1.4,1.7,1.2,2.4,1.9c-2,1.4-4.2,1.8-6.5,1.8
	c-1,0-2.5,0.4-3.2-1.2c-0.1-0.3-1.5,0-2.2,0c0.1,0.3,0,0.9,0.2,1c0.6,0.4,1.2,0.6,2.1,1c-1.3,2-2.5,3.9-3.8,5.8
	c-0.4,0.6-1,1-1.5,1.4c-0.5,0.4-1.3-0.2-1.2-1.1c0.1-1.5,0.4-2.9,0.6-4.4c0.3-2,2.2-2.7,3.3-4.1c0.3-0.4,0.5-0.8,0.8-1.3
	c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4,0.1-0.9,0.2-1.3,0.4c-2.4,1-4,2.9-5.6,4.9c-1.3,1.6-2.6,3.2-4.5,4.2c-0.3,0.1-0.7,0.2-0.9,0.1
	c-0.2-0.2-0.3-0.6-0.3-0.9c0.2-1.7,0.5-3.3,0.8-5c0.3-1.7,1.5-2.8,3-3.4c1.2-0.5,2.5-0.8,3.8-1.2c0.2-0.1,0.5-0.2,0.7-0.4
	c0.5-0.7,1-0.5,1.7-0.3c0.7,0.3,1.4,0.5,2,0.8c0.1-0.1,0.2-0.2,0.3-0.2c-0.2-0.4-0.3-0.9-0.6-1.1c-1-0.5-1.8-1.5-3.1-1.4
	c-0.2,0-0.5-0.2-0.7-0.4c-0.4-0.3-0.7-0.8-1.2-1c-1.7-0.8-2.2-2.3-2.5-4c-0.1-0.4-0.9-0.7-1.4-0.9c-0.2-0.1-0.9,0.1-0.9,0.2
	c-0.1,0.4,0.1,0.8,0.2,1.2c0.1,0.5,0.3,1,0.5,1.6c-3,0.2-5,2.1-7,4.1c-1.3,1.2-2.5,2.4-3.9,3.5c-0.7,0.6-1.7,1-2.6,1.5
	c-0.2-0.9-0.5-1.6-0.6-2.4c-0.2-1,0.1-1.8,0.9-2.5c1.6-1.6,3.2-3.3,4.7-5c0.9-1.1,1.5-2.4,2.4-3.4c0.8-0.9,1.8-1.7,2.8-2.3
	c1.5-0.8,3.3-1.2,4.8-2.2c2.2-1.5,4.6-1.4,7-1.9C234.3,232.1,234.8,232.3,235.4,232.4z" />
        <path fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1} stroke={borderColor} fill={this.state.color15 ? this.state.color15 : 'transparent'}
              onClick={() => {
                this.setState({ color15: color, opacity15: opacity });
              }} d="M316.6,156.2c0,0.7,0,1.4,0,2.1c0,0.4-0.1,1,0.1,1.3c0.3,0.4,0.8,0.7,1.2,1.1c0.3-0.5,1-1,1-1.5
	c0.1-2.3,0-4.7-0.1-7c0-1.6,0-3.2-0.1-4.8c0-0.9-0.2-1.8-0.2-2.7c1.3,0.2,2.6,0.3,3.8,0.4c5.4,0.5,10.1-1.3,14.4-4.3
	c2-1.4,3.7-3.4,5.4-5.3c1.5-1.7,2.8-3.6,4.4-5.7c-0.8,5.4-1.1,10.7,0.3,15.9c0.6-0.1,1.3-0.2,2-0.4c-0.2-0.6-0.3-1.1-0.5-1.7
	c1.4,1.4,1.6,3.3,0.7,5c-0.4,0.9-0.6,1.9-1.1,2.7c-1.3,2.2-3.1,3.9-5.2,5.4c-1.6,1.1-3,2.5-4.6,3.7c-2.8,2-5.6,4.2-8.6,5.8
	c-2.8,1.5-5.8,2.6-9.1,2.5c-2.8-0.1-5.9-2.5-7-4.9c-0.3-0.7-0.3-1.7,0-2.4c0.7-1.9,1.7-3.7,2.6-5.6
	C316.3,156.1,316.5,156.1,316.6,156.2z" />
        <path fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1} stroke={borderColor} fill={this.state.color16 ? this.state.color16 : 'transparent'}
              onClick={() => {
                this.setState({ color16: color, opacity16: opacity });
              }} d="M88.5,468.4c-1.8,2.3-3.5,4.7-5.5,6.9c-2.4,2.7-5,5.2-8.3,6.8c-0.6,0.3-1.5,0.3-2.2,0.2c-2.5-0.4-5-0.8-7.5-1.4
	c-2.2-0.5-4.5-1-6.7-1.8c-1.5-0.5-3-1.1-4-2.6c-0.8-1.1-1.4-2.2-0.9-3.7c0.7-2,1.2-4.1,2-6c0.5-1,1.5-1.8,2.5-3.1
	c0.4-2.3-0.7-4.3-3.2-5.6c-0.4-0.2-0.7-0.6-1-1c-0.4-0.5-0.5-1,0.1-1.5c1.7-1.6,1.7-1.6,3.7-0.4c1.5,1,2.9,2.1,4.4,3
	c2.8,1.6,5.9,1.9,9.1,1.6c1.6-0.2,3.1-0.9,4.6-1.2c1.1-0.2,2.3-1.2,3.5-0.2c0.3,0.2,0.7,0.4,1,0.6c1.9,2.2,3.8,4.3,5.7,6.5
	C86.6,466.6,87.5,467.4,88.5,468.4z" />
        <path fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1} stroke={borderColor} fill={this.state.color17 ? this.state.color17 : 'transparent'}
              onClick={() => {
                this.setState({ color17: color, opacity17: opacity });
              }} d="M68.7,458.1c-2.2-0.1-4.4-0.5-6.3-1.7c-1-0.7-1.9-1.6-3-2.3c-1.1-0.8-0.9-1.8-0.7-2.8c0.8-3.9,0.2-7.8,0.2-11.6
	c0-1.3-0.7-2.7-0.6-3.9c0.3-2.2-0.5-4.1-0.8-6.1c-0.2-1.1-0.6-2.1-0.9-3.2c-0.4-1.3,0.4-2.3,1.8-2c1.9,0.4,3.7,0.9,5.6,1.2
	c1.4,0.2,2.8,0,4.1,0.1c0.5,0,1.1,0.2,1.6,0.1c2.6-0.2,5.2-0.4,7.8-0.7c0.6-0.1,1.2-0.2,1.7-0.2c0.1,0,0.4,0.5,0.4,0.7
	c-0.3,1.4-0.8,2.7-1.1,4.1c-0.5,2.5-1,4.9-1.3,7.4c-0.3,2.4-0.2,4.8-0.2,7.1c0,0.5,0.1,1.2,0.4,1.6c1.2,1.4,0.1,2.9,0.2,4.4
	c0,0.5-0.1,1-0.2,1.5c-0.1,1.8-1,3.3-2.4,4.2c-1.3,0.8-2.9,1.2-4.3,1.6C70.2,457.8,69.5,457.9,68.7,458.1z" />
        <path fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1} stroke={borderColor} fill={this.state.color18 ? this.state.color18 : 'transparent'}
              onClick={() => {
                this.setState({ color18: color, opacity18: opacity });
              }} d="M175,370.6c-1,1.7-1.9,3.6-3.1,5.1c-2.3,2.6-4.8,5-7.2,7.5c-1.2,1.2-2.2,2.6-3.3,3.8c-0.2,0.2-0.4,0.4-0.6,0.4
	c-4.9,1-9.9,0.9-14.8,0.7c-2.7-0.1-5.6-0.5-7.5-3.1c-1-1.3-1.3-2.5-1.3-4.1c0.1-2.7,1.2-4.9,2.3-7.2c0.4-0.8,1-0.5,1.5-0.2
	c0.5,0.3,1.1,0.5,1.6,0.8c0.1-0.1,0.3-0.2,0.4-0.3c-0.3-0.7-0.6-1.4-1-2.1c-0.5-0.9-1.2-1.6-1.8-2.5c-0.8-1.3-0.4-2.6,0.4-3.9
	c0.2-0.3,0.4-0.7,0.5-1.1c1.5,0,2.1,0.5,2.8,1.9c0.8,1.5,2.3,2.5,3.9,2.8c3,0.5,6.1,0.9,9.2,0.9c3.1,0,5.9-1.3,8.5-3.2
	c1-0.7,2-1.6,3.4-1.1c0.3,0.1,0.6,0.3,0.8,0.5C171,368.4,173.1,369.8,175,370.6z" />
        <path fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1} stroke={borderColor} fill={this.state.color19 ? this.state.color19 : 'transparent'}
              onClick={() => {
                this.setState({ color19: color, opacity19: opacity });
              }} d="M302.6,79c0.4-4,2-7.5,4.8-10.5c-0.1-0.1-0.1-0.3-0.2-0.4c0.8,0.3,1.6,0.5,2.3,0.9c3,1.8,6.1,2.6,9.6,2
	c1.4-0.2,2.9-0.4,4.1,0.7c0.1,0.1,0.3,0.2,0.4,0.2c2.7,0.3,5.5,0.6,8.2,0.7c2.1,0.1,4.1-0.3,5.7-1.2c2.5,2.3,4.9,4.5,7.4,6.9
	c0.1,0.2,0.2,0.7,0.3,1.3c0.3,1.9,0.7,3.8,0.9,5.7c0,0.3-0.7,0.9-1.2,1c-1.8,0.5-3.5,1.1-5.4,1.2c-1.8,0.1-3.6,0-5.3-0.4
	c-2.2-0.4-4.3-1.3-6.4-1.8c-2.4-0.6-4.8-0.6-7.1,0.7c-0.8,0.5-1.8,0.8-2.6,1.4c-0.3,0.2-0.9,0.1-1.4,0c-1.6-0.2-3.2-0.5-5.3-0.9
	c-0.2-0.5-0.7-1.6-1.2-2.6c-0.2-0.3-0.6-0.6-1-0.6c-0.8,0-1.5,0.1-2.4,0.2c0.9,1.2,1.6,2.2,2.4,3.3c-0.1,0.1-0.2,0.1-0.3,0.2
	c-2.3-1-4.5-2-6.8-3.1c0-1.9,1.2-2.8,3.1-3.4c1.3-0.4,2.5-1.3,4-1.1c0.3,0,0.7,0.1,1,0.1c0.4,0,0.7-0.1,1.1-0.2
	c-0.1-0.4-0.2-0.9-0.4-1.1c-1.3-1-2.8-1-4.2-0.6C305.4,77.9,304,78.5,302.6,79z M321.8,79.7c0.4,0,0.7,0,1,0.1
	c0.8,0,1.7,0.1,2.5,0.1c0.5,0,1.1-0.2,1.6-0.2c4.4-0.4,7.6,1.6,10.6,4.5c0.7,0.7,1.3,1,2.1,0.1C335.7,78.1,326,76,321.8,79.7z" />
        <path fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1} stroke={borderColor} fill={this.state.color20 ? this.state.color20 : 'transparent'}
              onClick={() => {
                this.setState({ color20: color, opacity20: opacity });
              }} d="M313.5,470.8c-0.8,0.4-1.6,0.8-2.4,1.2c-4.6,2.3-9.1,4.7-14.2,5.6c-2.6,0.5-5.1,0.7-7.7,1.1c-3,0.5-6,1-9.1,0.7
	c-3.2-0.3-6.4-0.4-9.4-1.7c-1.1-0.5-2.5-1.2-2.3-2.6c0.2-1.3,0.7-2.8,1.5-3.9c1.3-1.9,3-3.6,4.5-5.3c0.7-0.8,1.6-1.4,2.5-2.1
	c0.2-0.1,0.7-0.1,1,0.1c1.6,1.2,3.4,1.5,5.4,1.5c2.7,0.1,5.4-0.3,8-0.8c2-0.4,3.9-1,5.9-1.5c1.6-0.4,3.1,0,4.4,0.7
	c3,1.8,6,3.7,9,5.5C311.6,470,312.5,470.3,313.5,470.8z" />
        <path fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1} stroke={borderColor} fill={this.state.color21 ? this.state.color21 : 'transparent'}
              onClick={() => {
                this.setState({ color21: color, opacity21: opacity });
              }} d="M320.6,477c-0.3,1.5-0.9,3.1,0.4,4.2c1.3,1.1,2.7,2.2,4.2,3c3.4,1.6,7.1,1.2,10.6,0.7c1.9-0.3,3.9-0.6,5.8-1.1
	c2-0.6,3.9-1.5,5.9-2.3c1-0.4,2-0.6,3.1-0.3c3.1,0.9,6.2,1.6,9.5,2.4c-0.3,0.2-0.7,0.5-1.1,0.8c-2.5,1.7-4.9,3.5-7.4,5.2
	c-3,2.1-6.4,3.1-10,3.7c-2.6,0.4-5.3,1-7.9,1.3c-1.7,0.2-3.4,0.3-5,0c-3.2-0.4-6.3-1-9.4-1.7c-2.2-0.5-4.2-1.7-5.5-3.6
	c-1.6-2.4-1.5-3.7,0.6-7c0.4-0.7,0.9-1.4,1.4-2.1C316.9,478.4,318.9,478,320.6,477z" />
        <path fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1} stroke={borderColor} fill={this.state.color22 ? this.state.color22 : 'transparent'}
              onClick={() => {
                this.setState({ color22: color, opacity22: opacity });
              }} d="M122.8,476.7c-0.9,1.7-1.9,3.4-2.8,5.1c-0.1,0.2-0.2,0.3-0.4,0.4c-2.6,1.2-5.1,2.6-7.9,3.5
	c-2.4,0.8-5.1,0.9-7.6,1.4c-1.8,0.3-3.6,0.7-5.5,1c-1.7,0.3-3.4,0.6-5,0.6c-3.7,0.1-7.4,0.1-11,0c-4-0.1-8-0.1-12-0.5
	c-3.3-0.4-6.5-1.3-9.7-2.1c-1.8-0.4-3.9-2.7-4-5.7c1.6,0.5,3.2,1,4.9,1.4c1.1,0.3,2.3,0.6,3.5,0.7c3.5,0.5,7,0.9,10.6,1.2
	c2.4,0.3,4.7,0.5,7.1,0.7c1.7,0.1,3.4-0.1,5.1-0.1c0.4,0,0.7,0,1.1,0c2.5,0,4.9,0.1,7.4,0c3.9-0.1,7.9-0.1,11.7-1
	c4.4-1,8.6-2.8,12.4-5.2c0.4-0.3,0.7-0.7,1-1c0.4-0.3,0.8-0.5,1.2-0.8C122.6,476.5,122.7,476.6,122.8,476.7z" />
        <path fillOpacity={this.state.opacity23 ? this.state.opacity23 : 1} stroke={borderColor} fill={this.state.color23 ? this.state.color23 : 'transparent'}
              onClick={() => {
                this.setState({ color23: color, opacity23: opacity });
              }} d="M203.3,384.9c0.2,2.4-1.5,4.7-3.6,5.6c-2.5,1-5.1,1.3-7.7,1.6c-2.4,0.2-4.8,0.2-7.3,0.3c-1.9,0-3.7-0.2-5.6-0.2
	c-3,0-6.1,0.1-9.1,0.3c-2.8,0.2-5.5,0.8-8.2,0.9c-4.1,0.2-8.2,0.1-12.3,0c-1.7,0-3.4-0.1-5.1-0.3c-1.2-0.1-2.4-0.4-3.5-0.8
	c-0.7-0.2-1.2-0.7-1.1-1.6c0-0.9-0.3-1.7-0.4-2.7c3.3,1.7,6.8,1.9,10.3,1.9c3.4,0,6.9,0.2,10.3-0.2c3.4-0.5,6.8-1.1,10.3-1.5
	c2-0.2,3.9-0.1,5.9-0.2c1.4-0.1,2.9-0.3,4.3-0.2c3.4,0.2,6.8,0.7,10.1,0.9c1.5,0.1,3.1-0.2,4.6-0.4c2.5-0.4,4.9-1.1,7-2.4
	C202.5,385.6,202.8,385.3,203.3,384.9z" />
        <path fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1} stroke={borderColor} fill={this.state.color24 ? this.state.color24 : 'transparent'}
              onClick={() => {
                this.setState({ color24: color, opacity24: opacity });
              }} d="M110.6,480.2c-0.2-0.8-0.6-1.5-0.5-2.2c0-1.2,0.4-2.3,0.5-3.4c0.5-3.7-1.1-8.5-5.8-9.3
	c-0.8-0.1-1.6-0.7-1.6-1.7c0-0.4-0.2-0.7-0.4-1.4c1.3-0.3,2.6-0.7,4-0.9c4.9-0.8,8.4,1.5,11.4,4.9c1,1.2,2.2,2.4,2.8,3.8
	c0.5,1.1,0.5,2.6,0.4,3.9c-0.1,0.6-0.9,1.3-1.6,1.6c-2.8,1.6-5.6,3.1-8.5,4.6C111.2,480.2,110.9,480.2,110.6,480.2z" />
        <path fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1} stroke={borderColor} fill={this.state.color25 ? this.state.color25 : 'transparent'}
              onClick={() => {
                this.setState({ color25: color, opacity25: opacity });
              }} d="M346.9,479.7c-3.1,1.1-5.8,2.2-8.6,3c-4,1.1-8,1.8-12.1,0.4c-1.3-0.5-2.4-1.3-3.5-2.1c-0.3-0.2-0.5-0.9-0.5-1.4
	c0.1-2.5,2.3-4.7,4.9-5.4c3.1-0.8,6.1-0.3,9.1,0.5c3,0.7,5.5,2.4,8.2,3.9C345.1,478.9,345.9,479.2,346.9,479.7z" />
        <path fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1} stroke={borderColor} fill={this.state.color26 ? this.state.color26 : 'transparent'}
              onClick={() => {
                this.setState({ color26: color, opacity26: opacity });
              }} d="M318.4,475.6c-1.2,1.1-2.5,1.5-3.9,1.9c-2.2,0.6-4.4,1.5-6.5,2.4c-3,1.2-6.1,1.9-9.4,2.3
	c-2.6,0.3-5.1,1-7.6,1.5c-2.5,0.4-5,0.9-7.6,1.1c-2.8,0.2-5.6,0.1-8.3,0c-1.5-0.1-3.3-0.3-4.2-1.7c-0.7-1.2-1-2.7-1.6-4.3
	c1.7,0.6,3.1,1.3,4.5,1.6c2.3,0.4,4.6,0.6,6.9,0.9c1.3,0.1,2.5,0.3,3.8,0.2c3.1-0.2,6.1-0.6,9.2-1c2.5-0.4,5.1-0.7,7.5-1.4
	c2.8-0.9,5.6-2.1,8.3-3.2c1.7-0.7,3.4-1.6,5.1-2.5c0.7-0.4,1.3-1,1.9-1.5c-0.1-0.1-0.1-0.2-0.2-0.3c0.5,0.1,1,0.2,1.6,0.3
	C317.1,473,317.6,474.3,318.4,475.6z" />
        <path fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1} stroke={borderColor} fill={this.state.color27 ? this.state.color27 : 'transparent'}
              onClick={() => {
                this.setState({ color27: color, opacity27: opacity });
              }} d="M365.9,484.2c-3,2.7-5.8,5.4-8.8,7.9c-0.9,0.8-2,1.2-3.1,1.8c-3.7,2.1-7.7,3.4-12,4.1
	c-3.5,0.5-7.1,0.7-10.7,0.5c-1.7-0.1-3.4,0.1-5.1,0.3c-2.6,0.3-5-0.4-7.2-1.4c-1.7-0.8-2.8-2.4-3.9-4.3c6.3,2.8,12.7,3.2,19.2,2.8
	c2.5-0.2,5-0.8,7.4-1.3c1.4-0.2,2.9-0.4,4.3-0.7c5.2-1.3,9.8-3.9,13.9-7.4c0.8-0.6,1.5-1.2,2.3-1.9
	C363.3,483.9,363.9,483.8,365.9,484.2z" />
        <path fillOpacity={this.state.opacity28 ? this.state.opacity28 : 1} stroke={borderColor} fill={this.state.color28 ? this.state.color28 : 'transparent'}
              onClick={() => {
                this.setState({ color28: color, opacity28: opacity });
              }} d="M186.5,368.4c1.8-0.6,3.3-1.3,4.9-1.5c5.3-0.5,9.1,1.9,11.9,6.4c1.2,1.9,1.6,3.9,1.6,6c0,1.3-0.1,2.7-1.7,3.4
	c-1.6,0.7-3.2,1.8-4.7,2.6c-0.4,0.2-0.9,0.2-1.6,0.4c0.4-1.4,0.8-2.6,1.1-3.9c1.2-4-1.2-7.9-4.3-9.7c-0.8-0.5-1.9-0.5-2.8-0.8
	c-1.2-0.3-2.4-0.7-3.1-1.9C187.6,369.2,187.2,368.9,186.5,368.4z" />
        <path fillOpacity={this.state.opacity29 ? this.state.opacity29 : 1} stroke={borderColor} fill={this.state.color29 ? this.state.color29 : 'transparent'}
              onClick={() => {
                this.setState({ color29: color, opacity29: opacity });
              }} d="M182,385.9c3.8-4,5.7-8.7,6.9-13.9c1.3,0.6,3,0.5,4.4,1.7c2.3,2,3.4,5.6,2.4,8.4c-0.5,1.4-0.7,2.8-1.1,4.3
	C190.5,387.5,182.7,387.1,182,385.9z" />
        <path fillOpacity={this.state.opacity30 ? this.state.opacity30 : 1} stroke={borderColor} fill={this.state.color30 ? this.state.color30 : 'transparent'}
              onClick={() => {
                this.setState({ color30: color, opacity30: opacity });
              }} d="M330.1,102.7c-2.2-0.3-4.3-0.6-6.3-0.9c-0.7-0.1-1.1-0.5-1.3-1.3c-0.3-1.2-0.8-2.3-0.9-3.5
	c-0.2-2.2,0.3-4.2,1.2-6.2c0.3-0.6,0.8-1.2,1.3-1.7c0.2-0.2,0.6-0.4,0.8-0.3c3.1,1,6,2.4,7.6,5.4c0.8,1.5,1.7,3.2,1.3,5.1
	c-0.1,0.4-0.2,1-0.5,1.3C332.5,101.5,331.5,102.6,330.1,102.7z M326.4,96.1c-0.4-1-0.7-2.1-1.2-3.1c-0.2-0.4-0.9-0.6-1.3-0.6
	c-0.4,0-1,0.4-1.2,0.7c-0.9,1.9-0.7,3.9-0.1,5.9c0.1,0.4,0.8,1,1.3,1c0.5,0,1.3-0.4,1.6-0.9C326,98.2,326.1,97.2,326.4,96.1z" />
        <path fillOpacity={this.state.opacity31 ? this.state.opacity31 : 1} stroke={borderColor} fill={this.state.color31 ? this.state.color31 : 'transparent'}
              onClick={() => {
                this.setState({ color31: color, opacity31: opacity });
              }} d="M96.4,466.4c-2,0.1-4.1,0.5-5.9,0.2c-1.2-0.2-2.5-1.4-3.2-2.5c-1.2-1.9-2.6-3.5-4-5.1c-1.1-1.2-2.2-2.1-3.5-2.9
	c-0.1-0.1-0.1-0.6,0-0.9c0.5-1.5,1.1-3,1.6-4.5c0.1-0.3-0.1-0.7-0.3-1c-1.2-1.5-0.5-3.1,0-4.5c0.2-0.8,1.4-1.4,2.3-1.7
	c0.8-0.3,1.8-0.4,2.7-0.4c0.8,0,1.4,0.4,1.6,1.3c0.2,0.9-0.3,1.1-0.9,1.5c-1.1,0.7-2.2,1.5-3,2.5c-1.1,1.3-1.9,2.9-1.1,4.7
	c0.7,1.8,1.5,3.6,2.2,5.4c0.3,0.9,0.3,1.9,1.2,2.5c0.7,0.5,1.4,1.1,2.2,1.2c1.5,0.3,3.1,0.2,4.6,0.3C94.9,462.8,96,463.8,96.4,466.4
	z" />
        <path fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1} stroke={borderColor} fill={this.state.color32 ? this.state.color32 : 'transparent'}
              onClick={() => {
                this.setState({ color32: color, opacity32: opacity });
              }} d="M99.4,464.7c1.1-0.5,1.8-0.5,2.6,0.5c0.5,0.7,1.7,0.9,2.6,1.3c0.5,0.2,0.9,0.4,1.6,0.7c-1.1,0.2-2,0.4-2.8,0.5
	c-1.4,0.1-2.8,0.2-4,1.1c-0.4,0.4-0.8,0.8-1.1,1.3c-2.3,3.5-4.6,7.1-7,10.6c-0.5,0.7-1.2,1.2-1.9,1.8c-0.1,0.1-0.3,0.2-0.5,0.2
	c-1.6,0.1-3.2,0.2-5,0.2c4.9-4,8.2-9.3,11.7-14.3c1.2-0.2,2.5-0.3,3.6-0.7c0.3-0.1,0.3-1.2,0.4-1.8
	C99.6,465.7,99.5,465.1,99.4,464.7z" />
        <path fillOpacity={this.state.opacity33 ? this.state.opacity33 : 1} stroke={borderColor} fill={this.state.color33 ? this.state.color33 : 'transparent'}
              onClick={() => {
                this.setState({ color33: color, opacity33: opacity });
              }} d="M106.4,467.5c1.3,1.9,2.3,3.8,1.7,6.2c-0.6,2.3-0.5,4.5,0.3,6.7c0.1,0.3-0.3,1-0.6,1.1
	c-1.2,0.4-2.4,0.6-3.6,0.8c-2,0.3-4.1,0.5-6.2,0.6C101.7,478.2,104.7,473.3,106.4,467.5z" />
        <path fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1} stroke={borderColor} fill={this.state.color34 ? this.state.color34 : 'transparent'}
              onClick={() => {
                this.setState({ color34: color, opacity34: opacity });
              }} d="M229.8,247.3c-1.3,0.5-2.3,1-3.4,1.3c-2.2,0.6-3.7,1.9-5,3.8c-1.5,2.1-3,4.3-5.4,5.5c-1.1,0.5-1.5,0.3-1.8-0.9
	c-0.3-1.4,0.2-2.7,0.5-4c0.1-0.2,0.3-0.4,0.4-0.5c1.7-1.4,3.3-2.7,5-4c1.6-1.3,3.2-2.5,4.9-3.7c0.9-0.6,2.6-0.2,2.9,0.7
	C228.1,246.5,229,246.5,229.8,247.3z" />
        <path fillOpacity={this.state.opacity35 ? this.state.opacity35 : 1} stroke={borderColor} fill={this.state.color35 ? this.state.color35 : 'transparent'}
              onClick={() => {
                this.setState({ color35: color, opacity35: opacity });
              }} d="M297.3,461.5c-2.5,0.6-4.9,1.2-7.4,1.7c-2,0.4-4.1,0.6-6.2,0.9c-1.4,0.3-2.7,0.1-4-0.5
	c-0.8-0.4-0.8-0.9-0.1-1.3c1.3-0.7,2.6-1.4,4-2c0.2-0.1,0.6-0.1,0.8-0.1c1.5,0.2,1.6,0.2,1.3-1.4
	C289.6,459.7,293.7,459.5,297.3,461.5z" />
        <path fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1} stroke={borderColor} fill={this.state.color36 ? this.state.color36 : 'transparent'}
              onClick={() => {
                this.setState({ color36: color, opacity36: opacity });
              }} d="M91.4,482.7c1.1-1.2,2.2-2.3,3.1-3.6c1.6-2.2,3.1-4.6,4.6-6.9c0.3-0.5,0.7-1.1,0.9-1.6c0.6-1.2,2.4-1.9,4.2-1.6
	c-1,2-2,4-3.1,5.9c-1.4,2.3-3,4.3-4.3,6.6c-0.9,1.6-2.4,1.3-3.7,1.4C92.6,483,92,482.8,91.4,482.7z" />
        <path fillOpacity={this.state.opacity37 ? this.state.opacity37 : 1} stroke={borderColor} fill={this.state.color37 ? this.state.color37 : 'transparent'}
              onClick={() => {
                this.setState({ color37: color, opacity37: opacity });
              }} d="M93.7,468.8c-2.2,2.9-4.5,6-6.9,9c-1.1,1.3-2.4,2.5-3.6,3.7c-1.5,1.4-3.2,1.6-5.2,1.2c-0.3-0.1-0.7-0.1-1.3-0.1
	c1.2-0.8,2.3-1.3,3.2-2.2c1.8-1.7,3.6-3.4,5.3-5.2c1.7-1.9,3.3-4.1,4.9-6.1c0.1-0.2,0.5-0.2,0.7-0.2
	C91.8,468.8,92.8,468.8,93.7,468.8z" />
        <path fillOpacity={this.state.opacity38 ? this.state.opacity38 : 1} stroke={borderColor} fill={this.state.color38 ? this.state.color38 : 'transparent'}
              onClick={() => {
                this.setState({ color38: color, opacity38: opacity });
              }} d="M186,370.4c-1.6,0.1-2.6,1-3.3,2.4c-1.2,2.3-2.3,4.5-3.8,6.6c-1.6,2.3-3.5,4.4-5.4,6.6
	c-0.2,0.2-0.5,0.3-0.7,0.3c-1.1,0-2.3,0-3.8,0c0.7-0.7,1.2-1.3,1.7-1.6c2.6-1.7,4.1-4.3,6-6.7c1.1-1.4,2.1-2.9,3.2-4.4
	c0.3-0.4,0.6-0.8,0.7-1.2c0.2-1,0.5-1.6,1.7-1.5c0.3,0,0.6-0.4,0.9-0.7C184.2,369.1,185.1,369.2,186,370.4z" />
        <path fillOpacity={this.state.opacity39 ? this.state.opacity39 : 1} stroke={borderColor} fill={this.state.color39 ? this.state.color39 : 'transparent'}
              onClick={() => {
                this.setState({ color39: color, opacity39: opacity });
              }} d="M311.1,90.1c-1.1,0.7-2.3,1.4-3.6,2.3c0.4,0.3,0.7,0.4,1.2,0.7c-1.3,2.2-2.6,4.4-3.9,6.7
	c-0.4,0.7-1,0.6-1.5,0.3c-1.6-1.3-2.9-3.2-2.5-5.6c0.3,0.9,0.6,2.1,1.2,3.1c0.5,1,1.4,0.9,2.1,0c1-1.4,0.7-2.9,0.6-4.5
	c0-0.5-0.2-1.1-0.4-1.5c-0.8-1.1-2.1-0.8-2.9,0.4c-0.1,0-0.2-0.1-0.3-0.1c0.6-1.3,1-2.7,1.7-3.9c0.2-0.3,1.2-0.2,1.8-0.2
	c0.8,0.1,1.5,0.3,2.2,0.7c0.5,0.2,1.2,0.3,1,1.2c0,0.1,0.9,0.6,1.4,0.6C309.9,90.4,310.5,90.2,311.1,90.1L311.1,90.1z" />
        <path fillOpacity={this.state.opacity40 ? this.state.opacity40 : 1} stroke={borderColor} fill={this.state.color40 ? this.state.color40 : 'transparent'}
              onClick={() => {
                this.setState({ color40: color, opacity40: opacity });
              }} d="M172.7,367.3c-0.9-2.1-2.5-2.9-4.3-3.4c-0.4-0.1-0.7-1.2-0.6-1.7c0.1-0.6,0.7-1.1,1.1-1.6
	c0.4-0.5,0.9-0.9,1.2-1.5c0.9-1.6,2.8-2.5,4.5-2.1c1.1,0.3,1.6,1.2,1.1,2.3c-0.9,2.1-1.8,4.2-2.7,6.4
	C172.9,366.1,172.9,366.5,172.7,367.3z" />
        <path fillOpacity={this.state.opacity41 ? this.state.opacity41 : 1} stroke={borderColor} fill={this.state.color41 ? this.state.color41 : 'transparent'}
              onClick={() => {
                this.setState({ color41: color, opacity41: opacity });
              }} d="M176.2,386c1.7-2.3,3.2-4.7,5-6.9c1.6-1.9,2.4-4.2,3.6-6.3c0.3-0.5,1.1-0.7,1.7-1.1c0.1,0.3,0.4,0.5,0.4,0.8
	c-0.3,2-0.9,3.8-2,5.6c-1.3,1.9-2.4,4-3.6,6C180.1,386.1,178.2,386.5,176.2,386z" />
        <path fillOpacity={this.state.opacity42 ? this.state.opacity42 : 1} stroke={borderColor} fill={this.state.color42 ? this.state.color42 : 'transparent'}
              onClick={() => {
                this.setState({ color42: color, opacity42: opacity });
              }} d="M179.4,371.5c-1.3,1.9-2.5,3.6-3.7,5.2c-1.4,1.8-2.8,3.6-4.2,5.4c-0.3,0.4-0.7,0.8-1,1.1
	c-0.7,0.7-1.5,1.3-2.1,2.1c-1.3,1.6-3.2,1.6-4.9,2.1c-0.1-0.1-0.2-0.2-0.3-0.3c1.1-1.1,2.1-2.3,3.3-3.4c3.3-2.9,6.6-5.9,8.8-9.8
	c0.1-0.1,0.1-0.3,0.2-0.4C176.6,371.3,176.6,371.3,179.4,371.5z" />
        <path fillOpacity={this.state.opacity43 ? this.state.opacity43 : 1} stroke={borderColor} fill={this.state.color43 ? this.state.color43 : 'transparent'}
              onClick={() => {
                this.setState({ color43: color, opacity43: opacity });
              }} d="M353.1,115c-0.3-2.7,0.7-5.1,1.7-7.5c1.9,0.4,4.1-0.8,5.1-2.8c0.5-0.8,1-1.4,0.5-2.5c-0.3-0.7,0.2-1.6,0.3-2.4
	c0.3,0.3,0.9,0.7,1.4,1.1C360.3,108.1,357.4,112.7,353.1,115z" />
        <path fillOpacity={this.state.opacity44 ? this.state.opacity44 : 1} stroke={borderColor} fill={this.state.color44 ? this.state.color44 : 'transparent'}
              onClick={() => {
                this.setState({ color44: color, opacity44: opacity });
              }} d="M334.7,102.1c1.8-2.2,2.6-4.8,1.7-7.5c-0.8-2.2-1.6-4.5-3.5-6c-0.2-0.1-0.4-0.2-0.6-0.2
	c0.1-0.1,0.1-0.2,0.2-0.3c1,0.2,2,0.3,3,0.5c0.2,0,0.3,0.1,0.4,0.1c2-0.1,2.9,0.7,2.9,2.6c0,1.5-0.4,3-0.5,4.6
	c0,0.7,0.2,1.4,0.2,2.1c0,0.3,0,0.7-0.1,0.9C337.7,100.4,336.5,101.6,334.7,102.1z" />
        <path fillOpacity={this.state.opacity45 ? this.state.opacity45 : 1} stroke={borderColor} fill={this.state.color45 ? this.state.color45 : 'transparent'}
              onClick={() => {
                this.setState({ color45: color, opacity45: opacity });
              }} d="M115.2,85.1c1.6-0.8,3-1.6,4.5-2.4c0.5,1.5,1.2,3.1,3.1,3.7c-0.2,0.7-0.5,1.5-0.7,2.3
	C119.4,88.4,117.1,87.5,115.2,85.1z" />
        <path fillOpacity={this.state.opacity46 ? this.state.opacity46 : 1} stroke={borderColor} fill={this.state.color46 ? this.state.color46 : 'transparent'}
              onClick={() => {
                this.setState({ color46: color, opacity46: opacity });
              }} d="M352.6,85.8c-0.6,1.8-1.5,2.5-3.2,2.8c-1.9,0.3-3.7,1.2-5.5,1.9c-0.4,0.2-0.8,0.4-1.2,0.6
	c-0.6-0.7-1.1-1.3-1.8-2.2c1.3-0.2,2.3-0.2,3.2-0.5c1.5-0.5,2.9-1.2,4.4-1.7C349.8,86.3,351.1,86.1,352.6,85.8z" />
        <path fillOpacity={this.state.opacity47 ? this.state.opacity47 : 1} stroke={borderColor} fill={this.state.color47 ? this.state.color47 : 'transparent'}
              onClick={() => {
                this.setState({ color47: color, opacity47: opacity });
              }} d="M298.4,90.5c0.1,2.1,0.2,4.3,0.1,6.4c0,0.8-0.6,1.5-1,2.5c-1.1-1.5-1.9-3-2.3-4.8c-0.4-2.1-0.4-2.1,1.5-3.2
	C297.4,91.1,297.9,90.7,298.4,90.5z" />
        <path fillOpacity={this.state.opacity48 ? this.state.opacity48 : 1} stroke={borderColor} fill={this.state.color48 ? this.state.color48 : 'transparent'}
              onClick={() => {
                this.setState({ color48: color, opacity48: opacity });
              }} d="M349.3,126.7c1.1-3.4,2.1-6.9,3.2-10.3c0.9,0,1.5,0,2.3,0c-1.1,3.8-2.1,7.6-5.1,10.3
	C349.5,126.7,349.4,126.7,349.3,126.7z" />
        <path fillOpacity={this.state.opacity49 ? this.state.opacity49 : 1} stroke={borderColor} fill={this.state.color49 ? this.state.color49 : 'transparent'}
              onClick={() => {
                this.setState({ color49: color, opacity49: opacity });
              }} d="M333.1,91.8c-2.9-2.1-5.9-4.2-9.8-4.5C326.9,84.9,331.2,87.7,333.1,91.8z" />
        <path fillOpacity={this.state.opacity50 ? this.state.opacity50 : 1} stroke={borderColor} fill={this.state.color50 ? this.state.color50 : 'transparent'}
              onClick={() => {
                this.setState({ color50: color, opacity50: opacity });
              }} d="M181,368.9c-1.6,1.2-4.4,1.2-5.9-0.1c-0.2-0.2-0.3-0.7-0.2-0.9c0.1-0.2,0.5-0.6,0.8-0.5c1,0.2,2.1,0.4,3.1,0.7
	C179.4,368.3,180.1,368.6,181,368.9z" />
        <path fillOpacity={this.state.opacity51 ? this.state.opacity51 : 1} stroke={borderColor} fill={this.state.color51 ? this.state.color51 : 'transparent'}
              onClick={() => {
                this.setState({ color51: color, opacity51: opacity });
              }} d="M311.1,90.2c0.9-1.2,3.2-2.1,4.6-1.7c0.3,0.1,0.5,0.5,0.7,0.8c0.2,0.4,0.3,0.9,0.4,1.1
	C315.1,90.3,313.1,90.2,311.1,90.2L311.1,90.2z" />
        <path fillOpacity={this.state.opacity52 ? this.state.opacity52 : 1} stroke={borderColor} fill={this.state.color52 ? this.state.color52 : 'transparent'}
              onClick={() => {
                this.setState({ color52: color, opacity52: opacity });
              }} d="M294.7,91c0-1.9,2.2-3.4,4.8-3.1C297.7,89,296.2,90,294.7,91z" />
        <path fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1} stroke={borderColor} fill={this.state.color53 ? this.state.color53 : 'transparent'}
              onClick={() => {
                this.setState({ color53: color, opacity53: opacity });
              }}
              d="M89.3,235.3c0.8-1.1,1.4-2,2.1-2.7c0.6-0.7,1.2-0.4,1.9,0.9C92,234.1,90.7,234.6,89.3,235.3z" />
        <path fillOpacity={this.state.opacity54 ? this.state.opacity54 : 1} stroke={borderColor} fill={this.state.color54 ? this.state.color54 : 'transparent'}
              onClick={() => {
                this.setState({ color54: color, opacity54: opacity });
              }}
              d="M321.8,88.2c-0.6,1.5-1.1,2.8-1.7,4.3c-0.3-1.1-0.5-2-0.8-3C319.9,89.1,320.7,88.7,321.8,88.2z" />
        <path fillOpacity={this.state.opacity55 ? this.state.opacity55 : 1} stroke={borderColor} fill={this.state.color55 ? this.state.color55 : 'transparent'}
              onClick={() => {
                this.setState({ color55: color, opacity55: opacity });
              }} d="M121.9,213.3c-0.3-0.4-0.7-0.8-1-1.3c-0.2-0.4-0.4-0.9-0.5-1.4c0.5-0.1,0.9-0.3,1.6-0.5c0.1,1.1,0.3,2,0.4,2.9
	C122.2,213.2,122.1,213.3,121.9,213.3z" />
        <path fillOpacity={this.state.opacity56 ? this.state.opacity56 : 1} stroke={borderColor} fill={this.state.color56 ? this.state.color56 : 'transparent'}
              onClick={() => {
                this.setState({ color56: color, opacity56: opacity });
              }} d="M363.2,96.7c-0.8,0.9-1.5,1.8-2.4,2.8c-0.3-0.6-0.5-1-0.7-1.4c0.9-0.6,1.9-1.1,2.8-1.7
	C363,96.5,363.1,96.6,363.2,96.7z" />
        <path fillOpacity={this.state.opacity57 ? this.state.opacity57 : 1} stroke={borderColor} fill={this.state.color57 ? this.state.color57 : 'transparent'}
              onClick={() => {
                this.setState({ color57: color, opacity57: opacity });
              }}
              d="M348.6,92.9c-0.2-0.8-0.4-1.6-0.6-2.4c0.7-0.1,1.4-0.3,2.1-0.4C349.6,91.1,349.1,91.9,348.6,92.9z" />
        <path fillOpacity={this.state.opacity58 ? this.state.opacity58 : 1} stroke={borderColor} fill={this.state.color58 ? this.state.color58 : 'transparent'}
              onClick={() => {
                this.setState({ color58: color, opacity58: opacity });
              }} d="M122.2,82.6c-0.3-0.6-0.7-1.1-0.7-1.6c0-0.3,0.6-0.6,1-0.9C123.1,81.1,123.7,81.8,122.2,82.6z" />
        <path fillOpacity={this.state.opacity59 ? this.state.opacity59 : 1} stroke={borderColor} fill={this.state.color59 ? this.state.color59 : 'transparent'}
              onClick={() => {
                this.setState({ color59: color, opacity59: opacity });
              }} d="M303.7,92.5c-0.7,0.3-1.2,0.5-1.7,0.7c-0.1-0.4-0.3-0.9-0.2-1C302.3,91.5,302.9,91.7,303.7,92.5z" />

        <path fillOpacity={this.state.opacity60 ? this.state.opacity60 : 1} stroke={borderColor} fill={this.state.color60 ? this.state.color60 : 'transparent'}
              onClick={() => {
                this.setState({ color60: color, opacity60: opacity });
              }} d="M325.9,120.3c-0.7,2.1-1.5,3.6-2.5,5.1c-1.1,1.7-2.5,2.4-4.3,2.8c-2.2,0.5-5-1-5.8-3.1
	c-0.3-0.8-0.5-1.6-0.8-2.5C317.1,122.6,321.5,122.5,325.9,120.3z" />
        <path fillOpacity={this.state.opacity61 ? this.state.opacity61 : 1} stroke={borderColor} fill={this.state.color61 ? this.state.color61 : 'transparent'}
              onClick={() => {
                this.setState({ color61: color, opacity61: opacity });
              }} d="M323.9,95.5c-1.2-0.9-0.8-1.5-0.4-2c0.1-0.1,0.5-0.1,0.8-0.1C324.2,94,324.1,94.6,323.9,95.5z" />
      </SvgIcon>
    )
  }

};