import React, { useEffect, useState } from 'react';
import DrawingImage from './DrawingImage';
import { connect } from 'react-redux'
import {
  addPageDrawStateItem,
  draggableImageMoved, setMaskImages,
  setPageChangedState,
  setPageSelectedShape, showDivider,
  updatePageDrawStateItem
} from "../actions";

const VisibleDrawingImage = ({ drawables, image, name, dispatch, boxContainer, layer2, edition, userName, fieldRectangles }) => {
  const [mounted, setMounted] = useState(false);
  useEffect(()=>{
    if (!mounted) {
      dispatch(setPageChangedState({ [name]: false }));
    } else {
      setMounted(true);
    }
  }, [mounted, dispatch, name])
  return (
    <DrawingImage
      drawables={drawables}
      image={image}
      name={name}
      layer2={layer2}
      fieldRectangles={fieldRectangles}
      boxContainer={boxContainer}
      onAddDrawingShape={(item: any, drawType) => {
        dispatch(addPageDrawStateItem({ [name]: item }, drawType));
      }}
      onDraggableImageMoved={()=>{
        dispatch(draggableImageMoved(true));
      }}
      onUpdateShape={(item: any) => {
        dispatch(updatePageDrawStateItem({ [name]: item, name: name }));
      }}
      onSelectedShape={(item: any) => {
        dispatch(setPageSelectedShape({ [name]: item, name: name }));
      }}
      onExportDrawingFile={() => {
        dispatch(setPageChangedState({ [name]: true }));
      }}
      onSave={() => {
        dispatch(setMaskImages(false));
      }}
      edition={edition}
      userName={userName}
    />
  )
}

const getVisibleDrawables = (drawables) => {
  return drawables;
}

const mapStateToProps = state => ({
  drawables: getVisibleDrawables(state.drawables)
})

export default connect(
  mapStateToProps
)(VisibleDrawingImage)

