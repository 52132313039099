import React, { Component } from "react";
import { Line, Rect } from "react-konva";
import uuid from "uuid-random";
import _ from "lodash";

export default class PolygonDrawable extends Component<any, any> {
  drawableType = 'PolygonDrawable';
  id;
  color = 'black';
  weight = 2;
  fill = 'transparent';
  opacity = 1;
  isPolygonCurveLine = false;
  isInitState = false;

  constructor(props) {
    super(props);
    const { x, y, initState, initProperties } = props;
    if (initState) {
      this.isInitState = true;
      let { moveLinePos, points } = initState;
      points = points.map((item, index) => {
        if (_.isArray(item)) {
          item[0] += moveLinePos.x;
          item[1] += moveLinePos.y;
        } else {
          if (index % 2) {
            item += moveLinePos.y;
          } else {
            item += moveLinePos.x;
          }
        }
        return item;
      })
      moveLinePos = {
        x: 0,
        y: 0
      }
      initState.moveLinePos = moveLinePos;
      initState.points = points;

      this.state = initState;
      _.assign(this, initProperties);
    } else {
      this.state = {
        points: [],
        curMousePos: [x, y],
        isMouseOverStartPoint: false,
        isFinished: false,
        dragLine: '',
        dragPointIndex: '',
        moveLinePos: {
          x: 0,
          y: 0
        }
      };
      this.id = uuid();
    }
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    const { x, y, type } = this.props;
    if (prevProps.type !== this.props.type || prevProps.x !== this.props.x || prevProps.y !== this.props.y) {
      if (type === 'mousedown') {
        this.handleMouseDown(x, y);
      } else if (type === 'mousemove') {
        this.registerMovement(x, y);
      }
    }
    if (!prevState.isFinished && this.state.isFinished) {
      this.props.onFinish(this);
    }
  }

  componentDidMount() {
    const { x, y, color, weight, opacity } = this.props;
    this.color = color;
    this.weight = weight;
    this.opacity = opacity ? 0.5 : 1;
    this.handleMouseDown(x, y);
  }

  handleMouseDown = (x, y) => {
    const {
      state: { points, isMouseOverStartPoint, isFinished }
    } = this;
    const mousePos = [x, y];

    if (isFinished) {
      return;
    }
    if (isMouseOverStartPoint && points.length >= 3) {
      this.setState({
        isFinished: true
      });
    } else {
      this.setState({
        points: [...points, mousePos]
      });
    }
  };

  registerMovement = (x, y) => {
    const mousePos = [x, y];
    this.setState({
      curMousePos: mousePos
    });
  };

  handleMouseOverStartPoint = event => {
    if (this.state.isFinished || this.state.points.length < 3) return;
    event.target.scale({ x: 2, y: 2 });
    this.setState({
      isMouseOverStartPoint: true
    });
  };

  handleMouseOutStartPoint = event => {
    event.target.scale({ x: 1, y: 1 });
    this.setState({
      isMouseOverStartPoint: false
    });
  };

  handleDragStartPoint = event => {

  };

  handleDragMovePoint = event => {
    const points = this.state.points;
    const index = event.target.attrs.index;
    const pos = [event.target.attrs.x - this.state.moveLinePos.x, event.target.attrs.y - this.state.moveLinePos.y];
    this.setState({
      points: [...points.slice(0, index), pos, ...points.slice(index + 1)],
      dragPointIndex: uuid()
    });
  };

  handleDragEndPoint = (event) => {
    this.props.onChange(this);
  }

  handleDragStartLine = (event) => {
    this.setState({
      dragLine: 'start'
    })
  }

  handleDragMoveLine = (event) => {

  }

  handleDragEndLine = (event) => {
    const attrsPoints = [...event.target.attrs.points];
    const x = event.target.attrs.x;
    const y = event.target.attrs.y;
    const len = attrsPoints.length / 2;
    const points = [];
    for (let i = 0; i < len; i++) {
      points.push([attrsPoints[i * 2], attrsPoints[i * 2 + 1]]);
    }
    this.setState({
      dragLine: 'end',
      points,
      moveLinePos: {
        x,
        y
      }
    }, () => {
      this.props.onChange(this);
    });
  }

  handleClickLine = (event) => {

  }

  getCurvePoints = (points, tension?, isClosed?, numOfSegments?) => {
    const pts = points
      .reduce((a, b) => a.concat(b), []);
    // use input value if provided, or use a default value
    tension = (typeof tension != 'undefined') ? tension : 0.5;
    isClosed = isClosed ? isClosed : false;
    numOfSegments = numOfSegments ? numOfSegments : 16;

    var _pts = [], res = [],	// clone array
      x, y,			// our x,y coords
      t1x, t2x, t1y, t2y,	// tension vectors
      c1, c2, c3, c4,		// cardinal points
      st, t, i;		// steps based on num. of segments

    // clone array so we don't change the original
    //
    _pts = pts.slice(0);

    // The algorithm require a previous and next point to the actual point array.
    // Check if we will draw closed or open curve.
    // If closed, copy end points to beginning and first points to end
    // If open, duplicate first points to befinning, end points to end
    if (isClosed) {
      _pts.unshift(pts[pts.length - 1]);
      _pts.unshift(pts[pts.length - 2]);
      _pts.unshift(pts[pts.length - 1]);
      _pts.unshift(pts[pts.length - 2]);
      _pts.push(pts[0]);
      _pts.push(pts[1]);
    } else {
      _pts.unshift(pts[1]);	//copy 1. point and insert at beginning
      _pts.unshift(pts[0]);
      _pts.push(pts[pts.length - 2]);	//copy last point and append
      _pts.push(pts[pts.length - 1]);
    }

    // ok, lets start..

    // 1. loop goes through point array
    // 2. loop goes through each segment between the 2 pts + 1e point before and after
    for (i = 2; i < (_pts.length - 4); i += 2) {
      for (t = 0; t <= numOfSegments; t++) {

        // calc tension vectors
        t1x = (_pts[i + 2] - _pts[i - 2]) * tension;
        t2x = (_pts[i + 4] - _pts[i]) * tension;

        t1y = (_pts[i + 3] - _pts[i - 1]) * tension;
        t2y = (_pts[i + 5] - _pts[i + 1]) * tension;

        // calc step
        st = t / numOfSegments;

        // calc cardinals
        c1 = 2 * Math.pow(st, 3) - 3 * Math.pow(st, 2) + 1;
        c2 = -(2 * Math.pow(st, 3)) + 3 * Math.pow(st, 2);
        c3 = Math.pow(st, 3) - 2 * Math.pow(st, 2) + st;
        c4 = Math.pow(st, 3) - Math.pow(st, 2);

        // calc x and y cords with common control vectors
        x = c1 * _pts[i] + c2 * _pts[i + 2] + c3 * t1x + c4 * t2x;
        y = c1 * _pts[i + 1] + c2 * _pts[i + 3] + c3 * t1y + c4 * t2y;

        //store points in array
        res.push(x);
        res.push(y);

      }
    }

    return res;
  }

  render() {
    const {
      state: { points, isFinished, curMousePos, dragLine, moveLinePos },
      props,
      handleMouseOverStartPoint,
      handleMouseOutStartPoint,
      handleDragStartPoint,
      handleDragMovePoint,
      handleDragEndPoint,
      handleDragStartLine,
      handleDragMoveLine,
      handleDragEndLine,
      color,
      weight,
      opacity
    } = this;
    this.fill = this.isInitState ? this.fill : props.fill;
    this.isInitState = false;
    const enableSelectShape = props.selectShape === this.id;
    const enableRenderRects = dragLine !== 'start' && ((enableSelectShape && props.dragImgEnabled) || !isFinished);
    let flattenedPoints;
    if (props.transformPolygonToCurveLine && enableSelectShape && isFinished) {
      this.isPolygonCurveLine = !this.isPolygonCurveLine;
    }
    if (this.isPolygonCurveLine && !props.dragImgEnabled) {
      flattenedPoints = this.getCurvePoints(this.state.points, 0.5, true);
    } else {
      // [ [a, b], [c, d], ... ] to [ a, b, c, d, ...]
      flattenedPoints = points
        .concat(isFinished ? [] : curMousePos)
        .reduce((a, b) => a.concat(b), []);
    }
    return (
      <>
        <Line
          points={flattenedPoints}
          stroke={color}
          strokeWidth={weight}
          fill={this.fill}
          opacity={opacity}
          closed={isFinished}
          draggable={props.dragImgEnabled}
          onDragStart={handleDragStartLine}
          onDragMove={handleDragMoveLine}
          onDragEnd={handleDragEndLine}
          onClick={() => {
            if (isFinished) {
              props.onSelect(this);
            }
          }}
        />
        {points.map((point, index) => {
          const width = weight * 2 < 6 ? 6 : weight * 2;
          const x = point[0] - width / 2 + moveLinePos.x;
          const y = point[1] - width / 2 + moveLinePos.y;
          const startPointAttr =
            index === 0
              ? {
                hitStrokeWidth: weight * 4 < 12 ? 12 : weight * 4,
                onMouseOver: handleMouseOverStartPoint,
                onMouseOut: handleMouseOutStartPoint
              }
              : null;
          return (
            enableRenderRects && <Rect
              key={index}
              index={index}
              x={x}
              y={y}
              width={width}
              height={width}
              fill="transparent"
              stroke={color}
              strokeWidth={weight / 2 < 3 ? 3 : weight}
              onDragStart={handleDragStartPoint}
              onDragMove={handleDragMovePoint}
              onDragEnd={handleDragEndPoint}
              draggable
              {...startPointAttr}
            />
          );
        })}
      </>
    );
  }

}