import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_5_4 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 212 218"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M100.4,137.3c-2.3-1.1-3.2-3.2-4.2-5.1c0-0.9,0.1-0.9,0.2-1h1c-0.4-0.6-0.8-1.1-1.1-1.6c0-0.1,0.1-0.2,0.1-0.3
	c1,0.3,1.9,0.5,3,0.8c-1.2-1.4-2.2-2.7-3.4-4.1c-0.1,0.2-0.3,0.5-0.5,0.9c-1.7,3.4-4.6,5-8.2,5.6c-1.1,0.2-1.8,0.8-1.7,1.8
	c0.1,1.5,0.5,3,0.7,4.6c0,0.1,0.1,0.1,0.1,0.2c2.6,1.4,5.1,3.1,8.4,1.8c-0.5-0.6-1.1-1.2-1.6-1.8c0-0.1,0.1-0.2,0.1-0.3l3.3,1.1
	c-1.4-1.5-3-2.8-3.1-5.2C95.3,136.7,97.7,137.2,100.4,137.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M56.2,217.6c-0.1-2.8,1.2-9.1,2.4-10.9c13.7,5.5,27.6,5.1,41.7,0.7c0.5,2.6,1,5.3,1.5,7.9
	c0.1,0.3,0.2,0.5,0.2,0.8c0,1,0.5,1.3,1.4,1.3c0.3,0,0.5,0.1,0.8,0.2C88.2,217.6,72.2,217.6,56.2,217.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M54.8,150.7c0.6-1.4,1.3-3.1,2-4.8c1.5-3.5,3.8-6,7.4-7.4c1.9-0.8,3.4-0.5,5,0.7c-0.5,1-1.2,1.9-1.5,2.9
	c-0.3,1.2,0.8,3,2.1,3.5c0.8,0.3,0.9,0.7,1.1,1.3c0.7,2.7,2.1,3.7,4.8,3.7c0.1,0,0.2,0,0.3,0c1.6,4.2,3,5.1,5.8,4.1
	c0.1,1.6,0.7,2.9,2.2,3.6c1.7,0.8,2.9-0.2,3.9-1.5c0.4,1,0.5,2.1,1.9,1.9c0.9-0.2,1.3-1.3,1-2.9c2.3-0.7,2.4-1.7,0.4-5.5
	c0.3-0.4,0.7-0.8,0.9-1.2c0.5-1.1,0.1-2.1-1.2-3.2c-0.3-0.2-0.5-0.5-0.9-0.6c-1.6-0.6-2.3-2.1-3.2-3.4c-0.2-0.2-0.4-0.4-0.9-0.8
	c0-0.2,0-0.7,0-1.4c2.6,1.5,5.3,2.8,7.6,4.5c2.6,2,4.4,4.7,4.9,8.1c0.3,2.2,0.6,4.3,0.7,6.5c-1.6-3.7-3.2-7.4-4.7-11
	c-0.2,0-0.3,0.1-0.5,0.1c0,0.4,0,0.8,0.2,1.2c0.9,2.3,2,4.6,2.8,7c0.8,2.6,1.6,5.3,1.7,8c0.2,5.2,0,10.4-0.2,15.6
	c-0.1,3.5-0.3,6.9-0.4,10.4c-0.1,3.7,0.9,7.3,2,10.9c0.3,1.1,0.7,2.2,1,3.3c0.3,1.3-0.3,2.3-1.6,2.6c-3.7,0.9-7.5,1.9-11.3,2.4
	c-3.9,0.5-8,0.6-11.9,0.5c-4-0.2-7.9-0.9-11.7-2.2c-1.3-0.4-2.7-0.6-3.9-1.2c-1.3-0.6-2.4-1.5-3.3-2.2c1.1-3.3,2.4-6.3,2.9-9.4
	c0.6-3.5,0.4-7.1,0.3-10.7c0-1.6,0.1-2.7,1.5-3.6c2.1-1.3,2.8-3.7,3.1-5.9c0.5-3.6,0.7-7.2,1.3-10.7c0.3-2.2,0.8-4.5,3-5.8
	c0.2-0.1,0.3-0.3,0.3-0.6c-2.1,0.7-3.5,2.2-4,4.4c-0.5,2-0.8,4-1.2,6.3c-0.5-2.1-1.1-3.9-2.7-5.1c-1-0.7-1.4-1.7-1.9-2.8
	C58.7,157,56.7,153.9,54.8,150.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M17.3,13.1c-0.6,1-1.3,1.9-1.8,2.9c-0.5,1-0.6,2.2-0.7,3.4c0.3-0.7,0.6-1.4,1-2.1c1.8-4.2,5-6.8,9.6-7.5
	c2.7-0.4,5.2-0.5,7.8,0.3c2.8,0.9,5.5,2.2,7.3,4.5c1.3,1.5,2.2,3.4,2.9,5.2c1.8,4.3,3,8.8,2.8,13.3c-0.2,5.6-1.4,11.1-4.2,16.1
	c-1.4,2.5-3.5,4.4-5.8,6c-1.1,0.8-2.4,0.7-3.8,0.6c-0.9-0.1-1.7,0-2.6,0c-0.4,0-0.7,0.1-1.1,0c-4.1-1.3-7.8-3.3-10.9-6.3
	c-1.8-1.7-3.7-3.3-5.4-5c-2.2-2.2-3.9-4.8-5.4-7.5c-1.5-2.8-2.8-5.7-3.5-8.9c-0.7-3.1-0.9-6.2,0.6-9.2c1.5-3.1,4.2-4.6,7.4-5.5
	c1.8-0.5,3.7-0.5,5.5-0.7C17.2,12.8,17.3,12.9,17.3,13.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M43.2,16.3c1.5-1.5,3.4-2.8,5.4-3.3c2.5-0.6,5-1,7.5-1.1c2.3-0.1,4.5,0.5,6.6,1.6c3,1.5,5.8,3.2,8.2,5.5
	c1.8,1.8,2.8,4,3.8,6.2c1.4,3.2,1.9,6.6,1.9,10c-0.1,3.7-0.9,7.1-3,10.2c-1.6,2.3-3.1,4.8-5,6.8c-2.8,2.9-6.5,4.5-10.5,4.7
	c-0.1-0.4-0.2-0.8-0.4-0.9c-1.3-0.8-2.7-1.6-4.3-1c-1.5,0.6-2.5,0-3.5-0.9c-0.8-0.7-1.5-1.7-2.1-2.6c-0.9-1.3-1.6-2.6-2.5-3.9
	c-1-1.4-0.1-2.6,0.2-4c0.6-2.8,1.3-5.7,1.7-8.5c0.3-2.4,0.4-4.9,0-7.3c-0.5-2.8-1.5-5.6-2.4-8.4C44.4,18.6,43.8,17.5,43.2,16.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M66.2,118c-0.2,0.4-0.4,0.8-0.6,1.2c-2.1-0.6-3.6-1.8-4.5-3.6c-0.5-1-0.4-2.5,0.1-3.5c0.5-1.1,2-1.2,3-0.7
	c0.8,0.4,1.4,1.3,1.8,2.1c0.4,0.9,0.8,0.8,1.6,0.4c-0.7-1-1.3-2-2-3.1c1.7-2.8,1.3-5.5-0.9-8.1c1-0.1,2,0.1,2.6-0.4
	c0.5-0.4,0.4-1.5,0.6-2.3c0.4,0,1.1,0,2,0c-0.3-0.6-0.6-1-0.8-1.4c1.3-1.4,2.8-0.1,4.5-0.5c-0.8-0.6-1.5-1-2.1-1.4
	c0-0.1,0-0.2,0.1-0.3c2.3,0.5,4.5,1.1,7,1.6c-0.3-0.6-0.5-0.8-0.7-1.2c4.1,0.7,7,2.3,8.7,4c-0.7-0.1-1.3-0.3-1.9-0.4
	c-0.3,0-0.7,0-1.2,0.1c0.9,0.8,2.4,0.6,2.5,1.9c0,0.3-0.4,0.6-0.8,1.1c-0.1-0.8-0.1-1.2-0.2-1.7c-0.1-0.1-0.2-0.1-0.3-0.2
	c-0.2,0.3-0.4,0.7-0.5,1c-0.2,0.4-0.4,0.8-0.6,1.1c-0.2,0-0.3-0.1-0.5-0.1c-0.2-0.8-0.4-1.5-0.7-2.5c-0.1,0-0.4,0-0.7-0.1
	c0,2.7,0.1,3.1,1.3,4c-1.4,0.5-2.5,1.4-3,2.7c0.1,0.1,0.2,0.2,0.3,0.3c0.3-0.3,0.6-0.5,0.8-0.8c1.7-2.2,3.8-2.2,5.4,0.2
	c0.5,0.8,0.9,1.7,1.5,2.9c0.3-0.5,0.6-1,0.9-1.5c1.9,0.4,3.3,1.6,4.6,3.2c2.9,3.7,3.8,7.8,2,12.2c-0.9,2.2-2.1,4.5-4.2,5.9
	c-2.3,1.5-4.8,2.2-7.6,1.7c-4.2-0.7-7.8-2.6-11-5.4c-0.3-0.3-0.7-0.3-1.2-0.3c3.6,3.8,8,6.2,13.5,6.6c-2,2.5-0.4,5.1-0.2,7.6
	c0.1,1.3,0,2.6,0.1,3.9c0,0.5,0.2,1.1,0.3,1.6c0.2,0,0.3,0,0.5,0c0.2-1,0.5-2.1,0.8-3.2c3,3.7,3.1,7.9,2.4,12.7
	c-1.1-0.6-2.1-1.1-3.1-1.7c-4.1-2.4-8.1-5-11.5-8.3c-1.4-1.3-1.7-2.9-2.2-4.9c1.8,0.6,3.5,1.2,5.2,1.7c-1.6-1.3-3.2-2.5-4.7-3.7
	c0.1-0.1,0.1-0.2,0.2-0.3c0.9,0.2,1.7,0.4,2.6,0.7c0-0.1,0-0.2,0-0.2c-1.2-0.8-2.3-1.7-3.6-2.6c1.4,0.1,2.5,0.3,3.7,0.3
	c1.1,0,2.4,0.3,3-1.1c-3.9-0.6-6.3-3-8.6-5.9c1.2,0.2,2.3,0.4,3.4,0.6c0.1-0.1,0.2-0.3,0.3-0.4c-2.7-1.9-4.8-4.2-5.3-7.6
	c0.6-0.4,1.2-0.9,1.8-1.4c0-0.2,0-0.3-0.1-0.5c-1.3,0.8-2.6,2-3.2-0.4c-0.2-0.9-0.3-0.9,1.2-2.3c-1.1-0.1-2.5,0.2-2.4-1.7
	c0.4,0,0.7-0.1,1.1-0.1c-1.1-2.2-2.2-3.1-4-2.7c-0.5,0.1-0.9,0.5-1.3,0.9c-0.1,0.1,0.2,0.5,0.3,0.8c1-1.5,2.4-1.4,3.2,0.1
	c0.1,0.2,0.1,0.5,0,0.7C64.4,116.9,64.6,117.3,66.2,118z M79.3,109.5c0.1-2.1-0.5-3.8-2.3-5c0.4-0.5,0.8-0.9,1.2-1.3
	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.6,0.4-1.3,0.9-1.9,1.3c-0.1-0.1-0.1-0.2-0.2-0.3c0.3-0.6,0.5-1.3,0.8-1.9c-0.6,0.2-1.2,0.5-1.3,0.8
	c-0.2,1.3-0.9,1-1.6,0.9c0-0.8,0-1.5,0-2.3c-0.4,0.2-0.7,0.3-1,0.4c0,0.8,0,1.6,0,2.3c-0.1,0-0.3,0-0.4,0c0-0.4-0.1-0.8-0.1-1.4
	c-0.4,0.1-0.8,0.2-1.1,0.3c0.2,0.6,0.3,1.1,0.5,1.6c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4-0.3-0.8-0.5-1.3-0.9c0,0.7,0.2,1.4,0,1.9
	c-0.2,0.6-0.8,1-1.1,1.5C70.1,110.5,76.8,111.8,79.3,109.5z M77.7,117.4c0.3,0.6,0.5,1,0.7,1.3c0.9,0.9,1.6,1.9,2.6,2.6
	c2.1,1.5,4.6,0.3,6.3-1.8c1-1.3,2-2.2,3.6-2.6c-2.1,0.1-4.1,0.8-6.1,0.7c-2.2,0-4.3-0.7-6.5-1.1c-0.1-0.1-0.2-0.3-0.2-0.4
	c0.4-0.1,0.7-0.2,1.1-0.3c-1.5-0.3-2.4,0.5-2.5,1.8c0,0.4,0.3,0.8,0.4,1.1c0.1,0,0.2-0.1,0.4-0.1C77.5,118.4,77.6,118.1,77.7,117.4z
	 M67.7,104.2c0.1,0.1,0.3,0.2,0.4,0.4c1.8-3.4,5.3-3.5,8.4-4.4c-2.6-0.8-6.9,0.8-8.4,2.8C67.9,103.3,67.8,103.7,67.7,104.2z
	 M81.8,110.2c0.1,0,0.3,0.1,0.4,0.1c0.3-0.5,0.6-1,0.7-1.6c0-0.1-0.5-0.4-0.9-0.7C82,108.9,81.9,109.6,81.8,110.2z M85.6,110.3
	c1-0.4,0.7-1,0.4-1.6c-0.1-0.2-0.6-0.1-1-0.1C85.3,109.3,85.4,109.8,85.6,110.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M62.1,167.1c-0.2-1.2-0.4-2.4-0.7-3.9c1.7,1.9,2.4,3.9,2.5,6.1c0,0.5,0.1,1,0.2,1.5c0.1,0.8,0.3,1.6-0.6,2.3
	c-0.7,0.5-1.3,1.4-1.8,2.2c-2.1,3-6.3,1.9-7.7-0.6c-0.3-0.6-0.8-1.1-1.4-1.9c-0.1,0.7-0.2,1.1-0.3,1.5c-3-2-4.7-4.8-5.2-8.3
	c-0.4-2.9-0.5-5.8-1-8.7c-0.8-4.3-1.2-8.9-3.8-12.8c0.4-0.1,0.8-0.3,1.2-0.4c0-0.1,0-0.1,0-0.2c-1-0.1-2-0.4-3-0.2
	c-1.6,0.4-4.8-1.4-5.3-2.8c-0.3-0.9-1-1.7-0.6-2.8c0.3-1.1,0.8-1.6,1.9-1.7c1.5-0.1,3.2,0.6,3.4,1.6c0.1,0.4-0.1,0.9-0.1,1.4
	c-0.4-0.1-0.9-0.1-1.3-0.2c-0.6-0.2-1.2-0.6-1.8-0.9c-0.2,0.2-0.3,0.4-0.5,0.6c0.5,0.3,1.1,0.4,1.4,0.8c0.2,0.3-0.1,0.9-0.1,1.5
	c0.7-1,1.7-1.2,2.8-1.4c0.3,0,0.5-0.4,0.7-0.7c0.6-1.4,0.7-1.4,2.5-1.2c-0.3-0.8-0.5-1.4-0.7-2.1c1.2-0.5,1.3-1.5,0.4-2.8
	c-0.3-0.4-0.4-1-0.6-1.4c0.1-0.1,0.2-0.1,0.3-0.2c-0.3-0.1-0.6-0.2-0.9-0.4c-0.1,0.2-0.2,0.3-0.2,0.4c-0.2,1-0.4,2-0.8,3
	c-0.2,0.4-0.7,0.7-1.1,1c-0.2-0.4-0.6-0.8-0.5-1.3c0.1-1.5,0.3-3,0.5-4.5c0.1-1,0.9-1.4,1.6-0.8c1.2,1,2.3,2.2,3.5,3.3
	c0.4,0.4,0.7,0.8,1.1,1.1c1.8,1.6,2.1,3.7,2.3,6.1c-0.6-0.4-0.9-0.6-1.3-0.9c-0.1,0.1-0.1,0.1-0.2,0.2c0.1,0.2,0.2,0.4,0.4,0.6
	c1.7,1.6,2.2,3.9,2.9,6c0.8,2.5,2.1,4.6,3.7,6.7c1.8,2.3,3.7,4.7,5.1,7.3c1.2,2.2,1.7,4.8,2.3,7.2C61.2,167,61.3,167.3,62.1,167.1z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M65.2,133.6c0.1,1.6,0.9,2.8,2.1,4.1c-5-1.3-6.2-5.4-7.7-9.4c-1.3,2.1,1.3,7.1,4.5,9.2c-1.7,1-3.5,2-5.5,3.2
	c-0.7-2.5-1.5-5-2-7.6c-0.8-4,0-7.9,0.6-11.8c0.2-1.3-0.4-2.9-0.8-4.2c-0.8-2.8-1.9-5.5-1.7-8.6c0.2-2.6,0.8-5,2.3-7.1
	c0.1-0.2,0.3-0.3,0.4-0.4c0,0,0.1,0,0.2-0.1c0.2,0.5,0.5,1,0.7,1.5c0.1,0,0.2-0.1,0.3-0.1c-0.2-2.2-0.4-4.4,0.5-6.4
	c1.7-3.7,4-4.5,7.9-4c1.2,0.2,2.4,0.4,3.6,0.7c0-0.1,0.1-0.3,0.1-0.4c-1.1-0.4-2.2-0.8-3.4-1.2c3.2-1.3,6.3-0.9,9.3,0.6
	c-0.4,0-0.7-0.1-1.1-0.1c-0.4,0-0.7-0.1-1.1-0.1c0,0.2-0.1,0.3-0.1,0.5c1.4,0.5,2.7,1,4.1,1.5c-2,0.1-3.9,0.3-6,0.4
	c1.3,0.7,2.4,1.4,3.7,2.2c-2.9,0-5.4,0-7.9,0c0,0.1,0,0.2-0.1,0.3c0.7,0.2,1.4,0.5,2.3,0.8c-1.1,0.4-1.8,1.1-2.9,0.2
	c-0.6-0.5-1.6-0.5-2.4-0.7c-0.1,0.1-0.1,0.2-0.2,0.4c1,0.6,2,1.2,3.3,2c-1,0.1-1.6,0.1-2.3,0.2c0.3,0.9,0.5,1.8,0.9,2.9
	c-2-0.3-3.9-0.7-5.8-1c0,0.1-0.1,0.3-0.1,0.4c0.6,0.2,1.2,0.5,1.7,0.7c0.4,0.2,0.8,0.4,1,0.7c0.8,1.6,2.1,3,1.9,5
	c0,0.3-0.1,0.6-0.1,0.9c-0.2,1.1-0.4,1.9-2,1.8c-1.6-0.1-3.1,0.6-3.7,2.4c-0.6,1.7,0.1,3.1,1.1,4.4c1.1,1.3,2.5,2,4,2.7
	c1.2,0.4,1.9,1.5,2.1,2.7c0.3,1.7,1.8,4.3,3.4,6c-1.1-0.6-2.3-1-3.4-1.7c-1.1-0.7-2.1-1.6-3.4-2.2c0.2,0.3,0.3,0.7,0.6,0.9
	c0.9,0.8,1.8,1.4,2.7,2.2c0.8,0.7,1.6,1.3,2.2,2.1c1.7,2.4,4,4.1,6.7,5.2c0.2,0.1,0.5,0.1,0.8,0.3c-3.1,0.5-5.8-0.8-8.3-2.5
	c-0.1,0.1-0.2,0.1-0.3,0.2c0.4,0.5,0.7,0.9,1.2,1.4c0.5,0.5,1,0.9,1.5,1.3c0.5,0.4,1,0.9,1.5,1.3c0,0.1-0.1,0.2-0.1,0.3
	c-0.9-0.4-1.8-0.8-2.6-1.3c-0.1,0.1-0.1,0.1-0.2,0.2c1.3,1.2,2.7,2.4,4,3.6C69.5,138.9,67.1,136.6,65.2,133.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M106.3,209.9c2,2.1,2.2,4.5,2.1,7.2c-1.9-0.2-3.6-0.3-5.4-0.6c-0.2,0-0.5-0.7-0.6-1c-0.6-2.8-1.1-5.6-1.6-8.4
	c1.4-1.3,2.1-2.5,1.2-4.9c-2.1-5.7-3.1-11.6-2.7-17.7c0.1-1.7,0.3-3.4,0.5-5.1c0.5-0.1,0.9-0.1,1.3-0.2c2.1,4.8,3.3,9.7,2.8,14.9
	c-0.2,2,0,4,0.1,6c0.1,1,0.8,1.7,1.5,2.5c0.9,1.1,1.6,2.4,2.4,3.6c1,1.4,2,2.8,2.9,4.3c0.4,0.6,0.6,1.2,0.8,1.8
	c-0.7-0.1-1.5,0.1-2.1-0.3c-1.1-0.6-2-1.5-2.9-2.3C106.5,209.9,106.4,209.9,106.3,209.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M75.2,94c1.8,0,3.4,0,4.9,0c0.1-0.1,0.1-0.3,0.2-0.4c-0.9-0.6-1.7-1.1-2.6-1.7c0.3-0.1,0.6-0.2,1.2-0.5
	c-0.9-0.4-1.5-0.8-2.4-1.2c2.9-0.8,5.5-0.1,8.3,0.9c-0.9,0.4-1.6,0.7-2.4,1.1c0,0.1,0,0.2,0,0.3c0.5,0,1,0.1,1.5,0.1
	c0,0.1,0,0.1,0.1,0.2c-0.9,0.2-1.7,0.5-2.7,0.8c1.5,1.3,3-0.4,4.1,0.2c-1.1,0.7-2.3,1.6-3.5,2.3c-0.3,0.2-0.8,0.3-1.2,0.3
	c-0.9-0.1-1.9-0.2-2.8-0.4c-0.4-0.1-0.7-0.3-1-0.6C76.4,95,75.9,94.6,75.2,94z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M54.5,177.1c1.8,1.1,3.6,1.1,5.4,0.7c1.9-0.5,2.9-2.1,4-3.6c0.7,1.8-0.2,3.2-0.9,4.3c-1.5,2.3-3.2,3.5-6,2.2
	c-1.1-0.5-1.9-1.6-2.8-2.6C54.2,177.9,54.4,177.4,54.5,177.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }}
              d="M100,179.3c0.1-3.7,0.3-7.2,0.4-10.4c1,2.8,2.1,5.9,3.2,9.1C102.3,178.4,101.2,178.8,100,179.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M50.3,60.4c0.6-0.9,1.1-1.7,1.8-2.4c0.3-0.4,0.9-0.7,1.4-0.7c1.3-0.1,2.4,0.9,2.7,2.2c0.2,0.8,0.5,1.6,0.7,2.2
	C54.8,61.3,52.5,60.9,50.3,60.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M33.7,137.3c-0.4-2.6,0.4-3.7,2.6-4.2c1.1-0.2,1.5,0.1,1.6,1.2c0.1,1.1,1.2,1.7,2.4,1.4c0.3-0.1,0.8-0.1,0.9,0
	c0.3,0.4,0.4,0.9,0.6,1.4c-0.6,0-1.2,0.2-1.7,0c-1-0.3-1.8-0.8-2.8-1.1C35.9,135.5,34.7,136.1,33.7,137.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M30.6,57.7c0.6,0,1.1,0.1,1.6,0.1c0.5-0.1,1.1-0.3,1.6-0.4c0.1,0,0.1,0.1,0.2,0.1c0.9,0.6,1.9,1.2,2.8,1.8
	c-0.8,1.4-1.7-0.2-2.5,0.1c-0.3,1.5-0.4,1.5-1.7,0.7c-0.9-0.5-1.6-0.6-2.5,0.2c-0.3,0.3-0.9,0.1-1.4,0.2c0.1-0.4,0.2-0.8,0.4-1.2
	C29.6,58.7,30.1,58.2,30.6,57.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M83.2,153.3c1.4,0.8,2.9,1.6,4.3,2.4c-0.4,1.8-0.8,2.4-2,2.1c-0.9-0.3-1.8-1.1-2.4-1.9
	C82.6,155.2,82.2,154.2,83.2,153.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1}
              fill={this.state.color17 ? this.state.color17 : 'transparent'} onClick={() => {
          this.setState({ color17: color, opacity17: opacity });
        }} d="M77.5,149.6c1.5,1.1,2.9,2,4.2,2.9c0.7,0.5,0.5,1.4-0.3,1.8c-1.2,0.5-3.4-0.5-3.7-1.8c-0.1-0.6-0.3-1.1-0.4-1.7
	C77.3,150.4,77.5,150,77.5,149.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }}
              d="M76.7,149.5c-0.6,0.8-1.6,0.5-2.5,0.1c-1.9-0.8-2.5-2.5-2-5.3C73.7,146.1,75.1,147.8,76.7,149.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M70,139.6c1.1,0.6,1.8,3.8,1.2,5.2C68.6,144.1,67.7,141.3,70,139.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }}
              d="M38.2,132.5c-1.5,0.2-2.9,0.4-4.2,0.6c-0.2-1.7,0.5-2.6,2.4-2.6C37.8,130.4,38.2,130.9,38.2,132.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }}
              d="M90.8,108.4c-1.5-0.5-3.5,0.2-3.7-1.9c0.7-0.2,1.5-0.5,2.6-0.8C90.1,106.4,90.5,107.4,90.8,108.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }}
              d="M89.3,145.4c0.8,0.8,1.4,1.4,1.9,2c1,1.3,0.7,2.1-1.1,2.5C89.8,148.5,89.6,147.1,89.3,145.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity23 ? this.state.opacity23 : 1}
              fill={this.state.color23 ? this.state.color23 : 'transparent'} onClick={() => {
          this.setState({ color23: color, opacity23: opacity });
        }}
              d="M90.1,150.5c1.1,1.5,1.7,2.8,1.6,4.5c-0.8-0.2-1.4-0.4-2-0.6C89.8,153.2,89.9,152,90.1,150.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }}
              d="M38.9,128.5c-0.2,0.7-0.4,1.3-0.6,1.9c-0.8-0.2-1.7-0.4-2.5-0.6C36.1,128,37.2,127.6,38.9,128.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1}
              fill={this.state.color25 ? this.state.color25 : 'transparent'} onClick={() => {
          this.setState({ color25: color, opacity25: opacity });
        }} d="M55.9,62.4c-2.3,1-4.3,0.5-6.1-1C51.9,61.7,53.9,62.1,55.9,62.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1}
              fill={this.state.color26 ? this.state.color26 : 'transparent'} onClick={() => {
          this.setState({ color26: color, opacity26: opacity });
        }} d="M86,103.5c0.7,0.4,1.5,0.9,2.2,1.3c0.2,0.1,0.2,0.4,0.3,0.7c-0.2,0.1-0.5,0.3-0.7,0.2c-0.7-0.3-1.3-0.6-1.9-1
	c-0.2-0.2-0.2-0.7-0.3-1C85.7,103.6,85.9,103.6,86,103.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M56.5,57c-1.1-0.1-2.3-0.2-3.5-0.3c-0.1,0-0.3-0.3-0.3-0.4c0-0.1,0.1-0.3,0.2-0.4C53.7,55.4,56.1,56.3,56.5,57z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity28 ? this.state.opacity28 : 1}
              fill={this.state.color28 ? this.state.color28 : 'transparent'} onClick={() => {
          this.setState({ color28: color, opacity28: opacity });
        }} d="M88.7,155.8c0.3-0.2,0.5-0.4,0.9-0.8c0.3,1,0.5,1.9,0.7,2.7c-0.1,0.1-0.3,0.1-0.4,0.2
	C89.6,157.3,89.2,156.6,88.7,155.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity29 ? this.state.opacity29 : 1}
              fill={this.state.color29 ? this.state.color29 : 'transparent'} onClick={() => {
          this.setState({ color29: color, opacity29: opacity });
        }} d="M85.7,96.9c-1.1,1.2-1.2,1.2-2.8,0C83.9,96.9,84.7,96.9,85.7,96.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity30 ? this.state.opacity30 : 1}
              fill={this.state.color30 ? this.state.color30 : 'transparent'} onClick={() => {
          this.setState({ color30: color, opacity30: opacity });
        }}
              d="M41.5,135.2c0.3-0.7,0.5-1.2,0.7-1.7c0.1,0,0.2,0,0.3,0c0,0.6,0,1.2,0,1.9C42.2,135.3,41.9,135.2,41.5,135.2z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity31 ? this.state.opacity31 : 1}
              fill={this.state.color31 ? this.state.color31 : 'transparent'} onClick={() => {
          this.setState({ color31: color, opacity31: opacity });
        }} d="M188.4,172.1c1.2,2.4,2.4,4.9,3.7,7.3c0.9-0.4,1.1-0.9,0.9-1.7c-0.6-2.3-1-4.6,0-6.8c0.4-1,1.1-1.9,1.6-2.9
	c1.6-3.2,1.2-5.3-0.7-8.8c1.1,0.5,2.1,0.6,3-0.2c0.8-0.8,1.1-1.7,1.2-2.7c0.3-2.6,0.1-5-1.2-7.3c-0.5-0.9-1-1.6-2.2-0.7
	c-0.4,0.3-0.9,0.3-1.6,0.4c1-2,0.7-4,0.3-6c-0.1-0.7,0-1.4,0.1-2.1c0.2-1.2,0.4-2.3,0.7-3.6c1.1,0.7,2.3,1.4,3.3,2.2
	c2.7,2.3,5.4,4.6,7.2,7.7c0.7,1.2,1.1,2.7,1.3,4.1c0.5,3.1,0.4,6.2-0.1,9.4c-0.9,6-2.8,11.7-4.2,17.6c-1,4.2-1.2,8.4-0.5,12.6
	c0.5,2.9,0.7,5.9,1,8.9c0.1,1,0.3,2.1,0.5,3c0.3,1-0.3,1.6-1,2.1c-2.4,1.7-5.2,2.5-8.1,3c-4.1,0.7-8.1,1.2-12.2,1.1
	c-4.6,0-9.1-0.9-13.4-2.4c-2.8-0.9-5.5-1.9-8.2-2.9c-2-0.7-2.1-1-1.8-3c0.6-3.8,1.1-7.6,1.7-11.5c0.7-4.9,1.1-9.7,0.6-14.6
	c-0.2-1.8,0.8-2.9,1.6-4.2c0.2-0.3,0.6-0.5,1-0.5c3-0.2,4.1-2.2,4.7-4.7c0.3-1.3,0.5-2.6,0.8-3.9c0.4-2,1.3-3.7,2.9-5
	c0.3-0.2,0.5-0.5,0.7-0.8c-0.1-0.1-0.2-0.2-0.2-0.3c-0.5,0.3-1,0.5-1.4,0.9c-2.1,2-3.2,4.5-3.7,7.3c-0.3,1.5-0.8,3-1.2,4.5
	c-0.2,0.8-1.8,1.1-3.1,1c-3.3-0.3-5.6-2.6-8.2-4.2c-1.5-1-2.1-2.7-1.2-4.6c0.8-1.6,1.5-3.2,2.4-4.7c1.7-2.6,2.3-5.4,2.9-8.3
	c0.3-1.6,1-2.9,2.2-4c2.5-2.2,5-4.1,8-5.4c0.8-0.3,1.7-0.3,2.5-0.4c1.4-0.1,2.6-0.5,3.7-1.5c-0.3,0.8-0.6,1.7-0.9,2.7
	c2.1-0.7,4-1.4,5.1-3.9c0.5,3.4,0.8,6.5,3.2,8.3c0.2,2,0.4,3.7,0.6,5.4c-0.3,0-0.9-0.1-1.4-0.1c-1.6,0.1-2.8,1.1-2.6,2.7
	c0.2,2.1,0.2,4.2,0.9,6.1c0.4,1.3,1.6,2.4,2.8,3.3c1,0.8,2.1-0.1,2.9-0.7c-0.3,1.8-0.6,3.7-0.9,5.5c-0.3,2,0.5,3.8,1.1,5.6
	c0.7,1.8,1.4,3.6,2.1,5.4c0.1,0.2,0.2,0.4,0.3,0.5C189.3,175.2,188,173.4,188.4,172.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1}
              fill={this.state.color32 ? this.state.color32 : 'transparent'} onClick={() => {
          this.setState({ color32: color, opacity32: opacity });
        }} d="M129.8,16.2c4.9,0,9.4,1.2,13.3,4.2c2.8,2.1,4.8,5.1,6.2,8.3c1.5,3.4,2.9,6.8,3.1,10.6c0.1,2.4-0.1,4.7-0.1,7.1
	c0,2.3-0.5,4.6-1.5,6.7c-2.2,4.6-5.4,8.2-10.2,10.2c-0.3,0.1-1,0-1.1-0.2c-1.2-1.5-3.1-1.4-4.1-0.7c-2.4,1.5-4.5,0.7-6.4-0.5
	c-4-2.6-7.8-5.4-10.5-9.4c-2.8-4.1-4.6-8.6-5.1-13.5c-0.2-2-0.6-4.1-0.7-6.1c-0.2-3.4,1.4-6.3,3.2-9.1c0.7-1.1,1.6-2.2,2.5-3.2
	c2.6-2.9,6-3.9,9.7-4.2C128.6,16.2,129.2,16.2,129.8,16.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity33 ? this.state.opacity33 : 1}
              fill={this.state.color33 ? this.state.color33 : 'transparent'} onClick={() => {
          this.setState({ color33: color, opacity33: opacity });
        }} d="M147.2,22.4c0.4-1.4,0.9-2.5,1.1-3.6c0.9-4.2,2.2-8.3,4.6-11.9c3.7-5.6,10.7-5.1,14.4-0.5
	c2.2,2.8,3.2,6,3.9,9.4c0.9,3.8,1.9,7.5,1.8,11.5c-0.1,4.7-0.2,9.5-1.7,14.1c-1.4,4.4-3.1,8.7-5.3,12.7c-1.4,2.6-3.8,4.8-6,6.9
	c-1.4,1.4-3.2,2.4-4.9,3.3c-0.9,0.5-2,0.4-3.1,0.6c-0.9-1.4-1.8-2.6-3.5-2.9c-1.9-0.3-2.5,0.2-2.7,2.1c-0.1,0-0.2,0-0.3-0.1
	c-1.9-1.1-1.9-1.1-0.1-2.3c2.7-1.8,4.5-4.5,6.2-7.3c1.7-2.9,2-6.1,2.4-9.4c0.4-3.2,0.4-6.4-0.5-9.5c-1.3-4.3-3.1-8.4-5.6-12.2
	C147.7,22.9,147.3,22.6,147.2,22.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M188.5,137.3c1.8,0.3,2.7-1.2,3.9-2.1c0.4,3.3,0.1,6.3-2.3,8.7c-0.2,0.3-0.5,0.6-0.8,0.8
	c-1.1,0.7-1.1,1.6-0.5,2.7c0.5,1,0.3,2.3-0.4,2.7c-0.9,0.5-2.6,0-3.3-0.9c-1.3-1.8-2-3.7-2-6.3c1.6,0.7,3.1,1.3,4.6,1.9
	c0.1-0.1,0.1-0.3,0.2-0.4c-0.7-0.5-1.4-1.4-2.2-1.5c-4.2-0.5-4.8-3.8-5.8-7.1c1.3,0.5,2.1,1.9,3.9,1.3c-0.5-0.3-0.8-0.6-1.2-0.8
	c-2.8-1.7-4.6-4.3-5.8-7.3c-0.4-0.8-0.7-1.7-1.1-2.5c-0.2-0.4-0.6-0.7-1-1.1c-0.1,0.1-0.2,0.1-0.3,0.2c0.5,1.5,0.9,2.9,1.5,4.4
	c0.5,1.2,1.4,2.2,1.7,3.3c0.2,0.6-0.1,1.6-0.5,2.2c-0.5,0.7-1.4,1.1-2.3,1.8c0.9-3.4,0.2-9-1.5-10.7c0.2,0.8,0.4,1.6,0.5,2.5
	c0.1,1.6,0.4,3.3,0.1,4.9c-0.4,2.1-1.3,2.5-4.1,2.4c1.5-0.8,2.5-1.9,2.8-3.3c0.3-1.7,0.5-3.4,0.5-5.2c-0.1-3.7-2.6-6.2-4.4-9.1
	c-0.9-1.4-1.6-2.8-2.3-4.3c-0.6-1.2-0.1-2.4,0.1-3.6c0.5-2.2,0.7-4.5,1.5-6.6c1.5-4.1,4.7-6.7,8.8-7.7c1.8-0.4,3.9-0.1,5.8,0
	c1.4,0.1,2.7,0.3,4.1,0.6c2.5,0.6,4.7,1.6,6.2,3.7c1.1,1.5,2,3.1,2.7,4.8c0.6,1.5,1.1,3.1,1.2,4.6c0.3,2.5,0.8,5.2-0.2,7.7
	c-0.7,1.6-1.6,3.2-2.5,4.7c-0.5,0.9-1.2,1.8-1.7,2.6c-0.7,1.1-1.5,2.2-2.1,3.3c1.3-0.3,2.1-1.5,2.9-3.1c0.5,3,0.6,5.7-0.9,8
	c-0.6,1-1.5,1.8-2.4,2.6c-0.3,0.3-0.8,0.3-1.1,0.5C188.8,136.9,188.7,137.2,188.5,137.3c-0.5-0.8-1.1-1.6-1.8-2.6
	c0.5-0.8,0.4-2.2,0.1-3.8c-0.3-1.6-0.3-3.3-0.3-5c0-0.5,0.4-1.1,0.7-1.6c-0.4,0.1-0.5,0.3-0.7,0.5c-1.3,2.2-1.2,4.5-0.8,6.9
	c0.4,2.1,0.1,3.2-1.6,3.9c0.8,0.3,1.4,0.5,1.9,0.8c1.2,1,2,2.2,2.2,3.8c0,0.3,0.6,0.6,0.9,0.8c0.1-0.4,0.4-0.8,0.3-1.1
	C189.2,139.1,188.8,138.2,188.5,137.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity35 ? this.state.opacity35 : 1}
              fill={this.state.color35 ? this.state.color35 : 'transparent'} onClick={() => {
          this.setState({ color35: color, opacity35: opacity });
        }} d="M143.6,140.8c-0.2,0.3-0.5,0.6-0.9,1.1c-0.9-1.5-1.7-2.9-2.6-4.4c0-0.1,0-0.2,0-0.3c1.1-1,0.4-2,0.1-3.1
	c-0.2-0.9-0.2-1.9-0.3-2.8c0.1,0,0.3,0,0.4-0.1c0.1,0.2,0.2,0.5,0.4,0.7c0.1,0,0.3,0,0.4,0.1c0.2-0.9,0.3-1.8,0.6-2.6
	c0.2-0.6,0.5-1.6,0.9-1.7c0.9-0.2,2-0.1,3,0.1c0.2,0,0.2,1,0.1,1.6c0.6,1.3,1.7,1.9,3.2,1.7c1.2-0.1,2.5-0.2,3.5,0.9
	c1.3,1.4,0.8,3,0.3,4.5c-0.4,1-0.6,2.3-1.7,2.7c-0.4-0.5-0.8-1-1.1-1.5c-0.1,0.1-0.2,0.2-0.4,0.2c0.5,2.6,0.8,5.3,1.5,7.8
	c0.6,2.1,1.7,3.9,2.5,6c0.5,1.3,1.1,2.5,0,4.1c-1.5,2-2.5,4.2-2.5,6.9c0,0.6-0.8,1.3-1.2,2c-0.4,0.7-0.9,1.4-1.2,2.1
	c-0.2,0.4,0,0.9,0.3,1.4c0.8-1.5,1.7-3,2.5-4.5c2.8,2.8,5.8,5.1,9.7,6c-1.5,2-2.9,4-4.5,6c-0.5,0.6-1.2,1.1-1.9,1.4
	c-1.5,0.7-2.5,0.4-3.4-0.5c-3.9-3.8-5.9-8.3-5.9-13.7c0-2.3-0.3-4.5-0.3-6.8c-0.1-2.7,0-5.3-0.2-8c-0.2-2.5-0.6-4.9-1-7.3
	C143.8,140.8,143.7,140.8,143.6,140.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1}
              fill={this.state.color36 ? this.state.color36 : 'transparent'} onClick={() => {
          this.setState({ color36: color, opacity36: opacity });
        }} d="M191,157.1c0.7,1.3,1.4,2.5,2.2,3.7c1.8,2.9,1.6,5.7-0.4,8.5c-1.3,1.8-1.8,3.7-1.7,6.2
	c-0.7-1.8-1.5-3.2-1.8-4.8c-0.2-1,0.2-2.1,0.3-3.2c-1-0.1-1.2,1-2.1,4.2c-1.7-3.3-2.7-6.6-1.7-10.2c0.2-0.9,0.5-1.7,0.8-2.6
	c0.3-0.8,0.7-1.3,1.8-1.3C189.2,157.8,190.2,157.3,191,157.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity37 ? this.state.opacity37 : 1}
              fill={this.state.color37 ? this.state.color37 : 'transparent'} onClick={() => {
          this.setState({ color37: color, opacity37: opacity });
        }} d="M182.6,93.9c-3.3-0.5-6.5,0-9.5,1.4c-2.5,1.2-4.8,2.9-6.1,5.4c-0.5,0.9-0.8,1.7-1.9,2c-0.5,0.1-0.8,0.7-1.3,1.1
	c-0.1-0.9-0.2-1.7,0.5-2.4c0.4-0.4,0.6-1.2,0.6-1.8c0.2-1.8,0.6-3.5,2.1-4.6c0.5-0.4,1.2-0.5,1.8-0.7c0.3-0.1,0.8-0.2,1-0.5
	c1.2-1.5,2.6-1.7,4.3-1.3c0.3,0.1,0.8,0.3,1,0.1C177.9,91.1,180.1,93.1,182.6,93.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity38 ? this.state.opacity38 : 1}
              fill={this.state.color38 ? this.state.color38 : 'transparent'} onClick={() => {
          this.setState({ color38: color, opacity38: opacity });
        }} d="M186,157.2c-0.4,0.4-0.8,0.8-1.1,1.2c-1.1,1.3-2.5,1.1-3.5-0.4c-1.1-1.8-1.4-3.7-1.6-5.7
	c-0.1-0.7-0.1-1.5-0.1-2.2c0.1-1.2,0.6-1.5,1.8-1c0.6,0.3,1.2,0.7,1.9,1c0.6,0.3,1.2,0.5,1.8,0.8C184.5,152.8,184.8,155.5,186,157.2
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity39 ? this.state.opacity39 : 1}
              fill={this.state.color39 ? this.state.color39 : 'transparent'} onClick={() => {
          this.setState({ color39: color, opacity39: opacity });
        }} d="M172.4,129.6c-0.2-0.5-0.5-1-0.7-1.5c-0.7,1.5,0.1,2.8,0.5,4.3c-3-0.7-4.7-2.6-6.2-4.9
	c-1.6-2.4-1.2-5.1-1.2-7.8c0.7,0.9,1.4,1.8,2,2.8c0.1,0.2,0,0.8-0.2,1c-0.7,0.9-0.3,1.6,0.3,2.2c0.6,0.7,1.6,0.5,2.1-0.1
	c0.7-0.7,1.2-1.6,1.8-2.4C171.9,124.9,172.5,127.1,172.4,129.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity40 ? this.state.opacity40 : 1}
              fill={this.state.color40 ? this.state.color40 : 'transparent'} onClick={() => {
          this.setState({ color40: color, opacity40: opacity });
        }} d="M186.7,92.9c0.5-0.7,0.9-1.1,1.2-1.7c0.4-0.7,0.7-1.1,1.4-0.5c1.6,1.1,2,0.8,0.8,3c-0.2,0.3-0.2,0.6-0.3,1
	c1.3,0,2.3-1.6,3.5-0.4c0.5,0.6,0.7,1.4,1.1,2.3c-0.4,0.1-0.6,0.1-0.8,0.1c-0.3,0.1-0.5,0.3-0.8,0.5c0.2,0.2,0.4,0.6,0.7,0.6
	c1,0,1.1,0.8,1.4,1.4c0.1,0.3-0.2,0.7-0.3,1c-0.3-0.1-0.7-0.1-1-0.3c-2.3-1.6-4.4-3.5-6.9-4.9c-1.3-0.7-2.1-1.4-2.3-2.8
	c0-0.4,0.2-0.8,0.3-1.2c0.4,0.2,0.9,0.3,1.2,0.5C186.1,91.7,186.3,92.1,186.7,92.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity41 ? this.state.opacity41 : 1}
              fill={this.state.color41 ? this.state.color41 : 'transparent'} onClick={() => {
          this.setState({ color41: color, opacity41: opacity });
        }} d="M192.2,156.4c1.5-2.4,1.3-5.1-0.5-5.8c0.4-0.3,0.8-0.7,1.2-1c1.7-1,2.9-0.5,3.5,1.4c0.7,2.3,0.5,4.6,0.2,6.9
	c0,0.4-0.3,0.7-0.6,1.3C194.6,158.3,193.4,157.4,192.2,156.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity42 ? this.state.opacity42 : 1}
              fill={this.state.color42 ? this.state.color42 : 'transparent'} onClick={() => {
          this.setState({ color42: color, opacity42: opacity });
        }} d="M185.8,151.4c1.9,0,3.5,0,5,0c1.1,0,1.3,0.7,1.4,1.7c0.1,1.3,0.1,2.6-1.2,3.3c-1.1,0.6-2.4,0.8-3.6,0.4
	c-0.2-0.1-0.5-0.2-0.5-0.4C186.4,154.7,186.1,153.1,185.8,151.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity43 ? this.state.opacity43 : 1}
              fill={this.state.color43 ? this.state.color43 : 'transparent'} onClick={() => {
          this.setState({ color43: color, opacity43: opacity });
        }}
              d="M188,96.8c-10.1-2.8-17.9-0.7-21.8,9.8c0.1-4.4,2.5-8.2,7.8-10.8C178.3,93.7,186.6,94.9,188,96.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity44 ? this.state.opacity44 : 1}
              fill={this.state.color44 ? this.state.color44 : 'transparent'} onClick={() => {
          this.setState({ color44: color, opacity44: opacity });
        }} d="M163.8,113.5c0.5,0.4,1.1,0.7,1.4,1.2c1.4,2.1,2.7,4.3,4,6.5c0.7,1.1,0.5,2.4-0.2,3.4c-0.2,0.3-1,0.5-1.3,0.3
	c-0.3-0.1-0.6-0.9-0.4-1.2c0.7-1.4,0.2-2.5-0.7-3.6c-1-1.2-2-2.3-2.8-3.6c-0.5-0.8-0.5-1.9-0.8-2.8
	C163.4,113.7,163.6,113.6,163.8,113.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity45 ? this.state.opacity45 : 1}
              fill={this.state.color45 ? this.state.color45 : 'transparent'} onClick={() => {
          this.setState({ color45: color, opacity45: opacity });
        }} d="M140.6,68.1c-1.6,0.2-3.2,0.3-4.7,0.7c-0.8,0.2-0.9,0-0.8-0.7c0.1-0.9,0.3-1.9,0.3-2.8c0.1-1.2,1-1.3,1.9-1.6
	c0.9-0.3,1.2,0.4,1.5,0.9C139.4,65.7,140,67,140.6,68.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity46 ? this.state.opacity46 : 1}
              fill={this.state.color46 ? this.state.color46 : 'transparent'} onClick={() => {
          this.setState({ color46: color, opacity46: opacity });
        }} d="M192.4,143c0.6,2.5,0.6,5.1-1.4,7.1c-0.3,0.3-1.1,0.3-1.9,0.5c1-1.6,1.1-2.9,0.4-4.3c-0.1-0.2,0.1-0.7,0.3-0.9
	C190.6,144.6,191.5,143.8,192.4,143z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity47 ? this.state.opacity47 : 1}
              fill={this.state.color47 ? this.state.color47 : 'transparent'} onClick={() => {
          this.setState({ color47: color, opacity47: opacity });
        }} d="M144.9,65.9c0.9-0.7,1.6-1.3,2.3-1.8c0.4-0.3,1-0.5,1.3,0.1c0.4,0.8,0.9,1.7,1.1,2.6c0.2,1,0,2,0,3.3
	C147.8,68.6,147.3,66.2,144.9,65.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity48 ? this.state.opacity48 : 1}
              fill={this.state.color48 ? this.state.color48 : 'transparent'} onClick={() => {
          this.setState({ color48: color, opacity48: opacity });
        }} d="M148.6,70.5c-2,0.5-4.8-1.9-4.8-4C145.4,66.2,145.9,66.7,148.6,70.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity49 ? this.state.opacity49 : 1}
              fill={this.state.color49 ? this.state.color49 : 'transparent'} onClick={() => {
          this.setState({ color49: color, opacity49: opacity });
        }} d="M150.9,65.7c-0.6-0.7-1-1.4-1.6-1.9c-0.6-0.5-1.4-0.7-2.2-1.1c0.7-0.3,1.4-0.3,2,0
	C150.2,63.4,151,64.7,150.9,65.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity50 ? this.state.opacity50 : 1}
              fill={this.state.color50 ? this.state.color50 : 'transparent'} onClick={() => {
          this.setState({ color50: color, opacity50: opacity });
        }} d="M139.9,69.1c-0.8,0.9-2.5,1.1-4,0.4C136.9,68.7,138.8,68.5,139.9,69.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity51 ? this.state.opacity51 : 1}
              fill={this.state.color51 ? this.state.color51 : 'transparent'} onClick={() => {
          this.setState({ color51: color, opacity51: opacity });
        }}
              d="M134.1,64.4c0-0.2,0-0.6,0.2-0.8c0.9-0.8,2-1,3.4-1.1C136.3,63.2,135.2,63.8,134.1,64.4z" />
        <path d="M79.3,109.5c-2.6,2.3-9.2,1-10.2-2c0.4-0.5,0.9-0.9,1.1-1.5c0.2-0.5,0-1.2,0-1.9c0.5,0.4,0.9,0.6,1.3,0.9
	c0.1-0.1,0.2-0.1,0.3-0.2c-0.1-0.5-0.3-1-0.5-1.6c0.3-0.1,0.7-0.2,1.1-0.3c0,0.6,0.1,1,0.1,1.4c0.1,0,0.3,0,0.4,0c0-0.7,0-1.5,0-2.3
	c0.2-0.1,0.6-0.3,1-0.4c0,0.8,0,1.5,0,2.3c0.7,0.1,1.5,0.4,1.6-0.9c0-0.4,0.7-0.6,1.3-0.8c-0.3,0.6-0.5,1.3-0.8,1.9
	c0.1,0.1,0.1,0.2,0.2,0.3c0.6-0.4,1.3-0.9,1.9-1.3c0.1,0.1,0.1,0.1,0.2,0.2c-0.4,0.4-0.7,0.8-1.2,1.3
	C78.8,105.7,79.4,107.4,79.3,109.5z M72.7,105c-0.1,0-0.2-0.1-0.3-0.1c-0.6,0.8-1.3,1.6-1.8,2.5c-0.1,0.3,0,0.9,0.3,1.1
	c1.5,1.5,6,2.1,7.5,0.6c-0.1-1-0.2-2-0.4-3.2c-1.3,1.5-2.7,1.4-4,1C73.2,106.8,72.3,106.3,72.7,105z M77.1,106.1
	c-0.7-1.3-1.5-1.7-2.7-1.5c-0.3,0.1-0.8,0.4-0.9,0.6c-0.1,0.3,0.2,0.7,0.5,0.9C75,107,75.9,107,77.1,106.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity52 ? this.state.opacity52 : 1}
              fill={this.state.color52 ? this.state.color52 : 'transparent'} onClick={() => {
          this.setState({ color52: color, opacity52: opacity });
        }} d="M72.7,105c-0.4,1.3,0.5,1.8,1.3,2c1.3,0.4,2.7,0.5,4-1c0.1,1.2,0.3,2.2,0.4,3.2c-1.5,1.4-6,0.8-7.5-0.6
	c-0.3-0.2-0.4-0.9-0.3-1.1c0.5-0.9,1.2-1.7,1.8-2.5C72.5,104.9,72.6,105,72.7,105z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1}
              fill={this.state.color53 ? this.state.color53 : 'transparent'} onClick={() => {
          this.setState({ color53: color, opacity53: opacity });
        }} d="M77.1,106.1c-1.2,0.9-2.1,0.9-3.1,0.1c-0.3-0.2-0.5-0.7-0.5-0.9c0.1-0.3,0.5-0.6,0.9-0.6
	C75.6,104.3,76.4,104.8,77.1,106.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity54 ? this.state.opacity54 : 1}
              fill={this.state.color54 ? this.state.color54 : 'transparent'} onClick={() => {
          this.setState({ color54: color, opacity54: opacity });
        }}
              d="M78.8,117.4c2.9,1.3,5.5,1.2,8.3,0.9c-0.7,1.5-1.7,2.6-3.3,2.8C81.2,121.5,80.3,119.3,78.8,117.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity55 ? this.state.opacity55 : 1}
              fill={this.state.color55 ? this.state.color55 : 'transparent'} onClick={() => {
          this.setState({ color55: color, opacity55: opacity });
        }}
              d="M160.2,204.9c0,0-2.7,11.7-2.5,12.7h42.8c0,0,1.4-1.9,0-11.2C200.5,206.4,185.8,214.8,160.2,204.9z" />
      </SvgIcon>
    )
  }
};