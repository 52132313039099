import React, { Component } from "react";
import { SvgIcon } from "@material-ui/core";

export default class Copy extends Component<any, any> {

  render() {
    const st0 = "#9CCC69";
    const st1 = "#0B4B5E";
    const st2= "#B3D8F2";
    const st3 = "#272425";
    const st4 = "#F37785";
    const st5 = "#FFC96C";
    const st6 = "#FFFFFF";
    const { disabled } = this.props;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 47.5 63.6"}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <g id="XMLID_27_">
          <g id="XMLID_1426_">
            <path id="XMLID_1430_" fill={st0} d="M33.8,35.8V4c0-1.6-1.3-2.9-2.9-2.9h-4.2H8.2H4C2.4,1.1,1.1,2.4,1.1,4v41.8
			c0,1.6,1.3,2.9,2.9,2.9h4.2h13L33.8,35.8z" />
            <path id="XMLID_1427_" fill={st1} d="M21.6,49.8H4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4h26.8c2.2,0,4,1.8,4,4v32.3L21.6,49.8z
			 M4,2.1C3,2.1,2.1,3,2.1,4v41.8c0,1,0.8,1.9,1.9,1.9h16.7l12-12.3V4c0-1-0.8-1.9-1.9-1.9H4z" />
          </g>
          <g id="XMLID_1420_">
            <path id="XMLID_1425_" fill={st2} d="M21.1,48.7v-10c0-1.6,1.3-2.9,2.9-2.9h4.2h5.5L21.1,48.7z" />
            <path id="XMLID_1421_" fill={st3} d="M21.1,49.8c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.7-0.5-0.7-1v-10c0-2.2,1.8-4,4-4h9.7
			c0.4,0,0.8,0.3,1,0.6c0.2,0.4,0.1,0.8-0.2,1.1L21.9,49.5C21.7,49.7,21.4,49.8,21.1,49.8z M24.1,36.9c-1,0-1.9,0.8-1.9,1.9v7.4
			l9.1-9.3H24.1z" />
          </g>
          <g id="XMLID_1415_">
            <path id="XMLID_1419_" fill={st4} d="M40.1,42.7V10.9c0-1.6-1.3-2.9-2.9-2.9H33H14.5h-4.2c-1.6,0-2.9,1.3-2.9,2.9v41.8
			c0,1.6,1.3,2.9,2.9,2.9h4.2h13L40.1,42.7z" />
            <path id="XMLID_1416_" fill={st1} d="M27.9,56.7H10.3c-2.2,0-4-1.8-4-4V10.9c0-2.2,1.8-4,4-4h26.8c2.2,0,4,1.8,4,4v32.3
			L27.9,56.7z M10.3,9c-1,0-1.9,0.8-1.9,1.9v41.8c0,1,0.8,1.9,1.9,1.9H27l12-12.3V10.9c0-1-0.8-1.9-1.9-1.9H10.3z" />
          </g>
          <g id="XMLID_1341_">
            <path id="XMLID_1409_" fill={st5} d="M13.7,27.8v31.8c0,1.6,1.3,2.9,2.9,2.9h4.2h18.5h4.2c1.6,0,2.9-1.3,2.9-2.9V17.8
			c0-1.6-1.3-2.9-2.9-2.9h-4.2h-13L13.7,27.8z" />
            <path id="XMLID_1346_" fill={st1} d="M43.5,63.6H16.6c-2.2,0-4-1.8-4-4V27.3l13.3-13.5h17.6c2.2,0,4,1.8,4,4v41.8
			C47.5,61.8,45.7,63.6,43.5,63.6z M14.8,28.2v31.4c0,1,0.8,1.9,1.9,1.9h26.8c1,0,1.9-0.8,1.9-1.9V17.8c0-1-0.8-1.9-1.9-1.9H26.8
			L14.8,28.2z" />
            <path id="XMLID_1345_" fill={st6} d="M26.3,14.8v10c0,1.6-1.3,2.9-2.9,2.9h-4.2h-5.5L26.3,14.8z" />
            <path id="XMLID_1342_" fill={st1} d="M23.4,28.8h-9.7c-0.4,0-0.8-0.3-1-0.6c-0.2-0.4-0.1-0.8,0.2-1.1l12.6-12.9
			c0.3-0.3,0.8-0.4,1.2-0.2c0.4,0.2,0.7,0.5,0.7,1v10C27.4,27,25.6,28.8,23.4,28.8z M16.2,26.7h7.2c1,0,1.9-0.8,1.9-1.9v-7.4
			L16.2,26.7z" />
          </g>
        </g>
      </SvgIcon>
    )
  }

};
