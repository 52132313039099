import CircleDrawable from "./CircleDrawable";

export default class PointDrawable extends CircleDrawable {
  drawableType = 'PointDrawable';
  static defaultSize = {
    w: 1,
    h: 1
  }

  constructor(startx, starty, color, weight, opacity) {
    super(startx, starty, color, weight, opacity);
    const { w, h } = PointDrawable.defaultSize;
    this.x = startx + w + weight;
    this.y = starty + h + weight;
    this.weight = weight;
    this.fill = color;
  }
}