import React, { Component } from "react";
import { SvgIcon } from "@material-ui/core";

export default class Save extends Component<any, any> {

  render() {
    const st0 = "#064B5E";
    const st1 = "#FDBC5F";
    const { disabled } = this.props;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-364.5 570.8 56.7 58.7"}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <g id="XMLID_75_">
          <g id="XMLID_302_">
            <path id="XMLID_181_" fill={st0} d="M-319.4,570.8h-1.1v7.8c0,5.6-4.6,10.2-10.2,10.2h-14.4c-5.6,0-10.2-4.6-10.2-10.2v-7.8h-6.2
			c-1.6,0-3,1.3-3,3v52.7c0,1.6,1.3,3,3,3h50.7c1.6,0,3-1.3,3-3v-44L-319.4,570.8z M-319.1,619.1H-353c-0.8,0-1.5-0.7-1.5-1.5
			s0.7-1.5,1.5-1.5h33.9c0.8,0,1.5,0.7,1.5,1.5S-318.3,619.1-319.1,619.1z M-319.1,610.5H-353c-0.8,0-1.5-0.7-1.5-1.5
			s0.7-1.5,1.5-1.5h33.9c0.8,0,1.5,0.7,1.5,1.5S-318.3,610.5-319.1,610.5z M-319.1,601.8H-353c-0.8,0-1.5-0.6-1.5-1.5
			s0.7-1.5,1.5-1.5h33.9c0.8,0,1.5,0.7,1.5,1.5S-318.3,601.8-319.1,601.8z" />
          </g>
          <g id="XMLID_301_">
            <path id="XMLID_91_" fill={st1}
                  d="M-352.3,579.9c0,4,3.2,7.2,7.2,7.2h14.4c4,0,7.2-3.2,7.2-7.2v-7.8h-28.8V579.9z" />
          </g>
        </g>
      </SvgIcon>
    )
  }

};
