import React from "react";
import { getPageMath1WorkingsNotes } from "./math-1-working-notes/Pages";
import NoTask from "./NoTask";
import { getPageMath2WorkingsNotes } from "./math-2-working-notes/Pages";
import { getPageMath1WorkingsNotesDemo } from "./math-1-working-notes-demo/Pages";
import { getPageMath2WorkingsNotesDemo } from "./math-2-working-notes-demo/Pages";
import { getPageMath3WorkingsNotes } from "./math-3-working-notes/Pages";
import { getPageMath4WorkingsNotes } from "./math-4-working-notes/Pages";
import { getPageMath3WorkingsNotesDemo } from "./math-3-working-notes-demo/Pages";
import { getPageMath4WorkingsNotesDemo } from "./math-4-working-notes-demo/Pages";

export const getPage = (page, props, edition, userName, setPage, setSearchPage, isPageChanged, task) => {
  const {
    flexBasisLeft,
    flexWrap,
    flexBasisRight,
    showDrawables,
    saveChanging,
    saveChangingDevMode,
    showedMicrophone,
    color,
    opacity,
    enabledSVGClick,
    dispatch
  } = props;
  let PageContent;
  let addProps;
  let editionCfg;
  let editable;
  let SVGClickEditable;
  switch (edition) {
    case 'math-1-working-notes':
      editionCfg = getPageMath1WorkingsNotes(page, edition);
      PageContent = editionCfg.component;
      addProps = editionCfg.addProps;
      editable = editionCfg.editable;
      SVGClickEditable = !!editionCfg.SVGClickEditable;
      break;
    case 'math-1-working-notes-demo':
      editionCfg = getPageMath1WorkingsNotesDemo(page, edition);
      PageContent = editionCfg.component;
      addProps = editionCfg.addProps;
      editable = editionCfg.editable;
      SVGClickEditable = !!editionCfg.SVGClickEditable;
      break;
    case 'math-2-working-notes':
      editionCfg = getPageMath2WorkingsNotes(page, edition);
      PageContent = editionCfg.component;
      addProps = editionCfg.addProps;
      editable = editionCfg.editable;
      SVGClickEditable = !!editionCfg.SVGClickEditable;
      break;
    case 'math-2-working-notes-demo':
      editionCfg = getPageMath2WorkingsNotesDemo(page, edition);
      PageContent = editionCfg.component;
      addProps = editionCfg.addProps;
      editable = editionCfg.editable;
      SVGClickEditable = !!editionCfg.SVGClickEditable;
      break;
    case 'math-3-working-notes':
      editionCfg = getPageMath3WorkingsNotes(page, edition);
      PageContent = editionCfg.component;
      addProps = editionCfg.addProps;
      editable = editionCfg.editable;
      SVGClickEditable = !!editionCfg.SVGClickEditable;
      break;
    case 'math-3-working-notes-demo':
      editionCfg = getPageMath3WorkingsNotesDemo(page, edition);
      PageContent = editionCfg.component;
      addProps = editionCfg.addProps;
      editable = editionCfg.editable;
      SVGClickEditable = !!editionCfg.SVGClickEditable;
      break;
    case 'math-4-working-notes':
      editionCfg = getPageMath4WorkingsNotes(page, edition);
      PageContent = editionCfg.component;
      addProps = editionCfg.addProps;
      editable = editionCfg.editable;
      SVGClickEditable = !!editionCfg.SVGClickEditable;
      break;
    case 'math-4-working-notes-demo':
      editionCfg = getPageMath4WorkingsNotesDemo(page, edition);
      PageContent = editionCfg.component;
      addProps = editionCfg.addProps;
      editable = editionCfg.editable;
      SVGClickEditable = !!editionCfg.SVGClickEditable;
      break;
  }
  return task ? <NoTask
    edition={edition}
    page={page}
    showDrawables={showDrawables}
    saveChanging={saveChanging}
    saveChangingDevMode={saveChangingDevMode}
    showedMicrophone={showedMicrophone}
    dispatch={dispatch}
    userName={userName}
    task={task}
    color={color}
  /> : <PageContent
    editable={editable}
    SVGClickEditable={SVGClickEditable}
    enabledSVGClick={enabledSVGClick}
    flexWrap={flexWrap}
    flexBasisLeft={flexBasisLeft}
    flexBasisRight={flexBasisRight}
    edition={edition}
    page={page}
    showDrawables={showDrawables}
    saveChanging={saveChanging}
    saveChangingDevMode={saveChangingDevMode}
    showedMicrophone={showedMicrophone}
    dispatch={dispatch}
    userName={userName}
    setPage={setPage}
    setSearchPage={setSearchPage}
    isPageChanged={isPageChanged}
    color={color}
    opacity={opacity}
    {...addProps}
  />;
}