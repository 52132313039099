import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_29_1 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 510.2 130.6"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M475.1,61.6c1.1,0.2,1.6,1.1,1.7,2.1c0.1,1.1-0.8,1.6-1.7,1.9c-1.2,0.4-2.3,0.9-3.5,1.3c0,0.1,0.1,0.3,0.1,0.4
	c0.7,0.1,1.5,0.2,2.4,0.3c0,1.4,0.1,2.9,0,4.4c-0.3,6-1.1,12-0.6,18c0.1,1.8,0.3,3.6,0.4,5.3c0.1,1.8,0.2,3.7,0.4,5.5
	c0.3,3.5-1.3,7.6-4.8,9c-6.5,2.6-13.3,3.9-20.2,4.7c-1.4,0.2-2.9-0.1-4.3-0.2c-4.3-0.2-8.6-0.8-12.9-1.8c-3.2-0.8-5.9-2.4-8.7-3.9
	c-1.5-0.8-2.8-1.7-3.6-3.2c-0.8-1.5-0.2-3,0-4.5c0.3-2.5,0.6-5,1-7.5c0.4-2.5,0.9-5,1.1-7.6c0.3-3.9,0.4-7.9,0.6-11.8
	c0.1-2.6,0-5.3,0-8c0.5,0.1,1,0.3,1.4,0.3c0.3,0,0.6-0.1,1-0.2c-0.3-0.2-0.5-0.6-0.8-0.7c-1.5-0.2-2.7-1-3.7-2.1
	c-0.1-0.1-0.2-0.3-0.4-0.5c0.6-0.4,1.1-0.7,1.7-1c2.5,2.8,6,3.2,9.4,3.6c4.4,0.6,8.8,1.1,13.3,1.4c2.3,0.2,4.6,0.3,6.9,0.1
	c4.7-0.3,9.4-0.6,14-1.2c3-0.5,6-1.6,8.9-2.5C474.7,63.1,474.8,62.2,475.1,61.6z M463.2,81.3c0.2-0.8,0.3-1.4,0.5-2.1
	c0.1-0.2,0.4-0.5,0.6-0.5c1.9-0.3,3.8-0.5,5.7-0.7c1.3-0.2,1.9-0.7,1.9-1.7c0-1.2-0.6-1.8-2-1.9c-0.4,0-0.9,0-1.3,0.1
	c-1.8,0.3-3.5,0.6-5.3,0.8c-1.6,0.2-2.2,0.6-2.6,2.2c-0.6,2.4-1.1,4.8-1.5,7.2c-0.1,0.6,0.3,1.4,0.7,1.8c0.7,0.6,1.6,0.4,2.2-0.3
	c0.2-0.2,0.3-0.4,0.5-0.5c0.6-0.8,1.6-1.3,2.4-0.8c0.7,0.4,1.4,1.2,1.7,2c0.5,1.5,0.3,3-0.3,4.5c-0.9,2.3-3.2,3.3-5.3,2.2
	c-0.9-0.5-2.3-0.4-2.6,0.4c-0.3,0.7-0.3,1.8,0,2.4c0.9,1.6,2.5,2,4.3,1.5c0.7-0.2,1.5-0.4,2.2-0.6c2.4-0.4,3.6-2,4.7-4
	c1.2-2.3,1.5-4.7,0.7-7.3c-0.7-2.5-2.6-5.1-5.8-4.7C464.2,81.2,463.8,81.2,463.2,81.3z M440.5,82.6c0-0.5,0-1.4-0.2-2.2
	c-0.6-2.5-2.6-3.9-5.3-4.3c-1.9-0.3-3.5-0.3-4.6,1.4c-0.2,0.3-0.5,0.6-0.7,1c-0.8,2.3-1.7,4.5-2.2,6.9c-0.4,2-0.5,4.2-0.2,6.3
	c0.4,2.4,2.2,3.9,4.6,4.3c2.3,0.4,4.1-0.8,5.5-2.7C439.9,90.3,440.4,86.7,440.5,82.6z M451.6,88.9c0.7,0,1.4,0,2.1,0.1
	c1.4,0.3,2.3-0.2,2.5-1.5c0.2-1.2-0.6-2.1-2-2.2c-0.9,0-1.8,0-2.8,0c0.1-1.9,0.2-3.5,0.3-5.2c0-1.3-0.6-2-1.7-2
	c-1,0-1.5,0.7-1.7,1.9c-0.2,1.7-0.4,3.5-0.6,5.2c-1.4-0.1-3-0.1-3.2,1.5c-0.2,2.1,1.9,1.7,3.3,2.1c-0.1,0.6-0.2,1.2-0.2,1.7
	c-0.1,0.9-0.3,1.9-0.3,2.8c0,0.8,0.3,1.5,1.3,1.5c0.9,0,1.8,0,2-1.2C451,92.2,451.3,90.7,451.6,88.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M423.4,60.8c1.9-0.4,3.8-0.9,5.8-1.2c2.5-0.3,5-0.4,7.5-0.5c4.3-0.2,8.5-0.6,12.8-0.6c4.6,0,9.3,0.3,13.9,0.7
	c3.3,0.3,6.5,1.1,9.8,1.6c0.2,0,0.4,0.2,0.6,0.3c0,0.1,0.1,0.2,0.1,0.4c-1,0.4-2,1-3.1,1.3c-6,1.9-12.3,2.2-18.5,2.6
	c-5.5,0.4-10.8-0.4-16.2-1c-3.5-0.4-6.9-1.2-10.3-1.9c-0.8-0.2-1.6-0.7-2.4-1.1C423.4,61.2,423.4,61,423.4,60.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M478.7,86.1c-1.1,0.9-2,1.5-3.2,1c0-2.8,0-5.6,0.1-8.4c0-0.3,0.7-0.7,1.1-0.8c1.8-0.2,2.9-1.1,3.8-2.7
	c1-1.7,0.6-3.5-1.2-4.3c-1-0.4-2-0.8-3.3-1.3c-0.1-0.4,0.1-0.8,1-0.9c3.9-0.2,7,1.6,5.8,6.4c-0.2,0.9-0.8,1.8-1.5,2.5
	c-1,1-2.1,1.8-3.3,2.5c-2,1.2-1.6,3.6-0.3,4.9C478,85.5,478.3,85.8,478.7,86.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M421.1,69.3c-0.1,0.6-0.1,1.1-0.2,1.7c-1,0.3-2.1,0.4-3,0.8c-1.5,0.5-2.1,3-1.4,4.7c0.8,2,2.3,3,4.4,3.2
	c-0.1,1.3-0.1,2.5-0.4,3.7c-0.1,0.6-0.4,1.5-0.9,1.7c-0.9,0.4-2,0.4-2.7-0.8c0.4-0.3,0.8-0.6,1.2-0.9c1.4-0.9,1.5-1.9,0.3-3
	c-0.5-0.5-1.1-1-1.7-1.3c-2.6-1.4-3-4.1-1.8-7.1c0.9-2.2,2.7-3.5,5.6-2.7C420.7,69.2,420.9,69.2,421.1,69.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M91.9,64.7c1.3,0,2.7,0,4.1,0c0,1.4,0,3,0,4.5c-0.1,2.4-0.4,4.8-0.2,7.2c0.2,4.1,0.7,8.2,0.9,12.4
	c0.1,2.8-0.2,5.6-0.2,8.4c0,2.6-0.1,5.1,0,7.7c0.1,2.7-1.5,4.3-3.6,5.5c-1.8,1-3.8,1.4-5.7,2.1c-5.4,1.9-11.1,2.1-16.7,2.5
	c-4.3,0.3-8.4,0-12.6-0.8c-4.9-0.9-9.7-1.8-14.5-2.9c-2.2-0.5-4.1-1.7-4.8-4c-0.2-0.6-0.3-1.2-0.3-1.8c0.1-2.3,0.3-4.7,0.5-7
	c0.2-2.1,0.6-4.2,0.7-6.3c0.2-2.7,0.2-5.4,0.3-8.1c0-1,0.2-2,0.3-3c0-0.1,0-0.2,0-0.3c-0.1-1-0.4-1.9-0.3-2.9
	c0.3-4-0.4-7.9-1.3-11.8c-0.1-0.5-0.2-0.9-0.3-1.6c1.7,0.2,3.2,0.5,4.7,0.7c-2.5-2-5.9-1.4-8.4-3.4c0.7-1.2,1.6-0.5,2.3-0.4
	c1.5,0.3,3,0.9,4.6,1.1c4.4,0.5,8.8,1.1,13.2,1.3c3.6,0.2,7.2,0.1,10.9,0.1c5.5-0.1,11-0.3,16.4-0.7c3.6-0.3,7.2-1,10.8-1.6
	c1.4-0.2,2.8-0.7,4.1-1c0.3-0.1,1.1,0.3,1.1,0.5c0,0.5-0.2,1.2-0.5,1.4c-1.6,0.7-3.2,1.3-4.9,1.9c-0.2,0.1-0.4,0.2-0.7,0.2
	C91.9,64.5,91.9,64.6,91.9,64.7z M53.9,82.2c0.2-0.7,0.5-1.4,0.7-2.2c0.8-2.4-1.9-4.8-3.7-4.8c-2.5,0-4.3,1.4-5.9,3
	c-0.3,0.3-0.3,1.3-0.1,1.9c0.2,0.4,1,0.7,1.5,0.7c0.6-0.1,1.2-0.5,1.8-0.8c0.7-0.4,1.4-0.8,2.1-1.1c0.2-0.1,0.6,0,0.7,0.1
	c0.1,0.2,0.2,0.6,0.1,0.8c-0.8,1.6-1.4,3.3-3,4.4c-0.4,0.3-0.5,1.3-0.5,2c0,0.9,0.8,1.1,1.5,1c0.8-0.1,1.5-0.5,2.3-0.6
	c0.7-0.2,1.4-0.4,2.1-0.3c1.5,0.3,2.3,1.7,2.6,3c0.3,1.5-0.1,2.9-1.4,3.8c-1.9,1.3-4.2,1.2-6-0.4c-1.3-1.2-3.1-0.8-3.5,0.7
	c-0.3,1.3,0.8,3.1,2.6,3.5c1.8,0.4,3.7,0.7,5.5,0.8c2.4,0,4.3-1.4,5.6-3.2c2-3,1.4-7.6-1.3-10.1C56.4,83.3,55.1,82.9,53.9,82.2z
	 M81.5,79.4c-0.2-0.9-0.1-1.2,0.7-1.3c1.4-0.2,2.7-0.4,4.1-0.7c1.2-0.2,1.7-1.1,1.3-2.3c-0.3-1-1.2-1.3-2.5-1.1
	c-1.8,0.3-3.7,0.6-5.5,0.9c-1,0.2-1.6,0.7-1.6,1.9c0,1.2-0.1,2.5-0.2,3.7c0,0.5-0.1,1,0,1.4c0.1,1.6,1.1,2.2,2.5,1.6
	c0.4-0.2,0.8-0.4,1.3-0.7c1.1-0.6,2.7-0.4,3.6,0.6c1.5,1.8,1.1,4.9-1,6.3c-0.4,0.3-0.7,0.5-1.1,0.7c-1,0.7-2.2,0.7-3.1,0
	c-1-0.7-2.5-0.5-2.8,0.3c-0.6,1.3,0.4,3.2,1.8,3.5c5.6,1.3,11.3-2.9,10.6-9.3c-0.3-2.2-1-4.4-3.3-5.3C84.7,79.3,83.2,78.7,81.5,79.4
	z M66.1,86.4c0.2,1.6,0.3,3.1,0.6,4.7c0.3,1.3,1.2,1.7,2.4,1.2c0.9-0.4,0.8-1.2,0.7-1.9c-0.1-1.3-0.2-2.6-0.4-3.9
	c1.4-0.1,2.6-0.2,3.8-0.4c1-0.1,1.1-1,1-1.7c0-0.7-0.3-1.4-1.3-1.4c-1.3,0-2.5,0-3.9,0c0-1.1,0.1-2.1,0-3.1c0-0.4-0.2-1-0.5-1.2
	c-0.5-0.3-1.3-0.5-1.7-0.3c-0.5,0.3-1,1-1,1.7c-0.1,0.9,0.1,1.9,0.1,2.8c-3.6,0.5-3.8,0.5-3.6,2C62.8,87.2,64.8,86,66.1,86.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M94.4,60.2c-0.3,0.1-0.5,0.3-0.8,0.4c-5.2,1.4-10.4,2.2-15.8,2.3C71.5,63,65.3,63,59,62.9
	c-4.1-0.1-8.2-0.5-12.2-0.9c-2.3-0.2-4.6-0.7-6.8-1c-0.6-0.1-1.3-0.4-1.9-0.5c0-0.1,0-0.2,0-0.3c2-0.2,4-0.6,6.1-0.7
	c5.5-0.1,11-0.1,16.5-0.1c6.1,0,12.1,0.1,18.2,0.2c2.6,0,5.2-0.1,7.8-0.1c2.6,0.1,5.1,0.3,7.7,0.4C94.3,60,94.4,60.1,94.4,60.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M98,90c-0.1-2.3-0.5-4.3-0.3-6.4c0.1-1.9,0.9-3.5,2.9-4.5c3-1.5,3.3-3.7,2.6-6.7c-0.5-2.1-3.4-3-5.1-1.7
	c-0.3,0.2-0.6,0.3-0.9,0.5c-0.2-2.7,2-4.2,4.5-3.6c2.8,0.7,4,3.2,3.8,6c-0.2,2.5-1,4.8-2.7,6.8c-1.1,1.3-2.1,2.6-3,4
	c-0.6,1-0.3,1.9,0.5,2.6c1.1,0.9,0.9,2-0.4,2.5C99.3,89.7,98.7,89.8,98,90z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M35.7,88.1c0.4-0.6,0.8-1.3,1.3-1.9c0.8-0.9,0.9-2.2-0.2-3.3c-1-1-2.2-1.7-3.3-2.5c-0.7-0.5-1.6-0.9-2.3-1.5
	c-1-0.8-1.5-1.8-1.4-3.1c0.1-1.9,0.5-3.6,1.3-5.3c1.3-2.5,4-3.4,6-2.1c1.1,0.7,0.7,1.8,1,2.8c-0.7-0.1-1.3-0.4-1.9-0.4
	c-2.5-0.1-3.8,1.5-3.9,4c-0.1,3.2,1.6,4.2,4,5.1c2.4,0.9,1.9,0.5,2,2.7c0,1.4,0.1,2.9,0,4.3c0,0.6-0.2,1.6-0.5,1.7
	c-0.6,0.2-1.4-0.1-2.1-0.2C35.8,88.4,35.7,88.2,35.7,88.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M323.5,59.5c0.2,0.3,0.8,0.8,0.9,1.4c0,0.4-0.6,1-1.1,1.3c-0.9,0.6-1.8,0.9-2.7,1.7c0.8-0.1,1.7-0.1,2.5-0.2
	c0.2,3.3,0.6,6.6,0.6,9.8c0,2.3-0.7,4.5-0.8,6.8c-0.2,3.8,0,7.6-0.2,11.3c-0.2,3.9-0.3,7.8-1.2,11.7c-0.3,1.4-1.3,2.5-2.5,3.1
	c-2.7,1.2-5.4,2.3-8.2,3.2c-6.6,2.2-13.4,2.4-20.2,2.3c-6-0.1-11.9-1.6-17.3-4.2c-1.8-0.8-3.4-1.9-4.5-3.6c-1-1.4-1.1-2.9-0.9-4.6
	c0.6-4.5,1-9,1.4-13.4c0.2-2.2,0.4-4.4,0.5-6.5c0.1-2.5,0.2-5.1,0.2-7.6c0-0.8,0-1.6,0-2.4c0-2.1,0-4.3,0-6.4
	c0.7-0.1,1.4-0.1,2.2-0.2c0.2,0,0.3-0.3,0.6-0.5c-1.4-0.3-2.6-0.5-3.8-0.8c-0.5-0.2-1-0.8-1.4-1.2c0.4-0.5,0.8-1,1.3-1.3
	c0.2-0.2,0.7,0,1.1,0.2c1.3,0.7,2.6,1.7,4,2.1c5.3,1.7,10.9,1.8,16.4,2c1.9,0.1,3.7,0.4,5.5,0.4c2.1,0,4.1,0,6.2-0.2
	c3.6-0.2,7.2-0.3,10.7-0.9c3.2-0.5,6.3-1.4,9.4-2.2C322.6,60.5,322.9,60,323.5,59.5z M317,74.5c-1.4,1.5-2.7,2.8-4,4.1
	c-0.7,0.7-0.8,1.7-0.4,2.4c0.3,0.5,1.3,0.6,2,0.7c0.4,0.1,0.9-0.2,1.4-0.3c1.3-0.4,2.6,0.5,2.5,1.8c-0.1,1.3-0.5,2.7-0.9,3.9
	c-0.5,1.5-1.4,2.8-2.9,3.5c-0.7,0.3-1.6,0.3-2.5,0.3c-0.3,0-0.5-0.7-0.8-0.8c-0.7-0.2-1.4-0.5-2.1-0.4c-0.4,0.1-1.1,0.8-1,1.2
	c0.1,0.9,0.3,2.1,0.8,2.7c1.1,1.2,2.8,0.9,4.3,0.9c2.9,0,5-1.4,6.4-3.8c0.8-1.4,1.5-2.9,1.9-4.5c0.3-1.4,0.3-3,0.2-4.4
	c-0.2-1.9-1.7-3-3.3-3.9c0.4-0.5,0.7-0.9,1.1-1.4c1.2-1.7,0.8-3.7-0.8-4.8c-2.1-1.5-4.3-1-6.2,0.1c-1.3,0.8-2.4,2.2-3.3,3.4
	c-0.3,0.4,0,1.6,0.4,1.9c0.4,0.3,1.4,0,2-0.2c0.3-0.1,0.6-0.6,0.8-1C313.5,74.7,315.7,73.9,317,74.5z M283,86.4
	c1.9-3.5,3.8-7,5.6-10.6c0.3-0.6,0.4-1.6,0.2-2.2c-0.2-0.4-1.1-0.7-1.7-0.7c-3.7,0-7.4,0.1-11.1,0.2c-0.9,0-1.4,0.4-1.4,1.3
	c0,1.2,0.1,2.4,0.3,3.6c0.2,0.9,0.9,1.3,1.8,1.2c0.9-0.1,1.2-0.6,1.2-1.5c0-0.5,0-0.9,0-1.4c2.1,0,4.1,0,6.1,0c-1.4,3-2.7,5.9-4,8.9
	c-0.7,1.5-1.1,1.7-2.7,1.3c-0.2,0-0.3-0.1-0.5-0.1c-1.1-0.3-2.3,0.1-2.5,0.9c-0.3,1.1,0.2,2,1.6,2.4c0.9,0.3,1.9,0.4,2.9,0.6
	c0,0.3,0.1,0.5,0,0.7c-0.1,0.9-0.2,1.8-0.2,2.7c0,1,0.6,1.8,1.6,1.8c1,0,1.7-0.5,1.8-1.6c0.1-0.6,0.1-1.1,0.2-1.7
	c0.1-0.7,0.1-1.3,0.2-2.1c0.9,0.2,1.6,0.6,2.2,0.5c0.6-0.1,1.2-0.6,1.6-1.2c0.2-0.3,0.2-1.3-0.1-1.4C285.3,87.3,284.3,87,283,86.4z
	 M296.7,84.8c0.1,0.2,0.1,0.3,0.1,0.3c0,1,0,2,0,3c0,1.6,0.3,2,1.6,2c1.3,0,1.8-0.6,1.8-2.1c0-1,0-2,0-3c0.9,0,1.7,0.1,2.4,0
	c0.8-0.1,1.7-0.2,1.7-1.4c0-1.3-0.2-1.8-1.5-2c-0.9-0.1-1.7-0.1-2.6-0.2c0-1.3,0-2.5,0-3.7c0-0.4,0-0.9-0.3-1.1
	c-0.5-0.4-1.1-0.7-1.6-0.7c-1,0.1-1.1,1.1-1.2,1.8c-0.2,1.2-0.2,2.3-0.3,3.5c-0.1,0.1-0.1,0.1-0.2,0.1c-1,0-2.1,0-3.1,0.1
	c-1.5,0-2,0.4-1.9,1.6c0,1.1,0.7,1.7,2,1.7C294.6,84.9,295.7,84.8,296.7,84.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M271,58.2c3.1-0.4,6.1-1,9-1.1c5.4-0.2,10.9-0.1,16.3-0.1c1.5,0,3-0.2,4.5-0.2c2.4,0,4.9,0.2,7.3,0.3
	c2.8,0.2,5.6,0.4,8.4,0.7c1.7,0.2,3.4,0.7,5.1,1c0,0.1,0.1,0.3,0.1,0.4c-0.6,0.3-1.2,0.8-1.9,1c-3,0.6-6,1.3-9,1.7
	c-5.1,0.7-10.3,0.8-15.5,0.6c-4.7-0.2-9.4-0.4-14-0.9c-3.3-0.3-6.4-1.1-9.4-2.5c-0.2-0.1-0.4-0.2-0.5-0.3
	C271.3,58.8,271.2,58.7,271,58.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M327.9,82.6c-0.2,0.2-0.3,0.3-0.5,0.4c-0.4,0.3-1.2,0.8-1.3,0.7c-0.6-0.6-1.6-0.9-1.4-2c0.2-1.5,0.3-3,0.4-4.5
	c0-0.8,0.3-1.2,1.2-1.3c1.8-0.3,3.5-0.8,4.1-2.9c0.5-1.5-0.2-4.4-1.6-5.2c-1-0.5-2.2-0.4-3.6-0.6c-0.6-1.3-0.3-1.9,1.4-1.9
	c1.8,0,3.5,0.5,4.8,1.8c1.1,1,1.6,2.2,1.1,3.6c-1,2.6-2.4,5-4.6,6.8C325.9,79,325.9,80.3,327.9,82.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M268.3,67.7c-1,0.3-1.9,0.5-2.7,0.9c-1.1,0.7-1.6,2.8-1.1,4.5c0.4,1.4,1.1,2,2.9,2c0.9,0,1.1,0.4,1,1.2
	c-0.2,1.5-0.3,3-0.5,4.5c-0.1,0.8-0.8,1.1-1.4,0.8c-0.7-0.3-1.4-0.8-2-1.2c0.3-0.3,0.6-0.8,0.9-1c1-0.6,1.5-1.8,0.7-2.7
	c-0.6-0.7-1.3-1.4-2.1-1.9c-0.9-0.6-1.4-1.3-1.4-2.5c0-2.2,0.5-4.2,1.9-5.8c0.8-0.8,2.3-1,3.7-1.5C268.2,66.2,268.3,67,268.3,67.7z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M171.1,59.2c0,0,0.2,0.1,0.4,0.2c0.5,0.2,0.9,0.5,1.4,0.7c-0.4,0.5-0.6,1.1-1.1,1.4c-0.8,0.5-1.7,0.8-2.6,1.1
	c-0.4,0.2-0.8,0.3-1.2,0.7c1.2,0.1,2.4,0.2,3.6,0.3c0,2.5,0,4.9,0,7.4c0,1.3,0.3,2.7,0.4,4c0.1,1.5,0.1,2.9,0.4,4.4
	c0.5,2.9,0.6,5.7,0.5,8.7c-0.1,2.8,0.2,5.7,0.3,8.5c0.1,3.1-0.6,6.2-1.8,9c-0.4,1-1.2,1.8-2,2.6c-2,1.7-4.5,2.3-7.1,2.7
	c-5.9,1-11.7,1.9-17.7,1.9c-5.3,0-10.4-0.8-15.4-2.2c-2.8-0.8-5.4-2.3-8-3.7c-1.4-0.7-1.7-2.3-1.8-3.7c-0.2-2.9-0.3-5.9-0.2-8.8
	c0-2.3,0.4-4.6,0.5-6.9c0.1-1.1-0.1-2.3,0-3.4c0.1-1.8,0.4-3.6,0.3-5.4c0-2.5-0.1-5-0.4-7.4c-0.2-1.8-0.7-3.5-1.2-5.6
	c0.7,0.2,1.2,0.4,1.6,0.6c0.8-0.7,0.3-1.1-0.5-1.4c-1.3-0.5-2.6-0.9-3.9-1.6c-0.5-0.2-1.1-0.9-1.1-1.4c0-0.5,0.6-1,1.1-1.4
	c0.2-0.2,0.8,0,1.2,0.1c1.6,0.6,3.2,1.3,4.9,1.8c1.4,0.4,3,0.3,4.4,0.6c7.1,1.4,14.2,1.4,21.4,1.5c2.1,0,4.2,0,6.3-0.2
	c0.8,0,1.6-0.4,2.4-0.5c1.6-0.2,3.2-0.3,4.8-0.6c2.9-0.5,5.9-0.9,8.5-2.4C170,60.2,170.4,59.8,171.1,59.2z M161.9,91.6
	c0.5-0.5,0.7-0.8,1-1.1c1.8-1.9,3.8-3.6,5.5-5.6c2.6-3.1,1.4-9.6-3.5-10.4c-3.6-0.5-6.6,2.3-7.3,4.6c-0.2,0.7-0.4,1.7,0.7,2.1
	c1,0.3,1.9,0.3,2.5-0.7c0.3-0.4,0.5-0.9,0.7-1.4c0.5-1,1.6-1.6,2.7-1.3c1.9,0.4,2.9,2.5,1.9,4.1c-1,1.6-2.4,3-3.7,4.5
	c-0.9,1-2.2,1.7-3.1,2.8c-1.1,1.3-2.1,2.8-3,4.3c-0.3,0.6-0.3,1.6,0,2.2c0.2,0.4,1.1,0.5,1.8,0.5c0.3,0,0.7-0.5,1.1-0.6
	c0.6-0.3,1.3-0.8,1.7-0.6c2,0.7,3.9,1.6,5.8,2.4c1,0.4,2.1,0.6,2.9-0.1c0.7-0.7,1.2-1.6,1.7-2.5c0.5-0.7,0.2-1.4-0.4-1.9
	c-0.7-0.5-1.4-0.5-2.1,0c-0.4,0.3-0.8,0.7-1.2,1.1C165.8,93.2,164,92.5,161.9,91.6z M127.2,87.8c0.8-2,1.5-3.9,2.4-5.7
	c0.9-2,2-4,3-6c0.4-0.9,0.6-1.9-0.3-2.5c-0.9-0.6-1.8-0.3-2.4,0.6c-0.8,1.2-1.5,2.4-2.2,3.7c-1.7,3.2-3.2,6.4-4,9.9
	c-0.6,2.6,0,3.3,2.7,3.4c1.5,0.1,3.1,0.2,4.7,0.2c0,0.4,0,0.8,0,1.1c-0.2,1.8-0.5,3.6-0.7,5.4c-0.2,1.5,0.2,2.1,1.4,2.1
	c1.1,0,1.6-0.6,1.8-1.7c0.3-2.4,0.7-4.7,1-7.1c0.7-0.2,1.7-0.3,1.8-1.5c0.1-1.4-0.8-1.8-2-2.1c0.1-0.8,0.1-1.5,0.3-2.2
	c0.2-1.1-0.2-2-1.1-2.2c-1.1-0.3-2,0.2-2.2,1.4c-0.2,1-0.3,2.1-0.4,3.2C129.7,87.8,128.5,87.8,127.2,87.8z M148,83
	c0.1-1.4,0.3-2.6,0.3-3.8c0-1.3-0.6-1.8-1.8-1.8c-1.2,0-1.5,0.4-1.6,1.7c0,1.2,0,2.5,0,3.8c-0.6,0-1,0-1.4,0c-0.4,0-0.9,0-1.3,0
	c-1.1,0.1-1.8,0.7-1.8,1.6c0,1,0.6,1.7,1.8,1.7c0.8,0,1.7,0,2.6,0c0.1,1.3,0.3,2.5,0.4,3.7c0.1,1,0.8,1.6,1.7,1.6
	c1.3,0,1.9-1.3,1.5-2.9c-0.2-0.7-0.3-1.5-0.4-2.3c1.3-0.1,2.4-0.2,3.5-0.3c1.1-0.1,1.5-0.8,1.4-1.8c-0.1-1-1-1.6-2.3-1.5
	C149.9,82.8,149.1,82.9,148,83z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M168.7,59.1c-2.3,1.3-4.7,2.1-7.3,2.5c-4.8,0.6-9.5,1.3-14.3,1.6c-4,0.2-8.1-0.1-12.2-0.1
	c-4.7-0.1-9.2-1.3-13.8-2.2c-0.8-0.1-1.5-0.5-2.5-0.8C123.9,56.4,166.4,55.7,168.7,59.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M118.2,79c0,2.4,0,4.8-0.1,7.1c0,0.3-0.8,0.7-1.2,0.7c-0.6-0.1-1.2-0.5-1.8-0.7c-0.1-0.2-0.2-0.4-0.2-0.6
	c0.4-0.2,0.8-0.4,1.2-0.7c1.1-0.7,1-1.8,0.7-2.8c-0.3-1-1.1-1.6-2.2-1.7c-2.5-0.2-3.3-0.9-3.8-3.3c-0.7-3.4,1.5-7.6,4.9-8.6
	c1.7-0.5,1.7-0.5,1.9,1.3c-3.1,0.6-4.5,2.2-4.5,5.1C112.9,77.1,114.2,78,118.2,79z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M174.1,80.4c0,0.1-0.1,0.2-0.2,0.5c-0.2-1.1-0.4-2.1-0.5-3.1c-0.1-0.8,0.4-1.1,1-0.9c2,0.6,2.8-1,3.7-2.1
	c0.9-1.1,0.7-2.5,0.2-3.8c-0.9-2.3-2.6-3.4-5.1-3.2c-0.1-0.1-0.1-0.2-0.2-0.3c0.3-0.3,0.7-0.7,1-0.8c2.2-0.3,5.4,1.5,6.4,3.3
	c1,1.9,0,6.5-2.7,7.5c-0.8,0.3-1.7,1-1.9,1.7c-0.3,1.2,0.6,2,1.6,2.6c0.7,0.4,1,0.9,0.4,1.6c-0.3,0.3-0.5,0.7-0.8,0.9
	c-0.7,0.3-1.4,0.5-2.1,0.7c-0.2-0.8-0.4-1.7-0.5-2.5c-0.1-0.6,0-1.3,0-1.9C174.2,80.5,174.1,80.5,174.1,80.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M370.4,64.3c3.5,0,6.9,0,10.4,0c0,0.1,0,0.2,0,0.3c-1.2,0-2.5,0-3.7,0c0,0.1,0,0.2,0,0.3c2.2,0,4.5,0,6.7,0
	c0,0,0-0.1,0.1-0.1c-0.6-0.1-1.2-0.1-1.8-0.2c0.8,0.1,1.8-0.8,2.5,0.4c0.1,0.1,0.4,0.2,0.7,0.2c4.1,0.1,8.2-0.1,12.2-1
	c1.1-0.2,2.1-0.6,3.2-0.8c1.2-0.3,1.8-0.9,1.9-2.5c0.6,0.5,1.2,0.8,1.2,1.2c0.1,0.6,0,1.5-0.4,1.8c-1.2,0.8-2.6,1.4-3.9,2
	c-0.4,0.2-0.9,0.3-1.4,0.7c0.2,0,0.5,0.1,0.7,0.1c1,0.1,2,0.1,3.1,0.2c0,0.8,0.2,1.6,0,2.3c-1.7,5.1-2.1,10.5-2,15.8
	c0.1,4.4-0.6,8.8-0.9,13.2c-0.1,1.2-0.1,2.4,0.9,3.5c0.8,0.9,0.1,2.6-0.8,3.3c-1.5,1.3-3.2,2.7-5,3.3c-3.3,1.2-6.8,2.1-10.2,2.7
	c-3.1,0.6-6.2,0.7-9.3,0.9c-2.4,0.2-4.7,0.2-7.1,0.1c-2.4,0-4.9,0-7.3-0.4c-4.5-0.8-9-1.8-13-4.2c-1.6-1-3.3-2.2-3.3-4.5
	c0-0.1,0-0.2,0-0.2c0.5-3.7,0.6-7.5,1.5-11.1c0.7-3.1,0.9-6.3,1.1-9.5c0.3-3.5,0.2-7,0.2-10.4c0-1.8-0.3-3.7-0.5-5.5
	c0-0.3,0-0.7,0-1.3c0.6,0.3,1,0.5,1.5,0.6c0.2,0.1,0.5,0,0.7,0c0-0.3,0-0.7-0.1-0.8c-0.6-0.4-1.2-0.8-1.8-1.1
	c-0.3-0.2-0.7-0.1-1-0.3c-0.5-0.4-1-0.9-1.5-1.3c0.4-0.5,0.8-1,1.4-1.4c0.5-0.4,0.9-0.3,1.5,0.2c1,0.8,2.1,1.6,3.3,1.8
	c4.2,0.8,8.5,1.3,12.7,2c0.2,0,0.3,0,0.8-0.1c-0.5-0.2-0.7-0.3-1-0.4c0-0.1,0-0.2,0-0.3c1,0.1,2.1,0.1,3.1,0.2
	c0.4,0,0.5,0.1,0.4,0.5c0.3-0.1,0.7-0.1,0.9-0.2c0.7,0.2,1.3,0.4,1.9,0.4c1.7,0.1,3.5,0,5.2-0.1c-1.3,0-2.6,0-3.9,0
	C370.4,64.6,370.4,64.5,370.4,64.3z M389.3,76.4c2.4-0.3,4.6-0.5,6.8-0.7c1.2-0.1,1.9-0.9,1.8-1.9c-0.1-1.1-0.9-1.8-2.2-1.7
	c-2.5,0.2-4.9,0.4-7.3,0.8c-0.9,0.1-2.2,0.2-2.5,1.6c-0.5,3-0.9,6-1.4,9c-0.1,0.8,0,1.6,0.9,2c0.9,0.4,1.5,0.1,2.2-0.5
	c0.4-0.4,1.3-0.7,1.9-0.6c1.2,0.2,2.6,2.6,2.4,3.8c-0.2,1.3-0.6,2.6-1.7,3.4c-1,0.7-2,1.4-2.9,2c-0.8,0.6-1.7,0.4-2.2-0.4
	c-0.2-0.3-0.5-0.5-0.7-0.7c-0.9-0.5-1.8-0.3-2.4,0.6c-0.5,0.7-0.2,2.6,0.5,3.2c1.7,1.6,5,1.8,6.9,0.6c0.3-0.2,0.6-0.4,0.9-0.6
	c2.7-1.5,4.6-3.5,5.3-6.8c0.6-2.9-1.2-8.3-5.2-8.6c-0.5,0-1.1-0.2-1.7-0.4C388.8,79.2,389.1,77.8,389.3,76.4z M353.6,90.2
	c0.3-0.4,0.8-0.9,1.2-1.4c1-1,2.1-1.8,3-2.9c1.5-1.7,2.3-3.6,1.9-5.9c-0.4-2.2-1.9-4.1-3.5-4.3c-3-0.4-4.7,0.9-5.6,3
	c-0.3,0.6-0.1,1.7,0.2,2.2c0.6,0.7,1.6,0.6,2.4,0.1c0.4-0.3,0.5-0.9,0.9-1.2c0.3-0.3,0.7-0.8,1.1-0.7c0.3,0,0.7,0.5,0.9,0.9
	c0.5,0.8,0.5,1.7-0.1,2.5c-0.8,1-1.6,2-2.5,2.8c-2.2,2-4.1,4.1-4.9,7c-0.3,1-0.3,1.9,0.4,2.6c0.8,0.7,1.8,0.5,2.7-0.2
	c0.9-0.6,1.8-0.6,2.5,0.2c1.6,1.9,3.6,1.7,5.7,1.6c1.2-0.1,1.8-1,1.6-2c-0.2-1.2-1-1.6-2.3-1.4c-0.9,0.2-1.5,0-2.3-0.6
	C356.1,91.5,354.9,91,353.6,90.2z M369.1,84.7c-1-0.1-2-0.1-2.9-0.2c-1.2-0.2-2,0.2-2.3,1.2c-0.2,0.9,0.3,1.8,1.5,2
	c1.1,0.2,2.3,0.3,3.5,0.5c0,1-0.1,2.1,0,3.1c0.1,1.1,0.9,1.9,1.8,1.7c1-0.1,1.5-0.8,1.5-1.8c0-0.9,0-1.7,0-2.6
	c0.4,0,0.7-0.1,0.9-0.1c0.5,0,1,0,1.4,0.1c1,0.2,1.6-0.3,1.9-1.2c0.3-0.8-0.2-1.9-1-2.1c-1-0.2-1.9-0.3-3-0.4c0-0.8,0-1.6,0-2.4
	c0-0.8,0.2-1.7,0.2-2.5c0-1.3-0.3-1.7-1.5-1.7c-1.2,0-1.8,0.5-1.9,1.6C369.3,81.5,369.2,83,369.1,84.7z M397.5,85.7
	c-0.1,0-0.1,0-0.2,0c0,1.6,0,3.2,0,4.8c0.1,0,0.1,0,0.2,0C397.5,88.9,397.5,87.3,397.5,85.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M375.2,57.1c-0.1,0.2-0.1,0.3-0.4,0.7c1.4-0.4,2.8-0.2,4-0.3c0.4-0.1,0.8-0.1,1.2-0.2c2.9,0.7,5.9,1,8.9,0.8
	c0,0-0.1,0.1-0.1,0.2c3.1,0.5,6.1,1,9.1,1.5c0.6,0.1,1.3,0.2,1.9,0.4c0.4,0.1,0.6,0.5,1,0.8c-0.3,0.3-0.6,0.7-0.9,0.8
	c-2.4,0.5-4.9,1.3-7.4,1.4c-4.1,0.3-8.2,0.2-12.2,0.2c-3.7,0-7.5,0.1-11.2,0.1c-5.4-0.1-10.7-0.5-16-1.6c-1.8-0.4-3.6-0.5-5.4-1.8
	c1.3-0.3,2.4-0.7,3.6-0.9c3-0.5,6.1-0.9,9.1-1.2c2.9-0.2,5.9-0.2,8.8-0.3c1.7-0.1,3.3-0.3,5-0.4C374.4,57.3,374.8,57.2,375.2,57.1z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M345,69.3c-0.9,0.1-1.8-0.1-2.5,0.3c-2.6,1.2-3.7,5-0.5,7.2c0.7,0.5,1.5,0.8,2.4,1.1c0.9,0.3,1.5,0.7,1.1,1.8
	c-0.3,1-0.3,2.1-0.8,2.9c-0.8,1.5-2.8,1.4-3.5,0c0.9-0.4,2.1-0.6,2.2-1.7c0.1-1.2-0.5-2.2-1.7-2.7c-0.3-0.1-0.5-0.3-0.8-0.4
	c-2.3-1-3.1-2.3-2.7-4.8c0.2-1.3,0.6-2.6,1.1-3.9c0.4-1.1,2.5-1.8,4.2-1.5C344.5,67.7,345.3,68,345,69.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M405.3,78.5c0-0.2-0.1-0.6,0-0.7c1.2-0.8,1.5-2.1,1.6-3.3c0.3-2.6-1.1-4-3.7-3.9c-0.2,0-0.5,0-0.7,0
	c0.1-1.7,1.6-2.6,3.4-2c1.8,0.5,2.8,2,2.8,3.9C408.9,75.3,407.7,77.2,405.3,78.5L405.3,78.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M405.3,78.5c-1.8,1-1.9,2.7-0.5,3.9c0.3,0.2,0.7,0.7,0.6,0.9c-0.3,0.5-0.6,1-1.1,1.2c-1.6,0.6-3-0.6-3.3-2.1
	c-0.2-1.1,0.1-2.3,0.2-3.3C402.8,78.8,404.1,78.7,405.3,78.5C405.3,78.5,405.3,78.5,405.3,78.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M194,62c1.8,0.4,3.2,0.7,4.6,1c0.1-0.2,0.1-0.4,0.2-0.6c-2-1.3-4.4-1.5-6.7-2.8c0.7-0.5,1.4-0.9,2-1.3
	c2.1,1.7,4.6,2.3,7,2.7c2.6,0.4,5.2,0.6,7.8,0.8c3.3,0.3,6.6,0.6,9.9,0.6c4.2,0,8.3-0.2,12.4-0.5c2.6-0.2,5.2-0.7,7.8-1.2
	c1.1-0.2,2.1-0.9,3.1-1c1.1-0.1,2-0.7,2.9-0.7c2,0,2.6-0.1,3.2-1.9c0.6,0.5,1.5,0.9,1.6,1.5c0.1,0.5-0.6,1.2-1.1,1.6
	c-0.5,0.4-1.1,0.6-1.7,0.8c-0.6,0.2-1.2,0.4-1.8,0.5c0,0.1,0,0.2,0,0.3c0.8,0.1,1.5,0.1,2.3,0.2c0.4,0.1,0.9,0.4,0.9,0.7
	c0.1,1.5,0.2,3,0.1,4.5c-0.1,2.2-0.4,4.3-0.5,6.5c-0.1,3.2-0.1,6.4-0.1,9.6c0.1,3,0.3,6,0.3,9c0.1,2.5,0.1,5,0,7.5
	c0,1.1-0.3,2.1-0.4,3.2c-0.3,2.4-1.9,3.7-4.1,4.4c-4.8,1.3-9.6,2.4-14.6,2.8c-6.8,0.6-13.6,0.5-20.4,0.3c-3.6-0.1-7.2-1.1-10.6-2.4
	c-1.1-0.4-2.1-1.2-3-1.9c-0.3-0.2-0.5-0.7-0.6-1.1c-0.2-3.6-0.8-7.2-0.5-10.7c0.4-5.3,0.2-10.6,0.2-15.9c0-4.3-0.1-8.7-0.1-13
	C194.3,64.5,194.1,63.3,194,62z M206.9,84.2c0.4-0.5,0.6-0.9,0.9-1.2c1-1.2,1.9-2.5,2.2-4.2c0.2-1.3,0.4-2.7-0.8-3.8
	c-1-0.9-2.2-1.8-3.2-2.7c-1.5-1.3-3.3-1.3-4.8-0.1c-0.1,0.1-0.3,0.3-0.5,0.3c-2.7,0.9-3.6,4-3.2,5.8c0.5,2.7,2,4.9,4.2,6.6
	c-0.5,0.7-0.9,1.4-1.4,2c-1.8,2.3-2.3,4.8-0.9,7.4c0.4,0.7,1.1,1.6,1.8,1.8c1.5,0.4,3.2,0.7,4.8,0.6c3.9-0.4,5.3-4,4.4-7.1
	C209.7,87.7,208.9,85.6,206.9,84.2z M238.6,76.5c0.1,0.1,0.3,0.1,0.4,0.2c-0.3,2.5-0.6,5-0.7,7.4c-0.1,2.2-0.2,4.5,0,6.7
	c0.1,1.2,0.8,1.6,2,1.5c1.1-0.1,1.5-0.8,1.5-1.8c0.2-4.8,0.4-9.5,0.7-14.3c0.1-1.2,0.4-2.3,0.4-3.5c0-0.6-0.2-1.5-0.7-1.9
	c-0.4-0.3-1.5-0.4-1.8-0.1c-2.4,1.9-4.6,3.9-6.9,5.8c-0.8,0.7-0.9,2-0.2,2.7c0.6,0.6,1.8,0.5,2.6-0.2
	C236.7,78.1,237.7,77.3,238.6,76.5z M220.5,81.5c-1.1,0-2.3,0-3.4,0c-0.8,0-1.7,0.1-1.8,1.2c-0.1,1.1,0.4,1.8,1.5,1.9
	c1.2,0.2,2.5,0.2,3.6,0.3c0,1.8,0,3.5,0,5.3c0,1.5,0.5,2.1,1.7,2.1c1.2,0,1.7-0.6,1.7-2.1c0-1.8,0-3.6,0-5.3
	c1.4-0.5,3.6,0.9,3.8-1.6c0.1-2.3-2.2-1.5-3.6-2.1c0.1-1.6,0.1-3.3,0.3-4.9c0.1-0.9-0.4-1.7-1.3-1.9c-1.2-0.2-1.9,0.2-2,1.2
	C220.7,77.6,220.6,79.5,220.5,81.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M195.9,57.6c0.7-0.3,1.3-0.7,2-0.8c5.5-1.5,11.1-2.1,16.8-1.9c3.3,0.1,6.6-0.1,9.8,0c2.5,0.1,5,0.4,7.4,0.6
	c2.2,0.2,4.4,0.2,6.7,0.4c2.3,0.1,4.7,0.3,7,0.5c0.4,0,0.8,0.2,1.2,0.3c0,0.1,0.1,0.2,0.1,0.3c-0.6,0.3-1.1,0.6-1.7,0.8
	c-5.5,1.6-11.2,2.5-17,2.8c-5.9,0.3-11.8,0.3-17.7,0.2c-4.3-0.1-8.7-0.3-12.8-2c-0.6-0.2-1.3-0.5-1.9-0.8
	C195.9,57.9,195.9,57.7,195.9,57.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M193.2,68.7c-0.7,0.1-1.3,0.1-1.9,0.2c-1.5,0.3-2.1,1-2.4,2.5c-0.3,1.8,0.1,3.4,1.3,4.7c0.4,0.4,1,0.8,1.6,0.9
	c1.2,0.2,1.7,0.6,1.7,1.9c0,1.4-0.1,2.8-0.2,4.2c-0.1,0.4-0.7,1.2-0.9,1.1c-0.8-0.1-1.5-0.5-2.3-0.8c0.2-0.5,0.3-1,0.6-1.4
	c1.6-1.7,1.6-2-0.3-3.5c-0.3-0.2-0.6-0.5-0.9-0.6c-3-1.9-4.1-5.2-2.8-8.5c0.8-2.1,3.7-3.4,5.7-3.4c0.3,0,0.7,0.5,0.8,0.8
	C193.3,67.4,193.2,68,193.2,68.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M250.5,68.2c0-0.9,0-1.5,0-2.2c2.4-1.1,6.5,1.5,6.6,4.4c0.1,3-1.1,5.2-3.8,6.7c-0.6,0.3-1.1,0.9-1.6,1.5
	c-0.6,0.7-0.4,1.4,0.2,2c0.3,0.2,0.5,0.5,0.7,0.8c0.3,0.4,1,0.7,0.4,1.4c-0.5,0.5-1.2,1-1.9,0.4c-0.8-0.7-1.9-1.3-1.5-2.8
	c0.1-0.6,0.1-1.2,0-1.8c-0.2-1.4,0.6-1.8,1.7-2.1c0.5-0.1,1-0.3,1.5-0.5c2.3-1.1,2.9-3.4,1.5-5.5C253.4,69.1,252.4,68,250.5,68.2z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M244.3,33.8c-2.1-1.1-4.3-2.2-6.6-2.8c-5.8-1.6-11.6-2.8-17.6-3.1c-5.1-0.3-10.3-0.5-15.3,0.5
	c-1,0.2-2,0.5-3,0.7c-5,1-9.6,2.8-13.3,6.3c-0.6,0.6-0.9,1.6-1.3,2.4c-0.2,0.3-0.3,0.7-0.6,1.3c0.9,0.4,1.8,1,2.8,1.4
	c0.8,0.3,1.8,0.4,2.7,0.6c3.2,0.9,6.4,2,9.7,2.6c3.5,0.7,7,0.9,10.6,1.3c1.2,0.1,3.9,0.3,5.1,0.2c-0.7-0.4-0.4-1.9-0.1-1.9
	c0.1-0.6,0.2-1.1,0.3-1.7c-0.5,0-0.8,0-1.2,0c-0.4,0-0.7,0-1.1,0c-1,0-1.6-0.7-1.6-1.7c0-1,0.6-1.7,1.6-1.7c0.9,0,1.8,0.1,2.6-0.1
	c0.4,0,0.9-0.4,1.1-0.7c0.7-1.6,1.4-3.3,2.1-5.1c-1.2,0.1-2.2,0.2-3.3,0.3c0,0.3,0,0.5,0,0.7c0,1-0.4,1.8-1.4,1.9
	c-1,0.1-1.7-0.4-1.8-1.6c0-0.5-0.3-1.1-0.5-1.6c-0.5-1.7,0.3-2.8,2-2.8c2.6,0,5.3,0,7.9,0c1,0,1.8,0.2,2.2,1.2
	c0.5,1-0.2,1.5-0.8,2.2c-1.3,1.4-2.2,3-2.8,4.9c1,0.2,1.4,0.7,1.4,1.7c0,1.2-0.4,1.6-2.1,1.7c-0.7,0.1-0.9,0.3-1,0.9
	c-0.1,0.5-0.2,1-0.3,1.5c0,0,0,0,0,0c-0.4,1.2-0.6,1.9-0.4,1.9c2.5,0,5.1-0.5,7.4-0.5c6.3,0,11.8-2.2,17-5.5c1-0.6,1.7-1.4,1.6-2.7
	C246.2,35.3,245.3,34.4,244.3,33.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M247.5,35.6c-0.4-0.6-0.7-1.3-1.2-1.7c-1-0.8-2.1-1.5-3.3-2.1c-5.3-2.6-11.1-3.8-16.9-4.3
	c-5.1-0.5-10.3-0.8-15.4-0.9c-4.1-0.1-8.2,0.8-12.1,2.2c-2.5,0.9-5,1.7-7.3,3c-1.7,0.9-3.1,2.3-4.5,3.7c-0.6,0.6-0.8,1.6-1.2,2.4
	c-2.3-1.5-2-3.1,0-4.8c2.3-2,4.9-3.6,7.8-4.7c0.8-0.3,1.6-0.5,2.4-0.8c0-0.1,0-0.2-0.1-0.3c-0.9,0-1.9,0.1-2.8,0.1
	c0-0.1-0.1-0.2-0.1-0.3c1.4-0.7,2.9-1.5,4.4-2.1c2.4-0.9,4.9-1.9,7.4-2.5c2-0.5,4.1-0.6,6.1-0.8c4.2-0.4,8.4-0.1,12.6,0.6
	c4.6,0.7,9.2,1.5,13.3,4c0.5,0.3,1.1,0.5,1.7,0.8c-0.3,0.3-0.6,0.6-1.2,1c0.5,0.2,0.9,0.3,1.2,0.4c1.6,0.5,3.4,0.7,4.9,1.5
	c1.5,0.8,2.8,2.2,4,3.4c0.5,0.5,0.4,1.5,0.6,2.2C247.7,35.5,247.6,35.6,247.5,35.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M219.7,20.4c-1.7-0.1-3.5-0.3-5.2-0.3c-1.4,0-2.2-1-2.5-2c-0.2-0.6,0.5-1.8,1.1-2.2c3-1.9,6.4-1.3,8.2,1.9
	c0.3,0.5,0.2,1.4,0,1.9c-0.2,0.4-0.9,0.6-1.4,1C219.8,20.6,219.8,20.5,219.7,20.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M441.6,28.2c5.1,0.2,10.3,0,15.4,0.5c4.9,0.5,9.6,1.9,13.9,4.4c1.5,0.9,2.9,2,4.1,3.2c1.5,1.5,1.3,3.1-0.6,4.2
	c-4.8,2.8-9.9,5.1-15.5,5.6c-4,0.4-8,0.5-12,0.4c-3.7-0.1-7.4-0.4-11.1-1c-3.4-0.6-6.7-1.5-9.9-3c-1.4-0.7-2.9-1.3-4.3-1.8
	c-1.5-0.6-2.5-1.7-3.2-3.1c-0.5-1,0.5-2.9,1.7-3.8c2.2-1.7,4.8-2.5,7.3-3.4c4-1.4,8.2-1.6,12.4-1.9
	C440.5,28.3,441.1,28.3,441.6,28.2C441.6,28.3,441.6,28.2,441.6,28.2z M451.4,38.2c0.1,1.5-1,1.9-1.9,2.5c-0.7,0.5-1.4,0.5-2.2,0.1
	c-0.6-0.3-1.4-0.5-2-0.4c-0.7,0.1-1.6,0.5-1.5,1.5c0.1,1.2,1.8,2.7,3,2.7c0.6,0,1.1,0,1.7,0c3.3-0.1,6.8-2.6,6.6-6.5
	c-0.1-1.9,0-3.9,0-5.8c0-1.5-0.4-1.8-1.8-1.9c-0.5,0-1,0-1.4-0.2c-2-0.8-3.8-0.3-5.6,0.7c-1.1,0.6-1.9,1.5-2.1,2.7
	c-0.6,3.4,0.5,5.5,4.5,5.1C449.6,38.7,450.4,38.5,451.4,38.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M422.7,27.3c-0.5,0.1-0.9,0.1-1.4,0.2c-0.1-0.1-0.1-0.2-0.2-0.2c1-0.8,1.9-1.9,3.1-2.4c2.2-1,4.6-1.7,7-2.3
	c2.2-0.6,4.6-0.9,6.9-1.4c3.4-0.6,6.9-1.3,10.4-0.9c3,0.4,6,0.6,8.9,1.3c5.1,1.2,10.1,3,14.3,6.2c0.8,0.6,1.5,1.5,2.2,2.3
	c-0.1,0.1-0.1,0.2-0.2,0.2c-0.4,0-0.8-0.1-1.2-0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0.5,0.4,1,0.8,1.5,1.2c1.4,1.1,3.1,2,4.3,3.3
	c1.5,1.6,1.3,2.7-0.4,4.1c-0.5-1.1-0.8-2.3-1.6-3.2c-2-2.4-4.6-3.8-7.5-5c-4.2-1.7-8.6-3.2-13.1-3.4c-6.2-0.4-12.5-0.8-18.7,0.1
	c-3.1,0.4-6.2,0.6-9.1,1.5c-3.1,0.9-6.2,2-8.8,4.1c-0.7,0.5-1.2,1.3-1.9,2c-1.3-0.9-1.5-1.5-0.7-2.8c1.2-2.1,3.1-3,5.2-3.9
	c0.4-0.2,0.8-0.5,1.3-0.7C422.8,27.5,422.8,27.4,422.7,27.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M450.6,18.7c-2.2,0-4.3,0.1-6.5,0c-1.1-0.1-1.7-0.9-2.1-1.9c-0.3-0.8,0-1.4,0.6-2c1.8-1.8,4-1.7,6.2-1.2
	c1.2,0.3,2.4,1,3.4,1.8c0.5,0.4,1,1.6,0.8,2c-0.4,0.8-1.4,1.3-2.2,1.9C450.7,19.1,450.6,18.9,450.6,18.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M36.8,36.3c1.8-1,3.4-2.2,5.2-2.9c2.6-1.1,4.8-3.2,7.8-3.6c0.7-0.1,1.5-0.1,2.2-0.2c0.3,0,0.6,0,0.8-0.1
	c2.7-1.3,5.6-1.3,8.4-1c2.5,0.2,4.9-0.4,7.3,0c1.8,0.2,3.6,0.5,5.4,0.8c2.1,0.3,4.1,0.7,6.2,1.1c1,0.2,2,0.2,3,0.5
	c3.8,1,7.6,2,10.6,4.7c0.9,0.9,1.7,2.1,2.1,3.3c0.2,0.6-0.2,1.8-0.7,2.4c-2.2,2.6-5.1,4.2-8.6,4.8c-2.4,0.4-4.7,1.4-7.1,1.8
	c-3.2,0.5-6.4,0.9-9.6,1c-4.6,0.1-9.3,0.1-13.9-0.5c-4.3-0.6-8.8-1.2-12.8-3.2c-1.8-0.9-3.8-1.3-5.4-2.4c-1.6-1.1-2.9-2.7-4.4-4.1
	c-0.5-0.5-1.1-0.8-1.9-1.2c0.3-0.4,0.5-0.9,0.8-1.2c1.3-1.1,2.4-2.2,4.1-2.9c2.3-1,4.3-2.6,6.3-4c2.6-1.9,5.5-3,8.5-3.9
	c1.9-0.5,3.8-1.1,5.8-1.3c4.1-0.6,8.3-0.9,12.5-0.2c3.9,0.6,7.8,1.1,11.7,1.9c1.5,0.3,3,1,4.4,1.7c3.6,1.7,7.4,3,10.4,6
	c1.3,1.3,1.9,2.9,1.2,4.7c-0.1,0.2-0.2,0.3-0.5,0.5c-0.4-0.7-0.7-1.4-1.2-2c-2.5-2.9-5.5-4.6-9.3-5.2c-3.1-0.6-6.1-1.6-9.2-2.2
	c-2.5-0.5-5.1-0.9-7.6-1.3c-1.8-0.2-3.6-0.3-5.3-0.4c-2.1,0-4.1,0.1-6.2,0.3c-1.7,0.1-3.5,0.3-5.2,0.5c-0.5,0.1-1,0.1-1.5,0.2
	c-2.4,0.8-4.8,1.5-7.1,2.4C41.2,31.9,38.6,33.5,36.8,36.3z M61.1,38.4c0.2-0.6,0.2-1.3,0.6-1.8c1.7-2.3,3.9-4,7-2.5
	c0.9,0.5,2.3,0.1,2.6-0.6c0.4-0.8,0-2.2-1-2.6c-2.7-1.1-5.5-1.3-8.2,0.3c-2.8,1.6-4.3,4-4.6,7.3c-0.2,2.8,0.5,4.6,2.9,5.9
	c1.3,0.7,2.8,1.3,4.3,1.5c3,0.3,6.3-0.5,7.1-4.4c0.5-2.3-0.1-4.1-2.7-4.6C66.4,36.3,63.7,36.6,61.1,38.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M64.6,22c-0.3,0-0.6,0-1,0c-0.9,0.1-1.7,0-2-1.2c-0.3-1.1,0-2,0.9-2.6c1.1-0.8,2.3-0.9,3.5-0.5
	c1.2,0.4,2.3,1.1,3.3,1.8c0.3,0.2,0.6,1.1,0.4,1.3c-0.4,0.6-1,1.2-1.6,1.3C67,22.3,65.8,22.1,64.6,22C64.6,22,64.6,22,64.6,22z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M298.5,28.9c3.1,0.5,6.2,1.1,9.4,1.5c3.4,0.4,6.6,1.4,9.5,2.9c2.2,1.1,4.2,2.6,6,4.1c1.3,1,0.9,2.3-0.5,3.1
	c-3.3,1.6-6.8,3-10.5,3.4c-6.3,0.8-12.6,1-18.9,0.8c-2.2-0.1-4.4-0.8-6.6-1.3c-3.4-0.8-6.7-1.7-10-2.6c-2.1-0.6-4.2-1.2-6.2-2
	c-1.2-0.5-2.3-1.5-3.4-2.3c0.1,0,0.2-0.1,0.4-0.1c-0.1-0.8-0.1-1.5-0.2-2.6c1.7-1.3,4-2.4,6.6-3c2.7-0.6,5.3-1.4,8-1.7
	c4.5-0.6,9.1-1.1,13.7-0.5c0.8,0.1,1.5,0.1,2.3,0.2c-1.1,0-2.1,0-3.2,0.1c-1.8,0.1-3.4,1.2-3.7,2.5c-0.5,2.4,0.1,3.9,2,5
	c-0.5,0.6-1.1,1.1-1.5,1.8c-0.9,1.4-1.2,3-0.5,4.6c0.6,1.4,2,1.8,3.4,1.7c1.7-0.2,3.4-0.6,5.1-1.1c1.4-0.4,2-1.4,2.1-2.8
	c0.1-1.9-1.1-3.3-2.1-4.7c0.3-0.3,0.6-0.6,0.8-0.9c1-1.3,1-3.8,0-4.5C299.7,29.8,299.1,29.3,298.5,28.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M265.9,33.5c-0.5-1.4-0.3-2.1,0.8-3c1.6-1.2,3.4-1.9,5.3-2.2c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.1-0.1,0.3-0.2
	c-0.4-0.1-0.8-0.2-1.6-0.5c1.1-0.5,1.9-1,2.8-1.3c2.9-0.9,5.7-1.7,8.6-2.5c3.3-1,6.7-1.4,10.1-1.4c2.5,0,4.9-0.1,7.4-0.1
	c3.2,0.1,6.2,1.1,9.1,2.2c3.3,1.3,6.5,2.8,9.2,5.1c0.5,0.5,0.8,1.2,1.3,1.8c0.4,0.4,0.7,0.9,1.2,1.2c1.1,0.6,2.2,1.2,3.3,1.8
	c1.2,0.6,1.2,1.6,0.8,2.8c-2.9-2.8-6.3-4.9-10.1-6c-2.4-0.7-4.9-1.2-7.4-1.7c-2.6-0.5-5.3-1.1-8-1.5c-1.4-0.2-2.9-0.2-4.3-0.2
	c-1.6-0.1-3.2-0.3-4.8-0.2c-3,0.2-6,0.4-9,0.8c-3,0.4-6,1-8.9,1.7C270.2,30.5,267.9,31.6,265.9,33.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M297,20.8c-1,0.1-1.9,0.1-2.9,0.2c-1.4,0.1-2.9-0.9-3-2.2c-0.1-0.5,0.3-1.3,0.7-1.6c1.7-1.3,6.9-1.7,8.7,1.2
	c0.3,0.5,0.4,1.2,0.2,1.7c-0.2,0.4-0.9,0.7-1.4,0.8C298.7,20.9,297.8,20.8,297,20.8C297.1,20.8,297.1,20.8,297,20.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M368.9,25.9c5.2-0.4,10.1,0.8,15.1,2.2c3,0.8,6,1.9,8.9,3.1c1.8,0.7,3.4,1.9,5,3c1.6,1.1,1.5,2.7,0.2,4.1
	c-3.8,3.9-8.7,5.3-13.7,6.6c-2.8,0.8-5.6,0.8-8.5,1c-3.3,0.3-6.5,0-9.7-0.4c-4.2-0.6-8.5-1.4-12.6-2.6c-3.5-1-6.9-2.6-10.3-4.1
	c-1.1-0.5-2-1.5-2.8-2.3c-1-1-0.9-2.6,0.1-3.6c0.5-0.5,1-1,1.7-1.3c3.7-2,7.4-3.8,11.6-4.2c4.5-0.5,8.9-1,13.4-1.4
	C367.7,25.8,368.3,25.9,368.9,25.9z M370.8,31.5c0.6-0.3,0.9-0.5,1.3-0.5c1.2-0.2,2.4-0.3,3.5-0.6c0.4-0.1,0.9-0.7,1-1.1
	c0.3-1.4-0.5-2.1-2.1-2c-1.4,0.1-2.8,0.1-4.2,0.2c-0.6,0.1-1.5,0.4-1.7,0.9c-0.8,2.1-1.5,4.2-2.2,6.3c-0.3,1.1,0.4,2,1.4,1.9
	c0.8-0.1,1.5-0.5,2.2-0.9c0.6-0.3,1.3-0.8,2-0.3c0.9,0.7,0.9,3,0.1,3.8c-1,0.9-2.8,1.1-4,0.4c-0.7-0.4-1.6-0.7-2.1,0
	c-0.4,0.6-0.8,1.6-0.5,2.1c0.3,0.7,1.1,1.6,1.9,1.7c1.3,0.3,2.7,0.2,4,0c1.1-0.2,2.2-0.7,3.1-1.3c1.9-1.4,2.3-3.5,1.7-5.7
	c-1.1-3.7-1-3.9-4.8-4.6C371.2,31.8,371.2,31.7,370.8,31.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M340.2,31.4c0-1.3,1-1.8,1.8-2.3c0.9-0.6,1.9-0.9,2.9-1.4c1.1-0.4,1.1-0.6,0.2-1.7c2.5-1.3,4.8-3.1,7.7-3.8
	c1.6-0.4,3.2-1,4.8-1.3c3-0.5,6.1-0.8,9.1-1.1c5.6-0.5,10.9,0.7,16.2,2.2c3.2,0.9,6.4,2.3,8.8,5c-0.1,0.2-0.3,0.5-0.5,0.9
	c0.3,0.2,0.5,0.4,0.7,0.5c3,0.6,5.3,2.3,7.5,4.3c1,0.9,1.3,2.1,0.8,3c-2.5-4.1-6.9-5-10.9-6.6c-2-0.8-4-1.7-6.1-2.3
	c-1.9-0.6-3.9-1-5.9-1.3c-2.5-0.3-5-0.6-7.5-0.6c-3.2,0-6.3,0.4-9.5,0.6c-0.9,0.1-1.8,0.1-2.7,0.2c-4.6,0.4-9,1.3-13.1,3.5
	C343.1,29.9,341.7,30.6,340.2,31.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M371.5,18.7c-1.8,0.1-3.5,0.2-5.3,0.3c-0.3,0-0.6-0.1-0.8-0.3c-0.4-0.4-0.7-1-1.2-1.4c-0.6-0.5-0.6-1,0-1.4
	c1.9-1.4,4-1.9,6.3-1.1c0.8,0.3,1.5,0.7,2.2,1.2c0.8,0.5,0.8,1.4,0.4,2.1c-0.3,0.5-0.9,0.8-1.4,1.3C371.7,19,371.6,18.9,371.5,18.7z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M144.1,47.4c-3.7-0.4-7.3-1-11-1.1c-4.7-0.2-9.2-1.1-13.7-2.6c-2.9-0.9-5.8-1.7-8.7-2.6
	c-4.3-1.4-4.4-3.6-1.6-6.5c1.1-1.2,2.7-2.1,4.3-2.8c3-1.3,6.1-2.7,9.2-3.5c3.1-0.7,6.3-1,9.5-1c3.7,0,7.4,0.1,11.1,0.7
	c3.3,0.5,6.6,1.4,9.8,2.4c2.5,0.8,4.9,1.9,7.3,3c0.9,0.5,1.6,1.4,2.4,2.2c0.8,0.8,1.5,1.7,2.2,2.5c0.9,1.2,0.9,2.1-0.1,3.2
	c-0.9,1-2,1.9-3.1,2.5c-3.8,1.9-8,2.7-12.2,3c-1.7,0.1-3.5,0.2-5.2,0.4C144.2,47.3,144.1,47.3,144.1,47.4z M135.2,38.7
	c0,4,3.4,7.1,7.6,6.3c5.4-1,7.9-5.8,7.3-10.4c-0.2-1.9-1.2-3.6-3.5-4c-1.3-0.2-2.6-0.6-3.9-0.9c-0.5-0.1-1-0.1-1.4,0.1
	c-2,0.5-3.4,1.8-4.7,3.3C135.3,34.5,135.1,36.3,135.2,38.7z M128.4,35.3c0.1,0.1,0.2,0.1,0.3,0.2c-0.2,2.1-0.5,4.2-0.7,6.4
	c-0.1,1,0.7,2.1,1.4,2.2c1.1,0.1,2.1-0.7,2.1-1.9c0.1-1.2,0.1-2.4,0.3-3.6c0.3-2.4,0.6-4.9,0.8-7.3c0.1-0.6-0.2-1.3-0.6-1.8
	c-0.7-0.8-2.1-0.4-3.2,0.6c-1.6,1.5-3.1,3.1-4.6,4.6c-0.9,0.9-0.9,1.8-0.1,2.6c0.7,0.7,1.7,0.6,2.5-0.2
	C127.3,36.6,127.8,35.9,128.4,35.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M107,34.6c-0.2-1,1.1-3.1,2.4-3.9c1.2-0.7,2.3-1.5,3.4-2.1c0.8-0.5,1.7-0.7,2.6-1.1c0.5-0.2,0.9-0.5,1.2-0.9
	c-0.5,0.1-1,0.2-1.5,0.3c-0.5,0.1-1.1,0.3-1.6,0.4c-0.1-0.1-0.1-0.2-0.2-0.3c0.7-0.5,1.4-1.1,2.2-1.4c4.4-1.9,9-2.9,13.7-3.6
	c2.7-0.4,5.6-0.2,8.3,0c4.8,0.4,9.6,1.2,14.1,2.9c2,0.8,3.9,1.9,5.5,3.4c1.3,1.2,2.6,2,4.2,2.5c2.2,0.7,3.7,2.4,5,4.2
	c0.3,0.4,0.7,1.1-0.4,1.2c0.2,0.5,0.4,1.1,0.5,1.5c-0.9-0.8-2-1.4-2.6-2.4c-1.4-2.2-3.6-3.2-5.8-4c-3.9-1.6-8-2.9-12.2-3.8
	c-3.6-0.8-7.2-0.9-10.8-1c-3.5-0.1-7-0.1-10.5,0.5c-3,0.4-5.8,1.6-8.7,2.6c-1.5,0.5-2.9,1.1-4.2,1.9C110.2,32.4,108.6,33.5,107,34.6
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M133.3,20.5c-0.4-0.5-0.7-0.9-1.1-1.3c-1.1-1.2-0.8-2.1,0.6-2.8c2.3-1,4.4-0.6,6.4,0.9c0.4,0.3,0.8,0.4,1.1,0.7
	c0.4,0.4,0.9,1.1,0.8,1.5c-0.2,0.8-0.7,1.5-1.7,1.5C137.4,20.8,135.3,20.7,133.3,20.5z" />
        <path
          d="M397.5,85.7c0,1.6,0,3.2,0,4.8c-0.1,0-0.1,0-0.2,0c0-1.6,0-3.2,0-4.8C397.4,85.7,397.4,85.7,397.5,85.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M295.9,34.2c-0.6-0.8-1.9-0.7-1.6-1.9c0.1-0.4,0.8-0.8,1.3-0.9c0.5-0.1,1.2-0.5,1.6,0.4
	C297.7,33.2,296.3,33.2,295.9,34.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M296.8,38.1c0.5,0.7,0.8,1.3,1.3,2c-1.3,0.3-2.5,0.5-3.7,0.7c-0.1-0.2-0.2-0.3-0.3-0.5
	C295,39.6,295.8,38.9,296.8,38.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M432.7,92.5c-1.9-0.8-2.3-2.1-2-3.9c0.3-2.4,0.3-4.8,1.3-7c0.4-0.9,1.9-1.8,2.9-1.5c1.3,0.3,2,1.1,1.9,2.4
	c-0.1,2.2-0.2,4.4-1.1,6.5C435.1,90.5,434.2,91.7,432.7,92.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M203.6,82.1c-0.8-1.1-1.7-1.9-2.3-2.9c-0.9-1.6-0.8-2.3,0.5-3.7c0.2-0.2,0.7-0.3,0.9-0.2
	c0.9,0.4,1.9,0.9,2.7,1.4c1,0.6,1.1,1.4,0.5,2.3C205.3,80,204.5,80.9,203.6,82.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M204.6,87.4c1.4,1.2,1.9,2.6,2.1,4c0.1,0.5-0.6,1.4-1.1,1.5c-0.9,0.2-1.9,0.2-2.7-0.1c-0.4-0.1-0.7-1.4-0.5-1.9
	C203,89.8,203.8,88.7,204.6,87.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }}
              d="M450.8,33.7c-0.7,1.6-1.9,1.9-3.7,1.9c0.2-0.6,0.3-1.2,0.6-1.4C448.6,33.6,449.5,32.8,450.8,33.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M68.2,40.6c-0.5,1.5-2.2,2.2-4.3,1.3C64.5,40.4,66.2,40.2,68.2,40.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M146.4,36.2c0,2.2-1.9,4.6-4.1,5.1c-1.6,0.4-3.4-0.8-3.6-2.4c-0.2-1.5-0.6-3.1,1-4.3c0.7-0.5,1.4-1.5,2-1.5
	c1.2,0,2.5,0.4,3.6,0.9c0.6,0.3,0.9,1.3,1.3,2C146.6,36.1,146.5,36.2,146.4,36.2z" />
      </SvgIcon>
    )
  }
};