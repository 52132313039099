import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_19_1 extends SVG {

  render() {
    let { color, borderColor = 'black', opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 218.5 190.4"}
        style={{ width: "100%", height: "100%" }}
      >
        <path id="XMLID_83_" stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M194.6,26.1c-0.2-1.6-0.2-3.3-1.2-4.7c-0.1,0-0.2,0.1-0.3,0.1c0.1,0.4,0.2,0.8,0.3,1.2
	c0.5,1.6,0.9,3.3-0.1,4.9c-0.8,1.3-2.2,2-3.6,2.6c-0.9,0.4-1.8,0.7-2.8,0.9c-1.1,0.3-2.1-0.4-2.2-1.5c-0.1-1-0.5-1.6-1.5-1.8
	c0.1,0.4,0.1,0.8,0.2,1c1,1.7-0.2,3.1-1.9,3.4c-1.3,0.2-2.6,0.2-3.8,0.2c-0.1,0-0.3,0-0.4,0c-0.5-0.4-1-0.8-1.4-1.2
	c0.4-0.4,0.8-0.9,1.2-1.3c0.7-0.6,1.5-1.1,2.2-1.6c0.1-0.1,0.1-0.2,0.2-0.2c2-0.2,1.9-2.2,2.9-3.2c0.6-0.6,1.2-1.1,2-1.4
	c0.7-0.3,1.4-0.1,1.6,0.8c0,0.1,0.1,0.3,0.1,0.3c0.2,0.2,0.5,0.4,0.7,0.5c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.2-0.6
	c-0.5-1.3,0-2.7,1-3.6c1.3-1.2,2.5-2.4,3.4-4c0.4-0.7,1.2-1.2,1.9-1.8c0.2-0.1,0.5-0.1,0.8-0.1c1.1,0.4,2.5,3.2,2.2,4.3
	c-0.1,0.2-0.2,0.3-0.4,0.7c-0.2-0.7-0.3-1.3-0.5-1.8c-0.1,0-0.3,0-0.4,0c0,0.7-0.1,1.5,0,2.2c0.1,0.3,0.7,0.7,1,0.7
	c0.4-0.1,0.9-0.6,1-1c0.4-1.4,0.2-2.8-0.7-4.1c-0.5-0.7-0.9-1.4-1.5-2.4c0.9-0.3,1.7-0.7,2.6-0.9c0.5-0.2,0.9-0.4,1.1-1
	c0.2-0.5,0.8-0.9,1.3-1.5c0.6,1.2,1.1,2.1,1.6,3.1c0.1,0,0.2-0.1,0.4-0.1c-0.1-0.8-0.3-1.6-0.4-2.4c1.2,0.2,2.2,0.6,2.7,1.7
	c0.7,1.6,1.7,3.1,1.7,5c0,0.3,0,0.6-0.1,0.8c-0.6,1-1.3,2.1-2.1,3c-0.3,0.3-1.1,0.3-1.6,0.4c-0.2-1.4-0.5-2.7-0.7-3.9
	c-0.1,0-0.2,0-0.4,0c-0.1,0.2-0.2,0.4-0.2,0.6c0,1.2-0.1,2.5,0.2,3.7c0.3,1.2-0.1,2.1-0.8,2.8c-1,0.9-2.2,1.7-3.4,2.3
	C196.1,26.4,195.3,26.1,194.6,26.1z" />
        <path id="XMLID_82_" stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M206.8,9.7c-0.4,0.5-0.8,1-1.2,1.6C205.1,10.6,205.2,10.5,206.8,9.7z" />
        <path id="XMLID_81_" stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M149.1,26.8c0.4-0.8,0.3-1.6-0.2-2.3c-0.3-0.4-0.2-0.6,0.2-1c1-1,1.9-2.1,2.8-3.2
	c0.7-0.9,1.4-1.9,2.2-3c0.9,0.7,1.6,1.3,2.2,1.7c-0.3,1-0.7,1.8-0.8,2.6c-0.1,0.8,0,1.6,0.9,1.9c0.3,0.1,1-0.1,1.2-0.4
	c0.5-0.5,0.9-1.2,0.4-2c-0.2-0.3-0.3-0.6-0.3-0.9c0-1.5-1-2.4-1.9-3.3c-0.1-0.1-0.2-0.2-0.4-0.3c0.9-1.1,1.7-2.3,3.2-2.7
	c0.4-0.1,0.6-0.7,0.9-1.1c0.4,0.4,0.8,0.7,1.2,1.1c0.2,0.3,0.3,0.7,0.4,1.1c0.1,0.5-0.1,1.1,0.1,1.5c0.2,0.4,0.6,1,0.9,1.1
	c0.3,0.1,0.9-0.4,1.2-0.8c0.5-0.8,0.2-1.6-0.6-2.1c-0.1-0.1-0.3-0.2-0.4-0.4c-0.5-0.7-0.9-1.4-1.4-2c-0.6-0.7-0.7-0.9,0.1-1.5
	c1.3-1,2.6-1.5,4.2-0.6c0.2,0.1,0.5,0.3,0.6,0.5c0.5,0.8,0.5,0.8,1.4,0.5c0.5,2.3,2.5,4.6,0.2,7c-0.3-0.1-0.7-0.1-1.1-0.2
	c-0.1-0.5-0.1-1.1-0.2-1.6c-0.1-0.3-0.5-0.5-0.7-0.8c-0.1,0.3-0.2,0.7-0.2,1c0.2,1.9,0.3,3.7-1.4,5.2c-0.5,0.4-0.9,0.9-1.5,1.5
	c-0.2-1.4-0.3-2.7-0.5-4c-0.2,0-0.3,0-0.5,0c0,1.6,0,3.2,0,4.9c-0.1,0-0.3-0.1-0.4-0.1c-0.3,0.4-0.6,0.7-0.7,1.1
	c-0.5,1.2-1.7,1.9-2.7,2.5c-1,0.6-2.2,1-3.4,1.4c-0.2,0.1-0.5,0.1-0.8,0.1c0-1.5,0-2.9,0-4.3c0-0.2-0.1-0.5-0.1-0.7
	c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1,0.1-0.3,0.3-0.3,0.4c0,0.4,0,0.8,0.1,1.2c0,0.5,0.2,1,0.1,1.5c-0.1,0.8-0.3,1.4-1.1,2
	c-2.2,1.6-4.6,1.8-7.2,1.2c-0.6-0.1-1.2-0.1-1.8,0c-0.6,0.1-1.3,0-1.4-0.5c-0.1-0.5,0.1-1.2,0.4-1.7c0.2-0.3,0.6-0.6,1-0.7
	c1.3-0.6,2.6-1.1,3.9-1.7c0.1,0,0.1,0,0.2-0.1c0.3-0.2,0.6-0.5,0.9-0.7c0.4,0.5,0.8,1,1.2,1.5c0.1,0.2,0.2,0.5,0.2,0.8
	C148.9,26.8,149,26.8,149.1,26.8z" />
        <path id="XMLID_80_" stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M166.7,10c0.6-1.1,1.1-2.1,1.7-3c0.1-0.2,0.5-0.2,0.7-0.4c0.1,0.3,0.3,0.7,0.2,0.9
	c-0.4,0.8-0.9,1.5-1.4,2.2C167.7,9.8,167.2,9.8,166.7,10z" />
        <path id="XMLID_79_" stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M170.6,13.4c0.6-0.6,1.2-1.2,1.8-1.7c0.2-0.2,0.5-0.3,0.7-0.3c0.2,0,0.5,0.2,0.6,0.4
	c0.1,0.1-0.1,0.5-0.2,0.6c-0.9,0.5-1.8,0.9-2.7,1.3C170.7,13.5,170.7,13.5,170.6,13.4z" />
        <path id="XMLID_78_" stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M156.2,22.3c0.2-0.6,0.3-1.1,0.5-1.7C157.5,21.6,157.4,22,156.2,22.3z" />
        <path id="XMLID_75_" stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M128.6,52.2c-0.2-0.3-0.3-0.6-0.5-1c-0.7-1.6-3.2-1.9-4.3-0.7c-0.9,0.9-1.1,2-0.9,3.1
	c0.1,0.6-0.1,0.8-0.7,0.8c-1.5,0.1-3,0.3-4.5,0.2c-1.2,0-2.3-0.4-3.5-0.6c-1.1-0.2-2.1-0.4-3.2-0.5c-0.9-0.1-1.4-0.5-1.5-1.2
	c-0.1-1,0.4-1.4,1.4-1.2c0.5,0.1,0.9,0.3,1.4,0.3c2-0.1,2.8-1.4,2.4-3.5c-0.2-1.1-0.8-1.9-1.8-2.4c1.4-2,3.1-3.5,5.3-4.4
	c0.9-0.4,1.8-0.8,2.8-0.3c0.1,0,0.2,0.1,0.3,0.2c0,0,0,0.1,0.1,0.2c-0.2,0.1-0.5,0.2-0.7,0.3c-1.4,0.5-2,1.1-2,2.5
	c0,1.4,0.4,2.8,1.4,3.9c0.4,0.5,2.2,0.8,2.9,0.5c0.3-0.1,0.6-0.3,0.9-0.4c0.8-0.4,1.2-1.2,1-2.1c-0.3-1.2-0.6-2.4-0.9-3.6
	c0.9,0,3.3,2.3,4.1,4.1c0.8,1.9,1.6,3.8,1,5.9C128.8,52.2,128.7,52.2,128.6,52.2z M117.9,52.3c0.5-0.3,1.1-0.4,1.4-0.8
	c0.6-0.6-0.5-2.9-1.4-3c-0.2,0-0.4,0.1-0.5,0.2c-0.3,0.3-0.6,0.6-0.8,0.9C116,50.7,116.7,52.2,117.9,52.3z" />
        <path id="XMLID_74_" stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M118,56.8c0.9,0,1.8,0,2.7,0c1,3,1.3,6.2,0.9,9.4c-0.2,1.5-2.6,3.5-4.3,3
	c-0.4-0.1-0.9,0-1.2-0.2c-0.4-0.3-1-0.7-1-1.1c-0.1-1.5-0.1-2.9,0.7-4.3c0-0.1,0.1-0.2,0.1-0.2c2.1-1.6,2.1-4,2.2-6.3
	C117.9,57,117.9,56.9,118,56.8z" />
        <path id="XMLID_73_" stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M123.8,45.5c0,1-0.7,1.5-2.1,1.6c-1.2,0.1-2-1-2.1-2.6c-0.1-0.9,0.4-1.4,1.2-1.4
	c0.6,0,1.6-0.3,1.8,0C123.3,43.7,123.5,44.6,123.8,45.5z" />
        <path id="XMLID_72_" stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M110.1,49.9c0.4-0.9,0.7-1.5,1-2.2c0.3-0.6,0.7-0.7,1.3-0.5c0.7,0.3,1.1,1.1,0.9,2
	c-0.2,0.8-0.7,0.9-1.4,0.9C111.4,50.1,110.8,50,110.1,49.9z" />
        <path id="XMLID_71_" stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M127.3,53.2c-0.9,0.3-1.7,0.6-2.6,0.8c-0.5,0.1-0.8-0.8-0.4-1.3c0.3-0.5,0.9-1.1,1.3-1.1
	C126.3,51.6,127,52.1,127.3,53.2z" />
        <path id="XMLID_67_" stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M190.8,43c3.9-0.5,6.9,0.8,9,4.2c-1,0.7-1.5,1.7-1.5,3c0,1.5,0.5,2.8,2.5,2.7
	c0.2,0,0.4,0.1,0.5,0.2c-0.1,0.2-0.1,0.4-0.2,0.5c-1.1,1.1-2.4,1.5-3.8,1.7c-1.8,0.2-3.6,0.4-5.4,0.5c-1,0.1-2-0.1-2.9-0.2
	c-0.2,0-0.3-0.5-0.4-0.8c-0.2-0.9-0.3-1.8-0.6-2.6c-0.3-0.8-1-1.1-1.9-0.8c-1.4,0.5-2.7,0.9-3.2,2.5c-0.1,0.2-0.3,0.4-0.4,0.6
	c-1.6-0.5-2.1-1.5-1.4-2.9c0.7-1.6,1.5-3.1,2.3-4.6c0.3-0.6,0.6-0.5,1.1-0.2c1.1,0.7,3.4,0.6,4.4-0.2
	C190.2,45.5,190.2,45.5,190.8,43z M192.5,54.5c0.4,0,0.7,0,1-0.1c1.3-0.5,2.3-1.5,1.5-2.5c-0.5-0.7-1.3-1.2-2.1-1.4
	c-0.4-0.1-1.1,0.5-1.6,0.8c-0.9,0.5-0.6,1.4-0.3,2.1C191.3,53.9,191.7,54.6,192.5,54.5z M194.7,48.2c1.6,0,2.1-0.3,2.1-1.3
	c0-0.5-1.1-1.6-1.6-1.6c-0.7,0-1.6,0.8-1.6,1.6C193.6,47.7,194,48.2,194.7,48.2z" />
        <path id="XMLID_66_" stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M189.8,57.7c1.4-0.1,2.7-0.3,4-0.4c0.9,2.3,1,4.7,0.8,7.1c-0.1,1.4-0.2,2.7-0.5,4.1
	c-0.2,1.3-0.6,2.6-1.8,3.3c-1.9,1.1-4.2-0.3-4.6-2.4c-0.3-1.5,0.3-2.7,1.1-3.8c1.4-2,1.3-4.2,1.1-6.4
	C189.8,58.7,189.8,58.2,189.8,57.7z" />
        <path id="XMLID_65_" stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M184,54.9c0.5-1.1,1.3-1.7,2.3-1.7c0.4,0,0.9,0.5,1,0.8c0.2,0.4,0,1,0,1.5c0,0-0.3,0.1-0.4,0.1
	C186,55.4,185,55.1,184,54.9z" />
        <path id="XMLID_64_" stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M189.2,43c0.2,1.2,0,1.6-1.1,2c-0.6,0.2-1.2,0.4-1.9,0.6c-0.3,0-0.6-0.2-0.8-0.3
	c0.1-0.2,0.2-0.5,0.4-0.6c0.5-0.4,1.1-0.7,1.6-1C188,43.4,188.6,43.2,189.2,43z" />
        <path id="XMLID_63_" stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M199.5,50.1c0.1-0.3,0.1-0.6,0.3-0.9c0.3-0.5,0.8-0.5,1.1,0.1c0.2,0.4,0.4,0.8,0.4,1.2
	c0.1,0.4,0.4,0.9-0.3,1.1c-0.6,0.2-1.1-0.1-1.4-0.7C199.5,50.6,199.5,50.4,199.5,50.1z" />
        <path id="XMLID_60_" stroke={borderColor} fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1}
              fill={this.state.color17 ? this.state.color17 : 'transparent'} onClick={() => {
          this.setState({ color17: color, opacity17: opacity });
        }} d="M163.7,51.4c0,0.3,0.3,0.9,0.2,1.3c-0.2,0.5-0.7,0.9-1.2,1.1c-1.2,0.4-2.5,0.6-3.7,1
	c-0.6,0.2-0.8,0-0.9-0.5c-0.3-1.7-2.8-3.2-4.3-2.6c-1.5,0.6-2.6,1.7-2.7,3.3c-1.9,0.1-3.8-0.2-5.7-0.8c-1.9-0.6-2.1-1.2-1.6-3.2
	c0.1-0.2,0.1-0.4,0.2-0.7c1.2,1.5,2.5,0.9,3.7,0.3c1.1-0.6,1.3-1.7,1.3-2.8c0-0.9-0.1-1.8-0.2-2.7c0-0.2,0.2-0.4,0.3-0.5
	c1.4-1,2.8-1.9,4.6-2.1c0.9-0.1,1.3,0.2,1.3,1.1c0,0.8,0.3,1.4,1,1.7c0.9,0.5,2,0.2,2.7-0.6c0.7,0.5,1.4,1,2.2,1.5
	c-0.3,0.5-0.6,0.9-0.9,1.4c-0.6,0.9-0.7,1.9-0.4,2.9c0.1,0.4,0.6,0.8,1,0.9C161.6,51.4,162.6,51.3,163.7,51.4z M153.2,50.3
	c0.3-0.2,0.9-0.5,1.3-1c0.2-0.3,0.3-1.1,0.1-1.3c-0.7-0.5-1.4-0.9-2.2-1c-0.6-0.1-1.2,1-1.1,1.8C151.5,49.7,152.3,50.4,153.2,50.3z"
        />
        <path id="XMLID_59_" stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M156.5,62.5c-0.5,1.9-1,3.8-1.5,5.8c-0.3,1.1-1.9,1.3-2.9,1.1c-0.4-0.1-0.8-0.2-1.2-0.2
	c-1.2-0.1-2-1.1-1.8-2.3c0.1-0.4,0.2-0.9,0.5-1.3c1.5-2.5,1.5-5.3,1.3-8.1c-0.1-1-0.1-1.1,1-1c1,0.1,2,0.1,3,0.2
	c0.2,0,0.6,0.2,0.6,0.4C155.8,59,156.2,60.7,156.5,62.5z" />
        <path id="XMLID_58_" stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M152.5,55.5c-0.2-1.2,0.4-1.9,1.6-2.2c1.5-0.4,2.3,0.1,2.9,1.8C155.5,55.2,154,55.3,152.5,55.5z
	" />
        <path id="XMLID_57_" stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }} d="M147.6,45.8c0.7,2.2,0,3.7-1.7,3.7c-0.3,0-0.7-0.2-0.9-0.5c-0.1-0.1,0.1-0.6,0.2-0.8
	C146,47.4,146.7,46.7,147.6,45.8z" />
        <path id="XMLID_56_" stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }} d="M162.9,49.3c-0.6,0.7-1.3,0.6-1.9,0.3c-0.2-0.1-0.4-0.5-0.3-0.7c0.1-0.3,0.4-0.7,0.7-0.8
	C162.4,47.8,162.5,48.7,162.9,49.3z" />
        <path id="XMLID_55_" stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }} d="M188.9,152c-0.7,0-1.4-0.1-1.4,0.8c0,0.6,0.3,1.2,0.9,1.3c0.4,0.1,0.9-0.1,1.3-0.1
	c1.5,0,3.1,0,4.6,0.2c1.2,0.2,2.2,1.1,2.9,2.1c1.1,1.5,2.4,3.1,2.6,5c0.4,3.3-0.2,6.5-2.4,9.1c-1.1,1.3-2.3,2.6-3.6,3.6
	c-1.2,0.9-2.8,1.5-4.2,2.1c-0.8,0.3-1.7,0.4-2.5,0.6c-1,0.2-3.4-1-3.8-2c-0.4-0.8-1-1.1-1.6-0.7c-1.2,0.7-2.1,0.1-3-0.3
	c-0.9-0.4-1.4-1.3-1.5-2.2c-0.3-2.2-0.6-4.5-0.8-6.7c-0.1-1.2,0.2-2.4,0.4-3.6c0.2-1.3,0.7-2.5,1-3.7c0.6-2.3,1.9-4.3,3.9-5.4
	c1.7-0.9,3.6-1.7,5.7-1.1c0.6,0.2,1.1,0.5,1.6,0.8C188.9,151.8,188.9,151.9,188.9,152z" />
        <path id="XMLID_54_" stroke={borderColor} fillOpacity={this.state.opacity23 ? this.state.opacity23 : 1}
              fill={this.state.color23 ? this.state.color23 : 'transparent'} onClick={() => {
          this.setState({ color23: color, opacity23: opacity });
        }} d="M192.6,151c0.3-1.3,0.9-2.4,1.7-3.3c0.5-0.5,1.2-0.5,1.9,0.2C195.1,148.9,193.9,149.9,192.6,151
	z" />
        <path id="XMLID_51_" stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }} d="M53.9,45.5c1.1,0.5,2.2,1.1,3.3,1.7c-1.2,1.2-2.3,2.6-1.4,4.2c0.8,1.4,2.3,1.3,3.7,0.7
	c0,1.2-1.2,2.9-2.3,3.1c-0.2,0-0.6-0.2-0.8-0.4c-1-1.1-3-1-3.9,0.1c-0.1,0.1-0.2,0.3-0.2,0.5c0.1,1.1-0.8,1.2-1.5,1.4
	c-3,0.5-5.8-0.2-8.5-1.1c-1.7-0.6-2.3-2.6-1.3-4.1c0.1-0.1,0.4-0.2,0.6-0.1c1.2,0.5,3.1,0,4-1.1c0.9-1.1,1-2,0-3.4
	c-0.1-0.1-0.2-0.2-0.3-0.5c0.8-0.5,1.7-1,2.5-1.4c0.4,2.5,1,3,2.9,3.1C51.8,48,53.7,46.5,53.9,45.5z M50.2,52.9
	c1.1,0,2.3-1.2,2.3-2.3c0-0.6-1.1-1.5-1.9-1.5c-0.9,0-1.9,1.2-1.9,2.1C48.6,52.2,49.3,53,50.2,52.9z" />
        <path id="XMLID_50_" stroke={borderColor} fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1}
              fill={this.state.color25 ? this.state.color25 : 'transparent'} onClick={() => {
          this.setState({ color25: color, opacity25: opacity });
        }} d="M48.6,71.5c-0.7-0.1-1.4,0-2.1-0.2c-1.9-0.4-2.1-1.9-1.4-3.3c0.5-1.1,1.1-2.2,1.7-3.3
	c0.8-1.6,1.2-3.3,1.2-5c0-0.6,0.2-0.8,0.7-0.9c0.5,0,0.9-0.2,1.4-0.2c1.1,0,1.1,1,1.3,1.6c0.4,1.6,0.7,3.2,0.8,4.8
	c0.1,1.2-0.1,2.5-0.3,3.7C51.7,69.9,49.6,71.5,48.6,71.5z" />
        <path id="XMLID_49_" stroke={borderColor} fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1}
              fill={this.state.color26 ? this.state.color26 : 'transparent'} onClick={() => {
          this.setState({ color26: color, opacity26: opacity });
        }} d="M43,50.5c-0.6-0.2-1-0.3-0.6-0.9c0.4-0.7,0.9-1.3,1.4-1.9c0.3-0.3,0.7-0.9,1.2-0.4
	c0.5,0.5,0.8,1.2,0.3,1.7C44.6,49.6,43.7,50,43,50.5z" />
        <path id="XMLID_48_" stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M57.8,51.1c-0.5-0.1-1.1-0.1-1.1-0.9c0-0.8,0.9-2.1,1.3-1.8c0.6,0.3,1.2,0.9,1.4,1.5
	C59.5,50.5,58.7,51.1,57.8,51.1z" />
        <path id="XMLID_47_" stroke={borderColor} fillOpacity={this.state.opacity28 ? this.state.opacity28 : 1}
              fill={this.state.color28 ? this.state.color28 : 'transparent'} onClick={() => {
          this.setState({ color28: color, opacity28: opacity });
        }} d="M52.5,45.4c-0.8,1-2,1.5-2.8,1.1c-0.6-0.2-0.9-0.6-0.7-1.2c0.1-0.7,0.6-0.9,1.2-0.7
	C51,44.8,51.7,45.1,52.5,45.4z" />
        <path id="XMLID_46_" stroke={borderColor} fillOpacity={this.state.opacity29 ? this.state.opacity29 : 1}
              fill={this.state.color29 ? this.state.color29 : 'transparent'} onClick={() => {
          this.setState({ color29: color, opacity29: opacity });
        }} d="M53.4,56.3c0.7-1.1,1.5-1.3,2.2-0.7C54.9,55.8,54.2,56,53.4,56.3z" />
        <path id="XMLID_43_" stroke={borderColor} fillOpacity={this.state.opacity30 ? this.state.opacity30 : 1}
              fill={this.state.color30 ? this.state.color30 : 'transparent'} onClick={() => {
          this.setState({ color30: color, opacity30: opacity });
        }} d="M188.4,90.4c0.3-0.5,0.6-0.5,1.1-0.3c0.4,0.2,1.1,0.3,1.3,0.1c0.5-0.6,0-1.1-0.5-1.4
	c-0.5-0.3-0.4-0.7,0-1c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.7,0.3,0.9,0.2c0.2-0.1,0.4-0.7,0.3-0.9c-1.2-1.8,0.5-3,1.1-4.3
	c0.2-0.5,0.7-0.9,1.1-1.4c1-1.2,2.5-1.1,3.9-1.1c0.5,0,1.2,0.1,1.4,0.4c0.6,1,1.3,2.1,1.6,3.3c0.5,1.8-0.3,3.6-0.8,5.3
	c-0.1,0.4-0.2,0.8-0.2,1.2c0,0.4,0.1,0.7-0.1,1.1c-0.9,2.6,0.3,4.7,1.5,6.8c0.9,1.4,0.8,2.9,0.4,4.3c-0.6,2.5-1.9,4.6-3.9,6.2
	c-1.6,1.3-3.5,2.2-5.7,1.9c-1.4-0.1-2.8,0-4.2-0.2c-2.4-0.5-4.8-1.4-6.7-3c-2.5-2.2-3.1-6.1-1.5-9c1.3-2.4,2.9-4.5,5-6.1
	c0.5-0.4,1.1-0.7,1.6-1c0.2-0.1,0.5,0.1,0.7,0.1c0.5,0.1,0.9,0.3,1.4,0.2C189.2,92,189.1,91.2,188.4,90.4z M187.8,108.2
	c0,0,0,0.1,0,0.1c-0.3,0-0.6-0.1-0.9,0c-0.3,0-0.7,0.1-0.7,0.3c0,0.4,0,0.8,0.2,1.1c0.2,0.2,0.6,0.4,0.9,0.4c0.7,0.1,1.4,0.1,2.1,0
	c0.3,0,0.8-0.3,0.8-0.4c0-0.3-0.2-0.9-0.4-1C189.1,108.5,188.4,108.4,187.8,108.2z" />
        <path id="XMLID_42_" stroke={borderColor} fillOpacity={this.state.opacity31 ? this.state.opacity31 : 1}
              fill={this.state.color31 ? this.state.color31 : 'transparent'} onClick={() => {
          this.setState({ color31: color, opacity31: opacity });
        }} d="M200.5,78.7c0.1-1-0.1-2.1,1-2.7c0.3-0.2,0.7-0.1,1.1-0.2c-0.1,0.3-0.2,0.6-0.4,0.8
	C201.6,77.3,201.1,78,200.5,78.7z" />
        <path id="XMLID_41_" stroke={borderColor} fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1}
              fill={this.state.color32 ? this.state.color32 : 'transparent'} onClick={() => {
          this.setState({ color32: color, opacity32: opacity });
        }} d="M130.7,159.5c-0.2,2.6-0.4,5.1-1.3,7.6c-0.8,2.2-2.1,4.1-3.9,5.6c-0.9,0.7-2.1,1.2-3.3,1.5
	c-0.8,0.2-1.7-0.2-2.6-0.2c-1.2,0-1.3-1-1.7-1.7c-0.8-1.3-2-1.9-3.4-2.2c-0.5-0.1-1.2,0.1-1.2,0.9c0,0.6,0,1.3,0.9,1.3
	c0.5,0,1,0.2,1.5,0.4c0.2,0.1,0.4,0.4,0.5,0.6c0,0-0.1,0.3-0.2,0.3c-0.2-0.1-0.5-0.1-0.7-0.3c-0.6-0.3-1.1-0.7-1.7-0.9
	c-1.4-0.4-2.5-1.2-3.2-2.5c-0.6-1.1-1.3-2.1-1.7-3.2c-0.3-0.9-0.6-2-0.5-3c0.2-3.3,0.8-6.6,2.1-9.7c1.3-3,3.3-4.8,6.6-4.8
	c0.8,0,1.6,0.5,2.4,0.9c0.8,0.4,1.4,1,2.1,1.5c0.2,0.1,0.4,0.2,0.6,0.2c0.7,0,1.2,0.3,1.5,0.9c0.3,0.5,0.8,0.7,1.5,0.4
	c0.8-0.4,2.7,0.3,3.3,1c0.8,1,1.7,1.9,2,3.2C130.3,158.1,130.5,158.8,130.7,159.5z" />
        <path id="XMLID_40_" stroke={borderColor} fillOpacity={this.state.opacity33 ? this.state.opacity33 : 1}
              fill={this.state.color33 ? this.state.color33 : 'transparent'} onClick={() => {
          this.setState({ color33: color, opacity33: opacity });
        }} d="M125.5,148.5c0.1-0.8-0.4-1.9,0.8-2.3c0.3-0.1,0.7-0.1,1.4-0.1
	C126.7,147,126.1,147.8,125.5,148.5z" />
        <path id="XMLID_39_" stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M190.4,119.8c-1,0.4-1.9,0.6-2.7,1.1c-0.8,0.4-1.4,1.1-2,1.7c-0.2,0.2-0.2,0.6-0.3,1
	c0.1,0,0.1,0.1,0.2,0.1c0.5-0.5,0.9-1.1,1.5-1.4c1.7-0.8,3.6-1.4,5.4-2c0.9,0.5,1.9,0.9,2.7,1.6c1.8,1.5,1.7,3.6,1.5,5.6
	c-0.1,0.9-0.5,1.9-0.9,2.7c-0.8,1.6-1.6,3.4-2.7,4.8c-2.3,2.9-4.6,5.7-8.4,7c-1.7,0.6-3.3,0.3-5,0.2c-0.9,0-1.1-0.9-1.2-1.7
	c-0.1-0.9-0.1-1.7-0.2-2.6c-0.2-1.6,0.5-3.1,1-4.5c0.5-1.5,1.4-2.9,2.1-4.4c0.2-0.4,0.2-0.8,0.2-1.2c-0.1-0.1-0.2-0.1-0.3-0.2
	c-0.4,0.6-1,1.1-1.3,1.8c-0.9,2.2-1.7,4.4-2.5,6.5c-0.1,0.3-0.3,0.5-0.5,0.8c-1.2-0.8-1-1.9-0.9-3c0.1-1.2,0.3-2.4,0.5-3.6
	c0.2-1.9,1.2-3.4,2.1-5c0.6-1,1.1-2.2,1.9-3c1.3-1.3,2.4-2.8,4.2-3.6c1.9-0.8,3.6-0.4,5.3,0.5C190.1,119.3,190.2,119.6,190.4,119.8z
	" />
        <path id="XMLID_36_" stroke={borderColor} fillOpacity={this.state.opacity35 ? this.state.opacity35 : 1}
              fill={this.state.color35 ? this.state.color35 : 'transparent'} onClick={() => {
          this.setState({ color35: color, opacity35: opacity });
        }} d="M127,123.7c1.3-0.4,1.7-1.8,1.5-3c-0.2-1.1-0.1-1.1,0.8-1.6c0.1,0.4,0.2,0.7,0.3,1.1
	c0.3,2.5-0.2,4.8-1.5,7c-0.9,1.6-1.6,3.3-2.5,4.8c-1.2,2.1-2.9,3.7-5.1,4.8c-1,0.5-2.1,0.7-3.1,0.8c-0.2,0-0.4-0.1-0.9-0.1
	c0.5-0.4,0.9-0.6,1.2-0.8c0.8-0.5,1.8-1,2.5-1.6c0.9-0.7,1.6-1.6,2.4-2.5c0.6-0.7,1.3-1.5,1.8-2.3c0.2-0.3,0.2-0.7,0.2-1.1
	c0-0.1-0.4-0.3-0.5-0.3c-0.3,0.1-0.5,0.4-0.7,0.6c-0.9,1.2-1.7,2.4-2.6,3.6c-0.3,0.3-0.8,0.4-1.3,0.6c-0.3,0.1-0.5,0.2-0.8,0.4
	c-1.5,1-3,2-4.8,2.3c-1.8,0.3-3.6-0.1-5-1.2c-0.5-0.4-0.9-1.3-0.9-2c0.1-1.8,0.3-3.6,0.7-5.3c0.5-2.4,2.1-4.2,3.6-6.1
	c1.2-1.5,2.5-3,4.2-3.9c1.6-0.9,3.3-1.9,5.2-2c0.6,0,1.2-0.2,1.7-0.2c2.7,0.4,3.2,1.6,3.5,3.9c0.1,0.6,0.3,1.2,0.4,1.8
	c0.1,0.4-0.1,0.8-0.1,1.1C127.2,122.8,127.1,123.2,127,123.7z M125.5,128.1c-0.3-0.3-0.5-0.5-0.6-0.8c-0.2,0.3-0.5,0.5-0.5,0.8
	c0,0.2,0.3,0.4,0.5,0.6C125.1,128.6,125.3,128.4,125.5,128.1z" />
        <path id="XMLID_35_" stroke={borderColor} fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1}
              fill={this.state.color36 ? this.state.color36 : 'transparent'} onClick={() => {
          this.setState({ color36: color, opacity36: opacity });
        }} d="M130.2,115c0.3-0.8,0.5-1.6,0.8-2.4c0.1,0,0.3,0.1,0.4,0.1c0.2,1-0.4,1.7-0.8,2.4
	C130.5,115.1,130.4,115.1,130.2,115z" />
        <path id="XMLID_34_" stroke={borderColor} fillOpacity={this.state.opacity37 ? this.state.opacity37 : 1}
              fill={this.state.color37 ? this.state.color37 : 'transparent'} onClick={() => {
          this.setState({ color37: color, opacity37: opacity });
        }} d="M161.4,78.1c0,0.6,0,1.3,0,1.9c0.1,1.2,0.7,2.1,1.8,2.7c0.2,0.1,0.7,0.1,0.9,0
	c0.2-0.1,0.2-0.5,0.2-0.8c0-0.3,0-0.7-0.1-1c0.1,0,0.3,0,0.4-0.1c0.1,0.7,0.5,1.5,0.3,2.1c-0.5,1.6-1.2,3.2-1.9,4.7
	c-0.6-0.1-0.9-0.2-1.3-0.3c0,0.6,0,1.2,0,1.9c-1.3,0.3-1.3,0.3-0.9,1.7c0.1,0.2-0.1,0.6-0.3,0.7c-0.8,0.5-0.9,0.7-0.4,1.6
	c0.1,0.2,0.3,0.5,0.4,0.8c-0.7,0.4-1.3,1.1-0.5,1.7c1.4,1,1,2.2,0.9,3.4c-0.2,1.4-0.4,2.9-0.7,4.2c-0.9,3.6-3.1,4.5-6.4,4.9
	c-1.8,0.2-3.5-0.3-5.2-0.4c-2.1-0.1-2.9-1.6-4.1-2.7c-0.8-0.7-1.6-1.5-2.4-2.2c-1.5-1.4-1.5-3.3-1.3-5c0.2-2.7,1.2-5.2,2.8-7.4
	c0.8-1.1,1.7-2.1,3.1-2.5c1.8-0.6,3.6-1.3,5.4-2c0.6-0.3,1.2-0.9,1.7-1.4c1.6-1.7,2.4-4,4.2-5.6C158.9,78.3,160.4,77.9,161.4,78.1z"
        />
        <path id="XMLID_33_" stroke={borderColor} fillOpacity={this.state.opacity38 ? this.state.opacity38 : 1}
              fill={this.state.color38 ? this.state.color38 : 'transparent'} onClick={() => {
          this.setState({ color38: color, opacity38: opacity });
        }}
              d="M167,74.6c-0.7,2.4-2.1,3.9-3.9,5.5c1.1-1.9,2-3.5,3-5.1C166.3,74.7,166.6,74.7,167,74.6z" />
        <path id="XMLID_30_" stroke={borderColor} fillOpacity={this.state.opacity39 ? this.state.opacity39 : 1}
              fill={this.state.color39 ? this.state.color39 : 'transparent'} onClick={() => {
          this.setState({ color39: color, opacity39: opacity });
        }} d="M80,46.4c2.6-2.2,5.3-3.6,8.9-3.2c-0.2,1-0.6,1.9,0.2,2.7c0.2,0.2,0.6,0.5,1,0.4
	c1-0.1,1.9-0.4,2.5-1.3c0.4,0.5,0.8,0.9,1.1,1.4c0.1,0.1-0.1,0.6-0.3,0.7c-0.8,0.7-1.1,1.6-0.9,2.5c0.3,1.1,1.8,1.9,3,1.4
	c0.5,1.7,0,2.8-1.8,3.4c-1.7,0.6-3.3,1.1-5.1,1.5c-1.4,0.3-2.8,0.3-4.2,0.2c-1.5,0-3-0.2-4.4-0.3c-0.8-0.1-1.7-0.2-2.5-0.5
	c-1.1-0.4-1.5-1.1-0.8-2.8c0.1-0.2,0.2-0.4,0.3-0.6c0.5-1.5,0.6-1.6,2.1-1.6c1.1,0,1.8-0.6,2.2-1.5c0.4-0.9-0.2-1.6-1-2
	C80.1,46.7,80.1,46.6,80,46.4z M85.4,52.5c0.7-0.3,1.4-0.5,2.1-0.9c1.2-0.7,2-2.1,0.9-3.3c-0.2-0.2-0.2-0.5-0.3-0.8
	c-0.2-0.9-0.8-1.4-1.7-1.2c-1.7,0.4-2.8,1.4-3.3,3.1C82.5,51.1,83.5,52.3,85.4,52.5z" />
        <path id="XMLID_29_" stroke={borderColor} fillOpacity={this.state.opacity40 ? this.state.opacity40 : 1}
              fill={this.state.color40 ? this.state.color40 : 'transparent'} onClick={() => {
          this.setState({ color40: color, opacity40: opacity });
        }} d="M84.7,57.9c0.9-0.1,1.7-0.2,2.6-0.2c1,0,1,0.9,1.1,1.6c0.3,1.5,0.5,3,0.6,4.4
	c0.1,0.9-0.1,1.9-0.1,2.8c0,0.2-0.1,0.4-0.1,0.5c-0.5,1.1-0.9,1.9-2.4,2c-1.4,0.1-2.7,0.1-4-0.1c-1-0.2-1.3-1.1-0.8-2
	c0.7-1.1,1.5-2.1,2.1-3.3c0.6-1.2,1.3-2.3,1.1-3.8C84.6,59.3,84.7,58.7,84.7,57.9z" />
        <path id="XMLID_28_" stroke={borderColor} fillOpacity={this.state.opacity41 ? this.state.opacity41 : 1}
              fill={this.state.color41 ? this.state.color41 : 'transparent'} onClick={() => {
          this.setState({ color41: color, opacity41: opacity });
        }} d="M95,49.6c-0.5-0.2-1.3,0.1-1.4-0.8c0-0.2,0.4-0.4,0.6-0.6c0.3,0.4,0.7,0.8,1,1.2
	C95.1,49.5,95.1,49.5,95,49.6z" />
        <path id="XMLID_27_" stroke={borderColor} fillOpacity={this.state.opacity42 ? this.state.opacity42 : 1}
              fill={this.state.color42 ? this.state.color42 : 'transparent'} onClick={() => {
          this.setState({ color42: color, opacity42: opacity });
        }} d="M78.5,48.5c0.2-0.4,0.4-0.8,0.8-1.5c0.3,0.6,0.7,1.1,0.6,1.2c-0.3,0.3-0.7,0.5-1.1,0.7
	C78.7,48.7,78.6,48.6,78.5,48.5z" />
        <path id="XMLID_26_" stroke={borderColor} fillOpacity={this.state.opacity43 ? this.state.opacity43 : 1}
              fill={this.state.color43 ? this.state.color43 : 'transparent'} onClick={() => {
          this.setState({ color43: color, opacity43: opacity });
        }} d="M89.7,44.2c0.4,0,0.9,0,1.3,0c0,0.2-0.1,0.5-0.1,0.5c-0.4,0-0.8,0-1.2,0
	C89.7,44.5,89.7,44.3,89.7,44.2z" />
        <path id="XMLID_25_" stroke={borderColor} fillOpacity={this.state.opacity44 ? this.state.opacity44 : 1}
              fill={this.state.color44 ? this.state.color44 : 'transparent'} onClick={() => {
          this.setState({ color44: color, opacity44: opacity });
        }} d="M80.8,126.2c-0.8-0.1-1.1,0.4-1.3,0.9c-0.5,1.4-1,2.8-1.5,4.4c-0.2-0.3-0.4-0.5-0.4-0.7
	c-0.4-1.4-0.1-2.8,0.4-4.1c0.6-1.6,1-3.2,1.7-4.7c1-2.3,2.7-3.9,4.8-5.3c1.8-1.1,3.7-1.3,5.7-1.1c0.4,0,0.8,0.2,1.1,0.3
	c0,0.1,0,0.2,0,0.3c-0.8,0.3-1.5,0.7-2.3,1c-2.5,1.1-4.5,3-6.1,5.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.4,0.1,0.9,0.1,1.3
	c0.3-0.2,0.7-0.4,0.9-0.7c1.3-1.9,2.9-3.4,5-4.4c0.8-0.4,1.7-0.7,2.5-1.1c1.6-0.8,3-0.2,4.5,0.4c0.2,0.1,0.3,0.5,0.4,0.7
	c1.4,2.2,1.1,4.4,0.2,6.6c-0.7,1.8-1.4,3.6-2.3,5.3c-1.5,2.7-3.6,5.1-6.5,6.3c-2,0.8-4.1,0.7-6-0.4c-1.9-1.1-2.6-3.7-1.9-5.7
	C80,129.4,80.3,127.8,80.8,126.2z" />
        <path id="XMLID_24_" stroke={borderColor} fillOpacity={this.state.opacity45 ? this.state.opacity45 : 1}
              fill={this.state.color45 ? this.state.color45 : 'transparent'} onClick={() => {
          this.setState({ color45: color, opacity45: opacity });
        }}
              d="M100.5,114.6c-1.4-0.9-2.6-0.4-3.9,0.6C97.1,113.5,99.2,113.3,100.5,114.6z" />
        <path id="XMLID_23_" stroke={borderColor} fillOpacity={this.state.opacity46 ? this.state.opacity46 : 1}
              fill={this.state.color46 ? this.state.color46 : 'transparent'} onClick={() => {
          this.setState({ color46: color, opacity46: opacity });
        }} d="M159.4,153.8c0.6,0.2,1.3,0.2,1.6,0.6c1,1.1,2.2,1.9,2.8,3.5c0.6,1.7,0.8,3.4,0.6,5.1
	c-0.2,2.5-1.1,4.8-2.7,6.8c-1.4,1.7-2.8,3.3-4.8,4.4c-1.5,0.8-3,1.1-4.6,1c-0.7,0-1.5-0.1-2.2-0.2c-1.3-0.2-2-0.9-2.2-2.1
	c-0.3-1.3-0.7-1.5-2-1.3c-0.1,0-0.3,0.1-0.4,0c-2-0.8-3.8-1.8-4.3-4.2c-0.7-3.1-0.7-6.3,0.2-9.3c0.6-1.9,1.3-3.8,2.4-5.4
	c1-1.5,2.5-2.7,4.4-3.1c1.3-0.3,2.6-0.6,3.9-0.7c1.3,0,2.3,0.8,3,1.8c0.5,0.6,0.7,1.4,1.2,2c0.7,0.9,1.4,1.8,2.1,2.8
	c0.3,0.4,0.6,0.9,1,1.3c0.1,0.2,0.4,0.4,0.5,0.3c0.2,0,0.4-0.3,0.4-0.5c0-0.4-0.1-0.8-0.3-1.2c-0.2-0.5-0.6-0.9-0.8-1.4
	C159.3,154,159.4,153.9,159.4,153.8z" />
        <path id="XMLID_22_" stroke={borderColor} fillOpacity={this.state.opacity47 ? this.state.opacity47 : 1}
              fill={this.state.color47 ? this.state.color47 : 'transparent'} onClick={() => {
          this.setState({ color47: color, opacity47: opacity });
        }} d="M157.2,152.4c0-0.4-0.1-0.8,0-1.1c0.2-0.8,0.4-1.7,0.6-2.5c0.3-1.1,1.3-1.5,2.2-2
	c0.3-0.1,0.8,0.2,1.3,0.5c-1.9,1.3-2.6,3.4-3.7,5.2C157.4,152.5,157.3,152.5,157.2,152.4z" />
        <path id="XMLID_19_" stroke={borderColor} fillOpacity={this.state.opacity48 ? this.state.opacity48 : 1}
              fill={this.state.color48 ? this.state.color48 : 'transparent'} onClick={() => {
          this.setState({ color48: color, opacity48: opacity });
        }} d="M82.8,87c0.5,0.2,0.8,0.4,1.2,0.4c0.3,0,0.6-0.1,0.9-0.2c-0.1-0.3-0.1-0.7-0.3-1
	c-0.2-0.3-0.6-0.5-1.1-1c0.4-0.1,0.7-0.1,0.9-0.2c0.3-0.1,0.6-0.1,1-0.2c-0.1-0.3,0-0.7-0.2-0.9c-0.6-0.6-0.5-1-0.1-1.6
	c0.7-1.1,1.2-2.5,2.1-3.4c1.1-1.1,2.6-1.7,4.3-1.2c0.9,0.2,1.2,0.9,1.5,1.5c0.6,1,1.2,2.2,0.8,3.3c-1.3,3.5-1.3,6.9-0.2,10.5
	c0.8,2.7,1,5.4,0.4,8.1c-0.6,2.9-2.4,5-5.1,6.2c-1.6,0.7-3.2,1.3-4.9,1c-0.5-0.1-1-0.2-1.5-0.4c-1.9-0.5-3.9-1-5.5-2.2
	c-1.7-1.3-3.3-2.7-3.4-5.1c-0.1-3.4,1-6.2,3.4-8.6c0.8-0.8,1.8-1.3,2.7-1.9c0.3-0.2,0.5-0.4,0.8-0.6c0.7-0.6,1.3-0.9,2.2-0.4
	c0.2,0.1,0.8-0.1,0.9-0.4c0.2-0.4,0.2-0.8-0.4-0.9C82.7,87.7,82.3,87.6,82.8,87z M84.4,106.8c-0.1-0.3-0.1-0.5-0.1-0.6
	c-0.4-2.1-4.2-3.3-6.2-2.3c-0.2,0.1-0.3,0.6-0.2,0.8c0.1,0.2,0.4,0.5,0.7,0.5c0.9,0.1,1.8-0.1,2.6,0.1c0.6,0.1,1.5,0.2,1.6,1.2
	c0.1,0.3,0.5,0.6,0.8,0.9C83.9,107.2,84.2,107,84.4,106.8z" />
        <path id="XMLID_18_" stroke={borderColor} fillOpacity={this.state.opacity49 ? this.state.opacity49 : 1}
              fill={this.state.color49 ? this.state.color49 : 'transparent'} onClick={() => {
          this.setState({ color49: color, opacity49: opacity });
        }} d="M94.2,76c0.4-0.4,0.9-0.9,1.3-1.3c0.1-0.1,0.4,0,0.5,0c-0.1,0.2,0,0.4-0.2,0.5
	c-0.5,0.3-1,0.6-1.5,0.9C94.3,76.2,94.3,76.1,94.2,76z" />
        <path id="XMLID_17_" stroke={borderColor} fillOpacity={this.state.opacity50 ? this.state.opacity50 : 1}
              fill={this.state.color50 ? this.state.color50 : 'transparent'} onClick={() => {
          this.setState({ color50: color, opacity50: opacity });
        }} d="M58.8,152.3c0.2,0,0.6-0.2,0.7-0.1c1.2,0.7,2.7,0.9,3.6,2.2c0.5,0.7,1,1.4,1.4,2.1
	c0.8,1.3,0.6,2.8,0.5,4.2c-0.1,4.6-2.5,8.1-5.9,11c-1.1,1-2.5,1.3-3.9,1.9c-1.1,0.5-2,0.4-3,0.1c-0.5-0.1-0.8-0.5-1.3-0.7
	c-0.5-0.2-1-0.4-1.6-0.5c-2.1-0.3-4.1-0.9-5-2.9c-0.6-1.3-0.8-2.8-1.1-4.2c-0.7-3.6-0.4-7.2,0.4-10.7c0.5-2,1.8-3.4,3.8-4.1
	c1.2-0.4,2.3-1,3.5-0.5c0.9,0.3,1.9,0.7,2.6,1.4c1.1,1.1,2.5,1.7,3.8,2.4c0.3,0.2,0.6,0.4,0.9,0.5c0.5,0.1,1,0.2,1.4,0.1
	c0.2,0,0.4-0.4,0.6-0.7c-0.3-0.2-0.5-0.4-0.8-0.6c-0.3-0.2-0.6-0.3-0.9-0.5C58.7,152.5,58.7,152.4,58.8,152.3z" />
        <path id="XMLID_16_" stroke={borderColor} fillOpacity={this.state.opacity51 ? this.state.opacity51 : 1}
              fill={this.state.color51 ? this.state.color51 : 'transparent'} onClick={() => {
          this.setState({ color51: color, opacity51: opacity });
        }} d="M57.7,150.1c0.1-0.8,0.1-1.7,0.3-2.5c0-0.2,0.4-0.4,0.6-0.6c0.1,0.3,0.3,0.5,0.2,0.8
	c-0.2,0.8-0.5,1.6-0.7,2.4C57.9,150.1,57.8,150.1,57.7,150.1z" />
        <path id="XMLID_15_" stroke={borderColor} fillOpacity={this.state.opacity52 ? this.state.opacity52 : 1}
              fill={this.state.color52 ? this.state.color52 : 'transparent'} onClick={() => {
          this.setState({ color52: color, opacity52: opacity });
        }} d="M87,152.2c-0.3,0-0.6,0-0.9-0.1c-0.5-0.1-1.1-0.3-1.2,0.5c-0.1,0.7,0.1,1.2,0.9,1.3
	c0.4,0.1,0.9,0,1.3-0.1c0.9-0.2,1.9-0.4,2.7-0.8c2.1-1,4.3-0.5,6.4,1.2c2.4,2.1,2.5,4.8,2.2,7.6c-0.3,2.5-1.1,4.8-2.8,6.7
	c-0.6,0.6-1.3,1.2-1.9,1.7c-1.5,1.3-3,2.5-4.5,3.7c-1.1,0.9-2.4,0.9-3.7,0.9c-0.3,0-0.6-0.2-0.8-0.5c-1.1-1.3-2.5-1.7-4.1-1.5
	c-1.9,0.2-2.5-1.1-3.1-2.4c-1.9-3.8-2-7.8-1.3-11.8c0.3-1.5,1.1-3,2-4.3c1.2-1.7,2.8-3.2,5.1-3.3c0.7,0,1.3,0.2,2,0.4
	c0.3,0.1,0.6,0.1,0.9,0.2c0.4,0.1,0.7,0.3,1.1,0.5C87,152,87,152.1,87,152.2z" />
        <path id="XMLID_14_" stroke={borderColor} fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1}
              fill={this.state.color53 ? this.state.color53 : 'transparent'} onClick={() => {
          this.setState({ color53: color, opacity53: opacity });
        }} d="M88.9,151.5c-0.1-1.1-0.3-2.3-0.3-3.4c0-0.2,0.6-0.5,1-0.6c0.1,0,0.5,0.5,0.5,0.7
	c-0.2,1.1-0.4,2.2-0.6,3.3C89.2,151.5,89.1,151.5,88.9,151.5z" />
        <path id="XMLID_13_" stroke={borderColor} fillOpacity={this.state.opacity54 ? this.state.opacity54 : 1}
              fill={this.state.color54 ? this.state.color54 : 'transparent'} onClick={() => {
          this.setState({ color54: color, opacity54: opacity });
        }} d="M117.9,85.8c0.2,0,0.5,0.1,0.7,0.1c0.3,0,0.7,0.2,0.8,0.1c0.2-0.2,0.2-0.6,0.1-0.9
	c0-0.3-0.2-0.5-0.3-0.8c0.7-0.5,0.9-1.1,0.2-1.9c-0.1-0.1-0.1-0.4-0.1-0.6c0.6-1.3,1.1-2.7,1.9-3.9c0.9-1.3,4.4-2,5.4-0.2
	c1.1,1.8,1.7,3.7,1.1,6c-0.8,2.7-0.9,5.5,0.2,8.2c0.4,0.9,0.9,1.8,1.4,2.7c1.2,2.7,1.1,5.2-0.5,7.7c-1,1.5-2.1,2.7-3.8,3.2
	c-1.7,0.6-3.4,0.5-4.8-0.4c-1.4-0.9-2.8-0.8-4.3-1c-1.9-0.2-3.5-0.9-4.4-2.6c-0.7-1.4-0.8-3-0.6-4.6c0.2-1.7,0.6-3.4,1.6-4.8
	c0.6-0.8,1.4-1.4,2.1-2.1c0.7-0.7,1.3-1.3,2-1.9c0.2-0.2,0.6-0.3,0.7-0.3c0.5,0.3,0.9,0.2,1.2-0.3c0.3-0.6,0-1-0.4-1.3
	c-0.1-0.1-0.2-0.1-0.4-0.2C117.8,86,117.8,85.9,117.9,85.8z" />
        <path id="XMLID_12_" stroke={borderColor} fillOpacity={this.state.opacity55 ? this.state.opacity55 : 1}
              fill={this.state.color55 ? this.state.color55 : 'transparent'} onClick={() => {
          this.setState({ color55: color, opacity55: opacity });
        }} d="M147.6,125.4c-0.8,1.4-1.6,2.8-2.4,4.2c-0.7,1.5-1.4,3-2.1,4.6c-0.2-0.6-0.6-1.3-0.5-1.9
	c0.2-1.9,0.5-3.8,0.9-5.6c0.2-0.9,0.7-1.7,1-2.6c0.9-2.7,3-4.5,5.3-6c1.5-1,3.3-1.5,5.1-1.6c0.6,0,1.2,0.2,1.9,0.4
	c-1.4,1.4-3.3,1.9-4.2,3.7c0.2,0,0.4,0.1,0.5,0.1c1.1-0.6,2.1-1.2,3.1-1.7c3.2-1.7,6.1-1.2,7.1,2.7c1,3.8-0.2,7.3-2.7,10.3
	c-2,2.4-4,4.7-6.6,6.3c-1.8,1.1-3.7,2.1-5.9,1.9c-1.8-0.1-3.9-1.6-3.5-4.1c0.5-2.9,1.5-5.6,3-8.1c0.4-0.7,1-1.5,0.5-2.4
	C147.9,125.5,147.7,125.4,147.6,125.4z" />
        <path id="XMLID_11_" stroke={borderColor} fillOpacity={this.state.opacity56 ? this.state.opacity56 : 1}
              fill={this.state.color56 ? this.state.color56 : 'transparent'} onClick={() => {
          this.setState({ color56: color, opacity56: opacity });
        }}
              d="M159.5,116.1c0.7-1,1.4-2,2.1-3c0.2-0.2,0.5-0.3,0.9-0.5C162.5,114.3,160.7,116.3,159.5,116.1z"
        />
        <path id="XMLID_10_" stroke={borderColor} fillOpacity={this.state.opacity57 ? this.state.opacity57 : 1}
              fill={this.state.color57 ? this.state.color57 : 'transparent'} onClick={() => {
          this.setState({ color57: color, opacity57: opacity });
        }} d="M48.1,93.4c-0.1-0.4-0.3-0.8-0.4-1.3c0.4-0.1,1.2,0,1.5-0.4c0.6-0.5,0-1.1-0.5-1.5
	c0.8-0.5,0.8-1.2,0.3-1.8c-0.1-0.2-0.2-0.5-0.1-0.6c0.5-0.9,1.1-1.9,1.7-2.8c0.5-0.6,1.1-1.1,1.7-1.6c1.3-1,2.8-0.9,4.3-0.6
	c0.8,0.2,1.4,1.6,1.1,2.6c-0.5,2-0.2,4,0.2,6c0.2,1,0.7,2,1.1,2.9c0.9,2.2,0.6,4.6,0.6,6.9c-0.1,2.3-1.1,3.9-2.7,5.3
	c-1.2,1.1-2.7,1.5-4.3,1.6c-0.3,0-0.7-0.3-1-0.5c-1.2-1.3-2.9-1.8-4.5-2.2c-0.7-0.2-0.8,0.7-0.7,1.6c-1.1-0.6-2.2-1.2-3.2-1.7
	c-1.9-1.1-2.7-3.5-2.1-5.7c0.4-1.5,1.2-2.8,2.4-3.9c0.5-0.4,1.1-0.7,1.7-1c0.4-0.2,0.7-0.6,1-0.7C46.8,93.7,47.4,93.6,48.1,93.4z" />
        <path id="XMLID_9_" stroke={borderColor} fillOpacity={this.state.opacity58 ? this.state.opacity58 : 1}
              fill={this.state.color58 ? this.state.color58 : 'transparent'} onClick={() => {
          this.setState({ color58: color, opacity58: opacity });
        }} d="M57.7,79.8c0.3-0.8,0.6-1.5,1-2.3c0.1-0.2,0.5-0.1,0.8-0.2c0,0.2,0.1,0.5,0,0.7
	c-0.5,0.7-1.1,1.3-1.6,1.9C57.9,79.9,57.8,79.9,57.7,79.8z" />
        <path id="XMLID_8_" stroke={borderColor} fillOpacity={this.state.opacity59 ? this.state.opacity59 : 1}
              fill={this.state.color59 ? this.state.color59 : 'transparent'} onClick={() => {
          this.setState({ color59: color, opacity59: opacity });
        }} d="M45.7,128.6c-0.5,1.4-1,2.8-1.5,4.5c-0.1-0.6-0.3-1-0.3-1.4c-0.2-2,0.2-3.9,0.8-5.9
	c0.8-2.3,2-4.1,3.7-5.6c1.1-1,2.4-1.7,3.6-2.4c0.6-0.4,2.8-0.2,3.8,0.2c-0.7,0.4-1.5,0.7-2.1,1.1c-1.3,0.9-2.5,1.9-3.7,3
	c-0.5,0.4-0.6,1-0.1,1.5c1-0.9,2-1.7,3-2.5c1-0.8,2.1-1.3,3.4-1.7c1-0.3,1.8-0.3,2.7-0.1c1.9,0.4,2.9,1.8,3.1,3.6
	c0.2,1.6,0,3.2-0.3,4.8c-0.7,3.9-3.2,6.8-6.2,9.2c-1.2,0.9-2.7,1.3-4.2,1.4c-1.4,0.2-2.7,0.3-4-0.4c-1.5-0.8-2.2-2-2.1-3.7
	c0.1-1.5,0.5-3,0.8-4.5c0.1-0.3,0-0.7,0-1.1C46.1,128.7,45.9,128.7,45.7,128.6z" />
        <path id="XMLID_7_" stroke={borderColor} fillOpacity={this.state.opacity60 ? this.state.opacity60 : 1}
              fill={this.state.color60 ? this.state.color60 : 'transparent'} onClick={() => {
          this.setState({ color60: color, opacity60: opacity });
        }}
              d="M59.5,118c1.2-1.2,1.7-2.7,1.8-4.3c0.1-1,0.4-1.1,1.7-0.6C62.6,116.3,61,118.6,59.5,118z" />
        <path id="XMLID_6_" stroke={borderColor} fillOpacity={this.state.opacity61 ? this.state.opacity61 : 1}
              fill={this.state.color61 ? this.state.color61 : 'transparent'} onClick={() => {
          this.setState({ color61: color, opacity61: opacity });
        }}
              d="M194,52.6c-0.9,0.6-1.8,0.7-1.9,0.2c-0.1-0.3,0.2-0.8,0.5-1.1C192.9,51.4,193.5,51.7,194,52.6z"
        />
        <path id="XMLID_5_" stroke={borderColor} fillOpacity={this.state.opacity62 ? this.state.opacity62 : 1}
              fill={this.state.color62 ? this.state.color62 : 'transparent'} onClick={() => {
          this.setState({ color62: color, opacity62: opacity });
        }} d="M50.2,50.2c0.3,0.5,0.5,0.7,0.7,1.1c-0.4,0.1-0.7,0.3-1,0.3c-0.1,0-0.3-0.3-0.3-0.4
	C49.8,50.9,50,50.6,50.2,50.2z" />
        <path id="XMLID_4_" stroke={borderColor} fillOpacity={this.state.opacity63 ? this.state.opacity63 : 1}
              fill={this.state.color63 ? this.state.color63 : 'transparent'} onClick={() => {
          this.setState({ color63: color, opacity63: opacity });
        }} d="M85.6,47.6c0.9,0.6,1.8,0.9,1.8,1.9c0,0.2,0,0.5-0.1,0.5c-0.8,0.3-1.7,0.5-2.5,0.6
	c-0.1,0-0.5-0.9-0.3-1.2C84.7,48.8,85.2,48.3,85.6,47.6z" />
        <path id="XMLID_3_" stroke={borderColor} fillOpacity={this.state.opacity64 ? this.state.opacity64 : 1}
              fill={this.state.color64 ? this.state.color64 : 'transparent'} onClick={() => {
          this.setState({ color64: color, opacity64: opacity });
        }} d="M128.4,75.3c0.1-0.3,0.8-1.2,1.4-1.1c0.7,0.1,0,0.5,0,0.5L128.4,75.3z" />
        <path id="XMLID_2_" stroke={borderColor} fillOpacity={this.state.opacity65 ? this.state.opacity65 : 1}
              fill={this.state.color65 ? this.state.color65 : 'transparent'} onClick={() => {
          this.setState({ color65: color, opacity65: opacity });
        }}
              d="M192.1,119.2c0,0,3-5.1,4.9-3.7s0.4,1.8-0.6,0.9S192.1,119.2,192.1,119.2z" />
      </SvgIcon>
    )
  }
};