import No from "../No";

export const getPageMath2WorkingsNotes = (page, edition) => {
  let EditionPageContent;
  let editable = true;
  switch (page) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 80:
      editable = false;
      EditionPageContent = No;
      break;
    case 50:
      return {
        editable,
        component: No,
        addProps: {
          layer2: {
            images: [{
              cfg: {
                src: `images/${edition}/No${page}/${page}_1.png`,
                width: 35,
                height: 40,
                draggable: true
              },
              count: 10
            }, {
              cfg: {
                src: `images/${edition}/No${page}/${page}_2.png`,
                width: 30,
                height: 30,
                draggable: true
              },
              count: 10
            }]
          }
        }
      }
      break;
    case 78:
      return {
        editable,
        component: No,
        addProps: {
          layer2: {
            images: [{
              cfg: {
                src: `images/${edition}/No${page}/${page}_1.png`,
                width: 150,
                height: 19,
                draggable: true,
                rotatable: true
              },
              count: 9
            }]
          }
        }
      }
      break;
    default:
      if (page > 4) {
        EditionPageContent = No;
      }
      break;
  }
  return {
    editable,
    component: EditionPageContent,
    addProps: {}
  };
}