import React, { Component } from 'react';
import { Layer } from "react-konva";
import Divider from "./Divider";
import RulerTriangle from "./RulerTriangle";
import Ruler from "./Ruler";
import Protractor from "./Protractor";
import _ from "lodash";

export default class ToolLayer extends Component<any, any> {
  private readonly dividerRef: any;

  constructor(props) {
    super(props);
    this.dividerRef = React.createRef();
  }

  public registerMouseMove = (x, y) => {
    this.dividerRef.current?.registerMouseMove(x, y);
  }

  public registerMouseDown = () => {
    this.dividerRef.current?.registerMouseDown();
  }

  public registerMouseUp = () => {
    this.dividerRef.current?.registerMouseUp();
  }

  render() {
    const {showedDivider, showedRulerTriangle, showedRuler, showedProtractor, dragImgEnabled, pageY, stageWidth} = this.props;
    const scaleX = stageWidth / 1032;
    const pageYScaled = !_.isUndefined(pageY) ? pageY - ( scaleX ) * 74 : pageY;
    return (
      <Layer>
        {showedRuler && <Ruler
          dragImgEnabled={dragImgEnabled}
          pageY={pageYScaled}
          scaleX={scaleX}
        />}
        {showedRulerTriangle && <RulerTriangle
          dragImgEnabled={dragImgEnabled}
          pageY={pageYScaled}
          scaleX={scaleX}
        />}
        {showedProtractor && <Protractor
          dragImgEnabled={dragImgEnabled}
          pageY={pageYScaled}
          scaleX={scaleX}
        />}
        {showedDivider && <Divider
          ref={this.dividerRef}
          onDrawWithDivider={(type, x, y, radius, rotation) => {
            this.props.onDrawWithDivider(type, x, y, radius, rotation);
          }}
          pageY={pageYScaled}
        />}
      </Layer>
    )
  }
}



