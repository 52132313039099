import React, { Component } from 'react';
import { Box, TextField } from '@material-ui/core';
import VisibleDrawingImage from "../components/VisibleDrawingImage";
import Axios from "../ajax/Axios";
import _ from "lodash";
import { setMaskImages, setMaskText, setPageChangedState, setPageChangedStateText } from "../actions";
import { PLACEHOLDERS } from "../util/APP_CONSTANS";
import AudioPlayer from "../components/AudioPlayer";

export default class NoTask extends Component<any, any> {
  axios: Axios;
  scaleDefault = 912;
  timer;
  initialText;

  constructor(props) {
    super(props);
    const { edition, userName, task } = this.props;
    this.axios = new Axios(userName, edition);
    const text = task.text ? task.text : '';
    this.initialText = text;
    this.state = {
      text
    }
  }

  private getImageName = (prefix: string = '', suffix: string = '', number: number = 1) => {
    const { task } = this.props;
    return `${prefix}image_${task?.page}_${number}${suffix}`;
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    const doSaving = this.props.saveChanging && this.props.saveChanging !== prevProps.saveChanging;
    if (doSaving) {
      this.saveValues();
      this.props.dispatch(setMaskImages(false));
      this.props.dispatch(setMaskText(false));
      this.props.dispatch(setPageChangedState());
      this.props.dispatch(setPageChangedStateText());
    }
    const propsText = _.get(this.props, 'task.text');
    if (!_.isEqual(_.get(prevProps, 'task.text'), propsText)) {
      this.setState({
        text: propsText
      })
    }
  }

  private saveValues = () => {
    const { task } = this.props;
    if (task.type !== 'button-drawing') {
      const { number, fields } = task;
      const { text } = this.state;
      let rootButtonCounter = 0;
      const jsonValue = fields.map((item) => {
        if (!item.type || item.type === 'input') {
          return item.value;
        } else {
          rootButtonCounter++;
          if (rootButtonCounter === number) {
            return text;
          } else {
            return item.text;
          }
        }
      });
      const json = { fields: jsonValue };
      const konvaJSON = JSON.stringify(json, null, "  ");
      this.axios.postRequest({ action: 'saveKonvaJSON', name: this.getImageName(), konvaJSON });
    }
  }

  private handleChangeInputField = (event: any): void => {
    const value = event.target.value;
    if (!_.isEqual(value, this.initialText)) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.props.dispatch(setPageChangedStateText(true));
      }, 500)
    }
    this.setState({
      text: value
    });
  };

  render() {
    const { edition, userName, task, showedMicrophone } = this.props;
    const { type, number, page } = task;
    const value = this.state.text;
    return (
      <Box
        display="flex"
        flexDirection={"column"}
        style={{ position: 'relative' }}
      >
        {showedMicrophone &&
        <div
          style={{ position: 'absolute', top: 0, left: 0 }}
        >
          <AudioPlayer
            edition={edition}
            number={number}
            page={page}
            onPlay={(number) => {
              this.setState({
                activeAudioPlayerNumber: number
              })
            }}
            isActive={this.state.activeAudioPlayerNumber === number}
          />
        </div>
        }
        <img
          src={`images/${edition}/No${page}/task_${number}.png`}
          width={"100%"}
        />
        {(type === 'button-text' || type === 'button-text-drawing') &&
        <TextField
          id="outlined-multiline-flexible"
          label={PLACEHOLDERS.TASK_TEXT_LABEL}
          multiline
          rows={20}
          value={value}
          onChange={(e: any) => {
            this.handleChangeInputField(e)
          }}
        />}
        {(type === 'button-drawing' || type === 'button-text-drawing') &&
        <Box
          paddingTop={3}
        >
          {PLACEHOLDERS.TASK_DRAWING_LABEL}
        </Box>
        }
        {(type === 'button-drawing' || type === 'button-text-drawing') &&
        <VisibleDrawingImage
          boxContainer={{ marginTop: 1, border: '1px solid', borderRadius: 5 }}
          name={this.getImageName('task_', '', number)}
          edition={edition}
          userName={userName}
        />
        }
      </Box>
    )
  }
}