import React, { Component } from "react";
import { SvgIcon } from "@material-ui/core";
import { changeWeight } from "../actions";
import { connect } from 'react-redux';
import _ from 'lodash';

class LineWeight extends Component<any, any> {
  render() {
    const st0 = "#084B5E";
    let st1 = "none";
    let st2 = "#FFFFFF";
    let st3 = "none";
    let st4 = "none";
    let st5 = "none";
    let st6= "none";
    let st7 = "none";
    let st8 = "none";
    const { w, h, disabled, dispatch, drawables, weight } = this.props;
    const lineWeight = drawables.weight ? drawables.weight : weight;
    if(lineWeight && _.isNumber(lineWeight)) {
      switch (lineWeight) {
        case 1:
          st1 = "#FFFFFF";
          st2 = "none";
          st3 = "none";
          st4 = "none";
          st5 = "none";
          st6= "none";
          st7 = "none";
          st8 = "none";
          break;
        case 2:
          st1 = "none";
          st2 = "#FFFFFF";
          st3 = "none";
          st4 = "none";
          st5 = "none";
          st6= "none";
          st7 = "none";
          st8 = "none";
          break;
        case 3:
          st1 = "none";
          st2 = "none";
          st3 = "#FFFFFF";
          st4 = "none";
          st5 = "none";
          st6= "none";
          st7 = "none";
          st8 = "none";
          break;
        case 5:
          st1 = "none";
          st2 = "none";
          st3 = "none";
          st4 = "#FFFFFF";
          st5 = "none";
          st6= "none";
          st7 = "none";
          st8 = "none";
          break;
        case 8:
          st1 = "none";
          st2 = "none";
          st3 = "none";
          st4 = "none";
          st5 = "#FFFFFF";
          st6= "none";
          st7 = "none";
          st8 = "none";
          break;
        case 13:
          st1 = "none";
          st2 = "none";
          st3 = "none";
          st4 = "none";
          st5 = "none";
          st6= "#FFFFFF";
          st7 = "none";
          st8 = "none";
          break;
        case 21:
          st1 = "none";
          st2 = "none";
          st3 = "none";
          st4 = "none";
          st5 = "none";
          st6= "none";
          st7 = "#FFFFFF";
          st8 = "none";
          break;
        case 34:
          st1 = "none";
          st2 = "none";
          st3 = "none";
          st4 = "none";
          st5 = "none";
          st6= "none";
          st7 = "none";
          st8 = "#FFFFFF";
          break;
      }
    }

    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 160.9 87.4"}
        style={{ width: w, height: h, opacity: disabled ? 0.5 : 1 }}
      >
        <g id="XMLID_178_">
          <g id="XMLID_1457_">
            <g id="XMLID_114_"
               onClick={()=>{
                 dispatch(changeWeight(5));//Fourth-Left
               }}
            >
              <path id="XMLID_264_" fill={st0}
                    d="M6,82.9c-2.8,0-5-2.2-5-5s2.2-5,5-5h59.1c2.8,0,5,2.2,5,5s-2.2,5-5,5H6z" />
              <path id="XMLID_257_" fill={st4} d="M65.1,73.9c2.2,0,4,1.8,4,4s-1.8,4-4,4H6c-2.2,0-4-1.8-4-4s1.8-4,4-4H65.1 M65.1,71.9H6
				c-3.3,0-6,2.7-6,6s2.7,6,6,6h59.1c3.3,0,6-2.7,6-6S68.4,71.9,65.1,71.9L65.1,71.9z" />
            </g>
          </g>
          <g id="XMLID_1453_"
             onClick={()=>{
               dispatch(changeWeight(1));//First-Left
             }}
          >
            <g id="XMLID_113_">
              <path id="XMLID_260_" fill={st0}
                    d="M3.1,8.5C1.9,8.5,1,7.6,1,6.5s0.9-2,2.1-2H68c1.2,0,2.1,0.9,2.1,2s-0.9,2-2.1,2H3.1z" />
              <path id="XMLID_252_" fill={st1} d="M68,5.5c0.6,0,1.1,0.4,1.1,1s-0.5,1-1.1,1H3.1C2.5,7.5,2,7.1,2,6.5s0.5-1,1.1-1H68 M68,3.5
				H3.1C1.4,3.5,0,4.8,0,6.5s1.4,3,3.1,3H68c1.7,0,3.1-1.3,3.1-3S69.7,3.5,68,3.5L68,3.5z" />
            </g>
          </g>
          <g id="XMLID_1454_">
            <g id="XMLID_112_"
               onClick={()=>{
                 dispatch(changeWeight(2));//Second-Left
               }}
            >
              <path id="XMLID_256_" fill={st0}
                    d="M4.1,33.3c-1.7,0-3.1-1.3-3.1-3s1.4-3,3.1-3h62.8c1.7,0,3.1,1.3,3.1,3s-1.4,3-3.1,3H4.1z" />
              <path id="XMLID_247_" fill={st2} d="M66.9,28.3c1.2,0,2.1,0.9,2.1,2s-1,2-2.1,2H4.1c-1.2,0-2.1-0.9-2.1-2s1-2,2.1-2H66.9
				 M66.9,26.3H4.1c-2.3,0-4.1,1.8-4.1,4s1.9,4,4.1,4h62.8c2.3,0,4.1-1.8,4.1-4S69.2,26.3,66.9,26.3L66.9,26.3z" />
            </g>
          </g>
          <g id="XMLID_1455_"
             onClick={()=>{
               dispatch(changeWeight(3));//Third-Left
             }}
          >
            <g id="XMLID_111_">
              <path id="XMLID_253_" fill={st0}
                    d="M5.1,58.1c-2.3,0-4.1-1.8-4.1-4s1.8-4,4.1-4H66c2.3,0,4.1,1.8,4.1,4s-1.8,4-4.1,4H5.1z" />
              <path id="XMLID_242_" fill={st3} d="M66,51.1c1.7,0,3.1,1.3,3.1,3s-1.4,3-3.1,3H5.1c-1.7,0-3.1-1.3-3.1-3s1.4-3,3.1-3H66
				 M66,49.1H5.1c-2.8,0-5.1,2.2-5.1,5s2.3,5,5.1,5H66c2.8,0,5.1-2.2,5.1-5S68.8,49.1,66,49.1L66,49.1z" />
            </g>
          </g>
          <g id="XMLID_1434_"
             onClick={()=>{
               dispatch(changeWeight(8));//First-Right
             }}
          >
            <g id="XMLID_110_">
              <path id="XMLID_249_" fill={st0}
                    d="M89,12c-3.3,0-5.9-2.5-5.9-5.5S85.7,1,89,1h65c3.3,0,5.9,2.5,5.9,5.5S157.3,12,154,12H89z"
              />
              <path id="XMLID_241_" fill={st5} d="M154,2c2.7,0,4.9,2,4.9,4.5S156.7,11,154,11H89c-2.7,0-4.9-2-4.9-4.5S86.3,2,89,2H154
				 M154,0H89c-3.8,0-6.9,2.9-6.9,6.5S85.2,13,89,13h65c3.8,0,6.9-2.9,6.9-6.5S157.8,0,154,0L154,0z" />
            </g>
          </g>
          <g id="XMLID_1445_"
             onClick={()=>{
               dispatch(changeWeight(13));//Second-Right
             }}
          >
            <g id="XMLID_109_">
              <path id="XMLID_245_" fill={st0} d="M90.6,36.8c-3.8,0-6.8-2.9-6.8-6.5s3.1-6.5,6.8-6.5h62.5c3.8,0,6.8,2.9,6.8,6.5
				s-3.1,6.5-6.8,6.5H90.6z" />
              <path id="XMLID_240_" fill={st6} d="M153.1,24.8c3.2,0,5.8,2.5,5.8,5.5s-2.6,5.5-5.8,5.5H90.6c-3.2,0-5.8-2.5-5.8-5.5
				s2.6-5.5,5.8-5.5H153.1 M153.1,22.8H90.6c-4.3,0-7.8,3.4-7.8,7.5s3.5,7.5,7.8,7.5h62.5c4.3,0,7.8-3.4,7.8-7.5
				S157.4,22.8,153.1,22.8L153.1,22.8z" />
            </g>
          </g>
          <g id="XMLID_1432_"
             onClick={()=>{
               dispatch(changeWeight(21));//Third-Right
             }}
          >
            <g id="XMLID_108_">
              <path id="XMLID_239_" fill={st0}d="M90.8,61.6c-4.3,0-7.7-3.4-7.7-7.5s3.5-7.5,7.7-7.5h61.4c4.3,0,7.7,3.4,7.7,7.5
				s-3.5,7.5-7.7,7.5H90.8z" />
              <path id="XMLID_236_" fill={st7} d="M152.2,47.6c3.7,0,6.7,2.9,6.7,6.5s-3,6.5-6.7,6.5H90.8c-3.7,0-6.7-2.9-6.7-6.5
				s3-6.5,6.7-6.5H152.2 M152.2,45.6H90.8c-4.8,0-8.7,3.8-8.7,8.5s3.9,8.5,8.7,8.5h61.4c4.8,0,8.7-3.8,8.7-8.5S157,45.6,152.2,45.6
				L152.2,45.6z" />
            </g>
          </g>
          <g id="XMLID_1458_"
             onClick={()=>{
               dispatch(changeWeight(34));//Fourth-Right
             }}
          >
            <g id="XMLID_107_">
              <path id="XMLID_235_" fill={st0} d="M92.3,86.4c-4.7,0-8.5-3.8-8.5-8.5s3.8-8.5,8.5-8.5h59.2c4.7,0,8.5,3.8,8.5,8.5
				s-3.8,8.5-8.5,8.5H92.3z" />
              <path id="XMLID_232_" fill={st8} d="M151.4,70.4c4.1,0,7.5,3.4,7.5,7.5s-3.4,7.5-7.5,7.5H92.3c-4.1,0-7.5-3.4-7.5-7.5
				s3.4-7.5,7.5-7.5H151.4 M151.4,68.4H92.3c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5h59.2c5.2,0,9.5-4.3,9.5-9.5
				S156.7,68.4,151.4,68.4L151.4,68.4z" />
            </g>
          </g>
        </g>
      </SvgIcon>
    )
  }
};

const getVisibleDrawables = (drawables) => {
  return drawables;
}

const mapStateToProps = state => (
  {
    drawables: getVisibleDrawables(state.drawables)
  }
)

export default connect(
  mapStateToProps
)(LineWeight)