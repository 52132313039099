import React, { Component } from "react";
import { SvgIcon } from "@material-ui/core";

export default class Move extends Component<any, any>{

  render() {
    const st0 = "#FFC96C";
    const st1 = "#064B5E";
    const st2 = "#A0D8CB";
    const { disabled } = this.props;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-321.8 521.7 63.5 63.5"}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <g>
          <g id="XMLID_382_">
            <circle id="XMLID_142_" fill={st0} cx="-290" cy="553.5" r="30.9" />
          </g>
          <g id="XMLID_379_">
            <path id="XMLID_570_" fill={st1} d="M-290,585.2c-17.5,0-31.8-14.2-31.8-31.7s14.3-31.8,31.8-31.8s31.7,14.3,31.7,31.8
			S-272.5,585.2-290,585.2z M-290,523.3c-16.6,0-30.1,13.5-30.1,30.1c0,16.6,13.5,30.2,30.1,30.2c16.6,0,30.1-13.5,30.1-30.1
			C-259.9,536.9-273.4,523.3-290,523.3z" />
          </g>
          <g id="XMLID_378_">
            <circle id="XMLID_141_" fill={st2} cx="-290" cy="553.5" r="22.8" />
          </g>
          <g id="XMLID_375_">
            <path id="XMLID_564_" fill={st1} d="M-290,577.1c-13,0-23.7-10.6-23.7-23.7c0-13,10.6-23.7,23.7-23.7c13,0,23.7,10.6,23.7,23.7
			C-266.4,566.5-277,577.1-290,577.1z M-290,531.4c-12.1,0-22,9.9-22,22c0,12.1,9.9,22,22,22c12.1,0,22-9.9,22-22
			C-268,541.3-277.9,531.4-290,531.4z" />
          </g>
          <path fill={st1} d="M-272.8,553.5c-2.1-1.4-4.3-2.7-6.5-3.9c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2-0.1-0.3-0.2-0.5-0.4
		c-0.2-0.1-0.5,0-0.6,0.3c-0.3,1.2,0.1,2.4,0.2,3.6c0,0.1,0,0.1,0,0.2c-0.8-0.2-1.6-0.4-2.4-0.5c-0.8-0.1-5.3-0.2-7.1,0
		c0.3-1.5,0.8-4.8,0.7-6.5c-0.1-0.8-0.2-1.6-0.5-2.4c0.1,0,0.1,0,0.2,0c1.2,0.1,2.4,0.5,3.6,0.2c0.2-0.1,0.4-0.3,0.3-0.6
		c-0.1-0.2-0.3-0.4-0.4-0.6c0-0.1,0-0.2-0.1-0.3c-1.2-2.2-2.5-4.4-3.9-6.5c-0.1-0.2-0.4-0.2-0.5,0c-1.4,2.4-2.7,4.8-3.8,7.3
		c-0.1,0.3,0.2,0.5,0.4,0.5c1.2-0.2,2.4-0.2,3.7-0.2c0.1,0.8,0.3,1.5,0.3,2.2c0.2,3.8-0.7,5.9-0.8,7.1l0,0c-1.5-0.3-5.6-0.4-6.5-0.3
		c-0.8,0.1-1.6,0.2-2.4,0.5c0-0.1,0-0.1,0-0.2c0.1-1.2,0.5-2.4,0.2-3.6c-0.1-0.2-0.3-0.4-0.6-0.3c-0.2,0.1-0.4,0.2-0.5,0.4
		c-0.1,0-0.2,0-0.3,0.1c-2.2,1.2-4.4,2.5-6.5,3.9c-0.2,0.1-0.2,0.4,0,0.5c2.4,1.5,4.9,2.8,7.4,3.9c0.3,0.1,0.5-0.2,0.5-0.4
		c-0.2-1.2-0.2-2.4-0.2-3.7c0.7-0.1,1.4-0.3,2.1-0.4c4.9-0.3,6,0.3,7,0.3c-0.3,1.4-0.5,6.2-0.3,7.2c0.1,0.8,0.2,1.6,0.5,2.4
		c-0.1,0-0.1,0-0.2,0c-1.2-0.1-2.4-0.5-3.6-0.2c-0.2,0.1-0.4,0.3-0.3,0.6c0.1,0.2,0.2,0.4,0.4,0.5c0,0.1,0,0.2,0.1,0.3
		c1.2,2.2,2.5,4.4,3.9,6.5c0.1,0.2,0.4,0.2,0.5,0c1.4-2.4,2.7-4.8,3.8-7.3c0.1-0.3-0.2-0.5-0.4-0.5c-1.2,0.2-2.4,0.2-3.7,0.2
		c-0.1-0.7-0.3-1.4-0.4-2.1c-0.1-1.1,0.3-6.2,0.3-7.8c1-0.1,2.5-0.3,7.4-0.1c0.7,0,1.4,0.3,2.1,0.4c0.1,1.2,0,2.5-0.2,3.7
		c0,0.2,0.2,0.6,0.5,0.4c2.5-1.1,4.9-2.4,7.3-3.8C-272.5,553.9-272.6,553.6-272.8,553.5z" />
        </g>
      </SvgIcon>
    )
  }

};
