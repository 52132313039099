import { Component } from "react";
import { SvgIcon } from "@material-ui/core";
import React from "react";

export default class Eraser extends Component<any, any>{

  render() {
    const st0 = "#FFC96C";
    const st1 = "#F37785";
    const st2 = "#9DCE6A";
    const st3 = "#084B5E";
    const st4 = "#FFFFFF";
    const { disabled } = this.props;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-340.6 563.9 35.5 63.8"}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <g id="XMLID_1_">
          <g id="XMLID_480_">
            <rect id="XMLID_170_" x="-339.5" y="594.5" fill={st0} width="33.3" height="11.1" />
          </g>
          <g id="XMLID_476_">
            <path id="XMLID_169_" fill={st1}
                  d="M-314.5,565h-16.7c-4.6,0-8.3,3.7-8.3,8.3v21.1h33.3v-21C-306.2,568.8-309.9,565-314.5,565z"
            />
          </g>
          <g id="XMLID_472_">
            <path id="XMLID_168_" fill={st2}
                  d="M-339.5,605.6v12.8c0,4.6,3.7,8.3,8.3,8.3h16.7c4.6,0,8.3-3.7,8.3-8.3v-12.8H-339.5z" />
          </g>
          <path id="XMLID_60_" fill={st3} d="M-314.5,563.9h-16.7c-5.2,0-9.4,4.2-9.4,9.4v21.1v11.1v12.8c0,5.2,4.2,9.4,9.4,9.4h16.7
		c5.2,0,9.4-4.2,9.4-9.4v-12.7v-11.1v-21.1C-305.1,568.2-309.3,563.9-314.5,563.9z M-338.4,595.6h31.1v8.9h-31.1V595.6z
		 M-338.4,573.4c0-4,3.2-7.2,7.2-7.2h16.7c4,0,7.2,3.2,7.2,7.2v20h-31.1V573.4z M-307.3,618.4c0,4-3.2,7.2-7.2,7.2h-16.7
		c-4,0-7.2-3.2-7.2-7.2v-11.7h31.1C-307.3,606.7-307.3,618.4-307.3,618.4z" />
          <g id="XMLID_467_">
            <g id="XMLID_468_">
              <path id="XMLID_167_" fill={st4} d="M-316.8,588.1c-0.6,0-1.1-0.5-1.1-1.1v-13.5c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1V587
				C-315.7,587.6-316.2,588.1-316.8,588.1z" />
            </g>
          </g>
          <g id="XMLID_465_">
            <g id="XMLID_466_">
              <path id="XMLID_166_" fill={st4} d="M-316.8,619c-0.6,0-1.1-0.5-1.1-1.1v-5.6c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1v5.6
				C-315.7,618.6-316.2,619-316.8,619z" />
            </g>
          </g>
        </g>
      </SvgIcon>
    )
  }

};


