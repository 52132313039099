import React, { Component } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, Tooltip } from "@material-ui/core";
import {
  changeWeight
} from "../actions";
import { connect } from 'react-redux'
import IconButtonCorrect from "./IconButtonCorrect";
import { FormatColorFill } from "@material-ui/icons";
import { appColors } from "../App";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ReactDOM from "react-dom";
import { withStyles } from "@material-ui/core/styles";
import { ACTION_TOOLTIPS } from "../util/APP_CONSTANS";

class WeightLineMenuButton extends Component<any, any> {
  state = {
    anchorEl: null
  }

  private iconDom: any;
  private readonly disabledColor = 'rgba(0, 0, 0, 0.26)';

  private handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  private handleClose = () => {
    this.setState({ anchorEl: null });
  };

  private getIconButtonWeight = (containerType: string, iconButtonStyle: any) => {
    const { disabled } = this.props;
    let IconButtonWeight = null;
    if (containerType === 'tbar') {
      IconButtonWeight = (
        <Tooltip title={ACTION_TOOLTIPS.CHANGE_WEIGHT}>
          <IconButton
            disabled={disabled}
            onClick={this.handleClick}
          >
            <div style={iconButtonStyle} />
          </IconButton>
        </Tooltip>
      );
    } else {
      iconButtonStyle.backgroundColor = disabled ? this.disabledColor : 'black';
      IconButtonWeight = (
        <ListItemIcon
          ref={(el: any) => {
            this.iconDom = ReactDOM.findDOMNode(el);
          }}
          className={"correctMinWidth"}
        >
          <div
            style={iconButtonStyle}
          />
        </ListItemIcon>
      );
    }
    return IconButtonWeight;
  }


  render() {
    const disabledColor = this.disabledColor;
    const { disabled, drawables, dispatch, weightPalette, weight, containerType } = this.props;
    const iconButtonStyleColor = disabled ? disabledColor : 'white';
    const iconButtonStyleWeightDrawables = drawables.weight ? drawables.weight : weight;
    const iconButtonStyleWeight = iconButtonStyleWeightDrawables ? iconButtonStyleWeightDrawables : 1;
    const iconButtonStyle = { height: iconButtonStyleWeight, backgroundColor: iconButtonStyleColor, width: 24 };
    const menuItemStyle = { height: 24, width: 150 };
    const lineStyle = { backgroundColor: 'rgba(0, 0, 0, 0.26)', width: '100%' };
    const lineStyle1 = { ...lineStyle, height: 1 };
    const lineStyle2 = { ...lineStyle, height: 2 };
    const lineStyle3 = { ...lineStyle, height: 3 };
    const lineStyle4 = { ...lineStyle, height: 5 };
    const lineStyle5 = { ...lineStyle, height: 8 };
    const lineStyle6 = { ...lineStyle, height: 13 };
    const lineStyle7 = { ...lineStyle, height: 21 };

    const anchorEl = weightPalette ? this.iconDom : null;
    return (
      <>
        {this.getIconButtonWeight(containerType, iconButtonStyle)}
        <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          id="customized-menu"
          anchorEl={this.state.anchorEl || anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl || anchorEl)}
          onClose={this.handleClose}
          style={{ marginTop: 10 }}
        >
          <MenuItem
            style={menuItemStyle}
            onClick={() => {
              this.handleClose();
              dispatch(changeWeight(1))
            }}
          >
            <div style={lineStyle1} />
          </MenuItem>
          <MenuItem
            style={menuItemStyle}
            onClick={() => {
              this.handleClose();
              dispatch(changeWeight(2))
            }}
          >
            <div style={lineStyle2} />
          </MenuItem>
          <MenuItem
            style={menuItemStyle}
            onClick={() => {
              this.handleClose();
              dispatch(changeWeight(3))
            }}
          >
            <div style={lineStyle3} />
          </MenuItem>
          <MenuItem
            style={menuItemStyle}
            onClick={() => {
              this.handleClose();
              dispatch(changeWeight(5))
            }}
          >
            <div style={lineStyle4} />
          </MenuItem>
          <MenuItem
            style={menuItemStyle}
            onClick={() => {
              this.handleClose();
              dispatch(changeWeight(8))
            }}
          >
            <div style={lineStyle5} />
          </MenuItem>
          <MenuItem
            style={menuItemStyle}
            onClick={() => {
              this.handleClose();
              dispatch(changeWeight(13))
            }}
          >
            <div style={lineStyle6} />
          </MenuItem>
          <MenuItem
            style={menuItemStyle}
            onClick={() => {
              this.handleClose();
              dispatch(changeWeight(21))
            }}
          >
            <div style={lineStyle7} />
          </MenuItem>
        </Menu>
      </>
    );
  }
}

const getVisibleDrawables = (drawables) => {
  return drawables;
}

const mapStateToProps = state => (
  {
    drawables: getVisibleDrawables(state.drawables)
  }
)

export default connect(
  mapStateToProps
)(WeightLineMenuButton)


