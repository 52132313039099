export const spliceArray = (array: any[], newArray: any[], index: number = 0, count: number = 0) => {
  let args = [index, count].concat(newArray);
  array.splice.apply(array, args as any);
  return array;
};

export const removeAt = (array: any[], index: number): any[] => spliceArray(array, [], index, 1);

export const getFontSize = (height: number) => {
  return height * 0.6;
}
