export const ACTION_TOOLTIPS = {
  SHOW_ADVANCED_TOOLBAR: 'Додатни алат за цртање',
  SHOW_DRAWABLES: 'Оловка за цртање',
  SHOW_LINE: 'Цртање праве линије',
  SHOW_ARROW: 'Цртање праве линије са стрелицом',
  SHOW_CIRCLE: 'Цртање круга',
  SHOW_CURVE: 'Цртање криве линије',
  SHOW_RECT: 'Цртање квадрата и правоугаоника',
  TEXT: 'Писање текста',
  POINT: 'Цртање тачке',
  FILL: 'Бојење унутрашњости фигуре',
  SHOW_ELLIPSE: 'Цртање елипсе (заокруживање)',
  SHOW_ERASE: 'Гумица за брисање',
  CHANGE_COLOR: 'Бирање боје за цртање',
  CHANGE_WEIGHT: 'Дебљина линије',
  DRAG_IMG_ENABLE: 'Алат за померање слика',
  SAVE_CHANGING: 'Сачувај промене',
  SAVE_CHANGING_DEV_MODE: 'Сачувај промене u "DEV" моду',
  NEXT_PAGE: 'Следећа страна',
  PREVIOUS_PAGE: 'Претходне страна',
  UNDO: 'Врати се корак уназад',
  REDO: 'Иди корак унапред',
  COPY_SELECTED_SHAPE: 'Копирај означену фигуру',
  SEARCH_PAGE: 'Изабери страну',
  COLOR_PALETTE: 'Изабери боју',
  COLOR_INDICATOR: 'Изабрана боја',
  CONTENT: 'Садржај',
  HELP: 'Упутство за коришћење',
  DIVIDER: 'Шестар',
  RULER_TRIANGLE: 'Троугао',
  RULER: 'Лењир',
  PROTRACTOR: 'Угломер',
  AUDIO_PLAYER: 'Слушање текста задатка',
  POLYGON: 'Цртање многоугла',
  CURVE_POLYGON: 'Претвори многоугао у затворену криву линију',
  BACK: 'Повратак на главну  страну',
  COLOR_OPACITY: 'Боја постаје провидна',
  SVG_CLICK: 'Бојење жељеног дела слике'
};
export const ACTION_NAMES = {
  SAVE_CHANGES: 'САЧУВАЈ',
  NO: 'НЕ'
};
export const DIALOGS = {
  SAVE_CHANGES: {
    TITLE: 'САЧУВАЈ ПРОМЕНЕ',
    CONTENT1: 'Да ли желиш да сачуваш промене које си направио на тренутној страни?',
    CONTENT2:  'Ако желиш, притисни дугме САЧУВАЈ, а ако не желиш, дугме НЕ.'
  }
};
export const INFO_DIALOG = {
  TITLE: 'Информација',
  CONTENT1: 'Истекла ти је лиценца за ово издање!',
  CONTENT2:  'Немаш лиценцу да радиш на овом издању!',
  BUTTON_NAME: 'Затвори'
}
export  const  PLACEHOLDERS = {
  CHECK_MARK: 'Тачан резултат',
  INCORRECT: 'Нетачан резултат',
  SEARCH_PAGE: 'Стр.',
  TASK_BUTTON: 'Задатке реши на новој страни',
  TASK_TEXT_LABEL: 'Овде решавај задатак:',
  TASK_DRAWING_LABEL: 'Овде решавај задатак користећи алате за цртање:'
}

export const DEFAULT_FOLDER_PATH = process.env.NODE_ENV !== 'production' ? '' : '/digital';

