import React, { Component } from "react";
import { SvgIcon } from "@material-ui/core";

export default class PreviousPage extends Component<any, any> {

  render() {
    const st0 = "#064B5E";
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-310.5 537.1 31 35.5"}
      >
        <g id="XMLID_484_">
          <g id="XMLID_485_">
            <path id="XMLID_84_" fill={st0} d="M-280.7,572.6c-0.2,0-0.4-0.1-0.6-0.2l-28.6-16.5c-0.4-0.2-0.6-0.6-0.6-1.1
			c0-0.4,0.2-0.8,0.6-1.1l28.6-16.5c0.4-0.2,0.9-0.2,1.2,0c0.4,0.2,0.6,0.6,0.6,1.1v33.1c0,0.4-0.2,0.8-0.6,1.1
			C-280.3,572.5-280.5,572.6-280.7,572.6z" />
          </g>
        </g>
      </SvgIcon>
    )
  }
};
