import { Component } from "react";
import { SvgIcon } from "@material-ui/core";
import React from "react";

export default class Pencil extends Component<any, any> {

  render() {
    const st0 = "#F37785";
    const st1 = "#084B5E";
    const st2 = "#FFC96C";
    const st3 = "#FFFFFF";
    const st4 = "#FCD7B6";
    const st5 = "#9DCE6A";
    const { disabled } = this.props;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-351.7 553.9 52.4 86"}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <g id="XMLID_260_">
          <g id="XMLID_284_">
            <path id="XMLID_90_" fill={st0} d="M-304.8,558.6l-6.4-3.1c-3.5-1.7-7.3-1.1-8.4,1.2l-2.7,5.5l19.1,9.2l2.7-5.5
			C-299.4,563.7-301.3,560.4-304.8,558.6z" />
          </g>
          <g id="XMLID_281_">
            <path id="XMLID_256_" fill={st1} d="M-303.6,572.2l-19-9.2c-0.5-0.2-0.8-0.7-0.6-1.1l2.7-5.5c1.3-2.8,5.6-3.4,9.6-1.4l6.4,3.1
			c4,1.9,6.2,5.7,4.9,8.5l-2.8,5.4C-302.5,572.4-303.1,572.4-303.6,572.2z M-321.1,562.2l17.2,8.4l2.4-4.9c1-2-0.6-4.9-3.6-6.3
			l-6.4-3.1c-3-1.5-6.2-1-7.2,1.1L-321.1,562.2z" />
          </g>
          <g id="XMLID_280_">

            <rect id="XMLID_89_" x="-324.7" y="566.8"
                  transform="matrix(-0.8997 -0.4365 0.4365 -0.8997 -845.5046 945.4595)" fill={st2} width="21.2"
                  height="6.2" />
          </g>
          <g id="XMLID_277_">
            <path id="XMLID_250_" fill={st1} d="M-306.3,577.8l-19.1-9.2c-0.5-0.2-0.8-0.7-0.6-1.1l2.7-5.5c0.2-0.3,0.7-0.4,1.2-0.2l19.1,9.2
			c0.5,0.2,0.8,0.7,0.6,1.1l-2.7,5.5C-305.2,577.9-305.8,578-306.3,577.8z M-323.8,567.7l17.2,8.4l2.1-4.3l-17.2-8.4L-323.8,567.7z"
            />
          </g>
          <g id="XMLID_275_">
            <g id="XMLID_276_">
              <path id="XMLID_88_" fill={st3} d="M-308.8,565.7c-0.5-0.2-0.8-0.7-0.6-1.1l1.8-3.6c0.2-0.3,0.7-0.4,1.2-0.2s0.8,0.7,0.6,1.1
				l-1.8,3.6C-307.8,565.9-308.3,566-308.8,565.7z" />
            </g>
          </g>
          <g id="XMLID_274_">

            <rect id="XMLID_87_" x="-338.9" y="569.7"
                  transform="matrix(-0.8997 -0.4365 0.4365 -0.8997 -885.2301 994.8358)" fill={st4} width="21.2"
                  height="58.9" />
          </g>
          <g id="XMLID_271_">
            <path id="XMLID_241_" fill={st1} d="M-332,630.8l-19-9.3c-0.5-0.2-0.8-0.7-0.6-1.1l25.7-53c0.2-0.3,0.7-0.4,1.2-0.2l19.1,9.2
			c0.5,0.2,0.8,0.7,0.6,1.1l-25.7,53C-330.9,630.9-331.5,631-332,630.8z M-349.5,620.7l17.2,8.4l25.1-51.8l-17.2-8.3L-349.5,620.7z"
            />
          </g>
          <g id="XMLID_270_">

            <rect id="XMLID_86_" x="-332.4" y="569.6"
                  transform="matrix(-0.8997 -0.4365 0.4365 -0.8997 -885.306 994.7747)" fill={st5} width="8.1"
                  height="58.9" />
          </g>
          <g id="XMLID_267_">
            <path id="XMLID_234_" fill={st1} d="M-337.9,627.9l-7.3-3.5c-0.5-0.2-0.8-0.7-0.6-1.1l25.7-53c0.2-0.3,0.7-0.4,1.2-0.2l7.3,3.5
			c0.5,0.2,0.8,0.7,0.6,1.1l-25.7,53C-336.8,628.1-337.4,628.1-337.9,627.9z M-343.6,623.6l5.4,2.6l25.1-51.8l-5.4-2.6L-343.6,623.6
			z" />
          </g>
          <g id="XMLID_266_">
            <polygon id="XMLID_85_" fill={st2} points="-331.7,630.1 -347.8,639.1 -350.7,620.9 		" />
          </g>
          <g id="XMLID_263_">
            <path id="XMLID_226_" fill={st1} d="M-348.1,639.7c-0.3-0.2-0.6-0.5-0.6-0.7l-2.9-18.2c0-0.3,0.1-0.5,0.3-0.6
			c0.3-0.1,0.6-0.1,0.9,0.1l19.1,9.2c0.3,0.2,0.5,0.4,0.6,0.7s0,0.5-0.2,0.6l-16.1,8.9C-347.3,639.9-347.7,639.9-348.1,639.7z
			 M-349.6,622.2l2.5,15.4l13.7-7.6L-349.6,622.2z" />
          </g>
          <g id="XMLID_261_">
            <g id="XMLID_262_">
              <polygon id="XMLID_1_" fill={st1} points="-339.7,634.6 -347.8,639.1 -349.3,630 			" />
            </g>
          </g>
        </g>
      </SvgIcon>
    )
  }
};