import React, { Component } from 'react';
import KonvaStage from "./KonvaStage";
import Axios from "../ajax/Axios";

class FieldsAndResults extends Component<any> {
  state = {
    action: null,
    points: [],
    page: '',
    remoteFields: [],
    valuesValue: '',
    minSizeLine: 50,
    resultPos: 'default'
  }
  fields = [];
  typeTimeout;
  axios: Axios;
  values = [];

  constructor(props) {
    super(props);
    this.axios = new Axios(props.userName, props.edition);
  }

  reset = () => {
    this.setState({
      points: [],
      valuesValue: ''
    })
  }

  clearSameFields = (fields) => {
    for (let j = 0; j < fields.length; j++) {
      for (let i = j + 1; i < fields.length; i++) {
        if (Math.abs(fields[j].x - fields[i].x) < 3 && Math.abs(fields[j].y - fields[i].y) < 3) {
          fields.splice(i, 1);
          i--;
        }
      }
    }
    return fields;
  }

  setSavedLines = (response) => {
    let remoteFields = [];
    if (response.data) {
      remoteFields = response.data.fields;
    }
    remoteFields = this.clearSameFields(remoteFields);
    this.setState({
      remoteFields: [...remoteFields]
    })
  }

  refreshRemoteFields = () => {
    this.axios.jsonRequest(`image_${this.state.page}_1`, this.setSavedLines);
  }

  render() {
    const { points, action, remoteFields, minSizeLine, resultPos } = this.state;
    const { edition } = this.props;
    return (
      <>
        <div className={'fixed-header'}>
          <div
            className={'container'}
            style={{ height: 100 }}
          >
            <span>Page: </span>
            <input
              onChange={(e) => {
                if (this.typeTimeout) {
                  clearTimeout(this.typeTimeout);
                }
                const page = e.target.value;
                this.typeTimeout = setTimeout(() => {
                  this.setState({
                    page
                  }, () => {
                    this.axios.jsonRequest(`image_${this.state.page}_1`, this.setSavedLines);
                  });
                }, 1000);
              }}
              // value={this.state.page}
              style={{ width: 50 }}
            />
            <span>&nbsp;</span>
            <button
              onClick={() => {
                this.setState({
                  action: 'line'
                }, () => {
                  this.setState({
                    action: null
                  }, () => {
                    this.reset();
                  })
                })
              }}
            >
              PRAVA LINIJA
            </button>
            <span>&nbsp;</span>
            <button
              onClick={() => {
                this.setState({
                  action: 'shape'
                }, () => {
                  this.setState({
                    action: null
                  }, () => {
                    this.reset();
                  })
                })
              }}
            >
              KONTURA
            </button>
            <span>&nbsp;</span>
            <span>Values: </span>
            <input
              onChange={(e) => {
                this.values = e.target.value.split('/');
                this.setState({
                  valuesValue: e.target.value
                })
              }}
              style={{ width: 300 }}
              value={this.state.valuesValue}
            />
            <span>&nbsp;</span>
            <button
              onClick={() => {
                const points = [...this.state.points];
                this.fields = this.fields.map((item) => {
                  points.every((pointItem, index) => {
                    if (pointItem.x >= item.x && pointItem.x <= item.x + item.width && pointItem.y >= item.y && pointItem.y <= item.y + item.height) {
                      if (this.values[index]) {
                        item.result = this.values[index];
                      }
                      if (item.textAlign === 'center') {
                        item.resultCorrection = {
                          pos: this.state.resultPos === 'default' ? 'i' : this.state.resultPos,
                          size: 's'
                        };
                      } else {
                        item.resultCorrection = {
                          pos: this.state.resultPos === 'default' ? undefined : this.state.resultPos,
                          size: 's'
                        };
                      }
                      return false;
                    }
                    return true;
                  })
                  return { ...item };
                })
              }}
            >
              SET VALUES
            </button>
            <span>&nbsp;</span>
            <button
              onClick={() => {
                this.reset();
              }}
            >
              RESET
            </button>
            <span>&nbsp;</span>
            <button
              onClick={() => {
                this.axios.postRequest({
                  action: 'saveKonvaJSON',
                  name: `image_${this.state.page}_1`,
                  konvaJSON: JSON.stringify({
                    fields: [...this.fields]
                  }, null, 2)
                }, this.refreshRemoteFields)
              }}
            >
              SAVE
            </button>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span>Min Size Line: </span>
            <input
              onChange={(e) => {
                this.setState({
                  minSizeLine: parseInt(e.target.value)
                });
              }}
              style={{ width: 50 }}
              defaultValue={50}
            />
            <span>&nbsp;</span>
            <span>Result Pos: </span>
            <select
              name="resultPos"
              id="rpId"
              onChange={(e)=>{
                this.setState({
                  resultPos: e.target.value
                })
              }}
            >
              <option value="default">Default</option>
              <option value="">Right</option>
              <option value="i">Inside</option>
              <option value="b">Bottom</option>
              <option value="t">Top</option>
              <option value="l">Left</option>
            </select>
            <br/>
            <br/>
            <table
              // @ts-ignore
              border={1}
              width={"100%"}
              height={50}
            >
              <tr>
                {points.map((item) => {
                  return (
                    <td>{`${item.x} , ${item.y}`}</td>
                  )
                })}
              </tr>
            </table>
          </div>
        </div>
        <div className={'container'}>
          <KonvaStage
            img={`images/${edition}/No${this.state.page}/${this.state.page}.png`}
            action={action}
            points={[...points]}
            remoteFields={[...remoteFields]}
            onClick={(e) => {
              let stateObj = [...this.state.points];
              stateObj.push({
                x: parseInt(e.currentTarget.pointerPos.x),
                y: parseInt(e.currentTarget.pointerPos.y)
              })
              this.setState({
                points: stateObj
              })
            }}
            onChange={(fields) => {
              this.fields = [...fields];
            }}
            minSizeLine={minSizeLine}
            resultPos={resultPos}
          />
        </div>
      </>
    );
  }
}

export default FieldsAndResults;
