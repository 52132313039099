import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_28_1 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 198.5 179.5"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1}
              fill={this.state.color32 ? this.state.color32 : 'transparent'} onClick={() => {
          this.setState({ color32: color, opacity32: opacity });
        }} d="M124.5,61.7c-2.8-2.1-5.3-4-7.9-6c-1.7-1.3-3.2-2.8-4.9-4c-1.6-1.1-3.3-2.1-5-3c-2.2-1.2-4.8-1.7-6.9-3.3
	c-2.2-1.6-5-2.1-7.7-2.4c-2.3-0.2-4.6,0-7,0c1.3-1.3,2.6-2.6,3.7-4c1.6-1.9,3-3.9,4.5-5.8c0.6-0.8,1.4-1.4,2-2.2
	c1.2-1.4,2.3-2.8,3.5-4.1c1.4-1.6,2.9-3,4.2-4.6c1.5-1.9,3.5-3.1,5.6-3.9c1.7-0.7,3.5-1.4,5.1-2.2c5.9-2.7,12.1-2.7,18.4-2.9
	c1.5,0,3.1-0.4,4.5-0.9c2.1-0.7,4.1-1.6,6.2-2.3c4.5-1.5,8.8-1,12.9,1.6c0.7,0.4,1.4,0.7,2.1,1.2c1.1,0.6,1.1,1.1,0.1,1.9
	c-1.6,1.1-3.4,2.2-4.3,4c-1.1,2.1-2.4,4.1-3.3,6.2c-1.8,4.3-4.7,7.4-8.6,9.7c-5.3,3.1-9.6,7.4-12.3,13c-1.6,3.2-2.7,6.7-3.9,10.1
	C125.2,59,124.9,60.3,124.5,61.7z M108.4,38.7c3.1-0.2,5.1-2.8,4.2-5.4c-0.2-0.6-1-1.4-1.6-1.5c-0.7-0.1-1.7,0.2-2.3,0.7
	c-0.8,0.6-0.9,1.6-0.3,2.5c0.6,1.1,1.7,1.1,2.9,0.7C110.8,37.3,109.7,38.1,108.4,38.7z M120.4,34.5c0.3-1.6,1.5-2.1,2.8-2.8
	c-3,0.2-4.7,2.2-4.4,4.8c0.2,1.5,1.3,2.5,2.7,2.2c1.4-0.2,2.3-1.5,2-2.9C123.3,34.5,122.3,34,120.4,34.5z M114.2,36.3
	c1.1,0,2,0,3.1,0C116.5,35.6,115,35.6,114.2,36.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M173.4,85.3c-0.6-0.5-1-1-1.6-1.3c-1.2-0.6-2.6-1-3.8-1.7c-4.3-2.2-8.9-2.4-13.6-2.4c-0.4,0-1-0.3-1.1-0.6
	c-1-2.2-2.2-4.2-4.5-5.6c0.5-0.4,0.8-0.8,1.2-1c2.8-1.4,4.6-3.7,6.2-6.2c1.9-3,3.5-6.2,5.4-9.1c1.2-2,2.7-3.8,4.2-5.6
	c2.6-3.2,5.9-5.7,9.3-8c3.4-2.3,7-4.4,11.2-4.9c1.9-0.2,4,0.1,5.9,0.5c2.5,0.5,3.8,4,2.5,6.3c-0.8,1.5-1.8,2.8-2.8,4.2
	c-1.9,2.8-3.9,5.6-5.7,8.5c-2.3,3.8-4.5,7.7-6.4,11.7c-1.8,3.6-3.1,7.4-4.7,11.1C174.5,82.6,174,83.8,173.4,85.3z M165.2,65.8
	c-1,0-3.6,3.1-3.9,4.9c0.9,0,1.7,0,2.9,0c-0.4,0.8-0.6,1.4-0.8,1.9c0.7,0,1.6,0,2.5,0c-1-0.9-1-1.5-0.1-2c-0.2-0.7-0.5-1.4-0.6-2
	C165.1,67.7,165.2,66.7,165.2,65.8z M172.4,66.2c2,0.7,1.1,2.1,1.2,3.1c0,1.1,0.6,2.4-0.7,3.3c1,0,1.9,0,2.9,0
	c-1.5-0.9-0.7-2.1-0.8-3.2c-0.1-1.2,0-2.4,0-3.7C174,65.9,173.3,66,172.4,66.2z M170.4,70.3c0-0.1,0-0.2,0-0.4c-0.9,0-1.9,0-2.8,0
	c0,0.1,0,0.2,0,0.4C168.6,70.3,169.5,70.3,170.4,70.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M71.9,44.9c0-0.5,0-1,0-1.6c0-0.6,0-1.2,0.1-1.8c0,0,0.6-0.1,0.7,0c0.4,0.5,0.8,1.1,0.9,1.7
	c0.1,0.5-0.2,1-0.2,1.5c0,0.4-0.1,1,0.1,1.3c0.8,1.6,1.9,2,4.2,0.8c4.4-2.2,9-2.2,13.6-2c3.2,0.1,6.4,1.1,8.7,3.8
	c0.6,0.7,1.5,1,2.2,1.6c2.9,2.7,5.1,5.9,6.5,9.6c0.4,1.2,0.4,2.6,0.2,3.8c-0.4,2.1-0.8,4.3-2.6,5.8c-1.3,1.1-2.7,2.1-4.2,2.9
	c-0.5,0.3-1.3-0.1-2-0.1c-2.6-0.3-5.1-0.8-7.7-1C90.3,71.1,88,71,85.8,71c-1.2,0-2.4,0.2-3.5,0.4c-1.6,0.2-1.9-0.1-2.2-1.7
	c-0.2-1.3-0.5-2.7-0.8-4c-0.5-2.1-1.1-4.1-1.4-6.2c-0.5-2.8-1.6-5.4-3.2-7.8c-0.3-0.4-0.5-0.9-0.7-1.4c-0.6-1.1-1.4-1.9-2.6-2.4
	c-0.7-0.3-1.3-0.7-2-1c-0.5-0.2-1.1-0.2-1.6-0.2c-0.8,0-1.7,0.1-2.5-0.1c-1.5-0.3-2.4-1.7-2.4-3.2c0.1-1,1.5-2.4,2.6-2.7
	c1.9-0.5,3.2,0.3,4.2,1.8c0.5,0.8,1.1,1.5,1.6,2.3C71.5,45,71.7,44.9,71.9,44.9z M94.9,57.5c0.2-0.1,0.3-0.4,0.5-0.4
	c0.5-0.1,1.2-0.4,1.5-0.2c0.4,0.2,0.6,1,0.6,1.4c-0.2,0.7-0.6,1.5-1,2.1c-0.5,0.8-1.2,1.4-2,2.4c0.9,0,1.6,0,2.3,0
	c0.7,0,1.4,0,2.2,0c0.1-0.6,0.2-1,0.3-1.3c-1,0.1-1.9,0.2-2.8,0.3c0.6-1.1,1.4-1.8,1.9-2.7c0.4-0.7,0.5-1.7,0.2-2.4
	c-0.1-0.4-1.1-0.8-1.8-0.9C95.7,55.8,95.1,56.5,94.9,57.5z M85.2,58.3c-0.1-1,0.3-1.5,1.3-1.3c0.3,0,0.7,0.1,0.9,0
	c0.3-0.1,0.6-0.4,0.9-0.6c-0.1-0.1-0.1-0.2-0.2-0.3c-1,0-2,0-3.1,0c-0.2,1.1-0.3,2.1-0.5,3.1c0.4,0,0.7-0.2,0.9-0.1
	c0.6,0.3,1.4,0.5,1.7,1c0.3,0.5,0.1,1.5-0.2,1.8c-0.3,0.3-1.2,0-1.8,0c-0.3,0-0.6,0.1-0.9,0.1c0.2,0.3,0.3,0.6,0.6,0.7
	c1.2,0.5,3.1-0.4,3.7-1.8c0.5-1.3-0.2-2.6-1.6-2.7C86.3,58.2,85.7,58.3,85.2,58.3z M93.4,60.1c-1.1,0-2.1,0-3.1,0
	C91,60.9,92.5,60.9,93.4,60.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M139.5,113.9c2.1,1.9,4,3.8,6.1,5.6c1.3,1.2,2.9,2,4.3,3.2c1,0.8,1.9,1.7,2.6,2.8c1.2,1.9,1.6,4.1,0.5,6.2
	c-1,1.9-2,3.9-3.2,5.7c-1.4,2-3.5,3.2-5.7,3.8c-2.1,0.6-4.4,0.9-6.6,1.3c-2.2,0.4-4.3-0.1-6.2-0.8c-2.7-1-5.5-1.6-7.8-3.4
	c-1.2-1-2.4-2-3.6-3.1c-0.1-0.1-0.1-0.7,0.1-0.9c1.4-1.8,3.2-3.4,4.2-5.3c2.2-4.4,6.3-6.8,9.7-10c1.7-1.5,3.4-3,5.1-4.5
	C139.1,114.1,139.5,113.9,139.5,113.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M82.5,93.6c-0.2-3.9-0.3-7.7-0.6-11.6c-0.2-2.5-0.6-5.1-1-7.6c-0.1-0.8,0-1,0.8-1.2c3.5-0.8,7-0.4,10.4-0.1
	c2.4,0.2,4.9,0.4,7.3,0.8c3.1,0.6,5.6,2.3,7.5,4.9c0.7,1,1.4,1.9,2,2.9c1.9,2.9,1.1,5.8-0.2,8.5c-1.5,3-3.8,5.3-6.6,7.2
	c-0.6,0.4-1.5,0.6-2.3,0.7c-2,0.2-3.9,0.8-5.9,0.3c-0.6-0.2-1.3-0.1-1.9,0c-2.7,0.3-5.4,0.6-8,0.8c-1.3,0.1-1.7-0.3-1.8-1.7
	c0-1.4,0-2.7,0-4.1C82.3,93.6,82.4,93.6,82.5,93.6z M95.9,85.5c0.1,0.1,0.1,0.1,0.2,0.2c0.1-0.1,0.3-0.3,0.4-0.3
	c0.5-0.1,1.2-0.4,1.6-0.2c0.3,0.2,0.6,1,0.5,1.4c-0.2,0.8-0.7,1.6-1.2,2.3c-0.5,0.7-1.1,1.3-1.8,2.1c1.6,0,2.9,0,4.4,0
	c0.1-0.5,0.2-1,0.3-1.2c-1,0.1-1.9,0.2-3.1,0.4c1-1.2,1.8-2.1,2.5-3.1c0.6-0.9,0.4-2.1-0.4-2.7c-0.8-0.6-2-0.5-2.7,0.3
	C96.3,84.9,96.1,85.2,95.9,85.5z M89.9,85c-1.2,2.2-2.2,4-3.2,5.9c0.8,0.2,1.2,0,1.5-0.7c0.6-1.6,1.4-3.2,2.1-4.7
	c0.2-0.4,0.4-0.9,0.6-1.5c-1.3,0-2.3,0.1-3.2,0c-1.3-0.1-1.5,0.5-1.4,1.3C87.3,85.3,88.4,85.2,89.9,85z M94.5,88.7
	c-0.8-0.9-2.4-0.8-3,0C92.5,88.7,93.4,88.7,94.5,88.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity33 ? this.state.opacity33 : 1}
              fill={this.state.color33 ? this.state.color33 : 'transparent'} onClick={() => {
          this.setState({ color33: color, opacity33: opacity });
        }} d="M147.6,107.6c0.5-0.3,1-0.6,1.6-0.8c3.4-1.1,6.9-2.1,10.3-3.3c3.7-1.3,7-3.3,10-5.9c0.9-0.8,1.6-1.7,2.5-2.8
	c0.6,1.6,1.1,2.8,1.5,4c1.7,5.7,5.7,9.9,9,14.6c1.3,1.8,2.5,3.6,2.5,5.9c0,2.2-1.9,5.1-4,5.9c-2.8,1-5.5,0.3-8.2-0.2
	c-2.8-0.5-5.2-1.8-7.4-3.5c-1.7-1.3-3-3.1-4.4-4.7c-3-3.2-5.9-6.5-9.9-8.3c-0.6-0.3-1.3-0.3-2-0.4c-0.4-0.1-0.8-0.1-1.3-0.2
	C147.6,107.9,147.6,107.8,147.6,107.6z M164.9,106.4c0-0.1-0.1-0.2-0.1-0.3c-0.7,0.3-1.5,0.4-2.2,0.8c-1.6,0.9-2.3,2.8-1.9,4.5
	c0.3,1.4,1.6,2.2,3,1.8c1.3-0.4,2.1-1.9,1.5-3.2c-0.2-0.4-0.5-0.9-0.8-1c-0.7-0.2-1.4-0.2-2.2-0.2
	C162.6,107.5,163.7,106.9,164.9,106.4z M171.1,107.3c0.7,0,1.8-0.3,2,0c1,1.4-0.9,1.4-1.3,2.2c0.1,0,0.2,0.1,0.3,0.1
	c0.8-0.1,1.5,0.2,1.6,1c0.1,0.6,0,1.4-0.4,1.7c-0.3,0.3-1.1-0.1-1.7-0.1c-0.3,0-0.6,0.1-0.9,0.2c0.2,0.3,0.4,0.7,0.7,0.8
	c1.1,0.4,2.9-0.5,3.4-1.6c0.5-1.1,0.2-1.9-0.9-2.7c0.2-0.7,0.6-1.3,0.4-1.8c-0.1-0.4-0.9-0.7-1.4-0.8
	C172.1,106.1,171.4,106.6,171.1,107.3z M169.8,111.1c-0.4-0.9-1.6-1-2.8-0.2C167.8,111,168.7,111.1,169.8,111.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M80.2,144.6c2,0.1,3.9,0.3,5.7,0.3c1.3,0,2.7-0.3,4-0.4c0.3,0,0.7,0,1,0c2.1,0.5,4.2,0.7,6.3,0.3
	c1.7-0.4,3.3-0.8,5-1.1c1.6-0.3,3.3-0.5,5.3-0.7c0.3,1.6,0.6,3.3,0.8,5c0.4,3.1,0.6,6.3,0.9,9.4c0.3,2.4,2.3,3.8,3.8,5.4
	c0.1,0.1,0.3,0.2,0.5,0.4c0.6,0.8,1.6,1.6,1,2.6c-0.4,0.6-1.4,1.1-2.3,1.3c-3.6,0.8-7.2,0.2-10.8-0.2c-2.5-0.3-4.5-1.4-6.6-2.5
	c-3.1-1.6-5.5-4-7.7-6.5c-1.3-1.5-2.3-3.3-3.3-5C82.2,150.3,81,147.6,80.2,144.6z M90.3,154.2c-1.3,1.1-1.5,2-0.8,2.9
	c0.8,1,2.7,0.9,3.7,0c0.9-0.9,0.7-2.1-0.6-3.4c1-1.1,1.2-1.9,0.5-2.5c-0.7-0.7-2.3-0.7-3.1,0.1C89.1,152,89.2,152.9,90.3,154.2z
	 M100.3,154c0.4,0,0.6-0.1,0.8-0.1c0.8-0.1,1.6,0.1,1.8,0.9c0.2,0.6,0.1,1.4-0.2,1.9c-0.5,0.8-1.3,0.3-1.9,0
	c-0.2-0.1-0.5,0.1-0.8,0.1c0.2,0.3,0.3,0.7,0.5,0.8c1,0.5,2.8-0.1,3.4-1.3c0.3-0.6,0.5-1.6,0.2-2.1c-0.3-0.5-1.2-0.8-1.8-1.1
	c-0.4-0.1-0.9,0-1.3,0c-0.2-1.4,0.6-1.4,1.5-1.4c0.6,0,1.5,0.3,1.5-1c-1.1,0-2.1,0-3.2,0C100.6,151.9,100.4,152.8,100.3,154z
	 M95.4,154.8c0,0.2,0,0.3,0,0.5c0.9,0,1.9,0,2.8,0c0-0.1,0-0.3,0-0.4C97.3,154.9,96.4,154.8,95.4,154.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity40 ? this.state.opacity40 : 1}
              fill={this.state.color40 ? this.state.color40 : 'transparent'} onClick={() => {
          this.setState({ color40: color, opacity40: opacity });
        }} d="M78.5,122.9c0.6-2.5,0.9-4.9,1.6-7.1c1-3.1,1.3-6.3,1.5-9.5c0.1-1.3,0.2-2.6,0.5-3.9c0.1-0.3,0.5-0.8,0.8-0.9
	c3.3-0.5,6.7-1.2,10-1.4c2.2-0.2,4.5,0.2,6.7,0.4c1,0.1,1.8,0.7,2.4,1.6c0.7,1.1,2.1,1.8,2.6,2.9c1.5,2.9,2.2,5.9,0.8,9.1
	c-1.2,2.6-3.3,4.2-5.9,5.2c-2.1,0.7-4.2,1.4-6.4,1.9c-1.6,0.3-3.4,0.1-5.1,0.3c-2.4,0.3-4.8,0.9-7.3,1.3
	C80.2,122.8,79.4,122.8,78.5,122.9z M88,111c0.2,0.8,0.2,1.5,0.5,2.2c0.3,0.9,1,1.5,2,1.5c1,0,1.7-0.6,2-1.5
	c0.5-1.5,0.6-3.1-0.2-4.5c-0.3-0.6-1.2-1.1-1.8-1.1c-0.6,0-1.4,0.6-1.8,1.1C88.2,109.4,88.2,110.3,88,111z M99.3,110.8
	c0.4,0,0.7-0.1,0.9,0c0.6,0.3,1.4,0.5,1.7,1c0.2,0.5,0.1,1.5-0.3,1.8c-0.3,0.3-1.2,0-1.8,0c-0.3,0-0.5,0.1-0.8,0.1
	c0.1,0.3,0.2,0.7,0.4,0.8c0.9,0.5,2.6,0,3.3-1.1c0.4-0.6,0.6-1.7,0.3-2.3c-0.3-0.6-1.3-0.8-2-1.1c-0.3-0.1-0.7,0-1.1,0.1
	c-0.2-1.4,0.6-1.4,1.5-1.4c0.6,0,1.5,0.4,1.5-0.9c-1,0-2.1,0-3.2,0C99.6,108.8,99.4,109.8,99.3,110.8z M86.2,114.5
	c-0.2-0.5-0.5-0.9-0.5-1.4c-0.1-1.8,0-3.6,0-5.5c-0.8,0.2-1.5,0.3-2,0.5c0.3,0.4,0.7,0.8,0.7,1.2c-0.2,1.7,0.6,3.6-0.6,5.3
	c0.5,0,1.2,0,1.8,0C85.9,114.6,86.2,114.5,86.2,114.5z M97.2,112.4c0-0.2,0-0.5,0-0.7c-0.8,0-1.7,0-2.5,0c-0.1,0-0.3,0.2-0.3,0.3
	c0,0.2,0.2,0.4,0.3,0.4C95.6,112.4,96.4,112.4,97.2,112.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1}
              fill={this.state.color36 ? this.state.color36 : 'transparent'} onClick={() => {
          this.setState({ color36: color, opacity36: opacity });
        }} d="M124.1,104.3c1.5-1.2,3.2-2.2,4.5-3.6c1.6-1.6,3.3-3.2,4.2-5.5c0.2-0.5,0.9-1,1.5-1.2c3.2-0.9,6.4-1.6,9.6-2.4
	c0.4-0.1,0.8-0.3,1.2-0.5c2.8-1.2,6.7,1.2,6.7,4.6c0,3.5-1.2,6.6-3.6,9.2c-3.4,3.8-7.9,6.3-11.7,9.6c-2.5,2.2-5,4.3-7.5,6.5
	c-1.4,1.2-2.8,2.5-4.1,3.7c-1.3-1.3-1.3-2.8,0-4.1c1.5-1.6,3.1-3.2,3.7-5.4c1-3.4,0.4-7.7-3.1-10C125,105.1,124.6,104.7,124.1,104.3
	z M136.1,99.1c0.9-0.7,1.4-1.4,0.6-2.4c-0.7-0.8-2.3-0.8-3.2,0c-0.9,0.8-0.8,1.7,0.4,3c-1.4,1.2-1.6,2.1-0.7,3
	c0.9,0.9,2.7,0.8,3.6-0.1C137.7,101.6,137.5,100.5,136.1,99.1z M147.2,95.9c-1.8,1.2-2.6,3.2-3.9,5c1.2,0.1,2.1,0.1,3.3,0.2
	c-0.4,0.8-0.6,1.3-0.8,1.8c0.7,0,1.6,0,2.3,0c-0.2-0.4-0.5-0.9-0.5-1.3c0.1-0.4,0.5-0.7,0.8-1C146.5,99.4,148,97.5,147.2,95.9z
	 M141.6,100.4c-1.1,0-2,0-3,0C139.4,101.2,140.8,101.2,141.6,100.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity39 ? this.state.opacity39 : 1}
              fill={this.state.color39 ? this.state.color39 : 'transparent'} onClick={() => {
          this.setState({ color39: color, opacity39: opacity });
        }} d="M67.3,140.5c0.6-0.5,1-0.9,1.4-1.3c2.7-2.7,4.6-6,6.2-9.4c0.8-1.6,1.7-3,2.6-4.5c0.1-0.1,0.3-0.3,0.5-0.3
	c2.4-0.5,4.7-1.2,7.1-1.6c2.5-0.3,5.1-0.4,7.6-0.3c0.9,0,1.9,0.8,2.5,1.5c0.9,0.9,1.5,2.1,2.1,3.2c1,1.6,0.7,3.3,0.4,5
	c-0.8,3.8-2.6,7-5.9,9.2c-1.9,1.2-3.8,2-6.2,1.5c-4.7-0.8-9.5-1.3-14.3-2C70,141.4,68.8,140.9,67.3,140.5z M75.6,138.3
	c3.2,0,5.3-2.8,4.4-5.5c-0.6-1.7-2.5-2.2-3.9-0.9c-0.7,0.7-1,1.9-0.5,2.7c0.6,0.9,1.3,1.2,2.4,0.8c0.1,0,0.3-0.1,0.5-0.1
	C78.2,136.8,77.7,137.3,75.6,138.3z M90.3,131.3c-1,0.1-3.3,2.9-4,4.8c0.9,0,1.8,0.1,3,0.1c-0.3,0.8-0.6,1.3-0.8,1.8
	c0.7,0,1.6,0,2.5,0c-1-0.9-1-1.4-0.1-2c-0.2-0.7-0.5-1.3-0.6-1.9C90.3,133.3,90.3,132.3,90.3,131.3z M84.9,135.9
	c-1.2-0.8-2.6-0.7-3,0C82.8,135.9,83.7,135.9,84.9,135.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity37 ? this.state.opacity37 : 1}
              fill={this.state.color37 ? this.state.color37 : 'transparent'} onClick={() => {
          this.setState({ color37: color, opacity37: opacity });
        }} d="M111.5,82.7c2.8,0,5.5-0.1,8.2,0c1.6,0.1,3.4-0.1,4.8,1.1c1.1,0.9,2.2,1.9,3.2,3c1,1.1,2.1,2.1,2.9,3.5
	c1.6,2.7,0.7,5.2-0.8,7c-1.4,1.7-3.1,3.1-4.8,4.6c-0.6,0.5-1.6,0.8-2.4,1c-2.9,0.6-5.9,1.5-8.9,1.7c-2.1,0.1-4.4,0.7-6.5-0.4
	c-0.2-0.1-0.5-0.3-0.7-0.5c-0.9-1-1.8-1.9-2.6-2.9c-0.5-0.5-0.8-1.1-1.3-1.8C108,94.8,111.9,90,111.5,82.7z M115.4,90.8
	c-0.1-0.1-0.1-0.3-0.2-0.4c-0.9,0.4-1.9,0.7-2.7,1.3c-1.3,1-1.8,3-1.3,4.4c0.5,1.3,1.8,1.9,3.1,1.4c1.2-0.5,1.9-2.1,1.2-3.3
	c-0.7-1.2-1.7-1.2-3-0.7C113,92,114.1,91.3,115.4,90.8z M125.2,97.6c-1.4-0.9-0.7-2.2-0.8-3.3c-0.1-1.2,0-2.3,0-3.6
	c-0.9,0.2-1.5,0.4-2.4,0.6c1.5,0.5,1.6,5.3,0.4,6.4C123.2,97.6,124.1,97.6,125.2,97.6z M120.6,94.9c-1.1,0-2.1,0-3.1,0
	C118.2,95.7,119.7,95.7,120.6,94.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M110.4,81c-1.1-3.8-4.3-5.4-6.9-7.8c4.2-2.1,6.8-5.4,7.5-10.1c0.7-4.8-2.5-8.1-4.7-11.8
	c0.1-0.1,0.2-0.2,0.3-0.3c1.1,0.8,2.3,1.5,3.4,2.3c3.2,2.4,6.4,4.8,9.6,7.3c1.4,1.1,2.9,2.2,4.2,3.5c2.9,3,5.3,6.3,5.2,10.8
	c0,2.1-1.5,3.2-3,4.1c-3.1,1.8-6.3,2.9-10,2.3C114.2,81,112.3,81.1,110.4,81z M112.3,72.2c0.8-0.6,1.3-1.4,0.5-2.4
	c-0.7-0.8-2.2-0.8-3.1-0.1c-0.9,0.8-0.8,1.7,0.3,3.1c-1.3,1.1-1.5,2-0.7,2.9c0.8,0.9,2.7,0.9,3.6,0
	C113.9,74.7,113.7,73.6,112.3,72.2z M122.9,71.9c0.2-0.7,0.7-1.3,0.5-1.8c-0.2-0.4-0.9-0.8-1.5-0.9c-0.9-0.1-1.6,0.4-1.9,1.4
	c0.7-0.5,1.6-1.2,2.1-0.2c0.7,1.3-0.9,1.4-1.5,2.1c0.1,0.1,0.2,0.1,0.2,0.1c0.8-0.1,1.5,0,1.7,0.9c0.1,0.6,0.1,1.3-0.2,1.8
	c-0.5,0.8-1.3,0.3-1.9,0c-0.2-0.1-0.5,0.1-0.8,0.2c0.2,0.3,0.3,0.6,0.6,0.7c1.1,0.5,3-0.4,3.6-1.6C124.4,73.4,124.1,72.7,122.9,71.9
	z M117.8,73.9c0-0.1,0-0.2,0.1-0.3c-0.9,0-1.8,0-2.8,0c0,0.1,0,0.2,0,0.3C116,73.9,116.9,73.9,117.8,73.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity38 ? this.state.opacity38 : 1}
              fill={this.state.color38 ? this.state.color38 : 'transparent'} onClick={() => {
          this.setState({ color38: color, opacity38: opacity });
        }} d="M96.4,122.2c1.4-0.7,2.7-1.4,4.2-1.9c4.2-1.4,8.1-7.7,7.2-12.1c-0.1-0.5-0.3-1-0.4-1.6c1.3,0,2.5-0.1,3.7,0
	c2.4,0.2,4.7-0.3,7.1-0.7c1.6-0.3,3.4-0.5,4.8,0c1.3,0.4,2.4,1.8,3.2,3c2.2,3.4,1.1,6.7-1.1,9.7c-1.3,1.8-3.1,2.8-5,3.6
	c-2,0.8-4,1.5-6.1,2.2c-2,0.6-4.1,1.2-6.1,1.7c-0.9,0.2-1.8,0.4-2.6,0.5c-1.7,0.2-3.5,0.3-5.2,0.4c-0.3,0-0.8-0.2-0.9-0.4
	C98.2,125.1,97.4,123.7,96.4,122.2z M118.7,114.7c0.2,0.7,0.2,1.4,0.4,2.1c0.3,1,1,1.6,2,1.5c1,0,1.8-0.6,1.9-1.5
	c0.2-1.4,0.2-2.8,0-4.2c-0.1-0.9-1-1.4-2-1.4c-1,0-1.6,0.5-1.9,1.4C119,113.2,118.9,114,118.7,114.7z M111.5,113.8
	c0.2-0.7,0.7-1.5,0.5-1.8c-0.3-0.5-1.1-0.8-1.8-0.9c-0.9-0.1-1.3,0.4-1.6,1.3c1.1-0.7,1.8-0.8,2.1-0.2c0.6,1.4-0.9,1.4-1.6,2.2
	c0.3,0,0.5-0.1,0.7,0c0.5,0.3,1.2,0.6,1.4,1c0.2,0.5,0,1.3-0.3,1.7c-0.2,0.3-1,0-1.6,0c-0.3,0-0.7,0.1-1,0.1
	c0.2,0.3,0.4,0.7,0.7,0.8c1.1,0.4,2.9-0.5,3.5-1.6C112.9,115.4,112.6,114.7,111.5,113.8z M117.4,115.8c-1-0.8-2.5-0.7-3.1,0
	C115.3,115.8,116.2,115.8,117.4,115.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M150.7,104.4c0.2-0.7,0.3-1,0.4-1.3c0.6-1.8,1.4-3.6,1.9-5.5c0.8-2.8-0.2-5.3-2-7.5c-0.5-0.6-1.3-0.8-2.1-1.2
	c2.5-1.6,3.8-3.9,4.6-6.6c0.1-0.3,0.6-0.8,0.9-0.8c2.8,0.2,5.6,0.3,8.3,0.8c1.7,0.3,3.3,1.2,4.9,1.9c1.1,0.5,2.1,1,3,1.6
	c3.1,2,3.2,5.2,1.1,7.9c-3.7,4.5-8.4,7.2-13.9,8.9C155.6,103.2,153.3,103.7,150.7,104.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity35 ? this.state.opacity35 : 1}
              fill={this.state.color35 ? this.state.color35 : 'transparent'} onClick={() => {
          this.setState({ color35: color, opacity35: opacity });
        }} d="M124.7,81.3c4.6-2.5,7.8-5.6,5.8-11.6c1.1,0.5,2,1.1,2.9,1.3c2.6,0.7,5.1,1.3,7.7,1.9c4.4,1,8.1,2.9,10,7.3
	c1,2.3,0,4.2-1.2,6c-1.2,1.8-2.9,2.8-5,3.4c-3,0.8-6.1,1.6-9.1,2.4c-1.5,0.4-2.1-0.1-2.8-1.5c-1.1-1.9-2.4-3.7-3.8-5.4
	c-1.1-1.3-2.1-2.9-4-3.4C125,81.8,124.8,81.5,124.7,81.3z M141.6,80.8c0.2,0.8,0.2,1.5,0.4,2.1c0.3,0.9,1,1.6,2,1.6
	c1.1,0,1.7-0.6,2-1.6c0.5-1.5,0.6-3-0.2-4.3c-0.3-0.6-1.2-1.2-1.8-1.2c-0.6,0-1.4,0.6-1.8,1.2C141.8,79.3,141.8,80.2,141.6,80.8z
	 M131.4,80.7c0.5,0,0.8-0.2,1-0.1c0.6,0.3,1.3,0.6,1.6,1.1c0.2,0.4,0.1,1.4-0.2,1.7c-0.4,0.3-1.2,0-1.8,0c-0.3,0-0.6,0.1-0.9,0.1
	c0.2,0.3,0.4,0.7,0.6,0.8c1.3,0.5,3.2-0.6,3.6-1.9c0.5-1.3-0.3-2.5-1.8-2.6c-0.5,0-1,0-1.5,0.1c-0.2-1.2,0.4-1.5,1.4-1.4
	c0.6,0,1.6,0.4,1.6-0.9c-1.1,0-2.1,0-3.2,0C131.7,78.6,131.6,79.6,131.4,80.7z M140,82.1c0-0.1,0-0.2,0-0.4c-0.9,0-1.8,0-2.7,0
	c0,0.1,0,0.2,0,0.4C138.2,82.1,139.1,82.1,140,82.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M114,137.4c-1.7,1.1-3.3,2.4-5.1,3.1c-2.4,0.9-5,1.2-7.5,1.7c-1.8,0.3-3.5,0.5-5.3,0.6
	c-0.8,0.1-1.6-0.1-2.7-0.3c2.3-2.2,3.9-4.4,4.8-7c0.6-1.6,0.8-3.3,1.1-5c0.2-0.9,0.3-1.7,1.6-1.7c2,0,4-0.3,6-0.7
	c1.9-0.4,3.8-1.1,5.7-1.6c1.4-0.4,2.8-0.6,4.2-1c1.3-0.5,2.6-1.2,3.9-1.7c0.4-0.2,1.2-0.3,1.4-0.1c0.4,0.4,0.7,1.1,0.7,1.7
	c0,1.3,0.5,2.7-0.5,3.8c-1.3,1.5-2.7,2.9-4.1,4.4c-0.2,0.2-0.3,0.4-0.5,0.6C116.8,135.7,115.6,136.9,114,137.4
	c-1.1-0.3-1.2-1.1-1.2-2.1c0.1-1.6,0-3.2,0-4.9c-0.8,0.2-1.5,0.3-2,0.4c0.3,0.6,0.6,1,0.6,1.5c0.1,1.1,0.1,2.3,0,3.5
	c0,0.5-0.3,1-0.5,1.6C112,137.4,113,137.4,114,137.4z M100.6,133c0.1-1.4,0.1-1.4,1.6-1.5c1.3,0,1.3,0,1.3-0.9c-1,0-2,0-3.1,0
	c-0.1,1-0.3,2-0.4,3c0.4,0,0.6-0.1,0.8,0c0.6,0.3,1.4,0.5,1.7,1c0.3,0.5,0.1,1.4-0.2,1.9c-0.5,0.7-1.3,0.3-1.9,0
	c-0.2-0.1-0.5,0.1-0.8,0.1c0.2,0.3,0.3,0.7,0.5,0.8c1.1,0.4,2,0,2.9-0.7c0.9-0.7,1.3-1.6,0.8-2.7c-0.4-1.1-1.4-1.3-2.5-1.1
	C101.1,132.9,100.9,132.9,100.6,133z M108.8,135.1c0-0.1,0-0.2,0-0.4c-0.9,0-1.8,0-2.7,0c0,0.1,0,0.2,0,0.4
	C107,135.1,107.9,135.1,108.8,135.1z" />
        <path
          d="M108.8,135.1c-0.9,0-1.8,0-2.7,0c0-0.1,0-0.2,0-0.4c0.9,0,1.8,0,2.7,0C108.8,134.8,108.8,134.9,108.8,135.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1}
              fill={this.state.color32 ? this.state.color32 : 'transparent'} onClick={() => {
          this.setState({ color32: color, opacity32: opacity });
        }} d="M111.5,34.5c-0.2,0.2-0.6,0.7-0.9,0.7c-1,0-1.4-0.8-1.3-1.7c0.1-0.5,0.6-1.4,0.9-1.4
	C111.4,32.3,111.3,33.4,111.5,34.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1}
              fill={this.state.color32 ? this.state.color32 : 'transparent'} onClick={() => {
          this.setState({ color32: color, opacity32: opacity });
        }} d="M120.1,35.7c0.2-0.2,0.5-0.6,0.8-0.7c0.8-0.1,1.4,0.7,1.3,1.9c0,0.6-0.1,1.5-0.9,1.4
	C120.2,38.1,120.3,37,120.1,35.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }}
              d="M163.9,67.6c0,0.8,0,1.5,0,2.4c-0.5,0-1.1,0-1.8,0c0.5-0.9,1-1.7,1.5-2.5C163.7,67.5,163.8,67.5,163.9,67.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity33 ? this.state.opacity33 : 1}
              fill={this.state.color33 ? this.state.color33 : 'transparent'} onClick={() => {
          this.setState({ color33: color, opacity33: opacity });
        }} d="M164.3,111.6c-0.3,0.5-0.7,0.9-1,1.4c-0.4-0.4-0.8-0.8-1.1-1.2c-0.1-0.1-0.1-0.4-0.1-0.6
	c0.1-0.5-0.4-1.4,0.6-1.5c0.9-0.1,1.2,0.5,1.4,1.3c0,0.2,0,0.3,0.1,0.5C164.1,111.4,164.2,111.5,164.3,111.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity40 ? this.state.opacity40 : 1}
              fill={this.state.color40 ? this.state.color40 : 'transparent'} onClick={() => {
          this.setState({ color40: color, opacity40: opacity });
        }} d="M91.5,111.1c-0.1,0.8-0.3,1.7-0.5,2.5c-0.1,0.2-0.4,0.5-0.6,0.5c-0.3,0-0.6-0.3-0.7-0.5c-0.5-1.7-0.5-3.4,0-5.1
	c0.1-0.2,0.4-0.6,0.6-0.6c0.2,0,0.6,0.3,0.6,0.6C91.3,109.4,91.4,110.3,91.5,111.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1}
              fill={this.state.color36 ? this.state.color36 : 'transparent'} onClick={() => {
          this.setState({ color36: color, opacity36: opacity });
        }} d="M133.5,101.4c0.5-0.6,0.8-1.2,1.2-1.4c0.5-0.2,1.6,0.9,1.4,1.6c-0.1,0.5-0.7,0.8-1,1.2
	C134.6,102.4,134.1,102,133.5,101.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1}
              fill={this.state.color36 ? this.state.color36 : 'transparent'} onClick={() => {
          this.setState({ color36: color, opacity36: opacity });
        }}
              d="M135.5,98.9c-0.9-0.4-1.7-0.8-1.3-1.8c0.1-0.2,0.8-0.5,1-0.4C136.2,97.2,136,98,135.5,98.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1}
              fill={this.state.color36 ? this.state.color36 : 'transparent'} onClick={() => {
          this.setState({ color36: color, opacity36: opacity });
        }}
              d="M146.2,97.8c0,0.8,0,1.5,0,2.4c-0.5,0-1,0-1.8,0c0.6-0.9,1.1-1.7,1.6-2.5C146,97.8,146.1,97.8,146.2,97.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity39 ? this.state.opacity39 : 1}
              fill={this.state.color39 ? this.state.color39 : 'transparent'} onClick={() => {
          this.setState({ color39: color, opacity39: opacity });
        }} d="M78.9,134.1c-0.2,0.2-0.5,0.7-0.8,0.7c-1,0-1.4-0.8-1.3-1.6c0.1-0.5,0.6-1.4,0.9-1.4
	C78.8,131.9,78.7,132.9,78.9,134.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity39 ? this.state.opacity39 : 1}
              fill={this.state.color39 ? this.state.color39 : 'transparent'} onClick={() => {
          this.setState({ color39: color, opacity39: opacity });
        }}
              d="M89,135.6c-0.6,0-1,0-1.8,0c0.6-0.9,1.1-1.7,1.6-2.5c0.1,0,0.1,0.1,0.2,0.1C89,133.9,89,134.7,89,135.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity37 ? this.state.opacity37 : 1}
              fill={this.state.color37 ? this.state.color37 : 'transparent'} onClick={() => {
          this.setState({ color37: color, opacity37: opacity });
        }} d="M112.3,94.7c0.2-0.2,0.5-0.7,0.8-0.7c0.8-0.1,1.4,0.7,1.3,1.9c0,0.6-0.1,1.5-0.9,1.4
	C112.4,97.1,112.5,95.9,112.3,94.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M112.8,74.8c-0.8,0.5-1.2,1.1-1.7,1c-0.9,0-1.2-0.8-1.1-1.5c0.1-0.4,0.5-0.8,0.8-1.3
	C111.4,73.5,112,74,112.8,74.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }}
              d="M111.7,72c-1-0.4-1.8-0.7-1.4-1.8c0.1-0.3,0.8-0.6,1-0.4C112.4,70.2,112.2,71.1,111.7,72z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity38 ? this.state.opacity38 : 1}
              fill={this.state.color38 ? this.state.color38 : 'transparent'} onClick={() => {
          this.setState({ color38: color, opacity38: opacity });
        }} d="M122.2,114.7c-0.1,0.9-0.2,1.7-0.4,2.5c-0.1,0.2-0.4,0.6-0.6,0.6c-0.2,0-0.6-0.4-0.6-0.6c0-1.7,0-3.4,0-5.1
	c0-0.2,0.4-0.5,0.7-0.5c0.2,0,0.5,0.3,0.6,0.5C122,113,122.1,113.8,122.2,114.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity35 ? this.state.opacity35 : 1}
              fill={this.state.color35 ? this.state.color35 : 'transparent'} onClick={() => {
          this.setState({ color35: color, opacity35: opacity });
        }} d="M142.9,81c0.1-0.9,0.2-1.7,0.4-2.4c0.1-0.3,0.4-0.6,0.7-0.7c0.1,0,0.6,0.4,0.6,0.6c0,1.7,0,3.4,0,5.1
	c0,0.2-0.4,0.5-0.6,0.5c-0.3,0-0.6-0.3-0.7-0.5C143.2,82.7,143.1,81.8,142.9,81z" />
      </SvgIcon>
    )
  }
};