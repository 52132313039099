import { Component } from "react";
import React from "react";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import { ACTION_TOOLTIPS } from "../util/APP_CONSTANS";

export default class AudioPlayer extends Component<any, any> {
  width = 50;
  height = 50;
  padding = 15;
  state = {
    play: false,
    trackProgress: 0
  }
  audio;
  intervalRef;

  constructor(props) {
    super(props);
    const { page, number, edition } = props;
    const url = `images/${edition}/No${page}/${page}_${number}.mp3`;
    this.audio = new Audio(url);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (!this.props.isActive) {
      this.audio.pause();
      clearInterval(this.intervalRef);
    }
  }


  componentDidMount() {
    this.audio.addEventListener('ended', () => this.setState({ play: false }));
  }

  componentWillUnmount() {
    this.audio.removeEventListener('ended', () => this.setState({ play: false }));
    this.audio.pause();
    clearInterval(this.intervalRef);
  }

  togglePlay = () => {
    this.setState({ play: !this.state.play }, () => {
      this.state.play ? this.audio.play() : this.audio.pause();
      if (this.state.play) {
        this.startTimer();
      }
    });
  }

  onScrubEnd = () => {
    // If not already playing, start
    if (!this.state.play) {
      this.setState({ play: true }, () => {
        this.audio.play();
        this.startTimer();
      });
    } else {
      this.startTimer();
    }
  }

  onScrub = (value) => {
    clearInterval(this.intervalRef);
    this.audio.currentTime = value;
    this.setState({ trackProgress: this.audio.currentTime });
  };

  startTimer = () => {
    // Clear any timers already running
    clearInterval(this.intervalRef);

    this.intervalRef = setInterval(() => {
      if (this.audio.ended || this.audio.paused) {
        clearInterval(this.intervalRef);
      } else {
        this.setState({ trackProgress: this.audio.currentTime });
      }
    }, 500);
  };

  render() {
    const played = this.state.play;
    const { trackProgress } = this.state;
    let border = 'none';
    const borderRadius = `${this.height / 2}px`;
    let backgroundColor = 'transparent';
    const { duration } = this.audio;
    const currentPercentage = duration
      ? `${(trackProgress / duration) * 100}%`
      : "0%";
    const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
  `;
    return (
      <Box
        display="flex"
        flexDirection="column"
        width={100}
      >
        <IconButton
          color={"inherit"}
          style={{ backgroundColor, width: `${this.width}px`, height: `${this.height}px`, border, borderRadius }}
          onClick={this.togglePlay}
        >
          {!this.props.isActive &&
          <Tooltip title={ACTION_TOOLTIPS.AUDIO_PLAYER}>
            <img
              src={"images/icons/microphone.png"}
              style={{ width: `${this.width - this.padding}px`, height: `${this.height - this.padding}px` }}
              onClick={() => {
                this.props.onPlay(this.props.number);
              }}
            />
          </Tooltip>
          }
          {this.props.isActive && !played &&
          <img
            src={"images/icons/play.png"}
            style={{ width: `${this.width}px`, height: `${this.height}px` }}
          />
          }
          {this.props.isActive && played &&
          <img
            src={"images/icons/pause.png"}
            style={{ width: `${this.width}px`, height: `${this.height}px` }}
          />
          }
        </IconButton>

        {this.props.isActive &&
        <input
          type="range"
          value={trackProgress}
          step="1"
          min="0"
          max={duration ? duration : `${duration}`}
          className="progress"
          onChange={(e) => this.onScrub(e.target.value)}
          onMouseUp={() => {
            this.onScrubEnd();
          }}
          onKeyUp={() => {
            this.onScrubEnd();
          }}
          style={{ background: trackStyling }}
        />
        }
      </Box>
    );
  }
}
