import React, { Component } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import {
  FormatColorFill
} from "@material-ui/icons";
import {
  changeColor
} from "../actions";
import { connect } from 'react-redux'
import IconButtonCorrect from "./IconButtonCorrect";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ReactDOM from 'react-dom';
import { appColors } from "../App";
import { ACTION_TOOLTIPS } from "../util/APP_CONSTANS";


class ColorMenuButton extends Component<any, any> {
  state = {
    anchorEl: null
  }

  private iconDom: any;

  private handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  private handleClose = () => {
    this.setState({ anchorEl: null });
  };

  private getIconButton = (containerType: string) => {
    const { disabled, drawables, color } = this.props;
    let iconColor = drawables.color ? drawables.color : 'white';
    let IconButton = null;
    if (containerType === 'tbar') {
      IconButton = (<Tooltip title={ACTION_TOOLTIPS.CHANGE_COLOR}>
        <IconButtonCorrect
          disabled={disabled}
          color={iconColor}
          onClick={this.handleClick}
        >
          <FormatColorFill />
        </IconButtonCorrect>
      </Tooltip>);
    } else {
      iconColor = drawables.showDrawables ? color : appColors.icon.drawing.primary;
      IconButton = (
        <ListItemIcon
          ref={(el: any) => {
            this.iconDom = ReactDOM.findDOMNode(el);
          }}
          style={{ color: iconColor }}
          className={"correctMinWidth"}
        >
          <FormatColorFill />
        </ListItemIcon>
      );
    }
    return IconButton;
  }

  render() {
    const { dispatch, containerType, colorPalette } = this.props;
    const anchorEl = colorPalette ? this.iconDom: null;
    return (
      <>
        {this.getIconButton(containerType)}
        <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          id="customized-menu"
          anchorEl={this.state.anchorEl || anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl || anchorEl)}
          onClose={this.handleClose}
          style={{ marginTop: 10 }}
        >
          <MenuItem
            onClick={() => {
              this.handleClose();
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <Box
                  bgcolor="black"
                  p={2}
                  onClick={() => dispatch(changeColor("black"))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  bgcolor="blue"
                  p={2}
                  onClick={() => dispatch(changeColor("blue"))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  bgcolor="red"
                  p={2}
                  onClick={() => dispatch(changeColor("red"))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  bgcolor="green"
                  p={2}
                  onClick={() => dispatch(changeColor("green"))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  bgcolor="orange"
                  p={2}
                  onClick={() => dispatch(changeColor("orange"))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  bgcolor="yellow"
                  p={2}
                  onClick={() => dispatch(changeColor("yellow"))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  bgcolor="brown"
                  p={2}
                  onClick={() => dispatch(changeColor("brown"))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  bgcolor="pink"
                  p={2}
                  onClick={() => dispatch(changeColor("pink"))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  bgcolor="silver"
                  p={2}
                  onClick={() => dispatch(changeColor("silver"))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  bgcolor="gold"
                  p={2}
                  onClick={() => dispatch(changeColor("gold"))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  bgcolor="aqua"
                  p={2}
                  onClick={() => dispatch(changeColor("aqua"))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  bgcolor="white"
                  p={2}
                  onClick={() => dispatch(changeColor("white"))}
                />
              </Grid>
            </Grid>
          </MenuItem>

        </Menu>
      </>
    );
  }
}

const getVisibleDrawables = (drawables) => {
  return drawables;
}

const mapStateToProps = state => (
  {
    drawables: getVisibleDrawables(state.drawables)
  }
)

export default connect(
  mapStateToProps
)(ColorMenuButton)
