import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_7_4 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 195.1 151"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M160.1,98.5c0,3.2,0,6.4,0,9.5c-1.8,0.5-2,0.3-2.1-1.2c-0.1-1.6-0.4-3.2-0.5-4.9c-0.1,0-0.2,0-0.3,0
	c0,0.3-0.1,0.6-0.1,0.9c0,1.5,0,3,0,4.4c0,0.7-0.2,1-1,1c-0.8,0-0.9-0.3-1-1c0-1.9-0.1-3.8-0.2-5.6c0-0.8-0.2-1.6-0.4-2.3
	c-0.1-0.2-0.4-0.4-0.6-0.6c-0.1,0.3-0.4,0.5-0.4,0.8c0,0.7,0.2,1.4,0.2,2.1c0,2.2,0,4.3,0,6.6c-0.4,0-0.8,0-1.2,0
	c-0.1-2.2-0.2-4.4-0.4-6.6c-0.1-0.7-0.2-1.4-0.4-2.1c-0.1-0.3-0.5-0.5-0.7-0.8c-0.1,0.3-0.4,0.6-0.4,0.9c0.2,2.1,0.4,4.2,0.5,6.3
	c0.1,0.7,0.1,1.4,0.1,2.1c-1.6,0.5-1.6,0.4-1.7-1.1c-0.1-2.3-0.4-4.5-0.6-6.8c0-0.3-0.4-0.6-0.7-1c-0.1,0.4-0.4,0.7-0.4,1.1
	c0.1,2.3,0.3,4.6,0.4,6.9c0,0.5,0,1-0.8,1.1c-0.8,0-1.1-0.2-1.1-1.1c-0.1-2.2-0.2-4.5-0.3-6.7c0-0.4-0.1-0.8-0.2-1.4
	c-1.1,0.5-1,1.3-1,2.2c0.1,2,0.2,4.1,0.3,6.1c0,0.3-0.3,0.6-0.4,1c-0.2-0.3-0.5-0.6-0.6-1c-0.4-2.6-0.8-5.2-1.1-7.8
	c0-0.1-0.1-0.3-0.4-0.4c-0.5,1.5-0.3,2.9-0.1,4.4c0.2,1.5,0.4,3.1,0.6,4.6c-1.6,0.4-1.6,0.3-1.7-1c-0.2-2-0.6-3.9-0.9-5.9
	c-0.1-0.8,0.1-1.7,0-2.5c0-0.4-0.5-0.7-0.7-1c-0.2,0.3-0.6,0.7-0.6,1c0.1,0.7,0.4,1.4,0.6,2c0.1,0.2,0.2,0.4,0.2,0.7
	c0.2,1.7,0.3,3.5,0.4,5.2c0.2,1.9,0,2-2.4,1.8c0-0.4-0.1-0.7-0.1-1.1c0-2.2,0.1-4.3,0.1-6.5c0-0.3,0.1-0.7-0.1-0.9
	c-0.1-0.2-0.4-0.4-0.6-0.6c-0.2,0.2-0.4,0.5-0.4,0.7c0,1.7,0.2,3.4,0.2,5.1c0,1-0.3,2.1-0.5,3.1c0,0.1-0.5,0.3-0.7,0.3
	c-0.2-0.1-0.5-0.4-0.5-0.6c-0.1-0.9-0.1-1.8-0.1-2.6c-0.2-2.1-0.4-4.1-0.6-6.2c0-0.2-0.2-0.4-0.3-0.7c-1,0.2-0.9,0.8-0.8,1.5
	c0.2,2.4,0.4,4.9,0.5,7.3c0,0.6,0.2,1.4-0.9,1.4c-1.1,0-1.1-0.6-1.1-1.4c0-2.9,0-5.7,0-8.6c-0.1-0.1-0.3-0.1-0.4-0.2
	c-0.2,0.5-0.6,0.9-0.6,1.4c-0.1,1.2,0,2.4-0.1,3.6c-0.1,1.6-0.2,3.2-0.3,4.7c-1.5,0.3-1.7,0.2-1.7-1.1c-0.1-2.5-0.1-5-0.2-7.6
	c0-0.4-0.3-0.8-0.5-1.2c-0.2,0.4-0.6,0.9-0.6,1.3c0,2.4,0.1,4.7,0.1,7.1c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.3,0.5-0.4,0.4
	c-0.2-0.1-0.5-0.3-0.5-0.5c-0.1-0.6-0.2-1.2-0.2-1.8c-0.1-2.2-0.1-4.3-0.1-6.5c0-0.4-0.2-0.8-0.3-1.2c-0.1,0-0.3,0.1-0.4,0.1
	c0,3.1,0,6.3,0,9.5c-1,0.2-1.3-0.1-1.3-0.9c0-2.8,0-5.6,0-8.4c-0.2,0-0.5,0-0.7,0c-0.6,2.6,0.2,5.4-0.6,8.1
	c-0.8-0.5-1.2-1.2-1.3-2.2c-0.2-2.2-0.6-4.4-0.4-6.6c0.1-2.4,0.5-4.9,1.2-7.2c1.5-4.9,2.6-9.9,3.5-15c0.2-0.9,0.4-1.9,0.7-2.8
	c0.7-2.4,0.5-4.8-0.6-7.1c-0.8-1.7-0.7-3.6-0.2-5.5c0.3-1.4-0.1-2.9-0.3-4.3c-0.4-3-0.9-6-1.1-9c-0.1-1.3,0.3-2.7,0.2-4
	c-0.1-1.7-0.2-3.5-0.6-5.2c-0.4-1.4-0.3-2.6,0.5-3.7c0.4-0.5,0.7-1,1.1-1.7c1,1.1,1.9,2.1,2.8,3.2c1.3-1.8,2.6-3.4,4-5.3
	c0.8,1.2,1.4,2.2,2,3.2c0.7,1.1,1,1.1,1.7,0c1-1.4,2-2.9,3-4.3c-0.1-0.1-0.2-0.2-0.4-0.3c-1,1.5-2,2.9-3.2,4.6c-0.6-1-1-1.8-1.6-2.6
	c-1-1.6-1.4-1.6-2.5-0.1c-0.9,1.2-1.9,2.4-3.1,3.9c-0.8-1.1-1.6-2.1-2.2-3.1c-0.5-0.8-0.9-0.7-1.3-0.1c-0.4,0.6-0.7,1.3-1.2,2
	c-0.1,0.2-0.6,0.1-0.8,0.3c-0.2,0.1-0.6,0.5-0.5,0.6c1,1.7,0.2,3.1-0.7,4.5c-0.4,0.7-0.8,1.4-1.3,2c-0.7,0.9-1.3,0.8-1.8-0.2
	c-0.2-0.4-0.4-0.8-0.6-1.1c-0.2-0.3-0.5-0.6-0.8-1.2c-0.5,0.5-0.9,1-1.2,1.5c-0.4,0.6-0.6,1.2-1,1.8c-0.5,0.9-1.2,0.9-1.8,0
	c-0.4-0.6-0.8-1.2-1.2-1.8c-0.7-1.1-1.6-1.1-2.3-0.1c-0.4,0.5-0.7,1.1-1.1,1.6c-0.2,0.2-0.6,0.4-0.9,0.4c-0.2,0-0.5-0.3-0.6-0.5
	c-0.6-1.4-1.3-2.8-2-4.5c-0.9,0.7-1.8,1.3-2.7,2c-0.2-3,2.9-4,3.6-6.6c0.8,1.3,1.3,2.2,1.9,3.2c0.6,1.1,1.2,1.1,1.8,0.1
	c0.4-0.7,0.7-1.4,1-2.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.5,0.7-1,1.4-1.6,2.2c-0.7-1.4-1.4-2.7-2.1-4c-1,0.9-2,1.9-3.2,2.9
	c-0.4-3.3,1.2-5.3,3.5-7.5c0.3,1.2,0.5,2.2,0.8,3.1c0.1,0.4,0.4,0.9,0.6,1c0.3,0.1,0.8-0.2,1.1-0.5c0.9-1.2,1.7-2.4,2.7-3.8
	c0.7,1.6,1.3,3,1.9,4.3c0.2,0.4,0.6,0.6,0.9,0.9c0.3-0.3,0.6-0.7,0.9-1c0.7-1.2,1.3-2.4,2-3.8c1.2,1.6,2.2,3,3.3,4.4
	c0.4,0.4,1,0.7,1.5,1c0.3-0.4,0.6-0.9,0.7-1.3c0.5-1.4,1-2.7,1.6-4.5c0.9,1.2,1.6,2.2,2.3,3.2c0.3,0.3,0.8,0.9,1,0.8
	c0.4-0.1,0.7-0.6,0.9-1.1c0.7-1.3,1.3-2.7,2.1-4.4c1.3,1.2,2.5,2.1,3.6,3.2c0.8,0.8,1.2,0.9,1.8-0.1c0.8-1.4,1.6-2.8,2.3-4.2
	c1.3,1.2,2.6,2.3,3.7,3.6c0.8,0.8,1.4,0.8,2.1,0.1c1-1,2-2,3-3c0.3-0.3,0.6-0.6,1-1.1c1,1.4,2.9,1.7,3.5,3.5c0.2,0.7,1.2,1,1.8,0.4
	c1.1-1.1,2-2.3,3.2-3.7c-1.8,0.2-2.5,1.8-3.9,2.5c-1.3-1.3-2.5-2.5-3.8-3.8c-0.7-0.6-1.2-0.2-1.7,0.3c-1,1.1-2.1,2.2-3.1,3.4
	c-0.6,0.6-1,0.6-1.6,0c-0.8-0.9-1.7-1.7-2.5-2.6c-1-1.1-2-1-2.8,0.3c-0.7,1.2-1.3,2.3-2.1,3.7c-1.4-1.3-2.6-2.4-3.8-3.6
	c-0.6-0.6-1.1-0.6-1.5,0.3c-0.8,1.4-1.6,2.8-2.4,4.4c-1-1.2-1.9-2.2-2.8-3.2c-0.3-0.3-0.7-0.5-1-0.7c-0.2,0.3-0.4,0.7-0.6,1
	c-0.5,1.5-1,2.9-1.6,4.7c-0.6-0.7-1.1-1-1.3-1.5c-1.3-2.9-3.7-4.9-5.6-7.3c-1.2-1.5-2.3-3.1-3.4-4.7c-0.7-1.1,0-1.6,0.9-1.9
	c4-1.3,8-2.6,12-3.8C127,10,129,9.4,131,9c3.2-0.5,6.4-0.9,9.7-1.3c0.3,1.2,0.5,2.2,0.9,3.1c0.1,0.3,0.7,0.5,1,0.8
	c0.1-0.3,0.2-0.7,0.1-1c-0.2-1.2,0.7-1.6,1.6-2.2c-0.4,0.7-0.7,1.1-0.9,1.6c-0.1,0.2-0.1,0.6,0,0.7c1,0.4,0.5,1,0.2,1.8
	c4.5,2.2,9.1,2.9,13.9,2.9c-1-0.8-2.9,0-2.9-2.5c-0.6,0.7-1,1.1-1.3,1.5c-0.4-1.4,0.9-3,2.5-2.8c3.3,0.5,6.6,0.4,9.9-0.1
	c1.5-0.2,2.9-0.9,4.3-1.5c1.2-0.5,3.5-0.4,4.5,0.5c0.2,0.2,0.3,0.7,0.4,1.1c-0.4,0-0.9,0.1-1.2,0c-1.3-0.8-2.3-1.1-3.2-0.9
	c0.9,0.5,2.1,1.1,3.6,1.9c1.7-0.8,3.9-1.6,4.3-4.3c0-0.2,0.6-0.5,0.8-0.4c1.9,0.2,3.7,0.5,5.6,0.8c3.5,0.6,7.1,1.2,10.6,2
	c3.2,0.8,6.3,2,9.5,3c0.2,0.1,0.4,0.2,0.6,0.4c-0.1,0.2-0.1,0.3-0.1,0.4c-2.2,2.7-2.9,5.8-3,9.2c0,1.6-0.7,3.3-1.8,4.5
	c-0.3-0.7-0.7-1.4-0.9-2.1c-0.5-1.3-1.1-1.5-2.3-0.6c-1.1,0.8-2.2,1.6-3.6,2.6c-0.2-1.2-0.3-2.2-0.4-3.1c-0.2-1.4-0.5-1.6-1.7-0.9
	c-1.3,0.8-2.6,1.7-4,2.7c-0.5-1.2-1.1-2.5-1.6-3.7c-0.5-1.1-0.8-1.3-1.7-0.5c-1.2,1-2.2,2.1-3.4,3.1c-1.2,1-1.4,1-2.3-0.4
	c-0.6-0.9-1.1-1.9-1.7-2.9c-0.2-0.3-0.5-0.6-0.8-0.6c-0.2,0-0.5,0.4-0.7,0.6c-1.2,1.2-2.5,2.4-3.8,3.7c-0.9-1.3-1.9-2.5-2.7-3.9
	c-0.7-1.1-1-1.2-2-0.4c-1.3,1.2-2.6,2.4-3.8,3.6c-0.3,0.3-0.5,0.8-0.7,1.2c-2-1.2-2.2-3.5-3.9-4.5c-0.1,0.1-0.3,0.2-0.4,0.3
	c0.7,1.1,1.3,2.2,2,3.3c0.4,0.6,0.7,1.2,1.1,1.7c0.2,0.2,0.6,0.4,0.9,0.5c0.1-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.5,0.3-0.6
	c1.6-1.5,3.2-2.9,4.9-4.4c1.1,1.5,2.1,2.9,3,4.3c0.5,0.8,1.1,0.9,1.8,0.2c1.1-1.1,2.3-2.1,3.4-3.3c1,1.6,2,3,3,4.5
	c2.3-0.9,4.1-2.6,5.9-4.7c0.7,1.8,1.3,3.2,1.8,4.7c0.3,1,0.5,1,1.2,0.4c1.4-1.2,2.8-2.4,4.5-3.8c0,1.7,0,3.3,0,5
	c0.5,0.3,1,0.4,1.7-0.2c1.2-1,2.5-1.8,3.8-2.7c1.5,1.5,1.7,3.1,0.7,4.4c0.1,0,0.2,0,0.3,0c0,0.6,0.2,1.3,0,1.8
	c-0.6,1.6-1.3,3.1-2,4.6c-0.5,1.2-1,2.3-1.5,3.5c-0.5,1.1-0.8,2.3-2,2.9c-0.5,0.3-0.6,1.4-0.9,2.1c-0.5,1.6-1,3.1-1.3,4.7
	c-0.2,1.2-0.2,2.4-0.1,3.7c0,1.2,0.2,2.3,0.4,3.4c0.1,1.2,0.2,2.3,0.9,3.4c0.6,1,0.4,2.5,0.5,3.7c0.3,3.7,0.8,7.3,0.7,11
	c0,5.5,1.6,10.6,2.7,15.9c0.3,1.4-0.2,3-0.5,4.6c-0.2,1-0.7,1.9-0.8,2.8c-0.1,1.2-0.1,2.5,0,3.7c0.1,1.2,0.4,2.4,0.7,3.6
	c-1.3,0.6-1.6,0.6-1.9-0.8c-0.6-2.9-1.8-5.8-1-8.9c-0.1,0-0.3,0-0.4-0.1c-0.1,0.3-0.3,0.6-0.3,1c-0.1,1.9-0.3,3.8-0.3,5.7
	c0,0.9,0.3,1.9,0.4,2.8c0,0.3-0.1,0.5-0.2,0.8c-0.2-0.2-0.6-0.3-0.7-0.6c-1.4-2.5-1.5-5.2-1.5-8c0-0.4-0.4-0.8-0.6-1.2
	c-0.2,0.4-0.5,0.9-0.5,1.3c0.1,1.9,0.2,3.8,0.4,5.7c0.1,0.9,0.4,1.8,0.6,2.8c-0.9,0.3-1.3,0.2-1.3-0.8c-0.1-2.2-0.2-4.5-0.4-6.7
	c-0.1-0.7-0.6-1.3-0.9-1.9c-0.2,0.7-0.5,1.4-0.5,2c0.1,2.3,0.3,4.5,0.4,6.8c0,0.3,0,0.5-0.1,0.8c-0.1,0-0.2,0.1-0.3,0.1
	c-0.1-0.4-0.3-0.8-0.3-1.2c-0.2-2.4-0.4-4.9-0.7-7.3c0-0.4-0.4-0.7-0.6-1.1c-0.2,0.4-0.6,0.8-0.6,1.1c0.1,2.4,0.3,4.8,0.5,7.2
	c0,0.4,0.1,0.7,0.2,1.2c-0.4,0-0.8,0.1-1.3,0.1c-0.3-3.1-0.5-6.1-0.8-9.1c-0.2,0-0.3,0-0.5,0c-0.1,0.5-0.2,1-0.2,1.5
	c0.1,2.5,0.3,5.1,0.4,7.7c-0.8,0-1.5,0-2.4,0c0.7-3,0.5-6,0.1-9c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0.5-0.5,1-0.6,1.5
	c-0.1,0.7,0.1,1.4,0.1,2.1c-0.1,1.8-0.2,3.7-0.3,5.5c0,0.1-0.2,0.1-0.4,0.4c-0.2-0.7-0.5-1.3-0.6-1.9c-0.3-2.2-0.5-4.4-0.7-6.7
	c0-0.4-0.4-0.7-0.6-1.1c-0.1,0.4-0.4,0.8-0.4,1.1c0.2,2.7,0.5,5.4,0.7,8.1c0,0.1,0,0.2-0.1,0.4c-0.5,0-1.1,0.1-1.6,0
	c-0.4-0.1-0.8-0.5-0.9-0.8c-0.3-1.2-0.4-2.5-0.5-3.8c-0.2-1.3-0.4-2.7-0.7-4c-0.1-0.3-0.6-0.6-1-0.9c-0.1,0.4-0.3,0.9-0.3,1.2
	c0.4,2.7,0.8,5.4,1.2,8.2c-0.7,0-1.4,0.1-2.3,0.1c-0.1-3.1-0.3-6.1-1.6-8.9c-0.2,0-0.3,0-0.5,0c0,0.5-0.1,1.1,0,1.6
	c0.4,2.1,0.8,4.1,1.2,6.2c0.2,0.8-0.2,1.3-1.1,1.2c-0.1,0-0.2,0-0.4,0c-2.1,0.1-2,0.1-1.7-2c0.1-0.9,0.2-1.8,0.1-2.6
	c-0.2-1.5-0.6-3-1.2-4.6c-0.1,0.3-0.4,0.7-0.4,1c0,0.6,0.2,1.2,0.2,1.8c0,2.1,0,4.2,0,6.3c-1.5,0.3-1.6,0.2-1.6-1.1
	c-0.1-2.7-0.3-5.3-0.4-8c0-0.3-0.2-0.6-0.3-0.9c-0.1,0-0.2,0-0.3,0c-0.1,0.7-0.4,1.4-0.4,2.1c0,2.2,0.1,4.5,0.1,6.7
	c0,0.5,0.4,1.3-0.6,1.3c-1,0-0.9-0.7-0.9-1.3c-0.1-2.1-0.1-4.2-0.1-6.2c0-0.7-0.3-1.5-0.4-2.2C160.4,98.5,160.2,98.5,160.1,98.5z
	 M189.4,41.4c0.1-1.5,0.2-2.7,0.2-4c0-0.4-0.2-0.8-0.3-1.2c-0.4,0.1-1,0.1-1.3,0.4c-0.9,1-1.7,2.2-2.5,3.3c-0.2,0.3-0.5,0.5-0.8,0.5
	c-0.3,0-0.7-0.3-0.8-0.6c-0.4-1.4-0.6-2.8-0.9-4.2c-0.1-0.4-0.1-0.8-0.2-1.4c-0.5,0.4-0.8,0.6-1,0.9c-0.9,0.9-1.8,1.9-2.6,2.8
	c-0.8,0.8-1.4,0.7-1.9-0.1c-0.8-1.4-1.6-2.8-2.5-4.3c-0.2,0.1-0.5,0.2-0.6,0.3c-1.1,1.4-2.3,2.9-3.4,4.3c-1.1,1.4-1.7,1.3-2.6-0.2
	c-0.9-1.5-1.9-3-2.9-4.6c-1.1,1.4-2.1,2.7-3.1,4c-0.9,1.2-1.9,1.2-2.8,0c-0.8-1.2-1.6-2.3-2.7-3.8c-1,1.6-1.9,2.9-2.6,4.3
	c-0.6,1.1-1.6,1.7-2.2,1.1c-0.9-0.9-1.5-1.9-2.3-2.9c-0.6-0.8-1.1-1.6-1.8-2.6c-1.2,1.6-2.2,3-3,4.4c-0.6,1-1.5,1-2.3,0.1
	c-1-1.2-2-2.4-2.9-3.6c-0.4-0.5-0.6-0.5-1,0.1c-0.9,1.6-1.9,3.1-2.8,4.7c-0.2,0.3-0.5,0.5-0.8,0.8c-0.3-0.3-0.7-0.6-0.9-1
	c-0.6-0.9-1-1.9-1.7-2.8c-0.5-0.7-1.1-0.7-1.5,0.1c-0.5,1-0.8,2.1-1.3,3.2c-0.2,0.4-0.4,0.8-0.7,1.1c-0.4-0.3-0.8-0.6-1.1-1
	c-0.2-0.3-0.4-0.7-0.5-1.1c-0.2-0.3-0.4-0.7-0.5-1c-0.1,0.1-0.2,0.1-0.3,0.2c0.7,1.7,1.3,3.4,3,4.8c0.8-1.7,1.6-3.2,2.4-4.9
	c0.9,1.1,1.5,2,2.3,2.9c0.8,0.9,1.7,0.7,2.2-0.2c0.7-1.4,1.5-2.8,2.3-4.1c0.3-0.6,0.8-0.6,1.3-0.1c1,1.1,2,2.1,2.9,3.3
	c0.9,1.2,1.3,1.2,2.2,0.1c1-1.3,2.1-2.5,3.2-3.8c0.5,0.7,0.9,1.1,1.2,1.5c0.6,0.9,1.2,1.9,1.9,2.7c1.1,1.2,2.1,1.1,3-0.2
	c0.9-1.2,1.8-2.4,2.7-3.6c1.1,1.2,2.2,2.3,3.3,3.4c0.7,0.8,1.4,0.7,2.1-0.1c1-1.3,2.1-2.5,3.3-3.9c0.9,1.5,1.8,2.8,2.5,4.2
	c0.6,1.3,1.5,1.6,2.6,0.7c0.6-0.5,1-1.2,1.4-1.8c0.7-0.8,1.4-1.7,2.2-2.7c1.1,1.5,1.9,2.7,2.8,3.9c0.7,0.9,1.4,0.8,2.1-0.1
	c0.8-0.9,1.6-1.8,2.6-2.8c0.2,0.6,0.4,0.8,0.5,1.1c0.3,1,0.4,2,0.8,2.9c0.5,1.2,1.4,1.1,2.2,0.2c0.6-0.7,1.4-1.3,2.1-2
	c0.1,0.1,0.3,0.1,0.4,0.2c0,0.9,0,1.9,0,2.8c0,0.9,0.9,1.3,1.5,0.7c1-1,1.9-2.1,2.9-3.2c0.1-0.1,0-0.4,0-0.6c-0.2,0.1-0.5,0-0.6,0.2
	c-0.5,0.6-0.9,1.2-1.4,1.8C190.2,41.1,189.7,41.2,189.4,41.4z M157.6,27.6c1.2,1.6,2.2,3,3.2,4.4c0.4,0.6,0.8,0.8,1.3,0.1
	c0.4-0.5,0.9-1,1.4-1.5c0.8-1.1,1.6-2.2,2.4-3.3c-0.1-0.1-0.2-0.2-0.3-0.3c-1.3,1.5-2.6,3-3.8,4.4c-0.9-1.2-1.6-2.2-2.5-3.2
	c-0.9-0.9-1.8-0.9-2.7,0c-1.3,1.4-2.6,2.8-4,4.2c-0.2-0.4-0.4-0.7-0.5-1.1c-0.5-1.3-0.9-2.6-1.4-3.8c-0.3-0.9-0.7-1-1.3-0.3
	c-1.1,1.2-2.1,2.5-3.1,3.8c-0.3,0.4-0.5,0.7-0.8,1.1c-4.8-4.5-4.8-4.5-5.7-4.3c0.1,0.1,0.2,0.3,0.3,0.4c1.5,1.6,3.1,3.1,4.6,4.7
	c0.6,0.6,1,0.4,1.3-0.1c0.6-0.9,1.2-1.9,1.9-2.8c0.5-0.7,1-1.2,1.7-2.1c0.4,1,0.7,1.6,1,2.3c0.4,0.9,0.9,1.8,1.4,2.7
	c0.5,0.9,0.8,0.5,1.3-0.1C154.4,31.2,155.9,29.6,157.6,27.6z M179.2,32.2c-1-1.7-1.8-3.2-2.6-4.6c-0.8,0.9,0.8,4.1,2.6,5.6
	c1.2-1.1,2.4-2.2,3.5-3.3c0.8,1.4,1.5,2.8,2.2,4.2c0.5,1.1,1.2,1,2,0.4c0.9-0.8,1.8-1.6,2.8-2.3c0.6,0.7-0.1,2.1,1.4,2.5
	c0-1.9,0-3.7,0-5.9c-1.8,2.1-3.5,4-5.3,6.2c-0.5-2.1-0.9-3.9-1.4-5.7c-0.1-0.5-0.4-0.9-0.6-1.3c-0.4,0.3-0.8,0.5-1.2,0.9
	C181.4,29.8,180.3,30.9,179.2,32.2z M166.3,27.1c1.1,1.3,2.1,2.6,3.2,3.8c0.3,0.4,0.5,0.8,0.8,1.3c0.1,0.2,0.3,0.6,0.4,0.6
	c0.3,0,0.6-0.2,0.7-0.4c0.6-0.8,1.2-1.6,1.8-2.4c0.7-0.8,1.4-1.6,2.1-2.4c-0.1-0.1-0.3-0.2-0.4-0.4c-1.2,1.3-2.3,2.7-3.5,4
	c-0.2-0.1-0.3-0.2-0.4-0.3c-0.8-1-1.7-2-2.5-3C167.9,27.4,167.5,26.5,166.3,27.1z M164,14.6c0.1,0.1,0.4,0.4,0.7,0.4
	c0.4,0,0.8-0.1,1.3-0.2c2-0.5,3.9-1,5.9-1.5c0.8-0.2,0.7-0.9,0.3-1.4c-0.4-0.7-2.3-1-3.3-0.5c1,0.4,1.8,0.7,2.7,1
	c0,0.1,0,0.3-0.1,0.4c-0.9,0.2-1.8,0.5-2.5,0.7c-0.5-0.4-0.8-0.8-1.1-1.1c-0.1,0.1-0.2,0.2-0.3,0.3c0.1,0.3,0.2,0.6,0.4,1
	C166.7,14,165.4,14.3,164,14.6z M114.1,35.4c-0.3-0.7-0.6-1.1-0.8-1.6c-0.4-0.8-0.7-1.6-1.2-2.3c-0.1-0.2-0.4-0.2-0.7-0.4
	c-0.1,0.2-0.3,0.5-0.3,0.7c0.8,1.4,1.6,2.8,2.5,4.2c0.1,0.1,0.5,0.2,0.6,0.1c1.1-1.2,2.1-2.4,3.1-3.6c-0.2-0.1-0.3-0.3-0.5-0.4
	C116,33.2,115.1,34.2,114.1,35.4z M121.5,35.4c-1-1.6-1.9-2.9-2.8-4.2c-0.1-0.1-0.4-0.2-0.6-0.2c-0.1,0.1-0.2,0.4-0.2,0.6
	C118,32.6,120.1,35,121.5,35.4z M196.7,29.7c-0.2-0.2-0.3-0.3-0.5-0.5c-1.7,1.7-3.6,3.3-4.7,5.8C193.3,33.1,195,31.4,196.7,29.7z
	 M161,15.1c-0.7-0.1-1.5-0.2-2.3-0.2c-0.1,0-0.3,0.4-0.4,0.6c0.2,0.1,0.5,0.4,0.6,0.3C159.7,15.7,160.3,15.4,161,15.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M106,106.3c-0.1,0.5-0.2,0.9-0.2,1.4c0,2.1,0,4.2,0,6.4c0,0.5,0.3,1.3-0.7,1.4c-1.1,0.1-0.8-0.8-0.9-1.3
	c-0.2-2.3-0.3-4.5-0.5-6.8c0-0.4-0.2-0.9-0.4-1.3c-0.5,3.1,0.1,6.1,0.2,9.1c-1,0.3-1.4,0-1.8-0.8c-1-2.3-0.4-4.7-0.5-7
	c0-0.5,0-1-0.1-1.4c0-0.3-0.3-0.5-0.5-0.7c-0.2,0.2-0.4,0.5-0.4,0.7c0,0.7,0,1.4,0,2.2c0.1,2.3,0.3,4.5,0.4,6.8
	c-1.2,0.5-1.8,0-2-0.9c-0.2-1-0.4-2-0.3-3c0.2-3.3,0.3-6.5-0.5-9.7c-0.5-2-0.5-4.1-0.6-6.2c-0.2-4.3-0.3-8.7-0.5-13.1
	c-0.1-2.8-0.6-5.6-0.3-8.4c0.3-3.4,1-6.7,1.7-10c0.3-1.3,1.2-2.5,1.4-3.7c0.4-2.8,0.5-5.6,0.9-8.5c0.3-2.8,1-5.7,1.1-8.5
	c0.1-2.2,1.2-3.2,2.9-4c0.2-0.1,0.4-0.3,0.7-0.6c0.2,0.6,0.4,1.1,0.6,1.6c0.4,1,0.8,2.1,1.3,3.1c0.4,0.8,1.2,0.8,1.9,0
	c0.7-0.8,1.4-1.8,2.2-2.8c0.8,0.9,1.5,1.6,2.2,2.5c1,1.3,1.6,1.2,2.4-0.2c0.5-0.9,1.2-1.6,1.9-2.6c0.6,0.6,1,1.1,1.3,1.5
	c0.7,0.8,1.4,0.7,2-0.1c0.5-0.8,1.1-1.5,1.9-2.5c0.4,2.4,0.9,4.4,0.4,6.5c-0.2,0.9-0.1,1.8-0.2,2.8c-0.1,1.4-0.4,2.8-0.5,4.2
	c-0.1,1.8-0.3,3.7-0.2,5.5c0.1,4.4-0.4,8.7-0.9,13c-0.3,2.4-0.1,4.9-0.3,7.3c-0.5,5.9-1.7,11.7-4,17.2c-0.4,1-0.6,2.2-1,3.2
	c-0.8,1.9-1.4,3.9-2.6,5.6c-1,1.5-1.4,2.8-0.9,4.5c0.3,1.2,0.2,2.4,0.3,3.6c-0.1,0-0.3,0-0.4,0c-0.2-1.6-0.4-3.2-0.7-4.8
	c-0.1-0.5-0.1-1-0.3-1.5c-0.1-0.2-0.4-0.3-0.6-0.5c-0.1,0.2-0.3,0.5-0.3,0.7c0.2,1.6,0.4,3.2,0.7,4.7c0.2,0.9,0.3,1.8,0.6,2.7
	c0.3,1.1-0.1,1.5-1.3,1.5c-0.3-2-0.6-4.1-0.9-6.1c-0.1-0.7-0.2-1.5-0.4-2.2c-0.1-0.3-0.5-0.6-0.7-0.9c-0.1,0.3-0.4,0.7-0.3,1
	c0.4,2.6,0.8,5.3,1.2,7.9c0,0.2,0.1,0.3,0.2,0.8c-0.9,0-1.8,0.1-2.6,0c-0.2,0-0.4-0.6-0.4-0.9c0-2.3,0-4.6,0-6.8
	c0-0.4-0.1-0.9-0.2-1.3C106.3,106.3,106.1,106.3,106,106.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M218.8,105.1c-0.4,0-0.8,0-1.1,0c0,1.6-0.1,3.2,0,4.7c0.1,1.2,0.3,2.3,0.4,3.4c0,0.3,0,0.8-0.1,1
	c-0.2,0.3-0.6,0.5-0.9,0.7c-0.2-0.3-0.5-0.6-0.5-0.9c-0.1-1.8,0-3.6-0.1-5.4c-0.1-1.2-0.4-2.4-0.6-3.5c-0.1-0.3-0.4-0.4-0.7-0.7
	c-0.2,0.3-0.4,0.5-0.5,0.8c-0.1,0.1,0,0.3,0,0.5c0.9,2.4,0.4,5,0.7,7.4c0.1,0.6,0.5,1.7-0.6,1.7c-1,0-0.6-1-0.6-1.6
	c-0.1-2.3-0.1-4.6-0.2-6.8c0-0.6,0.1-1.7-1-1.5c-1,0.2-0.6,1.1-0.5,1.8c0.2,2.6,0.4,5.2,0.6,7.8c-1.1,0.4-1.6,0.2-1.6-0.8
	c-0.1-1.1-0.1-2.2-0.1-3.3c-0.1-1.6-0.2-3.2-0.4-4.8c0-0.3-0.4-0.5-0.6-0.7c-0.2,0.2-0.5,0.5-0.7,0.7c-0.1,0.1,0,0.2,0,0.4
	c0.1,2.3,0.1,4.6,0.3,6.9c0,0.5,0.2,1,0.4,1.5c-1.3,0.3-1.6,0-1.6-1.2c-0.1-2.3-0.1-4.6-0.2-6.8c0-0.4-0.4-0.9-0.7-1.3
	c-0.2,0.4-0.7,0.9-0.7,1.3c0,2.2,0.2,4.5,0.2,6.7c0,0.4-0.3,1-0.6,1.1c-0.7,0.2-0.9-0.3-0.9-0.9c0-1.2,0-2.3,0-3.5
	c0-1.6,0-3.2,0-4.9c-1,0.3-1.2,0.7-1.1,1.4c0.2,1.6,0.2,3.2,0,4.9c-0.4-2.7-0.8-5.3-1.3-8c-0.4-2.6-0.8-5.1-1.3-7.7
	c-0.2-0.9-0.9-1.7-1.1-2.7c-0.6-3-1-6-1.6-8.9c-0.7-3.4-1.4-6.7-2.2-10c-1.1-4.4-1.2-8.8-1.1-13.3c0-1.4-0.1-2.8-0.4-4.1
	c-0.6-3.3-1.3-6.5-1.8-9.8c-0.1-0.8,0.1-1.6,0.4-2.4c0.7-1.9,1.5-3.8,2.2-5.7c0.1-0.3,0.3-0.6,0.6-1.3c0.8,1.6,1.5,2.9,2.3,4.2
	c0.5,0.8,1.3,1.5,2.1,2.1c0.5,0.4,1.1,0.1,1.4-0.6c0.5-1.1,1.2-2.2,1.8-3.3c0.9,1.1,1.7,2.1,2.6,3c0.8,0.8,1.2,0.7,1.7-0.3
	c0.5-1,1.1-1.9,1.8-3.2c0.6,1.1,1.1,1.9,1.4,2.7c0.7,1.5,1.5,1.7,2.9,0.5c0.2,1,0.6,2,0.7,2.9c0.4,3.6,0.7,7.3,1.1,10.9
	c0.2,1.8,0.4,3.6,0.8,5.3c0.3,1.3,1.1,2.4,1.5,3.7c0.8,3,1.6,6,2,9.1c0.3,2.2,0,4.6,0.1,6.8c0.2,2.8,0.5,5.6,0.7,8.5
	c0.1,1.1-0.1,2.2-0.3,3.2c-0.8,3.3-1.7,6.5-2.6,9.8C219,103.6,219,104.3,218.8,105.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M210.2,31.2c0.5,0.7,0.9,1.4,1.4,2.1c0.7,0.8,1.4,1.6,2.1,2.4c0.1,0.1,0.4,0.2,0.4,0.3c0,1.7,1.4,3.2,0.8,5
	c-0.1,0.3-0.4,0.7-0.6,0.7c-0.3,0-0.8-0.2-1-0.4c-1-1.2-1.9-2.5-2.9-4c-0.5,1.2-1,2.3-1.4,3.3c-0.1,0.3-0.3,0.7-0.5,0.8
	c-0.3,0-0.7-0.2-0.9-0.4c-0.8-1.1-1.6-2.3-2.4-3.4c-0.1-0.2-0.2-0.3-0.5-0.7c-0.3,1.9-2,3-1.9,4.9c0,0.1-0.7,0.5-1,0.3
	c-0.4-0.2-0.8-0.7-1.1-1.1c-1-1.4-2-2.8-3.1-4.3c0.6-1.1,1.2-2.3,1.8-3.6c0.1,0,0.2,0,0.4,0c0.3,0.7,0.6,1.5,1,2.2
	c0.2,0.3,0.5,0.6,0.7,0.9c0.3-0.2,0.6-0.5,0.8-0.7c0.5-0.6,0.8-1.3,1.3-1.9c1-1.3,1.7-1.3,2.3,0.2c0.4,1.1,1.1,1.7,2,2.2
	c0.1,0.1,0.3,0.1,0.5,0.1c0.5-1.7,1-3.3,1.5-5C209.9,31.2,210,31.2,210.2,31.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M115.1,29.1c-0.6-1.3-1.3-2.5-1.8-3.8c-0.4-1-0.9-1-1.5-0.3c-0.9,1-1.7,2-2.8,3.2c-0.4-1.1-0.7-1.9-1.1-2.8
	c-0.1-0.3-0.3-0.7-0.5-0.8c-0.3,0-0.6,0.2-0.8,0.5c-0.6,0.7-1.1,1.5-1.7,2.2c-0.1,0.2-0.5,0.1-0.7,0.2c0-0.2-0.2-0.5-0.1-0.7
	c1.4-2.9,2.9-5.7,4.4-8.6c0.3-0.6,0.8-1.1,1.2-1.6c0.7-0.8,1-0.6,1.6,0.3c1.1,1.6,2.2,3.2,3.6,4.6C118.5,25.1,118.1,25.4,115.1,29.1
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M142.3,9.4c-0.5-1.9,0.3-3.1,1.9-3.6c2.1-0.7,4.1-0.4,6.2-0.3c3.1,0.2,6.3,0.2,9.5,0.2c2.2,0,4.5,0,6.7-0.1
	c1.2-0.1,2.4-0.2,3.6-0.5c1.9-0.5,3.7,0.1,5.2,1.1c0.7,0.5,1.2,1.3,1.7,1.9c-0.1,0.1-0.2,0.1-0.2,0.2c-0.8-0.4-1.6-0.8-2.5-1.2
	c0,0.1-0.1,0.1-0.1,0.2c0.8,0.7,1.6,1.5,2.5,2.4c-0.4,0.4-0.7,0.7-1,1c-0.1,0-0.3,0-0.4,0c0-1.7-0.7-2.7-2.5-2.6
	c0.4,0.3,0.8,0.6,1.3,0.9c0,0.1-0.1,0.1-0.1,0.2c-0.8-0.2-1.5-0.4-2.4-0.6c0.3-0.8,0.6-1.5,0.9-2.2c0.7,0.1,1.4,0.2,2.2,0.3
	c0-0.1,0.1-0.2,0.1-0.2c-0.4-0.2-0.7-0.4-1.1-0.6c-0.7-0.2-1.5-0.4-2.2-0.5c-0.6-0.1-1.2,0-1.3,0.8c0,0.3-0.5,0.7-0.8,0.7
	c-1.3,0.3-2.6,0.4-3.9,0.6c-5.2,1-10.4,0.7-15.6,0.1c-0.5-0.1-0.9-0.2-1.2-0.3c-0.1-0.6-0.2-1-0.2-1.4c-0.4,0.1-0.9,0.2-1.3,0.3
	c-0.8,0.2-1.6,0.3-2.5,0.5c-0.9,0.2-2,0.3-1.8,1.7C143,8.8,142.6,9.1,142.3,9.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M212.2,27.9c-0.4-1.3-0.8-2.5-1.3-3.8c-0.1-0.3-0.4-0.6-0.7-0.9c-0.2,0.3-0.5,0.5-0.7,0.8
	c-0.2,0.3-0.3,0.7-0.5,1.1c-0.7,1.2-1.4,2.5-2.2,3.7c-0.1,0.2-0.3,0.5-0.5,0.9c-0.4-0.6-0.7-1.1-0.9-1.6c-0.6-1.4-1.1-2.8-1.7-4.2
	c-0.5-1.3-0.5-2.5,0-3.8c0.6-1.2,0.9-2.6,1.3-3.8c0.1-0.4,0.3-0.8,0.5-1.2c0.5-0.8,1-0.8,1.5,0c0.5,0.8,0.9,1.6,1.4,2.4
	c1.8,2.5,3.4,5.2,3.8,8.4c0,0.4,0.1,0.8,0.1,1.2c0,0.3,0,0.5-0.1,0.8C212.4,27.9,212.3,27.9,212.2,27.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M205.1,30.4c-1.7,0.9-2.4,2.8-3.5,4.2c-0.5-0.9-1.1-1.8-1.6-2.7c-0.1-0.1,0-0.3,0-0.5c0.7-1.9,1.3-3.8,2.1-5.7
	c0.1-0.3,0.6-0.6,0.9-0.8c0.1,0.3,0.3,0.6,0.4,0.9C204,27.4,204.6,29,205.1,30.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M209.9,25.8c0.5,1.1,1.1,2.2,1.3,3.4c0.2,1.2,0.7,1.6,1.8,1.4c0.3,1.3,0.6,2.5,0.9,3.7
	c-0.1,0.1-0.2,0.2-0.2,0.2c-0.3-0.3-0.5-0.6-0.7-0.9c-0.8-1.1-1.7-2.2-2.5-3.3c-0.7-0.9-1.1-0.4-1.4,0.3c-0.4,1.2-0.7,2.5-1,3.7
	c-0.1,0.2-0.1,0.4-0.3,1c-0.9-1.4-1.7-2.6-2.7-4c1.6,0.7,2-0.3,2.5-1.3c0.6-1.4,1.3-2.9,1.9-4.3C209.6,25.7,209.7,25.8,209.9,25.8z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }}
              d="M166.1,9.9c-4.4,0.8-8.7,0.7-12.9,0c0-0.2,0-0.4,0-0.6c2.1,0,4.3,0,6.4,0C161.7,9.2,163.9,8.8,166.1,9.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }}
              d="M151.4,11.1c-0.3,1-0.6,1.8-0.9,2.8c-0.4,0-1-0.1-1.5-0.1C148.8,11.8,149.9,10.6,151.4,11.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M148.4,11.2c-0.1,0.8-0.3,1.6-0.4,2.6c-0.9-0.4-1.7-0.8-2.9-1.4c1.2-1,2.1-1.7,3-2.5c0,0,0,0.1,0,0.1
	c-0.6,0.7-1.2,1.5-1.7,2.2c-0.1,0.1,0.2,0.4,0.4,0.6c0.5-0.7,1-1.2,1.4-1.8C148.2,11.1,148.3,11.2,148.4,11.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M152,14.3c-0.2-0.1-0.5-0.1-1-0.3c0.4-0.9,0.7-1.7,1.2-2.5c0.2-0.3,0.7-0.2,1.2-0.4
	C152.9,12.4,152.5,13.3,152,14.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M219.1,110.7c0.1,1,0.2,2.1,0.2,3.1c-0.1,0-0.2,0-0.4,0c-0.1-1-0.3-2.1-0.4-3.1
	C218.8,110.7,219,110.7,219.1,110.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M146.7,8.7c-0.8,0.5-1.6,1-2.6,1.6C144.6,9,145.6,8.8,146.7,8.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M58,107.7c-0.4,0-0.8,0-1.3,0.1c0-3,0-6,0-8.9c-0.2,0-0.3,0-0.5,0c-0.5,3,0.3,6.1-0.7,9
	c-0.3-3.1-0.5-6.2-0.8-9.4c-0.2,0-0.4,0-0.6,0c0.1,3.1,0.3,6.2,0.4,9.4c-1.4,0.3-1.5,0.3-1.6-1.1c-0.2-2.3-0.4-4.6-0.6-6.9
	c0-0.5-0.3-0.9-0.5-1.3c-0.2,0-0.3,0.1-0.5,0.1c0.3,3.1,0.6,6.2,0.9,9.3c-0.4,0-0.9,0.1-1.3,0.1c-0.3-3-0.6-5.9-0.9-8.8
	c-0.2,0-0.4,0-0.6,0c0,0.5,0,1,0,1.5c0.2,2.1,0.5,4.2,0.8,6.3c0.2,1.2-0.7,1.1-1.4,1.1c-0.9,0-0.6-0.7-0.6-1.2
	c-0.1-2.6-0.1-5.2-0.2-7.8c-0.2,0-0.4,0-0.6,0c0,0.5-0.1,1.1-0.1,1.6c0,2.2,0.1,4.4,0.1,6.6c0,0.3-0.3,0.6-0.4,0.9
	c-0.2-0.3-0.5-0.6-0.5-0.9c-0.1-1.9,0-3.8-0.1-5.6c0-1.1-0.2-2.2-0.3-3.3c-0.1,0-0.2,0-0.3,0c-0.1,0.5-0.2,0.9-0.2,1.4
	c0,2.2,0,4.4,0,6.6c0,0.4,0.1,0.8,0,1.2c0,0.2-0.3,0.5-0.4,0.7c-0.2-0.2-0.5-0.4-0.5-0.7c-0.1-0.5,0-1,0-1.6c-0.1-2-0.1-4.1-0.2-6.1
	c0-0.4-0.1-0.8-0.2-1.2c-0.1,0-0.2,0-0.3,0c0,3.1,0,6.2,0,9.3c-0.9,0.4-1.3,0.2-1.3-0.7c-0.1-1.8-0.2-3.5-0.3-5.3
	c-0.1,0-0.2,0-0.3,0c0,1.8,0,3.5,0,5.3c0,0.8-0.2,1.1-1.1,0.8c-0.1-3.1-0.3-6.2-0.4-9.4c-0.2,0-0.4,0-0.6,0
	c-0.1,0.4-0.2,0.9-0.2,1.3c0.1,2.4,0.2,4.7,0.2,7.1c0,0.4-0.3,0.8-0.4,1.1c-0.1-0.4-0.4-0.8-0.4-1.2c-0.2-2.5-0.3-5-0.5-7.5
	c0-0.3-0.2-0.6-0.2-0.8c-0.1,0-0.3,0-0.4,0c0.1,3.1,0.3,6.3,0.4,9.4c-0.9,0.4-1.1,0-1.1-0.7c-0.1-2.4-0.2-4.7-0.4-7.1
	c0-0.4-0.1-0.7-0.2-1.1c-0.1,0-0.3,0-0.4,0c0,0.7,0,1.4,0,2.1c0.1,2,0.2,3.9,0.2,5.9c0,0.3-0.4,0.7-0.6,1c-0.2-0.4-0.5-0.7-0.5-1.1
	c-0.1-2.3-0.2-4.6-0.3-6.9c0-0.3-0.1-0.6-0.1-0.9c-0.1,0-0.3,0-0.4,0c-0.1,0.7-0.2,1.4-0.2,2c0,2,0.1,3.9,0.2,5.9
	c0,0.4-0.1,0.7-0.1,1.1c-0.1,0-0.3,0-0.4,0c-0.3-3-0.6-6-0.9-9.1c-0.1,0-0.2,0-0.3,0c0,0.6-0.2,1.1-0.1,1.7c0.1,2.2,0.3,4.3,0.4,6.5
	c0,0.3-0.3,0.7-0.5,1c-0.2-0.3-0.4-0.7-0.5-1c-0.2-1.9-0.3-3.8-0.5-5.7c-0.1,0-0.3,0-0.4,0c0.1,2.3,0.2,4.5,0.3,6.8
	c-0.6,0-1,0-1.5,0c0-1.6,0-3.1,0-4.6c-0.1-1.5,0.3-3.1-0.3-4.5c-0.1,0-0.3,0-0.4,0c-0.1,0.5-0.2,1-0.2,1.5c0,2.2,0,4.5,0,6.7
	c0,0.3-0.2,0.7-0.4,1c-0.2-0.3-0.4-0.7-0.5-1c-0.2-3-0.3-6-0.4-9c-0.2,0-0.4,0-0.5,0c-0.1,0.5-0.2,1-0.2,1.5c0.1,2.5,0.2,5,0.3,7.4
	c0,0.4-0.3,1-0.5,1c-0.8,0.2-0.7-0.5-0.7-1c0.1-2.6,0.1-5.3,0.2-7.9c0-0.4,0-0.7,0-1.1c-0.2,0-0.3-0.1-0.5-0.1
	c-0.1,0.6-0.4,1.2-0.4,1.8c0,2.7,0,5.3,0,8c-1.1,0.3-1.3-0.1-1.3-0.8c0-2.6-0.1-5.1-0.1-7.7c0-0.4-0.1-0.8-0.1-1.2
	c-0.1,0-0.3,0-0.4,0c0,0.6-0.1,1.1-0.1,1.7c0,2.3,0.1,4.6,0.1,7c0,0.3-0.1,0.6-0.2,0.8c-0.2,0-0.3,0-0.5,0c-0.1-3.3-0.2-6.5-0.2-9.8
	c-0.2,0-0.3,0-0.5,0c0,3.2,0,6.3,0,9.5c-0.1,0-0.3,0.1-0.4,0.1c-0.1-0.4-0.3-0.7-0.3-1.1c-0.1-2.6-0.1-5.1-0.2-7.7
	c0-0.4,0-0.8-0.1-1.2c-0.1,0-0.2,0-0.4,0c0,2.8,0,5.6,0,8.6c-0.3-0.2-0.4-0.3-0.4-0.3c0-4-0.4-8.1,0-12.1c0.6-6.8,1.8-13.6,2.6-20.4
	c0.4-3.2,0.3-6.3-0.4-9.5c-0.3-1.2,0.2-2.6,0.2-3.9c0-1.5,0.1-3.1-0.1-4.6c-0.3-2.8-1-5.6-1.1-8.4c-0.1-2.4,0.3-4.8,0.5-7.2
	c0.2,0,0.4,0,0.6-0.1c0.3,0.7,0.6,1.4,1,2c0.2,0.3,0.6,0.5,0.9,0.8c0.2-0.3,0.4-0.7,0.5-1c0.3-1.2,0.6-2.4,1.2-3.6
	c0.4,0.8,0.7,1.6,1.2,2.3c0.2,0.3,0.6,0.6,0.9,0.8c0.2-0.3,0.5-0.7,0.6-1c0.6-1.4,1.1-2.9,1.7-4.7c0.5,0.6,0.7,0.9,0.9,1.3
	c0.6,1,1.1,2.1,1.6,3.1c0.1,0.2,0.4,0.5,0.6,0.5c0.2,0,0.4-0.3,0.5-0.5c0.7-1.3,1.3-2.5,2.1-4.1c0.5,1.2,0.9,2,1.3,2.9
	c0.2,0.4,0.3,0.9,0.5,1.2c0.3,0.4,0.8,0.7,1.2,1.1c0.3-0.4,0.6-0.7,0.8-1.1c0.6-1.1,1.1-2.3,1.7-3.5c0.7,1.2,1.3,2.3,2,3.3
	c0.2,0.3,0.5,0.4,0.8,0.6c0.2-0.2,0.4-0.5,0.5-0.7c0.6-1.1,1.2-2.3,2.1-3.8c0.6,1.6,1.1,2.9,1.6,4.2c0.2,0.4,0.5,0.8,0.8,1.2
	c0.4-0.3,0.9-0.6,1.1-1c0.7-1.2,1.2-2.4,2-4c0.5,1,0.9,1.7,1.2,2.5c0.2,0.5,0.4,1,0.6,1.4c0.1,0.2,0.4,0.6,0.7,0.6
	c0.2,0,0.5-0.3,0.6-0.6c0.5-0.8,1-1.7,1.5-2.6c0.1,0,0.2,0,0.3,0.1c0.1,0.8,0.3,1.7,0.4,2.5c0.1,0.5,0.2,1,0.4,1.5
	c0.1,0.2,0.4,0.5,0.7,0.5c0.1,0,0.4-0.3,0.5-0.6c0.4-0.7,0.8-1.3,1.2-2c0.1,0,0.3,0.1,0.4,0.1c0,0.9,0,1.8,0,2.7
	c0,0.3,0.3,0.7,0.5,1c0.2-0.2,0.5-0.4,0.7-0.7c0.5-0.7,0.9-1.5,1.4-2.4c0.7,1.2,0.8,2.3,0.6,3.4c-0.5,2.3-1.4,4.6-1.5,7
	c-0.1,3.4,0.3,6.8,0.5,10.3c0.1,1.4,0.1,2.7,0.2,4.1c0.1,2.1,0.2,4.1,0.3,6.2c0.1,1.2,0.2,2.4,0.1,3.6c-0.1,5,0.3,10,1.3,14.9
	c0.7,3,0.4,5.8,0.3,8.8c-0.1,2.6,0.2,5.2,0.2,7.7c0,0.3-0.2,0.7-0.4,1c-0.2-0.3-0.6-0.5-0.6-0.8c-0.1-1.5-0.2-2.9-0.3-4.4
	c-0.1-1.3,0-2.6-0.1-4c0-0.3-0.2-0.6-0.2-0.9c-0.1,0-0.3,0-0.4,0c0,3.4,0,6.9,0,10.3c-0.1,0-0.3,0-0.4,0c-1.1-3.1-0.1-6.6-1.2-9.7
	c-0.2,0-0.3,0-0.5,0c0,0.5-0.1,1-0.1,1.6c0.2,2.6,0.4,5.2,0.6,7.8c0,0.2-0.2,0.4-0.3,0.6c-0.2-0.2-0.4-0.4-0.4-0.7
	c-0.1-2.3-0.2-4.6-0.3-6.8c0-0.7-0.2-1.3-0.3-2c-0.1,0-0.3,0-0.4,0c-0.1,0.6-0.2,1.1-0.2,1.7c0.1,2.4,0.2,4.8,0.3,7.2
	c0,0.2,0,0.5-0.3,0.7c-0.2-3.1-0.4-6.2-0.6-9.3c-0.2,0-0.3,0-0.5,0c-0.1,0.5-0.2,1-0.2,1.6c0.1,2.4,0.2,4.7,0.3,7.1
	c0,0.3-0.2,0.6-0.3,0.9c-0.1-0.3-0.4-0.6-0.4-0.8c-0.2-2.4-0.3-4.8-0.4-7.2c0-0.3-0.1-0.6-0.4-0.9C58,101.6,58,104.6,58,107.7z
	 M31.4,97.7c-0.9,1-0.8,2.2,0,2.8C31.4,99.6,31.4,98.7,31.4,97.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M14,111.4c-0.2-2-0.4-4-0.6-6c-0.1,0-0.2,0-0.4,0c0,0.2-0.1,0.5-0.1,0.7c0.3,2.5,0.5,5.1,0.8,7.6
	c0.1,0.6,0.1,1.1-0.9,1.1c-0.3-3.1-0.6-6.1-0.9-9.2c-0.2,0-0.4,0-0.6,0.1c0.3,3.1,0.5,6.3,0.8,9.6c-0.5,0.1-1,0.1-1.8,0.2
	c0-3.1,0-6.1,0-9.1c-0.1,0-0.2,0-0.3,0c-0.1,0.4-0.1,0.8-0.1,1.1c0,2.3,0,4.6,0,6.8c0,0.3-0.3,0.7-0.5,1c-0.2-0.3-0.4-0.6-0.4-1
	c-0.1-2.3-0.2-4.6-0.3-6.9c0-0.5-0.1-0.9-0.3-1.3c0,3,0,6,0,8.9c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2-0.3-0.7-0.6-0.7-0.9
	c-0.2-2.4-0.3-4.8-0.4-7.2c0-0.7,0-1.3,0-2c-0.1,0-0.3,0-0.4,0c0,3.2,0,6.5,0,9.7c-1,0.2-1.2-0.6-1.2-1.1c-0.1-1.6-0.1-3.3-0.2-4.9
	c-0.1-2.7-0.3-5.3-0.4-8c-0.1-2.1-0.3-4.2-0.3-6.3c-0.2-5.5-0.6-11-0.6-16.5c0-4.1,0.5-8.3,0.9-12.4C5,64.2,5.3,62.9,6,61.6
	c0.5-1,0.3-2.5,0.3-3.8c0.3-4.8,0.6-9.6,1-14.5c0-0.2,0.1-0.5,0.1-0.7c-0.7-2,0.7-3,2-4.2c0.4,1.4,0.7,2.8,1.1,4.1
	c0.1,0.3,0.4,0.6,0.6,0.9c0.3-0.3,0.6-0.5,0.8-0.8c0.4-0.8,0.8-1.6,1.3-2.6c0.5,1,1,1.9,1.4,2.7c0.2,0.3,0.5,0.5,0.7,0.7
	c0.2-0.3,0.5-0.5,0.6-0.8c0.4-0.8,0.8-1.7,1.3-2.9c0.4,0.6,0.6,1,0.8,1.3c0.2,0.3,0.5,0.5,0.7,0.7c0.2-0.2,0.5-0.5,0.6-0.8
	c0.4-0.7,0.7-1.4,1-2.1c0.1,0,0.2,0,0.3,0.1c0.1,1.8,0.6,3.7,0.2,5.4c-1.3,5.7-1,11.4-0.9,17.1c0,1.6-0.3,3.3-0.4,4.9
	c-0.1,3.5,0,7-0.2,10.5c-0.2,3.3-0.7,6.5-1.1,9.7c-0.6,3.8-1.2,7.6-1.9,11.3c-0.4,2.1-1,4.1-1.7,6.1c-0.4,1.2-0.8,2.3-0.5,3.6
	c0.2,1.1,0.2,2.3,0.2,3.5C14.2,111.4,14.1,111.4,14,111.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M72.1,105.1c0,2,0,4,0,6c0,0-0.1,0-0.1,0c-0.2-3-0.4-6-0.7-9.1c-0.3-2.9-0.8-5.8-1.2-8.8
	c-0.4-3.1-0.9-6.2-1.4-9.4c-0.4-2.7-0.7-5.4-1-8.1c-0.4-3.9-0.7-7.7-0.9-11.6c-0.2-3.8-0.2-7.7-0.3-11.5c-0.1-2.4-0.4-4.9-0.7-7.3
	c-0.3-2.6,0.7-4.9,1.3-7.3c0.1,0,0.2,0,0.4-0.1c0.4,1.1,0.8,2.2,1.2,3.3c0.3,0.7,0.6,1.5,1.1,2.1c0.5,0.8,0.9,0.5,1.2-0.3
	c0.3-1.1,0.7-2.1,1.1-3.3c0.6,1.1,1.1,2.1,1.6,3c0.2,0.3,0.6,0.4,0.9,0.6c0.1-0.3,0.2-0.6,0.3-0.9c0.3-0.9,0.5-1.8,0.8-2.7
	c0.1,0,0.2,0,0.4,0c0.3,0.9,0.5,1.8,0.8,2.6c0.3,1,0.7,1.1,1.7,0.4c0.1,1,0.3,1.8,0.4,2.7c0.4,5.5,0.7,11,1.1,16.5
	c0.2,2.8,1,5.6,1.3,8.4c0.3,2.9,0.5,5.9,0.7,8.8c0.2,2.9,0.6,5.9,0.6,8.8c0,2.7-0.5,5.3-0.8,8c-0.3,2.7-0.6,5.3-0.9,8
	c-0.1,0.4,0.2,1.1-0.7,1.1c-0.1,0-0.3,0.5-0.3,0.8c0.1,2.4,0.2,4.7,0.4,7.1c0,0.5,0,1-0.1,1.4c0,0.2-0.3,0.4-0.5,0.6
	c-0.1-0.2-0.4-0.5-0.4-0.7c0-1.9,0-3.8,0-5.8c-0.1-1.3-0.3-2.6-0.7-3.9c-0.9,1.7,0.2,3.4-0.1,5.2c-0.2,1.7,0,3.5,0,5.3
	c-0.1,0-0.3,0.1-0.4,0.1c-0.1-0.4-0.2-0.8-0.2-1.3c-0.1-2.6-0.1-5.1-0.2-7.7c0-0.4-0.2-0.8-0.3-1.1c-0.2,0-0.3,0-0.5,0
	c0,0.6-0.2,1.1-0.1,1.7c0.1,2.7,0.3,5.3,0.4,8c-0.2,0.1-0.3,0.1-0.5,0.2c-0.2-0.3-0.5-0.5-0.5-0.8c-0.1-1.8-0.1-3.7-0.2-5.5
	c0-1.2-0.2-2.3-0.2-3.7c-0.8,0.6-0.9,5.8-0.4,9.6c-0.9,0-1,0-1-1.1c0-2.3-0.1-4.6-0.1-7c0-0.5-0.1-0.9-0.2-1.3c-0.2,0-0.3,0-0.5,0
	c-0.1,0.6-0.3,1.2-0.3,1.8c0,2.1,0.1,4.2,0.1,6.4c0,0.3-0.3,0.6-0.4,0.9c-0.2-0.3-0.5-0.6-0.5-0.9c-0.1-2.4,0-4.9-0.1-7.3
	c0-0.4-0.2-0.8-0.3-1.1C72.3,105,72.2,105.1,72.1,105.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M42.4,22.9c-0.9,0.8-1.7,1.6-2.6,2.4c-0.9-1.2-1.7-2.5-2.8-4.1c-1,1.6-1.9,3.2-3,5c-0.8-1.3-1.4-2.3-2-3.4
	c-0.2-0.3-0.6-0.8-0.8-0.7c-0.4,0.1-0.7,0.5-0.9,0.9c-0.5,1.2-0.9,2.4-1.4,3.8c-1.1-1.4-2-2.7-3-4.1c-0.6,1.7-1.2,3.4-1.9,5.2
	c-0.8-1.4-1.5-2.6-2.2-3.9c-0.1,0-0.2,0-0.3,0c-0.4,1.8-0.8,3.5-1.2,5.7c-0.4-0.6-0.7-0.9-0.8-1.2c-1-3.5-3.2-6.4-4.8-9.6
	C14.4,18,14,17,13.8,16c-0.1-0.4,0.1-1.1,0.5-1.4c1.9-1.6,11.6-6,14-6.5c0.4,1.2,0.7,2.5,1.1,3.7c0.2,0,0.4,0,0.5,0
	c0-1.4-0.1-2.8,1.6-3.5c-0.3,0.4-0.6,0.6-0.7,1c-0.2,0.5-0.8,1.1,0.1,1.6c0.1,0.1,0.2,0.4,0.1,0.6c-0.5,1,0.2,1.4,0.9,1.8
	c3.1,1.4,6.2,2.3,9.7,2.1c-1-0.4-2.3-0.6-2.4-2.4c-0.5,0.7-0.7,1.1-1,1.4c-0.2-1.9,0.7-2.8,2.6-2.7c3.7,0.3,7.5,0.7,10.8-1.8
	c0.8-0.6,3,0.1,3.4,1.1c0.1,0.2-0.1,0.5-0.1,0.8c-0.3,0-0.6,0-0.8-0.2c-0.8-0.8-1.7-1.1-2.6-1c0.9,0.6,1.8,1.2,2.9,2
	c1.7-0.8,3.3-2.1,3.4-4.7C58.4,8,59,8.2,59.6,8.4c3.5,1.3,7.1,2.6,10.6,4.1c2.3,1.1,2.1,1.2,1.4,3.6c-0.6,2.3-0.8,4.8-1.2,7.2
	c-0.2,1.5-0.5,3-0.7,4.5c-0.2,0-0.3,0-0.5,0c-0.2-0.6-0.3-1.3-0.6-1.9c-0.1-0.3-0.3-0.7-0.5-1c-0.3,0.2-0.7,0.4-1,0.7
	c-0.7,0.7-1.3,1.4-2.1,2.3c-0.2-1.7-0.3-3-0.5-4.3c-0.1,0-0.3-0.1-0.4-0.1c-1,1-1.9,2-3,3.1c-0.4-1.6-0.9-3.1-1.3-4.5
	c-0.1-0.1-0.3-0.1-0.4-0.2c-1.1,1.5-2.1,2.9-3.4,4.7c-0.7-1.9-1.3-3.4-2-5.2c-1,1.6-1.9,3-2.9,4.7c-0.8-1.8-1.6-3.4-2.4-5.2
	c-1.3,1.9-2.5,3.6-3.9,5.6c-0.7-1.5-1.3-2.9-2-4.5C42.7,22.4,42.6,22.7,42.4,22.9C42.4,22.9,42.4,22.9,42.4,22.9z M49.8,12.5
	c-0.1,0-0.2,0-0.3,0.1c0,0.3,0.1,0.7,0.2,1.1c-1,0.3-1.9,0.6-3,1c0,0,0.2,0.5,0.3,0.4c1.9-0.5,3.9-1.1,5.8-1.8
	c0.2-0.1,0.4-0.8,0.3-1.2c-0.2-0.9-1.7-1.3-2.6-0.7c0.7,0.4,1.4,0.7,2.1,1.1c0,0.1,0,0.2-0.1,0.3c-0.7,0.2-1.4,0.5-2,0.7
	C50.2,13.1,50,12.8,49.8,12.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M42.4,22.9C42.4,22.9,42.4,22.9,42.4,22.9c0.4,0.8,0.8,1.6,1.2,2.5c0.4,0.9,0.8,1.7,1.2,2.5
	c1.2-1.9,2.4-3.8,3.7-5.8c0.9,1.8,1.6,3.4,2.5,5.3c1-1.4,1.8-2.6,2.8-4c0.7,1.6,1.3,3,1.9,4.4c1.7-1,2.6-2.6,3.5-4.2
	c0.1,0,0.2,0,0.3,0c0.4,1.7,0.8,3.4,1.3,5.3c1.1-1.5,2.1-2.8,3.4-4.4c0,1.6,0,2.9,0,4.3c0,0.3,0.1,0.6,0.2,0.9
	c0.3-0.2,0.6-0.3,0.8-0.5c0.8-0.8,1.6-1.7,2.5-2.8c0.3,1.3,0.6,2.4,0.9,3.4c-2.2,1.1-1.7,3.5-2.8,5.1c-0.9-2.1,0.5-3.7,1.1-5.4
	c-0.1-0.1-0.3-0.1-0.4-0.2c-1,1.8-1.9,3.6-2.9,5.3c0.1,0.1,0.2,0.1,0.3,0.2c0.2-0.3,0.4-0.5,0.8-1c0.3,1.2,0.5,2.1,0.8,3.2
	c0.4-0.3,0.7-0.5,1-0.7c-0.3,1.1-0.6,2.2-0.9,3.2c-0.1,0-0.2,0-0.3,0c0-0.6-0.1-1.2-0.1-1.8c-0.1-0.1-0.3-0.1-0.4-0.2
	c-0.7,1.3-1.5,2.6-2.2,3.9c-0.1-0.1-0.2-0.1-0.4-0.2c0.1-0.7,0.2-1.3,0.3-2c0-0.7,0-1.4,0-2c0-0.3-0.2-0.7-0.3-1
	c-0.3,0.2-0.6,0.3-0.8,0.6c-0.5,1-0.9,2-1.4,3c-0.1,0.3-0.4,0.4-0.6,0.6c-0.2-0.2-0.4-0.5-0.5-0.7c-0.3-1.7-0.5-3.4-0.8-5.4
	c-0.8,1.3-1.4,2.3-2,3.3c-0.2,0.3-0.6,0.6-0.9,0.8c-0.2-0.3-0.5-0.6-0.6-1c-0.5-1.3-1-2.6-1.6-4.1c-0.9,1.7-1.6,3.3-2.4,4.8
	c-0.2,0.4-0.5,0.6-0.8,0.9c-0.3-0.4-0.6-0.7-0.8-1.1c-0.6-1.5-1.2-3-1.8-4.7c-0.7,1.4-1.3,2.6-1.9,3.8c-0.2,0.4-0.6,0.7-0.9,1
	c-0.3-0.4-0.7-0.7-0.9-1.1c-0.5-1-1-2.1-1.4-3.1c-0.1,0-0.2,0-0.3,0c-0.5,1.1-0.9,2.2-1.4,3.4c-0.1,0.3-0.2,0.7-0.3,1
	c-0.2,0.3-0.5,0.6-0.7,0.7c-0.2,0-0.6-0.4-0.7-0.6c-0.7-1.4-1.3-2.8-1.9-4.2c-0.1-0.1-0.2-0.2-0.4-0.6c-0.6,1.4-1.2,2.7-1.8,3.9
	c-0.2,0.4-0.5,0.6-0.7,1c-0.3-0.3-0.6-0.5-0.8-0.8c-0.7-1.3-1.3-2.5-2-4c-1.1,1.1-1.4,2.4-1.8,3.6c-0.1,0.2,0,0.5-0.1,0.7
	c-0.1,0.3-0.4,0.5-0.6,0.6c-0.1,0-0.5-0.2-0.6-0.5c-0.6-1-1.1-2-1.8-3.4c-0.5,2-1,3.8-1.5,5.6c-0.1,0-0.2,0.1-0.4,0.1
	c-0.4-1.1-0.8-2.2-1.3-3.3c-0.1,0-0.2,0-0.3,0c-0.2,0.7-0.5,1.4-0.8,2.2c-0.6-2.5-1-5,0.7-7.2c0.6,1,1.1,1.9,1.7,3
	c0.8-1.7,1.6-3.3,2.5-5.2c0.7,1.5,1.2,2.8,1.7,4c0.1,0,0.2,0,0.3,0c0.7-1.7,1.4-3.4,2.2-5.1c-0.1,0-0.2-0.1-0.3-0.1
	c-0.6,1.4-1.2,2.8-1.9,4.4c-0.6-1.4-1-2.6-1.6-4c-1.4,1.5-1.8,3.4-2.9,5.1c-0.6-1.3-1.1-2.5-1.8-3.9c-0.5,1.2-0.9,2.2-1.4,3.2
	c-0.5-0.7-0.4-0.7,0-2.2c0.4-1.3,0.6-2.7,0.9-4.1c0.1-0.3,0.1-0.5,0.5-0.8c0.6,1.2,1.1,2.5,1.7,3.7c0.1,0,0.2,0,0.3,0
	c0.5-1.7,1.1-3.4,1.8-5.5c1.1,1.4,2,2.6,3,3.9c0.6-1.7,1.2-3.4,1.9-5.2c0.8,1.3,1.6,2.4,2.3,3.6c0.5,0.9,0.9,0.8,1.4,0
	c0.8-1.2,1.6-2.5,2.5-4c0.7,1.2,1.4,2.3,2,3.4c0.2,0.2,0.4,0.6,0.7,0.6c0.2,0,0.6-0.3,0.7-0.5C41.2,25.3,41.8,24.1,42.4,22.9z
	 M31.5,27.6c-0.1,0.1-0.2,0.2-0.3,0.2c1.1,1.8,2.3,3.7,3.5,5.7c0.8-1.8,1.6-3.5,2.4-5.2c0.1,0,0.2,0.1,0.3,0.1
	c0.5,1.7,1,3.4,1.5,5.1c0.1,0,0.2-0.1,0.3-0.1c1-1.8,2-3.7,3.2-5.8c0.9,1.8,1.7,3.3,2.6,5.1c0.9-1.9,1.8-3.7,2.7-5.4
	c-0.1-0.1-0.2-0.1-0.3-0.2c-0.8,1.4-1.5,2.8-2.3,4.3c-0.5-1.1-0.9-2.1-1.5-3c-0.6-1.1-1.2-1.1-1.9,0c-0.8,1.3-1.6,2.6-2.5,4.1
	c-0.5-2-0.9-3.8-1.4-5.7c-0.2,0-0.3,0-0.5-0.1c-0.9,1.8-1.8,3.5-2.7,5.3C33.6,30.6,32.9,28.8,31.5,27.6z M60.2,34.5
	c-0.1,0-0.2,0-0.2-0.1c-0.4-2.2-0.7-4.4-1.1-6.6c-0.1,0-0.2,0-0.3,0c-0.8,1.4-1.7,2.8-2.6,4.3c-0.6-1.7-1.1-3-1.6-4.4
	c-0.1,0-0.2,0.1-0.4,0.1c0,1.9,1.2,3.5,1.9,5.5c0.9-1.3,1.6-2.3,2.5-3.7c0.3,2,0.5,3.8,1.9,5.4c0.8-1,1.5-2,2.3-2.9
	c0.2,0.8,0.3,1.6,0.5,2.3c0.1,0,0.3,0,0.4-0.1c0-1.7,0-3.5,0-5.2c-0.1,0-0.2,0-0.2-0.1C62.2,30.8,61.2,32.7,60.2,34.5z M51,31.3
	c-0.5-1-1-1.7-1.4-2.5c-0.8-1.8-0.8-1.8-1.7-1.7c0.9,1.9,1.9,3.8,2.8,5.8c0.9-1.8,1.9-3.5,2.8-5.3c-0.1-0.1-0.2-0.1-0.3-0.2
	C52.5,28.6,51.8,29.9,51,31.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M9.5,36.3c-0.6,0.7-1.1,1.3-1.6,1.9c-0.2-1.7,0.2-2.7,2.3-6.1c0.5,1.2,1,2.4,1.5,3.6c0.1,0,0.3,0,0.4,0
	c0.3-0.9,0.7-1.8,1-2.8c-0.1-0.1-0.2-0.1-0.4-0.2c-0.3,0.6-0.5,1.1-0.9,1.8c-0.5-1.3-0.9-2.6-1.3-3.9c-0.6,0.9-1.2,1.8-2,2.8
	c-0.2-2.9,0.5-5.1,2.2-7.2c0.2,1.2,0.3,2.1,0.5,3c0.1,0.3,0.3,0.6,0.4,0.9c0.2-0.2,0.6-0.4,0.7-0.7c0.5-1.1,1-2.2,1.6-3.7
	c0.6,1.9,1,3.6,1.5,5.2c0.1,0,0.3,0,0.4,0c0.5-1.5,1.1-3.1,1.6-4.6c0.1,0,0.2,0,0.3,0c0.4,1.2,0.8,2.3,1.1,3.5
	c0.5,2.1,0.8,4.1,1.3,6.2c0.4,1.9-0.9,3.2-1.4,4.8c-0.1,0-0.3,0-0.4,0c-0.4-1-0.9-1.9-1.3-2.9c-0.1,0-0.2,0-0.3-0.1
	c-0.4,0.9-0.7,1.9-1.1,2.8c-0.1,0.3-0.4,0.6-0.7,0.9c-0.2-0.3-0.5-0.5-0.6-0.8c-0.3-0.6-0.5-1.3-0.8-1.9c-0.1-0.2-0.5-0.4-0.7-0.5
	c-0.2,0-0.4,0.3-0.5,0.5c-0.3,0.5-0.5,1.1-0.7,1.6c-0.1,0.2-0.4,0.3-0.6,0.5c-0.1-0.2-0.3-0.3-0.4-0.5C10.3,39.4,9.9,38,9.5,36.3z
	 M15.1,36.4c0.8-1.4,1.4-2.7,2.1-3.9c-0.1-0.1-0.3-0.1-0.4-0.2c-0.5,1-1,1.9-1.7,3.1c-0.5-1.6-1-3-1.4-4.3c-0.2,0.1-0.3,0.1-0.5,0.2
	C13.6,33,14,34.6,15.1,36.4z M17.8,30.7c-0.4,1.5,0.7,3.9,2,4.7C19.1,33.8,18.5,32.4,17.8,30.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M75.4,37.7c-0.3,1.2-0.7,2.5-1,3.7c-0.1,0.1-0.2,0.1-0.4,0.2c-0.7-1.4-1.4-2.9-2.2-4.5c-0.5,1.8-1,3.5-1.6,5.3
	c-0.3-0.3-0.6-0.6-0.8-1c-0.7-1.4-1.3-2.7-1.9-4.1c-0.1-0.3-0.1-0.8,0-1.1c0.3-0.9,0.6-1.9,1.2-2.8c0.3,1,0.6,2,1,3.3
	c0.7-1.5,1.3-2.7,2-4.2c0.8,1.5,1.4,2.7,2,3.9c0.1,0,0.3-0.1,0.4-0.1c0.3-1.6,0.6-3.2,0.9-4.7c0.1,0,0.2,0,0.3-0.1
	c0.2,0.4,0.4,0.8,0.6,1.2c0.6,1.1,1.4,2.2,1.7,3.3c0.4,1.7,0.4,3.6,0.6,5.4c0,0.1-0.3,0.4-0.5,0.4c-0.2,0-0.4-0.2-0.5-0.4
	c-0.6-1.2-1.1-2.4-1.7-3.6C75.6,37.7,75.5,37.7,75.4,37.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M56.6,8.5c-0.6-0.4-1.2-0.8-1.8-1.2c0,0,0,0.1-0.1,0.1c0.6,0.8,1.2,1.6,1.7,2.5c0.1,0.1-0.3,0.5-0.7,1.1
	c-0.3-1.4-0.3-2.8-2-2.7C54,8.5,54.4,8.7,54.8,9c-0.1,0.1-0.2,0.3-0.3,0.4c-0.6-0.2-1.2-0.4-1.8-0.6c0.3-0.8,0.5-1.4,0.7-2.2
	c0.5,0.1,1.1,0.1,1.7,0.2c0-0.1,0.1-0.3,0.1-0.4c-1-0.3-2-0.6-3.1-0.8c-0.2,0-0.5,0.8-0.8,1.3c-5.4,1.6-11,2-16.7,0.7
	c0.1-0.4,0.2-0.8,0.1-1c-0.1-0.2-0.5-0.5-0.8-0.5c-0.5,0.1-0.9,0.3-1.4,0.5C31.3,7,29.9,7.1,30,8.8c0,0.1-0.2,0.2-0.3,0.4
	C29,8.1,29.6,6.3,31,5.9c1.3-0.5,2.6-0.7,4.2-0.4c2.1,0.4,4.3,0.3,6.4,0.3c3.5,0,6.9,0.3,10.4-0.6c1.5-0.4,3.4,0.7,4.1,2
	c0.2,0.4,0.5,0.7,0.8,1C56.8,8.2,56.6,8.4,56.6,8.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M73.5,15.1c1.8,4.1,3.7,8.1,3.2,12.7c-0.3-1.5-0.7-3-1-4.5c-0.1,0-0.2,0-0.3,0c-0.8,2-1.5,4.1-2.3,6.1
	C70.7,26.7,70.9,18.8,73.5,15.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M13.9,24.6c-0.6,1.1-1.2,2.3-2,3.7c-0.4-1.4-0.6-2.5-1-3.8c-0.4,0.7-0.7,1.1-1,1.5c-0.1,0-0.2-0.1-0.3-0.1
	c1-3.1,1.9-6.1,2.9-9.2c1.6,2.9,3.1,5.5,4.6,8.2c0.1,0.2,0.2,0.6,0.1,0.8c-0.4,1.1-0.9,2.3-1.4,3.7c-0.6-1.8-1-3.2-1.5-4.7
	C14.2,24.6,14.1,24.6,13.9,24.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M73.8,35c-0.5-1.1-0.9-2.3-1.3-3.1c0.5-0.5,1-0.9,1.1-1.4c0.4-1.2,0.7-2.4,1-3.6c0.1-0.4,0.3-0.7,0.6-1.3
	c0.3,1.5,0.6,2.7,0.9,3.8c0.1,0.5,0.5,0.9,0.8,1.4c0.1,0,0.2-0.1,0.3-0.1c0.2,1.2,0.4,2.4,0.6,3.6c-0.1,0-0.1,0.1-0.2,0.1
	c-0.7-1.5-1.4-3-2.1-4.5c-0.2,0-0.3,0-0.5,0c-0.2,0.8-0.4,1.7-0.6,2.5c-0.2,0.8-0.2,1.7-0.4,2.5C74,35,73.9,35,73.8,35z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }}
              d="M70,34.5c-0.9-1.9-1.2-3.7,0-5.5c0.5-0.8,0.7-1.7,1.1-2.8c0.4,1.5,0.7,2.9,1,3.9C71.4,31.7,70.7,33.1,70,34.5z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M48.1,9.8c-3.4,0.9-6.7,0.8-10-0.2C41.4,9.8,44.8,8.5,48.1,9.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }}
              d="M36.1,14.1c-0.4-0.1-0.7-0.2-1.1-0.4c-0.3-1.8,0.5-3.1,1.8-2.7C36.5,12.1,36.3,13.1,36.1,14.1L36.1,14.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M34,13.6c-2.2-0.8-2.4-1.2-0.9-2.6c-0.1,0.4-0.3,0.7-0.3,1c0,0.2,0.1,0.5,0.2,0.9c0.4-0.7,0.8-1.2,1.1-1.7
	c0.1,0,0.1,0,0.2,0.1C34.2,12,34.1,12.8,34,13.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M36.1,14.1c0.3-0.7,0.7-1.3,1-2c0.3-0.5,0.4-1.2,1.2-0.8c-0.3,0.9-0.7,1.9-1.1,2.8
	C36.9,14.2,36.5,14.2,36.1,14.1C36.1,14.1,36.1,14.1,36.1,14.1z" />
      </SvgIcon>
    )
  }
};