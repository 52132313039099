import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_18_1 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 198.5 237.1"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M41.1,136.5c-1.7-2.1-3-4.3-2.2-7.2c1.2,0.2,2.3,0.3,3.5,0.5c0.3,0.1,0.6,0.4,0.8,0.6c1.1,1,2.2,2,3.3,3
	c0.1-0.1,0.1-0.2,0.2-0.2c-1-1-2.1-1.9-3.1-2.9c0.1-0.1,0.1-0.2,0.2-0.3c2.8,0.8,5.7,1.6,8.5,2.4c-4.5-2.2-9.4-2.9-14.3-3.8
	c0.3-1.9-0.8-2.9-2.7-2.8c-0.7,0-1.5-0.2-2.2-0.3c0.3-0.8,0.3-1.7,0.8-2.3c1.4-1.7,3.2-2.7,5.4-3.2c0.6-0.1,1.2,0.1,2.1,0.1
	c-0.4,0.5-0.7,0.6-0.8,0.9c-0.2,0.7-0.5,1.7-0.2,2.1c0.3,0.5,1.4,0.5,2.1,0.5c0.5,0,1-0.4,1.5-0.6c1.5-0.8,2.8-0.1,4,0.5
	c0.2,0.1,0.2,0.8,0.1,1c-0.8,0.7-1.1,1.5-0.7,2.5c0.4,1,1.3,1.4,2.3,1.2c2.7-0.4,4.2,1.1,5.6,3c0.5,0.7,0.5,1.3-0.2,1.8
	c-2.1,1.4-4.1,2.9-6.7,3.3c-0.9,0.2-1,0.7-0.6,1.4c0.3,0.4,0.6,0.8,1,1.3c-3.2,1.5-6.3,2-9.6,0.5c-0.2,0.7-0.3,1.3-0.4,1.9
	c-2-0.2-5.5-3.1-6-5c-0.4-1.6,0.2-2.6,2-3.6c1.6-0.9,1.9-1.5,1.2-3c0.3-0.1,0.6-0.2,1-0.3c0.3-0.1,0.7-0.3,1-0.4
	c0.1,0.4,0.3,0.9,0.3,1.3C38.1,132.9,39,134.9,41.1,136.5z M40.7,128.9c1-2.3,2.5-3.7,4.7-4.3C43.5,124.4,40.6,126.9,40.7,128.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M22.1,188.9c-2.1-0.4-4.7-0.9-5.8-3.6c-0.9-2.2-0.7-4.4,0.7-6.4c1.7-2.3,4-3.3,6.9-2.8c1.6,0.3,2.7,1,3.5,2.4
	c0.1,0.2,0.2,0.5,0.4,0.7c2.2,1.8,1.6,3.9,0.6,5.9C27.2,187.6,25.2,188.9,22.1,188.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M52.8,164.1c-0.2,0.5-0.3,1.2-0.6,1.8c-1.5,3.2-6.4,4.7-9.3,2.9c-2.8-1.7-4.3-4.9-3.7-8
	c0.2-0.8,0.5-1.4,1.5-1.3c2.6,0.4,4.9-0.5,6.6-2.5c0.5-0.6,0.9-0.6,1.6-0.3C52,158.2,52.3,161,52.8,164.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M28.2,153.6c0.1-3.8,2.9-6.6,7.1-6.7c1.5,0,2.7,0.4,3.7,1.2c0.9,0.7,1.6,1.9,1.8,3c0.4,1.9-0.3,3.7-1.3,5.3
	c-1.8,2.8-4.6,3.2-7.5,2.9C29.6,159,28,156.4,28.2,153.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M21.3,130.7c-0.9,0.5-1.8,0.9-2.6,1.5c-2,1.5-2.6,3.7-2.7,6c0,0.8-0.2,1-1,1.1c-1.2,0.2-2.3,0.6-3.4,1
	c-0.8,0.3-2.1-0.2-2.8-1.4c-2.6-4-2.6-10.8,4.4-12.5c1.1-0.2,2.4,0.1,3.4,0.5c1.3,0.6,2.5,1.6,3.7,2.4
	C20.9,129.8,21.1,130.3,21.3,130.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M22.7,147.4c-0.3,2.2-1.7,3.7-3.5,4.8c-2.5,1.4-5,1.4-7.3-0.4c-2-1.6-2.8-3.8-2.3-6.4c0.4-2,1.8-3.1,3.5-4
	c0.6-0.3,1.4-0.4,2.1-0.6c1-0.3,1.7,0,2.4,0.9c0.9,1.1,2.1,1.9,3.3,2.6C22.3,145.1,22.8,145.7,22.7,147.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M36.1,195.9c-0.3,3.2-1.6,5.3-3.9,6.5c-0.8,0.4-1.9,0.4-2.8,0.3c-3.1-0.5-5.5-3.2-5.4-5.9
	c0.1-2.7,2.7-6.2,5.9-6.2c0.9,0,1.9,0.4,2.8,0.8c0.9,0.4,1.9,0.9,2.4,1.7C35.7,194,35.9,195.3,36.1,195.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M16.5,172.6c-3.2,0-5.3-2.1-5.4-5.5c-0.1-3.2,1.6-5.6,4.7-6.5c0.7-0.2,1.2-0.1,1.8,0.4c1.1,0.9,2.4,1.7,3.7,2.3
	c1.1,0.5,2,1.2,1.9,2.5C23,168.9,21,173,16.5,172.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M27.3,150.9c-0.8,2.4-0.6,4.8,0.6,7.2c0.3,0.5-0.2,1.7-0.7,2.3c-2.7,2.8-8.1,1.9-9.6-1.6
	c-0.4-1.1-0.1-2.5-0.1-3.7c0-0.2,0.4-0.6,0.6-0.6c2.4-0.7,4.1-2.3,5.2-4.3C24.7,150.4,26,150.6,27.3,150.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M27.8,133.1c-0.6,1.2-1.2,2.2-1.7,3.3c-0.7,1.7-0.3,3.4,0.1,5.2c0.1,0.3-0.2,0.9-0.4,1.1
	c-3.2,2-8.4-0.7-8.6-4.5c-0.1-1.9,0.8-3.5,2.2-4.7C21.6,131.5,25.6,130.8,27.8,133.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M32.7,180.2c-1.8,0.1-3.3-0.5-4.2-2c-0.9-1.7-0.9-3.5,0.1-5.2c2-3.4,5-3.3,7.7-2.4c1.4,0.5,2,1.9,2.1,3.2
	c0.3,3.8-0.8,6-5.3,6.5C33.1,180.2,32.9,180.2,32.7,180.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M62.1,158.7c-0.1,3.2-1.9,5.7-4.9,6.6c-0.7,0.2-1.5,0.1-2.2,0c-0.7-0.1-1-0.6-1.1-1.5c-0.1-1.4-0.5-2.9-0.8-4.3
	c0-0.3,0.1-0.7,0.2-0.9c1.7-1.3,2.6-3,3.2-5.1c0.1-0.3,0.6-0.6,0.9-0.6C60,153.4,62.2,156.1,62.1,158.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M39.9,176.2c3.7,0.4,5.6,4.1,4.2,7.2c-1.7,3.7-5.8,4.2-8.6,2c-0.8-0.6-1-1.9-1.4-2.9c-0.1-0.2,0.3-0.8,0.6-0.8
	C37.6,181.1,39.4,179.3,39.9,176.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M35.5,144.9c0.6-0.7,1-1.3,1.5-1.7c0.2-0.2,0.7-0.3,1-0.3c1.7,0.3,1.8,0.3,2.2-1.1c2.8-0.2,5.4-0.5,8.2-0.7
	c1.1,1.5-0.2,5-1.5,6c-0.9,0.6-1.8,1.1-2.7,1.4c-1.4,0.5-2.6,0.2-3.8-0.9c-1-0.9-2.4-1.4-3.6-2C36.4,145.4,36,145.1,35.5,144.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M47.9,148.6c0.6-1.1,1-2.1,1.7-3c0.3-0.4,1-0.6,1.5-0.5c2.6,0.4,4.2,2.2,4.3,5.2c0.1,2.4-0.3,4.6-2.1,6.4
	c-0.7,0.7-1.2,0.9-2,0.1c-0.6-0.6-1.3-1-2-1.4c-0.6-0.3-0.7-0.7-0.5-1.3C49.5,152,49.6,150.1,47.9,148.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M35.9,142.1c-0.5,1-1.3,1.5-2.4,1.7c-2,0.5-4.8-0.3-5.7-1.6c-1.4-2-1.3-4.8,0-6.7c0.9-1.3,1.9-2.3,3.4-2.9
	c0.4-0.1,0.8-0.2,1.5-0.3c-1.8,2.9-1.5,5.2,0.9,7.4C34.4,140.5,35.1,141.3,35.9,142.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1}
              fill={this.state.color17 ? this.state.color17 : 'transparent'} onClick={() => {
          this.setState({ color17: color, opacity17: opacity });
        }} d="M29.6,159.9c3.2,1.4,3.2,1.4,8.3,0.3c0,1.2,0.3,2.4-0.1,3.4c-0.3,0.8-1.4,1.4-2.3,1.7c-2,0.6-4.1,0.9-6.2,0.1
	c-0.6-0.2-1.1-0.5-1.6-0.8c-1.5-1-1.4-1.3-0.1-2.4C28.3,161.5,28.9,160.6,29.6,159.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M38.2,165.7c1.3,1.3,2.5,2.7,3.8,3.9c0.6,0.6,1.5,1,2.3,1.1c1,0.2,1.2,0.7,1.1,1.5c-0.3,2.5-2.7,3.8-4.9,2.7
	c-0.2-0.1-0.3-0.2-0.4-0.4c-0.5-1.1-1-2.2-1.5-3.4c-0.6-1.3-1.8-1.8-3.1-2.2c-0.3-0.1-0.9-0.5-0.8-0.7c0.1-0.4,0.4-1,0.7-1.2
	C36.3,166.5,37.3,166.1,38.2,165.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M39.1,188.5c-0.7,1.6-1.8,2.5-3.4,2.9c-0.4,0.1-1.1-0.1-1.4-0.4c-1.5-1.1-3.2-2-5.1-1.8c-0.3,0-0.8-0.2-1-0.5
	c-0.1-0.1,0.2-0.6,0.4-0.9c1.3-1.8,2.1-3.8,2.1-6.2c0.6,0.1,1.2,0.2,1.9,0.3C32.6,186.2,35.3,187.9,39.1,188.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }} d="M40.3,157.9c1.3-2.4,2.3-4.7,1.6-7.1c1.5-0.5,2.9-1,4.3-1.5c0.8-0.3,1.7,0.5,1.7,1.6
	C48.3,155.1,45.1,158.7,40.3,157.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }} d="M12.6,173.2c3.7,2,7.5,1.3,10.2-1.9c0.6-0.7,1-0.4,1.3,0.1c0.6,0.8,0.5,2.6-0.3,3.2c-0.2,0.2-0.6,0.3-1,0.2
	c-3.1-0.4-5.3,1-7,3.4c-0.6,0.9-1.2,0.8-2,0.3C12,177.3,11.6,175,12.6,173.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }} d="M50.3,143.3c-0.3-1-0.5-1.8-0.8-2.8c1.5-0.8,0.6-1.8,0.2-2.8c1.1-0.5,2-1.1,3.1-1.5c0.3-0.1,0.9,0.1,1.2,0.4
	c1.5,1.6,1.5,3.5,1,5.5c-0.1,0.5-0.4,0.9-0.6,1.3c-0.4,0.8-0.9,1.1-1.8,0.7C51.9,143.7,51.1,143.6,50.3,143.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity23 ? this.state.opacity23 : 1}
              fill={this.state.color23 ? this.state.color23 : 'transparent'} onClick={() => {
          this.setState({ color23: color, opacity23: opacity });
        }} d="M27.4,143.7c1.8,1.5,4,2.1,6.4,1.7c-1.3,0.6-2.6,1.2-4,1.8c-0.3,0.1-0.6,0.3-0.7,0.6c-1,1.8-2.7,1.3-4.2,1.2
	c-0.3,0-0.8-0.7-0.9-1.1c-0.2-0.9-0.2-1.9-0.3-2.8C24.9,144.7,26.1,144.2,27.4,143.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }} d="M27.9,126.6c0.3-2.4,0.6-5.2,1.2-8c0.2-1.1,1.1-2.2,1.8-3.2c0.8-1,2.3-0.8,3,0.4c0.1,0.1,0.1,0.3,0.1,0.5
	c0,0.8-1.4,2.5-2.2,2.7c-1,0.2-1.4,0.7-1.6,1.7c-0.4,1.8-1.2,3.6-1.4,5.4c-0.2,1.6,0.3,3.2,0.5,4.9c0,0.4,0.1,0.7,0,1.1
	c0,0.1-0.6,0.2-0.6,0.2c-0.2-0.3-0.4-0.7-0.5-1.1C28.2,129.7,28.1,128.4,27.9,126.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1}
              fill={this.state.color25 ? this.state.color25 : 'transparent'} onClick={() => {
          this.setState({ color25: color, opacity25: opacity });
        }} d="M30,167.3c0.8,0,1.6,0,2.4,0c0.3,0,0.5,0.3,0.8,0.5c-0.2,0.3-0.3,0.8-0.6,0.8c-3.8,0.7-5.6,3.1-6,6.7
	c0,0.1-0.1,0.1-0.1,0.2c-0.8-0.1-1.2-0.4-0.9-1.4c0.2-0.8,0-1.8,0.1-2.7c0-0.4,0.2-0.8,0.5-1.1C27.4,169.4,28.7,168.4,30,167.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1}
              fill={this.state.color26 ? this.state.color26 : 'transparent'} onClick={() => {
          this.setState({ color26: color, opacity26: opacity });
        }} d="M27.9,167.2c-0.8,0.7-2,1.8-4,2.3c0.7-2.1,1-3.9-0.2-5.6c0.9-0.5,1.6-0.4,2.1,0.5
	C26.4,165.3,27.1,166.2,27.9,167.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M26.5,189.8c-0.9,1.1-1.9,2.5-3,3.9c-0.1,0.2-0.6,0.3-0.8,0.2c-0.8-0.5-1.4-1.2-1.3-2.2
	c0.1-0.9,0.4-1.2,1.4-1.2C24.1,190.4,25.4,190,26.5,189.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity28 ? this.state.opacity28 : 1}
              fill={this.state.color28 ? this.state.color28 : 'transparent'} onClick={() => {
          this.setState({ color28: color, opacity28: opacity });
        }}
              d="M16.1,154.9c0,1.3,0,2.7,0,4.1c-0.4,0.1-0.9,0.2-1.4,0.4C13.8,156.9,14.3,155.3,16.1,154.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity29 ? this.state.opacity29 : 1}
              fill={this.state.color29 ? this.state.color29 : 'transparent'} onClick={() => {
          this.setState({ color29: color, opacity29: opacity });
        }} d="M35.1,130.8c-0.5,1.1-1.3,0.5-2,0.4c-0.7-0.1-1.5-0.1-2.4-0.2c-0.1-0.2-0.3-0.6-0.6-1.3c1,0,1.9,0.1,2.8-0.2
	C34.1,129.3,34.4,130.2,35.1,130.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity30 ? this.state.opacity30 : 1}
              fill={this.state.color30 ? this.state.color30 : 'transparent'} onClick={() => {
          this.setState({ color30: color, opacity30: opacity });
        }} d="M51.7,202.8c3.4,1.7,6.8,3.5,10.2,5c4,1.7,8.2,2.5,12.5,3.1c5.1,0.7,10.3,0.9,15.4,0.8
	c6.3-0.2,12.5-0.3,18.4-2.8c3.7-1.6,7.6-2.9,11.4-4.3c0.5-0.2,1.3-0.3,1.6,0c0.6,0.5,0.2,1.2-0.2,1.8c-3.3,4.7-7.7,7.9-13,9.9
	c-6.6,2.4-13.4,3.7-20.2,4.7c-3.2,0.5-6.2-0.3-9.2-1c-4.3-1-8.5-2.5-12.5-4.5c-2.3-1.2-4.4-2.7-6.5-4.3c-2.5-1.9-4.9-3.9-7.3-5.9
	C51.2,204.2,51,203.5,51.7,202.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity31 ? this.state.opacity31 : 1}
              fill={this.state.color31 ? this.state.color31 : 'transparent'} onClick={() => {
          this.setState({ color31: color, opacity31: opacity });
        }} d="M122.8,207c0,0.4,0.2,0.9,0,1.2c-4.3,6.2-9,12-15.7,15.8c-4.6,2.7-9.5,4.6-14.6,6.1c-1.2,0.4-2.6,0.5-3.8,0.3
	c-2.9-0.4-5.9-0.8-8.7-1.5c-2.9-0.7-5.9-1.5-8.6-2.8c-3.3-1.6-6.9-2.8-9.7-5.4c-1.8-1.7-3.8-3.3-5.3-5.3c-2-2.7-3.5-5.8-5.1-8.7
	c0.1-0.1,0.2-0.1,0.3-0.2c0.3,0.2,0.6,0.4,0.8,0.6c3.5,3.5,7.7,6.2,11.9,8.9c2.1,1.4,4.6,2.3,7,3.1c3.5,1.2,7.1,2.4,10.7,3.1
	c2.5,0.5,5.1,0.4,7.6,0c4.8-0.8,9.7-1.7,14.4-3c5.8-1.5,11.2-4,15.5-8.3c1.2-1.2,2.1-2.6,3.2-3.9C122.6,207,122.7,207,122.8,207z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1}
              fill={this.state.color32 ? this.state.color32 : 'transparent'} onClick={() => {
          this.setState({ color32: color, opacity32: opacity });
        }} d="M48.2,200.2c-2.1-1.1-4-2.2-5.9-3.2c-1-0.5-1.1-1-0.5-1.8c0.5-0.6,1.1-1.2,1.7-1.6c0.3-0.2,1.1,0,1.4,0.4
	c1.2,1.6,2.3,3.3,3.3,4.9C48.4,199.3,48.2,199.9,48.2,200.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity33 ? this.state.opacity33 : 1}
              fill={this.state.color33 ? this.state.color33 : 'transparent'} onClick={() => {
          this.setState({ color33: color, opacity33: opacity });
        }} d="M49.6,199.6c1.2,1.6,1.2,2.2,0.3,3.2c-0.1,0.2-0.5,0.4-0.6,0.3c-0.2-0.2-0.6-0.6-0.6-0.8
	C48.9,201.5,49.3,200.7,49.6,199.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M122.6,203.4c1.9,0.3,2.5,1.5,1.5,2.8C123.6,205.3,123.1,204.5,122.6,203.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity35 ? this.state.opacity35 : 1}
              fill={this.state.color35 ? this.state.color35 : 'transparent'} onClick={() => {
          this.setState({ color35: color, opacity35: opacity });
        }} d="M57.6,125.8c0.6-1.2,1.3-2.3,1.8-3.3c1.5,0.4,2.8,0.9,4.1,1.2c2.3,0.5,4.6,0.9,6.9,1.2c4.7,0.8,9.5,0.4,14.2,0
	c4.7-0.5,9.2-1.8,13.6-3.6c1-0.4,2-0.8,3-1.1c3-0.8,5.5-2.7,8.1-4.4c2.6-1.6,5.2-3.4,7.9-4.9c0.8-0.5,2-0.3,3.4-0.4
	c-0.4,0.9-0.6,1.5-0.9,2.1c-3.1,5.4-7.5,9.5-12.4,13.2c-3.2,2.4-6.5,4.3-10.3,5.3c-3.2,0.8-6.4,1.5-9.7,2.2
	c-1.8,0.4-3.5,0.6-5.3,0.9c-2.8,0.4-5.5,0.1-8.2-0.4c-4.3-0.8-8.2-2.2-11.8-4.6C60.4,128.4,59.1,127.1,57.6,125.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1}
              fill={this.state.color36 ? this.state.color36 : 'transparent'} onClick={() => {
          this.setState({ color36: color, opacity36: opacity });
        }} d="M57.9,127.9c3.9,3.2,8.4,5,13.2,6.5c6.4,1.9,12.6,0.9,18.8-0.5c3.7-0.8,7.5-1.7,11.1-2.9
	c4.6-1.6,8.4-4.7,11.8-8c2.7-2.6,5-5.6,7.4-8.5c0.5-0.6,0.8-1.4,1.1-2.2c0.1,0,0.2,0,0.3,0.1c-0.1,1,0,2.1-0.4,3
	c-1.8,3.9-3.7,7.9-5.8,11.7c-1.9,3.5-4.9,6.1-8.1,8.5c-3.6,2.6-7.5,4.1-12,4.4c-2,0.1-4,0.7-6,0.6c-3-0.1-6-0.4-8.9-1
	c-4.4-0.9-8.7-2.1-12.7-4.2c-2.2-1.2-4.2-2.7-6.2-4.2c-1.2-0.8-2.2-1.8-3.3-2.8C58.1,128.2,58,128.1,57.9,127.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity37 ? this.state.opacity37 : 1}
              fill={this.state.color37 ? this.state.color37 : 'transparent'} onClick={() => {
          this.setState({ color37: color, opacity37: opacity });
        }} d="M120.8,109.7c1-0.3,2-0.6,3-0.9c2.2-0.7,4.3-0.1,6.4,0.4c1.3,0.3,1.4,0.6,0.8,1.7c-0.1,0.2-0.2,0.4-0.3,0.5
	c-0.9,1.5-0.9,1.4-2.5,0.6c-2.1-1.1-4.2-2.2-6.7-1.6c-0.2,0-0.4-0.1-0.7-0.2C120.9,110.1,120.8,109.9,120.8,109.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity38 ? this.state.opacity38 : 1}
              fill={this.state.color38 ? this.state.color38 : 'transparent'} onClick={() => {
          this.setState({ color38: color, opacity38: opacity });
        }} d="M134.6,117.5c-1.1,2.1-2.3,4.3-3.4,6.4c-0.8,1.5-1.7,3-2.1,4.7c-1.2,3.9-2.6,7.7-2.6,12c0,4.3-0.9,8.7-0.9,13
	c-0.1,7.8,0.5,15.5,2.7,23c0.7,2.4,1.4,4.8,2.1,7.2c0.1,0.3,0.1,0.6-0.1,0.9c-0.4,0.6-0.9,1.1-1.5,2c-1.4-1.9-2.8-3.6-3.9-5.5
	c-3.6-6-6.3-12.3-8.3-19c-2.3-7.6-3-15.2-1.5-22.9c1.3-6.5,4.5-12.1,9.6-16.3c2.5-2,5.4-3.5,8.2-5.2c0.5-0.3,1.1-0.3,1.7-0.4
	C134.4,117.3,134.5,117.4,134.6,117.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity39 ? this.state.opacity39 : 1}
              fill={this.state.color39 ? this.state.color39 : 'transparent'} onClick={() => {
          this.setState({ color39: color, opacity39: opacity });
        }} d="M133.2,184.6c-1.8-0.4-2.1-1.8-2.3-3c-1.3-5.4-2.8-10.7-3.5-16.1c-1-7.3-0.9-14.7,0.1-22c0.3-1.8,0-3.8,0.4-5.6
	c0.7-3.2,1.5-6.5,2.7-9.5c1.3-3.3,3.2-6.4,4.8-9.6c0.3-0.6,0.6-0.7,1.1-0.4c0.2,0.1,0.5,0.2,0.7,0.2c1.4,0,2.1,0.6,2,2
	c-0.2,1.4-0.5,2.9-1,4.3c-1.4,4.8-3.5,9.4-3.9,14.5c-0.2,2.5-0.8,4.9-1.4,7.3c-1,4.7-1.4,9.5-1.2,14.3c0.1,2-0.1,4.1,0.1,6.1
	c0.3,4.6,0.8,9.1,1.2,13.7C133,182.1,133.1,183.3,133.2,184.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity40 ? this.state.opacity40 : 1}
              fill={this.state.color40 ? this.state.color40 : 'transparent'} onClick={() => {
          this.setState({ color40: color, opacity40: opacity });
        }} d="M136.6,116.9c-0.3-0.4-0.5-0.7-0.7-1.1c3.4-2.6,6.9-5,11.2-5.7c0.5,1.7,0.3,2.1-1.4,2.4c-2.5,0.4-5,0.7-7.1,2.3
	C137.9,115.4,137.3,116.1,136.6,116.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity41 ? this.state.opacity41 : 1}
              fill={this.state.color41 ? this.state.color41 : 'transparent'} onClick={() => {
          this.setState({ color41: color, opacity41: opacity });
        }} d="M129.9,186.8c0.6-0.4,1.2-1,1.8-1c0.4,0,1.3,0.7,1.3,1c0,0.6-0.6,1.6-0.9,1.6c-0.7,0-1.5-0.6-2.2-0.9
	C130,187.2,129.9,187,129.9,186.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity42 ? this.state.opacity42 : 1}
              fill={this.state.color42 ? this.state.color42 : 'transparent'} onClick={() => {
          this.setState({ color42: color, opacity42: opacity });
        }} d="M54,79.7c0.7-0.1,1.1-0.2,1.5-0.2c-0.2-0.4-0.2-0.9-0.5-1.2c-0.3-0.5-0.3-0.7,0.3-1c0.3-0.1,0.4-0.7,0.4-1
	c0-0.5-0.3-1-0.1-1.4c0.8-2.6,1.5-5.2,2.6-7.7c1.4-3.2,5.8-5.6,9.3-5.3c0.1,0,0.3,0.1,0.6,0.2c-1.1,0.8-1,1.5-0.2,2.3
	c0.5,0.6,2,0.9,2.6,0.5c0.2-0.1,0.2-0.6,0.3-0.9c0.1-0.3,0.2-0.7,0.3-1c0.3,0.1,0.7,0.2,0.9,0.4c2,2.1,2.6,4.7,2.4,7.5
	c-0.1,1.6-0.5,3.1-0.7,4.7c-0.5,3.2,0.3,6.2,1.6,9c1.3,2.8,2.8,5.6,3.8,8.5c0.7,2.1,0.9,4.4,1.1,6.7c0.4,4.4-1.4,8.1-3.8,11.6
	c-0.8,1.1-2,1.9-3.1,2.5c-2.9,1.6-6,2.1-9.4,1.9c-4-0.2-7.9-0.8-11.5-2.6c-5.3-2.7-8.5-7-9.5-12.9c-0.7-4.2,1.2-7.4,3.9-10.3
	c2.2-2.4,4.5-4.7,6-7.6c0.2-0.4,0.3-1.2,1.2-0.9c0,0,0.3-0.6,0.3-0.9C54.4,80.3,54.1,80,54,79.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity43 ? this.state.opacity43 : 1}
              fill={this.state.color43 ? this.state.color43 : 'transparent'} onClick={() => {
          this.setState({ color43: color, opacity43: opacity });
        }} d="M91.7,77.4c-2.1-2.9-4.9-4.7-8.1-5.6c-3.8-1.2-5-4-5.9-7.3c-0.3-1.2-0.8-2.3-1.2-3.4c1.8-0.4,4.6,1.3,5.1,3.3
	c0.5,1.7,0.9,3.3,2.1,4.7c-0.7-1.9-1.4-3.7-2.1-5.6c0.1-0.1,0.3-0.2,0.4-0.3c0.9,0.8,1.9,1.5,2.7,2.4c0.9,0.9,1.7,1.9,2.6,2.8
	c0.1-0.1,0.2-0.1,0.2-0.2c-0.8-1-1.7-2-2.7-3.2c1.1,0.2,1.9,0.3,2.8,0.4c-0.2-0.1-0.3-0.3-0.4-0.3c-2.4,0-4.1-1.6-5.9-2.8
	c-1.1-0.7-2.3-1.1-3.5-2c0.9-0.2,1.7-0.6,2.6-0.6c1.3-0.1,2.6-0.1,3.8,0.1c3.3,0.5,5.4,2.8,6.9,5.6c1.6,2.9,1.9,6,1.1,9.3
	C92,75.5,91.9,76.5,91.7,77.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity44 ? this.state.opacity44 : 1}
              fill={this.state.color44 ? this.state.color44 : 'transparent'} onClick={() => {
          this.setState({ color44: color, opacity44: opacity });
        }} d="M69,63.8c0-0.3-0.2-0.6-0.1-0.9c0.2-0.6,0.5-1.2,0.8-1.9c1.1-3.2,3.4-5.1,6.5-6.1c0.3-0.1,0.6,0,0.9-0.1
	c0.1,0.1,0.1,0.3,0.2,0.4c-0.9,0.5-1.7,1.1-2.7,1.5c-2,0.8-2.2,1.3-1.5,3.4c-2.4,0-3,1.8-3.7,3.5C69.2,63.8,69.1,63.8,69,63.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity45 ? this.state.opacity45 : 1}
              fill={this.state.color45 ? this.state.color45 : 'transparent'} onClick={() => {
          this.setState({ color45: color, opacity45: opacity });
        }} d="M147.6,87.2c-0.1,0.3-0.2,0.6-0.3,0.7c-1.1-0.4-2.1-0.7-3.1-1.1c-0.3-0.1-0.5-0.5-0.8-0.9
	c1.8-0.4,3.3-0.2,4.6,0.5c4.7,2.4,6.6,7.5,4.8,12.5c-2.2,5.7-8.8,7.9-14,4.5c-0.5-0.3-1-0.6-1.4-0.9c-1.4-1-1.7-1.9-1.2-3.5
	c0.7-2.6,0.6-5.2-0.7-7.7c-0.4-0.7-0.4-1.2,0.1-1.8c1.1-1.4,2.4-2.5,4.2-3c0.4,0.7,0.8,1.4,1.2,2.1c0.1,0.2,0.2,0.6,0.1,0.7
	c-0.6,0.8-0.1,1,0.5,1.3c2.6,1.2,5.5,0.3,6.7-2.2C148.7,87.7,148.6,87.4,147.6,87.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity46 ? this.state.opacity46 : 1}
              fill={this.state.color46 ? this.state.color46 : 'transparent'} onClick={() => {
          this.setState({ color46: color, opacity46: opacity });
        }} d="M126.2,87c-1.2,2-0.2,4.4,1.9,4.7c1,0.2,2,0.4,2.9,0.4c0.6,0,1.1-0.5,1.7-0.7c-0.4-0.4-0.8-0.7-1.3-1.1
	c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2-0.7-0.3-1.4-0.5-2.2c1.4,0.3,2.5,1.1,3.2,2.4c1.5,2.6,2.1,5.3,1.1,8.1c-0.8,2.5-2.9,3.9-5.2,4.8
	c-4.1,1.6-7.5,0.2-10.6-2.7c-3.2-3-2.6-10.6,2.7-13C123.2,87.5,124.7,87.4,126.2,87z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity47 ? this.state.opacity47 : 1}
              fill={this.state.color47 ? this.state.color47 : 'transparent'} onClick={() => {
          this.setState({ color47: color, opacity47: opacity });
        }} d="M136.6,72.8c-0.1,0.3-0.2,0.6-0.3,0.9c-0.7,4-3.2,7.1-5.3,10.4c-0.4,0.6-0.7,1.3-1.1,1.9
	c-0.7,1-0.7,2.1-0.3,3.2c0.4,1.2,0.3,1.5-1,1.3c-1.6-0.2-1.9-0.9-1-2.2c1.1-1.8,2.2-3.6,3.3-5.4c1.3-2.1,2.7-4.3,3.9-6.4
	c0.5-0.9,0.8-2,1.2-3c0.2-0.7,0.7-1,1.3-0.9c0.3,0.1,0.6,0.7,0.7,1.1c0.7,2.3,1.5,4.6,2.1,7c0.6,2.6,1.6,4.9,3,7.1
	c0.2,0.3,0.7,0.3,1.1,0.4c0.4,0.1,0.9,0,1.2,0.2c0.3,0.2,0.5,0.7,0.5,1c0,0.2-0.5,0.4-0.8,0.5c-1.1,0.3-2,0-2.5-1
	c-0.8-1.4-1.6-2.9-2.2-4.4c-0.9-2.4-1.7-4.8-2.4-7.2c-0.3-0.9-0.2-1.9-0.4-2.8c-0.1-0.4-0.2-0.8-0.3-1.2
	C137,73.1,136.8,72.9,136.6,72.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity48 ? this.state.opacity48 : 1}
              fill={this.state.color48 ? this.state.color48 : 'transparent'} onClick={() => {
          this.setState({ color48: color, opacity48: opacity });
        }} d="M136,68.1c0-1.7,0.2-1.9,1.5-1.5c0.3,0.1,0.6,0.5,0.7,0.8c0.2,0.8,0.3,1.6,0.3,2.5c0,0.3-0.2,0.8-0.4,0.8
	c-0.4,0-1.1,0-1.3-0.2c-0.5-0.7-0.8-1.5-1.3-2.2C135.8,68.2,135.9,68.1,136,68.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity49 ? this.state.opacity49 : 1}
              fill={this.state.color49 ? this.state.color49 : 'transparent'} onClick={() => {
          this.setState({ color49: color, opacity49: opacity });
        }} d="M80.9,166.7c-0.1-0.1-0.6-0.2-0.8-0.4c-0.1-0.1,0-0.6,0.1-0.8c-0.3-0.2-0.7-0.4-0.9-0.8c-1.5-2.6-2-5.6-2.1-8.5
	c-0.1-3.1,1.7-5.3,4.4-6.6c1.5-0.7,3.1-1.2,4.7-1.6c0.4-0.1,1,0.2,1.5,0.4c0.2,0.1,0.3,0.3,0.5,0.3c2-0.2,2.4,1.5,3.2,2.7
	c1,1.7,1.7,3.6,2.9,5.2c1.9,2.8,4.9,4.2,8,5.5c3.9,1.6,7.8,3.3,11.1,6.1c1.9,1.7,3.3,3.7,4.1,6.1c0.7,1.8,1.4,3.7,1.7,5.6
	c0.3,1.5,0.2,3.1,0,4.6c-0.5,3.4-2.1,6.5-4,9.4c-1.7,2.5-4.4,3.7-7.7,2.9c0.3-0.1,0.7-0.3,1-0.3c1.4,0,2.6-0.3,3.4-1.6
	c0.1-0.2,0.6-0.3,0.9-0.5c0.3-0.2,0.5-0.4,0.8-0.6c-0.3-0.2-0.5-0.5-0.8-0.5c-1.2-0.1-2.6-0.6-3.4-0.1c-1.6,0.9-2.9,2.5-4.2,3.8
	c-0.2,0.2-0.3,0.4-0.4,0.6c-1.9,3.9-5.5,4-9.1,3.6c-3.3-0.3-6.1-1.8-8-4.6c-2-2.9-2.6-6.2-2.7-9.7c-0.2-5.1-0.1-10.2-1.9-15
	c-0.5-1.3-1.1-2.5-1.8-3.7C80.7,167.7,80.9,167.1,80.9,166.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity50 ? this.state.opacity50 : 1}
              fill={this.state.color50 ? this.state.color50 : 'transparent'} onClick={() => {
          this.setState({ color50: color, opacity50: opacity });
        }} d="M80.7,148.7c-1.7-3.2-3.5-5.7-7.6-4.8c0.2-0.9,0.4-1.4,1.3-1.2c2.3,0.7,4.4,1.8,6,3.6
	C80.8,147,81.1,147.7,80.7,148.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity51 ? this.state.opacity51 : 1}
              fill={this.state.color51 ? this.state.color51 : 'transparent'} onClick={() => {
          this.setState({ color51: color, opacity51: opacity });
        }} d="M171.2,130.6c0.7-0.5,1.3-0.9,2-1.4c-0.1-0.2-0.2-0.3-0.2-0.5c-0.7,0.2-1.4,0.4-2.2,0.6
	c-2.9,0.8-5.7,1.7-8.6,2.4c-5.9,1.5-12.4-3-12.5-9.9c-0.1-6.4,1.5-12.1,6-16.9c0.4-0.5,0.7-1.1,1.1-1.7c0.3,0,0.7,0,1.3,0.1
	c-0.5-0.3-0.7-0.4-1.3-0.8c0.5-0.6,1-1.1,1.4-1.6c-0.1,0-0.6-0.3-0.5-0.4c0.1-0.4,0.4-0.8,0.6-1.1c-0.1-0.3-0.5-0.8-0.4-1
	c0.2-0.5,0.8-0.8,1.1-1.1c-0.2,0-0.6,0-1.1,0c0.2-0.8-0.7-1.8,0.5-2.2c-0.2-0.3-0.6-0.5-0.6-0.8c-0.4-3.6-0.8-7.2,0.3-10.7
	c0.6-1.8,1.3-3.6,2.9-4.7c1.1-0.8,2.3-1.5,3.7-0.8c0.1,0,0.2,0.1,0.2,0.1c2-0.3,3.4,0.8,4.6,2.3c1.1,1.4,1.9,3,1.9,4.9
	c0.1,1.9,0.4,3.7,0.6,5.6c0.3,2.2,1.7,3.7,3.1,5.3c1.7,2,3.7,3.9,5,6.1c3.9,6.3,6,13,3.1,20.4c-1.5,3.7-4,6.7-8.1,7.6
	c-1.3,0.3-2.6,0.3-3.9,0.4C171.3,130.8,171.2,130.7,171.2,130.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity52 ? this.state.opacity52 : 1}
              fill={this.state.color52 ? this.state.color52 : 'transparent'} onClick={() => {
          this.setState({ color52: color, opacity52: opacity });
        }} d="M165.3,76.3c0.3-1.7,0.4-3.3,0.9-4.9c0.2-0.8,0.9-1.6,1.9-1.3c-0.8,2.1-1.6,4.2-2.4,6.3
	C165.5,76.4,165.4,76.3,165.3,76.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1}
              fill={this.state.color53 ? this.state.color53 : 'transparent'} onClick={() => {
          this.setState({ color53: color, opacity53: opacity });
        }} d="M177.8,151.3c0,0,0.2,0.1,0.5,0.3c-0.1,1.5-1.2,1.4-2.3,1c0,0.1,0,0.2,0,0.3c0.4,0.1,0.9,0.3,1.4,0.4
	c-0.2,0.5-0.4,1-0.6,1.5c-0.4-0.1-0.8-0.2-1.2-0.2c0,0.1-0.1,0.1-0.1,0.2c0.4,0.2,0.7,0.4,1.1,0.6c0,0.1,0.1,0.2,0,0.2
	c-0.7,1-0.3,2.2-0.3,3.3c0,1.2,0.1,2.4,0.2,3.6c0.1,1.2,0.4,2.5,0.5,3.7c0.7,5.4-0.5,10.3-5.2,13.6c-3.8,2.7-8.1,4.1-12.7,2.1
	c-1.2-0.6-2.2-1.7-3.3-2.7c-1.2-1.1-2.4-2.1-3.4-3.3c-1.9-2.2-4.8-2.7-7.1-4.1c-3.2-2.1-4.5-4.9-4.8-8.5c-0.3-4,0.6-7.9,2.5-11.4
	c1.7-3,4-5.5,7.5-6.5c2.9-0.8,5.8-1.6,8.5-2.7c2.7-1.2,5.2-2.9,7.9-4.3c4.1-2.1,8.4-3.5,13.1-2.7c0.3,0,0.6,0.1,0.8,0
	c2.1-0.5,3.3,0.8,4.3,2.2c1.1,1.7,1.2,3.6,0.1,5.3c-0.5,0.8-1.2,1.4-1.8,2.1c-1.4,1.7-2.8,3.4-4.3,5
	C179,150.7,178.4,150.9,177.8,151.3z M154.7,174.4c-0.3,2,0.4,2.8,2.3,2.8c0.2,0,0.3,0,0.5,0c0.6-0.2,1,0,1.2,0.6
	c0.2,0.7,0.7,1,1.2,0.6c0.2-0.2,0.3-1,0.1-1.3c-1.8-2.8-4.6-4.1-7.9-4.3c-0.3,0-0.6,0.3-0.8,0.5c0.2,0.2,0.4,0.5,0.6,0.6
	C152.9,174,153.8,174.2,154.7,174.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity54 ? this.state.opacity54 : 1}
              fill={this.state.color54 ? this.state.color54 : 'transparent'} onClick={() => {
          this.setState({ color54: color, opacity54: opacity });
        }} d="M185.3,135.9c0.5-0.8,1-1.6,1.4-2.4c0.9-1.7,0.8-2.8-0.6-4.2c-0.2-0.2-0.6-0.4-0.6-0.6c0.1-0.6,0.3-1.2,0.5-1.8
	c0.3,0.3,0.7,0.5,0.9,0.8c0.2,0.3,0.2,0.7,0.4,1c1.7,2.6,0.1,4.6-1.1,6.7c-0.2,0.3-0.5,0.5-0.8,0.7
	C185.5,136.1,185.4,136,185.3,135.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity55 ? this.state.opacity55 : 1}
              fill={this.state.color55 ? this.state.color55 : 'transparent'} onClick={() => {
          this.setState({ color55: color, opacity55: opacity });
        }} d="M146.9,33.4c-0.4-0.2-0.7-0.4-1.1-0.6c0.1-1.3,1.4-0.4,2.1-1.2c-0.4-0.2-0.8-0.4-1.5-0.7c0.5-0.5,0.9-1,1.4-1.5
	c-1-1,0.1-2.1,0.4-3.3c0.5-2.2,1-4.4,2.6-6.2c2.4-2.8,5.5-4,9.2-3.6c2,0.2,3.9,2.6,3.9,5.3c0,2.3-0.3,4.7-0.6,7
	c-0.2,2,0,3.9,1.1,5.6c1.2,1.8,2.5,3.6,3.5,5.6c1,1.9,1.8,4,2.5,6.1c1.1,3.1,0.8,6.2-0.1,9.2c-1.4,4.8-3.9,8.8-8.6,11
	c-3.8,1.8-7.6,2.1-11.6,1c-1.6-0.5-3.4-0.7-4.9-1.6c-3.2-2-6.5-4-7.9-7.8c-0.5-1.4-1.3-2.7-1.7-4.2c-0.6-2.3-0.1-4.5,0.7-6.7
	c1.3-3.7,3.2-7,6.3-9.6c0.4-0.4,0.8-0.8,1.1-1.2c0.9-1,1.3-2.6,3.3-2C146.9,33.7,146.9,33.6,146.9,33.4z M147.9,63
	c-0.1,0-0.6,0-0.9,0.1c-0.4,0.2-0.7,0.6-1.1,1c0.4,0.1,0.9,0.2,1.3,0.3c0.4,0.1,0.9,0.1,1.1,0.4c1.3,1.9,2.7,2.4,4.6,1.3
	c0.6-0.3,1-0.4,1.4,0.1c0.4,0.5,1,0.5,1.3-0.1c0.1-0.3,0-0.9-0.3-1.1C153.3,63.4,150.8,63,147.9,63z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity56 ? this.state.opacity56 : 1}
              fill={this.state.color56 ? this.state.color56 : 'transparent'} onClick={() => {
          this.setState({ color56: color, opacity56: opacity });
        }}
              d="M166,12.1c-1.8,1.2-3.3,2.1-4.9,3.2c0.3-2,1.6-2.9,2.8-3.8C164.3,11.2,165.2,11.8,166,12.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity57 ? this.state.opacity57 : 1}
              fill={this.state.color57 ? this.state.color57 : 'transparent'} onClick={() => {
          this.setState({ color57: color, opacity57: opacity });
        }} d="M149.6,188.6c-1,0.2-2.1,0.3-3.1,0.6c-0.4,0.1-0.8,0.6-1.1,0.9c0.4,0.2,0.9,0.6,1.2,0.5
	c1.9-0.5,3.8-1.2,5.7-1.6c1.6-0.3,3.3-0.6,4.9-0.5c3.7,0.3,7,2,9.8,4.3c3.5,2.9,3.9,7,3.3,11.2c-0.6,4.2-2.1,8.1-5.1,11.3
	c-1.8,2-3.5,4.1-5.3,6c-1.5,1.5-3.4,2-5.5,1.8c-0.7,0-1.6-0.1-2.2,0.2c-2.3,1.2-4.5,0.4-6.8-0.1c-3.7-0.8-6.4-3.2-8.8-5.9
	c-3.7-4.1-5.7-8.9-5.4-14.5c0.1-1.4,0.2-2.8,0.4-4.2c0.7-5.5,6.6-10.6,12.2-11c1.9-0.2,3.8,0.2,5.6,0.4
	C149.6,188.3,149.6,188.5,149.6,188.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity58 ? this.state.opacity58 : 1}
              fill={this.state.color58 ? this.state.color58 : 'transparent'} onClick={() => {
          this.setState({ color58: color, opacity58: opacity });
        }}
              d="M151.5,187.9c-0.5-1.8-0.8-3.5-1.6-5c-0.7-1.5-2-2.7-2.9-4C149.6,178.9,152.5,184.4,151.5,187.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity59 ? this.state.opacity59 : 1}
              fill={this.state.color59 ? this.state.color59 : 'transparent'} onClick={() => {
          this.setState({ color59: color, opacity59: opacity });
        }} d="M81.7,17.1c2.4,0,4.7,0.1,6.9,0c1.6,0,2.9,0.5,4,1.5c3.2,2.8,5,6.1,4.9,10.5c-0.1,3.5-1.4,6.2-3.1,9
	c-2.3,4-5.4,7.3-8.9,10.2c-1.6,1.3-3.6,1.7-5.6,1.6c-0.9,0-1.7-0.7-2.5-1c-0.7-0.3-1.3-0.6-1.9,0.2c-0.1,0.1-0.3,0.1-0.5,0.1
	c-5.1-0.6-8.5-3.3-10.4-8c-1-2.4-2.5-4.6-3.3-7c-1.1-3.4-1.8-6.9-0.1-10.5c0.8-1.9,1.9-3.2,3.6-4.3c2.2-1.4,4.2-3.1,6.8-3.4
	c2-0.2,4,0,6-0.1c0.5,0,0.9-0.1,1.5-0.2c-0.1,0.7-0.1,1.5-0.2,2.5c-0.6-0.4-1.1-0.6-1.5-0.8c-0.2-0.1-0.6-0.1-0.7,0
	c-0.1,0.2-0.2,0.5-0.1,0.7c0.2,0.3,0.4,0.6,0.7,0.8c2.6,1.6,5.3,1.8,8.2,1c0.2-0.1,0.4-0.4,0.6-0.6c-0.3-0.2-0.5-0.4-0.8-0.4
	c-0.6,0-1.2,0.1-1.8,0.2c-1.6,0.2-1.9,0-1.7-1.6C81.6,17.3,81.7,17.1,81.7,17.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity60 ? this.state.opacity60 : 1}
              fill={this.state.color60 ? this.state.color60 : 'transparent'} onClick={() => {
          this.setState({ color60: color, opacity60: opacity });
        }}
              d="M84.4,12.4c-2.3,0.5-3.2,2.3-4.2,4.4c-0.1-1.9,0.1-3.4,1.2-4.5C82.3,11.3,82.7,10.6,84.4,12.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity61 ? this.state.opacity61 : 1}
              fill={this.state.color61 ? this.state.color61 : 'transparent'} onClick={() => {
          this.setState({ color61: color, opacity61: opacity });
        }} d="M40.1,31.3c-0.9,0.1-1.8,0.2-2.6,0.4c-0.6-0.8-0.3-1.2,0.5-1.5c3.6-1.5,7.4-1.9,11.3-1.1
	c2.3,0.5,3.5,2.4,4.5,4.4c2.4,5.2,1.9,10.6,0.3,15.9c-1.2,3.9-3.5,7.1-6,10.1c-2.2,2.6-5,3.7-8.4,2.5c0.5-0.4,0.9-0.7,1.4-1.1
	c0-0.1-0.1-0.2-0.1-0.3c-0.3,0-0.6-0.1-0.9,0c-1,0.4-2.1,0.8-3,1.4c-3.4,2.1-6.9,2.5-10.4,0.3c-1.1-0.7-2-1.9-2.7-3.1
	c-3-4.6-5-9.6-6.1-15c-0.5-2.6-0.4-5.1,0.7-7.5c1.1-2.3,2.9-3.8,5.2-4.6c0.9-0.3,1.7-0.6,2.6-0.9c2.1-0.8,3.9,0.3,5.8,0.9
	c2.5,0.8,4.9,0.8,7.4,0.2c0.4-0.1,0.7-0.4,1-0.6C40.3,31.6,40.2,31.5,40.1,31.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity62 ? this.state.opacity62 : 1}
              fill={this.state.color62 ? this.state.color62 : 'transparent'} onClick={() => {
          this.setState({ color62: color, opacity62: opacity });
        }} d="M36,31.8c-1.3,0.3-1.3-0.7-1.3-1.4c0.1-3,1.1-5.6,2.9-8c0-0.1,0.1-0.1,0.2-0.2c0.5-0.4,1.1-0.9,1.6-1.3
	c0.4,0.6,0.9,1.2,1.4,1.9C36.5,24.5,35.8,27.9,36,31.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity63 ? this.state.opacity63 : 1}
              fill={this.state.color63 ? this.state.color63 : 'transparent'} onClick={() => {
          this.setState({ color63: color, opacity63: opacity });
        }} d="M108.3,37.1c0.3,0.2,0.6,0.4,0.9,0.5c2.1,0.5,4.2,0.8,6.4-0.2c2.7-1.3,5.6-1.7,8.3-0.3
	c4.7,2.4,6.8,5.9,6.5,11.9c-0.3,5.6-3,10.1-6,14.5c-1.3,1.9-2.8,3.6-4.9,4.7c-0.8,0.4-1.8,0.4-2.7,0.6c-1.1,0.2-2.2,0.1-3.2,0.4
	c-2.6,0.9-5.1,0.6-7.4-0.8c-1.6-1-3.2-2.1-4.7-3.3c-3-2.2-4.9-5.3-5.5-8.8c-0.6-3.3-0.9-6.6-0.9-10c0-3.9,2.3-6.6,5.5-8.6
	c1.3-0.8,2.6-1.5,4-2.1c1.8-0.8,3.7-0.5,5.6-0.3c0.6,0.1,1.2,0.2,1.8,0.3c0.1,0.4,0.2,0.7,0.3,1.3c-1.3-0.1-2.4-0.2-3.5-0.3
	C108.5,36.8,108.4,37,108.3,37.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity64 ? this.state.opacity64 : 1}
              fill={this.state.color64 ? this.state.color64 : 'transparent'} onClick={() => {
          this.setState({ color64: color, opacity64: opacity });
        }} d="M113.5,36.4c-0.7-2.2-1.8-4.5-1.7-7.2c0-0.8,0.4-1.3,1.2-1.1c0.2,0,0.5,0.8,0.5,1.2
	C113.5,31.6,113.5,34,113.5,36.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity65 ? this.state.opacity65 : 1}
              fill={this.state.color65 ? this.state.color65 : 'transparent'} onClick={() => {
          this.setState({ color65: color, opacity65: opacity });
        }} d="M87.8,104.3c-2-1.9-2.7-4.2-2.3-6.9c1-6.8,5.1-11.4,10.7-14.8c2.7-1.6,5.8-2.1,8.8-0.5c0.1,0.1,0.2,0.2,0.4,0.3
	c-4.1,2.3-8.3,4.3-10.6,8.6c0.1,0.2,0.2,0.3,0.4,0.5c0.3-0.2,0.8-0.4,1-0.7c2.7-3.6,6.5-5.6,10.4-7.3c0.5-0.2,1.2,0.1,1.7,0.2
	c0.7,0.2,1.6,0.2,2.1,0.6c2.3,1.8,3.5,4.2,2.8,7.2c-1.6,7.5-5.8,13.1-12.5,16.8c-2.1,1.2-4.4,1.9-6.9,1.5c-2.8-0.5-4.8-3.2-4.3-6
	c0.1-0.9,0.5-1.7,0.7-2.5c0.1-0.2-0.1-0.5-0.1-0.7c-0.3,0.1-0.7,0.1-0.8,0.3C88.8,101.8,88.4,103,87.8,104.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity66 ? this.state.opacity66 : 1}
              fill={this.state.color66 ? this.state.color66 : 'transparent'} onClick={() => {
          this.setState({ color66: color, opacity66: opacity });
        }} d="M108.9,74.1c0.6-0.1,1-0.2,1.5-0.3c0.4,2.5,0.4,4.9-0.5,7.2c-0.3,0.7-0.6,1.1-1.3,0.4
	C110.2,77.1,110.2,76.8,108.9,74.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity67 ? this.state.opacity67 : 1}
              fill={this.state.color67 ? this.state.color67 : 'transparent'} onClick={() => {
          this.setState({ color67: color, opacity67: opacity });
        }} d="M62.4,180.8c-1.3-0.1-2.5-0.3-3.8-0.3c-1.5,0-3,0.2-4.5,0.2c-0.4,0-0.9-0.4-1.3-0.6c2-3.7,5.3-4.6,9.1-4.6
	c3.6,0,6.8,1.1,9.9,2.7c1.8,0.9,3.6,1.7,5,3.3c1.5,1.7,2.7,5.9,1,7.9c-1.3-2.3-2.8-4.5-5.2-5.7c-0.1,0.1-0.2,0.2-0.2,0.2
	c0.2,0.3,0.5,0.6,0.7,0.8c1.8,1.7,3.2,3.8,3.7,6.3c0.6,2.7-0.1,4.7-2.6,5.9c-1.4,0.7-3.1,1.3-4.8,1.4c-2.7,0.1-5.4-0.1-8.1-0.4
	c-2.5-0.3-4.8-1.5-6.8-3.1c-1.3-1.1-2.5-2.4-3.6-3.8c-1.2-1.5-1.1-3.2-0.7-5c0.7-2.9,2-3.9,5.1-4.1c2-0.1,4-0.2,6-0.3
	c0.3,0,0.6-0.2,1-0.3C62.4,181.1,62.4,181,62.4,180.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity68 ? this.state.opacity68 : 1}
              fill={this.state.color68 ? this.state.color68 : 'transparent'} onClick={() => {
          this.setState({ color68: color, opacity68: opacity });
        }}
              d="M51.4,181.4c-2.2-1.1-3.5-4.6-2.7-6.7c0.4-1,1-0.9,1.9-0.4C49.8,176.7,49.2,179.2,51.4,181.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity69 ? this.state.opacity69 : 1}
              fill={this.state.color69 ? this.state.color69 : 'transparent'} onClick={() => {
          this.setState({ color69: color, opacity69: opacity });
        }} d="M32.9,72.2c-0.8,0.8-0.8,0.8,0,2.7c-1.4,0.5-2.7,1-4.1,1.6c-0.5,0.2-1.1,0.4-1.5,0.7c-0.2,0.2-0.2,0.6-0.3,0.9
	c0.3,0.1,0.7,0.3,0.9,0.2c1.4-0.5,2.7-1,4-1.6c2.1-1,4.2-0.3,6.3,0.1c1.1,0.2,2,2.4,1.9,4.1c-0.4,4.9-2.5,9-5.7,12.6
	c-2.1,2.2-4.5,4-7.3,5.1c-3.1,1.3-7.5,1.4-10.2-1c-1.9-1.8-1.4-3.9-1-6c0.2-0.9,0.7-1.7,1.1-2.6c-1.1-0.4-1.1-0.4-2.9,3.8
	c-0.2-1.1-0.5-2.2-0.6-3.3c-0.2-3.7,1-6.9,3.2-9.9c1.7-2.5,3.9-4.4,6.3-5.9c2.6-1.6,5.5-2.6,8.6-2C32.1,71.8,32.4,72,32.9,72.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity70 ? this.state.opacity70 : 1}
              fill={this.state.color70 ? this.state.color70 : 'transparent'} onClick={() => {
          this.setState({ color70: color, opacity70: opacity });
        }} d="M36.6,68.8c1.1,1.2,0.3,3.6-1.3,4.6C35.8,71.8,36.2,70.3,36.6,68.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity71 ? this.state.opacity71 : 1}
              fill={this.state.color71 ? this.state.color71 : 'transparent'} onClick={() => {
          this.setState({ color71: color, opacity71: opacity });
        }}
              d="M149.9,64.3c0.7,0.2,1.5,0.4,2.2,0.6c-1,0.6-1.8,0.7-2.4-0.2C149.8,64.6,149.8,64.5,149.9,64.3z" />
      </SvgIcon>
    )
  }
};