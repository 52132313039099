import No from "../No";

export const getPageMath3WorkingsNotesDemo = (page, edition) => {
  let EditionPageContent;
  let editable = true;
  switch (page) {
    case 1:
      editable = false;
      EditionPageContent = No;
      break;
    default:
      EditionPageContent = No;
      break;
  }
  return {
    editable,
    component: EditionPageContent,
    addProps: {}
  };
}