import axios from 'axios';
import _ from "lodash";

export default class Axios {
  // public baseUrl: string = process.env.NODE_ENV !== 'production' ? 'http://localhost/xdebug/PHP/' : 'https://atos.rs/digital/php/';
  public baseUrl: string = 'https://atos.rs/digital/php/';
  public checkDigitalCodeUrl: string = 'https://atos.rs/api/checkDigitalCode';
  public drawingImageFolder: string = 'pagesImages/'
  private file;
  private acceptedTypes: string[] = [
    'image/png',
    'image/jpg',
    'image/jpeg',
  ];
  private userName: string;
  private edition: string;

  constructor(userName: string = 'admin', edition: string = 'math-1-working-notes') {
    this.userName = userName;
    this.edition = edition;
  }

  public handleFileUpload = (name: string, data: any, callBackFn) => {
    const formData = new FormData();
    formData.append(name, data);
    formData.append('name', name);
    formData.append('userName', this.userName);
    formData.append('edition', this.edition);

    axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      url: `${this.baseUrl}UploadImage.php`,
      onUploadProgress: (ev: ProgressEvent) => {
        const progress = ev.loaded / ev.total * 100;
      },
    })
      .then((resp) => {
        callBackFn();
        /*this.getBase64(this.file, (uri: string) => {
          setImageURI(uri);
        });*/
      })
      .catch((err) => console.error(err));
  }

  private getBase64 = (img: Blob, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  private isValidFileType = (fileType: string): boolean => {
    return this.acceptedTypes.includes(fileType);
  };

  public postRequest = (data: any, callBackFn?, errorFn?, callBackArg?) => {
    const params = new URLSearchParams();
    params.append('userName', this.userName);
    params.append('edition', this.edition);
    for (let prop in data) {
      if (data.hasOwnProperty(prop)) {
        params.append(prop, data[prop]);
      }
    }
    axios.post(`${this.baseUrl}Request.php`, params)
      .then((response) => {
        if(_.isFunction(callBackFn)) {
          callBackFn(response, callBackArg);
        }
      }, (error) => {
        if(_.isFunction(errorFn)) {
          errorFn(error);
        }
      });
  }

  public jsonRequestOld = (name: string, callBackFn, errorFn?) => {
    axios.get(`${this.baseUrl}${this.drawingImageFolder}admin/${this.edition}/${name}.json`)
      .then((adminResponse) => {
        if (this.userName === 'admin') {
          callBackFn(adminResponse, name);
        } else {
          axios.get(`${this.baseUrl}${this.drawingImageFolder}${this.userName}/${this.edition}/${name}.json`)
            .then((response) => {
              callBackFn(response, name, adminResponse);
            }, (error) => {
              if (errorFn) {
                errorFn(error);
              }
            });
        }
      }, (error) => {
        if (errorFn) {
          errorFn(error);
        }
      });
  }

  public jsonRequest = (name: string, callBackFn, errorFn?) => {
    const params = new URLSearchParams();
    params.append('userName', this.userName);
    params.append('edition', this.edition);
    params.append('url', `${this.baseUrl}${this.drawingImageFolder}admin/${this.edition}/${name}.json`);
    axios.post(`${this.baseUrl}RequestJSON.php`, params)
      .then((adminResponse) => {
        if (this.userName === 'admin') {
          callBackFn(adminResponse, name);
        } else {
          const userParams = new URLSearchParams();
          userParams.append('userName', this.userName);
          userParams.append('edition', this.edition);
          userParams.append('url', `${this.baseUrl}${this.drawingImageFolder}${this.userName}/${this.edition}/${name}.json`);
          axios.post(`${this.baseUrl}RequestJSON.php`, userParams)
            .then((response) => {
              callBackFn(response, name, adminResponse);
            }, (error) => {
              if (errorFn) {
                errorFn(error);
              }
            });
        }
      }, (error) => {
        if (errorFn) {
          errorFn(error);
        }
      });
  }

  public checkDigitalCode = (code: string, callBackFn, errorFn) => {
    axios({
      method: 'get',
      url: `${this.checkDigitalCodeUrl}?code=${code}`
    })
      .then((resp) => {
        callBackFn(resp);
      })
      .catch((err) => {
        errorFn(err);
      });
  }

}