import { Component } from "react";
import { SvgIcon } from "@material-ui/core";
import React from "react";

export default class Ellipse extends Component<any, any> {

  render() {
    const st0 = "#084B5E";
    const { disabled } = this.props;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-354.6 575.8 53 30.7"}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <g id="XMLID_299_">
          <path id="XMLID_1_" fill={st0} d="M-328.1,606.5c-14.9,0-26.5-6.7-26.5-15.3c0-8.6,11.6-15.4,26.5-15.4s26.5,6.7,26.5,15.4
		C-301.6,599.9-313.3,606.5-328.1,606.5z M-328.1,579.1c-12.6,0-23.2,5.5-23.2,12.1c0,6.6,10.6,12.1,23.2,12.1s23.2-5.5,23.2-12.1
		C-305,584.6-315.6,579.1-328.1,579.1z" />
        </g>
      </SvgIcon>
    )
  }

};