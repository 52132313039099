import { Component } from 'react';
import React from "react";
import { Circle, Ellipse, Rect, Text, Transformer } from 'react-konva';
import _ from "lodash";
import PointDrawable from "./Point";

export default class ShapeDraggable extends Component<any, any> {
  private readonly shapeRef: any;
  private readonly trRef: any;

  constructor(props) {
    super(props);
    this.shapeRef = React.createRef();
    this.trRef = React.createRef();
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (this.props.isSelected) {
      // we need to attach transformer manually
      this.trRef.current.nodes([this.shapeRef.current]);
      this.trRef.current.getLayer().batchDraw();
    }
  }

  render() {
    const { shapeProps, isSelected, onSelect, onChange, shapeType, stageRef, onTextDblClick } = this.props;
    let Shape;
    if (shapeType === 'circle') {
      Shape = Circle;
    } else if (shapeType === 'ellipse') {
      Shape = Ellipse;
    } else if (shapeType === 'text') {
      Shape = Text;
    } else if (shapeType === 'point') {
      Shape = Circle;
    } else {
      Shape = Rect;
    }
    return (
      <React.Fragment>
        <Shape
          onClick={onSelect}
          onTap={onSelect}
          ref={this.shapeRef}
          {...shapeProps}
          draggable={this.props.draggableEnabled}
          onDragEnd={(e) => {
            onChange({
              shapeType,
              ...shapeProps,
              x: e.target.x(),
              y: e.target.y(),
            });
          }}
          onTransformEnd={(e) => {
            // transformer is changing scale of the node
            // and NOT its width or height
            // but in the store we have only width and height
            // to match the data better we will reset scale on transform end
            const node = this.shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            onChange({
              shapeType,
              ...shapeProps,
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(node.height() * scaleY),
              rotation: node.rotation()
            });
          }}
          onDblClick={() => {
            if (shapeType === 'text') {
              onTextDblClick('start');
// hide text node and transformer:
              const textNode = this.shapeRef.current;
              const tr = this.trRef.current;
              const stage = stageRef;
              textNode.hide();
              tr.hide();

              // create textarea over canvas with absolute position
              // first we need to find position for textarea
              // how to find it?

              // at first lets find position of text node relative to the stage:
              var textPosition = textNode.absolutePosition();

              // so position of textarea will be the sum of positions above:
              var areaPosition = {
                x: stage.current.attrs.container.offsetLeft + textPosition.x,
                y: stage.current.attrs.container.offsetTop + textPosition.y,
              };

              // create textarea and style it
              var textarea = document.createElement('textarea');
              stage.current.attrs.container.appendChild(textarea);

              // apply many styles to match text on canvas as close as possible
              // remember that text rendering on canvas and on the textarea can be different
              // and sometimes it is hard to make it 100% the same. But we will try...
              textarea.value = textNode.text();
              textarea.style.position = 'absolute';
              textarea.style.top = areaPosition.y + 'px';
              textarea.style.left = areaPosition.x + 'px';
              textarea.style.width = textNode.width() - textNode.padding() * 2 + 'px';
              textarea.style.height = textNode.height() - textNode.padding() * 2 + 5 + 'px';
              textarea.style.fontSize = textNode.fontSize() + 'px';
              textarea.style.border = 'none';
              textarea.style.padding = '0px';
              textarea.style.margin = '0px';
              textarea.style.overflow = 'hidden';
              textarea.style.background = 'none';
              textarea.style.outline = 'none';
              textarea.style.resize = 'none';
              textarea.style.lineHeight = textNode.lineHeight();
              textarea.style.fontFamily = textNode.fontFamily();
              textarea.style.transformOrigin = 'left top';
              textarea.style.textAlign = textNode.align();
              textarea.style.color = textNode.fill();
              textarea.style.opacity = textNode.opacity();
              const rotation = textNode.rotation();
              var transform = '';
              if (rotation) {
                transform += 'rotateZ(' + rotation + 'deg)';
              }

              var px = 0;
              // also we need to slightly move textarea on firefox
              // because it jumps a bit
              var isFirefox =
                navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
              if (isFirefox) {
                px += 2 + Math.round(textNode.fontSize() / 20);
              }
              transform += 'translateY(-' + px + 'px)';

              textarea.style.transform = transform;

              // reset height
              textarea.style.height = 'auto';
              // after browsers resized it we can set actual value
              textarea.style.height = textarea.scrollHeight + 3 + 'px';

              textarea.focus();

              const removeTextarea = () => {
                textarea.parentNode.removeChild(textarea);
                window.removeEventListener('click', handleOutsideClick);
                textNode.show();
                tr.show();
                tr.forceUpdate();
                onSelect();
                onTextDblClick('stop');
              }

              const setTextareaWidth = (newWidth) => {
                if (!newWidth) {
                  // set width for placeholder
                  newWidth = textNode.placeholder.length * textNode.fontSize();
                }
                // some extra fixes on different browsers
                var isSafari = /^((?!chrome|android).)*safari/i.test(
                  navigator.userAgent
                );
                var isFirefox =
                  navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
                if (isSafari || isFirefox) {
                  newWidth = Math.ceil(newWidth);
                }

                var isEdge =
                  _.get(document, 'documentMode') || /Edge/.test(navigator.userAgent);
                if (isEdge) {
                  newWidth += 1;
                }
                textarea.style.width = newWidth + 'px';
              }

              textarea.addEventListener('keydown', function (e) {
                // hide on enter
                // but don't hide on shift + enter
                if (e.keyCode === 13 && !e.shiftKey) {
                  textNode.text(textarea.value);
                  removeTextarea();
                }
                // on esc do not set value back to node
                if (e.keyCode === 27) {
                  removeTextarea();
                }
              });

              textarea.addEventListener('keydown', function (e) {
                const scale = textNode.getAbsoluteScale().x;
                setTextareaWidth(textNode.width() * scale);
                textarea.style.height = 'auto';
                textarea.style.height =
                  textarea.scrollHeight + textNode.fontSize() + 'px';
              });

              const handleOutsideClick = (e) => {
                if (e.target !== textarea) {
                  textNode.setAttrs({
                    height: 'auto'
                  });
                  textNode.text(textarea.value);

                  //Save text value
                  const scaleX = textNode.scaleX();
                  const scaleY = textNode.scaleY();
                  textNode.scaleX(1);
                  textNode.scaleY(1);
                  onChange({
                    shapeType,
                    ...shapeProps,
                    x: textNode.x(),
                    y: textNode.y(),
                    // set minimal value
                    width: Math.max(5, textNode.width() * scaleX),
                    height: Math.max(textNode.height() * scaleY),
                    rotation: textNode.rotation(),
                    text: textNode.text()
                  });

                  removeTextarea();
                }
              }

              setTimeout(() => {
                window.addEventListener('click', handleOutsideClick);
              });
            }
          }}
        />
        {isSelected && shapeType !== 'point' && (
          shapeType === 'text' ? <Transformer
            ref={this.trRef}
            enabledAnchors={['middle-left', 'middle-right']}
            boundBoxFunc={(oldBox, newBox) => {
              newBox.width = Math.max(30, newBox.width);
              return newBox;
            }}
          /> : <Transformer
            ref={this.trRef}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox;
              }
              return newBox;
            }}
          />
        )}
      </React.Fragment>
    )
  }
}
//@ts-ignore