import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_10_1 extends SVG {

  render() {
    let { color,  opacity = false } = this.props;
    const borderColor = '#636464';
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 502.6 100.1"}
        style={{ width: "100%", height: "100%" }}
      >
        <rect x="189.7" y="19.1" stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} width="37.7" height="63" />
        <rect x="425" y="32" stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} width="63" height="37.7" />
        <circle stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
                fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} cx="136.3" cy="51" r="31.8" />
        <polygon stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
                 fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} points="246,77 290.7,16 320.3,72 " />
        <polygon stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
                 fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} points="334.3,50.6 369.7,16 404.3,50.6 370.7,85.7 " />
        <rect x="22.3" y="19.2" stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} width="62.8" height="62.8" />
      </SvgIcon>
    )
  }
};