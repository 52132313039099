import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_70_1 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 453.6 453.6"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M355,288.8c-2.3,0-4.4,0-6.5,0c-1.5,0-2.9-0.3-4.4-0.2c-2.3,0.1-4.6,0.5-6.8,0.8c-1.1,0.1-2.2,0.2-3.3,0.4
	c-1.4,0.3-2.9,0.7-4.3,1.2c-2.4,0.8-4.8,1.6-7.1,2.7c-2.1,1-4,2.5-6,3.7c-4.6,2.7-8.2,6.6-12,10.1c-3.2,3-5.7,6.5-7.8,10.3
	c-2.2,3.9-3.8,8.1-5.3,12.3c-0.9,2.5-1.1,5.3-1.6,8c-0.7,4-0.3,8,0.3,12c0.6,4,1.7,7.9,3.2,11.7c0.4,1.1,0.3,1.5-1,1.4
	c-2.6-0.1-5.2-0.2-7.7-0.5c-1.5-0.2-3-0.8-4.5-1.2c-2.1-0.5-4.2-0.9-6.3-1.4c-1.8-0.4-3.5-0.8-5.3-1.3c-1.6-0.5-3.2-1.1-4.8-1.7
	c-0.4-0.1-0.6-0.6-1-0.8c-1.2-0.7-2.3-1.4-3.5-2c-2.5-1.3-5.2-2.5-7.6-3.9c-1.3-0.7-2.4-1.8-3.5-2.9c-1.9-1.9-3.8-3.9-5.6-5.8
	c-1.8-2-3.5-4-5.3-6c-3.1-3.4-4.5-7.8-7.1-11.5c-0.8-1.1-1.1-2.5-1.5-3.8c-0.1-0.3,0.2-0.9,0.4-1c2.8-1,5.2-2.6,7.4-4.4
	c0.2-0.1,0.3-0.3,0.5-0.3c3.7-1.2,6-4.2,8.7-6.7c3.5-3.3,6.5-7.1,9.8-10.6c1.2-1.3,2.5-2.6,3.6-4c1.9-2.5,3.7-5.1,5.3-7.8
	c1.8-3,3.4-6.1,4.8-9.2c1.1-2.3,1.9-4.7,2.7-7.2c0.6-1.7,0.7-1.9,2.6-1.9c1.8,0,3.6-0.2,5.4-0.3c0.3,0,0.5,0.2,0.8,0.2
	c1.6,0,3.2,0,4.7-0.2c0.3,0,0.5-0.6,0.7-0.9c0-0.1-0.1-0.2-0.1-0.4c-1.7-0.1-3.4-0.2-5.1-0.2c-1.6,0-3.1,0.3-4.7,0.4
	c-2.1,0.2-3-0.6-2.9-2.7c0.1-2.2,0.3-4.4,0.5-6.6c0.1-0.9,0.5-1.4,1.5-1.6c2.1-0.3,3.9-1.4,5.7-2.6c3.3-2.4,6.1-5.2,8.8-8.3
	c0.8-0.9,1.2-2.2,1.7-3.3c0.9-1.7,0.5-3.3,0.5-5c0-2.3-2.5-3.8-4.9-3.8c-1.8,0-3.5-0.3-5.3-0.4c0-0.1-0.1-0.2-0.1-0.3
	c0.9-0.7,1.8-1.3,2.7-2c1.7-1.3,3.5-2.5,5-4c2.8-2.7,5.6-5.4,8.2-8.3c1.5-1.7,3.2-3.3,3.5-5.7c0-0.1,0.2-0.2,0.4-0.4
	c1.4,1.5,2.8,3,4.3,4.5c1.5,1.5,2.9,3.1,4.4,4.6c0.4,0.4,0.9,0.5,1.4,0.7c1.3,0.6,2.6,1,3.5,2.2c2.1,2.7,4.5,5.1,6.6,7.8
	c3.1,3.9,6,7.9,9,11.8c3.2,4.1,5.2,9,7.6,13.5c2,3.6,3.5,7.5,6.2,10.7c0.6,0.7,1.2,1.4,1.8,2.2c3.6,5.2,6.3,10.8,7.8,17
	C354.5,286.9,354.7,287.7,355,288.8z M322.1,253c-0.1-0.9,0.1-1.7-0.2-2.3c-0.7-1.7-3-2.8-4.8-2.3c-0.7,0.2-1.5,0.4-2.1,0.7
	c-0.4,0.3-0.7,0.9-0.8,1.4c0,0.3,0.4,1,0.7,1c0.4,0.1,1-0.2,1.5-0.4c0.5-0.2,1-0.8,1.4-0.7c0.7,0.2,1.3,0.7,2,1.1
	c-0.3,0.4-0.6,1-1,1.3c-0.5,0.4-1.2,0.6-1.7,1.1c-0.3,0.2-0.4,0.9-0.3,1.2c0.1,0.3,0.7,0.6,1,0.7c0.4,0,0.9-0.2,1.3-0.4
	c1-0.5,2.3-0.4,3.2,0.4c1.2,1,1.5,2.1,1,3.3c-0.8,1.9-3.5,2.4-5,1c-0.4-0.4-1.2-0.3-1.8-0.5c-0.1,0.4-0.4,0.9-0.2,1.2
	c0.6,1.5,1.9,2.1,3.4,2.4c1.2,0.3,2-0.4,3-0.7c1.9-0.5,3.1-2.9,2.9-4.7C325.6,255.4,324.2,253.9,322.1,253z M305.1,248.1
	c0-0.1,0-0.2,0-0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.5,0.8-0.7,1.5-0.5,2.6c0.3,1.3-0.3,2.8-0.5,4.2c-0.2,1.4,0.5,2,1.8,1.5
	c0.2-0.1,0.4-0.3,0.6-0.4c1.2-0.6,2.7-0.3,3.4,0.8c0.7,1.1,0.5,2.1-0.6,3.2c-0.9,0.9-1.8,0.8-3.1-0.1c-0.3-0.2-0.8-0.3-1.1-0.2
	c-0.3,0.1-0.6,0.6-0.7,0.9c-0.2,1,1.3,2.5,2.4,2.4c1-0.1,1.9-0.4,2.9-0.6c1.4-0.4,3.1-3,2.9-4.4c-0.2-2.3-2.1-4.1-4.5-4.5
	c-0.4-0.1-1-0.2-1.1-0.4c-0.8-1.5,0.1-2.7,1.7-2.7c0.6,0,1.2,0.4,1.7,0.4c0.6-0.1,1.3-0.4,1.9-0.7c-0.3-0.4-0.6-1.1-1-1.2
	c-1.1-0.3-2.2-0.4-3.3-0.6C306.9,248.2,306,248.2,305.1,248.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M176.6,264.3c-2.6-4.2-2.3-8.2-0.9-12.2c-0.6-0.5-1-0.9-1.6-1.3c-1.1,0.9-2.2,1.8-3.5,2.9
	c-0.1-1.5-0.3-2.9-0.1-4.2c0.2-1.7,0.7-3.4,1.2-5.1c0.2-0.9,0.6-1.8,0.9-2.6c0.5-1.4,0.5-3.1,2.3-3.6c0.1,0,0.2-0.3,0.2-0.4
	c-0.3-0.4-0.5-0.9-0.9-1.2c-0.3-0.2-0.8-0.2-1.1-0.1c-1.3,0.5-2.6,1.1-3.9,1.6c-0.1-0.1-0.2-0.2-0.3-0.3c0.4-1.3,0.6-2.7,1.1-4
	c1.5-3.6,3.2-7,4.8-10.6c0.7-1.6,1.4-3.1,2.1-4.7c0.4-0.8,0.8-1.5,1.3-2.1c0.7-0.8,0.3-1.4-0.4-1.9c-0.7-0.5-1.4-0.4-1.8,0.5
	c-0.1,0.2-0.2,0.4-0.2,0.5c-0.8,2.1-0.8,2.1-3,1.7c-1-0.2-1.4-0.8-0.9-1.7c1-2.1,2-4.2,3.2-6.2c1.8-3.1,3.7-6.1,5.6-9.2
	c0.2-0.3,0.4-0.6,0.6-0.9c0.7-0.8,1.1-0.6,1.6,0.3c0.7,1.3,1.6,2.6,2.5,3.8c0.1,0.2,0.5,0.4,0.7,0.4c1-0.5,1.5,0.2,2,0.8
	c1.7,1.7,3.6,3.1,5.8,4.4c2.2,1.3,4.2,2.8,6.2,4.4c1.2,0.9,2.4,1.7,3.9,2.2c1.3,0.5,2.6,0.9,4,1.1c0.6,0.1,1.2,0.7,1.8,1.1
	c2.6,1.8,5.4,2.9,8.4,3.7c1.6,0.5,3.1,1.2,4.7,1.4c5.1,0.7,10.2,1.3,15.4,0.6c3.7-0.5,7.4-0.9,11.1-1.5c3.6-0.6,7.2-1.3,10.7-2.1
	c1.2-0.3,2.3-0.9,3.4-1.4c3.9-1.6,7.8-3.1,11.7-4.8c1.4-0.6,2.7-1.3,4.1-2c3-1.6,6-3.2,9-4.8c1.6-0.8,3.2-1.5,4.8-2.4
	c1.4-0.7,2.5-0.1,3.4,0.8c1,1,2,2.1,2.8,3.3c0.8,1.1,0.4,2.4-0.3,3.3c-1.9,2.6-4,5.1-6.1,7.5c-1.5,1.7-3.1,3.2-4.7,4.7
	c-1.2,1.1-2.7,2-4,2.9c-0.1,0.1-0.2,0.1-0.3,0.2c-2.8,2.5-6.4,3.4-9.7,5c-0.7,0.3-1.5,0.4-2.2,0.9c0.5,0,1.1,0.1,1.6,0
	c2.3-0.6,4.6-0.3,6.9,0.2c2.3,0.4,4.7,0.7,7.1,1c3,0.4,3.7,2.9,2.9,5.3c-1,2.8-3.2,4.7-5.2,6.7c-1.8,1.8-3.9,3.5-5.8,5.2
	c-0.2,0.2-0.5,0.4-0.8,0.5c-1.7,0.7-3.5,1.3-5.2,2c-0.5,0.2-1,0.9-1,1.5c-0.3,2.6-0.5,5.1-0.8,7.7c-0.1,1-0.7,1.9-1,2.5
	c-2.6,0.4-5,0.7-7.3,1.2c-2,0.5-3.9,1.2-5.9,1.8c-1.7,0.5-3.4,0.8-5.1,1.4c-2,0.7-3.8,1.7-5.8,2.6c-3.2,1.4-6.2,3.3-8.9,5.6
	c-1.1,0.9-1.8,2.2-2.9,3.1c-1.8,1.4-2.8,3.3-3.8,5.3c-0.6,1.2-1.6,2.2-2.3,3.4c-0.7,1.2-1.4,2.5-2,3.7c-1.4,2.6-1.4,5.5-1.8,8.3
	c-0.3,1.8-0.6,3.6-0.7,5.4c-0.1,2-0.2,4.1,0.3,6c0.2,1,0.4,2.1,0.8,3.1c0.4,1,0.4,2-1.1,2.4c-1.1,0.3-2.1,1-3.1,1.4
	c-0.4,0.1-1.1,0-1.3-0.3c-2.6-3.6-5.1-7.2-7.6-10.9c0.3-2.5-2.2-3.4-2.9-5.4c-0.7-2.1-1.7-4-2.4-6.1c-0.6-1.9-1.7-3.8-1.2-6
	c0-0.2-0.2-0.4-0.3-0.7c1.2-0.3,2.4-0.8,3.5-0.9c2.9-0.1,5.2-1.6,7.5-3.2c3.8-2.7,6.7-6.2,9.4-9.9c0.4-0.6,0.7-1.3,0.8-2
	c0.5-3.1,1-6.2,1.5-9.4c0.1-0.4-0.2-0.8-0.2-1.2c-0.4,0.2-0.8,0.3-1.1,0.6c-0.4,0.4-0.7,1-0.8,1.5c-0.7,5-2.2,9.7-4.6,14
	c-1.1,2-3,3.3-4.9,4.5c-1.6,1-3.2,1.8-4.8,2.7c-0.9,0.5-1.9,0.8-3,0.4c-0.5-0.2-1.3,0-1.9,0.1c-1.5,0.3-2.1,0-2.3-1.6
	c-0.2-0.9-0.1-1.8-0.1-2.7c0-0.4,0-0.8,0-1.5c-0.5,0.4-0.9,0.5-1.1,0.8c-1.1,1.2-1.9,2.5-3.4,3.3c-1.8,1-3,2.9-4.6,4.3
	c-1,0.8-2.2,1.4-3.4,1.9c-4.1,1.8-8.4,2.7-12.7,3.5c-1.6,0.3-3.1,0.9-4.7,1.3c-0.3,0.1-0.5,0.1-1,0.2c0.2-1.3,0.3-2.4,0.5-3.6
	c0.7-3.6,1.4-7.3,2.1-10.9c0.5-2.6,1.3-5.2,1.8-7.8c0.7-3.2,1.3-6.4,1.8-9.6c0.2-1.6,0.2-3.3,0.1-4.9c0-0.4-0.6-0.8-0.9-1.2
	c-0.3,0.3-0.8,0.6-1,1C177.3,262,177,263,176.6,264.3z M199.4,250c-0.7,0.2-1.5,0.5-2.4,0.7c-1.3,0.2-2.6,0.6-4,0.5
	c-1.1,0-1.5,0.1-1.6,1.3c-0.1,1.7-0.2,3.3-0.3,5c0,0.4-0.1,0.9,0,1.3c0,1.2,1,1.6,2.3,0.8c1-0.6,1.9-1,3-0.2c0.8,0.5,1.3,2.2,0.9,3
	c-0.7,1.2-1.7,1.5-2.8,0.8c-0.2-0.1-0.4-0.3-0.6-0.3c-0.5,0-1,0-1.3,0.2c-0.2,0.2-0.2,1,0,1.2c1,0.8,1.9,1.6,3.1,2
	c0.8,0.3,3.8-2.4,4.1-3.3c0.5-1.7-0.3-4.6-2-5.4c-1.2-0.6-2.7-0.7-4.3-1c0.1-0.6,0.1-1.6,0.4-2.4c0.2-0.4,0.9-0.7,1.4-0.9
	c0.6-0.2,1.3-0.2,1.9-0.3c0.4,0,0.8-0.1,1.2,0c1.7,0.4,2.4-0.2,2.4-1.7C200.3,250.8,199.9,250.5,199.4,250z M206.2,261.7
	c-0.1-0.1-0.2-0.2-0.3-0.3c0.6-1.1,1.2-2.2,1.9-3.2c1.5-2.1,2.8-4.1,2.2-6.9c-0.5-2.1-3.9-3.9-5.7-2.9c-1.6,0.9-2.8,3.6-2.3,5.4
	c0.1,0.5,0.7,1.1,1.1,1.2c0.8,0.1,1.2-0.5,1.2-1.3c0.1-0.6,0.1-1.2,0.3-1.8c0.3-1.2,1-1.7,2-1.4c1.1,0.3,1.9,1.4,1.4,2.5
	c-0.7,1.6-1.6,3.2-2.4,4.8c-0.7,1.5-1.6,2.9-2.1,4.5c-0.4,1.2,0.7,2.2,2,1.9c1.7-0.3,3.4-0.5,5.2-0.8c0.9-0.2,1.8-0.4,3-0.7
	c-1.3-1.3-2.2-2.2-3.9-1.5C208.6,261.7,207.3,261.6,206.2,261.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity200 ? this.state.opacity200 : 1}
              fill={this.state.color200 ? this.state.color200 : 'transparent'} onClick={() => {
          this.setState({ color200: color, opacity200: opacity });
        }} d="M214.1,81.3c-0.9,0.2-1.1,1.1-0.4,2.5c0.4-0.3,0.8-0.6,1.2-0.9c2.2-1.6,4.2-3.4,6.5-4.8c1.6-1,3.5-1.5,5.3-2.1
	c0.4-0.1,1,0.1,1.3,0.4c1.5,1.3,2,3.2,2.1,5.1c0.1,1.7,0.1,3.6-0.3,5.3c-0.6,3.1-1.8,6-4.3,8.3c-1,0.9-1.8,2.1-2.6,3.3
	c-0.2,0.3-0.1,1-0.2,1.4c0.5,0,1.1,0.1,1.5-0.1c2.4-0.8,4.7-1.5,7.1-0.3c1.8,0.9,2.7,2.4,2.1,4.3c-0.4,1.2-0.8,2.5-2,3.3
	c-1.7,1.2-3.2,2.5-4.9,3.6c-0.7,0.5-1.5,0.6-2.2,1.1c-0.4,0.3-0.8,0.8-1,1.2c-0.4,0.7-0.2,1.3,0.6,1.6c0.7,0.3,1.3,0.5,2,0.8
	c2.5,1.2,3.1,4.5,0.9,6.3c-1.3,1.1-3,1.9-4.6,2.7c-0.5,0.2-1.2,0.1-1.7,0c-1.3-0.2-2.1,0.1-2.4,1.3c-0.8,2.9-1.4,5.8-2.1,8.7
	c-0.1,0.5-0.2,1-0.4,1.5c-2.6,4.7-5,9.5-8.9,13.4c-1.6,1.6-2.7,3.7-4.1,5.5c-0.6,0.8-1.2,1.5-1.8,2.2c-1.3,1.6-2.6,3.1-3.9,4.7
	c-0.1,0.2-0.2,0.4-0.4,0.4c-1.9,0.6-2.4,2.5-3.5,3.9c-1.7,2.3-3.4,4.7-5,7.1c-0.7,1-1,2.2-1.6,3.5c-0.2-0.4-0.4-0.7-0.4-1
	c-0.3-4.3-2.3-8-3.9-11.8c-0.4-0.9-1-1.7-1.4-2.6c-0.3-0.6-0.4-1.3-0.5-2c1.2-0.1,2.5-0.3,3.7-0.4c-0.2-0.3-1.9-1.3-2.7-1
	c-0.8,0.3-1.4-0.1-2.1-0.2c-1.2-0.2-2.4-0.4-3.6-0.5c-1.2-0.1-2.4-0.1-3.5-0.2c-2.7-0.4-5.2,0.2-7.6,1.4c-1.2,0.6-2.5,1.1-3.3,2
	c-1.7,1.8-3.8,1.8-6,2.1c0.3,2.7-1,4.7-2.6,6.5c-0.4,0.4-1.1,0.7-1.7,0.8c-1.5,0.3-3,0.8-4.6,0.6c-1.9-0.2-4.5-3.3-4.8-4.8
	c-0.4-2.2-0.4-4.2,0.8-6.1c0.1-0.2,0.1-0.4,0.2-0.6c0.3-2.5,1.6-4.1,3.7-5.3c3.1-1.8,6.5-2.4,10-2.3c1.2,0,2.3,0.8,3.4,1.3
	c0.5,0.2,1,0.6,1.6,0.9c-1.5,1.4-4.1,1.4-4.7,3.9c1,0.2,1.9,0.2,2.8-0.4c1.1-0.7,2.5-1,3.7-1.5c1-0.4,1.9-0.8,2.8-1.2
	c0.2-0.1,0.3-0.4,0.3-0.6c-0.2-0.4-0.4-0.8-0.8-1c-3.2-1.6-6.3-3.1-9.5-4.6c-0.4-0.2-0.8-0.5-1-0.8c-0.8-1.2-2-0.9-3-0.7
	c-0.8,0.1-0.7,0.8-0.3,1.3c0.2,0.3,0.6,0.5,0.8,0.8c-1.9,0.5-3.7,0.9-5.5,1.3c-0.6,0.2-1.3,0.3-1.8,0.6c-0.7,0.4-1.4,0.4-2.1-0.1
	c-0.2-0.1-0.6,0.1-0.9,0.3c-0.8,0.5-1.6,0.9-2.4,1.4c-0.9,0.5-2.7,0.2-3.5-0.6c-0.8-0.8-1.7-1.4-2.4-2.2c-2.5-2.9-3.9-6.4-5.2-9.9
	c-0.1-0.2-0.2-0.5-0.1-0.7c1.7-3.8,3.4-7.6,5.1-11.3c0.8-1.7,1.7-3.4,2.7-5c1-1.5,1.8-3.3,3.5-4.3c-0.2-0.5-0.5-0.9-0.6-1.2
	c-1.1-0.4-2-0.8-3-1.2c0-0.1,0-0.2,0.1-0.3c0.9-0.3,1.8-0.6,2.6-1c2.3-1.3,4.6-2.6,6.8-4.1c1.9-1.3,3.8-2.7,5.5-4.3
	c1-0.9,1.7-2.1,2.5-3.3c1.3-2.1,2.7-4.1,3.7-6.3c1.7-4.2,2.8-8.5,3.1-13.1c0.1-1.7,0.6-3.4,0.9-5c0-0.2,0.2-0.4,0.1-0.6
	c-0.4-1-0.1-1.5,1-1.5c0.2,0,0.5-0.2,0.6-0.4c1.1-2.6,3.6-3,5.9-3.6c2-0.5,4-1,6-1.3c1.6-0.3,3.2-0.4,4.8-0.4c3.3,0,6.7,0,10,0.2
	c1.5,0.1,2.9,0.5,4.4,0.8c0.7,0.1,1.4,0.4,2,0.6c0.4,0.1,0.8,0.3,1.2,0.5c1.8,1.1,1.8,1.1,2.7-0.9c0.6-1.5,1.2-2.9,1.8-4.4
	c0.5-1.4,1.4-2.4,2.8-3c1-0.4,1.9-1,2.9-1.4c3.9-1.5,8.9,0.7,9.2,5.3c0.1,1.8,0.5,3.7,0.5,5.5c0,1.1-0.6,2.2-1.1,3.2
	c-0.4,0.8-1.3,1.4-1,2.6C215,79.7,214.5,80.4,214.1,81.3z M184.5,99.7c-0.1-0.6,0-1-0.2-1.4c-0.9-1.8-1.2-3.9-2.7-5.5
	c-0.8-0.9-1.4-1.7-2.6-1.7c-1.5-0.1-3-0.6-4.5-0.5c-3,0-5.1,2-7.1,3.8c-2.2,2-2.3,4.7-1.4,7.1c0.8,2,2.5,3.7,4.1,5.2
	c1.6,1.4,3.8,1.7,5.8,1.2c2-0.5,3.8-1.8,5.6-2.8C183.6,103.9,183.9,101.6,184.5,99.7z M186.4,125.4c-0.1-0.7-0.4-1.2-0.2-1.5
	c0.6-1.1,1.3-2.3,2.1-3.2c0.4-0.5,1.4-0.6,2.1-0.7c0.2,0,0.5,0.7,0.8,1c0.3,0.3,0.8,0.5,1.1,0.5c0.3-0.1,0.9-0.6,0.9-0.8
	c-0.3-0.8-0.5-1.7-1.1-2.2c-0.6-0.5-1.7-1.2-2.2-1c-1.7,0.8-3.5,1.5-4.5,3.3c-0.9,1.5-1.8,3-1.7,4.8c0,1.2-0.2,2.3,0.8,3.5
	c2,2.5,4.3,3.5,7.2,2.2c1-0.5,1.9-1.5,2.6-2.5c0.6-0.9-0.1-3.2-1-3.8C191,123.7,188.8,123,186.4,125.4z M176.4,124.3
	c1-0.9,1.2-1.9,0.5-2.6c-0.8-0.8-1.8-1.7-2.8-1.7c-1.9,0-3.8,0.6-4.9,2.4c-0.2,0.4-0.1,1.2,0.1,1.6c0.4,0.7,1,0.3,1.4-0.2
	c0.8-1,1.8-1.7,3.1-1.7c0.3,0,0.9,0.2,1,0.3c0.1,0.3-0.1,0.8-0.3,1.1c-0.5,0.6-1.2,1-1.5,1.6c-0.2,0.4-0.1,1.2,0.2,1.6
	c0.2,0.3,0.9,0.2,1.4,0.2c0.6-0.1,1.2-0.4,1.8-0.5c1.3-0.2,2.7,0.5,2.8,1.3c0.3,1.7-1.4,3.3-2.9,3.1c-0.4-0.5-0.7-1.1-1.1-1.1
	c-1.1-0.2-2.1,0.2-2.7,1.1c0.2,1.4,0.9,2.1,2.2,2.2c1,0.1,1.9,0.2,2.9,0.2c0.3,0,0.6-0.1,0.8-0.3c1.3-1,2.4-2.1,2.8-3.9
	c0.4-1.6-0.4-3.7-1.9-4.3C178.4,124.5,177.5,124.5,176.4,124.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity206 ? this.state.opacity206 : 1}
              fill={this.state.color206 ? this.state.color206 : 'transparent'} onClick={() => {
          this.setState({ color206: color, opacity206: opacity });
        }} d="M360.9,315.1c0.1,2.2,0.2,4.6,0.3,6.9c0,1,0,2.1,0.1,3.1c0.4,3.6-0.5,7.1-0.8,10.6c-0.2,2.2-0.5,4.4-1.2,6.4
	c-0.9,3.1-2.2,6.1-3.3,9.1c-0.3,0.7-0.4,1.5-0.7,2.1c-0.4,1.1-0.8,2.2-1.4,3.1c-1.6,2.3-2.8,4.9-4.2,7.3c-0.6,1.1-1.9,1.7-2.6,2.7
	c-2,2.6-4.8,1.3-7.2,1.6c-1.7,0.2-3.5,0-5.2,0.1c-2.2,0-4.5,0-6.7,0.2c-1.5,0.1-2.9,0-4.4,0.6c-1.2,0.5-2.7,0.4-4,0.3
	c-2.4-0.1-4.7,0.4-7,1.2c-1.3,0.4-2.7,0.3-4,0.6c-0.7,0.2-1.7,0.7-2,1.4c-0.5,1-1.2,1.3-2.1,1.2c-1.3-0.2-2.3-0.7-3-1.9
	c-0.9-1.3-2.2-2.4-2.8-3.8c-2-4.3-3.9-8.7-5.7-13.2c-0.3-0.7,0.1-1.6,0.1-2.4c0-0.3,0.1-0.6,0-0.9c-1.2-4.2-1.2-8.4-0.5-12.7
	c0.2-1.6,0.3-3.2,0.7-4.7c0.4-1.8,0.7-3.6,1.4-5.3c1.3-3,2.8-5.8,4.2-8.7c0.2-0.4,0.4-0.8,0.7-1.1c1.5-1.3,2.3-3.1,3.3-4.8
	c1.5-2.5,3.3-4.8,5.8-6.5c1.6-1.1,2.9-2.8,4.3-4.2c0.2-0.2,0.3-0.4,0.5-0.5c1.6-1.3,3.1-2.6,4.7-4c2.4-2,5.2-3.2,8.1-4.3
	c2.3-0.9,4.7-1.6,7.2-2.3c0.9-0.3,1.9-0.3,2.9-0.3c1-0.1,2,0,2.9-0.3c3-0.9,6.1-0.4,9.1-0.4c1.7,0,3.4-0.1,5.1-0.4
	c1.7-0.3,2.3-0.1,2.8,1.5c1.2,4.1,2.4,8.2,3.4,12.4c0.7,3.1,1.1,6.2,1.6,9.4C360.9,314.5,360.9,314.8,360.9,315.1z M330.9,331.9
	c-1.4,1.3-2.7,2.4-3.9,3.6c-0.7,0.8-1.2,1.8-2,2.5c-1.3,1-2.5,2-2.2,4.1c0.5,0.2,1.1,0.5,1.7,0.7c0.9,0.2,1.8,0.3,2.6,0.6
	c0.4,0.1,0.7,0.7,0.8,1.2c0.2,0.9,0.2,1.9,0.4,2.8c0.1,0.4,0.7,0.7,1,1c0.3-0.4,0.9-0.7,1-1.2c0.2-0.6,0.2-1.3,0.2-2
	c0.1-0.8,0.8-1.3,1.5-1c0.9,0.5,1.4-0.1,1.6-0.7c0.3-0.9-0.5-1-1.1-1.1c-1.4-0.2-1.4-0.4-1.3-1.8
	C331.4,337.9,332.2,335.1,330.9,331.9z M320.1,331.3c-0.4,0.7-0.5,1.4-0.9,1.7c-2.2,1.4-4.4,2.6-6.7,3.9c-0.3,0.2-0.4,0.5-0.7,0.8
	c0.3,0.2,0.7,0.6,1,0.6c0.9-0.2,1.9-0.5,2.8-0.9c1-0.4,2-1,3.2-1.5c-0.2,1.9-0.3,3.5-0.4,5.2c-0.2,2.1-0.5,4.2-0.7,6.3
	c-0.1,1,0.1,1.9,1.2,2.6c0.2-0.4,0.5-0.7,0.6-1c0.2-1.5,0.4-3,0.6-4.5c0.3-2.3,0.5-4.6,0.8-7c0.1-0.9,0.1-1.9,0.2-2.8
	C321.3,333.4,321.1,332.3,320.1,331.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity204 ? this.state.opacity204 : 1}
              fill={this.state.color204 ? this.state.color204 : 'transparent'} onClick={() => {
          this.setState({ color204: color, opacity204: opacity });
        }} d="M386.2,375.8c0.2-2.3-1.3-3.5-3.5-3c-0.9,0.2-1.8,0-2.8-0.1c-0.1-0.2-0.2-0.3-0.3-0.5c0.8-0.8,1.4-2,2.3-2.5
	c0.9-0.5,2.2-0.3,3.5-0.4c-0.1-0.7-0.1-1.5-0.2-2.3c-3.4-0.7-5.2,0.4-7.1,3.4c-0.7,1.1-1.4,1.3-2.5,1.1c-4.6-1.3-9.4-1.6-14.1-1.7
	c-1.5,0-3.1-0.5-4.6-0.3c-1.4,0.1-2.4-0.4-3.6-0.7c-1.3-0.4-2.8-0.6-4.3,0.3c0.3-0.5,0.6-1,0.9-1.5c1.4-2.1,2.9-4.1,3.6-6.5
	c0.3-0.9,0.9-1.9,1.7-2.6c1.7-1.5,2.3-3.5,2.8-5.5c0.3-1.2,1-1.4,2-1.3c3.3,0.4,6.6,0.7,9.8,1.3c4.6,0.9,9.2,2.2,13.6,4
	c1.7,0.7,3.6,1.2,5.3,1.9c1.7,0.7,3.3,1.7,4.8,2.6c1.7,1,3.5,2.1,5.1,3.4c1,0.8,2,1.9,2.6,3c1.2,1.9,2.1,4,3.2,6
	c1.9,3.6,1.7,7.9-1.5,10.9c-0.3,0.3-0.5,0.6-0.7,1c-1.4,3.3-3.9,5.5-6.9,7.4c-2,1.3-3.8,2.9-5.9,4.1c-2.1,1.2-4.4,1.9-6.7,2.8
	c-2.4,0.9-4.7,1.7-7.1,2.4c-2.2,0.6-4.4,1-6.6,1.5c-1.9,0.4-3.8,1-5.8,1.4c-1.4,0.3-2.9,0.3-4.4,0.6c-1.6,0.3-3.2,0.7-4.8,1
	c-1.8,0.3-3.5,0.7-5.3,0.8c-3.7,0.2-7.4,0.6-11.1,0.4c-5.1-0.2-10.2-0.5-15.3-1.6c-4-0.9-7.4-2.9-11.1-4.3c-1.9-0.7-3.3-2-5-2.9
	c-1.5-0.8-2.7-1.9-3.1-3.8c-0.3-1.7-1.5-3.2-2-4.8c-0.9-2.9-0.7-5.8,0.8-8.3c2.2-3.7,4.7-7.2,8.9-8.8c3.2-1.3,6.4-2.7,10-2
	c0.6,0.1,1.4-0.3,2-0.6c2.1-1,4.4-0.4,6.6-0.6c1.1-0.1,2.2,0.1,3.3,0.1c2.8,0,5.6,0,8.4,0c0.7,0,1.5-0.4,2.2-0.4c1,0,2,0.3,3,0.4
	c1.4,0.2,2.8,0.4,4.2,0.4c1.2,0,2.3,0.1,3.5,0.6c1.1,0.5,2.3,0.6,3.5,0.7c2.5,0.1,4.9,0,7.4,0.2c2,0.1,4.1,0.2,6.1,0.6
	c2.5,0.5,5,1.2,7.5,1.9c0.4,0.1,0.9,0.2,1.3,0.2c1.7,0.3,3.4,0.5,5.1,0.8C385.4,375.7,385.8,375.8,386.2,375.8z M360,396.8
	c0.2-0.1,0.5-0.1,0.9-0.2c3-0.5,4.2-3.2,2.5-5.7c-0.6-0.8-0.9-1.5,0-2.3c0.2-0.2,0.3-0.5,0.5-0.8c0.4-0.9,0.5-1.8-0.5-2.5
	c-1-0.7-1.9-1.6-3-2.2c-0.6-0.3-1.4-0.8-2.2,0c-1.7,1.6-1.9,3-0.6,4.9c0.5,0.8,0.6,1.5-0.3,2.2c-0.6,0.4-1.2,1.1-1.4,1.8
	C355,394.4,357.2,396.8,360,396.8z M351.3,384c-1.1,1-2.1,1.9-3.2,2.7c-0.7,0.5-1.6,0.6-2.3,1.1c-0.4,0.3-0.7,0.8-1.1,1.5
	c2.6,0.6,4-1.4,6.2-1.7c-0.2,2.5-0.4,5-0.5,7.4c0.6,0.3,1.2,0.6,2,1c0.1-1,0.1-1.8,0.1-2.6c0-2.3-0.3-4.5-0.1-6.8
	C352.5,385.2,352.1,384.5,351.3,384z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M42.2,245.6c0.3-1.5,0.5-2.8,0.9-4c1-2.8,2-5.5,3.1-8.2c2-4.6,5.2-8.5,8.4-12.3c2.2-2.6,4.5-5.3,6.8-7.9
	c2-2.4,4-4.8,6-7.2c1.9-2.2,2.7-4.9,3-7.7c0.8-6.1-2.1-10.4-7.1-13.5c-1.3-0.8-2.8-1.2-4.3-1.6c-0.7-0.2-1.5,0.3-2.3,0.5
	c-0.4,0.1-0.8,0.3-1.1,0.3c-2,0-3.8,0.4-5.2,2c-0.1-0.1-0.2-0.2-0.3-0.3c0.8-2,1.7-3.9,2.5-5.9c0.9-2.3,1.8-4.7,2.5-7
	c0.8-2.3,1.5-4.6,2-7c0.4-2,0.6-4.1,0.6-6.2c0.1-4.5,0-9.1,0-13.6c0-0.8,0.2-1,1-1.1c2.6-0.5,5.1-1.1,7.7-1.5c1.6-0.2,3.3-0.1,5-0.2
	c0.2,0,0.4,0,0.6,0.1c1.2,1.1,2.7,0.8,4.2,1c1.9,0.2,3.7,0.9,5.5,1.3c0.1,0,0.2,0,0.2,0.1c0.3,1.8,2.2,1.4,3.2,2.4
	c-0.5,0.7-1.1,1.5-1.6,2.3c-0.9,1.6-2,3.3-2.7,5c-0.8,1.8,0,2.8,2,2.8c2.4,0,4.8-0.1,7.1,0.2c1.7,0.2,3.4,0.9,5.1,1.4
	c2.4,0.6,4.8,1.3,7.3,1.8c2.3,0.4,3.6,1.8,4.3,3.8c1,2.6,1.7,5.2,2.6,7.8c1.7,4.6,2.2,9.4,2.4,14.2c0.1,1.6-0.5,3.2-0.9,4.8
	c-0.5,2.1-0.8,4.2-1.5,6.3c-0.5,1.4-1.5,2.7-2.2,4.1c-0.2,0.3-0.4,0.5-0.4,0.8c-0.3,1.7-1.6,2.8-2.8,3.9c-2.2,2-4.6,3.9-6.3,6.4
	c-0.3,0.4-0.7,0.7-1.1,1.1c-2.4,2.2-4.1,4.8-6,7.4c-1.3,1.7-2.7,3.4-3.9,5.1c-0.7,1-1.3,2-1.9,3c-0.6,1-1.2,2-1.5,2.6
	c-0.9-0.2-1.6-0.5-2.3-0.5c-2.6,0.4-5.1,0.8-7.7,1.3c-0.5,0.1-1,0.2-1.5,0.3c-3,0.8-6,1.6-8.9,2.5c-1.6,0.5-3.1,1.2-4.6,1.9
	c-4.8,2.1-9.5,4.2-14.3,6.3C43.4,244.8,42.9,245.2,42.2,245.6z M96.8,191.1c-0.1-0.2-0.1-0.6-0.3-1c-0.2-0.4-0.4-0.9-0.8-1.1
	c-1-0.7-2.1-0.9-3.4-0.8c-2.6,0.3-4.2,1.9-5.5,4c-0.5,0.9-0.6,2,0.2,2.8c1.1,1,2.4,1.5,3.9,0.8c1-0.4,2.1-0.8,3.1-1.2
	c0.8,2-0.2,3.8-2.1,4.1c-0.6,0.1-1.2,0.1-1.8,0.2c-0.5,0.1-0.9,0.4-1.3,0.6c0.3,0.4,0.6,0.8,1,1.1c0.2,0.2,0.6,0.2,0.9,0.2
	c3.9-0.2,5.8-2.3,5.8-6C96.5,193.7,96.7,192.5,96.8,191.1z M77,186.8c-0.7-0.2-1.7-0.4-1.4,0.7c0.4,1.3,1,2.5,1.7,3.8
	c0.1,0.2,0.9,0.4,1,0.3c0.3-0.2,0.4-0.8,0.6-1.2c0-0.2-0.3-0.4-0.3-0.6c0.1-0.3,0.3-0.8,0.5-0.8c1.1-0.1,2.2-0.1,3.3-0.1
	c0.8,0,0.9,0.3,0.6,1c-0.5,1.1-1.1,2.1-1.6,3.2c-0.7,1.5-1.5,3-2.2,4.5c-0.1,0.3,0.1,1.1,0.5,1.3c0.3,0.2,1,0,1.4-0.2
	c0.2-0.1,0.3-0.5,0.5-0.8c0.8-1.6,1.5-3.1,2.2-4.8c0.6-1.4,1.3-2.8,1.7-4.3c0.3-1.3-0.2-1.9-1.5-2C81.6,186.7,79.3,186.8,77,186.8z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M114,82.7c-0.8,0.1-1.5,0.3-2.2,0.3c-2.5,0.3-5.1,0.5-7.6,0.7c-2.8,0.3-5.7,0.5-8.4,1c-2.6,0.5-5.2,1.3-7.8,1.8
	C86.4,87,84.6,87,83,87.4c-3,0.8-6,1.7-8.9,2.7c-1.5,0.5-1.4,0.6-2.1-0.8c-1.5-2.8-3.1-5.6-4.5-8.4c-1.5-3-2.9-6.2-4.2-9.3
	c-1-2.2-2.1-4.4-2.8-6.7c-1.1-3.4-1.9-6.9-2.8-10.4c-0.7-2.6-1.3-5.2-1.9-7.9c-0.2-0.9-0.2-1.7,0.8-2.3c3.3-2,6.6-4,9.8-6.2
	c3-2.1,5.8-4.6,8.7-6.9c3.5-2.8,7-5.5,10.5-8.3c1.4-1.1,2.6-2.4,3.8-3.6c1.4-1.4,2.9-2.8,4.2-4.4c2.2-2.5,4.2-5.2,6.3-7.8
	c0.4-0.5,1.2-1,1.8-1.1C106.3,6,111,5.9,115.7,6c2.9,0,5.9,0.3,8.8,0.4c1.3,0,2.6-0.1,3.9-0.2c0.6,0,1.2,0,2,0
	c-0.5,0.6-0.8,1.2-1.2,1.6c-3,3.3-5.2,7.1-7.2,11c-2,3.9-4,8-5.7,12.1c-2.2,5.4-4,11-5.2,16.7c-0.8,4-1.5,8.1-2,12.2
	c-0.4,3.5,0.1,7,0.5,10.5c0.4,3.5,1.8,6.6,2.9,9.8C112.9,81,113.4,81.7,114,82.7z M82.7,65.1c1.4,1.3,3,1.3,4.6,1.1
	c2.2-0.3,3-1.6,2.9-3.8c-0.1-1.4,0-2.9,0.1-4.3c0.1-0.9-0.5-2.6-1.3-3.1c-1.6-1-4.3-0.8-5.8,0.5c-1.1,1-1.6,3.3-0.8,4.2
	c1.4,1.6,3,1.9,4.7,0.7c0.2-0.1,0.3-0.2,0.6-0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.2,0.8,0.3,1.6,0.4,2.3c0.2,1.5-1,2.5-2.4,1.9
	C84.7,64.1,83.7,64.3,82.7,65.1z M79.6,66.6c1.2-0.9,0.4-2,0.4-2.9c0.1-0.8,0-1.4,1.1-1.4c0.4,0,0.8-0.3,1.6-0.7
	c-0.7-0.4-1.1-0.9-1.6-1c-1.3-0.2-1.5-0.4-1.3-1.8c0.1-1.1,0.1-2.3,0.1-3.5c0-1-0.7-1.6-1.6-1.6c-0.5,0-1,0.5-1.4,0.9
	c-0.4,0.4-0.8,1-1.1,1.5c-1.3,1.8-2.6,3.5-3.7,5.3c-0.6,1,0,1.7,1.3,1.6c0.3,0,0.5-0.1,0.8-0.2c0.9-0.1,1.8-0.3,2.7-0.4
	c0.9-0.1,1.4,0.3,1.2,1.4C77.9,65.5,78.2,65.9,79.6,66.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M435.7,138.5c0.2-0.4,0.3-0.7,0.4-1c0.1,0,0.3,0,0.4,0c0,0.8,0,1.7,0,2.5c0,3-0.1,6-0.1,8.9
	c-0.1,3.3-0.1,6.6-0.2,9.9c0,0.4-0.2,0.9,0,1.3c0.5,1.7-0.6,2.3-1.8,3c-2.8,1.7-5.6,3.5-8.4,5.2c-1.1,0.7-2.3,1.3-3.4,2
	c-1.4,0.9-2.8,1.9-4.2,2.7c-5.1,2.9-10.2,5.7-15.4,8.5c-2.1,1.2-4.1,2.5-6.3,3.5c-3.1,1.3-6.2,3-9.7,3.3c-2.8,0.2-5.7,0.7-8.5,0.7
	c-2.1,0-4.2-0.4-6.4-0.6c-1.4-0.2-2.7-0.3-4.1-0.6c-1-0.2-1.9-0.5-2.9-0.8c-2.8-0.9-5.6-2-8.5-2.8c-4.5-1.3-8.1-4.2-11.7-7
	c-2.5-1.9-4.7-4.2-7-6.4c-0.4-0.4-0.8-0.7-1-1.2c-0.2-0.3-0.3-0.9-0.1-1.1c1.2-1.5,2.4-3,3.7-4.5c1.3-1.4,2.7-2.7,3.9-4.2
	c2.9-3.3,6.5-5.5,10.9-5.8c2.7-0.2,5.5,0.3,8.2,0.5c3.4,0.3,6.8,0.9,10.2,0.8c3.4-0.1,6.7-0.9,9.1-3.7c1-1.1,2.3-2,3.6-2.7
	c2.1-1.2,3.3-3.3,4.9-5c1.4-1.5,2.7-3.2,4-4.8c1.5-2,3.1-4,4.5-6c1.2-1.7,2.4-3.5,3.7-5.4c4.5,1.7,9.1,3.5,13.7,5.2
	c5.2,1.8,10.5,3.5,15.7,5.1C434,138.3,434.9,138.3,435.7,138.5z M389.7,160.2c-0.2,0.8-0.3,1.7-0.5,2.5c-0.3,1.1-0.7,2.2-1,3.3
	c-0.1,0.4,0,1,0.3,1.2c0.3,0.2,1,0.2,1.3,0c0.8-0.8,1.7-0.6,2.7-0.5c1.4,0.2,2,1.4,1.3,2.7c-1,1.8-1.8,2.1-3.7,1.3
	c-0.3-0.1-0.8,0.1-1.2,0.2c-0.1,0.1-0.1,0.7,0,0.8c0.8,0.4,1.7,1.1,2.5,1.1c1.8-0.2,3.3-1.1,4.2-2.9c1-2,0.1-4.4-2.1-4.9
	c-0.9-0.2-1.9-0.4-2.8-0.7c0.4-1.6,0.9-2.1,2.2-2.1c0.9,0,1.9,0.3,2.8,0.3c0.6,0,1.1-0.4,1.7-0.6c-0.4-0.4-0.7-0.8-1.1-1.1
	c-0.3-0.2-0.7-0.2-1-0.3C393.6,160.7,391.8,160.5,389.7,160.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M52.4,425.8c1.5-1.6,3-3.2,4.3-4.9c3.1-3.9,6.2-7.9,9.3-11.8c1.5-1.8,3-3.9,4.9-5.1c2.4-1.5,4-3.8,6.3-5.4
	c2.2-1.5,4.1-3.3,6-5.1c0.3-0.3,0.9-0.4,1.4-0.7c2.1-1.6,4.3-3.2,6.4-4.8c1.5-1.2,3-2.4,4.6-3.5c0.6-0.4,1.6-0.5,2.4-0.5
	c3.2,0.2,6.3,0.5,9.5,0.9c1.3,0.2,2.6,0.6,3.9,0.8c0.6,0.1,1.1,0.3,1.7,0.3c2,0.1,4.1,0,6.1,0.1c1.3,0.1,2.6,0.5,3.9,0.7
	c1.3,0.2,2.6,0.4,3.9,0.5c0.4,0,1.1-0.4,1.3-0.8c1-1.6,2.2-3.2,2.1-5.4c0-0.9,0.6-1.9,1-2.8c0.1-0.2,0.5-0.5,0.7-0.5
	c2.3,0,4.8-0.3,7,0.3c2.4,0.6,4.9,0.5,7.3,0.8c0.5,0.1,1-0.1,1.5-0.2c1.4-0.2,1.5,0,1.2,1.4c-0.3,1.6-0.6,3.3-0.6,5
	c0,2.4,0.7,4.5,3.1,5.6c0.9,0.4,1.6,1.3,2.4,1.7c3.3,1.5,6.7,2.5,10.3,2.8c3.6,0.3,7.3,0.7,10.9,1c0.7,0.1,1.2,0.4,1.1,1.2
	c-0.5,3.5-1,7-1.5,10.5c-0.3,2.2-0.5,4.4-0.8,6.6c-0.2,1.1-0.6,2.1-0.8,3.1c-0.5,1.8-1,3.7-1.4,5.5c-0.2,0.9-0.5,1.3-1.5,1.2
	c-1.5-0.1-3.1-0.1-4.7,0c-5.5,0.3-11,0.8-16.6,1c-2.7,0.1-5.3,0-8,0c-2,0-4,0.1-5.9,0.1c-3.6,0-7.1-0.1-10.7,0
	c-1.7,0-3.3,0.5-4.9,0.5c-2,0-4-0.2-6-0.3c-0.2,0-0.3-0.1-0.5,0c-1.2,0.6-2.5,0.1-3.8,0.2c-1,0-1.3-0.5-1.4-1.2
	c-0.4-2.5-0.8-5-0.9-7.6c-0.2-5.9-0.9-11.8-1.4-17.6c-0.1-0.9,0-1.7,0-2.6c0-0.2,0.1-0.5,0.2-0.5c1.7-0.6,0.6-1.8,0.6-2.7
	c0-0.3-0.6-0.6-1-0.9c-0.3-0.3-0.7-0.6-1.2-1c-1.1,1.3-2.2,2.8-3.4,4.1c-1,1.1-2.3,2-3.3,3.1c-2.9,3.1-5.6,6.3-8.5,9.3
	c-2.5,2.5-5.3,4.8-7.7,7.3c-2.3,2.4-4.4,5-6.7,7.5c-0.9,1-1.9,1.9-2.9,2.8c-0.2,0.1-0.4,0.2-0.6,0.2c-2.3,0-4.6,0-6.9,0
	c-2.9,0-5.9-0.1-8.8-0.2c-0.8,0-1.6,0.1-2.5,0.2C52.5,426,52.4,425.9,52.4,425.8z M151.5,404.7c0-0.1,0-0.3,0.1-0.4
	c-0.2-0.2-0.5-0.4-0.5-0.6c-0.4-1.5-1.5-1.2-2.6-1.3c-2-0.1-4-0.4-6-0.6c-0.3,0-0.6,0-0.8,0.1c-1,0.7-2.1,1.3-1.7,2.9
	c0.1,0.3-0.1,0.8-0.2,1.1c-0.3,0.8-0.7,1.6-0.8,2.4c-0.1,0.5,0.2,1.2,0.5,1.6c0.2,0.2,0.9-0.1,1.4-0.2c0.6-0.2,1.1-0.6,1.6-0.6
	c1.9,0.1,2.9,0.8,2.4,2.4c-0.3,0.9-0.9,1.4-1.8,1.7c-0.9,0.3-1.1-0.4-1.6-0.9c-0.3-0.3-0.9-0.5-1.3-0.4c-0.3,0-0.7,0.9-0.6,1
	c0.8,0.8,1.6,1.9,2.6,2.2c1.4,0.4,2.9-0.4,4-1.4c1.8-1.6,1.5-4.9-0.6-6.1c-0.8-0.4-1.7-0.8-2.6-0.9c-1.2-0.2-0.8-1-0.8-1.6
	c0-0.7,0.3-1.1,1.1-1.1c2.1,0.2,4.2,0.4,6.3,0.6C150.1,404.8,150.8,404.7,151.5,404.7z M130.8,410c0,0.1,0,0.2,0,0.3
	c1-0.1,1.4,0.2,1.3,1.3c-0.1,0.6-0.2,1.3,0,1.9c0.1,0.4,0.4,0.8,0.8,1c0.2,0.1,0.8-0.1,1-0.4c0.3-0.5,0.5-1.1,0.5-1.7
	c0.1-1.1,0.1-2,1.5-2.2c0.7-0.1,0.9-0.6,0.2-1.2c-0.5-0.4-1.1-1-1.1-1.6c-0.1-1,0-2.1,0.1-3.1c0.1-0.9-1.4-3.2-2.3-3.4
	c-0.2,0-0.5,0.1-0.7,0.3c-2.6,2.2-5.2,4.5-7.8,6.8c-0.3,0.3-0.6,0.9-0.5,1c0.3,0.4,0.8,1,1.3,1C126.9,410,128.9,410,130.8,410z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity201 ? this.state.opacity201 : 1}
              fill={this.state.color201 ? this.state.color201 : 'transparent'} onClick={() => {
          this.setState({ color201: color, opacity201: opacity });
        }} d="M260.3,205.8c-1.1-2.1-1.8-3.9-2.8-5.5c-0.3-0.5-1.4-0.5-2.2-0.7c-0.1,0-0.3,0.7-0.4,1.1
	c-0.2,0.5-0.6,1-0.8,1.5c-0.3,1.2-0.5,2.5-0.6,3.7c-0.3,2.2-0.5,4.5-0.9,6.7c-0.3,1.6-1,3.1-2.2,4.3c-1.1,1-2.1,2.2-3.3,3.1
	c-1.2,0.8-2.6,1.2-3.9,1.8c0-0.1-0.1-0.2-0.1-0.3c0.3-0.2,0.6-0.3,1-0.5c0-0.1,0-0.1-0.1-0.2c-2.5,0.3-5,0.5-7.4,0.8
	c-3.4,0.4-6.8,0-10.1-0.3c-1.5-0.1-2.9-0.2-4.4-0.3c-0.4,0-0.7,0.4-1.3,0.7c0.1-0.4,0.1-0.6,0.1-0.5c-0.6-0.4-0.9-0.8-1.4-1
	c-1.1-0.5-2.3-1-3.4-1.5c-1.8-0.8-3.7-1.5-5.2-2.6c-1.1-0.8-2.1-1.1-3.3-1.4c-0.5-0.1-1-0.4-1.5-0.6c-1-0.4-2-0.7-2.9-1.2
	c-2.1-1.3-4.1-2.8-6.2-4.2c-1.6-1.1-3.2-2.1-4.7-3.3c-1.6-1.3-3.2-2.8-4.6-4.3c-1.1-1.1-2.1-2.3-3-3.5c-0.7-0.9-1.2-1.9-1.8-2.9
	c-0.1-0.3-0.2-0.6-0.2-0.9c0.3-2.9,0.5-5.9,0.8-8.8c0.2-2.1,1.1-3.9,2.9-5c1.8-1.2,2.8-3,3.8-4.8c0.6-1.2,1.5-2.4,2.2-3.6
	c0.6-0.9,1-1.9,1.6-2.8c1.7-2.2,3.5-4.3,5.2-6.4c0.5-0.6,0.8-0.4,1.4,0c1.8,1.3,3.6,2.5,5.5,3.7c1.1,0.7,2.3,1,3.4,1.8
	c2.4,1.6,5,2.6,7.7,3.4c2.4,0.7,4.7,1.7,7.1,2.3c4.3,1,8.4,2.9,12.9,3.2c1.5,0.1,3,0.8,4.5,1c2.1,0.3,4.2,0.6,6.3,0.7
	c4,0.1,7.8,1.2,11.7,1.9c3.1,0.5,6.1,0.9,9.2,1.3c0.3,0,0.8,0.5,0.8,0.7c-0.5,2.6-0.7,5.3-2.4,7.6c-0.7,0.9-1.6,1-2.6,1.1
	c-1.1,0-1.9,0.6-2,1.7c-0.3,3.4-0.6,6.7-1.1,10.1C261.4,203.7,260.8,204.6,260.3,205.8z M217.2,199.2c0-0.1-0.1-0.3-0.1-0.4
	c0.5-0.4,1-0.8,1.6-1.2c1.5-0.9,3-1.7,3.4-3.6c0.3-1.4,0.3-2.2-0.9-2.9c-1-0.6-2.2-1.2-3.4-1.3c-1-0.1-2.2,0.4-3.1,0.9
	c-0.3,0.1-0.4,1.1-0.2,1.6c0.2,0.5,0.8,0.9,1.3,1.3c0.1,0.1,0.7-0.2,0.7-0.4c0.4-1.6,1.5-1.2,2.6-1c1.3,0.3,1.4,1,0.5,1.9
	c-0.2,0.2-0.4,0.3-0.6,0.4c-1.7,1.4-3.4,2.9-5,4.4c-0.3,0.3-0.7,0.9-0.6,1c0.3,0.4,0.9,1,1.4,1c1.7,0.3,3.4,0.3,5.1,0.5
	c1.2,0.1,2.4,0.1,3.6,0.2c0.7,0,1.3-0.5,0.9-1.1c-0.5-0.8-1.2-1.4-2.5-1.2C220.3,199.6,218.8,199.3,217.2,199.2z M231.3,196.3
	c0-1.1-0.1-2.3,0-3.5c0.2-1.2-0.2-1.9-1.3-2.4c-1.1-0.4-1.9,0-2.6,0.8c-1.4,1.7-2.9,3.3-4.3,5c-0.2,0.2-0.2,0.9,0,1
	c0.8,0.4,1.6,0.8,2.5,1c0.7,0.2,1.4,0.1,2.1,0.2c1.1,0.2,1.3,0.7,1.2,1.7c-0.1,0.9,0,1.8,0.2,2.7c0.1,0.3,0.6,0.6,1,0.6
	c0.2,0,0.6-0.5,0.7-0.8c0.2-0.9,0.3-1.8,0.4-2.7c0.1-0.8,0.1-1.4,1.2-1.4c0.3,0,0.7-0.2,1-0.4c-0.4-0.5-0.7-0.8-1-1.1
	C232.1,196.9,231.8,196.7,231.3,196.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M170.4,5.5c-1.9,2.1-3.9,4.3-5.8,6.4c-0.4,0.4-0.8,0.9-1.1,1.4c-1.4,2.3-3.6,4.1-4.1,7
	c-0.2,0.9-1.1,1.5-1.4,2.4c-0.8,2-1.5,4.1-2.2,6.1c-0.7,2-1.5,4-1.8,6c-0.7,4.8-0.7,9.6-0.2,14.4c0.2,2.2,0.2,4.4,0.5,6.5
	c0.2,2,0.6,4,1.1,5.9c0.8,3.2,1.9,6.4,2.8,9.6c0.1,0.2,0,0.5,0,0.8c0,0.5-0.1,1,0,1.4c0.5,0.9,0,1.3-0.7,1.4c-4,0.5-7,3-10.1,5.1
	c-2,1.4-3.8,2.9-5.7,4.4c-0.7,0.5-1.3,1.2-1.9,1.8c-0.4,0.4-0.8,0.5-1.4,0.2c-4.4-1.6-8.7-3.4-13.5-3.3c-2.2,0.1-4.4-0.3-6.7-0.5
	c-0.3,0-0.6-0.1-1,0c-1.5,0.2-2-0.1-2.5-1.5c-0.3-1-0.8-1.9-1.1-2.9c-0.4-1.1-0.8-2.2-1-3.4c-0.5-4-1.1-8.1-1.3-12.1
	c-0.1-2.8,0.5-5.5,0.9-8.3c1-7.6,3.1-15,5.9-22.1c2.5-6.4,5.3-12.7,9.3-18.3c1.4-2,2.9-3.9,4.4-5.8c1-1.2,2.3-1.9,3.9-2
	c3.7-0.1,7.4-0.4,11.1-0.4c5.8-0.1,11.6-0.2,17.3-0.2C166.3,5.5,168.3,5.5,170.4,5.5z M138.3,55.6c0.7,1.4,0,2.2-1.7,2.8
	c-0.7,0.2-1.5,0.3-2.2,0.5c-0.3,0.1-0.6,0.4-0.6,0.6c0.1,0.3,0.4,0.7,0.6,0.7c0.8,0.2,1.6,0.3,2.3,0.3c2.1-0.1,4.7-2,3.9-4.6
	c-0.1-0.3-0.1-0.6-0.1-0.9c0.3-1.8-1.1-3.1-1.6-4.7c-0.1-0.2-0.4-0.3-0.6-0.3c-1.2,0.1-2.4,0-3.5,0.3c-2,0.7-3.2,3.5-2.2,5
	c1.4,2.2,1.9,2.6,4.1,1.2C137.3,56.2,137.8,55.9,138.3,55.6z M122.7,53.8c0.2,0.5,0.4,0.9,0.6,1.4c1.9,0.3,2.1-2.1,3.8-2.4
	c-0.1,0.8-0.4,1.5-0.3,2.2c0.1,2-1.1,4.3,1.6,6c0.2-0.7,0.4-1.3,0.5-1.9c0.1-2.1,0.2-4.1,0.3-6.2c0-1,0.1-2.1,0.2-3.1
	c-0.6,0.1-1.1,0.3-1.3,0.2c-0.6-0.5-1-0.2-1.5,0.2C125.3,51.4,124,52.6,122.7,53.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M406.7,105.9c0.1-2.6-0.3-4.9-1.5-7c-0.9-1.6-2.2-2.5-4.1-2.5c-2.1,0-4.2,0.1-6.3-0.3c-2-0.4-4.1-1.2-6-2.1
	c-3-1.4-5.9-3-8.3-5.4c-3.3-3.2-5.5-7-6.5-11.5c-0.7-3.3-1.6-6.7-2.1-10c-0.6-3.9-1.4-7.8-1.3-11.8c0-1.7-0.3-3.3-0.5-5
	c-0.1-0.8,0.1-1.3,0.7-1.7c4.5-3.2,9.4-5.8,14.3-8.2c2-1,3.9-2.1,5.9-3c2-1,4.2-1.8,6.3-2.7c0.2-0.1,0.5-0.1,0.7-0.2
	c1.8-0.6,1.7-0.6,2.3,1.1c1.4,3.8,2.6,7.7,4.5,11.2c2.3,4.3,5.3,8.2,8.5,11.9c3.6,4,7.5,7.6,11.7,10.9c2.9,2.3,5.9,4.4,9.1,6.2
	c0.5,0.3,0.9,0.4,0.7,1.2c-0.3,1.1,0.4,1.2,1.3,1c2.1-0.4,2.5-0.1,2.6,2c0.2,3.7,0.4,7.3,0.5,11c0,2-0.2,4-0.4,6
	c0,0.3-0.2,0.7-0.4,0.9c-0.7,0.6-1.5,1.3-2.3,1.7c-0.5,0.3-1.1,0.2-1.7,0.3c-0.4,0.1-0.8,0.2-1.1,0.3c-3.1,1-6.2,2.3-9.4,3
	c-4.7,1.1-9.5,1.9-14.3,2.7C408.6,106,407.7,105.9,406.7,105.9z M392.3,70.4c0.1,0.1,0.2,0.2,0.4,0.2c-1.4,1.6-2.8,3.2-4.2,4.9
	c-0.3,0.3-0.4,1.1-0.2,1.3c0.4,0.3,1,0.5,1.5,0.5c0.9,0,1.8-0.3,2.7-0.3c1.1,0,2.1,0.1,2.1,1.6c0,0.3,0.6,0.8,0.9,0.8
	c0.4,0,1.1-0.5,1.1-0.7c0-0.8,0.4-1.2,1-1.6c0.4-0.3,0.9-1-0.1-1.2c-1-0.2-1.1-0.7-1-1.5c0.1-1.2,0.2-2.5,0.3-3.7
	c0-0.4-0.2-1-0.5-1.3c-0.4-0.4-1-0.7-1.6-0.7c-0.8-0.1-1.6,0.1-2.4,0.2C392.1,69.1,392.2,70,392.3,70.4z M405.7,70.9
	c-0.3,0.5-0.5,0.9-0.7,1.3c-1.1,2-2.3,3.9-3.3,5.9c-0.2,0.3-0.1,0.8,0,1.1c0.1,0.2,0.6,0.4,0.8,0.3c0.4-0.2,0.8-0.5,1.1-0.9
	c0.3-0.5,0.3-1.2,0.7-1.7c1.1-1.9,2.3-3.7,3.5-5.5c0.8-1.2,0.6-1.9-0.7-2c-2.3-0.3-4.6-0.3-7-0.3c-0.3,0-0.8,0.4-0.8,0.6
	c0.1,1.1,0.3,2.1,0.6,3.2c0,0.2,0.8,0.4,1,0.2c0.3-0.2,0.6-0.6,0.7-1c0.1-0.3,0-0.7-0.1-1C402.9,70.9,404.2,70.9,405.7,70.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity202 ? this.state.opacity202 : 1}
              fill={this.state.color202 ? this.state.color202 : 'transparent'} onClick={() => {
          this.setState({ color202: color, opacity202: opacity });
        }} d="M215.5,140.9c0.3-0.6,0.4-1.3,0.8-1.8c1.6-1.8,1.9-4.1,2.7-6.2c0.7-1.9,1.3-3.9,2.1-5.9
	c0.2-0.4,0.7-0.8,1.2-0.9c2.5-0.7,4.9-1.7,6.6-3.8c2.3-2.9,1.4-7.1-1.9-9c-0.1-0.1-0.3-0.2-0.6-0.4c0.9-0.7,1.6-1.4,2.5-2
	c1.9-1.4,4.1-2.4,5.5-4.4c1.4-2.1,1.7-6.2-0.2-8c-1.2-1.2-2.9-1.9-4.7-1.9c-0.9,0-1.7,0-2.5,0c1-1.6,2-3.2,3-4.8
	c1.7-2.9,2.5-6,2.2-9.4c-0.1-1.7-0.2-3.3-0.9-4.9c-1.9-4.2-3.5-4.8-7.4-2.8c-2.1,1.1-4.2,2.1-6.2,3.2c-0.1,0-0.1,0-0.2,0
	c1.7-3.9,1.7-7.9,0.8-12c-1.1-4.4-6.2-6.8-10.1-5.8c-2.9,0.7-5.7,1.9-7.4,4.5c-1,1.5-1.5,3.3-2.2,5c-0.1,0.2-0.2,0.4-0.3,0.7
	c-1.3-0.4-2.5-0.7-3.7-1.1c-2.6-0.9-5.2-1.2-8-0.9c-2.9,0.2-5.9,0.1-8.8,0.1c-4,0-7.9,1-11.6,2.3c-1.2,0.4-2.3,0.7-3.6,1.1
	c-0.3-1.2-0.6-2.1,0.4-3.2c2.7-2.9,5.5-5.7,9-7.5c2.9-1.5,5.7-3,8.6-4.3c0.8-0.4,1.8-0.2,2.8-0.3c1.3-0.1,2.6-0.2,3.9-0.3
	c0.5,0,0.9-0.2,1.4-0.3c-0.4-1.9-0.4-1.9-2.4-1.9c-0.1,0-0.2,0-0.2,0c-2.2,0.6-4-0.5-5.9-1.2c-3-1.1-4.7-3.4-5.2-6.6
	c-0.1-0.6-0.5-1.4-0.2-1.8c0.5-0.7,1.2-1.4,2-1.9c2.2-1.5,4.5-1,6.8-0.4c2.9,0.8,5.6,2.1,7.7,4.3c0.3,0.3,0.6,0.6,1,0.8
	c0.4,0.2,0.9,0.1,1.3,0.2c0-0.4,0.3-1,0.1-1.3c-0.6-1.2-0.3-2.1,0.4-3.1c0.4-0.5,0.8-0.8,1.4-0.3c1.3,1.1,2.7,2,3.9,3.2
	c0.5,0.5,0.4,1.6,0.5,2.4c0.1,0.6-0.1,1.5,0.8,1.5c0.8,0,1.1-0.6,1.2-1.4c0.1-1.3,2.4-3.8,3.7-4.1c1.1-0.2,1.6,0.6,2.4,1.1
	c0.4,0.3,0.8,0.6,1.3,0.7c1.2,0.1,1.7,0.6,1.5,1.8c-0.1,0.7-0.3,1.4-0.5,2.1c-0.3,0.8-0.1,1.3,0.8,1.5c2.2,0.5,4.4,1.4,6.6,1.6
	c3.4,0.2,6.3,1.7,9.4,2.7c0.4,0.1,0.8,0.3,1.2,0.3c2.4-0.1,3.9,1.2,5,3.1c1.3,2.3,2.4,4.7,3.5,7.2c0.7,1.5,1.9,2,3.3,2.1
	c3,0.4,5.1,1.9,7,4.2c2.5,3.1,3.7,6.8,5.2,10.3c1.3,3.1,2.8,6.1,4,9.2c1,2.4,1.8,4.9,2.5,7.5c1,4.2,1.8,8.6,2.7,12.8
	c0.3,1.4,0.5,2.7,0.6,4.1c0,0.2-0.3,0.7-0.5,0.8c-1.7,0.9-3.5,1.6-5.2,2.5c-2.6,1.3-5.2,2.7-7.9,3.9c-2.6,1.2-5.2,2.4-7.8,3.6
	c-1,0.5-2,1.1-3.1,1.6c-0.6,0.3-1.3,0.4-2,0.6c-0.3,0.1-0.7,0.2-1,0.3c-3.2,1.8-6.4,3.5-9.5,5.4c-2.6,1.6-5.2,3.4-7.8,5.1
	c-0.4,0.3-0.8,0.5-1.2,0.8C215.7,141.1,215.6,141,215.5,140.9z M245,107c0.1,0,0.2-0.1,0.3-0.1c0-0.8,0-1.7-0.2-2.5
	c-0.1-0.5-0.5-1.2-0.8-1.2c-0.9-0.1-2-0.9-2.9,0.2c-0.5,0.7-1.2,1.3-1.7,2c-1.2,1.6-2.4,3.2-3.5,4.8c-0.2,0.3-0.3,1.1-0.1,1.4
	c0.3,0.4,1,0.6,1.5,0.6c1.3-0.1,2.6-0.4,3.9-0.5c1.4-0.2,1.7,0.2,1.8,1.6c0,0.4,0.3,1,0.6,1.3c0.3,0.2,1.1,0.2,1.3,0
	c0.3-0.4,0.5-1.1,0.5-1.6c-0.1-0.8-0.3-1.5,0.4-2.2c0.2-0.2,0.1-0.8,0-1.2C245.8,108.7,245.4,107.9,245,107z M251.3,106.1
	c0,2.4,0.1,4.8,0,7.1c-0.1,1,0.5,1.5,1.2,1.4c0.4,0,1.2-0.8,1.2-1.2c0.2-1.5,0-3,0.2-4.5c0.3-1.8,0.3-3.6-0.1-5.4
	c-0.3-1.1-1.2-1.3-2-0.6c-1.2,1-2.4,2-3.6,2.9c-0.6,0.4-0.6,0.9-0.4,1.4c0.3,0.7,0.9,0.5,1.4,0.2C249.8,107,250.5,106.6,251.3,106.1
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M272.1,160c-0.1,2.3-0.2,4.5-0.3,6.7c-0.2,3.1-0.5,6.1-0.7,9.2c-0.1,2-0.3,2.2-2.3,2.4
	c-1.1,0.1-2.1,0.3-3.2,0.4c-0.5,0-1-0.1-1.4-0.3c-1.5-0.8-3.1-0.9-4.8-0.6c-0.4,0.1-0.8,0-1.2-0.1c-4.5-0.8-9-1.6-13.6-1.7
	c-1.7,0-3.3-0.7-5-1c-3.3-0.7-6.7-1.2-10-2.1c-3.9-1-7.7-2.4-11.5-3.6c-3.1-1-6.1-2-8.6-4.1c-0.8-0.7-2-0.9-2.9-1.5
	c-1.5-0.9-2.8-2-4.3-3c-0.7-0.5-0.6-1-0.1-1.6c1.7-2,3.4-4.1,5-6.1c1-1.3,2-2.5,3.1-3.8c1.7-2,3.5-3.9,5.9-5.3
	c2.6-1.5,4.9-3.5,7.5-5.1c2.6-1.6,5.4-2.8,8.1-4.3c1.5-0.8,2.8-1.8,4.3-2.6c3.7-1.9,7.5-3.7,11.3-5.6c2.6-1.3,5.2-2.6,7.8-3.9
	c2.3-1.1,4.5-2.2,6.8-3.3c0.9-0.4,1.1-0.3,1.4,0.6c0.6,1.8,1.4,3.6,2.1,5.4c0.6,1.7,1.2,3.5,1.6,5.2c1.3,5.6,2.6,11.3,3.5,17
	C271.4,151.5,271.7,155.8,272.1,160z M233.1,157.5c0-0.1-0.1-0.2-0.1-0.4c0.5-0.3,0.9-0.7,1.4-1c1.4-1,3.1-1.6,3.2-3.8
	c0.1-1.6-1.3-3.7-3-4.1c-1.4-0.3-3.6,1-4,2.5c-0.2,0.6,0.5,1.7,1.3,1.9c0.9,0.2,0.6-0.6,0.8-1c0.4-0.5,0.9-1,1.4-1.1
	c0.4,0,1,0.6,1.2,1.1c0.3,0.8,0,1.5-0.9,1.9c-1.9,0.9-3.6,2.1-4.6,4c-0.2,0.5-0.4,1.2-0.3,1.6c0.3,0.8,1,0.9,1.9,0.9
	c1.6,0,3.1,0.1,4.7,0.1c0.9,0,1.8-0.1,2.7-0.4c0.4-0.1,0.5-0.8,0.8-1.2c-0.4-0.2-0.8-0.6-1.3-0.6
	C236.6,157.8,234.8,157.7,233.1,157.5z M242.9,152.7c0.1,0.5,0.2,0.8,0.3,1.1c0.1,1.5,0,3,0.2,4.4c0.1,0.6,0.8,1.5,1.4,1.6
	c0.8,0.1,1-0.8,0.9-1.5c-0.1-1.3-0.3-2.7-0.3-4c0-1.3,0.2-2.7,0.2-4c0-0.6-0.1-1.4-0.5-1.6c-0.4-0.2-1.3-0.1-1.7,0.2
	c-1.4,1.1-2.6,2.4-3.9,3.7c-0.3,0.3-0.6,1-0.4,1.2c0.3,0.3,0.9,0.6,1.3,0.6c0.5,0,1-0.4,1.5-0.7
	C242.2,153.4,242.5,153.1,242.9,152.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M78.5,320.9c0.9,0.6,1.6,1.1,2.5,1.7c-0.8,1.7-1.7,3.6-2.4,5.4c-0.5,1.3-0.8,2.8-1.3,4.1
	c-0.1,0.4-0.6,0.7-0.8,1.1c-1.1,2.1-2.1,4.3-3.1,6.5c-0.3,0.6-0.4,1.2-0.8,1.7c-0.3,0.4-0.9,0.7-1.4,0.7c-1.7,0-3.3-0.6-4.9-1.3
	c-2.2-0.9-4.4-1.7-6.5-2.8c-3.3-1.7-6.5-3.5-9.7-5.3c-0.8-0.4-1.6-1-2.4-1.4c-6-3.5-11.2-8.1-16.2-12.9c-4-3.8-7.5-8.1-11.2-12.1
	c-3-3.3-4.1-7.3-4-11.7c0.1-4.6,0.2-9.3,0.3-13.9c0.1-3.7,0.3-7.5,0.2-11.2c-0.1-3.1,1.3-5.3,3.5-7.2c1.3-1.1,2.5-2.2,3.9-3.2
	c3-2.1,6-4.1,9-6.1c1.5-1,2.9-1.9,4.4-2.8c0.3-0.2,0.7-0.2,1.1-0.3c0,0.3,0.2,0.7,0.1,1c-0.5,4.5-0.5,9.1,0.2,13.6
	c0.5,3.5,1,7.1,2.3,10.4c1,2.5,1.7,5.1,2.7,7.6c0.8,1.8,1.8,3.5,2.8,5.2c0.4,0.7,0.8,1.3,1,2c0.8,2.4,2.3,4.2,3.8,6.1
	c1.2,1.6,2.2,3.4,3.5,5c1.9,2.3,3.9,4.5,5.9,6.7c0.7,0.8,1.4,1.5,2.1,2.3c1.1,1.1,2,2.3,3.2,3.2c3.2,2.4,6.6,4.6,9.9,6.9
	C77,320.4,77.5,321.6,78.5,320.9z M37.6,316c0.3,0.2,0.6,0.5,0.9,0.6c1.7,0.6,3.5,0.5,5.1-0.3c2.1-1.1,2.1-3.7,0.1-5
	c-0.7-0.4-1.4-0.7-2.1-1.1c0.4-0.8,0.8-1.5,1-2.2c0.1-0.4-0.1-1.1-0.4-1.3c-2.1-1.8-4.6-1.6-6.5,0.4c-0.5,0.6-0.4,1,0.1,1.4
	c0.7,0.6,1,0,1.4-0.4c1.3-1.3,2.5-1.5,4.1-0.3c-1,1.2-1.9,2.4-3,3.8c0.4,0.1,0.9,0.5,1.3,0.4c1.2-0.4,2.1,0.1,3,0.8
	c0.7,0.5,1,1.2,0.4,2c-0.6,0.7-2.2,1.1-2.8,0.6C39,314.4,38.6,314.5,37.6,316z M55.8,313.8c0.1-1.1-0.3-2.3-1.6-3.2
	c-1.6-1.2-3.6-0.8-5.3-1c-0.2,0-0.6,0.3-0.7,0.5c-0.6,1.1-1.2,2.3-1.6,3.5c-0.2,0.5-0.2,1.4,0.1,1.8c0.7,1.1,1.4,2.3,2.4,3.1
	c1.5,1.4,4.4,1.3,5.4-0.1C55.3,317.2,56,315.8,55.8,313.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M360.3,106c1.4,0.2,2.9,0.4,4.5,0.7c2.8,0.4,5.6,0.9,8.5,1.4c1.1,0.2,2.1,0.3,3.2,0.4c0.2,0,0.5,0.2,0.6,0.3
	c1.7,1.6,3.5,3.2,5,4.9c2.6,3,5.9,5.1,9.2,7.2c2.8,1.7,5.7,3,8.7,4.5c1.6,0.8,1.9,1.2,1,2.7c-1.3,2.2-2.7,4.3-4.2,6.3
	c-2.5,3.3-5,6.5-7.6,9.6c-1.1,1.3-2.1,2.9-3.6,3.5c-2,0.7-2.9,2.3-4.4,3.5c-2.1,1.6-4.5,2.1-6.9,2.6c-2.8,0.5-5.6-0.1-8.3-0.2
	c-3.6-0.1-7.3-0.5-10.9-0.6c-1.3,0-2.7,0.5-4,0.9c-0.9,0.3-1.7,0.8-2.6,1.2c-0.9,0.6-1.8,1.2-2.7,1.8c-2.4,1.5-4,3.8-5.8,5.9
	c-0.7,0.9-1.8,1.5-2.6,2.3c-0.8,0.8-1.4,1.7-2.2,2.8c-1.2-1.1-2.4-2.2-3.6-3.1c-2.4-1.8-3.9-4.4-5.7-6.7c-3-3.7-5.8-7.7-7.7-12.1
	c-0.7-1.6-1.3-3.3-2-5c-0.3-0.7,0-0.9,0.6-1.2c5.9-2.3,11.9-3.7,18.2-4.2c1.8-0.1,3.7-0.4,5.2-1.1c2.9-1.4,5.9-2.8,7.9-5.5
	c1.1-1.5,2.1-3,3.4-4.3c2.5-2.5,3.7-5.7,5.2-8.7c0.9-1.7,1.4-3.6,2.1-5.4C359.1,108.7,359.7,107.4,360.3,106z M370,137.5
	c0.2-0.4,0.3-0.8,0.5-1.2c0.6-1.4-0.3-2.8-1.8-2.8c-1.2,0-2.4,0.3-3.6,0.4c-1.3,0.2-2.7,3.7-1.7,4.6c0.6,0.6,0.5,1.2,0.2,1.9
	c-0.6,1.6-0.1,3.2,1.1,3.6c2,0.6,3.9,0.4,5.7-0.7c1.3-0.8,1.9-2.1,1.8-3.6C372.1,138.7,371.2,138,370,137.5z M359.9,135
	c-1.1,2.4-2.1,4.7-3.2,7c-0.4,0.7-0.2,1.3,0.5,1.6c0.7,0.3,1-0.3,1.3-0.8c1.2-2.5,2.4-4.9,3.6-7.4c0.2-0.5,0.5-1.3,0.4-1.5
	c-0.6-0.4-1.3-0.8-2-0.8c-1.7-0.1-3.5,0-5.2,0c-0.4,0-1.1,0.4-1.1,0.6c0,1.1,0.1,2.1,0.3,3.2c0.1,0.2,0.7,0.5,1,0.5
	c0.3-0.1,0.5-0.6,0.6-0.9c0.1-0.4,0-0.8,0-1.4C357.5,135,358.7,135,359.9,135z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1}
              fill={this.state.color17 ? this.state.color17 : 'transparent'} onClick={() => {
          this.setState({ color17: color, opacity17: opacity });
        }} d="M405.9,183c0.5,0.1,1.1,0.2,1.6,0.4c0.8,0.3,1.7,0.7,2.5,1.1c1.5,0.9,3,1.9,4.4,2.8c1.1,0.7,2.3,1.3,3.4,2
	c3.6,2.4,7.6,4.3,10.7,7.5c1.5,1.5,2.9,3,4.4,4.5c0.5,0.5,1.3,0.9,2,1.2c1.3,0.5,1.8,1,1.8,2.5c-0.1,3.7-0.4,7.4-0.4,11.1
	c-0.1,6.7-0.2,13.3-0.2,20c0,3.2,0.3,6.3,0.5,9.5c0,1,0,2,0,3.3c-0.8-0.4-1.4-0.6-1.8-1c-1.2-1.1-2.4-2.2-3.6-3.3
	c-2-1.7-3.7-3.7-6.1-4.9c-2.9-1.5-5.5-3.5-8.4-4.9c-2.5-1.3-5.1-2.1-7.7-3.1c-1.7-0.7-3.4-1.6-5.1-2.3c-1.9-0.8-3.8-1.5-5.8-2.1
	c-4.7-1.5-8.7-4.2-12.3-7.4c-3.2-2.9-6.5-5.9-9.3-9.2c-1.8-2.1-3.7-4.2-4.5-7.1c-0.6-2.1-2-4-2.6-6.1c-0.7-2.2-1-4.5-1.5-6.9
	c1.9,0.1,3.4,0.2,5,0.4c1.7,0.2,3.5,0.4,5.2,0.6c0.8,0.1,1.7-0.1,2.5-0.2c2.3-0.2,4.6-0.1,6.8-0.6c2.7-0.6,5.5-1.4,8.1-2.5
	c2.8-1.2,5.4-2.7,8-4.1c0.8-0.4,1.6-0.9,2.4-1.4C405.8,182.7,405.9,182.9,405.9,183z M408.8,209.7c-0.9-0.9-1.8-1.7-2.6-2.6
	c-0.2-0.3-0.4-0.8-0.4-1.1c0.2-1.5-0.9-2.8-2.3-3.1c-1.5-0.3-2.6,0.4-3.8,1.1c-1.7,1-1.7,1.2-0.3,2.8c0.8-0.6,1.7-1.2,2.6-1.7
	c0.4-0.2,0.9-0.1,1.4-0.1c-0.2,0.4-0.2,0.9-0.5,1.2c-0.5,0.8-1.1,1.5-1.6,2.1c0.6,0.8,1,1.3,1.4,1.8c0.4-0.2,0.7-0.5,1.1-0.7
	c1.3-0.7,2.5-0.1,2.5,1.2c0,1.1-2.1,2.4-3.1,2.1c-0.6-0.2-1.3-0.3-2.1-0.4c0.2,2.3,1.8,3.3,3.6,2.6c1.6-0.6,3-1.6,3.8-3
	c0.7,0.5,1.2,1.1,1.8,1.4c1.6,0.8,3.2,0.5,4.8-0.4c2.3-1.4,2.6-3.5,0.6-5.2c1-1.2,1.5-2.7,0.8-3.4c-1-1-2.2-2.1-3.7-2.2
	c-0.8,0-1.9,0-2.3,0.5c-0.9,1.1-1.5,2.6-1,4C409.9,208.1,409.3,208.9,408.8,209.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M110.4,275.1c-1.7,0.7-3.2,1.3-4.7,1.8c-2.2,0.7-4.3,1.6-6.6,2.1c-3.2,0.7-6.3,1.7-9.7,1.7
	c-0.9,0-1.7,0.4-2.6,0.5c-1.3,0.2-2.6,0.4-3.9,0.4c-0.5,0-1.2-0.5-1.3-0.9c-0.9-3-1.7-6-2.5-9c-0.6-2.2-1-4.5-1.4-6.8
	c-0.2-1.2-0.1-1.2,1.2-3.2c-0.5-0.3-1-0.7-1.6-1.1c0.1-1.8,0.2-3.7,0.5-5.7c0.3-3,0.7-6,1-9c0.3-3.1,1.7-5.8,3.5-8.3
	c1.6-2.3,3-4.9,4.6-7.2c1.6-2.3,3.2-4.6,4.9-6.7c2.6-3.2,5.2-6.5,8-9.5c2-2.2,4.3-4.1,6.6-6.1c3-2.5,6.1-5,9.2-7.4
	c2.2-1.7,4.7-3.2,6.9-4.8c1.3-1,2.5-2.1,3.8-3.1c1.4-1.1,3-2.1,4.4-3.2c1.6-1.2,3.2-2.4,4.7-3.6c0.4-0.3,0.8-0.6,1.3-1
	c0.1,0.2,0.3,0.4,0.3,0.5c-0.6,1.3-1.3,2.6-2,3.9c-0.6,1.3-1.4,2.5-1.8,3.9c-1.4,4.5-2.9,9-3.2,13.8c-0.2,2.6-0.8,5.1-0.9,7.6
	c-0.1,2.3,0,4.6,0.1,6.9c0,1-0.4,1.7-1.3,2.2c-2.7,1.3-5.3,2.6-7.9,3.9c-2.4,1.3-4.2,3.3-5.8,5.4c-0.8,1.1-1.6,2.1-2.4,3.2
	c-2,2.8-3.6,5.8-4.8,9c-0.9,2.4-2.1,4.8-2.7,7.3c-0.5,1.8-0.4,3.9-0.3,5.8c0.1,2.9,0.1,5.8,1.5,8.4c1,1.9,2.2,3.7,3.3,5.5
	C109.4,273.2,109.9,274,110.4,275.1z M98.7,254.3c0.1-0.1,0.5-0.4,0.8-0.8c1.1-1.1,0.8-2.6-0.8-2.8c-1.1-0.1-2.2,0.1-3.3,0.4
	c-0.4,0.1-1,0.7-1,1c0.1,0.8,0.8,1,1.6,0.7c0.5-0.2,1-0.3,1.5-0.4c0.1,0.1,0.1,0.3,0.2,0.4c-1.3,1-2.5,2-3.8,3.1
	c0.8,1.1,1.9,0.7,2.8,0.7c0.9,0,1.8,0.1,2.2,1.1c0.4,1.1,0,2.2-0.7,3.1c-0.6,0.8-1.5,0.5-2.3,0.4c-0.9-0.2-1.8-0.3-2.6-0.3
	c-0.2,0-0.5,0.3-0.8,0.4c0.2,0.3,0.4,0.7,0.6,0.8c0.9,0.3,1.9,0.5,2.9,0.6c3.7,0.7,6.1-1.9,5-5.5C100.7,256.1,99.6,255.3,98.7,254.3
	z M90.6,250.8c-0.9,1.9-1.7,3.4-2.5,4.9c-0.6,1.1-1.2,2.3-1.6,3.5c-0.1,0.3,0.2,1.1,0.4,1.1c0.5,0.1,1.4,0,1.5-0.3
	c1.5-3,3-6.1,4.4-9.2c0.5-1.2,0-1.9-1.3-2c-2.1,0-4.3,0-6.4,0c-0.3,0-0.8,0.6-0.8,0.9c0.1,1,0.4,1.9,0.6,2.9
	c0.1,0.3,0.4,0.8,0.6,0.9c0.4,0.1,1,0,1.3-0.2c0.1-0.1,0-0.7-0.1-1.1c-0.1-0.4-0.2-0.7-0.4-1.3C87.8,250.8,89,250.8,90.6,250.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M391.8,399c-1.3,1.7-2.4,3.2-3.5,4.7c-2.9,3.9-6.5,7.2-10.4,10.2c-3,2.3-6.1,4.6-9.6,6.2
	c-2.9,1.3-5.6,3.2-8.4,4.8c-3,1.7-6.1,3.4-9.2,4.9c-2.2,1-4.5,1.7-6.7,2.5c-2.3,0.8-4.6,1.5-6.9,2.3c0,0.1,0.1,0.3,0.2,0.7
	c-2.5,1-5,0.5-7.5,0.6c-1.7,0.1-3.3,0.1-5,0.2c-2,0.2-6.3,0.2-8.1-1.1c-1.7,1.4-3.4,0.5-4.9-0.3c-1.4-0.8-2.6-2-3.9-3
	c-3.2-2.5-6.5-4.9-9.6-7.6c-2.6-2.2-5-4.8-7.5-7.2c-1.4-1.4-2.8-2.8-4.3-4.2c-3.4-3.2-5.4-6.9-5.4-11.7c0-2.1-0.8-4.2-1-6.2
	c-0.3-2.1-0.4-4.3-0.5-6.4c0-0.6,0.2-1.4,0.5-1.9c0.2-0.3,0.9-0.6,1.1-0.5c2,1.1,4.2,1,6.4,1.1c2,0.1,4,0.3,6.1,0.5
	c0.9,0.1,1.9,0.1,2.8,0.1c0.9-0.1,1.4,0.2,1.4,1.2c-0.1,4.2,1.8,7.8,4.2,11.1c2.1,2.9,5.2,4.4,8.3,5.8c1.4,0.6,2.8,1.3,4.3,1.9
	c1,0.4,2.1,0.6,3.1,0.8c3.3,0.6,6.6,1.5,10,1.3c2.2-0.1,4.5,0.2,6.8,0.5c4.2,0.6,8.4,0.3,12.6-0.1c3.1-0.3,6.2-0.5,9.3-0.9
	c2.7-0.4,5.4-1.1,8.1-1.6c0.7-0.1,1.3-0.2,2-0.3c3-0.8,5.9-1.6,8.9-2.4c2.6-0.8,5.3-1.5,7.9-2.4c2.6-1,5.2-2.2,7.8-3.3
	C391.3,399.2,391.4,399.2,391.8,399z M334.5,422.5c0,1.4-0.6,2.1-1.9,2.1c-0.4,0-0.7,0-1-0.2c-0.8-0.4-1.1,0-1.4,0.6
	c-0.4,0.6-0.3,1,0.3,1.5c0.8,0.7,1.6,0.5,2.4,0.4c2.1-0.2,4.1-1.9,3.8-3.8c-0.3-2.3,0.4-4.6-0.3-6.9c-0.5-1.6-1.6-2.5-3.2-2.2
	c-1.8,0.3-3.2,1.2-4.5,2.3c-1.4,1.2-1.6,2.8-0.4,4.3c0.9,1.1,2.9,1.4,4.5,0.6c1.6-0.8,1.6-0.8,1.8,0.9
	C334.5,422.2,334.5,422.4,334.5,422.5z M318,416.7c0.4-0.4,0.7-0.5,0.7-0.7c0.5-1.6,1.8-1.7,3.2-1.6c0.9,0,1.4,0.4,1.5,1.3
	c0.1,1.4-0.9,2.3-1.9,2.8c-1.9,1-3.2,2.4-4.2,4.2c-0.8,1.4-0.4,2.4,1.3,2.6c1.1,0.1,2.2,0.2,3.3,0.4c1.1,0.1,2.2,0.4,3.3,0.4
	c0.4,0,0.9-0.5,1.2-0.8c0.1-0.1,0-0.5-0.1-0.6c-0.5-0.3-1.1-0.7-1.7-0.8c-1.1-0.2-2.3-0.1-3.5-0.2c-0.5,0-1-0.2-1.8-0.3
	c0.8-1.2,1.2-2.3,2.5-2.7c1.7-0.6,3-1.7,3.6-3.4c0.3-0.8,0.2-2.1-0.2-2.7c-1-1.3-2.3-2.5-4.2-2.1c-0.7,0.1-1.5,0.1-2.3,0.1
	c-0.2,0-0.5,0-0.6,0.1c-0.3,0.8-0.7,1.7-0.9,2.6C317.2,415.6,317.7,416.1,318,416.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }} d="M436.6,6.3c1.3,1.1,2.4,2,3.4,2.9c0.3,0.2,0.5,0.7,0.4,1c-0.2,1.4-0.2,2.7,0.1,4.2c0.3,1.4-0.1,3-0.2,4.5
	c-0.1,3.6-0.2,7.1-0.3,10.7c-0.1,1.9-0.2,3.8-0.3,5.7c-0.1,1.9-0.1,3.8-0.2,5.7c-0.1,2.9-0.4,5.7-0.5,8.6c-0.1,3.2,0,6.4,0,9.7
	c0,0.9-0.3,1.9-0.4,2.8c0,0.5,0.1,1.1,0.1,1.6c-0.1,2.4-0.3,4.8-0.4,7.1c-0.1,1.3-0.1,2.6-0.1,3.9c0,0.3-0.1,0.5-0.3,0.7
	c-0.1,0.1-0.4,0-0.6-0.1c-2.2-1.3-4.4-2.6-6.5-4c-4.8-3.3-9.5-6.8-13.3-11.2c-2.5-2.8-5.1-5.6-7.4-8.6c-3.3-4.5-5.8-9.4-7.3-14.8
	c-1.4-4.9-2.5-9.9-3.3-14.9c-0.7-3.9-1.2-7.7-1-11.6c0-0.9,0.1-1.8-0.1-2.6c-0.2-1.1,0.4-1.3,1.3-1.2c3.7,0.2,7.5,0.4,11.2,0.5
	c1.7,0.1,3.4-0.1,5.1-0.1c1.5,0,3.1,0.2,4.6,0.2c1.7,0,3.4-0.2,5.1-0.2c2.2,0.1,4.4,0.4,6.6,0.4C433.9,7.1,435.5,7.8,436.6,6.3z
	 M423.6,38.4c-0.1-0.1-0.1-0.1-0.2-0.2c0.6-0.4,1.1-0.9,1.7-1.1c2-0.7,2.7-2.5,3.3-4.3c0.4-1-0.1-1.8-1-2.5
	c-1.1-0.8-2.2-0.8-3.4-0.2c-1.2,0.6-1.3,1.7-1.4,2.8c0,0.3,0.4,0.7,0.6,0.8c0.2,0,0.6-0.3,0.8-0.6c0.2-0.2,0.2-0.5,0.3-0.8
	c0.2-0.7,0.5-1.2,1.3-0.9c0.8,0.3,1,1,0.7,1.7c-0.4,0.8-1,1.6-1.6,2.3c-1,0.9-2.1,1.7-3.1,2.6c-0.3,0.3-0.4,1.1-0.3,1.6
	c0.2,0.7,0.8,0.9,1.7,0.8c1.2-0.1,2.4,0,3.7-0.1c0.7,0,1.4,0,2-0.2c0.3-0.1,0.5-0.7,0.7-1.1c-0.4-0.2-0.9-0.5-1.3-0.6
	C426.7,38.4,425.2,38.4,423.6,38.4z M414.8,29.3c0.5-0.2,1-0.4,1.4-0.5c0.3-0.1,0.7,0,1.1,0c-0.1,0.3-0.2,0.8-0.4,1
	c-0.6,0.6-1.4,1.1-2,1.7c-0.3,0.3-0.3,0.9-0.5,1.3c0.5,0.1,0.9,0.3,1.4,0.4c0.6,0.1,1.4,0,1.9,0.2c0.8,0.3,1.2,1.6,0.8,2.3
	c-0.5,1.1-0.9,1.2-2.2,0.7c-0.5-0.2-1.1-0.3-1.7-0.3c-0.3,0-0.6,0.4-0.9,0.6c0.3,0.3,0.5,0.7,0.8,0.8c0.4,0.2,0.9,0,1.3,0.2
	c2.3,1.4,3.3,0.4,4.4-1c0.9-1.2,0.7-3-0.6-4.2c-0.5-0.5-1.2-0.9-1.8-1.4c0.4-0.3,0.8-0.6,1-0.9c0.2-0.6,0.6-1.6,0.3-1.9
	c-1.3-1.5-2.9-1.5-4.6-0.2C414.6,28.6,414.7,28.9,414.8,29.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }} d="M131.4,222.2c0-0.7,0-1.3,0-1.9c0.1-3.3,0-6.7,0.3-10c0.6-5.1,1.2-10.2,3.1-15.1c0.9-2.3,1.9-4.5,3-6.8
	c1.7-3.6,3.4-7.1,5.1-10.7c0.8-1.6,1.7-3.2,2.4-4.8c0.4-0.8,0.9-0.9,1.7-0.5c0,0.6,0,1.2,0,1.9c0.2-0.3,0.4-0.5,0.5-0.7
	c0.8-1.6,2.5-1.6,3.9-1.9c2.3-0.5,3.7-2.1,4.6-4.1c0.5-1.2,0.6-2.6,1-3.9c0.1-0.3,0.6-0.6,1-0.6c1.8,0,3.4-0.7,4.9-1.8
	c3.3-2.2,6.8-3.1,10.8-2.3c0.9,0.2,1.9,0,2.8,0c1.7,0.1,2.3,1.7,2.9,2.9c2.2,4,3.8,8.2,4.1,12.7c0.1,2-0.5,4.2-0.9,6.2
	c-0.5,2.1-1.2,4.1-1.7,6.2c-0.6,2.4-1.1,4.9-1.6,7.3c-0.2,0.7-0.5,1.4-0.8,2.1c-0.1,0.2-0.2,0.5-0.2,0.7c0.2,3.2-2.2,5-3.6,7.3
	c-1.1,1.7-2.3,3.3-3.4,5c-1.1,1.7-2,3.6-3,5.4c-0.2,0.3-0.7,0.5-1.1,0.5c-1.7-0.1-3.3-0.4-5-0.3c-2.9,0.2-5.8,0.6-8.6,1.1
	c-3.5,0.6-7,1.1-10.4,2c-3.7,1-7.2,2.4-10.8,3.6C132.1,222,131.9,222.1,131.4,222.2z M162,206.4c1.7,0,3.4-0.3,4.5-1.8
	c1.3-1.6,2.5-3.2,1.6-5.4c-1.1-2.7-2.8-3.7-5.8-3.6c-0.2,0-0.5-0.1-0.7-0.2c-1.4-0.6-2-0.5-2.9,0.6c-2,2.4-2.1,6.9-0.3,9.3
	c0.2,0.3,0.6,0.5,1,0.6C160.2,206.2,161.1,206.3,162,206.4z M149.2,199.2c0-0.2-0.1-0.3-0.1-0.5c0.9-1.1,1.9-2.2,2.9-3.4
	c0.1,0.5,0.2,0.6,0.2,0.7c-0.2,1.7-0.4,3.4-0.6,5.1c-0.5,3.6-0.5,3.6,0.9,5.3c1.6-2.2,2-12,0.5-14.2c-0.3,0.2-0.6,0.5-0.9,0.7
	c-1.3,1.2-2.5,2.4-3.8,3.5c-0.7,0.6-1.6,1.2-1.1,2.1C147.4,199,148.5,199,149.2,199.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }} d="M70.5,91.3c-2,0.9-4.1,1.7-6,2.7c-3.1,1.6-6.1,3.2-9.1,4.9c-3,1.7-5.9,3.5-8.9,5.2c-1.5,0.9-3,1.8-4.6,2.7
	c-0.3,0.2-0.9,0.1-1.2-0.1c-3.7-2.5-7.3-5.3-11.2-7.5c-4.6-2.6-9.4-4.8-14.2-7.1c-1.7-0.8-1.8-0.9-1.7-2.7
	c0.2-5.1,0.5-10.1,0.6-15.2c0.1-2.8-0.1-5.6-0.3-8.3c-0.1-0.8,0.3-1.1,1-1.3c1.3-0.4,2.7-0.9,4-1.4c2.2-0.9,4.3-1.9,6.4-2.9
	c3.6-1.7,7.3-3.4,10.9-5.1c4.4-2,8.9-4,13.3-6.1c1.5-0.7,2.8-1.7,4.2-2.6c0.5,2.1,0.8,4,1.3,5.9c1.2,4.6,2.5,9.2,3.9,13.7
	c0.7,2.3,1.9,4.5,2.9,6.8c0.8,1.9,1.5,3.9,2.3,5.7c1.2,2.6,2.5,5.2,3.8,7.9C68.8,87.9,69.6,89.6,70.5,91.3z M33.8,88.1
	c1.5,1.2,1.7,1.2,2.2-0.9c0.2-0.9,0.3-1.9,0.7-2.8c0.4-0.8,1-1.3-0.2-1.9c-0.1-0.1-0.1-0.4-0.1-0.7c0.4-1.6,0.8-3.2,1.2-4.7
	c0.1-0.5,0.2-1.2,0-1.4c-0.4-0.3-1.1-0.6-1.7-0.5c-2.2,0.2-4.4,0.6-6.7,0.8c-0.8,0.1-1.1,0.5-0.9,1.2c0.4,1.1,0.9,2.1,1.5,3.1
	c0.1,0.2,0.8,0.5,0.9,0.4c0.3-0.3,0.5-0.8,0.7-1.2c0-0.1-0.1-0.3-0.1-0.4c-0.5-0.9,0-1.2,0.7-1.3c1-0.1,2-0.1,3.2-0.2
	c-0.3,1.4-0.6,2.7-0.9,3.9c-0.3,1.8-0.4,1.9-2.3,2c-0.5,0-1,0.3-1.4,0.4c0,0.2,0,0.4,0,0.6c0.9,0.4,1.9,0.8,3.1,1.2
	C33.8,86.2,33.8,87,33.8,88.1z M46,76.5c0.1,0.1,0.2,0.2,0.2,0.3c-0.6,1.3-1.3,2.6-1.9,3.9c-0.4,0.9-0.8,1.6-1.9,1.6
	c-0.4,0-0.8,0.4-1.2,0.6c0.1,0.2,0.1,0.3,0.1,0.4c0.4,0.4,0.9,0.7,1.3,1c-0.1,0.5-0.2,1-0.2,1.4c0,0.4,0.1,1,0.4,1.2
	c0.9,0.8,1.8,0.2,1.9-1.3c0.1-1,0.4-1.5,1.4-1.9c0.6-0.2,0.9-1,1.4-1.6c-0.4-0.1-0.9-0.2-1.3-0.3c-0.1,0-0.1-0.1-0.2-0.3
	c0.2-0.3,0.5-0.7,0.6-1c0.7-1.6,1.4-3.2,2-4.8c0.3-0.8-0.3-1.3-1-1.3c-1.2,0-2.4,0-3.5,0.4c-0.9,0.2-1.7,0.5-2.6,0.2
	c-1.4-0.4-2.3,0.6-2,2c0.1,0.3,0,0.5,0,0.8c0.1,0.3,0.2,0.7,0.4,0.9c0.3,0.3,0.7,0.5,1.3,0.9c0.2-0.9,0.3-1.6,0.4-2.3
	C43,77.2,44.5,76.9,46,76.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity23 ? this.state.opacity23 : 1}
              fill={this.state.color23 ? this.state.color23 : 'transparent'} onClick={() => {
          this.setState({ color23: color, opacity23: opacity });
        }} d="M257.2,85.7c-0.4-0.6-0.7-0.9-0.9-1.3c-0.9-2-1.8-4.1-2.7-6.1c-0.9-1.9-1.7-3.9-2.8-5.7c-2-3.1-4.5-5.7-8.1-7
	c-0.8-0.3-1.6-0.3-2.5-0.4c-1.1-0.2-1.6-1.1-1.8-2.1c-0.7-3.2-2.5-5.7-5.1-7.6c-1.8-1.3-3.9-2.4-5.8-3.6c-0.4-0.2-0.7-0.7-0.8-1.1
	c-0.4-2.9-0.9-5.9-1.1-8.9c-0.4-5.5,0.9-10.8,3.2-15.8c0.4-0.8,0.8-1.5,1.3-2.4c1.5,1.6,3.4,1.5,5.2,1.9c2.1,0.5,3.7,1.7,5.3,3.1
	c1.2,1.1,2.3,2.6,3.7,3.4c1.8,1,3.1,2.5,4.2,4.1c1.8,2.7,3.4,5.5,5.2,8.2c0.7,1,1.7,1.8,2.2,2.9c1,2.2,1.6,4.5,2.4,6.7
	c0.1,0.4,0.2,0.8,0.4,1.1c0.2,0.3,0.5,0.4,0.8,0.6c0.2-0.3,0.6-0.7,0.6-1c0-0.6-0.3-1.2-0.5-1.7c-0.4-1.1-0.8-2.1-1.2-3.1
	c-0.5-1.2-0.2-1.7,1.1-2c0.9-0.2,1.9-0.2,2.8-0.6c2.7-1.3,5.5-2.6,8.1-4.1c0.8-0.4,1.6-1.1,2.2-1.9c1.2-1.8,3-2.9,4.8-4.1
	c2.4-1.6,4.8-3.3,7.4-4.6c1.9-1,4-1.5,6.1-2.1c2.6-0.7,5.2-1.4,7.8-1.8c2-0.4,4-0.4,6-0.5c1,0,2.5,1.8,2.8,3c0.5,2,0,4-0.7,5.8
	c-0.2,0.7-1.1,1.2-1.7,1.7c-2.2,1.7-4.5,3.3-6.7,5.1c-2.8,2.2-5.5,4.5-8.2,6.8c-2.5,2.1-5,4.2-7.6,6.3c-0.2,0.2-0.4,0.5-0.7,0.5
	c-2.1,0-2.8,1.9-4,3c-1.9,1.7-3.5,3.7-5.2,5.6c-1.1,1.3-2,2.8-3.1,4.1c-2.4,2.9-5,5.6-7.4,8.5c-1.4,1.7-2.4,3.7-3.7,5.6
	C258.1,84.6,257.8,85.1,257.2,85.7z M234.2,46.5c0.2,0.2,0.4,0.3,0.6,0.5c0.2-0.5,0.4-1,0.7-1.4c0.9-1.2,2.3-1.4,3.1-0.7
	c0.6,0.6,0.4,1.9-0.5,2.9c-1.1,1.3-2.3,2.5-3.4,3.9c-0.2,0.3-0.3,0.9-0.2,1.3c0.3,1.1,1.1,1.1,2.1,0.8c1.3-0.4,2.6-1.1,4.1-0.7
	c1.3,0.4,1.8,0.1,2.5-1.5c-0.8-0.2-1.6-0.6-2.5-0.7c-0.9-0.1-1.8,0-2.9,0c0.8-0.9,1.5-1.7,2.1-2.4c1.8-2,1.6-3.7-0.7-5.1
	c-0.3-0.2-0.7-0.5-1-0.6c-0.6-0.1-1.1-0.2-1.7-0.1c-0.9,0.1-1.8,0.4-2.8,0.5c-0.9,0.1-1.2,0.5-0.8,1.3
	C233.3,45.2,233.8,45.8,234.2,46.5z M244,45.5c0.2,0,0.3,0,0.5,0c0.2-0.2,0.3-0.5,0.5-0.5c0.4-0.1,0.8-0.1,1.3-0.2
	c-0.1,0.4,0,0.9-0.2,1.2c-0.3,0.4-0.7,0.8-1.2,1c-0.7,0.2-1.4,0.5-1.1,1.4c0.3,0.6,1,0.9,1.8,0.7c0.6-0.2,1.3-0.1,2,0
	c1.1,0.2,1.4,0.9,1,1.9c-0.4,1-1.2,1.6-2.4,1.8c-0.3,0.1-0.6,0.3-0.9,0.4c0.2,0.3,0.3,0.8,0.6,0.9c0.4,0.3,0.9,0.5,1.3,0.4
	c2.3-0.4,4-2.8,3.6-5.1c-0.3-1.5-1.6-2-2.6-2.5c0-0.8,0.4-1.7,0.1-2.1c-0.6-0.8-1.5-1.4-2.3-1.8c-1-0.5-1.6,0.4-2.2,1
	C243.6,44.3,243.9,45,244,45.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }} d="M360,291.7c2.9,0.6,6,1.4,9.1,2c4.9,0.9,9.5,2.7,14.1,4.5c4,1.6,8,3.1,12.1,4.7c1.1,0.4,2.2,0.6,3.3,1
	c1.3,0.5,2.1,1.1,2.1,2.8c-0.1,2.6,0.3,5.2,0.2,7.7c-0.2,4.1-0.6,8.2-0.9,12.2c-0.2,2.4-0.3,4.8-0.5,7.1c-0.2,2.6-0.5,5.1-0.9,7.7
	c-0.3,2.4-0.6,4.8-1.2,7.1c-0.6,2.3-1.5,4.5-2.2,6.7c-0.4,1.2-0.9,2.4-1.3,3.4c-2.1-0.9-4-1.6-5.8-2.4c-1.7-0.7-3.5-1.3-5.2-2.2
	c-3.1-1.8-6.7-2-10.1-2.7c-2.1-0.4-4.1-1-6.2-1.4c-2.1-0.5-4.2-0.9-6.4-1.3c0.4-2.2,1-4.7,1.4-7.1c1-5.7,1.6-11.4,1.7-17.2
	c0-0.8-0.2-1.6-0.3-2.4c-0.1-0.5-0.2-1.2-0.1-1.6c0.8-1.9,0.1-3.8-0.1-5.7c-0.2-2.3-0.6-4.5-1-6.7c-0.4-2.2-0.8-4.4-1.3-6.6
	c-0.5-2.2-1.2-4.5-1.7-6.7c-0.1-0.4,0.2-0.8,0.4-1.2C359.4,292.7,359.6,292.4,360,291.7z M372.9,332.5c0.2-0.3,0.3-0.4,0.5-0.6
	c1.4-1.5,3-2.9,4.3-4.4c1-1.2,1-2.4,0.2-3.6c-1-1.4-2.3-1.9-4-1.7c-1.5,0.1-2.2,0.9-2.5,2.2c-0.2,0.8,0,1.4,0.7,1.7
	c0.3,0.1,1-0.5,1.2-0.9c0.5-0.8,1.3-1.3,2.1-0.9c0.9,0.5,1.1,1.3,0.5,2.3c-0.3,0.5-0.7,0.9-1.2,1.4c-1.4,1.4-2.9,2.8-4.2,4.2
	c-1.2,1.2-0.8,2.2,0.9,2.4c1.3,0.2,2.7,0.1,4,0.2c1.4,0,2.9,0.1,4.3-0.1c0.5,0,0.9-0.6,1.3-0.9c-0.5-0.3-0.9-0.8-1.4-0.8
	C377.5,332.7,375.3,332.6,372.9,332.5z M385.8,326.8c0.2-0.6,0.6-1.2,0.6-1.7c0-0.6,0-1.3-0.3-1.8c-0.9-1.3-3.1-1.6-4.4-0.7
	c-0.6,0.4-1.3,0.8-0.7,1.6c0.5,0.7,1.2,0.5,1.8,0.1c0.5-0.3,1-0.8,1.4-0.1c0.2,0.3-0.1,1.1-0.4,1.4c-0.5,0.6-1.2,0.9-1.7,1.4
	c-0.3,0.3-0.4,1-0.3,1.3c0.1,0.3,0.7,0.5,1.1,0.5c0.9,0.1,1.7,0,2.6,0.2c1.2,0.2,2,2.1,1.7,3.2c-0.5,1.5-1.8,1.5-3,1.7
	c-1.3,0.2-1.6,1-1,2.1c0.6,1,1.7,1.2,2.6,0.5c0.4-0.3,0.8-0.6,1.3-0.8c1.4-0.8,2.6-3,2.3-4.6C389,329,388,327.4,385.8,326.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1}
              fill={this.state.color25 ? this.state.color25 : 'transparent'} onClick={() => {
          this.setState({ color25: color, opacity25: opacity });
        }} d="M206.9,374.4c3.2,0.1,6.3-0.4,9.3,0.8c-0.2,2.4,0.8,4.3,2.8,5.5c1.3,0.8,2.9,1.4,4.4,1.9
	c1.1,0.3,1.3,0.8,1.2,1.9c-0.6,3.9-1.3,7.8-1.8,11.7c-0.3,2.1-0.3,4.3-0.7,6.4c-0.4,2.6-1,5.2-1.6,7.8c-0.2,0.7-0.6,1.3-0.9,1.9
	c-1.2,2.9-2.2,6-3.7,8.8c-1.6,3-3.7,5.8-5.6,8.7c-1.1,1.7-2.2,3.4-3.4,5c-0.8,1.1-1.8,2.2-2.9,2.9c-0.7,0.4-1.8,0.3-2.7,0.3
	c-4.4,0.2-8.8,0.4-13.1,0.5c-5.3,0.2-10.7,0.3-16,0.4c-1,0-2.1,0-3.1,0c-0.1,0-0.2-0.1-0.4-0.2c0.3-0.8,0.6-1.5,0.9-2.3
	c1.1-2.9,2.1-5.8,3.2-8.8c0.2-0.7,0.5-1.3,1.3-1.3c0.3,0,0.6-0.3,1.1-0.5c-2.3-2.8-0.2-5.3,0.2-7.9c0.1-0.8,0.5-1.6,0.6-2.4
	c0.8-6.1,1.6-12.2,2.4-18.4c0.1-0.8,0.5-0.9,1.1-0.9c3.7-0.2,7.5-0.4,11.2-0.6c3-0.1,5.8-0.8,8.5-1.9c2.1-0.8,3.8-2.4,5-4.3
	c1.4-2.3,2.7-4.7,3-7.4c0.2-1.7,0-3.5,0-5.2C207.1,376.1,207,375.3,206.9,374.4z M203,410.4c0.3,0.1,0.7,0.2,1,0.3
	c0-0.3,0-0.7-0.1-1c-1-1.2-2.3-1.6-3.9-1.4c-1.9,0.2-3.3,1.4-4.5,2.8c-0.8,0.9-0.6,2-0.2,3c0.4,1.1,1,2.1,2.4,1.9
	c1.2-0.1,2.4-0.4,3.6-0.6c0,0.5,0,1.1,0,1.7c0,1.8-1,2.8-2.8,2.7c-0.2,0-0.4-0.1-0.5-0.1c-0.4,0.2-0.8,0.4-1,0.7
	c-0.1,0.2,0.2,0.7,0.4,1c1,1,2.4,0.6,3.6,0.8c0.2,0,0.7-0.3,0.8-0.6c0.7-1.8,2-3.4,1.6-5.4c-0.3-1.3,0.1-2.5,0.6-3.7
	c0.3-0.7,0.4-1.2-0.6-1.4C203.3,411.2,203.1,410.7,203,410.4L203,410.4z M186.6,419.8c0.6,0.7,2.7,1.5,3.4,1.2
	c1.5-0.7,3.3-3.3,2.9-5.1c-0.2-1,0.1-2,0.1-3.1c0-1.2,1.1-2.7-0.8-3.4c-0.1-0.1-0.2-0.3-0.3-0.5c-0.9-1.8-2.6-2.4-4.5-1.5
	c-1.7,0.7-2.8,2-3.7,3.6c-0.6,1.1-0.6,2.2,0.2,3.3c0.9,1.2,2.4,1.7,3.7,1.2c0.9-0.3,1.8-0.8,2.8-1.3c0.1,0.9,0.3,1.8,0.3,2.6
	c0,1.2-1.1,2.3-2.4,2.2C187.6,418.8,187,418.9,186.6,419.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1}
              fill={this.state.color26 ? this.state.color26 : 'transparent'} onClick={() => {
          this.setState({ color26: color, opacity26: opacity });
        }} d="M394.8,228.4c-1.9,0.8-3.6,1.3-5.2,2.1c-2.3,1.1-4.6,2.1-6.8,3.4c-2.8,1.6-5.5,3.4-8.2,5.1
	c-0.1,0-0.1,0.1-0.2,0.1c-1.5,1.2-1.5,1.2-2.7-0.4c-2.5-3.5-5.6-6.5-8.9-9.1c-5.7-4.4-11.3-8.8-17.1-13.1c-3.6-2.6-7.5-4.9-11.2-7.3
	c-4.2-2.7-8.4-5.3-12.6-8c-0.2-0.1-0.3-0.8-0.1-1.1c1.9-4.2,3.8-8.3,5.8-12.5c2.2-4.8,4.5-9.6,6.8-14.4c0.3-0.6,0.6-1.3,0.9-2.1
	c2.5,2.2,4.9,4.2,7.1,6.4c2.9,2.8,6.1,5.1,9.7,6.9c2.7,1.4,5.6,2.5,8.5,3.6c0.8,0.3,1.8,0.5,2.7,0.6c1.6,0.2,2.3,1.3,2.6,2.7
	c0.6,2.8,1.2,5.5,1.9,8.2c0.2,0.9,0.8,1.8,1.2,2.7c0.6,1.4,1.2,2.7,1.8,4.1c0.2,0.4,0.3,0.8,0.6,1c2.7,1.9,4.2,4.9,6.4,7.2
	c3.1,3.4,6.4,6.7,10.2,9.4c1.9,1.3,3.9,2.4,5.9,3.6C394,227.9,394.3,228,394.8,228.4z M360,203.3c-0.5-0.3-0.8-0.5-1.1-0.6
	c-1.1-0.1-1.1-0.6-0.8-1.4c0.4-1.3,0.7-2.6,1-3.9c0.3-1-0.1-1.5-1.1-1.5c-2.5,0.2-4.9,0.5-7.4,0.8c-0.2,0-0.6,0.6-0.5,0.9
	c0.2,1.2,0.5,2.3,0.9,3.4c0.2,0.4,0.8,1,1.1,1c0.7-0.1,0.5-0.7,0.3-1.3c-0.2-0.7-0.1-2,0.2-2.2c1.4-0.5,2.9-0.7,4.8-1.1
	c-0.6,2-1,3.6-1.6,5.3c-0.1,0.2-0.7,0.2-1,0.3c-0.4,0.1-0.9,0.2-1.3,0.3c-0.1,0.1-0.2,0.3-0.2,0.4c2.6,0.2,1.9,1.9,1.7,3.2
	c-0.2,1,0.2,1.3,1.2,1.6c0.2-0.6,0.4-1.2,0.5-1.8c0.1-1,0.2-1.9,1.4-2.3C358.6,204.2,359.1,203.8,360,203.3z M342.4,204.5
	c0.1,0.2,0.1,0.3,0.2,0.5c0.5-0.1,0.9-0.1,1.4-0.2c1.4-0.3,2.6,0,3.7,0.8c0.3,0.2,0.4,0.8,0.4,1.2c0,0.1-0.6,0.3-0.9,0.3
	c-0.6,0-1.3-0.1-1.9,0c-0.4,0.1-0.9,0.3-1.3,0.5c-0.1,0,0,0.6,0.2,0.7c0.4,0.2,0.9,0.5,1.4,0.5c0.7,0,1.4-0.2,2.1-0.2
	c1.3,0,2.3-0.6,2.5-1.6c0.2-1.5-0.7-3-2.1-3.5c-0.7-0.2-1.4-0.5-1.9-0.6c0.3-1.2,0.7-2.3,0.9-3.3c0.1-0.3-0.3-0.8-0.6-1
	c-1.6-0.9-4.5-0.1-5.7,1.9c0.9-0.1,1.5-0.2,2.1-0.3c0.7-0.1,1.4-0.3,2.1-0.3c0.6,0,0.8,0.4,0.4,1c-0.6,0.8-1.1,1.5-1.7,2.3
	C343.2,203.6,342.8,204,342.4,204.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M438.3,378.8c-1-1.5-2.4-1.3-3.8-0.8c-2.9,1-5.9,1.9-8.8,2.9c-0.8,0.3-1.6,0.6-2.4,0.9c-0.7,0.3-0.9,0-1-0.7
	c-0.6-5.3-2.5-9.9-6-13.9c-2.9-3.3-5.5-6.7-8.6-9.8c-1.9-2-4.1-3.6-6.9-4.2c-1.4-0.3-1.7-1-1.4-2.4c0.6-3.4,1.2-6.7,1.7-10.1
	c0.7-4.6,1.5-9.2,2-13.8c0.3-2.8,0.3-5.7,0.5-8.6c0.1-1.1,0.3-2.2,0.4-3.3c0.1-2.4,0.2-4.8,0.3-7.2c0-0.9,0.3-1.2,1.1-0.8
	c0.5,0.2,1,0.4,1.5,0.7c1.7,1.3,3.4,2.8,5.2,4c1.7,1.2,3.6,2.1,5.3,3.2c3.1,1.9,6.2,3.8,9.2,5.7c1.6,1,3.1,2.2,4.7,3.2
	c1.2,0.8,2.5,1.5,3.8,2.2c1.3,0.8,2,1.8,2.1,3.4c0.2,5.7,0.5,11.3,0.6,17c0,3.7-0.5,7.5-0.4,11.2c0,4.6-0.6,9.2,0.1,13.8
	C437.7,374,438,376.4,438.3,378.8z M421,342.4c0.1,0.1,0.1,0.3,0.2,0.4c-0.6,1.1-1.3,2.2-1.9,3.3c-0.8,1.5-1.6,2.9-2.4,4.4
	c-0.3,0.7-0.4,1.3,0.5,1.6c0.9,0.3,1.3-0.1,1.7-0.8c0.6-1.5,1.3-3,2.1-4.4c0.8-1.6,1.8-3.1,2.6-4.7c0.2-0.4,0.3-1.2,0.1-1.4
	c-0.4-0.4-1.1-0.5-1.7-0.6c-2.1-0.2-4.3,0.2-6.5-0.5c-0.4-0.2-1.6,0.4-1.6,0.6c0.2,1.8-0.4,3.9,1.4,5.2c0.3,0.2,0.8,0.3,1.2,0.4
	c0.1-0.4,0.2-0.9,0.1-1.3c-0.1-0.7-0.4-1.4-0.6-2.2C418,342.4,419.5,342.4,421,342.4z M427,346.3c0,0.7,0,1.2,0,1.7
	c-0.1,1.4-0.3,2.8-0.2,4.2c0,0.5,0.8,1,1.3,1.5c0.3-0.5,0.9-0.9,1-1.4c0.1-1.9,0-3.7,0.1-5.6c0.1-1.1,0.4-2.2,0.5-3.3
	c0.1-0.4,0-0.9-0.1-1.4c-0.5,0-1.3-0.1-1.6,0.2c-1.2,1-2.2,2.2-3.3,3.3C425.5,347.2,425.5,347.2,427,346.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity28 ? this.state.opacity28 : 1}
              fill={this.state.color28 ? this.state.color28 : 'transparent'} onClick={() => {
          this.setState({ color28: color, opacity28: opacity });
        }} d="M311.5,36.1c1.4-0.9,2.8-1.8,4.1-2.7c4.2-2.8,8.6-5.3,12.5-8.6c4.7-4,9.7-7.5,14.6-11.3c2-1.5,4-2.9,6-4.3
	c1.1-0.8,2.3-1.4,3.5-2c0.2-0.1,0.4-0.2,0.7-0.2c4.8-0.1,9.6-0.1,14.3-0.2c1,0,1.3,0.4,1,1.2c-0.4,1.2-0.9,2.5-1.5,3.6
	c-1.7,2.9-4.4,4.8-7.2,6.6c-1,0.6-1.8,1.4-2.6,2.3c-0.2,0.2-0.2,0.9,0,1.1c0.3,0.3,0.9,0.7,1.2,0.6c0.9-0.2,1.7-0.6,2.5-1.1
	c2.6-1.6,5.3-0.6,7.2,0.8c0.9,0.7,1.2,2.7,0.8,3.9c-0.6,1.7-1.7,3.1-3,4.3c-1.5,1.5-3.1,3-4.6,4.4c-0.7,0.7-0.4,1.2,0.3,1.8
	c0.9,0.8,1.8,1.7,2.6,2.6c2.1,2,2.3,4.8,3,7.4c0.2,0.7,0.4,1.5,0.6,2.3c-9.9,7.4-18.8,16-26.2,26.3c-0.1-1.1-0.3-2.1-0.3-3.1
	c0-4.6-0.9-9.1-2.9-13.2c-2.8-5.8-6.4-10.9-12.2-14.3c-0.4-0.2-0.8-0.4-1.3-0.6c-0.4-0.2-0.8-0.3-1.3-0.4c0.6-1.7,0.5-3.3-0.7-4.5
	c-1.1-1.1-2.5-1.9-4.3-1.8c-2,0.1-4-0.1-6.1-0.3c-0.3,0-0.5-0.2-0.8-0.3C311.6,36.4,311.6,36.3,311.5,36.1z M337.8,33.8
	c0,0.8,0,1.5,0,2.2c-0.1,1.1-0.2,2.2-0.2,3.3c0,0.6,0.3,1.2,0.6,1.7c0.1,0.1,0.9,0,1.2-0.2c1.5-1.1,3.3-0.1,3.9,0.9
	c0.4,0.8,0.1,1.4-0.5,2c-0.6,0.6-0.9,0.1-1.4-0.2c-0.4-0.2-1,0-1.5,0c0.1,0.5,0.1,1.2,0.4,1.6c0.8,0.8,3,0.8,3.7,0.2
	c1.7-1.5,2-3,1.2-4.5c-0.9-1.7-2.5-2.3-4.3-2.4c-0.7,0-1.1-0.3-1-1c0.1-0.7-0.6-1.9,0.9-2c1.3-0.1,2.6-0.1,3.9-0.1
	c0.4,0,0.7-0.3,1.1-0.4c-0.3-0.5-0.5-1.3-0.8-1.3c-2.1,0-4.2-0.7-6.2,0.6C338.7,34,338.3,33.8,337.8,33.8z M356,34.3
	c-0.4-0.3-0.8-0.8-1.2-0.9c-2-0.1-4-0.1-6-0.2c-0.9,0-0.9,0.6-1,1.2c-0.1,1.2,0,2.4-0.1,3.5c0,0.8,0,1.6,1,2
	c1.1-1.1,2.3-0.7,3.4-0.2c1,0.4,1.4,1.7,0.9,2.6c-0.5,0.9-1.2,0.9-2.3,0.2c-0.2-0.1-0.3-0.3-0.5-0.3c-0.5,0-1.1,0.1-1.6,0.2
	c0.3,0.5,0.4,1.2,0.8,1.5c0.5,0.4,1.2,0.6,1.9,0.7c1.2,0.2,2.4,0.3,3.3-1.1c0.8-1.3,1.1-2.5,0.3-3.9c-0.9-1.5-2.3-2.1-3.9-2.1
	c-1,0-1.2-0.4-1-1.3c0.1-0.3,0.1-0.7,0.2-0.9C352.1,35.1,354.2,36.3,356,34.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity29 ? this.state.opacity29 : 1}
              fill={this.state.color29 ? this.state.color29 : 'transparent'} onClick={() => {
          this.setState({ color29: color, opacity29: opacity });
        }} d="M17.1,262.2c-0.3-16.9,0.2-33.7-0.9-50.8c1.7,0.1,3.3,0.2,5,0.2c1.5,0,3,0,4.5-0.2c3.9-0.7,7.1-2.9,10.2-5.2
	c3.5-2.6,5.6-6.2,8-9.6c0.9-1.2,1.8-2.5,2.6-3.7c0.2-0.3,0.5-0.5,0.5-0.8c0.4-2,2-2.8,3.5-3.9c2.3-1.7,5-2.1,7.6-2.6
	c2.9-0.6,5.3,0.9,7.2,3c3.4,3.9,3.5,8.3,1.7,12.9c-0.6,1.4-1.5,2.5-2.5,3.6c-2.4,2.7-4.7,5.6-7,8.3c-3.1,3.7-6.4,7.4-9.3,11.2
	c-1.5,1.9-2.6,4.2-3.8,6.3c-2,3.3-3.2,7-4,10.7c-0.2,1.1-0.6,2.2-0.6,3.4c-0.1,1.3-0.7,2-1.9,2.5c-2.2,1.1-4.2,2.4-6.2,3.7
	c-2.5,1.6-5.1,3.2-7.6,4.9c-0.9,0.6-1.6,1.4-2.4,2.1C20.1,259.6,18.7,260.8,17.1,262.2z M28.2,226.5c0.6,2.1,0.1,2.8-2,2.9
	c-0.6,0-1.2,0.6-1.8,0.9c0.4,0.6,0.7,1.4,1.3,1.7c0.5,0.2,1.3-0.1,1.9-0.3c0.5-0.2,1-0.7,1.5-0.9c1.2-0.6,1.8-1.4,1.7-2.7
	c-0.2-1.7-0.2-3.4-0.5-5.1c-0.3-1.9-1.3-2.8-2.8-2.9c-1.5-0.1-4,1.4-4.5,2.8c-0.3,0.9-0.7,1.9-0.6,2.7c0.3,1.8,2.3,2.7,4,2
	C27,227.3,27.5,226.9,28.2,226.5z M37,230.7c0.2-0.4,0.7-0.9,0.7-1.4c0-1.2-0.2-2.4-0.2-3.6c0-1.5,0.1-2.9,0-4.4
	c-0.1-0.6-0.6-1.2-1.1-1.5c-0.3-0.2-1.1,0-1.3,0.3c-0.9,1.3-1.7,2.7-2.5,4c1.4,1.8,2.1,0.8,2.8-0.6c0.1,1.4,0.2,2.6,0.1,3.8
	C35.3,228.6,35,229.9,37,230.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity30 ? this.state.opacity30 : 1}
              fill={this.state.color30 ? this.state.color30 : 'transparent'} onClick={() => {
          this.setState({ color30: color, opacity30: opacity });
        }} d="M206.4,5.3c-0.6,1.7-0.6,3.3-1.2,4.8c-0.9,2.2-1.3,4.6-1.6,7c-0.4,3.5-0.5,7-0.5,10.5c0,1.6,0.4,3.2,0.7,4.8
	c0.6,3,1.3,6,1.9,9c0.1,0.3,0.1,0.7,0.2,1.2c-2.4-0.6-3.2,1.4-4.5,2.4c-1.2-1.1-2.2-2.3-3.5-3.1c-4-2.7-4.2-2.5-6.7,1.7
	c-1.1-0.6-2.2-1.1-3.2-1.8c-2-1.4-4.4-1.4-6.6-2.1c-2.7-0.8-7.5,2.1-8.8,4.2c-0.7,1.1-0.6,4.6,0.9,6.2c1.1,1.2,2.3,2.3,3.5,3.4
	c0.4,0.4,0.8,0.6,1.4,1.1c-7.7,2.4-13.7,6.9-19.4,12.3c-0.2-0.5-0.4-1-0.5-1.6c-0.9-6-1.7-12-2.6-18c-0.5-3-0.1-6,0.4-9
	c0.3-1.9,0.9-3.7,1.5-5.6c0.8-2.3,1.5-4.6,2.5-6.8c0.7-1.7,1.7-3.4,2.6-5c1.4-2.5,2.9-5,4.5-7.3c1.7-2.5,3.6-4.8,6.1-6.6
	c0.4-0.3,1-0.4,1.5-0.6c0.1-0.1,0.4,0,0.5-0.1c1.4-1.5,3.1-0.7,4.7-0.8c3.9-0.1,7.7-0.2,11.6-0.3c2.7-0.1,5.3-0.2,8-0.3
	C202,5.2,204.1,5.3,206.4,5.3z M178.7,26.1c0.1-0.2,0.4-0.6,0.7-1c0.9-1.5,0.4-4.3-1-4.9c-1.9-0.8-3.3-0.4-4.5,1.4
	c-0.2,0.3-0.4,0.5-0.7,0.6c-0.9,0.7-1.3,3.1-0.5,4c0.6,0.6,0.3,0.9,0,1.4c-0.5,0.6-0.9,1.3-1.2,2c-0.8,2,0.7,3.6,2.3,3.8
	c1.1,0.1,2.1,0,3.2-0.1c1-0.1,3.1-2.2,3.3-3.2c0.1-0.3,0-0.7-0.1-0.9C179.8,28.3,179.3,27.3,178.7,26.1z M185,26.2
	c0,0.7-0.1,1.1-0.1,1.4c-0.1,1.5-0.3,2.9-0.3,4.4c-0.1,1,0.5,1.6,1.4,1.6c1,0,0.7-0.9,0.7-1.5c0.1-2.7,0.3-5.4,0.4-8.1
	c0-0.4-0.1-1.2-0.3-1.2c-0.5-0.1-1.1,0-1.5,0.3c-1,0.8-2,1.7-2.9,2.7c-0.3,0.3-0.4,0.8-0.5,1.2c0.5,0.1,1.1,0.2,1.6,0.2
	C183.9,27.1,184.3,26.7,185,26.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity31 ? this.state.opacity31 : 1}
              fill={this.state.color31 ? this.state.color31 : 'transparent'} onClick={() => {
          this.setState({ color31: color, opacity31: opacity });
        }} d="M87.1,145.9c-2.1-2.4-5.2-2.7-8-3.4c-2.5-0.5-5-1.1-7.5-1.1c-3-0.1-5.9,0.3-8.9,0.6c-1.5,0.2-2.9,0.6-4.3,1
	c-0.8,0.2-1-0.3-1.1-0.9c-0.3-2-0.5-4-1-5.9c-0.6-2.5-1.4-4.9-2.4-7.2c-1.2-3-2.6-6-4.2-8.8c-1.6-3-3.5-6-5.4-8.9
	c-0.5-0.9-1.1-1.7-1.8-2.7c2.8-1.7,5.4-3.4,8.1-5c3.7-2.2,7.5-4.3,11.3-6.3c2.7-1.4,5.5-2.6,8.2-3.8c0.8-0.3,1.2,0,1.6,0.7
	c2.2,4.6,5.5,8.5,8.8,12.3c1.4,1.6,2.5,3.4,4,4.7c1.9,1.7,4.2,3.1,6.3,4.6c1,0.7,2.1,1.4,3.1,2.1c1.4,0.9,2.7,1.9,4.1,2.8
	c2.2,1.5,4.4,3,6.7,4.4c1.3,0.8,1.2,0.9,0.1,2.1c-1.2,1.3-2.5,2.5-3.7,3.8c-1.8,1.9-3.5,3.8-5.4,5.7c-1.8,1.8-3.7,3.5-5.5,5.3
	C89.3,143.2,88.3,144.6,87.1,145.9z M71.8,121.5c-0.2-1.4-1.1-1.7-2-1.7c-1.6,0-3,0.4-3.8,2c-0.6,1.1-1.2,2.3-1.8,3.4
	c-0.7,1.5-0.8,3.1-0.4,4.6c0.1,0.5,0.5,1.1,1,1.4c2.5,1.8,4.6,1.7,7-0.2c1.2-1,1.5-3.9,0.5-4.9c-1.3-1.2-3.5-1.5-5.2-0.7
	c-0.2,0.1-0.5,0.1-0.7,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c0.5-1,0.9-2.2,1.6-3.1C68.7,121,70.3,121.4,71.8,121.5z M81.4,125.2
	c0.1,0.8,0.2,1.6,0.3,2.3c0.2,1.8-1.2,3-3,2.6c-0.7-0.2-1.5-0.1-2.3,0c-0.3,0-0.6,0.3-0.7,0.6c0,0.2,0.3,0.6,0.5,0.7
	c0.8,0.2,1.7,0.5,2.5,0.5c0.7,0,1.6-0.2,2.2-0.5c0.7-0.4,1.4-1,1.8-1.7c0.4-0.6,0.7-1.4,0.7-2.1c0-1.8-0.1-3.6-0.2-5.5
	c0-1-0.9-1.9-1.9-1.8c-0.4,0-0.7,0.1-1.1,0.1c-2.3-0.1-4,0.9-5.2,2.8c-0.6,0.9-0.5,1.8,0.4,2.6c1,0.9,2.4,1.2,3.6,0.6
	C80,125.9,80.7,125.6,81.4,125.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1}
              fill={this.state.color32 ? this.state.color32 : 'transparent'} onClick={() => {
          this.setState({ color32: color, opacity32: opacity });
        }} d="M55.5,145.2c0.1,1.5,0.2,2.8,0.2,4.1c0,2.5-0.1,5,0.1,7.5c0.4,5-0.7,9.7-2.3,14.3c-1.7,4.9-3.9,9.6-5.9,14.3
	c-0.8,2-1.8,4-3.4,5.6c-0.3,0.3-0.3,1-0.5,1.4c-0.4,0.9-0.8,1.7-1.4,2.5c-2,2.6-3.7,5.5-6,7.7c-2.9,2.8-6.2,5.2-10.3,6.3
	c-0.1,0-0.2,0-0.3,0.1c-1.9,0.9-3.7,1.3-5.8,0.7c-1-0.3-2.1-0.4-3.1-0.6c-0.8-0.1-1.1-0.7-1.1-1.5c0-6.1,0-12.3-0.1-18.4
	c0-1.6-0.2-3.3-0.2-4.9c0-3.9,0.1-7.8,0.2-11.7c0-1.8-0.1-3.6-0.2-5.4c0-0.3,0.1-0.7,0.3-1c0.7-0.9,1.5-1.6,2.2-2.5
	c2.2-2.9,4.9-5.3,8.2-7c2.2-1.2,4.4-2.5,6.7-3.6c3.8-1.7,7.7-3.2,11.6-4.7c2.4-0.9,4.9-1.8,7.4-2.6
	C52.9,145.7,54.1,145.5,55.5,145.2z M28.8,176c0.3-0.3,0.4-0.5,0.7-0.7c1.6-1.3,1.7-2.9,0.4-4.9c-0.6-0.9-3.2-1.2-4.3-0.6
	c-1.7,0.9-2.9,4.1-1.5,5.9c0.5,0.7,0.4,1.1-0.1,1.7c-1.2,1.4-0.8,2.8-0.1,4.2c0.7,1.2,1.8,1.6,3.1,1.3c1.8-0.4,3-1.5,3.9-3.1
	C31.7,178.3,30.7,176.2,28.8,176z M41.7,175.5c-0.1-0.5-0.3-1.6-0.6-2.6c-0.2-0.5-0.7-1.1-1.1-1.1c-1.3,0-2.6,0.1-3.9,0.3
	c-0.3,0-0.5,0.2-0.7,0.4c-1.4,1.1-2.6,4.8-2.1,6.5c0.2,0.8,3.3,2.7,4.2,2.5C39.7,181.1,41.8,178.4,41.7,175.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity33 ? this.state.opacity33 : 1}
              fill={this.state.color33 ? this.state.color33 : 'transparent'} onClick={() => {
          this.setState({ color33: color, opacity33: opacity });
        }} d="M13.7,62.6c0-4.1,0-7.8,0-11.5c0-3-0.1-6.1-0.2-9.1c-0.1-3.4-0.3-6.9-0.4-10.3c-0.1-2.3-0.1-4.5,0-6.8
	c0.2-2.6,0.7-5.2,1.1-7.8c0.4-3,0.8-6.1,1.3-9.1c0-0.1,0.1-0.2,0.1-0.3c3.7,0,7.3,0,11,0c3,0,5.9-0.2,8.9-0.3
	c4.2-0.1,8.3-0.2,12.5-0.3c1.3,0,2.7,0,4,0c0.9,0,0.9,0.6,0.8,1.1c-0.2,2-0.4,4-0.7,6.1c-0.6,4.5-1,9-0.4,13.6
	c0.1,0.8,0.2,1.5,0.1,2.3c-0.3,4.3,0.4,8.5,1.5,12.6c0.2,0.8,0.1,1.3-0.7,1.8c-3.6,2-7.1,4.1-10.7,6c-2.2,1.2-4.3,2.4-6.6,3.3
	c-1.3,0.5-2.4,1.4-4,1.3c-0.9-0.1-1.9,1-2.9,1.5c-2.1,1-4.3,1.9-6.4,2.9c-1.2,0.6-2.4,1.3-3.6,1.8C16.8,61.8,15.4,62.1,13.7,62.6z
	 M21,40.5c-2.5,3.2-2.2,5,1.4,6.6c0.5,0.2,1.2,0.1,1.8,0c0.7-0.2,1.5-0.6,2.1-1.1c0.7-0.7,1.3-1.5,1.8-2.4c0.6-1.3-0.4-2.1-1-3
	c1.9-2.1,1.5-3.9,0.2-5.3c-1.1-1.1-3.6-1.9-5.3-0.6C20.4,35.8,19.6,37.9,21,40.5z M35.9,48.1c0.7-0.6,0.9-2.1,0.6-2.9
	c-0.2-0.5-0.2-1.2-0.1-1.8c0-0.2,0.9-0.2,1.3-0.3c0.5-0.1,0.9-0.2,1.4-0.3c-0.1-0.4-0.3-0.8-0.4-1.2c-0.1-0.2-0.2-0.4-0.4-0.5
	c-0.3,0.1-0.6,0.2-0.9,0.3c-1.4,0.5-1.6,0.2-1.4-1.2c0.1-1.1,0.2-2.3,0.1-3.5c0-0.5-0.4-1.1-0.9-1.4c-0.3-0.2-0.9,0.2-1.4,0.4
	c-0.2,0.1-0.4,0.4-0.6,0.6c-1.4,1.6-2.9,3.2-4.3,4.8c-0.4,0.4-0.5,0.8,0,1.2c0.9,0.9,2,1.3,3.4,1c1.8-0.4,2-0.2,2.1,1.5
	C34.4,46.1,34,47.5,35.9,48.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M403.8,303.4c-1.7-9.5-5-18.5-8.6-27.5c3.6-2.7,7.7-4.4,11.9-6c4.6-1.8,9.3-3.3,14.2-3.8
	c2.3-0.3,4.6-0.9,6.9-1.3c0.4-0.1,0.9,0.2,1.4,0.3c0.2,0,0.5,0.2,0.7,0.1c2.3-0.8,4.2,0.2,6.1,1.2c0.2,0.1,0.3,0.5,0.3,0.8
	c0,1.4-0.1,2.9-0.1,4.3c0,3.1,0.1,6.1,0,9.2c0,2.7-0.2,5.3-0.1,8c0.1,3,0.4,6,0.5,8.9c0.1,4,0,8,0,12.1c0,3,0.1,6,0.1,9.1
	c0,1.9,0,3.7,0,5.9c-1.5-1-2.8-1.8-4.1-2.6c-4-2.6-8-5.2-12-7.8c-2.3-1.5-4.8-2.7-7.1-4.2c-1.7-1.1-3.3-2.3-4.9-3.6
	c-1.2-0.9-2.3-1.9-3.5-2.8C405.1,303.5,404.5,303.5,403.8,303.4z M421,290.5c0.1,0.1,0.2,0.1,0.4,0.2c-0.2,2-0.4,4-0.6,6
	c-0.1,0.7-0.3,1.5-0.1,2.1c0.2,0.6,0.8,1.4,1.4,1.6c0.8,0.2,0.9-0.7,1-1.3c0.2-3,0.3-6.1,0.5-9.1c0.1-2.1,0.2-2.1-1.7-2.9
	c-1.9,1.6-3.7,3.2-5.6,4.8c-0.4,0.4-0.8,0.9-1.2,1.4c0.7,0.4,1.2,0.7,1.6,0.9C418.2,292.8,419.6,291.6,421,290.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity205 ? this.state.opacity205 : 1}
              fill={this.state.color205 ? this.state.color205 : 'transparent'} onClick={() => {
          this.setState({ color205: color, opacity205: opacity });
        }} d="M164.5,328.1c7.6-0.1,15,1.3,22.5,0.6c0,0,0.1,0.1,0.1,0.2c-0.9,2.1-1.7,4.2-2.6,6.2c-1.6,3.4-3.2,6.8-4.4,10.4
	c-0.9,2.5-2.2,4.9-3.4,7.4c-0.5,1-1.1,1.9-1.5,2.9c-0.2,0.3-0.2,0.7-0.2,1.1c0,0.2,0,0.6,0,0.7c-1.4,0.6-0.8,2-1.2,3
	c-0.2,0.6,0.4,1.4,0.3,2.1c0,1.6-0.1,3.2-0.3,4.7c-0.2,1.2-0.5,2.4-0.9,3.6c-0.2,0.5-0.8,0.9-1.2,1.3c-1.8,1.6-4,2.3-6.2,2.8
	c-2.3,0.6-4.6,1-7,1.3c-1.8,0.2-3.7,0.1-5.5,0.1c-2.3,0-4.5,0.1-6.8,0c-2.2-0.1-4.3-0.4-6.5-0.5c-1-0.1-2-0.4-2.9-0.2
	c-1.9,0.3-3.6-0.2-5.4-1c-2.3-1-2.8-3.1-4-4.9c-0.5-0.7-0.3-1.9-0.2-2.9c0.1-1.4,0.6-2.7,0.7-4.1c0.3-1.8,1.2-3.3,2.2-4.7
	c1.1-1.4,2.3-2.8,4.3-3.2c1.9-0.4,3.7,0,5.5,0.7c0.8,0.4,1.9,0.3,3.1,0.4c-1.4-2.5-3.5-2.9-5.6-3.2c-0.7-0.1-1.5-0.2-2.3-0.1
	c-3.9,0.3-7.4,3.2-8.5,6.9c-0.5,1.7-0.9,3.4-1.3,5.1c-0.1,0.3,0.1,0.7,0.1,1c0.3,2-1,3.2-2.9,2.7c-0.9-0.2-1.8-0.6-2.7-0.9
	c-1.9-0.7-2.1-2.5-2.6-4.2c0-0.1,0-0.2,0-0.4c-0.8-2.6,0.2-4.7,1.9-6.5c0.7-0.8,1.4-1.6,2.2-2.4c2.3-2.4,4.9-4.1,8.3-4.6
	c0.5-0.1,0.9-0.4,1.4-0.5c1.2-0.3,2.4-0.5,3.6-0.7c0.9-0.2,1.8-0.4,2.7-0.4c1.6,0,3.2,0.3,4.9,0.2c2.4-0.1,4.4,1,6.6,1.5
	c1.8,0.4,3.4,1.4,5.2,2c0.7,0.3,1.5,0.4,2.2,0.5c0.2,0,0.6-0.2,0.7-0.4c1.2-2.8,2.4-5.5,3.4-8.3c1.1-2.9,2-5.9,2.9-9
	C163.6,332.5,164,330.3,164.5,328.1z M150.5,363.2c-0.3-1.2-0.5-2.4-0.9-3.6c-0.5-1.2-1.5-1.2-2.3-0.2c-1,1.1-2,2.3-3.1,3.3
	c-1,1-2.1,1.8-3.2,2.7c-0.7,0.5-0.5,1.2,0.1,1.4c1.2,0.5,2.5,0.8,3.7,1c0.8,0.1,1.7,0,2.5,0c0.7,0,1.1,0.2,0.9,1
	c-0.2,0.7-0.3,1.3-0.2,2c0.1,0.7,0.1,1.6,1.2,1.5c0.9-0.1,1.3-0.7,1.4-1.6c0.1-0.9,0.3-1.8,0.4-2.6c0-0.2,0.1-0.5,0-0.7
	C149.9,366.2,150.1,364.7,150.5,363.2z M159.8,363.3c0-0.6,0-1.2,0-1.9c-0.1-1.2-1.2-1.7-2.2-1.1c-2.8,1.7-4,4.7-5.9,7.3
	c-0.4,0.6,0.6,1.6,1.4,1.4c1-0.2,2-0.3,3.1-0.4c1.2-0.1,1.6,0.2,1.5,1.4c0,0.5-0.1,0.9-0.1,1.4c0,0.9,0.3,1.6,1.2,1.8
	c0.9,0.2,1.4-0.4,1.4-1.3c0-0.5-0.1-0.9,0-1.4c0.1-0.5,0.3-1,0.6-1.3c0.7-0.6,0.8-1.4,0.2-1.8C159.6,366.3,159.7,364.8,159.8,363.3z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1}
              fill={this.state.color36 ? this.state.color36 : 'transparent'} onClick={() => {
          this.setState({ color36: color, opacity36: opacity });
        }} d="M131.7,233.1c-0.2-2-0.4-4-0.5-5.9c-0.1-1.3,0.9-2.5,2.1-3c1.9-0.7,3.7-1.6,5.6-2.2c2.6-0.8,5.3-1.5,7.9-2.3
	c0.8-0.2,1.5-0.6,2.3-0.7c3.1-0.4,6.3-0.4,9.3-1.2c3.1-0.8,6,0.2,9-0.1c0.4,0,0.8,0.4,1.2,0.6c0.7,0.4,1.4,1.1,2.2,1.3
	c1.9,0.6,2.4,1.5,1.4,3.2c-1.1,2.1-2.5,4.1-3.4,6.3c-0.8,2-1.3,4.2-1.8,6.3c-0.2,1.1-0.1,2.3-0.3,3.4c-0.1,0.5-0.4,0.9-0.5,1.4
	c-0.1,0.4-0.2,0.9,0,1.1c0.3,0.3,0.8,0.4,1.2,0.4c0.4,0,0.9-0.3,1.3-0.4c1.8-0.3,2.3,0.1,1.4,1.7c-1.2,2.4-1.4,4.9-1.6,7.5
	c-0.1,1.7-0.5,3.3-1.1,4.9c-1.1,2.9-2.4,5.6-3.5,8.5c-1.4,3.7-2.7,7.5-4.1,11.3c0,0.1,0,0.2-0.1,0.2c-0.6,2.2-1,2.3-3.1,1.3
	c-3.9-1.8-7-4.7-10-7.7c-1.9-2-3.7-4.1-5.5-6.2c-0.7-0.8-1.3-1.7-1.7-2.7c-2-4-4.1-7.9-5.7-12c-1-2.5-1.2-5.2-1.6-7.9
	c-0.3-2.4-0.4-4.8-0.6-7.1C131.6,233.1,131.6,233.1,131.7,233.1z M153.6,243.7c0.1,1.1-0.2,1.7-1.3,2.6c-0.9,0.8-1.5,0.4-2.3-0.1
	c-0.4-0.2-1.2-0.1-1.4,0.2c-0.2,0.3-0.2,1.3,0.1,1.4c0.8,0.5,1.7,1.1,2.6,1.1c1.4,0.1,3.9-2.2,4.3-3.6c0.3-1.2,0.5-2.5,0.6-3.7
	c0-0.5-0.2-1.2-0.5-1.4c-0.3-0.2-1,0.1-1.5,0.3c-1,0.4-1.9,1-3,1.2c-1,0.2-1.6-0.5-1.4-1.4c0.4-1.8,2.8-2.6,4.3-1.5
	c0.4,0.3,1.2,0.1,1.8,0.2c-0.2-0.6-0.1-1.3-0.5-1.6c-1.1-0.9-2.5-1.1-3.9-0.9c-1.4,0.3-2.3,1.1-3.2,2.1c-1.4,1.5-0.9,4.5,1.1,4.9
	C150.7,244,152.1,243.7,153.6,243.7z M141.3,238.7c1.1-0.6,2.1-1.2,3.4-2c-0.3,0.8-0.4,1.3-0.6,1.8c-0.6,1.8-1.2,3.7-1.9,5.5
	c-0.3,0.8,0,1.6,0.7,1.7c0.4,0.1,1.2-0.5,1.3-1c1.1-3,2-5.9,3-8.9c0.3-0.8,0.8-1.8-0.9-1.6c-0.2,0-0.4-0.3-0.5-0.4
	c-1,0.5-1.9,1-2.8,1.5c-0.9,0.5-1.8,1.1-2.7,1.7C140.7,237.6,140.9,238,141.3,238.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity37 ? this.state.opacity37 : 1}
              fill={this.state.color37 ? this.state.color37 : 'transparent'} onClick={() => {
          this.setState({ color37: color, opacity37: opacity });
        }} d="M345,435c0.6-0.3,1.2-0.7,1.8-1c2.3-1,4.7-1.7,6.9-2.8c3.1-1.6,6.1-3.4,9.2-5.1c1.8-1,3.6-2.2,5.5-3.2
	c3.7-1.9,7-4.2,10.4-6.5c2.4-1.7,4.6-3.5,6.8-5.5c3-2.9,6.2-5.6,9.1-8.6c1.6-1.7,2.6-4.1,4-6.1c0.6-0.9,1.2-2,2.1-2.6
	c3.1-2.3,4.9-5.3,5.9-8.8c0.6-2.2,1.1-4.4,1.2-6.7c0.1-4.7-1.3-8.8-4.5-12.3c-1.4-1.5-2.8-2.9-4.6-3.9c-0.9-0.5-1.7-1.3-2.5-1.9
	c0.4-1.1,0.8-2.2,1-3.2c0.1-0.4-0.1-0.9,0-1.4c0-0.4,0.1-0.9,0.3-1c0.3-0.2,0.8,0,1.2,0c0.1,0,0.3,0.1,0.4,0.3
	c1.3,2.2,3.6,3,5.5,4.5c5.4,4.3,10.2,9.2,13.6,15.3c1.2,2.1,1.8,4.5,2.6,6.8c0.1,0.4,0,1-0.2,1.4c-1.2,1.9-1.6,3.7-1.3,6.1
	c0.3,2.5-0.3,5.1-0.7,7.7c-0.4,2.2-1.2,4.3-2.4,6.3c-1.5,2.7-2.4,5.7-3.8,8.5c-1.2,2.5-2.6,4.8-4,7.2c-1.5,2.7-4,4.4-6.3,6.2
	c-3.3,2.5-6.7,4.8-10.1,6.9c-1.7,1-3.6,1.6-5.4,2.4c-0.3,0.1-0.7,0.2-1,0.4c-1.9,1.6-4.2,1.4-6.5,1.3c-3.1-0.1-6.1,0.3-9.2,0.3
	c-2.5,0-4.9-0.3-7.4-0.3c-3-0.1-6,0-9.1,0c-2.7,0-5.4,0-8.2,0C345,435.2,345,435.1,345,435z M397.5,416.6c-0.1,0-0.2,0-0.3,0
	c0-1.2-0.2-2.5,0-3.7c0.2-1.2-0.2-2.1-1.2-2.4c-0.9-0.3-1.7,0.2-2.5,1c-1.8,1.9-3.7,3.7-5.5,5.6c-0.3,0.3-0.5,0.7-0.7,1.1
	c0.4,0.2,0.9,0.6,1.3,0.6c1.8,0,3.6,0,5.4-0.1c1.1-0.1,1.3,0.3,1.2,1.3c-0.1,1.1,0,2.1,0,3.2c1.6,0.7,2,0.5,2.3-1.2
	c0.1-0.3-0.1-0.6,0-0.9c0.3-0.9-0.3-2,0.8-2.7c0.2-0.1,0.4-0.8,0.2-1.1C398.4,417.1,397.9,416.9,397.5,416.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity38 ? this.state.opacity38 : 1}
              fill={this.state.color38 ? this.state.color38 : 'transparent'} onClick={() => {
          this.setState({ color38: color, opacity38: opacity });
        }} d="M401.4,302.7c-3.6-1.4-7-2.7-10.3-4c-1.4-0.5-2.8-0.8-4.2-1.3c-2-0.8-3.9-1.7-5.9-2.4c-1.9-0.7-3.8-1.2-5.7-1.8
	c-0.9-0.3-1.8-0.7-2.8-0.9c-3.2-0.8-6.5-1.5-9.7-2.2c-1.4-0.3-2.8-0.3-4.5-0.5c-1.7-1.7-2.1-4.2-2.9-6.7c-0.9-2.8-2-5.5-3.2-8.2
	c-1.1-2.3-2.7-4.4-3.9-6.7c-0.3-0.6,0.2-1.8,0.7-2.4c1.6-2.1,3.3-4.2,5.2-6.1c3.2-3.3,6.4-6.5,9.7-9.7c1.3-1.3,2.8-2.4,4.2-3.5
	c1.4-1.1,3-2,4.6-3.1c1.5,2.1,3,4.1,4.5,6.1c2.2,3,4.4,6.1,6.5,9.1c2.8,3.9,5.4,8,7.2,12.5c2,5,4,10,5.8,15.1
	c1.4,3.9,2.6,7.9,3.8,11.8C401.1,299.3,401.2,300.9,401.4,302.7z M367.1,272.9c-0.1-0.1-0.2-0.2-0.3-0.3c0.5-1.1,0.9-2.3,1.6-3.3
	c0.3-0.4,1.1-0.5,1.6-0.8c0.3-0.2,0.7-0.8,0.7-1.1c-0.1-0.3-0.8-0.8-1-0.7c-0.9,0.3-2,0.7-2.7,1.3c-2.6,2.6-2.7,6.2-0.3,9.4
	c1.1,1.5,2.7,1.9,4.1,1.4c1.3-0.4,2.3-1.7,3.3-2.7c0.3-0.3,0.1-1.3-0.1-1.8c-0.9-2-3.1-2.9-5.2-2.1
	C368.2,272.4,367.6,272.6,367.1,272.9z M376,268.6c0.1,0.1,0.2,0.3,0.3,0.4c-0.4,0.6-0.8,1.2-1.2,1.8c-0.6,0.8-0.2,1.6,0.8,1.7
	c0.2,0,0.5,0,0.7-0.1c1.2-0.1,1.9,0.7,2.4,1.5c0.4,0.8,0.3,1.7-0.3,2.4c-0.8,0.8-1.4,0.4-2.2,0c-0.3-0.2-1.1,0-1.2,0.3
	c-0.2,0.4-0.1,1,0.2,1.4c0.8,1,2.4,1.3,3.6,0.7c2.7-1.3,3.3-4.7,1.1-6.8c-0.7-0.6-1.4-1.1-2.2-1.7c0-0.5,0.2-1.1,0.1-1.6
	c-0.2-0.6-0.7-1.4-1.2-1.7c-1-0.5-3.5,0.7-4.1,1.7c-0.1,0.3-0.1,0.6-0.1,1c0.3,0,0.7,0,1-0.1C374.4,269.4,375.2,269,376,268.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity39 ? this.state.opacity39 : 1}
              fill={this.state.color39 ? this.state.color39 : 'transparent'} onClick={() => {
          this.setState({ color39: color, opacity39: opacity });
        }} d="M256.6,383.8c-0.3-1.8-0.4-3.4-0.7-5c-0.1-0.4-0.6-0.7-0.9-1.1c-0.3,0.4-0.9,0.7-1,1.2c-0.1,0.9-0.1,1.8,0,2.7
	c0.4,2.5-1,5.1-3.1,6.3c-3.6,2.1-7.3,1.5-11,0.7c-1.2-0.2-2.4-0.7-3.5-1c-0.8-0.2-1.9-2.2-1.9-3.4c-0.1-4.2,1.8-7.5,4.7-10.4
	c0.7-0.7,1.6-1.2,2.3-1.9c0.3-0.3,0.4-0.9,0.6-1.3c-1-0.6-1.8-0.7-2.5,0.1c-1.2,1.3-2.4,2.6-3.6,4c-2.5,2.9-3.7,6.4-3.5,10.3
	c0,0.4,0,0.8,0,1.5c-1.4-1.1-2.9-1.7-3.8-3.1c-0.2-0.3-0.3-0.6-0.6-0.9c-2.1-2.4-2.3-7.9-0.6-10.7c2.3-3.7,5.5-6.1,9.5-7.8
	c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.1-0.1,0.2-0.2c-0.2-1-1-0.7-1.5-0.5c-1.9,0.8-3.8,1.5-5.5,2.6c-1.4,0.9-2.6,2.3-3.8,3.6
	c-0.2,0.2-0.3,0.4-0.4,0.6c-0.4,1.7-0.8,3.4-1.1,5.2c-0.3,1.5-0.4,3-0.6,4.4c-0.1,0.7-0.5,0.7-1,0.4c-0.3-0.1-0.6-0.4-0.9-0.3
	c-2.2,0.3-4.1-1.5-4.1-3.8c0-2.8,1.5-5,3-7c1.5-2.1,3.3-3.9,5-5.8c0.4-0.5,1-0.8,1.5-1.1c3-1.5,5.9-3,9.4-2.8
	c1.2,0.1,2.5-0.3,3.7-0.4c2-0.2,3.6,0.9,5.3,1.5c1.6,0.6,3.2,1.3,4.9,1.8c1.2,0.4,2.4,0.7,3.6,1c1.5,0.3,3,0.7,4.5,0.8
	c3.5,0.1,7-0.1,10.5-0.1c2,0,3.9-0.1,5.9-0.2c2-0.1,3.9,0.2,5.8,0.9c2.8,1.1,5.8,1.4,8.8,0.9c1.3-0.2,2.7-0.2,4,0.1
	c0.6,0.1,1.1,1,1.4,1.7c1,2.6,2.9,4.5,4.9,6.4c0.1,0.1,0.3,0.2,0.5,0.4c0.5,0.6,1.3,1.1,1.3,1.8c0.1,0.5-0.8,1-1.2,1.6
	c-0.9,1.3-1.9,2.6-2.6,4c-0.4,0.9-0.4,2.1-0.6,3.2c0,0.3-0.1,0.5-0.3,1c-2.8-0.2-5.7-0.4-8.5-0.6c-2.2-0.1-4.3-0.2-6.5-0.4
	c-1.3-0.1-2.6-0.4-3.9-0.5c-1.2-0.1-2.4-0.3-3.6-0.2c-3.9,0.2-7.9,0-11.8-0.5c-1.9-0.2-3.8,0.4-5.7,0.6
	C257.5,383.8,257,383.8,256.6,383.8z M280.2,378c0-0.1-0.1-0.2-0.1-0.3c0.6-0.5,1.1-1.1,1.8-1.5c1.2-0.7,2-1.8,2.6-3
	c1.1-2.1-0.4-5.6-3-5.5c-0.8,0-1.6,0.3-2.4,0.5c-1.1,0.2-1.7,0.8-2,1.8c-0.3,0.8,0.1,1.7,0.9,2c0.9,0.3,1-0.3,1.3-1
	c0.4-1.2,1.6-0.5,2.4-0.8c0.1-0.1,0.9,0.7,0.9,1.1c0,0.7-0.2,1.6-0.6,2.1c-0.8,0.9-1.8,1.5-2.7,2.4c-0.8,0.8-1.5,1.7-2.2,2.7
	c-0.2,0.4-0.2,1,0,1.4c0.1,0.3,0.7,0.3,1.1,0.4c2.1,0.2,4.3,0.3,6.4,0.6c1,0.2,1.8,0.4,2.7-0.6C285.4,377.6,282.6,378.3,280.2,378z
	 M269.1,377.4c1.3-1.4,2.4-2.5,3.5-3.8c1.2-1.4,2-3,1.2-4.9c-0.6-1.5-2.2-1.7-3.6-2c0,0-0.1,0-0.1,0c-1.9-0.2-3.9,1.2-3.9,3
	c0,0.5,0.7,1.1,1.1,1.6c0.3-0.3,0.6-0.6,0.9-0.9c0.3-0.3,0.5-0.9,0.8-1c0.7-0.2,1.6-0.4,2.3-0.1c0.8,0.3,0.5,1.2,0.2,1.8
	c-0.6,0.9-1.3,1.7-2,2.5c-1.2,1.4-2.4,2.9-3.5,4.3c-0.2,0.3-0.3,1-0.1,1.3c0.2,0.3,0.8,0.7,1.1,0.7c1.8-0.2,3.6-0.1,5.4,0.4
	c1,0.3,2.1-0.3,2.2-1.1c0.1-1.2-0.9-1.3-1.7-1.4C271.7,377.6,270.4,377.5,269.1,377.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity40 ? this.state.opacity40 : 1}
              fill={this.state.color40 ? this.state.color40 : 'transparent'} onClick={() => {
          this.setState({ color40: color, opacity40: opacity });
        }} d="M436.9,263.2c-1.6-0.3-2.8-0.6-4-0.6c-2,0-4,0-5.9,0.3c-3,0.4-5.9,0.8-8.8,1.4c-1.7,0.3-3.3,1-5,1.5
	c-0.2,0.1-0.4,0.1-0.6,0.2c-4.7,0.5-8.6,2.8-12.8,4.7c-1.7,0.8-3.3,1.7-5,2.5c-0.6,0.3-0.8,0-1-0.5c-1.4-3.1-2.7-6.2-4.3-9.2
	c-0.9-1.8-2.2-3.4-3.4-5c-2.6-3.6-5.1-7.3-7.7-10.9c-1-1.5-2.1-2.9-3.2-4.3c-0.6-0.8-0.6-1.2,0.3-1.8c2.5-1.6,5-3.3,7.5-4.8
	c3.5-2.1,7.2-3.9,11-5.3c1.2-0.4,2.4-1,3.8-0.6c0.1,0,0.3,0.1,0.4,0c2.3-1.2,4.1,0.2,6.1,0.9c1.7,0.6,3.3,1.4,5,2.1
	c3.3,1.3,6.6,2.5,9.8,4c2.2,1,4.1,2.7,6.3,3.7c1.9,0.8,2.7,2.5,4,3.8c1.7,1.8,3.3,3.7,5.5,5.1c1.6,1,2,2.6,2,4.4
	C437,257.5,436.9,260.2,436.9,263.2z M408.6,254.5c-0.1-0.1-0.1-0.3-0.2-0.4c1.1-0.8,2.1-1.6,3.1-2.5c1.8-1.5,1.7-3.9-0.2-5.2
	c-0.6-0.4-1.2-0.7-1.8-1c-2.5-1.2-5-0.4-5,2.9c0,0.6,0.6,1.6,1,1.7c0.8,0.2,0.8-0.7,0.9-1.3c0-0.1,0-0.2,0.1-0.4c0.3-1.1,1-1.5,2-1
	c0.4,0.2,0.7,0.4,1,0.6c1.3,0.8,1.3,1.3,0.1,2.3c-1.6,1.3-3.3,2.5-4.1,4.5c-0.2,0.6-0.4,1.6,0,2c0.3,0.4,1.3,0.3,1.9,0.3
	c0.8,0,1.5-0.2,2.3-0.2c1.1,0,2.2,0.3,3.3,0.3c0.5,0,1.1-0.1,1.6-0.4c0.7-0.4,0.6-1.3-0.1-1.6c-0.4-0.2-0.8-0.3-1.3-0.3
	C411.8,254.8,410.2,254.6,408.6,254.5z M400.7,245.5c-1.5,1.1-3.2,2.3-4.9,3.6c-0.3,0.2-0.4,0.8-0.5,1.3c0.4,0.1,0.9,0.2,1.3,0.2
	c0.3,0,0.5-0.3,0.8-0.5c0.9-0.6,1.9-1.3,2.8-1.9c0.1,0,0.3,0.1,0.4,0.1c0,1.3,0.1,2.6,0.1,3.9c-0.1,1.3-0.9,2.6,0.1,3.9
	c0.2,0.3,0.4,0.6,0.7,0.9c0.2-0.3,0.7-0.6,0.7-0.9c0.2-1.2,0.3-2.4,0.3-3.5c0-2.2,0-4.4,0-6.4C401.9,245.8,401.4,245.7,400.7,245.5z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity41 ? this.state.opacity41 : 1}
              fill={this.state.color41 ? this.state.color41 : 'transparent'} onClick={() => {
          this.setState({ color41: color, opacity41: opacity });
        }} d="M348.4,125.3c-1.2,1.1-2.3,2.3-3.6,3.3c-1.4,1-2.8,2.1-4.4,2.8c-1.6,0.6-3.5,0.8-5.2,1
	c-3.1,0.4-5.9-1.1-8.8-1.8c-2.1-0.5-4.2-1.1-6.3-1.5c-2.1-0.4-4.2-0.6-6.2-0.9c-0.5-0.1-1-0.1-1.5,0c-1.7,0.1-1.7,0.1-1.8-1.6
	c-0.2-2.1-0.2-4.3-0.6-6.4c-0.4-2.8-1-5.6-1.7-8.4c-0.8-3.1-1.7-6.1-2.6-9.1c-0.3-1-0.9-1.9-1.4-3c1.8-1,3.6-2,5.4-2.9
	c1.9-0.9,3.7-1.8,5.6-2.6c3.3-1.4,6.3-3.4,9.5-5c3.4-1.8,6.8-3.9,10.1-5.9c1.1-0.7,2-1.5,3-2.3c0.3-0.2,0.6-0.4,1.1-0.8
	c0.1,0.5,0.2,0.8,0.1,1.1c-0.2,2.1,0.7,4,1.2,6c1.1,4.4,3.9,7.6,7.1,10.6c2.3,2.1,4.7,3.9,7.5,5.3c0.3,0.1,0.6,0.3,0.8,0.5
	c0.1,0,0.1,0.1,0.2,0.5c-1.8,0.4-3.7,0.6-5.4,1.1c-0.9,0.2-1.8,0.8-2.4,1.1c0.1,1.2,0.2,2,0.3,2.8c0.2,2.1,0.3,4.3,0.6,6.4
	c0.2,1.1,0.6,2.2,0.9,3.3c0.1,0.2,0,0.6-0.1,0.8c-1.3,2.4-3.3,4-6,4.6c-2.9,0.7-5.7,1.4-8.7,1.2c-2.2-0.2-4.4-0.4-6-2.2
	c-0.3-0.3-1.2-0.2-1.8-0.2c0.1,0.5,0,1.1,0.3,1.3c0.7,0.7,1.4,1.6,2.3,1.9c1.8,0.6,3.8,1.1,5.7,1.2c1.7,0.1,3.5-0.1,5.1-0.4
	c2.3-0.5,4.5-1.3,6.7-2c0.2-0.1,0.4-0.1,0.5-0.1C348.1,125.1,348.3,125.2,348.4,125.3z M319.8,111.6c0.9,0.7,1.2,2.3,2.7,1.8
	c1.1-0.4,2.5-0.6,3.1-1.4c2.3-3,0.5-4.9-2.6-6.1c0.1-0.1,0.2-0.2,0.3-0.2c1.2-0.3,2.4-0.7,3.6-1c0-0.2,0-0.3,0-0.5
	c-0.8-0.3-1.6-1.1-2.3-0.9c-1.8,0.4-3.5,0.8-5.4,0.1c-0.1,1.4-0.1,2.6-0.2,3.7c0,1,0.6,1.5,1.4,1c1.5-1,2.7,0,3.9,0.5
	c0.4,0.2,0.9,1.1,0.8,1.6c-0.1,0.6-0.7,1.3-1.3,1.6c-0.5,0.3-1.3,0.7-1.9-0.2C321.4,110.7,320.6,111.1,319.8,111.6z M332.3,113.8
	c1.9,0,3.3-1,4.3-2.4c1.4-1.9,0.3-4.7-2-5.1c-0.7-0.1-1.3-0.5-1.9-0.9c-1.1-0.7-1.9-0.6-2.8,0.4c-1.2,1.4-0.9,3.1-1.3,4.7
	c-0.3,1.3,0.4,2.1,1.4,2.6C330.7,113.4,331.5,113.6,332.3,113.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity42 ? this.state.opacity42 : 1}
              fill={this.state.color42 ? this.state.color42 : 'transparent'} onClick={() => {
          this.setState({ color42: color, opacity42: opacity });
        }} d="M284.8,58.7c2.7-2.4,5-4.7,7.5-6.9c3.1-2.7,6.2-5.2,9.3-7.8c1.6-1.3,3.3-2.7,4.9-4c0.1-0.1,0.1-0.1,0.2-0.1
	c1.1-0.2,2.2-0.2,2.4-1.7c0-0.1,0.7-0.2,1-0.1c1.6,0.8,3.1,1.2,4.9,0.9c1.3-0.2,2.8-0.1,4.1,0.1c0.7,0.1,1.5,0.7,2,1.3
	c0.3,0.3,0,1.2-0.2,1.7c-0.3,0.7-0.7,1.3-1.1,1.9c-0.4,0.7,0.4,2,1.3,1.7c3.5-1.1,5.4,1.4,7.6,3.2c1.7,1.4,3.4,3.2,4.5,5.1
	c1.8,3.1,3.7,6.2,4.6,9.8c1.1,4.1,1,8.3,1.2,12.4c0.1,1.7-1.4,2.5-2.5,3.4c-1.6,1.3-3.2,2.6-4.9,3.7c-2.6,1.6-5.2,3-7.9,4.5
	c-0.9,0.5-1.8,0.9-2.6,1.5c-1,0.7-1.3,0.5-1.8-0.5c-0.3-0.8-1-1.4-1.6-2.1c-0.6-0.7-1.4-1.4-2-2.2c-1.4-1.6-2.8-3.2-4.2-4.8
	c-2.6-2.9-5.1-5.7-8.3-8c-1.5-1.1-3.1-2.1-4.5-3.3c-2.1-1.7-4-3.5-6.1-5.1c-1.8-1.4-3.9-2.5-5.9-3.7
	C286.1,59.4,285.6,59.1,284.8,58.7z M317.8,58.6c1.9-0.1,3.7-0.2,5.5-0.3c0.1,0.1,0.2,0.3,0.2,0.4c-0.8,1.4-1.5,3-2.6,4.2
	c-0.6,0.6-1.9,0.4-2.9,0.6c0,0-0.1,0-0.1,0c-0.2,0.1-0.6,0.2-0.7,0.4c-0.1,0.2,0.1,0.5,0.3,0.7c0.6,0.4,1.2,0.8,1.9,1.1
	c-0.3,0.7-0.7,1.5-1,2.2c-0.3,0.7,0.2,1.1,1,1.1c1-0.1,1.3-1,1.5-1.7c0.5-1.6,1.6-2.4,3.1-2.8c0.2-0.1,0.4-0.1,0.6-0.2
	c0.3-0.3,0.5-0.6,0.7-0.9c-0.3-0.1-0.7-0.3-1-0.4c-0.2-0.1-0.5,0-0.9,0c1-2.1,2.5-3.8,2.7-6.4c-1.7,0-3.3-0.1-4.8-0.1
	c-1.7-0.1-3.5-0.1-5.2-0.1c-0.4,0-1,0.2-1.2,0.5c-0.1,0.2,0.2,0.8,0.3,1.1c0.2,0.4,0.4,0.9,0.6,1.3c0.2,0.6,0.3,1.3,0.6,1.8
	c0.2,0.3,0.9,0.6,1.2,0.5c0.3-0.1,0.5-0.7,0.6-1.1C318,59.9,317.8,59.3,317.8,58.6z M309.5,65.4c-0.5,0.2-1,0.3-1.5,0.6
	c-0.1,0.1-0.1,0.9,0.1,1c0.5,0.4,1.1,0.6,1.8,0.8c0.3,0.1,0.7-0.2,1-0.2c2.3,0.1,3.4-2.7,2.5-4.7c-0.2-0.5-0.4-1.1-0.5-1.7
	c-0.3-2.3-0.7-2.8-3-3.2c-1.7-0.3-3.5,0.6-4.3,2.1c-0.6,1.1-0.7,2.2,0.1,3.2c0.7,0.8,1.8,0.8,2.8,0.5c0.4-0.1,0.8-0.3,1.2-0.6
	c1.1-0.7,1.6-0.5,1.8,0.8C311.8,65.1,311.2,65.6,309.5,65.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity43 ? this.state.opacity43 : 1}
              fill={this.state.color43 ? this.state.color43 : 'transparent'} onClick={() => {
          this.setState({ color43: color, opacity43: opacity });
        }} d="M125.5,315.7c-0.3,0.3-0.6,0.6-0.7,0.9c-0.5,1.3-1.2,1-2.2,0.6c-4.9-2.1-9.9-4.1-14.8-6.1
	c-2.9-1.2-5-3.4-7.1-5.6c-3.6-3.8-7.2-7.8-10.8-11.6c-2.7-2.8-4.8-6-6.6-9.4c-0.1-0.2-0.1-0.4-0.1-0.6c3.3-0.3,6.5-0.7,9.7-1
	c0.2,0,0.3-0.1,0.5-0.1c2.8-0.7,5.5-1.4,8.3-2.2c3-0.9,5.9-1.9,8.8-3c1.1-0.4,1.6-0.2,2.4,0.7c1.6,1.9,3.4,3.5,5.3,5.1
	c1.7,1.5,3.6,2.9,5.4,4.3c0.4,0.3,0.9,0.6,1.4,0.9c3.9,2.6,8.3,4,12.7,5.4c3,1,5.7,2.3,8.5,3.7c1.3,0.7,2.9,0.9,4.5,1.4
	c-0.3,0.9-0.5,2.1-1,3.2c-1.2,2.9-2.5,5.8-3.6,8.8c-0.9,2.3-1.7,4.6-2.5,6.8c-0.3,0.9-0.7,1.7-1.1,2.5c-0.6,1.1-1.6,0.9-2.5,0.7
	c-2.1-0.6-4.2-1-6.4-1.1c-1.8-0.1-3.6-0.7-5.5-1.1c-0.4-0.1-0.8-0.1-1.2-0.1c-1,0-1.5-0.6-1.2-1.5c0.1-0.4,0.2-0.9,0.2-1.3
	C125.8,315.8,125.7,315.7,125.5,315.7z M112.2,307.1c1.6,0,2.2-0.3,4.2-1.5c1.5-0.9,1.9-2.9,0.8-4.3c-1.4-1.8-3.1-2.4-6-1.5
	c-0.3,0.1-0.8,0.1-0.9,0c-0.2-0.2-0.2-0.7-0.1-1c0.6-1.6,1.5-3,3.3-3.3c1-0.2,2.1-0.1,3.2-0.2c0.3,0,0.8-0.4,0.8-0.6
	c0.1-0.9-0.5-1.4-1.4-1.3c-0.4,0-0.7,0-1.1-0.1c-1.7-0.3-3,0.5-4.2,1.4c-3.2,2.6-3.7,6-2.4,9.8C109.1,306.2,110.5,307.1,112.2,307.1
	z M120.1,310.4c0.4-0.3,1-0.6,1.3-1c1.9-2.5,3.8-5.1,5.7-7.7c0.6-0.8,1.1-1.6,1.5-2.4c0.5-0.9,0.1-1.8-0.8-2c-2-0.5-3.9-1-5.9-1.2
	c-2.1-0.2-3.1,1.3-2,3.3c0.2,0.4,0.8,0.6,1.2,0.9c0.3-0.4,0.7-0.8,0.7-1.3c0.1-0.6,0.2-0.9,0.9-0.8c0.9,0.1,1.7,0.3,2.5,0.5
	c1.3,0.4,1.3,0.4,0.5,1.5c-1.8,2.4-3.6,4.9-5.6,7.2C119.2,308.3,118.9,309.1,120.1,310.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity44 ? this.state.opacity44 : 1}
              fill={this.state.color44 ? this.state.color44 : 'transparent'} onClick={() => {
          this.setState({ color44: color, opacity44: opacity });
        }} d="M309.1,80.2c-1.5,0.2-3,0.3-4.5,0.7c-1.2,0.3-2.4,1-3.5,1.6c-1.5,0.8-3,1.7-4.3,2.7c-1.1,0.8-2,1.9-3,2.9
	c-0.1,0.1-0.2,0.4-0.1,0.6c0.7,1.2,0.8,2.6,2.1,3.4c0.8,0.5,1.4,1.5,2.2,2.1c1.3,1,2,2.3,2.9,3.6c0.6,1,0.2,1.3-0.6,1.7
	c-1.9,0.8-3.7,1.7-5.6,2.6c-2.8,1.3-5.6,2.7-8.4,3.9c-3.5,1.5-7,2.9-10.5,4.4c-3.2,1.4-6.4,3-9.5,4.5c-0.8,0.4-1.4-0.1-1.6-1.3
	c-0.5-2.1-0.9-4.3-1.5-6.4c-1.4-5.3-3-10.7-4.4-16c-0.1-0.2-0.2-0.4-0.1-0.6c0.9-2,0.7-4.3,2.1-6.2c2.1-2.8,4.1-5.8,6.3-8.6
	c1.4-1.7,3.1-3.1,4.7-4.6c1.7-1.7,3.3-3.5,5.1-5.2c2-1.9,4.2-3.8,6.3-5.7c0.2-0.2,0.7-0.1,1,0.1c4.8,2.3,8.9,5.5,12.8,9.1
	c1.6,1.5,3.6,2.4,5.3,3.8c2,1.6,3.8,3.4,5.7,5.2C308.3,79.1,308.7,79.7,309.1,80.2z M280.3,81.8c-0.6,1-1.1,2.1-1.9,3
	c-1.5,1.9-3.1,3.6-4.6,5.5c-0.2,0.3-0.4,0.9-0.3,1c0.3,0.3,0.8,0.5,1.2,0.5c1.1,0,2.1-0.2,3.2-0.3c1.2-0.1,2.4-0.2,3.6-0.3
	c0.1,0.9,0.1,1.7,0.4,2.3c0.2,0.4,0.8,0.7,1.3,0.9c0.1,0,0.6-0.6,0.7-0.9c0.1-0.3,0-0.7,0-1.1c-0.2-1-0.3-2,0.9-2.5
	c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.2c-1.2-0.4-1.3-1.4-1.4-2.5C282.9,84.9,282.5,83,280.3,81.8z M288.6,83.5
	c0.1,0.1,0.2,0.3,0.2,0.4c-0.9,1-1.8,2-2.5,3.1c-0.2,0.3-0.3,1.1-0.1,1.3c0.3,0.3,1,0.4,1.5,0.5c0.5,0.1,1.3-0.1,1.4,0.2
	c0.4,0.9,1,1.9,0.9,2.8c-0.1,1-1.1,1.5-2.2,1.2c-0.3-0.1-0.6,0-0.8,0c-0.4,0.1-0.7,0.3-1.1,0.4c0.1,0.3,0.2,0.7,0.5,1
	c1,1.2,2.3,0.6,3.4,0.4c1.2-0.2,2.2-1,2.6-2.2c0.5-1.5-0.3-4.2-1.6-5.1c-1-0.7-1.1-1.2-0.4-2.2c0.4-0.6,0.8-1.7,0.5-2.3
	c-0.7-1.7-2.1-2.1-3.9-1.6c-0.6,0.2-1.3,0.3-1,1.2c0.2,0.7,0.6,1.3,1.6,1C287.8,83.5,288.2,83.6,288.6,83.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity45 ? this.state.opacity45 : 1}
              fill={this.state.color45 ? this.state.color45 : 'transparent'} onClick={() => {
          this.setState({ color45: color, opacity45: opacity });
        }} d="M368.2,51.2c0.2,2.6,0.2,5,0.5,7.4c0.5,3.7,1.1,7.4,1.7,11.1c0.3,2,0.8,4.1,1.2,6.1c0.6,3.4,1.9,6.5,3.6,9.5
	c2,3.5,4.6,6.3,8.2,8.1c2.5,1.3,5,2.6,7.5,3.8c3,1.4,6.2,1.7,9.4,1.5c2.1-0.1,2.7,0.2,3.3,2.2c0.4,1.3,0.6,2.8,0.7,4.2
	c0.1,0.7-0.2,1.1-1,1.1c-3,0.1-6.1,0.2-9.1,0.3c-5.4,0-10.9,0-16.3,0c-3.6,0-7.1-0.7-10.5-1.4c-2-0.4-4.1-0.7-6.1-1.1
	c-0.7-0.1-1.2-0.6-1.8-1c-0.3-0.2-0.5-0.5-0.8-0.5c-4.3-1.1-7.4-3.8-10.6-6.7c-3.1-2.9-5.3-6.2-6.2-10.3c-0.4-1.9-0.6-3.9-0.6-5.9
	c0-0.6,0.5-1.3,0.9-1.9c1.3-1.8,2.5-3.7,3.9-5.4c2-2.4,4.1-4.8,6.2-7.1c2.2-2.4,4.5-4.7,6.8-6.8c2.6-2.3,5.3-4.4,8-6.6
	C367.4,51.5,367.6,51.5,368.2,51.2z M354.3,88.3c0.8-1.3,1.8-2.5,2.3-3.8c0.7-1.8,0.4-4-1.7-4.3c-2.1-0.3-4.3,1.4-4.6,4
	c0,0.4,0.6,1.1,1,1.2c0.6,0.1,1.1-0.4,0.9-1.2c-0.2-1.2,0.5-1.9,1.5-2.2c0.3-0.1,1.1,0.3,1.1,0.4c0,0.8,0,1.8-0.4,2.5
	c-0.8,1.2-1.9,2.2-2.8,3.3c-0.3,0.4-0.5,1-0.8,1.5c0.4,0.2,0.9,0.5,1.3,0.5c1.9,0,3.7-0.1,5.6-0.2c0.6,0,1.2-0.3,2.2-0.6
	c-0.5-0.4-0.7-0.7-1-0.7C357.4,88.5,355.8,88.4,354.3,88.3z M361.8,89.6c0.4,0.1,0.8,0.1,1.3,0.2c0.5,0.1,1,0.1,1.4,0.2
	c1.9,0.4,2.4-0.3,2.3-2.2c-0.1-1.7-0.4-3.3-0.6-5c-0.2-1.6-0.8-2.1-2.4-2c-1.9,0.2-3.3,1.2-4.5,2.5c-0.7,0.8-1.4,1.7-0.7,2.8
	c0.7,1,1.7,0.5,2.6,0.3c0.2,0,0.4-0.2,0.6-0.3c0.8-0.4,1.6-0.7,2.6-1.2c0.1,0.9,0.3,1.6,0.3,2.2c0.1,0.9,0,1.6-1.3,1.4
	C362.9,88.4,361.7,88.3,361.8,89.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity46 ? this.state.opacity46 : 1}
              fill={this.state.color46 ? this.state.color46 : 'transparent'} onClick={() => {
          this.setState({ color46: color, opacity46: opacity });
        }} d="M136.6,89.4c-0.8,1.1-1.5,1.9-2.1,2.8c-2.1,3.2-4.2,6.4-5.7,10c-1,2.3-1.6,4.7-1.9,7.2
	c-0.2,1.7-0.7,3.5-1.1,5.2c-0.1,0.2-0.3,0.5-0.5,0.5c-3.3,0.5-5.9,2.4-8.7,4c-2.8,1.6-5.4,3.4-8.1,5c-0.2,0.1-0.6,0.1-0.8,0
	c-2.9-1.8-5.9-3.6-8.8-5.4c-1.2-0.7-2.4-1.4-3.6-2.2c-2-1.4-3.8-2.9-5.9-4.1c-2.5-1.5-4.4-3.6-6-6c-1.3-1.9-3-3.5-4.4-5.4
	c-1.6-2.1-3.1-4.4-4.6-6.6c-0.2-0.2-0.2-0.6-0.4-0.9c3.4-1.5,6.9-2.7,10.5-3.2c1.1-0.2,2.2-0.4,3.2-0.8c4.8-2.1,9.8-2.7,14.9-3.3
	c3.8-0.4,7.5-1,11.3-1.4c2.4-0.2,4.8-0.2,7.1,0c2.1,0.2,4.3,0.6,6.3,1.2c2.6,0.7,5.2,1.7,7.8,2.7C135.6,88.8,136,89.1,136.6,89.4z
	 M112.2,100.8c-1.3-0.7-1.8-0.6-2.5,0.7c-0.4,0.8-0.7,1.6-1,2.4c-0.5,1.7,0.8,4.8,2.4,5.4c1.7,0.7,5.6-1,6.1-2.8
	c0.4-1.4,0.5-2.9,0.6-4.4c0-1.1-0.5-2.2-1.7-2.4C114.7,99.5,113.2,99.2,112.2,100.8z M100.5,100c1.2-0.2,2.3-0.3,3.5-0.4
	c0.6,0,1.1,0.1,1.7,0.2c-0.1,0.6-0.2,1.2-0.4,1.7c-0.8,1.9-1.7,3.7-2.4,5.6c-0.1,0.4,0.1,1.2,0.4,1.3c0.3,0.2,1-0.1,1.4-0.4
	c0.3-0.2,0.3-0.8,0.5-1.2c0.2-0.6,0.3-1.3,0.5-1.8c0.9-2.1,1.8-4.2,2.7-6.4c0.3-0.8-0.1-1.3-0.9-1.3c-1.5,0-3,0.3-4.5,0.3
	c-0.8,0-1.6,0-2.4-0.1c-1.4-0.3-2.5,0.3-2.3,1.5c0.2,1.4,0.7,2.8,2.2,3.4C101.7,101.6,100.6,100.8,100.5,100z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity47 ? this.state.opacity47 : 1}
              fill={this.state.color47 ? this.state.color47 : 'transparent'} onClick={() => {
          this.setState({ color47: color, opacity47: opacity });
        }} d="M79.7,281.7c-0.6,0.1-1.1,0.3-1.7,0.4c-1.8,0.2-3.6,0.2-5.4,0.4c-3.8,0.4-7.6,1.2-11.3,2.4
	c-2.6,0.8-5.3,1.3-7.9,2c-1.1,0.3-2.2,0.7-3.2,1.2c-0.6,0.3-0.9,0.2-1.2-0.3c-2.1-3.4-3.7-7.1-4.8-10.9c-0.9-3.4-1.9-6.8-2.5-10.2
	c-0.9-5.4-1.2-10.8-0.3-16.3c0-0.2,0-0.5,0.1-0.7c0.1-0.9,0.4-1.6,1.3-2c2-0.9,3.8-2,5.8-2.9c2.7-1.2,5.5-2.3,8.3-3.5
	c1.8-0.7,3.5-1.5,5.3-2.1c1.7-0.6,3.5-1,5.2-1.6c1.3-0.4,2.6-0.9,4-1.2c1.9-0.4,3.7-0.7,5.6-1c1.5-0.3,2.9-0.6,4.4-0.9
	c0.1,0.1,0.2,0.2,0.2,0.3c-0.7,1.3-1.3,2.8-2.2,4c-1.3,1.7-1.7,3.6-2.2,5.6c-0.6,2.3-0.5,4.7-0.7,7c-0.2,2.5-0.8,5-0.9,7.5
	c-0.1,2.9,0,5.9,0.5,8.8c0.6,3.7,1.7,7.4,2.6,11C78.9,279.7,79.3,280.6,79.7,281.7z M60.8,271.8c-0.1,0.1-0.1,0.2-0.2,0.3
	c0.3,0.3,0.5,0.9,0.9,1c0.9,0.3,1.8,0.4,2.8,0.5c2.3,0.2,3.7-1.4,3.2-3.6c-0.1-0.6-0.2-1.2-0.5-1.7c-0.5-0.9-0.4-1.7,0.1-2.6
	c1.1-2.3,0.8-3-1.5-4c-1.7-0.7-3.4-1.6-5.4-0.3c-2.1,1.4-2.5,4.4-0.7,6.1c1.3,1.2,2.9,1.3,4.5,0.9c0.8-0.2,1,0.1,1.2,0.7
	c0.1,0.3,0.2,0.7,0.2,1c0.3,1.3-0.1,1.7-1.5,1.6C62.8,271.7,61.8,271.8,60.8,271.8z M53.7,265.7c0.2-0.3,0.3-0.6,0.5-0.8
	c1.3-2,1-3.1-1.1-4.1c-1.4-0.6-4.5,0.2-5.3,1.5c-0.2,0.3,0,0.9,0.1,1.4c0.4-0.1,0.9-0.1,1.3-0.2c1-0.3,1.9-0.7,2.8-0.9
	c0.9-0.2,1.1,0.4,0.6,1.1c-0.7,0.9-1.6,1.7-2.4,2.5c-0.7,0.6-0.6,1.2,0.1,1.6c0.5,0.2,1,0.2,1.6,0.3c0.9,0.1,1.9,0.1,2.8,0.3
	c0.9,0.2,1.3,1,0.8,1.8c-0.5,0.7-1.3,1.3-2.1,1.7c-0.5,0.3-1.2,0.1-1.7,0.3c-0.5,0.2-0.8,0.7-1.3,1.1c0.5,0.3,1,0.8,1.6,0.7
	c0.8,0,1.5-0.4,2.3-0.5c1.5-0.2,2.5-1,3.1-2.3c0.8-1.4,0.5-3.3-0.6-3.9C55.8,266.6,54.8,266.3,53.7,265.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity48 ? this.state.opacity48 : 1}
              fill={this.state.color48 ? this.state.color48 : 'transparent'} onClick={() => {
          this.setState({ color48: color, opacity48: opacity });
        }} d="M65.7,397.9c1.3-1.8,2.6-3.5,3.8-5.1c0.8-1.1,1.7-2.1,2.5-3.2c0.2-0.3,0.5-0.5,0.8-0.7c0.5-0.3,1.6-0.4,0.7-1.4
	c-0.1-0.1,0.2-0.5,0.3-0.7c1.2-1.7,2.4-3.5,3.6-5.2c0.7-1,1.3-2,2-3c0.2-0.3,0.7-0.6,1-0.8c0.6-0.3,1.2-0.6,0.6-1.4
	c-0.1-0.2,0-0.6,0.1-0.9c0.6-1.2,1.4-2.4,2-3.7c0.1-0.3,0.2-1,0-1.1c-0.4-0.2-1.1-0.3-1.3-0.1c-0.8,0.8-1.7,1.7-2,2.8
	c-0.7,2.3-2.4,2.7-4.4,3.2c-1.9,0.4-3.8,1-5.8,1.5c-0.3,0.1-0.6,0.3-0.8,0.2c-2-0.7-3.7,0.3-5.4,0.9c-3.4,1.2-6.8,1.8-10.4,2
	c-0.6,0-1.2,0.1-1.8,0.3c-1.2,0.4-1.7-0.2-1.8-1.2c-0.1-1.5-0.1-2.9-0.1-4.4c0-0.4,0-0.8,0.2-1.1c0.2-0.5,0.5-1.1,0.9-1.2
	c1.8-0.7,3.6-1.7,5.5-1.9c4.4-0.5,8.2-2.7,12.4-3.7c3-0.7,5.9-1.7,8.8-2.7c1.5-0.5,3-1.2,4.6-1.8c0.4-0.1,1-0.1,1.3,0.1
	c0.7,0.5,0.9,0.2,1.2-0.4c1.3-3.2,2.6-6.3,3.9-9.5c1.4-3.4,2.6-6.9,3.9-10.3c0-0.1,0.1-0.2,0.1-0.3c0.3-1.5,0.5-1.6,1.9-1.3
	c1.3,0.3,2.6,0.6,4,0.7c2.7,0.2,5.5,0.7,8.2-0.1c1-0.3,2.1-0.4,3.2-0.5c0.3,0,0.7,0.2,0.8,0.4c0.1,0.4,0.2,1,0,1.3
	c-0.8,1.4-1.8,2.7-2.5,4.2c-1.7,3.3-3.7,6.5-4.5,10.1c-0.7,3.1-1.2,6.3-1.6,9.4c-0.1,0.5,0.4,1.3,0.8,1.5c3.2,1.5,6.5,3,9.8,4.4
	c1.5,0.7,3.1,1.1,4.6,1.8c0.8,0.4,1.5,0.9,2.3,1.4c0.3,0.2,0.7,0.3,1,0.4c2.6,0.3,4.6,2,6.9,3c0.2,0.1,0.3,0.7,0.2,1
	c-0.1,0.9-0.2,1.9-0.5,2.7c-0.2,0.5-0.7,1.1-1.1,1.2c-0.8,0.2-1.8,0.3-2.6,0c-1.8-0.5-3.5-0.7-5.4-0.6c-1.9,0.1-3.9-0.2-5.8-0.4
	c0,0-0.1,0-0.1,0c-2.3-1.3-4.8-0.8-7.3-0.8c-0.1,0-0.2,0-0.2,0c-2.4-0.4-4.8-0.8-7.1-1.4c-1.8-0.4-3.1,0.4-4.3,1.4
	c-2.2,1.8-4.4,3.7-6.7,5.4c-1.9,1.5-4,2.8-6,4.4c-1.9,1.5-3.7,3.2-5.5,4.7c-0.8,0.7-1.8,1.1-2.7,1.8c-0.4,0.3-0.8,0.7-1,1.1
	c-0.4,0.7-0.7,0.8-1.4,0.4C68.4,399.8,67.1,398.9,65.7,397.9z M93.3,372.1c1.3-0.7,1.6-2,1.9-3.3c0.2-0.6,0.1-1.3-0.8-1.5
	c-0.3-0.1-0.5-0.3-0.7-0.4c-1.1-0.3-2.2-0.8-3.2-0.8c-0.8,0-1.8,0.4-2.3,1c-0.6,0.7-1.2,1.8-1.1,2.8c0.1,1.6-0.5,2.9-1.4,4.2
	c-0.9,1.4-0.7,2.6,0.8,3.4c1.9,1,3.8,0.9,5.7,0C94.1,376.5,94.8,373.8,93.3,372.1z M98.6,372.3c0.1,0.1,0.2,0.2,0.3,0.3
	c-0.3,1.1-0.7,2.2-1,3.2c-0.2,0.9-0.6,1.7-0.6,2.6c0,0.4,0.6,1.1,1,1.2c0.4,0.1,1.1-0.5,1.2-0.9c0.6-2.5,1.2-4.9,1.7-7.4
	c0.1-0.5,0-1.2-0.3-1.4c-0.3-0.3-1.1-0.3-1.5-0.2c-1.1,0.5-2.2,1.2-3.3,1.7c-0.6,0.3-1.1,0.7-0.7,1.4c0.4,0.7,1.1,0.7,1.8,0.3
	C97.6,372.7,98.1,372.5,98.6,372.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity49 ? this.state.opacity49 : 1}
              fill={this.state.color49 ? this.state.color49 : 'transparent'} onClick={() => {
          this.setState({ color49: color, opacity49: opacity });
        }} d="M320.4,202.7c2.5,1.3,4.5,3.3,7.1,4.3c0.2,0.2,0.5,0.6,0.8,0.8c5.2,3.3,10.4,6.6,15.5,10c3,2,5.7,4.3,8.6,6.4
	c1.5,1.1,3.2,2,4.6,3.2c4.8,3.9,9.5,7.8,13.4,12.8c0.6,0.7,0.3,1.1-0.2,1.6c-2.2,1.8-4.4,3.5-6.6,5.3c-1.9,1.5-3.9,3-5.5,4.8
	c-3.9,4.2-7.5,8.7-11.3,13c-0.1,0.2-0.3,0.3-0.6,0.7c-1.2-1.9-2.4-3.6-3.4-5.5c-1.6-3.1-3.2-6.2-4.6-9.3c-2.1-4.8-5.1-9.1-8.3-13.3
	c-2.4-3.2-5.1-6.1-7.7-9.2c-1.1-1.4-2.2-2.8-3.4-4.2c-1-1.2-2.1-2.4-3.2-3.7c-0.6-0.7-1.2-1.3-0.3-2.3c0.5-0.7,0.4-1.9,0.8-2.8
	c1-2.9,2-5.8,3-8.7C319.5,205.3,319.9,204.1,320.4,202.7z M339.8,233c0.2,1.3,0.3,2.2,0.4,3.1c0.1,0.6,0.3,1.3,0,1.7
	c-0.2,0.3-1.1,0.2-1.6,0.4c-0.3,0.1-0.6,0.3-0.9,0.5c0.2,0.3,0.3,0.6,0.5,0.7c2.5,1.6,3.2,1.2,4-1.3c0.1-0.4,0.2-0.9,0.1-1.3
	c-0.3-2.1-0.5-4.3-1-6.4c-0.1-0.6-0.7-1.2-1.3-1.5c-1.7-0.8-3.4,0-4.9,0.6c-1.9,0.7-1.9,1.6-1.8,3.4c0.1,1.1,1.2,1.7,2.7,1.8
	c1.2,0.1,2.1-0.2,2.8-1.2C339,233.5,339.3,233.4,339.8,233z M349.7,233.7c0.5-0.4,0.9-0.5,1.1-0.8c1.1-1.2,0.7-2.6-0.7-3
	c-1.5-0.4-2.9,0.1-4.4,0.5c0.9,1.9,2.6,0.5,3.9,1.5c-0.7,0.5-1.2,1-1.8,1.4c-0.6,0.4-1.3,0.9-2,1.4c0.4,0.3,0.6,0.4,0.7,0.5
	c1.1,0.8,2.3,0.6,3.5,0.7c0.6,0.1,1.4,0.5,1.6,1c0.2,0.3-0.2,1.3-0.6,1.6c-0.6,0.5-1.4,0.8-2.1,1c-0.3,0.1-0.8-0.4-1.2-0.6
	c-0.4-0.2-0.8-0.6-1.1-0.5c-0.4,0.1-0.8,0.6-1,1c-0.1,0.1,0.3,0.7,0.6,0.9c2.7,1.7,5.1,0.6,6.8-1.2c1.5-1.6,0.8-4.1-1.3-5
	C351.2,234,350.6,233.9,349.7,233.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity50 ? this.state.opacity50 : 1}
              fill={this.state.color50 ? this.state.color50 : 'transparent'} onClick={() => {
          this.setState({ color50: color, opacity50: opacity });
        }} d="M438.5,379.8c0.4,3.7,0.7,7.4,1.1,11c0.1,1.1,0.4,2.3,0.5,3.4c0.1,1.5,0,3,0,4.5c0,3.7-0.1,7.3-0.1,11
	c0,3,0.3,6.1,0.3,9.1c0.1,3.4,0.1,6.8,0.1,10.2c0,1.4-0.2,2.8-0.3,4.3c0,0.8-0.5,0.9-1.1,0.9c-2.1,0.1-4.2,0.1-6.3,0.2
	c-3.6,0.3-7.1,0.8-10.7,1.1c-1.7,0.1-3.5-0.1-5.3-0.1c-4.2,0.1-8.4,0.4-12.7,0.4c-4.9,0.1-9.7,0-14.6-0.1c-0.4,0-0.8-0.1-1.1-0.1
	c0-0.1-0.1-0.2-0.1-0.3c0.3-0.2,0.5-0.3,0.8-0.4c2.3-0.8,4.7-1.6,7-2.5c0.6-0.2,1-0.9,1.6-1.3c0.6-0.4,1.3-0.7,2-1.1
	c2.6-1.6,4.9-3.5,6.6-6.1c1.6-2.4,3.7-4.5,5.2-7c1.7-2.6,3-5.4,4.4-8.1c0.6-1.2,1.1-2.4,1.8-3.5c1.8-2.9,2.4-6.2,2.8-9.5
	c0.3-2.2,0.3-4.5,0.2-6.8c-0.1-1.4,0.5-2.4,1.3-3.2c2.2-2,4.6-3.9,7.7-4.4c2.6-0.4,5.1-1.2,7.7-1.8
	C437.8,379.8,438.1,379.8,438.5,379.8z M427.7,420.3c0.1,0,0.2,0,0.3,0.1c0,0.3,0,0.5,0.1,0.8c0.3,0.9,0.4,2,0.9,2.6
	c2.1,2.3,5.8,1.3,7.4-1.2c0.6-0.9-0.1-2.6-1.1-3.1c-1.2-0.5-2.5-1.1-3.8-0.2c-0.3,0.2-0.6,0.2-1.1,0.4c0.8-2.9,2.4-4.6,5.1-5.6
	c-0.7-0.8-1.4-1.5-2.4-0.9c-1,0.6-2.1,1.4-2.9,2.3c-0.9,1.1-1.5,2.5-2.2,3.8c-1.9-2.8-2.4-3.1-5-3.4c1.1-1.3,1.9-2.8,3.8-3.2
	c0.3-0.1,0.5-0.6,0.8-1c-0.4-0.3-0.8-0.7-1.3-0.9c-0.3-0.1-0.8,0.1-1.1,0.3c-2,0.8-3.2,2.5-4.5,4.2c-1,1.4-1.4,2.9-1.4,4.5
	c0,1.4,0.7,2.5,1.9,3.2c1.8,1,4.7,0.1,5.6-1.7C427.2,421,427.5,420.7,427.7,420.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity51 ? this.state.opacity51 : 1}
              fill={this.state.color51 ? this.state.color51 : 'transparent'} onClick={() => {
          this.setState({ color51: color, opacity51: opacity });
        }} d="M223.7,349.9c0.8-1.4,0-2-1.1-2.5c-1.3-0.6-2.7-1.2-4-1.8c-0.1,0-0.3-0.1-0.3-0.2c-1.3-1.5-3.5-2-4.4-3.9
	c-0.1-0.2-0.4-0.3-0.6-0.5c-0.3-0.2-0.7-0.4-1-0.7c-2.4-2.7-5-5.3-5.7-8.9c-0.3-1.3,0.1-2.7,0.4-4.1c0.1-0.4,1-1.1,1.4-1
	c1,0.3,1.5-0.3,2.3-0.6c0.6-0.2,1.2-0.2,1.8-0.4c1.6-0.4,3.2-0.8,4.6-1.4c1.3-0.5,2-0.4,2.9,0.5c1,1.1,2.1,2,3.1,3.1
	c0.5,0.5,0.9,1,1.4,1.5c0.2,0.2,0.6,0.4,0.8,0.3c0.2-0.1,0.4-0.6,0.4-0.8c-0.2-0.6-0.5-1.3-0.9-1.8c-1-1.3-2.2-2.6-3.2-3.8
	c1.6-0.7,3.1-1.4,4.5-2.1c0.9,1.8,1.7,3.7,2.7,5.4c2.6,4.3,5.2,8.6,8,12.8c1.6,2.3,3.7,4.1,5.5,6.2c2.7,3.1,5.8,5.6,9.2,7.7
	c2.9,1.7,6,3.1,9,4.5c2,1,4.1,2,6.1,2.9c0.9,0.4,1.9,0.8,2.9,1.4c-0.8-0.1-1.6-0.2-2.3-0.3c-1.5-0.1-3-0.1-4.5-0.2
	c-2.5-0.2-5-0.2-7.5-0.7c-2.7-0.6-5.3-1.5-7.8-2.5c-2.6-0.9-5.1-1.8-8-1.3c-1.7,0.3-3.5,0.2-5.3,0.4c-0.5,0-1,0.2-1.5,0.3
	c-1.4,0.6-2.9,1.1-4.2,1.9c-1.5,0.9-2.8,2.1-4.1,3.2c-0.3,0.2-0.5,0.7-0.8,0.8c-3,1.6-4.1,4.5-5.1,7.4c-0.3,1-1.1,1.2-1.7,1.2
	c-2.7,0-5.5,0.1-8.2-0.3c-2.6-0.4-5.1-0.3-7.6-0.3c-2.2,0-3.2-0.8-3.7-3c-1.5-6.4,2.4-12.2,7.1-14.6c5.9-3,12.1-4.7,18.8-4.1
	C223.4,349.9,223.6,349.9,223.7,349.9z M231.3,336.5c0.3-1.9-3.2-3.9-4.7-3.5c-0.4,0.1-0.8,0.8-0.9,1.3c-0.2,0.7,0.5,0.6,1,0.8
	c1,0.3,1.9,0.8,2.8,1.3c0.1,0.1,0,1-0.2,1.1c-1.4,0.5-2.8,0.8-4.2,0c-1-0.5-1.7,0-1.6,1.1c0,0.4,0.6,0.8,0.9,1
	c1.5,0.8,1.7,1.8,0.7,2.8c-1.6,1.6-4.4,0.7-5.2-1.6c-0.2-0.4-0.8-0.7-1.1-1.1c-0.3,0.5-0.7,1-0.9,1.6c-0.1,0.3,0.2,0.7,0.5,1.1
	c1.2,1.6,3,2.2,4.6,3c0.2,0.1,0.6,0.1,0.8,0c2.5-0.4,3.9-1.9,4.1-4.4c0-0.3,0.3-0.8,0.6-0.9c0.5-0.3,1.2-0.4,1.8-0.7
	c0.4-0.2,0.8-0.6,0.9-0.9C231.3,337.8,231.2,337.1,231.3,336.5z M235.3,345.2c-2.1,1.5-3.8,2.9-5.7,3.9c-1.2,0.6-2,1.3-2.2,2.6
	c-0.1,0.3-0.1,0.6-0.1,1c0.3,0,0.7,0.1,1,0c0.4-0.1,0.7-0.4,1-0.6c2.1-1.5,4.2-3,6.3-4.5c0.7-0.5,1.5-1.1,2.2-1.7
	c0.9-0.9,0.6-2.1-0.6-2.4c-1.4-0.4-2.8-0.8-4.3-1c-0.5-0.1-1.3,0.4-1.5,0.9c-0.3,0.7,0.4,1,1,1.1
	C233.3,344.6,234.1,344.9,235.3,345.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity52 ? this.state.opacity52 : 1}
              fill={this.state.color52 ? this.state.color52 : 'transparent'} onClick={() => {
          this.setState({ color52: color, opacity52: opacity });
        }} d="M55.4,142.5c-2.9,0.8-5.6,1.4-8.4,2.2c-2.6,0.7-5.3,1.3-7.8,2.3c-2.9,1.2-5.6,2.9-8.4,4.4
	c-0.2,0.1-0.5,0.3-0.7,0.4c-5.4,2.1-9.7,5.8-13.5,10.1c-0.2,0.2-0.5,0.4-1.1,0.9c0-2.6-0.1-4.9,0-7.2c0.1-4.2-0.1-8.4-0.7-12.5
	c-0.3-2.3-0.4-4.6-0.5-6.9c0-0.3,0.1-0.6,0.2-0.8c1.4-0.9,1.8-2.4,2.6-3.7c0.9-1.5,2.1-2.8,3.2-4.1c2.1-2.3,4.2-4.7,6.5-6.9
	c2.1-1.9,4.4-3.5,6.7-5.2c2.1-1.6,4.1-3.3,6.1-4.9c0.8-0.6,1.3-0.3,1.7,0.4c1.1,1.8,2.2,3.6,3.3,5.4c1.7,2.9,3.5,5.8,5.1,8.8
	c2,3.6,3.7,7.3,4.4,11.4C54.6,138.5,55.1,140.5,55.4,142.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1}
              fill={this.state.color53 ? this.state.color53 : 'transparent'} onClick={() => {
          this.setState({ color53: color, opacity53: opacity });
        }} d="M295.1,159.1c-1.1-1.2-1.1-1.2,0-2.6c4-4.7,7.9-9.5,13-13c1.5-1.1,3.2-1.8,4.9-2.6c0.3-0.1,0.9,0.2,1.1,0.5
	c0.4,0.8,0.7,1.6,1,2.4c1.2,3.8,3.2,7.3,5.6,10.5c1.9,2.5,3.5,5.3,5.5,7.8c1.6,2,3.6,3.7,5.5,5.5c0.6,0.6,1.5,1,1.8,1.7
	c0.2,0.5-0.5,1.4-0.7,2.1c-1.2,3.3-2.5,6.5-4.4,9.5c-1.4,2.2-2.2,4.8-3.3,7.1c-0.4,0.9-0.9,1.8-1.3,2.7c-1.1,2.5-2.2,5-3.4,7.5
	c-0.2,0.5-0.6,0.9-1,1.6c-1.6-1.3-3-2.6-4.4-3.8c-1-0.9-2.1-1.6-3-2.6c-2-2.2-4.3-4.3-5.7-6.9c-3.2-5.6-6.1-11.5-8.9-17.3
	c-1.3-2.6-2.5-5.3-2.5-8.3C294.9,160.4,295,159.9,295.1,159.1z M324.7,168.3c-0.3-0.8-0.5-1.7-1-2.2c-0.5-0.5-1.4-1-2.1-1
	c-0.8,0-1.6,0.6-2.4,0.9c-0.4,0.2-0.8,0.5-1,0.8c-0.6,1.2-0.9,2.4-0.3,3.7c0.3,0.7,0.4,1.2-0.1,1.8c-0.8,1-0.6,2.2,0.1,3.2
	c0.8,1.1,2,2,3.4,1.9c2.4-0.2,3.3-1.1,3.9-3.5c0.1-0.3,0.1-0.7,0.3-1c0.3-0.8-0.2-1.1-0.9-1.2c-1-0.1-1.2,0.4-1.3,1.7
	c-0.1,0.6-0.4,1.5-0.9,1.6c-0.7,0.3-1.6,0.2-2.3,0c-0.7-0.3-0.8-1.1-0.5-1.9c0.3-0.7,0.7-1,1.5-0.6c0.2,0.1,0.5,0,0.7-0.1
	C323.7,171.8,324.6,170.4,324.7,168.3z M316.5,175.9c-0.1,0-0.2-0.1-0.4-0.1c0-1.1,0-2.3,0-3.4c0-0.1-0.1-0.3-0.2-0.3
	c-1.1-0.7-0.8-1.9-1-2.9c-0.2-1-0.4-2.1-0.9-3c-0.7-1.2-1.9-0.9-2.5,0.3c-1,2-2.3,3.9-3.4,5.9c-0.2,0.4-0.5,0.9-0.4,1.2
	c0.2,0.3,0.8,0.5,1.2,0.5c1.1,0,2.3-0.1,3.4-0.3c1-0.1,1.7,0,1.7,1.3c0,0.4,0.2,0.9,0.4,1.2c0.3,0.3,0.8,0.5,1.2,0.5
	C316.1,176.8,316.3,176.3,316.5,175.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity54 ? this.state.opacity54 : 1}
              fill={this.state.color54 ? this.state.color54 : 'transparent'} onClick={() => {
          this.setState({ color54: color, opacity54: opacity });
        }} d="M138.8,336.9c1.3-3.5,2.3-6.5,3.5-9.5c2.6-6.1,5.2-12.1,7.2-18.4c0.6-1.7,0.9-3.5,1.4-5.2
	c0.5-1.3,1-2.7,1.8-3.9c0.3-0.5,1.2-0.8,1.8-0.7c2.2,0.2,4.4,0.4,6.6,1c2.7,0.7,5.5,0.3,8.4,0.4c-0.1,0.7-0.3,1.3-0.4,1.9
	c-0.4,2.2-0.8,4.4-1.3,6.5c-0.3,1.3-0.9,2.6-1.3,3.9c-1.5,5.5-2.9,11-4.4,16.5c-0.5,1.7-1.2,3.3-1.8,4.9c-1.2,3.3-2.4,6.6-3.6,9.9
	c-0.6,1.5-1.5,2.9-2.1,4.4c-0.5,1.1-1.1,0.9-1.9,0.6c-1.5-0.6-3-1.2-4.6-1.7c-1.9-0.6-3.8-1.2-5.7-1.7c-0.5-0.1-1-0.2-1.5-0.2
	c-2,0.1-4,0.2-6,0.6c-1.7,0.3-3.3,0.8-4.9,1.2c-1.1,0.3-2.3,0.5-3.4,0.8c-0.7,0.2-1.4,0.6-2.1,0.9c-3.3,1.2-5.8,3.4-7.8,6.3
	c-0.3,0.5-1.2,0.6-1.9,0.7c-3,0.4-4.9-1.7-4.4-4.6c0.3-1.4,0.4-2.9,0.8-4.3c0.1-0.4,0.6-0.7,0.9-1c0.1-0.1,0.3-0.2,0.3-0.3
	c0.2-1.7,1.5-2.6,2.6-3.6c1.4-1.3,3-2.4,4.5-3.6c1.6-1.2,3.5-1.1,5.3-1.5c2.1-0.5,4.3-0.8,6.4-1.1c0.7-0.1,1.5,0.1,2.2,0.3
	C135.3,336.3,137.1,336.6,138.8,336.9z M159.1,325.6c1-0.7,2-1.4,2.8-2.3c0.3-0.3,0.1-1.1,0-1.7c0-0.2-0.1-0.3-0.1-0.5
	c-0.1-2.1-2.6-4.2-4.6-4c-2.4,0.3-4.1,1.5-4.8,3.9c-0.1,0.4-0.6,1-0.9,0.9c-0.8-0.1-0.6-0.8-0.4-1.3c0.4-1.5,0.9-3,1.3-4.5
	c0.1-0.3-0.3-0.8-0.4-1.1c-0.6,0.5-1.7,0.3-2,1.4c-0.5,1.8-1.1,3.6-1.8,5.3c-0.4,1,0,1.4,0.8,1.7c0.8,0.3,1.7,0.6,2.5,0.9
	c1.5,0.7,2.6,0.2,3-1.4c0.1-0.4,0.1-0.8,0.3-1.1c0.4-0.7,0.7-1.5,1.2-2c0.9-0.8,1.9-0.6,2.8,0.3c0.8,0.8,0.9,1.6,0,2.5
	c-0.4,0.4-0.6,0.8-0.8,1.3C157.7,324.8,158.1,325.4,159.1,325.6z M146.7,329.7c1.7,0.1,2.6,1.2,3,2.9c0.1,0.5,0.5,1.3,0.9,1.3
	c0.7,0.1,1.3-0.4,1.4-1.3c0.2-1.4,0.8-2.7,2.4-3.2c1.3-0.4,2.3,0.1,2.3,1.5c0,0.5-0.1,1.1-0.4,1.5c-0.6,0.8-1.3,1.4-2,2.1
	c-0.9,0.9-0.8,1.3,0.4,1.7c1.4,0.5,2.2-0.7,2.8-1.4c1.9-1.8,1.9-4.4,0.5-6.6c-0.7-1-1.6-1.8-2.9-1.4c-1.5,0.5-3,1.3-4.5,1.9
	c-0.2,0.1-0.4-0.2-0.7-0.4c-0.8-0.4-1.6-1.1-2.4-1c-0.8,0.1-1.7,0.8-2.4,1.3c-0.2,0.1-0.2,0.4-0.2,0.7c-0.1,1.5-0.2,3-0.2,4.5
	c0,0.2,0.2,0.6,0.3,0.6c0.2,0.1,0.6,0,0.7-0.2c0.4-1,1.4-1.8,1.1-3C146.8,330.8,146.7,330.3,146.7,329.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity55 ? this.state.opacity55 : 1}
              fill={this.state.color55 ? this.state.color55 : 'transparent'} onClick={() => {
          this.setState({ color55: color, opacity55: opacity });
        }} d="M263.1,44.8c-0.8,0.4-1.4,0.7-2,1c-1,0.3-2.1,0.6-3.1,0.9c-0.9,0.3-1.4,0-1.9-0.7c-1.6-2.7-3.3-5.3-4.9-8
	c-1.5-2.5-3.2-4.8-5.6-6.6c-2.1-1.6-3.9-3.7-6.1-5.2c-1.6-1.1-3.6-1.7-5.3-2.5c-1-0.5-2.1-0.9-3-1.5c-0.9-0.6-1.4-0.6-2,0.4
	c-3.2,5.1-4.6,10.8-4.8,16.8c-0.2,3.6,0.2,7.2,0.4,10.7c0.1,1,0.5,2.1,0.9,3.5c-1.6-0.6-2.9-1.2-4.2-1.7c-0.4-0.2-0.9-0.6-1.3-0.5
	c-2.3,0.6-4.4-0.7-6.6-0.9c-0.8-0.1-1.3-0.4-1.1-1.5c0.5-2.4-0.8-4.7-3.1-5.8c-0.4-0.2-0.8-0.6-1-1c-1.4-2.7-1.7-5.8-2.2-8.8
	c-0.7-4.2-1.3-8.5-0.8-12.7c0.5-3.4,1.3-6.8,2-10.2c0.3-1.2,0.8-2.2,1.1-3.4c0.3-1,0.8-1.4,1.9-1.5c3-0.2,5.1,1.4,7.5,2.6
	c3.3,1.6,6.6,3.1,9.8,4.8c2.5,1.3,4.9,2.9,7.3,4.4c0.2,0.1,0.4,0.3,0.6,0.4c4.9,2.3,8.6,6.1,12.4,9.8c2.2,2.2,4.5,4.4,6.6,6.7
	c2.5,2.7,4.8,5.6,6.8,8.8C261.8,43.4,262.4,44,263.1,44.8z M217.4,24.9c1.9,1.1,1.9,1,2.3-0.9c0.3-1.9,0.7-3.7,1.1-5.6
	c0.5-2.2,0.5-2.2-0.9-3.2c-2.2,1-4.3,2-6.6,3c1.5,1.5,1.5,1.4,3,0.6c0.4-0.2,0.8-0.3,1.3-0.5C218,20.6,218.5,22.8,217.4,24.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity56 ? this.state.opacity56 : 1}
              fill={this.state.color56 ? this.state.color56 : 'transparent'} onClick={() => {
          this.setState({ color56: color, opacity56: opacity });
        }} d="M327.5,133.6c-1.7,0.3-3.4,0.3-5,0.9c-2.5,0.9-5.3,0.9-7.6,2.4c-1.1,0.7-2.4,1.1-3.6,1.7
	c-0.2,0.1-0.6,0.3-0.7,0.5c-1.1,2.3-3.3,3.5-5.1,5.1c-3.2,2.8-6.5,5.6-9.7,8.4c-0.5,0.5-0.7,1.4-1,2c-1,1.8-2,3.6-3,5.4
	c-1.9,3.3-3.9,6.6-5.8,9.9c-0.6,1-0.9,2-1.4,3c-0.4-0.2-0.7-0.5-1-0.6c-1.1-0.3-1.6-0.9-1.9-2c-0.6-2.5-1.2-5-2.2-7.3
	c-1.2-2.9-2.8-5.7-4.2-8.6c-0.5-1-0.5-2.1-1-3c-0.7-1.1-1-2.3-1.1-3.6c-0.1-1.3-0.5-2.6-0.8-3.9c-0.5-2.6-1-5.2-1.6-8.2
	c1.2-0.5,2.6-1.2,4-1.6c2.4-0.8,4.7-1.4,7.1-2c1.4-0.4,2.8-0.6,4.2-0.9c2.4-0.4,4.7-0.7,7.1-1c1.2-0.1,2.4-0.1,3.7-0.2
	c3.4,0,6.8,0,10.2,0c5.8,0,11.5,0.8,17.1,2.1c1.1,0.3,2.2,0.7,3.3,1C327.5,133.5,327.5,133.6,327.5,133.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity57 ? this.state.opacity57 : 1}
              fill={this.state.color57 ? this.state.color57 : 'transparent'} onClick={() => {
          this.setState({ color57: color, opacity57: opacity });
        }} d="M304.6,25.7c-0.4,0.1-0.7,0.2-1,0.2c-3.6,0.2-6.9,1.1-10.3,2.1c-2.1,0.6-4.3,1-6.4,1.5
	c-2.9,0.8-5.5,2.2-7.9,4.1c-1.8,1.4-3.7,2.5-5.4,3.9c-0.8,0.6-1.4,1.4-2.1,2.1c-1.9,1.9-4.2,3-6.5,4.4c-4.2-6.7-9.5-12.3-15.2-17.6
	c1.7-1.6,3.2-3.1,4.8-4.4c1.6-1.4,3.3-2.7,4.9-4c2.7-2.3,5.3-4.7,8.1-6.9c2-1.6,4-3.1,6-4.6c0.3-0.2,0.7-0.3,1.1-0.3
	c5.7,0.1,11.5,0.2,17.2,0.3c0.7,0,1.1,0.2,1.4,0.8c1.4,2.5,2.8,5,4.2,7.5c1.1,1.9,2.2,3.7,3.4,5.5C302,22,303.3,23.8,304.6,25.7z
	 M268.3,30.1c1-0.4,2.1-0.6,3.1-1.1c1.5-0.8,2.3-3.2,1.4-4.4c-0.4-0.6-0.4-1-0.1-1.6c1-1.8,0-3.9-2.1-4.2c-0.6-0.1-1.2-0.3-1.7-0.4
	c-0.9,0-2,0-2.4,1c-0.5,1.1-0.7,2.3-1,3.5c-0.1,0.4,0.1,1,0.3,1.2c1.1,0.7,0.8,1.4,0.3,2.3C265.3,28,266.5,29.9,268.3,30.1z
	 M283.7,18c-0.5-0.2-1.1-0.7-1.7-0.7c-1.8-0.2-3.6-0.3-5.3-0.4c-0.8-0.1-1.4,0.2-1.5,1.2c-0.1,1.1-0.3,2.2-0.4,3.3
	c0,0.4,0,1,0.2,1.2c0.3,0.2,0.9,0.3,1.3,0.1c1.1-0.6,1.5,0.3,2.1,0.8c0.6,0.5,0.6,1.2,0.2,1.7c-0.9-0.1-1.6-0.3-2.3-0.2
	c-0.3,0-0.8,0.5-0.8,0.6c0.2,0.7,0.5,1.5,0.9,2.1c0.5,0.7,1.2,0.6,1.9,0c0.2-0.2,0.4-0.3,0.6-0.4c2.1-0.7,2.9-2.9,1.6-4.6
	c-0.8-1-1.9-1.8-3-2.8c0.1-0.1,0.3-0.4,0.5-0.5c1-0.1,2.1-0.2,3-0.1C282.4,19.3,283.2,19.1,283.7,18z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity58 ? this.state.opacity58 : 1}
              fill={this.state.color58 ? this.state.color58 : 'transparent'} onClick={() => {
          this.setState({ color58: color, opacity58: opacity });
        }} d="M379.6,108.6c2.3,0,4.4,0,6.5,0c0.9,0,1.9-0.1,2.8-0.1c2.1,0,4.1-0.1,6.2-0.1c2.8-0.1,5.7-0.2,8.5-0.2
	c0.3,0,0.7,0.2,0.9,0.4c0.6,0.9,1.1,0.7,1.7,0c0.3-0.3,0.6-0.5,1-0.5c3.5-0.5,7.1-0.7,10.6-1.4c4.6-0.9,9.2-2.1,13.7-3.3
	c1.8-0.5,3.5-1.2,5.2-1.9c1.2-0.4,1.8-0.1,1.7,1.1c-0.2,4.8-0.5,9.5-0.8,14.3c-0.1,1.3-0.1,2.5-0.2,3.8c-0.3,3.5-0.7,7-0.9,10.6
	c-0.1,1.5,0,3.1,0,4.7c-1.7,0.1-3.1-0.1-4.5-0.7c-0.8-0.3-1.8-0.5-2.6-0.7c-3.2-1.1-6.3-2.1-9.5-3.2c-2.4-0.9-4.7-1.8-7.1-2.7
	c-2.5-0.9-5-1.8-7.4-2.8c-0.6-0.2-1.1-0.7-1.7-1c-1.6-0.8-3.2-1.6-4.8-2.5c-1.2-0.6-2.3-1.4-3.5-2.1c-2.4-1.4-4.9-2.6-7.2-4.2
	c-1.8-1.2-3.3-2.9-4.9-4.3c-1-0.9-2.1-1.7-3.1-2.6C379.9,109.1,379.8,108.9,379.6,108.6z M416.1,119.9c0.1,0.3,0.2,0.5,0.3,0.8
	c0.5,1.7,0.2,2.2-1.5,2.7c-0.2,0.1-0.5,0.8-0.5,0.9c0.4,0.3,1,0.8,1.4,0.8c2-0.1,3.1-1.8,2.8-3.4c-0.3-1.7-0.2-3.7-1.8-5
	c-0.1-0.1,0-0.4-0.1-0.5c-0.3-0.6-0.5-1.3-1-1.6c-2-1.3-4.4-0.5-5.5,1.8c-0.6,1.2-0.4,2.1,0.4,3.1c0.9,1,1.8,1.6,3.2,1.3
	C414.6,120.6,415.3,120.3,416.1,119.9z M429.5,119.3c-0.1-2.6-2.7-4.3-5.2-3.3c-0.4,0.2-0.9,0.2-1.3,0.4c-0.6,0.3-1.5,0.5-1.8,1
	c-1.4,1.9-0.4,3.7,0.7,5.4c1,1.5,2.6,1.9,4.2,1.6C428,124.2,429.6,121.7,429.5,119.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity59 ? this.state.opacity59 : 1}
              fill={this.state.color59 ? this.state.color59 : 'transparent'} onClick={() => {
          this.setState({ color59: color, opacity59: opacity });
        }} d="M17.5,306.4c0.6,0.7,1,1.2,1.4,1.7c3,3.4,5.8,7.1,9,10.3c3.3,3.4,6.8,6.6,10.6,9.4c5.5,4.1,11.1,7.8,17.3,10.8
	c2.6,1.2,5.1,2.4,7.8,3.3c2.1,0.7,4.1,1.7,6.1,2.7c0.2,0.1,0.5,0.8,0.3,1.1c-0.6,1.4-1.2,2.8-1.9,4.2c-0.8,1.8-1.7,2-3.7,1.7
	c-1.3-0.2-2.6,0.2-3.9,0.2c-2.8,0.1-5.6,0.1-8.3,0.2c-3,0.1-6.1,0.1-9.1,0.4c-2.1,0.2-4-0.4-5.9-1c-3.1-0.9-6-2-9.1-3
	c-2.3-0.7-4.7-1.3-7-2c-0.7-0.2-1.4-0.4-2.1-0.7c-0.2-0.1-0.4-0.5-0.5-0.7c-0.7-5.8-1-11.6-0.9-17.4c0.1-4.7-0.2-9.4-0.2-14.1
	C17.5,311.3,17.5,309,17.5,306.4z M33.1,331.2c0.1,0.1,0.3,0.2,0.4,0.4c-2.6,2.7-5.2,5.3-7.8,8c-0.2-0.2-0.4-0.4-0.5-0.6
	c0.4,1.2,1.2,1.8,2.4,1.9c1.4,0.1,2.9,0.2,4.3,0.2c0.8,0,1,0.3,1,1c0,1,0,2,0.2,3c0,0.3,0.5,0.7,0.9,0.8c0.3,0.1,0.7-0.2,0.9-0.5
	c0.2-0.3,0.3-0.7,0.3-1.1c0.2-1.2,0.3-2.3,0.4-3.5c0-0.2-0.2-0.4-0.3-0.6c-0.2-0.5-0.6-1-0.6-1.5c-0.1-1.1,0-2.2-0.1-3.2
	c-0.1-0.7-0.3-1.4-0.5-2.2c-0.1,0-0.2,0-0.3,0c0.2-0.3,0.4-0.6,0.6-0.9C33.8,331.9,33.4,331.6,33.1,331.2z M39.3,345.5
	c0-0.1-0.1-0.2-0.1-0.4c0.4-0.4,0.8-0.7,1.2-1.1c1.2-1.3,2.5-2.7,3.7-4c0.7-0.8,0.6-1.7,0.2-2.6c-0.7-1.6-2.2-1.7-3.6-2.1
	c-1.2-0.3-2.3,0-2.8,1c-0.3,0.6-0.1,1.6,0.2,2.3c0.2,0.5,0.9,1,1.3,1c0.9,0,0.7-0.7,0.6-1.4c-0.1-1,0.4-1.3,1.3-0.9
	c1.1,0.5,1.4,1.2,0.7,2.1c-0.8,1-1.6,2-2.5,2.9c-1,1-2.2,1.8-2.6,3.2c-0.2,0.9,0.4,1.7,1.3,1.8c2.4,0.1,4.8,0.3,7.2,0.4
	c0.5,0,1-0.3,1.5-0.6c0.1-0.1,0-0.8-0.2-1c-1.1-0.8-2.3-0.7-3.6-0.6C41.9,345.7,40.6,345.6,39.3,345.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity60 ? this.state.opacity60 : 1}
              fill={this.state.color60 ? this.state.color60 : 'transparent'} onClick={() => {
          this.setState({ color60: color, opacity60: opacity });
        }} d="M126.2,138.1c0.3-0.2,0.5-0.3,0.9-0.5c0.1,1,0.6,1.8,0.2,2.9c-0.3,1,0.1,2.5,0.7,3.6c1.3,2.7,2.4,5.6,5,7.4
	c1.3,1,2.7,2,4.2,2.7c0.6,0.3,1.7,0.1,2.3-0.2c1-0.5,2.2-0.7,3.1-0.1c-0.5,0.5-1.2,1-1.4,1.6c-0.4,1.1-1.4,1.4-2.3,1.6
	c-4.9,0.7-9.7,1.4-14.6,2c-1.9,0.2-3.7,0.5-5.6,0.7c-0.4,0-0.7,0.1-1.1,0.1c-3.5-0.2-6.9-0.4-10.4-0.6c-0.3,0-0.6-0.2-0.9-0.3
	c-1-0.2-1.9-0.3-2.9-0.6c-0.9-0.2-1.7-0.6-2.5-1.1c3.6,0.2,6.9-0.6,10.3-1.3c3.9-0.8,7.9-1.3,11.9-2c0.8-0.1,1.5-0.4,1.3-1.4
	c-0.2-1-0.8-1.5-1.9-1.1c-4.8,1.9-9.9,1.8-14.8,3c-2.4,0.6-4.8,0.7-7.3,0.4c-1.4-0.2-2.9,0.5-4.3,0.7c-1.3,0.2-2.7,0.3-4.1,0.3
	c-0.4,0-0.8-0.2-1.3-0.2c-0.5,0-1,0-1.6,0c-0.5,0-1,0.2-1.5,0.2c-1.1,0-2.3,0-3.7-0.3c0.6-0.7,1.1-1.3,1.6-2
	c0.2-0.2,0.4-0.5,0.5-0.8c1.4-4,4.2-7.1,7-10.2c2.2-2.3,4.5-4.5,6.8-6.8c0.8-0.8,1.7-1.4,2.5-2.2c4.1-3.9,8.6-7.2,13.3-10.3
	c2.4-1.6,5-2.8,7.5-4.2c0.4-0.2,0.9-0.3,1.4-0.5c0.1,2,0.6,4,0.1,5.7c-0.6,2.2,0.3,4.3,0.1,6.4c-0.1,1.1-0.2,2.2,0,3.2
	C125.1,135.4,125.7,136.6,126.2,138.1z M105.1,146.8c0-0.1-0.1-0.3-0.1-0.4c0.8-0.7,1.6-1.3,2.3-2c0.8-0.9,1.8-1.8,1.9-2.9
	c0.1-1-0.5-2.3-1.1-3.2c-1-1.5-4.5-0.9-5.5,0.9c-0.4,0.8-0.7,1.7,0.3,2.2c0.8,0.4,1.4,0,1.9-0.9c0.3-0.5,1.4-0.7,2-0.6
	c0.8,0.2,0.7,1,0.3,1.6c-0.4,0.6-0.9,1.1-1.4,1.6c-1.3,1.3-2.8,2.6-4,4c-0.3,0.3-0.1,1.1,0.1,1.6c0.1,0.2,0.5,0.4,0.8,0.5
	c1.1,0.1,2.2,0.1,3.3,0.2c1.8,0.2,3.5,0.4,5-1.2c-1-0.7-1.7-1.3-3-1C107,147.1,106,146.9,105.1,146.8z M115,149.8
	c0.1-0.1,0.1-0.2,0.2-0.3c0.4,0,0.7,0,1.1-0.1c0.6-0.2,1.2-0.6,1.8-0.9c2.6-1.1,3.4-3.6,1.8-5.9c-0.9-1.2-1.6-2.3-3.5-2.7
	c-1.3-0.3-1.9,0.1-2.6,0.8c-0.3,0.3-0.7,0.7-1,1.1c-1.7,1.9-1.2,4.3-0.2,6.1C113,148.8,114.1,149.2,115,149.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity61 ? this.state.opacity61 : 1}
              fill={this.state.color61 ? this.state.color61 : 'transparent'} onClick={() => {
          this.setState({ color61: color, opacity61: opacity });
        }} d="M269.5,267.7c0.5,1.2,0.3,2.1-0.3,3.1c-0.3,0.5-0.3,1.3-0.5,2c-1.5,4.5-3.6,8.7-6.2,12.6
	c-1.3,1.9-2.3,3.9-3.6,5.8c-0.7,1.1-1.5,2.1-2.2,3.1c-0.2,0.3-0.5,0.7-0.8,0.7c-2.5,0.7-3,3.3-4.7,4.8c-2,1.8-3.8,3.9-5.8,5.7
	c-3.6,3.3-7.5,6.2-11.6,8.9c-1.2,0.8-2.4,1.6-3.7,2.2c-1.6,0.8-2.7,0.3-3-1.5c-0.4-2.1-0.8-4.1-1.1-6.2c0-0.2-0.1-0.5-0.1-0.7
	c0.4-3.9,0.6-7.8,1.4-11.6c0.4-2,1.6-3.9,2.6-5.7c1.3-2.2,2.5-4.4,4.1-6.3c2.8-3.4,5.8-6.7,9.8-8.8c2.5-1.3,5.1-2.6,7.6-3.8
	c1.1-0.5,2.3-0.8,3.5-1.1c4.3-1.1,8.6-2.2,12.9-3.3C268.5,267.6,269,267.7,269.5,267.7z M247,284.5c-0.3,1.9-0.6,3.5-0.7,5.2
	c0,1.9-1.2,2.6-2.6,3.1c-0.7,0.2-1.1,0.5-0.9,1.3c0.3,0.8,0.9,0.9,1.6,0.7c0.5-0.1,0.9-0.5,1.6-0.8c0,1,0.1,1.7,0.1,2.5
	c0,0.8,0.5,1.2,1.2,1.2c0.7,0,1.1-0.3,1.1-1.1c0-0.7,0.1-1.4,0.1-2c-0.1-1-0.2-1.9,1.1-2.3c0.4-0.1,0.5-1,0.7-1.6
	c0-0.1-0.5-0.5-0.7-0.4c-1.3,0.2-1.2-0.6-1.1-1.4c0.3-1.9,0.6-3.8,0.9-5.8c0.1-0.9-0.4-1.6-1.3-1.5c-0.4,0-0.9,0.2-1.2,0.4
	c-2,1-4,2-6.1,3.1c-1.3,0.7-1.8,1.8-1.1,2.7c0.4,0.6,0.8,1.2,1.4,1.6c0.4,0.3,1.2,0.5,1.6,0.3c0.3-0.2,0.3-1,0.3-1.5
	c0-0.3-0.3-0.6-0.5-1C244,286.1,245.4,285.4,247,284.5z M236.2,293.5c0.3,0.4,0.5,0.6,0.6,0.8c0.7,1.8,1.4,3.6,2.2,5.4
	c0.2,0.5,0.9,0.9,1.4,0.9c0.7,0,1-0.7,0.8-1.4c-0.5-1.5-1.1-3.1-1.7-4.6c-0.6-1.7-2.3-2.9-2.3-4.9c0-0.3-0.9-0.8-1.4-0.8
	c-0.4,0-1,0.4-1.1,0.8c-0.5,1.6-1,3.2-1.3,4.9c-0.1,0.5,0.6,1.1,0.9,1.6c0.4-0.3,1-0.5,1.3-0.9C235.9,294.8,235.9,294.2,236.2,293.5
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity62 ? this.state.opacity62 : 1}
              fill={this.state.color62 ? this.state.color62 : 'transparent'} onClick={() => {
          this.setState({ color62: color, opacity62: opacity });
        }} d="M47.3,428.6c-2.9,3.2-5.8,6.4-8.7,9.6c-0.7,0.8-1.6,1.2-2.7,1.2c-4.6-0.2-9.2-0.3-13.9-0.4
	c-0.8,0-1.7,0.1-2.5,0.3c-1,0.1-1.4-0.2-1.3-1.2c0.1-2.6,0.2-5.1,0.2-7.7c0-1.4-0.2-2.9-0.2-4.3c0-3.5,0.2-7.1,0.2-10.6
	c0.1-4.9,0.2-9.8,0.2-14.7c0-1.1,0.6-1.6,1.4-1.6c0.9,0.1,1.5-0.4,2.2-0.6c1.9-0.5,3.9-0.9,5.8-1.4c1.9-0.5,3.8-0.9,5.7-1.4
	c1.2-0.3,1.7-0.1,2.1,1.1c1.6,5.5,3,11,4.7,16.5c0.4,1.5,1.4,2.9,1.9,4.4c0.9,3,1.5,6.1,2.3,9.1c0.2,0.7,0.5,1.4,1.4,1.3
	c0.3,0,0.7,0.1,1,0.2C47.2,428.4,47.2,428.5,47.3,428.6z M27.4,418.5c1.3,0.3,1.5-0.4,1.9-1c0.5-1,1-2,1.6-2.9
	c0.6-0.9,1.5-0.8,2.3-0.3c1.1,0.7,1.6,1.6,1,2.4c-1.3,1.8-2.7,3.4-4.1,5.1c-0.7,0.8-1.5,1.5-2.2,2.4c-0.2,0.3-0.3,0.9-0.1,1.2
	c0.3,0.4,0.8,0.8,1.2,0.9c1.7,0.3,3.5,0.5,5.3,0.5c0.7,0,1.5-0.3,2.2-0.5c0.1,0,0.1-0.8,0-1c-0.3-0.3-0.7-0.4-1.2-0.5
	c-1.4-0.3-2.8-0.6-4-0.9c1.6-2.1,3.2-4.2,4.8-6.2c0.7-1,0.7-2.1,0.2-3.2c-0.8-1.6-3.1-2.8-5.1-2.6c-0.8,0.1-3.4,2.6-3.6,3.5
	C27.5,416.4,27.5,417.4,27.4,418.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity63 ? this.state.opacity63 : 1}
              fill={this.state.color63 ? this.state.color63 : 'transparent'} onClick={() => {
          this.setState({ color63: color, opacity63: opacity });
        }} d="M242.7,416.3c1.3,0.1,2.7,0.2,4.1,0.3c1.8,0.1,3.6,0.1,5.4,0.2c1.9,0,3.7,0.1,5.6,0.1c1.6,0,3.3-0.1,4.9-0.1
	c4.5,0,9,0,13.5-0.1c3,0,5.9-0.2,8.9-0.3c0.9,0,1.6,0.2,2.3,1c2.1,2.4,4.5,4.6,6.5,7.2c3.5,4.5,8.2,7.3,13,9.9
	c0.4,0.2,0.9,0.2,1.4,0.4c0.4,0.2,0.8,0.4,1.1,0.6c0,0.1,0,0.2,0,0.3c-0.9,0-1.8-0.1-2.7,0c-3.1,0.3-6.2,0.7-9.2,0.9
	c-2.8,0.2-5.6,0.1-8.4,0.2c-1.9,0-3.7,0.1-5.6,0.2c-1.6,0.1-3.2,0.3-4.8,0.4c-4.5,0.3-8.9,0.5-13.4,0.8c-2.9,0.2-5.8,0.3-8.7,0.5
	c-0.8,0-1.2-0.1-1.7-0.8c-0.9-1.1-2-2.1-2.8-3.2c-1.8-2.6-3.5-5.3-5.1-8.1c-1-1.6-1.7-3.4-2.5-5.1
	C243.8,419.7,243.3,418.1,242.7,416.3z M278.3,429.5c1.6-2.9-0.3-4.5-2.2-6c-0.4,0.2-0.9,0.5-1.3,0.6c-2.2,0.1-3.9,2.6-3,4.7
	c0.5,1,0.5,1.9,0.2,2.9c-0.5,1.7,0.4,2.9,2,3.5c1.3,0.5,2.3-0.1,3.4-0.4c1.1-0.3,2.2-0.8,2.6-1.9c0.4-1.2-0.3-2.2-1.3-3
	C278.5,429.8,278.4,429.7,278.3,429.5z M269.2,431.8c0.2,0,0.3,0,0.5-0.1c0.4-0.3,0.8-0.6,1.2-1c-0.3-0.3-0.5-0.8-0.8-0.9
	c-1.4-0.5-1.4-1.5-1.5-2.7c-0.1-1.1-0.4-2.3-0.9-3.3c-0.3-0.6-0.9-0.8-1.7-0.1c-2,1.9-4,3.8-6,5.6c-0.8,0.7,0.1,1.3-0.1,1.9
	c0.3,0.1,0.5,0.3,0.8,0.4c1.6,0.1,3.2,0.2,4.7,0.3c0.7,0,1.1,0.3,1.3,1.1c0.1,0.4,0.8,0.8,1.3,0.9
	C269.2,433.7,268.9,432.6,269.2,431.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity64 ? this.state.opacity64 : 1}
              fill={this.state.color64 ? this.state.color64 : 'transparent'} onClick={() => {
          this.setState({ color64: color, opacity64: opacity });
        }} d="M396.4,6.3c0,1.8,0,3.5,0,5.1c0.1,1.8,0.1,3.5,0.3,5.3c0.3,3,0.7,5.9,1.1,8.9c0.2,1.4,0.7,2.8,1.1,4.2
	c0.4,1.5,0.3,1.6-1.1,2.2c-3.3,1.5-6.6,3.1-9.8,4.7c-2.5,1.2-5,2.4-7.4,3.8c-3.2,1.8-6.2,3.9-9.3,5.8c-0.6,0.4-1.1,0.7-2,1.2
	c-0.8-4.7-2.1-9-5.9-12.2c1.5-1.4,2.8-2.7,4.1-3.9c1.7-1.6,2.9-3.4,3.3-5.7c0.6-3.2-1.1-5.8-4.3-6.8c-0.8-0.3-1.7-0.5-2.6-0.8
	c0.7-0.7,1.3-1.2,1.8-1.8c1.2-1.5,2.6-2.9,3.6-4.5c0.7-1.1,0.9-2.4,1.1-3.7c0.1-0.9,0.5-1.2,1.3-1.2c3.8,0,7.6,0.2,11.5,0.1
	c3.2,0,6.4-0.3,9.6-0.4C393.9,6.4,395,6.4,396.4,6.3z M377.2,28.6c0.2-1.4,1.3-2.6,2.4-3c0.4-0.1,0.7-0.6,1.1-0.9
	c-0.6-0.7-1.2-1.5-1.9-1.9c-0.8-0.4-1.3,0.2-1.3,1.1c0,0.4-0.1,0.8-0.3,1.1c-0.7,1.2-1.6,2.4-2.1,3.7c-0.7,1.7,0.2,4.3,1.5,5.1
	c2,1.2,4.8,0.7,6.3-1.2c1.5-1.9,0.9-3.7-1.3-4.6C380,27.4,378.7,27.7,377.2,28.6z M387.1,26.1c0.1,0.1,0.3,0.1,0.4,0.2
	c0,1.7-0.1,3.5,0,5.2c0,0.5,0.6,1,0.9,1.5c0.4-0.5,1-1,1-1.5c0-2.4-0.1-4.7-0.1-7.1c0-0.6,0-1.3-0.8-1.4c-0.6,0-1.4,0.1-1.8,0.5
	c-0.9,0.8-1.6,1.8-2.3,2.7c-0.2,0.3-0.1,0.9-0.1,1.3c0.5-0.1,1.1-0.1,1.5-0.3C386.4,27,386.7,26.5,387.1,26.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity65 ? this.state.opacity65 : 1}
              fill={this.state.color65 ? this.state.color65 : 'transparent'} onClick={() => {
          this.setState({ color65: color, opacity65: opacity });
        }} d="M74.8,284.5c1.1-0.1,2.2-0.3,3.3-0.4c2-0.3,2.4-0.1,3.4,1.6c2,3.4,3.9,6.8,6.7,9.7c1.1,1.1,2.2,2.2,3.4,3.3
	c0.6,0.5,0.6,0.9,0.2,1.6c-0.7,1.2-1.2,2.5-1.7,3.8c-1.4,3.2-2.7,6.3-4,9.5c-0.7,1.6-1.3,3.2-1.9,4.8c-0.4,1.1-1.3,1.6-2.6,1.1
	c-1.4-0.4-2.8-0.9-3.9-1.7c-3.8-2.6-7.7-5.1-11.3-8c-2.5-2.1-4.8-4.6-6.9-7.1c-2.1-2.5-3.8-5.3-5.7-7.9c-0.7-0.9-1.4-1.9-2.1-2.8
	c-0.5-0.6-0.2-1,0.4-1.2c2.7-1.1,5.5-2.2,8.2-3.2c2-0.7,4-1.3,6.1-1.8c2.8-0.6,5.7-1.1,8.5-1.6C74.8,284.3,74.8,284.4,74.8,284.5z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity66 ? this.state.opacity66 : 1}
              fill={this.state.color66 ? this.state.color66 : 'transparent'} onClick={() => {
          this.setState({ color66: color, opacity66: opacity });
        }} d="M252,219.4c0.8-2.1,1.7-4.1,2.4-6.2c0.1-0.3,0-0.7,0.1-1.1c0.4-3,0.9-6,1.3-9c0-0.2,0.2-0.3,0.5-0.8
	c0.6,1.2,1.1,2.2,1.5,3.3c0.3,1,0.7,2,1.9,2.1c1.2,0.1,1.8-0.6,2.5-1.4c1.9-2.3,1.2-4.9,1.5-7.4c0.2-1.8,0.5-3.6,0.8-5.4
	c0.1-0.6,0.5-0.9,1.2-0.8c0.7,0.1,1.7,0.2,2-0.1c1-1.3,2.5-2.2,2.8-4.1c0.3-2.4,1.2-4.7,1.7-7.1c0.4-2,0.7-4.1,0.9-6.1
	c0.4-3.7,0.6-7.4,0.9-11.2c0-0.3,0-0.5-0.1-0.8c-0.3-1.3-0.6-2.6-0.9-3.9c0-0.2,0.2-0.7,0.3-0.6c0.5,0.1,1.1,0.2,1.5,0.5
	c0.3,0.2,0.4,0.7,0.5,1c0.5,1.6,1,3.2,1.6,4.8c1.1,2.8,2.4,5.6,3.6,8.4c0.7,1.6,1.1,3.2,1.8,4.8c0.8,2,1.7,4,2.6,5.9
	c2.4,4.7,4.9,9.3,7.3,13.9c0.2,0.4,0.5,0.7,0.8,1c0.8,1,0.8,1.6-0.1,2.6c-0.2,0.2-0.2,0.7-0.4,1.2c-1.3,0.6-2.8,1.2-4.2,1.9
	c-3.8,2.1-7.5,4.3-11.4,6.3c-1.6,0.8-3.4,1.4-5.1,2c-0.7,0.3-1.4,0.7-2.2,1c-1.3,0.6-2.5,1.2-3.9,1.6c-1.8,0.4-3.5,1.5-5.3,2.1
	c-2.1,0.7-4.2,1.1-6.3,1.5C253.6,219.4,252.7,219.4,252,219.4z M275.3,195.4c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,1.6-0.4,3.1-0.4,4.7
	c0,1,0.1,2,0.4,2.9c0.3,0.9,1.3,0.9,1.7,0c0.2-0.5,0.3-1,0.3-1.5c0-1.3-0.1-2.5,0-3.8c0.1-1.4,0.4-2.8,0.6-4.3c0-0.3-0.2-0.9-0.5-1
	c-0.5-0.3-1.2-0.3-1.5-0.4c-0.8,0.5-1.4,0.9-2,1.2c-2.1,0.9-3.2,2.5-3,4.6c0.8,0.3,1.5,0.2,2-0.6
	C273.8,196.6,274.6,196.1,275.3,195.4z M282.2,195.8c0.1,0,0.3,0.1,0.4,0.1c0.2,1.7,0.2,3.4,0.6,5c0.2,0.8,0.9,1.5,1.4,2.3
	c0.3-0.7,0.6-1.4,0.9-2.2c0,0,0-0.1,0-0.1c-0.1-2.3-0.2-4.7-0.4-7c0-0.5-0.2-1.3-0.5-1.4c-0.6-0.2-1.4-0.2-1.9,0.1
	c-1.1,0.8-2.2,1.8-3.2,2.7c-0.7,0.6-0.5,1.7,0.3,2.2c0.8,0.5,1.2-0.2,1.6-0.7C281.7,196.5,282,196.2,282.2,195.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity67 ? this.state.opacity67 : 1}
              fill={this.state.color67 ? this.state.color67 : 'transparent'} onClick={() => {
          this.setState({ color67: color, opacity67: opacity });
        }} d="M255.5,386.1c0.7,0.4,1.3,0.7,2,1c0,0,0.1,0,0.2-0.1c0.5-1.7,2-1.7,3.2-1.6c4,0.1,8.1,0.5,12.1,0.7
	c1,0.1,2-0.2,3-0.2c0.4,0,1,0.4,1,0.8c0.2,0.9,0.1,1.8,0.2,2.7c0,0.2,0,0.5,0,0.7c0.3,3,0.6,6,0.8,9.1c0,0.2,0.1,0.5,0,0.7
	c-0.7,1.6,0.1,2.9,0.8,4.1c0.1,0.1,0.2,0.3,0.2,0.4c0.8,3.4,2.7,6.3,4.2,9.3c0.1,0.1,0,0.2,0.1,0.6c-13.7,0.1-27.3,0.8-41.1,0.1
	c-0.1-3.3-0.4-6.6,1-9.6c0.2-0.4,0.3-0.8,0.4-1.3c0.8-4.7,3.8-8.1,6.8-11.6c0.3-0.3,0.7-0.5,1.1-0.8c1.7-1.1,3.1-2.3,3.5-4.4
	C255.2,386.7,255.3,386.5,255.5,386.1z M268,399.5c-1,1.9-1.9,3.5-2.8,5.1c-0.1,0.2-0.5,0.3-0.8,0.3c-0.6,0-1.1,0-1.7-0.1
	c-0.7-0.1-1.3,0.1-1.3,0.9c0,0.8,0.3,1.3,1.2,1.3c0.4,0,0.8,0,1.3,0c-0.2,0.9-0.5,1.5-0.4,2.2c0,0.4,0.3,1,0.7,1.2
	c0.2,0.2,0.9,0,1.1-0.3c0.4-0.3,0.7-0.8,0.7-1.3c0-1.3,0.7-1.5,1.8-1.4c0.5,0.1,0.9,0.2,1.4,0.3c1.5,0.4,2.1,0.1,2.9-1.4
	c-1.1-1.5-3.1-0.5-4.5-1.5c0.3-0.6,0.6-1.2,0.9-1.8c0.7-1.3,1.5-2.5,2.2-3.8c0.6-1.3,0-2.1-1.5-2.1c-0.6,0-1.1,0-1.7-0.1
	c-2.1-0.2-4.2-0.5-6.3-0.7c-1.1-0.1-1.5,0.6-1.2,1.6c0.3,0.9,0.6,1.7,1,2.6c0.3,0.7,0.6,1.4,1.5,1c0.9-0.3,0.7-1,0.5-1.7
	c-0.3-0.8,0.1-1.3,0.9-1.2C265.3,399,266.5,399.3,268,399.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity68 ? this.state.opacity68 : 1}
              fill={this.state.color68 ? this.state.color68 : 'transparent'} onClick={() => {
          this.setState({ color68: color, opacity68: opacity });
        }} d="M97.8,6c-0.8,1.2-1.2,2.1-1.9,2.9c-2.4,2.7-4.9,5.3-7.3,8c-2.9,3.3-6.1,6.2-9.6,8.9c-2.1,1.7-4.4,3.2-6.5,4.9
	c-1.8,1.4-3.4,3-5.2,4.4c-1.4,1-2.9,1.9-4.3,2.8c-2.5,1.5-5.1,3-7.8,4.7c-0.3-1.3-0.8-2.6-1-4c-0.3-3.5-0.5-7-0.8-10.5
	c-0.2-2.2-0.3-4.5-0.3-6.7c0-1.3,0.4-2.6,0.5-3.9c0.4-3.2,0.8-6.4,1.2-9.6c0.1-0.8,0.4-1.1,1.3-1.1c4.3,0,8.5-0.2,12.8-0.3
	c2.4-0.1,4.9-0.1,7.3-0.2C77.7,6.2,79.3,6,80.9,6c2.5,0,5.1,0,7.6,0c2.5,0,5.1,0,7.6,0C96.6,6,97,6,97.8,6z M66.2,23.3
	c0,0.1-0.1,0.3-0.1,0.4c0.4,0.5,0.6,1.3,1.1,1.6c2.4,1.3,5.4-0.6,5.5-3.3c0.1-2,0.2-4,0.4-6c0.1-1-0.3-1.5-1.1-1.9
	c-3-1.4-6.9,0.3-7.9,3.4c-0.3,0.9-0.4,1.8,0.5,2.5c0.9,0.8,1.7,1.2,2.9,0.6c0.9-0.5,1.8-0.8,3.1-1.4c0,1.2,0.1,2.1,0,3.1
	c-0.1,1.1-1.2,1.8-1.9,1.6C67.8,23.6,67,23.5,66.2,23.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity69 ? this.state.opacity69 : 1}
              fill={this.state.color69 ? this.state.color69 : 'transparent'} onClick={() => {
          this.setState({ color69: color, opacity69: opacity });
        }} d="M204.2,327.2c0.4,2.3,0.4,4.6,1.1,6.6c0.7,2,2.2,3.7,3.4,5.6c-0.1,0.1-0.4,0.3-0.8,0.4
	c-1.5,0.3-2.9,0.7-4.4,0.9c-2.6,0.4-4.8,1.7-6.8,3.2c-3.6,2.6-6.5,5.7-9.1,9.3c-2.2,3-3.8,6.2-5,9.6c-0.7,1.9-0.8,3.9-1.3,5.8
	c-0.5,1.8-1.4,3.5-1.9,5.2c-0.5,1.9-0.9,3.9-1.2,5.9c-0.5,3.8-0.9,7.6-1.4,11.3c-0.4,3.4,0.2,2.8-3.2,2.6c-4.4-0.2-8.7-0.9-13-1.6
	c-3.3-0.6-6.5-1.9-8.9-4.3c-0.7-0.7-1.1-2.2-1-3.3c0-1.8,0.5-3.6,0.8-5.4c0.1-0.3,0.6-0.7,0.9-0.6c4.7,0.4,9.2-0.3,13.7-1.2
	c1.2-0.3,2.5-0.1,3.7-0.4c2.1-0.7,3.7-2.2,4.9-4.1c2.3-3.4,2.4-7.2,1.9-11.2c-0.2-1.7,0.5-3.3,1.1-4.8c1.2-3,2.6-6,3.9-9
	c1.9-4.2,3.7-8.4,5.6-12.6c0.9-1.9,1.8-3.7,2.8-5.6c0.5-1,1.2-1.7,2.5-0.9c0.4,0.3,1.2,0,1.8-0.1c2.5-0.3,5-0.7,7.5-1
	C202.6,327.6,203.4,327.3,204.2,327.2z M167.4,382.5c0.1,0.2,0.2,0.4,0.3,0.6c-0.7,0.5-1.3,1.2-2.1,1.6c-0.7,0.4-1.1,0.9-0.7,1.5
	c0.2,0.4,1,0.5,1.6,0.5c0.7,0,1.4-0.3,2.1-0.2c0.4,0.1,1,0.6,1.1,1c0,0.4-0.5,1.1-0.8,1.2c-1,0.2-2.1,0.4-3.1,0.2
	c-0.9-0.1-1.5,0.1-1.7,0.8c-0.1,0.4,0.6,1.1,1,1.3c1.7,0.7,3.5,0.3,5.1-0.5c1.1-0.6,2.2-1.5,2.1-3c-0.1-1.4-1-2.5-2.4-3.1
	c-0.2-0.1-0.5-0.3-0.7-0.4c0.5-1.8,0.5-2-0.3-2.7c-1.4-1.2-5.3-0.9-6.5,0.5c-0.2,0.2-0.2,0.6-0.1,0.9c0,0.1,0.5,0.3,0.7,0.3
	c0.9-0.1,1.9-0.4,2.8-0.5C166.2,382.6,166.8,382.5,167.4,382.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity70 ? this.state.opacity70 : 1}
              fill={this.state.color70 ? this.state.color70 : 'transparent'} onClick={() => {
          this.setState({ color70: color, opacity70: opacity });
        }} d="M129.2,226c0.1,3.6,0.2,7,0.4,10.4c0.1,1.6,0.4,3.2,0.6,4.9c0.3,1.8,0.7,3.7,0.9,5.5c0.2,1.8,0.9,3.3,1.8,4.9
	c1.3,2.4,2.4,4.8,3.7,7.5c-3.8,2.8-7.5,5.9-11.5,8.4c-4,2.6-8.3,4.7-12.6,7c-0.6-0.9-1-1.5-1.4-2.2c-0.9-1.7-1.6-3.5-2.8-5
	c-1-1.4-1.6-2.9-1.8-4.5c-0.5-3.1-0.9-6.3-0.3-9.5c0.2-0.9,0.5-1.9,0.8-2.7c1.9-4.5,3.2-9.2,6.1-13.2c2.2-3.1,4.3-6.2,7.7-8.2
	c1.4-0.8,2.7-2,4.6-1.7c1,0.1,2.1-0.8,3.1-1.3C128.7,226.2,128.8,226.2,129.2,226z M116.4,262.7c1.7,0.1,3-0.8,4.2-1.8
	c0.4-0.3,0.5-1.2,0.6-1.8c0.1-0.9-0.1-1.9,0.2-2.8c0.3-1,1-1.9,1.4-2.9c0.5-1.5,0.4-2.4-0.8-2.9c-0.9-0.4-1.7-0.9-2.6-1.4
	c-2-1.1-5.9,1.6-5.3,3.8c0.3,1.1,0.3,2.3-0.7,3.2c-1.4,1.2-1.2,2.9-0.6,4.3C113.3,262.1,114.9,262.5,116.4,262.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity71 ? this.state.opacity71 : 1}
              fill={this.state.color71 ? this.state.color71 : 'transparent'} onClick={() => {
          this.setState({ color71: color, opacity71: opacity });
        }} d="M219.7,348.6c-2.7,0.1-5.3,0-7.9,0.4c-2.2,0.3-4.3,1.1-6.3,1.9c-1.9,0.8-3.6,2.1-5.3,3.2
	c-3.2,2.2-5,5.3-5.7,9.1c-0.6,3.6-0.4,7,2.3,9.6c1,0.9,2.3,1.5,3.5,2.1c0.2,0.1,0.5,0,0.7,0c0.9-0.2,1.9-0.4,2.8-0.4
	c0.3,0,0.7,0.7,0.8,1.2c0.2,1,0.4,2,0.5,3c0.1,0.9,0.2,1.8,0,2.6c-0.8,3.6-2.3,6.9-5.2,9.3c-1.8,1.5-4.1,1.9-6.3,2.2
	c-3.4,0.4-6.8,0.5-10.2,0.8c-1.5,0.1-3,0.3-4.6,0.4c0.2-2.3,0.4-4.4,0.6-6.5c0.6-7.2,2.5-14.1,4.4-21c1-3.6,2.2-7.1,4.2-10.1
	c1.9-2.9,4.2-5.7,6.7-8.2c1.9-1.9,4.3-3.2,6.5-4.7c0.4-0.3,1-0.4,1.5-0.5c2.4-0.6,4.8-1.2,7.2-1.7c0.5-0.1,1.5-0.1,1.7,0.3
	c1.8,2.9,4.6,4.7,7.6,6.2c0.3,0.2,0.6,0.4,0.9,0.6C219.8,348.4,219.7,348.5,219.7,348.6z M197.4,385.4c-2.8-1.7-2.8-1.7-2.3-4.6
	c0-0.2,0-0.3,0.1-0.5c0.1-0.9,0.4-1.6-0.3-2.5c-0.7-0.9-1.6-1.2-2.5-0.4c-1.8,1.7-3.5,3.5-5.4,5.1c-0.9,0.8-1.3,1.5-0.8,2.5
	c0.1,0.3,0.6,0.6,1,0.6c1,0,2.1-0.2,3.1-0.1c2.4,0.2,2.4,0.3,1.8,2.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.7,0.9,1.7,1.6,1.7
	c1,0,1-0.6,1-1.4c0-0.5,0.2-1.1,0.2-1.6c0-0.7,0.2-1,1-1.1C196.2,386.1,196.7,385.8,197.4,385.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity72 ? this.state.opacity72 : 1}
              fill={this.state.color72 ? this.state.color72 : 'transparent'} onClick={() => {
          this.setState({ color72: color, opacity72: opacity });
        }} d="M270.3,133.3c-0.6-2.1-1.2-4.2-1.8-6.3c-0.2-0.6-0.3-1.2-0.5-1.9c-0.6-2.5-1.3-4.9-1.9-7.4
	c-0.1-0.3,0.3-0.8,0.5-1c2.4-1.2,4.9-2.4,7.3-3.5c4.6-2.1,9.3-4,13.9-6.1c4.3-1.9,8.5-3.9,12.8-5.9c0.5-0.2,1.1-0.4,1.9-0.8
	c4,8.7,5.2,17.9,6.4,27.4c-6.6,0.2-13-0.2-19.5,0.8C283,129.8,276.8,131.6,270.3,133.3z M286.2,123.1c-0.1-0.1-0.1-0.3-0.2-0.4
	c0.4-0.6,0.7-1.3,1.2-1.9c1.2-1.7,1.3-4.1,0.2-5.1c-1.1-1-2.5-1.7-3.9-1.2c-1.6,0.5-2.6,1.8-3,3.5c-0.2,0.8,0.2,1.4,0.9,1.4
	c0.4,0,0.9-0.5,1.3-0.9c0.1-0.1,0-0.3,0.1-0.5c0.2-1.1,1-1.6,2-1.4c1.3,0.3,1.9,0.9,1.4,2.1c-0.5,1.2-1.4,2.3-2.2,3.4
	c-0.6,0.7-1.3,1.3-1.9,1.9c-0.2,0.3-0.3,0.7-0.5,1c0.4,0.1,0.8,0.4,1.2,0.3c1.4-0.1,2.8-0.3,4.2-0.5c1.2-0.2,2.3-0.4,3.6-0.6
	c-0.5-1.2-1.1-1.6-2-1.4C287.8,122.8,287,123,286.2,123.1z M299.4,112.7c-2.1,0.2-4.4,0.5-6.7,0.8c-0.3,0.1-0.7,0.7-0.8,1.2
	c-0.2,0.5-0.1,1.1-0.2,1.6c-0.8,3.1-0.9,3.3,2.3,3.4c0.7,0,1.4,0.3,2,0.6c0.3,0.2,0.4,0.7,0.5,1c-0.6,0.2-0.7,1.2-1.6,0.8
	c-0.4-0.2-1-0.1-1.4,0c-0.2,0.1-0.4,0.8-0.3,0.9c0.4,0.7,2.5,1,3.2,0.6c1.3-0.7,2-2.2,1.6-3.4c-0.6-1.7-2-2.4-3.7-2.5
	c-0.7-0.1-1.1-0.3-1-1c0.1-0.7,0.2-1.3,1-1.4c1.8-0.2,3.7-0.4,5.5-0.7c0.2,0,0.6-0.2,0.7-0.3c0.1-0.2-0.1-0.5-0.3-0.7
	C300,113.4,299.7,113.1,299.4,112.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity73 ? this.state.opacity73 : 1}
              fill={this.state.color73 ? this.state.color73 : 'transparent'} onClick={() => {
          this.setState({ color73: color, opacity73: opacity });
        }} d="M293,161.2c0.1,0.3,0.2,0.6,0.3,0.9c0.3,3.9,2.1,7.2,4,10.5c1.1,2,2,4.1,3.1,6.1c2.2,3.8,3.4,8.2,6.4,11.6
	c1.5,1.7,2.8,3.6,4.8,4.9c1.8,1.1,3.2,2.9,4.7,4.3c0.3,0.3,0.6,0.6,1,0.9c1.8,1.2,1.6,0.9,0.9,2.8c-1.2,3.3-2.5,6.5-3.6,9.7
	c-0.4,1-0.6,2.1-0.7,3.1c-0.2,1.6-1,2-2.3,1c-1.1-0.8-2-1.8-3-2.7c-1.4-1.3-2.8-2.5-4.1-3.9c-2.4-2.4-4.8-4.8-7-7.3
	c-0.9-0.9-1.4-2.2-2-3.3c-1.6-2.6-3.4-5.2-4.7-7.9c-2.2-4.7-4.1-9.5-6.1-14.3c-0.2-0.5-0.1-1.2,0.2-1.7c1.8-3.8,3.4-7.7,6-11.2
	c0.8-1.1,1.3-2.4,2-3.6C292.8,161.1,292.9,161.2,293,161.2z M298.9,195c-0.6,1.4,1,1.5,1.8,2.4c0.1-0.6,0.1-1,0.2-1.3
	c0.1-0.9,0.2-1.7,1.5-1.3c0.4,0.1,0.9-0.1,1.4-0.2c0.7-0.2,0.9-0.6,0.5-1.3c-0.5-0.9-1.3-0.7-2.1-0.7c-0.5,0-1.1,0.1-1-0.7
	c0.2-1.1,0.3-2.2,0.5-3.3c0.3-1.7-0.7-2.6-2.3-2c-0.4,0.1-0.8,0.1-1.2,0.1c-1.4,0.1-2.7,0.2-4.1,0.3c0.3,2.7,0.8,3.3,2.5,3.4
	c-0.1-0.4-0.3-0.7-0.4-1c0-0.3,0.1-0.9,0.2-0.9c0.9-0.1,1.8-0.2,2.7,0.1c0.3,0.1,0.5,1,0.5,1.5c0,0.6,0,1.3-0.2,1.9
	c-0.1,0.4-0.5,0.7-0.8,0.9c-0.5,0.2-1,0.2-1.5,0.3c0,0.1-0.1,0.3-0.1,0.4C297.5,194,298.1,194.5,298.9,195z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity74 ? this.state.opacity74 : 1}
              fill={this.state.color74 ? this.state.color74 : 'transparent'} onClick={() => {
          this.setState({ color74: color, opacity74: opacity });
        }} d="M295.4,6.5c17.6,0.1,35,0.2,52.4,0.3c0,0.1,0.1,0.3,0.1,0.4c-0.5,0.3-1,0.5-1.5,0.9c-1.7,1.3-3.4,2.5-5.1,3.8
	c-2.7,2-5.3,4-8,6c-2.7,2.1-5.3,4.3-8,6.4c-1.9,1.5-3.9,2.8-5.9,4.1c-3,2.1-6,4.1-9.1,6.1c-0.3,0.2-0.6,0.3-1.1,0.5
	c0.3-3.2,0-6-2.6-8.2c1.2-0.9,0.4-1.5-0.2-2.2c-1.8-2.3-3.6-4.7-5.1-7.2c-2.1-3.4-3.9-6.9-5.8-10.3C295.6,7,295.6,6.9,295.4,6.5z
	 M314.8,20c-0.1,0.1-0.2,0.2-0.2,0.4c0.8,0.8,1.5,1.5,2.3,2.3c0.1,0.1,0.3,0.1,0.5,0.1c1.3-0.5,2.8-0.8,4-1.5
	c1.1-0.7,2.2-1.8,1.3-3.3c-0.5-0.8-1.3-1.6-2.1-2.1c-0.7-0.5-1.1-0.8-1.1-1.8c0-1.1-0.5-1.9-1.7-2.3c-1.7-0.6-2.7,0.5-3.9,1
	c0.5,1.2,1,1.5,1.9,1c0.6-0.4,1.4-0.7,1.8,0c0.2,0.4-0.2,1.2-0.5,1.7c-0.3,0.5-0.9,0.9-1.5,1.5c0.7,0.4,1.1,0.7,1.7,1
	c0.3-0.2,0.5-0.5,0.8-0.6c0.8-0.3,1.6-0.2,2.2,0.5c0.3,0.3,0.6,1.1,0.4,1.2c-0.6,0.6-1.3,1.2-2.1,1.6c-0.6,0.3-1.4,0.5-2.1-0.3
	C316.1,20.1,315.4,20.1,314.8,20z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity75 ? this.state.opacity75 : 1}
              fill={this.state.color75 ? this.state.color75 : 'transparent'} onClick={() => {
          this.setState({ color75: color, opacity75: opacity });
        }} d="M138.7,161.2c0.2,1.8,0.1,3.7,0.7,5.4c0.7,1.7,2.2,3.1,3.4,4.7c0.3,0.4,0.9,0.8,0.9,0.9c-0.4,0.7-0.9,1.3-1.4,2
	c-0.2,0.3-0.6,0.4-0.7,0.7c-1.2,2.8-2.5,5.4-4.9,7.4c-2.2,1.8-4.6,3.6-6.9,5.3c-2.2,1.7-4.4,3.4-6.7,5c-0.9,0.7-2,1.2-2.9,1.9
	c-1,0.7-2,1.6-3,2.3c-1.7,1.3-3.4,2.6-5.1,3.9c-0.4,0.3-0.6,0.9-0.9,1.3c-0.2-0.1-0.3-0.2-0.5-0.3c2.7-6.2,3.6-12.6,3.4-19.3
	c-0.2-6.7-0.8-13.1-4.5-18.6c0.4-0.6,0.5-1,0.8-1.2c0.2-0.2,0.5-0.5,0.8-0.6c0.8-0.1,1.7-0.1,2.5-0.1c2.7,0.1,5.5,0.4,8.2,0.3
	c4.2-0.2,8.4-0.7,12.6-1.1C135.9,161.1,137.4,161.2,138.7,161.2z M130.3,171.5c0-0.4,0.1-1-0.1-1.2c-0.6-0.4-1.3-0.9-2-0.9
	c-3.8-0.2-7.2,2.6-8.3,6.2c-0.3,0.9-0.8,1.8-0.7,2.6c0.3,2.4,2.5,4.1,5,4.1c0.7,0,1.4-0.1,2.1-0.2c2.2-0.2,3-1.2,3-3.7
	c0-1.7-1.3-2.9-3.1-3c-1.3,0-2.7,0-4.1,0c0.7-1.5,1.7-2.9,3.3-3.8C126.9,170.9,128.4,170,130.3,171.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity76 ? this.state.opacity76 : 1}
              fill={this.state.color76 ? this.state.color76 : 'transparent'} onClick={() => {
          this.setState({ color76: color, opacity76: opacity });
        }} d="M113.9,277c0-0.3,0.1-0.6,0.1-1c0.2-0.1,0.4-0.1,0.6-0.2c2.9-1.5,5.9-3,8.8-4.6c2.7-1.5,5.2-3.2,7.8-4.9
	c1.7-1.1,3.2-2.5,4.7-3.9c1.3-1.1,1.7-1.1,2.7,0.2c2.6,3.5,5.4,6.9,8.6,9.8c2.7,2.4,5.5,4.8,8.8,6.3c0.5,0.2,1.1,0.4,1.8,0.6
	c-0.2,0.6-0.4,1.1-0.6,1.6c-0.9,2.6-2.2,5.1-2.7,7.7c-0.3,1.6-1.1,3-1.5,4.5c-0.3,0.9-0.8,1.8-1.3,2.7c-0.4,0.7-1.6,0.9-2.7,0.4
	c-2-0.9-4-1.9-6.1-2.8c-2.9-1.2-5.9-2.1-8.8-3.3c-2.3-0.9-4.7-1.9-6.8-3.1c-2-1.1-3.7-2.9-5.7-4c-2.6-1.4-4.5-3.5-6.6-5.4
	C114.7,277.5,114.3,277.3,113.9,277z M145.8,278c0.1-1.1-0.2-1.7-1.1-1.6c-1.2,0.1-2.4,0.3-3.4,0.9c-1.9,1-2.9,2.9-3.7,4.9
	c-0.6,1.6,0,4.3,1.1,5.1c1.6,1.2,3.4,1.6,5.3,1.5c2.5-0.2,4.1-3.1,2.8-5.1c-0.8-1.3-3.3-1.9-5.1-1.2c-0.6,0.2-1.2,0.5-1.9,0.7
	C139.6,280.6,142.7,277.9,145.8,278z M134.3,279.1c-0.1,0.6,0,1.4-0.4,1.9c-0.3,0.4-1.2,0.5-1.8,0.7c-0.5,0.1-1.1-0.1-1.5,0
	c-0.3,0.1-0.7,0.4-0.8,0.7c-0.1,0.2,0.3,0.6,0.5,0.8c2.3,1.6,5.7,0.3,6.3-2.4c0.3-1.4,0.5-2.8,0.7-4.2c0-0.1,0-0.3-0.1-0.5
	c-0.5-0.9-1-2-1.7-2.8c-0.5-0.5-1.3-0.8-2.1-1c-2.7-0.5-4.3,1.2-5.6,3.2c-0.8,1.2,0.5,3.4,2,3.4
	C131.1,279.1,132.5,279.1,134.3,279.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity77 ? this.state.opacity77 : 1}
              fill={this.state.color77 ? this.state.color77 : 'transparent'} onClick={() => {
          this.setState({ color77: color, opacity77: opacity });
        }} d="M69,389.5c-1.1,1.5-2.1,3-3.2,4.4c-0.9,1.1-1.7,2.3-2.7,3.4c-0.5,0.6-0.5,1.1,0.2,1.5c1.3,0.9,2.7,1.8,4,2.7
	c0.6,0.4,1.1,0.8,1.7,1.3c-0.6,0.8-1.2,1.5-1.7,2.2c-1.6,1.9-3.3,3.8-4.9,5.8c-1.9,2.3-3.7,4.5-5.5,6.9c-2,2.6-4.5,4.8-6.7,7.3
	c-0.4,0.4-1,0.9-1.6,1c-1.6,0.3-1.7,0.1-2.1-1.5c-0.8-3.6-1.5-7.2-3.4-10.4c-0.6-1-0.7-2.2-1-3.3c-1.5-5.1-3.1-10.2-4.6-15.3
	c-0.1-0.3-0.1-0.5-0.1-0.7c10.5-1.9,20.9-3.9,31.3-5.8C68.9,389.2,69,389.3,69,389.5z M56.5,407.1c-1.9-0.3-1.7-1.6-1.5-2.8
	c0.2-1.1,0.5-2.2,0.4-3.3c0-0.5-0.5-1.2-1-1.5c-0.4-0.3-1-0.6-1.6,0c-2,2.1-4.2,4-6.3,6c-0.3,0.3-0.7,0.9-0.6,1.3
	c0.1,0.4,0.8,0.7,1.2,0.8c1.5,0.1,2.9,0.2,4.4,0.1c0.7,0,1.1,0.1,1,0.9c0,0.7,0,1.4,0,2.1c0,0.6,0,1.1,0.8,1.2
	c0.7,0.1,1.3-0.1,1.4-0.9c0.1-0.4,0.1-0.9,0.1-1.3c0-1-0.4-2.1,1.3-2.1C56.2,407.6,56.3,407.4,56.5,407.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity78 ? this.state.opacity78 : 1}
              fill={this.state.color78 ? this.state.color78 : 'transparent'} onClick={() => {
          this.setState({ color78: color, opacity78: opacity });
        }} d="M55.4,354.2c3.2,0.3,5.9-0.8,8.7,0.4c-0.6,1.3-1.1,2.5-1.6,3.7c-0.9,2-1.7,4-2.6,6c-0.8,1.7-1.6,3.4-2.7,4.8
	c-0.7,0.9-2.1,1.2-3.3,1.8c-0.5,0.2-1.1,0.3-1.6,0.4c-0.8,0.1-1.6,0.9-2.3-0.3c-0.1-0.2-0.8,0-1.6,0c-0.2,1.1-0.4,2.4-0.5,3.6
	c-0.2,2-0.8,3.9,0,5.9c0.1,0.1-0.5,0.7-0.8,0.7c-1.6,0.3-3.1,0.6-4.7,0.9c-3.3,0.7-6.7,1.3-10,2c-1.4,0.3-2.8,0.4-4.1,0.7
	c-2.4,0.6-4.8,1.2-7.2,1.9c-1.5,0.4-1.8,0.3-1.7-1.3c0-4.1,0.1-8.3,0-12.4c0-1.3,0.5-1.6,1.5-1.8c2.3-0.3,4.6-0.5,6.8-0.9
	c1.1-0.2,2.1-0.7,3.2-0.9c2.8-0.7,5.6-1.4,8.4-1.8c1.1-0.2,2.4-0.7,3.6,0.3c0.1,0.1,0.8-0.4,1-0.7c1.4-2.2,2.6-4.5,4-6.8
	c1-1.8,2-3.5,3.1-5.2C51.8,353.9,54.6,353.6,55.4,354.2z M25.2,376.6c1.3,0.8,2.3-0.1,3.3-0.6c0.7-0.3,1.6-0.5,2.4-0.6
	c0.3,0,0.8,0.3,0.9,0.6c0.1,0.2-0.2,0.7-0.4,0.9c-0.5,0.5-1,0.9-1.6,1.3c-0.9,0.7-2,1.4-2.8,2.2c-0.3,0.3-0.3,1-0.2,1.5
	c0,0.1,0.7,0.2,1.1,0.3c0.2,0,0.4,0,0.6,0c2.1-0.5,4.4-0.2,6.3-1.6c0.3-0.2,0.5-0.6,0.7-0.8c-0.3-0.1-0.7-0.4-1-0.4
	c-1,0-2,0.1-3.2,0.1c1.3-1.1,2.5-1.9,2.5-3.7c0-1.1-0.4-1.9-1.5-2.2c-2-0.5-5.7,0.9-6.9,2.6C25.3,376.3,25.3,376.5,25.2,376.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity79 ? this.state.opacity79 : 1}
              fill={this.state.color79 ? this.state.color79 : 'transparent'} onClick={() => {
          this.setState({ color79: color, opacity79: opacity });
        }} d="M250.1,438.3c-14,1.6-27.9,0.3-41.9-0.7c0.1-0.2,0.2-0.4,0.3-0.5c3.8-3.3,6.4-7.5,8.8-11.8c1.1-2,2-4.2,2.9-6.3
	c0.3-0.7,0.7-1,1.5-1c1.2,0,2.4-0.3,3.6-0.5c3-0.5,6-1.2,9.1-1.6c1.6-0.2,3.3,0.1,4.8,0.5c0.5,0.1,0.9,1,1.1,1.6
	c0.5,1.2,0.7,2.5,1.2,3.8c0.9,2.2,1.7,4.3,2.8,6.4C246.2,431.6,248.2,435,250.1,438.3z M230.6,431.6c1-0.6,1.8-1.2,2.7-1.7
	c2.3-1.4,3.1-3,2.1-4.9c-0.6-1.2-1.4-2.2-3-2.2c-1.8,0.1-3.5,0.4-4.7,1.9c-0.3,0.3,0.1,1.3,0.4,1.8c0.1,0.2,1,0.1,1.4-0.2
	c0.7-0.3,1.2-0.9,1.8-1.3c0.9-0.5,1.5,0.1,2,0.8c0.5,0.7,0.2,1.2-0.4,1.6c-0.8,0.5-1.6,1.1-2.4,1.6c-1.4,0.9-3.1,1.5-4.1,3
	c-0.6,0.9-0.3,2,0.6,2.1c1.3,0.1,2.5-0.1,3.8,0c1.6,0,3.2,0,4.7,0.1c0.9,0,1.8,0.3,2.7,0c1-0.3,1-0.9,0.1-1.4
	c-0.9-0.4-1.9-0.8-2.9-0.9C233.9,431.6,232.4,431.6,230.6,431.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity203 ? this.state.opacity203 : 1}
              fill={this.state.color203 ? this.state.color203 : 'transparent'} onClick={() => {
          this.setState({ color203: color, opacity203: opacity });
        }} d="M165.1,326c0.9-3.2,1.8-6.2,2.7-9.2c0.6-1.9,1.2-3.9,1.8-5.8c0.7-2.4,1.3-4.9,2-7.4c0.2-0.8,0.4-1.6,0.7-2.4
	c0.1-0.3,0.5-0.7,0.9-0.8c2.9-0.7,5.9-1.3,8.8-2c2.2-0.5,4.3-1,6.4-1.8c2.2-0.8,4.5-1.7,6.6-2.8c2-1,4-2.1,5.3-4.1
	c0.1-0.2,0.6-0.3,1.1-0.5c-0.6,2.4-1.1,4.5-1.7,6.6c-1.1,3.5-2.2,7-3.4,10.5c-0.6,1.6-1.4,3.1-2,4.7c-0.5,1.4-0.8,2.8-1.3,4.2
	c-0.4,1.2-1,2.3-1.5,3.4c-0.9,2.1-1.7,4.2-2.6,6.3c-0.3,0.8-0.8,1.4-2,1.4c-4.3-0.1-8.7-0.1-13-0.1c-0.8,0-1.5,0.4-2.3,0.3
	c-1.1,0-2.2-0.2-3.2-0.3C167.1,326.2,166.1,326.1,165.1,326z M179.7,315.5c-0.7-0.6-1.2-1.2-1.8-1.7c-1-0.8-2.6-1.6-3.6-0.3
	c-0.7,1.1-1.2,2.4-1.7,3.6c-0.1,0.1,0,0.3,0,0.5c0.9,3.1,0.6,3.8,4.4,3.8c0.6,0,1.2-0.2,1.8-0.2c0.4,0,0.9,0.1,1.2,0.3
	c0.9,0.7,1.7,1.3,3,1.3c3.3,0,5.2-1.3,4.9-4.5c-0.1-1.3-0.4-2.5-0.6-3.7c-0.1-0.3-0.5-0.6-0.8-0.7
	C184.5,313.1,181.3,313.5,179.7,315.5z M180.7,309.6c0.3,0,0.6-0.1,0.9,0c1,0.3,1.3-0.2,1.3-1.1c0-1.6,0.6-2.5,2.1-3.1
	c1-0.4,2.3,0,3,0.9c0.6,0.8,0.9,1.5,0,2.4c-0.3,0.4-0.3,1.4-0.1,1.9c0.2,0.3,1.4,0.5,1.6,0.3c0.7-0.8,1.6-1.8,1.7-2.8
	c0.3-2.9-3.4-5.9-6.3-5c-0.9,0.3-1.9,0.7-2.7,1.3c-0.8,0.6-1.3,0.5-1.9-0.2c-1.3-1.6-2.7-0.6-4,0.1c-0.6,0.4-1,1.2-1.5,1.8
	c-0.1,0.1,0,0.2,0,0.4c0,1.1,0,2.3,0,3.4c0,1.2,1,0.6,1.5,0.9c0.1,0,0.5-0.5,0.6-0.8c0.1-1,0-2,0.2-3c0.1-0.5,0.6-0.9,0.9-1.4
	c0.4,0.3,0.9,0.5,1.2,0.9C179.8,307.4,180.2,308.5,180.7,309.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity81 ? this.state.opacity81 : 1}
              fill={this.state.color81 ? this.state.color81 : 'transparent'} onClick={() => {
          this.setState({ color81: color, opacity81: opacity });
        }} d="M150.1,439.2c0.6-0.2,1.4-0.3,1.9-0.8c1.4-1.3,1.3-2.6-0.3-4.2c-1.6-1.6-3.5-1.9-5.6-1.3
	c-0.3,0.1-0.6,0.1-0.9,0c1.4-1.7,3.1-2.4,5.2-2.6c0.7,0,1.3,0.1,2,0.1c0.3,0,0.7-0.1,0.8-0.3c0.1-0.2-0.1-0.6-0.2-0.8
	c-0.6-0.8-3.4-1.5-4.3-1.1c-1.5,0.7-3,1.6-4.5,2.4c-2,1-3,5.1-1.1,6.9c0.3,0.3,0.6,0.7,1.1,1.4c-1.2-0.1-2.1-0.2-2.9-0.2
	c-2.5,0-4.9,0.1-7.4,0c-3.7-0.1-7.4-0.3-11.1-0.3c-3.7,0-7.4,0.2-11.1,0.4c-1,0.1-1.5-0.3-1.5-1.3c0-0.1,0-0.3,0-0.4
	c-1.3-2.5-0.8-5.3-1.1-8c-0.1-1,0.4-1.3,1.2-1.3c2.6,0,5.2,0.1,7.7,0.1c2.5,0,5-0.2,7.5-0.2c2.6,0,5.2,0.1,7.8,0.1c2,0,4,0,6.1-0.1
	c2.3-0.1,4.5-0.2,6.8-0.2c2.9-0.1,5.9-0.1,8.8-0.3c2.7-0.2,5.4-0.6,8.1-0.7c1.5-0.1,3.1,0.1,4.6,0.1c0.5,0,1.1-0.1,1.6-0.1
	c0.5,0,1.2-0.1,0.9,0.8c-0.8,2.7-1.5,5.5-2.8,8c-0.3,0.6-0.6,1.3-0.9,1.9c-0.5,1.1-1.3,1.8-2.6,1.8c-4.5,0-9,0-13.5,0
	C150.3,439.3,150.2,439.2,150.1,439.2z M130.6,433.2c1.1,1,2.1,1.2,3.2,0.1c0.4-0.4,1-0.7,1.6-1c0.3-0.1,0.8-0.2,0.9-0.1
	c0.2,0.2,0.2,0.7,0.2,1c-0.2,1.1-0.5,2.2-0.8,3.4c-0.1,0.2,0,0.6,0.1,0.7c0.5,0.3,1,0.7,1.5,0.7c0.3,0,0.7-0.7,0.8-1.1
	c0.3-1.8,0.4-3.6,0.9-5.4c0.3-1.3,0.6-2.3-1-2.8c-0.1,0-0.1,0-0.2,0c-0.8,0.6-1.6,1.2-2.5,1.7c-1.3,0.7-2.7,1.4-4,2.1
	C131,432.5,130.9,432.9,130.6,433.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity82 ? this.state.opacity82 : 1}
              fill={this.state.color82 ? this.state.color82 : 'transparent'} onClick={() => {
          this.setState({ color82: color, opacity82: opacity });
        }} d="M136.9,113.4c0.2-0.3,0.3-0.6,0.5-0.9c-1.5,0.1-4,0.9-4.2,1.7c-1.2,0.1-2.3,0.1-3.5,0.2c-2,0.2-2.1,0.2-1.9-1.8
	c0.6-5.2,2-10.2,4.8-14.7c1.9-3,3.7-6.1,6.1-8.7c2.6-2.7,5.9-4.8,8.8-7.3c2.2-1.9,4.8-3.1,7.4-4.4c0.9-0.5,1.9-0.8,2.8-1.2
	c0.6-0.3,1,0,1.1,0.7c0.1,0.5,0.1,1,0,1.4c-0.4,2.2-0.7,4.5-1.1,6.7c-0.4,2-0.7,4.1-1.4,5.9c-1.2,2.8-2.7,5.4-4.1,8.1
	c-0.7,1.2-1.3,2.6-2.3,3.6c-1.5,1.6-3.2,3.1-5,4.5c-1.9,1.5-4.1,2.8-6.1,4.2c-0.5,0.4-1.4,0.5-1.2,1.6c0,0.1-0.4,0.3-0.7,0.5
	C137,113.5,137,113.5,136.9,113.4z M139.4,101c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.5,0.2,1,0.5,1.3c0.2,0.3,0.7,0.4,1.1,0.4
	c1-0.1,2-0.4,3-0.4c0.9,0,1.9,0.2,2.8,0.2c0.4,0,0.8-0.4,1-0.7c0.1-0.2,0.1-0.8,0-0.9c-1-0.7-1.7-2-3.2-1c-0.2,0.1-0.7,0-1.2-0.1
	c0.4-0.3,0.5-0.5,0.7-0.7c1.3-1.1,2.8-2.2,3.9-3.4c0.9-1,1.5-2.3,0.8-3.6c-1.3-2.5-5.3-3.5-7.6-1.3c-1,1-1.1,2.1-0.3,3.2
	c0.2,0.3,0.6,0.4,0.9,0.6c0.2-0.3,0.4-0.6,0.5-1c0.3-1.3,1.2-2.2,2.5-2.1c0.6,0.1,1.3,0.6,1.7,1.1c0.2,0.3,0,1.2-0.3,1.6
	c-0.7,0.8-1.6,1.6-2.4,2.3C142.6,98.2,141,99.6,139.4,101z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity83 ? this.state.opacity83 : 1}
              fill={this.state.color83 ? this.state.color83 : 'transparent'} onClick={() => {
          this.setState({ color83: color, opacity83: opacity });
        }} d="M172.5,254.8c0.3,2.3,0.3,4.6,0.9,6.6c0.5,1.7,1.4,3.3,2.6,4.6c1.2,1.3,1.7,2.7,1.4,4.4
	c-0.3,1.4-0.8,2.7-1.2,4.1c-0.7,3-1.3,6.1-2,9.1c-0.3,1.4-0.8,2.7-1.1,4.1c-0.3,1.3-0.3,2.6-0.6,3.9c-0.6,2.2-1.3,4.3-2,6.4
	c-0.1,0.3-0.7,0.5-1.1,0.5c-1.6,0.1-3.2-0.1-4.8,0.2c-3,0.5-5.6-0.7-8.3-1.3c-0.4-0.1-0.9-0.1-1.3,0c-0.6,0.1-1.1-0.6-1-1.3
	c0.7-4.3,2.4-8.2,4-12.2c0.8-1.9,1.2-4,2-5.9c1.8-4.5,3.7-8.9,5.5-13.4c0.7-1.8,1.4-3.6,2.1-5.3c0.4-1,0.8-1.8,2-2.1
	c0.7-0.2,1.1-1,1.7-1.6C171.8,255.3,172.2,255.1,172.5,254.8z M167.4,284.8c0.1-0.3,0.2-0.5,0.3-0.7c0.4-1.2,0.8-2.5,1.2-3.7
	c0.3-0.9,0.9-1.2,1.8-1c1,0.2,1.5,1.3,1.4,2.6c-0.1,1.2-0.7,1.7-1.8,1.8c-0.4,0.1-0.8,0.6-1.2,0.9c0.4,0.3,0.9,1,1.1,0.9
	c0.8-0.4,1.7-0.8,2.2-1.5c0.9-1.3,0.6-2.9,0.3-4.4c-0.2-0.9-0.7-1.8-1.7-2.1c-1.1-0.2-2.3-0.5-3,0.7c-0.7,1.1-1.1,2.2-1.8,3.3
	c-0.2,0.3-0.6,0.4-0.9,0.6c-0.1-0.4-0.3-0.8-0.2-1.2c0.2-1.3,0.5-2.5,0.8-3.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4,0.2-1.1,0.3-1.2,0.6
	c-0.6,1.5-1,3-1.4,4.5c-0.1,0.3,0.2,0.8,0.5,0.9C164.7,283.6,166,284.2,167.4,284.8z M161.8,292.8c1-0.2,1.5-0.5,1.2-1.5
	c-0.3-0.8,0.2-1.1,1-0.8c0.9,0.3,1.7,0.7,2.6,1c0.6,0.2,1.2,0.3,1.8,0.4c0.2,0,0.5-0.3,0.8-0.4c-0.2-0.2-0.3-0.5-0.5-0.7
	c-0.5-0.3-1.1-0.6-1.7-0.9c-1.4-0.7-2.7-1.6-4.2-2.1c-0.6-0.2-1.5,0-2.1,0.3c-0.3,0.1-0.3,1.1-0.2,1.5
	C160.8,290.7,161.3,291.7,161.8,292.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity84 ? this.state.opacity84 : 1}
              fill={this.state.color84 ? this.state.color84 : 'transparent'} onClick={() => {
          this.setState({ color84: color, opacity84: opacity });
        }} d="M14.3,132c-0.2-2.1-0.4-4.1-0.5-6.2c-0.2-7.3-0.4-14.7-0.5-22c-0.1-3,0-6,0-9.1c0-0.8,0.6-1.1,1.5-0.7
	c2.3,1,4.6,1.9,6.7,3.1c4.5,2.5,8.9,5.1,13.3,7.8c1.6,1,3,2.3,4.8,3.8C29.9,115.2,21.2,122.5,14.3,132z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity85 ? this.state.opacity85 : 1}
              fill={this.state.color85 ? this.state.color85 : 'transparent'} onClick={() => {
          this.setState({ color85: color, opacity85: opacity });
        }} d="M102.9,396.2c1.7,9.9,1.1,20,3,30c-2.9,0-5.6,0.1-8.3,0c-2.6-0.1-5.2-0.3-7.8-0.5c-0.5,0-1.1-0.3-1.6-0.3
	c-1,0-2,0.2-3,0.3c-1.5,0-3,0-4.5,0c-2,0.1-4,0.2-6.2,0.3c0.5-0.7,0.9-1.2,1.4-1.8c1.9-2.2,3.4-4.7,5.6-6.6c2.8-2.4,5.5-4.9,8.1-7.5
	c1.9-1.8,3.6-3.9,5.4-5.7c2.1-2.2,4.3-4.3,6.5-6.5C101.9,397.3,102.4,396.7,102.9,396.2z M96,415.9c-0.4,2.2-1.6,3.9-1.2,5.9
	c0.4,0.1,0.7,0.2,0.9,0.3c0.9,0.4,1.2-0.2,1.3-0.8c0.5-1.8,0.9-3.7,1.3-5.5c0.2-0.8,0.3-1.5,0.5-2.6c-1.3,0.9-0.9-0.6-1.6-0.7
	c-0.7,0.5-1.5,1.2-2.4,1.7c-1.2,0.8-2.6,1.4-3.8,2.3c-0.3,0.2-0.4,0.8-0.6,1.2c0.5,0.2,1.1,0.7,1.4,0.5c0.9-0.3,1.7-0.9,2.6-1.4
	C94.9,416.4,95.3,416.2,96,415.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity86 ? this.state.opacity86 : 1}
              fill={this.state.color86 ? this.state.color86 : 'transparent'} onClick={() => {
          this.setState({ color86: color, opacity86: opacity });
        }} d="M436.1,165.3c0,11.8,1.4,23.5,0.4,35.3c-1.6-0.4-2.5-1.3-3.4-2.4c-0.5-0.7-1.1-1.3-1.8-1.8
	c-1.4-1.3-2.8-2.6-4.4-3.7c-2.3-1.6-4.7-3-7-4.5c-2-1.3-3.8-2.7-5.8-3.9c-1.4-0.9-3-1.5-4.5-2.2c-0.3-0.2-0.6-0.5-0.9-0.7
	c0.3-0.3,0.6-0.6,0.9-0.8c4.1-2.7,8.8-4.4,12.6-7.5c0.5-0.4,1.2-0.6,1.8-1c2.5-1.5,5.1-3,7.6-4.5c0.2-0.1,0.4-0.2,0.5-0.3
	c0.6-0.5,1.1-1,1.7-1.3C434.5,165.6,435.3,165.5,436.1,165.3z M425.7,179.2c2-1,2-1,2.6-0.4c-0.3,0.4-0.7,0.8-0.9,1.2
	c-0.3,0.5-0.5,0.9-0.8,1.4c0.6,0.2,1.2,0.5,1.8,0.7c0.6,0.3,1.3,0.4,1.7,0.9c0.6,0.7,0.7,1.6,0,2.4c-0.8,0.8-2,1-2.9,0.4
	c-0.4-0.2-0.8-0.5-1.3-0.5c-0.3,0-1,0.2-1,0.4c-0.1,0.3,0,0.9,0.3,1.2c1.4,1.2,3,1.5,4.8,1c1.9-0.6,3-2.3,2.6-4.3
	c-0.3-1.3-1-2.1-1.9-2.9c-0.2-0.2-0.4-0.6-0.4-0.9c0-1.3-0.8-2.7-1.9-3.3c-1.6-0.8-2.4,0.6-3.7,1.1
	C425,178.3,425.3,178.7,425.7,179.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity87 ? this.state.opacity87 : 1}
              fill={this.state.color87 ? this.state.color87 : 'transparent'} onClick={() => {
          this.setState({ color87: color, opacity87: opacity });
        }} d="M192.5,326.3c-1.9-3.5,0.6-6.1,1.7-8.7c2.1-4.9,4.3-9.7,5.5-14.9c0.8-3.3,1.7-6.7,2.6-10
	c0.1-0.3,0.5-0.6,0.8-0.9c0.3,0.3,0.8,0.5,0.8,0.9c0.5,1.9,1,3.7,1.5,5.6c0.4,1.6,0.7,3.1,1.7,4.5c1.5,2.2,2.5,4.7,3.8,7.1
	c0.8,1.4,1.7,2.8,2.6,4.1c1.3,2,2.6,3.9,3.8,5.9c0.5,0.8-0.7,2.1-1.9,2.6c-3.5,1.3-7.2,1.3-10.8,1.9c-1.6,0.2-3.1,0.6-4.7,0.9
	c-1.7,0.2-3.5,0.3-5.2,0.6C193.9,326,193.2,326.2,192.5,326.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity88 ? this.state.opacity88 : 1}
              fill={this.state.color88 ? this.state.color88 : 'transparent'} onClick={() => {
          this.setState({ color88: color, opacity88: opacity });
        }} d="M269.5,6.1c-0.3,0.4-0.6,0.8-1,1.1c-3,2.6-6,5.3-9,7.9c-2.8,2.4-5.8,4.8-8.6,7.2c-0.8,0.7-1.5,1.6-2.4,2.7
	c-0.4-0.4-1-0.9-1.6-1.5c-2.4-2.1-4.7-4.4-7.2-6.3c-2.7-2-5.7-3.7-8.7-5.3c-3-1.6-6.2-3-9.3-4.5c-1-0.5-2-1.1-3.1-1.7
	c17,0,33.9,0,50.8,0C269.4,5.8,269.4,6,269.5,6.1z M249.9,15.2c-0.1-0.1-0.2-0.3-0.2-0.4c0.3-0.4,0.6-0.8,0.9-1.2
	c1.3-1.5,0.8-3.6-1.1-4.4c-0.4-0.2-0.9-0.3-1.3-0.5c-1.8-0.7-4.4,0.9-4.5,2.9c0,0.5,0.6,1.4,1.1,1.6c0.7,0.2,1-0.5,1.1-1.2
	c0.1-0.5,0.6-1.3,1-1.4c0.6-0.1,1.4,0.3,1.9,0.7c0.2,0.2,0,1.1-0.2,1.6c-0.2,0.4-0.6,0.6-0.9,0.9c-0.7,0.7-1.4,1.3-1.9,2.1
	c-0.2,0.3-0.3,1-0.1,1.3c0.2,0.3,0.9,0.6,1.3,0.5c2.2-0.4,4.4-0.8,6.7-0.5c1,0.1,1.3-0.5,0.8-1.4c-0.5-1-1.2-1.1-2.2-0.9
	C251.5,15.1,250.7,15.1,249.9,15.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity89 ? this.state.opacity89 : 1}
              fill={this.state.color89 ? this.state.color89 : 'transparent'} onClick={() => {
          this.setState({ color89: color, opacity89: opacity });
        }} d="M221.7,415.9c1.1-5.2,2.4-10.1,3.3-15.1c0.8-5,1.3-10.1,1.9-15.2c0.8,0.7,1.6,1.3,2.4,2c1.9,1.6,4,2.7,6.6,2.7
	c0.4,0,0.8,0.1,1.2,0.2c2.9,1.4,6,1.7,9.2,1.3c0.4,0,0.8,0,1.4,0.1c-2.1,3.7-4.8,6.7-6,10.6c-1.2,3.9-1.7,7.9-1.8,11.8
	c-3.1,0.1-6.1,0.3-9.2,0.4C227.6,414.8,224.7,416.3,221.7,415.9z M233,401.4c-0.1,0.8-0.1,1.3-0.2,1.9c-0.3,1.9-0.7,3.8-0.9,5.7
	c-0.1,0.6-0.2,1.3,0.8,1.4c1,0.1,1.3-0.6,1.4-1.3c0.2-0.8,0.2-1.7,0.4-2.5c0.4-1.8,0.7-3.6,1.3-5.4c0.3-0.9,1.1-1.7-0.3-2.4
	c-0.1-0.1-0.1-0.3-0.2-0.6c-1.7,0.6-3.3,1.3-4.9,1.9c-0.9,0.3-1.2,0.9-0.8,1.7c0.4,0.9,1.1,0.8,1.8,0.3
	C231.7,401.9,232.2,401.7,233,401.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity90 ? this.state.opacity90 : 1}
              fill={this.state.color90 ? this.state.color90 : 'transparent'} onClick={() => {
          this.setState({ color90: color, opacity90: opacity });
        }} d="M61.7,438.9c1.7-1.9,3.2-3.6,4.7-5.3c1.5-1.6,3.1-3.2,4.7-4.8c0.3-0.2,0.7-0.4,1-0.4c2.4-0.2,4.8-0.4,7.2-0.5
	c0.9,0,1.8,0.3,2.7,0.2c4.6-0.5,9.2-0.4,13.9,0c3.2,0.3,6.4,0.5,9.6,0.8c0.4,0,0.9,0.5,1,0.9c0.5,2.7,0.8,5.4,1.2,8.1
	c0.1,0.8-0.1,1.1-0.9,1.1c-5.3,0.1-10.7,0.2-16,0.3c-1.6,0-3.3,0.2-4.9,0.1c-3.9-0.2-7.9-0.6-11.8-0.7c-3.2-0.1-6.4,0.1-9.6,0.1
	C63.7,438.9,62.9,438.9,61.7,438.9z M90.6,436.2c0-0.1,0-0.2-0.1-0.4c0.7-0.4,1.4-0.9,2.1-1.2c1.2-0.6,1.7-1.6,1.8-2.8
	c0-0.4-0.2-1.1-0.6-1.3c-1.7-1.1-3.5-1.3-5.3-0.3c-0.5,0.3-1,0.7-1.5,1.1c0.4,0.4,0.9,0.8,1.3,1.2c0.1,0,0.1,0,0.2,0.1
	c1.7-1.1,2.6-1.2,3.8-0.3c-1,0.7-1.9,1.3-2.7,1.9c-1,0.7-2,1.3-2.9,2c-0.3,0.2-0.4,1-0.2,1.4c0.2,0.3,0.8,0.5,1.3,0.7
	c0.4,0.1,0.8,0,1.2,0c2.3,0.1,4.6,0.2,7,0.3c0.3,0,0.7-0.1,0.9-0.2c0.3-0.2,0.6-0.5,0.7-0.8c0-0.2-0.4-0.5-0.7-0.7
	c-1.4-0.9-2.9-0.5-4.4-0.5C91.9,436.3,91.3,436.3,90.6,436.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity91 ? this.state.opacity91 : 1}
              fill={this.state.color91 ? this.state.color91 : 'transparent'} onClick={() => {
          this.setState({ color91: color, opacity91: opacity });
        }} d="M48.8,354.6c-0.8,1.5-1.6,2.9-2.4,4.4c-0.7,1.4-1.4,2.8-2.2,4.1c-0.6,1-1.1,1.9-1.9,2.7c-0.3,0.4-1,0.8-1.5,0.8
	c-4.7-0.1-9.4,0.6-14.1,1.3c-1.5,0.2-3,0.6-4.5,0.9c-1,0.2-2,0.2-3.2,0.3c-0.2-7-0.3-13.9-0.5-21.1c0.8,0.2,1.3,0.2,1.8,0.3
	c6,1.8,12,3.6,17.9,5.4c2.4,0.7,4.8,1,7.3,0.7C46.6,354.3,47.6,354.5,48.8,354.6z M31.9,354.6c-2.1,1.5-4.3,3.1-6.5,4.6
	c1,1.5,1.2,1.5,2.5,0.5c0.7-0.5,1.3-1.2,2-1.7c0.4-0.3,0.8-0.4,1.2-0.6c0.1,0.1,0.2,0.2,0.4,0.3c-0.3,1.7-0.5,3.3-0.9,5
	c-0.1,0.6-0.4,1.1,0.4,1.4c0.7,0.3,1.4,0.3,1.6-0.6c0.2-0.7,0.4-1.4,0.6-2.2c0.2-1.5,0.4-3,0.5-4.5c0-0.4-0.1-0.9-0.4-1.2
	C33,355.2,32.5,355,31.9,354.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity92 ? this.state.opacity92 : 1}
              fill={this.state.color92 ? this.state.color92 : 'transparent'} onClick={() => {
          this.setState({ color92: color, opacity92: opacity });
        }} d="M93.9,302c1.9,1.7,3.6,3.4,5.3,4.9c1.1,1,2.2,1.9,3.4,2.8c0.9,0.7,1.1,1.6,0.7,2.7c-1.5,3.7-3,7.3-4.5,11
	c-1.4,3.5-2.7,7-4.2,10.5c-0.7,1.6-1.6,3.2-2.4,4.7c-0.4,0.7-0.7,0.8-1.5,0.4c-1.4-0.7-2.9-1-4.4-1.5c-2.4-0.9-4.9-1.7-6.8-3.5
	c-0.2-0.2-0.4-0.8-0.3-1.1c0.5-1,1.1-1.9,1.6-2.9c2.8-5.8,5.6-11.6,8.3-17.4c1-2.1,1.6-4.3,2.5-6.4C92.2,304.8,93.2,303.4,93.9,302z
	 M93.4,324c0.5,1.3,1.5,2,2.3,1.6c0.5-0.2,0.8-0.7,1.3-1c1.4-0.9,1.9-2.3,1.9-3.9c0-1.5-0.7-2.3-2.2-2.4c-0.8-0.1-1.6,0-2.4,0
	c-1-2.6-2.4-2.9-4.4-0.6c-0.2,0.2-0.4,0.3-0.4,0.6c-0.3,0.9-1,1.8-0.1,3.1c0.9-1.1,1.8-2.2,2.7-3.2c0.1,0.1,0.3,0.2,0.4,0.2
	c-0.1,1-0.3,2.1-0.4,3.1c0,0.5,0.2,1,0.4,1.5c0.5-0.3,1.1-0.5,1.5-0.9c0.4-0.4,0.6-1,1-1.3c0.4-0.3,1-0.4,1.5-0.3
	c0.3,0.1,0.5,0.7,0.6,1.1c0.1,1.3-0.8,2.2-2.2,2.3C94.5,323.8,94,323.9,93.4,324z M85.7,329c1,0.5,1.5,0.2,1.7-0.8
	c0.1-0.8,0.4-1.5,0.5-2.2c0.1,0,0.2,0,0.3,0c0.3,0.6,0.7,1.1,1,1.7c0.6,0.9,1.3,1.8,1.9,2.7c0.6,0.8,0.9,0.6,1.5-0.1
	c0.5-0.6,0.2-1.1-0.1-1.6c-1-1.4-2-2.9-3-4.3c-0.4-0.5-0.8-1-1.4-1.3c-0.6-0.3-1.4-0.4-1.6,0.7C86.4,325.5,86,327.2,85.7,329z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity93 ? this.state.opacity93 : 1}
              fill={this.state.color93 ? this.state.color93 : 'transparent'} onClick={() => {
          this.setState({ color93: color, opacity93: opacity });
        }} d="M83.2,360.8c-1.5,0.5-2.6,0.8-3.6,1.2c-4.5,1.8-9,3.8-13.8,4.9c-1.5,0.3-2.9,1-4.3,1.5
	c-0.3,0.1-0.7,0.1-1.5,0.3c0.7-1.4,1.3-2.5,1.8-3.6c1.2-2.3,2.3-4.6,3.5-6.9c1.1-2.1,2.2-4.4,3.6-6.3c1.6-2.1,2.4-4.6,3.7-6.8
	c1.3-2.2,2.4-4.6,3.5-6.9c0.1-0.1,0.1-0.3,0.2-0.4c0.6-1.9,0.6-1.9,2.5-1.2c2.9,1.1,5.7,2.3,8.6,3.4c0.8,0.3,1.7,0.5,2.6,0.8
	c1,0.4,1.1,1.1,0.7,1.9c-0.7,1.5-1.5,3.1-2.2,4.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,2.4-1.4,4.4-2.4,6.5
	C84.9,356.5,84,358.9,83.2,360.8z M79.3,348.2c-0.1-0.1-0.3-0.2-0.4-0.2c0.3-0.9,0.6-1.7,0.9-2.6c0.1-0.5,0.1-1,0.2-1.6
	c-0.5,0.1-1,0.1-1.5,0.4c-0.3,0.1-0.5,0.5-0.7,0.8c-0.9,1.8-1.1,3.7-0.9,5.6c0.2,2,2.6,4.3,4.6,4.2c0.6,0,1.3-0.3,1.8-0.7
	c1.4-1,1.7-2.6,1.8-4.2c0.1-2.8-1.8-3.8-4.2-2.5C80.4,347.7,79.8,347.9,79.3,348.2z M73.1,354c0.1,0,0.2-0.1,0.3-0.1
	c0.3,0.5,0.6,1.1,0.9,1.6c0.6,1,1.3,2,2,2.9c0.2,0.2,0.7,0.5,0.8,0.4c0.3-0.3,0.7-0.7,0.8-1.1c0-0.5-0.3-0.9-0.6-1.4
	c-0.3-0.5-0.7-0.9-1-1.4c-0.7-1.5-1.4-3-2.1-4.5c-0.2-0.3-0.6-0.5-0.9-0.8c-0.4,0.7-1.3,0.6-1.5,1.6c-0.4,1.9-1.1,3.8-1.6,5.7
	c-0.1,0.4,0,0.8,0,1.2c0.4,0,1,0.1,1.3-0.2c0.4-0.2,0.6-0.7,0.7-1.2C72.5,356,72.8,355,73.1,354z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity94 ? this.state.opacity94 : 1}
              fill={this.state.color94 ? this.state.color94 : 'transparent'} onClick={() => {
          this.setState({ color94: color, opacity94: opacity });
        }} d="M105.3,312.3c4.3,1.9,8.5,3.7,12.6,5.5c1.4,0.6,2.9,1.1,4.4,1.6c1.1,0.4,1.1,1.1,0.5,1.9
	c-1.1,1.7-2.3,3.4-3.4,5.2c-2.5,4-5.4,7.8-6.8,12.4c-0.3,0.9-0.9,1.5-2.1,1.5c-1,0-2,0.4-3,0.5c-4.2,0.3-8.5,0.5-12.7-0.7
	c-1-0.3-1-0.6-0.6-1.4c1.2-2.5,2.4-4.9,3.4-7.4c1.7-4.2,3.3-8.6,5-12.8C103.4,316.4,104.3,314.4,105.3,312.3z M107.8,329.4
	c-0.5-1.2-0.8-2.3-1.5-3c-0.9-1-3.7-0.7-4.8,0.3c-1.1,1-1.5,3.4-0.6,4.7c1,1.4,2.1,2.8,3.2,4.2c0.7,0.8,1.5,1,2.6,0.5
	c2.9-1.3,3.5-2.3,3.4-5.5c0-0.9-0.5-1.2-1.2-1.2C108.4,329.4,108,329.4,107.8,329.4z M113.8,324.8c-0.3-0.7-0.5-1.5-0.8-2.3
	c-0.3-0.8-0.7-1.6-1.2-2.3c-0.2-0.4-0.4-0.8-0.7-1c-1.8-1.3-4.3-0.4-4.9,1.9c-0.3,1.1-0.6,2.4,0.5,3.4c0.5,0.4,1.1,0.7,1.6,1.1
	c0.8-1.1,0.4-2.3,0.1-3.3c-0.2-0.6-0.3-1.1,0.2-1.3c0.5-0.2,1.4-0.3,1.6-0.1c0.7,1.1,1.8,2,1.7,3.5c-0.1,1.4-0.2,2.8-0.2,4.2
	c0,0.4,0.4,0.9,0.6,1.3c0.5-0.3,1.1-0.6,1.5-1c0.9-1.2,1.5-2.5,2.4-3.6c0.7-1,0.8-1.8-0.3-2.6C115.2,323.3,114.5,324.1,113.8,324.8z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity95 ? this.state.opacity95 : 1}
              fill={this.state.color95 ? this.state.color95 : 'transparent'} onClick={() => {
          this.setState({ color95: color, opacity95: opacity });
        }} d="M141.4,323.8c-1.1,2.9-2.1,5.6-3.1,8.4c-0.3,0.9-0.7,1.2-1.8,1c-0.9-0.2-2-0.1-3,0c-3,0.4-6,0.9-9.1,1.2
	c-3.7,0.3-6.5,2.5-9.7,4.4c0.5-1,0.8-1.9,1.3-2.7c1.8-2.9,3.8-5.7,5.4-8.7c0.9-1.6,1.4-3.4,2-5.1c0.6-1.9,1.4-2.6,3.4-2.3
	c1.5,0.2,3,0.6,4.3,1.7c0.4,0.4,1.1,0.5,1.7,0.6c2.5,0.4,5,0.8,7.5,1.2C140.7,323.5,141,323.7,141.4,323.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity96 ? this.state.opacity96 : 1}
              fill={this.state.color96 ? this.state.color96 : 'transparent'} onClick={() => {
          this.setState({ color96: color, opacity96: opacity });
        }} d="M44.8,391.2c0-2.3,0-4.4,0-6.5c0-0.2,0.3-0.5,0.5-0.6c1.1-0.3,2.1-0.7,3.2-0.9c0.9-0.2,1.5,0.1,1.5,1.4
	c8.7-1.8,17.3-3.7,26.3-5.6c-0.8,1.3-1.4,2.4-2,3.4c-0.7,1.1-1.4,2.2-2.2,3.2c-0.3,0.4-0.8,0.8-1.3,0.9c-3.3,0.5-6.7,0.8-10,1.4
	c-4.3,0.8-8.6,1.9-12.9,2.9C46.9,391,46,391.1,44.8,391.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity97 ? this.state.opacity97 : 1}
              fill={this.state.color97 ? this.state.color97 : 'transparent'} onClick={() => {
          this.setState({ color97: color, opacity97: opacity });
        }} d="M69,428.3c-1.8,1.8-3.2,3.4-4.8,5c-2,1.9-4,3.7-6.1,5.6c-0.3,0.3-0.8,0.4-1.2,0.4c-4.1-0.1-8.2-0.3-12.3-0.4
	c-1,0-2,0.1-3.1,0.2c0.1-0.3,0.2-0.5,0.3-0.6c2.4-2.9,4.8-5.7,7.2-8.6c0.6-0.7,1.2-1.1,2.2-1.1c3.6,0,7.2,0,10.7-0.1
	c2,0,4-0.2,6-0.3C68.1,428.2,68.4,428.2,69,428.3z M54.8,433c0,0.4,0,0.7-0.1,1.1c-0.2,0.8-0.7,1.6-0.7,2.4c0,0.6,0.4,1.3,0.7,1.9
	c0.4-0.4,1.1-0.8,1.3-1.3c0.5-1.4,0.8-2.8,1-4.2c0.1-0.6-0.1-1.3-0.4-1.8c-0.5-0.8-1.3-0.8-2.1-0.3c-1,0.6-2,1.1-2.9,1.8
	c-0.4,0.3-0.7,0.9-1,1.3C52.5,435.3,53.3,433.3,54.8,433z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity98 ? this.state.opacity98 : 1}
              fill={this.state.color98 ? this.state.color98 : 'transparent'} onClick={() => {
          this.setState({ color98: color, opacity98: opacity });
        }} d="M114.7,358.9c0.1,1.4,0.3,2.6,0.3,3.8c0,0.5-0.4,1-0.4,1.5c-0.2,1.8,1.4,4.3,3.2,4.9c1.8,0.6,3.6,1.1,5.4,1.7
	c0.2,0.1,0.4,0.1,0.6,0c1-0.7,1.5-0.1,2,0.7c0.9,1.2,1.8,2.3,2.8,3.4c0.6,0.6,1,1.1,0.5,1.9c-0.5,0.7-1.2,0.7-1.9,0.4
	c-2.5-1.2-5-2.4-7.6-3.6c-0.9-0.4-2-0.6-2.9-1c-3.8-1.6-7.7-3.3-11.5-5c-0.7-0.3-0.9-0.6-0.8-1.5c0.4-2.1,0.7-4.1,1-6.2
	c0.1-0.9,0.9-1.1,1.6-1.1c1.5,0,2.9,0.2,4.4,0.3C112.6,358.9,113.7,358.9,114.7,358.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity99 ? this.state.opacity99 : 1}
              fill={this.state.color99 ? this.state.color99 : 'transparent'} onClick={() => {
          this.setState({ color99: color, opacity99: opacity });
        }} d="M19.9,389.9c0.2,0.3,0.5,0.7,0.8,1.2c0.3-0.4,0.5-0.7,0.6-1c0.3-0.9,0.8-1.4,1.7-1.7c2.2-0.8,4.4-1.2,6.7-1.4
	c1.5-0.1,3-0.5,4.4-0.8c2.6-0.5,5.2-1,8-1.3c-0.8,0.6-1.5,1.3-2.3,1.9c-1.5,1.1-1.5,1.1,0.2,2.1c0.8-0.7,1.6-1.4,2.7-2.3
	c0,1.8,0,3.2,0,4.7c0,0.2-0.3,0.6-0.6,0.7c-1.7,0.3-3.5,0.6-5.3,0.8c-0.4,0-0.9-0.2-1.3-0.5c-0.7-0.5-1.4-0.5-1.6,0.2
	c-0.3,1.5-1.5,1.6-2.6,1.9c-4.1,1-8.2,1.9-12.6,3c0.2-2.5,0.4-4.8,0.6-7.2C19.6,389.9,19.8,389.9,19.9,389.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity100 ? this.state.opacity100 : 1}
              fill={this.state.color100 ? this.state.color100 : 'transparent'} onClick={() => {
          this.setState({ color100: color, opacity100: opacity });
        }} d="M304.1,87.4c-0.9-0.4-1.8-0.7-2.7-1.2c-0.7-0.4-0.8-1.1,0-1.6c1.5-1.1,3.2-1.9,5.1-2.2c0.2,0,0.7,0.1,0.7,0.3
	c0.1,1,0.5,2.2,0.2,3.1c-0.3,1.2-1.7,1.3-2.8,1.3c-0.1,0-0.2,0-0.4,0C304.2,87.2,304.1,87.3,304.1,87.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity101 ? this.state.opacity101 : 1}
              fill={this.state.color101 ? this.state.color101 : 'transparent'} onClick={() => {
          this.setState({ color101: color, opacity101: opacity });
        }} d="M150.8,436.6c-1.6,0.9-3.1,1.6-4.8,0.7c-0.3-0.2-0.7-0.4-0.8-0.6c-0.3-0.4-0.4-0.8-0.6-1.3
	c0.4-0.1,0.8-0.3,1.3-0.3c0.9-0.1,1.7-0.1,2.6-0.1C149.6,435,150.1,435.7,150.8,436.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity102 ? this.state.opacity102 : 1}
              fill={this.state.color102 ? this.state.color102 : 'transparent'} onClick={() => {
          this.setState({ color102: color, opacity102: opacity });
        }} d="M108.4,351.3c0.1,0.6,0.2,1.2,0.2,1.8c0.1,0.6,0.1,1.2,0.1,1.9c0,0.4,0.2,1.1,0.1,1.1c-0.6,0.2-1.2,0.5-1.8,0.4
	c-0.2,0-0.5-0.9-0.5-1.3c0.3-1.3,0.8-2.7,1.2-4C108,351.2,108.2,351.2,108.4,351.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity103 ? this.state.opacity103 : 1}
              fill={this.state.color103 ? this.state.color103 : 'transparent'} onClick={() => {
          this.setState({ color103: color, opacity103: opacity });
        }} d="M170.2,94c1.6-1.1,3.2-2.1,5.2-1.8c0.1,0.7,0.2,1.3,0.2,1.9c0.1,1-1.4,2.2-2.9,2.4c-1.3,0.2-1.8-0.2-2.2-1.5
	C170.4,94.6,170.3,94.4,170.2,94z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity200 ? this.state.opacity200 : 1}
              fill={this.state.color200 ? this.state.color200 : 'transparent'} onClick={() => {
          this.setState({ color200: color, opacity200: opacity });
        }} d="M187.5,129.3c0.1-0.7,0.1-1.1,0.2-1.6c0-0.9,1.8-2,3-1.9c1,0.1,1.6,1,1.4,2.1c-0.1,0.8-1.9,1.9-3,1.8
	C188.6,129.5,188.1,129.4,187.5,129.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity206 ? this.state.opacity206 : 1}
              fill={this.state.color206 ? this.state.color206 : 'transparent'} onClick={() => {
          this.setState({ color206: color, opacity206: opacity });
        }} d="M325.9,341c0.7-1.4,1.4-2.7,2.3-4c0.2-0.3,0.7-0.4,1.1-0.4c0.2,0,0.4,0.6,0.4,1c-0.1,1.1-0.3,2.2-0.6,3.3
	c-0.1,0.4-0.3,1-0.6,1.1c-0.3,0.1-0.8-0.2-1.2-0.3C326.7,341.5,326.4,341.3,325.9,341z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity204 ? this.state.opacity204 : 1}
              fill={this.state.color204 ? this.state.color204 : 'transparent'} onClick={() => {
          this.setState({ color204: color, opacity204: opacity });
        }} d="M359,394.7c-0.4-0.2-1.2-0.5-1.2-0.8c-0.1-0.5,0.2-1.2,0.5-1.7c0.4-0.5,1.1-0.8,1.7-1.1c0.9-0.4,1.8,0,2.1,0.8
	c0.2,0.8,0.2,1.7-0.8,2.1C360.6,394.3,359.9,394.5,359,394.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity204 ? this.state.opacity204 : 1}
              fill={this.state.color204 ? this.state.color204 : 'transparent'} onClick={() => {
          this.setState({ color204: color, opacity204: opacity });
        }} d="M361.5,385.1c0,0.8,0.1,1.6-0.1,2.3c-0.1,0.4-0.7,0.9-1.1,1c-0.8,0.1-1.6-0.2-1.7-1.2c-0.1-0.5,0-1.1,0.2-1.5
	c0.2-0.4,0.7-0.7,1-0.8c0.4-0.1,0.9,0.2,1.3,0.3C361.2,385.1,361.3,385.1,361.5,385.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity108 ? this.state.opacity108 : 1}
              fill={this.state.color108 ? this.state.color108 : 'transparent'} onClick={() => {
          this.setState({ color108: color, opacity108: opacity });
        }} d="M89.3,194.4c-0.2-0.5-0.6-0.9-0.6-1.2c0.4-2,3.5-3.7,5.4-3.2c0.7,0.2,1,0.7,0.6,1.2c-0.6,0.7-1.2,1.5-2,1.9
	C91.8,193.7,90.6,193.9,89.3,194.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity109 ? this.state.opacity109 : 1}
              fill={this.state.color109 ? this.state.color109 : 'transparent'} onClick={() => {
          this.setState({ color109: color, opacity109: opacity });
        }} d="M87.9,56.6c-0.7,0.9-1.2,1.6-1.9,2.3c-0.3,0.3-1.1,0.5-1.3,0.4c-0.3-0.3-0.6-1-0.5-1.4c0.2-1.4,1.3-2,2.6-1.6
	C87.2,56.3,87.5,56.4,87.9,56.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity110 ? this.state.opacity110 : 1}
              fill={this.state.color110 ? this.state.color110 : 'transparent'} onClick={() => {
          this.setState({ color110: color, opacity110: opacity });
        }} d="M74.2,61c0.6-2.3,2.7-3,3.8-4.7c0.1,0,0.3,0.1,0.4,0.1c-0.1,1.4-0.2,2.9-0.3,4.3c0,0.1-0.2,0.3-0.3,0.3
	C76.7,61,75.5,61,74.2,61z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity111 ? this.state.opacity111 : 1}
              fill={this.state.color111 ? this.state.color111 : 'transparent'} onClick={() => {
          this.setState({ color111: color, opacity111: opacity });
        }} d="M132.8,403.8c-0.1,1.3-0.1,2.5-0.2,3.8c0,0.2-0.5,0.6-0.8,0.6c-1.2,0-2.5-0.1-4-0.2c1.4-2,3.3-2.9,4.8-4.4
	C132.6,403.6,132.7,403.7,132.8,403.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity201 ? this.state.opacity201 : 1}
              fill={this.state.color201 ? this.state.color201 : 'transparent'} onClick={() => {
          this.setState({ color201: color, opacity201: opacity });
        }} d="M225.8,196.3c1.2-1.4,2.1-2.6,3.1-3.8c0.1,0,0.2,0,0.4,0.1c-0.1,1.3-0.1,2.5-0.2,3.8c0,0.1-0.3,0.4-0.5,0.4
	C227.7,196.6,226.9,196.5,225.8,196.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity113 ? this.state.opacity113 : 1}
              fill={this.state.color113 ? this.state.color113 : 'transparent'} onClick={() => {
          this.setState({ color113: color, opacity113: opacity });
        }} d="M138.2,52.3c-0.5,0.7-0.9,1.4-1.4,1.9c-0.3,0.4-0.9,0.6-1.3,0.8c-0.2,0.1-0.7,0.2-0.8,0c-0.3-0.2-0.5-0.7-0.5-1
	c0.1-1.1,2.1-2.6,3.1-2.3C137.6,51.7,137.8,52,138.2,52.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity114 ? this.state.opacity114 : 1}
              fill={this.state.color114 ? this.state.color114 : 'transparent'} onClick={() => {
          this.setState({ color114: color, opacity114: opacity });
        }} d="M395,71.4c-0.1,1.2-0.1,2.4-0.2,3.6c0,0.2-0.5,0.5-0.7,0.5c-1,0-2-0.2-3.2-0.3c1.3-1.4,2.5-2.8,3.7-4.2
	C394.7,71.2,394.8,71.3,395,71.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity202 ? this.state.opacity202 : 1}
              fill={this.state.color202 ? this.state.color202 : 'transparent'} onClick={() => {
          this.setState({ color202: color, opacity202: opacity });
        }} d="M243.2,105.3c0,1.2,0,2.4,0,3.7c0,0.2-0.4,0.6-0.7,0.6c-0.9,0.1-1.9,0-3,0c0.6-2,2.1-3,3.2-4.4
	C242.9,105.2,243,105.2,243.2,105.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity116 ? this.state.opacity116 : 1}
              fill={this.state.color116 ? this.state.color116 : 'transparent'} onClick={() => {
          this.setState({ color116: color, opacity116: opacity });
        }} d="M54.5,313.9c-0.2,0.7-0.3,1.4-0.5,2.1c-0.2,0.9-0.6,1.7-1.6,1.9c-1.2,0.2-2.2,0-3.1-0.9c-1.7-2-1.7-3.9,0.2-5.6
	c0.2-0.2,0.4-0.3,0.6-0.3c1.1,0.2,2.1,0.3,3.1,0.6C54.4,312,54.6,312.9,54.5,313.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity117 ? this.state.opacity117 : 1}
              fill={this.state.color117 ? this.state.color117 : 'transparent'} onClick={() => {
          this.setState({ color117: color, opacity117: opacity });
        }} d="M367,143.4c-0.6-0.4-1.4-0.7-1.7-1.2c-0.4-0.8,0.4-2.1,1.4-2.5c0.8-0.3,1.6-0.6,2.3-0.9
	c0.8-0.3,1.8,0.5,1.7,1.4C370.5,141.9,369,143.1,367,143.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity118 ? this.state.opacity118 : 1}
              fill={this.state.color118 ? this.state.color118 : 'transparent'} onClick={() => {
          this.setState({ color118: color, opacity118: opacity });
        }} d="M365.6,138.1c-0.1-0.1-0.6-0.2-0.6-0.4c0-0.6,0-1.2,0.3-1.7c0.3-0.6,2.7-1.2,3.2-0.8c0.2,0.2,0.3,0.6,0.2,0.9
	C368.4,137.3,367.2,138.1,365.6,138.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity119 ? this.state.opacity119 : 1}
              fill={this.state.color119 ? this.state.color119 : 'transparent'} onClick={() => {
          this.setState({ color119: color, opacity119: opacity });
        }} d="M411.5,211.7c-0.1-0.1-0.5-0.3-0.9-0.6c0.4-0.6,0.7-1.4,1.3-1.8c0.5-0.4,1.3-0.4,1.9-0.5c0.6-0.1,1,1,0.6,1.7
	C413.9,211.4,413,211.7,411.5,211.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity120 ? this.state.opacity120 : 1}
              fill={this.state.color120 ? this.state.color120 : 'transparent'} onClick={() => {
          this.setState({ color120: color, opacity120: opacity });
        }} d="M411.2,206.1c0.2-1.7,1.4-2.5,2.6-1.7c0.3,0.2,0.5,0.8,0.7,1.2c-0.5,0.2-0.9,0.6-1.4,0.6
	C412.4,206.4,411.8,206.2,411.2,206.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity121 ? this.state.opacity121 : 1}
              fill={this.state.color121 ? this.state.color121 : 'transparent'} onClick={() => {
          this.setState({ color121: color, opacity121: opacity });
        }} d="M335.2,415.7c-0.2,0.4-0.4,0.7-0.5,1c-0.7,1.8-2.5,3-4.3,2.8c-0.6-0.1-1.1-0.3-0.8-1c0.8-1.6,3.3-3.4,5.3-3.1
	C334.9,415.5,335,415.6,335.2,415.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity122 ? this.state.opacity122 : 1}
              fill={this.state.color122 ? this.state.color122 : 'transparent'} onClick={() => {
          this.setState({ color122: color, opacity122: opacity });
        }} d="M159.1,200.6c0.1-0.4,0.2-1.3,0.5-2c0.6-1.5,1.1-1.7,2.7-1.3c1.4,0.4,3,0.5,3.6,2.2c0.2,0.5,0.3,1.1,0.1,1.6
	c-1.1,2.8-2.2,3.4-5,3.2C159.7,204.1,159.1,203.1,159.1,200.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity123 ? this.state.opacity123 : 1}
              fill={this.state.color123 ? this.state.color123 : 'transparent'} onClick={() => {
          this.setState({ color123: color, opacity123: opacity });
        }} d="M202.9,410.4c-0.4,0.7-0.9,1.4-1.3,2.1c-0.6,1-3.3,2-4.3,1.4c-0.7-0.4-0.5-1.1-0.2-1.6c1.2-1.9,3.1-2.5,5.3-1.8
	C202.6,410.4,202.8,410.4,202.9,410.4C203,410.4,202.9,410.4,202.9,410.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity124 ? this.state.opacity124 : 1}
              fill={this.state.color124 ? this.state.color124 : 'transparent'} onClick={() => {
          this.setState({ color124: color, opacity124: opacity });
        }} d="M186.7,413.8c-1.3,0-1.7-0.5-1.4-1.5c0.6-1.6,1.7-2.8,3.4-3.3c0.9-0.3,1.8-0.2,2.2,0.7c0.2,0.5-0.1,1.3-0.5,1.8
	C189.3,412.6,188.1,413.6,186.7,413.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity125 ? this.state.opacity125 : 1}
              fill={this.state.color125 ? this.state.color125 : 'transparent'} onClick={() => {
          this.setState({ color125: color, opacity125: opacity });
        }} d="M28.1,222.6c0.1,1.1-1.9,3.1-2.7,2.9c-0.3-0.1-0.6-0.6-0.6-0.9c0-1,1.5-2.5,2.5-2.4
	C27.6,222.2,27.9,222.5,28.1,222.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity126 ? this.state.opacity126 : 1}
              fill={this.state.color126 ? this.state.color126 : 'transparent'} onClick={() => {
          this.setState({ color126: color, opacity126: opacity });
        }} d="M175.3,32.2c-2.2,0-2.7-0.7-1.8-2.5c0.4-0.8,1.1-1.6,1.8-1.9c0.7-0.3,2-0.4,2.4,0.1c0.5,0.5,0.6,1.7,0.4,2.5
	C177.7,31.8,176.5,32.3,175.3,32.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity127 ? this.state.opacity127 : 1}
              fill={this.state.color127 ? this.state.color127 : 'transparent'} onClick={() => {
          this.setState({ color127: color, opacity127: opacity });
        }} d="M176.4,22.2c0.2,0.2,0.2,0.4,0.3,0.4c0.5,0.4,1.4,0.5,1.1,1.4c-0.3,0.9-0.8,1.5-1.9,1.6c-0.2,0-0.5,0.1-0.7,0.1
	c-0.4-0.1-0.8-0.2-1.2-0.4c0.2-0.5,0.3-1.3,0.6-1.5c0.7-0.4,1.1-0.7,1.4-1.5c0.1-0.5,0.8-0.8,1.2-1.1c0.1,0.1,0.1,0.2,0.2,0.2
	C177.1,21.7,176.8,21.9,176.4,22.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity128 ? this.state.opacity128 : 1}
              fill={this.state.color128 ? this.state.color128 : 'transparent'} onClick={() => {
          this.setState({ color128: color, opacity128: opacity });
        }} d="M65.6,129.2c-0.1-1.3,0.7-1.6,2.5-2.3c1-0.4,1.9,0.1,2.5,0.9c0.3,0.4,0.3,1.2,0,1.7c-0.7,1.3-3.1,1.8-4.3,1
	C65.9,130.1,65.8,129.6,65.6,129.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity129 ? this.state.opacity129 : 1}
              fill={this.state.color129 ? this.state.color129 : 'transparent'} onClick={() => {
          this.setState({ color129: color, opacity129: opacity });
        }}
              d="M81.9,121.9c-0.2,1.5-2.8,3.6-4.2,3.3c-0.9-0.1-1.2-0.9-0.8-1.8C77.6,122.1,80.1,121.2,81.9,121.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity130 ? this.state.opacity130 : 1}
              fill={this.state.color130 ? this.state.color130 : 'transparent'} onClick={() => {
          this.setState({ color130: color, opacity130: opacity });
        }} d="M27.5,176.6c0.5,0.6,1.1,0.9,1.3,1.4c0.2,0.5,0.4,1.4,0.2,1.7c-0.7,0.7-1.7,1.1-2.6,1.6
	c-0.2,0.1-0.8-0.2-0.8-0.4c-0.1-1.1-0.5-2.2-0.2-3.2C25.7,177.1,26.9,176.9,27.5,176.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity131 ? this.state.opacity131 : 1}
              fill={this.state.color131 ? this.state.color131 : 'transparent'} onClick={() => {
          this.setState({ color131: color, opacity131: opacity });
        }} d="M28.8,172.5c0,1.1-1.7,2.7-2.6,2.4c-0.3-0.1-0.6-0.8-0.6-1.2c0-0.4,0.4-0.9,0.5-1.3c0.1-0.8,0.5-1.4,1.3-1.3
	C28.3,171.2,28.8,171.8,28.8,172.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity132 ? this.state.opacity132 : 1}
              fill={this.state.color132 ? this.state.color132 : 'transparent'} onClick={() => {
          this.setState({ color132: color, opacity132: opacity });
        }} d="M36.3,174.4c0.7-0.1,1.5,0,2.1-0.2c0.8-0.3,1-0.1,1.2,0.7c0.3,2.2-0.1,3.7-1.5,4.2c-0.8,0.3-1.8,0.1-2.6-0.2
	c-0.9-0.3-0.8-1.3-0.5-2.1c0.3-1,0.7-1.9,1.1-2.8C36.2,174.1,36.3,174.2,36.3,174.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity133 ? this.state.opacity133 : 1}
              fill={this.state.color133 ? this.state.color133 : 'transparent'} onClick={() => {
          this.setState({ color133: color, opacity133: opacity });
        }} d="M26.1,37.9c0,1.1-1.1,2.1-2.2,2.1c-0.9,0-1.9-0.8-1.9-1.5c0-1.6,0.9-2.7,1.9-2.7C25.3,35.8,26.1,36.7,26.1,37.9
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity134 ? this.state.opacity134 : 1}
              fill={this.state.color134 ? this.state.color134 : 'transparent'} onClick={() => {
          this.setState({ color134: color, opacity134: opacity });
        }} d="M24,41.8c0.3,0,0.6,0,1,0c0.9,0.1,1.3,0.9,0.8,1.6c-0.7,1.1-1.8,1.7-3,1.8c-1,0.1-1.5-0.6-1.3-1.6
	C21.8,42,22.2,41.8,24,41.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity135 ? this.state.opacity135 : 1}
              fill={this.state.color135 ? this.state.color135 : 'transparent'} onClick={() => {
          this.setState({ color135: color, opacity135: opacity });
        }} d="M31,41.4c1.3-1.4,2.6-2.8,3.8-4.2c0.1,0,0.2,0.1,0.3,0.1c-0.2,1.3-0.3,2.6-0.5,3.9c0,0.2-0.5,0.5-0.7,0.5
	c-1,0.1-2,0-2.9,0C31,41.6,31,41.5,31,41.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity205 ? this.state.opacity205 : 1}
              fill={this.state.color205 ? this.state.color205 : 'transparent'} onClick={() => {
          this.setState({ color205: color, opacity205: opacity });
        }} d="M143.7,365.5c1.5-1.2,2.9-2.3,4.2-3.4c0.4,0.5,0.7,3.1,0.3,3.5c-0.1,0.1-0.3,0.2-0.4,0.2c-1.3,0-2.5,0-3.8-0.1
	C144,365.8,143.9,365.7,143.7,365.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity205 ? this.state.opacity205 : 1}
              fill={this.state.color205 ? this.state.color205 : 'transparent'} onClick={() => {
          this.setState({ color205: color, opacity205: opacity });
        }} d="M155.1,366.3c0.8-1.3,1.5-2.3,2.2-3.3c0.1,0,0.3,0.1,0.4,0.1c0.1,0.9,0.2,1.8,0.1,2.7c0,0.2-0.5,0.6-0.9,0.6
	C156.5,366.6,156,366.4,155.1,366.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity138 ? this.state.opacity138 : 1}
              fill={this.state.color138 ? this.state.color138 : 'transparent'} onClick={() => {
          this.setState({ color138: color, opacity138: opacity });
        }} d="M390.7,417.1c1.6-1.4,3.1-2.8,4.6-4.1c0,1,0.1,2.1,0.2,3.3c0,0.7-0.2,1.1-1,1.1c-1.2,0-2.4,0-3.6,0
	C390.8,417.3,390.7,417.2,390.7,417.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity139 ? this.state.opacity139 : 1}
              fill={this.state.color139 ? this.state.color139 : 'transparent'} onClick={() => {
          this.setState({ color139: color, opacity139: opacity });
        }} d="M367.7,275.5c0.8-0.5,1.5-1.1,2.3-1.3c0.5-0.1,1.3,0.2,1.7,0.6c0.6,0.7-0.2,1-0.7,1.3
	C369.7,277.1,368.8,277,367.7,275.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity140 ? this.state.opacity140 : 1}
              fill={this.state.color140 ? this.state.color140 : 'transparent'} onClick={() => {
          this.setState({ color140: color, opacity140: opacity });
        }} d="M330.3,110.1c0.1-2.2,0.5-3,1.6-3c2.7,0.1,3.9,0.9,3.8,2.6c-0.1,1.4-1.6,2.4-3.4,2.3
	C330.9,112,330.3,111.4,330.3,110.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity141 ? this.state.opacity141 : 1}
              fill={this.state.color141 ? this.state.color141 : 'transparent'} onClick={() => {
          this.setState({ color141: color, opacity141: opacity });
        }} d="M310.6,59.5c-0.8,1.4-2,2.1-3.5,2.5C307.4,60,308.8,58.8,310.6,59.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity142 ? this.state.opacity142 : 1}
              fill={this.state.color142 ? this.state.color142 : 'transparent'} onClick={() => {
          this.setState({ color142: color, opacity142: opacity });
        }} d="M114.1,301.3c0.5,0.5,1.1,0.9,1.4,1.5c0.2,0.4-0.2,1.2-0.6,1.6c-2,1.7-4.2,0.7-4.7-1c-0.2-0.7-0.2-1.2,0.8-1.3
	C112,302,112.9,301.6,114.1,301.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity143 ? this.state.opacity143 : 1}
              fill={this.state.color143 ? this.state.color143 : 'transparent'} onClick={() => {
          this.setState({ color143: color, opacity143: opacity });
        }} d="M280.5,85.2c0.4,1.4,0.8,2.6,1.1,3.8c0,0.2-0.2,0.7-0.4,0.7c-1.6,0.2-3.3,0.3-4.9,0.5c0-0.1-0.1-0.2-0.1-0.3
	C277.5,88.4,279,86.8,280.5,85.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity144 ? this.state.opacity144 : 1}
              fill={this.state.color144 ? this.state.color144 : 'transparent'} onClick={() => {
          this.setState({ color144: color, opacity144: opacity });
        }} d="M360.2,85.2c0.8-1.8,2-2.6,3.6-3c0.3-0.1,0.7,0.2,1,0.4c-0.2,0.3-0.3,0.7-0.5,0.9c-0.9,0.5-1.8,1-2.8,1.4
	C361.2,85.1,360.8,85.1,360.2,85.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity145 ? this.state.opacity145 : 1}
              fill={this.state.color145 ? this.state.color145 : 'transparent'} onClick={() => {
          this.setState({ color145: color, opacity145: opacity });
        }} d="M115.8,103.6c-0.1,0.5-0.1,1.3-0.3,2.1c-0.2,1-2.1,1.9-3.4,1.7c-1.1-0.2-1.7-1-1.6-2.3c0.1-0.8,0.3-1.5,0.6-2.2
	c0.2-0.3,0.7-0.5,1.1-0.7c0.4-0.2,0.8-0.3,1.2-0.4C115.2,100.9,115.9,101.3,115.8,103.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity146 ? this.state.opacity146 : 1}
              fill={this.state.color146 ? this.state.color146 : 'transparent'} onClick={() => {
          this.setState({ color146: color, opacity146: opacity });
        }} d="M60.5,265.1c-0.1-1.7,1.4-2.1,2.5-2.5c0.6-0.2,1.5,0.4,2.2,0.7c0.2,0.1,0.3,0.3,0.4,0.5
	c0.3,0.8-1.1,2.7-2.2,3.2c-0.9,0.4-2.4-0.2-2.8-1.1C60.6,265.6,60.6,265.3,60.5,265.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity147 ? this.state.opacity147 : 1}
              fill={this.state.color147 ? this.state.color147 : 'transparent'} onClick={() => {
          this.setState({ color147: color, opacity147: opacity });
        }} d="M90.3,372.8c0.9-0.1,1.7,0,1.7,1.3c0,1.2-0.7,2-1.8,2.1c-1.4,0.1-2.7-0.4-2.7-1.1
	C87.4,374.1,89,372.8,90.3,372.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity148 ? this.state.opacity148 : 1}
              fill={this.state.color148 ? this.state.color148 : 'transparent'} onClick={() => {
          this.setState({ color148: color, opacity148: opacity });
        }} d="M88.9,370.4c0.1-0.7,0.2-1.5,0.5-2.1c0.3-0.7,0.9-1,1.7-0.5c0.2,0.1,0.4,0.3,0.6,0.3c0.6,0.2,1.6,0,1.2,1.2
	c-0.5,1.4-0.9,1.6-2.2,1.2C90.1,370.4,89.6,370.4,88.9,370.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity149 ? this.state.opacity149 : 1}
              fill={this.state.color149 ? this.state.color149 : 'transparent'} onClick={() => {
          this.setState({ color149: color, opacity149: opacity });
        }} d="M340,230.3c-0.8,1.1-1.4,2.1-2.2,2.8c-0.6,0.5-1.6,0.7-2.4,0.6c-0.7,0-0.9-2.1-0.1-2.6
	C336.7,230.3,338.1,229.8,340,230.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity150 ? this.state.opacity150 : 1}
              fill={this.state.color150 ? this.state.color150 : 'transparent'} onClick={() => {
          this.setState({ color150: color, opacity150: opacity });
        }} d="M422.9,421.4c-0.3-0.2-0.9-0.5-0.9-0.9c-0.1-0.4,0.4-0.9,0.6-1.3c0.1-0.2,0.3-0.4,0.5-0.4
	c0.5-0.2,1.1-0.5,1.6-0.3c0.4,0.1,0.9,0.9,0.8,1.2c-0.2,0.6-0.7,1.1-1.2,1.5C424,421.3,423.5,421.3,422.9,421.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity151 ? this.state.opacity151 : 1}
              fill={this.state.color151 ? this.state.color151 : 'transparent'} onClick={() => {
          this.setState({ color151: color, opacity151: opacity });
        }} d="M430.2,421.6c1-0.2,1.9-0.4,2.9-0.5c0.4,0,0.8,0.2,1.1,0.3c-0.2,0.3-0.2,0.8-0.5,1c-0.3,0.3-0.6,0.5-1,0.7
	C431.4,423.8,430.5,423.2,430.2,421.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity152 ? this.state.opacity152 : 1}
              fill={this.state.color152 ? this.state.color152 : 'transparent'} onClick={() => {
          this.setState({ color152: color, opacity152: opacity });
        }} d="M323,168.3c0,1.9-1.3,2.7-2.9,1.7c-0.4-0.2-0.6-1-0.5-1.4c0.1-0.4,0.5-0.9,0.9-1.2c0.2-0.2,0.6-0.1,0.9-0.3
	C322.7,166.6,323,166.8,323,168.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity153 ? this.state.opacity153 : 1}
              fill={this.state.color153 ? this.state.color153 : 'transparent'} onClick={() => {
          this.setState({ color153: color, opacity153: opacity });
        }} d="M310.8,171.9c0.7-1.3,1.4-2.6,2.2-3.9c0.6,0.8,1.1,3.4,0.7,3.6c-0.8,0.4-1.8,0.5-2.6,0.7
	C310.9,172.2,310.9,172.1,310.8,171.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity154 ? this.state.opacity154 : 1}
              fill={this.state.color154 ? this.state.color154 : 'transparent'} onClick={() => {
          this.setState({ color154: color, opacity154: opacity });
        }} d="M268,23.4c-1,0.3-1.1-0.2-0.9-1c0.2-0.6,0.3-1.3,0.7-1.9c0.2-0.3,0.7-0.5,1-0.6c0.6-0.1,1.4-0.2,1.8,0.1
	c0.5,0.4,1,1.1,1.1,1.8c0.1,0.4-0.5,1.1-0.9,1.3C269.9,23.3,268.9,23.3,268,23.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity155 ? this.state.opacity155 : 1}
              fill={this.state.color155 ? this.state.color155 : 'transparent'} onClick={() => {
          this.setState({ color155: color, opacity155: opacity });
        }} d="M269.9,24.9c1.2,0,1.9,1.1,1.4,2.1c-0.5,1.1-1.5,1.5-2.6,1.6c-0.2,0-0.6-0.2-0.7-0.5
	C267.3,26.8,268.5,25,269.9,24.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity156 ? this.state.opacity156 : 1}
              fill={this.state.color156 ? this.state.color156 : 'transparent'} onClick={() => {
          this.setState({ color156: color, opacity156: opacity });
        }} d="M415.9,117.1c-0.4,1.2-1.6,1.7-2.6,2c-0.3,0.1-1.4-0.9-1.4-1.4c0-0.6,0.7-1.3,1.2-1.7c0.3-0.2,1.1,0.1,1.6,0.3
	C415.2,116.4,415.5,116.8,415.9,117.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity157 ? this.state.opacity157 : 1}
              fill={this.state.color157 ? this.state.color157 : 'transparent'} onClick={() => {
          this.setState({ color157: color, opacity157: opacity });
        }} d="M422.1,119.5c0.2-0.5,0.2-0.9,0.4-1.1c0.8-0.9,4.2-0.6,4.9,0.3c0.6,0.9,0.2,2.7-0.8,3.6
	c-0.8,0.7-2.4,0.6-3.1-0.3C422.9,121.3,422.5,120.3,422.1,119.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity158 ? this.state.opacity158 : 1}
              fill={this.state.color158 ? this.state.color158 : 'transparent'} onClick={() => {
          this.setState({ color158: color, opacity158: opacity });
        }} d="M27.8,339.2c1.6-1.6,3.1-3,4.7-4.5c0,0.5,0.1,1.1,0.1,1.6c0.1,3.6,0.4,3.1-3.2,3.1
	C29,339.5,28.4,339.3,27.8,339.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity159 ? this.state.opacity159 : 1}
              fill={this.state.color159 ? this.state.color159 : 'transparent'} onClick={() => {
          this.setState({ color159: color, opacity159: opacity });
        }} d="M113.6,145.1c0-1.9,1-2.9,2.9-2.9c0.9,0,1.9,1.3,2,2.5c0,1.2-1.7,2.6-3.1,2.6
	C114.4,147.4,113.6,146.3,113.6,145.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity160 ? this.state.opacity160 : 1}
              fill={this.state.color160 ? this.state.color160 : 'transparent'} onClick={() => {
          this.setState({ color160: color, opacity160: opacity });
        }} d="M274.8,433.2c-0.2-0.2-0.7-0.6-0.8-1.1c-0.1-1.2,1-2,2.4-2c1,0,1.1,0.9,1.2,1.6
	C277.8,432.6,276.7,433.2,274.8,433.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity161 ? this.state.opacity161 : 1}
              fill={this.state.color161 ? this.state.color161 : 'transparent'} onClick={() => {
          this.setState({ color161: color, opacity161: opacity });
        }} d="M276.9,427.4c-1.1,0.3-1.8,0.5-2.5,0.7c-0.7,0.3-1,0-0.9-0.7c0.1-0.9,1.2-1.9,2-1.6
	C276.1,426,276.5,426.8,276.9,427.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity162 ? this.state.opacity162 : 1}
              fill={this.state.color162 ? this.state.color162 : 'transparent'} onClick={() => {
          this.setState({ color162: color, opacity162: opacity });
        }} d="M266.5,426.3c0.1,0.9,0.2,1.9,0.2,2.8c0,0.2-0.5,0.5-0.7,0.5c-0.9,0-1.9-0.1-2.9-0.2c1.1-1.2,2.1-2.2,3-3.3
	C266.2,426.2,266.4,426.3,266.5,426.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity163 ? this.state.opacity163 : 1}
              fill={this.state.color163 ? this.state.color163 : 'transparent'} onClick={() => {
          this.setState({ color163: color, opacity163: opacity });
        }} d="M379,32.8c-1.1,0-2.2-1-1.8-1.9c0.3-0.7,1.3-1.3,2.1-1.4c0.7-0.1,1.9,0.4,2.2,1C381.9,31.3,380.1,32.8,379,32.8
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity164 ? this.state.opacity164 : 1}
              fill={this.state.color164 ? this.state.color164 : 'transparent'} onClick={() => {
          this.setState({ color164: color, opacity164: opacity });
        }}
              d="M71.6,16.5c-2,1-3.8,1.9-5.6,2.9c-0.3-1.2,0.6-3.1,1.4-3.5C68.8,15.2,70.4,15.3,71.6,16.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity165 ? this.state.opacity165 : 1}
              fill={this.state.color165 ? this.state.color165 : 'transparent'} onClick={() => {
          this.setState({ color165: color, opacity165: opacity });
        }} d="M119.3,258.5c0,1.3-0.4,1.9-1.8,2.2c-1.1,0.3-2.1-0.1-2.7-1c-0.6-0.8-0.5-1.8,0.3-2.5c0.3-0.3,0.8-0.3,1.2-0.3
	c0.5,0,1.1,0.3,1.6,0.1C119.1,256.9,119.3,257.6,119.3,258.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity166 ? this.state.opacity166 : 1}
              fill={this.state.color166 ? this.state.color166 : 'transparent'} onClick={() => {
          this.setState({ color166: color, opacity166: opacity });
        }} d="M116.3,253.3c0-1.5,0.6-2.1,1.8-2.1c1.9,0,2.6,0.5,2.6,1.6c0,1.2-0.7,2.1-1.7,2.1
	C117.4,254.8,116.3,254.2,116.3,253.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity167 ? this.state.opacity167 : 1}
              fill={this.state.color167 ? this.state.color167 : 'transparent'} onClick={() => {
          this.setState({ color167: color, opacity167: opacity });
        }} d="M189.1,383.2c1.1-1.2,2.3-2.3,3.4-3.5c0.2,0.1,0.3,0.2,0.5,0.3c-0.1,1.2-0.2,2.4-0.4,3.5c0,0.2-0.3,0.4-0.5,0.4
	c-1-0.1-1.9-0.2-2.9-0.3C189.3,383.5,189.2,383.3,189.1,383.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity168 ? this.state.opacity168 : 1}
              fill={this.state.color168 ? this.state.color168 : 'transparent'} onClick={() => {
          this.setState({ color168: color, opacity168: opacity });
        }} d="M124.2,180.6c-1.2,0.3-2.1-0.6-2.7-1.8c-0.4-0.8,0-1.3,0.8-1.4c1.1-0.1,2.2-0.2,3.3-0.2c1.1,0,1.9,0.8,1.9,1.8
	c-0.1,1.1-0.9,1.4-1.8,1.6C125.3,180.7,124.9,180.6,124.2,180.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity169 ? this.state.opacity169 : 1}
              fill={this.state.color169 ? this.state.color169 : 'transparent'} onClick={() => {
          this.setState({ color169: color, opacity169: opacity });
        }} d="M139.6,285c1.6-0.3,2.9-0.6,4.2-0.8c0.7-0.1,1.4,0.4,1.2,1.1c-0.1,0.5-0.8,1.3-1.3,1.3
	C142.4,286.8,141,286.6,139.6,285z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity170 ? this.state.opacity170 : 1}
              fill={this.state.color170 ? this.state.color170 : 'transparent'} onClick={() => {
          this.setState({ color170: color, opacity170: opacity });
        }}
              d="M129.8,276.9c0.6-1.6,2-2.8,3.1-2.7c0.9,0.1,1.4,0.5,1.9,2.1C133.2,277,131.7,277.8,129.8,276.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity171 ? this.state.opacity171 : 1}
              fill={this.state.color171 ? this.state.color171 : 'transparent'} onClick={() => {
          this.setState({ color171: color, opacity171: opacity });
        }} d="M48.5,406c1.5-1.4,3-2.7,4.5-4.1c0.1,0,0.2,0.1,0.3,0.1c-0.2,1.3-0.3,2.5-0.5,3.8c0,0.2-0.3,0.5-0.5,0.5
	c-1.2,0-2.4,0-3.6,0C48.6,406.2,48.5,406.1,48.5,406z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity203 ? this.state.opacity203 : 1}
              fill={this.state.color203 ? this.state.color203 : 'transparent'} onClick={() => {
          this.setState({ color203: color, opacity203: opacity });
        }} d="M180.5,316.9c1,0.1,1.8,0.2,2.1-1c0.2-0.7,2.3-0.6,2.7,0c0.6,1.1,0.8,3.9-0.7,4.5c-0.6,0.2-1.3,0.3-1.9,0.1
	C181.2,320,180.2,318.4,180.5,316.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity203 ? this.state.opacity203 : 1}
              fill={this.state.color203 ? this.state.color203 : 'transparent'} onClick={() => {
          this.setState({ color203: color, opacity203: opacity });
        }} d="M176.8,320.1c-1.1-0.1-1.8-0.4-1.9-1.5c-0.1-0.6-0.4-1.3-0.3-1.8c0.3-1.3,1.3-1.6,2.4-0.7
	c1.2,1,1.5,2.8,0.5,3.6C177.3,319.8,176.9,320,176.8,320.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity174 ? this.state.opacity174 : 1}
              fill={this.state.color174 ? this.state.color174 : 'transparent'} onClick={() => {
          this.setState({ color174: color, opacity174: opacity });
        }} d="M83.6,350.5c-0.6,0.7-1,1.6-1.7,2c-0.4,0.2-1.4-0.1-1.9-0.4c-1-0.6-0.9-1.6,0.2-2.1c0.5-0.2,0.9-0.5,1.4-0.6
	c0.4-0.1,0.9-0.4,1.1-0.2C83.1,349.4,83.2,349.9,83.6,350.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity175 ? this.state.opacity175 : 1}
              fill={this.state.color175 ? this.state.color175 : 'transparent'} onClick={() => {
          this.setState({ color175: color, opacity175: opacity });
        }} d="M105.4,328.7c0,1.4-0.9,2.5-2,2.6c-0.9,0-1.7-0.7-1.7-1.7c0-1.5,0.9-2.8,1.9-2.8
	C104.7,326.9,105.4,327.6,105.4,328.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity176 ? this.state.opacity176 : 1}
              fill={this.state.color176 ? this.state.color176 : 'transparent'} onClick={() => {
          this.setState({ color176: color, opacity176: opacity });
        }} d="M108.5,332.1c-0.1,1.4-0.9,2-1.8,2.3c-0.4,0.1-1.1-0.2-1.5-0.6c-0.5-0.4-0.4-1,0.2-1.3c0.4-0.2,0.8-0.6,1.2-0.8
	c0.4-0.2,0.9-0.7,1.1-0.6C108.1,331.4,108.4,331.9,108.5,332.1z" />
      </SvgIcon>
    )
  }
};