import React, { Component } from "react";
import { Box } from "@material-ui/core";
import SceneWithDrawables from "../drawing/SceneWithDrawables";
import ReactDOM from "react-dom";
import _ from 'lodash';
import uuid from 'uuid-random';
import SceneWithDraggableDrawables from "../drawing/SceneWithDraggableDrawables";

class DrawingImage extends Component<any, any> {
  state = {
    stageWidth: 0,
    stageHeight: 0
  }
  dom: any;

  componentDidMount() {
    this.dom = ReactDOM.findDOMNode(this);
    this.setState({
      stageWidth: this.dom.offsetWidth
    })
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (this.dom.offsetHeight !== this.state.stageHeight) {
      if (this.props.drawables.client === 'mobile' && prevProps.drawables.client === 'desktop') {
        this.setState({
          stageWidth: 0,
          stageHeight: 0
        })
      } else {
        this.setState({
          stageWidth: this.dom.offsetWidth,
          stageHeight: this.dom.offsetHeight
        })
      }
    }
  }

  private getContent = () => {
    let Content = null;
    if (this.props.image) {
      const { src, dir, width = "100%", height } = this.props.image;
      if (_.isString(src)) {
        Content = <img
          id={`${this.props.name}_image_id`}
          width={width}
          height={height}
          {...this.props.image}
          onLoad={(e) => {
            this.setState({
              stageWidth: this.dom.offsetWidth,
              stageHeight: this.dom.offsetHeight
            })
          }}
        />
      } else if (_.isArray(src) && dir === 'row') {
        Content = <Box
          display="flex"
          flexDirection="row"
          width={width}
          height={height}
        >
          {src.map((item, index) => {
            if (item.src) {
              return (<img
                id={`${this.props.name}_image_id_${index}`}
                width={item.width}
                height={item.height}
                src={item.src}
                onLoad={(e) => {
                  this.setState({
                    stageWidth: this.dom.offsetWidth,
                    stageHeight: this.dom.offsetHeight
                  })
                }}
              />)
            } else {
              return (
                <div
                  style={{ width: item.width, height: item.height }}
                />
              )
            }
          })}
        </Box>
      }
    }
    return Content;
  }

  render() {
    const propsStyle = this.props.style ? { ...this.props.style } : {};
    const style = {
      ...propsStyle,
      position: 'relative'
    }
    let {
      showDrawables,
      drawableType,
      color,
      weight,
      saveChanging,
      saveChangingDevMode,
      addPageDrawStateItem,
      removePageDrawStateItem,
      dragImgEnabled,
      copiedSelectedShape,
      pageSelectedShape,
      showedDivider,
      showedRuler,
      showedRulerTriangle,
      showedProtractor,
      showTextId,
      showedFill,
      showPointId,
      showPolygonId,
      showCurvePolygonId,
      opacity,
      pageY
    } = this.props.drawables;
    const { edition, userName, fieldRectangles } = this.props;
    return (
      <Box
        style={style}
      >
        {this.getContent()}
        <Box
          position="absolute"
          top={0}
          left={0}
          zIndex="tooltip"
          width={"100%"}
          {...this.props.boxContainer}
        >
          <SceneWithDrawables
            key={`${this.props.name}_key`}
            name={this.props.name}
            layer2={this.props.layer2}
            fieldRectangles={fieldRectangles}
            addPageDrawStateItem={addPageDrawStateItem}
            removePageDrawStateItem={removePageDrawStateItem}
            saveChanging={saveChanging}
            saveChangingDevMode={saveChangingDevMode}
            drawableType={drawableType}
            showTextId={showTextId}
            showPointId={showPointId}
            showPolygonId={showPolygonId}
            showCurvePolygonId={showCurvePolygonId}
            showedFill={showedFill}
            drawableColor={color}
            drawableWeight={weight}
            drawableOpacity={opacity}
            pageY={pageY}
            showedDivider={showedDivider}
            showedRuler={showedRuler}
            showedRulerTriangle={showedRulerTriangle}
            showedProtractor={showedProtractor}
            onAddShape={(item: any, drawType) => {
              this.props.onAddDrawingShape(item, drawType);
            }}
            onDraggableImageMoved={() => {
              this.props.onDraggableImageMoved()
            }}
            onUpdateShape={(item: any) => {
              this.props.onUpdateShape(item);
            }}
            onSelectedShape={(item: any) => {
              this.props.onSelectedShape(item);
            }}
            onExport={() => {
              this.props.onExportDrawingFile();
            }}
            onSave={() => {
              this.props.onSave();
            }}
            showDrawables={showDrawables}
            stageWidth={this.state.stageWidth}
            stageHeight={this.state.stageHeight}
            dragImgEnabled={dragImgEnabled}
            copiedSelectedShape={copiedSelectedShape}
            pageSelectedShape={pageSelectedShape}
            edition={edition}
            userName={userName}
          />
        </Box>
      </Box>
    )
  }
}

export default DrawingImage;