import { Component } from "react";
import { SvgIcon } from "@material-ui/core";
import React from "react";

export default class ColorIndicator extends Component<any, any> {

  render() {
    const { colorInd, disabled } = this.props;
    const st0 = colorInd ? colorInd : 'black';
    const st1 = '#084B5E';
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-305 358.1 54.1 78.6"}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <path fill={st0} d="M-269.7,368.1c0.3-2.5-0.4-4.9-1.8-6.5c-1-1.1-2.2-1.7-3.6-1.7h-5.8c-1.4,0-2.6,0.6-3.6,1.7
	c-1.4,1.6-2,3.9-1.8,6.5l5,25.9c0.2,2.1,1.7,3.9,3.2,3.9c1.5,0,3-1.7,3.2-3.8L-269.7,368.1z" />
        <path fill={st0} d="M-255.6,400.1h-44.9c-0.7,0-1.4,0.3-1.9,0.8c-0.5,0.5-0.8,1.2-0.8,1.9v0.8c0,0.7,0.3,1.4,0.8,1.9
	c0.5,0.5,1.2,0.8,1.9,0.8h7.5h37.4c1.5,0,2.7-1.2,2.7-2.7v-0.8C-252.8,401.4-254.1,400.1-255.6,400.1z" />
        <polygon fill={st0} points="-299.4,408.3 -299.4,434.8 -288.1,434.8 -288,434.7 -287.8,431.7 -287,420.5 -284.1,431.4
	-283.3,434.3 -283.2,434.8 -256.6,434.8 -256.6,408.3 -293,408.3 " />
        <path fill={st1} d="M-255.6,398.3h-19.1c0.9-1,1.6-2.3,1.7-3.8l5.1-26.1c0.3-3.1-0.5-6-2.2-8c-1.3-1.5-3.1-2.3-5-2.3h-5.8
	c-1.9,0-3.7,0.8-5,2.3c-1.8,2-2.6,4.9-2.2,8.1l5,25.9c0.2,1.5,0.8,2.9,1.8,3.9h-19.2v0c-1.2,0-2.3,0.5-3.2,1.3
	c-0.9,0.9-1.3,2-1.3,3.3v0.8c0,1.2,0.5,2.4,1.3,3.3c0.7,0.7,1.5,1.1,2.4,1.3l0,28.5h13.1h2h0l0.2-4.8l1.1,4.8h2.2h28v-28.5
	c2.1-0.4,3.7-2.3,3.7-4.5v-0.8C-251,400.3-253,398.3-255.6,398.3z M-286.3,368.1c-0.3-2.6,0.4-5,1.8-6.5c1-1.1,2.2-1.7,3.6-1.7h5.8
	c1.4,0,2.6,0.6,3.6,1.7c1.4,1.6,2,3.9,1.8,6.5l-5.1,26.1c-0.2,2-1.7,3.8-3.2,3.8c-1.5,0-3-1.7-3.2-3.9L-286.3,368.1z M-256.6,434.8
	h-26.7l-0.1-0.5l-0.8-3l-2.8-10.9l-0.9,11.2l-0.2,3l0,0.1h-11.4l0-26.5h6.5h36.4V434.8z M-252.8,403.7c0,1.5-1.2,2.7-2.7,2.7H-293
	h-7.5c-0.7,0-1.4-0.3-1.9-0.8c-0.5-0.5-0.8-1.2-0.8-1.9v-0.8c0-0.7,0.3-1.4,0.8-1.9c0.5-0.5,1.2-0.8,1.9-0.8h44.9
	c1.5,0,2.7,1.2,2.7,2.7V403.7z" />
      </SvgIcon>
    )
  }

};


