import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_21_1 extends SVG {

  render() {
    let { color, borderColor = 'black', opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 198.5 112.8"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M134.9,42.1c-2.3,3.5-5.8,5-9.6,5.9c-4,1-8,1.6-12.1,1.3c-2.3-0.2-3.4-2-3.8-3.8c-0.1-0.6,0.4-1.5,0.8-2.1
	c0.8-1,1.8-1.9,2.7-2.7c0.3-0.2,0.8-0.3,1.2-0.3c4.6,0.2,8.3-2,12.1-4.3c0.5-0.3,0.9-0.6,1.3-1.1c-0.4,0.2-0.8,0.4-1.2,0.7
	c-3,2-6.3,3.3-9.9,3.8c-0.8,0.1-1.8,0.2-2.1-0.7c-0.2-0.5,0.1-1.4,0.4-1.9c1.5-2.4,3.1-4.7,4.5-7c0.5-0.8,0.8-1.8,1.2-2.7
	c0.4-1.1,1.1-1.7,2.2-2c1.5-0.3,2.8-1.1,3.9-2.6c-1.3,0.6-2.3,1.2-3.3,1.6c-0.4,0.1-1,0.2-1.2,0c-0.2-0.3-0.3-0.9-0.2-1.3
	c0.6-2.2,1.3-4.4,1.9-6.7c0.3-1,0.4-1.9,0.5-2.9c-1.1,3.2-2.1,6.5-3.3,9.7c-1,2.7-2.1,5.3-3.4,7.9c-1.1,2.2-2.7,2.3-4.2,0.4
	c-1-1.2-1.6-2.7-2.4-4.1c-0.6-1-1.1-1.9-1.6-2.9c-0.1,0.1-0.2,0.1-0.3,0.2c0.4,1,0.8,2.1,1.3,3c0.8,1.6,1.6,3.1,2.5,4.6
	c0.3,0.5,1.2,0.8,1.8,1c1.1,0.3,1.5,1.1,0.8,1.9c-2.5,3.2-4.4,6.9-7.4,9.7c-2.1,1.9-4.6,3.1-7,4.3c-1,0.5-2,1.1-2.9,1.6
	c-0.9,0.5-2.4,0-2.9-1.1c-0.5-1.1,0.3-1.6,1.1-1.9c2-1,4-1.7,5.9-2.8c2-1.2,4-2.5,5.8-4c1.2-1,1.9-2.5,2.8-3.8
	c-0.1-0.1-0.2-0.2-0.4-0.3c-0.3,0.4-0.6,0.8-0.9,1.2c-1.8,3.1-4.3,5.1-8.2,4.8c-2-0.2-4.1-0.4-6.1-0.6c-3.8-0.4-6-4.9-6.2-7.8
	c-0.3-5,2.4-8.5,6.3-11.2c1.2-0.9,1.4-0.7,2.2,0.6c0.4,0.6,0.7,1.3,1.1,2c0.7-1.3,1.3-2.5,2.1-3.6c0.8-1,1.7-1.9,2.7-2.7
	c1.5-1.1,3-2.1,4.6-3.1c1.7-1.1,2.4-0.9,3.4,0.7c0.1,0.2,0.3,0.5,0.5,0.8c2-3.7,6-5.1,8.5-8.2c1.3-1.7,2.5-3.5,3.6-5.3
	c0.7-1.2,1.3-1.5,2.3-0.6c0.5,0.5,0.9,1.1,1.3,1.7c1.3,1.8,2.6,3.6,3.7,5.4c2.1,3.4,3,7.2,2.9,11.2c0,0.4-0.1,0.7-0.2,1.1
	c-0.1,0.3-0.2,0.6-0.5,1.2c1.3-0.6,2.4-1,3.4-1.4c1.2-0.5,2-0.1,1.9,1.1c-0.2,3.9-0.5,7.9-2.9,11.2c-1.4,1.9-3.1,3.5-4.8,5.4
	c0.5-0.1,1-0.2,1.5-0.3c0.3-0.1,0.4-0.4,0.7-0.5c0.6-0.1,1.2-0.2,1.9-0.3c0,0.6,0,1.2,0,1.9C135.1,41.8,135,42,134.9,42.1
	C134.9,42.2,134.9,42.1,134.9,42.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M39.9,23.3c-0.8,0.3-1.6,0.8-2.4,1c-0.5,0.1-1.2,0.2-1.5-0.1c-0.3-0.3-0.5-1-0.4-1.5c0.4-2,0.9-4,1.4-5.9
	c0.1-0.4,0.1-0.9,0-1.3c-0.3,1.1-0.7,2.3-0.8,3.4c-0.6,4.4-3,8.2-4.7,12.1c0,0.1-0.1,0.1-0.1,0.2c-1.3,2.3-2,2.5-3.8,0.4
	c-1.2-1.4-2.5-2.8-2.7-4.8c-0.1-0.8-0.6-1.6-1.1-2.2c0.8,3.4,1.3,7,4.8,8.9c0.8,0.5,1.3,1.2,0.7,2.1c-2.9,4.2-5.5,8.6-9.6,11.7
	c-1.9,1.4-4.1,2.5-6.2,3.6c-1.2,0.6-2.7,0.9-3.9,0c-0.6-0.4-1-1.2-1.2-1.9c-0.1-0.2,0.9-1,1.4-1.1c4.8-1.1,8.5-3.9,12-7.2
	c1-0.9,1.7-2.1,2.5-3.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5,0.6-1,1.1-1.5,1.7c-1,1.1-1.9,2.1-2.9,3.2c-0.9,0.9-1.9,1.2-3.2,1.1
	c-2.2-0.1-4.4-0.3-6.6-0.3c-2.4-0.1-4.2-1-5.2-3.3c-1.5-3.5-2.1-7-0.1-10.3c1.3-2.1,3.2-3.8,4.9-5.6c0.8-0.8,1.5-0.5,2.1,0.4
	c0.4,0.6,0.7,1.3,1,1.9c1.8-4.9,6.1-7.2,10.3-9.6c0.8-0.5,1.5,0,1.8,0.8c0.2,0.4,0.3,0.9,0.6,1.4c0.4-2.4,2.4-3,4.1-3.9
	c3.3-1.8,5.5-4.8,7.6-7.8c0.3-0.5,0.3-1.2,0.5-1.8c0.2-0.5,0.5-1.2,1-1.4c0.3-0.1,1.2,0.3,1.4,0.7c1.2,1.7,2.2,3.5,3.3,5.3
	c0.4,0.6,0.8,1.3,1.2,1.9c2.4,3.2,3.5,6.9,3.6,10.8c0.1,1.4-0.5,2.3-1.6,3.1c0.1,0.1,0.2,0.2,0.2,0.3c1.2-0.6,2.4-1.2,3.6-1.9
	c1.2-0.7,1.9-0.5,1.8,0.9c-0.2,4.3-0.8,8.5-3.7,12c-0.9,1.1-1.8,2.4-2.7,3.6c0.2,0,0.4-0.1,0.6-0.1c1.1-0.1,1.7,0.7,1.3,1.7
	c-0.3,0.7-0.6,1.6-1.1,2.1c-1.6,1.6-3.3,3.1-5.7,3.7c-2.8,0.6-5.6,1.5-8.5,1.9c-1.8,0.3-3.7,0.2-5.6,0.1c-1.8-0.2-3.7-2.5-3.5-4.2
	c0.1-0.9,1-1.8,1.5-2.6c0,0,0.1,0,0.1-0.1c1.6-1.5,2.8-3.5,5.5-3.2c0.4,0.1,0.9-0.3,1.5-0.5c-1.2,0.1-2.3,0.2-3.8,0.3
	c0.5-1.2,0.8-2.1,1.2-3c1.5-3.1,3-6.1,4.4-9.3c0.6-1.3,1.2-2,2.8-2.1c1.2-0.1,2.4-0.9,3.6-1.4C40,23.5,40,23.4,39.9,23.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M136.4,75.8c0.2,1.3,0.5,2.6,0.5,4c0.1,5.2,0.1,10.4,0.2,15.6c0.1,3.6,0.2,7.3,0.2,10.9c0,1.7-0.1,1.9-1.7,1.9
	c-5.4,0.1-10.8,0.2-16.1,0.1c-2.7,0-5.5-0.2-8.2-0.3c-1,0-2,0.3-3,0.3c-1.4,0.1-2-0.5-2-1.8c-0.1-4.3-0.3-8.7-0.4-13
	c-0.1-3.3-0.1-6.5-0.1-9.8c0-2.2,0.3-4.5,0.4-6.7c0.1-2.1,0.1-2.1,2.3-2c5,0.1,10,0.3,15.1,0.4C127.7,75.6,132,75.7,136.4,75.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M66.1,75.6c0.1,2.2,0.2,4.3,0.3,6.3c0.1,3.9,0.1,7.7,0.2,11.6c0.1,4.3,0.2,8.6,0.3,12.9c0,1.1-0.3,1.7-1.6,1.7
	c-5.4,0-10.8,0.1-16.2,0.1c-2.7,0-5.5-0.2-8.2-0.3c-1,0-2,0.3-3,0.3c-1.5,0.1-2-0.5-2.1-2c-0.1-3.3-0.3-6.6-0.4-9.9
	c-0.1-3.2-0.1-6.4-0.1-9.6c0.1-3.3,0.2-6.6,0.4-9.9c0.1-1.9,0.1-2,2-1.9c5.7,0.2,11.4,0.4,17.1,0.6C58.4,75.7,62,75.6,66.1,75.6z" />
      </SvgIcon>
    )
  }
};