import React, { Component } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import { changePage, setAppBarSize } from "../App";
import { ACTION_TOOLTIPS } from "../util/APP_CONSTANS";
import { Menu as MenuIcon } from "@material-ui/icons";
import { ContentList as ContentListMath1} from "./math-1-working-notes/ContentList";
import { ContentList as ContentListMath1Demo} from "./math-1-working-notes-demo/ContentList";
import { ContentList as ContentListMath2} from "./math-2-working-notes/ContentList";
import { ContentList as ContentListMath2Demo} from "./math-2-working-notes-demo/ContentList";
import { ContentList as ContentListMath3} from "./math-3-working-notes/ContentList";
import { ContentList as ContentListMath3Demo} from "./math-3-working-notes-demo/ContentList";
import { ContentList as ContentListMath4} from "./math-4-working-notes/ContentList";
import { ContentList as ContentListMath4Demo} from "./math-4-working-notes-demo/ContentList";
import { ThemeProvider, withStyles, withTheme } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "#084b5e",
        color: "white",
        opacity: "0.8 !important"
      },
      rounded: {
        borderRadius: "10px"
      }
    }
  }
});

const styles: any = theme => ({
  ivanaMenuItem: {
    '&:hover': {
      backgroundColor: '#05323f'
    },
    borderBottom: "solid 1px white"
  }
});

class Content extends Component<any, any> {

  state = {
    open: false
  }

  buttonRef: any;

  contentList;

  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
    switch(props.edition){
      case 'math-1-working-notes':
        this.contentList = ContentListMath1;
        break;
      case 'math-1-working-notes-demo':
        this.contentList = ContentListMath1Demo;
        break;
      case 'math-2-working-notes':
        this.contentList = ContentListMath2;
        break;
      case 'math-2-working-notes-demo':
        this.contentList = ContentListMath2Demo;
        break;
      case 'math-3-working-notes':
        this.contentList = ContentListMath3;
        break;
      case 'math-3-working-notes-demo':
        this.contentList = ContentListMath3Demo;
        break;
      case 'math-4-working-notes':
        this.contentList = ContentListMath4;
        break;
      case 'math-4-working-notes-demo':
        this.contentList = ContentListMath4Demo;
        break;
    }
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (this.state.open) {
      setAppBarSize('beforeOpenedDialog', true);
      setTimeout(() => {
        setAppBarSize('openedDialog', true);
      }, 1)
    }
  }

  listItemClick = (e, page) => {
    e.preventDefault();
    e.stopPropagation();
    const { dispatch, setSearchPage, setPage } = this.props;
    this.handleClose(e);
    changePage(page, dispatch, setSearchPage, setPage);
  }

  handleClose = (event) => {
    if (this.buttonRef.current && this.buttonRef.current.contains(event.target)) {
      return;
    }
    this.setState({
      open: false
    });
  };

  handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      this.setState({
        open: false
      })
    }
  }


  getMenuItems = () => {
    const menuItems = this.contentList.map((item) => {
      return (<MenuItem
        className={this.props.classes.ivanaMenuItem}
        onClick={(e) => {
          this.listItemClick(e, item.page);
        }
        }>
        {item.header}
      </MenuItem>)
    })
    return menuItems;
  }

  render() {

    return (
      <>
        <Tooltip title={ACTION_TOOLTIPS.CONTENT}>
          <IconButton
            ref={this.buttonRef}
            style={{ padding: 5 }}
            color="inherit"
            aria-label="save"
            onClick={() => {
              this.setState({
                open: !this.state.open
              })
            }}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
        <ThemeProvider theme={theme}>
          <Menu
            id="edition-content-menu"
            anchorEl={this.buttonRef}
            keepMounted
            open={this.state.open}
            onClose={this.handleClose}
            onKeyDown={this.handleListKeyDown}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {this.getMenuItems()}
          </Menu>
        </ThemeProvider>
      </>
    )
  }
}

export default withStyles(styles)(Content);