import React, { Component } from "react";
import { SvgIcon } from "@material-ui/core";

export default class SVGClick extends Component<any, any> {

  render() {
    const st0 = "#084B5E";
    const st1 = "#084B5E";
    const { disabled } = this.props;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 512 512"}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <circle cx="165.33" cy="74.58" r="74.58" fill={st1}/><polygon points="313.33 321.74 421.25 320.71 165.33 74.58 190.34 428.79 260.39 346.6 337.75 512 364.22 499.71 390.69 487.29 313.33 321.74" fill={st0}/>
      </SvgIcon>
    )
  }
};
