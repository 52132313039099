import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton } from "@material-ui/core";
import {
  ArrowRightAlt,
  AspectRatio,
  Create,
  Gesture,
  Menu as MenuIcon,
  RadioButtonUnchecked, Redo,
  ShowChart, Undo
} from "@material-ui/icons";
import {
  dragImgEnable,
  drawingArrow,
  drawingCircle,
  drawingCurve, drawingEllipse,
  drawingErase,
  drawingLine,
  drawingRect,
  redo,
  showAllDrawables,
  undo
} from "../actions";
import { connect } from 'react-redux'
import { appColors, getStateDrawables, useStyles } from "../App";
import ColorMenuButton from "./ColorMenuButton";
import WeightLineMenuButton from "./WeightLineMenuButton";
import Eraser from "../icons/Eraser";
import Move from "../icons/Move";
import { ACTION_TOOLTIPS } from "../util/APP_CONSTANS";
import Ellipse from "../icons/Ellipse";

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: any) => {
  return (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  )
});

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    },
    '& .MuiListItemIcon-root, & .correctMinWidth': {
      minWidth: 20,
      marginRight: 10
    }
  },
}))(MenuItem);

const ListItemIconCorrect = (props) => {
  let { toggle, children, color, disabled } = props;
  if(color && disabled){
    color = appColors.icon.drawing.primary;
  }
  let backgroundColor = 'transparent';
  if (toggle) {
    color = appColors.icon.drawing.toggle;
    backgroundColor = "white";
  }
  return (
    <ListItemIcon
      {...props}
      className={"correctMinWidth"}
      style={{ color: color, backgroundColor: backgroundColor}}
    >
      {children}
    </ListItemIcon>
  )
}

function MenuButton({ dispatch, drawables }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const initialState = {
    showAllDrawables: false,
    drawing: {
      line: false,
      arrow: false,
      curve: false,
      circle: false,
      rectangle: false,
      ellipse: false,
      erase: false,
      color: 'white',
      weight: 1
    },
    disabledUndo: true,
    disabledRedo: true
  }
  const [state, setState] = React.useState(initialState);
  const [colorPalette, setColorPalette] = React.useState(false);
  const [weightPalette, setWeightPalette] = React.useState(false);

  useEffect(() => {
    const stateDrawables = getStateDrawables(drawables, initialState);
    if (stateDrawables) {
      setState(stateDrawables);
    }
  }, [drawables]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  const isShowAllDrawables = state.showAllDrawables;
  const disabledUndo = state.disabledUndo;
  const disabledRedo = state.disabledRedo;
  return (
    <div>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          onClick={() => {
            dispatch(showAllDrawables(!isShowAllDrawables, true));
            handleClose();
          }}
        >
          <ListItemIconCorrect
            toggle={isShowAllDrawables}
          >
            <Create fontSize="small" />
          </ListItemIconCorrect>
          <ListItemText primary={ACTION_TOOLTIPS.SHOW_DRAWABLES} />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={!isShowAllDrawables}
          onClick={() => {
            dispatch(drawingLine(true));
            handleClose();
          }}
        >
          <ListItemIconCorrect
            toggle={state.drawing.line && isShowAllDrawables}
          >
            <ShowChart fontSize="small" />
          </ListItemIconCorrect>
          <ListItemText primary={ACTION_TOOLTIPS.SHOW_LINE} />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={!isShowAllDrawables}
          onClick={() => {
            dispatch(drawingArrow(true));
            handleClose();
          }}
        >
          <ListItemIconCorrect
            toggle={state.drawing.arrow && isShowAllDrawables}
          >
            <ArrowRightAlt fontSize="small" />
          </ListItemIconCorrect>
          <ListItemText primary={ACTION_TOOLTIPS.SHOW_ARROW} />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={!isShowAllDrawables}
          onClick={() => {
            dispatch(drawingCurve(true));
            handleClose();
          }}
        >
          <ListItemIconCorrect
            toggle={state.drawing.curve && isShowAllDrawables}
          >
            <Gesture fontSize="small" />
          </ListItemIconCorrect>
          <ListItemText primary={ACTION_TOOLTIPS.SHOW_CURVE} />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={!isShowAllDrawables}
          onClick={() => {
            dispatch(drawingCircle(true));
            handleClose();
          }}
        >
          <ListItemIconCorrect
            toggle={state.drawing.circle && isShowAllDrawables}
          >
            <RadioButtonUnchecked fontSize="small" />
          </ListItemIconCorrect>
          <ListItemText primary={ACTION_TOOLTIPS.SHOW_CIRCLE} />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={!isShowAllDrawables}
          onClick={() => {
            dispatch(drawingRect(true));
            handleClose();
          }}
        >
          <ListItemIconCorrect
            toggle={state.drawing.rectangle && isShowAllDrawables}
          >
            <AspectRatio fontSize="small" />
          </ListItemIconCorrect>
          <ListItemText primary={ACTION_TOOLTIPS.SHOW_RECT} />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={!isShowAllDrawables}
          onClick={() => {
            dispatch(drawingEllipse(true));
            handleClose();
          }}
        >
          <ListItemIconCorrect
            toggle={state.drawing.ellipse && isShowAllDrawables}
          >
            <Ellipse fontSize="small" />
          </ListItemIconCorrect>
          <ListItemText primary={ACTION_TOOLTIPS.SHOW_ELLIPSE} />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={!isShowAllDrawables}
          onClick={() => {
            dispatch(drawingErase(true));
            handleClose();
          }}
        >
          <ListItemIconCorrect
            toggle={state.drawing.erase && isShowAllDrawables}
          >
            <Eraser fontSize="small" />
          </ListItemIconCorrect>
          <ListItemText primary={ACTION_TOOLTIPS.SHOW_ERASE} />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={!isShowAllDrawables}
          onClick={()=>{
            setColorPalette(!colorPalette)
          }}
        >
          <ColorMenuButton
            key={"colorMenuButton_menu"}
            disabled={!isShowAllDrawables}
            containerType={"menu"}
            color={state.drawing.color}
            colorPalette={colorPalette}
          />
          <ListItemText primary={ACTION_TOOLTIPS.CHANGE_COLOR} />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={!isShowAllDrawables}
          onClick={()=>{
            setWeightPalette(!weightPalette)
          }}
        >
        <WeightLineMenuButton
          key={"weightLineMenuButton_menu"}
          disabled={!isShowAllDrawables}
          containerType={"menu"}
          weight={state.drawing.weight}
          weightPalette={weightPalette}
        />
          <ListItemText primary={ACTION_TOOLTIPS.CHANGE_WEIGHT} />
        </StyledMenuItem>


        <StyledMenuItem
          disabled={disabledUndo}
          onClick={() => {
            dispatch(undo());
            handleClose();
          }}
        >
          <ListItemIconCorrect>
            <Undo fontSize="small" />
          </ListItemIconCorrect>
          <ListItemText primary={ACTION_TOOLTIPS.UNDO} />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={disabledRedo}
          onClick={() => {
            dispatch(redo());
            handleClose();
          }}
        >
          <ListItemIconCorrect
            toggle={drawables.dragImgEnabled}
          >
            <Redo fontSize="small" />
          </ListItemIconCorrect>
          <ListItemText primary={ACTION_TOOLTIPS.REDO} />
        </StyledMenuItem>


        <StyledMenuItem
          onClick={() => {
            const isDragImgEnabled = !drawables.dragImgEnabled;
            dispatch(dragImgEnable(isDragImgEnabled));
            handleClose();
          }}
        >
          <ListItemIconCorrect
            toggle={drawables.dragImgEnabled}
          >
            <Move fontSize="small" />
          </ListItemIconCorrect>
          <ListItemText primary={ACTION_TOOLTIPS.DRAG_IMG_ENABLE} />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}

const getVisibleDrawables = (drawables) => {
  return drawables;
}

const mapStateToProps = state => ({
  drawables: getVisibleDrawables(state.drawables)
})

export default connect(
  mapStateToProps
)(MenuButton)
