import React, { Component } from 'react';
import { Circle, Group, Transformer } from "react-konva";
import URLImage from "../drawing/URLImage";
import _ from "lodash";

export default class Divider extends Component <any> {
  private readonly trRef: any;
  private readonly shapeRef: any;
  private mouseDown = false;
  private readonly dividerSize = {
    height: 25,
    x: 0,
    y: 0
  }
  private spikePosition = {
    x: 0,
    y: 0
  }
  private pencilPosition = {
    x: 0,
    y: 0
  }
  private movePointPosition = {
    x: 0,
    y: 0
  }

  state = {
    x: 100,
    y: 100,
    width: 150,
    height: 47,
    rotation: 0,
    moveIcon: {
      x: 48,
      y: 30,
      x1: 55,
      y1: 37,
      bgColor: 'transparent',
      selected: false
    },
    rotationIcon: {
      x: 68,
      y: 30,
      x1: 75,
      y1: 37,
      bgColor: 'transparent',
      selected: false
    },
    pencilIcon: {
      x: 88,
      y: 30,
      x1: 95,
      y1: 37,
      bgColor: 'transparent',
      selected: false
    }
  }

  constructor(props) {
    super(props);
    this.trRef = React.createRef();
    this.shapeRef = React.createRef();
    if (!_.isUndefined(this.props.pageY)) {
      this.state.y = this.props.pageY;
    }
  }


  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (this.state.moveIcon.selected) {
      // we need to attach transformer manually
      this.trRef.current.nodes([this.shapeRef.current]);
      this.trRef.current.getLayer().batchDraw();
    }
  }

  registerMouseDown = () => {
    this.mouseDown = true;
    if (this.state.pencilIcon.selected) {
      const {width, rotation}= this.state;
      const spikePosition = this.spikePosition;
      this.props.onDrawWithDivider('start', spikePosition.x, spikePosition.y, width, rotation);
    }
  }

  registerMouseUp = () => {
    this.mouseDown = false;
    if (this.state.pencilIcon.selected) {
      const {width, rotation}= this.state;
      const spikePosition = this.spikePosition;
      this.props.onDrawWithDivider('stop', spikePosition.x, spikePosition.y, width, rotation);
    }
  }

  registerMouseMove = (x, y) => {
    if (this.mouseDown && this.state.rotationIcon.selected) {
      const spikeX = this.spikePosition.x;
      const spikeY = this.spikePosition.y;
      let dx = x - spikeX;
      let dy = y - spikeY;
      let hypotenuse = Math.pow(Math.pow(dx, 2) + Math.pow(dy, 2), 0.5);
      let rotation = (Math.acos(dx / hypotenuse) * 180) / Math.PI;

      if (dy < 0) {
        rotation = -rotation;
      }

      const groupX = spikeX + (this.dividerSize.height / 2) * Math.sin((rotation * Math.PI) / 180);
      const groupY = spikeY - (this.dividerSize.height / 2) * Math.cos((rotation * Math.PI) / 180);

      this.setState({
        ...this.state,
        rotation,
        x: groupX,
        y: groupY
      }, () => {
        if (this.state.pencilIcon.selected) {
          const {width, rotation}= this.state;
          const spikePosition = this.spikePosition;
          this.props.onDrawWithDivider('drawing', spikePosition.x, spikePosition.y, width, rotation);
        }
      })
    }
  }

  private getPencilPosition = () => {
    const { x, y } = this.spikePosition;
    const { width, rotation } = this.state;
    const positionPencilX = x + width * Math.cos((rotation * Math.PI) / 180);
    const positionPencilY = y + width * Math.sin((rotation * Math.PI) / 180);
    return {
      x: positionPencilX,
      y: positionPencilY
    }
  }

  private setSpikePosition = () => {
    const { x, y, rotation } = this.state;
    const positionSpikeX = x - (this.dividerSize.height / 2) * Math.sin((rotation * Math.PI) / 180);
    const positionSpikeY = y + (this.dividerSize.height / 2) * Math.cos((rotation * Math.PI) / 180);
    this.spikePosition = {
      x: positionSpikeX,
      y: positionSpikeY
    }
  }

  render() {
    const { x, y, width, height, rotation, rotationIcon, moveIcon, pencilIcon } = this.state;
    return (
      <React.Fragment>
        <Group
          ref={this.shapeRef}
          x={x}
          y={y}
          width={width}
          rotation={rotation}
          height={height}
          draggable={!(rotationIcon.selected || pencilIcon.selected)}
          onTransformEnd={(e) => {
            // transformer is changing scale of the node
            // and NOT its width or height
            // but in the store we have only width and height
            // to match the data better we will reset scale on transform end
            const node = this.shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            const groupWidth = Math.max(5, node.width() * scaleX);
            const { moveIcon, rotationIcon, pencilIcon } = this.state;
            this.setState({
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: groupWidth,
              rotation: node.rotation(),
              moveIcon: {
                x: (groupWidth / 2) - 27,
                y: moveIcon.y,
                x1: (groupWidth / 2) - 20,
                y1: moveIcon.y1,
                bgColor: moveIcon.bgColor,
                selected: moveIcon.selected
              },
              rotationIcon: {
                x: (groupWidth / 2) - 7,
                y: rotationIcon.y,
                x1: groupWidth / 2,
                y1: rotationIcon.y1,
                bgColor: rotationIcon.bgColor,
                selected: rotationIcon.selected
              },
              pencilIcon: {
                x: (groupWidth / 2) + 13,
                y: pencilIcon.y,
                x1: (groupWidth / 2) + 20,
                y1: pencilIcon.y1,
                bgColor: pencilIcon.bgColor,
                selected: pencilIcon.selected
              },
            })
          }}
          onDragEnd={(e) => {
            this.setState({
              ...this.state,
              x: e.target.x(),
              y: e.target.y()
            }, () => {
            })
          }}
        >
          <URLImage
            x={this.dividerSize.x}
            y={this.dividerSize.y}
            src={'images/tools/Divider.svg'}
            width={width}
            height={this.dividerSize.height}
          />
          <Circle
            x={moveIcon.x1}
            y={moveIcon.y1}
            radius={10}
            fill={moveIcon.bgColor}
            stroke={moveIcon.bgColor}
          />
          <URLImage
            x={moveIcon.x}
            y={moveIcon.y}
            src={'images/tools/move.png'}
            width={14}
            height={14}
            onClick={(e: any) => {
              let moveIcon = { ...this.state.moveIcon };
              let selected = !moveIcon.selected;
              let bgColor = selected ? '#00F3FF' : 'transparent';
              moveIcon = { ...moveIcon, selected, bgColor };

              let rotationIcon = { ...this.state.rotationIcon };
              const rotationIconSelected = selected ? false : rotationIcon.selected;
              const rotationIconBgColor = rotationIconSelected ? '#00F3FF' : 'transparent';
              rotationIcon = { ...rotationIcon, selected: rotationIconSelected, bgColor: rotationIconBgColor };

              let pencilIcon = { ...this.state.pencilIcon };
              const pencilIconSelected = selected ? false : pencilIcon.selected;
              const pencilIconBgColor = pencilIconSelected ? '#00F3FF' : 'transparent';
              pencilIcon = { ...pencilIcon, selected: pencilIconSelected, bgColor: pencilIconBgColor };

              this.setState({ ...this.state, moveIcon, rotationIcon, pencilIcon });
            }}
          />
          <Circle
            x={rotationIcon.x1}
            y={rotationIcon.y1}
            radius={10}
            fill={rotationIcon.bgColor}
            stroke={rotationIcon.bgColor}
          />
          <URLImage
            x={rotationIcon.x}
            y={rotationIcon.y}
            src={'images/tools/arrows.png'}
            width={14}
            height={14}
            onClick={(e: any) => {
              let rotationIcon = { ...this.state.rotationIcon };
              let selected = !rotationIcon.selected;
              let bgColor = selected ? '#00F3FF' : 'transparent';
              rotationIcon = { ...rotationIcon, selected, bgColor };

              let moveIcon = { ...this.state.moveIcon };
              const moveIconSelected = selected ? false : moveIcon.selected;
              const moveIconBgColor = moveIconSelected ? '#00F3FF' : 'transparent';
              moveIcon = { ...moveIcon, selected: moveIconSelected, bgColor: moveIconBgColor };

              const pencilIcon = { ...this.state.pencilIcon };

              this.setState({ ...this.state, moveIcon, rotationIcon, pencilIcon }, () => {
                this.setSpikePosition();
              });
            }}
          />
          <Circle
            x={pencilIcon.x1}
            y={pencilIcon.y1}
            radius={10}
            fill={pencilIcon.bgColor}
            stroke={pencilIcon.bgColor}
          />
          <URLImage
            x={pencilIcon.x}
            y={pencilIcon.y}
            src={'images/tools/pencil.png'}
            width={14}
            height={14}
            onClick={(e: any) => {
              let pencilIcon = { ...this.state.pencilIcon };
              let selected = !pencilIcon.selected;
              let bgColor = selected ? '#00F3FF' : 'transparent';
              pencilIcon = { ...pencilIcon, selected, bgColor };

              let moveIcon = { ...this.state.moveIcon };
              const moveIconSelected = selected ? false : moveIcon.selected;
              const moveIconBgColor = moveIconSelected ? '#00F3FF' : 'transparent';
              moveIcon = { ...moveIcon, selected: moveIconSelected, bgColor: moveIconBgColor };

              const rotationIcon = { ...this.state.rotationIcon };

              this.setState({ ...this.state, moveIcon, pencilIcon, rotationIcon });
            }}
          />
        </Group>
        {moveIcon.selected && <Transformer
          enabledAnchors={['middle-left', 'middle-right']}
          ref={this.trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />}
      </React.Fragment>
    )
  }

}