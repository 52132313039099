import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_7_2 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 434.4 525.6"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M181.5,90.9c0.4,6.7-1,13.1-2.7,19.4c-0.8,3.1-2.2,6-3.8,8.8c-1.5,2.8-3.8,4.8-5.9,7.1c-1.3,1.3-2.6,2.7-4,3.9
	c-1,0.8-2.1,1.4-3.2,2.1c-0.7,0.5-1.4,1-2.2,1.4c-0.2,0.1-0.6,0.1-0.9,0c-1.3-0.3-2.6-0.9-3.9-0.9c-1.1,0-2.4,0.3-3.4,0.8
	c-1.2,0.6-2.4,1.4-3,2.8c-0.2,0.3-0.7,0.6-1.1,0.6c-3.9,0.7-7.8,0.4-11.8-0.1c-2.8-0.3-5.6-0.4-8.3-0.6c-3.4-0.2-6.4-1.5-9.3-3.2
	c-1.9-1.1-3.9-2.2-5.6-3.6c-3-2.5-6.1-4.9-8.6-8.1c-2.2-2.7-3.9-5.7-5.4-8.8c-1.3-2.7-2.4-5.6-2.9-8.7c-0.5-3.1-1.1-6.2-1.5-9.4
	c-0.2-1.7,0-3.4,0.1-5.2c0.2-3,0.4-6.1,0.7-9.1c0.3-3.5,1-7,1.8-10.4c0.6-2.5,1.6-5,2.6-7.4c0.6-1.4,1.4-2.7,2.1-4.1
	c0.3-0.5,0.3-1.2,0.6-1.7c1.6-2.6,3.2-5.2,4.8-7.8c0.8-1.3,1.8-2.5,2.7-3.7c0.2-0.3,0.6-0.4,0.8-0.7c0.7-0.6,1.3-1.2,2-1.8
	c1.2-1,2.2-2.2,3.6-2.9c2.7-1.5,5.5-3.1,8.4-4.2c3.1-1.2,6.3-2,9.5-2.6c1.9-0.4,4-0.1,5.9,0.2c2.3,0.4,4.6,0.9,6.9,1.6
	c3.2,0.9,6.4,1.9,9.5,3c3.6,1.3,6.4,3.7,8.8,6.6c3,3.6,5.2,7.6,7.4,11.7c1.7,2.9,3.1,6,4.1,9.2c0.8,2.6,2,5,2.7,7.6
	c1.3,4.5,1.5,9.1,2,13.7C181.2,88.1,181.4,89.5,181.5,90.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M259.7,257.8c-2.5-0.2-4.4-1.9-6.3-3.4c-3.7-3-5.9-7.1-7.3-11.7c-0.3-0.9-0.9-1.8-1.2-2.7
	c-0.7-1.8-1.5-3.5-2-5.3c-0.9-3.1-2-6.2-2.3-9.3c-0.4-4.5-0.1-9.1,0.1-13.6c0.1-2.2,0.2-4.5,0.7-6.6c0.7-3,1.9-5.9,2.7-8.9
	c1.3-4.7,3.7-8.7,6.6-12.5c2.5-3.3,5-6.7,7.9-9.5c3.2-3.2,6.7-6.1,10.9-8.2c2.7-1.3,5.5-2.1,8.4-2.7c3.7-0.8,7.3-0.5,11-0.1
	c2.8,0.3,5.5,1.2,7.9,2.6c1.1,0.6,2.4,1,3.5,1.7c0.5,0.3,0.9,0.9,1.4,1.3c1,0.9,2.2,1.7,3,2.7c1.5,2.1,2.9,4.2,4.2,6.5
	c0.9,1.5,1.7,3.2,2.2,4.8c0.7,2.2,1.4,4.5,1.8,6.8c0.6,3.2,1,6.4,1.4,9.6c0.4,3.6,0.6,7.3,0,10.9c-0.2,1.3-0.2,2.6-0.2,3.9
	c0.1,3.1-1,6-1.7,9c-1.7,6.8-5.1,12.8-9.6,18.1c-3.1,3.6-5.9,7.5-9.6,10.6c-3.3,2.7-7,4.7-11.1,6.1c-5.7,1.9-11.5,2.4-17.3,1.8
	c-1.4-0.1-2.8-0.6-4.2-1C260.1,258.5,259.9,258.1,259.7,257.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M200,55.2c0.4-4.8,0.6-9.6,1.2-14.2c0.7-5,2.5-9.7,4.9-14.1c1.3-2.4,2.8-4.6,4.5-6.8c1.7-2.2,3.9-3.8,6.4-5
	c0.9-0.5,1.7-1.2,2.6-1.8c2.2-1.4,4.6-2.4,7.1-3.1c3-0.8,6.1-1.2,9.2-0.8c2.5,0.3,4.9,1.1,7.4,1.6c2.8,0.5,5.2,1.9,7.7,3.3
	c0.5,0.3,1,0.7,1.5,1.1c2.3,1.7,4.6,3.4,6.7,5.3c1.5,1.4,2.8,3.1,4.1,4.7c1.4,1.7,2.9,3.3,4.1,5.1c1.6,2.6,2.9,5.5,4.5,8.2
	c1.6,2.9,2.3,6.2,3.3,9.3c0.5,1.7,0.5,3.5,0.7,5.2c0.2,1.5,0.3,3,0.4,4.5c0.3,4.1,0,8.1-1.1,12.2c-1.7,6.5-5.1,12-9.1,17.2
	c-2.5,3.2-5.2,6.2-8.5,8.7c-3.1,2.3-6.4,4.3-10.1,5.5c-1.6,0.5-3.2,1.1-4.9,1.6c-0.6,0.2-1.4,0.1-1.9-0.2c-2.2-1.5-4.4-2-6.7-0.5
	c-0.3,0.2-0.8,0.2-1.1,0.2c-1.1-0.3-2.1-0.7-3.2-0.9c-2.9-0.7-5.3-2.2-7.6-4c-2.7-2.1-5.3-4.1-8-6.2c-0.5-0.4-0.7-0.9-1.1-1.4
	c-1-1.2-2-2.3-3-3.5c-1-1.3-2.2-2.6-2.9-4.1c-1.2-2.4-2.2-4.8-3-7.3c-1.2-3.8-2.3-7.7-3.2-11.6C200.4,60.6,200.3,57.9,200,55.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M199.9,190.3c-2.8,0.7-5.6,0.8-8.2,0.1c-3-0.7-5.9-1.7-8.8-2.9c-2.8-1.1-5.4-2.7-7.6-4.8
	c-1.8-1.7-3.8-3.2-5.5-5c-0.9-1-1.3-2.4-1.8-3.6c-1-2.4-1.8-4.8-2.7-7.2c-1.9-4.9-3.2-9.8-2.8-15.1c0.1-0.8,0.2-1.6,0.3-2.4
	c0.1-0.5,0.3-0.9,0.9-1.2c0.9-0.4,1.6-1.2,2.2-2c0.6-0.7-0.1-2.1-1.3-2.5c-1.3-0.4-1.4-1.1-1.1-2.2c0.8-2.7,1.5-5.5,2.5-8.2
	c0.4-1,1.5-1.7,2.4-2.6c1.7-1.7,3.3-3.6,5.1-5.2c2.1-1.9,3.5-4.3,4.8-6.7c0.8-1.4,1.6-2.9,2.5-4.2c0.5-0.8,1.8-1.2,2.8-1.7
	c3.6-1.7,7.3-2.9,11.2-2.6c2,0.2,4,0.6,5.9,1.2c2.4,0.7,4.9,1.4,7.1,2.5c2.2,1.2,4.2,2.8,6.2,4.4c1.4,1.1,2.7,2.5,3.8,3.9
	c1.3,1.5,2.4,3.1,3.5,4.6c2.6,3.5,3.9,7.6,5.6,11.5c1.8,4.1,2.4,8.4,3,12.8c0.2,1.8,0.5,3.6,0.5,5.4c0,1.6-0.6,3.2-0.7,4.8
	c-0.2,7.1-3.5,12.9-7.8,18.1c-4.4,5.3-10.3,8.6-16.9,10.4c-1.3,0.4-2.6,0.5-3.8,0.9C200.4,190.9,199.9,191.2,199.9,190.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M258.1,261.8c-0.7,0.5-0.7,1-0.2,1.7c0.5,0.8,0.6,1.8,0.9,2.7c0.7,2.1,1.3,4.1,2.1,6.2c0.3,0.9,0,1.3-0.9,1.1
	c-0.5-0.1-1.1-0.2-1.6-0.5c-1.7-0.9-3.4-1.8-5-2.7c0.5-0.8,0.9-1.3,1.2-1.9c-0.1-0.1-0.2-0.2-0.3-0.3c-0.8,1.1-2.5,0.5-3.3,1.9
	c-0.2,0.3-1.1,0.5-1.7,0.4c-0.8-0.1-1.6-0.5-2.5-0.8c-2.2-0.8-2.3-3.4-1-4.8c1.2-1.2,2.4-2.6,3.6-3.8c0.3-0.3,1-0.4,1.3-0.3
	C253.1,262.1,255.4,263.2,258.1,261.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M200.2,203.7c0.3-0.6,0.4-1.1,0.7-1.8c-1.5,0.4-2.6,0.6-3.5,1.6c-0.4,0.5-1.9,0.3-2.9,0.1
	c-0.5-0.1-1.1-1-1.1-1.4c0.1-1.6,0.4-3.3,0.9-4.8c0.4-1.2,1.8-1.3,2.7-0.4c0.1,0.1,0.2,0.1,0.1,0.1c0.7-0.3,1.3-0.5,1.8-0.7
	c0.5-0.2,1-0.4,1.4-0.6c1.1-0.7,1.6-0.6,2.3,0.5c0.7,1.1,1.5,2.2,2.2,3.2c0.7,1,1.8,2,1.2,3.1c-0.6,1.4-2.2,1.5-3.5,1.5
	C201.9,204.2,201,203.9,200.2,203.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M150.9,149.8c0.6-1.8,1.3-3.4,1.6-5c0.4-1.8,1.2-3.4,2-5.1c0.2-0.3-0.4-1-0.6-1.7c0.9,0,1.9,0,2.8,0
	c0.9,3.5,3.2,5.4,6.4,6.5C159.5,147.2,155.4,148.5,150.9,149.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M234.5,110.3c0.3-1,0.5-2.2,1-3.4c0.2-0.4,0.9-0.6,1.4-0.7c0.6-0.1,1.3,0,1.9,0c2.2,2.5,4.9,4.8,6.1,8.2
	c-1.7-1-3.2-2.2-5-2.8C238.2,110.9,236.3,110.7,234.5,110.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M233.9,112.4c3.1,0.3,6.1,1,8.8,3.2c-0.6,0-0.9,0.1-1.2,0c-2.3-0.5-4.6-1-6.8-1.7c-0.4-0.1-0.6-0.7-0.9-1.1
	C233.8,112.7,233.8,112.5,233.9,112.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M252.1,256.3c2.1,1.3,4,2.4,6.1,3.7C255.3,261.4,252.1,259.3,252.1,256.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M200,192.5c-0.8,0.7-1.5,1.4-2.3,2c-0.3,0.2-0.8,0.4-1.1,0.3c-1.2-0.6-2.3-1.3-3.5-2c0.1-0.1,0.1-0.2,0.2-0.3
	C195.6,192.5,197.8,192.5,200,192.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M157.9,134.9c-1.8,0.5-3.6,1-5.5,1.5c0-0.1-0.1-0.2-0.1-0.3c1.6-1.3,3.3-2.2,5.4-1.6
	C157.8,134.6,157.8,134.8,157.9,134.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M201.9,193.1c-0.7,0.7-1.5,1.3-2.2,2c-0.1-0.1-0.2-0.2-0.2-0.3c0.7-0.7,1-1.9,2.2-2.1
	C201.7,192.9,201.8,193,201.9,193.1z" />
      </SvgIcon>
    )
  }

};