import ArrowDrawable from "./ArrowDrawable";
import { Text } from "react-konva";
import React from "react";

export default class TextBox extends ArrowDrawable {
  drawableType = 'TextBox';
  defaultSize = {
    w: 100,
    h: 50,
    fontSize: 8
  }
  text = '';

  constructor(startx, starty, color, weight, opacity) {
    super(startx, starty, color, weight, opacity);
    const { w, h, fontSize } = this.defaultSize;
    this.initProperties(w, h, fontSize, startx, starty, color, weight);
  }

  initProperties = (w, h, fontSize, startx, starty, color, weight, align?) => {
    this.x = startx + w;
    this.y = starty + h;
    this.color = color;
    this.fontSize = fontSize * weight;
    this.text = '';
    if (align) {
      this.align = align;
    }
  }

  render() {
    const dx = this.x - this.startx;
    return (
      <Text
        x={this.startx}
        y={this.starty}
        width={dx}
        text={this.text}
        fill={this.color}
        fontSize={this.fontSize}
        align={this.align}
        opacity={this.opacity}
      />
    );
  }
}