import React, { useEffect, useRef, useState } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import NoSsr from '@material-ui/core/NoSsr';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Backdrop,
  Box,
  CircularProgress,
  Container,
  IconButton, InputBase,
  ListItem, Paper,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  DeveloperMode,
  Search
} from "@material-ui/icons";
import { connect } from 'react-redux';
import {
  changeClient,
  copySelectedShape,
  draggableImageMoved,
  dragImgEnable,
  drawingArrow,
  drawingCircle,
  drawingCurve,
  drawingEllipse,
  drawingErase,
  drawingLine,
  drawingRect,
  redo,
  saveChanged,
  saveChangedDevMode,
  setMaskImages,
  setMaskText,
  setPageChangedState,
  setPageChangedStateText,
  showAllDrawables,
  showDivider,
  showAdvancedToolbar,
  undo,
  setPageToTaskContentChange,
  setOpacity,
  setMicrophone,
  SVGImageChange,
  enableSVGClick
} from './actions';
import MenuButton from "./components/MenuButton";
import _ from "lodash";
import IconButtonCorrect from "./components/IconButtonCorrect";
import Eraser from "./icons/Eraser";
import Move from "./icons/Move";
import Pencil from "./icons/Pencil";
import uuid from "uuid-random";
import { ACTION_NAMES, ACTION_TOOLTIPS, DEFAULT_FOLDER_PATH, DIALOGS, PLACEHOLDERS } from "./util/APP_CONSTANS";
import Ellipse from "./icons/Ellipse";
import ModalDialog from "./components/ModalDialog";
import { getPage } from "./editions/Util";
import Axios from "./ajax/Axios";
import Line from "./icons/Line";
import ColorPalette from "./icons/ColorPalette";
import ArrowRightAlt from "./icons/ArrowRightAlt";
import CurveLine from "./icons/CurveLine";
import Circle from "./icons/Circle";
import Rectangle from "./icons/Rectangle";
import ColorIndicator from "./icons/ColorIndicator";
import Undo from "./icons/Undo";
import Redo from "./icons/Redo";
import PreviousPage from "./icons/PreviousPage";
import NextPage from "./icons/NextPage";
import Save from "./icons/Save";
import Copy from "./icons/Copy";
import LineWeight from "./icons/LineWeight";
import NoNavigation from "./editions/NoNavigation";
import Content from "./editions/Content";
import AdvancedToolbar from "./tools/AdvancedToolbar";
import { SvgIcon } from '@material-ui/core';
import AdvancedToolbarIcon from '../public/images/tools/AdvancedToolbar.svg';
import './App.css';
import KeyboardEventHandler from 'react-keyboard-event-handler';
// @ts-ignore
import { useHistory } from "react-router-dom";
import HelpVideoContentMenu from "./components/HelpVideoContentMenu";
import SVGClick from "./icons/SVGClick";
import PositionViewer from "./tools/PositionViewer";

export const zIndexTooltip = 1300;

const theme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      root: {
        backgroundImage: "url(images/tbarBackground.svg)",
        backgroundSize: "cover"
      },
      colorPrimary: {
        color: "#064B5E",
        backgroundColor: "transparent"//"#3f51b5"
      },
      positionFixed: {
        position: 'fixed',
        left: '50%',
        top: 0,
        transform: 'translate(-50%, -50%)',
        width: 'inherit',
        zIndex: zIndexTooltip + 1
      }
    },
    MuiSvgIcon: {
      root: {
        width: '1.1em',
        height: '1.1em'
      }
    },
    // MuiPaper: {
    //   elevation4: {
    //     boxShadow: "none"
    //   }
    // },
    MuiToolbar: {
      gutters: {
        paddingLeft: "12px !important",
        paddingRight: "12px !important"
      }
    },
    MuiListItem: {
      root: {
        paddingTop: 1,
        paddingBottom: 1,
      }
    },
    MuiContainer: {
      maxWidthMd: {
        maxWidth: process.env.NODE_ENV !== 'production' ? '1080px !important' : '1050px !important'
      }
    }
  }
});
theme.zIndex.modal = zIndexTooltip + 1;
theme.zIndex.tooltip = zIndexTooltip;

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 0px 0px 0px',
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginLeft: theme.spacing(1),
    width: 28
  },
  iconButton: {
    padding: 3,
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff',
  },
  menuButton: {
    marginRight: theme.spacing(0),
    marginLeft: 0
  },
  menuButtonPrev: {
    padding: "7px 3px 7px 3px"
  },
  menuButtonNext: {
    padding: "7px 3px 7px 3px"
  },
  menuButtonSave: {
    padding: "7px 0px 7px 3px"
  },
  title: {
    flexGrow: 1,
  }
}));

export const appColors: any = {
  icon: {
    drawing: {
      primary: "",
      toggle: "#3f51b5"
    }
  }
}


const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

const useCurrentWidth = (task) => {
  let [width, setWidth] = useState(getWidth());
  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setWidth(getWidth())
        setAppBarSize(undefined, false, task);
      }, 150);
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  return width;
}

const getStateConf = (width: number) => {
  const conf = width > 700 ? {
    flexWrap: 'nowrap',
    flexBasisLeft: '50%',
    flexBasisRight: '50%'
  } : {
    flexWrap: 'wrap',
    flexBasisLeft: 'auto',
    flexBasisRight: 'auto'
  };
  return conf;
}

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const getMenu = (flexWrap, classes, dispatch, anchorEl, setAnchorEl, state, setState, dragImgEnabled, pageSelectedShape, color, showedAdvancedToolbar, pageEditable, SVGClickEditable) => {
  const handleClose = () => {
    setAnchorEl(null);
  };

  let menu;
  const isShowAllDrawables = state.showAllDrawables;
  const disabledUndo = state.disabledUndo;
  const disabledRedo = state.disabledRedo;
  if (flexWrap === 'wrap') {
    menu = <><MenuButton />
      <Typography variant="h6" className={classes.title}>
        Meni
      </Typography></>
  } else {
    menu = <div style={{ flexGrow: 1 }}>
      <Tooltip title={ACTION_TOOLTIPS.SHOW_DRAWABLES}>
        <IconButtonCorrect
          disabled={!pageEditable}
          edge={"start"}
          toggle={state.showAllDrawables}
          onClick={() => {
            dispatch(showAllDrawables(!isShowAllDrawables));
          }}
        >
          <Pencil
            disabled={!pageEditable}
          />
        </IconButtonCorrect>
      </Tooltip>
      <Tooltip title={ACTION_TOOLTIPS.SHOW_LINE}>
        <IconButtonCorrect
          toggle={state.drawing.line && isShowAllDrawables}
          disabled={!isShowAllDrawables}
          onClick={() => {
            dispatch(drawingLine());
          }}
        >
          <Line
            disabled={!isShowAllDrawables}
          />
        </IconButtonCorrect>
      </Tooltip>
      <Tooltip title={ACTION_TOOLTIPS.SHOW_ARROW}>
        <IconButtonCorrect
          toggle={state.drawing.arrow && isShowAllDrawables}
          disabled={!isShowAllDrawables}
          onClick={() => {
            dispatch(drawingArrow());
          }}
        >
          <ArrowRightAlt
            disabled={!isShowAllDrawables}
          />
        </IconButtonCorrect>
      </Tooltip>
      <Tooltip title={ACTION_TOOLTIPS.SHOW_CURVE}>
        <IconButtonCorrect
          toggle={state.drawing.curve && isShowAllDrawables}
          disabled={!isShowAllDrawables}
          onClick={() => {
            dispatch(drawingCurve());
          }}
        >
          <CurveLine
            disabled={!isShowAllDrawables}
          />
        </IconButtonCorrect>
      </Tooltip>
      <Tooltip title={ACTION_TOOLTIPS.SHOW_ADVANCED_TOOLBAR}>
        <IconButtonCorrect
          toggle={showedAdvancedToolbar && isShowAllDrawables}
          disabled={!isShowAllDrawables}
          onClick={() => {
            dispatch(showAdvancedToolbar(!showedAdvancedToolbar));
          }}
        >
          <img
            src={"images/tools/AdvancedToolbar.svg"}
            className={"MuiSvgIcon-root"}
            style={{ opacity: isShowAllDrawables ? 1 : 0.5 }}
          />
        </IconButtonCorrect>
      </Tooltip>
      <Tooltip title={ACTION_TOOLTIPS.SHOW_ERASE}>
        <IconButtonCorrect
          toggle={state.drawing.erase && isShowAllDrawables}
          disabled={!isShowAllDrawables}
          onClick={() => {
            dispatch(drawingErase());
          }}
        >
          <Eraser
            disabled={!isShowAllDrawables}
          />
        </IconButtonCorrect>
      </Tooltip>
      <Tooltip title={ACTION_TOOLTIPS.COLOR_PALETTE}>
        <IconButtonCorrect
          disabled={!isShowAllDrawables}
        >
          <ColorPalette
            w={120}
            h={48}
            disabled={!isShowAllDrawables}
            dispatch={dispatch}
          />
        </IconButtonCorrect>
      </Tooltip>
      <Tooltip title={ACTION_TOOLTIPS.COLOR_INDICATOR}>
        <IconButtonCorrect
          disabled={!isShowAllDrawables}
        >
          <ColorIndicator
            disabled={!isShowAllDrawables}
            colorInd={color}
          />
        </IconButtonCorrect>
      </Tooltip>
      {SVGClickEditable && <Tooltip title={ACTION_TOOLTIPS.SVG_CLICK}>
        <IconButtonCorrect
          toggle={state.enabledSVGClick && isShowAllDrawables}
          disabled={!isShowAllDrawables}
          onClick={() => {
            dispatch(enableSVGClick(!state.enabledSVGClick));
          }}
        >
          <SVGClick
            disabled={!isShowAllDrawables}
          />
        </IconButtonCorrect>
      </Tooltip>}
      <Tooltip title={ACTION_TOOLTIPS.COLOR_OPACITY}>
        <IconButtonCorrect
          toggle={state.drawing.opacity && isShowAllDrawables}
          disabled={!isShowAllDrawables}
          onClick={() => {
            dispatch(setOpacity(!state.drawing.opacity));
          }}
        >
          <img
            src={"images/icons/opacity.png"}
            className={"MuiSvgIcon-root"}
            style={{ opacity: !isShowAllDrawables ? 0.5 : 1 }}
          />
        </IconButtonCorrect>
      </Tooltip>
      <Tooltip title={ACTION_TOOLTIPS.CHANGE_WEIGHT}>
        <IconButtonCorrect
          disabled={!isShowAllDrawables}
        >
          <LineWeight
            w={72}
            h={48}
            disabled={!isShowAllDrawables}
          />
        </IconButtonCorrect>
      </Tooltip>
      <Tooltip title={ACTION_TOOLTIPS.UNDO}>
        <IconButtonCorrect
          disabled={disabledUndo}
          onClick={() => {
            dispatch(undo());
          }}
        >
          <Undo
            disabled={disabledUndo}
          />
        </IconButtonCorrect>
      </Tooltip>
      <Tooltip title={ACTION_TOOLTIPS.REDO}>
        <IconButtonCorrect
          disabled={disabledRedo}
          onClick={() => {
            dispatch(redo());
          }}
        >
          <Redo
            disabled={disabledRedo}
          />
        </IconButtonCorrect>
      </Tooltip>
      <Tooltip title={ACTION_TOOLTIPS.DRAG_IMG_ENABLE}>
        <IconButtonCorrect
          disabled={!pageEditable}
          toggle={dragImgEnabled}
          onClick={() => {
            const isDragImgEnabled = !dragImgEnabled;
            dispatch(dragImgEnable(isDragImgEnabled));
          }}
        >
          <Move
            disabled={!pageEditable}
          />
        </IconButtonCorrect>
      </Tooltip>
      <Tooltip title={ACTION_TOOLTIPS.COPY_SELECTED_SHAPE}>
        <IconButtonCorrect
          disabled={!pageSelectedShape}
          toggle={false}
          onClick={() => {
            dispatch(copySelectedShape(uuid()))
          }}
        >
          <Copy
            disabled={!pageSelectedShape}
          />
        </IconButtonCorrect>
      </Tooltip>
    </div>;
  }
  return menu;
}

export const getStateDrawables = (drawables: any, initialState: any) => {
  if (!_.isEmpty(drawables)) {
    const showAllDrawables = drawables.showDrawables;
    const drawablesProps = { ...initialState };
    const enabledSVGClick = drawables.enabledSVGClick;
    if (showAllDrawables) {
      if (!enabledSVGClick) {
        switch (drawables.drawableType) {
          case 'LineDrawable':
            drawablesProps.drawing.line = true;
            break;
          case 'FreePathDrawable':
            drawablesProps.drawing.curve = true;
            break;
          case 'CircleDrawable':
            drawablesProps.drawing.circle = true;
            break;
          case 'ArrowDrawable':
            drawablesProps.drawing.arrow = true;
            break;
          case 'RectangleDrawable':
            drawablesProps.drawing.rectangle = true;
            break;
          case 'TextBox':
            drawablesProps.drawing.text = true;
            break;
          case 'EllipseDrawable':
            drawablesProps.drawing.ellipse = true;
            break;
          case 'EraseDrawable':
            drawablesProps.drawing.erase = true;
            break;
        }
      } else {
        drawablesProps.enabledSVGClick = true;
      }
      drawablesProps.drawing.color = drawables.color;
      drawablesProps.drawing.weight = drawables.weight;
      drawablesProps.drawing.opacity = !!drawables.opacity;
    }
    drawablesProps.showAllDrawables = showAllDrawables;
    drawablesProps.disabledUndo = !(drawables.pageDrawState && drawables.pageDrawState.length);
    drawablesProps.disabledRedo = !(drawables.undoPageDrawState && drawables.undoPageDrawState.length);
    return drawablesProps;
  }
}

let firstTimeOpenedDialog = false;

export const setAppBarSize = (action?, force: boolean = false, task = null) => {
  const appBarSufix = task ? '_task' : '';
  const appBarBox = document.getElementById('app_bar_box' + appBarSufix);
  const appBar = document.getElementById('app_bar' + appBarSufix);
  const appBarMarginDiv = document.getElementById('app_bar_margin_div' + appBarSufix);
  if (appBar && appBarBox && appBarMarginDiv) {
    if (_.isUndefined(action)) {
      appBar.style.width = appBarBox.offsetWidth + "px";
      appBar.style.top = (parseInt(String(appBar.offsetHeight / 2)) + 5) + "px";
      appBarMarginDiv.style.height = (appBar.offsetHeight + 10) + "px";
      appBar.style.left = null;
    } else if (firstTimeOpenedDialog || force) {
      if (action === 'beforeOpenedDialog') {
        const left = appBar.offsetLeft;
        appBar.style.left = left + "px";
      } else if (action === 'openedDialog') {
        appBar.style.paddingRight = '0px';//default behavior 15px
      }
    }
  } else {
    setTimeout(() => {
      setAppBarSize(action, force, task);
    }, 100)
  }
}

let totalPages = 80;
let enabledMicrophone = false;


const afterCreateUserFolders = (res, setBackDropOpen) => {
  const data = res.data;
  totalPages = data.totalPages;
  enabledMicrophone = data.microphone;
  setBackDropOpen(false);
}

const nextPreviousPage = (type, currentPage, pageDrawState, pageChangedStateText, isDraggableImageMoved, setOpenedModalDialog, dispatch, setSearchPage, setPage, isSVGImageChanged) => {
  nextPage = type === 'next' ? ++currentPage : --currentPage;
  if ((pageDrawState && pageDrawState.length) || pageChangedStateText || isDraggableImageMoved || isSVGImageChanged) {
    setOpenedModalDialog(true);
  } else {
    changePage(nextPage, dispatch, setSearchPage, setPage);
  }
}

export const changePage = (page, dispatch, setSearchPage, setPage) => {
  if (page < 1) {
    page = 1;
  } else if (page > totalPages) {
    page = totalPages
  }
  dispatch(setPageChangedState());
  dispatch(setPageChangedStateText());
  dispatch(draggableImageMoved());
  dispatch(SVGImageChange());
  setSearchPage(page);
  setPage(page);
}

export const forceTask = (dispatch, task, history, pageToTaskContentChanged?) => {
  dispatch(setPageChangedState());
  dispatch(setPageChangedStateText());
  dispatch(draggableImageMoved());
  if (pageToTaskContentChanged) {
    const pageToTaskContentChangedClone = _.cloneDeep(pageToTaskContentChanged);
    dispatch(setPageToTaskContentChange());
    history.push(`${DEFAULT_FOLDER_PATH}/task`, { task: pageToTaskContentChangedClone });
  } else {
    history.push(`${DEFAULT_FOLDER_PATH}/app`, { pageFromTask: task.page });
  }
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

window.addEventListener('load', () => {
  setTimeout(() => {
    window.scrollTo(window.scrollX, 0)
  }, 1);
})

let nextPage: number = -1;
let axios: Axios = null;

function App({ dispatch, drawables, userName, edition, task, pageFromTask, expiredLicense }) {
  const {
    dragImgEnabled,
    pageDrawState,
    savedChanged,
    showDrawables,
    saveChanging,
    saveChangingDevMode,
    pageChangedStateText,
    pageSelectedShape,
    isDraggableImageMoved,
    color,
    maskText,
    maskImages,
    showedDivider,
    showedAdvancedToolbar,
    pageEditable,
    pageToTaskContentChanged,
    showedMicrophone,
    isSVGImageChanged,
    enabledSVGClick,
    SVGClickEditable,
    opacity
  } = drawables;
  const history = useHistory();
  const isPageChanged = (pageDrawState && pageDrawState.length) || pageChangedStateText || isDraggableImageMoved || isSVGImageChanged;
  const appBarSufix = task ? '_task' : '';
  const appBarId = "app_bar" + appBarSufix;
  const shouldCreateUserFolders = axios === null && userName !== 'admin';
  const classes = useStyles();
  const { flexBasisLeft, flexWrap, flexBasisRight } = getStateConf(useCurrentWidth(task));
  const client = flexWrap === 'wrap' ? 'mobile' : 'desktop';
  if (drawables.client !== client) {
    dispatch(changeClient(client));
  }
  let [currentPage, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedModalDialog, setOpenedModalDialog] = useState(false);
  const [backDropOpen, setBackDropOpen] = useState(shouldCreateUserFolders);
  if (shouldCreateUserFolders) {
    axios = new Axios(userName, edition);
    axios.postRequest({ action: 'createUserFolders' }, afterCreateUserFolders, null, setBackDropOpen);
  }

  if (dragImgEnabled) {
    document.body.style.cursor = 'crosshair';
  } else {
    document.body.style.cursor = '';
  }

  const initialState = {
    showAllDrawables: false,
    drawing: {
      line: false,
      arrow: false,
      curve: false,
      circle: false,
      ellipse: false,
      rectangle: false,
      text: false,
      erase: false,
      color: 'white',
      weight: 1,
      opacity: false
    },
    disabledUndo: true,
    disabledRedo: true,
    enabledSVGClick: false
  };
  const [state, setState] = React.useState({ ...initialState });

  useEffect(() => {
    const stateDrawables = getStateDrawables(drawables, initialState);
    if (stateDrawables) {
      setAppBarSize(undefined, false, task);
      setState(stateDrawables);
    }
  }, [drawables]);

  if (openedModalDialog && !isPageChanged && !maskText && !maskImages) {
    if (!task) {
      if (pageToTaskContentChanged) {
        const pageToTaskContentChangedClone = _.cloneDeep(pageToTaskContentChanged);
        dispatch(setPageToTaskContentChange());
        history.push(`${DEFAULT_FOLDER_PATH}/task`, { task: pageToTaskContentChangedClone });
      } else {
        changePage(nextPage, dispatch, setSearchPage, setPage);
      }
    } else {
      history.push(`${DEFAULT_FOLDER_PATH}/app`, { pageFromTask: task.page });
    }
    setOpenedModalDialog(false);
  }

  useEffect(() => {
    if (openedModalDialog) {
      firstTimeOpenedDialog = true;
      setAppBarSize('beforeOpenedDialog', false, task);
      setTimeout(() => {
        setAppBarSize('openedDialog', false, task);
      }, 1)
    } else {
      setAppBarSize('closedDialog', false, task);
    }
  }, [openedModalDialog]);

  useEffect(() => {
    window.scrollTo(window.scrollX, 0);
  }, [currentPage]);

  useEffect(() => {
    if (!task && pageFromTask) {
      setSearchPage(pageFromTask);
      setPage(pageFromTask);
    }
  }, [task]);


  const prevPageToTaskContentChanged = usePrevious(pageToTaskContentChanged);
  useEffect(() => {
    if (prevPageToTaskContentChanged !== pageToTaskContentChanged) {
      setOpenedModalDialog(true);
    }
  }, [pageToTaskContentChanged]);

  return (

    <NoSsr>
      <Backdrop className={classes.backdrop} open={userName !== 'admin' && (backDropOpen || maskText || maskImages)}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ThemeProvider theme={theme}>
        <Container
          maxWidth={"md"}
        >
          <Box
            id={"app_bar_box" + appBarSufix}
            color="black"//primary.main
            bgcolor="background.paper"
            fontFamily="h6.fontFamily"
            fontSize={{ xs: 'h6.fontSize', sm: 'h6.fontSize', md: 'h6.fontSize' }}
            //p={{ xs: 2, sm: 3, md: 4 }}
            //style={{ width: '100%' }}
          >
            <div id={"app_bar_margin_div" + appBarSufix} />
            <AppBar
              position="fixed"
              id={appBarId}
            >
              <Toolbar>
                {getMenu(flexWrap, classes, dispatch, anchorEl, setAnchorEl, state, setState, dragImgEnabled, pageSelectedShape, color, showedAdvancedToolbar, pageEditable, SVGClickEditable)}
                <HelpVideoContentMenu />
                {enabledMicrophone && <Tooltip title={ACTION_TOOLTIPS.AUDIO_PLAYER}>
                  <IconButtonCorrect
                    toggle={showedMicrophone}
                    // disabled={!pageEditable}
                    onClick={() => {
                      dispatch(setMicrophone(!showedMicrophone));
                    }}
                  >
                    <img
                      src={"images/icons/microphone.png"}
                      className={"MuiSvgIcon-root"}
                      // style={{ opacity: !pageEditable ? 0.5 : 1 }}
                    />
                  </IconButtonCorrect>
                </Tooltip>}
                <Tooltip title={ACTION_TOOLTIPS.SAVE_CHANGING}>
                  <IconButton
                    disabled={!pageEditable}
                    className={`${classes.menuButton} ${classes.menuButtonSave}`}
                    color="inherit"
                    aria-label="save"
                    onClick={() => {
                      dispatch(setMaskImages(true));
                      dispatch(setMaskText(true));
                      dispatch(saveChanged(uuid()));
                      dispatch(draggableImageMoved());
                      dispatch(setPageChangedState());
                    }}
                  >
                    <Save
                      disabled={!pageEditable}
                    />
                  </IconButton>
                </Tooltip>
                {task && <Tooltip title={ACTION_TOOLTIPS.BACK}>
                  <IconButton
                    className={`${classes.menuButton} ${classes.menuButtonSave}`}
                    color="inherit"
                    aria-label="help"
                    onClick={() => {
                      if (isPageChanged) {
                        setOpenedModalDialog(true);
                      } else {
                        history.push(`${DEFAULT_FOLDER_PATH}/app`, { pageFromTask: task.page })
                      }
                    }}
                  >
                    <img src={"images/tools/Back.png"} className={"MuiSvgIcon-root"} />
                  </IconButton>
                </Tooltip>}
                {!task &&
                <>
                  <Tooltip title={ACTION_TOOLTIPS.PREVIOUS_PAGE}>
                    <IconButton
                      edge="start"
                      className={`${classes.menuButton} ${classes.menuButtonPrev}`}
                      color="inherit"
                      aria-label="prethodna strana"
                      onClick={() => {
                        nextPreviousPage('previous', currentPage, pageDrawState, pageChangedStateText, isDraggableImageMoved, setOpenedModalDialog, dispatch, setSearchPage, setPage, isSVGImageChanged);
                      }}
                    >
                      <PreviousPage />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={ACTION_TOOLTIPS.SEARCH_PAGE}>
                    <Paper component="form" className={classes.root} style={{ display: "inline" }}>
                      <InputBase
                        className={classes.input}
                        placeholder={PLACEHOLDERS.SEARCH_PAGE}
                        inputProps={{ 'aria-label': 'Izaberi stranu direktno' }}
                        value={searchPage}
                        onChange={(e) => {
                          let value: any = e.target.value;
                          if (value) {
                            value = parseInt(value);
                          }
                          setSearchPage(value);
                        }}
                      />
                      <IconButton
                        type="submit"
                        className={classes.iconButton}
                        aria-label="search"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          nextPage = searchPage;
                          if ((pageDrawState && pageDrawState.length) || pageChangedStateText || isDraggableImageMoved) {
                            setOpenedModalDialog(true);
                          } else {
                            changePage(nextPage, dispatch, setSearchPage, setPage);
                          }
                        }}
                      >
                        <Search />
                      </IconButton>
                    </Paper>
                  </Tooltip>
                  <Tooltip title={ACTION_TOOLTIPS.NEXT_PAGE}>
                    <IconButton
                      className={`${classes.menuButton} ${classes.menuButtonNext}`}
                      color="inherit"
                      aria-label="sledeca strana"
                      onClick={() => {
                        nextPreviousPage('next', currentPage, pageDrawState, pageChangedStateText, isDraggableImageMoved, setOpenedModalDialog, dispatch, setSearchPage, setPage, isSVGImageChanged);
                      }}
                    >
                      <NextPage />
                    </IconButton>
                  </Tooltip>
                  <Typography variant="h6" style={{ display: "inline" }}>
                    od {totalPages}
                  </Typography>
                  <Content
                    dispatch={dispatch}
                    edition={edition}
                    setSearchPage={setSearchPage}
                    setPage={setPage}
                  >
                  </Content>
                </>
                }
                {process.env.NODE_ENV !== 'production' &&
                <Tooltip title={ACTION_TOOLTIPS.SAVE_CHANGING_DEV_MODE}>
                  <IconButton
                    className={`${classes.menuButton} ${classes.menuButtonSave}`}
                    color="inherit"
                    aria-label="save dev"
                    onClick={() => {
                      dispatch(saveChangedDevMode(uuid()))
                    }}
                  >
                    <DeveloperMode />
                  </IconButton>
                </Tooltip>}

              </Toolbar>
            </AppBar>
            {showedAdvancedToolbar && showDrawables && <AdvancedToolbar
              appBarId={appBarId}
            />}
            {process.env.NODE_ENV !== 'production' && <PositionViewer />}
            {getPage(currentPage, {
              flexBasisLeft,
              flexWrap,
              flexBasisRight,
              showDrawables,
              saveChanging,
              saveChangingDevMode,
              showedMicrophone,
              color,
              opacity,
              enabledSVGClick,
              dispatch
            }, edition, userName, setPage, setSearchPage, isPageChanged, task)}
          </Box>
        </Container>
        <ModalDialog
          opened={openedModalDialog}
          title={DIALOGS.SAVE_CHANGES.TITLE}
          content1={DIALOGS.SAVE_CHANGES.CONTENT1}
          content2={DIALOGS.SAVE_CHANGES.CONTENT2}
          button1Name={ACTION_NAMES.NO}
          button2Name={ACTION_NAMES.SAVE_CHANGES}
          button2Disabled={expiredLicense}
          button1Action={() => {
            if (task) {
              forceTask(dispatch, task, history);
            } else if (pageToTaskContentChanged) {
              forceTask(dispatch, task, history, pageToTaskContentChanged);
            } else {
              changePage(nextPage, dispatch, setSearchPage, setPage);
            }
            setOpenedModalDialog(false);
          }}
          button2Action={() => {
            dispatch(setMaskImages(true));
            dispatch(setMaskText(true));
            dispatch(saveChanged(uuid()));
            dispatch(draggableImageMoved());
            dispatch(setPageChangedState());
            dispatch(SVGImageChange());
          }}
          onClose={() => setOpenedModalDialog(false)}
        />
      </ThemeProvider>
      <KeyboardEventHandler
        handleKeys={['right', 'left', 'CTRL+C']}
        handleFocusableElements={true}
        onKeyEvent={(key, e) => {
          if (!(e.target.nodeName.toLowerCase() === 'input' && e.target.type.toLowerCase() === 'text')) {
            let type;
            if (key === 'right' || key === 'left') {
              if (key === 'right') {
                type = 'next';
              } else if (key === 'left') {
                type = 'previous';
              }
              nextPreviousPage(type, currentPage, pageDrawState, pageChangedStateText, isDraggableImageMoved, setOpenedModalDialog, dispatch, setSearchPage, setPage, isSVGImageChanged);
            } else {
              dispatch(copySelectedShape(uuid()))
            }
          }
        }}
      />
    </NoSsr>
  );
}

const getVisibleDrawables = (drawables) => {
  return drawables;
}

const mapStateToProps = state => ({
  drawables: getVisibleDrawables(state.drawables)
})

export default connect(
  mapStateToProps
)(App)