import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_21_2 extends SVG {

  render() {
    let { color, borderColor = 'black', opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 198.5 185.3"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M54.8,153.6c8.6,0.2,17.1,0.5,25.8,0.7c0.1,1.4,0.3,2.8,0.4,4.2c0.2,5.2,0.3,10.5,0.4,15.7c0,2.1,0,4.2,0,6.3
	c0,0.8-0.2,1.3-1.2,1.3c-4.6,0-9.2,0.1-13.9,0.1c-2.3,0-4.7-0.2-7-0.2c-0.8,0-1.6,0.2-2.4,0.2c-1.5,0.1-1.9-0.4-1.9-1.8
	c-0.1-6.3-0.3-12.6-0.4-18.9c0-1.7,0.2-3.4,0.3-5.1C54.8,155.4,54.8,154.6,54.8,153.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M116.1,153.6c8.5,0.2,16.9,0.5,25.6,0.7c0.1,1.2,0.4,2.4,0.4,3.6c0.2,5.6,0.3,11.2,0.4,16.8c0,2,0,4-0.1,6
	c0,0.8-0.3,1.2-1.2,1.2c-4.6,0-9.2,0.1-13.9,0.1c-2.3,0-4.7-0.2-7-0.2c-0.9,0-1.7,0.2-2.6,0.3c-1.2,0-1.7-0.4-1.7-1.7
	c-0.2-6.4-0.4-12.8-0.4-19.3C115.6,158.7,115.9,156.3,116.1,153.6z" />
        <g>
          <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
                fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
            this.setState({ color3: color, opacity3: opacity });
          }} d="M60.7,126c2,0.8,4,0.8,5.8-0.4c0.5-0.3,0.8-1.1,1.1-1.7c0.3-0.6,0.4-1.3,0.7-1.8c0.6-1.1,1.5-1.5,2.7-1.1
		c1.9,0.6,3.4-0.3,4.6-1.5c1.2-1.2,1.2-1.5,0.2-2.5c0.2-0.8,0.5-1.6,0.3-2c-0.2-0.5-1-0.8-1.6-0.9c-0.5-0.1-1.1,0.1-1.7,0.1
		c-1.2,0.1-2.2-0.1-2.9-1.2c-0.6-0.9-1.3-1-2.5-0.8c-2,0.4-4,0.5-6,0.6c-1.1,0-1.5-0.8-1.3-1.9c0.2-1.1-0.5-1.6-1.3-1.9
		c-2.2-0.8-4.4-0.4-6.6,0.4c-1.4,0.5-2.7,0.4-4,0.1c-0.3-0.1-0.7-0.5-0.8-0.9c-0.4-1.4-1.2-2.2-2.6-2c-2.1,0.3-4.2,1-6.2,1.6
		c-0.5,0.1-1.1,0.6-1.2,1.1c-0.5,1.5-1.7,2-2.9,2.5c-0.8,0.1-1.7,0.3-2.5,0.2c-0.4,0-0.7-0.5-1.1-0.7c0.1,0,0.2,0,0.3,0
		c-0.2-0.1-0.3-0.2-0.5-0.3c0,0.1,0,0.1,0,0.2c-0.2-0.1-0.4-0.1-0.6-0.2c-2-1.3-5.1-0.8-6.1,1.3c-1.8,3.9-5,4.4-8.7,4.1
		c-2.2-0.2-4.2,0.2-5.6,2.2c-0.9,1.2-0.8,2.1,0.5,2.9c0.6,0.4,1.2,0.6,1.7,1c0.5,0.4,1.2,1,1.2,1.5c0,0.6-0.7,1.3-1.2,1.6
		c-1.1,0.5-2.3,0.6-3.5,0.8c-0.7,0.1-1.4,0.1-2,0.1c-2,0.3-2.5,0.7-2.2,3.1c0.3,2.7,2.7,4.3,5.6,4.3c2,0,3.3-1.1,4.9-1.8
		c1.1-0.5,2.3-1,3.4-1.4c1.1-0.4,2.3-0.9,3.5-1.3c-2.2-2-2-4.7-2.6-7.2c0.1,0.2,0.3,0.3,0.4,0.5c0.4,1.3,0.7,2.6,1.3,3.8
		c0.4,0.8,1,1.5,1.6,2.1c0.1,0.1,0.5,0.2,0.7,0.1c2-0.7,4.1-1.4,6.1-2.1c-0.2-3.3-0.6-6.7,2.1-9.4c-1.5,3-2,6-1.2,9.3
		c0.5-0.1,0.9-0.2,1.2-0.3c0,0.1,0.1,0.1,0.1,0.2c-1.2,0.5-2.5,1.1-3.7,1.6l-0.1,0.5c1.8,0.7,3.6,1.4,5.4,2.1l-0.1,0.3
		c-1.8-0.6-3.6-1.2-5.4-1.7c-0.6-0.2-1.3-0.2-2-0.3c0,0.1,0.1-0.1,0.4-0.5c-0.5,0.1-0.9,0.1-1.2,0.3c-3.2,1.3-6.5,2.4-9.5,3.9
		c-3.9,2-7.6,4.4-11.4,6.6c-0.5,0.3-1.4,0.6-0.8,1.4c0.6,0.8,1.3,0.2,1.9-0.2c3.5-2.2,7-4.5,10.5-6.7c0.8-0.5,1.6-0.9,2.4-1.2
		c0.5-0.2,0.9-0.4,1.6,0.2c0.9,0.8,2.1,1.2,3.2,1.7c-0.1,0.1-0.1,0.2-0.2,0.3c-1-0.4-2.2-0.7-3.1-1.3c-1.1-0.7-1.9-0.2-2.9,0.4
		c0.4,0.6,1.2,1.1,0.5,1.9c0,0.3,0.1,0.5,0.1,0.8c1.9,2.8,5.2,3.9,8.4,2.5c0.8-0.4,1.7-1.3,1.9-2.1c0.9-3.1,4-3.6,5.7-1.1
		c1.1,1.6,2.6,1.6,4,1.5c1.5-0.1,3-0.7,4.4-1.3c0.6-0.2,1.2-0.9,1.3-1.5c0.3-1.4,1.1-2.3,2.4-2.5c1.1-0.2,2.4-0.1,3.5,0.2
		c2.1,0.5,5.6,0.3,7-1.7c0.3-0.5,0.6-1,0.7-1.6C56.5,126.3,58.4,125.1,60.7,126z M37.6,127.1c-1.2-0.6-2.4-1.1-3.7-1.7
		c0.4-0.1,0.8-0.3,1-0.4c1,0.7,1.9,1.3,2.7,1.9C37.7,127,37.7,127.1,37.6,127.1z M38.8,124.1c-0.2,0.5-1.3,0.6-1.9,0.8
		c-0.1-0.1-0.2-0.2-0.3-0.4c1.5-0.1,1.6-1.2,2.1-2.1c0.7-1.6,1.6-3.1,2.7-4.5C40.5,120,39.8,122.1,38.8,124.1z M44.1,123.3l0,0.3
		c1.7,0.4,3.5,0.8,5.1,1.2c-2.8,1.1-5-1.1-7.7-1.5c0.8-0.2,1.6-0.4,2.5-0.6c1-0.2,1.9-0.6,2.9-0.7c1-0.1,1.5-0.6,2-1.3
		c1.6-2.4,3.6-4.2,6.1-5.7c-1.9,2-3.9,3.9-5.8,5.9l0.3,0.5c4.5-1,8.9-2,13.4-3l0.1,0.3C56.6,120.2,50.4,121.7,44.1,123.3z" />
        </g>
        <g>
          <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
                fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
            this.setState({ color4: color, opacity4: opacity });
          }} d="M160.4,125.8c2,0.8,4,0.8,5.8-0.4c0.5-0.3,0.8-1.1,1.1-1.7c0.3-0.6,0.4-1.3,0.7-1.8c0.6-1.1,1.5-1.5,2.7-1.1
		c1.9,0.6,3.4-0.3,4.6-1.5c1.2-1.2,1.2-1.5,0.2-2.5c0.2-0.8,0.5-1.6,0.3-2c-0.2-0.5-1-0.8-1.6-0.9c-0.5-0.1-1.1,0.1-1.7,0.1
		c-1.2,0.1-2.2-0.1-2.9-1.2c-0.6-0.9-1.3-1-2.5-0.8c-2,0.4-4,0.5-6,0.6c-1.1,0-1.5-0.8-1.3-1.9c0.2-1.1-0.5-1.6-1.3-1.9
		c-2.2-0.8-4.4-0.4-6.6,0.4c-1.4,0.5-2.7,0.4-4,0.1c-0.3-0.1-0.7-0.5-0.8-0.9c-0.4-1.4-1.2-2.2-2.6-2c-2.1,0.3-4.2,1-6.2,1.6
		c-0.5,0.1-1.1,0.6-1.2,1.1c-0.5,1.5-1.7,2-2.9,2.5c-0.8,0.1-1.7,0.3-2.5,0.2c-0.4,0-0.7-0.5-1.1-0.7c0.1,0,0.2,0,0.3,0
		c-0.2-0.1-0.3-0.2-0.5-0.3c0,0.1,0,0.1,0,0.2c-0.2-0.1-0.4-0.1-0.6-0.2c-2-1.3-5.1-0.8-6.1,1.3c-1.8,3.9-5,4.4-8.7,4.1
		c-2.2-0.2-4.2,0.2-5.6,2.2c-0.9,1.2-0.8,2.1,0.5,2.9c0.6,0.4,1.2,0.6,1.7,1c0.5,0.4,1.2,1,1.2,1.5c0,0.6-0.7,1.3-1.2,1.6
		c-1.1,0.5-2.3,0.6-3.5,0.8c-0.7,0.1-1.4,0.1-2,0.1c-2,0.3-2.5,0.7-2.2,3.1c0.3,2.7,2.7,4.3,5.6,4.3c2,0,3.3-1.1,4.9-1.8
		c1.1-0.5,2.3-1,3.4-1.4c1.1-0.4,2.3-0.9,3.5-1.3c-2.2-2-2-4.7-2.6-7.2c0.1,0.2,0.3,0.3,0.4,0.5c0.4,1.3,0.7,2.6,1.3,3.8
		c0.4,0.8,1,1.5,1.6,2.1c0.1,0.1,0.5,0.2,0.7,0.1c2-0.7,4.1-1.4,6.1-2.1c-0.2-3.3-0.6-6.7,2.1-9.4c-1.5,3-2,6-1.2,9.3
		c0.5-0.1,0.9-0.2,1.2-0.3c0,0.1,0.1,0.1,0.1,0.2c-1.2,0.5-2.5,1.1-3.7,1.6l-0.1,0.5c1.8,0.7,3.6,1.4,5.4,2.1l-0.1,0.3
		c-1.8-0.6-3.6-1.2-5.4-1.7c-0.6-0.2-1.3-0.2-2-0.3c0,0.1,0.1-0.1,0.4-0.5c-0.5,0.1-0.9,0.1-1.2,0.3c-3.2,1.3-6.5,2.4-9.5,3.9
		c-3.9,2-7.6,4.4-11.4,6.6c-0.5,0.3-1.4,0.6-0.8,1.4c0.6,0.8,1.3,0.2,1.9-0.2c3.5-2.2,7-4.5,10.5-6.7c0.8-0.5,1.6-0.9,2.4-1.2
		c0.5-0.2,0.9-0.4,1.6,0.2c0.9,0.8,2.1,1.2,3.2,1.7c-0.1,0.1-0.1,0.2-0.2,0.3c-1-0.4-2.2-0.7-3.1-1.3c-1.1-0.7-1.9-0.2-2.9,0.4
		c0.4,0.6,1.2,1.1,0.5,1.9c0,0.3,0.1,0.5,0.1,0.8c1.9,2.8,5.2,3.9,8.4,2.5c0.8-0.4,1.7-1.3,1.9-2.1c0.9-3.1,4-3.6,5.7-1.1
		c1.1,1.6,2.6,1.6,4,1.5c1.5-0.1,3-0.7,4.4-1.3c0.6-0.2,1.2-0.9,1.3-1.5c0.3-1.4,1.1-2.3,2.4-2.5c1.1-0.2,2.4-0.1,3.5,0.2
		c2.1,0.5,5.6,0.3,7-1.7c0.3-0.5,0.6-1,0.7-1.6C156.2,126,158.1,124.9,160.4,125.8z M137.3,126.9c-1.2-0.6-2.4-1.1-3.7-1.7
		c0.4-0.1,0.8-0.3,1-0.4c1,0.7,1.9,1.3,2.7,1.9C137.4,126.8,137.3,126.8,137.3,126.9z M138.4,123.9c-0.2,0.5-1.3,0.6-1.9,0.8
		c-0.1-0.1-0.2-0.2-0.3-0.4c1.5-0.1,1.6-1.2,2.1-2.1c0.7-1.6,1.6-3.1,2.7-4.5C140.1,119.8,139.4,121.9,138.4,123.9z M143.8,123
		l0,0.3c1.7,0.4,3.5,0.8,5.1,1.2c-2.8,1.1-5-1.1-7.7-1.5c0.8-0.2,1.6-0.4,2.5-0.6c1-0.2,1.9-0.6,2.9-0.7c1-0.1,1.5-0.6,2-1.3
		c1.6-2.4,3.6-4.2,6.1-5.7c-1.9,2-3.9,3.9-5.8,5.9l0.3,0.5c4.5-1,8.9-2,13.4-3l0.1,0.3C156.3,120,150,121.5,143.8,123z" />
        </g>
        <g>
          <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
                fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
            this.setState({ color5: color, opacity5: opacity });
          }} d="M158.3,91.2c2,0.8,4,0.8,5.8-0.4c0.5-0.3,0.8-1.1,1.1-1.7c0.3-0.6,0.4-1.3,0.7-1.8c0.6-1.1,1.5-1.5,2.7-1.1
		c1.9,0.6,3.4-0.3,4.6-1.5c1.2-1.2,1.2-1.5,0.2-2.5c0.2-0.8,0.5-1.6,0.3-2c-0.2-0.5-1-0.8-1.6-0.9c-0.5-0.1-1.1,0.1-1.7,0.1
		c-1.2,0.1-2.2-0.1-2.9-1.2c-0.6-0.9-1.3-1-2.5-0.8c-2,0.4-4,0.5-6,0.6c-1.1,0-1.5-0.8-1.3-1.9c0.2-1.1-0.5-1.6-1.3-1.9
		c-2.2-0.8-4.4-0.4-6.6,0.4c-1.4,0.5-2.7,0.4-4,0.1c-0.3-0.1-0.7-0.5-0.8-0.9c-0.4-1.4-1.2-2.2-2.6-2c-2.1,0.3-4.2,1-6.2,1.6
		c-0.5,0.1-1.1,0.6-1.2,1.1c-0.5,1.5-1.7,2-2.9,2.5c-0.8,0.1-1.7,0.3-2.5,0.2c-0.4,0-0.7-0.5-1.1-0.7c0.1,0,0.2,0,0.3,0
		c-0.2-0.1-0.3-0.2-0.5-0.3c0,0.1,0,0.1,0,0.2c-0.2-0.1-0.4-0.1-0.6-0.2c-2-1.3-5.1-0.8-6.1,1.3c-1.8,3.9-5,4.4-8.7,4.1
		c-2.2-0.2-4.2,0.2-5.6,2.2c-0.9,1.2-0.8,2.1,0.5,2.9c0.6,0.4,1.2,0.6,1.7,1c0.5,0.4,1.2,1,1.2,1.5c0,0.6-0.7,1.3-1.2,1.6
		c-1.1,0.5-2.3,0.6-3.5,0.8c-0.7,0.1-1.4,0.1-2,0.1c-2,0.3-2.5,0.7-2.2,3.1c0.3,2.7,2.7,4.3,5.6,4.3c2,0,3.3-1.1,4.9-1.8
		c1.1-0.5,2.3-1,3.4-1.4c1.1-0.4,2.3-0.9,3.5-1.3c-2.2-2-2-4.7-2.6-7.2c0.1,0.2,0.3,0.3,0.4,0.5c0.4,1.3,0.7,2.6,1.3,3.8
		c0.4,0.8,1,1.5,1.6,2.1c0.1,0.1,0.5,0.2,0.7,0.1c2-0.7,4.1-1.4,6.1-2.1c-0.2-3.3-0.6-6.7,2.1-9.4c-1.5,3-2,6-1.2,9.3
		c0.5-0.1,0.9-0.2,1.2-0.3c0,0.1,0.1,0.1,0.1,0.2c-1.2,0.5-2.5,1.1-3.7,1.6l-0.1,0.5c1.8,0.7,3.6,1.4,5.4,2.1l-0.1,0.3
		c-1.8-0.6-3.6-1.2-5.4-1.7c-0.6-0.2-1.3-0.2-2-0.3c0,0.1,0.1-0.1,0.4-0.5c-0.5,0.1-0.9,0.1-1.2,0.3c-3.2,1.3-6.5,2.4-9.5,3.9
		c-3.9,2-7.6,4.4-11.4,6.6c-0.5,0.3-1.4,0.6-0.8,1.4c0.6,0.8,1.3,0.2,1.9-0.2c3.5-2.2,7-4.5,10.5-6.7c0.8-0.5,1.6-0.9,2.4-1.2
		c0.5-0.2,0.9-0.4,1.6,0.2c0.9,0.8,2.1,1.2,3.2,1.7c-0.1,0.1-0.1,0.2-0.2,0.3c-1-0.4-2.2-0.7-3.1-1.3c-1.1-0.7-1.9-0.2-2.9,0.4
		c0.4,0.6,1.2,1.1,0.5,1.9c0,0.3,0.1,0.5,0.1,0.8c1.9,2.8,5.2,3.9,8.4,2.5c0.8-0.4,1.7-1.3,1.9-2.1c0.9-3.1,4-3.6,5.7-1.1
		c1.1,1.6,2.6,1.6,4,1.5c1.5-0.1,3-0.7,4.4-1.3c0.6-0.2,1.2-0.9,1.3-1.5c0.3-1.4,1.1-2.3,2.4-2.5c1.1-0.2,2.4-0.1,3.5,0.2
		c2.1,0.5,5.6,0.3,7-1.7c0.3-0.5,0.6-1,0.7-1.6C154.1,91.4,156,90.3,158.3,91.2z M135.2,92.3c-1.2-0.6-2.4-1.1-3.7-1.7
		c0.4-0.1,0.8-0.3,1-0.4c1,0.7,1.9,1.3,2.7,1.9C135.3,92.1,135.3,92.2,135.2,92.3z M136.4,89.2c-0.2,0.5-1.3,0.6-1.9,0.8
		c-0.1-0.1-0.2-0.2-0.3-0.4c1.5-0.1,1.6-1.2,2.1-2.1c0.7-1.6,1.6-3.1,2.7-4.5C138.1,85.1,137.3,87.2,136.4,89.2z M141.7,88.4l0,0.3
		c1.7,0.4,3.5,0.8,5.1,1.2c-2.8,1.1-5-1.1-7.7-1.5c0.8-0.2,1.6-0.4,2.5-0.6c1-0.2,1.9-0.6,2.9-0.7c1-0.1,1.5-0.6,2-1.3
		c1.6-2.4,3.6-4.2,6.1-5.7c-1.9,2-3.9,3.9-5.8,5.9l0.3,0.5c4.5-1,8.9-2,13.4-3l0.1,0.3C154.2,85.3,148,86.9,141.7,88.4z" />
        </g>
        <g>
          <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
                fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
            this.setState({ color6: color, opacity6: opacity });
          }} d="M155.1,56.1c2,0.8,4,0.8,5.8-0.4c0.5-0.3,0.8-1.1,1.1-1.7c0.3-0.6,0.4-1.3,0.7-1.8c0.6-1.1,1.5-1.5,2.7-1.1
		c1.9,0.6,3.4-0.3,4.6-1.5c1.2-1.2,1.2-1.5,0.2-2.5c0.2-0.8,0.5-1.6,0.3-2c-0.2-0.5-1-0.8-1.6-0.9c-0.5-0.1-1.1,0.1-1.7,0.1
		c-1.2,0.1-2.2-0.1-2.9-1.2c-0.6-0.9-1.3-1-2.5-0.8c-2,0.4-4,0.5-6,0.6c-1.1,0-1.5-0.8-1.3-1.9c0.2-1.1-0.5-1.6-1.3-1.9
		c-2.2-0.8-4.4-0.4-6.6,0.4c-1.4,0.5-2.7,0.4-4,0.1c-0.3-0.1-0.7-0.5-0.8-0.9c-0.4-1.4-1.2-2.2-2.6-2c-2.1,0.3-4.2,1-6.2,1.6
		c-0.5,0.1-1.1,0.6-1.2,1.1c-0.5,1.5-1.7,2-2.9,2.5c-0.8,0.1-1.7,0.3-2.5,0.2c-0.4,0-0.7-0.5-1.1-0.7c0.1,0,0.2,0,0.3,0
		c-0.2-0.1-0.3-0.2-0.5-0.3c0,0.1,0,0.1,0,0.2c-0.2-0.1-0.4-0.1-0.6-0.2c-2-1.3-5.1-0.8-6.1,1.3c-1.8,3.9-5,4.4-8.7,4.1
		c-2.2-0.2-4.2,0.2-5.6,2.2c-0.9,1.2-0.8,2.1,0.5,2.9c0.6,0.4,1.2,0.6,1.7,1c0.5,0.4,1.2,1,1.2,1.5c0,0.6-0.7,1.3-1.2,1.6
		c-1.1,0.5-2.3,0.6-3.5,0.8c-0.7,0.1-1.4,0.1-2,0.1c-2,0.3-2.5,0.7-2.2,3.1c0.3,2.7,2.7,4.3,5.6,4.3c2,0,3.3-1.1,4.9-1.8
		c1.1-0.5,2.3-1,3.4-1.4c1.1-0.4,2.3-0.9,3.5-1.3c-2.2-2-2-4.7-2.6-7.2c0.1,0.2,0.3,0.3,0.4,0.5c0.4,1.3,0.7,2.6,1.3,3.8
		c0.4,0.8,1,1.5,1.6,2.1c0.1,0.1,0.5,0.2,0.7,0.1c2-0.7,4.1-1.4,6.1-2.1c-0.2-3.3-0.6-6.7,2.1-9.4c-1.5,3-2,6-1.2,9.3
		c0.5-0.1,0.9-0.2,1.2-0.3c0,0.1,0.1,0.1,0.1,0.2c-1.2,0.5-2.5,1.1-3.7,1.6l-0.1,0.5c1.8,0.7,3.6,1.4,5.4,2.1l-0.1,0.3
		c-1.8-0.6-3.6-1.2-5.4-1.7c-0.6-0.2-1.3-0.2-2-0.3c0,0.1,0.1-0.1,0.4-0.5c-0.5,0.1-0.9,0.1-1.2,0.3c-3.2,1.3-6.5,2.4-9.5,3.9
		c-3.9,2-7.6,4.4-11.4,6.6c-0.5,0.3-1.4,0.6-0.8,1.4c0.6,0.8,1.3,0.2,1.9-0.2c3.5-2.2,7-4.5,10.5-6.7c0.8-0.5,1.6-0.9,2.4-1.2
		c0.5-0.2,0.9-0.4,1.6,0.2c0.9,0.8,2.1,1.2,3.2,1.7c-0.1,0.1-0.1,0.2-0.2,0.3c-1-0.4-2.2-0.7-3.1-1.3c-1.1-0.7-1.9-0.2-2.9,0.4
		c0.4,0.6,1.2,1.1,0.5,1.9c0,0.3,0.1,0.5,0.1,0.8c1.9,2.8,5.2,3.9,8.4,2.5c0.8-0.4,1.7-1.3,1.9-2.1c0.9-3.1,4-3.6,5.7-1.1
		c1.1,1.6,2.6,1.6,4,1.5c1.5-0.1,3-0.7,4.4-1.3c0.6-0.2,1.2-0.9,1.3-1.5c0.3-1.4,1.1-2.3,2.4-2.5c1.1-0.2,2.4-0.1,3.5,0.2
		c2.1,0.5,5.6,0.3,7-1.7c0.3-0.5,0.6-1,0.7-1.6C150.9,56.3,152.8,55.2,155.1,56.1z M132,57.2c-1.2-0.6-2.4-1.1-3.7-1.7
		c0.4-0.1,0.8-0.3,1-0.4c1,0.7,1.9,1.3,2.7,1.9C132.1,57,132,57.1,132,57.2z M133.2,54.2c-0.2,0.5-1.3,0.6-1.9,0.8
		c-0.1-0.1-0.2-0.2-0.3-0.4c1.5-0.1,1.6-1.2,2.1-2.1c0.7-1.6,1.6-3.1,2.7-4.5C134.9,50,134.1,52.2,133.2,54.2z M138.5,53.3l0,0.3
		c1.7,0.4,3.5,0.8,5.1,1.2c-2.8,1.1-5-1.1-7.7-1.5c0.8-0.2,1.6-0.4,2.5-0.6c1-0.2,1.9-0.6,2.9-0.7c1-0.1,1.5-0.6,2-1.3
		c1.6-2.4,3.6-4.2,6.1-5.7c-1.9,2-3.9,3.9-5.8,5.9l0.3,0.5c4.5-1,8.9-2,13.4-3l0.1,0.3C151,50.2,144.8,51.8,138.5,53.3z" />
        </g>
        <g>
          <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
                fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
            this.setState({ color7: color, opacity7: opacity });
          }} d="M154.5,21.9c2,0.8,4,0.8,5.8-0.4c0.5-0.3,0.8-1.1,1.1-1.7c0.3-0.6,0.4-1.3,0.7-1.8c0.6-1.1,1.5-1.5,2.7-1.1
		c1.9,0.6,3.4-0.3,4.6-1.5c1.2-1.2,1.2-1.5,0.2-2.5c0.2-0.8,0.5-1.6,0.3-2c-0.2-0.5-1-0.8-1.6-0.9c-0.5-0.1-1.1,0.1-1.7,0.1
		c-1.2,0.1-2.2-0.1-2.9-1.2c-0.6-0.9-1.3-1-2.5-0.8c-2,0.4-4,0.5-6,0.6c-1.1,0-1.5-0.8-1.3-1.9c0.2-1.1-0.5-1.6-1.3-1.9
		c-2.2-0.8-4.4-0.4-6.6,0.4c-1.4,0.5-2.7,0.4-4,0.1c-0.3-0.1-0.7-0.5-0.8-0.9c-0.4-1.4-1.2-2.2-2.6-2c-2.1,0.3-4.2,1-6.2,1.6
		c-0.5,0.1-1.1,0.6-1.2,1.1c-0.5,1.5-1.7,2-2.9,2.5c-0.8,0.1-1.7,0.3-2.5,0.2c-0.4,0-0.7-0.5-1.1-0.7c0.1,0,0.2,0,0.3,0
		c-0.2-0.1-0.3-0.2-0.5-0.3c0,0.1,0,0.1,0,0.2c-0.2-0.1-0.4-0.1-0.6-0.2c-2-1.3-5.1-0.8-6.1,1.3c-1.8,3.9-5,4.4-8.7,4.1
		c-2.2-0.2-4.2,0.2-5.6,2.2c-0.9,1.2-0.8,2.1,0.5,2.9c0.6,0.4,1.2,0.6,1.7,1c0.5,0.4,1.2,1,1.2,1.5c0,0.6-0.7,1.3-1.2,1.6
		c-1.1,0.5-2.3,0.6-3.5,0.8c-0.7,0.1-1.4,0.1-2,0.1c-2,0.3-2.5,0.7-2.2,3.1c0.3,2.7,2.7,4.3,5.6,4.3c2,0,3.3-1.1,4.9-1.8
		c1.1-0.5,2.3-1,3.4-1.4c1.1-0.4,2.3-0.9,3.5-1.3c-2.2-2-2-4.7-2.6-7.2c0.1,0.2,0.3,0.3,0.4,0.5c0.4,1.3,0.7,2.6,1.3,3.8
		c0.4,0.8,1,1.5,1.6,2.1c0.1,0.1,0.5,0.2,0.7,0.1c2-0.7,4.1-1.4,6.1-2.1c-0.2-3.3-0.6-6.7,2.1-9.4c-1.5,3-2,6-1.2,9.3
		c0.5-0.1,0.9-0.2,1.2-0.3c0,0.1,0.1,0.1,0.1,0.2c-1.2,0.5-2.5,1.1-3.7,1.6l-0.1,0.5c1.8,0.7,3.6,1.4,5.4,2.1l-0.1,0.3
		c-1.8-0.6-3.6-1.2-5.4-1.7c-0.6-0.2-1.3-0.2-2-0.3c0,0.1,0.1-0.1,0.4-0.5c-0.5,0.1-0.9,0.1-1.2,0.3c-3.2,1.3-6.5,2.4-9.5,3.9
		c-3.9,2-7.6,4.4-11.4,6.6c-0.5,0.3-1.4,0.6-0.8,1.4c0.6,0.8,1.3,0.2,1.9-0.2c3.5-2.2,7-4.5,10.5-6.7c0.8-0.5,1.6-0.9,2.4-1.2
		c0.5-0.2,0.9-0.4,1.6,0.2c0.9,0.8,2.1,1.2,3.2,1.7c-0.1,0.1-0.1,0.2-0.2,0.3c-1-0.4-2.2-0.7-3.1-1.3c-1.1-0.7-1.9-0.2-2.9,0.4
		c0.4,0.6,1.2,1.1,0.5,1.9c0,0.3,0.1,0.5,0.1,0.8c1.9,2.8,5.2,3.9,8.4,2.5c0.8-0.4,1.7-1.3,1.9-2.1c0.9-3.1,4-3.6,5.7-1.1
		c1.1,1.6,2.6,1.6,4,1.5c1.5-0.1,3-0.7,4.4-1.3c0.6-0.2,1.2-0.9,1.3-1.5c0.3-1.4,1.1-2.3,2.4-2.5c1.1-0.2,2.4-0.1,3.5,0.2
		c2.1,0.5,5.6,0.3,7-1.7c0.3-0.5,0.6-1,0.7-1.6C150.3,22.1,152.1,21,154.5,21.9z M131.4,23c-1.2-0.6-2.4-1.1-3.7-1.7
		c0.4-0.1,0.8-0.3,1-0.4c1,0.7,1.9,1.3,2.7,1.9C131.4,22.9,131.4,22.9,131.4,23z M132.5,20c-0.2,0.5-1.3,0.6-1.9,0.8
		c-0.1-0.1-0.2-0.2-0.3-0.4c1.5-0.1,1.6-1.2,2.1-2.1c0.7-1.6,1.6-3.1,2.7-4.5C134.2,15.9,133.5,18,132.5,20z M137.8,19.2l0,0.3
		c1.7,0.4,3.5,0.8,5.1,1.2c-2.8,1.1-5-1.1-7.7-1.5c0.8-0.2,1.6-0.4,2.5-0.6c1-0.2,1.9-0.6,2.9-0.7c1-0.1,1.5-0.6,2-1.3
		c1.6-2.4,3.6-4.2,6.1-5.7c-1.9,2-3.9,3.9-5.8,5.9l0.3,0.5c4.5-1,8.9-2,13.4-3l0.1,0.3C150.4,16.1,144.1,17.6,137.8,19.2z" />
        </g>
      </SvgIcon>
    )
  }
};