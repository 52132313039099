import { IconButton } from "@material-ui/core";
import React, { Component } from "react";
import { appColors } from "../App";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  menuButton: {
    marginRight: 2,
    marginLeft: 0,
    padding: 3
  },
  menuButtonVertical: {
    marginTop: 3,
    padding: 3
  }
};

class IconButtonCorrect extends Component <any, any> {

  render() {
    let { toggle, children, classes, color, disabled, tbarType = 'horizontal' } = this.props;
    if(color && disabled){
      color = appColors.icon.drawing.primary;
    }
    let backgroundColor = 'transparent';
    if (toggle) {
      color = appColors.icon.drawing.toggle;
      backgroundColor = "white";
    }
    return (
      <IconButton
        {...this.props}
        className={tbarType === 'horizontal' ? classes.menuButton : classes.menuButtonVertical}
        color={"inherit"}
        style={{ color: color, backgroundColor: backgroundColor}}
      >
        {children}
      </IconButton>
    )
  }
}

export default withStyles(styles)(IconButtonCorrect);