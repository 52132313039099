import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_14_3 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 510.2 174"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M147.4,59.4c-0.5,0.1-1,0.2-1.5,0.3c-1.8,0.3-2.6-0.7-1.9-2.5c0.2-0.6,0.5-1.3,0.9-1.8c0.3-0.6,0.8-1,1.2-1.5
	c0.4,0.4,0.8,1,1.2,1.5c0.5-0.6,0.9-1.1,1.4-1.5c2.4-1.8,4.8-1.5,6.7,0.8c0.3,0.4,0.8,0.7,1.2,1c0.1-0.1,0.3-0.2,0.4-0.4
	c-0.8-1-1.6-2-2.6-3.3c1.9-0.6,3.7-1.1,5.6-1.7c0,0.1,0,0.2,0.1,0.3c-0.9,0.8-1.8,1.6-3.1,2.6c1,0.5,1.8,1.2,2.7,1.3
	c1.9,0.2,3.5,0.8,4.6,2.4c0.3,0.4,0.5,0.8,0.6,1.1c2.2-1.7,2.3-4.1,0.2-6.7c1.5-0.6,3.6,0.8,3.8,2.6c0.1,0.8-0.1,1.7-0.1,2.5
	c0,0.7,0.1,1.5,0.4,2.2c1.2-1.7,1.2-3.5,1-5.6c0.2,0.2,0.5,0.4,0.5,0.6c-0.1,0.8-0.2,1.5,1,1.8c0.4,0.1,0.5,1.3,0.7,2.1
	c0.1,0.5,0.1,1.2,0.3,1.6c0.4,0.7,0.9,0.8,1.6,0c1.7-2.1,2.6-4.5,3.1-7.1c0.2-1.1,0.9-2.2,1.6-3.1c0.5-0.6,0.4-0.9,0-1.3
	c-0.5-0.5-0.8-0.1-1.1,0.3c-1.3,1.5-1.5,3.5-2.1,5.3c-0.5,1.3-1,2.6-1.5,3.8c-0.2,0-0.3-0.1-0.5-0.1c0-0.6-0.1-1.1-0.1-1.7
	c0-0.2,0-0.5-0.1-0.6c-1.2-1-2.1-2.2-3.8-2.5c-0.6-0.1-1.2-0.6-1.8-0.8c0-0.2,0-0.3,0-0.5c3.1-1.5,6.2-3.1,9.4-4.5
	c1-0.5,2.2-0.6,3.4-0.6c1-0.1,0.7,0.7,0.5,1.3c-0.5,1.3-1.1,2.6-1.7,3.8c-0.7,1.4-0.9,2.9-0.7,4.4c0.4,3.1-1.1,5.5-2.8,7.9
	c-0.6-0.5-1.2-0.9-1.8-1.4c-0.1,0-0.2,0.1-0.3,0.1c1.6,2.8,2.8,5.6,1.9,8.9c-0.9-0.6-1.6-1.2-2.4-1.8c-0.1,0.1-0.1,0.1-0.2,0.2
	c0.6,1.1,1.1,2.2,1.7,3.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5-0.4-1.1-0.9-1.6-1.3c0.4,1.3,1,2.5-0.1,3.6c-0.6-0.5-1.3-1-1.9-1.5
	c-0.7,1-0.3,1.7,0.3,2.4c1.1,1.5,2.1,3.2,1.4,5.4c-0.3-0.6-0.6-1.3-0.9-1.9c-0.3-0.6-0.7-1.2-1.2-1.7c1.6,4.2,1.3,8.3-0.2,12.4
	c-0.2,0-0.3,0-0.4-0.1c-3.3-1.3-6.3,0.9-6.1,4.5c0.1,1.9,0.6,3.9,0.8,5.8c0.1,0.4,0,1-0.2,1.2c-1.6,1.4-3.2,2.8-4.8,4.1
	c-0.1-0.1-0.1-0.2-0.2-0.2c0.3-0.4,0.5-0.7,0.8-1.1c-0.1-0.1-0.1-0.2-0.2-0.4c-0.7,0.3-1.4,0.6-2,1c-1.9,1.4-3.7,3.1-5.7,4.3
	c-3.6,2.1-7.5,3.1-11.7,3.2c-5,0.2-9.9,0.3-14.8-0.8c-3.8-0.8-6.9-2.7-8.4-5.1c0.6,0.2,1.3,0.4,2,0.6c0.1-0.2,0.1-0.3,0.2-0.5
	c-0.7-0.5-1.4-0.9-2.1-1.4c-2-1.5-3.7-3.3-3.8-5.9c-0.2-2.7,0.4-5.3,1.4-7.8c1-2.5,2.6-4.4,4.7-5.9c1.3-1,2.9-1.6,4.3-2.5
	c5-3,9-7,11.4-12.4c0.2-0.4,0.3-0.9,0.5-1.3c0.2-0.4,0.4-0.9,0.6-1.4c0.6,1.4,0.4,2.6-0.1,3.8c-0.5,1.1-1,2.2-1.5,3.2
	c-0.4,0.9,0.2,1.8,1.4,1.7c-0.7,0.8-1.3,1.5-2,2.2c-0.7,0.8-0.6,1.7,0.5,2.5c-1,0.7-1.9,1.4-2.8,2.1c-0.8,0.6-1.5,1.1-1.3,2.3
	c0.1,0.5-0.8,1.3-1.3,1.7c-0.6,0.5-1.2,0.8-1,1.8c0.1,0.3-0.5,0.8-0.9,1.1c-0.9,0.7-1.9,1.2-2.7,2c-2,1.9-2.2,4.9-0.2,7.2
	c0-0.4,0.2-0.8,0.1-1.1c-0.8-3.3-0.2-4.3,3.1-6.4c0.8-0.5,1.6-1.1,2.2-1.8c0.3-0.3,0.2-0.9,0.2-1.5c0.9-0.9,1.9-1.9,2.8-3
	c0.2-0.3,0.1-0.8,0.2-1.2c1-1.3,2-2.5,3-3.8c0.1-0.2,0.4-0.5,0.3-0.6c-0.9-2,1-1.7,1.7-2c2.1-0.8,4.2-0.3,6.3,0.5
	c0.4,0.2,0.8,0.2,1.2,0.2c-2.1-2.1-4.8-2.4-7.7-2.3c0.7-2.7,3.1-2.8,5.2-3.6c-0.5-0.5-1-1-1.7-1.7c1.1-0.1,2-0.1,2.9-0.2
	c0.1-0.2,0.2-0.3,0.3-0.5c-0.4-0.2-0.9-0.5-1.4-0.7c0.2-0.2,0.3-0.4,0.5-0.5c2.2-0.8,4.8,0,6.1,1.9c1.3,1.8,2,2,4,0.9
	c1.4,1.3,2.6,0.5,3.7-0.6c0.8-0.8,1.4-1.9,2.1-2.8c0.5-0.7,1.1-1.3,1.7-1.9c-0.8-0.6-1.6-0.6-2.4,0.2c-0.5,0.5-0.7,1.2-1.2,1.8
	c-0.6,0.8-1.3,1.6-2.1,2.3c-0.8,0.7-1.2,0.5-1.4-0.6c-0.2-0.8,0-1.9-1.4-1.9c0.2,0.8,0.3,1.6,0.5,2.4c-2.1,1.1-2.1,0.6-2.8-0.7
	c-0.2-0.4,0-1.2,0.2-1.8c0.3-0.7,0.8-1.4,1.2-2.1c0.1-0.3,0.1-0.6,0.1-0.9c-0.4,0.1-0.8,0.1-1,0.3c-0.7,0.9-1.4,1.8-2.2,2.9
	c0.1-1.6,0.5-2.7,1.8-3.4c1.1-0.6,2.2-1,3.6-1.2c1.1-0.1,2.3-0.7,3.2-1.4c1.2-0.9,2.4-0.8,3.6-0.3c1.2,0.5,1.6,1.8,1.5,2.9
	c-0.2,2.6,0.5,5,1.6,7.8c-1-0.3-1.8-0.4-2.4-0.8c-1.4-0.7-3-0.3-4.1,1.1c-2.9,3.6-6.5,6-11.4,6.1c-1.9,0-3.5,0.9-4.8,2.1
	c-1.3,1.2-1.2,2.2-0.1,4.6c0.5,1,0.6,2.1,0.9,3.2c-5,0.8-9.6,1.5-12.8,5.7c0.7,1,1.4,0.5,1.9,0c2.6-2.9,6.3-3.4,9.8-4.3
	c0,0.5,0.1,1,0.1,1.7c0.4-0.3,0.7-0.4,0.9-0.7c1.2-1.3,1.4-3.7,0.3-5.1c-1.3-1.6-0.5-4,1.2-4.9c1.4-0.7,2.9-0.9,4.4-1.1
	c1.9-0.3,3.8-0.9,5.5-1.7c2.2-1,4-2.7,5.4-4.7c0.8-1.1,1.4-1.2,2.3-0.8c-0.9,2.1-2.3,3.7-4.7,4.4c0.5,0.8,1,0.9,1.7,0.7
	c1.7-0.5,3-1.5,3.6-3.2c0.4-1,1.4-1.4,2.2-1.2c1.7,0.3,2.3,1.2,2.1,2.5c-0.9,0.3-1.8,0.6-2.7,1c-0.8,0.4-1.6,0.9-2.4,1.3
	c0.6,0.9,1.4,1,2.6,0.3c0.8-0.5,1.7-0.9,2.5-1.3c1,1.5,0.6,3.2-0.8,4.1c-2.7,1.8-5.7,2.4-8.8,2.4c-2.8,0-5.2,0.7-7.4,2.5
	c-0.9,0.7-2,1.1-3.1,1.7c0.2,0.6,0.7,0.9,1.5,0.7c1.2-0.4,2.4-0.9,3.7-1.1c1.9-0.4,3.7-0.1,5.4,0.8c1.2,0.6,1.6,2.5,1.2,4.3
	c-1.3,4.9-4.1,8.7-7.6,12.2c-1,0.9-1.8,2-2.7,3.1c0.1,0.1,0.2,0.3,0.4,0.4c0.9-0.7,1.8-1.5,2.6-2.2c1.4-1.2,2.8-2.3,4-3.6
	c0.9-0.9,1.5-2,2.2-3.1c1.4-2.3,2.7-4.7,2.8-7.5c0.1-2-1-3.8-2.8-4.6c-1.4-0.5-2.8-0.9-4.4-1.4c1.4-0.3,2.8-0.7,4.1-0.8
	c2.2-0.3,4.5-0.3,6.7-0.8c1.8-0.3,3.3-1.4,4.4-2.9c0.3-0.4,0.5-1,0.4-1.5c-0.1-1.5-0.2-2.9-0.5-4.4c-0.1-0.6-0.5-1.3-1-1.7
	c-2.4-2-2.8-4.7-2.6-7.4c0.1-2.3-0.4-4.2-2.4-5.3c-1.7-1-3.5-1.1-5.2,0.1c-1.1,0.8-2.2,1.4-3.6,1.4c-1.1,0-2.2,0.4-3.2,0.7
	c-1.3,0.3-2.8-0.4-3.1-1.6c-0.2-0.8-0.1-1.5,0.8-1.7c0.6-0.2,0.9-0.5,0.8-1.1c1.9,1.8,5.6,2,7.5,0.4c0.2,0.4,0.4,0.8,0.7,1.3
	c1.1-0.9,0.5-1.9,0.2-2.6c-0.3-0.6-1.1-1-1.7-1.4c-0.1,0.1-0.2,0.2-0.3,0.3c0.2,0.3,0.4,0.6,0.5,0.9c-1.3,1.4-2.9,1.6-4.6,1.3
	c-1.8-0.3-2.4-1.7-2.6-3.3c-0.1-0.7-0.3-1.3-1.2-1.3C145.9,56.9,146.8,58.1,147.4,59.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M128.1,62.8c0.3-0.7,0.5-1.2,0.8-1.6c0.5-0.8,1-1.5,2-1.2c1.2,0.4,1.9,1.5,1.9,2.7c0.1,2.9-1.6,4.7-3.6,6.2
	c-2.8,2.1-4.4,4.9-5,8.2c-0.1,0.3,0.1,0.7,0.1,1.1c0.2,0,0.3,0,0.5,0.1c0.4-0.9,1-1.8,1.3-2.7c0.3-0.9,0.5-2.1,1.7-2.2
	c1.2-0.2,2.2,0.4,2.9,1.5c-2.5,3.5-5.8,6-9.6,8c-0.3-0.7-0.6-1.4-1-2.1c0.8-0.5,1.6-1.1,2.4-1.6c-0.1-0.1-0.1-0.3-0.2-0.4
	c-1.5,0.4-2.9,0.9-4.4,1.3c-0.4,0.4-0.7,1-1.6,0.5c-0.8-0.5-1-1.1-0.6-1.9c0.6-1,1.3-1.9,1.9-2.9c0.8-1.3,1.4-2.7,2.1-4.1
	c0.2-0.4,0.4-0.8,0.7-1c2.2-1.1,2.4-1.4,2.2-3.8c-0.1-0.5-0.2-1.1-0.2-1.6c0-0.5,0.1-1,0.1-1.6c1.4,0.2,1.9-0.9,2.5-1.9
	c0.6-0.9,1.2-1.9,2.4-2c-0.2,0.9-0.5,1.6-0.6,2.4C127.1,62.4,127.8,62.6,128.1,62.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M135,65.5c0.5-1.5,1.1-3.1,1.6-4.7c0.5,0.1,1.2,0.3,2,0.5c0.1-0.1,0.1-0.2,0.2-0.4c-0.3-0.2-0.5-0.5-0.8-0.7
	c-0.7-0.4-1.5-0.8-2.2-1.3c-1.7-1.1-1.8-1.6-0.2-3.1c-1.4-1.1-3.2-2.1-1.5-4.2c0.6-0.7,1.2-1.4,2.2-1.2c-0.1,1.1-0.4,2.2-0.3,3.1
	c0.1,0.6,0.8,1,1.3,1.5c0.1,0.1,0.5-0.2,0.7-0.3c-0.2-0.2-0.3-0.5-0.5-0.5c-1.2-0.3-0.9-1.1-0.5-1.7c0.5-0.8,1.2-1.4,1.9-2.1
	c0.5-0.5,1.1-0.8,1.7-0.3c0.4,0.4,1,1.3,0.9,1.6c-1,2.3-0.7,4.6-0.1,6.9c0.2,0.7-0.1,1.6-0.4,2.3c-0.8,1.9-0.2,3.7,0.1,5.6
	c0,0.3,0.4,0.6,0.6,0.9c0.1,0,0.2-0.1,0.3-0.1c0.1-0.7,0.1-1.3,0.2-2.1c0.3,0.2,0.7,0.4,0.7,0.4c-0.3,1-0.5,2-1,2.8
	c-0.3,0.5-1,0.7-1.6,1.1c0.2,0.2,0.4,0.4,0.6,0.5c0,0.1-0.1,0.2-0.1,0.2c-0.6,0.3-1.2,0.5-1.9,0.8c-0.2,0.1-0.4,0.3-0.6,0.4
	c0.2,0.2,0.4,0.7,0.7,0.7c0.6,0.1,1.2,0,1.9,0c-1.5,0.7-2.9,1.3-4.1,1.9c0.3-1.5,0.8-3,0.9-4.5c0.1-0.7-0.4-1.5-0.6-2.2
	c-0.1-0.5-1-0.9-0.3-1.6c0,0-0.4-0.7-0.6-0.7C135.9,65.1,135.4,65.3,135,65.5L135,65.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M127.4,72.6c1.1-1,2.8-2.6,4.6-2.4c1,0.1,1.3,0.5,0.9,1.5c-0.4,0.8-1.4,1.8-1.9,2.6
	C129.9,73.5,130.3,72.3,127.4,72.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M148,65c-0.2,1.1-0.5,2.2-0.7,3.3c-1,0-2.1,0-3.2,0C144,66.2,146.2,64.4,148,65z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }}
              d="M156,46.6c0.8,0.8,1.5,1.6,2.3,2.5c-1.7,0.6-3.1,1.1-4.7,1.6C153.7,49.1,154.7,47.9,156,46.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M164.6,56c-0.8-1-2-1.6-1.6-3.2c0.1-0.4-0.3-1-0.5-1.9c0.9,0.5,1.7,0.7,2.1,1.2C165.5,53.3,165.2,54.7,164.6,56
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M132.6,54.4c0.3,0.8,0.6,1.5,0.9,2.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.5-0.3-1.2-0.6-1.4-1.1
	c-0.2-0.4,0.3-1,0.4-1.5C132.3,54.4,132.4,54.4,132.6,54.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M83.8,104.3c-0.4-0.5-0.7-0.9-1.1-1.4c0.3-0.2,0.5-0.6,0.8-0.5c0.6,0.2,1,1,0.8,1.5
	C84.2,104.1,83.9,104.1,83.8,104.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M172,126.7c0.7-0.7,1.3-1.4,2-2.1c0.5,0.5,1.1,1.2,1.8,2c0.3-2.9-2.6-4.2-5.1-3.9c0.1,0.3,0.3,0.5,0.5,0.9
	c-1.1-0.1-2.1-0.1-3.1-0.2c1.3-1.8,3.2-1.4,5.1-2.1c-0.4-0.2-0.7-0.5-0.9-0.5c-1.7,0.2-3.5,0.2-5.1,1.1c-0.6,0.4-0.6,0.8-0.3,1.1
	c0.6,0.6,1,1.6,2.2,1.1c0.4-0.2,0.9,0,1.3,0c0.7-0.1,1.3-0.2,2-0.4c-0.6,1.3-1.5,2.4-3,2.6c-1.7,0.2-3.1-0.3-4.1-1.8
	c-0.5-0.7-0.4-1.3,0.1-1.9c0.4-0.5,0.9-0.9,1.5-1.7c-1.2-0.7-2.3-1.3-3.3-1.8c1.3-0.5,1.6-1.9,1.8-3.2c0.2-1.1,0-2.4-0.2-3.5
	c-0.1-1-0.9-1.2-2-1.5c0.4,0.6,0.6,1,0.8,1.3c0,0.1-0.1,0.2-0.1,0.3c-0.9-0.3-1.7-0.5-2.6-0.8c1.5-3.1,2.8-3.7,6.3-2.9
	c-0.1-1-0.2-1-2.6-1.5c3-4.1,7.5-7.9,12.6-7.2c0-1-0.8-1.1-1.3-1.1c-1.3,0-1.7-0.6-1.9-1.7c-0.7-3.5-3.4-5.3-6.4-4.8
	c0.6,0.2,1.1,0.5,1.7,0.7c1.2,0.4,2.2,1,2.7,2.3c0.4,1.2,0.9,2.3,1.3,3.3c-2.3,1.3-4.5,2.5-6.9,3.8c-1.2-1.5-1.4-3.6-1.9-5.6
	c-0.6-2.4,0-4.4,1.8-6.1c0.5-0.4,1-0.8,1.8-0.4c0.7,0.4,1.6,0.7,2.4,1c3.3,0.9,5.3,3.5,7.2,6c0.5,0.7,0.8,1.5,1.4,2.6
	c0.2-0.7,0.3-1,0.4-1.2c1.2-0.2,2.5-0.8,3.6,0.3c0.1,0.1,0.6-0.4,1-0.6c1.2,1.1,1.2,1.2,0,2.2c-0.8,0.7-1.7,1.2-2.4,1.9
	c-0.3,0.2-0.3,0.7-0.5,1c0.5,0,1.2,0.2,1.5,0c2.4-1.9,4.8-3.9,7.2-5.9c0.6-0.5,1.2-0.8,1.8-1.2c1.2-0.8,1.7-0.6,2.7,0.4
	c1.7,1.6,1.8,3.7,1.8,5.8c0,2.2-0.4,4.3-0.5,6.5c0,0.8,0.2,1.6,0.4,2.4c0.1,0.5,0.2,1,0.4,1.5c0.1,0.5,0.3,1,0.6,1.7
	c0.8-1.2,0.9-2,0.4-3c-0.2-0.5-0.4-1.1-0.3-1.7c0.6-3.4,1-6.7,0.2-10.1c-0.5-2.4-1.7-4.4-4.4-5c-0.8-0.2-1.9,0.1-2.6,0.6
	c-1.5,0.9-2.8,2.1-4.3,3.2c-0.3-0.4-0.6-0.9-1-1.4c-0.5-0.7-1.1-0.8-1.9-0.5c-0.5,0.2-1,0.2-1.5,0.2c-0.6,0-1.2,0-1.8,0
	c-0.2,0-0.5,0-0.8-0.1c2.9-5.4,8-5.9,13.2-6.2c-1.6-0.8-3.3-0.4-5-0.6c-0.3-2.9,2.3-4.2,3.7-6.2c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.5,0.2-1.1,0.3-1.6,0.6c-2,1.3-3.4,3.1-3.7,5.6c0,0.3-0.2,0.7-0.5,0.8c-0.9,0.4-1.8,0.7-2.8,1c-0.3-2.9,0.8-5.4,2.3-7.8
	c1.8-2.9,4.3-4.8,7.4-6.3c1.2-0.6,2.3-1.6,3.3-2.5c1-0.9,1.9-1.9,2.7-3c0.5-0.7,0.6-1.6,0.8-2.4c1-3.7,0.5-7.5,0.5-11.3
	c0-1.3-0.3-2.5-0.5-3.8c-0.5-2.6-1.2-5.1-1.6-7.7c-0.2-1.7,0-3.5,0-5.2c0-0.7,0-1.5,0-2.4c0.2,0.2,0.4,0.4,0.4,0.5
	c0.2,1.3,0.7,2.4,2,2.9c0.2,0.1,0.2,0.5,0.2,0.7c0.2,2,1.1,2.5,3.3,1.7c0.2,1.6,0.4,3.2,0.5,4.7c0.1,1.3-0.1,2.7,1.5,3.3
	c0.2,0.1,0.4,0.4,0.4,0.7c0.3,1.5,0.2,3.2,0.8,4.6c1,2.3,0.6,4.7,1.1,7c0.4,2.1,0.5,4.3,0.9,6.4c0.5,2.4,1.9,4.2,4.2,5.1
	c0.5,0.2,1.1,0,1.6,0c0.9,0.1,1.8,0.1,2.7,0.3c4.9,1.1,6.9,4.8,8,9.2c1,3.9,0.5,7.7-1.5,11.6c-0.4-6.2-1-12.2-5.4-16.8
	c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.3,0.2,0.7,0.4,1c2.6,3.5,3.6,7.6,3.8,11.9c0.2,2.9-0.2,5.8-0.4,8.7c0,0.5-0.3,1-0.6,1.5
	c-1.8,3.8-4.2,7.1-7.1,10.1c-0.4,0.4-0.9,0.8-1.5,1.4c-0.1-1-0.2-1.7-0.4-2.4c-0.5-1.7-1.2-3.4-1.6-5.1c-0.3-1.3-0.4-2.6-0.6-3.9
	c-0.2-0.9-0.8-1.1-1.8-0.8c1.6,2.3,1,4.7,0.5,7c-0.3,1.4-0.2,1.8,0.8,2.2c0.2-0.6,0.4-1.2,0.7-2.2c2,5.8-0.2,10.1-3.5,14.2
	c1.2,1.3,2.1,2.9,1.3,4.9c-0.4,1.1-1.3,1.7-2.3,2c-1.2,0.3-1.4-0.7-1.6-1.5c-0.2-0.9-0.6-1.3-1.6-1.3c0.3,0.9,0.7,1.8,1,2.7
	c-0.4,0.3-0.7,0.6-1.2,0.8c-1.6,0.9-2.8,0.3-3.1-1.5c-0.1-0.9-0.3-1.7-1.6-1.2c0.1,0.5,0.2,0.9,0.3,1.4c0.1,0.4,0.3,0.8,0.6,1.6
	c-0.9-0.3-1.9-0.3-2.3-0.7c-0.8-0.9-0.8-2.5,0.1-3.4c0.9-0.9,2.1-1.7,3.2-2.4c0.9-0.6,1.9-1,2.8-1.5c-0.1-0.2-0.1-0.3-0.2-0.5
	c-0.9,0.2-1.8,0.3-2.6,0.5c-0.7-1.3-0.2-3.3,0.9-4.1c0.2-0.1,0.4-0.4,0.4-0.6c-0.2-1.1,0.2-1.6,1.4-1.6c1.6,0,3.2-0.3,5-0.5
	c-0.2-0.2-0.4-0.6-0.7-0.6c-2.1-0.1-4.2-0.1-6.3,0c-0.2,0-0.4,0.5-0.5,0.8c-0.2,0.4-0.4,0.9-0.5,1.1c-0.8-0.1-1.6-0.2-2.4-0.3
	c-0.2,2.9-1.3,5.4-2.8,7.9c-0.2-0.5-0.3-0.8-0.4-1.1c-1.2-0.5-1.9,0.1-2.2,1c-0.8-0.3-1.5-0.5-2.4-0.8c-0.5,1.6-0.9,2.9-1.2,4
	c-0.7-0.6-1.4-1.5-2.3-1.9c-1.4-0.5-1.6,1-2.2,1.9c-1.7-1.4-3.5-2.1-5.6-1.9c-0.8,0.1-1.6-0.2-2.7-0.4c0.6-0.3,0.9-0.5,1.2-0.7
	c0-0.1,0-0.2,0-0.3C174.5,126.9,173.2,126.8,172,126.7L172,126.7z M174.5,119.1c0.4,0,0.7,0.1,1,0c1.1-0.5,2.3-0.8,3.3-1.5
	c0.5-0.3,0.9-1.3,0.8-1.8c-0.4-2.1-1.8-3.4-3.8-4.2c-2-0.7-3.8-0.1-5.3,1.2c-1.1,1-1.2,2.7-0.5,4
	C171.1,118.4,172.6,119.1,174.5,119.1z M192.7,97.3c1.8,4.3,1.2,8.3-0.4,12c-0.7-0.3-1.3-0.5-1.9-0.8c-0.3-0.1-0.6-0.3-0.7-0.5
	c-0.3-1.3-1.2-1.5-2.4-1.2c0-1.4-0.1-1.6-0.9-1.5c-0.8,0.1-1.5,0.8-2.2,1.2c0,0.2,0.1,0.4,0.1,0.5c1.6-0.2,2.1,2.7,4.2,0.9
	c0,0.6-0.2,1.2,0,1.6c0.2,0.6,0.6,1,0.9,1.6c0.4-0.3,0.7-0.6,1.1-0.9c0.1-0.1,0.3-0.2,0.6-0.5c0.3,1.3,0.5,2.5,0.8,3.7
	c0.2,0,0.3,0,0.5,0c0.4-0.9,0.8-1.8,1.3-2.8c1.7-3.6,2.3-7.2,1.1-11.1C194.3,98.6,194.2,97.5,192.7,97.3z M172.1,108
	c0.5,0.7,0.9,1,1.8,0.9c1.2-0.2,2.5-0.1,3.4,0.9c0.9,1,1.8,2,2.7,3.1c0.2,0.2,0.5,0.4,0.8,0.7C181.2,110,176.5,107.1,172.1,108z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M147.8,126.7c-0.9-0.4-1.5-0.6-2.2-0.9c0,1.2,0,2.3,0,3.3c-1.6-1.5-1.5-3.8,0.3-4.9c0.6-0.4,1.3-0.7,2-0.8
	c1.7-0.3,3-1.2,4.3-2.1c1.5-1,3.2-1.6,4.8-2.4c0.1-0.1,0.2-0.1,0.3-0.1c0.4,0.1,0.8,0.2,1.3,0.3c-0.4,0.1-0.7,0.3-1.1,0.4
	c-1,0.2-2,0.4-3,0.7c0.3,0.9,0.5,1.1,1.3,0.8c0.8-0.3,1.7-0.4,2.6-0.6c0,0.2,0.1,0.3,0.1,0.5c-0.3,0.1-0.6,0.2-0.9,0.4
	c-1,0.5-2,0.9-3,1.5c-0.2,0.1-0.2,0.7-0.2,1c0.4,0,0.8,0.1,1.1-0.1c0.9-0.5,1.8-1.1,2.8-1.8c0.2,0.5,0.3,0.8,0.4,1
	c-0.8,0.4-1.5,0.8-2.2,1.2c0,0.2,0,0.3,0,0.5c0.5,0.1,1.3,0.4,1.4,0.2c1-1.2,1.7-0.6,2.5,0.6c-1.7,0.2-3.3,0.3-4.9,0.7
	c-1,0.3-1.9,1-2.8,1.6c-1,0.7-1.9,1.6-2.9,2.2c-0.9,0.5-2,0.4-2.6-0.5C146.7,128.6,146.7,127.6,147.8,126.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M227,111.7c0.6-1.2,0.5-2.1-0.7-2.9c0.3,1.6-0.3,2.7-1.2,3.7c-0.3,0.4-0.9,0.5-1.6,0.8c0.5-1.7,1.9-3.2,0.1-4.5
	c-0.3,1-0.4,2.2-1,3c-1.2,1.4-2.8,2.1-3.7,0.3c-0.1-0.3-0.1-0.7,0-0.9c1.2-2.1,2.4-4.2,3.6-6.3c2.9-0.2,5,1.8,6.1,4.3
	C229,110.2,228.5,110.9,227,111.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M160,121.9c0.2,0.5,0.4,0.7,0.5,1.1c0.2-0.2,0.6-0.3,0.6-0.5c0-0.3-0.1-0.8-0.4-0.9c-1.1-0.6-1-1.4-0.4-2.5
	c0.6,1.1,1.1,2.1,1.7,3c0.6,0.9,1.4,1.6,2.1,2.6C161.5,125.1,160.3,124.3,160,121.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M164.3,125.6c0.6,0.4,1.3,0.8,2,1.2c-0.8,0.7-1.5,1.3-2.2,1.7c-0.2,0.1-0.7-0.1-1-0.2c0.1-0.6,0.2-1.1,0.4-1.6
	c0.1-0.3,0.5-0.4,0.7-0.6C164.3,126,164.3,125.8,164.3,125.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M171.9,126.7c0,0.3,0.1,0.7-0.1,0.8c-0.7,0.7-3.5,1.2-4.7,0.6c0.7-0.2,1.3-0.4,2-0.6
	C170.1,127.2,171,126.9,171.9,126.7C172,126.7,171.9,126.7,171.9,126.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M162.5,120c0.8,0.3,1.6,0.5,2.5,0.8c0,0.1,0,0.2,0,0.3c-0.6,0.3-1.3,0.9-1.7,0.8c-0.5-0.2-0.7-1-1.1-1.6
	C162.3,120.1,162.4,120.1,162.5,120z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1}
              fill={this.state.color17 ? this.state.color17 : 'transparent'} onClick={() => {
          this.setState({ color17: color, opacity17: opacity });
        }}
              d="M163.5,113.5c-0.8,0.6-1.4,1.1-2.1,1.7c0-0.9,0-1.6,0-2.4C162.1,113.1,162.6,113.2,163.5,113.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M174.8,113.8c-0.6,1.1-1,2.4-2.5,2.7c-0.6,0.1-1.2,0.1-1.3-0.8c-0.1-0.8-0.3-1.5,0.8-1.7
	c0.9-0.2,1.8-0.5,2.8-0.7C174.7,113.5,174.7,113.6,174.8,113.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }}
              d="M172.4,117.6c2.2-0.1,3.1-1.4,3.8-3.2c0.5,0.7,0.9,1.3,1.5,2C175.9,117.1,174.7,118.8,172.4,117.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }} d="M77.7,77.4c0.4,0.2,0.8,0.4,1.2,0.5c2,0.4,3.4,1.7,4.5,3.3c0.2,0.3,0,0.7,0.1,1c0.1,0.4,0.4,0.8,0.7,0.9
	c1.5,0.7,2.1,2.1,3,3.6c-0.8-0.3-1.4-0.5-2-0.8c-0.1,0.1-0.2,0.2-0.4,0.3c2,1.5,3.3,3.3,3.3,5.9c-0.2,0.1-0.5,0.1-0.7,0.2
	c-0.2,1-0.3,2-0.6,2.8c-0.3,0.8-1,1.4-1.6,2.2c-1-0.4-1.7,0-2.3,1.2c-0.8,1.5-1.8,2.9-3.1,4.1c-1.8,1.7-6.4,1.2-8.1-1.1
	c-1.2-1.6-2.6-3.1-4.5-4c-0.8-0.4-1.6-1.1-2.9-0.4c3.2,0.8,5.1,2.9,6.8,5.4c-1.3-0.5-3-0.5-4.2-1.2c-1.9-1.1-3.7-2.4-5.3-3.9
	c-3.2-3-4-6.9-3.3-11.2c0.1-0.4-0.2-0.8-0.4-1.2c-0.4,0.3-1,0.5-1,0.9c-0.2,1.4-0.3,2.8-0.1,4.3c0.2,2.1,0.7,4.2,2.1,6
	c-1.2,0.2-2.2,0.4-3.4,0.6c-0.3-1.1-1.1-1.7-2.3-1.8c-0.4,0-0.8-0.9-1.2-1c-0.8-0.1-1.7,0.2-2.6,0.2c-0.5,0-1.5,0-1.5-0.2
	c-0.2-0.5-0.2-1.3,0.1-1.8c0.5-1.4,0.6-1.4-0.8-1.8c-0.3-0.1-0.5-0.1-1.1-0.3c0.4-0.5,0.7-0.8,1.2-1.3c-0.7-1.4-2.6-1.2-3.8-2.3
	c0.4-0.1,0.8-0.3,1.1-0.3c1.2,0.1,1.3-0.5,1.1-1.5c-0.1-0.2-0.2-0.5-0.1-0.7c0.4-0.8-0.2-1.1-0.8-1.5c-0.9-0.7-1.7-1.5-2.5-2.3
	c-0.2-0.2-0.1-0.6-0.2-0.9c0.5-0.1,1.1-0.2,1.3-0.4c0.2-0.3-0.1-0.8-0.2-1.2c0-0.4-0.2-1.1,0-1.2c1.1-0.8,0.4-1.5-0.1-2.3
	c-0.4-0.6-0.8-1.1-1.1-1.9c0.7,0.2,1.4,0.3,2.3,0.5c-0.2-0.5-0.2-1-0.5-1.2c-1.5-1.6-2-2.8-1.7-5.4c2,1.8,4.3,3.3,5.9,5.3
	c1.1,1.3,1.2,3.4,1.7,5.1c0.5,1.5,0.8,3.1,1.4,4.5c1.8,3.8,4.4,4.4,7.7,1.7c0.1-0.1,0.2-0.1,0.4-0.3c0.2,0.9,0.3,1.6,0.4,2.4
	c0.8,0.2,1-0.1,0.9-0.9C60.2,82,60,79.6,60,77.2c0-3.5,1.4-6.7,3.1-9.8c2.4-4.1,5.4-7.7,9-10.7c2.8-2.3,6.1-3.6,9.7-4.3
	c-0.9,1.8-1.7,3.6-2.6,5.4c1.2,0.4,1.4-0.4,1.7-1c0.8-1.4,1.6-2.8,2.5-4.1c0.3-0.5,0.9-0.7,1.5-1.1c0.3-0.4,0.7-0.9,1.1-1.5
	c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0.4-0.2,0.7-0.3,1.1c-0.3,1.2,0,1.6,1.2,1.5c0.2,0,0.7-0.5,0.7-0.6c-0.7-1.1,0.1-2,0.3-3
	c0.7-3.2,2.7-5.8,4.7-8.4c0,0.9-0.1,1.8,0,2.6c0,0.3,0.4,0.7,0.6,1c0.3-0.3,0.9-0.7,0.9-1c-0.2-2,0.3-3.8,0.9-5.7
	c1-3.1,2.7-5.7,4.7-8.2c0.7-0.8,1.3-1.7,2.1-2.4c0.5-0.5,1.2-0.8,2-1.3c0.2,0.8,0.4,1.3,0.5,1.8c0.3,2.9,0.5,5.9,0.8,8.8
	c0.1,0.8,0.4,1.6,0.6,2.3c0.1,0.2,0.4,0.3,0.6,0.5c0.1-0.2,0.4-0.4,0.4-0.6c0-0.8,0-1.5,0-2.4c2.9,1.4,6,2.1,7.6,5.1
	c0.3,0.5,0.5,1.4,0.2,1.9c-1.2,3.1-0.3,5.5,2.1,7.6c0.1,0.1,0.3,0.3,0.4,0.4c-1,0.6-1.9,1.2-2.9,1.8c0.8,0.7,1.5,1.5,0.9,2.4
	c-0.5,0.7-1.3,1.2-2.1,1.4c-1.5,0.4-2.7-0.1-3.7-1.5c-0.6-0.9,0-1.3,0.3-1.8c-1.4-0.5-3.7,0.7-3.8,2c0,1.2,0.9,0.5,1.6,0.7
	c0.1-0.4,0.1-0.8,0.1-1.1c1,1,2,1.9,3,2.9c-0.6,0.5-1.4,0.5-2.3,0.2c-0.6-0.2-1.3-0.1-1.9-0.2c0,0.1,0,0.2,0,0.3
	c0.4,0.2,0.8,0.5,1.5,0.9c-1.8,1.1-3.4,2-5,2.9c0,0.1,0,0.2,0,0.3c0.6,0.1,1.3,0.1,2,0.2c-0.9,2.5-2.3,4.9-4.3,6.8
	c-1.1,1.1-1.9,0.9-2.8-0.4c-1.3-1.9-1.6-2.1-2.9-2.2c0.8,1.2,1.5,2.4,2.2,3.6c-0.6,0.2-1,0.4-1.4,0.6c0,0.1,0,0.2-0.1,0.3
	c0.4,0.1,0.9,0.5,1.3,0.4c2.3-0.4,4.5-0.9,6.5-2.2c1.1-0.8,2.1-0.7,3.2,0.2c0.9,0.7,2.1,1.3,3.5,0.8c-0.3-0.2-0.5-0.4-0.7-0.5
	c1.6-0.8,2.7-0.4,3.2,1.1c-0.6,0.1-1.3,0.2-1.9,0.3c-0.1,0-0.1,0.1-0.3,0.4c0.5,0.2,1,0.5,1.5,0.5c1.6-0.2,1.7-0.2,1.9,1.4
	c0.1,0.7,0,1.4-0.1,2.2c-0.7-0.7-0.9-2.1-2.5-1.6c0.2,0.3,0.3,0.5,0.4,0.7c1,1.2,0.8,2.5,0.1,3.6c-0.3,0.6-1.5,0.8-2.3,0.8
	c-1.2,0.1-2.3-0.4-3-1.6c-0.4-0.6-1-1.1-1.6-1.8c0.5-0.2,0.9-0.5,1.6-0.8c-0.6-0.1-1-0.3-1.3-0.3c-2.5-0.1-4.4,1.3-6.3,2.8
	c-0.8,0.7-1.6,1.5-2.8,0.8c-0.2-0.1-0.9-0.3-1,0.5c-0.1,0.6-1.3,0.7-1.7,0.1c-0.7-1-1.5-1.1-2.6-0.7c-0.4-1.1-1.2-1.5-2.3-1.1
	c-0.7-1.4-1.6-1.8-3.5-1.4c0.3,0.9,0.5,1.8,1.8,1.9c0.5,0,0.8,0.6,1.3,0.9c0.5,0.4,1.1,0.9,1.4-0.2c0.9,0.5,1.5,1.7,2.8,1
	c0.1,0,0.4,0.2,0.5,0.3c0.8,1.4,1.5,2.8,1.5,4.6c-0.1,2.7-0.6,5.4-1.9,7.8c-1,1.7-3,2.9-4.8,3.9c0-0.9,0.2-1.9,0.2-2.8
	c1.6,0,1.8-0.6,1.4-1.8c-0.8-2.3-0.6-5-2.2-7c-1.5-1.9-3-4-4.8-5.7c-1-0.9-2.5-1.2-3.8-1.8c-0.2-0.1-0.5,0.1-0.7,0.1
	C77.7,77.2,77.7,77.3,77.7,77.4z M94.6,53.9c0.7-1,1.3-1.7,1.8-2.5c0.1-0.2,0.1-0.4,0.1-0.6c-0.2,0-0.4-0.1-0.5,0
	c-1.2,0.4-3.2,3.4-2.6,4.5c0.6,1.1-0.1,1.7-0.3,2.6c-0.2,1-1,2.3,0.2,3.2c0.7,0.5,0.9,1.1,1.1,1.8c0.1,0.4,0.5,0.8,0.9,1
	c0.1,0.1,0.6-0.4,0.9-0.6c0.3-0.2,0.5-0.5,0.7-0.8c0.7,0.9,1.4,1.8,2.5,0.4c0.1-0.1,0.5-0.1,0.6-0.2c0.2-0.3,0.4-0.6,0.5-0.9
	c0-0.1-0.5-0.5-0.7-0.4c-0.6,0.2-1.1,0.5-1.8,0.8c-0.5-0.2,0.6-1.7-0.7-1.5c-0.7,0.1-1.2,0.8-2.2,1.5c0.3-1.3,0.6-2.3,0.9-3.4
	c-0.5,0-1.1,0-1.7,0c0.1-1.5,0.9-2.6,1.7-3.7c0.2-0.2,0.2-0.5,0.4-0.7c-0.3-0.1-0.5-0.3-0.8-0.4C95.3,53.8,95.1,53.9,94.6,53.9z
	 M111.2,50.6c-0.2-2.4,0.7-4.6-1.3-6.4c0.8,0.1,1.4,0.1,2,0.1c-0.8-1.1-1.8-1.3-2.8-1c-2,0.5-3.3,1.8-4.3,3.5
	c-0.5,0.8-1.1,1.6-1.8,2.6c1.3-0.1,2.7-0.4,4.1-0.2C108.3,49.3,109.6,50,111.2,50.6z M103.5,28.5c-0.1-0.1-0.2-0.2-0.3-0.4
	c-0.5,0.3-1.3,0.6-1.6,1.1c-1.1,1.7-2.2,3.3-2.9,5.1c-1.4,3.4-1.3,7.1-0.7,10.7c1.2,0.1,1.5-0.4,1.3-1.8c-0.6-3.9,0.2-7.5,1.9-11
	C101.9,30.9,102.8,29.7,103.5,28.5z M102.5,44.3c0.6,0.3,1.3,0.7,1.6-0.2c0.7-1.6,2-2.5,3.7-2.9c0.3-0.1,0.5-0.3,0.8-0.4
	C106.6,39.6,103.7,41.3,102.5,44.3z M72.6,81.7c0,0.1,0,0.2,0,0.4c2.2,0.1,2.5-0.6,6.5,0.3C77,81.1,74.9,81.1,72.6,81.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }} d="M113.9,62.4c0.6,1.6,0.2,2.1-1.5,2.1c-0.3,0-0.7,0.1-1,0.3c-0.6,0.5-1.1,1-2,0.9c-0.3,0-0.7,0.9-1.1,1.4
	c-0.2,0.3-0.4,0.7-0.7,0.7c-1.2,0.1-2.4,0.2-3.6,0.3c1.2-2.4,2.1-4.7,3.4-6.8c1-1.7,2.9-2.1,4.7-2.3c1.5-0.2,3-0.4,4.5-0.5
	c1.7-0.1,2.8,1.1,3.2,3.2c0.2,1.3,0.6,2.7,1.1,4c0.9,2.1,0.4,3.8-1.4,4.5c-0.2,0.1-0.4,0.1-0.6,0.1c0.3-1.3,0.6-2.6,0.8-3.9
	c0.1-0.7,0.2-1.6-0.9-1.8c-0.3,1.7-0.6,3.3-1,4.9c-0.3,0-0.5,0.1-0.7,0.1c-1.9,0.1-2.9-0.1-2.1-2.7c0.4-1.2,0.2-2.6,0.1-3.9
	C115.1,62.5,114.8,61.8,113.9,62.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }} d="M99.2,95.8c-0.4,0-0.7-0.1-0.8,0c-1.3,1.7-2.9,2-4.9,1.4c-1.3-0.4-2.7-0.3-4.3-0.5c0.9-0.4,1.6-0.8,2.4-1.2
	c0.3-0.2,0.6-0.5,1-0.7c1.8-0.9,3.6-1.4,5.6-1.2C99.4,93.7,99.5,94.2,99.2,95.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity23 ? this.state.opacity23 : 1}
              fill={this.state.color23 ? this.state.color23 : 'transparent'} onClick={() => {
          this.setState({ color23: color, opacity23: opacity });
        }} d="M112.9,31.4c0.6-0.2,1.2-0.6,1.8-0.7c1.1-0.2,0.9,0.8,0.8,1.3c-0.4,1.3-0.8,2.6-1.3,3.9
	c-0.3,0.8-0.6,1.5-0.9,2.3c-1.2-0.6-2.4-1.3-3.8-2c0.8-0.8,1.4-1.7,2.3-2.4c0.8-0.7,1.7-1.3,2.8-2.1
	C113.8,31.6,113.3,31.5,112.9,31.4L112.9,31.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }}
              d="M94.8,80.4c0.7-0.1,1.1-0.1,1.6-0.2c2.4,4.1,0.9,7.6-1.8,11C96.2,87.7,96.1,84.1,94.8,80.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1}
              fill={this.state.color25 ? this.state.color25 : 'transparent'} onClick={() => {
          this.setState({ color25: color, opacity25: opacity });
        }} d="M78.7,104.9c0.9-0.5,1.8-1,2.6-1.5c0.7-0.4,1.2-0.3,1.3,0.5c0,0.7,1,1.5-0.2,2C81.1,106.3,79.6,106,78.7,104.9z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1}
              fill={this.state.color26 ? this.state.color26 : 'transparent'} onClick={() => {
          this.setState({ color26: color, opacity26: opacity });
        }} d="M112.9,31.4c-1.3,1.2-2.6,2.3-3.8,3.5c-1,0.9-0.9,0.9-2.5,0.3c0.6-0.5,1.1-1,1.6-1.4
	C109.7,32.8,111,31.6,112.9,31.4L112.9,31.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M105.6,47.7c0.7-0.8,1.4-1.8,2.3-2.5c0.8-0.7,1.3-0.3,1.6,0.2c-0.9,0.9-1.6,1.7-2.3,2.3
	c-0.3,0.3-0.8,0.3-1.2,0.4C105.7,48,105.6,47.8,105.6,47.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity28 ? this.state.opacity28 : 1}
              fill={this.state.color28 ? this.state.color28 : 'transparent'} onClick={() => {
          this.setState({ color28: color, opacity28: opacity });
        }} d="M109.7,47c0,0.6,0,1.2,0,1.8c-0.1,0.1-0.2,0.1-0.2,0.2c-0.3-0.4-0.8-0.7-0.8-1.1c0-0.4,0.5-0.7,0.7-1.1
	C109.5,46.9,109.6,47,109.7,47z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity29 ? this.state.opacity29 : 1}
              fill={this.state.color29 ? this.state.color29 : 'transparent'} onClick={() => {
          this.setState({ color29: color, opacity29: opacity });
        }} d="M437,134.4c-0.4-0.1-0.9-0.2-1.3-0.3c-0.1,0.1-0.1,0.2-0.2,0.3c0.5,0.4,1,0.8,1.6,1.1c1.5,0.7,2.5,2.2,2.1,3.5
	c-0.4,0-0.7-0.1-1.2-0.1c0.2,0.4,0.4,0.7,0.6,1c0,0-0.1,0.1-0.1,0.1c-0.3-0.2-0.6-0.4-0.9-0.6c-0.3-0.2-0.7-0.3-1-0.5
	c-0.1,0.1-0.2,0.2-0.2,0.2c0.3,0.5,0.6,1.1,0.9,1.6c-1.7,0.6-2.8-0.4-2.8-2.2c0-0.4-0.5-0.9-0.8-1.3c-0.3,0.4-0.7,0.7-0.8,1.2
	c-0.1,0.6,0.1,1.2,0.1,1.9c-0.5,0.1-1.2,0.3-1.9,0.5c0.3-1.2-0.3-1.2-1-1c0.2-0.5,0.5-1,0.7-1.6c-0.5,0-1,0-1.5,0c0.1-1.7,1-2.6,3-3
	c0.4-0.1,0.7-0.4,1-0.6c-0.4-0.2-0.9-0.6-1.3-0.5c-2.6,0.4-3.7,1.5-4.3,4.4c-1.1,0.6-1.4-0.7-1.6-1.1c-0.2-0.4,0-1.5,0.4-1.9
	c0.5-0.5,1.4-0.6,2.1-0.9c0.6-0.3,1.4-0.4,1.8-0.9c1.8-2.1,2.9-4.6,3.2-7.4c0.2-1.8,0.6-3.5,0.9-5.3c0.6-3.7,0.1-7.2-1.2-10.7
	c-0.2-0.5-0.7-0.9-1.3-1.5c-0.2,2.4,1.9,4.4,0.2,6.8c-0.2-0.6-0.3-1-0.4-1.4c-0.3-1.5-0.3-1.5-1.8-1.2c-0.1-0.5-0.2-1-0.4-1.8
	c-0.7,0.5-1.3,0.9-1.9,1.3c-0.1-0.3-0.2-0.8-0.4-1.3c-0.4,0.1-0.8,0.2-1.1,0.2c-0.6-0.6,0.6-2.7-1.7-2.1c-0.2,0.5-0.3,1-0.5,1.5
	c-0.1,0-0.2,0-0.2,0c-0.2-0.7-0.6-1.4-0.6-2.1c-0.1-0.9-0.5-1.2-1.5-1.3c0.1,0.8,0.2,1.5,0.4,2.1c0.2,0.8,0.6,1.5,0.9,2.2
	c0.4,0.9,1.2,1,1.7,0.5c0.8,0.6,1.6,1.2,2.2,1.9c0.2,0.3,0.2,1,0,1.3c-0.7,1.2-1.6,2.2-2.2,3.4c-0.6,1.3-1,2.7-1.2,4.1
	c-0.3,2.5-1.2,3.6-3.6,4.2c-0.3,0.1-0.5,0.2-0.8,0.6c0.4,0.1,0.9,0.1,1.5,0.2c-0.6,1.1-1.2,2.1-1.8,3.1c-0.1,0-0.1-0.1-0.2-0.1
	c0-0.3,0-0.6,0-0.8c-0.1-0.1-0.2-0.2-0.3-0.2c-0.5,0.5-0.9,1.1-1.4,1.6c-0.9,0.9-1.8,1.9-2.8,2.6c-0.5,0.3-1.3,0.2-2,0.3
	c0-0.1-0.1-0.2-0.1-0.3c0.2-0.2,0.5-0.4,0.7-0.6c0-0.1-0.1-0.2-0.1-0.3c-0.7,0.1-1.4,0.3-2.2,0.4c0.4-0.5,0.8-0.9,1.4-1.4
	c-0.6-0.1-0.9-0.2-1.4-0.3c0.3-1.1,0.8-1.9,1.9-2.3c0.8-0.3,1.5-0.6,2.3-0.7c1-0.1,1-0.9,1.1-1.5c0.5-1.7,0.5-3.7,1.5-4.9
	c1.4-1.7,2-3.6,2.7-5.4c0.6-1.4,1.1-2.9,1.4-4.4c0.2-1-0.3-2.2-0.4-3.3c-0.3-2.7-0.6-7.6,0.7-11.7c0.3,1,0.5,1.8,0.8,2.5
	c0.1,0.3,0.4,0.5,0.7,0.7c0.1-0.3,0.3-0.6,0.3-0.9c-0.1-1.8-0.2-3.7-0.5-5.5c-0.3-1.8-1.2-3.4-3.4-3.5c-0.2,0-0.3-0.4-0.6-0.9
	c-0.7,0.6-1.6,1.1-2.2,1.8c-1.3,1.3-2.5,1.5-4.1,0.4c0.7-0.9,1.5-1.7,1.9-2.7c0.2-0.5-0.1-1.4-0.5-2c-0.4-0.7-1.1-1.1-1.9-1.8
	c-0.3,0.5-0.7,1-1,1.7c-1.3,2.8-4.4,3.6-6.9,1.8c-0.9-0.7-1.4-2.4-1-3.7c0.2-0.5,0.4-1,0.6-1.6c-0.5-0.1-1-0.2-1.8-0.3
	c1.2-0.8,2.1-1.6,3.1-2.2c0.7-0.4,1.1-0.9,1.3-1.7c0.7-3,1.5-6,2.2-9c0.4-1.5,1.2-2.7,2.5-3.7c0.9-0.8,1.7-1.6,2.6-2.4
	c0.3-0.2,0.7-0.5,1-0.5c1.1,0,1.8-0.4,1.8-1.6c0.8,0.7,2.5-0.2,2.5,1.2c0,0.9-0.8,1.9-1.3,3c0.7,0.2,1.2,0.4,1.8-0.6
	c2-3.8,4.1-7.5,5.3-11.7c0.1-0.4,0.3-0.8,0.5-1.1c0.6-1,1.3-0.9,2.1-0.2c1.8,1.7,2.5,3.9,3.4,6.1c1,2.5,1.1,5.2,1.2,7.8
	c0,0.6,0,1.3,0.1,1.9c0.1,0.4,0.5,0.7,0.8,1.1c0.1-0.1,0.2-0.1,0.3-0.2c0-0.2,0-0.5,0.1-0.7c0.1-0.3,0.2-0.6,0.3-0.8
	c0.2,0.2,0.5,0.3,0.7,0.5c0.3,0.2,0.6,0.3,1,0.4c-0.1,0.6-0.9,1.4-0.1,2.2c0.2,0.2,0.6,0.3,0.9,0.5c-0.1,0.3-0.4,0.9-0.3,1.3
	c0.1,0.4,0.6,0.8,1,1.3c-0.4,0.8-1.1,1.8,0.2,2.7c0.2-0.2,0.3-0.4,0.6-0.7c-0.2,1.2,0,2.1,1.1,2.6c0.2-0.4,0.4-0.7,0.6-1.1
	c1.2,1,1.2,1,2.4-0.1c0.2-0.2,0.7-0.2,1.1-0.4c0-1.3,1.1-0.6,2-0.9c-0.1-0.3-0.2-0.7-0.3-0.7c-0.7-0.2-1.6-0.6-2.2-0.4
	c-0.6,0.2-0.9,1.1-1.5,2c0.2-1.6-0.6-1.5-1.6-1.3c1-1.3,0.1-1.6-0.7-1.7c0.3-0.7,0.7-1.4,1-2c-0.6-0.1-1.1-0.3-1.8-0.5
	c0.4-0.4,0.8-0.9,1.3-1.5c-0.1,0-0.1,0-0.2,0c0.1-1,0.3-1.9,0.4-2.8c0.4,0.3,0.7,0.5,1,0.7c0.6,0.4,1,0.4,1.3-0.3
	c0.3-0.7,0.7-1.4,1.1-2.2c0.4,0.3,0.7,0.6,1.2,1.1c0.4-1.3,0.8-2.4,1.2-3.5c0.1,0.1,0.2,0.3,0.2,0.4c0.8,1.1,1.1,1.1,1.7-0.1
	c0.2-0.5,0.4-0.9,0.7-1.7c0.2,0.6,0.3,1,0.4,1.3c0.4,0.9,1,1,1.6,0.1c0.3-0.5,0.6-1,0.9-1.6c0.5,1.1,0.6,2.4,2.1,2.8
	c0.2-0.6,0.5-1.2,0.7-1.9c0.5,0.4,0.9,0.7,1.3,1.1c0.4-0.3,0.7-0.5,1.1-0.9c0.1,0.5,0.2,1,0.4,1.4c0.2,0.4,0.5,0.6,0.8,0.9
	c0.3-0.3,0.6-0.5,0.8-0.8c0.4-0.8,0.8-1.6,1.2-2.7c0.4,1.4,0.7,2.5,1.1,3.6c0.2,0,0.3,0,0.5,0c0.3-1,0.6-2.1,1-3.4
	c0.2,0.9,0.4,1.6,0.6,2.3c0.3,1,0.8,1.3,1.9,0.8c-0.1,1,0.2,1.8,1.2,2c0.2,0,0.5-0.6,0.8-0.9c0.1,0.6,0.2,1.2,0.4,1.9
	c0.3,1,0.9,1.1,1.4,0.5c0.5,0.7,1,1.5,1.5,2.2c0,0,0.7-0.4,1.2-0.7c0.1,0.8,0.2,1.7,0.3,2.5c0.1,0.8-0.1,1.8,1.2,2.2
	c0.3-0.4,0.6-0.9,1-1.4c0.2,1.3,0.4,2.5,0.5,3.6c0.1,0,0.3,0,0.4,0c0.1-0.2,0.4-0.5,0.4-0.7c-0.1-1.4-0.3-2.9-0.6-4.3
	c-0.1-0.3-0.4-0.6-0.7-0.9c-0.3,0.3-0.6,0.5-0.8,0.8c-0.1,0.1-0.2,0.3-0.2,0.4c-0.5-1.2-1.1-2.5-1.7-4.1c1.3-2.4,1-5.2,0.2-8.1
	c0-0.1-0.1-0.2-0.1-0.3c-0.4-0.4-0.8-0.8-1.1-1.3c-0.3-0.5-0.4-1.1-0.6-1.7c0.3,0,0.7,0,1.2,0c-0.6-1.3-1.1-2.4-1.6-3.7
	c1.5,0.3,1.3-0.3,0.9-1.4c-0.3-0.8-0.2-1.8-0.3-2.8c0.2-0.1,0.5-0.1,0.7-0.3c0.2-0.1,0.4-0.4,0.4-0.6c-0.1-0.3-0.3-0.7-0.5-0.7
	c-1.3,0-1.1-0.9-1.4-1.7c-0.1-0.3-0.3-0.5-0.5-0.8c-0.2-0.3-0.4-0.5-0.7-1c0.7,0.1,1.1,0.2,1.5,0.2c-0.2-1.8-0.5-3.5-0.7-5
	c0.6-0.1,1.1-0.1,1.6-0.2c-0.1-0.5-0.1-1-0.2-1.4c-0.3-1.2-0.7-2.5-1.2-4c0.5,0.3,0.6,0.5,0.8,0.6c0.4,0.1,0.9,0.1,1.4,0.2
	c-0.1-0.4-0.2-0.8-0.3-1.2c-0.2-1.9-0.3-3.9-0.5-5.8c1.6-0.3,2-0.5,1.6-2.2c-0.3-1.4-0.2-2.9-0.1-4.4c0.2,0.8,0.3,1.7,0.6,2.5
	c0.1,0.4,0.6,0.8,0.8,1.2c0.2,0.2,0.3,0.5,0.4,0.7c0,0.8,0,1.5,0,2.3c0,0.4,0.3,0.8,0.5,1.2c0.3-0.3,0.6-0.6,0.9-0.9
	c0.1-0.1,0.1-0.2,0.3-0.2c-0.2,1.1-0.4,2.1-0.6,3.2c-0.1,0.7-0.3,1.3-0.3,2c0,1-0.3,2.1,1,2.7c0.1,0,0.2,0.2,0.2,0.3
	c0.2,1.4,1.1,1.7,2.3,1.6c0.1,0.9,0.1,1.7,0.2,2.4c0.2,1.6,0.4,1.7,2,1.5c-0.1,1.4-0.2,2.9-0.3,4.3c-0.1,1.1,0.4,1.4,1.3,1.2
	c-0.3,1.8-0.6,3.6-0.9,5.4c-0.2,1.1,0.1,1.9,1.3,1.9c0.2,1.7-1.1,3.5,0.7,5c0.4-0.7,0.8-1.3,1.1-1.8c0.1,0,0.2,0.1,0.3,0.1
	c-0.4,1.1-0.7,2.2-1.2,3.2c-0.4,0.8-0.4,1.4,0.3,2.1c0.4-0.2,0.8-0.4,1.5-0.7c-0.5,0.9-0.7,1.6-1.2,2.2c-0.7,0.9-0.4,1.5,0.4,2
	c0.1,1.2-2,2.3,0,3.4c-0.2,1.4-2.2,2.3-1.2,4.1c0.6-0.2,1.1-0.5,1.7-0.7c0.1,0.1,0.1,0.1,0.2,0.2c-0.5,0.6-1.1,1.2-1.5,1.8
	c-0.5,0.8-0.5,1.6,0.3,2.2c-1.7,2.1-3.4,4.1-5,6.1c-0.6-0.5-1.3-1-2-1.5c-0.1,0.1-0.1,0.1-0.2,0.2c0.5,1.5,1.1,3,1.7,4.7
	c-0.6-0.1-1-0.2-1.4-0.1c-0.3,0-0.8,0.2-0.9,0.4c-0.1,0.3,0,0.8,0.2,1.1c0.3,0.6,0.8,1.1,1.2,1.8c-1.9,0.6-2,0.7-1,2.6
	c-1.6-0.2-1.6,0.7-1.2,1.8c-2,0.2-2.1,0.5-1.1,2.6c-1.4,0.9-1.4,0.9-0.9,3.1c-2.5,0.3-2.5,0.3-1.8,2.7c-0.4,0.4-0.9,0.7-1.2,1.1
	c-0.2,0.3-0.1,0.7-0.2,1.1c0,0.4,0,0.8-0.1,1.2c-0.3,0.7-0.8,1.3-1.2,2c-0.7,1.3,0.1,2.7,1.5,2.8c0.4,0,1.1,0.1,1.2,0.3
	c0.2,0.7,0.4,1.5,0.3,2.1c-0.1,0.7-0.7,1.2-1.1,1.8c-1,1.5-1.6,3.2-1.6,5c0,1.6-0.4,3.1-1.4,4.5c-0.6,0.8-0.7,2-1,3.1
	c-0.1,0.3,0.1,0.8,0.3,1c1,0.9,1.6,1.8,1,3.2c-0.1,0.3,0.3,0.8,0.5,1.4c-0.6-0.5-1.1-0.9-1.7-1.4c-0.3,0.4-0.5,0.8-0.7,1.1
	c-1.4-0.5-1.9-1.6-1.1-2.9c0.7-1.1,0.1-1.3-0.8-1.6c-0.3,0.8-0.3,1.7-1.5,1.7c-0.2,0-0.5,0.7-0.6,1c-0.2,0.9-0.5,1.7-1.3,1.9
	c-0.4-0.4-0.7-0.8-1.2-1.3c-0.2-0.3-0.4-0.8-0.8-1.5c0.2-0.6,0.4-1.5,1.7-1.6c0.1,0,0.2-0.3,0.4-0.5c-0.2-0.1-0.4-0.4-0.6-0.3
	c-0.8,0.4-1.6,0.7-2.2,1.2c-0.5,0.4-0.6,1.2-1,1.8c-1.3-0.1-1.3-1.2-1.3-2c0-1.5,2.2-2.7,3.6-2c1.5,0.7,2.7,0.3,2.7-1.3
	c0-2.6,1.3-4.7,1.6-7.2c0.2-1.3,0.2-2.6,0.2-3.9c0-0.5-0.5-0.9-1-1.2c0,0.3-0.1,0.6-0.1,0.9c-1.4-1.3-2.9-2.6-4.2-4
	c-2.2-2.5-4-5.3-4-8.8c0-2.6,0.3-5.2,0.5-7.8c0-0.5-0.1-1-0.4-1.6c-0.6,3-1.6,6-1.2,9.1c0.3,3.2,1.3,6,2.9,8.7
	c-0.5-0.7-1.8-0.3-2-1.6c0-0.2-1.1-0.6-1.5-0.4c-1.4,0.7-2.5,0.5-3.4-0.8c-0.7-0.9-1.2-1-2.2-0.4c-0.4,0.2-0.9,0.2-1.3,0.4
	c0-0.5,0.1-0.9,0.1-1.4c0-0.1,0-0.2-0.2-0.6c-0.3,0.2-0.7,0.4-0.8,0.7c-1.1,2.3-1.5,4.7-1.1,7.3c0.3,2.2-0.1,4.3-1.5,6.1
	c-1.2,1.5-1.7,3.1-1.1,5c0.7,2.3-0.4,4.2-1.2,6.2C438.1,131.6,437.6,132.9,437,134.4z M425.3,76.3c5.1-1.4,7.1,1.8,9.1,5.3
	c-0.4,0.3-0.7,0.6-1,0.7c-2.1,0.6-2.3,2.3-2.3,4.1c0,0.1,0,0.3,0,0.4c-0.1,0.2-0.3,0.5-0.4,0.5c-1.2,0-2,0.6-2.2,2.1
	c-0.4-0.4-0.7-0.7-1.1-1.2c-0.6,0.8-1.1,1.6-1.6,2.4c-1.7-1.2-2.6-1.4-3.5-0.7c0.7,0.3,1.7,0.4,2.3,0.9c1.9,1.6,2.4,1.5,3.1-0.8
	c0-0.1,0.1-0.1,0.2-0.1c2.2,0.8,2.9,0.4,2.7-1.6c0.2,0,0.5-0.1,0.7-0.1c1.5-0.1,1.7-0.5,1.4-2c-0.1-0.7,0-1.4,0-1.9
	c1.1-0.3,2.1-0.5,3-0.8c0.3-2.9-2.5-7-5.4-8C428.2,74.8,426,75.1,425.3,76.3z M428.7,72.9c0.3-0.4,0.4-0.6,0.5-0.7
	c0.4-2.1,1-4.2,1.2-6.3c0.3-3.6-0.4-7-2-10.2c-0.1-0.3-0.7-0.3-1.3-0.6c2,4.4,2.4,8.6,1.6,13c-0.1-0.7-0.1-1.3-0.2-2
	c0-0.3,0-0.6-0.2-0.7c-0.4-0.4-0.8-0.8-1.3-1.1c0-0.1,0-0.3,0-0.4c-0.4,0.3-0.8,0.6-1.2,0.9c-0.4,0.3-0.7,0.6-1.1,0.8
	c-0.8,0.4-1.6,0.6-2.4,0.9c1.1,1.7,2.7,0,3.7,1c0.2-0.5,0.3-1,0.4-1.4c0.2,0.8,0.1,1.7,0.5,2.4c0.6,1,1.4,1.8,1.4,3.1
	C428.3,71.9,428.5,72.3,428.7,72.9z M409.3,83.2c0,0.1,0,0.2,0.1,0.3c0.5-0.1,1-0.1,1.5-0.2c1.9-0.4,3.1-1.8,4.5-3
	c0.8-0.8,0.6-1.3,0.1-2c-1.6-2.3-3.8-3.1-6.5-3.2c-0.3,0-0.7,0.2-1,0.3c0,0.1,0,0.2,0.1,0.2c0.3,0.1,0.7,0.2,1.2,0.4
	c-1.3,2.4-1.2,4.7,0.7,6.7C409.7,82.9,409.5,83.1,409.3,83.2z M417.3,77c0.1-4.1-3.8-7.4-6.5-6.2c0.4,0.2,0.6,0.4,0.8,0.4
	c1.5,0.1,2.5,0.9,3.1,2.2c0.4,0.8,0.7,1.7,1.2,2.4C416.3,76.3,416.8,76.5,417.3,77z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity30 ? this.state.opacity30 : 1}
              fill={this.state.color30 ? this.state.color30 : 'transparent'} onClick={() => {
          this.setState({ color30: color, opacity30: opacity });
        }} d="M447.6,120c-2.5-1.7-5.3-0.3-5.5,2.5c-0.3,0-0.6,0-0.9,0c-0.9-1.6,2.8-3.9,6.2-4c0.4-2.1,0.8-4.2,1.1-6.3
	c0.1-0.4-0.3-0.9-0.4-1.3c-0.3-0.9-0.6-1.7-0.7-2.7c1.4,1,2.7,1.9,4.5,2c0.2,0.3,0.6,0.7,1,1.1c0.3-0.5,0.5-0.8,0.9-1.4
	c0,0.5,0.1,0.8,0,0.9c-1.3,1.3-1.6,2.8-1.3,4.6c0.1,0.9-0.3,1.9-0.7,2.8c-1.1,2.7-2.9,4.7-5.4,6.1c-1.1,0.6-2.2,0.1-2.6-1.1
	c-0.6-2.1,0.1-3.1,2.3-3.2C446.7,120.1,447.2,120.1,447.6,120z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity31 ? this.state.opacity31 : 1}
              fill={this.state.color31 ? this.state.color31 : 'transparent'} onClick={() => {
          this.setState({ color31: color, opacity31: opacity });
        }} d="M412.4,128.4c-0.3-0.2-0.5-0.3-0.8-0.5c0.7-1.3,1.7-0.6,2.5-0.5c0.1,0.1,0.1,0.2,0.2,0.4
	c-0.5,0.4-1.1,1.2-1.6,1.2c-2.2,0.2-3.3,1.5-4,3.3c-0.9-0.7-1.1-1.8-0.6-2.8c0.5-0.9,1.1-1.4,2.2-1c0.6,0.2,1.3,0.2,2,0.3
	C412.3,128.7,412.3,128.6,412.4,128.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1}
              fill={this.state.color32 ? this.state.color32 : 'transparent'} onClick={() => {
          this.setState({ color32: color, opacity32: opacity });
        }} d="M412,65c0.4-2.1,0.7-4,1.1-6.1C414.7,61.4,414.5,62.3,412,65z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity33 ? this.state.opacity33 : 1}
              fill={this.state.color33 ? this.state.color33 : 'transparent'} onClick={() => {
          this.setState({ color33: color, opacity33: opacity });
        }} d="M413.3,58.2c0.1-0.5,0.2-1.1,0.3-2c0.6,0.7,1,1.2,1.3,1.7c0.4,0.5,0.6,1.2,1,1.6c0.7,0.8,0.5,1.5-0.2,2.2
	c-0.3-0.8-0.5-1.5-0.9-2.2c-0.3-0.6-0.8-1-1.2-1.5C413.5,58.1,413.4,58.2,413.3,58.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M329.7,105.4c0.6,0.1,0.9,0.2,1.2,0.2c-0.2,0.4-0.4,0.8-0.6,1.3c-1.3-1.6-2.3-3.5-4.8-3.9
	c0.3,0.4,0.5,0.8,0.9,1.1c2.1,1.7,3.6,3.7,3.8,6.4c-0.1,0.1-0.1,0.2-0.2,0.2c-0.4,0.2-0.8,0.4-1.1,0.7c0.4,0.2,0.8,0.5,1.3,0.6
	c0.6,0.1,1.3,0,2.1,0.2c-0.8,0.4-1.5,0.7-2.3,1.1c0.4,0.8,0.9,1.1,1.8,0.8c2.3-0.9,4.7-1.7,7.3-1.2c2.4,0.4,4.2,1.8,6.3,3.5
	c-2,0.7-3.8,1.4-5.5,2c0,0.2,0,0.3,0,0.5c0.4,0,0.8,0.2,1.1,0.1c2.3-0.6,4.5-1.2,6.8-1.8c6.2-1.6,12.1-0.8,17.7,2
	c4.7,2.3,6.3,6.7,6.6,11.7c0,0.7,0,1.4-0.3,2.4c-0.5-0.6-1.1-1.2-1.6-1.8c-0.5-0.7-1.1-1-2.3-0.9c0.8,0.9,1.3,1.7,2,2.4
	c2.5,2.2,3.3,5.1,3.8,8.3c0.5,3.4,1.5,6.8,4.4,9c3.5,2.7,7.4,4.4,11.9,4.2c0.4,0,0.8,0,1.2,0.1c-3.1,1.3-6.3,2.6-9.8,1.3
	c-1.7-0.6-3.5-0.7-4.9-1.9c-0.3-0.2-0.8-0.2-1.3-0.2c-0.2,0-0.4,0.1-0.6,0c-2.4-1.8-5.1-3.3-6.3-6.2c-0.4-0.9-1.4-1.1-2.1-0.4
	c-0.1,0.1-0.3,0.2-0.5,0.4c-2.3-2.1-4.5-4.4-8.2-3c0.4,0.2,0.8,0.6,1.3,0.7c0.8,0.1,1.7,0,2.6,0.2c0.4,0.1,0.9,0.5,1.1,0.9
	c0.1,0.4,0,1.1-0.3,1.4c-1.4,1.2-2.8,2.3-4.3,3.4c-0.9-1.5-1.5-1.6-2.6-0.6c-0.3,0.3-0.6,0.6-0.8,0.9c-0.3,0.3-0.5,0.6-0.7,1
	c-1.3-0.9-1.5-0.8-2.9,0.6c-0.6-0.2-1.2-0.3-1.8-0.5c-0.6,1.3-0.7,1.3-1.8,0.6c-0.9-0.6-1.7-1.1-2.5-1.7c-0.1,0.1-0.2,0.1-0.3,0.2
	c0.4,0.7,0.7,1.5,1.1,2.3c-1.1,0.3-2.2,0.3-3.3-0.3c-0.4-0.2-0.8-0.4-1.2-0.6c-0.3-0.1-0.6,0-0.8,0c0,0.3,0,0.6,0.1,0.9
	c0.1,0.3,0.3,0.6,0.6,1.1c-0.6-0.2-0.9-0.3-1.2-0.4c-1.8-0.6-3.4,0-4.3,1.6c-0.1,0.2,0,0.9,0.1,1c0.3,0.1,0.8,0.1,1.1-0.1
	c0.4-0.3,0.7-0.9,1.1-1.2c0.4-0.2,1.1-0.4,1.4-0.2c0.4,0.3,0.6,1,0.5,1.5c-0.3,2-1.9,3.4-3.9,3.5c-1.6,0.1-3.1-0.5-4.6-1
	c-3-1-6.1-1.5-9.1-2.1c-0.9-0.2-1.8,0-2.7-0.1c-1.5-0.1-2.2-1.1-1.9-2.6c0.4-2.3,1.6-2.9,3.8-1.9c0.6,0.3,1.2,0.7,1.9,0.8
	c0.3,0.1,0.7-0.3,1.1-0.5c-0.3-0.2-0.6-0.4-0.9-0.5c-0.7-0.3-1.5-0.5-2.3-0.8c1.7-1.7,3.2-1.5,4.7,0.3c1.2,1.4,2.8,2.6,4.2,3.9
	c0.3,0.3,0.8,0.3,1.2,0.4c0.1-0.1,0.1-0.3,0.2-0.4c-0.6-0.5-1.1-1-1.7-1.4c-2.8-1.9-4.4-4.7-4.8-7.9c-0.4-2.7,0.5-5.4,1.8-7.9
	c1.4-2.5,3.3-4.5,5.6-6.2c0.2-0.1,0.2-0.5,0.2-0.7c-0.4-0.1-0.9-0.4-1.1-0.3c-3.5,2.8-6.5,6-7.4,10.6c-0.4,1.8-0.5,3.6-0.7,5.5
	c-2.7-1.4-5.2-3.1-6.6-5.9c1.3-0.4,2.7-0.8,4-1.3c0.3-0.1,0.5-0.7,0.7-1.1c-0.5-0.1-0.9-0.3-1.4-0.2c-1.5,0.3-2.9,0.7-4.3,1.2
	c-0.5,0.2-1.1,0.6-1.4,1c-2,2.6-4.5,3-7.3,1.3c-2.5-1.6-4.4-3.8-6.5-5.7c-0.8-0.7-2.3-0.6-3.5-0.7c-0.1,0-0.3,0.4-0.3,0.5
	c0,0.2,0.3,0.4,0.4,0.4c0.3,0.1,0.7,0.1,0.9,0.1c-1.1,0.9-2.2,1.9-3.3,2.8c-1.1,0.8-2.3,0.2-2.5-1.1c-0.3-1.4,0.9-2.9,2.2-2.7
	c0.9-2.3,2.1-4.3,4.5-5.1c1.6-0.6,3.1-0.8,4.7-0.5c-0.2,0.4-0.4,0.8-0.5,1.1c0.4,0.1,0.9,0.5,1.2,0.4c1.6-0.4,3,0.2,4.3,1
	c0.5,0.3,0.7,0.9,1,1.4c0.1,0,0.2-0.1,0.3-0.1c0.2-1.2,0.3-2.3,0.6-3.5c0.1-0.6,0.3-1.2,0.6-1.7c0.5-1.2,0.5-1.4-0.9-1.8
	c-0.3,1.2-0.7,2.4-1,3.7c-0.2,0.8-0.5,0.8-1.1,0.5c-0.4-0.3-1-0.4-1.4-0.7c-0.3-0.3-0.5-0.7-0.8-1c-0.3-0.3-0.6-0.9-1-0.9
	c-1.5-0.2-2.4-1.2-2.1-2.6c0.3-1.1-0.3-1.2-0.9-1.3c-0.5,1-0.9,1.9-1.5,3.1c-0.6-1.4-1-2.4-0.6-3.7c0.1-0.4-0.1-0.9-0.2-1.3
	c-0.1,0-0.2,0-0.3,0c-0.6,0.9-1.1,1.7-1.7,2.6c-0.6-1.9,1.4-3.4,0.3-5.5c-0.5,0.8-0.9,1.4-1.3,2.1c-0.1-0.1-0.3-0.2-0.4-0.2
	c0.5-1.2,1.1-2.3,1.5-3.5c0.3-0.7,0.5-1.3,0.6-2c0.1-0.6-0.1-1.2-0.6-1.3c-0.6,1.4-1.1,2.6-1.6,3.9c-0.2,0-0.3,0-0.5,0
	c-0.1-0.5-0.2-1-0.3-1.6c-0.2,0-0.6-0.1-1-0.2c0.4-0.5,0.7-0.9,1.2-1.5c-0.6-0.1-0.9-0.1-1.3-0.1c0-0.1-0.1-0.2-0.1-0.2
	c0.3-1,0.4-2.1,0.8-3.1c0.3-0.6,0.9-1,1.6-1.8c-0.2-1-0.3-2.3,1-3.3c0.9,1,0.6,1.9,0.1,2.9c-0.6,1.4-0.2,2.6,0.8,3.8
	c0.4,0.4,0.7,0.9,1.1,1.3c2.8-1.6,2.1-6.1-0.3-7.7c0.7-1,1.9-0.9,2.8-1.3c0.3-0.1,0.8-0.3,0.8-0.4c0.4-2.2,2.2-3.1,3.7-4.4
	c2.9-2.5,6.4-2.8,10-2.1c1.4,0.3,2.6,0.8,4,1.2c0.5,0.2,1.1,0.2,1.7,0.3c0-0.1,0.1-0.2,0.1-0.4c-0.6-0.3-1.3-0.6-2.2-1.1
	c3.3-1.2,6,0.6,8.7,1.1c0.3-1.3,2.9-2.8,4-2.5c0.2,0.1,0.5,0.4,0.5,0.5c0,0.8,0.1,1.8-0.3,2.4c-0.7,1.1-1.8,2-2.6,3
	C330.3,104.6,330.1,105,329.7,105.4z M307.5,112c0.6-1.2,1.4-2.4,0.3-3.6c-0.3-0.4-0.9-0.5-1.3-0.8c0.4,1.7,0.3,1.7-0.2,3.5
	c-0.2,0.6-0.3,1.7,0.1,2.1c0.7,0.6,0.7,1.3,0.8,2.1c0.1,0.5,0.4,1,0.7,1.3c0.2,0.1,0.7-0.2,1.2-0.3c0.2,0.3,0.6,0.7,0.9,1.1
	c1.6,2.2,3.7,3.3,6.4,3c1.3-0.1,2.6-0.8,3.9-1.2c0.1-0.1,0.3-0.3,0.3-0.5c0-0.6,0.3-1.4,0-1.9c-0.8-1.1-0.4-2,0.1-2.7
	c0.5,0.1,1.1,0.4,1.5,0.2c1.2-0.5,2.3-1.2,3.7-2c-1.9-1.2-3.4-0.1-4.9,0.9c-0.5-2,0.3-4.3-2.2-5.7c0.3,1.4,0.6,2.4,0.7,3.6
	c0.2,1.1,0,2.3-0.7,3.2c-0.4-0.1-0.7-0.2-1-0.2c-0.2,0-0.4,0.2-0.5,0.4c0,0.1,0.1,0.4,0.2,0.4c1.4,0.2,1.3,1.6,2,2.4
	c-3.3,3-7.1,2.6-9-1.1c-0.5-0.9-0.9-1.7-2-1.5C308.1,113.7,309.4,112.4,307.5,112z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity35 ? this.state.opacity35 : 1}
              fill={this.state.color35 ? this.state.color35 : 'transparent'} onClick={() => {
          this.setState({ color35: color, opacity35: opacity });
        }} d="M378.6,148.8c-1.8-1.7-2.7-3.7-3.2-6c-0.5-2.3-1.2-4.5-1.7-6.8c-0.2-1.2-0.1-2.5-0.1-3.8c0,0,0-0.1,0.1-0.1
	c1.4-0.6,2.8-1.4,4.3-1.8c0.7-0.2,1,0.8,0.8,1.5c-0.2,0.7-0.5,1.5-0.8,2.1c-1.4,2.4-1.1,5-0.6,7.5c0.4,2.1,0.9,4.2,1.3,6.3
	C378.7,148.1,378.6,148.5,378.6,148.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1}
              fill={this.state.color36 ? this.state.color36 : 'transparent'} onClick={() => {
          this.setState({ color36: color, opacity36: opacity });
        }} d="M315.8,148.3c-0.1,0-0.1,0.1-0.2,0.1c-1.7,0.5-2.7,0.2-2.9-0.7c-0.1-0.6,1.4-2.4,2.3-2.5
	c1.1-0.1,2.2-0.2,3.2,0.7c0.9,0.8,2,1.5,3,2.2c-1.2,0.6-2.4,0.8-3.6,0.3c-0.6-0.3-0.9-0.6-0.5-1.3c0.1-0.2,0.2-0.7,0.1-0.9
	c-0.1-0.2-0.6-0.5-0.8-0.4c-0.3,0.2-0.5,0.6-0.6,0.9C315.7,147.3,315.8,147.8,315.8,148.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity37 ? this.state.opacity37 : 1}
              fill={this.state.color37 ? this.state.color37 : 'transparent'} onClick={() => {
          this.setState({ color37: color, opacity37: opacity });
        }} d="M371,152.8c-1.3,0-2.4,0-3.6,0c-1.2,0-1.5-0.6-0.9-1.6c0-0.1,0.1-0.1,0.1-0.2c1.2-1.8,1.8-1.8,2.9,0.1
	C369.9,151.7,370.4,152.2,371,152.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity38 ? this.state.opacity38 : 1}
              fill={this.state.color38 ? this.state.color38 : 'transparent'} onClick={() => {
          this.setState({ color38: color, opacity38: opacity });
        }}
              d="M300.4,111.4c-0.3,0.8-0.5,1.6-0.8,2.5c-1.2-1.2-1.2-1.2,0.5-2.6C300.2,111.3,300.3,111.3,300.4,111.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity39 ? this.state.opacity39 : 1}
              fill={this.state.color39 ? this.state.color39 : 'transparent'} onClick={() => {
          this.setState({ color39: color, opacity39: opacity });
        }} d="M409.9,77c0.3-0.3,0.6-0.7,0.9-0.7c1-0.2,3.3,1.4,3.5,2.7c0.1,0.7-0.8,1.5-1.4,2.2c-0.4-1.8-0.7-3.5-2.9-3.8
	C410,77.3,409.9,77.1,409.9,77z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity40 ? this.state.opacity40 : 1}
              fill={this.state.color40 ? this.state.color40 : 'transparent'} onClick={() => {
          this.setState({ color40: color, opacity40: opacity });
        }} d="M410.4,82c-0.3-1.2-0.7-2.4-1-3.6c0.2-0.1,0.3-0.2,0.5-0.3c0.4,0.3,0.9,0.6,1.2,1c0.5,1,0.5,2-0.2,2.9
	C410.7,82,410.5,82,410.4,82z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity41 ? this.state.opacity41 : 1}
              fill={this.state.color41 ? this.state.color41 : 'transparent'} onClick={() => {
          this.setState({ color41: color, opacity41: opacity });
        }} d="M174,112.4l0.6-0.3c0,0,2.7,0.2,4.2,3.7h-0.4C178.4,115.8,177.8,113.5,174,112.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity42 ? this.state.opacity42 : 1}
              fill={this.state.color42 ? this.state.color42 : 'transparent'} onClick={() => {
          this.setState({ color42: color, opacity42: opacity });
        }}
              d="M160.4,118.9c0,0,2.6-0.5,2.7-0.8c0.1-0.3-0.4-0.8-1-0.7C161.5,117.4,160.4,118.7,160.4,118.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity43 ? this.state.opacity43 : 1}
              fill={this.state.color43 ? this.state.color43 : 'transparent'} onClick={() => {
          this.setState({ color43: color, opacity43: opacity });
        }}
              d="M162.4,115.8c0,0,1.7-0.8,1.6-1.4s0.4,0.3,0,1.2c-0.4,0.8-0.1,1-0.4,1.1C163.3,116.8,162.2,116.6,162.4,115.8z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity44 ? this.state.opacity44 : 1}
              fill={this.state.color44 ? this.state.color44 : 'transparent'} onClick={() => {
          this.setState({ color44: color, opacity44: opacity });
        }} d="M122.1,62.8c0,0,0.4-1.3,1.7-1.5S123.8,63.3,122.1,62.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity45 ? this.state.opacity45 : 1}
              fill={this.state.color45 ? this.state.color45 : 'transparent'} onClick={() => {
          this.setState({ color45: color, opacity45: opacity });
        }} d="M146.4,53.5c0,0,3-1.8,4-1.3c0,0-2.9-0.6-4,1C145.3,54.7,146.4,53.5,146.4,53.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity46 ? this.state.opacity46 : 1}
              fill={this.state.color46 ? this.state.color46 : 'transparent'} onClick={() => {
          this.setState({ color46: color, opacity46: opacity });
        }} d="M116.1,53c0,0,0.6-0.8,1-0.5c0.3,0.3-0.2,0.9-0.5,1.3C116.3,54.1,116.2,53.4,116.1,53z" />
      </SvgIcon>
    )
  }
};