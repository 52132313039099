import React, { Component } from "react";
import { SvgIcon } from "@material-ui/core";

export default class Line extends Component<any, any>{

  render() {
    const st0 = "#084B5E";
    const { disabled } = this.props;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-362.8 572.8 69.1 42.8"}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <g id="XMLID_298_">
          <path id="XMLID_54_" fill={st0} d="M-361.1,615.6c-0.4,0-0.8-0.2-1.2-0.5c-0.6-0.6-0.6-1.7,0-2.3l23.9-23.8l13.1,13.1l28.9-28.9
		c0.6-0.6,1.7-0.6,2.3,0c0.6,0.6,0.6,1.7,0,2.3l-31.2,31.2l-13.1-13.1l-21.6,21.5C-360.3,615.5-360.7,615.6-361.1,615.6z" />
        </g>
      </SvgIcon>
    )
  }

};
