import Drawable from "./Drawable";
import { Line } from "react-konva";
import React from "react";

export default class EraseDrawable extends Drawable {
  points: any[];
  drawableType = 'EraseDrawable';
  constructor(startx, starty, color, weight, opacity) {
    super(startx, starty, color, weight, opacity);
    this.points = [startx, starty];
    this.color = color;
    this.weight = weight;
  }

  registerMovement(x, y) {
    this.points = [...this.points, x, y];
  }

  render() {
    return <Line
      points={this.points}
      fill={this.color}
      stroke={this.color}
      strokeWidth={this.weight}
      globalCompositeOperation={ 'destination-out'}
    />;
  }
}