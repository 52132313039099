import { Ellipse } from "react-konva";
import React from "react";
import RectangleDrawable from "./RectangleDrawable";

export default class EllipseDrawable extends RectangleDrawable {
  drawableType = 'EllipseDrawable';
  render() {
    const dx = (this.x - this.startx)/2;
    const dy = (this.y - this.starty)/2;
    const rx = Math.abs(dx);
    const ry = Math.abs(dy);
    const x = this.startx + dx;
    const y = this.starty + dy;
    return (
      <Ellipse
        x={x}
        y={y}
        fill={this.fill}
        stroke={this.color}
        strokeWidth={this.weight}
        radiusX={rx}
        radiusY={ry}
        opacity={this.opacity}
      />
    );
  }
}