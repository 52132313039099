import React, { Component } from "react";
import { SvgIcon } from "@material-ui/core";

export default class NextPage extends Component<any, any> {

  render() {
    const st0 = "#064B5E";
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-328.8 565.9 31 35.5"}
      >
        <g id="XMLID_481_">
          <g id="XMLID_482_">
            <path id="XMLID_1_" fill={st0} d="M-327.6,565.9c0.2,0,0.4,0.1,0.6,0.2l28.6,16.5c0.4,0.2,0.6,0.6,0.6,1.1c0,0.4-0.2,0.8-0.6,1.1
			l-28.6,16.4c-0.4,0.2-0.9,0.2-1.2,0c-0.4-0.2-0.6-0.6-0.6-1.1v-33c0-0.4,0.2-0.8,0.6-1.1C-328,565.9-327.8,565.9-327.6,565.9z" />
          </g>
        </g>
      </SvgIcon>
    )
  }
};
