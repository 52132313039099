export const showAllDrawables = (show, fromMenu = false) => ({
  type: 'SHOW_DRAWABLES',
  show
})

export const drawingLine = (fromMenu = false) => ({
  type: 'SHOW_LINE',
  fromMenu
})

export const drawingArrow = (fromMenu = false) => ({
  type: 'SHOW_ARROW',
  fromMenu
})

export const drawingCircle = (fromMenu = false) => ({
  type: 'SHOW_CIRCLE',
  fromMenu
})

export const drawingPoint = (showPointId?: string, pageY?: number) => ({
  type: 'SHOW_POINT',
  showPointId,
  pageY
})

export const drawingPolygon = (showPolygonId?: string) => ({
  type: 'SHOW_POLYGON',
  showPolygonId
})

export const drawingCurvePolygon = (showCurvePolygonId?: string) => ({
  type: 'SHOW_CURVE_POLYGON',
  showCurvePolygonId
})

export const drawingCurve = (fromMenu = false) => ({
  type: 'SHOW_CURVE',
  fromMenu
})

export const drawingRect = (fromMenu = false) => ({
  type: 'SHOW_RECT',
  fromMenu
})

export const showText = (showTextId?: string, pageY?: number) => ({
  type: 'SHOW_TEXT',
  showTextId,
  pageY
})

export const showFill = (showedFill = false) => ({
  type: 'SHOW_FILL',
  showedFill
})

export const drawingEllipse = (fromMenu = false) => ({
  type: 'SHOW_ELLIPSE',
  fromMenu
})

export const drawingErase = (fromMenu = false) => ({
  type: 'SHOW_ERASE',
  fromMenu
})

export const changeColor = (color, fromMenu = false) => ({
  type: 'CHANGE_COLOR',
  color,
  fromMenu
})

export const setOpacity = (opacity: boolean = false) => ({
  type: 'SET_OPACITY',
  opacity
})

export const changeWeight = (weight, fromMenu = false) => ({
  type: 'CHANGE_WEIGHT',
  weight,
  fromMenu
})

export const changeClient = (client) => ({
  type: 'CHANGE_CLIENT',
  client
})

export const dragImgEnable = (dragImgEnabled) => ({
  type: 'DRAG_IMG_ENABLE',
  dragImgEnabled
})

export const saveChanged = (saveChanging = '') => ({
  type: 'SAVE_CHANGING',
  saveChanging
})

export const saveChangedDevMode = (saveChangingDevMode = '') => ({
  type: 'SAVE_CHANGING_DEV_MODE',
  saveChangingDevMode
})

export const addPageDrawStateItem = (pageDrawStateItem: any, drawType?) => ({
  type: 'ADD_PAGE_DRAW_STATE',
  pageDrawStateItem,
  drawType
})

export const updatePageDrawStateItem = (pageDrawStateItem: any) => ({
  type: 'UPDATE_PAGE_DRAW_STATE',
  pageDrawStateItem
})

export const undo = () => ({
  type: 'UNDO'
})

export const redo = () => ({
  type: 'REDO'
})

export const setPageChangedState = (pageChangedStateItem: any = {}) => ({
  type: 'PAGE_CHANGED_STATE',
  pageChangedStateItem
})

export const setPageChangedStateText = (pageChangedStateText: boolean = false) => ({
  type: 'PAGE_CHANGED_STATE_TEXT',
  pageChangedStateText
})

export const setPageSelectedShape = (pageSelectedShape: any = null) => ({
  type: 'PAGE_SELECTED_SHAPE',
  pageSelectedShape
})

export const copySelectedShape = (copiedSelectedShape: string = '') => ({
  type: 'COPY_SELECTED_SHAPE',
  copiedSelectedShape
})

export const draggableImageMoved = (isDraggableImageMoved: boolean = false) => ({
  type: 'DRAGGABLE_IMAGE_MOVED',
  isDraggableImageMoved
})

export const setMaskText = (maskText: boolean = false) => ({
  type: 'SET_MASK_TEXT',
  maskText
})

export const setMaskImages = (maskImages: boolean = false) => ({
  type: 'SET_MASK_IMAGES',
  maskImages
})

export const setPageEditable = (pageEditable: boolean = false) => ({
  type: 'SET_PAGE_EDITABLE',
  pageEditable
})

export const setPageToTaskContentChange = (pageToTaskContentChanged?: any) => ({
  type: 'SET_PAGE_TO_TASK_CONTENT_CHANGE',
  pageToTaskContentChanged
})

export const showDivider = (showedDivider: boolean = false, pageY?: number) => ({
  type: 'SHOW_DIVIDER',
  showedDivider,
  pageY
})

export const showAdvancedToolbar = (showedAdvancedToolbar: boolean = false) => ({
  type: 'SHOW_ADVANCED_TOOLBAR',
  showedAdvancedToolbar
})

export const showRuler = (showedRuler: boolean = false, pageY?: number) => ({
  type: 'SHOW_RULER',
  showedRuler,
  pageY
})

export const showRulerTriangle = (showedRulerTriangle: boolean = false, pageY?: number) => ({
  type: 'SHOW_RULER_TRIANGLE',
  showedRulerTriangle,
  pageY
})

export const showProtractor = (showedProtractor: boolean = false, pageY?: number) => ({
  type: 'SHOW_PROTRACTOR',
  showedProtractor,
  pageY
})

export const setMicrophone = (showedMicrophone: boolean = false) => ({
  type: 'SET_MICROPHONE',
  showedMicrophone
})

export const SVGImageChange = (isSVGImageChanged: boolean = false) => ({
  type: 'SVG_IMAGE_CHANGE',
  isSVGImageChanged
})

export const enableSVGClick = (enabledSVGClick: boolean = false) => ({
  type: 'ENABLE_SVG_CLICK',
  enabledSVGClick
})

export const setSVGClickEditable = (SVGClickEditable: boolean = false) => ({
  type: 'SET_SVG_CLICK_EDITABLE',
  SVGClickEditable
})

export const mouseMove = (x: number, y: number) => ({
  type: 'MOUSE_MOVE',
  x,
  y
})
