import React, { Component } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import VisibleDrawingImage from "../components/VisibleDrawingImage";
import Axios from "../ajax/Axios";
import _ from "lodash";
import { getFontSize } from "../util/Util";
import {
  enableSVGClick, mouseMove,
  setMaskText,
  setPageChangedStateText,
  setPageEditable,
  setSVGClickEditable,
  SVGImageChange
} from "../actions";
import ReactDOM from "react-dom";
import RouteButton from "../components/RouteButton";
import RectangleDrawable from "../drawing/RectangleDrawable";
import AudioPlayer from "../components/AudioPlayer";
import { Rnd } from "react-rnd";
import { ACTION_TOOLTIPS, PLACEHOLDERS } from "../util/APP_CONSTANS";

export default class No extends Component<any, any> {
  axios: Axios;
  state = {
    fields: [],
    fieldRectangles: [],
    layer2: null,
    layerSVG: null,
    activeAudioPlayerNumber: -1
  }
  initialFields: any[] = null;
  userDraggableSVGImagePositions: any[] = null;
  initialSVGImages: any[] = null;
  timer;
  dom: any;
  scaleDefault = 912;
  firstLoad = false;
  svgComponentList: any;

  componentDidMount() {
    this.dom = ReactDOM.findDOMNode(this);
  }

  constructor(props) {
    super(props);
    const { edition, userName } = this.props;
    this.axios = new Axios(userName, edition);
  }

  private getImageName = (prefix: string = '') => {
    const { edition, page } = this.props;
    return `${prefix}image_${page}_1`;
    //return `${prefix}image_${edition}_${page}_1`;
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    const doSaving = this.props.saveChanging && this.props.saveChanging !== prevProps.saveChanging;
    if (doSaving) {
      this.saveValues();
      this.props.dispatch(setPageChangedStateText());
      this.props.dispatch(setMaskText(false));
    }
    const isPageChangedNow = prevProps.page !== this.props.page;
    if (isPageChangedNow || _.isNull(this.state.layer2)) {
      const stateObj: any = {
        fields: [],
        fieldRectangles: [],
        layer2: {
          images: []
        },
        layerSVG: {
          draggableSVGImagePositions: []
        }
      };
      if (isPageChangedNow) {
        stateObj.activeAudioPlayerNumber = -1;
      }
      this.setState(stateObj, () => {
          if (this.props.layer2) {
            let { images } = this.props.layer2;
            this.setState({
              layer2: {
                images
              }
            }, () => {
              this.axios.jsonRequest(this.getImageName('draggable_'), this.setLayer2, this.setLayer2Error);
            })
          }
          if (this.props.layerSVG) {
            let { draggableSVGImagePositions } = this.props.layerSVG;
            this.setState({
              layerSVG: {
                draggableSVGImagePositions,
                hidden: this.props.userName !== 'admin'
              }
            }, () => {
              this.axios.jsonRequest(this.getImageName('SVG_'), this.setLayerSVG, this.setLayerSVGError);
            })
          }
          this.axios.jsonRequest(this.getImageName(), this.renderFields);
        }
      );
    }
    if (!this.firstLoad || this.props.editable !== prevProps.editable || this.props.SVGClickEditable !== prevProps.SVGClickEditable) {
      this.firstLoad = true;
      this.props.dispatch(setPageEditable(this.props.editable));
      this.props.dispatch(setSVGClickEditable(this.props.SVGClickEditable));
    }
    if (this.props.page !== prevProps.page) {
      this.props.dispatch(enableSVGClick(this.props.SVGClickEditable));
    }

    //DEV MODE SAVED
    const doHandleExportDevJson = this.props.saveChangingDevMode && this.props.saveChangingDevMode !== prevProps.saveChangingDevMode;
    if (doHandleExportDevJson) {
      this.handleExportSVGDevJson();
    }
  }

  private saveValues = () => {
    const { fields } = this.state;
    // eslint-disable-next-line array-callback-return
    const jsonValue = fields.map((item) => {
      if (!item.type || item.type === 'input') {
        return item.value;
      } else {
        return item.text;
      }
    });
    const json = { fields: jsonValue };
    const konvaJSON = JSON.stringify(json, null, "  ");
    this.axios.postRequest({ action: 'saveKonvaJSON', name: this.getImageName(), konvaJSON });
    this.handleExportSVGJson();
  }

  private handleExportSVGDevJson = () => {
    const layerSVG = _.get(this.state, 'layerSVG');
    if (layerSVG?.draggableSVGImagePositions.length) {
      const konvaJSON = JSON.stringify(layerSVG, null, "  ");
      this.axios.postRequest({ action: 'saveKonvaJSON', name: this.getImageName('SVG_'), konvaJSON });
    }
  };

  private handleExportSVGJson = () => {
    if (!_.isEqual(this.userDraggableSVGImagePositions, this.initialSVGImages)) {
      const layerSVG = {
        draggableSVGImagePositions: this.userDraggableSVGImagePositions
      }
      const konvaJSON = JSON.stringify(layerSVG, null, "  ");
      this.axios.postRequest({ action: 'saveKonvaJSON', name: this.getImageName('SVG_'), konvaJSON });
    }
  };

  private renderFields = (response: any, name, adminResponse) => {
    if (name === this.getImageName()) {
      let fields = _.get(adminResponse, 'data.fields');
      if (!_.isEmpty(fields)) {
        const userFields = response.data.fields;
        if (!_.isEmpty(userFields)) {
          fields = fields.map((item, index) => {
            if (!item.type || item.type === 'input') {
              item.value = userFields[index];
            } else {
              item.text = userFields[index];
            }
            return item;
          })
        }
        //if (_.isNull(this.initialFields)) {
        this.initialFields = _.cloneDeep(fields);
        //}
        this.setState({
          fields
        })
      } else if (this.props.userName === 'admin') {
        let fieldRectangles = _.get(response, 'data.fields');
        if (!_.isEmpty(fieldRectangles)) {
          let color;
          fieldRectangles = fieldRectangles.map((item, index) => {
            let { x, y, width = 50, height = 50, rotation, textAlign, stageWidth = this.scaleDefault, type } = item;
            const domWidth = this.dom.offsetWidth;
            const scale = domWidth / stageWidth;
            x = x * scale;
            y = y * scale;
            width = width * scale;
            height = height * scale;
            switch (type) {
              case 'button-text':
                color = '#0000FF';
                break;
              case 'button-drawing':
                color = '#008000';
                break;
              case 'button-text-drawing':
                color = '#FFFF00';
                break;
              case 'microphone':
                color = '#FF00FF';
                break;
              default:
                color = textAlign === 'left' ? 'red' : 'black';
                break;
            }
            item = new RectangleDrawable(x, y, color, 1, rotation);
            item.registerMovement(x + width, y + height);
            return item;
          })
          this.initialFields = _.cloneDeep(fieldRectangles);
          this.setState({
            fieldRectangles
          })
        }

      }
    }
  }

  private setLayer2 = (response: any, name) => {
    if (name === this.getImageName('draggable_')) {
      const domWidth = this.dom.offsetWidth;
      const draggableImagePositions = response?.data?.draggableImagePositions;
      if (!_.isEmpty(draggableImagePositions) && !_.isEmpty(this.props.layer2)) {
        let { images } = this.props.layer2;
        const layer2Images = [];
        let i;
        images.forEach((item) => {
          for (i = 0; i < item.count; i++) {
            layer2Images.push(item);
          }
        })
        images = layer2Images.map((item, index) => {
          let { x, y, rotation, changed = false, stageWidth = this.scaleDefault } = draggableImagePositions[index];
          x = x * (domWidth / stageWidth);
          y = y * (domWidth / stageWidth);
          const { cfg } = item;
          return {
            cfg: {
              ...cfg,
              x,
              y,
              rotation,
              changed
            },
            count: 1
          }
        })
        this.setState({
            layer2: {
              images
            }
          }
        )
      }
    }
  }

  private setLayerSVG = (response: any, name, adminResponse) => {
    if (name === this.getImageName('SVG_')) {
      let draggableSVGImagePositions = adminResponse?.data?.draggableSVGImagePositions;
      if (!_.isEmpty(draggableSVGImagePositions)) {
        draggableSVGImagePositions = this.getDraggableSVGImagePositions(draggableSVGImagePositions);
        this.userDraggableSVGImagePositions = response.data.draggableSVGImagePositions;
        if (_.isEmpty(this.userDraggableSVGImagePositions)) {
          this.userDraggableSVGImagePositions = draggableSVGImagePositions.map((item, index) => {
            item = {};
            return item;
          })
        }
        this.initialSVGImages = _.cloneDeep(this.userDraggableSVGImagePositions);
        this.setState({
            layerSVG: {
              draggableSVGImagePositions,
              userDraggableSVGImagePositions: this.initialSVGImages,
              hidden: false
            }
          }
        )
      } else if (this.props.userName === 'admin') {
        let draggableSVGImagePositions = response?.data?.draggableSVGImagePositions;
        draggableSVGImagePositions = this.getDraggableSVGImagePositions(draggableSVGImagePositions);
        if (!_.isEmpty(draggableSVGImagePositions)) {
          this.setState({
              layerSVG: {
                draggableSVGImagePositions
              }
            }
          )
        }
      }
    }
  }

  private getDraggableSVGImagePositions = (draggableSVGImagePositions) => {
    const domWidth = this.dom.offsetWidth;
    const layerSVGImagePositions = this.state?.layerSVG?.draggableSVGImagePositions;
    if (layerSVGImagePositions.length) {
      let draggableSVGImagePositionsItem;
      draggableSVGImagePositions = layerSVGImagePositions.map((item, index) => {
        draggableSVGImagePositionsItem = draggableSVGImagePositions && draggableSVGImagePositions[index] ? draggableSVGImagePositions[index] : item;
        let {
          x,
          y,
          width,
          height,
          stageWidth = this.scaleDefault
        } = draggableSVGImagePositionsItem;
        x = x * (domWidth / stageWidth);
        y = y * (domWidth / stageWidth);
        width = width * (domWidth / stageWidth);
        height = height * (domWidth / stageWidth);
        const { src } = item;
        return {
          src,
          x,
          y,
          width,
          height,
          stageWidth
        }
      })
    }
    return draggableSVGImagePositions;
  }

  private setLayer2Error = () => {
    // const layer2 = this.props.layer2 ? { ...this.props.layer2 } : {
    //   images: []
    // }
    // this.setState({ layer2 })
  }

  private setLayerSVGError = () => {
    // const layer2 = this.props.layer2 ? { ...this.props.layer2 } : {
    //   images: []
    // }
    // this.setState({ layer2 })
  }

  private handleChangeInputField = (event: any, index: number): void => {
    const value = event.target.value;
    let trimmedValue = value;
    let fields = [...this.state.fields];
    if (!_.isUndefined(fields[index].trim)) {
      switch (fields[index].trim) {
        case '.':
          trimmedValue = value.replace(/[\s.]/g, '');
          break;
        case ',':
          trimmedValue = value.replace(/[\s,]/g, '');
          break;
        case ';':
          trimmedValue = value.replace(/[\s;]/g, '');
          break;
        case 'all':
          trimmedValue = value.replace(/[\s.,;:\-_'"!?]/g, '');
          break;
        default:
          trimmedValue = value.replace(/\s+/g, '');
          break;
      }
    }
    fields[index].value = value;
    if (trimmedValue === fields[index].result) {
      fields[index].checked = true;
    } else if (!_.isUndefined(fields[index].result)) {
      fields[index].checked = false;
    }
    if (!_.isEqual(fields, this.initialFields)) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.props.dispatch(setPageChangedStateText(true));
      }, 500)
    }
    this.setState({
      fields
    });
  };

  private getRenderFields = (showDrawables: boolean) => {
    const { page, isPageChanged, dispatch, edition, showedMicrophone } = this.props;
    const { fields } = this.state;
    let initStyle: any = {
      backgroundColor: "transparent",
      height: "100%",
      width: "100%",
      textAlign: "",
      outline: "none",
      padding: 0,
      border: "none"
    };
    let rootButtonCounter = 0;
    let audioButtonCounter = 0;
    let resultTop;
    let resultLeft;
    let sizeCheckMarkIcon = 20;
    let checkMarkIconPadding = 3;
    return (
      _.isEmpty(fields) ? null : fields.map((item, index) => {
        let {
          x,
          y,
          width,
          height,
          rotation,
          value,
          textAlign,
          stageWidth = this.scaleDefault,
          type = 'input',
          text,
          checked,
          result,
          resultCorrection
        } = item;
        if (type === 'microphone') {
          audioButtonCounter++;
        }
        const domWidth = this.dom.offsetWidth;
        const scale = domWidth / stageWidth;
        x = x * scale;
        y = y * scale;
        width = width * scale;
        height = height * scale;
        const style = { ...initStyle };
        style.fontSize = `${getFontSize(height)}px`;
        style.textAlign = textAlign;
        if (rotation) {
          const d = Math.sqrt(width * width + height * height) / 2;
          const alfa = (Math.atan(height / width) * 180) / Math.PI;
          const beta = alfa + rotation;
          y -= height / 2 - (Math.sin((beta * Math.PI) / 180)) * d;
          x -= width / 2 - (Math.cos((beta * Math.PI) / 180)) * d;
          style.transform = `rotate(${rotation}deg)`;
        }
        if (textAlign === 'left') {
          style.paddingLeft = '5px';
          width -= 5;
        }
        if (showDrawables) {
          style.lineHeight = `${height}px`;
        }
        if (!_.isEmpty(result)) {
          resultTop = y + height / 2 - sizeCheckMarkIcon / 2;
          resultLeft = x + width + checkMarkIconPadding;
          sizeCheckMarkIcon = 20;
          checkMarkIconPadding = 3;
          if (resultCorrection) {
            if (resultCorrection.size === 's') {
              checkMarkIconPadding = 1;
              sizeCheckMarkIcon = 15;
            }
            resultTop = y + height / 2 - sizeCheckMarkIcon / 2;
            resultLeft = x + width + checkMarkIconPadding;
            if (resultCorrection.pos === 'b') {
              resultTop = y + height + checkMarkIconPadding;
              resultLeft = x + width / 2 - sizeCheckMarkIcon / 2;
              if (resultCorrection.y) {
                resultTop = resultCorrection.y * scale + checkMarkIconPadding;
              }
            } else if (resultCorrection.pos === 'l') {
              resultLeft = x - sizeCheckMarkIcon - checkMarkIconPadding;
              if (resultCorrection.x) {
                resultLeft = resultCorrection.x * scale - sizeCheckMarkIcon - checkMarkIconPadding;
              }
            } else if (resultCorrection.pos === 't') {
              resultTop = y - sizeCheckMarkIcon - checkMarkIconPadding;
              resultLeft = x + width / 2 - sizeCheckMarkIcon / 2;
              if (resultCorrection.y) {
                resultTop = resultCorrection.y * scale - sizeCheckMarkIcon - checkMarkIconPadding;
              }
            } else if (resultCorrection.pos === 'i') {
              resultTop = y + height - sizeCheckMarkIcon - checkMarkIconPadding + (resultCorrection.y ? resultCorrection.y : 0);
              resultLeft = x + width - sizeCheckMarkIcon - checkMarkIconPadding + (resultCorrection.x ? resultCorrection.x : 0);
            } else {
              resultLeft = x + width + checkMarkIconPadding;
              if (resultCorrection.x) {
                resultLeft = resultCorrection.x * scale + checkMarkIconPadding;
              }
            }
          }
        }
        return (
          <>
            <Box
              position="absolute"
              top={y}
              left={x}
              width={type === 'input' ? width : 'auto'}
              height={type === 'input' ? height : 'auto'}
              zIndex="tooltip"
            >
              {
                type === 'input' ?
                  <input
                    type={"text"}
                    style={style}
                    value={value}
                    onChange={(e) => {
                      this.handleChangeInputField(e, index);
                    }}
                  /> :
                  type === 'microphone' ?
                    (showedMicrophone && <AudioPlayer
                      edition={edition}
                      number={audioButtonCounter}
                      page={page}
                      onPlay={(number) => {
                        this.setState({
                          activeAudioPlayerNumber: number
                        })
                      }}
                      isActive={this.state.activeAudioPlayerNumber === audioButtonCounter}
                    />) :
                    <RouteButton
                      task={{
                        number: ++rootButtonCounter,
                        page: page,
                        type,
                        text,
                        fields: type === 'button-text' || type === 'button-text-drawing' ? [...fields] : []
                      }}
                      text={value}
                      isPageChanged={isPageChanged}
                      dispatch={dispatch}
                    />
              }
            </Box>
            {type === 'input' && checked ? <Tooltip title={PLACEHOLDERS.CHECK_MARK}>
                <Box
                  position="absolute"
                  top={resultTop}
                  left={resultLeft}
                  width={`${sizeCheckMarkIcon}px`}
                  height={`${sizeCheckMarkIcon}px`}
                  zIndex="tooltip"
                  className={"fade_in"}
                >
                  <img
                    src={'images/icons/checkMark.png'}
                    width={"100%"}
                    height={"100%"}
                  />
                </Box>
              </Tooltip>
              : null}
            {type === 'input' && _.isBoolean(checked) && !checked ? <Tooltip title={PLACEHOLDERS.INCORRECT}>
                <Box
                  position="absolute"
                  top={resultTop}
                  left={resultLeft}
                  width={`${sizeCheckMarkIcon}px`}
                  height={`${sizeCheckMarkIcon}px`}
                  zIndex="tooltip"
                  className={"fade_in"}
                >
                  <img
                    src={'images/icons/incorrect.png'}
                    width={"100%"}
                    height={"100%"}
                  />
                </Box>
              </Tooltip>
              : null}
          </>
        )
      })
    )
  };

  private getRenderSVGImages = () => {
    const { layerSVG } = this.state;
    if (layerSVG) {
      const { draggableSVGImagePositions, userDraggableSVGImagePositions, hidden } = layerSVG;
      const { color, userName, dispatch, enabledSVGClick, opacity } = this.props;
      const rndStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "solid 1px #ddd",
        background: "transparent"
      };
      let draggableSVGImagePositionsArr;
      const domWidth = this.dom.offsetWidth;
      return (
        _.isEmpty(draggableSVGImagePositions) ? null : draggableSVGImagePositions.map((item, index) => {
          let {
            src,
            x,
            y,
            width,
            height,
            stageWidth = domWidth
          } = item;
          // const scale = domWidth / stageWidth;
          // x = x * scale;
          // y = y * scale;
          // width = width * scale;
          // height = height * scale;
          const SVGIcon = src;
          return (
            userName === 'admin' ?
              <Box
                position="absolute"
                zIndex="tooltip"
              >
                <Rnd
                  style={rndStyle}
                  size={{ width, height }}
                  position={{ x, y }}
                  onDragStop={(e, d) => {
                    draggableSVGImagePositionsArr = [...this.state.layerSVG.draggableSVGImagePositions];
                    draggableSVGImagePositionsArr[index] = {
                      ...draggableSVGImagePositionsArr[index],
                      x: d.x,
                      y: d.y,
                      stageWidth: domWidth
                    };
                    this.setState({
                      layerSVG: {
                        draggableSVGImagePositions: draggableSVGImagePositionsArr
                      }
                    });
                  }}
                  onResizeStop={(e, direction, ref, delta, position) => {
                    draggableSVGImagePositionsArr = [...this.state.layerSVG.draggableSVGImagePositions];
                    draggableSVGImagePositionsArr[index] = {
                      ...draggableSVGImagePositionsArr[index],
                      width: parseFloat(ref.style.width),
                      height: parseFloat(ref.style.height),
                      stageWidth: domWidth,
                      ...position
                    };
                    this.setState({
                      layerSVG: {
                        draggableSVGImagePositions: draggableSVGImagePositionsArr
                      }
                    });
                  }}
                >{
                  _.isString(SVGIcon) ?
                    <img
                      src={SVGIcon}
                      width={"100%"}
                      height={"100%"}
                    /> :
                    <SVGIcon
                      color={color}
                      borderColor={"red"}
                    />
                }
                </Rnd>
              </Box> :
              <Box
                position="absolute"
                top={y}
                left={x}
                width={width}
                height={height}
                zIndex={enabledSVGClick ? "tooltip" : undefined}
                hidden={hidden}
              >
                {
                  _.isString(SVGIcon) ?
                    <img
                      src={SVGIcon}
                      width={"100%"}
                      height={"100%"}
                    /> :
                    <SVGIcon
                      color={color}
                      borderColor={"transparent"}
                      opacity={opacity}
                      value={userDraggableSVGImagePositions ? userDraggableSVGImagePositions[index] : {}}
                      onPartClick={(iconState) => {
                        if (!_.isEmpty(this.userDraggableSVGImagePositions)) {
                          const is = { ...iconState };
                          if (!_.isEqual(this.userDraggableSVGImagePositions[index], is)) {
                            this.userDraggableSVGImagePositions[index] = is;
                            if (!_.isEqual(this.userDraggableSVGImagePositions, this.initialSVGImages)) {
                              dispatch(SVGImageChange(true));
                            } else {
                              dispatch(SVGImageChange(false));
                            }
                          }
                        }
                      }}
                    />
                }
              </Box>
          )
        })
      )
    } else {
      return null;
    }
  };

  render() {
    const { edition, page, showDrawables, userName, dispatch } = this.props;
    const layer2Response = this.state.layer2;
    const fieldRectangles = this.state.fieldRectangles;
    return (
      <Box
        display="flex"
        style={{ position: 'relative' }}
        onClick={(e) => {
          dispatch(mouseMove(e.nativeEvent.offsetX, e.nativeEvent.offsetY));
        }}
      >
        <VisibleDrawingImage
          name={this.getImageName()}
          image={{
            src: `images/${edition}/No${page}/${page}.png`
          }}
          layer2={layer2Response}
          fieldRectangles={fieldRectangles}
          edition={edition}
          userName={userName}
        />
        {this.getRenderSVGImages()}
        {this.getRenderFields(showDrawables)}
      </Box>
    )
  }
}