export const ContentList = [{
  page: 2,
  header: "Положаји предмета и бића"
}, {
  page: 3,
  header: "Величина предмета и бића"
}, {
  page: 4,
  header: "Геометријске фигуре (круг, правоугаоник, квадрат и троугао)"
}, {
  page: 5,
  header: "Линије"
}, {
  page: 6,
  header: "Бројеви 1, 2, 3"
}, {
  page: 7,
  header: "Упоређивање бројева"
}, {
  page: 8,
  header: "Број 0"
}, {
  page: 9,
  header: "Бројеви до 10"
}, {
  page: 10,
  header: "Одузимање двоцифрених броја (облика 18 – 12)"
}, {
  page: 11,
  header: "Сабирање до 100 (облика 42 + 13)"
}]