import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_14_1 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 444 484.8"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M318.8,53.7c0.4,1.3,0.7,2.5,1.2,3.7c0.1,0.3,0.7,0.5,1.1,0.7c0.2-0.4,0.3-0.8,0.6-1.1c0.5-0.5,1.2-0.9,2-1.5
	c0.5,2.9,2.7,2.2,4.4,2.4c1,0.1,1.6,0.2,1.7,1.4c0,1.1,0.8,1.3,2,1.4c1.8,0.2,3.7,0.6,5.4,1.3c1.3,0.5,2.4,1.6,3.5,2.6
	c0.6,0.6,1.1,1.5,1.5,2.3c0.6,1.4,1.1,2.9,1.7,4.4c-1.4,1.9-3.4,2.8-5.9,2.5c-0.4-0.1-0.7,0-1.1,0c-0.5,0-1.2-0.2-1.2,0.7
	c0.1,0.9,0.7,0.8,1.3,0.7c0.5,0,1.1,0,1.6,0.1c2.7,0.7,5.1,0.2,7-2c0.3-0.3,0.6-0.6,0.8-0.8c0.6-0.6,1.1-0.6,1.7,0
	c1.7,1.6,3.6,2.6,5.9,2.9c1.9,0.3,3.8,0.1,5.4-1.2c0.6-0.5,0.9-1.2,1.4-1.7c0.3-0.3,1-0.4,1.3-0.3c0.3,0.1,0.5,0.8,0.5,1.2
	c-0.2,1.8-0.8,3.4-2.4,4.4c-2.1,1.4-4.2,2.9-6.2,4.5c-1.6,1.3-3.5,1.4-5.4,1.4c-2.2,0-4.4-0.1-6.5-0.7c-1.6-0.4-3.1-1.4-4.5-2.3
	c-0.4-0.3-0.7-1.1-0.7-1.7c-0.1-1.2-1.3-2.1-2.4-1.7c-1.2,0.4-2.4,2.2-2.3,3.4c0,0.5,0.2,1,0.5,1.4c0.2,0.3,0.8,0.6,0.9,0.4
	c0.4-0.2,0.7-0.6,0.8-1c0.2-0.5,0.2-1.2,0.6-1.8c1,1.5,2.2,2.6,3.8,3.3c1.5,0.6,3,1.4,4.5,2.1c-1.5,0.1-3.2,0.3-4.9,0.3
	c-2.4,0-4.8-0.3-6.8-2.1c-1.1-1-1.5-3.2-0.6-4.5c1.1-1.5,0.7-2.5-1.1-3c-3,3.8-0.5,9.7,5.8,10.9c-2.4,2-5.3,2.9-8.2,3.5
	c-1,0.2-2.3-0.6-3.5-0.9c-0.5-0.2-0.9-0.4-1.4-0.6c-0.2,0-0.5,0.1-0.5,0.3c-0.1,0.2,0,0.5,0.2,0.6c0.7,0.5,1.3,1,2.1,1.3
	c1.1,0.4,1.2,1.2,1,2.1c-0.6,4-2.1,7.7-3.9,11.3c-0.9,1.7-1.6,3.6-2.6,5.2c-0.9,1.5-2.3,2.8-3.4,4.1c-0.8,1-1.6,2.1-2.4,3.1
	c-2.1,2.9-3.8,6.1-6.6,8.4c-0.4,0.3-0.8,0.6-1.2,0.8c-2.5,1.3-5,2.7-7.6,3.7c-3,1.1-6.1,2-9.2,2.8c-2.2,0.6-4.4,0.9-6.7,1.4
	c0.5-1.5,1-2.8,1.3-4.2c0.1-0.4-0.2-1.1-0.5-1.3c-0.4-0.3-1-0.3-1.5-0.3c-0.2,0-0.4,0.6-0.5,1c-0.1,0.6,0,1.2-0.3,1.7
	c-0.5,0.9-0.9,2-1.7,2.6c-2.3,1.9-4.6,3.6-7,5.3c-0.6,0.4-0.8,0.9-0.6,1.6c0.1,0.2,0.1,0.6,0,0.8c-0.4,0.8,0.1,1.3,0.6,1.4
	c1.7,0.5,2.4,2.1,3.4,3.3c1.1,1.2,2.1,2.4,3.3,3.8c1.5-2.3,3.9-2.2,5.8-2.5c3.1-0.4,6.3-0.3,9.5-0.1c1.3,0,2.6,0.6,3.8,0.9
	c-0.2,1.2-0.7,1.3-1.5,1c-2.7-0.9-5.5-0.5-8.5-0.2c2.1,1.5,2.6,1.6,4.9,1.3c1-0.1,2.1,0,3.1,0.3c1.4,0.3,2.7,0.8,4,1.2
	c0,0.1,0,0.3,0,0.4c-0.8,0.1-1.6,0.3-2.5,0.4c-1.6,0.1-3.3,0.3-4.9,0.3c-0.8,0-1.6-0.5-2.3-0.7c-1.1-0.3-2.1-0.7-3.2-0.9
	c-0.4-0.1-1.1,0.2-1.2,0.5c-0.2,0.7,0.6,0.3,0.8,0.5c1.8,1.4,4.2,1.4,6,2.6c0.9,0.6,1.5,1.5,2.4,2.5c-2.6,0.2-5,0.8-7.3-0.3
	c-2.6-1.2-5.3-2.2-7.9-3.2c-3.6-1.4-7.3-1.8-11.1-1.4c-1,0.1-2,0.4-3,0.6c-0.1-0.2-0.1-0.3-0.2-0.5c1.3-0.5,2.5-1.1,3.8-1.4
	c1.7-0.4,3.4-0.7,5.1-1c0.2,0,0.5-0.1,0.6-0.3c0.1-0.2,0-0.5-0.2-0.6c-0.6-0.7-1.3-1.4-1.8-2.1c-2-2.9-5-4.4-8.1-5.7
	c-1.7-0.7-2-1.3-0.8-2.8c1.1-1.4,2.4-2.7,3.2-4.2c1-1.7,1.1-3.7,1.1-5.7c0-0.7-1.9-1.7-2.5-1.4c-0.2,0.1-0.4,0.8-0.3,0.8
	c1.1,0.8,0.5,1.8,0.2,3c-10-2.5-18.1-7.2-21.8-17.5c1.2,0.9,2.2,1.8,3.3,2.7c0.1-0.1,0.2-0.2,0.4-0.3c-0.1-0.3-0.1-0.7-0.3-0.9
	c-1.3-1.6-2.5-3.2-3.9-4.7c-1-1.1-2.3-2.1-3.4-3.2c-1.4-1.4-2.3-2.9-2.1-5c0.2-1.6,0.7-2.4,2.1-2.9c1.6-0.5,1.7-0.9,0.6-2.2
	c-0.4-0.5-0.6-1.2-0.7-1.9c-0.3-1.1-0.5-2.2-0.8-3.3c0.1-0.1,0.2-0.1,0.3-0.2c1.8,1.4,3.6,2.7,5.6,4.2c-0.6,0.5-0.9,0.9-1.3,1.2
	c-1.4,1.3-2.8,2.6-3,4.6c-0.2,2.1,1.8,4.8,3.9,5.5c0.9,0.3,1.9,0.6,2.8,0.8c0.3,0.1,0.7-0.1,1-0.2c-0.2-0.3-0.2-0.8-0.5-0.9
	c-1-0.5-2-0.8-3-1.2c-0.4-0.2-0.8-0.3-1.2-0.6c-1.3-0.9-1.5-2.3-0.6-3.6c0.6-0.7,1.1-1.5,1.8-2.1c1.3-1.1,2.6-0.3,3.8,0.2
	c2.8,1.2,5.5,2.6,8.3,3.9c0.8,0.4,1.6,0.7,2.5,0.8c0.4,0.1,0.8-0.4,1.2-0.6c-0.3-0.3-0.6-0.6-1-0.8c-1.6-0.7-3.2-1.3-5-2
	c2.6-2.3,5.2-3.9,8.1-5.1c1.6-0.7,3.1-1.5,4.7-2c5.1-1.4,10.2-3,15.6-2.6c3.1,0.2,6.2,0.1,9.2,0.6c3.9,0.6,7.6,2,10.7,4.5
	c1.7,1.3,3.3,2.9,4.9,4.3c0.9,0.8,2,0.9,3.3,1.1c-1.7-1.5-3.1-2.7-4.5-4c1.1-1.7,2.1-3.3,3-4.9c0.7-1.3,1.4-2.6,1.8-4
	c0.3-0.8,0.6-1.1,1.4-1c0.8,0.1,1.9,0.3,2-0.7c0.2-1.1-1-1.1-1.8-1.1c-2.7-0.2-4.4-1.7-5.4-4.2c-1.4-3.3-2.5-6.7-1.3-10.2
	c0.7-2,1.5-3.9,3.2-5.3c0.9-0.8,1.8-1.7,2.6-2.6c0.5-0.6,0.7-1.2-0.1-2c-0.7-0.7-1.2-1.7-1.8-2.5c-0.1,0.1-0.3,0.1-0.4,0.2
	c0.8,0.1,1.6,0.1,2.4,0.3c0.7,0.1,1.3-0.6,1.2-1.4c0-0.4,0-0.8-0.2-1.2c-0.5-1.1,0-1.7,1-2.3c0.6,0.7,1.1,1.4,1.7,1.9
	c0.3,0.3,0.9,0.6,1.2,0.5c0.4-0.2,0.7-0.8,0.7-1.2C317.8,55,318.1,54.2,318.8,53.7z M282.6,99.6c0.1-0.1,0.2-0.2,0.3-0.3
	c-0.5-0.4-1-1.1-1.5-1.1c-2-0.3-4.1-0.6-6.1-0.6c-2.5,0-4.5,1.5-6.1,3.1c-0.8,0.8-1.5,1.2-2.6,0.7c-0.9-0.4-1.4,0-2,0.6
	c-1,1.1-1.8,2.3-1.6,3.9c-0.9,0.2-1.7,0.4-2.4,0.7c0.3,0.5,0.8,0.9,0.8,1.3c0.2,4.7,2.9,7.9,6.6,10.2c2.8,1.8,5.8,3.2,8.7,4.6
	c2.1,1,4.2,2.2,6.7,2.4c2.4,0.2,4.8,0.6,7.2,0.8c1.1,0.1,2.3,0.1,3.4-0.1c2.2-0.4,4.3-1,6.4-1.5c0.2,0,0.4-0.1,0.5-0.2
	c1.2-1.4,2.8-2.6,3.5-4.1c0.8-1.9,1.3-4.2,1.2-6.2c-0.1-1.6,0.4-3.5-0.9-4.9c0,0,0-0.1,0-0.1c0.5-1.5-0.7-1.6-1.6-1.9
	c-0.9-0.3-1.1,0-1.1,0.7c0.5,0.6,1.1,1.2,1.1,1.9c0.2,2.3,0.3,4.7,0.2,7c0,0.9-0.6,1.9-1,2.7c-0.8,1.6-2.7,1.9-4,2.8
	c-2.8,1.8-5.8,2.8-9.1,2.4c-0.6-0.1-1.2-0.2-1.8-0.2c-3.1-0.4-6.2-0.7-9.1-2.1c-2.5-1.2-5.1-2.3-7.5-3.6c-3.3-1.8-6.1-4.2-6.9-8.2
	c-0.6-2.9-0.7-2.8,2.2-2.7c0.4,0,0.9-0.2,1.4-0.3c-0.1-0.4-0.2-0.9-0.4-1.3c-0.8-1.4-0.8-1.6,0.5-2.8c0.6,0.4,1.2,0.9,1.7,1.4
	c0.7,0.8,1.5,0.8,2.6,0.4c-0.1-0.5-0.2-1-0.3-1.5c-0.1-0.3-0.2-0.8,0-1c0.7-1.4,1.8-2.4,3.3-2.8C277.2,98.8,279.9,99,282.6,99.6z
	 M327.7,72c-0.1-0.2-0.2-0.4-0.3-0.5c-0.3-1.6,0.5-4.5,2.1-5.3c0.6-0.3,1.3-0.8,1.4-1.3c0.2-0.8,0.6-1.3,1.4-1.2
	c0.7,0.1,1.6,0.3,2,0.8c0.7,0.9,1.2,2,1.6,3.1c0.5,1.2,0.7,2.5,1.2,3.7c0.1,0.3,0.7,0.5,1.1,0.7c0.1-0.4,0.4-0.8,0.4-1.3
	c0-2.1-0.7-4-1.7-5.8c-1.5-2.8-4.1-3.7-6.9-2.2c-0.6,0.3-1,1.1-1.6,1.6c-2.9,2.3-3.1,5.3-2.1,8.6c-0.7,0.6-1.5,1.2-2.2,1.8
	c0.4,0.3,0.7,0.8,1.2,1c0.4,0.2,1.1,0.1,1.3-0.1c0.9-1.7,2.7-2.4,4.3-3c1.4-0.6,3.1-0.4,4.6-0.4c0.5,0,1,0.4,1.1-0.3
	c0.1-0.4-0.3-0.9-0.6-1.2c-0.3-0.3-0.7-0.3-1.1-0.5c0.7-1.9,0.4-3.7-0.7-4.4c-1.7-1.1-2.8,0.4-3.8,1.2c-1.3,1.1-0.2,2.4,0,3.6
	C329.6,71.1,328.7,71.5,327.7,72z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M316.3,141.2c-3.4,1-6.9,1.9-10.5,2.9c2.4,1.1,4.6,2,6.5,2.9c-2.1,0.9-5.4,0.5-7.8-1.2
	c-1.7-1.2-3.4-2.3-5.1-3.3c-2.6-1.5-5.3-1.7-8-0.6c-1,0.4-2,0.7-3.2,1c0.4-0.6,0.7-1.2,1.2-1.6c0.7-0.6,1.1-1.2,0-1.8
	c1.9-1.6,4.1-0.8,6-1.1c0.1-0.1,0.1-0.2,0.2-0.3c-0.3-0.3-0.6-0.7-1-0.8c-2.3-0.7-4.4-0.4-6.6,0.6c-1.9,0.8-3.8,1.5-5.8,2.2
	c-1.8,0.6-3.1-0.3-4.5-1.5c1.2-1,2.2-2,3.4-2.9c1.5,1.3,2.7-0.6,4.2-0.3c-1.5,0.8-2.9,1.5-4.3,2.2c1,1.2,1.8,0.9,2.8,0.3
	c2.7-1.5,5.6-2.5,8.7-2.9c1.7-0.2,3.2,0.7,4.5,1.7c2.4,1.8,5,2.7,8,2.9c2.9,0.2,5.7,0.3,8.6,0.6c1,0.1,2,0.4,3,0.7
	C316.3,140.9,316.3,141.1,316.3,141.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M326,241.2c3.6,4.2,8.5,7.1,11,6.3c-0.3-0.2-0.5-0.4-0.8-0.5c-2.6-1.2-5-2.7-7.1-4.7c-1.3-1.2-2-2.8-2.3-4.5
	c-0.5-2.6-0.8-5.3-1.3-7.9c-0.1-0.7,0-1.1,0.6-1.4c3.2-1.6,5.5-4.1,7.8-6.6c1.3-1.4,2.7-2.6,3-4.6c-0.2,3.5,0.2,6.6,3.9,8
	c1.7,0.7,3.6,0.8,5.1-0.5c0-0.2,0-0.3,0.1-0.5c-0.5-0.1-0.9-0.3-1.4-0.3c-1,0-2.1,0.1-3.1,0c-1.4-0.1-2.3-1-2.5-2.3
	c-0.3-2.9-0.4-6.1,1.3-8.3c2.2-2.7,4.1-5.9,7.4-7.6c2.1-1.1,4.2-2.1,6.6-2.4c1.1-0.2,1.7-0.9,2.3-1.8c0.8-1.2,1.6-2.3,2.6-3.7
	c0.4,0.8,0.7,1.5,1.1,2c0.4,0.6,1,1.1,1.5,1.6c0.5,0.4,1,0.3,1.3-0.4c0.5-1.1,1.8-1.2,2.8-0.5c1.1,0.8,2.1,1.8,3.6,1.9
	c0.7,0,1-0.1,1.1-0.8c0.2-2.1,1.2-3.9,2.4-5.6c0.6-0.9,1.4-1.7,2.2-2.4c0.6-0.5,1.4-0.7,2.2-0.8c2-0.1,2.1-0.4,2.8-2.4
	c0.8-2.1,1.7-4.1,3.7-5.4c0.6-0.4,1.1-0.7,1.9-1.2c0.1,0.6,0.1,0.9,0.2,1.2c0.3,2.3,0.2,4.6,0.9,6.8c0.4,1.5,1.8,2.6,2.7,4
	c0.3,0.4,0.8,0.9,0.8,1.4c0.1,4.4-0.1,8.7-1.7,12.9c-0.2,0.6-0.5,1.1-0.7,1.6c-1.2-0.7-2.3-1.5-3.5-2.2c-0.1,0.1-0.2,0.2-0.3,0.4
	c0.4,0.4,0.9,0.8,1.2,1.3c1,1.3,1.7,2.8,2.2,4.5c0.6,2,0.7,3.9,0.5,5.9c-0.3,2.8-1,5.6-1.6,8.4c-1,4.3-3.1,8.1-5.2,11.8
	c-1.2,2.2-3.2,3.7-5.3,5.2c-2.6,1.8-5.4,3.1-8.5,4c-2.6,0.7-5.2,1.5-7.8,0.7c-3.2-1-4.7-3.4-3.4-6.7c0.6-1.6,1.4-3.2,2.2-4.7
	c1.1-2,0.6-4.2,0.7-6.3c0-0.2-0.3-0.4-0.6-0.5c-0.5-0.3-1-0.5-1.5-0.8c0.2,0.9,0.4,1.8,0.5,2.7c0.2,2.2-1.1,3.9-1.9,5.8
	c-0.6,1.5-1.3,3-1.4,4.6c-0.3,3.3,1.1,5.4,4.2,6.7c0.2,0.1,0.5,0.2,1,0.5c-2,0.4-3.7,1-5.4,1.2c-3,0.4-6,0.9-9,0.7
	c-4-0.2-8.1-0.4-11.7-2.4c-2.2-1.2-4.2-2.8-6-4.5C325.2,246.2,324.9,243.9,326,241.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M303,217.7c1.9,0,3.6,0.4,5.1,1.5c1.3,0.9,2.6,0.6,3.9,0c1-0.5,2.1-0.9,3.2-1.2c2.8-0.7,4.2,0.4,5.6,3
	c0.2,0.4,0.4,0.8,0.6,1.1c0.1,0,0.3,0,0.4-0.1c-0.1-0.6,0-1.3-0.2-1.8c-1.3-2.8-4.3-5-7.9-4c-1.4,0.4-2.8,1-4.2,1.6
	c-0.8,0.3-1.4,0.2-2.1-0.3c-0.8-0.5-1.7-1-2.7-1.3c-0.7-0.2-1.5,0-2.2,0c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4-1.8-0.9-3.6-1-5.5
	c-0.1-1.5,0.3-3,0.4-4.5c0-0.1,0-0.2,0-0.2c1.7-2.7,2.7-5.9,5.7-7.7c1.7-1,3.3-2.3,4.7-3.7c1.5-1.6,3.4-2.4,5.4-2.9
	c0.4-0.1,0.7-0.3,1.3-0.5c-1.4-1.3-3.8-0.7-4.3-2.9c2.1-0.6,4.2-1.2,6.3,0.4c0.1,0.1,0.2,0.3,0.4,0.3c0.5,0.1,1,0.1,1.4,0.2
	c-0.1-0.4-0.1-0.9-0.3-1.3c-0.6-1-1.2-2-1.9-3c2.6-0.4,6.4,0.7,7.5,2.7c0.8,1.3,1.6,2.7,2.5,3.9c0.3,0.4,1,0.5,1.5,0.8
	c0.7,0.4,1.4,0.8,2,1.4c2.4,2.3,2.8,5.3,2.3,8.4c-0.3,1.9-1,3.7-1.4,5.6c-2.4-1.2-4.3-3.8-7.6-2.9c0.7,1,1.3,1.6,2.6,1.4
	c1.7-0.2,4.6,2.1,5.2,4.3c1.1,3.5,0.7,6.8-2,9.5c-2.2,2.2-4.5,4.3-7,6.1c-1.4,1-3.1,1.9-5.2,2c0.1-0.5,0.4-0.9,0.3-1.2
	c-0.1-0.3-0.6-0.8-0.8-0.7c-0.5,0.1-1,0.4-1.3,0.8c-1.4,1.6-2.8,3.2-4.3,5c0.4-1.7,1.1-3,1-4.3c-0.1-1.4-0.9-2.7-1.4-4.2
	c0.4,0,1,0.1,1.5,0.1c0.3,0,0.6-0.2,1-0.3c-0.2-0.3-0.3-0.6-0.6-0.8c-1.9-1.2-3-1.1-4.9,0.4c-1.2,0.9-1.4,1.4-0.6,2
	c0.5,0.3,1.1,0.4,1.7,0.6c0.2-0.6,0.4-1,0.5-1.3c2.1,1,2,5.7-0.4,6.9c-1,0.5-2,1.1-3,1.7c-3.1,2.2-6.5,3.3-10.2,3.5
	c-2.4,0.2-4.9,0.1-7.4,0c-0.7,0-1.7,0-2.2-0.4c-0.5-0.4-0.5-1.3-0.6-1.9c1.6-1.3,3.4-1,5.2-0.8c2.5,0.3,4.3-0.9,5.9-2.8
	c2-2.5,2.1-5.2,1.5-8.1c-0.3-1.5-1-3.2,0.8-4.3C302.9,218,302.9,217.8,303,217.7z M313.7,203.7c-1.3,0.3-2.6,0.4-3.8,1
	c-1.1,0.6-2.4,1.4-3,2.4c-1.1,1.9-0.6,4,0.7,5.6c1,1.2,2.5,2.2,4,2.9c1.2,0.6,2.3-0.4,3.3-1c2.8-1.7,5.7-3.3,9.2-2.6
	c0.2,0,0.5-0.3,0.8-0.5c-0.2-0.3-0.4-0.7-0.6-0.7c-1.2-0.1-1.5-0.8-2-1.8C320.5,205.6,317.7,203.8,313.7,203.7z M317.9,200.6
	c-0.2-1-0.8-1.4-1.8-1.3c-0.7,0.1-1.4,0-2.1,0c-4.3,0.2-7.9,1.6-10.4,5.3c1,0.9,1.3,1,2.3,0c1.8-1.7,3.8-3.1,6.3-3.4
	C314.1,200.9,316,200.8,317.9,200.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M376.4,265c0.7-1,1.4-2.1,2.1-3.1c0.4-0.5,0.6-1.4,1-1.5c0.9-0.3,2-0.2,3-0.1c1,0.1,1.2,1.8,0.4,2.5
	c-0.7,0.6-1.4,1.1-2.2,1.6c-2.5,1.3-4.4,3.1-5.8,5.7c-1.5,2.9-3.3,5.6-5,8.3c-0.2,0.4-0.6,0.7-1,1.1c-1.1-1.3-1.5-2.4-0.5-3.9
	c0.4-0.7,0.6-1.5,0.8-2.3c0.1-0.2-0.3-0.8-0.5-0.8c-0.7-0.2-1.5-0.3-2.2-0.2c-2,0.3-3.8,1.2-5.5,2.3c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.7,0.5-1.4,1.4-2.3,0.7c-0.5-0.4,0.4-2.6,1.2-3.1c1.5-0.9,1.9-2.7,0.8-4c-0.2-0.3-0.7-0.6-1.1-0.6c-1.3-0.1-2.6,0-4.1,0
	c2.1-1.7,4.1-3.4,6.8-3.8c2.4-0.4,4.9-0.5,7.3-0.9c1.2-0.2,2.6-0.4,3.6-0.9c1.5-0.8,3-1.7,2.9-3.9c0-0.9,0.6-1.8,1-2.8
	c0.4-0.9,0.7-1.9,1.1-2.7c0.4-0.8,0.1-1.1-0.6-1.3c-1-0.2-1.9-0.4-2.9-0.6c1.3-0.8,2.6-1.7,3.9-2.4c0.2-0.1,0.7,0.2,1,0.4
	c0.6,0.4,1.2,0.9,1.9,1.2c1.3,0.6,1.7,1.3,1,2.6c-0.9,1.6-1.9,3.1-3,4.6c-1.4,2-2.9,4-3.4,6.5c-0.1,0.4,0,0.8,0,1.2
	C376,264.9,376.2,265,376.4,265z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M337.6,261.9c-1.4-1-2.8-1-4.1-0.6c-1.3,0.3-2.4,1.1-3.7,1.7c-0.5,0.2-1.1,0.2-1.7,0.3c0-0.6-0.2-1.3,0.1-1.8
	c0.4-0.8,1.2-1.4,1.8-2.1c0.3-0.3,0.5-0.6,0.8-0.9c-0.3-0.2-0.7-0.5-1-0.6c-1.1-0.2-2.1-0.2-3.6-0.3c0.6-0.6,0.9-1.2,1.4-1.4
	c1.3-0.5,2.6-0.8,3.9-1.1c0.2-0.1,0.6,0.1,0.7-0.1c1.6-1.4,3.1,0,4.6,0.2c2.2,0.3,4.4,0.7,6.6,1c1.7,0.2,3.4,0.3,5.1,0.4
	c-0.8,0.6-1.7,1.3-2.6,2c-2.2,1.9-4.4,3.8-6.7,5.7c-0.7,0.5-1.5,0.9-2.3,1.2c-0.6,0.3-0.9,0-0.9-0.7
	C336,263.5,336.5,262.6,337.6,261.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M337.4,207.7c0.1,1.8,0.2,3.6,0.3,5.4c-0.2,0-0.3,0-0.5,0c-0.2-1.8-0.3-3.6-0.5-5.4
	C336.9,207.7,337.1,207.7,337.4,207.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M308.3,208.9c0.6-2.2,3.8-3.8,7.4-3.4c2.2,0.2,5.1,2.8,5.1,4.7c-1.2,0.3-2.4,0.7-3.6,0.9
	c-0.2,0.1-0.6-0.1-0.7-0.2c-1.1-1.5-2.9-1.6-4.5-1.9C310.7,208.7,309.5,208.9,308.3,208.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M311.1,209.8c0.2,0.1,0.7,0.4,0.8,0.7c0.2,0.7-0.4,0.8-1,0.8c-0.1,0-0.2,0-0.3,0.1c-0.6,0.4-1.2,0.1-1.2-0.5
	c0-0.3,0.5-0.7,0.9-1C310.3,209.8,310.6,209.8,311.1,209.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M328.3,384.4c-0.3,0.6,0,1.7-1.3,1.7c-0.3,0-0.6,0.9-1,1.3c-1.3,1.2-2.8,2.4-4.1,3.6c-0.8,0.8-1.6,1.7-2.4,2.5
	c-1.3,1.4-3,1.9-4.8,1.5c-1.5-0.3-2.9-1-4.4-1.5c-0.9-0.4-1.3-1-1.4-2c-0.2-2.4-2.3-5-4.5-5.7c0.3,0.5,0.4,0.9,0.7,1.2
	c1.4,1.2,1.7,2.9,2.2,4.5c0.8,2.6,2.9,4.1,5.2,5.3c0.3,0.2,0.7,0.2,1.1,0.6c-1.7,0.5-3.4,1-5,1.6c-2.2,0.8-4.2,0.8-6.4,0
	c-0.5-0.2-1.2,0-1.7,0.3c-2.3,1.4-4.6,1.4-7,0.5c-0.2-0.1-0.5-0.2-0.5-0.3c-0.3-1.3-1.6-1.1-2.4-1.6c-0.2-0.1-0.7,0-0.9,0.2
	c-2.2,2.3-4.8,2-7.5,1.1c-1.8-0.6-3-1.9-4.2-3.2c-1-1.1-2.5-1.4-3.7-0.7c-1.9,1-4,0.8-5.5-0.7c-1.4-1.4-2.8-2-4.7-1.5
	c-0.3,0.1-0.9-0.2-1.2-0.4c-1.4-1.3-3-2.5-4.1-4.1c-2.2-3.2-3.4-6.8-2.4-10.7c0.9-3.5,1.8-7,4-10.1c0.8-1.1,1.1-2.6,1.4-3.9
	c0.7-2.7,2.2-4.8,4.3-6.5c1.3-1.1,2.5-2.2,3.6-3.2c0.7,0.8,1.2,1.4,1.9,2c0.9,0.7,1,1.5,0.5,2.5c-1.2,2.7-2.9,4.9-5,6.9
	c-3.3,3.1-5.1,7-4.8,11.6c0.2,2.3,0.8,4.6,1.2,6.9c0.2,0,0.3,0,0.5,0c0.1-0.4,0.2-0.8,0.2-1.2c0.1-2.5,0-5.1,0.4-7.6
	c0.4-2.2,1.5-4.2,3.2-5.9c2.6-2.6,5.1-5.3,6.2-8.9c0.3-0.8,0.4-1.7,0.5-2.4c3,0,5.9-0.1,8.8,0c1.9,0.1,3.1,1.6,4.1,3
	c1.5,2.3,3.5,3.7,6.2,4.1c0.9,0.1,1.9,0.1,2.8-0.1c0.5-0.1,1-0.7,1.4-1.1c0.9,2.1,1.9,4.4,2.8,6.7c0.9,2.3,1.1,4.8,2.3,7.3
	c1-2.3,0.3-4.2-0.2-6c-0.5-1.7-1.2-3.3-1.9-5c-1.1-2.4-1.7-4.8-1.3-7.4c0.1-0.8,0.6-1.6,0.9-2.5c0,0,0.2-0.1,0.4,0
	c3.5,0.2,5.5-1.8,7.2-4.5c1.2-1.9,1.1-3.9,1-6c0-1.7-0.2-3.4,0.5-5.2c0.8,0.9,1.4,0.9,2.3,0.3c1.7-1.1,3.4-0.5,5,0.5
	c1.1,0.6,2.2,1.2,3.4,1.8c-0.7-2.9-5.7-5.4-9.5-5c0-1.9,0.1-3.9-1-5.7c-0.4-0.7-0.5-1.6-0.5-2.4c0-0.4,0.7-0.7,1.1-1.1
	c-0.6-1.1,0.7-1.3,1.3-1.8c1.2-1,2.3-2.2,4.1-2.3c2.8-0.1,3.5,1.3,3.8,3.5c0.2,1.7,0.3,3.4-0.8,5.1c-0.6,0.9,0.1,1.9,0.8,3
	c0.6-1.3,1.1-2.3,1.6-3.4c2.4-0.1,3.1,1.6,3.5,3.4c0.4,2.1,0.3,4.3-0.2,6.4c-0.1,0.3-0.1,0.6,0,0.8c0.7,2-0.3,3.4-1.4,4.9
	c-0.7,1-0.9,2.5-1.3,3.7c-0.8-0.7-1.9-1.5-2.8-2.5c-0.6-0.7-1.2-0.7-1.7-0.1c-2.3,2.7-4.6,5.4-5.6,8.9c-0.3,1-0.1,1.9,0.8,2.8
	c0.3-0.3,0.5-0.6,0.6-0.9c1-2.3,2.1-4.6,3.9-6.5c0.9-0.9,1.8-1.3,3-1.2c0.3,0,0.6,0.2,0.8,0.4c1.4,1.8,2.4,3.7,2.4,6.1
	c0,1.4,0.1,2.8,0.2,4.2c0,0.5,0.3,1,0.5,1.6c2-2,1.4-7.7-1.1-11.3c0.9-1.7,1.9-3.5,3-5.4c1.9,1.4,3.7,2.6,4.6,4.5
	c1.6,3.3,2.7,6.8,2.8,10.6c0.1,3.7-0.6,7.2-1.4,10.8c-0.5,2-1.4,3.6-2.9,5.1c-1.3,1.2-2,2.9-3,4.4c-0.1,0.2-0.1,0.7,0.1,0.7
	c0.2,0.1,0.6,0.1,0.8,0C327.1,385.2,327.7,384.8,328.3,384.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M273.9,346.5c0.3,0.7,0.6,1.2,0.8,1.8c0.1,0.2-0.1,0.5-0.2,0.7c-0.1,0.1-0.5,0-0.6-0.1
	c-1.1-0.9-2.1-2.1-1.9-3.5c0.3-1.8,0.5-3.9,2.5-4.7c3.7,2.2,7.4,2.6,11.2,1.2c1.9-0.7,3.6-2,5.2-3.5c1.6-1.6,3-1,3.4,1.3
	c0.1,0.8,0.2,1.8,0,2.6c-0.9,3.2,0.3,5.4,3.1,6.9c1.4,0.8,1.7,1.5,1.6,3.1c-0.5-0.1-0.9-0.2-1.5-0.3c-1,1.5-2,2.9-3,4.3
	c-1.9-1.1-3.9-2.5-6.1-3.5c-2.2-1.1-4.6-1.1-7.3-0.9c1,0.8,1.8,1.3,3,1.2c2.9-0.3,5.2,1.3,7.5,2.9c1,0.7,2,1.6,2.7,2.6
	c0.6,0.9,0.9,2.1,1.2,3.2c0.3,1.5-0.6,2.4-2.1,2.2c-1.3-0.2-2.3-0.8-3.1-1.9c-0.9-1.4-2-2.7-3.3-3.7c-1-0.8-2.4-1.2-3.7-1.4
	c-1.8-0.2-3.7-0.1-5.5-0.2c-1.3-0.1-2.6-0.3-3.9-0.6c-0.9-0.2-1.6-1.1-1.3-2c0.3-0.9,1.1-1.6,2.3-1.5c1.5,0,2.5-0.8,2.6-2.1
	c0.1-1.7-1.1-3.8-2.4-4C274.4,346.4,274.2,346.5,273.9,346.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M290.2,324.6c-0.2-1-0.8-1.5-1.9-1.7c-0.9-0.1-1.8-0.2-2.7-0.4c1.8-1.8,5.5-3.7,9.6-1.8
	c-0.2-1.2-0.3-2.3-0.5-3.3c1.1,0.6,1.5,1.6,1.9,2.7c0.2,0.5,0.7,0.9,1,1.3c0.3-0.3,0.6-0.6,1-0.9c0.9-0.6,1.9-1.2,3-1.6
	c-0.4,0.7-1,1.4-1.1,2.1c-0.1,0.6-0.1,1.5,0.3,2.1c1,1.4,2.1,2.7,3.2,4c0.7,0.8,1.5,1.4,2.3,2.2c-1.9-0.1-3.6-1.6-5.7-0.7
	c1.2,1.2,2.5,1.4,3.9,1.7c0.8,0.1,1.5,1,2.1,1.6c1.6,1.7,2.2,3.5,1.9,6.2c-0.5-0.9-0.8-1.6-1.3-2.1c-0.6-0.8-1.3-1.7-2.2-2.2
	c-1.7-1-3.9-0.6-5.3,0.9c-1.4,1.6-2.5,3.3-3.8,4.9c0-0.1-0.2-0.3-0.3-0.6c-1.4-3.1-5.6-3.8-7.9-1.3c-3.1,3.4-7.1,4.1-11.4,3.1
	c-0.2,0-0.3-0.1-0.4-0.2c-1.3-0.8-2.6-1.6-4-2.5c-0.8,1.6-1.6,3.1-2.4,4.7c-0.9-1.5-0.8-3-0.2-4.5c0.7-1.8,1.7-3.4,3.3-4.6
	c0.6-0.5,0.9-1.3,1.5-1.8c1-0.9,2.1-1.8,3.1-2.7c0.2-0.2,0.3-0.5,0.6-0.8c0.5-0.5,0.9-1.1,1.4-1.4c1.1-0.7,2.3-1.2,3.4-1.8
	c1.8-1,3.7-1.4,5.7-1.2c0.6,0.1,1.1,0.5,1.6,0.8C290,324.8,290.1,324.7,290.2,324.6z M275.5,335.3c0.1,3.1,2.7,6,5.9,4.4
	c1.5-0.8,3.2-1.5,4.5-2.6c1-0.8,1.9-2,2.5-3.3c0.5-1.1,0.9-2.4,0.9-3.6c0.1-1.8-0.7-2.6-2.5-3.1c-2.6-0.8-6.1,0.3-7.9,2.4
	c-0.6,0.7-1.1,1.4-1.8,2C275.9,332.6,275.6,334,275.5,335.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M297.8,354.6c0.3,0,0.6-0.1,0.8-0.1c1.2,0.2,2.6-0.6,2.4-1.7c-0.2-1.1-0.6-2.3-1.3-3.2c-0.7-0.9-1.8-1.5-2.8-2
	c-1-0.6-1.4-1.2-0.9-2.3c0.2-0.5,0.3-1.1,0.4-1.7c3.6,4.8,8.1,3.7,10.4,1.5c0.7,3.4-0.4,6.2-2.7,8.7
	C299,355.8,298.7,355.9,297.8,354.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M254.9,383.9c-2.5-2.3-4.2-4.8-4-8c0.1-1.4,0.6-2.9,1.3-4.1c1.4-2.7,3.6-4.8,5.9-6.6c0.3-0.3,0.7-0.6,1.1-0.8
	C255.7,370.3,253.4,376.6,254.9,383.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M306.6,339.6c0.1,2.4-0.9,4.4-4,5.4c-2.2,0.7-2.8,0.7-3.6-0.6c-0.5-0.8-1-1.6-0.6-2.8c0.4-1.2,0-2.6,1-3.5
	c1.3-1.2,2.2-2.9,4.1-3.4c1-0.3,1.8-0.1,2.2,0.8c0.5,1.3,0.9,2.7,1.3,4C306.7,339.5,306.6,339.6,306.6,339.6z M304.1,340.1
	c0-0.2,0-0.4,0-0.6c-0.1-1.4-0.6-2.1-1.8-2.2c-1.1-0.1-1.9,0.4-2.3,1.7c-0.2,0.5-0.3,1-0.4,1.5c-0.3,1.4,0.7,2.6,2.1,2.6
	C303.3,343,304.1,342,304.1,340.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M301.7,322.9c0.9-0.1,1.9-0.3,3.1-0.5c-0.5,0.9-0.8,1.5-1.3,2.2c-0.7-0.5-1.3-1-1.9-1.4
	C301.7,323.2,301.7,323,301.7,322.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M283.7,335.9c-1.7,0.9-3.3,1.9-5,2.7c-0.2,0.1-0.9-0.3-1-0.5c-0.2-0.6-0.3-1.4-0.2-2c1.1-4.1,3.6-6.8,7.8-8
	c0.9-0.2,1.4,0.1,1.6,0.9c0.4,1.5-1.3,5.7-2.5,6.6C284.2,335.8,283.9,335.8,283.7,335.9c0.5-1.1,1.3-2.2,0.7-3.6
	c-0.3-0.6-0.7-1.2-1.3-1.5c-0.4-0.2-1.2-0.1-1.6,0.2c-0.6,0.4-0.9,1.1-1.3,1.8c-0.7,1.1-0.6,1.7,0.4,2.6
	C281.6,336.4,282.7,335.9,283.7,335.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M301.3,339.2c0.3-0.3,0.5-0.6,0.8-0.9c0.3,0.3,0.6,0.6,1,0.9c-0.1,0.1-0.1,0.2-0.2,0.3c-0.4,0-0.8,0-1.2,0.1
	C301.5,339.4,301.4,339.3,301.3,339.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M281.8,331.8c1.4-0.2,1.5,0,1.3,1.6C282.2,333.3,281.6,332.9,281.8,331.8z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M196.8,332.2c-0.5-1.3-1.3-0.7-2.1-0.8c-1.1-0.1-1.3,0.4-1.4,1.2c-0.3,1.7-1.8,2.4-3.1,3.1
	c-1.7,1-3.5,1.8-5.5,0.6c-0.5-0.3-1.3,0.1-2,0.1c-1,0.1-0.7,0.9-0.8,1.5c-0.3,1.9-0.6,2.2-2.6,2.1c-1.6-0.1-2.5,0.6-3.5,1.8
	c-1.4,1.8-4.5,1.9-6.1,0.3c-1.2-1.2-2.6-1.2-3.8,0.1c-0.9,0.9-2.8,1.4-4.1,0.8c-1.3-0.6-2.5-1.3-3.8-1.9c-0.6-0.3-1.2-0.5-1.8-0.4
	c-2.9,0.3-5.6-0.5-8.1-2c-0.1,0-0.2-0.1-0.2-0.1c-1.2-1.9-3.2-2.3-5.1-2.8c-2.2-0.6-3.7-2.1-5.4-3.5c-0.3-0.3-0.2-0.9-0.4-1.4
	c-0.3-0.5-0.6-1.2-1-1.3c-2.6-0.7-4.6-2.3-6.5-4.2c-2.3-2.4-3.9-5.1-5.2-8.1c-0.9-2-0.3-4,0.6-5.8c0.4-0.7,1.5-0.5,1.8,0.3
	c0.4,1,0.6,2,1,2.9c0.4,0.9,0.8,1.8,1.3,2.6c0.2,0.4,0.8,0.6,1.2,0.8c0.1-0.1,0.2-0.2,0.3-0.3c-0.4-1.2-0.7-2.5-1.2-3.7
	c-1.1-2.6-0.7-5.2-0.3-7.8c0-0.2,0.5-0.5,0.7-0.4c2.1,0.4,4.3,0.6,5.9,2.2c0.5,0.4,0.9,1,1.2,1.6c1.6,3,3.2,6,4.7,9.1
	c0.2,0.3,0.5,0.6,0.9,1.1c0.9-2.4-1.5-3.7-1.3-5.6c1.2-0.8,2.5-0.2,3.7,0.1c1.1,0.3,2.2,0.8,3.2,1.2c2.6,1,4.3,3,5.9,5.1
	c0.3,0.5,0.7,0.9,1.2,1.6c0.6-1.5-0.1-2.5-0.8-3.3c-1.9-2.3-4.1-4.2-7.1-5.3c-1.9-0.7-3.8-1.2-5.7-1.4c-0.6-0.1-1.3-0.4-1.9-0.6
	c0-0.2,0-0.4,0-0.5c2.4-0.6,4.7-1.4,7.1-1.6c2.4-0.3,4.8-0.1,7.3-0.2c-1.1-1.3-2.8-1.4-4.2-1.8c-1.5-0.4-3.1-0.1-5-0.1
	c1.6-1.5,3-2.8,4.5-4.2c0.2-0.2,0.6-0.4,0.7-0.6c1.7-3.2,4.9-4.6,8-6c2.2-0.9,4.5-1.9,6.8-2.5c2.4-0.6,4.9-1.4,7.5-0.7
	c1.7,0.5,3.5,0.8,5.2,1.3c1.4,0.5,2.7,1.1,4,1.8c2.9,1.5,6.1,2.4,9.3,2.9c0.2,0,0.4-0.2,0.9-0.4c-0.9-0.5-1.5-1-2.3-1.4
	c-0.7-0.3-1.6-0.5-2.3-0.7c0.6-2,1.1-3.8,1.7-5.7c0.6-2-0.3-3.8-0.9-5.6c0.6,0.1,1.2,0.3,1.8,0.3c0.3,0,0.6-0.1,0.8-0.4
	c-1.8-0.7-2.9-1.6-3.9-2.7c-0.8-0.9-1.8-1.6-2.6-2.4c-2-2.1-2.2-4.6-1.4-7.2c0.7-2.1,1.8-4.2,2.9-6.1c0.4-0.8,1.4-1.3,2.2-1.8
	c0.8-0.6,1.7-1.2,2.8-2c-1.3-1.1-2.5-2.1-3.8-3.2c-0.1,0.1,0.1-0.2,0.2-0.2c1.5,0.1,3,0,4.3,1c2.1,1.6,1.7,1.3,3.2-0.8
	c0.6-0.9,1.2-1.7,2.1-2.6c-0.2,1.8,0.6,3,2.2,3.6c0.8-2.1,1.3-4.3,3.9-4.9c-0.1,0.9-0.3,1.8-0.4,2.6c0,1.6,1.2,2.4,2.6,1.7
	c0.5-0.3,1-0.6,1.7-0.9c-0.1,0.5-0.1,0.9-0.2,1.2c-0.3,1.7,0.5,2.8,2.2,3.2c2.4,0.6,4.7,1.6,6.1,3.7c0.8,1.2,1.2,2.6,1.8,4
	c0.1,0.1,0,0.4,0.1,0.5c1.7,2.6-0.4,4.1-1.8,5.6c-1.9,2-4.2,3.3-7.1,2.7c-0.2,0-0.4,0-0.7,0.1c0.7,1.7,2.1,1.4,3.3,1.4
	c4,0.1,6.4-2.5,8.6-5.4c0.4,0.6,0.9,1.1,1.3,1.7c2.2,3.8,7.3,3.4,10.3,1.2c1.3-1,2.8-1.7,4.4-2.7c0.2,0.8,0.4,1.2,0.4,1.6
	c-0.1,2.1-0.9,3.9-2.3,5.6c-2.1,2.6-4.9,3.7-8.1,4.4c-3.7,0.7-7.3,0.1-10.9-0.2c-2.2-0.2-3.8-1.5-5.7-2.2c0-0.1,0-0.2,0-0.3
	c0.4-0.1,0.8-0.1,1.1-0.2c0.2-0.1,0.6-0.2,0.7-0.3c0.1-0.3,0-0.7-0.2-0.8c-0.3-0.3-0.8-0.5-1.1-0.5c-0.8,0.2-1.6,0.6-2.5,0.8
	c-1.2,0.3-0.9,1.2-1,2c-0.1,0.3-0.2,0.7-0.3,1.1c1.2,1,1.9,0.2,2.7-0.7c2.7,1.6,5.4,3.3,8.8,3.3c-3,0.9-6.1,1.5-9,0
	c-1.8-1-3.4-2.3-3.8-4.6c-0.1-0.7-0.8-1-1.9-0.9c0,3.1,2.7,4.5,4.5,6.5c-1.7,0.9-3.2-0.1-5.1-0.4c2.9,2.9,4.1,6.1,3.8,9.7
	c-0.3,3.5-2,6.4-4.2,9.1c-2-2.8-4.5-4.7-7.9-5.5c0.3,0.3,0.7,0.7,1,0.9c2.5,1.3,4.2,3.2,5.7,5.6c2.5,3.8,3.7,7.8,3.5,12.3
	c-0.1,2.4-1.7,4-2.9,5.7c-0.8,1.1-1.7,2.2-2.6,3.2C197.5,331.6,197.2,331.8,196.8,332.2z M173.2,329.7c-0.9-0.1-1.6-0.2-2.3-0.1
	c-2.5,0.1-5,0.1-7.3-1.2c-2.2-1.2-4.7-2.2-4.8-5.3c0-1.3,0-2.6,0.3-3.9c0.1-0.5,0.8-0.8,1.3-1.2c1.5-1.1,3.2-1.8,5.1-1.9
	c2-0.1,3.5,0.9,4.7,2.4c0.2,0.2,0.7,0.1,1.1,0.1c0-0.4,0.1-0.9-0.2-1.1c-0.8-0.7-1.7-1.4-2.7-1.9c-0.8-0.5-1-0.8-0.1-1.3
	c0.7-0.4,1.4-0.7,2.1-1.2c1.5-1,2.9-0.7,4.3,0.2c1.2,0.8,2.6,1.3,4,1.8c1.5,0.6,2.9,0.1,4.2-0.7c1.6-1.1,3.2-2.2,4.7-3.3
	c-0.7-1.1-2.6-1.4-4-0.5c-1,0.6-2,1.4-3,1.9c-2.5,1.4-3.2,1.3-4.9,0c-0.3-0.2-0.6-0.5-1-0.6c-1.6-0.4-3.3-1.2-4.8-1
	c-1.5,0.2-3,1.4-4.4,2.3c-0.4,0.2-0.5,0.9-0.8,1.6c-1.9-0.1-3.9,0.2-5.4,2.1c-0.4,0.5-1,0.8-1.5,1.2c-0.3,0.1-0.3,0.5-0.4,0.9
	c-0.5,2.9-0.7,5.7,2,7.9c0.2,0.2,0.1,0.7,0.2,1c0.1,0.4,0.3,0.8,0.6,1.2c0.2,0.4,0.7,0.7,0.8,1.1c0.7,2,1.9,3.5,3.4,4.9
	c2,1.9,4.5,3,7.1,3.8c2.6,0.7,5.2,0.8,7.8,0.5c0.2,0,0.6-0.3,0.6-0.5c0-0.3-0.1-0.7-0.3-0.8c-0.3-0.2-0.7-0.2-1-0.2
	c-2.2-0.2-4.5-0.4-6.7-0.7c-2.6-0.4-4.9-1.5-6.8-3.3c-1.3-1.2-2.2-2.8-2.6-4.8c2.6,1.4,5.2,2.2,8.1,1.9
	C171.5,331,172.4,331.1,173.2,329.7z M209.7,271.5c-0.1-2.7-1.4-4.6-3.9-5.7c-1.8-0.8-3.7-1.5-5.6,0.1c-2.1,1.7-3.2,3.9-2.7,6.5
	c0.5,2.7,2,4.8,4.8,5.5c0.7,0.2,1.4,0.1,2.1,0.2c1.4,0.4,2.7-0.2,3.3-1.5c0.2-0.4,0.3-0.9,0.6-1.1
	C209.6,274.3,209.7,272.9,209.7,271.5z M193.4,265.8c0.8,0.9,1.3,1.5,1.8,2c0.6,0.5,1,0.5,1.1-0.4c0.1-0.4,0.2-1,0.5-1.2
	c1.5-1,2.7-2.7,4.8-2.6c0.3,0,0.5-0.4,0.8-0.7c-0.2-0.2-0.5-0.6-0.8-0.6c-0.8-0.1-1.7-0.3-2.3,0
	C197.4,263.3,195.5,264.5,193.4,265.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M207.7,273.8c-0.4,0.6-0.7,1.2-1.2,1.6c-0.4,0.4-1.1,0.9-1.7,0.9c-1.5-0.1-3.1-0.1-4.4-0.7
	c-1.6-0.7-2-3.7-1.1-5.4c0.4-0.8,0.9-1.6,1.3-2.4c0.6-1.2,1.9-1.2,2.9-1.6c1.1-0.4,3.6,0.8,4.1,2.2c0.4,0.9,0.4,1.9,0.7,2.9
	c-0.1,0-0.3,0.1-0.4,0.1c-0.2-0.4-0.4-0.7-0.6-1.1c-0.7-1.8-3.4-2.8-4.9-1.7c-0.5,0.3-0.8,1.5-0.6,1.9c0.6,1.2,1.4,2.4,2.4,3.3
	c1,0.9,2.2,0.5,3.2-0.4C207.5,273.6,207.6,273.7,207.7,273.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M204.1,269.6c0.6,0.1,1.2,0,1.7,0.2c0.2,0.1,0.2,1.1,0,1.2c-1,0.6-1.3-0.5-1.8-1
	C204,269.9,204.1,269.8,204.1,269.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M177.7,236.9c1-1.4,1.8-2.7,2.6-3.9c2.3-3.1,0.7-8.1-3.4-9c-2.3-0.5-4.5-1.2-6.8-1.8c-1.8-0.5-3.5,0.1-5.2,1.3
	c0.6,0.4,1.1,0.8,1.7,1.1c0.1,0.1,0.5-0.1,0.7-0.2c1-1.1,2.7-1.3,4.2-0.5c-0.8,0.5-1.6,1-2.5,1.7c1.3,0.6,2.1,1,3.2,0.3
	c1.3-0.9,2.8-1.2,4.3-0.8c2.1,0.6,3.7,2.3,2.4,5.5c-0.3,0.7-0.7,1.3-1.1,2c-2.4,3.7-5.6,6.4-9.6,8.2c-2.1,1-4.4,0.9-6.8,0.5
	c-5-0.9-9.5-3-13.8-5.3c-1.6-0.9-2.9-2.4-4.3-3.8c-0.7-0.7-0.4-2.6,0.3-3.7c1.1-1.6,2.3-3.1,3.2-4.8c0.7-1.4,0.2-2.9-0.8-4
	c-0.5-0.5-1.3-0.7-2.2-0.8c1.6,1.8,0.5,3.3-0.4,4.7c-0.7,1.1-1.6,2.2-2.1,3.4c-1.2,2.5-0.8,4.5,1.2,6.5c2.5,2.7,5.6,4.5,9,6
	c3.1,1.3,6.2,2.6,9.6,3.1c0.9,0.1,1.9,0.3,2.9,0.7c-1.3,0.4-2.6,0.8-4,1.1c-0.4,0.1-0.9-0.3-1.4-0.4c-0.7-0.2-1.5-0.2-2.2-0.4
	c-0.8-0.2-1.3,0.1-1.9,0.6c-1.4,1.3-4.5,1.2-6.1,0.2c-0.6-0.4-1.5-0.3-2.3-0.5c-0.9-0.2-1.2,0.4-1.8,0.8c-0.9,0.6-2.2,0.8-3.4,0.9
	c-1.1,0.1-2.3-0.2-3.4-0.4c-0.5-0.1-1.1-0.1-1-1c0-0.1-0.4-0.5-0.7-0.5c-1.3-0.1-2.6,0-3.8-0.2c-1.9-0.3-3.8-0.7-5.5-1.3
	c-2.3-0.8-4-2.6-5.2-4.7c-1.8-3.1-3.4-6.4-4.9-9.6c-1.5-3.2-0.7-6.3,1.4-9c0.9-1.2,1.7-2.4,2.7-3.5c0.6-0.7,1.2-1.5,2-2
	c1.5-0.8,1.9-2.3,2.5-3.7c0.8-2.1,1.4-4.3,2.1-6.4c0.3-0.9,0.5-1.7-0.2-2.2c2.2-0.7,4.6-1.3,6.9-2.1c1.5-0.5,3-1.3,4.5-1.9
	c0.6-0.3,1.4-0.4,2.2-0.8c-0.6-0.3-1.1-0.6-1.7-0.8c-1.9-0.5-3.6,0.4-5.2,1.3c-2.4,1.2-4.9,1.9-7.3,2.3c2.8-2.8,5.9-5.2,10-5.8
	c0,0,0.1,0,0.1,0.1c0.4,0.5,0.6,1.5,1.6,1.1c0.5-0.2,0.3-1.6-0.3-2.3c-1.9-1.8-4.2-2.4-6.7-2.3c-0.1,0-0.2,0.1-0.3,0.3
	c0.7,0.6,1.4,1.2,2.1,1.7c-1,0.4-2.3,0.7-3.3,1.4c-1.6,0.9-3,2.1-4.5,3.1c-1,0.7-2.1,1.4-3.2,1.8c-0.8,0.3-1.9,0.4-2.7,0.1
	c-3.8-1.2-6.7-3.6-8.7-7c-0.7-1.2-1.8-1.9-3.2-1.7c1,1.4,2,2.8,3.1,4.1c1,1.2,2.1,2.3,3.1,3.4c-2.4,1.4-4.5,0.5-6.7-1.4
	c-1.4-1.3-2.2-3-2.1-5c0.1-2.2,0.3-4.5,0.5-6.7c0.1-1,1.5-1.5,2.8-1c2.2,0.8,4.2,1.9,5.9,3.8c1.6,1.7,3.4,3.3,5.8,3.6
	c0.5,0.1,1.1-0.3,1.6-0.5c0.1,0-0.1-0.7-0.3-1c-0.1-0.1-0.5-0.3-0.6-0.2c-0.9,0.6-1.6,0-2.3-0.4c-1.1-0.7-2.2-1.5-3.2-2.4
	c3.1,0.3,5.9-0.2,8.5-2c0.6-0.4,1.1-0.7,0.9-1.8c-0.1-0.7,1-1.6,1.6-2.3c0.3-0.3,0.7-0.5,1.3-1c0,0.9,0,1.6,0,2.4
	c-0.1,3.3,1.3,5.7,4.3,7.1c0.9,0.4,2.2,0.2,3.3,0.3c1.4,0.1,2.8,0.2,4.1,0.5c1.2,0.3,1.8,2.4,1.3,3.6c-0.1,0.3-0.3,0.6-0.6,1.2
	c0.5,0.1,1.1,0.5,1.4,0.3c0.5-0.3,1.1-0.9,1.2-1.5c0.4-2.1-1.2-4.5-3.3-5.2c-2.2-0.7-4.5-1.1-6.8-1c-1.3,0.1-2.4-1.1-2.6-2.5
	c-0.1-0.5-0.1-1.1-0.1-1.6c0-0.6,0.1-1.1,0.1-1.4c0.9,1.1,1.8,2.4,2.9,3.5c1.7,1.7,5.2,2.2,6.9,0.9c3-2.2,3.6-5,2.4-7.7
	c-1.8-3.8-4.9-4.6-8.4-4c-2.1,0.4-3.6,1.8-3.9,5.5c-0.2-1.3-0.5-2.4-1.9-2.4c-1.1,0-2.1,0.1-2.9,1c-0.9,1-1.9,1.8-3,2.8
	c-0.1-0.2-0.2-0.5-0.2-0.7c1-5.2,3.6-9,8.6-11c0.9-0.4,1.8-0.8,2.8-1.1c3.1-0.8,5.6,0.5,8.1,2.2c0.1,0.1,0.3,0.1,0.6,0.3
	c-0.1-1.3-0.1-2.4-0.2-3.8c0.7,0.8,1.1,1.3,1.7,1.8c0.6,0.5,1,0.8,1.7-0.2c0.8-1,2.1-1.7,3.4-2.4c-0.2,0.4-0.3,0.9-0.5,1.3
	c-0.6,1.2-0.2,3,0.9,3.7c0.2,0.1,0.5,0.1,0.7,0c0.6-0.1,1.2-0.3,1.8-0.5c0.6-0.2,1.1-0.3,1.9-0.6c-0.4,1.1-0.8,2-1.2,3.1
	c-0.5-0.3-1-0.5-1.4-0.7c-0.2-0.1-0.5,0-0.7,0.2c-0.1,0.1,0,0.5,0.1,0.6c2.1,2.1,3,4.7,3.5,7.5c0.2,1.2,0,2.5,0,3.8
	c0,3.4-0.7,6.6-2.2,9.6c-1.1,2.1-2.7,4.2-4.8,5.6c-0.4,0.3-0.8,0.5-1.2,0.8c-1.3,1.1-2.7,1.5-4.3,0.8c-0.7,2-1.3,3.9-2,5.7
	c-0.8,2,2.1,7.6,4.8,7.5c-0.3-0.6-0.6-1.1-0.9-1.5c-0.5-0.6-0.5-1,0.3-1.3c3.1-1,6.1-2.3,9.2-3c4-1,7.8-0.1,11.4,1.8
	c2.2,1.2,4.4,2.5,6.8,3.5c1.8,0.8,3.8,1.2,5.7,1.6c1.1,0.2,2.2,0,3.3,0c0-0.2,0.1-0.3,0.1-0.5c-0.6-0.2-1.2-0.5-2-0.8
	c2.3-1.7,4.5-3.4,7.4-4c0.3-0.1,0.5-0.1,0.8-0.2c1.9-0.5,3.2,0.4,3.4,2.4c0.2,2.1-0.6,4-1.5,5.8c-0.8,1.7-1,3.3-0.2,5
	c0.3,0.6,0.4,1.4,0.2,2c-0.8,2.6-1.6,5.2-2.8,7.6C182.9,235,180.5,236.1,177.7,236.9z M141.4,175.3c-0.4-4.2-7.1-6.7-11.1-4.3
	c0.8,0.5,1.1,1.9,2.4,1.2c1.7-0.9,3.3-1,4.9,0.1c0.8,0.6,1.5,1.2,2.3,1.9C140.4,174.5,140.9,174.9,141.4,175.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M132.7,174.7c1.1,0.1,2.1,0.3,3.1,0.4c2.2,0.2,3.9,2,3.6,3.9c-0.1,0.4,0,0.9,0,1.3c-0.1,2.2-2.5,4.3-4.7,4.2
	c-1.4-0.1-3.1-1.4-3.4-2.8c-0.3-1.5-0.8-3.1-0.1-4.6C131.6,176.3,132.2,175.5,132.7,174.7z M132.8,177.5c-1.1,1.1-1.1,2.4-0.6,3.8
	c0.4,0.9,1.6,1.5,3,1.5c1.1,0,1.7-0.6,2.1-1.5c0.2-0.5-0.1-1.1-0.1-1.6c0-1.7-1.3-2.8-3-2.4C133.8,177.4,133.4,177.5,132.8,177.5z"
        />
      </SvgIcon>
    )
  }
};