import uuid from 'uuid-random';
export default class Drawable {
  startx: number;
  starty: number;
  color: string;
  weight: number;
  id: string;
  drawableType: string = '';
  fontSize: number;
  text: string;
  fill: string = 'transparent';
  align: string = 'left';
  opacity: number;
  rotation: number;

  constructor(startx: number, starty: number, color: string, weight:number, opacity: number) {
    this.startx = startx;
    this.starty = starty;
    this.color = color;
    this.weight = weight;
    this.opacity = opacity ? 0.5 : 1;
    this.id = uuid();
  }
}