import TextBox from "./TextBox";

export default class TextBoxPoint extends TextBox {
  defaultSize = {
    w: 20,
    h: 20,
    fontSize: 8,
    align: 'center'
  }

  constructor(startx, starty, color, weight, opacity) {
    super(startx, starty, color, weight, opacity);
    const { w, h, fontSize, align} = this.defaultSize;
    this.initProperties(w, h, fontSize, startx, starty, color, weight, align);
  }
}