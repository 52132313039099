import React, { Component } from "react";
import { SvgIcon } from "@material-ui/core";

export default class Undo extends Component<any, any> {

  render() {
    const st0 = "#064B5E";
    const { disabled } = this.props;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-333.5 539.1 82.6 33.9"}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <g id="XMLID_386_">
          <path id="XMLID_214_" fill={st0} d="M-251,572.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3-0.6-0.6-1.1-1-1.6c-12.4-19.6-32-31.4-49.8-30.4
		c-4.9,0.3-10.2,1.3-14.2,4c-0.8-1.5-1.5-3-2.2-4.5c-0.2-0.5-0.8-0.7-1.1-0.4c-2.6,2.4-4.7,5.6-7,8.5c-2.3,3-4.6,6-6.8,9
		c-0.4,0.6,0,1.5,0.7,1.6c8,0.8,15.9,1.4,23.8,1.5c0.4,0,0.6-0.6,0.4-1.1c-0.9-2.2-1.9-4.3-2.9-6.4c4.8-1.7,9.4-3.4,14.7-3.6
		c5.2-0.3,10.6,0.6,15.9,2.5c5.1,1.8,10.2,4.6,14.9,8.2c2.5,1.8,4.8,3.9,7.1,6.1c2.4,2.3,5.2,4.3,7.2,7.1c0,0.1,0.1,0.1,0.2,0.2
		C-251.1,573.2-250.7,572.8-251,572.4z" />
        </g>
      </SvgIcon>
    )
  }
};
