import React, { Component } from "react";
import { SvgIcon } from "@material-ui/core";

export default class Circle extends Component<any, any> {

  render() {
    const st0 = "#084B5E";
    const { disabled } = this.props;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-352.5 571.4 48.8 48.8"}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <g id="XMLID_300_">
          <path id="XMLID_1_" fill={st0} d="M-328.1,620.2c-13.5,0-24.4-11-24.4-24.4c0-13.5,11-24.4,24.4-24.4c13.5,0,24.4,11,24.4,24.4
		C-303.8,609.2-314.6,620.2-328.1,620.2z M-328.1,574.6c-11.7,0-21.2,9.5-21.2,21.1c0,11.6,9.5,21.2,21.2,21.2
		c11.7,0,21.2-9.5,21.2-21.2S-316.4,574.6-328.1,574.6z" />
        </g>
      </SvgIcon>
    )
  }
};
