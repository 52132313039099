import { Component } from "react";
import React from 'react';
import Draggable from 'react-draggable';
import { Box, Tooltip } from "@material-ui/core";
import { ACTION_TOOLTIPS } from "../util/APP_CONSTANS";
import IconButtonCorrect from "../components/IconButtonCorrect";
import { connect } from 'react-redux';
import {
  drawingCircle, drawingCurvePolygon,
  drawingEllipse,
  drawingPoint,
  drawingPolygon,
  drawingRect,
  showDivider,
  showFill,
  showProtractor,
  showRuler,
  showRulerTriangle,
  showText
} from '../actions';
import Move from "../icons/Move";
import { withStyles } from "@material-ui/styles";
import { zIndexTooltip } from "../App";
import uuid from "uuid-random";
import Circle from "../icons/Circle";
import Rectangle from "../icons/Rectangle";
import Ellipse from "../icons/Ellipse";

const styles: any = theme => ({
  box: {
    backgroundImage: "url(images/tools/Background.svg)",
    backgroundSize: "cover",
    position: "fixed",
    padding: "10px"
  }
});


class AdvancedToolbar extends Component<any, any> {
  state = {
    disabled: false,
    zIndex: zIndexTooltip,
    isPolygonCurveLine: false,
    paddingTop: 0,
    paddingLeft: 0
  }
  appBar;

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (prevProps.drawables.pageSelectedShape !== this.props.drawables.pageSelectedShape) {
      const fill = this.props.drawables.pageSelectedShape[this.props.drawables.pageSelectedShape.name].fill;
      const showedFill = this.props.drawables.showedFill;
      if (this.props.drawables.pageSelectedShape[this.props.drawables.pageSelectedShape.name].shapeType !== 'text') {
        if ((fill === 'transparent' && showedFill) || (fill !== 'transparent' && !showedFill)) {
          this.props.dispatch(showFill(!this.props.drawables.showedFill));
        }
      }
      const isPolygonCurveLine = this.props.drawables.pageSelectedShape[this.props.drawables.pageSelectedShape.name].isPolygonCurveLine;
      if (isPolygonCurveLine !== this.state.isPolygonCurveLine) {
        this.setState({
          isPolygonCurveLine
        })
      }
    }
    if (!this.appBar) {
      this.appBar = document.getElementById(this.props.appBarId);
      if (this.appBar) {
        const paddingTop = this.appBar.offsetHeight + 10;
        const paddingLeft = (document.body.offsetWidth - this.appBar.offsetWidth) / 2;
        this.setState({
          paddingTop,
          paddingLeft
        })
      }
    }
  }

  render() {
    const { classes, appBarId, drawables, dispatch } = this.props;
    const {
      showDrawables,
      showedDivider,
      showedRulerTriangle,
      showedRuler,
      showedProtractor,
      dragImgEnabled,
      pageSelectedShape,
      showedFill,
      drawableType,
      enabledSVGClick
    } = drawables;
    const { paddingTop, paddingLeft } = this.state;
    // const appBar = document.getElementById(appBarId);
    // const paddingTop = appBar.offsetHeight + 10;
    // const paddingLeft = (document.body.offsetWidth - appBar.offsetWidth) / 2;
    const y = 0;
    const x = -54;
    const curvePolygonDisabled = !(pageSelectedShape && pageSelectedShape[pageSelectedShape.name].drawableType === 'PolygonDrawable');
    return (
      <Draggable
        disabled={this.state.disabled}
        bounds={{
          top: -paddingTop,
          right: document.body.offsetWidth,
          left: -paddingLeft,
          bottom: document.body.offsetHeight
        }}
        defaultPosition={{
          x: x,
          y: y
        }}
        onDrag={(e, data) => {
          let zIndex = zIndexTooltip;
          if (data.x > -50) {
            zIndex += 2;
          }
          this.setState({
            zIndex
          })
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={34}
          height={460}
          className={classes.box}
          style={{ zIndex: this.state.zIndex }}
        >
          <Tooltip title={ACTION_TOOLTIPS.DRAG_IMG_ENABLE} placement={"right"}>
            <IconButtonCorrect
              tbarType="vertical"
              disabled={false}
            >
              <Move
                disabled={false}
              />
            </IconButtonCorrect>
          </Tooltip>
          <Tooltip title={ACTION_TOOLTIPS.SHOW_CIRCLE}>
            <IconButtonCorrect
              tbarType={"vertical"}
              toggle={drawableType === 'CircleDrawable' && showDrawables && !enabledSVGClick}
              disabled={!showDrawables}
              onClick={() => {
                dispatch(drawingCircle());
              }}
            >
              <Circle
                disabled={!showDrawables}
              />
            </IconButtonCorrect>
          </Tooltip>
          <Tooltip title={ACTION_TOOLTIPS.SHOW_RECT}>
            <IconButtonCorrect
              tbarType={"vertical"}
              toggle={drawableType === 'RectangleDrawable' && showDrawables && !enabledSVGClick}
              disabled={!showDrawables}
              onClick={() => {
                dispatch(drawingRect());
              }}
            >
              <Rectangle
                disabled={!showDrawables}
              />
            </IconButtonCorrect>
          </Tooltip>
          <Tooltip title={ACTION_TOOLTIPS.SHOW_ELLIPSE}>
            <IconButtonCorrect
              tbarType={"vertical"}
              toggle={drawableType === 'EllipseDrawable' && showDrawables && !enabledSVGClick}
              disabled={!showDrawables}
              onClick={() => {
                dispatch(drawingEllipse());
              }}
            >
              <Ellipse
                disabled={!showDrawables}
              />
            </IconButtonCorrect>
          </Tooltip>
          <Tooltip title={ACTION_TOOLTIPS.DIVIDER} placement={"right"}>
            <IconButtonCorrect
              tbarType="vertical"
              toggle={showedDivider}
              disabled={enabledSVGClick}
              onClick={(e) => {
                this.props.dispatch(showDivider(!showedDivider, e.pageY));
              }}
            >
              <img src={"images/tools/DividerIcon.svg"}
                   className={"MuiSvgIcon-root"}
                   style={{ marginLeft: 1, opacity: enabledSVGClick ? 0.5 : 1 }}
              />
            </IconButtonCorrect>
          </Tooltip>
          <Tooltip title={ACTION_TOOLTIPS.RULER} placement={"right"}>
            <IconButtonCorrect
              tbarType="vertical"
              toggle={showedRuler}
              disabled={enabledSVGClick}
              onClick={(e) => {
                this.props.dispatch(showRuler(!showedRuler, e.pageY));
              }}
            >
              <img
                src={"images/tools/Ruler.svg"}
                className={"MuiSvgIcon-root"}
                style={{ marginLeft: 1, opacity: enabledSVGClick ? 0.5 : 1 }}
              />
            </IconButtonCorrect>
          </Tooltip>
          <Tooltip title={ACTION_TOOLTIPS.RULER_TRIANGLE} placement={"right"}>
            <IconButtonCorrect
              tbarType="vertical"
              toggle={showedRulerTriangle}
              disabled={enabledSVGClick}
              onClick={(e) => {
                this.props.dispatch(showRulerTriangle(!showedRulerTriangle, e.pageY));
              }}
            >
              <img src={"images/tools/RulerTriangle.svg"}
                   className={"MuiSvgIcon-root"}
                   style={{ marginLeft: 1, opacity: enabledSVGClick ? 0.5 : 1 }}
              />
            </IconButtonCorrect>
          </Tooltip>
          <Tooltip title={ACTION_TOOLTIPS.PROTRACTOR} placement={"right"}>
            <IconButtonCorrect
              tbarType="vertical"
              toggle={showedProtractor}
              disabled={enabledSVGClick}
              onClick={(e) => {
                this.props.dispatch(showProtractor(!showedProtractor, e.pageY));
              }}
            >
              <img
                src={"images/tools/Protractor.svg"}
                style={{ marginLeft: 1, opacity: enabledSVGClick ? 0.5 : 1 }}
                className={"MuiSvgIcon-root"}
              />
            </IconButtonCorrect>
          </Tooltip>
          <Tooltip title={ACTION_TOOLTIPS.TEXT} placement={"right"}>
            <IconButtonCorrect
              tbarType="vertical"
              disabled={!dragImgEnabled || enabledSVGClick}
              onClick={(e) => {
                this.props.dispatch(showText(uuid(), e.pageY));
              }}
            >
              <img
                src={"images/tools/Text.svg"}
                className={"MuiSvgIcon-root"}
                style={{ opacity: !dragImgEnabled || enabledSVGClick ? 0.5 : 1 }}
              />
            </IconButtonCorrect>
          </Tooltip>
          <Tooltip title={ACTION_TOOLTIPS.POINT} placement={"right"}>
            <IconButtonCorrect
              tbarType="vertical"
              disabled={!dragImgEnabled || enabledSVGClick}
              onClick={(e) => {
                this.props.dispatch(drawingPoint(uuid(), e.pageY));
              }}
            >
              <img
                src={"images/tools/Point.svg"}
                className={"MuiSvgIcon-root"}
                style={{
                  opacity: !dragImgEnabled || enabledSVGClick ? 0.5 : 1,
                  marginTop: 5,
                  height: '0.7em',
                  width: '0.7em'
                }}
              />
            </IconButtonCorrect>
          </Tooltip>
          <Tooltip title={ACTION_TOOLTIPS.FILL} placement={"right"}>
            <IconButtonCorrect
              tbarType="vertical"
              toggle={showedFill}
              disabled={!(pageSelectedShape && dragImgEnabled)}
              onClick={(e) => {
                this.props.dispatch(showFill(!showedFill));
              }}
            >
              <img
                src={"images/tools/Circle.svg"}
                className={"MuiSvgIcon-root"}
                style={{ opacity: !(pageSelectedShape && dragImgEnabled) ? 0.5 : 1 }}
              />
            </IconButtonCorrect>
          </Tooltip>
          <Tooltip title={ACTION_TOOLTIPS.POLYGON} placement={"right"}>
            <IconButtonCorrect
              tbarType="vertical"
              toggle={drawableType === 'PolygonDrawable'}
              disabled={dragImgEnabled || enabledSVGClick}
              onClick={(e) => {
                this.props.dispatch(drawingPolygon(uuid()));
              }}
            >
              <img
                src={"images/tools/Polygon.svg"}
                className={"MuiSvgIcon-root"}
                style={{ opacity: dragImgEnabled || enabledSVGClick ? 0.5 : 1 }}
              />
            </IconButtonCorrect>
          </Tooltip>
          <Tooltip title={ACTION_TOOLTIPS.CURVE_POLYGON} placement={"right"}>
            <IconButtonCorrect
              tbarType="vertical"
              toggle={this.state.isPolygonCurveLine}
              disabled={curvePolygonDisabled}
              onClick={(e) => {
                this.setState({
                  isPolygonCurveLine: !this.state.isPolygonCurveLine
                })
                this.props.dispatch(drawingCurvePolygon(uuid()));
              }}
            >
              <img
                src={"images/icons/vectorCurveLine.png"}
                className={"MuiSvgIcon-root"}
                style={{ opacity: curvePolygonDisabled ? 0.5 : 1 }}
              />
            </IconButtonCorrect>
          </Tooltip>
        </Box>
      </Draggable>
    )
  }

}

const getVisibleDrawables = (drawables) => {
  return drawables;
};

const mapStateToProps = state => ({
  drawables: getVisibleDrawables(state.drawables)
});

export default connect(
  mapStateToProps
)(withStyles(styles)(AdvancedToolbar));