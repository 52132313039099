import React, { Component } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import { appColors, changePage, setAppBarSize } from "../App";
import { ACTION_TOOLTIPS, DEFAULT_FOLDER_PATH } from "../util/APP_CONSTANS";
import { Menu as MenuIcon } from "@material-ui/icons";
import { ThemeProvider, withStyles, withTheme } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import uuid from "uuid-random";
import { setPageToTaskContentChange } from "../actions";
import { Link } from "react-router-dom";

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "#084b5e",
        color: "white",
        opacity: "0.8 !important",
        marginTop: '20px'
      },
      rounded: {
        borderRadius: "10px"
      }
    }
  }
});

const styles: any = theme => ({
  ivanaMenuItem: {
    '&:hover': {
      backgroundColor: '#05323f'
    },
    borderBottom: "solid 1px white"
  }
});

class HelpVideoContentMenu extends Component<any, any> {

  state = {
    open: false
  }

  buttonRef: any;

  contentList = [{
    text: 'Притисак или клик на леви тастер миша',
    url: 'video/Matematika 1 l Uputstvo 1 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Слушање текста задатка',
    url: 'video/Matematika 1 l Uputstvo 2 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Бирање странице и лекције',
    url: 'video/Matematika 1 l Uputstvo 3 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Цртање криве, праве и линије са стрелицом',
    url: 'video/Matematika 1 l Uputstvo 4 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Гумица за брисање',
    url: 'video/Matematika 1 l Uputstvo 5 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Корак назад-Корак напред',
    url: 'video/Matematika 1 l Uputstvo 6 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Чување промена ',
    url: 'video/Matematika 1 l Uputstvo 7 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Померање слика',
    url: 'video/Matematika 1 l Uputstvo 8 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Додатни алат за цртање',
    url: 'video/Matematika 1 l Uputstvo 9 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Бојење слика и фигура',
    url: 'video/Matematika 1 l Uputstvo 10 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Бојење провидном бојом',
    url: 'video/Matematika 1 l Uputstvo 11 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Куцање текста',
    url: 'video/Matematika 1 l Uputstvo 12 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Куцање текста било где на страни',
    url: 'video/Matematika 1 l Uputstvo 13 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Цртање круга, квадрата или правоугаоника и елипсе ',
    url: 'video/Matematika 1 l Uputstvo 14 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Бојење круга, квадрата или правоугаоника и елипсе',
    url: 'video/Matematika 1 l Uputstvo 15 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Заокруживање и копирање',
    url: 'video/Matematika 1 l Uputstvo 16 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Цртање тачке било где на страни',
    url: 'video/Matematika 1 l Uputstvo 17 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Цртање многоугла',
    url: 'video/Matematika 1 l Uputstvo 18 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Коришћење лењира и троугла',
    url: 'video/Matematika 1 l Uputstvo 19 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Коришћење угломера',
    url: 'video/Matematika 1 l Uputstvo 20 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Коришћење шестара',
    url: 'video/Matematika 1 l Uputstvo 21 l ATOS l Izdavačka kuća.mp4'
  }, {
    text: 'Решавање задатка на посебној страни',
    url: 'video/Matematika 1 l Uputstvo 22 l ATOS l Izdavačka kuća.mp4'
  }];

  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (this.state.open) {
      setAppBarSize('beforeOpenedDialog', true);
      setTimeout(() => {
        setAppBarSize('openedDialog', true);
      }, 1)
    }
  }

  listItemClick = (e, page) => {
    e.preventDefault();
    e.stopPropagation();
    const { dispatch, setSearchPage, setPage } = this.props;
    this.handleClose(e);
    changePage(page, dispatch, setSearchPage, setPage);
  }

  handleClose = (event) => {
    if (this.buttonRef.current && this.buttonRef.current.contains(event.target)) {
      return;
    }
    this.setState({
      open: false
    });
  };

  handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      this.setState({
        open: false
      })
    }
  }


  getMenuItems = () => {
    const menuItems = this.contentList.map((item: any) => {
      return (<MenuItem
        className={this.props.classes.ivanaMenuItem}
        onClick={(e) => {
          // this.listItemClick(e, item.page);
        }
        }>
        <Link
          to={{
            pathname: `${DEFAULT_FOLDER_PATH}/helpVideo`,
            search: `url=${item.url}`
          }}
          target={"_blank"}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.setState({
              open: false
            }, () => {
              window.open(`${DEFAULT_FOLDER_PATH}/helpVideo?url=${item.url}`, '_blank')
            })
          }}
        >
          <span
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            {item.text}
          </span>
        </Link>
      </MenuItem>)
    })
    return menuItems;
  }

  render() {
    const { open } = this.state;
    let color = appColors.icon.drawing.primary;
    let backgroundColor = 'transparent';
    if (open) {
      color = appColors.icon.drawing.toggle;
      backgroundColor = "white";
    }
    return (
      <>
        <Tooltip title={ACTION_TOOLTIPS.HELP}>
          <IconButton
            ref={this.buttonRef}
            style={{ padding: 5, color: color, backgroundColor: backgroundColor }}
            color="inherit"
            aria-label="save"
            onClick={() => {
              this.setState({
                open: !open
              })
            }}
          >
            <img src={"images/tools/Help.svg"} className={"MuiSvgIcon-root"} />
          </IconButton>
        </Tooltip>
        <ThemeProvider theme={theme}>
          <Menu
            id="help-video-content-menu"
            anchorEl={this.buttonRef.current}
            keepMounted
            open={this.state.open}
            onClose={this.handleClose}
            onKeyDown={this.handleListKeyDown}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {this.getMenuItems()}
          </Menu>
        </ThemeProvider>
      </>
    )
  }
}

export default withStyles(styles)(HelpVideoContentMenu);