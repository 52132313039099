import React, { Component } from "react";
import { SvgIcon } from "@material-ui/core";
import { changeColor } from "../actions";

export default class ColorPalette extends Component<any, any> {
  render() {
    const { w, h, disabled, dispatch } = this.props;
    const st0 = "#FF0000";//1-2
    const st1 = "#084B5E";
    const st2 = "#FFFFFF";//1-1
    const st3 = "#FF00FF";//2-2
    const st4 = "#FFA500";//1-3
    const st5 = "#00BFFF";//2-3
    const st6 = "#FFFF00";//1-4
    const st7 = "#0000FF";//2-4
    const st8 = "#D2691E";//1-5
    const st9 = "#E2E2E2";
    const st10 = "#008000";//2-5
    const st11 = "#A52A2A";//1-6
    const st12 = "#90EE90";//2-6
    const st13 = "#010101";//2-1
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-231.2 553.5 324.2 84.5"}
        style={{ width: w, height: h, opacity: disabled ? 0.5 : 1 }}
      >
        <g id="XMLID_62_">
          <g id="XMLID_440_"
             onClick={()=>{
               dispatch(changeColor(st0));
             }}
          >
            <g id="XMLID_446_">
              <circle id="XMLID_160_" fill={st0} cx="-155" cy="572.5" r="17.2" />
            </g>
            <g id="XMLID_443_">
              <path id="XMLID_236_" fill={st1} d="M-155,591.5c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19S-144.5,591.5-155,591.5z
				 M-155,557.1c-8.5,0-15.4,6.9-15.4,15.4s6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4S-146.5,557.1-155,557.1z" />
            </g>
            <g id="XMLID_441_">
              <g id="XMLID_442_">
                <path id="XMLID_159_" fill={st2} d="M-145.5,574.3c-1,0-1.8-0.8-1.8-1.8c0-4.2-3.4-7.6-7.6-7.6c-1,0-1.8-0.8-1.8-1.8
					s0.8-1.8,1.8-1.8c6.2,0,11.2,5,11.2,11.2C-143.8,573.5-144.6,574.3-145.5,574.3z" />
              </g>
            </g>
          </g>
          <g id="XMLID_433_"
             onClick={()=>{
               dispatch(changeColor(st3));
             }}
          >
            <g id="XMLID_439_">
              <circle id="XMLID_158_" fill={st3} cx="-155" cy="619" r="17.2" />
            </g>
            <g id="XMLID_436_">
              <path id="XMLID_224_" fill={st1} d="M-155,638c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19S-144.5,638-155,638z M-155,603.6
				c-8.5,0-15.4,6.9-15.4,15.4s6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4S-146.5,603.6-155,603.6z" />
            </g>
            <g id="XMLID_434_">
              <g id="XMLID_435_">
                <path id="XMLID_157_" fill={st2} d="M-145.5,620.8c-1,0-1.8-0.8-1.8-1.8c0-4.2-3.4-7.6-7.6-7.6c-1,0-1.8-0.8-1.8-1.8
					s0.8-1.8,1.8-1.8c6.2,0,11.2,5,11.2,11.2C-143.8,620-144.6,620.8-145.5,620.8z" />
              </g>
            </g>
          </g>
          <g id="XMLID_426_"
             onClick={()=>{
               dispatch(changeColor(st4));
             }}
          >
            <g id="XMLID_432_">
              <circle id="XMLID_156_" fill={st4} cx="-97.7" cy="572.5" r="17.2" />
            </g>
            <g id="XMLID_429_">
              <path id="XMLID_112_" fill={st1} d="M-97.7,591.5c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19S-87.3,591.5-97.7,591.5z
				 M-97.7,557.1c-8.5,0-15.4,6.9-15.4,15.4s6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4S-89.2,557.1-97.7,557.1z" />
            </g>
            <g id="XMLID_427_">
              <g id="XMLID_428_">
                <path id="XMLID_155_" fill={st2} d="M-88.3,574.3c-1,0-1.8-0.8-1.8-1.8c0-4.2-3.4-7.6-7.6-7.6c-1,0-1.8-0.8-1.8-1.8
					s0.8-1.8,1.8-1.8c6.2,0,11.2,5,11.2,11.2C-86.5,573.5-87.3,574.3-88.3,574.3z" />
              </g>
            </g>
          </g>
          <g id="XMLID_419_"
             onClick={()=>{
               dispatch(changeColor(st5));
             }}
          >
            <g id="XMLID_425_">
              <circle id="XMLID_154_" fill={st5} cx="-97.7" cy="619" r="17.2" />
            </g>
            <g id="XMLID_422_">
              <path id="XMLID_111_" fill={st1} d="M-97.7,638c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19S-87.3,638-97.7,638z
				 M-97.7,603.6c-8.5,0-15.4,6.9-15.4,15.4s6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4S-89.2,603.6-97.7,603.6z" />
            </g>
            <g id="XMLID_420_">
              <g id="XMLID_421_">
                <path id="XMLID_153_" fill={st2} d="M-88.3,620.8c-1,0-1.8-0.8-1.8-1.8c0-4.2-3.4-7.6-7.6-7.6c-1,0-1.8-0.8-1.8-1.8
					s0.8-1.8,1.8-1.8c6.2,0,11.2,5,11.2,11.2C-86.5,620-87.3,620.8-88.3,620.8z" />
              </g>
            </g>
          </g>
          <g id="XMLID_412_"
             onClick={()=>{
               dispatch(changeColor(st6));
             }}
          >
            <g id="XMLID_418_">
              <circle id="XMLID_152_" fill={st6} cx="-40.5" cy="572.5" r="17.2" />
            </g>
            <g id="XMLID_415_">
              <path id="XMLID_110_" fill={st1} d="M-40.5,591.5c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19S-30,591.5-40.5,591.5z
				 M-40.5,557.1c-8.5,0-15.4,6.9-15.4,15.4s6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4S-32,557.1-40.5,557.1z" />
            </g>
            <g id="XMLID_413_">
              <g id="XMLID_414_">
                <path id="XMLID_151_" fill={st2} d="M-31,574.3c-1,0-1.8-0.8-1.8-1.8c0-4.2-3.4-7.6-7.6-7.6c-1,0-1.8-0.8-1.8-1.8
					s0.8-1.8,1.8-1.8c6.2,0,11.2,5,11.2,11.2C-29.3,573.5-30.1,574.3-31,574.3z" />
              </g>
            </g>
          </g>
          <g id="XMLID_405_"
             onClick={()=>{
               dispatch(changeColor(st7));
             }}
          >
            <g id="XMLID_411_">
              <circle id="XMLID_150_" fill={st7} cx="-40.5" cy="619" r="17.2" />
            </g>
            <g id="XMLID_408_">
              <path id="XMLID_109_" fill={st1} d="M-40.5,638c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19S-30,638-40.5,638z M-40.5,603.6
				c-8.5,0-15.4,6.9-15.4,15.4s6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4S-32,603.6-40.5,603.6z" />
            </g>
            <g id="XMLID_406_">
              <g id="XMLID_407_">
                <path id="XMLID_149_" fill={st2} d="M-31,620.8c-1,0-1.8-0.8-1.8-1.8c0-4.2-3.4-7.6-7.6-7.6c-1,0-1.8-0.8-1.8-1.8
					s0.8-1.8,1.8-1.8c6.2,0,11.2,5,11.2,11.2C-29.3,620-30.1,620.8-31,620.8z" />
              </g>
            </g>
          </g>
          <g id="XMLID_107_"
             onClick={()=>{
               dispatch(changeColor(st8));
             }}
          >
            <g id="XMLID_404_">
              <circle id="XMLID_148_" fill={st8} cx="16.8" cy="572.5" r="17.2" />
            </g>
            <g id="XMLID_401_">
              <path id="XMLID_108_" fill={st1} d="M16.8,591.5c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19S27.2,591.5,16.8,591.5z
				 M16.8,557.1c-8.5,0-15.4,6.9-15.4,15.4s6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4S25.3,557.1,16.8,557.1z" />
            </g>
            <g id="XMLID_399_">
              <g id="XMLID_400_">
                <path id="XMLID_147_" fill={st9} d="M26.2,574.3c-1,0-1.8-0.8-1.8-1.8c0-4.2-3.4-7.6-7.6-7.6c-1,0-1.8-0.8-1.8-1.8
					s0.8-1.8,1.8-1.8c6.2,0,11.2,5,11.2,11.2C28,573.5,27.2,574.3,26.2,574.3z" />
              </g>
            </g>
          </g>
          <g id="XMLID_105_"
             onClick={()=>{
               dispatch(changeColor(st10));
             }}
          >
            <g id="XMLID_397_">
              <circle id="XMLID_146_" fill={st10} cx="16.8" cy="619" r="17.2" />
            </g>
            <g id="XMLID_394_">
              <path id="XMLID_106_" fill={st1} d="M16.8,638c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19S27.2,638,16.8,638z M16.8,603.6
				c-8.5,0-15.4,6.9-15.4,15.4s6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4S25.3,603.6,16.8,603.6z" />
            </g>
            <g id="XMLID_392_">
              <g id="XMLID_393_">
                <path id="XMLID_145_" fill={st2} d="M26.2,620.8c-1,0-1.8-0.8-1.8-1.8c0-4.2-3.4-7.6-7.6-7.6c-1,0-1.8-0.8-1.8-1.8
					s0.8-1.8,1.8-1.8c6.2,0,11.2,5,11.2,11.2C28,620,27.2,620.8,26.2,620.8z" />
              </g>
            </g>
          </g>
          <g id="XMLID_95_"
             onClick={()=>{
               dispatch(changeColor(st11));
             }}
          >
            <g id="XMLID_103_">
              <circle id="XMLID_104_"fill={st11} cx="74" cy="572.5" r="17.2" />
            </g>
            <g id="XMLID_99_">
              <path id="XMLID_100_" fill={st1} d="M74,591.5c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19S84.5,591.5,74,591.5z M74,557.1
				c-8.5,0-15.4,6.9-15.4,15.4s6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4S82.5,557.1,74,557.1z" />
            </g>
            <g id="XMLID_96_">
              <g id="XMLID_97_">
                <path id="XMLID_98_" fill={st9} d="M83.5,574.3c-1,0-1.8-0.8-1.8-1.8c0-4.2-3.4-7.6-7.6-7.6c-1,0-1.8-0.8-1.8-1.8
					s0.8-1.8,1.8-1.8c6.2,0,11.2,5,11.2,11.2C85.2,573.5,84.4,574.3,83.5,574.3z" />
              </g>
            </g>
          </g>
          <g id="XMLID_85_"
             onClick={()=>{
               dispatch(changeColor(st12));
             }}
          >
            <g id="XMLID_93_">
              <circle id="XMLID_94_" fill={st12} cx="74" cy="619" r="17.2" />
            </g>
            <g id="XMLID_89_">
              <path id="XMLID_90_" fill={st1} d="M74,638c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19S84.5,638,74,638z M74,603.6
				c-8.5,0-15.4,6.9-15.4,15.4s6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4S82.5,603.6,74,603.6z" />
            </g>
            <g id="XMLID_86_">
              <g id="XMLID_87_">
                <path id="XMLID_88_" fill={st2} d="M83.5,620.8c-1,0-1.8-0.8-1.8-1.8c0-4.2-3.4-7.6-7.6-7.6c-1,0-1.8-0.8-1.8-1.8
					s0.8-1.8,1.8-1.8c6.2,0,11.2,5,11.2,11.2C85.2,620,84.4,620.8,83.5,620.8z" />
              </g>
            </g>
          </g>
          <g id="XMLID_75_"
             onClick={()=>{
               dispatch(changeColor(st2));
             }}
          >
            <g id="XMLID_83_">
              <circle id="XMLID_84_" fill={st2} cx="-212.2" cy="572.5" r="17.2" />
            </g>
            <g id="XMLID_79_">
              <path id="XMLID_80_" fill={st1} d="M-212.2,591.5c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19S-201.8,591.5-212.2,591.5z
				 M-212.2,557.1c-8.5,0-15.4,6.9-15.4,15.4s6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4S-203.7,557.1-212.2,557.1z" />
            </g>
            <g id="XMLID_76_">
              <g id="XMLID_77_">
                <path id="XMLID_78_" fill={st9} d="M-202.8,574.3c-1,0-1.8-0.8-1.8-1.8c0-4.2-3.4-7.6-7.6-7.6c-1,0-1.8-0.8-1.8-1.8
					s0.8-1.8,1.8-1.8c6.2,0,11.2,5,11.2,11.2C-201,573.5-201.8,574.3-202.8,574.3z" />
              </g>
            </g>
          </g>
          <g id="XMLID_61_"
             onClick={()=>{
               dispatch(changeColor(st13));
             }}
          >
            <g id="XMLID_73_">
              <circle id="XMLID_74_" fill={st13} cx="-212.2" cy="619" r="17.2" />
            </g>
            <g id="XMLID_67_">
              <path id="XMLID_68_" fill={st1} d="M-212.2,638c-10.5,0-19-8.5-19-19s8.5-19,19-19s19,8.5,19,19S-201.8,638-212.2,638z
				 M-212.2,603.6c-8.5,0-15.4,6.9-15.4,15.4s6.9,15.4,15.4,15.4s15.4-6.9,15.4-15.4S-203.7,603.6-212.2,603.6z" />
            </g>
            <g id="XMLID_63_">
              <g id="XMLID_64_">
                <path id="XMLID_65_" fill={st2} d="M-202.8,620.8c-1,0-1.8-0.8-1.8-1.8c0-4.2-3.4-7.6-7.6-7.6c-1,0-1.8-0.8-1.8-1.8
					s0.8-1.8,1.8-1.8c6.2,0,11.2,5,11.2,11.2C-201,620-201.8,620.8-202.8,620.8z" />
              </g>
            </g>
          </g>
        </g>
      </SvgIcon>
    )
  }
};