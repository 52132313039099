import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_14_2 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 379.4 537.6"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M149.5,99c0.7-3.8,0.4-6.1-2.4-6.6c1.1,1.9,0.9,4.4-0.5,6.2c-0.2-1.1-0.1-2.6-0.7-3.7c-0.6-1.2-1.9-2-3.7-1.8
	c1.2,0.8,2.4,1.3,2.2,2.9c-0.2,1.2-0.4,2.4-0.6,3.6c-0.1,0-0.3,0-0.4,0c-0.2-0.5-0.4-1.1-0.7-1.6c-0.5-0.9-1-1.9-1.7-2.5
	c-1.5-1.2-3.1-0.5-4.4,0.4c-1,0.7-1.9,1.2-3,1.6c-1.1,0.4-2.1,1-3.2,1.5c-0.7,0.3-1.2,0.3-1.8-0.2c-0.6-0.6-0.8-1.2-0.4-1.8
	c1.1-2.1,0.6-3.8-1-5.3c-1.3-1.2-1.8-2.8-1.8-4.4c0-1.6-0.9-1.9-2.2-2.4c0.2,2.3,0.1,4.6,1.6,6.3c1.6,1.7,2,3.4,0.9,5.4
	c-0.9-0.5-1.9-1-3-1.5c-1-0.4-2.1-0.7-3.2-1c-2.2-0.6-3.9-1.9-5.5-3.4c-0.6-0.5-1.4-0.9-2.1-1.3c-0.2-0.1-0.5-0.1-1.1-0.1
	c3.2,4.2,7.2,6.3,12.2,7c-0.4,1.7-0.6,3.4-1.1,5c-0.6,2-1.4,4-2.2,5.9c-0.8,1.8-2.5,2.9-4.3,3.7c-0.5,0.2-0.9,0.3-1.7,0.6
	c1.1-2.9,2.2-5.5,3.2-8.1c-2.5,1.8-3.9,4.2-5.2,6.8c-1.1,2.3-2.9,4-5.3,4.8c-1.9,0.7-3.5,0-5.1-1.2c3.7-3.6,5.5-8.1,7.2-12.7
	c-0.1-0.1-0.2-0.1-0.3-0.2c-2,2.6-3.9,5.3-5.9,7.9c-1.3,1.7-2.5,3.4-4,4.8c-1,0.9-2.5,1.3-3.8,1.5c-2.3,0.5-4.6,0.7-6.8-0.4
	c-0.6-0.3-1.1-0.6-1.7-1.1c6.8-2.2,11.9-6.4,16-12c-0.1-0.1-0.2-0.3-0.3-0.4c-2.3,1.8-4.7,3.4-6.9,5.3c-3.9,3.5-8.5,5.5-13.5,6.7
	c-2.9,0.7-5.1-2.1-4.8-4.7c0.1-0.4,0.4-0.8,0.5-1.3c1.2-3.5,3.4-6.4,6-8.9c3.6-3.5,8.1-5.9,12.7-7.6c3.9-1.5,7.4-3.3,10.6-5.7
	c1.1-0.8,1.9-1.9,2.8-2.9c0.5-0.5,0.9-1.3,1.5-1.5c1.6-0.5,1.1-1.6,0.6-2.3c-1.6-2.3-3.1-4.8-5.9-5.8c-0.6-0.2-1.1-0.3-1.7-0.4
	c-0.4-0.1-0.8-0.2-1.1-0.3c1-1.1,1.9-2.2,2.7-3.2c-0.1-0.2-0.2-0.3-0.4-0.5c-0.6,0.3-1.3,0.4-1.7,0.8c-2.2,1.8-4.2,3.8-6.4,5.6
	c-0.8,0.7-1.8,1.2-2.8,1.6c-2.4,0.9-3.3,0.1-3.1-2.4c0.1-1,0.7-2.1-0.2-3.1c-0.4-0.4-0.8-0.7-1.5-1.2c-0.2,1.5-0.4,2.5-0.6,3.6
	c-0.1,0.5-0.3,1.2-0.7,1.5c-1.7,1.1-3.4,2.4-5.3,3.2c-2,0.8-4.1-1.3-3.5-3.4c0.3-1.1,0.9-2.2,1.6-3.1c1.8-2.1,3.7-4.1,5.6-6.2
	c0.1-0.1,0.2-0.2,0.1-0.1c1.5,0.9,2.8,2.1,4.3,2.5c1.7,0.4,3-1,4.4-2.1c2.3,2.4,4.5,1,6.9-0.4c-0.2-0.1-0.5-0.2-0.7-0.2
	c-1,0.2-2.1,0.3-3,0.7c-1,0.4-1.6-0.1-1.9-0.8c-0.2-0.3,0.2-0.9,0.4-1.4c0.1-0.3,0.3-0.5,0.4-0.8c-0.1-0.1-0.1-0.2-0.2-0.3
	c-0.8,0.5-1.7,0.9-2.5,1.5c-0.9,0.6-1.7,1.5-2.6,2.1c-1.4,1-2.5,0.6-3.1-1c-0.3-0.8-0.7-1.5-0.8-2.2C87.7,62,88,61.4,88.3,61
	c1.1-1.4,2.1-2.8,3.3-4.1c1.5-1.6,3.2-3.1,4.8-4.6c-0.4,0.2-0.7,0.3-1.1,0.5c-3.7,1.9-6.4,4.8-8.9,8c-2.4,3-4.8,6-7.3,8.9
	c-2.2,2.5-4.5,5-6.7,7.5c-2.1,2.5-6.1,3.7-9.2,2.7c-0.4-0.1-0.7-0.3-1.3-0.6c1-0.8,1.8-1.5,2.6-2.2c2.6-2.3,4.2-5.4,6.1-8.2
	c1.2-1.8,2.3-3.8,3.6-5.6c0.9-1.3,2-2.6,3.1-3.8c1.6-1.8,3.4-3.6,5.1-5.4c0.2-0.2,0.3-0.4,0.4-0.6c-0.1-0.1-0.1-0.1-0.2-0.2
	C81.4,54.1,80.2,55,79,56c-2.2,2-4.5,3.8-6.4,6c-2,2.3-3.5,4.9-5.3,7.4c-1.6,2.3-3.1,4.8-5.1,6.7c-2.1,2.1-4.6,3.7-7.1,5.4
	c-1.3,0.9-2.9,1.1-4.4,0.5c-1.3-0.5-1.7-1.6-2.1-2.7c-0.1-0.2,0-0.5,0.1-0.7c0.8-0.8,1.6-1.8,2.6-2.3c2.3-1.2,4.2-2.8,5.9-4.7
	c2.3-2.4,4.6-4.8,6.6-7.5c2.9-3.8,6.2-7,10.1-9.7c0.4-0.3,0.7-0.9,0.9-1.5c-1,0.5-2,0.9-2.9,1.6c-2.6,1.9-5.3,3.7-7.6,5.9
	c-1.9,1.7-3.2,4.1-5,5.9c-2.9,3-5.9,6-9.2,8.5C46.8,77.3,43,79,39.4,81c-1.8,1-2.6,0.8-4-0.7c-0.8-0.8-1.4-4.3-0.7-5
	c1.4-1.4,2-3.3,3-4.9c1.4-2.3,3.4-3.9,5.4-5.6c3.1-2.6,6.1-5.3,8.6-8.5c3-3.7,5.9-7.5,9.3-10.9c3-3,6.2-5.8,10.1-7.8
	c2.2-1.1,4.1-2.5,6.3-3.6c1.6-0.8,3.4-1.6,5.2-2c3.1-0.7,6.3-1.1,9.4-1.5c2.4-0.3,4.6,0.3,6.9,0.9c4.5,1.1,8.3,3.6,12.3,5.7
	c2.3,1.2,4.7,2.1,7.1,3.1c0.4,0.2,0.9,0.1,1.3,0c3.3-1.1,6.5-2.5,9.8-3.3c2.9-0.8,6-1,9.1-1.5c0.3-0.1,0.7,0.1,1.1,0.1
	c0.3,0,0.7-0.1,1.3-0.2c-0.5-0.4-0.7-0.6-1-0.8c0.1-0.1,0.1-0.2,0.2-0.3c0.7-0.5,1.3-1,2-1.5c2.1-1.5,4.1-3.1,6.2-4.6
	c2-1.4,4-2.9,6.2-3.9c2.5-1.1,5.2-1.8,7.9-2.4c4.2-0.9,8.5-1,12.7,0.1c2.1,0.6,4.4,1,6.3,2c1.9,0.9,3.5,2.4,5.2,3.7
	c2.3,1.7,2.8,4.2,3.4,6.7c0.2,0.8,0.1,1.7,0.3,2.6c0.3,2.3-0.4,4.3-1.1,6.4c-0.6,1.6-1.2,3.2-2,4.8c-0.4,0.7-1.2,1.1-1.8,1.7
	c-0.1-0.1-0.3-0.1-0.4-0.2c0-0.5,0.1-1.1,0-1.6c-0.2-0.9-0.5-1.8-0.7-2.8c-0.1-0.4-0.3-0.9-0.2-1.1c0.9-1.2-0.1-1.5-1.1-1.9
	c0.8,1.5-0.5,2.1-1.1,2.9c-1.5,2.4-3.2,4.6-5.6,6.3c-1.6,1.2-4.4,0.9-5.5-0.5c-0.3-0.3-0.7-0.6-1.1-1c-0.2,0.5-0.3,0.8-0.4,1.2
	c-0.4-0.1-0.8-0.1-1.3-0.2c-0.3,1-0.5,1.9-0.8,2.8c-2.9-0.6-5.4-1.9-7.2-4.1c-2.8-3.5-3.1-7.4-1.2-11.5c0.7-1.5,1.7-2.8,2.4-4.3
	c0.3-0.8,0.2-1.7,0.1-2.7c-1.5,1.1-2.7,2.2-3.2,4c-0.5,1.6-1.3,3.2-1.6,4.8c-0.6,2.9-0.3,5.9,1.1,8.6c0.4,0.8,0.3,1.4-0.3,2.1
	c-1.9,2.1-2.7,4.8-3.2,7.5c-0.1,0.8-0.3,1.6-0.4,2.5c0,0.6,0,1.3,0.9,1.2c0.7-2.4,1.4-4.8,2.1-7.2c3.1,4,5.7,8,5.7,13
	c0,2-0.5,4.1-1.2,6c-1.7,5-4.7,9.1-8.9,12.4c-0.5,0.4-0.9,1-1.5,1.6c-0.1-1.4,1.2-2.6,0.2-3.8c-0.3-0.3-0.9-0.4-1.4-0.7
	c-0.5,2-0.9,3.8-1.3,5.5c-1.8-0.2-3.6-0.4-5.7-0.6c0-0.4,0.1-1.1,0.1-1.8c0-0.4-0.1-0.9-0.3-1.2c-0.3-0.3-0.7-0.4-1.1-0.5
	c-0.1,0.3-0.3,0.7-0.4,1.1c-0.1,0.7-0.3,1.5-0.2,2.2c0.1,0.9-0.4,1.1-0.9,1.5c-1.2,0.9-2.4,1.8-3.6,2.7c-0.6,0.4-1.2,0.9-1.9,1.4
	c-0.3-1.6,0.5-2.4,1.2-3.2c1-1,1.3-2.2,1.3-3.6c0-1.4-0.6-1.9-2.2-1.9c0.5,1.9-0.7,3.2-1.5,4.7c-0.7,1.3-0.9,2.8-1.3,4.2
	c0.1,0,0.2,0.1,0.4,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.6,0.2-1.1,0.7-0.6,1.2c0.3,0.3,1.1,0.4,1.6,0.3c0.9-0.3,1.7-1,2.6-1.2
	c1.6-0.3,2.7-1.1,3.7-2.4c1.7-2.3,7-3.4,9.5-2c1.6,0.9,2.9,4.3,2.3,6.1C150.2,97.3,149.8,98.1,149.5,99z M164.5,39.9
	c-1,2.3-0.9,4.5-0.2,6.8c0.7,2.4,4.3,4.3,6.2,2c0.6-0.7,1.1-1.4,1.7-2c0.4,0.4,0.8,0.8,1.2,1.2c0.1-0.1,0.3-0.2,0.4-0.3
	c-0.2-0.7-0.4-1.4-0.6-2.1c-0.1-0.6-0.3-1.3-0.2-1.9c0.4-2,0.3-4-0.9-5.6c-0.7-0.9-1.9-1.5-3-1.9c-1.1-0.4-1.8,0.6-2.4,1.3
	c-0.5,0.6-1,1.2-1.9,0.8c-0.4-0.2-1-0.1-1.5-0.2C163.3,39.7,163.5,40,164.5,39.9z M167.3,33.2c0.8,0.1,1.3,0.3,1.8,0.2
	c2.2-0.5,5.1,0.7,6.2,2.7c0.3,0.5,0.8,0.9,1.2,1.4c-0.2-3.6-4.4-6.8-9.4-5.4C167.2,32.5,167.2,32.9,167.3,33.2z M104.4,63.2
	c-0.4,3.4,1.6,5.1,4.1,4.7c0.9-0.1,1.9-0.3,2.5-0.8c0.5-0.4,1.9-0.6,1.3-1.8c-1.5,0.5-2.8,1.1-4.2,1.5c-1.3,0.4-1.7,0.2-1.8-1.1
	c0-0.4,0.2-0.9,0.3-1.6C106,63.8,105.2,63.5,104.4,63.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M332.7,470.2c1.5-7.1-0.7-11.5-3.7-13.1c0.5,1,0.9,1.8,1.4,2.5c1.3,2,1.6,4.2,1.1,6.5c-0.5,2.3-0.9,4.7-1.5,6.9
	c-0.5,1.8-1.3,3.4-3.4,4.3c-2.7,1.1-5.2,2.8-7.9,3.8c-4,1.6-8,3.1-12.4,2.9c-4.4-0.1-8.9,0.2-13.2-0.4c-5.1-0.7-9.4-3.2-12.7-7.4
	c-1.1-1.5-2.2-3-3.3-4.5c-0.9-1.3-0.4-2.7-0.2-4c0.2-1.4,0.2-2-1.1-2.7c-1.9-1.1-3.8-2.2-5-4.1c-0.5-0.8-0.8-1.9-0.8-2.9
	c0-0.5,0.7-1.1,1-1.6c0.6-1,0.4-1.2-0.7-1.6c-0.7-0.2-1.2-0.9-1.8-1.3c-0.1,0.2-0.3,0.4-0.4,0.7c-0.5-0.8-1.1-1.5-1.5-2.3
	c-0.4-0.7-0.7-1.5-0.9-2.3c-0.2-0.6-0.7-1.2,0.1-1.8c0.1-0.1-0.1-0.7-0.3-0.9c-0.6-0.8-0.7-1.5-0.5-2.5c0.3-1,0.4-2.1,0.6-3.1
	c1.4-0.3,2.4,0.2,3.2,1.1c1.6,2,3.1,4.1,4.7,6.1c1.6,2,3.6,3.5,6.2,4.2c0.7,0.2,1.3,0.5,1.9,0.8c0.4,0.1,0.9,0.2,1.4,0.2
	c-0.2-0.4-0.3-1-0.6-1.2c-1.3-0.8-2.1-2-2.5-3.4c0.3-0.2,0.8-0.4,0.8-0.7c0.2-1.4,1.1-1,1.9-0.9c1,0.2,2.1,0.3,3.1,0.7
	c1.3,0.6,2.6,1.3,3.5,2.3c1.2,1.2,2.6,1.2,4,1.4c0.6,0.1,1.2,0.1,1.9,0.1c0-0.4,0.1-0.8,0-1.1c-0.3-0.8,0-1,0.8-1.2
	c1.6-0.3,3-0.2,4.4,0.7c0.8,0.5,1.6,0.9,2.4,1.3c2.3,1.4,4.9,1.8,7.5,1.4c1-0.2,2-0.1,3-0.3c0.3,0,0.5-0.3,0.8-0.5
	c-0.2-0.3-0.4-0.8-0.6-0.9c-0.9-0.3-1.8-0.4-2.8-0.5c-2.2-0.2-4.5-0.2-6.7-0.6c-1.3-0.2-2.6-1-3.8-1.8c-2.8-1.7-5.5-1.7-8.3,0
	c0.2,0.4,0.4,0.8,0.8,1.6c-1-0.3-1.9-0.4-2.4-0.8c-0.5-0.4-0.5-1.1,0.3-1.4c1.8-0.6,3.6-1.5,5.5-1.8c2.8-0.5,5.8-0.8,8.6-0.7
	c3.3,0.1,6.6,0.2,9.9,1c3.6,0.8,7.2,1.9,10.6,3.4c0.8,0.3,1.7,0.5,2.5,0.6c0.3,0,0.6-0.2,1-0.3c0-0.1,0-0.3,0-0.4
	c-1.9-1-3.9-1.9-6-3c3.9-2.5,5.1-6,4.2-10.3c1.2,0.2,2.2,0.5,3.3,0.6c0.3,0,0.6-0.3,0.9-0.4c-0.3-0.3-0.6-0.7-0.9-0.7
	c-3.8-0.8-6.5-2.8-8-6.5c-1.1-2.7-0.3-5.2,0.8-7.6c0.8-1.7,1.8-3.3,2.9-4.8c0.5-0.8,1.6-1.2,2.1-1.9c1.3-1.8,3-3,5.2-3.4
	c2-0.3,4-1.2,6-1.1c4,0.2,7.8,1.2,10.9,4c1.3,1.2,2.1,2.6,2.4,4.4c0.1,0.9,0.8,1.8,1,2.4c1.4,0.3,2.6,0.4,3.4,0.9
	c2,1.2,3.8,2.7,6.1,3.4c0,0,0.1,0.1,0.1,0.3c-2.6,0.2-5.3,0.4-8,0.7c-0.1-0.5-0.1-1.1-0.3-1.7c-0.2-0.5-0.6-1-0.9-1.5
	c-0.2,0.1-0.4,0.3-0.6,0.4c0.9,2.2,0,4.2-0.7,6.2c-0.5,1.4-1.3,2.5-2.8,3c-0.8,0.3-1.5,0.9-2.2,1.4c-2.2,1.6-4.7,1.5-7.2,1.7
	c-1.9,0.1-3.5-0.3-5.2-1.3c-1.9-1.1-2.8-2.7-3-4.8c-0.1-0.6,0.1-1.3-0.1-1.8c-0.2-0.6-0.6-1.1-1.1-1.9c-0.9,4.5,1,8.3,5.2,10.5
	c1.2,0.6,2.5,0.9,3.7,1.4c0.3,0.1,0.5,0.6,0.5,0.9c0,3.1-0.6,6.2-1.5,9.2c-0.7,2.2-1.3,4.4-2,6.5c-0.4,1.3-1,2.5-1.4,3.8
	c-0.3,1-0.7,2.1-0.7,3.1c0.2,3-1.2,5.5-2,8.2C333,469.9,332.9,470,332.7,470.2z M341.7,423.3c1.2,2.8,4,3,6.1,4.3
	c0.5,0.3,1.8-0.4,2.7-0.9c0.5-0.3,0.5-0.9,0-1.3c-0.1-0.1,0.1-0.4,0.1-0.5c0.8-2.8-0.1-5.3-1.3-7.9c0.6-0.1,1.1-0.3,1.7-0.4
	c-0.6-1-1.5-1.2-2.4-1.3c-0.6,0-1.2,0.3-1.7,0.1c-1.1-0.3-2.2-0.8-3.1-1.1c-0.4,0.7-0.8,1.3-1.2,1.9c-0.4,0.7-1,1.4-1,2.2
	c0,1.2-0.4,2.1-1,3c-0.3,0.4-0.6,0.7-0.9,1.1c-0.6,0.9-0.6,1.8-0.1,2.8C340.3,424.5,342.1,425.2,341.7,423.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M289.5,513.3c0.6-0.2,1.3-0.4,2-0.6c-0.2-0.2-0.3-0.3-0.7-0.6c2.6-0.7,5-1.4,7.5-2.1c-3.2-1.8-4.8-4.9-7.2-7.3
	c-0.9-0.9-0.4-2.6,0.5-4.1c0.9-1.6,1.8-3.2,2.6-4.8c0.3-0.6,0.2-1.3,0.3-2.2c-1.3-0.6-1.8-2.9-4.3-2.4c0.7,0.6,1.2,1.1,1.7,1.6
	c-1.1-0.4-2.7,0.8-3.2-1.2c-2.1,1.3-3.9-0.1-5.7-0.6c-2.7-0.8-5.2-2-7.7-3.3c-2.3-1.3-4.6-2.7-6.6-4.4c-1.5-1.4-2.8-3.2-3.7-5
	c-1.4-2.9-2.5-5.9-3.7-8.9c-0.1-0.3-0.2-0.7-0.3-1.1c0.1-0.1,0.2-0.1,0.3-0.2c1.6,1.5,3.2,3,4.9,4.4c0.1-0.1,0.3-0.1,0.4-0.2
	c-0.1-0.4,0-0.8-0.2-1.1c-1.2-1.8-2.8-3.2-4.4-4.7c-2.2-2.1-4.4-4.3-5.5-7.3c-0.5-1.5-0.8-3.1-1.1-4.6c0.1-0.1,0.2-0.1,0.4-0.2
	c1.5,1.2,2.9,2.4,4.4,3.6c0.6,0.5,1.3,1.6,2.1,0.8c0.8-0.9-0.4-1.6-0.9-2.1c-1.3-1.3-2.8-2.4-4.3-3.4c-1.8-1.2-3.9-2.1-5.6-3.4
	c-2.5-2-4.9-4.1-4.8-7.8c0-1,0-1.9,0.1-2.9c0.1-1.2-0.7-1.6-1.5-2c-0.2-0.1-0.6,0.1-1.2,0.2c-0.1-0.1-0.5-0.5-0.9-0.8
	c-1.7-1-1.8-2.6-1.5-4.3c0.3-1.3,1.4-1.8,2.5-1c2.8,2.1,6,3.8,8.3,6.6c1.6,2,3.4,3.9,5.1,5.8c1.3,1.4,2.7,2.8,4.2,4
	c1,0.7,1.4,1.5,1.6,2.7c0.4,2.4,1.4,4.6,3,6.4c1,1.2,1.4,2.4,1.5,3.8c0.2,2.3,1.7,3.9,3.3,5.3c0.9,0.8,2,1.3,2.9,2
	c0.4,0.3,0.7,1,0.6,1.5c-0.3,2,0,3.6,1.1,5.4c1.7,2.7,3.7,5.2,5.9,7.4c2.4,2.3,5.1,4.1,8.4,4.9c2.3,0.5,4.5,1.3,6.7,1.6
	c2.4,0.2,4.8-0.1,7.2-0.1c1,0,2,0,3,0c4,0,7.8-0.9,11.6-2.4c2.3-0.9,4.3-2.1,6.2-3.5c0.2-0.2,0.5-0.3,0.8-0.3
	c-5.3,5.4-12.3,7.8-19.2,10.3c0.2-0.3,0.5-0.6,0.7-1c0.2-0.4,0.2-0.8,0.3-1.2c-0.5,0-1.1-0.2-1.4,0c-1,0.9-2,1.9-2.9,2.9
	c-1.1,1.2-2,2.4-3.1,3.6c-0.2,0.3-0.8,0.3-1.2,0.4c-0.1,0-0.4,0.1-0.4,0.2c-0.4,1.6-2,2-3,3c-0.5,0.5-1.1,1-1.7,1.5
	c1.3,2.8,2.5,5.6,3.9,8.4c0.4,0.8,1.2,1.3,1.9,1.9c0.2,0.2,0.6,0.2,0.9,0.1c1.4-0.2,2.9-0.8,4.2-0.6c4.1,0.6,8.3,1.1,12.5,0.8
	c0.2,0,0.3,0,0.5,0.1c-0.3,1.9-3.6,2.5-7.6,1.2c0,0.2,0,0.4,0,0.9c-1.6-0.8-3.1-1.5-4.6-2.3c-0.1,0.1-0.1,0.1-0.2,0.2
	c0.2,0.3,0.4,0.7,0.7,0.9c2.1,1.6,4.5,2.5,7.1,2.7c0.7,0.1,1.5,0.2,2.5,0.4c-3.8,1.4-7.6,1.2-11.2-0.7c-3.8-2-7.7-2.5-11.8-1.1
	c-1,0.3-2.1,0.5-3.1,0.7C289.6,513.6,289.6,513.4,289.5,513.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M210.4,67.8c-1.6,0.9-3.2,1.1-5,0.9c-2.6-0.3-5.3-0.6-7.6-2.1c-1.5-1-2.6-2.3-3.3-4c-1-2.3-2.1-4.6-3.1-6.9
	c-0.2-0.5-0.6-0.8-0.9-1.3c-0.3,1,2.6,8.7,4.2,11c-1.3-0.1-2.6-0.2-3.8-0.3c-3.5-0.2-5.6-2.4-7.6-5c-0.2-0.2-0.2-0.6-0.2-0.9
	c0.5-2.3,0.8-4.8,1.8-6.9c0.6-1.2,2.2-1.9,3.5-2.7c0.6-0.3,1.1-0.6,1.4-1.3c0.8-2,1.9-3.8,2.6-5.8c0.4-1.1,0.3-2.4,0.3-3.6
	c0-1.5-0.2-3.1-0.3-4.7c1.4-1,2.9-0.2,4.1,0.3c2,0.7,4.1,1.5,5.7,2.8c2.7,2,5.5,4.1,7.7,6.7c4,4.8,7.7,9.9,11.3,15
	c1.2,1.7,1.8,3.8,2.6,5.7c0.3,0.7,0,1.1-0.7,1.5c-1.8,1.2-3.9,1.7-6,1.8c-2.4,0.2-4.6-0.7-6.5-2.1c-2.3-1.7-4.4-3.6-6.4-5.6
	c-0.9-0.8-1.4-2.1-1.9-3.2c-0.6-1.1-1-2.4-1.8-3.5c0.1,2,0.5,4,1.6,5.6c1.1,1.6,2.4,3.1,3.8,4.5C207.3,65.2,208.9,66.5,210.4,67.8z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M171.7,53.6c1.1,0.2,2.1,0.6,3.1,0.5c1.5-0.1,2.6-1.2,3.6-2.3c0.5-0.5,1-1,1.4-1.6c0.8-1.1,1.5-2.2,2.3-3.2
	c0.3-0.4,0.7-0.8,1.1-1.2c0.1,0,0.3,0,0.4,0c0,1.6,0.3,3.2,0,4.7c-1,5.1-2.3,10.1-3.5,15.1c-0.2,0.7-0.6,1.7-1.1,2
	c-1,0.5-1.5-0.6-1.9-1.3c-0.6-1.1-1-2.3-1.5-3.4C174.2,59.8,173,56.7,171.7,53.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M305.4,494.8c-0.2-1.2,0.7-1.5,1.5-1.8c1.8-0.7,3.5-1.5,5.3-1.8c1-0.2,2.2,0.2,3.4,0.4c1.7,0.3,3.3,0.6,4.5,2.1
	c0.2,0.3,0.7,0.4,1.1,0.6c2.1,0.9,4.2,1.7,6.1,2.8c1.1,0.7,1.9,2.1,2.8,3.2c-0.1,0.1-0.1,0.3-0.2,0.4c-0.5-0.1-1.1,0.1-1.5-0.2
	c-1.6-1-3.2-2.1-4.8-3.2c-0.6-0.5-1.2-1.1-1.9-1.5c-0.7-0.4-1.6-0.6-2.4-0.7c3.6,2.6,7.2,5.2,9.2,9.3c-0.7,0.9-0.7,0.8-1.7,0.5
	c-1.4-0.5-2.2-1.6-3-2.7c-0.6-0.8-1.4-1.6-2.2-2.2c-2.5-2-4.6-4.7-8.4-4c-1.3,0.3-2.5,0.7-3.5,1.8c-0.8,1-1.8,1.7-2.7,2.5
	c-0.3,0.2-0.8,0.2-1.2,0.3c-0.1-0.1-0.2-0.2-0.2-0.3c0.9-0.9,1.7-1.8,2.7-2.6c1.2-0.9,2.6-1.6,3.8-2.4c0.3-0.2,0.6-0.7,0.9-1
	c-0.4-0.2-0.7-0.6-1.1-0.7C309.6,493.2,307.5,494.2,305.4,494.8L305.4,494.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M159.6,52.2c2.5,1.2,4.5,3.5,7.8,2.9c0,2.4,0,4.6,0,6.9c0,0.2-0.3,0.6-0.6,0.7c-0.5,0.2-1,0.2-1.5,0.3
	c-2.1,0.7-1.9,0.6-2.8-1.4c-0.9-2.1-2.3-3.9-3.4-5.9c-0.1-0.2-0.3-0.4-0.4-0.6c-1-1.4-1-1.6,0.4-2.6
	C159.3,52.4,159.4,52.3,159.6,52.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M168.6,64.8c0-3.9,0-7.8,0-11.6c0-0.3,0.3-0.5,0.5-0.9c0.5,0.6,1,1.2,1.5,1.8c1,1.4,1.2,2.8,0.6,4.4
	c-0.8,2.1-1.4,4.3-2.1,6.4C168.9,64.8,168.7,64.8,168.6,64.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M151.8,91.7c2.3-0.2,3,0.1,3.5,1.6c0.6,1.6-0.1,3.7-1.4,4.6c-0.6,0.4-1.1,0.7-1.5,1c0.1-1.1,0.5-2.3,0.4-3.5
	C152.7,94.2,152.2,93,151.8,91.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M170.3,69c-0.9-3.9,1.6-6.9,2.2-10.3c0.2,0.7,0.3,1.5,0.6,2.1c0.8,1.5,0.3,2.7-0.6,4
	C171.8,66.1,171.2,67.4,170.3,69z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M154.4,99.8c1.2-1.4,3.3-2,3.1-4.2c-0.1-1.7-0.8-3.9-2.7-4.5c0.3-1.1,1.5-1.3,2.4-0.4c1.5,1.7,2,3.6,1.3,5.7
	c-0.2,0.5-0.3,1.1-0.5,1.6c-0.3,0.7-0.7,1.1-1.5,1.1C155.7,99.2,155.1,99.6,154.4,99.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M159.2,99.9c1.7-3.6,2.9-4.6-0.1-9c1.9-0.2,2.1,0,2.8,1.8c0.9,2.2-0.1,4.1-0.7,6C161,99.1,160,99.3,159.2,99.9z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }}
              d="M182.7,61.1c1,1.1,1.8,1.9,2.7,2.9c-1.2,0.4-2.2,0.6-3.6,1C182.1,63.6,182.4,62.5,182.7,61.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M317.1,497.7c0.6,0.5,1.1,0.9,1.7,1.4c-0.3,0.5-0.5,1-0.9,1.7c-0.4-1.2-0.7-2-1.1-2.9
	C317,497.8,317,497.8,317.1,497.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M305.4,494.8c-0.8,0.2-1.6,0.4-2.7,0.8c0.5-0.7,0.9-1,1.2-1.4C304.4,494.3,304.9,494.6,305.4,494.8
	C305.4,494.8,305.4,494.8,305.4,494.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M341.8,361.8c-1.5,2.5-3.9,4-6.2,5.7c-0.1-0.9,0-1.9-0.4-2.5c-0.3-0.4-1.3-0.4-2.1-0.7c0.1,1.3,0.2,2.5,0.3,3.7
	c0.1,2.3,1,4.2,2.2,6.1c0.7,1,0.8,2.2,0,3.4c-1.4,2.1-2.5,4.4-3.7,6.7c-0.2,0.4-0.4,0.8-0.4,1.2c0,0.4,0.2,0.9,0.4,1.2
	c0.1,0.1,0.7-0.1,1.3-0.2c-0.5,0.7-0.9,1.2-1.3,1.8c-0.4,0.6-0.7,1.2-0.9,1.9c-0.5,1.2-0.9,2.3-1.5,3.5c-0.3,0.6-0.8,1.1-1.3,1.6
	c-1.1-2.7,0.5-4.7,1.6-6.8c-1.1-1-1.9-1-3.1-0.2c-0.5,0.3-1.1,0.5-1.6,0.8c-1.2,0.9-2.4,1.9-3.6,2.8c-0.5,0.4-1,0.6-1.6,0.9
	c3.7-3.9,2.7-4,9.5-6.8c-0.6-0.3-1-0.5-1.4-0.6c-2.2-0.3-4.3,0.2-6.2,1.4c-0.3,0.2-0.7,0.4-1.1,0.5c-0.3,0-0.6-0.1-0.9-0.2
	c0.1-0.3,0.2-0.6,0.4-0.7c1.8-1,3.5-2,5.7-1.6c0.9,0.2,1.8,0,2.7,0c0.2,0,0.4-0.1,0.6-0.1c1.4,0.7,1.7-0.4,2.2-1.3
	c0.8-1.7,1.6-3.5,2.4-5.2c0.4-0.9,0-1.3-0.7-1.6c-3.9-1.4-6.4-4.6-9.2-7.5c-0.1-0.1-0.3-0.3-0.4-0.5c-0.4-1.8-1.8-1.6-3.3-1.5
	c0.7,0.8,1.3,1.5,1.9,2.2c-3.9,0.9-7.5,0.5-11-1c-2.5-1.1-5.1-2-7.5-3.2c-1.2-0.6-2.1-1.9-3.2-2.9c-0.8-0.8-1.7-1.5-2.5-2.4
	c-2-2.2-2.6-5.1-3.3-7.8c-0.6-2.4-0.8-4.8-0.2-7.2c0.5-1.8,1.2-3.5,2-5.2c0.5-1.2,1.2-2.3,1.8-3.5c1.9-3.5,4.7-6.3,7.3-9.2
	c1.5-1.6,2.7-3.5,3.2-5.6c0.3-1,0.2-2.1,0.3-3.1c0.6-0.2,1.2-0.4,1.8-0.5c0.3-0.1,0.7-0.1,0.9,0.1c2.6,1.6,5.4,1.5,8.2,0.9
	c1.1-0.2,2-1.1,3-1.8c0.8-0.5,1.5-1.2,2.4-1.7c-0.5,0.6-0.9,1.3-1.4,1.9c-2.1,2.9-2.9,6.3-2.8,9.8c0,2.5,0.4,5,0.9,7.4
	c0.2,1,1.3,1.9,2.1,2.7c0.2,0.2,0.7,0.1,1.4,0.2c-1.6-1.7-2.2-6.7-2-9.8c0.7-0.1,1.3-0.2,2-0.3c2.7-0.1,5.3-0.2,8,0.4
	c3.1,0.7,6.2,1.3,9.2,2.6c0.7,0.3,1.5,0.3,2.2,0.4c0.1-0.2,0.1-0.3,0.2-0.5c-0.8-0.6-1.7-1.2-2.5-1.8c1.6-1.7,3.1-3.4,4.7-5.2
	c1.2-1.4,2.3-2.9,3.7-4.2c1.1-1,2.5-1.7,3.9-2.5c1.7-0.9,2.3,0.3,2,1.7c-0.2,1.2-0.6,2.3-1,3.4c-0.6,1.6-1.2,3.3-1.8,5
	c1.1,0.6,1.7-0.2,2.2-0.9c0.3-0.4,0.5-0.8,0.6-1.3c0.3-1.5,1.6-1.4,2.7-1.7c0.2,0,0.6,0.3,0.7,0.5c0.2,0.8,0.4,1.7,0.6,2.7
	c-4.1-0.5-6.6,2.2-9.5,4c-1.8,1.1-3.7,2.2-5.5,3.3c-0.4,0.2-1.2,0.1-1.5-0.1c-1.2-1.2-2.7-1.6-4.3-2.1c-2.8-0.8-5.7-1.1-8.6-0.5
	c-1.3,0.3-2.5,1-3.8,1.5c0,0.1,0,0.2,0.1,0.3c0.3,0.1,0.7,0.2,1,0.4c0.7,0.5,1.1,0.4,1.8-0.2c1.4-1.2,3.2-1.3,4.9-1
	c2.2,0.4,4.4,1,6.6,1.6c0.5,0.1,1,0.6,1.7,1c-1.7,0.8-3.2,1.6-4.8,2.4c0.6,0.3,1,0.6,1.4,0.8c0.3,0.1,0.8,0.3,1.1,0.1
	c4.5-2.1,9.2-4,13.2-7.1c1.4-1.1,3.2-1.9,4.8-2.7c0.3-0.1,0.9,0.1,1.2,0.4c2,1.7,0.9,3.6,0.3,5.4c-0.8,2.3-2.1,4.3-4.5,5.4
	c-1.9,0.9-3.7,2.4-5.5,3.5c-0.7,0.4-1.4,0.7-2.1,1.1c1.3,1.3,2.5,0.5,3.8-0.2c1.8-1,3.6-2.1,5.2-3.2c-0.9,4.1-6.2,9-13.3,10.3
	c1.1,0.7,1.9,1.3,3.3,0.7c1.3-0.6,2.8-0.8,4.1-1.4c1-0.5,1.9-1.3,2.9-1.8c-0.2,1.7-0.8,3.2-2,4.4c-2.2,2.2-4.3,4.6-7.1,6.2
	c-2,1.2-4.2,1.8-6.5,2.2c-1.7,0.3-3.5,0.6-5.3,0.7c-3,0.1-6-0.3-8.6-2c-2-1.3-3.3-2.9-3.2-5.4c0-1.8-0.2-3.7-1.6-5.1
	c-0.5-0.5-1.3-0.8-2.1-1c1.3,1.1,1.4,2.6,1.4,4.1c0.1,4.9,2.5,8.2,7.3,9.7c1.6,0.5,3.3,1,4.9,1.1c1.8,0.1,3.6-0.1,5.5-0.3
	C339.1,362.2,340.4,362,341.8,361.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1}
              fill={this.state.color17 ? this.state.color17 : 'transparent'} onClick={() => {
          this.setState({ color17: color, opacity17: opacity });
        }} d="M324.1,281.8c-0.5,1.2-1,2.4-1.6,3.5c-0.4,0.8-0.7,1.4,0.2,1.9c0.2,0.1,0.3,0.4,0.6,0.7c1-0.3,1.9-0.7,3-1
	c-0.2,0.3-0.3,0.7-0.4,0.9c-0.9,1.8-0.9,1.8,1.2,3.2c1.5,1,3,2.2,3.3,4.3c0.1,0.9,0.4,1.8,0.7,2.7c1,2.7,0.6,5.4,0.3,8.1
	c-0.2,1.8-0.5,3.6-1.6,5.2c-0.5,0.7-0.9,1.4-2,1.3c-0.3,0-0.6,0.4-0.9,0.7c-0.3,0.4-0.7,0.8-1.1,1.3c0.6-1.8,1.2-3.3,1.5-4.9
	c0.3-1.7,0-3.3-1.6-4.5c1,5.5-1.2,9.4-5.8,11.8c-2.3,1.2-4.8,1.4-7.4,0c0.6-0.6,1.2-1.1,1.7-1.6c1.1-1,0.9-2.2,0.7-3.5
	c0-0.1-0.1-0.3-0.2-0.3c-1.5-0.2-1.5-1.6-2.1-2.6c-1-1.6-2.3-2.5-4.2-2.5c-0.3,0-0.7-0.1-0.9-0.3c-1.4-1.2-2.4-2.6-2.6-4.6
	c0-0.2-0.3-0.5-0.4-0.5c-0.6-0.1-1.1-0.2-1.9-0.2c0.2-0.7,0.4-1.4,0.6-2.1c0.8-2.6,2.1-4.8,4.5-6.2c1.1-0.7,2.3-1.2,3.5-1.8
	c2.5-1.3,4-3.7,5.4-6c0.1-0.1,0.1-0.2,0.5-0.5c-0.4,1.3-0.1,2.1,0.8,2.4c0.3,0.1,0.8,0.1,1-0.1c1.6-1.7,3.2-3.4,4.7-5.1
	C323.9,281.7,324,281.7,324.1,281.8z M309.3,301.8c0,0.2,0.2,0.5,0.2,0.8c0.3,3.5,2.6,5,5.6,5.8c1.5,0.4,4.2-0.8,4.4-2.2
	c0.3-2.1,1.2-4.2-0.8-6.2c-1.5-1.5-2.8-2.5-4.9-2.3c-0.9,0.1-1.7,0.1-2.6,0.3c-0.2,0-0.5,0.3-0.6,0.5c-0.4,1.4-1.3,2.2-2.8,2.4
	c-0.3,0-0.6,0.3-0.9,0.5c0,0.1,0,0.2,0,0.3C307.7,301.7,308.5,301.7,309.3,301.8z M318,296.7c0-0.1,0.1-0.2,0.1-0.4
	c-1.1-1.6-2.7-2.4-4.6-2.3c-1.4,0.1-3.2-0.1-4.1,2c0.7,0,1.3,0,1.7-0.1c1.5-0.5,2.8-0.4,4.2,0.3C316.2,296.5,317.1,296.5,318,296.7z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M303.1,315.4c2-0.5,3.9-1.1,5.5-1.5c0.7,0.7,1.2,1.3,1.7,1.8c0.2-0.1,0.5-0.2,0.7-0.4c0-0.9,0.4-2.1-0.1-2.5
	c-0.7-0.7-1.6-1.8-3.1-1c0.2,0.3,0.4,0.5,0.6,0.8c0,0.1-0.1,0.2-0.1,0.2c-1.5,0.4-3,0.5-4.4,1.1c-1.7,0.7-3.3,1.5-4.8,2.5
	c-2,1.4-4.2,2.1-6.6,2.2c-0.1-0.1-0.2-0.2-0.3-0.3c0.6-1,1.1-2.1,1.8-2.9c0.9-1,1.9-2,3-2.7c1.6-1,1.6-2.8,2.4-4.2
	c0.4-0.7,0.7-1.3,1.1-2c0.5-0.9,0.9-1.8,1.3-2.7c0.3-0.5,0.6-1,1-1.6c0.8,1.2,1.5,2.4,2.3,3.4c1.1,1.2,2.2,2.5,4.2,2.3
	c0.4,0,0.9,0.5,1.2,0.9c0.8,1.1,1.5,2.2,2.1,3.3c0.7,1.1,0.1,3.2-1.1,3.9c-1.8,1.1-3.7,1.3-5.7,0.8c-0.9-0.2-1.8-0.6-2.7-0.9
	C303.2,315.7,303.2,315.5,303.1,315.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M314.1,376.4c-0.2-0.3-0.3-0.6-0.5-0.6c-2.1-0.2-2.8-2-3.9-3.4c-0.9-1.1-1.5-2.5-2.4-4c2.3,0.7,4.3,1.3,6.2,2
	c0.2,0.1,0.5,0.4,0.5,0.7C314.1,372.7,314.1,374.4,314.1,376.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }} d="M295.4,359.7c-1.5-1.1-2.9-2.2-4.4-3.3c0.1-0.1,0.1-0.2,0.2-0.3c0.4,0.1,0.7,0.3,1.1,0.4
	c-1.9-1.4-2.2-3.3-1.7-5.3c0.3-1.3,0.6-2.6,1-3.9C292.2,351.6,293.5,355.6,295.4,359.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }} d="M307.5,380.2c-3,1.3-5.8-0.2-8.6,0C300.4,378.8,305.3,378.8,307.5,380.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }}
              d="M333.6,322.2c-0.1,1.3-0.2,2.4-0.3,3.4c-1.5-0.2-3.1-0.3-4.6-0.5C330.2,324.2,331.8,323.2,333.6,322.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity23 ? this.state.opacity23 : 1}
              fill={this.state.color23 ? this.state.color23 : 'transparent'} onClick={() => {
          this.setState({ color23: color, opacity23: opacity });
        }} d="M312,381.1c0.7-0.8,1.3-1.8,2.8-1.5c0.5,0.1,1.1,0,1.6-0.1c0.9-0.2,1.1,0.5,1.2,1.1c0,0.1-0.7,0.5-1.1,0.5
	C315.1,381.2,313.6,381.1,312,381.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }}
              d="M339.7,327.3c-0.8-0.3-1.3-0.5-2-0.7c0.4-0.4,0.8-0.6,1.2-0.9C339.1,326.2,339.3,326.6,339.7,327.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1}
              fill={this.state.color25 ? this.state.color25 : 'transparent'} onClick={() => {
          this.setState({ color25: color, opacity25: opacity });
        }} d="M120.4,316.5c0-1.1,0-2,0-2.9c0.2-5.1,0.4-10.3,0.6-15.4c0-1,0-1.9,0.8-2.8c0.9-1,1.2-2.5,1.9-3.7
	c0.7-1.4,1.5-2.8,2.3-4.1c0.9-1.4,1.8-2.9,2.9-4.2c2.9-3.6,6.1-6.9,9.8-9.9c2.5-2,5.4-2.8,8.2-3.9c0.7-0.3,1.3-0.6,2-1
	c-0.5,0.8-0.9,1.6-1.2,2.3c0.1,0.1,0.3,0.3,0.4,0.4c1.7-1.2,3.4-2.4,5-3.6c0.1,1.4,0.2,3,0.3,4.5c0.1,2.5,0.3,4.9,0.3,7.4
	c0,2.3-0.2,4.5-0.2,6.8c0,3,0.2,6.1,0.4,9.1c0.2,2.8,0.4,5.6,0.6,8.5c0.2,3.8,0.3,7.6,0.5,11.3c0.1,1.2,0.3,2.4,0.4,3.7
	c0,0.3-0.2,0.9-0.4,1c-2.4,0.6-4.8,1.2-7.1,1.8c0.1-0.5,0.1-0.8,0.1-1.2c-0.5,0.1-1.2,0-1.4,0.2c-1.9,2.3-4.5,1.3-6.8,1.7
	c-0.1-0.1-0.2-0.3-0.3-0.4c1.6-2,3.3-4,4.9-6c0.1-0.2,0.3-0.4,0.2-0.6c0-0.2-0.4-0.4-0.5-0.4c-0.7,0.2-1.5,0.3-1.9,0.7
	c-1.5,1.7-3,3.5-4.4,5.3c-0.9,1.2-1.9,2.4-2.8,3.6c-1.7,2.3-3.5,4.6-5.2,6.8c-0.2,0.3-0.4,0.7-0.7,0.9c-0.6,0.6-0.6,1.3-0.2,1.9
	c1.3,2.2,2.5,4.5,3.8,6.7c0.1,0.2,0.2,0.4,0.3,0.6c-1,0.4-1.9,0.7-2.8,1.1c-0.9-1-1.9-2-2.7-3.1c-2.1-2.9-1.8-3.9-0.1-6.8
	c0.5-0.9,1.1-1.6,0.3-2.6c-0.1-0.2-0.1-0.6,0-0.9c0.2-0.9,0.9-1.6,0.2-2.6c-0.1-0.1,0.5-0.5,0.7-0.8c0.2-0.3,0.4-0.5,0.4-0.8
	c0.1-0.4-0.1-0.9,0-1.2c0.8-1.4,1.7-2.7,2.5-4.1c0.2-0.3,0.2-0.8,0.4-1.2c-1.8-0.5-3.2,0.1-3.8,1.5c-0.4,0.9-0.8,1.8-1.1,2.8
	c-0.2,0.6-0.5,1-1.3,0.7c-1.6-0.7-3.3-1.3-5-1.9c-0.3-0.1-0.5-0.5-0.5-0.8c-0.5-2.1,0-2.8,2.4-3.6c3.6-1.2,7.2-2.2,10.6-3.7
	c4.4-1.9,8.8-3.6,12.6-6.6c2.3-1.8,4.4-3.9,5.7-6.6c0.7-1.6,1.3-3.2,0.4-5.2c-1-2-1.2-4.3-1.7-6.5c-0.2-0.8-0.5-1.7-0.5-2.5
	c-0.2-2-0.4-4.1-0.3-6.1c0-1.2,0.6-2.3,1-3.5c0.1-0.4,0.3-0.8,0.3-1.1c0-0.3-0.2-0.6-0.4-0.9c-0.3,0.1-0.6,0.2-0.8,0.4
	c-3,3.4-3.5,7.4-2.7,11.6c0.7,3.6,1.6,7.1,2.3,10.6c0.2,0.9,0,2-0.3,2.9c-1.3,3.5-4,5.6-7.1,7.4c-5.3,3.1-11,5.5-16.9,7.3
	C123.4,315.3,122,315.9,120.4,316.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1}
              fill={this.state.color26 ? this.state.color26 : 'transparent'} onClick={() => {
          this.setState({ color26: color, opacity26: opacity });
        }} d="M131.3,345.1c0.2-0.4,0.3-0.8,0.5-1.2c0.8-1.1,2.1-1.1,3-0.1c1.4,1.5,2.2,3.5,2.7,5.5c0.6,2.4,0.4,4.9,0,7.3
	c-0.2,1-1.1,1.1-1.8,1.1c-0.2,0-0.6-0.8-0.5-1.3c0.3-3.2-0.4-6.2-2.1-9c-0.2-0.3-0.6-0.5-0.8-0.8c-0.2-0.3-1.1-0.3-0.6-1.1
	c0,0-0.6-0.5-0.9-0.8C131,344.9,131.1,345,131.3,345.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M135.1,342.2c3.9-3.4,9.7-3,11.7,2.7c0.5,1.5,0.7,3.1,1,4.6c0.3,1.6-0.9,2.3-1.9,3.3c0.1-0.5,0.3-1,0.2-1.5
	c0-1.2,0-2.5-0.2-3.7c-0.1-0.7-0.5-1.4-0.8-2.1c-0.8-1.5-2.2-1.8-3.7-1.9c-1,0-2.1,0.2-3.2,0.4c-0.2,0-0.5,0.1-0.7,0
	C136.8,343.4,136,342.8,135.1,342.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity28 ? this.state.opacity28 : 1}
              fill={this.state.color28 ? this.state.color28 : 'transparent'} onClick={() => {
          this.setState({ color28: color, opacity28: opacity });
        }} d="M152.6,335c-0.5,0.3-1,0.6-1.8,1.1c-0.6-1.3-1.3-2.6-1.8-3.8c-1-2.6-1.1-5.4-1.2-8.1c0-0.9,0.3-1.1,1.1-1.2
	c2.1-0.3,4.2-0.9,6.6-1.4c-0.8,1-1.4,1.9-2.1,2.6c-1,1-2.2,2-3.3,3c-0.3,0.3-0.5,0.9-0.3,1.2C150.7,330.5,151.6,332.7,152.6,335z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity29 ? this.state.opacity29 : 1}
              fill={this.state.color29 ? this.state.color29 : 'transparent'} onClick={() => {
          this.setState({ color29: color, opacity29: opacity });
        }} d="M149.6,340.9c1.1-3.3,3.4-4.8,6.1-6c-0.1,1.3-0.1,2.4-0.2,3.5c0,0.2-0.2,0.6-0.4,0.6
	C153.4,339.6,151.6,340.2,149.6,340.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity30 ? this.state.opacity30 : 1}
              fill={this.state.color30 ? this.state.color30 : 'transparent'} onClick={() => {
          this.setState({ color30: color, opacity30: opacity });
        }} d="M121.6,291.1c-0.5-2.1,0.1-3.7,1.4-5.1c2-2.2,4-4.5,6-6.8c0.5-0.6,1.2-1,2-1.4
	C127.1,281.7,124.4,286.3,121.6,291.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity31 ? this.state.opacity31 : 1}
              fill={this.state.color31 ? this.state.color31 : 'transparent'} onClick={() => {
          this.setState({ color31: color, opacity31: opacity });
        }} d="M160.7,317.2c-0.2-3.9-0.3-7.9-0.5-11.8c-0.1-1.2-0.1-2.5-0.2-3.7c0-2.8,0-5.7,0-8.5c0-2.2-0.1-4.5-0.1-6.7
	c-0.1-4.1-0.2-8.2-0.3-12.3c-0.1-3.3-0.3-6.5-0.4-9.8c-0.1-1.7,0.3-1.9,2-1.8c3,0.2,6.1,0.2,9.1,0.3c1.5,0,3,0.1,4.5,0.1
	c1.3-0.1,2.5-0.3,3.8-0.5c0,0.3,0.1,0.6,0,0.9c-0.2,0.7-0.5,1.3,0.6,1.5c0.2,0,0.3,0.6,0.4,0.9c0.5,2.5,1.5,4.8,3.9,6.1
	c1.5,0.8,1.9,2,1.8,3.6c-0.1,1.8,0,3.7,0,5.6c-1.5,0.3-1.9,1.2-1.9,2.5c-0.1,1.4-0.6,2.8-1,4.1c-0.3,1.4-0.6,2.8-1,4.1
	c-0.5,1.5-1,3-1.6,4.5c-2.4,5.5-5.9,10.2-10.4,14.3c-1.1,1-2.1,2-3.1,2.9c-1.3,1.1-2.6,2.2-4,3.1C161.8,317,161,317.1,160.7,317.2z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1}
              fill={this.state.color32 ? this.state.color32 : 'transparent'} onClick={() => {
          this.setState({ color32: color, opacity32: opacity });
        }} d="M164.4,343.2c-0.2-1-0.6-2-0.7-3.1c-0.2-1.6-1.1-2.8-2.1-3.7c-0.9-0.9-1.1-1.6-1-2.6c3.8-0.4,5.5,0.1,6.8,3
	c0.7,1.5,0.9,3.3,1.1,4.9c0.2,2-1.9,4.8-3.9,5.6c0.5-0.9,1.2-1.7,1.5-2.6c0.4-1.5,0.6-3,0.8-4.5c0-0.4-0.4-0.8-0.6-1.3
	c-0.1,0-0.2,0-0.4,0.1c-0.4,1.4-0.8,2.8-1.2,4.3C164.6,343.2,164.5,343.2,164.4,343.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity33 ? this.state.opacity33 : 1}
              fill={this.state.color33 ? this.state.color33 : 'transparent'} onClick={() => {
          this.setState({ color33: color, opacity33: opacity });
        }} d="M185.3,285.5c0.1,1.8,0.3,3.7,0.4,5.5c0.1,1.1,0.2,2.3,0.2,3.4c0,0.4-0.1,0.9-0.3,1.1c-1.8,2.1-3.7,4.1-5.8,6
	C182.7,296.5,184.3,291.1,185.3,285.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M157.2,227.5c1.4,2.1,3.3,1.7,5.2,1.3c-0.1,2-3.4,4.1-4.9,4.2c-0.8,0.1-1.5-0.3-2.2,0.4c-0.2,0.2-0.4,0.6-0.6,1
	c3.8,0.6,6.7-0.8,8.8-3.6c1.1-1.5,1.6-3.3,2.4-4.9c0.3-0.6-0.9-2.5-1.9-3.1c0.2-0.1,0.4-0.3,0.5-0.3c1.1,0.7,1.1-0.3,1.6-0.8
	c0.4-0.4,1-0.8,1.5-0.7c1.3,0.1,2.1-0.6,2.7-1.6c2.8-4.3,5.5-8.7,8.3-13c0.1-0.1,0.1-0.2,0.4-0.5c0.5,1.6,1,3,1.5,4.5
	c0.2,0.5,0.3,1.1,0.5,1.6c0.6,1.5,0.6,1.8-0.9,2.8c-0.4-0.8-0.9-1.5-1.2-2.4c-0.3-1-0.9-0.8-1.4-0.4c-0.4,0.3-0.8,0.8-1.1,1.3
	c-1.6,2.8-3.1,5.7-4.9,8.4c-0.8,1.2-2.2,2-3.4,3.1c1.8,0.9,2.3,0.6,3.2-0.6c0.5-0.7,1.4-1.4,2.2-1.7c2-0.9,4-1.7,5.3-3.6
	c1.5,0.3,2,0.2,2.1-1.1c0.1-1.5,1.2-2.2,2-3c0.4-0.4,0.9-0.8,1.5-1.3c0.6,3.3,1,6.5,0.3,9.8c-0.4,1.9-1.2,3.8-0.2,5.8
	c0.2,0.3,0.3,0.9,0.6,0.9c1.3,0.3,1.3,1.2,1.3,2.2c0,1.8,0.4,3.7,0.2,5.5c-0.3,3.6-1.6,6.9-4.3,9.5c-1.3,1.2-2.8,2.2-4.2,3.3
	c-0.2,0.1-0.4,0.2-0.5,0.2c0.1,0.1,0.1,0.3,0.2,0.4c0.9,0,1.8,0,2.9,0c-0.2,1.6-0.3,3.1-0.5,4.5c0,0.2-0.2,0.4-0.4,0.5
	c-0.3,0.1-0.6,0.2-0.9,0.2c-3.7,0.1-7.3,0.1-11,0.2c-1.4,0-2.9,0.3-4.3,0.2c-2.3-0.1-4.7-0.3-7.3-0.5c0.7-3.2,1.2-6.3,2-9.2
	c0.4-1.5,1.5-2.7,2.4-4.1c0.1-0.2,0.4-0.3,0.4-0.4c0.1-0.5,0.1-1,0.1-1.5c-0.5,0.2-1,0.2-1.3,0.5c-0.9,1.1-1.9,2.2-2.6,3.4
	c-0.5,0.7-1.1,0.8-1.6,0.5c-0.4-0.2-0.9-0.7-1-1.1c-0.3-2.1-0.4-4.2-0.5-6.3c-0.1-1.1-0.2-2.2-0.2-3.2c0-1.1,0.7-1.7,1.5-2.3
	c0.7-0.5,1.1-1.1,0.8-2C155.4,229.1,156.2,228.3,157.2,227.5z M180.4,230.4c0.1-0.1,0.1-0.2,0.2-0.4c-0.4-0.3-0.7-0.6-1.1-0.7
	c-1.2-0.5-2.6-0.7-3.6-1.4c-1.7-1.1-3.4-1-5-0.4c-1.8,0.7-3.4,1.9-5.1,2.8c0.7,0.3,1.3,0.3,1.9,0.4c0.8,0.2,1.3,0.2,1.6-0.8
	c0.4-1.3,3-1.9,4.3-1.5c2,0.7,4.1,1.3,6.1,2C179.8,230.6,180.1,230.5,180.4,230.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity35 ? this.state.opacity35 : 1}
              fill={this.state.color35 ? this.state.color35 : 'transparent'} onClick={() => {
          this.setState({ color35: color, opacity35: opacity });
        }} d="M173.7,220.5c1.1-1.9,2.2-3.8,3.5-5.9c0.5,0.9,0.9,1.5,1.2,2.1c-0.8,0.9-1.5,1.6-2.2,2.3
	c-0.7,0.7-1.4,1.2-2.1,1.9C174,220.7,173.8,220.6,173.7,220.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1}
              fill={this.state.color36 ? this.state.color36 : 'transparent'} onClick={() => {
          this.setState({ color36: color, opacity36: opacity });
        }} d="M89.4,377.4c7.3,1.3,14.3,3.2,21.3,2.9c0,2-0.1,3.8,0,5.5c0.2,3.6,0.6,7.2,0.7,10.7c0.1,3.7,0.1,7.3,0.1,11
	c0,0.3-0.3,0.8-0.5,1c-4.4,3.4-9.3,5.3-14.9,5.1c-1.9-0.1-3.8-0.3-5.6-0.4c-0.9-0.1-1-0.6-1-1.3c-0.1-5.5-0.3-10.9-0.4-16.4
	c-0.1-3.9,0-7.9,0-11.8c0-1.7,0.1-3.3,0.1-5C89.2,378.3,89.3,377.9,89.4,377.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity37 ? this.state.opacity37 : 1}
              fill={this.state.color37 ? this.state.color37 : 'transparent'} onClick={() => {
          this.setState({ color37: color, opacity37: opacity });
        }} d="M82.2,376.8c0.5,11.2,1.1,22.6,1.7,33.9c-0.1,0.1-0.2,0.1-0.3,0.2c-0.8-1.2-2.7-0.8-3-2.6
	c0-0.1-0.3-0.4-0.4-0.4c-2,0-2.7-1.8-3.9-2.8c-2.3-1.7-3.5-4.1-4.2-7c-0.8-3.1-0.1-6.2-0.1-9.3c0-0.2,0.1-0.3,0.1-0.5
	c-0.4-3.3,0.9-6,2.6-8.6c0.6-1,1.3-2,1.9-3c0.8-1.3,2-0.8,3-0.7C80.3,376.4,81.2,376.6,82.2,376.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity38 ? this.state.opacity38 : 1}
              fill={this.state.color38 ? this.state.color38 : 'transparent'} onClick={() => {
          this.setState({ color38: color, opacity38: opacity });
        }} d="M66.8,372.9c-0.3,8.4-0.7,16.6-1,24.7c-0.1,0.1-0.2,0.2-0.2,0.2c-1.7-1.8-3.4-3.5-5-5.3c-1.9-2.1-2-4.7-2.2-7.3
	c-0.3-3.4,0.2-6.7,0.7-10c0.2-1,0.4-2,0.6-3c0.1-0.7,0.6-0.7,1.1-0.6C62.8,372,64.9,372.5,66.8,372.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity39 ? this.state.opacity39 : 1}
              fill={this.state.color39 ? this.state.color39 : 'transparent'} onClick={() => {
          this.setState({ color39: color, opacity39: opacity });
        }} d="M121.2,382.1c4.1,0.3,8.3,0.6,12.7,0.9c-0.3,1.7-0.2,3.4-1.6,4.9c-1.7,1.9-3,4.2-5.5,5.2c-1,0.4-2,0.2-3.1-0.3
	c-1.7-0.7-1.5-1.9-1.6-3.3C121.8,387,121.5,384.5,121.2,382.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity40 ? this.state.opacity40 : 1}
              fill={this.state.color40 ? this.state.color40 : 'transparent'} onClick={() => {
          this.setState({ color40: color, opacity40: opacity });
        }} d="M122.6,394c-1.5,2.5-1.9,5.3-4.2,7.6c0-6.7,0-13.1,0-19.5c0.1,0,0.2,0,0.3-0.1c0.2,1.2,0.5,2.5,0.7,3.7
	c0.1,1,0,2,0.1,2.9C119.7,391,120.3,393,122.6,394z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity41 ? this.state.opacity41 : 1}
              fill={this.state.color41 ? this.state.color41 : 'transparent'} onClick={() => {
          this.setState({ color41: color, opacity41: opacity });
        }} d="M126.8,421.5c-2-0.5-3.9-1-5.8-1.4c-0.3-0.1-0.5-0.1-0.8-0.1c-2.1-0.1-2.1-0.1-1.9-2.2
	C121.6,417.3,125.8,418.1,126.8,421.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity42 ? this.state.opacity42 : 1}
              fill={this.state.color42 ? this.state.color42 : 'transparent'} onClick={() => {
          this.setState({ color42: color, opacity42: opacity });
        }} d="M81,411.6c-1,1.6-1.8,3-2.5,4.4c-0.5,0.8-0.6,0.8-2.4,0c1-1.5,1.8-3.1,2.9-4.6C79.2,411.1,80.2,411.5,81,411.6z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity43 ? this.state.opacity43 : 1}
              fill={this.state.color43 ? this.state.color43 : 'transparent'} onClick={() => {
          this.setState({ color43: color, opacity43: opacity });
        }} d="M111,419.5c0.7,2.5-0.7,3.4-4.7,2.8C107.9,421.3,109.4,420.4,111,419.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity44 ? this.state.opacity44 : 1}
              fill={this.state.color44 ? this.state.color44 : 'transparent'} onClick={() => {
          this.setState({ color44: color, opacity44: opacity });
        }} d="M74.7,374.8c-1,1.5-1.9,2.9-2.7,4.2c-0.1,0-0.2,0-0.3,0.1c0-1.3,0-2.7,0-4c0-0.2,0.5-0.4,0.7-0.4
	C73.2,374.6,73.9,374.7,74.7,374.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity45 ? this.state.opacity45 : 1}
              fill={this.state.color45 ? this.state.color45 : 'transparent'} onClick={() => {
          this.setState({ color45: color, opacity45: opacity });
        }} d="M122.4,352.2c0.5,0.3,1.1,0.7,1.6,1.1c0.1-0.1,0.2-0.1,0.3-0.2c-0.2-0.5-0.3-1.1-0.5-1.6c-0.3-1-0.2-2,0.7-2.7
	c0.9-0.7,1.7-0.1,2.5,0.4c2.1,1.4,1.9,3.5,2,5.6c0.1,1.4,0.1,2.8,0.2,4.2c0,0.5,0.2,1.1,0.6,1.4c1.6,1.4,1.9,3.3,2.4,5.2
	c0.6,2.8,1.4,5.7,2,8.5c-3.1,0-6,0-8.8,0c0.2-2.5,0.1-4.8-0.9-6.8c-0.9-1.8-1.3-3.7-1.5-5.6C122.8,358.4,122.6,355.3,122.4,352.2z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity46 ? this.state.opacity46 : 1}
              fill={this.state.color46 ? this.state.color46 : 'transparent'} onClick={() => {
          this.setState({ color46: color, opacity46: opacity });
        }} d="M118.2,372.8c-0.2,0-0.5,0.1-0.8,0.1c-3.2,0.1-6.5,0.3-9.7,0.3c-2.4,0-4.8-0.4-7.2-0.6c-1.3-0.1-2.7-0.3-4-0.5
	c-1.1-0.2-2.1-0.5-3.1-0.7c-1.1-0.2-2.2-0.2-3.2-0.4c-1.8-0.4-1.9-0.3-2-2.2c-0.2-3.2-0.4-6.3-0.6-9.5c-0.1-1.1,0.1-2.3,0.1-3.4
	c0-2.4-0.2-4.9-0.2-7.3c0-1.6,0.2-3.2,0.2-4.8c0-0.7-0.1-1.3-0.1-2c1.7,0.4,3.4,1,5.2,1.2c1.4,0.1,2.2,0.7,2.7,1.9
	c0.2,0.4,0.3,0.8,0.6,1c2.1,2,2.2,2.1,4.2-0.2c1.1-1.3,2.1-2.6,3.2-3.9c0.1,0.1,0.2,0.1,0.2,0.2c0,0.2-0.1,0.4-0.1,0.6
	c0.1,0.1,0.1,0.1,0.2,0.2c0.8-0.9,1.7-1.8,2.5-2.7c1.7,0.4,2.1,1.4,1.9,2.9c-0.4,3.1,0.4,6,2,8.7c1.2,2,2.3,4.2,3.3,6.3
	c0.6,1.2,1.5,1.8,2.7,2.1c0.6,0.2,1.2,0.3,1.2,1.2C117.7,365.1,118,368.9,118.2,372.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity47 ? this.state.opacity47 : 1}
              fill={this.state.color47 ? this.state.color47 : 'transparent'} onClick={() => {
          this.setState({ color47: color, opacity47: opacity });
        }} d="M106.4,307.7c-1.3-0.4-2.6-0.8-3.9-1c-0.5-0.1-1.1,0.1-1.6,0.1c0,0.2-0.1,0.3-0.1,0.5c1.2,0.4,2.4,0.8,3.6,1.2
	c1.7,0.5,2.8,1.7,4.4,2.8c-1.8,0-3.2,0-4.6,0c0.9,0.8,1.6,1.5,2.9,1.3c2-0.3,4.7,1.6,4.8,5.1c-1.6-1.6-3.4-1.9-5.4-1.8
	c-0.5,0-1.1,0.1-1.3,0.4c-0.8,1-1.8,0.9-2.9,0.7c-0.3,0-0.5,0.1-1.1,0.2c0.9,0.5,1.5,0.8,2,1.2c0.2,0.2,0.4,0.5,0.4,0.8
	c-0.1,1.8,0.7,3.3,1.6,4.8c0.1,0.2,0.3,0.4,0.3,0.6c0.2,0.7,0.5,1.1,1.4,1.2c0.8,0.1,1.6,0.9,2.4,1.2c1.3,0.4,2.7,0.5,4.1,0.7
	c0.2,0,0.5-0.3,0.6-0.5c0.3-1.1,0.5-2.3,0.8-3.5c0.3,0.8,0.8,1.7,0.8,2.7c0,3.1,0,6.2-0.3,9.3c-0.1,1.1-0.7,2.2-1.2,3.3
	c-0.1,0.2-0.3,0.4-0.5,0.4c-1.5,0.4-2.9,1.2-4.6,0.4c-0.5-0.3-1.2-0.2-1.7-0.3c0.6-2.6,1.7-4.9,0.3-7.3c-0.6-1.1-1.6-1.6-2.8-1.8
	c-0.9-0.2-1.2-0.6-1.3-1.5c-0.1-2.9-3.4-4.8-6-3.8c-1.1,0.4-1.1,1.1-1.6,1.8c-0.7,1.2-1.6,2.4-2.7,3.4c-1.9,1.8-3.9,3.6-4.8,6.2
	c-0.4,1.3-0.1,2.3,0.7,3.3c0.2,0.3,0.5,0.6,0.9,1.1c-0.8-0.3-1.4-0.5-2-0.7c-1-0.3-1.2-1-1.2-1.9c0-3.6,0-7.3,0-10.9
	c0-0.1,0-0.2,0.1-0.6c2.5,3,4.8,1.2,7.1-0.4c0.8-1.9,1-4.3,2.9-5.9c-0.1-0.5-0.2-1-0.3-1.6c-0.2-1.4-1.2-2.5-2.7-2.8
	c-1.7-0.3-3-0.1-5.3,0.8c0.8-1.6,4-3.6,5.7-3.6c0.8,0,1.5,0.1,2.3,0c0.5-0.1,1-0.5,1.4-1c-2.6,0-5.2-1.1-7.7,0.4
	c-0.1-0.1-0.2-0.2-0.3-0.3c0.7-1.1,1.3-2.2,2.1-3.2c0.4-0.5,1-1,1.7-1.3c1.9-0.9,2.8-2.6,3.8-4.2c0.9-1.3,1.8-2.6,3.3-3.3
	c0.1,0.6,0,1.2,0.2,1.6c0.7,1.5,1.2,1.7,2.7,0.8c0.5-0.3,1.1-0.6,2-1.2C104.3,304.1,104.8,306,106.4,307.7
	C106.5,307.6,106.4,307.7,106.4,307.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity48 ? this.state.opacity48 : 1}
              fill={this.state.color48 ? this.state.color48 : 'transparent'} onClick={() => {
          this.setState({ color48: color, opacity48: opacity });
        }} d="M97.5,344.5c-1-1.9-3-2.7-4.8-3.7c-0.4-0.2-0.8-0.3-1.2-0.6c-0.7-0.5-1-1.1-0.9-2.1c0.3-2.6,1.9-4,3.9-5.4
	c1.9-1.3,3.7-3,3.8-5.6c0.1-1.2,1.2-0.9,1.8-1.3c0.4,0.5,1,0.9,1,1.4c0.4,2.2,1.6,3.5,3.8,4.2c0.4,0.1,0.7,0.6,0.8,1
	c0.5,2.3,0,4.3-1.7,5.9C102,340.5,99.8,342.5,97.5,344.5z M98.5,332.9c-0.9-1.1-2.4-1.3-3-0.6c-0.8,1.1-0.4,3.1,0.8,3.8
	c0.3-0.5,0.6-1,1-1.5C97.7,334,98.1,333.5,98.5,332.9z M101.6,335.5c0.9-1.8,0.1-3.4-1.7-3.6c-0.3,0-0.7,0.4-0.9,0.7
	c-0.2,0.2-0.1,0.6-0.1,1C99.7,334.1,100.7,334.8,101.6,335.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity49 ? this.state.opacity49 : 1}
              fill={this.state.color49 ? this.state.color49 : 'transparent'} onClick={() => {
          this.setState({ color49: color, opacity49: opacity });
        }} d="M107.8,324.9c1-1.3,1.9-2.4,2.8-3.6c0.8,0.2,1.6,1,1.6,2c0,0.6-0.1,1.3-0.2,1.9c-0.1,1-0.7,1.4-1.7,1.1
	C109.5,325.8,108.6,325.3,107.8,324.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity50 ? this.state.opacity50 : 1}
              fill={this.state.color50 ? this.state.color50 : 'transparent'} onClick={() => {
          this.setState({ color50: color, opacity50: opacity });
        }} d="M90,320.8c0.2,1.2,0.2,2,0.5,2.8c0.3,0.8,0.7,1.5,1.1,2.3c-0.6,0.3-1.3,0.7-2,0.9c-0.3,0.1-1-0.2-1-0.4
	c-0.1-1.4-0.9-2.7-0.1-4.1C88.8,321.8,89.4,321.4,90,320.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity51 ? this.state.opacity51 : 1}
              fill={this.state.color51 ? this.state.color51 : 'transparent'} onClick={() => {
          this.setState({ color51: color, opacity51: opacity });
        }}
              d="M94.9,319.2c-1.9,0-3.4,0-4.9,0c1-1.7,2.9-2,3.9-1.6C94.3,317.7,94.5,318.6,94.9,319.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity52 ? this.state.opacity52 : 1}
              fill={this.state.color52 ? this.state.color52 : 'transparent'} onClick={() => {
          this.setState({ color52: color, opacity52: opacity });
        }} d="M111.3,319.7c-1.5-0.8-2.9-1.6-4.4-2.4C108.8,316.5,110.3,317.3,111.3,319.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1}
              fill={this.state.color53 ? this.state.color53 : 'transparent'} onClick={() => {
          this.setState({ color53: color, opacity53: opacity });
        }} d="M65.6,359c0.1,2.5,0.2,5.1,0.4,7.6c-1.5-0.3-2.9-0.5-4.5-0.9c1.2-2.3,2.5-4.6,3.7-7
	C65.3,358.9,65.4,358.9,65.6,359z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity54 ? this.state.opacity54 : 1}
              fill={this.state.color54 ? this.state.color54 : 'transparent'} onClick={() => {
          this.setState({ color54: color, opacity54: opacity });
        }} d="M82.2,370.7c-2.2-0.3-4.2-0.4-6.2-0.8c-1.3-0.2-2.5-0.7-3.8-1c-0.9-0.2-1.3-0.6-1.2-1.7
	c0.1-1.9-0.2-3.8-0.3-5.7c0-0.2,0-0.3,0.2-0.5c0,0.6,0.1,1.2,0.2,2.1c0.6-0.9,1-1.6,1.5-2.3c1.1-1.4,2.1-2.8,3.4-4.1
	c1-1,2.1-1.7,3.3-2.5c1.1-0.8,2-1.6,1.6-3.2C81.4,357.6,82.5,364,82.2,370.7z M81,356.9c-0.5,0.6-1,1-1.3,1.6
	c-0.5,0.9-1,1.8-1.4,2.8c-0.2,0.5,0,1.2,0.1,1.8c0.5-0.3,1-0.5,1.5-0.8c0.9-0.7,1.8-1.3,1.6-2.7C81.3,358.7,81.2,357.8,81,356.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity55 ? this.state.opacity55 : 1}
              fill={this.state.color55 ? this.state.color55 : 'transparent'} onClick={() => {
          this.setState({ color55: color, opacity55: opacity });
        }} d="M77.4,347.5c0.8,1.3,1.5,2.2,2.2,3.3c-3.5,1.9-6.9,4.1-8.8,8c0-3,0-5.9,0-8.8c0.1,0,0.1,0,0.2,0
	c0,0.7,0.1,1.3,0.1,2C73.2,350.5,75.3,349,77.4,347.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity56 ? this.state.opacity56 : 1}
              fill={this.state.color56 ? this.state.color56 : 'transparent'} onClick={() => {
          this.setState({ color56: color, opacity56: opacity });
        }}
              d="M171.9,43.1c-1.5-1.5-3.1-2.9-4.7-4.4c1.6-1.8,2.6-1.9,4.1-0.5C172.3,39.2,172.6,41.5,171.9,43.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity57 ? this.state.opacity57 : 1}
              fill={this.state.color57 ? this.state.color57 : 'transparent'} onClick={() => {
          this.setState({ color57: color, opacity57: opacity });
        }}
              d="M166.2,39.7c1.5,1.1,2.8,2.1,4.1,3.1c-1.9,0.6-3.6,1.1-5.4,1.7C164.3,43,165.2,41.5,166.2,39.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity58 ? this.state.opacity58 : 1}
              fill={this.state.color58 ? this.state.color58 : 'transparent'} onClick={() => {
          this.setState({ color58: color, opacity58: opacity });
        }} d="M168.8,44.3c0.8,0,1.5,0.2,1.4,1.1c-0.1,0.4-0.9,0.7-1.4,0.9c-0.3,0.1-0.7-0.1-1-0.2c-0.3-0.2-0.6-0.5-0.9-0.8
	c0.3-0.2,0.5-0.5,0.8-0.6C168.1,44.4,168.5,44.4,168.8,44.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity59 ? this.state.opacity59 : 1}
              fill={this.state.color59 ? this.state.color59 : 'transparent'} onClick={() => {
          this.setState({ color59: color, opacity59: opacity });
        }} d="M343.8,420.6c0.8,0.9,1.4,2,2.3,2.6c1.1,0.8,2.1,0.5,3-0.4c-0.6,2.6-1.4,3-3.8,2c-0.8-0.3-1.6-0.8-2.3-1.4
	c-0.7-0.6-1-1.5,0.1-2.1C343.5,421.1,343.6,420.8,343.8,420.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity60 ? this.state.opacity60 : 1}
              fill={this.state.color60 ? this.state.color60 : 'transparent'} onClick={() => {
          this.setState({ color60: color, opacity60: opacity });
        }} d="M317,301.3c0.2,0,0.4-0.1,0.5,0c1.1,1,0.8,4.4-0.4,5.3c-1.7,1.3-3.9,0.8-5.1-1.1c-0.7-1.1-1-2.3-0.9-3.6
	c0.1-0.7,0.3-1,0.9-0.9c0.1,1.1,0,2.1,0.3,3c0.2,0.6,1.1,1,1.7,1.4c1,0.6,2.1,0.6,2.9-0.3C317.7,304.5,317.7,302.6,317,301.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity61 ? this.state.opacity61 : 1}
              fill={this.state.color61 ? this.state.color61 : 'transparent'} onClick={() => {
          this.setState({ color61: color, opacity61: opacity });
        }} d="M312.1,300c1.1-2.2,4.1-2,4.8,0C315.4,300,313.8,300,312.1,300z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity62 ? this.state.opacity62 : 1}
              fill={this.state.color62 ? this.state.color62 : 'transparent'} onClick={() => {
          this.setState({ color62: color, opacity62: opacity });
        }} d="M309.2,383.8c-0.4,1.1-0.7,2.1-1.1,2.9c-0.3,0.5-0.9,0.9-1.3,1.3c-0.2-0.1-0.3-0.2-0.5-0.3
	c0.2-0.9,0.5-1.8,0.7-2.8c0.1-0.6,0.3-1.2,0.4-1.7c-0.8-0.1-1.6-0.5-2.2-0.3c-1.9,0.7-3.6,1.6-5.4,2.4c-1.2,0.5-2.2,1.5-4,1.2
	c0.3-0.4,0.4-0.8,0.7-1.1c2-1.8,4.3-2.8,7-3.1c1.6-0.1,3.2-0.3,4.7-0.6c0.5-0.1,0.8-0.7,1.4-0.9c-0.5,0.7-1,1.5-1.6,2.3
	C308.5,383.4,308.9,383.6,309.2,383.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity63 ? this.state.opacity63 : 1}
              fill={this.state.color63 ? this.state.color63 : 'transparent'} onClick={() => {
          this.setState({ color63: color, opacity63: opacity });
        }}
              d="M339.7,383.5c-0.2,1.3-1.2,1.6-2.3,1.7c-1.3,0.1-2.5,0.1-3.8,0.2C334.2,383.8,337.6,382.8,339.7,383.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity64 ? this.state.opacity64 : 1}
              fill={this.state.color64 ? this.state.color64 : 'transparent'} onClick={() => {
          this.setState({ color64: color, opacity64: opacity });
        }}
              d="M111.5,346.4l3.2-3.4c0,0,1,2.1,0,6.4c0,0-1.2-1.9-2.5-2.2C110.6,347,111.5,346.4,111.5,346.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity65 ? this.state.opacity65 : 1}
              fill={this.state.color65 ? this.state.color65 : 'transparent'} onClick={() => {
          this.setState({ color65: color, opacity65: opacity });
        }}
              d="M110,347.5c0,0,1.2,0.6,1.8,0.5s1.6,1.9,3.4,1.9v2c0,0-1.5,2.3-2,2.1S110.2,349.2,110,347.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity66 ? this.state.opacity66 : 1}
              fill={this.state.color66 ? this.state.color66 : 'transparent'} onClick={() => {
          this.setState({ color66: color, opacity66: opacity });
        }} d="M116.6,354.3l0.6,4.5c0,0-0.8,0.5-2-1.1s0.3-1.8,0.7-2.5S116.2,353.8,116.6,354.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity67 ? this.state.opacity67 : 1}
              fill={this.state.color67 ? this.state.color67 : 'transparent'} onClick={() => {
          this.setState({ color67: color, opacity67: opacity });
        }} d="M113.2,298l0.6,16.9c0,0-2-2-3.3-3.3c-1.9-1.9,1.4-5.3,1.3-5.8c-0.1-0.5-1.2-1.5-1.6-1.5
	c-0.5,0-4.1,1.1-3.5-0.4s1.3-1.1,2.2-1.1c0.9,0,1.7-0.3-0.5-1.1c-1.3-0.5,0.5-2.3,0.5-2.3l-1.3-0.6c0,0,4.1-2.8,5.2-1.9
	S113.2,297.3,113.2,298z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity68 ? this.state.opacity68 : 1}
              fill={this.state.color68 ? this.state.color68 : 'transparent'} onClick={() => {
          this.setState({ color68: color, opacity68: opacity });
        }}
              d="M106.5,307.6c0,0,1.5-0.9,1.8-1.3c0,0-0.1,3-0.5,2.7c-0.4-0.3-1.3-1.3-2.2-1.5s0.9,0.2,0.9,0.2L106.5,307.6z" />
      </SvgIcon>
    )
  }
};