import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_57_2 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"38.889 312.058 522.84 152.709"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }}
              d="M493.3,387.9c0.2-6.2,0.3-11.5,2.5-16.4c1.4-3.2,2.9-6.4,4.7-9.4c1.9-3.1,4.7-5.3,7.6-7.5 c2-1.6,4.3-2.2,6.7-2.7c2.4-0.5,4.8-0.9,7.3-0.1c1.7,0.6,3.7,0.5,5.3,1.1c1.8,0.8,3.5,2,5.2,3.1c4.5,3.1,8,7.1,10.5,11.9 c1.2,2.4,1.6,5.2,2.5,7.8c1,2.8,0.2,5.5,0.4,8.3c0.4,4.8-1.5,9.2-3.3,13.5c-1.9,4.5-4,8.9-7.5,12.5c-2.9,3-6.3,5.3-10.1,7 c-4.3,2-8.7,3.7-13.1,5.3c-1.2,0.4-2.5,0.4-3.8,0.5c-0.3,0-0.7-0.1-0.8-0.3c-1-1.3-1.9-2.6-2.8-3.9c-1.1-1.6-2.4-3-3.2-4.7 c-1.5-2.9-2.7-6-4-9c-1.6-3.8-3.2-7.7-3.6-11.9C493.6,391.3,493.4,389.1,493.3,387.9z M531,385c0.4,1.8,0.6,3.7,1.2,5.5 c0.5,1.6,1.7,2.4,3,2.3c1.5-0.1,2.4-1.1,2.9-2.4c1.2-3.5,1.3-7.1,0.1-10.7c-0.5-1.6-1.7-2.5-2.9-2.5c-1.6,0.1-2.6,1.1-3,2.5 C531.6,381.5,531.4,383.2,531,385z M514.2,385c-0.4-1.8-0.6-3.6-1.1-5.3c-0.4-1.3-1.3-2.3-2.8-2.3c-1.5-0.1-2.6,0.9-3.1,2.3 c-1.4,3.7-1.3,7.4,0,11.1c0.5,1.3,1.4,2.2,2.9,2.1c1.4-0.1,2.4-0.9,2.8-2.3C513.5,388.8,513.8,386.9,514.2,385z M505.4,380.5 c0-0.6,0-1.1,0-1.6c0.1-1.1-0.3-1.5-1.4-1.4c-1,0.1-2,0.1-3,0c-1-0.1-1.4,0.4-1.4,1.3c-0.1,1.8-0.3,3.7-0.5,5.5 c-0.1,1,0.3,1.3,1.3,1.2c2.2-0.2,3.2,1.3,2.4,3.4c-0.4,1-1.2,1.3-2.1,0.8c-0.4-0.2-0.8-0.6-1.3-0.8c-0.9,1.5-0.9,2.7-0.3,3.2 c1.1,0.9,3,1.1,4.2,0.4c2-1.3,2.5-3.3,2.4-5.5c-0.1-2-0.9-3.7-3.1-4.2c-0.8-0.2-0.9-0.8-0.7-1.4c0.1-0.3,0.7-0.7,1.1-0.7 C503.7,380.4,504.5,380.5,505.4,380.5z M527.4,389.7c0.8-1.4,1.5-2.4,2-3.5c1.1-2.3,1.6-4.7,0.4-7.1c-0.9-1.7-2.9-2.3-4.6-1.3 c-1.7,0.9-1.8,2.1-0.4,4.3c0.2-0.3,0.3-0.6,0.6-0.8c0.5-0.3,1.1-0.6,1.7-0.9c0.3,0.7,0.8,1.4,0.7,2c-0.2,1.2-0.6,2.4-1.2,3.5 c-0.7,1.4-1.8,2.6-2.6,4c-0.4,0.7-0.5,1.6-0.3,2.4c0,0.2,1.1,0.4,1.8,0.4c1.4,0.1,2.9,0,4.3,0c1.2,0,1-0.9,1-1.6 c0-0.7,0.1-1.4-1-1.4C529,389.8,528.3,389.7,527.4,389.7z M519.1,387.4c1,0,1.9,0,2.9,0c0.8,0,0.9-0.4,0.9-1.1 c0-0.7-0.2-1.1-0.9-1.1c-1.8,0-3.7,0-5.5,0c-0.7,0-1.1,0.3-1.1,1.1c0,0.7,0.3,1.1,1.1,1.1C517.4,387.4,518.2,387.4,519.1,387.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }}
              d="M506.8,429c1.1,1.9,1.7,4.2,3.7,5.6c0.1-0.1,0.2-0.1,0.3-0.2c-0.8-1.1-1.6-2.2-2.2-3.4c-0.6-1.2-1-2.5-1.4-3.8 c0.1,0,0.2-0.1,0.3-0.1c0.9,0.8,1.9,1.5,2.6,2.5c0.8,1.1,1.5,2.3,2.1,3.6c0.6,1.2,0,2.1-1,2.8c-0.9,0.6-1.9,1.1-3.1,1.8 C507.7,434.7,507.2,431.8,506.8,429z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }}
              d="M505.6,421.8c0.8,0.9,1.5,1.8,2.3,2.7c-0.5,0.2-1.1,0.7-1.4,0.6c-0.7-0.4-1.4-1-1.9-1.7c-0.2-0.3,0.2-1,0.4-1.5 C505.2,421.9,505.4,421.9,505.6,421.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }}
              d="M113.2,373.8c0,2.8,0.3,5.7-0.1,8.5c-0.6,4.8-2.6,9.2-5.6,12.9c-3.8,4.7-8.2,8.7-13.5,11.6 c-4.2,2.2-8.2,4.6-12.3,6.9c-1,0.6-2.1,0.4-2.9-0.4c-2.6-2.9-4.8-6-6.5-9.6c-2.9-5.9-4.1-12.2-4.9-18.7c-0.7-5.5,0.2-10.8,1.7-16.1 c0.8-2.8,1.7-5.7,3.2-8.2c1.5-2.6,3.7-4.8,5.7-7c1.8-2,4.2-3.2,7-3.7c1.8-0.3,3.5-0.7,5.3-1c0.9-0.2,1.8-0.5,2.8-0.6 c2.4-0.2,4.3,1,6.3,2.1c3.1,1.7,6,3.6,8.3,6.4c2.4,3,3.8,6.5,4.6,10.2c0.5,2.2,1,4.5,1.5,6.7C113.5,373.8,113.4,373.8,113.2,373.8z M112,379.1c-0.4-1.9-0.6-3.6-1-5.2c-0.4-1.4-1.3-2.5-2.9-2.5c-1.7,0-2.6,1-3.1,2.4c-1.3,3.6-1.3,7.3,0.1,10.9 c0.5,1.3,1.4,2.2,2.8,2.2c1.4,0,2.5-0.9,2.8-2.2C111.3,382.9,111.6,380.9,112,379.1z M76,379.3c0.3,1.7,0.6,3.4,1.1,5.1 c0.4,1.4,1.3,2.4,2.9,2.4c1.5,0,2.6-1,2.9-2.4c0.4-2.2,0.8-4.4,0.8-6.6c0-1.7-0.5-3.6-1.3-5.1c-0.9-1.9-3.3-1.9-4.6-0.2 c-0.4,0.5-0.8,1.2-0.9,1.8C76.5,376,76.3,377.6,76,379.3z M97.7,376.2c0.3-0.5,0.4-0.9,0.7-1.1c0.5-0.3,1.1-0.7,1.5-0.6 c0.4,0.1,0.6,0.9,0.7,1.4c0.2,1.6-0.5,3-1.3,4.2c-0.8,1.3-1.8,2.5-2.5,3.8c-0.3,0.6-0.3,1.5-0.2,2.3c0,0.2,1,0.4,1.6,0.4 c1.5,0,3,0,4.6,0c1.2,0,0.9-0.9,0.9-1.5c0-0.7,0.1-1.4-0.9-1.4c-0.7,0-1.5,0-2.4,0c0.7-1.2,1.3-2.2,1.8-3.1c1.2-2.3,1.8-4.6,0.8-7.1 c-0.8-1.9-2.8-2.6-4.6-1.7C96.5,372.6,96.3,373.7,97.7,376.2z M71.1,375.7c0,3.5,0,6.7,0,9.9c0,0.9,0.4,1.1,1.2,1.1 c0.7,0,1.5,0,1.5-1c0-4.4,0-8.8,0-13.2c0-0.3-0.6-0.8-0.9-0.9c-1.6-0.3-2.5,0.9-3.2,1.9c-0.5,0.6,0,1.9,0,3.1 C70.2,376.2,70.6,376,71.1,375.7z M89.6,375.5c0,2.3,0,2.3-2.2,2.5c0,0-0.1,0-0.1,0c-1,0-0.8,0.8-0.8,1.4c0,0.6-0.2,1.2,0.8,1.3 c0.7,0,1.8-0.1,2.1,0.3c0.4,0.5,0.2,1.5,0.2,2.3c0,0.4,0,0.7,0,1.1c-0.1,0.9,0.2,1.3,1.2,1.3c0.9,0,1.2-0.5,1.1-1.3 c0-0.4,0-0.9,0-1.3c0-2.3,0-2.3,2.3-2.4c0.7,0,0.9-0.4,0.8-1c-0.1-0.6,0.5-1.6-0.8-1.8c-0.8-0.1-1.8,0-2.2-0.4 c-0.4-0.5-0.1-1.5-0.2-2.3c0-0.3,0-0.6,0-1c0.1-0.9-0.3-1.3-1.2-1.3c-1,0-1.2,0.4-1.1,1.3C89.6,374.7,89.6,375.1,89.6,375.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }}
              d="M82.8,423.1c-0.1-1.3-0.4-2.6-0.3-3.9c0-0.8,0.3-1.5,0-2.3c0.5-0.2,1.1-0.6,1.3-0.4c2.1,1.2,3.6,2.9,4.1,5.3 c0.3,1.3-3.2,4.4-4.5,3.9c-0.3-0.1-0.7-0.7-0.8-1.1c-0.1-0.5,0-1,0-1.5C82.7,423.1,82.8,423.1,82.8,423.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M81.2,416.5c-0.7-1.3-0.1-1.5,1.1-1.7C81.9,415.5,81.6,415.9,81.2,416.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }}
              d="M349.7,379.3c-0.2,3.9-0.1,7.9-0.7,11.8c-0.8,5-2.9,9.5-5.5,13.8c-2.2,3.6-5.3,6.4-8.7,8.8 c-3.3,2.4-6.7,4.6-10.1,6.7c-1,0.6-2.2,1.1-3.3,1.4c-0.6,0.1-1.4-0.2-1.9-0.5c-3.5-2.5-6-5.7-8.1-9.5c-3.5-6.1-5.8-12.7-7.5-19.5 c-0.8-3.3-1.9-6.4-1.7-9.9c0.2-3.8,1.1-7.5,2-11.2c1.2-4.8,3.7-8.8,6.7-12.6c2.9-3.7,6.7-6.3,11.6-6.7c1-0.1,2-0.5,2.9-0.4 c1.9,0,3.9-0.1,5.7,0.4c1.6,0.5,3.2,1.6,4.6,2.6c2.2,1.5,4.3,3.2,6.3,4.9c5.3,4.3,7.3,10.2,7.6,16.8c0,1,0,2.1,0,3.1 C349.6,379.3,349.6,379.3,349.7,379.3z M321.4,383.9c-0.3-1.7-0.6-3.5-1.1-5.3c-0.4-1.3-1.3-2.1-2.7-2.2c-1.4-0.1-2.3,0.7-2.9,1.9 c-1.1,2.2-1.3,4.6-1.1,7c0.2,1.7,0.6,3.4,1.2,4.9c1,2.2,3.8,2.1,5,0.1c0.3-0.5,0.5-1,0.7-1.5C320.9,387.2,321.1,385.6,321.4,383.9z M337.5,383.9c0.4,1.9,0.7,3.7,1.2,5.5c0.4,1.4,1.3,2.4,2.9,2.4c1.6,0,2.5-1.1,3-2.4c1.2-3.6,1.2-7.2,0-10.8 c-0.4-1.3-1.3-2.2-2.8-2.3c-1.5,0-2.5,0.9-3,2.2C338.2,380.3,337.9,382.1,337.5,383.9z M310.9,379.5c-1.3,4.1-2.6,8-3.9,12 c2.7,0.2,2.7,0.2,3.4-1.9c1.1-3.4,2.2-6.8,3.2-10.3c0.2-0.5,0.2-1.1,0.2-1.7c0.1-0.7-0.2-1.1-1-1.1c-1.7,0-3.4,0.1-5,0 c-1,0-1.1,0.6-1,1.3c0.1,0.7-0.4,1.7,1,1.7C308.7,379.5,309.7,379.5,310.9,379.5z M333.4,380.6c0,3.5,0,6.7,0,9.9c0,0.9,0.5,1,1.2,1 c0.7,0,1.4,0.2,1.4-0.9c0-4.4,0-8.9,0-13.3c0-0.3-0.4-0.7-0.7-0.8c-1.6-0.3-2.7,0.9-3.5,1.9c-0.4,0.6,0,1.8,0.1,3 C332.6,381.1,332.9,380.9,333.4,380.6z M326.5,386.4C326.5,386.4,326.5,386.4,326.5,386.4c1,0,1.9,0,2.9-0.1c0.3,0,0.7-0.6,0.8-0.9 c0.2-0.6,0-1.1-0.8-1.1c-1.9,0-3.8,0-5.7,0.1c-0.3,0-0.8,0.7-0.8,1.1c0,0.3,0.5,0.9,0.8,0.9C324.5,386.5,325.5,386.4,326.5,386.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }}
              d="M318.4,425c0.5,1.2,1,2.4,1.5,3.6c0.3,0.7,0.6,1.6,1.8,1.4c-0.6-1.3-1.2-2.6-1.8-3.9c0.2-0.1,0.3-0.2,0.5-0.3 c0.9,1.2,2.6,1.3,3,3.1c0.2,1,0.1,1.6-0.6,2.3c-0.2,0.2-0.5,0.4-0.7,0.7c-1.3,1.3-2.5,1-2.9-0.8c-0.5-1.9-0.7-3.9-1.1-5.8 C318.2,425.1,318.3,425,318.4,425z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }}
              d="M319.8,423.4c-0.5,0.3-0.8,0.5-1.1,0.6c-0.8,0.1-1.9-0.9-1.9-1.6c0-0.2,0.5-0.7,0.6-0.6 C318.1,422.2,318.8,422.8,319.8,423.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }}
              d="M359.1,382.2c0-2.9-0.3-5.9,0.1-8.7c0.5-3.9,1.5-7.6,3.1-11.3c2.1-4.9,5.3-9,9.5-12.1c2.3-1.7,4.9-3.1,7.9-3.5 c2.5-0.3,4.9-1.3,7.4-0.9c0.4,0.1,0.9,0,1.3,0.2c1.7,0.6,3.3,1.3,5,1.9c4,1.5,7.3,3.8,10.1,7.2c3.3,4,4.8,8.8,6.2,13.5 c2.4,7.7,1.2,15.4-1.4,23c-2,5.9-5.6,10.7-10.3,14.7c-3.2,2.8-6.3,5.6-10.1,7.6c-2,1-4,2-6.1,2.8c-1.2,0.5-2.4,0.9-3.6,1.2 c-2.4,0.6-4.5-0.4-5.8-2.2c-2.3-3.1-4.5-6.3-6.5-9.6c-1.2-2.1-2.1-4.4-3-6.6c-1.7-4.7-3.1-9.6-3.6-14.6c-0.1-0.8,0-1.7,0-2.5 C359.2,382.2,359.1,382.2,359.1,382.2z M401.5,380.2c0.4,2,0.7,3.9,1.2,5.7c0.4,1.3,1.3,2.2,2.8,2.2c1.5,0,2.5-0.9,3-2.2 c1.3-3.7,1.5-7.5,0-11.2c-0.5-1.2-1.3-2.1-2.8-2.1c-1.4,0-2.4,0.8-2.8,2C402.2,376.5,401.9,378.4,401.5,380.2z M366.8,380.2 c0.4,2,0.7,3.9,1.2,5.6c0.4,1.3,1.3,2.3,2.9,2.3c1.6,0,2.5-1,3-2.4c1.2-3.6,1.2-7.3,0-10.9c-0.4-1.2-1.3-2.1-2.7-2.2 c-1.4,0-2.5,0.7-2.9,2C367.6,376.5,367.2,378.4,366.8,380.2z M382.8,380.2c-0.4-1.8-0.6-3.6-1.1-5.3c-0.3-1.3-1.3-2.1-2.7-2.2 c-1.5-0.1-2.5,0.7-3,2c-1.5,3.9-1.6,7.8,0.1,11.7c0.4,1.1,1.2,1.8,2.5,1.8c1.3,0,2.2-0.6,2.8-1.7 C382.5,384.5,382.6,382.4,382.8,380.2z M394.7,384.2c-1.3,2.9-0.7,3.8,2.2,3.9c0.9,0,2.1-0.5,2.7-1.2c2.6-2.7,1.6-7.9-1.6-8.9 c-0.8-0.2-1.1-0.6-0.8-1.2c0.2-0.4,0.7-0.7,1.1-0.9c0.4-0.1,0.9-0.1,1.4,0c1.2,0.1,1.1-0.7,1.1-1.5c0-0.8,0.1-1.6-1.1-1.5 c-1.2,0.1-2.4,0-3.6,0c-0.7,0-1.1,0.2-1.2,1c-0.2,2.1-0.4,4.2-0.6,6.3c-0.1,0.8,0.5,0.7,1.1,0.6c2.2-0.3,3.2,0.8,2.7,3 c-0.3,1.2-1.3,1.7-2.4,1.1C395.4,384.7,395.1,384.4,394.7,384.2z M363.4,376.9c0,0.7,0,1.1,0,1.5c0,2.8,0,5.7,0,8.5 c0,0.9,0.5,1,1.2,1c0.6,0,1.4,0.2,1.4-0.8c0-4.5,0-8.9,0-13.4c0-0.3-0.4-0.7-0.6-0.8c-1.6-0.3-2.7,0.8-3.5,1.9c-0.4,0.6,0,1.9,0.1,3 C362.6,377.5,362.9,377.3,363.4,376.9z M388.2,382.7c0.9,0,1.8,0,2.8,0c0.7,0,1-0.3,1-1c0-0.7-0.2-1.1-1-1.1c-1.8,0-3.6,0-5.4,0 c-0.7,0-1,0.3-1,1c0,0.8,0.3,1.1,1,1.1C386.5,382.6,387.4,382.7,388.2,382.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }}
              d="M371.8,419.5c-0.3,1.5-2.6,2.3-1.9,4.7c0.6-0.8,0.9-1.3,1.3-1.7c0.5-0.5,1-0.9,1.5-1.3 c-0.1,0.6-0.1,1.4-0.4,1.9c-0.9,2-2.6,3-4.8,3.3c-1.1,0.2-1.7-0.4-2.1-1.4c-0.1-0.5-0.2-0.9-0.3-1.4c-0.2-1.5,0.4-2.3,1.7-2.7 C368.5,420.5,370.1,420,371.8,419.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }}
              d="M374.6,420.7c-0.8-0.6-1.6-1.1-2.4-1.7c-0.1-0.1-0.1-0.7,0-0.8c0.3-0.2,0.8-0.4,1-0.3c0.8,0.3,1.5,0.7,2.2,1.2 c0.2,0.1,0.3,0.7,0.2,0.9C375.4,420.3,375,420.4,374.6,420.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }}
              d="M155.8,349.7c4,0.1,7.7,1.9,10.9,4.6c2.1,1.8,4,3.8,5.6,6c2.2,3.1,3.4,6.7,4.1,10.5c0.9,4.9,0.4,9.6-0.9,14.3 c-0.7,2.5-1.9,4.8-2.9,7.1c-0.4,0.9-0.5,2-1.1,2.8c-2.2,2.9-4.3,6-6.7,8.7c-3.1,3.5-7,6.1-11.1,8.3c-2.7,1.5-5.6,2-8.6,2.3 c-0.4,0-1-0.2-1.4-0.6c-1.8-1.6-3.5-3.2-5.2-4.9c-3.3-3.3-5.7-7.2-6.9-11.7c-1-4-2-8.1-1.9-12.1c0.1-4.4,1.3-8.9,2.4-13.3 c0.9-3.8,2.1-7.6,4.4-10.8c2.4-3.4,5.1-6.5,8.8-8.5c2.1-1.1,4.6-1.5,6.9-2.1C153.4,350,154.4,349.9,155.8,349.7z M147.8,382.6 c-0.4-1.9-0.7-3.8-1.2-5.6c-0.4-1.3-1.3-2.1-2.7-2.1c-1.5,0-2.4,0.8-2.9,2.1c-1.5,3.7-1.4,7.4-0.1,11.1c0.4,1.3,1.3,2.3,2.9,2.3 c1.6,0,2.5-1,2.9-2.3C147.1,386.3,147.4,384.5,147.8,382.6z M174.9,382.2c-0.3-1.6-0.6-3.5-1.2-5.3c-0.4-1.2-1.3-2-2.7-2 c-1.4,0-2.4,0.8-2.8,2c-0.5,1.3-0.8,2.6-1,3.9c-0.3,2.7-0.2,5.4,1.1,7.8c1.2,2.3,3.8,2.3,5.2,0.1c0.3-0.5,0.6-1,0.7-1.5 C174.4,385.7,174.6,384.2,174.9,382.2z M133.5,379.9c0.3-0.5,0.4-1,0.7-1.2c0.4-0.3,1.2-0.8,1.5-0.7c0.4,0.2,0.9,1.1,0.8,1.5 c-0.3,1.5-0.7,2.9-1.4,4.2c-0.7,1.3-1.7,2.5-2.5,3.8c-0.3,0.6-0.4,1.4-0.3,2.1c0,0.2,1,0.4,1.5,0.4c1.5,0.1,3,0,4.5,0 c0.9,0,1.1-0.5,1-1.2c-0.1-0.7,0.4-1.7-1-1.7c-0.7,0-1.5,0-2.3,0c0.7-1.2,1.4-2.3,2-3.4c1.1-2.3,1.6-4.6,0.5-7 c-0.8-1.8-2.8-2.5-4.5-1.5C132.1,376.4,132,376.7,133.5,379.9z M153.2,386.6C153.2,386.6,153.2,386.6,153.2,386.6c0,0.4,0,0.8,0,1.2 c0,0.6-0.1,1.3,0.9,1.4c1.2,0.1,1.6-0.3,1.5-1.5c-0.1-0.9-0.2-1.9,0-2.7c0.4-1.3,1.7-0.3,2.4-0.8c0.4-0.3,0.6-1,0.6-1.5 c0-0.3-0.4-0.9-0.8-1c-0.7-0.3-1.8,0-2.2-0.5c-0.4-0.5-0.2-1.5-0.2-2.3c0-0.3,0-0.6,0-0.8c0-0.8,0-1.5-1.1-1.5 c-1.2,0-1.3,0.6-1.3,1.5c0,0.6,0,1.2,0,1.8c0,1.7,0,1.8-1.8,1.7c-1,0-1.4,0.2-1.4,1.3c0,1.3,0.6,1.5,1.6,1.4 c1.2-0.1,1.7,0.3,1.6,1.5C153.2,386.1,153.2,386.3,153.2,386.6z M162.8,379.2c0,0.6,0,1,0,1.4c0,2.8,0,5.7,0,8.5 c0,0.8,0.4,1.1,1.1,1c0.6-0.1,1.5,0.3,1.5-0.8c0-4.4,0-8.9,0-13.3c0-0.3-0.5-0.8-0.8-0.8c-1.6-0.2-2.6,0.9-3.3,1.9 c-0.4,0.6,0.1,1.8,0.1,2.9C161.9,379.6,162.3,379.5,162.8,379.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }}
              d="M147.1,424.3c-0.6-2.1-1.2-3.9-1.8-5.9c1,0.7,1.8,1.4,2.7,2c0.1-0.1,0.2-0.2,0.4-0.3c-0.5-0.7-0.9-1.4-1.5-2 c-0.5-0.7-1.1-1.3-1.7-2c0.1-0.1,0.2-0.3,0.3-0.4c0.8,0.4,1.7,0.7,2.3,1.3c1.1,1,2,2.2,2.9,3.4c0.2,0.2-0.1,0.9-0.3,1.2 C149.5,422.5,148.4,423.3,147.1,424.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }}
              d="M186.8,390.6c0-3.3-0.2-6.5,0-9.8c0.2-3.4,1.5-6.5,2.8-9.6c1.1-2.7,2.3-5.5,4.2-7.7c2-2.3,4-4.7,6.2-6.8 c2.5-2.3,5.8-2.8,9-3.4c3.1-0.6,6-0.1,8.7,1.1c2.4,1,4.8,2.3,6.7,4.3c1.3,1.4,3,2.5,3.9,4.1c1.2,2,2.1,4.2,2.6,6.5 c0.7,3.1,0.8,6.2,1.3,9.4c0.6,4-0.8,7.7-1.6,11.5c-1.1,5.4-4.4,9.7-7.6,13.8c-1.8,2.3-4.6,4-7.2,5.7c-2.6,1.8-5.3,3.3-8.1,4.8 c-1.8,1-3.8,1.8-5.6,2.6c-1.3,0.6-1.9-0.5-2.4-1.2c-2.3-2.8-4.6-5.6-6.6-8.7c-1.9-3-3.3-6.3-5.1-9.4 C186.8,395.5,186.4,393.1,186.8,390.6z M204.2,383c-0.4-1.9-0.6-3.7-1.1-5.3c-0.4-1.3-1.3-2.4-2.8-2.4c-1.6,0-2.6,1-3.1,2.3 c-1.4,3.7-1.3,7.4,0,11.1c0.5,1.3,1.4,2.1,2.8,2.1c1.4,0,2.5-0.9,2.9-2.2C203.5,386.7,203.8,384.8,204.2,383z M230.6,383.1 c-0.3-1.8-0.5-3.5-0.9-5.1c-0.4-1.7-1.7-2.7-3-2.7c-1.4,0-2.6,0.8-3.1,2.5c-1.2,3.5-1.2,7.1,0,10.6c0.5,1.4,1.4,2.4,2.9,2.4 c1.5,0,2.6-1,2.9-2.4C230.1,386.6,230.3,384.8,230.6,383.1z M189,386.5c0.1,1.3-1.1,2.4,0,3.4c1.2,1.1,3.2,1.2,4.5,0.2 c0.5-0.4,1-0.9,1.3-1.5c0.9-1.8,1-3.7,0.4-5.6c-0.4-1.3-1.2-2.1-2.6-2.5c-0.7-0.2-1.1-0.6-0.8-1.2c0.2-0.4,0.7-0.7,1.2-0.8 c0.5-0.1,1,0,1.5,0c1.1,0,1-0.8,0.9-1.4c0-0.7,0.3-1.6-1-1.6c-1.3,0-2.6-0.1-4,0c-0.3,0-0.8,0.5-0.9,0.8c-0.2,1.7-0.4,3.3-0.5,5 c-0.1,0.7-0.1,1.4-0.2,2.1c0.5,0,0.9,0,1.3,0c2.1-0.1,3,1,2.5,3.1c-0.3,1.3-1.2,1.7-2.3,1.1C190,387.3,189.7,387,189,386.5z M211.3,379.4c0-0.4,0-0.7,0-1.1c0-0.8,0-1.5-1.2-1.5c-1.2,0-1.3,0.6-1.3,1.5c0,0.6-0.1,1.2,0,1.8c0.2,1.3-0.4,1.8-1.7,1.6 c-1.2-0.2-1.5,0.4-1.5,1.5c0,1.3,0.8,1.3,1.7,1.2c1.1-0.1,1.6,0.3,1.5,1.5c-0.1,0.8,0,1.5,0,2.3c-0.1,0.9,0.4,1.3,1.3,1.3 c0.9,0,1.2-0.4,1.2-1.3c0-0.6,0-1.3,0-1.9c0-1.8,0-1.8,1.8-1.8c0.5,0,1.1-0.2,1.3-0.5c0.7-1.2,0-2.3-1.4-2.2 c-1.4,0.1-1.8-0.4-1.6-1.7C211.3,379.9,211.3,379.7,211.3,379.4z M218.4,379.6c0,0.5,0.1,0.9,0.1,1.3c0,2.9,0,5.7,0,8.6 c0,1,0.6,1,1.3,1c0.7,0,1.2-0.1,1.2-1c0-4.4,0-8.8,0-13.2c0-0.7-0.3-0.9-0.9-0.9c-1.6-0.1-2.5,0.9-3.2,1.9c-0.4,0.6,0,1.9,0.1,3 C217.6,380,217.9,379.9,218.4,379.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }}
              d="M202,420.7c0.8,1.8,1.8,3.3,3.5,4.1c0.1-0.1,0.2-0.2,0.2-0.2c-0.2-0.2-0.5-0.4-0.7-0.6 c-0.5-0.6-1.1-1.2-1.5-1.8c-0.2-0.4-0.2-0.9-0.3-1.4c0.5,0.1,1,0.1,1.4,0.4c1.1,0.7,2.1,1.4,3.1,2.2c0.8,0.7,1,1.6,0.3,2.4 c-0.6,0.8-1.3,1.6-2.1,2.1c-1.7,1.2-2.8,0.9-3.5-1c-0.6-1.7-0.6-3.6-0.9-5.4C201.6,421.2,201.7,421.1,202,420.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1}
              fill={this.state.color17 ? this.state.color17 : 'transparent'} onClick={() => {
          this.setState({ color17: color, opacity17: opacity });
        }}
              d="M202.1,419.1c-0.7-0.1-1.5-0.2-2.2-0.4c-0.2-0.1-0.3-0.6-0.4-0.9c0.3-0.1,0.7-0.4,0.9-0.4c0.7,0.3,1.3,0.7,2,1 C202.3,418.7,202.2,418.9,202.1,419.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }}
              d="M472.9,379.8c-0.2,5.8-0.8,10.5-2.3,15c-1.5,4.6-4.4,8.4-7.4,12.1c-2.2,2.6-4.5,5.1-6.8,7.5 c-0.9,0.9-2,1.6-3,2.3c-4.4,3.1-9.3,5.1-14.1,7.3c-0.6,0.3-1.2,0.4-1.8,0.6c-2.1,0.4-2.7,0.1-3.7-1.7c-0.6-1-1.2-2-1.9-2.9 c-2.1-2.8-3.3-6-4.4-9.2c-1.1-3.4-2.5-6.8-3-10.3c-1.3-9-0.6-17.9,2.7-26.6c1.6-4,3.4-7.8,6.2-11c2-2.4,4.7-4.2,7.2-6.2 c1.9-1.5,4.2-2.3,6.6-2.5c3.6-0.2,7,0.5,10.2,2.1c1.2,0.6,2.4,1.2,3.6,1.7c2,0.9,3.4,2.4,4.7,4.1c2.3,2.8,3.9,6,5.2,9.4 C472.2,374.5,472.9,377.6,472.9,379.8z M443.5,388.7c-0.3-1.9-0.6-3.6-1-5.3c-0.4-1.4-1.3-2.5-2.9-2.5c-1.6-0.1-2.5,0.9-3,2.3 c-1.4,3.7-1.3,7.3,0,11c0.5,1.3,1.3,2.2,2.8,2.2c1.5,0,2.5-0.9,2.9-2.2C442.8,392.4,443.1,390.5,443.5,388.7z M467.7,388.7 c-0.3-1.8-0.5-3.5-0.9-5.2c-0.5-1.7-1.7-2.6-3-2.7c-1.2,0-2.5,0.9-3,2.5c-1.2,3.5-1.2,7.1,0,10.7c0.5,1.3,1.3,2.3,2.9,2.3 c1.4,0,2.6-0.8,3-2.4C467.1,392.2,467.3,390.4,467.7,388.7z M433.9,385.4c0-1,0.1-2,0-3c0-0.4-0.4-1.2-0.5-1.2 c-0.7,0-1.9,0.1-2.1,0.5c-1.5,2.8-2.8,5.7-4,8.6c-0.7,1.6-0.2,2.2,1.6,2.2c3,0,2.5-0.2,2.5,2.4c0,0.9,0.2,1.4,1.3,1.4 c1.1,0,1.4-0.4,1.3-1.4c0-0.3,0-0.6,0-1c0-0.7-0.1-1.3,0.9-1.5c0.7-0.2,0.7-2.6,0-2.7c-1.1-0.2-0.9-0.9-0.9-1.6 C433.9,387.1,433.9,386.3,433.9,385.4z M455.4,385.4c0,0.9,0,1.7,0,2.4c0,2.5,0,5,0,7.4c0,1,0.6,1.1,1.4,1.1c0.6,0,1.3,0.1,1.3-0.9 c0-4.4,0-8.9,0-13.3c0-0.3-0.6-0.8-0.9-0.9c-1.5-0.2-2.5,0.9-3.2,1.9c-0.4,0.6,0,1.8,0,2.9C454.6,385.7,454.9,385.6,455.4,385.4z M448.5,391c0.9,0,1.8,0,2.7,0c0.8,0,1.1-0.3,1.1-1.1c0-0.7-0.3-1.1-1.1-1.1c-1.8,0-3.6,0-5.4,0c-0.8,0-1,0.4-1,1.1 c0,0.8,0.3,1.1,1.1,1.1C446.7,391,447.6,391,448.5,391z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }}
              d="M426.7,423.7c1.2,0.3,2.3,0.5,3.3,0.7c1,0.1,2.1,0,3.1,0.1c0.5,0.1,0.9,0.6,1.4,0.9c-0.2,0.3-0.5,0.6-0.7,0.9 c-0.1,0.1-0.3,0.3-0.3,0.4c-1,2.8-3.4,3.6-6,4.1c-0.3,0.1-0.9-0.3-1-0.6C425.3,428,426.2,425.9,426.7,423.7z M430.5,426.5 c0.1,0.2,0.2,0.3,0.2,0.5c0.6-0.3,1.3-0.6,1.9-0.9c0.1-0.1,0.1-0.4,0.2-0.7c-0.2,0-0.6-0.2-0.7-0.1 C431.5,425.7,431,426.1,430.5,426.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }}
              d="M291.3,379.2c-0.2,4.1-1.1,10-3.4,15.7c-2.5,6.2-6,11.7-10.4,16.7c-3.4,3.8-7.4,6.8-12.4,8.2 c-0.7,0.2-1.7,0.1-2.2-0.3c-2.4-2.1-4.7-4.3-6.9-6.6c-2-2.2-4-4.6-5.6-7.1c-2.1-3.5-3.4-7.3-3.4-11.6c0-3.1-0.3-6.3-0.3-9.5 c0-1.4,0.2-2.8,0.5-4.1c0.5-2.3,1.1-4.6,1.8-6.8c1.4-4.5,3.1-9,6.2-12.7c2.9-3.4,6.2-6.4,10.2-8.5c2.6-1.3,5.4-1.4,8.2-0.8 c1.6,0.4,3.1,1,4.7,1.5c0.2,0.1,0.4,0.2,0.6,0.2c2.2-0.2,3.4,1.1,4.7,2.7c2,2.4,3.9,4.9,5,7.8C290.2,368.2,291.3,372.6,291.3,379.2z M264.4,381.2c-0.4-1.9-0.7-3.7-1.2-5.4c-0.4-1.3-1.3-2.3-2.9-2.3c-1.5,0-2.4,1-2.9,2.3c-1.4,3.7-1.3,7.4,0,11.1 c0.5,1.2,1.3,2.1,2.8,2.1c1.5,0,2.5-0.8,2.9-2.1C263.7,385,264,383.1,264.4,381.2z M282.1,381.2c0.4,1.8,0.6,3.6,1.1,5.3 c0.4,1.4,1.2,2.4,2.9,2.4c1.4,0,2.5-0.7,3.1-2.4c1.2-3.5,1.1-7,0.1-10.5c-0.4-1.4-1.3-2.5-2.9-2.6c-1.3-0.1-2.6,0.9-3.1,2.6 C282.7,377.7,282.5,379.4,282.1,381.2z M255.2,378c0-0.8,0.1-1.7,0-2.5c-0.1-0.6,0.4-1.6-0.7-1.8c-1-0.2-1.9-0.2-2.4,1.1 c-1,2.5-2.2,4.8-3.4,7.3c-0.2,0.4-0.4,0.9-0.4,1.4c-0.2,1.4,0,1.6,1.4,1.6c2.7,0,2.7,0,2.8,2.6c0,0.8,0.3,1.1,1.1,1.1 c1.2,0.1,1.7-0.3,1.6-1.6c0-0.9-0.4-1.9,0.9-2.5c0.3-0.1,0.3-2.1-0.2-2.5c-0.7-0.5-0.8-1-0.8-1.7C255.2,379.7,255.2,378.9,255.2,378 z M274.2,381.2c-0.3-0.4-0.6-0.9-1-1c-0.7-0.3-1.8,0-2.2-0.5c-0.4-0.5-0.2-1.5-0.2-2.3c0-0.3,0-0.6,0-0.8c0-0.8,0-1.5-1.2-1.5 c-1.1,0-1.3,0.5-1.2,1.4c0,0.4,0,0.9,0,1.3c0,2.1,0,2.2-2.1,2.2c-0.9,0-1,0.5-1,1.2c0,0.7-0.1,1.4,0.9,1.5c2.2,0.2,2.2,0.3,2.2,2.5 c0,0.3,0,0.6,0,1c0,0.9,0,1.5,1.2,1.5c1.2,0,1.2-0.7,1.2-1.5c0-0.6,0.1-1.2,0-1.8c-0.2-1.3,0.4-1.8,1.7-1.7 C273.5,382.9,274.2,382.7,274.2,381.2z M278,377.8c0,0.6,0,1,0,1.5c0,2.8,0,5.7,0,8.5c0,0.9,0.5,1.1,1.2,1.1c0.7,0,1.5,0.1,1.5-1 c0-4.4,0-8.7,0-13.1c0-0.7-0.3-1-1.1-1.1c-1.6-0.1-2.5,1-3.2,2c-0.4,0.6,0,1.8,0.1,3C277.1,378.3,277.4,378.1,278,377.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }}
              d="M270.2,428.5c-1-1.7-2.4-3.1-2.8-5.3c2,0.9,3.5,2.1,4.5,3.8c0.3,0.4,0.5,0.8,0.7,1.3c0.4,0.7,0.3,1.3-0.4,1.9 c-2.4,1.9-3,1.8-4.5-0.8c-1-1.7-2-3.4-1.9-5.7C267.5,425.2,267.7,428,270.2,428.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }}
              d="M264,423.6c-0.4-0.3-0.8-0.4-0.9-0.7c-0.1-0.2,0.2-0.7,0.4-0.9c0.7-0.4,1.4-0.9,2.1-1.1 c0.4-0.1,0.8,0.2,1.3,0.3c-0.1,0.4-0.1,0.9-0.3,1.1C265.7,422.8,264.9,423.2,264,423.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }}
              d="M534.8,389.9c-0.2-0.6-0.6-1.3-0.6-1.9c0-2.3,0.1-4.6,0.3-6.9c0-0.3,0.4-0.5,0.5-0.7c0.2,0.2,0.5,0.3,0.6,0.5 c0.9,2.9,0.9,5.8-0.1,8.7c-0.1,0.1-0.2,0.3-0.2,0.4C535.1,389.9,534.9,389.9,534.8,389.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M510.1,389.9c-1.6-1.8-1.6-8.1,0-9.6C511.7,381.7,511.7,388.1,510.1,389.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M107.9,383.9c-1.5-2-1.5-7.7,0-9.5C109.5,375.5,109.6,382.5,107.9,383.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }}
              d="M80.4,374.2c0.3,1.4,0.7,2.6,0.7,3.9c0,1.7-0.3,3.4-0.5,5.1c0,0.3-0.4,0.5-0.5,0.7c-0.2-0.2-0.5-0.3-0.6-0.5 c-0.9-2.9-0.9-5.8,0.1-8.6c0-0.1,0.1-0.2,0.2-0.3C79.9,374.4,79.9,374.4,80.4,374.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }}
              d="M317.8,379.3c0.2,1,0.6,1.9,0.6,2.9c0,2-0.2,3.9-0.4,5.9c0,0.2-0.3,0.5-0.6,0.6c-0.1,0-0.5-0.3-0.6-0.5 c-0.8-2.9-0.9-5.7,0.1-8.5c0.1-0.1,0.1-0.3,0.2-0.4C317.4,379.3,317.6,379.3,317.8,379.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }}
              d="M341.4,388.9c-0.2-0.7-0.6-1.4-0.6-2.1c0-2.2,0.1-4.3,0.2-6.5c0-0.3,0.5-0.6,0.7-0.9c0.2,0.3,0.5,0.6,0.6,0.9 c0.6,2.5,0.6,5.1,0,7.7c-0.1,0.2-0.2,0.4-0.3,0.7c0,0.1-0.1,0.2-0.2,0.3C341.7,388.9,341.5,388.9,341.4,388.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }}
              d="M406.8,380.4c-0.2,1.3-0.3,2.6-0.5,3.9c-0.1,0.3-0.5,0.6-0.7,0.9c-0.2-0.3-0.6-0.5-0.7-0.9 c-0.7-2.5-0.6-5,0-7.5c0.1-0.4,0.5-0.6,0.8-1c0.2,0.3,0.6,0.7,0.7,1C406.5,377.9,406.6,379.1,406.8,380.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M370.9,385.3c-1.6-1.7-1.6-7.7-0.1-9.6C372.4,376.9,372.5,383.4,370.9,385.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M378.8,385.1c-1.6-1.2-1.7-7.7-0.1-9.3C380.3,376.9,380.3,383.6,378.8,385.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M143.8,387.4c-1.7-1.6-1.6-8,0-9.5C145.2,379.3,145.3,385.5,143.8,387.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M170.9,387.4c-1.5-1.7-1.5-7.6,0-9.5C172.5,379.1,172.5,386,170.9,387.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M200.1,387.7c-1.5-1.5-1.6-7.6-0.1-9.4C201.7,379.3,201.7,386.3,200.1,387.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }}
              d="M227.1,378.2c0.2,1.1,0.6,2.3,0.6,3.4c0,1.8-0.3,3.6-0.5,5.5c0,0.2-0.3,0.5-0.6,0.6c-0.1,0-0.5-0.3-0.5-0.5 c-0.8-2.9-0.9-5.7,0.1-8.6c0.1-0.2,0.3-0.3,0.4-0.4C226.8,378.2,226.9,378.2,227.1,378.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M439.4,393.4c-1.5-1.5-1.5-7.8,0-9.5C441,385,441,392.1,439.4,393.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M463.7,383.9c1.6,1.5,1.5,8.3,0,9.5C462.2,391.9,462.2,385.6,463.7,383.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity38 ? this.state.opacity38 : 1}
              fill={this.state.color38 ? this.state.color38 : 'transparent'} onClick={() => {
          this.setState({ color38: color, opacity38: opacity });
        }} d="M431.2,387.6c0.2,1.7,0.2,1.7-0.9,1.9C430.6,388.8,430.9,388.3,431.2,387.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }} d="M260.3,386.1c-1.6-1.6-1.6-8,0-9.6C261.9,377.7,261.9,384.1,260.3,386.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }} d="M286.2,376.4c1.5,1.7,1.5,7.8,0.1,9.6C284.6,384.8,284.5,378.1,286.2,376.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity41 ? this.state.opacity41 : 1}
              fill={this.state.color41 ? this.state.color41 : 'transparent'} onClick={() => {
          this.setState({ color41: color, opacity41: opacity });
        }} d="M251.6,382c0.3-0.6,0.5-1.2,0.8-1.9C252.7,381.8,252.7,381.9,251.6,382z" />
      </SvgIcon>
    )
  }
};