import React, { Component } from 'react';
import { Group, Transformer } from "react-konva";
import URLImage from "../drawing/URLImage";
import _ from "lodash";

export default class Protractor extends Component <any> {
  private readonly trRef: any;
  private readonly shapeRef: any;

  state = {
    x: 50,
    y: 50,
    width: 453,
    height: 320,
    rotation: 0
  }

  constructor(props) {
    super(props);
    this.trRef = React.createRef();
    this.shapeRef = React.createRef();
    if (!_.isUndefined(this.props.pageY)) {
      this.state.y = this.props.pageY;
    }
    const { scaleX } = this.props;
    const { width, height } = this.state;
    this.state = { ...this.state, width: scaleX * width, height: scaleX * height };
  }


  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (this.props.dragImgEnabled) {
      // we need to attach transformer manually
      this.trRef.current.nodes([this.shapeRef.current]);
      this.trRef.current.getLayer().batchDraw();
    }
  }


  render() {
    const { x, y, width, height, rotation = 0 } = this.state;
    const { dragImgEnabled } = this.props;
    return (
      <React.Fragment>
        <Group
          ref={this.shapeRef}
          x={x}
          y={y}
          width={width}
          rotation={rotation}
          height={height}
          draggable={dragImgEnabled}
          onTransformEnd={(e) => {
            const node = this.shapeRef.current;
            node.scaleX(1);
            node.scaleY(1);

            this.setState({
              x: node.x(),
              y: node.y(),
              rotation: node.rotation()
            })
          }}
          onDragEnd={(e) => {
            this.setState({
              ...this.state,
              x: e.target.x(),
              y: e.target.y()
            }, () => {
            })
          }}
        >
          <URLImage
            x={0}
            y={0}
            src={'images/tools/Protractor.svg'}
            width={width}
            height={height}
          />
        </Group>
        {dragImgEnabled && <Transformer
          enabledAnchors={[]}
          ref={this.trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />}
      </React.Fragment>
    )
  }

}