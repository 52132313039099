import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_21_3 extends SVG {

  render() {
    let { color, borderColor = 'black', opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 198.5 198.2"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M108.7,71.8c-0.6,1.1-1.2,2.2-1.8,3.4c-2-2.4-2.6-5.1-2-8.1c-0.1,0-0.2,0-0.4,0c-0.1,0.2-0.4,0.5-0.4,0.7
	c-0.1,2.1-0.1,4.1,1.1,6c0.6,1,1.6,2,0.7,3.3c-0.3,0.4-0.7,0.8-1.1,1.4c0.7,1,0,2.3-0.6,3.3c-0.2,0.4-2.1,0.6-2.7,0.2
	c-2-1.5-3.9-3.3-5.7-5.1c-0.6-0.6-0.6-1.7-0.8-2.6c-0.1-0.5-0.2-1-0.4-1.4c-0.1,2.4,0.4,4.6,2.5,6.1c1,0.7,1.8,1.5,2.7,2.3
	c0.9,0.9,1.9,1.6,3.3,1.3c0.3-0.1,0.6,0,0.9,0c-0.1,1.7-1.3,3.1-3,3.5c-1.6,0.4-3.1,0.9-4.7,1c-1.2,0.1-2.5,0.1-3.6-0.3
	c-0.9-0.3-1.5-1.1-2.3-1.7c0.2,0.4,0.4,0.9,0.7,1.4c-1.7-0.6-3-2.5-3-4.2c0-0.7-0.1-1.4-0.4-2c-1-2.4,0.2-4.4,0.8-6.8
	c0.5,0.4,0.9,0.6,1.5,1.1c0.4-0.7,0.9-1.3,1-2c0.4-1.7,1-3.1,2-4.5c0.4,0.2,0.7,0.2,0.9,0.4c1,0.6,1.5,0.2,1.9-0.8
	c0.8-1.7,1.6-3.3,2.5-4.9c0.3-0.5,1-0.9,1.6-1.4c0.3,0.5,0.6,0.9,0.9,1.2c0.3,0.2,0.7,0.4,1,0.4c0.2,0,0.5-0.4,0.7-0.7
	c0.8-1.6,1.5-3.2,2.4-4.8c0.4-0.7,1.2-1.1,1.9-1.7c0.5,1.1,0.9,2.1,1.3,2.9c0.1,0.3,0.5,0.6,0.8,0.6c0.2,0,0.5-0.4,0.6-0.7
	c0.6-1.7,1.6-3,3.2-4c2.3-1.4,4.4-2.9,7.1-3.4c0.1,0,0.2-0.1,0.3-0.1c1.7-0.3,2.2,0.2,1.8,2c-0.5,2.4-1.2,4.6-2.4,6.7
	c-0.3,0.5-0.5,1.1-0.9,2c0.9-0.5,1.7-0.8,2.3-1.2c1-0.5,1.5-0.1,1.9,0.7c0.2,0.4,0.3,0.9,0.5,1.3c0.2,0.4,0.5,0.9,0.9,1.1
	c0.2,0.1,0.7-0.5,1.1-0.7c0.2-0.2,0.4-0.4,0.6-0.6c0.1,0.1,0.3,0.2,0.3,0.3c0.1,1.9,0.3,3.9,0.3,5.8c0,0.5-0.2,1-0.3,1.5
	c-0.1,0.3-0.1,0.6-0.1,1c0.4,0,0.8,0.1,1.1,0c0.7-0.3,1.4-0.7,2.1-1c0.2-0.1,0.5,0,0.8-0.1c-0.3,2-1,3.7-2.2,5.3
	c-0.2,0.3-0.3,0.7-0.4,1.1c0.5,0.1,1.1,0.3,1.6,0.2c0.5-0.1,1-0.4,1.8-0.8c-0.7,2.3-1.8,4.1-3.2,5.5c-1.4,1.4-3.1,2.5-4.9,3.9
	c0.4,0.1,0.8,0.3,1.2,0.3c0.9,0,1.8,0,2.7-0.1c0.9,0,1.2,0.5,0.7,1.2c-0.3,0.5-0.7,1-1.2,1.3c-2.7,2-5.9,2.7-9.1,3.2
	c-4.1,0.6-7.4-1.3-10.7-3.1c-0.5-0.3-0.9-0.8-1.5-1.4c2.1-0.2,4-0.3,5.8-0.7c1.8-0.4,3.5-1.2,5.2-1.9c-0.1-0.1-0.1-0.3-0.2-0.4
	c-0.2,0.1-0.5,0.2-0.7,0.3c-2.8,1.7-5.8,2.1-9,2c-1.8,0-2-0.3-1.7-2.1c0.3-1.4,0.6-2.7,1-4.1c0.1-0.3,0.6-0.8,0.9-0.8
	c4.3-0.2,8.1-2.1,11.7-4.2c0.7-0.4,1.4-0.8,2-1.3c-4.5,2-8.5,5.4-14.1,4.8C107.2,76.1,107.9,73.9,108.7,71.8
	c0.2-0.4,0.4-0.8,0.7-1.2c0.3-0.3,0.6-0.7,0.9-0.7c2.1-0.4,4.8-1.8,6.5-3.4c-1,0.5-1.9,1.1-2.9,1.5c-0.9,0.4-1.8,0.8-2.7,1.2
	c-0.8,0.3-1-0.2-0.9-0.9c0.2-0.3,0.4-0.6,0.6-0.9c1.6-3.1,3.2-6.1,4.7-9.2c0.2-0.4,0.4-0.7,0.6-1.1c-0.7,0.6-1.3,1.2-1.6,1.9
	c-1.5,3.1-2.9,6.2-4.3,9.3c-0.4,0.3-0.8,0.6-1.2,0.9c0.1,0,0.2,0,0.3,0C109.2,70.2,109,71,108.7,71.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M104,97.7c-0.3-0.3-0.8-0.6-0.8-0.9c-0.1-2.5,0-5,0.1-7.4c0.3,2.7,0.6,5.4,0.9,8.1
	C104.2,97.5,104.1,97.6,104,97.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M106.1,116.7c-0.5,1.9-0.7,3.7,0.1,5.5c0.3,0.7,0.7,1.5,1.2,2.1c0.9,1,0.3,2-0.1,2.9c-0.1,0.3-0.6,0.5-1,0.7
	c0.3,0.6,0.1,3.5-0.5,3.6c-1,0.2-2.1,0.1-3.1-0.1c-0.5-0.1-0.8-0.7-1.2-1.1c-0.6-0.5-1-1.1-1.7-1.5c-1.9-1.1-3-2.7-3.2-4.9
	c-0.1-0.5-0.2-1-0.5-1.5c0,2.7,0.5,5.1,3.1,6.5c0.5,0.3,1.1,0.7,1.5,1.2c1.3,1.6,2.8,2.6,5,1.9c0,2.2-1.6,3.6-3.6,3.7
	c-1.1,0.1-2.1,0.7-3.2,0.8c-1.1,0.2-2.3,0.2-3.4,0.1c-1.3-0.1-1.3-0.1-3.4-1.8c0.2,0.4,0.5,0.8,0.7,1.3c-1.6-0.5-3-2.4-3-4
	c0-1-0.3-2-0.6-2.9c-0.7-2.2,0.6-4,1-6.1c0.6,0.4,0.9,0.7,1.5,1.1c0.4-0.6,0.9-1.2,1-1.9c0.4-1.7,1-3.2,2-4.6
	c2.2,0.8,2.2,0.8,3.3-1.3c0.5-1.1,1.1-2.1,1.7-3.1c0.5-0.8,1.2-1.5,1.9-2.4c0.4,0.5,0.6,0.9,0.9,1.1c0.3,0.3,0.8,0.6,1.1,0.6
	c0.2,0,0.6-0.5,0.7-0.9c0.8-2.3,1.6-4.5,3.8-5.9c0.2-0.1,0.3-0.2,0.5-0.3c0.4,0.9,0.7,1.8,1.1,2.7c0.2,0.3,0.5,0.5,0.8,0.8
	c0.2-0.3,0.6-0.5,0.7-0.8c0.6-1.7,1.6-2.9,3.1-3.9c2.3-1.4,4.5-2.9,7.2-3.4c2-0.4,2.7,0.2,2,2.1c-0.9,2.8-1.9,5.5-2.8,8.2
	c0.5-0.3,1.3-0.6,2-1c0.9-0.5,1.5-0.1,1.9,0.8c0.2,0.4,0.3,0.9,0.5,1.3c0.2,0.4,0.5,1,0.8,1c0.3,0.1,0.7-0.4,1.1-0.7
	c0.2-0.2,0.3-0.4,0.5-0.6c0.1,0.1,0.3,0.2,0.3,0.3c0.1,1.9,0.3,3.7,0.4,5.6c0,0.6-0.3,1.1-0.4,1.7c-0.1,0.3-0.1,0.6-0.1,1
	c0.3,0,0.7,0.1,1-0.1c0.7-0.3,1.4-0.7,2.1-1c0.2-0.1,0.5-0.1,1-0.1c-0.5,1.4-0.9,2.7-1.4,3.9c-0.3,0.6-0.8,1.2-1.2,1.8
	c-0.5,0.7-0.2,1.1,0.6,1c0.4,0,0.8-0.2,1.2-0.3c0.4-0.1,0.8-0.3,1.3-0.5c-0.1,0.5-0.2,0.7-0.3,0.9c-1.1,2.5-2.7,4.7-4.8,6.4
	c-0.7,0.5-1.5,0.8-2.3,1.2c-0.2,0.1-0.4,0.4-0.6,0.6c0.2,0.1,0.5,0.4,0.7,0.4c1,0,1.9,0,2.9,0c0.4,0,1.1,0.1,1.1,0.2
	c0.1,0.4,0,1.1-0.2,1.3c-1.2,1-2.5,1.9-3.9,2.7c-1.1,0.6-2.3,0.7-3.5,1.1c-5.1,1.5-9.5-0.2-13.7-2.9c-0.4-0.3-0.7-0.7-1.2-1.3
	c4.1,0,7.8-0.4,11-2.8c-0.2,0.1-0.5,0.2-0.7,0.3c-2.8,1.7-6,2.1-9.2,2c-1.5,0-1.9-0.4-1.6-1.9c0.3-1.4,0.6-2.9,1-4.3
	c0.1-0.3,0.5-0.7,0.8-0.7c5-0.3,9.1-2.6,13.2-5.2c-4.3,2-8.3,5.1-13.6,4.5c0.9-2.3,1.7-4.4,2.6-6.5c-0.1,0-0.1,0-0.2-0.1
	c-0.5,1.1-1.1,2.1-1.7,3.3C106.4,122.3,105.6,119.7,106.1,116.7z M111.4,117.9c0.3-0.3,0.7-0.5,0.8-0.9c1.6-3.1,3.2-6.2,4.8-9.3
	c0.2-0.3,0.4-0.6,0.5-1c-0.1-0.1-0.2-0.1-0.3-0.2c-1,1.7-2,3.3-2.9,5C113.3,113.6,112.4,115.8,111.4,117.9c-0.3,0.3-0.6,0.7-1.1,1.2
	c0.5,0.1,0.8,0.3,1.2,0.3c2.9-0.1,4.8-2.1,7.1-3.4c-0.6,0.2-1.2,0.5-1.8,0.8c-1.2,0.6-2.4,1.3-3.7,1.9
	C112.3,119,111.7,118.8,111.4,117.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M104.7,138.3c0.3,2.8,0.5,5.7,0.9,9c-0.4-0.5-1-0.8-1-1.1C104.6,143.6,104.6,141,104.7,138.3
	C104.7,138.3,104.7,138.2,104.7,138.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M13.4,73.5c0.6-0.9,1.1-1.8,1.6-2.7c0.2,0,0.3-0.1,0.3,0c2,0.8,1.9,0.8,2.8-1.1c0.7-1.4,1.4-2.8,2.3-4.2
	c0.4-0.6,1-0.9,1.7-1.5c0.3,0.4,0.5,0.8,0.8,1c0.3,0.3,0.7,0.6,1,0.6c0.3,0,0.6-0.5,0.8-0.9c0.8-1.6,1.6-3.3,2.5-4.8
	c0.4-0.6,1.1-0.9,1.8-1.5c0.5,1.1,0.9,2.1,1.3,3c0.1,0.2,0.5,0.5,0.7,0.5c0.2,0,0.5-0.3,0.6-0.5c0.5-1.9,1.7-3.2,3.4-4.3
	c2.2-1.3,4.3-2.8,6.9-3.2c0.6-0.1,1.7-0.2,1.9,0c0.3,0.6,0.3,1.4,0.2,2.1c-0.4,2.9-1.7,5.4-3,7.9c0,0.1,0,0.2,0,0.4
	c0.8-0.4,1.5-0.7,2.2-1.1c0.9-0.5,1.5-0.1,1.9,0.7c0.2,0.5,0.3,1,0.5,1.4c0.2,0.4,0.5,0.9,0.9,1c0.2,0.1,0.7-0.4,1-0.7
	c0.2-0.2,0.4-0.5,0.8-1c0.1,1.7,0.2,3.2,0.4,4.6c0.1,1.2,0.3,2.5-0.4,3.6c-0.1,0.1,0,0.5,0.1,0.6c0.2,0.1,0.5,0.2,0.8,0.1
	c0.6-0.2,1.1-0.6,1.7-0.8c0.4-0.2,0.8-0.3,1.5-0.6c-0.3,2.2-1,3.9-2.2,5.5c-0.2,0.3-0.3,0.7-0.4,1.1c0.5,0.1,1,0.3,1.4,0.2
	c0.6-0.1,1.1-0.4,1.8-0.8c-0.1,0.5-0.1,0.8-0.2,1c-1.1,2.6-2.8,4.7-4.9,6.5c-0.6,0.5-1.4,0.7-2.1,1.1c-0.3,0.2-0.5,0.4-0.7,0.7
	c0.3,0.2,0.6,0.5,1,0.5c0.7,0.1,1.3-0.1,2,0c0.6,0,1.2,0.1,1.8,0.1c0.1,0.1,0.2,0.3,0.2,0.4c-0.6,0.7-1,1.6-1.8,2.1
	c-2.9,2-6.1,2.8-9.6,3.1c-3.9,0.4-6.9-1.5-10.1-3.2c-0.5-0.3-0.9-0.8-1.6-1.4c4.4-0.2,8.4-0.5,11.7-3.4c-0.3,0.2-0.6,0.3-0.9,0.5
	c-0.3,0.2-0.6,0.3-0.9,0.5c-2.9,1.5-6,2.1-9.3,1.7c-0.9-0.1-1.4-0.4-1.1-1.5c0.4-1.5,0.7-2.9,1-4.4c0.1-0.6,0.4-0.9,1.1-1
	c4.1-0.3,7.7-1.9,11.2-4c0.2-0.1,0.3-0.2,0.4-0.4c-3.9,1.7-7.5,4.3-12.2,3.7c0.7-1.9,1.4-3.6,2.1-5.3c-0.1,0-0.1-0.1-0.2-0.1
	c-0.4,0.7-0.7,1.4-1.1,2.1c-2-2.4-2.6-5-2-7.9c-0.1,0-0.2,0-0.3,0c-0.1,0.3-0.3,0.5-0.3,0.8c-0.4,2.5,0.2,4.8,1.7,6.9
	c1,1.3,0.5,2.9-1,3.6c0.6,1.3,0.1,2.6-0.5,3.4c-0.4,0.5-1.9,0.2-3,0.1c-0.4,0-0.7-0.6-1.1-0.9c-0.8-0.7-1.6-1.5-2.4-2.1
	c-1.5-1.1-2.4-2.5-2.7-4.4c-0.1-0.6-0.2-1.1-0.4-1.7c0,2.4,0.4,4.7,2.6,6.2c0.9,0.6,1.7,1.4,2.5,2.2c1.2,1.2,1.5,1.4,4.6,1.3
	c-0.4,0.7-0.8,1.3-1.1,1.9c-0.7,1.6-2.1,1.6-3.5,1.8c-1.2,0.2-2.4,0.8-3.6,0.9c-1.2,0.1-2.4,0.1-3.5-0.3c-0.9-0.3-1.5-1.1-2.3-1.8
	c0.3,0.5,0.5,1,0.8,1.5c-1.7-0.5-3-2.4-3-4.2c0-0.7-0.2-1.5-0.5-2.2c-0.9-2.3,0.3-4.3,0.9-6.6c0.5,0.4,0.9,0.6,1.3,0.9
	C13.4,76.4,13,74.8,13.4,73.5C13.4,73.5,13.4,73.5,13.4,73.5z M39.1,69.1c-2.5,0.4-4.2,2.9-7.4,2.7c2.1-4.2,4.1-8.1,6.1-12.1
	c-0.3,0.3-0.5,0.5-0.6,0.7c-1.7,3.3-3.3,6.5-5,9.8c-0.3,0.6-0.8,1.2-1.3,2c0,0,0.4,0.5,0.6,0.4c1-0.2,2.1-0.4,3.1-0.9
	C36.2,70.9,37.6,70,39.1,69.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }}
              d="M25.1,91.6c0.3,2.8,0.6,5.6,0.9,8.7c-0.4-0.4-1-0.7-1-1c-0.1-2.6,0-5.2,0-7.8C25,91.6,25,91.6,25.1,91.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M24,39.9c3.1-0.2,6.2-0.3,9.3-1.9c-2,0.5-3.8,0.9-5.7,1.2c-1.1,0.2-2.2,0.1-3.2,0.1c-1.3-0.1-1.5-0.4-1.3-1.7
	c0.3-1.4,0.6-2.8,0.9-4.2c0.2-0.7,0.5-1,1.3-1.1c4.9-0.3,9-2.6,12.9-5.2c-4.3,2-8.3,5.1-13.7,4.6c0.8-1.9,1.5-3.7,2.2-5.5
	c-0.1,0-0.1-0.1-0.2-0.1c-0.4,0.7-0.8,1.5-1.2,2.3c-2-2.4-2.5-5-2-8c-0.1,0-0.2,0-0.3,0c-0.1,0.2-0.3,0.5-0.4,0.7
	c-0.1,2.1-0.1,4.1,1.1,6c0.6,0.9,1.6,1.9,0.7,3.2c-0.3,0.5-0.6,0.9-1.1,1.5c0.7,1-0.1,2.2-0.6,3.2c-0.2,0.4-2.1,0.6-2.7,0.2
	c-2-1.5-3.9-3.2-5.6-5.1c-0.6-0.7-0.6-1.9-0.9-2.9c-0.1-0.4-0.1-0.8-0.3-1.3c-0.5,1.9,0.4,4.6,1.8,5.8c1.3,1.1,2.4,2.3,3.8,3.2
	c1,0.7,2.3,0.9,3.7,1.3c0.1,0.7-0.8,2.4-1.8,2.7c-1.7,0.5-3.4,1.1-5.1,1.3c-1.4,0.2-2.9,0.2-4.3-0.1c-0.9-0.2-1.6-1-2.4-1.6
	c0.2,0.4,0.4,0.8,0.6,1.3c-1.9-0.7-3-2.4-3-4.4c0-0.7-0.2-1.4-0.5-2.1c-1-2.3,0.3-4.2,0.8-6.5c0.6,0.4,0.9,0.6,1.6,1
	c0.3-0.6,0.7-1.1,1-1.7c0.5-1.2,0.9-2.5,1.4-3.8c0.3-0.7,0.7-1,1.6-0.6c1.1,0.6,1.5,0.3,2-0.8c0.8-1.7,1.6-3.3,2.5-4.9
	c0.3-0.5,0.9-0.8,1.5-1.3c0.3,0.4,0.6,0.8,0.9,1.1c0.3,0.2,0.7,0.5,1,0.5c0.3,0,0.6-0.5,0.7-0.8c0.7-1.5,1.3-3,2.1-4.4
	c0.5-0.8,1.4-1.4,2.2-2.2c0.5,1.1,0.8,2,1.2,2.8c0.2,0.3,0.6,0.6,0.9,0.9c0.2-0.3,0.6-0.5,0.7-0.8c0.5-2,1.9-3.2,3.5-4.2
	c2.1-1.3,4.2-2.6,6.7-3.1c2.1-0.4,2.7,0.1,2.1,2.1c-0.9,2.8-1.9,5.5-2.8,8.3c0.4-0.2,1-0.6,1.7-0.9c1.4-0.6,1.7-0.4,2.3,0.9
	c0.2,0.4,0.3,0.9,0.5,1.3c0.2,0.3,0.5,0.7,0.8,0.8c0.3,0,0.7-0.3,1-0.5c0.3-0.2,0.4-0.6,0.7-1c0.1,0.2,0.2,0.3,0.2,0.4
	c0.1,1.9,0.3,3.7,0.3,5.6c0,0.6-0.2,1.2-0.4,1.8c-0.1,0.3-0.1,0.6-0.1,0.9c0.3,0,0.7,0.1,1,0c0.6-0.2,1.1-0.5,1.6-0.8
	c0.4-0.2,0.8-0.3,1.2-0.4c0.1,0.1,0.2,0.2,0.3,0.2c-0.5,1.3-0.9,2.6-1.4,3.8c-0.2,0.4-0.5,0.7-0.7,1.1c-0.2,0.5-0.3,1-0.5,1.4
	c0.6,0,1.2,0,1.8-0.1c0.4-0.1,0.8-0.3,1.4-0.6c-0.2,0.6-0.2,0.8-0.3,1c-1.1,2.5-2.7,4.6-4.7,6.3c-0.6,0.6-1.5,0.8-2.3,1.2
	c-0.3,0.2-0.4,0.5-0.6,0.7c0.3,0.1,0.5,0.4,0.8,0.4c0.9,0,1.8-0.1,2.7,0c0.4,0,0.8,0.2,1.2,0.3c-0.1,0.5-0.1,1.2-0.4,1.4
	c-1.2,0.9-2.4,1.8-3.8,2.5c-1,0.5-2.3,0.7-3.4,1c-5.1,1.6-9.6-0.1-13.8-2.8c-0.4-0.3-0.8-0.6-1.2-0.8C23.8,40.2,23.9,40.1,24,39.9z
	 M28.9,21.4c-0.5,0.4-1,0.8-1.2,1c-0.1,0.9-0.3,1.5-0.3,2.1c0.4-0.4,0.8-1.2,1.3-1.3c2.7-0.3,4.6-2.1,6.8-3.4
	c-2.3,0.5-4,2.9-6.7,2.7C28.7,22,28.8,21.7,28.9,21.4c1.9-3.6,3.7-7.2,5.6-10.8c0,0-0.1-0.1-0.1-0.2c-0.2,0.2-0.4,0.4-0.5,0.6
	c-1.5,2.8-2.9,5.6-4.4,8.5C29.2,20,29.1,20.8,28.9,21.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M22.5,51.1c-0.5-0.5-1-0.7-1-1c-0.1-2.8,0-5.5,0.1-8.2C21.9,44.8,22.2,47.8,22.5,51.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M14.4,123.7c0.5-0.9,1-1.8,1.5-2.6c0.2,0,0.3-0.1,0.3,0c2.2,1.2,2.3,0.5,2.9-1.1c0.6-1.4,1.4-2.8,2.2-4.1
	c0.3-0.5,1-0.9,1.6-1.5c0.4,0.4,0.6,0.8,1,1.1c0.3,0.2,0.7,0.6,1,0.5c0.3,0,0.5-0.5,0.7-0.8c0.8-1.6,1.6-3.3,2.5-4.8
	c0.4-0.7,1.2-1.1,1.8-1.6c0.5,1.2,0.9,2.1,1.3,3c0.1,0.3,0.5,0.5,0.8,0.5c0.1,0,0.4-0.4,0.5-0.6c0.6-1.7,1.6-3.1,3.2-4
	c2.2-1.4,4.4-2.9,7.1-3.4c0.7-0.1,1.7-0.3,2,0.1c0.4,0.4,0.3,1.3,0.2,2c-0.5,2.8-1.7,5.4-3,7.9c0,0.1,0,0.2-0.1,0.4
	c0.8-0.4,1.6-0.8,2.3-1.1c0.8-0.5,1.4-0.1,1.7,0.7c0.2,0.5,0.3,1,0.6,1.4c0.2,0.4,0.6,0.8,0.8,1.2c0.4-0.3,0.7-0.5,1.1-0.8
	c0.2-0.2,0.4-0.5,0.7-0.9c0.2,2,0.4,3.8,0.4,5.6c0,0.8-0.3,1.7-0.5,2.5c0,0.2,0,0.7,0.1,0.7c0.3,0.1,0.6,0.1,0.9,0
	c0.9-0.4,1.8-0.9,2.9-1.2c-0.1,1.5-0.5,2.9-1.2,4.2c-0.3,0.5-0.7,1-1,1.5c-0.1,0.2-0.3,0.6-0.2,0.7c0.2,0.2,0.6,0.4,0.8,0.4
	c0.8-0.2,1.6-0.5,2.6-0.8c-0.3,0.7-0.4,1.2-0.7,1.7c-1.1,2.3-2.7,4.2-4.6,5.8c-0.6,0.5-1.4,0.7-2.1,1.1c-0.2,0.1-0.4,0.4-0.6,0.6
	c0.3,0.2,0.5,0.5,0.8,0.5c0.9,0.1,1.8,0,2.7,0c0.4,0,0.8,0.2,1.2,0.3c-0.1,0.4-0.1,1.1-0.4,1.3c-1.2,0.9-2.5,1.9-3.8,2.6
	c-1.1,0.5-2.4,0.7-3.5,1.1c-3.9,1.2-7.6,0.5-11.1-1.4c-1.3-0.7-2.7-1.2-3.6-2.7c4.1-0.2,8.1-0.4,11.4-3.2c-0.6,0.3-1.1,0.6-1.7,0.9
	c-2.8,1.5-5.8,2.1-8.9,1.8c-1.7-0.1-1.8-0.3-1.4-1.9c0.3-1.3,0.6-2.6,0.9-4c0.1-0.6,0.4-1,1.2-1c4.3-0.5,9.1-2.3,12-4.7
	c-2,1-3.9,2.1-5.9,3c-2.1,0.9-4.2,1.4-6.8,1.1c0.8-1.9,1.4-3.7,2.1-5.5c-0.1,0-0.1-0.1-0.2-0.1c-0.4,0.7-0.7,1.4-1.1,2.1
	c-2.2-2.3-2.5-5-2.3-7.9c-0.1,0.2-0.3,0.4-0.3,0.6c-0.6,2.7,0.3,5,1.7,7.3c0.8,1.2,0.2,2.8-1.2,3.3c1,1.3-0.2,2.4-0.3,3.5
	c-1,0-2.1,0.2-3.1,0c-0.5-0.1-0.8-0.7-1.2-1.1c-0.5-0.5-1-1.1-1.6-1.5c-2.3-1.3-3.3-3.3-3.6-5.8c0-0.3-0.1-0.5-0.1-0.9
	c-0.6,1.9,0.5,4.8,2.4,6.3c1,0.8,2,1.7,3,2.6c1.2,1.1,1.6,1.2,4,1c0.1,0.8-0.1,1.3-0.7,2c-0.6,0.8-1.2,1.4-2.1,1.5
	c-2.7,0.5-5.4,1.9-8.2,0.9c-0.9-0.3-1.7-1.1-2.5-1.6c0.2,0.3,0.4,0.8,0.8,1.6c-1.7-1.4-3.4-2.4-3.2-4.8c0-0.8-0.3-1.7-0.6-2.4
	c-0.9-2.2,0.6-4,0.9-6.1c1.9,1,2.1,0.9,2.6-1.1C14.1,124.8,14.3,124.2,14.4,123.7C14.4,123.6,14.4,123.7,14.4,123.7z M33.6,120
	c-0.5,0.7-1.1,1.5-1.7,2.4c0.1,0,0.4,0.3,0.7,0.3c2.4-0.1,4.3-1.5,6.2-2.9c-1.9,0.5-3.3,2.5-5.8,2.1C33.2,121.2,33.4,120.6,33.6,120
	c0.2-0.3,0.4-0.6,0.6-0.9c1.3-2.6,2.7-5.1,4-7.7c0.2-0.5,0.5-0.9,0.7-1.3c-0.7,0.6-1.2,1.2-1.6,2C36.1,114.7,34.9,117.4,33.6,120z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }}
              d="M26.9,150.6c-0.4-0.4-1-0.7-1-0.9c-0.1-2.5,0-5,0.1-7.6C26.3,144.7,26.6,147.4,26.9,150.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M131.7,162.2c0,3,0.1,5.8,0.1,8.7c0.1,3.9,0.3,7.7,0.4,11.6c0.1,2,0,4.1,0,6.1c0,0.8-0.3,1.3-1.3,1.3
	c-4.6,0-9.2,0.1-13.9,0.1c-3.1,0-6.1,0-9.2,0c-1.7,0-2.1-0.3-2.1-2c-0.1-6.3-0.3-12.5-0.4-18.8c0-2.3,0.2-4.6,0.4-6.9
	c0-0.2,0.5-0.6,0.8-0.6c2.6,0,5.3,0,7.9,0.1c4.1,0.1,8.2,0.3,12.3,0.4C128.3,162.2,129.8,162.2,131.7,162.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M39.9,161.6c8.6,0.2,17.1,0.5,25.9,0.7c0.1,2.3,0.2,4.7,0.3,7c0.1,4.3,0.3,8.6,0.4,12.9c0,2.1,0,4.2,0,6.3
	c0,0.8-0.2,1.2-1.2,1.2c-4.7,0-9.3,0.1-14,0.1c-3.1,0-6.1,0-9.2,0c-1.6,0-2-0.3-2.1-1.9c-0.1-6.2-0.3-12.5-0.4-18.7
	c0-1.7,0.2-3.5,0.3-5.3C39.9,163.4,39.9,162.7,39.9,161.6z" />
      </SvgIcon>
    )
  }
};