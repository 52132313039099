import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_5_1 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 278.6 259.4"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M245.1,127.3c0.1-2.3,1.1-4.2,1.9-6.2c0.4-0.8,0.6-1.7,0.7-2.6c0.1-0.4-0.1-1-0.4-1.4c-0.8-1-1.6-1.9-2.5-2.7
	c-3.2-3.3-3.7-7-2.1-11.2c0.7-2.1,1.4-4.2,2-6.1c-0.9-0.7-1.5-1.3-2.3-1.9c-0.4,1-0.7,1.9-1.1,2.8c-0.2-0.1-0.3-0.1-0.5-0.2
	c0.4-3.3,1-6.6,1.2-9.9c0.2-3.5-0.7-6.8-1.9-10.1c-0.1,0.4-0.2,0.8-0.1,1.2c0.1,1,0.3,2,0.3,2.9c0,1.4-0.8,2.5-1.9,2.6
	c-0.7,0.1-1.5-0.4-2.3-0.7c-1.4-0.5-2.2-1.6-2.6-2.9c-0.5-1.5-0.5-3.2-0.7-4.8c-5.3-0.1-11-0.3-16.3-0.4c-0.2,1.4-0.3,3-0.7,4.4
	c-0.7,2.2-3.1,3.1-4.7,1.9c-1.7-1.3-2.9-3-2.4-5.4c0-0.2-0.1-0.5-0.1-0.7c-0.2,0.1-0.6,0.2-0.6,0.4c-0.2,0.9-0.4,1.9-0.3,2.9
	c0.2,1.6,0.8,3.2,1,4.8c0.3,2,0.4,3.9,0.5,5.9c0.1,1.6-0.1,3.2-1.1,4.5c0.1,0.1-1.3,1-1.2,1.1c0.2,0.2,0.4,1.1,0.7,1.2
	c2.5,0.3,6.5,3,8.7,5.8c1.4,1.9,2.3,3.4,2.8,5.7c5.3,0.5,11.5,0.4,16.7-0.7c-0.8,0.6-1.7,1.1-2.6,1.4c-2.6,0.7-5.3,0.6-8,0.7
	c-1.6,0.1-4.2-0.3-5.9-0.3c0,0.2,0,0.4,0.1,0.6c0,0.4,0.1,0.9,0,1.1c3.8-0.2,8.2-0.2,11.8-0.3c-0.4,0.5-0.8,0.9-1.2,1
	c-3.4,0.6-7.7,0.9-11.1,0.4c0,0-0.2,0.4-0.3,0.4c-1.7,4.8-8.9,8.2-15,9.9c0.7,1.3,0.4,2.7-0.8,3.8c-0.1,0.1-0.1,0.2-0.1,0.3
	c-0.2,1.8-0.5,3.6-0.7,5.4c1.2,0.3,2.1,0.4,3,0.7c0.5,0.2,0.9,0.5,1.3,0.9c0,0-0.3,0.7-0.3,0.6c-0.5-0.1-1.1-0.3-1.5-0.5
	c-1-0.6-2.7-0.8-3.1-0.2c-1,1.2-1.9,2.5-2.9,3.9c1.2,0.2,2.1,0.5,3,0.6c2.6,0.4,2.6,0.4,3.8-1.9c0.3-0.6,0.6-1,1.3-0.6
	c-0.5,1.1-1,2.1-1.6,3.1c-0.2,0.3-0.5,0.6-0.7,0.6c-1.5,0.1-3.1,0-4.8,0c0,0.4,0.1,0.9-0.1,1.3c-0.4,1-0.1,1.7,0.7,2.4
	c1.4,1.1,2.8,2.3,4.1,3.5c-0.3-0.1-0.4-0.6-0.2-1c0.3-1-0.2-2.3,1.1-2.8c0.2-0.1,0.4-0.1,0.6,0c0.1,0,0.2,0.3,0.1,0.4
	c-1.2,1.3-0.3,2.9-0.7,4.2c-0.3,1,0.2,1.7,0.6,2.5c3.1,5,5.4,10.4,6.8,16.1c0.9,3.9,2.3,7.7,2.6,11.7c0.1,1.1,0.1,2.3,0.2,3.4
	c0.7-2.8,1.3-5.6,1.7-8.4c0.2-1.1,0.3-2.4-0.2-3.3c-1.2-2.2-1.5-4.4-1.5-6.8c0-0.5,0-1,0-1.6c-0.8,0.6-1.4,1.1-1.9,1.5
	c-0.1-0.1-0.3-0.2-0.4-0.3c0.4-0.8,0.7-1.7,1.2-2.4c2-2.5,4-5.1,4.8-8.3c0.2-0.7,0.3-1.3,0.5-2c1-3.1,2.2-6.1,3.1-9.2
	c0.5-1.7,0.4-3.6,0.5-5.4c0-0.2-0.4-0.6-0.7-0.7c-0.9-0.5-1.4-1.1-1.3-2.2c0.1-0.6,0-1.1-0.1-1.7c-0.1-1.9-0.3-3.9-0.4-5.8
	c-0.1-1.3,0.1-2.7,0-4c-0.1-1,0.4-1.9,1.2-2c0,1.8,0,3.6,0,5.5c0.1,2.6-0.3,5.3,0.7,7.9c0.4,1,0.7,1.6,1.8,1.7
	c0.8,0.1,1.7,0.2,2.5,0.4c0,0.1,0,0.2,0.1,0.4c-0.6,0.1-1.1,0.2-1.7,0.4c-0.4,0.2-0.8,0.5-0.9,0.8c-0.2,1.1-0.4,2.2-0.2,3.2
	c0.4,3.3,0.9,6.5,1.6,9.8c0.5,2.5,1.3,4.9,2,7.3c0.2,0.7,0.7,1.3,1.2,2.1c0.6-1.2,1.1-2,1.6-2.9c0.1,0,0.2,0.1,0.4,0.1
	c-0.1,0.4,0,0.8-0.2,1.1c-0.6,1.1-1.1,2.2-1.8,3.2c-1.9,2.6-2,4.3,0,6.9c0-0.4,0-0.8,0.1-1.1c0.1-0.3,0.3-0.6,0.6-0.7
	c0.2-0.1,0.7,0,0.9,0.1c0.1,0.1,0,0.6-0.1,0.8c-0.3,0.6-0.5,1.2-0.9,1.6c-2.8,2.7-3.3,6.1-3.4,9.7c-0.1,4,0.3,8,1.6,11.9
	c0.9,0,1.8,0.1,2.6,0c1.5-0.3,2.9-0.7,4.3-1.2c2.2-0.7,4.3-1.6,6.5-2.2c1.2-0.3,2.6-0.1,4-0.1c-1.9-2.4-3.7-4.7-5.5-7
	c1.5-0.6,2.3,0.4,3.1,1.2c0.4-0.7,0.7-1.2,1-1.8c0.7-1.3,1.3-2.6,1-4.2c-0.2-1.1-0.4-2.3-0.6-3.4c-0.3-2-0.6-4-0.8-6
	c-1,1.6-3.1,2.6-3,5.1c-1.4-0.4-1.5-0.7-1-1.8c0.1-0.3,0.3-0.7,0.5-0.9c1.7-1.9,3.4-3.9,5.2-5.7c2.5-2.5,3.1-5.4,2.3-8.6
	c-0.8,0-1.4,0-2.1,0c-1.2-0.1-2.2-0.4-2.9-1.8c-0.9-1.7-1.4-3.4-1.3-5.4c0.1-1.6-0.3-3.2,1.3-4.3c0.1-0.1,0.1-0.2,0.1-0.1
	c-1-1.6-2-3.1-3.2-5.1c2-0.7,3.6-1.3,5.4-2c0,0,0-0.1,0-0.1c-0.2-3-0.5-5.9-0.7-8.9c0-0.3,0.6-0.7,0.9-1.1c0.1,0.1,0.2,0.2,0.4,0.2
	c-0.1,0.7-0.3,1.3-0.3,2C244.8,126.1,245,126.7,245.1,127.3z M233.6,84.3c-1.1,2.3-0.7,4.8-0.8,7.2c0,2.2,0,4.4-0.6,6.4
	c-0.8,3-3.1,4.1-5.3,4c-4.7-0.1-8.5-1.1-11.8-3.4c-0.4-0.2-0.7-0.7-0.8-1c-0.7-1.8-0.7-3.7-0.4-5.5c0.3-1.8,0.5-3.6,0.6-5.4
	c0.1-2.6,1.3-4.4,3.7-4.5c1.6-0.1,3.2,0.1,4.7,0.3c3.4,0.3,6.8,0.6,10.2,0.9C233.8,83.4,233.9,83.8,233.6,84.3z M226.2,132.1
	c-0.2,0-0.3,0-0.5,0c-0.1-1.1-0.2-2.3-0.3-3.4c0-1.3-0.1-2.7,0-4c0.1-1.6,0.1-3.3,1.4-4.6C225.7,124,226.7,128.1,226.2,132.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M25.6,196.2c-1.6-1.2-3.4-2.3-4.9-3.7c-0.6-0.5-0.6-1.7-0.8-2.6c-0.4-1.9-0.7-3.9-0.9-5.8
	c-0.2-1.7-0.2-3.5-0.3-5.2c0-2.6-0.2-5.3,0-7.9c0.2-3.1,0.8-6.1,1.3-9.1c0.3-2.3,0.4-4.6,1-6.8c0.8-3.3,2-6.5,3-9.8
	c1-3.5,2.9-6.6,4.9-9.7c0.7-1.1,1.2-2.3,1.7-3.3c0.5,1.5,1,3.1,1.5,4.6c-0.9,2.1-1.8,4.2-2.8,6.4c-0.5,1.1-1.3,2.2-1.9,3.3
	c-1.4,2.5-2.3,5.1-2.1,8.1c0,0.3,0,0.6,0,0.8c-0.1,1.1,0.2,1.5,1.3,1.5c0.4,0,0.9,0,1.5,0c-0.2,0.8-0.6,1.3-0.6,1.9
	c0,2.1-1.1,3.5-2.3,5c-1,1.3-0.8,2.3,0.5,3.5c0.3,0.3,0.5,0.6,0.8,0.9c-1.9,2.7-1.8,5.7-1.2,8.7c0.4,2,1.1,3.9,2.2,5.8
	c0.8,1.4,1.5,2.7,2.6,3.9c2.5,2.8,5.8,3.8,9.2,4.7c3.2,0.8,6.4,0.6,9.6,0.7c4.6,0.1,9-0.6,13.4-2.2c3-1.1,5.3-3.1,7.6-5.1
	c1.6-1.5,2.9-3.1,3.3-5.3c0.6-3.3,1.1-6.5,0.3-9.8c-0.1-0.3,0.2-0.6,0.4-0.9c0.7-1,1.1-1.9,0.2-3c-1.1-1.4-1.7-3-1.8-4.8
	c0-0.3-0.3-0.7-0.4-1c0.2,0,0.4-0.1,0.6,0c1.1,0.3,2.2-0.4,2-1.5c-0.3-2.1-0.7-4.2-1.3-6.2c-0.4-1.6-1.1-3.2-1.8-4.7
	c-1.1-2.5-2.4-5-3.4-7.6c-0.4-0.9-1.2-2,0-3.4c1.4,2.1,2.8,4,4,6c1,1.6,1.8,3.3,2.7,5c1.9,3.5,2.7,7.3,3.4,11.2
	c0.4,2.2,0.9,4.5,1.3,6.7c0.3,1.6,0.5,3.3,0.6,4.9c0.1,3.7,0.3,7.4,0.2,11.1c-0.1,3.5-0.6,7.1-1.8,10.4c-0.8,2.1-2.6,2.7-4.5,3.3
	c-0.3,0.1-0.7,0.1-1.1,0.2c1.6-1.8,2-3.9,2.2-6.2c-1.2,0.3-1.3,1.2-1.4,1.9c-0.5,3.2-2.7,5.2-5.9,5.6c-2.4,0.3-4.9,0.5-7.3,0.7
	c-0.5,0.1-1.1,0.2-1.5,0.2c0.9-1.3,1.8-2.6,2.7-3.9c-0.1-0.1-0.2-0.3-0.3-0.4c-0.4,0.1-1,0.2-1.1,0.4c-1.1,3.2-3.8,4.1-6.7,4.7
	c-3.4,0.7-6.8,0.8-10-0.6c-0.7-0.3-1.2-1-1.9-1.3c-0.6-0.3-1.3-1-2.2-0.2c0.9,0.8,1.8,1.6,2.8,2.5c-2.8,0.9-5.4,1-8.2,0.6
	c-3-0.4-5.1-1.7-6.6-4.1c-0.9-1.5-1.6-3.3-2.4-4.9c-0.1-0.3-0.3-0.5-0.5-0.7c-0.1,0.3-0.5,0.6-0.4,0.8c0.4,1.5,0.8,2.9,1.3,4.4
	c0.1,0.4,0.3,0.7,0.5,1C25.8,196,25.7,196.1,25.6,196.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M65.9,101.7c-0.6,1.2-1.5,2.3-2,3.7c-1.3,3.6-2.1,7.3-2.5,11.1c-0.3,2.6-1,5.1-2.6,7.3c-2.4,3.4-4.3,7-6.4,10.6
	c-0.4,0.8-0.6,1.7-0.8,2.6c-0.1,0.2,0.2,0.6,0.4,0.8c0.9,0.9,1.1,2,0.3,3.1c-0.4,0.7-0.9,1.4-1.5,2.2c-0.9-0.9-1.9-1.8-2.7-2.9
	c-0.3-0.5-0.5-1.5-0.2-1.9c1.6-1.7,1.2-3.5,0.8-5.4c-0.9-4.4-3-8.3-5.6-11.9c-0.8-1-1.3-2.2-1.9-3.4c-0.1-0.2-0.1-0.4-0.1-0.6
	c0.6-2.6,0.1-5.2-0.6-7.7c-0.5-1.8-1.3-3.6-1.7-5.4c-0.4-1.7-0.8-3.5-0.7-5.2C38.5,94,36.6,90,34,86.2c-0.5-0.7-0.9-1.5-1.4-2.2
	c1.4-0.6,2.7-1.2,4-1.8c0.4,1.7,0.7,3.6,1.3,5.3c1.2,3.7,2.7,7.2,3.7,11c0.6,2.3,2.1,3,4.1,3.1c2.3,0.2,4.5,0.3,6.8,0.2
	c1.9-0.1,3.9-0.4,5.8-0.7c1.5-0.3,2.4-1.4,2.9-2.8c1.5-4,2.6-8,2.2-12.4c0-0.5,0.1-1,0.1-1.3c1.7,0.9,3.4,1.8,4.9,2.6
	c-2.2,3.3-3.7,7.1-4.3,11.3c-0.1,0.7-0.6,1.3-1.1,2.1C63.9,100.9,64.9,101.3,65.9,101.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M55.4,45.3c-0.2,1.1,0.6,1.7,1.4,1.9c1.7,0.5,3.1,1.4,4.4,2.6c0.3,0.3,1,0.1,1.5,0.2c-0.1-0.4-0.2-0.9-0.4-1.2
	c-0.4-0.5-0.9-0.8-1.3-1.3c1.4,0,3.3,2.4,2.9,3.6c-0.8,2-0.2,3.9,0.1,5.9c0.5,2.6,1.1,5.1,1,7.7c0,5.5-2.6,9.9-8.4,12.2
	c-2.3,0.9-4.7,1.5-7.2,1.2c-2.7-0.4-5.4-1.4-7.4-3.3c-2-1.9-4-4-5.3-6.3c-2-3.6-2.7-7.5-0.8-11.5c1-2.2,2.4-4.3,2.1-7
	c0-0.2,0-0.3,0-0.5c-0.8-2,0.6-3.1,1.8-4.2c0.2-0.2,0.9-0.2,1.1,0c0.5,0.6,0.9,0.6,1.5,0.1c0.4-0.4,0.8-0.8,1.3-1
	c0.4-0.2,1-0.3,1.4-0.2c0.4,0.1,0.9,0.6,1.1,0.5c0.4-0.2,0.7-0.7,1.1-1.2c0.1-0.1,0.1-0.3,0.4-0.5c0.1,0.4,0.2,0.9,0.3,1.7
	c0.9-0.4,1.8-0.8,2.7-1.2c0,0.1,0.1,0.3,0.2,0.5c0.2,0.3,0.5,0.6,0.7,0.9c0.3-0.3,0.7-0.5,0.9-0.8c0.3-0.5,0.5-1.1,1-1.9
	c0.6,1,0.9,1.8,1.4,2.5C54.8,44.9,55.2,45.1,55.4,45.3L55.4,45.3z M62.2,55.1c-0.2-2.7-1.5-4.7-3.8-6c-1.3-0.7-3.5-0.1-4.1,1.3
	c-0.8,2.2-0.3,4.4,0.5,6.5c0.4,1.2,1,1.9,2.4,2.1C59.6,59.3,62.4,57.3,62.2,55.1z M43.9,57.6c0.5-0.3,1.8-0.6,2.6-1.4
	c1.8-1.7,2.1-3.9,1.2-6.3c-0.6-1.7-2.1-2.4-3.8-1.7c-2.1,0.8-3.4,2.4-4.1,4.3C38.6,55.6,40,57.4,43.9,57.6z M56.6,66.8
	c-3.9,1.8-7.4,1.7-11-0.3c0.2-0.5,0.4-0.9,0.6-1.3c-1.4,0.4-2.8,1.8-2.9,3c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.1,0.4,0
	c0.3-0.2,0.5-0.5,0.8-0.8c4.2,2,8.4,2.4,12.5-0.4c0.4,0.7,0.6,1.3,0.9,2c0.4-0.5,0.7-0.9,1.2-1.4c-0.6-0.7-1.3-1.4-2-2.2
	c-0.7-0.8-1-0.8-1.8,0.1C55.9,66,56.2,66.3,56.6,66.8z M46.6,58.9c0,0.1,0,0.3,0,0.4c0.3,0,0.7,0.2,1,0c2.2-1.3,4.1-0.3,5.9,0.8
	c0.2,0.1,0.5,0.1,0.7,0c0.6-0.6,0.5-1.2-0.1-1.6c-0.8-0.5-1.7-0.8-2.5-1.1c-0.5-0.2-1.1-0.3-1.5-0.1C48.9,57.7,47.7,58.3,46.6,58.9z
	 M40.1,47.9c0,0.1,0.1,0.3,0.1,0.4c0.5-0.2,0.9-0.3,1.4-0.5c0.5-0.2,1-0.6,1.6-0.7c1.2-0.3,2.4-0.5,3.5-0.8c0.4-0.1,0.7-0.6,1.3-1
	C44.9,45.3,42.2,45.7,40.1,47.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M229.7,18.2c0.4,1,0.9,1.9,1.2,2.8c0.3,1.3,1.3,1.9,2.5,1.3c0.5-0.2,1-0.5,1.5-0.8c1.1-0.6,1.3-0.5,1.7,0.7
	c0.3-0.1,0.7-0.1,1-0.2c0,0.1,0.1,0.2,0.1,0.3c-0.2,1.9-0.5,3.8-0.6,5.8c-0.1,2.4-0.5,4.8,0.7,7.1c0.6,1.2,1.1,2.5,1.4,3.7
	c0.5,3.1,1.2,6.2-0.7,9.1c-2.5,3.7-5.9,6-10.3,6.9c-2.8,0.6-5.5,0.4-8.1-0.6c-2.8-1-4.9-3.1-6.7-5.4c-2-2.6-2.9-5.5-3.1-8.8
	c-0.1-2,0.8-3.6,1.3-5.4c0.3-1.1,0.9-2.2,1.3-3.3c0.6-1.5,0.3-2.9-0.3-4.3c-0.2-0.4-0.3-0.7-0.5-1.1c1.6,0.1,2.2-1.1,1.7-3.7
	c1.9,0.2,2.4-1.7,3.8-2.5c0.6,1.2,1.5,2.1,2.8,2.4c1.6,0.4,2-1.1,2.9-2.2c0,0.6,0.1,1,0.1,1.6c1.1-0.2,2.1-0.5,3.2-0.7
	c0.6,1.1,0.8,1.2,1.6,0.1C228.8,20.5,229.1,19.4,229.7,18.2z M227.3,32.6c-0.2,2.4,0.9,4.1,2.9,5.1c2.5,1.2,5.8-1.2,5.4-3.9
	c-0.2-1.2-0.6-2.4-1.2-3.5c-1-2-3.4-2.9-5.3-2.1c-0.6,0.3-1.3,1-1.5,1.6C227.3,30.6,227.4,31.6,227.3,32.6z M223,31.1
	c0.1,0,0.2-0.1,0.3-0.1c-0.4-0.9-0.5-2.1-1.2-2.8c-1.2-1.3-4.6-0.9-5.5,0.7c-0.9,1.5-1.5,3.2-2,4.8c-0.4,1.3,0.2,2.5,1.1,2.8
	C218.9,37.4,223.4,35.3,223,31.1z M231,45.2c-3.7,1.9-7.3,2.2-10.9,0c0.1-0.3,0.2-0.7,0.2-1c-1.5,0.1-1.9,0.7-1.9,2.6
	c0,0.7,0.3,1.1,1.1,1c0-0.5,0-0.9,0-1.4c1.9,1.5,3.9,1.7,5.9,1.4c2-0.3,3.9-0.9,5.8-1.4c0,0.1,0,0.7,0.1,0.7c0.5,0.1,1.1,0,1.7,0
	c-0.3-1-0.5-1.7-0.8-2.3c-0.4-1-1.2-0.7-2.1-0.7C230.4,44.4,230.7,44.7,231,45.2z M227.6,24.4c0.6,0.2,1.1,0.4,1.7,0.6
	c1.9,0.4,3.5,1.2,4.8,2.6c0.9,1,1.3,1.1,2.3,0.1C234.2,24.5,230.5,23.1,227.6,24.4z M222.7,24.3c-0.5-0.1-0.9-0.4-1.3-0.3
	c-1.5,0.4-3.2,0.7-4.6,1.5c-1.2,0.7-2,1.9-3,2.9c-0.1,0.1,0.1,0.5,0.1,0.8c0.2-0.1,0.5-0.2,0.6-0.4
	C216.4,25.9,219.6,25.7,222.7,24.3z M221,38.6c0.7-0.3,1.4-0.4,1.6-0.7c0.4-0.8,1-0.8,1.6-0.7c1,0.3,2.1,0.7,3.1,1
	c0.4,0.1,0.8,0.1,1.1,0.1c-0.2-0.4-0.3-0.8-0.5-1c-0.6-0.4-1.3-0.8-2-1C223.8,35.5,222.2,36.2,221,38.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M27.5,168.4c2.1,1.2,3.9,2.3,5.8,3.4c0,0,0.1,0,0.1,0c2.5,0.2,4.2,2,6.3,2.9c1.4,0.6,2.8,0.9,4.3,1.3
	c2.5,0.6,4.4,2.3,6.5,3.7c0.6,0.4,1.2,0.8,1.9,1.2c1.3,0.8,2.9,0.6,3.6-0.8c0.9-1.9,2.4-3.2,3.8-4.7c0.5-0.5,1.1-1,1.3-1.6
	c0.5-1.1,1.4-1.3,2.4-1.6c2.8-0.6,5.5-1.3,8.4-2.1c0,2.9,0.4,5.8-0.7,8.6c-0.3,0.8-0.4,1.6-0.7,2.3c-0.5,1-1,2.1-1.8,2.9
	c-1.8,1.7-3.6,3.5-6,4.6c-2.2,1-4.5,1.7-6.9,1.9c-2,0.2-4.1,0.4-6.1,0.5c-3.5,0.1-7-0.1-10.4-1c-2.1-0.6-4.2-1.1-6-2.3
	c-2.9-1.9-4.4-4.8-5.7-7.8c-1.3-3-1.5-6.2-0.9-9.4C26.8,169.9,27.1,169.4,27.5,168.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M31.8,86.4c0.8,1.1,1.2,2,1.9,2.7c1.9,2.3,2.6,5.1,3,7.8c0.3,2,0.2,4.2,0.6,6.2c0.3,1.6,1,3,1.4,4.6
	c0.6,2.4,1.1,4.8,1.6,7.3c-0.1,0-0.2,0.1-0.3,0.1c-0.2-0.4-0.5-0.7-0.8-1.1c-0.9,1.6,0.2,2.8,0.7,4c1.8,3.6,3.8,7,5.6,10.6
	c1.2,2.6,2.3,5.4,2.1,8.4c0,0.4-0.3,0.9-0.6,1c-2.8,1.5-5.8,2.6-9,1.9c-1.2-0.3-2.2-0.8-3-2c-1.9-3-2.8-6.3-3-9.7
	c-0.3-7-2.2-13.7-4.5-20.3c-1.3-3.8-2-7.6-1.7-11.6C25.9,93.5,27,91,29,89C29.9,88.1,30.8,87.3,31.8,86.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M65,117.5c-0.8,0.6-1.6,1.3-2.8,2.1c1.1-5,2-9.8,3.2-14.5c0.4-1.5,1.6-2.9,2.4-4.3c0.2-0.3,0.4-0.5,0.6-1
	c-1.3,0.8-2.6,1-3.7,0.1c0.6-2.2,1-4.4,1.7-6.5c0.5-1.6,1.2-3.1,2-4.5c0.1-0.2,1-0.4,1.2-0.2c1.2,1,2.5,2,3.5,3.2
	c0.9,1,1.4,2.3,2.2,3.3c2.6,3.2,2.5,6.8,2,10.5c-0.6,4.6-2.7,8.8-4.6,12.9c-0.8,1.7-1.7,3.4-1.5,5.4c0,0.3-0.2,0.8-0.4,0.9
	c-1.5,0.6-1.9,1.9-2.4,3.2c-1.4,3.5-2.7,7.1-4.2,10.6c-0.7,1.7-1.5,2.1-3.2,1.2c-2.5-1.2-5-2.4-7.7-2.5c1.1-3.9,2.9-7.3,5.1-10.6
	c2.1-3.1,3.9-6.5,7-9C65.4,117.8,65.2,117.7,65,117.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M240.1,19.8c-0.6,0.2-1.3,0.4-1.9,0.6c-0.3,0.1-0.7,0.2-0.8,0.1c-1-0.7-1.9-0.3-2.7,0.2
	c-1.5,0.9-1.4,0.9-2.1-0.6c-0.4-0.8-0.9-1.6-1.2-2.4c-0.4-1-1.3-1.1-2.2-1c-0.2,0-0.5,0.3-0.6,0.5c-0.3,0.7-0.5,1.4-0.9,2
	c-0.1,0.2-0.4,0.4-0.7,0.5c-0.6,0.3-1.2,0.5-1.9,0.7c0,0-0.3-0.3-0.3-0.5c0-1.2-0.9-1.1-1.7-1.2c-0.4,0-0.9-0.1-0.9,0.8
	c0,0.6-0.6,1.3-1.2,2.3c-0.7-1-1.3-1.8-1.8-2.6c-0.4-0.8-0.7-1.7-1-2.6c-0.1-0.1-0.2-0.3-0.3-0.4c-0.6-0.1-1.3-0.2-2-0.3
	c-0.1,0-0.2,0.2-0.1,0.1c0.2,1.3,0.3,2.4,0.4,3.5c0,0.2-0.4,0.6-0.7,0.7c-1.1,0.2-1.2,0.3-1.1,1.4c-0.3-0.1-0.5-0.2-0.8-0.2
	c-0.5,0-1.2,0-1.6,0.3c-0.2,0.1,0,0.9,0.1,1.4c0.1,0.6,0.3,1.3,0.5,2.1c-1.3-1-1.7-3.1-3.8-2.2c0-0.1,0-0.2-0.1-0.3
	c-1.4-1.5-0.5-3,0-4.6c0.2-0.5,0.5-1.1,0.4-1.4c-0.5-1.3,0.2-1.9,1.2-2.4c0.6-0.3,1.2-0.6,1.8-0.8c1.7-0.6,2.2-2.1,0.8-3.6
	c1.5-0.4,3.9,0,2.9-3c1.1,0.5,2.2,1.1,3.2,1.5c0.9,0.3,1.9,0.6,2.9,0.8c0.5,0.1,1.3-1.4,1.2-2.3c1.1,0.4,2.1,0.8,3.2,1.1
	c0.4,0.1,1,0.2,1.1,0c0.3-0.5,0.5-1.1,0.4-1.7c-0.1-0.5-0.5-1-0.8-1.6c3.2,0.6,5,2.7,6.4,5.1c0.5,0.9,0.8,2,2.3,1.4
	c-0.4,0.4-0.8,0.7-1.2,1.1c-0.2,0.2-0.3,0.6-0.2,0.8c0.1,0.2,0.6,0.4,0.8,0.3c1.8-1,2.9,0.4,4.1,1.2c1,0.7,1.8,1.9,2.8,2.9
	c-1.1-0.1-1.9-0.2-2.8-0.2c-0.2,0-0.5,0.3-0.7,0.4c0.1,0.2,0.3,0.4,0.4,0.6C239.4,18.8,239.7,19.3,240.1,19.8L240.1,19.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M242.9,93.4c0.2-1.3,0.3-2.5,0.4-3.6c0.6-3.8-0.1-7.4-1.2-10.9c-0.6-1.7-0.6-3.6-0.9-5.5
	c-0.1-0.8-0.1-1.7-0.4-2.4c-0.9-2.4-0.7-4.8-0.5-7.3c0.1-0.9,0.9-1.1,1.9-0.7c2.2,0.9,3.7,2.6,4.8,4.6c1.1,1.8,2.1,3.7,2.8,5.7
	c0.8,2.3,1.3,4.7,1.6,7.1c0.4,3.9,0.6,7.7,0.8,11.6c0.2,4.8-1.6,8.7-5.4,11.6c-1.1,0.8-2.1,1.8-3.3,2.8c0.1-1.8,0.6-3.4,1.1-5
	c0.6-1.6,0.9-3.2,1.2-4.9c0.1-0.5-0.3-1.2-0.7-1.6c-0.6-0.6-0.6-1.1-0.4-1.9c1.2-4,1.3-8.1,0.3-12.2c-0.2-0.9-0.2-1.9-0.3-2.9
	c-1,0.5-1.4,1.7-1.3,2.8c0.3,2.3,0.6,4.6,0.6,6.9c0,1.7-0.5,3.4-0.7,5.1C243.3,93.1,243.1,93.2,242.9,93.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M65.3,164.9c-2.5,1.4-3.3,3.8-4.2,6.3c-0.8,2.2-2.2,4.2-4.1,5.7c-1.1,0.8-1.9,1.8-2.3,3c0,0.1-0.2,0.2-0.4,0.5
	c-1.2-0.8-2.6-1.6-3.7-2.5c-1.9-1.6-4.1-2.4-6.4-3.1c-2.2-0.6-4.3-1.2-6-2.9c-0.6-0.5-1.1-1.1-1.6-1.8c-1.1-1.6-2.1-3.2-3-4.7
	c0.9,0.1,2,0,3.1,0.3c3.4,0.7,6.7,1.5,10,2.4c1.5,0.4,2.7-0.1,4-0.5c2.6-0.8,5.1-1.8,7.8-2.4c2.2-0.5,4.5-0.5,6.7-0.7
	C65.2,164.6,65.3,164.7,65.3,164.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M235,60.4c1.4,2,1.1,3.9,0.8,5.7c-0.4,2.3-1,4.7-1.5,7c-0.4,1.8-0.7,1.8-2.5,1.7c-2.1-0.2-4.3-0.1-6.4-0.1
	c-1.7,0-3.5-0.1-5.2-0.2c-0.9-0.1-1.9-0.4-2.8-0.3c-1.2,0.1-1.7-0.4-1.9-1.5c-0.4-2-1.2-3.9-1.3-5.9c-0.1-1.5,0.5-3,0.8-4.5
	c0.1-0.5,0.3-1.1,0.5-1.6c0.2-0.5,0.6-0.9,0.9-1.3c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.2-0.3,0.4-0.4,0.6c-0.7,0.9-0.6,1.8,0.4,2.4
	c1.1,0.7,2,1.7,3.2,2c4.5,1.2,8.8,0.5,12.6-2.3C233.4,61.9,234.1,61.2,235,60.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M62.2,85.3c-0.1,1.5-0.3,2.9-0.2,4.2c0.2,2.6-1,4.8-1.5,7.1c0,0.2-0.1,0.3-0.2,0.4c-0.9,2.5-1.5,3-4.2,3
	c-3.4,0.1-6.8,0-10.2,0c-0.6,0-1.2-0.2-2-0.3c-1.4-3.7-2.8-7.4-4.2-11.2c-0.6-1.6-0.9-3.3-1.3-4.9c-0.1-0.5-0.2-1.1,0.8-1
	c0.5,0.1,1-0.1,1.6-0.1c-1.7,1.1-1.2,2.7-0.9,4.1c0.2,0.7,0.7,1.3,1.2,1.8c2.3,2.1,5.1,3.3,8.1,3.8c3.8,0.6,9.5-1.4,10.7-6.4
	c0.2-0.9,0.3-1.7,1.5-0.7C61.6,85.3,61.9,85.2,62.2,85.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M58.4,219.2c0.3-0.3,0.5-0.5,0.8-0.8c0.7,0.6,1.4,1.2,2.1,1.8c-0.5,0.7-0.9,1.4-1.4,2c0.1,0.1,0.2,0.2,0.3,0.3
	c0.3-0.1,0.8-0.1,0.9-0.3c1.6-2.2,4-2.9,6.5-3.3c2.7-0.5,6,1.4,7.2,4.1c0.4,0.9,1.3,1.8,0.6,3c-0.2,0.4-0.5,0.9-0.8,1
	c-1.6,0.5-3.3,1-5.1,0.7c-2-0.4-4-0.3-6-0.6c-2.4-0.3-4.4-1-5.6-3.5c-0.8-1.6-2.2-2.8-3.5-4.2c-0.4,0.5-0.6,0.6-0.8,0.8
	c-1.4-0.6-2-1.7-2-3.2c0-2-0.4-4.2,2-5.5c-0.3,1.4-0.6,2.7,0.7,3.7c0.2,0.2,0.7,0.3,0.9,0.2c0.9-0.5,1.8-0.9,2.5-1.6
	c1.4-1.3,2.9-2.3,5-1.8c0.5,0.1,1,0,1.5,0.1c0.3,0.1,0.6,0.3,1.1,0.5C59.9,214,56.8,217,58.4,219.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M57.2,77.7c0.1,0.2,0.3,0.4,0.3,0.6c-0.6,1.8,0.1,3.4,0.9,4.8c1.3,2.4-0.2,4.5-1.5,5.7c-2.7,2.5-5.8,2.2-9,1.5
	c-2.5-0.5-4.3-2.1-6.1-3.6c-1-0.8-0.8-1.5-0.3-2.6c0.7-1.6,1-3.4,1.5-5.1c0.1-0.4,0.2-0.8,0.4-1.4C48,80.7,52.6,79.5,57.2,77.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M55.8,43.1c-2.6-2.3-2.6-2.3-3.6-2.2c0,0.7-0.1,1.3-0.1,2.2c-1.3-1.6-2.3-1.3-2.7-0.3c-0.6-0.4-1.1-1-1.7-1.2
	c-0.8-0.3-1.4,0.1-1.3,1c-0.7,0.1-1.3,0-1.9,0.2c-1.2,0.3-2.2,1-3.6,0.8c-0.9-0.1-1.9,1-2.8,1.6c0.2-1.3,0.4-2.8,0.7-4.2
	c0.7-3.3,3.3-4.9,6.1-6.1c4.8-2.1,9.4-1.2,13.5,1.7c1.4,1,2.4,2.6,3.5,4c0.8,1.2,1.5,2.6,2,3.9c0.3,0.9,0.3,1.9,0.5,2.9
	c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2-0.3-0.5-0.6-0.6-1c-0.2-1.5-0.9-1-1.7-0.6c-0.3,0.2-0.6,0.3-0.8,0.4c-0.1-0.8-0.1-1.6-0.2-2.3
	c-0.1-0.3-0.6-0.8-0.8-0.7c-0.9,0.4-1.2-0.2-1.5-0.8c-0.2-0.4-0.3-0.8-0.6-1.2c-0.3-0.3-0.7-0.5-1-0.7c-0.2,0.3-0.5,0.6-0.6,1
	C55.8,42.1,55.9,42.5,55.8,43.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1}
              fill={this.state.color17 ? this.state.color17 : 'transparent'} onClick={() => {
          this.setState({ color17: color, opacity17: opacity });
        }} d="M229.9,189.7c1.3-0.4,2.6-0.8,3.9-1.3c2.2-0.7,4.3-1.6,6.6-2.3c2.2-0.7,4.2,0.3,5.7,2.5
	c-0.7,0.2-1.3,0.3-1.9,0.5c-0.3,0.1-0.5,0.5-0.8,0.8c0.3,0,0.7,0.1,1,0c2.4-0.6,4.7-0.4,7.1,0.3c2,0.6,3.2,2.2,4.3,3.8
	c0.7,1,0.2,2.1-0.9,2.3c-4,0.5-8,0.8-12-0.4c-0.6-0.2-1.2-0.4-1.6-0.7c-1.7-1.6-3.8-1.9-6.1-1.9c-1.3,0-2.6-0.3-3.9-0.7
	c-0.9-0.2-1.4-1-1.2-2C230.2,190.5,230,190.2,229.9,189.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M40.2,78.5c-0.6-1.1-1.4-2-2.8-1.4c-1,0.4-1.9,0.8-2.9,1.3c0,0.1,0.1,0.3,0.1,0.4c-0.3-0.2-0.6-0.3-0.9-0.5
	c0.3-1.1,0.6-2.1,1-3.5c-1.8,1.4-2.1,3.1-2.6,4.7c-4.8-4.9-7.5-16-1.4-25.3c0.9,1.7,1.9,3.4,2.6,5.1c0.3,0.7,0.1,1.6,0.1,2.4
	c0,3.5,1.3,6.6,3.4,9.4c1.3,1.7,2.7,3.2,4.1,4.8C42.1,77.4,42.1,77.4,40.2,78.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M31.9,209.7c0.4-0.7,0.9-0.9,1.8-0.3c1.7,1,3.3,1.9,4.1,3.8c0.1,0.2,1,0.3,1.4,0.1c1.4-0.7,2.7-1.5,2.9-3.6
	c0.4,0.6,0.7,0.8,0.9,1.1c1,1.9,1,5.3-2,6.2c-1.4,0.5-2.9,0.8-4.2,1.5c-0.7,0.3-1.3,1.1-1.9,1.6c-0.7,0.5-1.5,0.9-2.3,1.3
	c-2.8,1.3-5.8,1.3-8.7,0.4c-2.9-0.9-3.6-3.2-2.1-5.8c1.3-2.2,3.1-3.7,5.8-3.5c0.9,0.1,1.7,0.6,2.5,1.1c0.6,0.4,1,1.1,1.6,1.7
	c0.9,1,1.8,0.4,2.7,0.1c0.3-0.1,0.5-0.4,0.8-0.5c1.3-0.5,1.4-0.8,0.4-1.8C34.4,212,33.2,210.9,31.9,209.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }} d="M198.4,78.5c0.3,0.6,0.7,1.1,1,1.7c1.4,1.7,2.1,3.7,3.2,5.8c0.3,0.5,1.6,3.2,1.8,3.7c0-1-0.5-4.4-0.4-5.4
	c0.2-2.1,0.6-4.2,1-6.3c0.3-1.3,0.8-2.6,1.1-3.9c0.2-0.9,0.3-1.9,0.4-2.8c0.3,0,0.5,0,0.8,0.1c-0.1,1.5-0.2,3.1-0.3,4.6
	c1.4-0.6,2.3-1.5,2-3.2c-0.2-1-0.6-1.9-0.7-2.9c-0.2-2.7-0.2-5.3,0.3-8c-0.3,0.1-0.5,0.2-0.7,0.3c-1.9,1.4-3.8,2.6-5.6,4.1
	c-3.7,3-5,7-4.4,11.6C198.1,78.1,198.3,78.3,198.4,78.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }} d="M53.6,199.5c1-0.4,1.9-0.8,2.8-1c0.8-0.2,1.7,0,2.5-0.1c2.1-0.1,4.2-0.3,6.4-0.4c0.4,0,0.7-0.1,1.4-0.1
	c-0.4,1.4-0.8,2.6-1.1,3.9c-0.6,2.6-1.2,5.2-1.7,7.8c-0.1,0.8-0.2,1.2-1.2,1.1c-2.8-0.4-5,0.8-6.8,2.9c-0.3,0.3-0.6,0.6-1.1,1.1
	c-0.3-1.6-0.2-2.9,0.5-4c0.7-1.1,0.5-2.1,0-3.1C54.2,205,53.2,202.4,53.6,199.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }} d="M65.6,157.8C55,157.2,44.5,156.6,34,156c-0.1-1.5,0.5-2.6,1.6-3.6c0.8-0.7,1.4-1.6,2-2.3c0.7,0.6,1.3,1.2,2,1.6
	c0.4,0.2,1,0.2,1.5,0.3c0.4,0.1,1.1,0,1.3,0.3c1,1.2,1.9,0.4,2.8-0.1c0.6-0.4,2.5-0.4,3.2-0.1c0.3,0.2,0.7,0.4,1,0.3
	c0.9-0.5,1.3,0,1.8,0.7c0.8,1.1,2.3,1.2,3.2,0.1c0.4-0.5,0.8-0.5,1.3-0.2c1.5,0.7,1.7,0.6,2.8-0.7c0.5-0.6,1.3-0.9,1.9-1.2
	c0.1-0.1,0.5,0,0.6,0.2c0.8,1,1.6,2,2.4,3c0.6,0.8,1.3,1.6,1.9,2.5C65.5,156.9,65.5,157.4,65.6,157.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity23 ? this.state.opacity23 : 1}
              fill={this.state.color23 ? this.state.color23 : 'transparent'} onClick={() => {
          this.setState({ color23: color, opacity23: opacity });
        }} d="M67.4,60.9c0.2,2.9,1.5,5.3,2.5,7.9c1,2.6,1.8,5.2,1.7,8c0,1.4,0,2.9,0,4.5c-1.3-1-2.5-0.9-3.5,0.3
	c-0.7-0.5-1.3-1.2-2.1-1.4c-1.1-0.3-1.4,0.7-1.5,1.6c-0.7-0.3-1.3-0.5-1.8-0.9c-0.7-0.4-1.3-0.4-2.1-0.2c-1.1,0.3-2.2-0.6-2.2-1.8
	c0-1.2,0.4-2,1.5-2.7c3.2-2,5.7-4.7,6.5-8.5c0.3-1.6,0.2-3.3,0.2-4.9C66.5,62.1,66.3,61.3,67.4,60.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }} d="M54.3,139c1.9,0.7,3.7,1.4,5.6,2.2c-1.7,2.3-1.1,4.4-0.1,6.6c0.3,0.6,0.4,1.3,0.6,2c-0.2-0.1-0.3-0.1-0.5-0.2
	c-0.7,0.5-1.5,1-2.2,1.5c-0.3,0.2-0.7,0.3-1.1,0.3c-1.1-0.1-2,0.1-2.9,0.8c-0.8,0.7-1.3-0.1-1.6-0.6c-0.6-0.8-1.1-1.1-2.1-0.6
	c-0.7,0.4-1.7-0.5-1.6-1.5c0.1-1.2,0.3-2.6,1-3.4C51.3,144,53.1,141.8,54.3,139z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1}
              fill={this.state.color25 ? this.state.color25 : 'transparent'} onClick={() => {
          this.setState({ color25: color, opacity25: opacity });
        }} d="M219.5,55.9c1.9,0.2,3.7,0.5,5.5,0.4c1.8-0.1,3.6-0.5,5.4-0.8c-0.4,1.9-0.4,3.8,1,5.6c0.3-0.2,0.7-0.5,1.1-0.8
	c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.3-0.2,0.7-0.4,0.8c-3.5,2.3-7.3,3.2-11.3,2.3c-1.4-0.3-2.6-1.5-3.9-2.3
	C218.5,59.6,218.9,58.8,219.5,55.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1}
              fill={this.state.color26 ? this.state.color26 : 'transparent'} onClick={() => {
          this.setState({ color26: color, opacity26: opacity });
        }} d="M46.3,139.7c0.1,2.2,1.8,3.2,3.2,4.5c-0.6,1-1.3,1.9-1.8,2.9c-0.4,1-0.7,2.1-1,3.1c0.1,0,0.2,0,0.3,0
	c-0.7,0.3-1.5,0.4-2,0.8c-0.8,0.7-1.4,0.3-1.9-0.3c-0.6-0.7-1.2-1-2.1-0.7c-1,0.3-2.1-0.6-2-1.7c0.1-1.5,0.5-2.9,0.5-4.4
	c0.1-2.3,1.6-3,3.5-3.4C44.2,140.4,45.2,140.1,46.3,139.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M32.7,138.3c1.9,1.6,3.5,3.2,6.1,3.1c0.4,1.3,0.1,2-1.2,2.4c-0.7,0.2-1.5,0.6-2,1.2c-2,2-4.2,3.8-5.2,6.6
	c-0.5,1.4-1.2,2.8-2,4.1c-0.3,0.4-1.3,0.4-2,0.6c0.1-3.2,0.4-6,1.8-8.7c0.3-0.6,0.7-1.2,0.9-1.8c1.1-2.2,2.1-4.5,3.2-6.7
	C32.4,138.8,32.5,138.5,32.7,138.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity28 ? this.state.opacity28 : 1}
              fill={this.state.color28 ? this.state.color28 : 'transparent'} onClick={() => {
          this.setState({ color28: color, opacity28: opacity });
        }} d="M210.7,79c-1-0.7-0.8-1.8-0.7-2.7c0.3-2.5,0.3-4.9-0.3-7.3c-0.7-2.8-0.5-5.6,0.3-8.3c0.5-1.7,3-2.4,4.9-1.4
	c-0.5,1.6-1.1,3.2-1.6,4.8c-0.9,2.7-0.6,5.4,0.3,8.1c0.7,2,1.7,3.9,1.7,6.2c-0.1,1.9-0.3,2.4-1.4,2.6c-1,0.2-2.4-0.7-2.9-1.8
	c0.2,0.1,0.4,0.1,0.5,0.2c0.8,0.9,2.1,0.7,2.6-0.4c0.7-1.4,0.2-2.7-1.3-4c-0.6,0.5-1.5,1-1.8,1.6C210.6,77.3,210.8,78.2,210.7,79z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity29 ? this.state.opacity29 : 1}
              fill={this.state.color29 ? this.state.color29 : 'transparent'} onClick={() => {
          this.setState({ color29: color, opacity29: opacity });
        }} d="M66.3,139.5c0.8,2,1.6,3.9,2.4,5.8c1.3,3.2,3.1,6.2,3.7,9.7c0.2,1.2,0.4,2.5,0.5,3.8c-1.2,0-1.6-0.3-1.9-1.4
	c-0.8-3.4-2.4-6.6-5-9.1c-1.2-1.1-2.8-1.8-4.3-2.6c-0.9-0.4-1.7-2.9-1.1-3.7c0.1-0.1,0.3-0.3,0.4-0.2
	C63.4,142.4,64.7,140.8,66.3,139.5C66.2,139.5,66.2,139.5,66.3,139.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity30 ? this.state.opacity30 : 1}
              fill={this.state.color30 ? this.state.color30 : 'transparent'} onClick={() => {
          this.setState({ color30: color, opacity30: opacity });
        }} d="M39.3,212.3c-1.2-2-3-3.3-4.9-4c-0.4-2.6-0.8-5.1-1.2-7.7c1.6,0,3.2,0.1,4.7,0c1.5-0.1,3-0.5,4.6-0.8
	c-1.4,1.6-2.1,3.5-2.4,5.6c-0.1,1.3,0.2,2.6,0.4,3.8C40.8,210.5,40.5,211.4,39.3,212.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity31 ? this.state.opacity31 : 1}
              fill={this.state.color31 ? this.state.color31 : 'transparent'} onClick={() => {
          this.setState({ color31: color, opacity31: opacity });
        }} d="M36.4,53.1c-1.6-1.1-1.2-2.8-1.6-4.2c-0.6-2.7-0.4-5.5,0.3-8.3c0.4-1.7,1.8-2.6,2.9-3.6
	c3.5-3.4,7.7-5.4,12.6-5.6c2.5-0.1,4.8,0.4,6.9,1.7c2.8,1.7,4.7,4.1,6.4,6.7c1,1.6,2,3.2,2.1,5c0.1,1.5-0.2,2.9-0.4,4.4
	c-0.1,1-0.5,2-0.9,2.9c0.2-1.8,0.4-3.6,0.5-5.4c0.2-2.5-0.4-4.8-2.2-6.7c-1.3-1.4-2.2-3.2-3.5-4.6c-1.8-2-4.3-2.7-6.9-3.1
	c-5.8-0.7-10.3,1.7-14.1,5.8c-0.5,0.6-0.9,1.4-1.1,2.2c-0.9,3.6-1.4,7.2-0.8,10.9C36.8,51.9,36.5,52.5,36.4,53.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1}
              fill={this.state.color32 ? this.state.color32 : 'transparent'} onClick={() => {
          this.setState({ color32: color, opacity32: opacity });
        }} d="M48.4,164.1c-2.2-2.6-4.1-5-7.7-5c-3.6,0-5.3,2.5-6.7,4.9c-0.1-1.1-0.3-2.4-0.4-3.6c0-0.6,0.1-1.2,0.2-1.9
	c0-0.4,0-0.9-0.1-1.3c6.8,0.3,13.7,0.5,20.6,0.8C51.4,158.9,48.7,160.1,48.4,164.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity33 ? this.state.opacity33 : 1}
              fill={this.state.color33 ? this.state.color33 : 'transparent'} onClick={() => {
          this.setState({ color33: color, opacity33: opacity });
        }} d="M238.8,80.4c-0.1-1,0.3-2.2-0.6-3.1c-0.4-0.4-0.6-0.9-1.4-0.4c-1.9,1.2-2.1,2.3-0.7,4c0.1,0.1,0.2,0.3,0.4,0.6
	c-1.1-0.6-2-2.3-2.1-3.6c-0.2-2.1,0.7-4,1.2-6c0.6-2.4,1-4.8,1.5-7.2c0.2-1.2,0.2-2.4,0.4-3.7c1.7,0,2.1,0.6,1.9,2.3
	c-0.4,2.7-1,5.6,0,8.2c1.1,2.6,0.4,5-0.2,7.5C239.1,79.5,238.9,79.9,238.8,80.4L238.8,80.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M62.1,163.2c-4.3,1.3-8.4,2.4-12.4,3.6c0.1-0.5,0.2-1,0.2-1.4c0.1-1,0.1-2,0.4-2.9c0.8-3,3.6-4.1,6.4-2.9
	C58.6,160.4,60.3,162,62.1,163.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity35 ? this.state.opacity35 : 1}
              fill={this.state.color35 ? this.state.color35 : 'transparent'} onClick={() => {
          this.setState({ color35: color, opacity35: opacity });
        }} d="M35.8,37.3c-0.2-0.7-0.2-1.3-0.5-1.9c-1-1.7-2.1-3.4-3.1-5.1c-0.2-0.4-0.3-0.9-0.5-1.4c0.4-0.1,0.9-0.4,1.3-0.4
	c1.7-0.1,3.5-0.1,5.2-0.3c0.8-0.1,1.2,0.2,1.4,0.9c0.4,1.2,1.1,2.3,0.8,3.7c-0.1,0.7-0.3,1.1-0.9,1.5c-1,0.7-1.8,1.5-2.7,2.2
	C36.4,36.9,36,37.2,35.8,37.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1}
              fill={this.state.color36 ? this.state.color36 : 'transparent'} onClick={() => {
          this.setState({ color36: color, opacity36: opacity });
        }} d="M244.9,140.6c0.9,0,1.2,0.4,1.4,1.5c0.3,1.9,1.2,3.7,1.8,5.5c0,0.1,0.1,0.1,0.2,0.2c0.2,0.8,0.5,1.6,0.7,2.4
	c-0.9,0.2-1.7,0.3-2.6,0.5c-0.2,0-0.5-0.2-0.7-0.2c-1.4,0.3-1.9-0.6-2.4-1.6c-1.1-2-1.4-4.1-1.2-6.3
	C242.1,141.8,243.9,140.6,244.9,140.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity37 ? this.state.opacity37 : 1}
              fill={this.state.color37 ? this.state.color37 : 'transparent'} onClick={() => {
          this.setState({ color37: color, opacity37: opacity });
        }} d="M48,166.4c-4.2-0.8-8.4-1.6-12.7-2.4c0.5-0.8,1-1.6,1.6-2.3c2.2-2.8,5.1-1.9,7.1,0
	C45.5,163.1,46.7,164.9,48,166.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity38 ? this.state.opacity38 : 1}
              fill={this.state.color38 ? this.state.color38 : 'transparent'} onClick={() => {
          this.setState({ color38: color, opacity38: opacity });
        }} d="M31.7,41.5c0.3,0.1,0.7,0.2,1.4,0.4c-1.5,2.7-3.5,5.3-2.9,8.8c-2-1.3-3.6-2.7-4.6-4.7c-0.3-0.7-0.5-1.1,0.5-1.5
	C27.9,43.7,29.7,42.6,31.7,41.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity39 ? this.state.opacity39 : 1}
              fill={this.state.color39 ? this.state.color39 : 'transparent'} onClick={() => {
          this.setState({ color39: color, opacity39: opacity });
        }} d="M68,217.6c-1.9,0.6-3.3,1.1-4.8,1.5c-0.4,0.1-1,0-1.3-0.2c-0.9-0.5-1.6-1.2-2.4-1.8c0.9-2,2.6-2.8,4.3-3.5
	c0.6-0.2,1.3-0.2,1.8,0.6C66.3,215.4,67.2,216.4,68,217.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity40 ? this.state.opacity40 : 1}
              fill={this.state.color40 ? this.state.color40 : 'transparent'} onClick={() => {
          this.setState({ color40: color, opacity40: opacity });
        }} d="M67.2,164.1c-0.8-0.1-1.5-0.2-2.1-0.4c-0.3-0.1-0.5-0.3-0.7-0.5c-1.4-1.3-2.8-2.6-4.3-3.8
	c-0.5-0.4-1.3-0.6-2.1-1c2.6,0.1,4.9,0.2,7.3,0.4c0.4,0,1,0.6,1.1,1C66.8,161.3,67,162.7,67.2,164.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity41 ? this.state.opacity41 : 1}
              fill={this.state.color41 ? this.state.color41 : 'transparent'} onClick={() => {
          this.setState({ color41: color, opacity41: opacity });
        }}
              d="M207.5,93.6c0.6-5.1,0-10-1.3-15.2c-1.2,5.3-0.6,10.1,1.1,14.9C207.3,93.4,207.4,93.5,207.5,93.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity42 ? this.state.opacity42 : 1}
              fill={this.state.color42 ? this.state.color42 : 'transparent'} onClick={() => {
          this.setState({ color42: color, opacity42: opacity });
        }} d="M209.5,30.7c0,1.1,0,2.1,0,3.1c0,0.6-0.9,1.4,0.4,1.8c-0.3,1-0.6,1.9-1,3.1c-0.9-1.3-1.4-2.5-0.6-3.8
	c0.5-0.9,0.1-1.4-0.4-2c-0.8-1-1.6-2.2-2.3-3.3c-0.5-0.8-0.3-1.6,0.6-2c0.9-0.3,1.3,0.3,1.7,1C208.5,29.3,209.1,30.1,209.5,30.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity43 ? this.state.opacity43 : 1}
              fill={this.state.color43 ? this.state.color43 : 'transparent'} onClick={() => {
          this.setState({ color43: color, opacity43: opacity });
        }} d="M230.6,194.5c1.8,0.1,3.7,0.1,5.5,0.1c1.2,0,2.1,0.4,3,1.1c1.4,1.1,3,1.9,4.8,2c2.6,0.2,5.2,0.3,7.8,0.2
	c1.4,0,2.8-0.5,4.4-0.9c-0.5,1.1-1.3,1.8-2.4,1.9c-1.9,0.1-3.9,0.2-5.8-0.1c-2.3-0.3-4.6-0.8-6.8-1.4c-0.8-0.2-1.6-0.6-2.3-1.1
	c-1.7-1.1-3.6-1.5-5.6-1.2C232.1,195.4,231.2,195.1,230.6,194.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity44 ? this.state.opacity44 : 1}
              fill={this.state.color44 ? this.state.color44 : 'transparent'} onClick={() => {
          this.setState({ color44: color, opacity44: opacity });
        }} d="M66.3,60.8c-0.4-1.6-0.7-3.2-1.1-4.7c-0.2-0.8-0.5-1.6,0.6-2.1c1-0.4,2-0.5,2.9,0.2c1.1,0.8,1.1,1.5,0.4,2.6
	C68.2,58.1,67.3,59.5,66.3,60.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity45 ? this.state.opacity45 : 1}
              fill={this.state.color45 ? this.state.color45 : 'transparent'} onClick={() => {
          this.setState({ color45: color, opacity45: opacity });
        }} d="M67.5,170.3c1.3-1.4,1.3-3,1-4.6c0.8-0.2,1.6-0.3,2.4-0.5c0.2,0,0.4-0.1,0.6,0c1.4,0.6,1.8,3.2,0.5,3.9
	c-1.3,0.7-2.8,1.1-4.2,1.6C67.7,170.6,67.6,170.4,67.5,170.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity46 ? this.state.opacity46 : 1}
              fill={this.state.color46 ? this.state.color46 : 'transparent'} onClick={() => {
          this.setState({ color46: color, opacity46: opacity });
        }}
              d="M66.4,165.4c0.3,1.4,1.4,2.5,0.4,3.9c-1,1.3-2.4,1.9-3.9,2.3C62.8,169,64.5,166.1,66.4,165.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity47 ? this.state.opacity47 : 1}
              fill={this.state.color47 ? this.state.color47 : 'transparent'} onClick={() => {
          this.setState({ color47: color, opacity47: opacity });
        }} d="M27.2,162.8c0.9,0.9,1.7,1.9,2.7,2.5c1.1,0.6,0.4,1.4,0.5,2.2c0.1,0.4-0.1,0.8-0.2,1.2c-1.1,0-3.7-1.7-4.1-2.8
	C25.8,164.8,26.3,163.5,27.2,162.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity48 ? this.state.opacity48 : 1}
              fill={this.state.color48 ? this.state.color48 : 'transparent'} onClick={() => {
          this.setState({ color48: color, opacity48: opacity });
        }} d="M246.8,139.3c-1,0.1-1.8,0.3-2.7,0.3c-0.2,0-0.6-0.3-0.8-0.6c-0.7-1.2-1.4-2.4-2.3-4c1.3-0.1,2.4-0.2,3.5-0.2
	c0.2,0,0.5,0.4,0.5,0.7c0,0.9,0.1,1.7,1,2.3C246.4,138.2,246.6,138.8,246.8,139.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity49 ? this.state.opacity49 : 1}
              fill={this.state.color49 ? this.state.color49 : 'transparent'} onClick={() => {
          this.setState({ color49: color, opacity49: opacity });
        }} d="M33,222.3c-2.1,1.4-4.3,2.2-6.8,2.1c-1.5,0-2.9,0-4.4-0.3c-1.6-0.3-1.9-0.7-1.8-2.5
	C24.2,223.9,28.5,223.8,33,222.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity50 ? this.state.opacity50 : 1}
              fill={this.state.color50 ? this.state.color50 : 'transparent'} onClick={() => {
          this.setState({ color50: color, opacity50: opacity });
        }} d="M77.9,194.4c0.2,1.4,0.5,2.7,0.6,4c0,0.4-0.7,0.7-1.2,1.2c-0.4-1-0.7-1.7-1-2.5c-0.6,0.2-1.1,0.3-1.7,0.5
	c-0.2-0.4-0.5-0.9-0.9-1.6C75.1,195.5,76.5,195,77.9,194.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity51 ? this.state.opacity51 : 1}
              fill={this.state.color51 ? this.state.color51 : 'transparent'} onClick={() => {
          this.setState({ color51: color, opacity51: opacity });
        }} d="M240.1,19.9c0.3,0.6,1,1.2,0,1.8c0.3,0.4,0.5,1,0.9,1.1c0.9,0.2,1.1,0.6,0.9,1.4c-0.7,0.2-1.4,0.4-2,0.5
	c0.2,1.1,0.5,2.2,0.4,3.2c0,0.3-1.1,0.5-1.8,0.8c0.1-0.6,0.2-1.1,0.2-1.6c0.1-1.3,0.1-2.6,0.2-4c0-0.5,0.3-0.9,0.4-1.4
	c-0.2,0-0.5,0-1,0C239,20.9,239.5,20.4,240.1,19.9C240.1,19.8,240.1,19.9,240.1,19.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity52 ? this.state.opacity52 : 1}
              fill={this.state.color52 ? this.state.color52 : 'transparent'} onClick={() => {
          this.setState({ color52: color, opacity52: opacity });
        }} d="M210.8,32.4c0.2-0.7,0.4-1.4,0.6-2.1c-2.2-1.2-4.2-4.5-2.1-7.2c0.4,1,0.9,1.9,1.2,2.8c0.4,1.1,0.7,2.3,1.2,3.5
	C212.2,30.6,211.8,31.6,210.8,32.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1}
              fill={this.state.color53 ? this.state.color53 : 'transparent'} onClick={() => {
          this.setState({ color53: color, opacity53: opacity });
        }} d="M34.1,57.7c-0.8-1.6-1.6-3.1-2.3-4.6c-0.2-0.4,0.1-0.9,0.1-1.4c0.5,0.1,1.1,0,1.4,0.3c0.7,0.6,1.4,1.2,1.9,2
	c0.2,0.4,0.1,1.1-0.1,1.6C35,56.3,34.6,56.9,34.1,57.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity54 ? this.state.opacity54 : 1}
              fill={this.state.color54 ? this.state.color54 : 'transparent'} onClick={() => {
          this.setState({ color54: color, opacity54: opacity });
        }} d="M33.2,148.4c0.7,1.2,1.2,2.3,2.9,1.7c-0.6,1.5-1.3,2.6-2.6,3c-0.6,0.2-1.4-0.2-2.1-0.3c0.1-0.5,0.1-1,0.3-1.4
	C32.1,150.5,32.6,149.5,33.2,148.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity55 ? this.state.opacity55 : 1}
              fill={this.state.color55 ? this.state.color55 : 'transparent'} onClick={() => {
          this.setState({ color55: color, opacity55: opacity });
        }} d="M239.9,35.5c-0.2-0.6-0.4-1.1-0.6-1.6c-0.5-1-0.6-1.9,0.2-2.8c0.4-0.5,0.5-1.3,0.7-2.1c0.8-0.2,1.1,0.5,1.4,1.1
	c0.7,1.3,0.3,2.5-0.7,3.6C240.5,34.3,240.3,34.9,239.9,35.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity56 ? this.state.opacity56 : 1}
              fill={this.state.color56 ? this.state.color56 : 'transparent'} onClick={() => {
          this.setState({ color56: color, opacity56: opacity });
        }} d="M38.5,144.3c-0.3,1.3-0.5,2.6-0.8,3.9c-0.1,0.7-1.3,1.3-2,1.1c-0.7-0.3-1.5-0.5-1-1.7
	C35.5,145.8,36.7,144.7,38.5,144.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity57 ? this.state.opacity57 : 1}
              fill={this.state.color57 ? this.state.color57 : 'transparent'} onClick={() => {
          this.setState({ color57: color, opacity57: opacity });
        }} d="M33.3,43.3c0.1,2.3,0.3,4.8,0.4,7.4c-0.5,0-1.3,0-2.2,0C31.2,48,31.8,45.4,33.3,43.3z" />
        <path d="M210.7,79c0-0.8-0.1-1.7,0.2-2.3c0.3-0.7,1.2-1.1,1.8-1.6c1.6,1.3,2,2.6,1.3,4c-0.5,1.1-1.8,1.3-2.6,0.4
	c-0.1-0.1-0.3-0.2-0.5-0.2C210.9,79.1,210.8,79,210.7,79z M214,78c-0.7-0.6-1.2-1-1.8-1.4c0,0-0.6,0.6-0.5,0.6
	c0.3,0.5,0.8,1,1.2,1.5C213,78.7,213.5,78.3,214,78z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity58 ? this.state.opacity58 : 1}
              fill={this.state.color58 ? this.state.color58 : 'transparent'} onClick={() => {
          this.setState({ color58: color, opacity58: opacity });
        }} d="M28.9,157.9c0.9,0.1,1.7,0.3,2.4,0.1c1.1-0.3,1.4,0.6,1.3,1c-0.1,0.6-0.6,1.3-1.2,1.8c-0.8,0.7-1.7,0.2-2.3-0.3
	c-0.3-0.2-0.3-1-0.3-1.6C28.7,158.6,28.9,158.2,28.9,157.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity59 ? this.state.opacity59 : 1}
              fill={this.state.color59 ? this.state.color59 : 'transparent'} onClick={() => {
          this.setState({ color59: color, opacity59: opacity });
        }} d="M68.1,154.1c-0.2,0.6-0.3,0.9-0.4,1.2c-0.1,0.8-0.7,0.9-1.1,0.4c-0.8-0.8-1.5-1.7-1.9-2.8
	c-0.1-0.4,0.8-1.2,1.2-1.7c0,0,0.7,0.3,0.9,0.6C67.3,152.6,67.7,153.4,68.1,154.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity60 ? this.state.opacity60 : 1}
              fill={this.state.color60 ? this.state.color60 : 'transparent'} onClick={() => {
          this.setState({ color60: color, opacity60: opacity });
        }} d="M61.5,149.9c0.2-0.5,0.3-0.9,0.4-1.4c0.1-0.5,0-0.9,0-1.4c2,0.7,3,1.9,2.9,3.6c-0.1,0.9-0.5,1.2-1.3,0.7
	C62.9,151.1,62.2,150.5,61.5,149.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity61 ? this.state.opacity61 : 1}
              fill={this.state.color61 ? this.state.color61 : 'transparent'} onClick={() => {
          this.setState({ color61: color, opacity61: opacity });
        }} d="M34.9,37.8c-0.7,1-1.3,1.8-1.9,2.6c-0.5-0.5-1-1.1-1.5-1.7c-0.4-0.5-1.1-1.3-0.3-1.8c0.5-0.4,1.5-0.3,2.2-0.2
	C34,36.8,34.4,37.4,34.9,37.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity62 ? this.state.opacity62 : 1}
              fill={this.state.color62 ? this.state.color62 : 'transparent'} onClick={() => {
          this.setState({ color62: color, opacity62: opacity });
        }} d="M67.8,161.1c0.9-0.5,1.6-0.8,2.2-1.1c0,0.9,0,1.8,0.9,2.6c0.3,0.3,0,1.2,0,1.8c-0.5,0-1.2,0.1-1.4-0.1
	C68.8,163.2,68.3,162.1,67.8,161.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity63 ? this.state.opacity63 : 1}
              fill={this.state.color63 ? this.state.color63 : 'transparent'} onClick={() => {
          this.setState({ color63: color, opacity63: opacity });
        }}
              d="M28.9,162.1c1.1-0.2,2.1-0.4,3.5-0.7c0.1,0.8,0.3,1.7,0.4,2.6C30.3,164.4,29.4,164,28.9,162.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity64 ? this.state.opacity64 : 1}
              fill={this.state.color64 ? this.state.color64 : 'transparent'} onClick={() => {
          this.setState({ color64: color, opacity64: opacity });
        }} d="M33,154.6c-0.4,0.8-0.5,1.9-1.2,2.5c-0.6,0.5-1.6,0-2-0.7c-0.2-0.5-0.2-1.6,0-1.7c0.9-0.3,1.8-0.3,2.8-0.5
	C32.7,154.3,32.8,154.5,33,154.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity65 ? this.state.opacity65 : 1}
              fill={this.state.color65 ? this.state.color65 : 'transparent'} onClick={() => {
          this.setState({ color65: color, opacity65: opacity });
        }} d="M32.3,166.3c1.1,1.7,2.1,3.3,3.2,5C33.5,171.2,32.5,169.7,32.3,166.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity66 ? this.state.opacity66 : 1}
              fill={this.state.color66 ? this.state.color66 : 'transparent'} onClick={() => {
          this.setState({ color66: color, opacity66: opacity });
        }} d="M67,157.1c0.6-0.4,1.1-0.7,1.7-1.2c0.2,0.9,0.5,1.7,0.6,2.5c0,0.4-0.3,0.8-0.5,1.2c-0.4-0.3-1-0.5-1.2-0.9
	C67.3,158.3,67.2,157.7,67,157.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity67 ? this.state.opacity67 : 1}
              fill={this.state.color67 ? this.state.color67 : 'transparent'} onClick={() => {
          this.setState({ color67: color, opacity67: opacity });
        }} d="M30.8,211.4c0.1-0.6,0.6-0.4,1.3,0.3c0.6,0.7,1.4,1.3,2,2c0.2,0.2,0.1,0.6,0.2,0.9c-0.3,0-0.8,0.1-1-0.1
	C32.4,213.5,31.6,212.4,30.8,211.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity68 ? this.state.opacity68 : 1}
              fill={this.state.color68 ? this.state.color68 : 'transparent'} onClick={() => {
          this.setState({ color68: color, opacity68: opacity });
        }} d="M55.4,45.3c0.5-0.7,1.1-1.4,1.6-2.2c0.1,0.4,0.3,1,0.5,1.6c1.6,0.2,2,0.6,1.9,1.8
	C58.1,46.1,56.7,45.7,55.4,45.3C55.4,45.3,55.4,45.3,55.4,45.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity69 ? this.state.opacity69 : 1}
              fill={this.state.color69 ? this.state.color69 : 'transparent'} onClick={() => {
          this.setState({ color69: color, opacity69: opacity });
        }} d="M66,53c0.5-1.9,1-3.9,1.6-5.8c0.1,0,0.1,0,0.2,0c0.1,0.2,0.2,0.5,0.2,0.7c-0.1,1.2,0,2.5-0.3,3.6
	c-0.2,0.7-1,1.1-1.5,1.7C66.1,53.1,66,53,66,53z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity70 ? this.state.opacity70 : 1}
              fill={this.state.color70 ? this.state.color70 : 'transparent'} onClick={() => {
          this.setState({ color70: color, opacity70: opacity });
        }}
              d="M42.2,217.5c-0.6,1.7-2.7,2.6-5.3,2.3c0-0.1-0.1-0.2-0.1-0.3C38.5,218.8,40.3,218.1,42.2,217.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity71 ? this.state.opacity71 : 1}
              fill={this.state.color71 ? this.state.color71 : 'transparent'} onClick={() => {
          this.setState({ color71: color, opacity71: opacity });
        }} d="M63.1,83.2c-1.2,0.9-2.5,0.5-3.2-0.7C61.3,81,62.1,82.5,63.1,83.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity72 ? this.state.opacity72 : 1}
              fill={this.state.color72 ? this.state.color72 : 'transparent'} onClick={() => {
          this.setState({ color72: color, opacity72: opacity });
        }} d="M238.8,80.4c0.1,0.5,0.3,0.9,0.4,1.4c0,0.4,0,0.8-0.1,1.2c-0.4-0.1-0.9-0.2-1.2-0.4c-0.3-0.1-0.5-0.4-0.6-0.5
	C237.7,81.5,238.3,81,238.8,80.4L238.8,80.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity73 ? this.state.opacity73 : 1}
              fill={this.state.color73 ? this.state.color73 : 'transparent'} onClick={() => {
          this.setState({ color73: color, opacity73: opacity });
        }}
              d="M237.5,80.5c-0.4-0.6-0.7-1-0.9-1.4c-0.1-0.2,0-0.5,0-0.7c0.3,0,0.7,0,0.8,0.1C238,79,238.1,79.7,237.5,80.5z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity74 ? this.state.opacity74 : 1}
              fill={this.state.color74 ? this.state.color74 : 'transparent'} onClick={() => {
          this.setState({ color74: color, opacity74: opacity });
        }}
              d="M214,78c-0.6,0.4-1,0.8-1.1,0.7c-0.5-0.4-0.9-0.9-1.2-1.5c0-0.1,0.5-0.6,0.5-0.6C212.8,77,213.3,77.4,214,78z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity75 ? this.state.opacity75 : 1}
              fill={this.state.color75 ? this.state.color75 : 'transparent'} onClick={() => {
          this.setState({ color75: color, opacity75: opacity });
        }} d="M226.1,100.3c0.1-3.7,0.2-7.1,0.3-10.5c0-2.3,0.1-2.3,2.3-2.2c1.1,0.1,2.1,0.1,3,0.2c0,1.8,0,3.6,0,5.5
	c0,1.6-0.1,3.2-0.2,4.8c-0.1,1.3-1.3,2.4-2.6,2.4C227.8,100.3,226.9,100.3,226.1,100.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity76 ? this.state.opacity76 : 1}
              fill={this.state.color76 ? this.state.color76 : 'transparent'} onClick={() => {
          this.setState({ color76: color, opacity76: opacity });
        }} d="M215.9,87.2c1.4,0,2.9,0,4.3,0c0.2,0,0.4,0.4,0.4,0.7c0,2.2,0.1,4.3,0,6.5c0,1.7-0.1,3.4-0.2,5.1
	c-3.5-0.3-5.9-3.3-5.3-6.9C215.3,90.9,215.6,89.1,215.9,87.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity77 ? this.state.opacity77 : 1}
              fill={this.state.color77 ? this.state.color77 : 'transparent'} onClick={() => {
          this.setState({ color77: color, opacity77: opacity });
        }}
              d="M225,100c-1.2,0-2.4,0-3.5,0c0-4.2,0-8.4,0-12.5c1.4,0,2.9,0,4.1,0C225.4,91.8,225.2,95.9,225,100z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity78 ? this.state.opacity78 : 1}
              fill={this.state.color78 ? this.state.color78 : 'transparent'} onClick={() => {
          this.setState({ color78: color, opacity78: opacity });
        }} d="M215.9,85.7c0-0.3,0-0.8,0.2-1.1c0.1-0.3,0.5-0.8,0.7-0.8c1-0.1,2-0.1,3,0c1.4,0.1,2.7,0.3,4.1,0.4
	c1.6,0.2,3.3,0.4,4.9,0.5c1.2,0.1,2.4,0,3.6,0c-0.2,0.7-0.3,1.3-0.5,1.9C226.6,86.3,221.2,86,215.9,85.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity79 ? this.state.opacity79 : 1}
              fill={this.state.color79 ? this.state.color79 : 'transparent'} onClick={() => {
          this.setState({ color79: color, opacity79: opacity });
        }} d="M61,56c-0.1,0.7-0.3,1.4-1.4,1.7c-1.7,0.4-3.1-0.2-3.6-2.1c-0.4-1.4-0.6-2.8-0.7-4.3c-0.1-1.4,0.8-1.9,2.1-1.4
	C59.5,50.9,60.9,53.9,61,56z M59.5,54.6c0-1.6-0.8-2.8-1.7-2.7c-0.9,0.1-1.4,0.8-1.4,1.6c0,1.5,0.5,2.5,1.7,2.5
	C59.4,55.9,59.5,55.2,59.5,54.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity80 ? this.state.opacity80 : 1}
              fill={this.state.color80 ? this.state.color80 : 'transparent'} onClick={() => {
          this.setState({ color80: color, opacity80: opacity });
        }} d="M47.1,52.7c-0.2,2.6-0.8,3.3-3.2,3.8c-2,0.5-3.6-1.2-3.1-3.1c0.5-1.7,1.1-3.3,2.9-4.1c1.5-0.7,2.8-0.1,3.1,1.5
	C46.9,51.4,47,52.1,47.1,52.7z M42.5,53c0,1.2,0.7,2.2,1.7,2.2c0.8,0,1.8-1.1,1.9-2c0.1-1.2-0.6-2.2-1.5-2.3
	C43.6,50.8,42.6,51.8,42.5,53z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity81 ? this.state.opacity81 : 1}
              fill={this.state.color81 ? this.state.color81 : 'transparent'} onClick={() => {
          this.setState({ color81: color, opacity81: opacity });
        }} d="M228.6,31.8c0.1-0.6,0.3-1.2,0.3-1.9c0-1.4,0.9-1.2,1.7-1.1c2.2,0.5,4.4,3.8,3.9,6c-0.2,0.8-0.8,1.7-1.4,2
	c-0.8,0.4-1.8,0.1-2.6-0.7C229,35,228.7,33.5,228.6,31.8z M229.7,32.4c0,1.3,0.7,2,1.9,2c1,0,1.5-0.6,1.5-1.7c0-1.1-1-2.2-2-2.2
	C230.3,30.5,229.7,31.4,229.7,32.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity82 ? this.state.opacity82 : 1}
              fill={this.state.color82 ? this.state.color82 : 'transparent'} onClick={() => {
          this.setState({ color82: color, opacity82: opacity });
        }} d="M221.8,31.4c0.2,3.7-2.6,5-5.1,4.1c-0.4-0.1-0.8-0.7-0.9-1.1c-0.4-2.1,0.7-3.9,1.8-5.5c0.7-1.1,2.1-0.7,3.1-0.3
	c0.6,0.2,0.9,1.2,1.2,1.9C221.9,30.8,221.8,31.2,221.8,31.4z M220.8,31.5c0.1-1-0.6-1.4-1.5-1.5c-1.1-0.1-2.2,1-2.1,2.2
	c0.1,0.7,0.4,1.3,1.4,1.3C219.7,33.6,220.8,32.6,220.8,31.5z" />
      </SvgIcon>
    )
  }
};