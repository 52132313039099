import { Component } from "react";
import React from "react";
import { Layer, Rect, Stage } from "react-konva";
import FreePathDrawable from "./FreePathDrawable";
import LineDrawable from "./LineDrawable";
import ArrowDrawable from "./ArrowDrawable";
import CircleDrawable from "./CircleDrawable";
import RectangleDrawable from "./RectangleDrawable";
import EllipseDrawable from "./EllipseDrawable";
import EraseDrawable from "./EraseDrawable";
import TextBox from "./TextBox";
import Axios from "../ajax/Axios";
import URLImage from "./URLImage";
import _ from 'lodash';
import ShapeDraggable from "./ShapeDraggable";
import uuid from "uuid-random";
import { setMaskImages, showDivider } from "../actions";
import Divider from "../tools/Divider";
import ToolLayer from "../tools/ToolLayer";
import ArcDrawable from "./ArcDrawable";
import { PLACEHOLDERS } from "../util/APP_CONSTANS";
import ReactDOM from "react-dom";
import PointDrawable from "./Point";
import TextBoxPoint from "./TextBoxPoint";
import PolygonDrawable from "./PolygonDrawable";

export type STATE_SCENE_TYPES = {
  drawables: any[],
  newDrawable: any[],
  selectShape: string,
  selectImage: string
}

export default class SceneWithDrawables extends Component<any, STATE_SCENE_TYPES> {
  state: STATE_SCENE_TYPES;
  myRef: any;
  axios: Axios;
  defaultState: any;
  private draggableImagePositions: any[];
  private initDraggableImagePositions: any[];
  private toolLayerRef: any;
  defaultStageHeight = 700;
  enabledDeselectShape = true;
  defaultTextPosition = {
    x: 200,
    y: 200
  }
  previousDrawableType: string = '';
  currentAction: string = '';

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.toolLayerRef = React.createRef();
    this.state = {
      drawables: [],
      newDrawable: [],
      selectShape: null,
      selectImage: null
    };
    this.defaultState = _.cloneDeep(this.state);
    const { edition, userName } = this.props;
    this.axios = new Axios(userName, edition);
  }

  componentDidMount() {
    this.draggableImagePositions = this.getDraggableImagesJson();
    this.initDraggableImagePositions = _.cloneDeep(this.draggableImagePositions);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<STATE_SCENE_TYPES>, snapshot?: any) {
    const {
      saveChanging,
      saveChangingDevMode,
      removePageDrawStateItem,
      addPageDrawStateItem,
      name,
      onExport,
      copiedSelectedShape,
      pageSelectedShape,
      drawableType,
      showTextId
    } = this.props;
    if (drawableType !== prevProps.drawableType) {
      this.previousDrawableType = prevProps.drawableType;
    }

    let drawables;
    const doHandleExport = saveChanging && saveChanging !== prevProps.saveChanging;
    const stateChanged = !_.isEqual(this.state, this.defaultState)
    this.currentAction = '';
    if (doHandleExport) {
      this.currentAction = 'save';
      if (stateChanged) {
        this.handleExport();
      } else {
        onExport();
      }
    } else if (removePageDrawStateItem && removePageDrawStateItem[name] && removePageDrawStateItem[name] !== _.get(prevProps, `removePageDrawStateItem.${name}`)) {
      this.currentAction = 'undo';
      drawables = [...this.state.drawables];
      if (drawables.length) {
        drawables.pop();
        this.setState({ drawables });
      }
    } else if (addPageDrawStateItem && addPageDrawStateItem[name] && addPageDrawStateItem[name] !== _.get(prevProps, `addPageDrawStateItem.${name}`)) {
      this.currentAction = 'redo';
      drawables = [...this.state.drawables];
      drawables.push(addPageDrawStateItem[name]);
      this.setState({ drawables })
    } else if (copiedSelectedShape !== prevProps.copiedSelectedShape && _.get(pageSelectedShape, 'name') === this.props.name) {
      this.currentAction = 'copy';
      drawables = [...this.state.drawables];
      const copiedDrawable = pageSelectedShape[name];
      copiedDrawable.x += 10;
      copiedDrawable.y += 10;
      copiedDrawable.id = uuid();
      drawables.push(copiedDrawable);
      this.setState({ drawables })
      this.props.onAddShape(copiedDrawable);
    } else if (prevProps.showTextId !== showTextId && drawableType === 'TextBox') {
      this.currentAction = 'addText';
      this.addTextBox();
    } else if (prevProps.showedFill !== this.props.showedFill) {
      this.currentAction = 'colorShape';
      this.setColorIntoShape();
    } else if (prevProps.showPointId !== this.props.showPointId) {
      this.currentAction = 'addPoint';
      this.addPoint();
    } else if (prevProps.showCurvePolygonId !== this.props.showCurvePolygonId) {
      this.currentAction = 'transformPolygonToCurveLine';
    }

    //DEV MODE SAVED
    const doHandleExportDevJson = saveChangingDevMode && saveChangingDevMode !== prevProps.saveChangingDevMode;
    const stateChangedDevJson = !_.isEqual(this.state, this.defaultState)
    if (doHandleExportDevJson && stateChangedDevJson) {
      this.currentAction = 'devSave';
      this.handleExportDevJson();
    }
    if (this.props.layer2) {
      this.draggableImagePositions = this.getDraggableImagesJson();
      const propsChangedDevJson = !_.isEqual(this.draggableImagePositions, this.initDraggableImagePositions);
      if (propsChangedDevJson && doHandleExport) {
        this.draggableImagePositions = this.draggableImagePositions.map((item, index) => {
          if (!item.changed && !_.isEqual(item, this.initDraggableImagePositions[index])) {
            item.changed = true;
          }
          return item;
        })
        this.handleExportDevDraggableImagesJson();
      }
    }
    if (this.props.fieldRectangles && this.props.fieldRectangles.length && !this.state.drawables.length) {
      this.setState({
        drawables: [...this.props.fieldRectangles]
      })
    }

    if (doHandleExport) {
      this.props.onSave();
    }
  }

  getNewDrawableBasedOnType = (x, y, type, color, weight, radius?, rotation?, opacity?) => {
    this.currentAction = type;
    const drawableClasses = {
      ArcDrawable,
      FreePathDrawable,
      ArrowDrawable,
      CircleDrawable,
      LineDrawable,
      RectangleDrawable,
      EllipseDrawable,
      EraseDrawable,
      TextBox,
      TextBoxPoint,
      PointDrawable
    };
    if (type === 'ArcDrawable') {
      return new drawableClasses[type](x, y, color, weight, radius, rotation, opacity);
    } else if (type === 'RectangleDrawable' || type === 'EllipseDrawable') {
      return new drawableClasses[type](x, y, color, weight, rotation, opacity);
    } else {
      return new drawableClasses[type](x, y, color, weight, opacity);
    }
  };

  addTextBox = () => {
    let { x, y } = this.defaultTextPosition;
    const { pageY, stageWidth } = this.props;
    if (!_.isUndefined(pageY)) {
      y = pageY - (stageWidth / 1032) * 74;
    }
    const newDrawable = this.getNewDrawableBasedOnType(
      x,
      y,
      this.props.drawableType,
      this.props.drawableColor,
      this.props.drawableWeight,
      null,
      null,
      this.props.drawableOpacity
    );
    const { drawables } = this.state;
    const drawableToAdd = newDrawable;
    drawables.push(drawableToAdd);
    this.setState({
      newDrawable: [],
      drawables,
      selectShape: drawableToAdd.id
    }, () => {
      this.props.onAddShape(drawableToAdd);
    })
  }

  addPoint = () => {
    let { x, y } = this.defaultTextPosition;
    const { drawableType, drawableColor, drawableWeight, pageY, stageWidth } = this.props;
    if (!_.isUndefined(pageY)) {
      y = pageY - (stageWidth / 1032) * 74;
    }
    const newTextDrawable = this.getNewDrawableBasedOnType(
      x,
      y,
      'TextBoxPoint',
      drawableColor,
      drawableWeight
    );
    const pointPosX = x + newTextDrawable.defaultSize.w / 2;
    const pointPosY = drawableWeight * 8 + y + 5;

    const newPointDrawable = this.getNewDrawableBasedOnType(
      pointPosX,
      pointPosY,
      drawableType,
      drawableColor,
      drawableWeight
    );

    newPointDrawable.id = `${newTextDrawable.id}_point`;

    const { drawables } = this.state;

    drawables.push(newPointDrawable);
    drawables.push(newTextDrawable);
    this.setState({
      newDrawable: [],
      drawables,
      selectShape: newTextDrawable.id
    }, () => {
      this.props.onAddShape(newPointDrawable);
      this.props.onAddShape(newTextDrawable);
    })
  }

  setColorIntoShape = () => {
    const { showedFill, drawableColor, pageSelectedShape } = this.props;
    const { drawables, } = this.state;
    if (pageSelectedShape) {
      const selectedShape = _.get(pageSelectedShape, pageSelectedShape.name);
      if (selectedShape && selectedShape.shapeType !== 'text') {
        const selectedShapeIndex = drawables.findIndex((drawable) => {
          return selectedShape.id === drawable.id;
        });
        if (selectedShapeIndex !== -1) {
          drawables[selectedShapeIndex].fill = showedFill ? drawableColor : "transparent";
          this.setState({
            drawables
          })
        }
      }
    }
  }

  handleMouseDown = e => {
    if (this.props.showDrawables && !this.props.showedDivider) {
      let isChangedState = false;
      if (this.props.drawableType !== 'TextBox') {
        const { newDrawable } = this.state;
        const { x, y } = e.target.getStage().getPointerPosition();
        if (newDrawable.length === 0 && this.props.drawableType !== 'PolygonDrawable' && !this.props.dragImgEnabled) {
          const newDrawable = this.getNewDrawableBasedOnType(
            x,
            y,
            this.props.drawableType,
            this.props.drawableColor,
            this.props.drawableWeight,
            null,
            null,
            this.props.drawableOpacity
          );
          this.setState({
            newDrawable: [newDrawable]
          });
          isChangedState = true
        } else {
          if (this.props.drawableType === 'PolygonDrawable' && !this.props.dragImgEnabled) {
            const newDrawableItem = {
              drawableType: 'PolygonDrawable',
              x,
              y,
              type: 'mousedown',
              fill: 'transparent',
              color: this.props.drawableColor,
              weight: this.props.drawableWeight,
              opacity: this.props.drawableOpacity
            }
            this.setState({
              newDrawable: [newDrawableItem]
            });
            isChangedState = true
          }
        }
      }
      if (!isChangedState) {
        this.checkDeselect(e);
      }
    }
    if (this.props.showedDivider) {
      this.toolLayerRef.current.registerMouseDown();
    }
  };

  handleMouseUp = e => {
    if (this.props.showDrawables && !this.props.dragImgEnabled && !this.props.showedDivider && this.props.drawableType !== 'TextBox' && this.props.drawableType !== 'PolygonDrawable') {
      const { newDrawable, drawables } = this.state;
      if (newDrawable.length === 1) {
        const { x, y } = e.target.getStage().getPointerPosition();
        const drawableToAdd = newDrawable[0];
        drawableToAdd.registerMovement(x, y);
        drawables.push(drawableToAdd);
        this.setState({
          newDrawable: [],
          drawables
        });
        this.props.onAddShape(drawableToAdd);
      }
    }
    if (this.props.showedDivider) {
      this.toolLayerRef.current.registerMouseUp();
    }
  };

  handleMouseMove = e => {
    const { x, y } = e.target.getStage().getPointerPosition();
    if (this.props.showDrawables && !this.props.showedDivider && this.props.drawableType !== 'TextBox') {
      const { newDrawable } = this.state;
      if (newDrawable.length === 1 && !this.props.dragImgEnabled) {
        let updatedNewDrawable;
        if (this.props.drawableType === 'PolygonDrawable') {
          updatedNewDrawable = {
            drawableType: 'PolygonDrawable',
            color: this.props.drawableColor,
            weight: this.props.drawableWeight,
            x,
            y,
            type: 'mousemove',
            fill: 'transparent',
            id: this.props.showPolygonId
          }
        } else {
          updatedNewDrawable = newDrawable[0];
          updatedNewDrawable.registerMovement(x, y);
        }
        this.setState({
          newDrawable: [updatedNewDrawable]
        });
      }
    }
    if (this.props.showedDivider) {
      this.toolLayerRef.current.registerMouseMove(x, y);
    }
  };

  handleTouchMove = (e) => {
    if (this.props.showDrawables && !this.props.dragImgEnabled) {
      e.evt.preventDefault();
      this.handleMouseMove(e);
    }
  }

  downloadURI = (uri, name) => {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  handleExport = () => {
    const uri = this.myRef.current.children[0].toDataURL();
    this.axios.handleFileUpload(this.props.name, uri, this.props.onExport);
  };

  handleExportDevJson = () => {
    let konvaJSON = this.myRef.current.toJSON();
    konvaJSON = JSON.parse(konvaJSON);
    const devObj = {
      fields: []
    };
    const konvaJSONChildren = konvaJSON.children[0].children;
    const value = '';
    const buttonValue = PLACEHOLDERS.TASK_BUTTON;
    let textAlign = '';
    konvaJSONChildren.forEach((item) => {
      if (item.className === 'Rect') {
        let { x, y, width, height, stroke, rotation = 0 } = item.attrs;
        textAlign = stroke === 'red' || stroke === '#FF0000' ? 'left' : 'center';
        if (stroke === '#0000FF' || stroke === '#008000' || stroke === '#FFFF00') {
          devObj.fields.push({
            x,
            y,
            width,
            height,
            value: buttonValue,
            type: stroke === '#0000FF' ? 'button-text' : stroke === '#008000' ? 'button-drawing' : 'button-text-drawing',
            text: '',
            stageWidth: this.props.stageWidth
          });
        } else if (stroke === '#FF00FF') {
          devObj.fields.push({ x, y, type: 'microphone', stageWidth: this.props.stageWidth });
        } else {
          devObj.fields.push({ x, y, width, height, rotation, value, textAlign, stageWidth: this.props.stageWidth });
        }
      }
    })
    konvaJSON = JSON.stringify(devObj, null, "  ");
    this.axios.postRequest({ action: 'saveKonvaJSON', name: this.props.name, konvaJSON });
  };

  private handleExportDevDraggableImagesJson = () => {

    const devObj = {
      draggableImagePositions: this.draggableImagePositions
    };
    const konvaJSON = JSON.stringify(devObj, null, "  ");
    this.axios.postRequest({ action: 'saveKonvaJSON', name: `draggable_${this.props.name}`, konvaJSON });
  }

  private checkDeselect = (e) => {
    if (this.enabledDeselectShape) {
      // deselect when clicked on empty area
      const clickedOnEmpty = e.target === e.target.getStage() || e.target.className === 'Image';
      if (clickedOnEmpty) {
        this.setState({
          selectShape: null
        });
      }
    }
  };

  private getDraggableImagesJson = () => {
    let konvaJSON = this.myRef.current.toJSON();
    konvaJSON = JSON.parse(konvaJSON);
    const konvaJSONChildren = _.get(konvaJSON, 'children[1].children');
    let draggableImagePositions = [];
    if (!_.isEmpty(konvaJSONChildren)) {
      konvaJSONChildren.forEach((item) => {
        if (item.className === 'Image') {
          draggableImagePositions.push({
            x: item?.attrs?.x,
            y: item?.attrs?.y,
            rotation: item?.attrs?.rotation,
            stageWidth: this.props.stageWidth
          })
        }
      });
    }
    return draggableImagePositions;
  }

  render() {
    let drawables = [...this.state.drawables, ...this.state.newDrawable];
    const {
      stageWidth,
      stageHeight,
      edition,
      userName,
      showedDivider,
      showedRulerTriangle,
      showedRuler,
      showedProtractor,
      dragImgEnabled,
      drawableOpacity,
      pageY
    } = this.props;
    return (
      <div>
        <Stage
          ref={this.myRef}
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          onMouseMove={this.handleMouseMove}
          onTouchStart={this.handleMouseDown}
          onTouchEnd={this.handleMouseUp}
          onTouchMove={this.handleTouchMove}
          width={stageWidth}
          height={stageHeight || this.defaultStageHeight}
        >
          <Layer>
            <URLImage
              // src={`${this.axios.baseUrl}${this.axios.drawingImageFolder}${userName}/${edition}/${this.props.name}.png`}
              src={`php/${this.axios.drawingImageFolder}${userName}/${edition}/${this.props.name}.png`}
              width={stageWidth}
              height={stageHeight || this.defaultStageHeight}
            />
            {
              // eslint-disable-next-line array-callback-return
              drawables.map((drawable, i) => {
                let shapeProps;
                let shapeType;
                if (this.props.dragImgEnabled) {
                  let dx = drawable.x - drawable.startx;
                  let dy = drawable.y - drawable.starty;
                  let r;
                  const shapeFill = drawable.fill ? drawable.fill : "transparent";

                  switch (drawable.drawableType) {
                    case 'RectangleDrawable':
                      shapeType = 'rectangle';
                      shapeProps = {
                        x: drawable.startx,
                        y: drawable.starty,
                        width: Math.abs(dx),
                        height: Math.abs(dy),
                        fill: shapeFill,
                        stroke: drawable.color,
                        strokeWidth: drawable.weight,
                        rotation: drawable.rotation,
                        opacity: drawable.opacity
                      }
                      break;
                    case 'EllipseDrawable':
                      shapeType = 'ellipse';
                      shapeProps = {
                        x: drawable.startx + dx / 2,
                        y: drawable.starty + dy / 2,
                        width: Math.abs(dx),
                        height: Math.abs(dy),
                        fill: shapeFill,
                        stroke: drawable.color,
                        strokeWidth: drawable.weight,
                        opacity: drawable.opacity
                      }
                      break;
                    case 'CircleDrawable':
                      shapeType = 'circle';
                      r = Math.sqrt(dx * dx + dy * dy);
                      shapeProps = {
                        x: drawable.startx,
                        y: drawable.starty,
                        width: 2 * r,
                        height: 2 * r,
                        fill: shapeFill,
                        stroke: drawable.color,
                        strokeWidth: drawable.weight,
                        opacity: drawable.opacity
                      }
                      break;
                    case 'PointDrawable':
                      shapeType = 'point';
                      r = Math.sqrt(dx * dx + dy * dy);
                      shapeProps = {
                        x: drawable.startx,
                        y: drawable.starty,
                        width: 2 * r,
                        height: 2 * r,
                        fill: drawable.fill,
                        stroke: drawable.color,
                        strokeWidth: drawable.weight,
                        opacity: drawable.opacity
                      }
                      break;
                    case 'TextBox':
                      shapeType = 'text';
                      shapeProps = {
                        x: drawable.startx,
                        y: drawable.starty,
                        width: Math.abs(dx),
                        height: Math.abs(dy),
                        fill: drawable.color,
                        fontSize: drawable.fontSize,
                        text: drawable.text,
                        align: drawable.align,
                        opacity: drawable.opacity
                      }
                      break;
                    default:
                      if (drawable.shapeType) {
                        shapeType = drawable.shapeType;
                        shapeProps = { ...drawable };
                        delete shapeProps.shapeType;
                      }
                      break;
                  }
                  if (shapeType) {
                    drawable = { shapeType, ...shapeProps, id: drawable.id };
                  }
                }
                if (drawable.shapeType) {
                  shapeType = drawable.shapeType;
                  shapeProps = { ...drawable };
                  delete shapeProps.shapeType;
                  return (
                    <ShapeDraggable
                      stageRef={this.myRef}
                      key={i}
                      draggableEnabled={this.props.dragImgEnabled}
                      shapeProps={shapeProps}
                      shapeType={shapeType}
                      isSelected={drawable.id === this.state.selectShape}
                      onSelect={(e) => {
                        if (this.props.dragImgEnabled && shapeType !== 'point') {
                          this.setState({ selectShape: drawable.id });
                          this.props.onSelectedShape(_.cloneDeep(drawable));
                        }
                      }}
                      onChange={(newAttrs) => {
                        const shapes = this.state.drawables.slice();
                        const id = shapes[i].id;
                        shapes[i] = { ...newAttrs, id };
                        let pointTextShape;
                        if (shapes[i].shapeType === 'point') {
                          const pointTextId = id.split('_')[0];
                          pointTextShape = shapes.find((item) => {
                            return item.id === pointTextId;
                          });
                          if (pointTextShape) {
                            const { x, y, strokeWidth } = shapes[i];
                            const { width, height, shapeType, defaultSize } = pointTextShape;
                            let pointTextX;
                            let pointTextY;
                            if (width) {
                              pointTextX = -width / 2 + x;
                            } else {
                              pointTextX = -defaultSize.w / 2 + x;
                            }
                            if (height) {
                              pointTextY = -height - 5 + y;
                            } else {
                              pointTextY = -strokeWidth * defaultSize.fontSize - 5 + y;
                            }
                            if (shapeType) {
                              pointTextShape.x = pointTextX;
                              pointTextShape.y = pointTextY;
                            } else {
                              pointTextShape.startx = pointTextX;
                              pointTextShape.starty = pointTextY;
                              pointTextShape.x = pointTextX + pointTextShape.defaultSize.w;
                              pointTextShape.y = pointTextY + pointTextShape.defaultSize.h;
                            }
                          }
                          this.setState({ drawables: shapes });
                          this.props.onUpdateShape(shapes[i]);
                          this.props.onSelectedShape(_.cloneDeep(shapes[i]));
                          this.props.onUpdateShape(pointTextShape);
                          this.props.onSelectedShape(_.cloneDeep(pointTextShape));
                        } else {
                          this.setState({ drawables: shapes });
                          this.props.onUpdateShape(shapes[i]);
                          this.props.onSelectedShape(_.cloneDeep(shapes[i]));
                        }
                      }}
                      onTextDblClick={(type) => {
                        if (type === 'start') {
                          this.enabledDeselectShape = false;
                        } else {
                          this.enabledDeselectShape = true;
                        }
                      }}
                    />
                  )
                } else if (drawable.drawableType === 'PolygonDrawable') {
                  return (
                    <PolygonDrawable
                      fill={drawable.fill}
                      weight={drawable.weight}
                      color={drawable.color}
                      selectShape={this.state.selectShape}
                      dragImgEnabled={this.props.dragImgEnabled}
                      transformPolygonToCurveLine={this.currentAction === 'transformPolygonToCurveLine'}
                      opacity={drawable.opacity}
                      x={drawable.x}
                      y={drawable.y}
                      type={drawable.type}
                      initState={_.cloneDeep(drawable.state)}
                      initProperties={{
                        id: drawable.id,
                        color: drawable.color,
                        weight: drawable.weight,
                        fill: drawable.fill
                      }}
                      onFinish={(polygon) => {
                        const { newDrawable, drawables } = this.state;
                        if (newDrawable.length === 1) {
                          drawables.push(polygon);
                          this.setState({
                            newDrawable: [],
                            drawables
                          });
                          this.props.onAddShape(polygon, this.previousDrawableType);
                        }
                      }}
                      onSelect={(polygon) => {
                        if (this.props.dragImgEnabled) {
                          this.setState({ selectShape: polygon.id });
                          this.props.onSelectedShape(polygon);
                        }
                      }}
                      onChange={(polygon) => {
                        this.props.onUpdateShape(polygon);
                      }}
                    />
                  );
                } else {
                  return drawable.render();
                }
              })
            }
          </Layer>
          {
            this.props.layer2 && <Layer>
              {
                this.props.layer2.images && this.getLayer2(this.props.layer2.images)
              }
						</Layer>
          }
          <ToolLayer
            ref={this.toolLayerRef}
            onDrawWithDivider={this.onDrawWithDivider}
            showedDivider={showedDivider}
            showedRuler={showedRuler}
            showedRulerTriangle={showedRulerTriangle}
            showedProtractor={showedProtractor}
            dragImgEnabled={dragImgEnabled}
            pageY={pageY}
            stageWidth={stageWidth}
          />
        </Stage>
      </div>
    );
  }

  private onDrawWithDivider = (type, x, y, radius, rotation) => {
    const { newDrawable, drawables } = this.state;
    switch (type) {
      case 'start':
        if (newDrawable.length === 0) {
          const newDrawable = this.getNewDrawableBasedOnType(
            x,
            y,
            'ArcDrawable',
            this.props.drawableColor,
            this.props.drawableWeight,
            radius,
            rotation,
            this.props.drawableOpacity
          );
          this.setState({
            newDrawable: [newDrawable]
          });
        }
        break;
      case 'drawing':
        if (newDrawable.length === 1) {
          const updatedNewDrawable = newDrawable[0];
          updatedNewDrawable.registerMovement(rotation);
          this.setState({
            newDrawable: [updatedNewDrawable]
          });
        }
        break;
      case 'stop':
        if (newDrawable.length === 1) {
          const drawableToAdd = newDrawable[0];
          drawables.push(drawableToAdd);
          this.setState({
            newDrawable: [],
            drawables
          });
          this.props.onAddShape(drawableToAdd);
        }
        break;
    }
  }

  private getLayer2 = (images) => {
    const imageArr = [];
    images.forEach((image: any) => {
      const count = image.count ? image.count : 1;
      for (let i = 0; i < count; i++) {
        imageArr.push({ ...image.cfg });
      }
    })
    return (
      imageArr.map((cfg: any, index) => {
        return (
          <URLImage
            id={index}
            isSelected={index.toString() === this.state.selectImage}
            dragImgEnabled={this.props.dragImgEnabled}
            onDraggableImageMoved={() => {
              this.props.onDraggableImageMoved()
            }}
            onClick={(event, id)=>{
              this.setState({
                selectImage: id.toString()
              })
            }}
            {...cfg}
          />
        )
      })
    )
  }

}

