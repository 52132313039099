import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import App from "./App";
import { DEFAULT_FOLDER_PATH } from "./util/APP_CONSTANS";
import FrontPage from "./FrontPage";
import HelpVideo from "./components/HelpVideo";
import Axios from "../src/ajax/Axios";
import InfoDialog from "./components/InfoDialog";
import { INFO_DIALOG } from "./util/APP_CONSTANS";
import _ from 'lodash';
import FieldsAndResults from "./generating/FieldsAndResults";

class AppRouter extends Component<any> {
  state = {
    name: "React",
    isUserAuthenticated: undefined,
    alertDialogMessage: '',
    expiredLicense: false
  };
  private readonly defaultFolderPath = DEFAULT_FOLDER_PATH;
  currentTaskPage = 1;
  localAuth = false;

  constructor(props) {
    super(props);
    // c53-e71-ff0
    let { userName, edition, code } = props;
    const axios = new Axios(userName, edition);
    if (code !== 'GEN_FIELD_RES') {
      axios.checkDigitalCode(code, (resp) => {
        if (resp?.data?.status == 1 || ((edition === 'math-1-working-notes-demo' || edition === 'math-1-working-notes' || edition === 'math-2-working-notes-demo' || edition === 'math-3-working-notes-demo' || edition === 'math-4-working-notes-demo') && userName === 'user')) {
          this.setState({
            isUserAuthenticated: true
          })
        } else if (resp?.data?.status == 2) {
          this.setState({
            isUserAuthenticated: true,
            alertDialogMessage: INFO_DIALOG.CONTENT1,
            expiredLicense: true
          })
        } else {
          this.setState({
            isUserAuthenticated: false,
            alertDialogMessage: INFO_DIALOG.CONTENT2
          })
        }
      }, (err) => {
        if ((edition === 'math-1-working-notes-demo' || edition === 'math-1-working-notes' || edition === 'math-2-working-notes-demo' || edition === 'math-3-working-notes-demo' || edition === 'math-4-working-notes-demo') && userName === 'user') {
          this.setState({
            isUserAuthenticated: true
          })
        } else {
          this.setState({
            isUserAuthenticated: false,
            alertDialogMessage: INFO_DIALOG.CONTENT2
          })
        }
      });
    }
  }

  private app = (task, pageFromTask = 0) => {
    const { userName, edition } = this.props;
    if (task) {
      this.currentTaskPage = task.page;
    }
    return (
      <App
        userName={userName}
        edition={edition}
        task={task}
        pageFromTask={pageFromTask}
        expiredLicense={this.state.expiredLicense}
      />
    )
  }

  private genFieldRes = () => {
    const { userName, edition } = this.props;
    return (
      <FieldsAndResults
        userName={userName}
        edition={edition}
      />
    )
  }

  render() {
    const { alertDialogMessage, isUserAuthenticated } = this.state;
    return (
      <Router>
        <Switch>
          <Route
            exact
            path={`${this.defaultFolderPath}/`}
            render={() => {
              if (this.props.code === 'GEN_FIELD_RES') {
                return (
                  <Redirect
                    to={`${this.defaultFolderPath}/genFieldRes`}
                  />
                )
              } else if (this.props.frontPage) {
                return (
                  <Redirect
                    to={`${this.defaultFolderPath}/frontPage`}
                  />
                )
              } else {
                return (
                  isUserAuthenticated || this.localAuth ?
                    <Redirect to={`${this.defaultFolderPath}/app`}/> :
                    (isUserAuthenticated === false && <InfoDialog
											title={INFO_DIALOG.TITLE}
											opened={true}
											content={alertDialogMessage}
											buttonName={INFO_DIALOG.BUTTON_NAME}
										/>)
                )
              }
            }}
          />
          <Route
            exact
            path={`${this.defaultFolderPath}/frontPage`}
          >
            <FrontPage/>
          </Route>
          <Route
            exact
            path={`${this.defaultFolderPath}/app`}
            component={(props) => {
              return this.app(false, this.currentTaskPage);
            }}
          />
          <Route
            exact
            path={`${this.defaultFolderPath}/task`}
            component={(props) => {
              const { task } = props.location.state;
              return this.app(task);
            }}
          />
          <Route
            exact
            path={`${this.defaultFolderPath}/helpVideo`}
            component={(props) => {
              const { search } = props.location;
              const url = search.substring(5, search.length);
              return <HelpVideo
                url={url}
              />;
            }}
          >
          </Route>
          <Route
            exact
            path={`${this.defaultFolderPath}/genFieldRes`}
            component={() => {
              return this.genFieldRes();
            }}
          >
          </Route>
        </Switch>
      </Router>

    );
  }
}

export default AppRouter;

