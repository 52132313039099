import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { render } from 'react-dom'
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './reducers'
import AppRouter from "./AppRouter";
import AdvancedToolbar from "./tools/AdvancedToolbar";

const store = createStore(rootReducer);

const query = new URLSearchParams(window.location.search);
let userName = query.get('user');
let edition = query.get('edition');
let code = query.get('code');
let frontPage = false;
if (edition === 'demo') {
  edition = 'math-1-working-notes-demo';
  userName = 'user';
} else if (edition === 'demo2') {
  edition = 'math-2-working-notes-demo';
  userName = 'user';
} else if (edition === 'demo3') {
  edition = 'math-3-working-notes-demo';
  userName = 'user';
} else if (edition === 'demo4') {
  edition = 'math-4-working-notes-demo';
  userName = 'user';
} else if (edition === 'promo') {
  edition = 'math-1-working-notes';
  userName = 'user';
} else if (!userName && !edition) {
  frontPage = true;
} else if (process.env.NODE_ENV !== 'production' && (!userName || !edition)) {
  edition = 'math-1-working-notes';
  userName = 'admin';
} else if (process.env.NODE_ENV === 'production' && userName === 'admin' && code !== 'GEN_FIELD_RES') {
  edition = '';
  userName = '';
}

render(
  <Provider store={store}>
    <AppRouter
      userName={userName}
      edition={edition}
      frontPage={frontPage}
      code={code}
    />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
