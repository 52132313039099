import React, { Component } from "react";
import { SvgIcon } from "@material-ui/core";

export default class CurveLine extends Component<any, any> {

  render() {
    const st0 = "#0B4B5E";
    const { disabled } = this.props;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-354.9 574.1 63.5 29.8"}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <path id="XMLID_70_" fill={st0} d="M-292.2,599.2L-292.2,599.2c-0.7-0.4-1.5-0.3-2-0.3h-0.2c-0.6,0-1.1,0.1-1.7,0.1
	c-4.1,0.3-8.7,0.7-12.4-1.1c-0.1-0.1-0.3-0.1-0.4-0.2c1.8-0.8,3.2-1.8,4.2-3.2c1.5-2,1.9-4.8,1-7c-0.7-1.8-2.3-2.9-4.3-3.2
	c-2.1-0.3-4.2,0.3-5.8,1.6c-1.3,1.1-2.2,2.5-2.5,4.2c-0.4-0.5-1-1.1-1.5-1.6c2-3.8,2.5-9-1-12.2c-3.2-2.9-8.3-2.9-15.3,0
	c-3.5,1.4-9.5,4.9-10.9,11.6c-0.6,2.6-0.2,5.2,1,7.6c-1.1,0-2.1,0-3.2,0h-0.2c-0.7,0-1.5-0.1-2.4-0.2c-2.2-0.3-4.2-0.6-4.9,0.5
	c-0.3,0.4-0.3,1,0,1.6c0.2,0.5,0.7,0.9,1.1,0.9c4.3,0.6,7,0.5,10.1,0.4c0.5,0,1,0,1.5,0c0.9,1,2,1.9,3.3,2.6
	c2.9,1.7,6.8,2.6,10.6,2.6c2.6,0,5.2-0.4,7.5-1.3c1.9-0.7,3.5-1.9,4.6-3.4c1.2-0.1,2.2-0.2,3.2-0.4c2.1,1.7,4.9,3,7.8,3.4
	c3.6,0.5,7.3,0.2,10.9-0.1l1.7-0.1c0.5,0,0.8-0.3,0.9-0.8C-291.2,600.5-291.5,599.6-292.2,599.2z M-340.3,595.5
	c-0.9-1.2-1.5-2.6-1.6-4.1c-0.5-4.9,3.1-8.9,6.6-10.9c1.6-1,7.4-4,12-3.1c1.6,0.3,2.8,1.2,3.4,2.6c0.8,1.8,0.7,4.3-0.3,6.6
	c-2.9-1.8-6.2-2.6-8.9-2.3c-2.3,0.3-4.5,1.6-5.8,3.6c-1.3,1.8-1.7,3.9-1.2,5.9c0.1,0.5,0.3,1,0.5,1.4
	C-337.1,595.3-338.6,595.4-340.3,595.5z M-322.1,589.4c-2.7,3-6.6,4.4-9.4,5.1c-0.1,0-0.3,0.1-0.5,0.1c-1.1-0.8-1.5-1.9-1.1-3.4
	s1.3-2.6,2.6-3.2c0.7-0.3,1.5-0.4,2.4-0.4c1.6,0,3.3,0.5,5,1.3C-322.7,589-322.4,589.2-322.1,589.4z M-333.4,599.9
	c-1.3-0.3-2.5-0.8-3.6-1.5c1.1-0.1,2.1-0.2,3-0.3c0.4-0.1,0.8-0.1,1.2-0.2c3.1,1.6,7.7,1.5,10.7,1.5h1l0,0
	C-324.6,601-329.8,600.8-333.4,599.9z M-317.8,596.1c-1.5,0-3,0-4.4,0h-0.7c-1,0-2.2,0-3.4-0.1c2.7-1.2,5-2.8,6.8-4.8
	c0.5,0.5,1,1,1.3,1.5C-317.4,594.1-317.5,595.3-317.8,596.1z M-314.9,596c-0.1,0-0.3,0-0.3,0s0.1-0.3,0.1-0.5s0-0.7,0-0.7
	s0.2,0.4,0.2,0.5c0.1,0.2,0.4,0.6,0.4,0.6S-314.8,596-314.9,596z M-313.2,590.8c0.1-1,0.7-1.9,1.7-2.5c0.7-0.5,1.7-0.9,2.6-0.9
	c0.6,0,1.2,0.1,1.6,0.5c0.7,0.5,1,1.1,1,2c0,1.5-1.2,3.2-2.3,4c-0.9,0.6-1.9,1.1-3.1,1.4C-312.8,593.8-313.3,592.1-313.2,590.8z" />
      </SvgIcon>
    )
  }
};
