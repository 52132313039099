import { Component } from "react";
import React from "react";
import { AppBar, Box, Container } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { zIndexTooltip } from "./App";
import { ThemeProvider } from "@material-ui/styles";
import { DEFAULT_FOLDER_PATH } from "./util/APP_CONSTANS";

export default class FrontPage extends Component<any, any> {

  render() {
    const theme = createMuiTheme({
      overrides: {
        MuiAppBar: {
          root: {
            backgroundImage: "url(images/tbarBackground.svg)",
            backgroundSize: "cover",
            textAlign: "center",
            height: "68px"
          },
          colorPrimary: {
            color: "#064B5E",
            backgroundColor: "transparent"//"#3f51b5"
          },
          positionFixed: {
            position: 'fixed',
            left: '50%',
            top: 0,
            transform: 'translate(-50%, 0%)',
            width: 'inherit',
            zIndex: zIndexTooltip + 1
          }
        }
      }
    });
    return (
      <ThemeProvider theme={theme}>
        <Container
          maxWidth={"md"}
        >
          <Box
          >
          <AppBar
            position="fixed"
          >
            <h2>АТОС - Дигитална издања</h2>
          </AppBar>
          <Box
            display="flex"
            flexDirection="row"
            padding={"68px"}
            textAlign={"center"}
          >
            <a
              href={`${DEFAULT_FOLDER_PATH}/?user=zarko&edition=math-1-working-notes`}
              target={"blank"}
            >
              <img
                width={"100%"}
                src={"images/math-1-working-notes/No1/1.png"}
              />
            </a>
            <a
              href={`${DEFAULT_FOLDER_PATH}/?user=zarko&edition=math-2-working-notes`}
              target={"blank"}
            >
              <img
                width={"88%"}
                src={"images/math-2-working-notes/No1/1.png"}
              />
            </a>
          </Box>
          </Box>
        </Container>
      </ThemeProvider>
    )
  }
}