import { withRouter, Link } from 'react-router-dom';
import { Component } from "react";
import React from 'react';
import { setPageToTaskContentChange } from "../actions";
import uuid from "uuid-random";
import { ACTION_TOOLTIPS, DEFAULT_FOLDER_PATH } from "../util/APP_CONSTANS";
import { Tooltip } from "@material-ui/core";

// this also works with react-router-native


class RouteButton extends Component<any, any> {
  render() {
    const { task, text, isPageChanged, dispatch } = this.props;
    return (
      <Link
        to={{
          pathname: `${DEFAULT_FOLDER_PATH}/task`,
          state: { task }
        }}
        onClick={(e) => {
          if (isPageChanged) {
            e.stopPropagation();
            e.preventDefault();
            task.id = uuid();
            dispatch(setPageToTaskContentChange(task));
          }
        }}
      >
        {/*<div style={{ border: 'solid 1px' }}>{text}</div>*/}
        <Tooltip title={text} placement={"right"}>
          <img src={"images/icons/newPageButtonIcon.png"} width={50} height={40} />
        </Tooltip>
      </Link>
    )
  }
}

export default withRouter(RouteButton);