import React, { Component } from "react";
import { SvgIcon } from "@material-ui/core";

export default class ArrowRightAlt extends Component<any, any> {

  render() {
    const st0 = "#084B5E";
    const { disabled } = this.props;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-339.7 581 45.1 29.6"}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <g id="XMLID_295_">
          <path id="XMLID_82_" fill={st0} d="M-295.2,596c-7.1-4.6-14.3-9.1-21.7-13.1c-0.2-0.3-0.5-0.5-0.8-0.5c-0.6-0.4-1.1-0.8-1.8-1.2
		c-0.8-0.5-1.7,0.1-1.9,0.9c-1.1,3.9,0.3,8.1,0.7,12c0,0.2,0,0.4,0,0.5c-2.6-0.8-5.5-1.2-8.1-1.6c-3.3-0.5-7-1-10.2,0.2
		c-1.2,0.4-0.9,2.1,0.4,2.2c3.7,0.2,7.3-0.4,11,0c2.4,0.3,4.7,0.9,7.1,1.3c0.2,4.1,0,8.2-0.5,12.3c-0.1,0.8,0.6,1.9,1.6,1.5
		c8.4-3.7,16.5-8,24.4-12.8C-294.4,597.2-294.6,596.4-295.2,596z" />
        </g>
      </SvgIcon>
    )
  }
};
