import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_6_2 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 241 169.2"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M217,19.2c-1.4-0.3-1.4-0.3-0.7-1.6c-0.4,0.1-0.6,0.1-0.8,0.2c-0.8,0.5-1.2,0.3-1.5-0.6
	c-0.2-0.5-0.8-0.8-1.3-1.2c0.1-0.1,0.1-0.3,0.2-0.4c-0.2-0.1-0.3-0.1-0.5-0.2c0,0,0,0,0,0.1c-0.4,0-1,0.1-1.2-0.2
	c-1.1-1.1-2.1-1-3.3-0.3c-1.1,0.6-1.8-0.1-2.2-1.2c-0.6,0.4-1.1,0.7-1.7,1c-0.5,0.3-1,0.8-1.7,0.1c-0.1-0.1-0.7,0-1,0.1
	c-0.7,0.3-1.3,0.6-2.2,1.1c-0.2-0.7-0.4-1.2-0.7-2c-1.4,0.8-2.8,1.7-4.5,2.7c-0.4-0.6-0.7-1.2-1.1-1.9c-0.8,0.3-1.5,0.6-1.6,1.6
	c0,0.3-0.6,0.6-1,0.7c-0.4,0.1-0.8-0.1-1.1-0.2c-0.4,0.7-0.7,1.3-1.2,1.8c-0.3,0.2-0.8,0.2-1.3,0.2c-0.4,0-0.9-0.1-1.3,0
	c-1.8,0.2-1.8,0.3-0.9,1.9c0.6,1,0.4,1.3-0.6,1.7c-0.5,0.2-0.9,0.4-1.3,0.6c0.2,1.1,0.4,2.2,0.5,3.3c0,0.2-0.5,0.6-0.8,0.7
	c-0.6,0.2-1.3,0.4-2.1,0.6c0.6,0.6,0.9,1,1.3,1.5c-0.8,0.5-1.4,0.8-2,1.2c0,0.1,0,0.2,0.1,0.3c0.7,0.1,1.3,0.2,2.3,0.4
	c-1,0.9-1.8,1.6-2.7,2.3c0.1,0.1,0.1,0.2,0.2,0.3c0.5,0,1,0.1,1.3,0.1c-0.2,1.2-0.3,2.1-0.4,3.1c0,0.1,0.3,0.4,0.5,0.4
	c1.4,0.3,1.6,0.8,0.8,2c-0.3,0.5-0.5,1-0.8,1.6h2.1c0.4,1.3-1.5,1.7-1.1,3.4c0.4,1.7,2.1,1.3,2.6,2.4c-0.1,0.3-0.3,0.7-0.5,1.3
	c0.4,0,0.5,0,0.7-0.1c1.2-0.1,1.5,0.1,1.6,1.3c0,0.4,0.1,0.8,0.1,1.4c0.2,0,0.5,0,0.7-0.1c1-0.2,1.8-0.1,2.6,0.8
	c0.7,0.9,1.9,1.4,2.8,2.1c1.2-0.6,1.3-2.4,3.2-2.3c-0.1-0.5-0.3-0.9-0.4-1.3c1.4-0.6,1.6-1.7,0.4-2.8c-1.4-1.3-1.6-2.5-1-4.3
	c0.1-0.4-0.1-0.9-0.3-1.3c-0.3-0.5-0.8-1-1.2-1.5c-1.5-1.9-1-3.9,0-5.8c0.6-1.1,2.9-1.3,4.4-0.6c1.8,0.9,3.1,3.9,3.1,5.8
	c0,0.7-0.3,1.6,0.7,2c1.3,0.6,1.6,0.5,2.2-0.7c0.3-0.6,0.7-1.1,0.7-1.7c0-0.5-0.3-1.2-0.6-1.6c-0.3-0.4-0.9-0.7-1.4-1.1
	c0.5,0,0.8,0,1,0c0.3,0,0.6-0.2,0.7-0.2c0.2-0.6,0.4-1,0.4-1.4c0-0.7-0.1-1.4-0.1-2.1c0-0.3,0.1-0.7,0.1-1.3
	c-1.1-1.9-1.1-1.9,0.1-2.8c-0.3-0.5-0.6-1.1-1-1.8c0.8-0.1,1.4-0.1,2.1-0.2c0-0.3,0.1-0.7,0.1-1.1c0.5,0.1,1,0.1,1.6,0.2
	c0.3-0.5,0.6-1,0.9-1.6c0.5-0.9,0.9-1.1,1.6-0.3c0.5,0.6,0.8,0.6,1.2,0c0.1-0.1,0.3-0.3,0.3-0.3c0.9,0.4,1.7-0.4,2.5-0.3
	c0.9,0,1.4-0.4,1.9-1c0.5-0.6,1.2-1.2,1.8-1.8c0.1-0.2,0.3-0.4,0.2-0.6C217.5,19.4,217.2,19.3,217,19.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M179.5,169.2c-0.5-1.9-1-3.8-1.3-5.7c-0.5-2.9-1.2-5.9-1.4-8.9c-0.2-4.4,0-8.8-0.1-13.2c0-0.9-0.3-1.9-0.5-2.8
	c-0.4-2.1,0.1-4,1-5.8c0.5-1.1,1.5-1.1,2.5-0.5c0.7,0.4,1.5,0.6,2.3,0.7c2.7,0.6,5.3,1.2,8.3,1.8c-1.3,4.1-2.7,8.5-4.1,12.9
	c2.9-3.9,3.7-8.7,5.3-13.4c1.1,0,2.2-0.1,3.5,0.3c1.6,0.5,3.5,0.6,5.2,0.3c1.5-0.3,2.8-1.4,4.2-2.1c0.5,4.6-0.4,9.2-1.2,13.7
	c-1.6-0.1-3.1-0.3-4.7-0.3c-0.5,0-1.3-0.4-1.4,0.6c0,0.2,0.9,0.5,1.5,0.6c1.5,0.3,3,0.5,4.6,0.7c0.7,0.1,0.9,0.5,0.8,1.2
	c-0.7,5.8-1.5,11.6-2.2,17.3c-0.1,0.9-0.3,1.7-0.4,2.6C194.3,169.2,186.9,169.2,179.5,169.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M68.2,169.2c-0.8-6-1.6-12-2.4-18c0-0.2,0-0.3,0-0.5c-0.2-2.6-0.2-2.7,2.4-3.1c0.9-0.2,1.9-0.1,2.9-0.2
	c0.5-0.1,0.9-0.4,1.4-0.6c-0.4-0.2-0.9-0.6-1.3-0.6c-1.5,0-3.1,0.2-4.7,0.3c-0.7-1.5-1.6-10.5-1.3-13.7c1.3,0.7,2.5,1.7,3.9,2.1
	c1.2,0.3,2.7,0,4-0.1c1.7-0.1,3.4-0.4,5-0.6c1.6,4.8,2.4,9.6,5.4,13.5c-1.4-4.4-2.8-8.8-4.2-13.3c1.2-0.1,2.2,0,3.1-0.2
	c2.2-0.5,4.5-1,6.7-1.6c0.2-0.1,0.5-0.1,0.7-0.2c1.8-1,3.1-0.4,3.5,1.5c0.4,2.1,0.7,4.3,0,6.5c-0.2,0.7,0,1.5,0,2.2
	c0.3,6.5-0.1,13-1.3,19.5c-0.3,1.4-0.8,2.8-1.2,4.3c-0.2,0.9-0.3,1.8-0.5,2.7C82.9,169.2,75.5,169.2,68.2,169.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M203.1,169.2c0-0.6,0-1.2,0.1-1.8c0.6-4.6,1.2-9.3,1.9-13.9c0.2-1.4,1.1-2.4,2.4-3c0.8-0.4,1.6-0.6,2.3-1
	c0.4-0.2,0.7-0.5,1-0.8c-0.1-0.2-0.2-0.3-0.2-0.5c-1.8,0.7-3.5,1.4-5.2,2.1c0.1-0.8-0.3-1.9,1-2.5c0.2-0.1,0.1-0.9,0.2-1.4
	c1.2-3.9,2.5-7.8,3.8-11.7c0.1-0.4,0.7-0.8,1.1-0.9c1.9-0.4,3.7-0.6,5.6-0.9c1.4-0.2,1.6,0,1.7,1.4c0.3,3.6,0.6,7.1-0.1,10.7
	c-0.2,1.1,0.4,2.3,0.4,3.4c0,3.1,0.3,6.2-0.1,9.2c-0.5,3.8-1.6,7.6-2.5,11.4C212,169.2,207.5,169.2,203.1,169.2z M214.4,146.4
	c-0.1-0.3-0.1-0.4-0.1-0.5c-1.4-1-2.9-1.2-4.5-1.1c-0.2,0-0.3,0.3-0.4,0.4c0.2,0.2,0.4,0.4,0.6,0.4c1.2,0.3,2.4,0.5,3.6,0.7
	C213.8,146.4,214.1,146.4,214.4,146.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M53.3,169.2c-0.6-2.6-1.1-5.2-1.8-7.8c-0.9-3.4-1.1-6.8-1.1-10.3c0-1.3,0.4-2.6,0.5-3.9c0.1-1.3,0-2.7,0-4
	c0-2.2-0.1-4.4-0.1-6.6c0-0.8,0.2-1.7,0.2-2.5c0.1-0.9,0.5-1.2,1.3-1.1c0.8,0.1,1.6,0.3,2.5,0.4c1.1,0.2,2.1,0.3,3.2,0.5
	c0.8,0.1,1.4,0.4,1.7,1.4c1.1,3.7,2.3,7.3,3.5,11c0.1,0.3,0.1,0.7,0.1,1.1c0.1,0.3,0.1,0.6,0.3,0.7c1.3,0.6,0.8,1.7,0.9,2.5
	c-1.7-0.7-3.5-1.4-5.2-2.2c-0.1,0.1-0.2,0.3-0.3,0.4c0.3,0.3,0.6,0.6,0.9,0.8c0.8,0.4,1.6,0.6,2.3,1c1.5,0.8,2.4,2,2.7,3.8
	c0.5,4.4,1.2,8.8,1.8,13.2c0.1,0.6,0.1,1.2,0.1,1.8C62.3,169.2,57.8,169.2,53.3,169.2z M55.4,146c0,0.1,0.1,0.3,0.1,0.4
	c1.6-0.3,3.3-0.6,4.9-0.9c0.1,0,0.2-0.3,0.2-0.4c-0.1-0.2-0.2-0.4-0.4-0.4C58.4,144.7,56.8,145,55.4,146z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M90.7,31c-0.6-0.9-1.2-1.8-1.9-2.7c-0.8-1.1-0.7-2.2,0.5-2.9c0.2-0.1,0.3-0.2,0.6-0.5c-1.6-0.5-3.1-1-4.6-1.4
	c-0.8-0.2-1.5-0.3-1.8-1.1c-0.3-0.9,0.2-1.5,0.8-2.1c0.2-0.2,0.3-0.6,0.4-0.9C84.3,19.2,84,19,83.7,19c-1.8,0.1-3.5,0.2-5.3,0.5
	c-2.2,0.4-2.6,0.2-2.7-2c0-0.3,0-0.6-0.1-0.8c-0.1-0.9-0.5-1.1-1.3-0.8c-1.6,0.5-2.8,1.7-4.7,1.7c-0.7,0-1.3,0.1-2-0.3
	c-1.7-1-3.2-2.5-5.4-2.7c-1.5-0.2-3.1-0.4-4.8-0.6c0.6,1.6,2.4,2.3,2.2,4.3c-0.7-0.5-1.1-1-1.7-1.1c-1-0.2-2.1-0.2-3.1-0.4
	c-2.7-0.5-4.6,0.7-6.1,2.7c-0.2,0.3-0.3,0.6-0.4,1c0.4,0,0.8,0.1,1.2,0.1c1.4-0.3,2.2,0.1,2.6,1.4c-1.2,0.7-2.5,1.4-3.8,2.2
	c0.4,0.4,0.7,0.7,1,1.1c-0.4,0.4-0.7,0.8-1,1.1c-0.9,1.1-1.8,2.3-2.6,3.4c-0.1,0.2,0,0.6,0,0.9c0.2,0,0.5,0.1,0.7,0
	c1.3-0.9,2.6-1.8,4-2.6c0.3-0.2,0.8-0.4,0.9-0.3c0.7,0.5,1.2,0.1,1.7-0.3c0.5-0.3,1-0.5,1.6-0.6c1.1-0.3,1.9,0.6,1.8,1.7
	c0,0.5,0.1,1.3,0.5,1.6c0.3,0.2,1.1-0.1,1.6-0.4c0.4-0.2,0.7-0.7,1.1-0.9c0.3-0.2,0.7-0.3,1.1-0.5c0.1,1,0.1,1.8,0.2,2.6
	c0.1,0.6,0.3,0.7,0.9,0.5c1.5-0.5,2.9-0.9,4.4-1.3c0.9-0.3,1.4,0.1,1.4,1.1c-0.1,1.6-0.2,3.2-0.2,4.8c0,0.6,0.2,1.6,0.4,1.6
	c1,0.1,0.7-0.8,0.9-1.3c0.1-0.3,0.6-0.5,0.9-0.7c0.1,0.3,0.2,0.6,0.2,0.9c0.1,0.6,0,1.3,0.1,1.9c0.1,0.7,1.4,1.8,1.9,2.5
	c0.5-0.1,0.5-1,0.9-1.3c0.6-0.4,1.3-0.5,2-0.8c2.9-1.4,6.6,1.6,6.5,4.7c-0.1,1.4-2.2,3.7-3.7,3.6c-0.8,0-2.4,1.6-2.2,2.4
	c0.5,1.8-0.8,2.6-1.8,3.7c0.1,0.5,0.4,1,0.4,1.6c0.1,1.7,0,3.4,0,5c1.3,0.5,2.4-1.6,3.1,0.2c1.1-0.4,2.2-0.6,3.2-1.1
	c1-0.5,2.4-0.6,2.5-2.1c0.1-1.1,0-2.2-0.1-3.3c-0.2-1.8-0.5-3.5,1.4-4.6c2.4-1.3,3.9-3.6,5.6-5.7c1-1.2,1.3-2.5,0.9-4
	c-0.5-2.1-1-4.1-1.5-6.3c0.4-0.1,0.8-0.3,1.3-0.4C90.9,32.1,91.2,31.7,90.7,31z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M71.6,40.7c-0.5-0.8-1.9-1.8-2.4-2.5c-0.8,0.9-1.5,1-1.9,0.2c-0.5-1.1-1-2.2-1.2-3.3c-0.2-1.1,0-2.3,0-3.6
	c-1.1,0.3-2.2,0.4-3.2,0.9c-1.9,0.9-3.2,0.5-3.7-1.6c-0.5,0.2-0.9,0.4-1.4,0.7c-1,0.6-1.7,0.4-2.1-0.7c-0.3-0.7-0.5-1.5-0.8-2.4
	c-0.7,0.4-1.4,0.9-2.1,1.3c-0.7,0.4-1,1-0.9,1.8c0.1,1.5-0.1,2.9,1,4.3c0.9,1.1,0,3.8-1.2,5.1c-1.2,1.3-2.2,2.6-3.6,4.3
	c1.4,0.4,2.3,0.7,3.3,1c0.5,0.1,1.1,0.1,1.5,0.2c0.4,0.1,0.6,0.5,0.9,0.8c-0.2,0.2-0.5,0.5-0.7,0.7c-0.2,0.1-0.5,0.3-0.8,0.2
	c-1.1-0.2-1.3,0.6-1.3,1.3c0,0.6-0.7,1.4,0.3,1.8c1.7,0.6,3.4,1.3,5.1,1.5c2.1,0.3,2.6-0.6,1.9-2.6c0,0,0.1-0.1,0.1-0.2
	c1.5,1.1,2.3,3.2,1.6,4.9c-0.4-0.6-0.7-1.1-0.9-1.4c-1.6,0.1-3,0.3-4.3,0.2c-1.4-0.1-2.7-0.6-4.1-1c-0.1,1.8,0.4,3.4,1.3,4.9
	c0.9,1.4,2.2,2.1,3.8,2.6c1.5,0.5,3,0.9,4.5,0.3c1.9-0.7,3.7-1.6,5.4-2.4c1-0.5,1.9-1,3.1-1.7c-1.2,1.9-2.8,3-4.7,3.8
	c-1.3,0.5-1.9,1.2-1.7,2.7c0.1,1.4,0.1,2.8-0.1,4.2c-0.3,2.4-0.4,4.8,0.2,7.3c0.2,1,0.3,2.1,0.5,3.2c0.3,1.2,0.7,2.5,1.1,3.9
	c1.4-2.2,2.6-4.1,3.8-6c0.2-0.3,0.3-0.8,0.6-1c1.1-0.9,2.3-1.8,3.4-2.7c1.5-1.3,2.6-2.6,2.6-4.9c0-2.5-0.9-4.7-1.4-7.1
	c-0.1-0.6-0.4-1.2-0.6-1.8c-0.8-2.4-0.3-5.1-1.5-7.5c-0.1-0.3-0.1-0.6-0.1-0.9c0.4,0.2,0.4,0.6,0.7,0.7c0.6,0.3,1.3,0.7,1.9,0.6
	c0.7-0.1,0.7-0.8,0.5-1.4c-0.3-1.1,0.3-1.9,1.1-2.5c1.1-0.8,2.2-1.6,3.3-2.4c1.5-1.1,1.5-3.2,0-4.2c-1.5-1-4.4-1-5.9,0
	c-0.3,0.2-0.6,0.4-0.8,0.5c-0.1-0.1-0.2-0.2-0.3-0.3C71.5,40.7,71.6,40.7,71.6,40.7z M60.6,45.3c-1.2,0.1-2.4,0.1-3.5-0.1
	c-0.5-0.1-1-0.7-1.5-1.1c-0.6-0.5-0.4-1,0-1.5c0.1-0.1,0-0.3,0-0.5c0-1.4,0.1-2.9,0.1-4.5c-0.1,0-0.4-0.2-0.8-0.3
	c0.5-0.8,1-1.1,1.9-0.9c2.9,0.5,4.8,2.1,6,4.6C64,43.4,63.1,45,60.6,45.3z M65,38.5c-0.3-0.2-0.6-0.4-0.8-0.7
	c-1.4-1.5-2.9-2.9-5.1-3.2c-0.9-0.1-1.8,0.1-2.7,0.2c-0.3,0-0.7-0.1-1.1-0.2c0.2-0.3,0.3-0.7,0.5-1c1-0.8,2-0.8,3.2-0.3
	c2.1,0.8,4.2,1.7,5.5,3.7c0.3,0.4,0.5,0.8,0.7,1.2C65.3,38.3,65.1,38.4,65,38.5z M76.6,41.5c-0.7,0.4-1.5,0.9-2.2,1.5
	c-0.2,0.2-0.1,0.9-0.1,1.3c0,0.4,0.2,0.9,0,1.1c-0.3,0.4-0.9,0.6-1.4,0.8c-0.1,0.1-0.5-0.2-0.6-0.4c-0.1-0.2,0.2-0.5,0.3-0.7
	c0.2-0.3,0.4-0.5,0.5-0.7c-0.1-0.1-0.2-0.2-0.4-0.3c-0.4,0.1-0.7,0.3-1.1,0.4C71.9,42.7,75.1,40.9,76.6,41.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M71.8,41c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0,0C71.7,40.8,71.7,40.9,71.8,41z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M84.9,76c-0.8-1.1-1.6-2.2-2.3-3.4c-1.2-2.1-4.1-3.1-6.2-1.9c-1.3,0.7-2.5,1.6-3.8,2.5c-0.2,0.2-0.3,0.6-0.4,1
	c0.3,0,0.7,0,0.9-0.1c1-0.7,2-1.5,3-2.1c0.9-0.6,1.8-0.8,2.8-0.3c1.6,0.7,2.7,1.9,3.6,3.4c0.5,0.8,1,1.6,1.7,2.3
	c0.6,0.7,1.4,0.5,1.6-0.4c0.2-0.9,0.2-1.8,0.2-2.7c-0.1-1.1-0.2-2.2-0.4-3.8c2.9,1.3,5.6,2.4,8.3,3.6c-1.2,2.8-2.3,5.2-3.4,7.7
	c-0.9,1.9-1.9,3.8-2.5,5.8c-0.6,2.3-0.8,4.7-1.2,7c-0.1,0.8-0.2,1.7-0.2,2.5c-0.1,3.7-0.4,7.5,0.9,11.1c0.1,0.4,0.2,0.9,0.2,1.3
	c0,2,0.6,3.7,2.3,4.9c0.4,0.3,0.6,0.6,0.7,0.7c-0.1,0.9-0.4,1.5-0.3,2.1c0.4,2.5,1,5,1.5,7.5c0.3,1.7,0.5,3.4,0.7,5.1
	c0.1,0.7-0.2,1.3-0.9,1.5c-1.8,0.4-3.5,0.9-5.3,1.2c-2.1,0.4-4.3,0.8-6.4,1c-1.3,0.1-2.6-0.1-3.9-0.1c-0.9,0-1.9,0-2.8,0.3
	c-3.5,0.8-6.8-0.4-8.8-3.3c-1.5-2.1-2.3-4.3-1.9-7c0.3-2,0.2-4.1,0.2-6.1c0-1.4,0.2-2.7,0.1-4.1c-0.2-3.4-0.3-6.8-0.7-10.1
	c-0.4-3.6,0-7.1,0.5-10.6c0.6-4.1,1.7-8,3.3-11.8c1.4-3.2,3.8-5.6,6.4-7.9c2.4-2.2,4.5-4.7,6.8-7c0.2-0.2,0.1-0.7,0.2-1
	c-0.4,0.1-0.8,0.1-1,0.4c-0.8,0.9-1.6,2-2.3,2.9c-0.1,0-0.2-0.1-0.3-0.1c0-1.4,0-2.7,0-4.1c0-0.4-0.2-0.8-0.3-1.2
	c-0.1-0.4-0.2-0.7-0.2-1.1c0.3,0,0.7-0.2,1-0.1c0.7,0.2,1.4,0.5,2.2,0.6c1.7,0.3,2.3,1.8,3.2,2.8c1,1.1,1.9,2.4,2.5,3.7
	c0.5,1,0.6,2.1,0.8,3.2c0.2,1.4,0.2,2.8,0.3,4.2C85.2,75.9,85.1,75.9,84.9,76z M86,89.7c-1-0.1-2-0.2-2.9-0.3
	c-1.7-0.1-3.5-0.3-5.2-0.1c-2.3,0.2-4.6,0.7-6.8,1.1c-0.2,0-0.5,0.2-0.5,0.3c0,1.1-0.2,2.2,0,3.3c0.1,0.5,0.9,0.8,1.7,1.4
	c0,1.1,0,2.5,0,3.8c0,0.2-0.2,0.4-0.2,0.6c-0.1,0.4-0.4,0.8-0.4,1.1c0.4,2,0.8,4,1.2,6c0.1,0.3,0.6,0.7,0.9,0.7
	c3.8-0.5,7.5-1.6,11.1-2.9c0.3-0.1,0.6-0.6,0.6-0.9c0-1.6-0.1-3.2,0-4.8c0.1-2.7-1.2-5.4,0.4-8C86,90.8,86,90.3,86,89.7z M66.6,85.2
	c0.4-0.5,0.7-0.8,0.8-1.2c0-0.2-0.4-0.4-0.6-0.7c-0.3,0.3-0.6,0.7-0.8,1C65.9,84.5,66.3,84.8,66.6,85.2z M63.8,103.9
	c0.3-0.5,0.5-0.8,0.5-1c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2,0.2-0.5,0.5-0.6,0.7C63.2,103.1,63.5,103.4,63.8,103.9z M64.8,124.8
	c-0.2,0-0.4,0.1-0.6,0.1c0.1,0.4,0.2,0.9,0.3,1.3c0,0,0.4-0.1,0.7-0.1C65.1,125.7,64.9,125.2,64.8,124.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M59.5,131.6c0,2-1.4,1.7-2.5,1.6c-1.7-0.1-3.4-0.1-5-0.5c-1.2-0.3-2.3-0.9-3.3-1.6c-1.1-0.8-1-1.9,0-2.8
	c0.1-0.1,0.2-0.2,0.2-0.3c0.2-1.2,0.8-2.4,0.6-3.5c-0.5-2.7,0.4-5.2,0.5-7.8c0-2.1,0.6-4.3,0.9-6.4c0.5-3.5,1-6.9,0.4-10.5
	c-0.5-2.8-0.1-5.7-0.2-8.6c0-1.5-0.3-2.9-0.4-4.4c-0.1-1.9-0.2-3.7-0.3-5.6c-0.1-1.2-0.3-2.4-0.3-3.6c0-0.3,0.3-0.7,0.4-1
	c0.8-2.1,2.7-2.9,4.4-4.1c0.1,0,0.3,0.1,0.4,0.1c-0.1,1-0.2,2-0.2,3c0,0.6,0.4,1.2,0.8,1.7c0.1,0.2,1-0.1,1.3-0.3
	c0.8-0.8,1.6-1.7,2.4-2.7c0.1,0.3,0.3,0.6,0.3,0.8c0.1,1.9,0.8,3.5,2,5.1c0.8,1.1,1.1,2.5,1.4,3.8c0.1,0.5-0.3,1.2-0.5,1.8
	c-0.4,1.3-0.8,2.6-1.2,3.8c-0.1,0.3-0.1,0.5-0.1,0.8c-0.1,1-0.1,2-0.3,2.9c-0.6,3.8-1,7.5,0.2,11.3c0,0.1,0.1,0.2,0.1,0.3
	c0.4,4.5,1,9,0.6,13.6c-0.2,2.2-0.3,4.3-0.4,6.5c-0.1,1.1-0.1,2.1,0.6,3.2c0.9,1.4,0,3.3-1.6,3.7C60.4,132.1,60.1,131.8,59.5,131.6z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M100.3,86.2c0.5,4.4,1,8.5,1.6,12.7c-1.7-0.2-2.2,1.1-2.9,2.2c-0.2,0.3,0,0.9,0,1.4c0.1,0,0.2,0,0.3,0
	c0.7-0.9,1.5-1.8,2.2-2.7c0.1,0.1,0.3,0.1,0.4,0.2c0,1.4-0.1,2.9,0,4.3c0.1,1.3,0.4,2.6,0.5,3.9c0,0.6-0.1,1.2-0.3,1.7
	c-1,3-1.9,6-3.1,8.8c-0.8,1.8-2.1,3.4-3.2,5.1c-0.2,0.3-0.6,0.4-0.9,0.5c-0.4,0.2-0.8,0.4-1.3,0.6c-0.6-2.9-1.3-6-1.9-9
	c0.1,0,0.2-0.1,0.2-0.1c0.8,0.7,1.7,1.4,2.7,2.2c0.3-1,0.2-1.5-0.6-1.8c-0.5-0.2-1.1-0.5-1.4-0.9c-0.2-0.3-0.1-0.9-0.2-1.4
	c0.5,0,1.2-0.3,1.4-0.1c0.7,0.9,1.5,0.7,2.3,0.4c0.9-0.3,1.9-0.4,2.8-0.6c0-0.2,0-0.3,0-0.5c-0.6,0-1.3,0-1.9,0c-0.9,0-1.8,0-2.7,0
	c-0.8,0-1.5-0.2-2.3-0.2c-1.7-0.1-2.4-0.7-2.8-2.4c-1.1-4.1-1.6-8.3-1.5-12.5c0-0.5,0.2-1,0.2-1.5c0-0.8-0.2-1.7-0.2-2.5
	c0-0.9-0.1-1.8,0.2-2.7c0.3-1,0.8-1.9,1.3-2.9C93,89.4,96.7,88.8,100.3,86.2z M93.4,105.1c0.2,0,0.3,0,0.5,0.1
	c0.1-0.4,0.2-0.7,0.3-1.1c1-3.9,2.5-7.5,4.6-10.8c0.2-0.3,0.8-0.4,1.1-0.7c0.3-0.3,0.4-0.7,0.6-1.1c-0.3-0.2-0.7-0.5-1-0.5
	c-0.4,0.1-0.7,0.5-1,0.8c-2.6,3.5-4.2,7.3-5.1,11.5C93.3,103.9,93.4,104.5,93.4,105.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M46.6,90.8c1.1,0.3,2.2,0.7,3.3,1c-0.4,3.8,0,7.4,0.6,11.1c0.1,0.4,0.1,0.8,0,1.2c-0.4,3.6-0.8,7.3-1.2,10.9
	c0,0.1-0.1,0.2-0.3,0.4c-0.2-0.4-0.3-0.7-0.5-1c-0.7-1.5-1.5-3-2.1-4.5c-0.6-1.7-1.1-3.5-1.3-5.3c-0.3-4.5,0.4-8.9,1.2-13.3
	C46.4,91.2,46.5,91.1,46.6,90.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M89.3,87.5c1.8-4.2,3.5-8.4,5.4-12.8c0.6,0.4,1.1,0.7,1.4,1.2c2.1,2.5,3.3,5.4,3.9,8.5c0.1,0.4-0.3,1.2-0.6,1.5
	C96.5,88.2,92.6,88.5,89.3,87.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M61.2,64.5c-0.8,5.3,0.8,10.2,1.2,15.4c-0.3-0.8-0.5-1.6-0.8-2.4c-0.6-1.9-1.3-3.8-2.1-5.8
	c0.4,2.3-1.8,3.1-2.4,4.8c-0.1,0-0.3,0-0.4,0.1C56.5,76,56,75.5,56.1,75c0.1-1.8,0.3-3.6,0.5-5.4c0.1-0.9,0.6-0.6,1.4,0
	C57.5,66.8,59.7,66,61.2,64.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M60.1,134.3c1.3-1.2,2.4-2.2,3.7-3.4c0.2,0.5,0.3,0.8,0.3,1.2c0.1,2,0.2,3.9,0.2,5.9c0,1.9,0.2,3.7,1,5.5
	c0.7,1.6,0.3,2.7-1.2,3.6C62.8,142.8,61.4,138.5,60.1,134.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M49.8,79c0,3.9,0,7.7,0,11.6c-1-0.3-1.9-0.6-2.7-0.8C46.7,87.1,48.4,79.8,49.8,79z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1}
              fill={this.state.color17 ? this.state.color17 : 'transparent'} onClick={() => {
          this.setState({ color17: color, opacity17: opacity });
        }} d="M179.4,111.5c0.3-0.3,0.9-0.7,1.3-1.2c0.6-0.7,1.3-1.5,1.5-2.4c0.6-3.6,1.8-7.2,1.5-11
	c-0.2-2.6-0.2-5.1-0.3-7.7c0-0.5,0-0.9,0-1.5c3.4,0,6.6-1.1,9.9-0.3c2.8,0.6,5.6,0.9,8.5,1.2c1.8,0.2,3.6,0,5.5,0
	c1,3.9,1.1,7.8,0.6,11.7c-0.3,2-0.5,4-0.5,6.1c0,4.6,0.1,9.1,0.2,13.7c0,1.6,0.2,3.1,0,4.7c-0.4,3.2-2,6-4.6,7.8
	c-2.3,1.6-5.1,1.8-7.8,0.8c-0.4-0.1-0.8-0.1-1.2-0.1c-1.5,0-3,0.3-4.5,0.1c-2.2-0.2-4.3-0.7-6.4-1c-1.5-0.3-3-0.5-4.5-0.9
	c-1.1-0.3-1.7-1-1.5-2.2c0.6-3.4,1.1-6.9,1.7-10.3c0.3-1.8,0.7-3.6,1-5.4C179.8,112.9,179.5,112.3,179.4,111.5z M205.6,88.8
	c-0.3,0.5-0.7,0.8-0.7,1c0.2,0.4,0.5,0.8,0.8,1.2c0.3-0.2,0.8-0.5,0.8-0.6C206.3,89.9,206,89.5,205.6,88.8z M205.8,110.8
	c0,0.8,0,1.4,0,2.1c0.3-0.1,0.8,0,0.9-0.2C207,112,206.9,111.4,205.8,110.8z M206.5,98.4c-0.2,0.6-0.5,1-0.5,1.3
	c0,0.2,0.4,0.3,0.6,0.5c0.2-0.3,0.4-0.5,0.5-0.8C207.1,99.1,206.8,98.9,206.5,98.4z M206.1,121.6c-0.2,0-0.4-0.1-0.7-0.1
	c-0.1,0.5-0.2,1-0.3,1.4c0.2,0,0.6,0.1,0.6,0C205.9,122.5,206,122,206.1,121.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M212.2,49.7c0.3,0.3,0.5,0.7,0.9,0.9c1.4,0.6,2.8,1.1,4.4,0.6c0.3-0.1,0.5,0,0.9,0c-0.1,2.2-0.8,4-2.6,5.2
	c-2.6,1.9-5.5,2-8.6,1.5c-3.1-0.5-5-2.5-6.5-5.1c-0.1-0.2-0.4-0.4-0.8-0.9c0.1,0.5,0.1,0.8,0.2,1c1.5,2.1,3.1,4.1,5.6,5.2
	c0.3,0.1,0.7,0.4,0.7,0.7c0.5,1.6,0.8,3.2,1.2,4.8c0.5,1.8,0.6,3.7,0.2,5.5c-0.5,2.5-1,4.9-1.5,7.4c-0.1,0.4-0.3,0.8-0.6,1.4
	c-0.8-1.3-1.5-2.3-2.1-3.5c-1.2-1.9-2.4-3.7-4.3-5.2c-1.7-1.3-3.1-2.9-4.7-4.5c-1.6-1.6-2.3-3.5-1.9-5.7c0.1-0.7,0.2-1.4,0.1-2
	c-0.3-2.1,0.9-3.5,2.1-4.9c0.7,0.3,1.4,0.7,2,1c0.1-0.1,0.2-0.2,0.3-0.3c-0.3-0.8-0.5-1.6-0.8-2.5c0.8-0.4,1.2-0.8,0.9-1.8
	c-0.1-0.4,0.3-1,0.7-1.4c0.4-0.5,1.2-0.8,0.7-1.4c-1,0.4-1.8,0.8-2.5,0.8c-0.4,0-1.2-0.7-1.3-1.2c-0.1-0.8,0.1-1.8,0.5-2.5
	c0.5-0.8,0.1-1-0.4-1.5c-0.9-0.9-1.7-1.9-2.3-3c-0.5-1,1-3.9,2.2-4.4c1.4-0.7,3.1-0.1,3.9,1.5c0.6,1.2,0.8,2.6,1.1,3.9
	c0.1,0.4,0.1,0.8,0.2,1.1c0.2,0.8,0.4,1.4,1.5,1.3c0.8,0,1.7,0.6,2.4,0.3c0.4-0.2,0.4-1.4,0.6-2.1c0.3-1.2,0.2-2.3-0.6-3
	c0.5-0.4,1-0.9,1.4-1.1c0.1-0.8,0.1-1.5,0.2-2.1c0.1-0.6,0.2-1.3,0.3-1.9c0.1,0,0.2,0,0.3,0c-0.3-0.4-0.6-0.7-0.9-1.1
	c-0.5-0.6-0.8-1.3,0.1-1.9c0.2-0.1,0.1-0.7,0.1-1c-0.1-0.3-0.3-0.6-0.5-1c2.4,0.3,3.4-1.2,4.4-3.1c0.4,0.6,0.7,1.1,1.1,1.7
	c0.5-0.7,0.9-1.1,1.2-1.6c0.4,0.2,0.9,0.3,1.3,0.5c0.2-0.3,0.4-0.6,0.7-0.8c0.3-0.1,0.7,0,1,0c-0.1,1.6,1.8,1.3,2.2,2.5
	c0.4,1.2,1.2,2.4,1.4,3.7c0.2,1.3,0,2.6-0.1,3.9c-0.1,1.3,0.5,2.3,1.3,3.2c0.6,0.7,1.3,1.5,2,2.2c1.3,1.6,1,3-0.9,3.7
	c-0.9,0.4-2,0.4-3.2,0.6c0.2,0.2,0.4,0.5,0.7,0.6c1.2,0.1,1.1,0.9,1.1,1.7c-0.1,1.2-0.1,2.4-0.3,3.6c-0.1,0.3-0.5,0.8-0.8,0.9
	C216,50.8,214.1,50.5,212.2,49.7c-0.4-0.7-0.8-1.5-1.1-2.2c-0.1,0-0.2,0.1-0.4,0.1c0,0.9-1.7,1.3-0.7,2.7c0.4-0.4,0.7-0.7,0.9-1
	C211.4,49.4,211.8,49.6,212.2,49.7z M213.2,30c-1.9,0.7-3.7,3.1-3.3,4.7c0.5-0.2,1.1-0.4,1.6-0.7c1.1-0.7,2.2-1.4,3.2-2.2
	c0.4-0.4,1-1.3,0.9-1.5c-0.4-0.6-1.1-1-1.9-0.5C213.5,29.9,213.3,30,213.2,30z M213.7,34.1c-0.2,0.1-0.4,0.1-0.5,0.2
	c-1.4,0.6-1.8,2.1-2.6,3.3c-0.5,0.9-0.6,1.4,0.2,2.1c0.2,0.2,0.3,0.3,0.5,0.5c1.2,1.3,2.9,1.6,4.5,1.8c0.2,0,0.6-0.3,0.6-0.5
	c0.1-1.7,0.1-3.4,0.1-5c0-0.5-0.7-1.1,0.1-1.5c-0.1-0.2-0.2-0.5-0.4-0.6c-0.4-0.2-0.8-0.3-1.2-0.3C214.6,33.9,214.1,34.1,213.7,34.1
	z M198.4,42.9c0.1-0.1,0.1-0.2,0.2-0.3c-0.7-0.5-1.9-0.7-1.5-1.9c0.1-0.3,0.8-0.5,1.4-0.8c-0.2-0.5-0.4-1.1-0.7-1.8
	c-1.5,1.3-1.9,2.7-0.7,4.2C197.4,42.6,198,42.7,198.4,42.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M210,131.6c-0.8,0.5-2.4-0.3-2.7-1.4c-0.1-0.6-0.2-1.4,0.1-1.8c1.3-2.2,1.2-4.7,1.1-7.1
	c-0.1-4.6-0.4-9.1-0.4-13.7c0-2.4,0.5-4.8,1-7.2c0.7-3.5,0.5-6.9-0.1-10.4c-0.1-0.8-0.3-1.6-0.5-2.6c2.9,0.3,5.7-0.8,8.5,0.2
	c0,0,0.1,0,0.1,0c2.2,0.4,2.3,0.4,2.1,2.7c-0.1,2.6-0.6,5.3-0.7,7.9c-0.1,1.9,0.1,3.8,0.3,5.7c0.2,2,0.6,4,0.8,6
	c0.4,2.9,0.7,5.8,1.1,8.7c0.1,0.4,0.1,0.9,0.1,1.3c0.1,2.3,0.1,4.6,0.3,6.9c0,0.8,0.4,1.7,0.4,2.5c0,0.5-0.3,1.3-0.7,1.5
	c-1.2,0.7-2.4,1.3-3.7,1.7c-1.3,0.3-2.8,0.3-4.2,0.5C211.3,133.5,211,133.4,210,131.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }} d="M175.6,71.5c1.3,3.1,2.5,6.1,3.8,9.1c0.9,2.3,1.8,4.6,2.8,6.8c0.5,1.2,1,2.4,0.2,3.8c-0.2,0.4-0.2,0.9-0.1,1.4
	c0.5,3.3,0.4,6.7-0.1,10c-0.3,1.8-0.8,3.6-1.2,5.3c-0.3,1.2-1.2,1.6-2.3,1.7c-1.1,0.1-2.3,0.4-3.4,0.5c-0.5,0-1-0.1-1.5-0.2
	c-0.7-0.1-1.5-0.1-2.2-0.1c0,0.2,0,0.3-0.1,0.5c0.3,0.1,0.5,0.2,0.8,0.3c0.8,0.2,1.7,0.2,2.5,0.5c0.7,0.2,1.2,0.3,1.7-0.3
	c0.4-0.5,1.1-0.7,1.5-0.1c0.5,0.6,0.3,1.2-0.4,1.6c-0.4,0.2-0.7,0.6-1.1,0.7c-0.9,0.2-0.9,0.8-0.8,1.9c1-0.9,1.9-1.6,2.7-2.3
	c0.1,0,0.2,0.1,0.3,0.1c-0.6,3.1-1.3,6.1-1.9,9.1c-0.6-0.3-1.2-0.4-1.6-0.7c-0.7-0.7-1.5-1.4-2-2.2c-2.6-4-4.3-8.5-5.1-13.2
	c-0.2-0.9,0.2-1.9,0.3-2.8c0.1-0.4,0.2-0.8,0.1-1.2c-0.5-4.1,0.1-8.1,0.9-12.1c0.3-1.4,0.2-2.9,0.3-4.4c0.4-3.4,0.8-6.8,2.6-9.8
	C173.1,73.8,173.8,72.2,175.6,71.5z M177.6,107.2c0.1,0,0.2-0.1,0.3-0.1c-0.3-0.4-0.5-1.1-0.8-1.2c-1.6-0.4-3.2-0.7-4.9-0.9
	c-0.2,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.2,0.5-0.3,0.8c0.2,0,0.5,0.1,0.7,0.1c0.9,0,1.7-0.2,2.6-0.1
	C175.6,106.4,176.6,106.9,177.6,107.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }} d="M184.5,67c-0.2,1.4-0.3,2.6-0.4,3.7c0,0.9-0.1,1.8,0.1,2.6c0.3,1.1,1,1.2,1.7,0.4c1-1.2,1.9-2.4,2.7-3.7
	c1.2-2,4-2.6,5.8-1.1c0.9,0.7,1.8,1.3,2.8,1.9c0.2,0.1,0.5,0,0.7,0c0-0.2,0-0.5-0.1-0.7c-1.7-1.4-3.3-3-5.7-3.2
	c-1.8-0.2-3.8,1-4.7,2.6c-0.7,1.2-1.5,2.2-2.4,3.6c-0.1-0.6-0.2-0.8-0.2-1.1c0.1-1.4,0.4-2.8,0.4-4.2c0-1.8,0.9-3.1,1.9-4.4
	c0.5-0.7,0.8-1.5,1.2-2.3c0.5-1,0.8-2.1,1.3-3c0.4-0.9,1.2-1.2,2.4-1c-0.1,1.5-0.3,2.9-0.4,4.4c-0.1,1.2,0,2.2,1.1,3.1
	c1.2,1,2.1,2.3,3.2,3.4c1.5,1.5,3.3,2.8,4.7,4.4c1,1.1,1.9,2.5,2.6,3.8c1.9,3.4,3,7.1,3.8,10.9c0,0.1,0,0.3,0,0.5c-3,0-6,0.1-8.9,0
	c-1.7-0.1-3.4-0.5-5.1-1c-1.9-0.6-3.8-0.2-5.7-0.1c-1.5,0-2.9,0.4-4.3,0.6c-0.9-2-1.8-3.9-2.6-5.8c-1.3-3-2.5-6-3.8-8.9
	c-0.4-0.9-0.3-1.4,0.5-1.8c1-0.5,2-1,3-1.4C181.6,68.1,183,67.6,184.5,67z M203.5,79.7c-0.3,0.5-0.7,0.9-0.6,1
	c0.1,0.3,0.5,0.6,0.8,0.9c0.1,0,0.6-0.4,0.6-0.5C204.2,80.6,203.9,80.3,203.5,79.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }} d="M210.7,70.8c0.8,0.9,1.4,1.7,2.1,2.5c0.4,0.5,0.9,1.2,1.7,0.6c0.7-0.5,1-1.2,0.6-2.2c-0.3-0.7,0-1.7,0.1-2.7
	c2.2,1.2,4.1,2.5,5,4.7c0.1,0.1,0,0.3,0,0.4c-0.1,1.2-0.3,2.4-0.3,3.6c-0.1,1.7-0.1,3.3-0.2,5c-0.1,1.3-0.3,2.6-0.5,3.7
	c-3.4-0.1-6.7-0.2-10.1-0.4c-0.3,0-0.7-0.4-0.8-0.6c-0.5-1.5-1.2-3.1-1.2-4.6c0-1.5,0.7-3.2,1.5-4.5c0.8-1.4,1.6-2.6,1.6-4.2
	C210.3,71.8,210.5,71.4,210.7,70.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity23 ? this.state.opacity23 : 1}
              fill={this.state.color23 ? this.state.color23 : 'transparent'} onClick={() => {
          this.setState({ color23: color, opacity23: opacity });
        }} d="M221,112c-0.2-1.7-0.4-3.5-0.5-5.2c0-0.2,0.3-0.5,0.4-0.8c0.2-0.3,0.5-0.7,0.7-1c-0.4-0.1-0.8-0.2-1.3-0.4
	c0-0.3,0-0.8,0-1.3c0.5-0.1,0.9-0.3,1.5-0.5c-0.3-0.3-0.4-0.5-0.5-0.5c-1.5-0.4-1.8-0.7-1.6-2.3c0.4-3.8,0.9-7.7,0.6-11.6
	c-0.2-3.1,0.3-6.2,0.4-9.3c0-1.1-0.1-2.3-0.2-3.4c0.1,0,0.2-0.1,0.3-0.1c0.2,0.2,0.5,0.5,0.6,0.8c0.7,2.7,1.5,5.4,2,8.1
	c0.6,3.2,1,6.4,1.4,9.6c0.2,2.4,0.4,4.8,0.3,7.2c-0.1,2.2-0.6,4.3-1.9,6.2c-0.7,1-0.9,2.2-1.3,3.3c-0.1,0.4-0.3,0.8-0.4,1.2
	C221.3,112,221.2,112,221,112z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }} d="M209.4,61.3c-0.7,0.3-1,0.4-1.5,0.7c-0.2-1-0.4-2-0.6-3.2c1.1,0.2,2.1,0.2,2.9,0.5c1.5,0.7,1.9,2.2,2.1,3.8
	c0.1,0.9,0.1,1.9,0.1,2.9c0.9-0.6,1.3-0.6,1.4,0.4c0.1,1.7,0.4,3.3,0.4,5c0,0.6-0.4,1.2-0.8,1.7c-0.9-1.7-3.1-2.5-2.5-4.8
	c-0.1,0-0.2,0-0.3,0c-0.9,2.6-1.7,5.3-2.6,7.9c0,0-0.1-0.1-0.1-0.1C208.4,71.3,208.9,66.5,209.4,61.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1}
              fill={this.state.color25 ? this.state.color25 : 'transparent'} onClick={() => {
          this.setState({ color25: color, opacity25: opacity });
        }} d="M205.9,130.9c1.4,1.3,2.5,2.3,3.7,3.4c-0.7,2.4-1.5,4.9-2.3,7.4c-0.5,1.6-1,3.2-1.5,4.8
	c-0.1,0.2-0.2,0.4-0.4,0.5c-0.1,0-0.3-0.1-0.5-0.3c-1-1-1.2-2.1-0.6-3.3c0.9-1.7,1-3.6,1-5.5c0-2,0.1-3.9,0.2-5.9
	C205.7,131.7,205.8,131.4,205.9,130.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1}
              fill={this.state.color26 ? this.state.color26 : 'transparent'} onClick={() => {
          this.setState({ color26: color, opacity26: opacity });
        }} d="M211.3,40.1c-0.2-0.2-0.3-0.3-0.5-0.5c-0.2-1.2,0.7-1.8,1.4-2.4c0.4-0.4,0.8-0.8,1.2-1.2
	c0.3-0.1,0.5-0.3,0.8-0.4c0.4-0.1,1.1-0.4,1.3-0.2c0.3,0.3,0.3,0.9,0.5,1.4c-0.8,0-1.4,0-2,0c0,0.7-0.1,1.5,0.2,2.1
	c0.2,0.4,1,0.5,1.7,0.8c0.1,1.3-0.1,1.6-1.6,1.4C213.2,40.9,212.3,40.5,211.3,40.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M213.2,30c0.1-0.1,0.3-0.1,0.4-0.2c0.6,0.2,1.2,0.3,1.8,0.5c0,0.1,0,0.3,0,0.4c-1.8,1-3.2,2.6-5.2,3.4
	c0-0.4,0.1-0.7,0.3-1C211.4,32.1,212.3,31,213.2,30z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity28 ? this.state.opacity28 : 1}
              fill={this.state.color28 ? this.state.color28 : 'transparent'} onClick={() => {
          this.setState({ color28: color, opacity28: opacity });
        }}
              d="M57.4,38.4c2-0.3,5.1,2,4.8,4.8c-3.2,1.5-5.4,1.4-5.7-0.2C59,42.1,59.2,40.9,57.4,38.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity29 ? this.state.opacity29 : 1}
              fill={this.state.color29 ? this.state.color29 : 'transparent'} onClick={() => {
          this.setState({ color29: color, opacity29: opacity });
        }} d="M57.8,40.4c-0.5,0.3-0.8,0.6-1,0.6c-0.2,0-0.5-0.4-0.5-0.6c0-0.2,0.3-0.6,0.5-0.6
	C57.1,39.8,57.3,40.1,57.8,40.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity30 ? this.state.opacity30 : 1}
              fill={this.state.color30 ? this.state.color30 : 'transparent'} onClick={() => {
          this.setState({ color30: color, opacity30: opacity });
        }} d="M73.9,107c-1.3-3.8-0.8-7.7-0.9-11.9c1.6,0.5,3.1,1,4.6,1.6c0.3,0.1,0.5,0.9,0.5,1.3c0.2,2.5,0.3,5,0.4,7.5
	c0.2,0,0.3,0,0.5,0c0-0.8,0-1.6,0-2.4c0-1.6-0.1-3.3-0.2-4.9c-0.1-0.8,0.2-1.3,0.9-1.6c1.4-0.6,2.8-1.1,4.4-1.7
	c0.1,2.9,0.2,5.7,0.2,8.4c0,0.3-0.4,0.7-0.7,0.8C80.5,105.6,77.3,106.8,73.9,107z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity31 ? this.state.opacity31 : 1}
              fill={this.state.color31 ? this.state.color31 : 'transparent'} onClick={() => {
          this.setState({ color31: color, opacity31: opacity });
        }} d="M71.5,91.8c4.4-1.1,8.7-2.1,13.1-0.9c-0.1,1.5-1.6,3.3-3,3.8c-0.2,0.1-0.4,0.1-0.6,0.2
	c-1.6,1.3-3.2,0.7-4.8,0.1c-1.1-0.4-2.2-0.9-3.3-1.3c-0.1,0-0.2-0.1-0.2,0c-1.4,0.6-1.2-0.4-1.2-1.2C71.5,92.3,71.5,92.1,71.5,91.8z
	 M77.1,91.8c-0.2,0.3-0.5,0.7-0.5,0.7c0.3,0.3,0.7,0.6,1,0.9c0.1,0.1,0.4-0.2,0.6-0.4C77.9,92.7,77.5,92.3,77.1,91.8z" />
        <path
          d="M77.1,91.8c0.5,0.5,0.8,0.9,1.2,1.2c-0.2,0.1-0.5,0.4-0.6,0.4c-0.4-0.2-0.7-0.5-1-0.9C76.6,92.5,76.9,92.1,77.1,91.8z" />
      </SvgIcon>
    )
  }
};