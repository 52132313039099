import { Component } from "react";
import React from 'react';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { Box } from "@material-ui/core";

class PositionViewer extends Component<any, any> {

  constructor(props) {
    super(props);
  }

  render() {
    const { others } = this.props;
    const {
      x,
      y
    } = others;
    const dy = 0;
    const dx = -200;
    return (
      <Draggable
        bounds={{
          top: 0,
          right: document.body.offsetWidth,
          left: 0,
          bottom: document.body.offsetHeight
        }}
        defaultPosition={{
          x: dx,
          y: dy
        }}
        onDrag={(e, data) => {

        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={200}
          height={30}
          style={{position: "fixed"}}
        >
          <span>{`${x} , ${y}`}</span>
        </Box>
      </Draggable>
    )
  }
}

const getVisibleDrawables = (others) => {
  return others;
};

const mapStateToProps = state => ({
  others: getVisibleDrawables(state.others)
});

export default connect(
  mapStateToProps
)(PositionViewer);