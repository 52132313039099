import React, { Component } from "react";
import { SvgIcon } from "@material-ui/core";

export default class Rectangle extends Component<any, any> {

  render() {
    const st0 = "#084B5E";
    const { disabled } = this.props;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-348.4 569.6 46.7 43.1"}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <g id="XMLID_385_">
          <path id="XMLID_60_" fill={st0} d="M-310.3,612.7h-29.5c-4.7,0-8.6-3.8-8.6-8.6v-25.9c0-4.7,3.8-8.6,8.6-8.6h29.5
		c4.7,0,8.6,3.8,8.6,8.6v25.9C-301.8,608.8-305.6,612.7-310.3,612.7z M-339.8,572.9c-2.9,0-5.3,2.4-5.3,5.3v25.9
		c0,2.9,2.4,5.3,5.3,5.3h29.5c2.9,0,5.3-2.4,5.3-5.3v-25.9c0-2.9-2.4-5.3-5.3-5.3H-339.8z" />
        </g>
        <g id="XMLID_384_">
          <path id="XMLID_144_" fill={st0} d="M-334.6,576.2h-1c-3.4,0-6.2,2.8-6.2,6.2v1.4h3.3v-1.4c0-1.6,1.3-2.9,2.9-2.9h1
		C-334.6,579.5-334.6,576.2-334.6,576.2z" />
        </g>
        <g id="XMLID_383_">
          <path id="XMLID_143_" fill={st0} d="M-308.3,598.9v1c0,3.4-2.8,6.2-6.2,6.2h-1.4v-3.3h1.4c1.6,0,2.9-1.3,2.9-2.9v-1
		C-311.6,598.9-308.3,598.9-308.3,598.9z" />
        </g>
      </SvgIcon>
    )
  }
};
