import No from "../No";
import SVG_7_1 from "../../images/SVG_7_1";
import SVG_7_3 from "../../images/SVG_7_3";
import SVG_7_2 from "../../images/SVG_7_2";
import SVG_7_4 from "../../images/SVG_7_4";
import SVG_5_1 from "../../images/SVG_5_1";
import SVG_5_2 from "../../images/SVG_5_2";
import SVG_5_3 from "../../images/SVG_5_3";
import SVG_5_4 from "../../images/SVG_5_4";
import SVG_5_5 from "../../images/SVG_5_5";
import SVG_6_1 from "../../images/SVG_6_1";
import SVG_6_2 from "../../images/SVG_6_2";
import SVG_14_1 from "../../images/SVG_14_1";
import SVG_14_2 from "../../images/SVG_14_2";
import SVG_14_3 from "../../images/SVG_14_3";
import SVG_18_1 from "../../images/SVG_18_1";
import SVG_18_2 from "../../images/SVG_18_2";
import SVG_29_1 from "../../images/SVG_29_1";
import SVG_29_2 from "../../images/SVG_29_2";
import SVG_32_1 from "../../images/SVG_32_1";
import SVG_9_1 from "../../images/SVG_9_1";
import SVG_10_1 from "../../images/SVG_10_1";
import SVG_11_1 from "../../images/SVG_11_1";
import SVG_19_1 from "../../images/SVG_19_1";
import SVG_21_1 from "../../images/SVG_21_1";
import SVG_21_2 from "../../images/SVG_21_2";
import SVG_21_3 from "../../images/SVG_21_3";
import SVG_10_2 from "../../images/SVG_10_2";
import SVG_28_1 from "../../images/SVG_28_1";
import SVG_33_1 from "../../images/SVG_33_1";
import SVG_33_2 from "../../images/SVG_33_2";
import SVG_34_1 from "../../images/SVG_34_1";
import SVG_70_1 from "../../images/SVG_70_1";
import SVG_28_2 from "../../images/SVG_28_2";
import SVG_57_1 from "../../images/SVG_57_1";
import SVG_57_2 from "../../images/SVG_57_2";

export const getPageMath1WorkingsNotesDemo = (page, edition) => {
  let EditionPageContent;
  let editable = true;
  switch (page) {
    case 1:
      editable = false;
      EditionPageContent = No;
      break;
    case 2:
      return {
        editable,
        SVGClickEditable: true,
        component: No,
        addProps: {
          layer2: {
            images: [{
              cfg: {
                src: `images/${edition}/No${page}/${page}_1.png`,
                width: 100,
                height: 100,
                draggable: true,
                defaultHidden: true
              },
              count: 1
            }, {
              cfg: {
                src: `images/${edition}/No${page}/${page}_2.png`,
                width: 76,
                height: 76,
                draggable: true,
                defaultHidden: true
              },
              count: 1
            }, {
              cfg: {
                src: `images/${edition}/No${page}/${page}_3.png`,
                width: 68,
                height: 168,
                draggable: true,
                defaultHidden: true
              },
              count: 1
            }, {
              cfg: {
                src: `images/${edition}/No${page}/${page}_4.png`,
                width: 70,
                height: 170,
                draggable: true,
                defaultHidden: true
              },
              count: 1
            }, {
              cfg: {
                src: `images/${edition}/No${page}/${page}_5.png`,
                width: 68,
                height: 108,
                draggable: true,
                defaultHidden: true
              },
              count: 1
            }, {
              cfg: {
                src: `images/${edition}/No${page}/${page}_6.png`,
                width: 75,
                height: 75,
                draggable: true,
                defaultHidden: true
              },
              count: 1
            }, {
              cfg: {
                src: `images/${edition}/No${page}/${page}_7.png`,
                width: 86,
                height: 86,
                draggable: true,
                defaultHidden: true
              },
              count: 1
            }]
          },
          layerSVG: {
            draggableSVGImagePositions: [{
              src: SVG_5_1,
              x: 0,
              y: 0,
              width: 278.6,
              height: 259.4
            }, {
              src: SVG_5_2,
              x: 0,
              y: 0,
              width: 150,
              height: 150
            }, {
              src: SVG_5_3,
              x: 0,
              y: 0,
              width: 283.4,
              height: 184.3
            }, {
              src: `images/${edition}/No${page}/IMG_${page}_4.png`,
              x: 0,
              y: 0,
              width: 212,
              height: 218
            }, {
              src: SVG_5_4,
              x: 0,
              y: 0,
              width: 358,
              height: 328
            }, {
              src: SVG_5_5,
              x: 0,
              y: 0,
              width: 194.2,
              height: 184.3
            }]
          }
        }
      }
    case 3:
      return {
        editable,
        SVGClickEditable: true,
        component: No,
        addProps: {
          layerSVG: {
            draggableSVGImagePositions: [{
              src: SVG_7_1,
              x: 0,
              y: 0,
              width: 150,
              height: 150
            }, {
              src: SVG_7_2,
              x: 0,
              y: 0,
              width: 150,
              height: 150
            }, {
              src: SVG_7_3,
              x: 0,
              y: 0,
              width: 150,
              height: 150
            }, {
              src: SVG_7_4,
              x: 0,
              y: 0,
              width: 150,
              height: 150
            }]
          }
        }
      }
    case 4:
      return {
        editable,
        SVGClickEditable: true,
        component: No,
        addProps: {
          layerSVG: {
            draggableSVGImagePositions: [{
              src: SVG_10_1,
              x: 0,
              y: 0,
              width: 502.6,
              height: 100.1
            }, {
              src: SVG_10_2,
              x: 0,
              y: 0,
              width: 495,
              height: 105
            }]
          }
        }
      }
    case 5:
      EditionPageContent = No;
      break;
    case 6:
      return {
        editable,
        component: No,
        addProps: {
          layer2: {
            images: [{
              cfg: {
                src: `images/${edition}/No${page}/${page}_1.png`,
                width: 40,
                height: 40,
                draggable: true
              },
              count: 10
            }, {
              cfg: {
                src: `images/${edition}/No${page}/${page}_2.png`,
                width: 40,
                height: 40,
                draggable: true
              },
              count: 2
            }, {
              cfg: {
                src: `images/${edition}/No${page}/${page}_3.png`,
                width: 40,
                height: 40,
                draggable: true
              },
              count: 2
            }, {
              cfg: {
                src: `images/${edition}/No${page}/${page}_4.png`,
                width: 40,
                height: 40,
                draggable: true
              },
              count: 3
            }, {
              cfg: {
                src: `images/${edition}/No${page}/${page}_5.png`,
                width: 40,
                height: 20,
                draggable: true
              },
              count: 3
            }, {
              cfg: {
                src: `images/${edition}/No${page}/${page}_6.png`,
                width: 40,
                height: 40,
                draggable: true
              },
              count: 1
            }]
          }
        }
      }
    case 7:
      return {
        editable,
        component: No,
        addProps: {
          layer2: {
            images: [{
              cfg: {
                src: `images/${edition}/No${page}/${page}_1.png`,
                width: 40,
                height: 40,
                draggable: true
              },
              count: 10
            }, {
              cfg: {
                src: `images/${edition}/No${page}/${page}_2.png`,
                width: 20,
                height: 20,
                draggable: true
              },
              count: 19
            }]
          }
        }
      }
    case 8:
      EditionPageContent = No;
      break;
    case 9:
      return {
        editable,
        SVGClickEditable: true,
        component: No,
        addProps: {
          layerSVG: {
            draggableSVGImagePositions: [{
              src: `images/${edition}/No${page}/IMG_${page}_1.png`,
              x: 0,
              y: 0,
              width: 913,
              height: 803
            }, {
              src: SVG_28_1,
              x: 0,
              y: 0,
              width: 198.5,
              height: 179.5
            }, {
              src: SVG_28_2,
              x: 0,
              y: 0,
              width: 190.3,
              height: 287.7
            }]
          }
        }
      }
    default:
      if (page > 9) {
        EditionPageContent = No;
      }
      break;
  }
  return {
    editable,
    component: EditionPageContent,
    addProps: {}
  };
}