import Drawable from "./Drawable";
import { Arrow } from "react-konva";
import React from "react";

export default class ArrowDrawable extends Drawable {
  x: number;
  y: number;
  drawableType = 'ArrowDrawable';
  constructor(startx, starty, color, weight, opacity) {
    super(startx, starty, color, weight, opacity);
    this.x = startx;
    this.y = starty;
    this.color = color;
    this.weight = weight;
  }
  registerMovement(x, y) {
    this.x = x;
    this.y = y;
  }
  render() {
    const points = [this.startx, this.starty, this.x, this.y];
    return <Arrow points={points} fill={this.color} stroke={this.color} strokeWidth={this.weight} opacity={this.opacity}/>;
  }
}