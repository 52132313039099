import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_29_2 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 510.2 159.4"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M357.3,104.7c-1.4,0.3-2.7,0.5-4,0.9c-1.8,0.6-3.6,1.2-5.3,2c-1.8,0.8-3.1,2.3-3.5,4.4
	c-1.8-0.3-2.3-1.6-2.7-2.9c-0.4-1.5,0.6-2.6,1.6-3.4c3.4-2.8,7.5-3.9,11.7-4.6c5.8-1,11.6-1.6,17.5-1.7c4.4-0.1,8.9,0.2,13.4,0.7
	c3.7,0.4,7.4,1.1,11,2.1c4.3,1.2,8.2,3.3,10.9,7c0.9,1.2,0.7,3.4-0.6,4.5c-0.2,0.1-0.4,0.1-0.7,0.2c-0.1-3-2-4.4-4.3-5.6
	c-2.8-1.4-5.8-2.1-8.9-2.5c-0.4-0.1-0.8,0-1.2,0c2.2,0.5,4.6,0.8,6.6,1.7c2.2,1,4.2,2.4,6,4c1.6,1.5,1,3.4-1,4.4
	c-4.8,2.4-10,3.5-15.2,4.2c-2.5,0.3-5.1,0.4-7.7,0.5c-0.3,0-0.5-0.1-0.8-0.1c0.1-1.9,0.9-2.4,3-1.9c1,0.2,1.5-0.1,1.8-1
	c0.4-0.9,0.8-1.8,1.2-2.7s0.1-1.4-0.9-1.6c-0.7-0.1-1.3-0.2-2.2-0.3c0.3-0.8,0.6-1.4,0.9-2c0.6-1.1,0.4-1.7-0.8-2.1
	c-1-0.3-2-0.5-3-0.5c-0.4,0-0.9,0.5-1.1,0.9c-0.6,1-1,2-1.6,3.2c-0.9-0.1-2-0.2-3-0.4c-0.9-0.2-0.5-0.8-0.1-1.2c1.3-1.3,2.6-2.6,4-4
	c1-1,2-2,2.9-3c0.4-0.5,0.2-1.3-0.5-1.5c-1.1-0.3-2.3-0.6-3.5-0.8c-0.4-0.1-0.9,0.2-1.2,0.5c-3,2.8-6,5.6-9,8.3
	c-1.2,1.1-2.4,2.2-3.6,3.3c-0.7,0.7-0.4,1.7,0.6,1.9c2.5,0.4,4.9,0.8,7.4,1.2c1,0.2,2,0.3,3.1,0.5c-0.2,0.8-0.4,1.6-0.8,2.4
	c-0.1,0.3-0.7,0.4-1.1,0.4c-3.8-0.5-7.6-0.9-11.4-1.6c-3.5-0.6-6.9-1.5-10.3-2.4c-1.4-0.4-2.7-1.2-4-2c-1.1-0.7-1.1-2.5-0.2-3.6
	c1.4-1.6,3.2-2.5,5.2-3.2c1.9-0.7,3.8-1.3,5.7-2C357.3,104.9,357.3,104.8,357.3,104.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M372.9,121.3c-0.2,0.6-0.3,0.9-0.5,1.3c-0.5,1.2-0.4,1.6,0.9,1.9c1.2,0.2,2.4,0.5,3.6,0.5c0.5,0,1.2-0.5,1.4-1
	c1.1-2.3,1-2.4,3.6-2.3c4.8,0,9.4-0.7,14-1.9c3.2-0.9,6.3-2.1,9.4-3.4c1.9-0.8,3.4-2.2,3.8-4.4c0.1-0.3,0.4-0.6,0.6-0.9
	c0.2,0.4,0.5,0.7,0.5,1.1c0,1.7-0.6,3.2-2.1,4.2c-2.4,1.5-4.9,3-7.5,4.4c0.5-0.1,1-0.2,1.9-0.4c-0.4,0.6-0.6,1.2-1,1.4
	c-1.7,1-3.5,1.8-5.3,2.7c-3.1,1.6-6.6,1.6-9.9,2.1c-2.8,0.4-5.7,0.7-8.5,0.7c-3.6-0.1-7.3-0.4-10.9-1c-3.2-0.6-6.4-1.8-9.6-2.5
	c-3.7-0.8-6.7-2.7-9.5-5.2c-0.5-0.4-0.8-1.1-1.2-1.6c-0.2-0.2-0.4-0.3-0.6-0.5c-1.6-1.2-3.3-2.5-4.9-3.7c-0.2-0.1-0.4-0.3-0.5-0.5
	c-0.1-0.4-0.1-0.8-0.1-1.2c0.3,0.1,0.8,0.1,1,0.3c1.4,1.1,2.7,2.5,4.3,3.3c1.9,1.1,3.9,1.9,5.9,2.6c3.1,0.9,6.3,1.7,9.4,2.4
	c3.4,0.7,6.9,1.1,10.4,1.7C372,121.2,372.4,121.2,372.9,121.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9: 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M189.5,111c-2.9-2-3.9-5.5-0.2-7.7c4-2.5,8.4-3.8,12.9-4.9c5.8-1.4,11.7-1.6,17.6-1.4c3,0.1,6,0.6,9.1,1
	c2.2,0.3,4.3,0.5,6.5,0.9c4.2,0.8,8.3,2,12,4c2.3,1.2,4.3,3.2,5.3,5.7c0.2,0.5,0,1.4-0.4,1.8c-1.4,1.4-3.1,2.3-5.2,2.9
	c0.2-0.5,0.2-0.8,0.3-1c1.7-2,0.9-3.1-0.3-4.3c-1.8-1.8-4.2-2.8-6.7-3.5c-1.4-0.4-2.8-0.6-3.8-0.9c2.5,1.1,5.3,2.2,8.1,3.5
	c1.4,0.6,2.5,1.9,2.3,3.3c-0.1,1.1-1.1,2.4-2,3.2c-2.1,1.7-4.8,2-7.3,2.5c-3.4,0.7-6.8,1.1-10.3,1.6c0.6-1.8,1.1-3.2,1.6-4.7
	c0.7-2.3-0.4-6.5-2.8-8.1c-0.8-0.5-2-0.8-3-0.8c-1.5-0.1-3.1,0.1-4.6,0.2c-0.7,0.1-1.3,0-1.3-0.7c0-0.4,0.7-1,1.1-1
	c1.2-0.1,2.4-0.1,3.6,0c0.9,0,1.9,0.2,2.8,0.2c0.4,0,1.1-0.4,1.1-0.7c0.2-1,0.2-2,0.2-3c0-0.2-0.4-0.5-0.7-0.5
	c-3.8-0.2-7.5-0.4-11.3-0.5c-0.3,0-0.8,0.6-0.9,1c-0.3,1.1-0.4,2.3-0.6,3.4c-0.4,2-0.8,3.9-1.1,5.9c-0.2,1.3,0.2,1.8,1.6,1.9
	c0.9,0.1,1.8,0.2,2.7,0.2c0.8-0.1,1.6-0.3,2.3-0.7c1.5-0.9,3.6-0.9,4.9,0.3c0.9,0.8,0.7,1.9,0.4,2.7c-0.2,0.6-0.8,1.3-1.4,1.5
	c-2.8,1-5.5,0.3-7.9-1.4c-0.9-0.7-1.7-0.8-2.4,0c-0.8,0.8-1.5,1.8-2,2.8c-0.2,0.5,0.2,1.4,0.5,1.9c0.3,0.5,0.9,0.8,1.3,1.1
	c0,0.1,0,0.2-0.1,0.3c-1.9-0.4-3.8-0.8-5.7-1.3c-1.5-0.4-3.1-0.8-4.6-1.3c-2.5-0.9-4.9-1.9-7.3-3c-0.9-0.4-1.8-0.9-2.4-1.7
	c-1.1-1.4-0.3-2.8,0.5-4c1.5-2.2,3.6-3.4,6.1-4.3c0.2-0.1,0.3-0.2,0.5-0.3C195.4,103.4,189.8,105.8,189.5,111z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M239.1,120c1-0.2,2.1-0.5,3.1-0.7c1-0.2,2.1-0.4,3.3-0.6c-1.3,1.8-3,2.4-4.6,3c-3.5,1.2-7,2.4-10.6,2.8
	c-5.2,0.5-10.5,0.6-15.7,0.2c-4.9-0.4-9.8-1.4-14.5-3.1c-3-1.1-6.1-2.1-8.4-4.4c-0.1-0.1-0.2-0.3-0.3-0.6c0.6,0.2,1.1,0.3,1.7,0.5
	c0-0.1,0.1-0.1,0.1-0.2c-0.6-0.4-1.3-0.7-1.9-1.2c-1.7-1.3-3.3-2.5-4.9-4c-0.5-0.5-0.6-1.5-0.9-2.2c0.1-0.1,0.2-0.1,0.3-0.2
	c0.2,0.1,0.5,0.3,0.6,0.4c1.3,2.3,3.7,3.1,5.7,4.3c3.7,2.2,7.7,3.4,11.8,4.4c3.2,0.7,6.4,1.4,9.6,1.7c2.7,0.3,5.5,0.1,8.2-0.1
	c3.5-0.3,7.1-0.7,10.6-1.3c3.9-0.7,7.7-1.7,11.5-2.7c3-0.8,6.1-1.7,8.4-4c0.2-0.2,0.6-0.2,0.8-0.2c0,0.3,0.2,0.8,0,1
	c-0.2,0.4-0.6,0.7-1,1c-2.6,2.2-5.6,3.4-8.8,4.3c-1.3,0.3-2.5,0.8-3.8,1.2c-0.2,0.1-0.4,0.2-0.7,0.4C239,119.7,239,119.9,239.1,120z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M157.6,105.5c1,0.3,2,0.6,3,1c1.8,0.8,3.6,1.6,5.3,2.7c0.9,0.6,1.5,1.8,2.1,2.8c0.5,0.9-0.5,2.1-1.8,2.9
	c-4.2,2.4-9,3.2-13.6,4.2c-1.2,0.3-2.5,0.3-3.8,0.5c0.5-1.7,1.1-3.2,1.5-4.7c0.5-2.2-1.3-5.1-3.4-5.9c-1-0.3-1.9-0.7-2.9-0.9
	c-0.7-0.2-0.6-0.5-0.5-1.1c0.5-2.8-0.5-4.6-3.1-5.6c-1-0.4-2.1-0.5-3.1-0.5c-2.6-0.1-4.9,0.8-7.1,2.1c-1.7,0.9-1.7,1.7-0.2,2.8
	c1.8,1.3,2.4,1.2,4.2,0.1c0.5-0.3,1.2-0.5,1.9-0.6c0.3,0,0.8,0.3,0.8,0.5c0.1,0.4,0,0.9-0.3,1.2c-0.7,0.6-1.5,1.2-2.3,1.6
	c-1,0.6-1.2,1-0.6,2c0.3,0.5,0.6,1,1,1.4c0.6,0.7,1.3,0.8,2.2,0.4c1.6-0.7,3.3-1.1,5-0.1c1.8,1.1,2.2,3.5,0.8,5.1
	c-0.9,0.9-1.9,1.5-3.2,1.6c-2.7,0.2-5.1-0.5-7.3-2c-0.4-0.3-0.6-0.7-0.8-1.1c-0.3-0.8-0.8-0.8-1.4-0.4c-1.1,0.7-2.2,1.4-3.4,1.8
	c-1,0.4-1.5,0.7-0.8,1.9c-2.9-0.1-5.7-0.4-8.3-1.4c-2.2-0.8-4.6-1.4-6.6-2.6c-2.9-1.6-3.5-3-1.7-5.7c0.5-0.8,1.6-1.3,2.4-1.9
	c0.4-0.3,1-0.5,1.5-0.8c-3.8,1-6.5,2.3-6,6.8c-1.3-0.8-2.4-1.3-3.3-2.1c-0.4-0.4-0.4-1.6-0.2-2.3c0.7-2.3,2.7-3.4,4.6-4.5
	c3.9-2.3,8.2-3.1,12.6-3.8c3.4-0.5,6.7-1,10.1-1.3c3.2-0.2,6.4-0.2,9.6,0c3.2,0.2,6.4,0.7,9.5,1.2c5.6,0.8,11.1,2.1,16.3,4.5
	c1.7,0.8,3.3,1.6,4.5,3.1c0.7,1,0.6,2.8-0.5,3.4c-0.2,0.1-0.9-0.2-1-0.3c-0.1-1.6-1.4-2.2-2.4-2.9c-2.6-1.7-5.5-2.5-8.6-3
	C158,105.5,157.8,105.4,157.6,105.5C157.6,105.4,157.6,105.4,157.6,105.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M108.5,118.4c0.5,0.1,0.8,0.2,1.1,0.3c0.3,0.1,0.6,0.2,1,0.4c-1.2-0.8-2.3-1.4-3.3-2.1c-1-0.8-1.9-1.6-2.8-2.5
	c0.1-0.2,0.2-0.3,0.3-0.5c0.4,0.2,0.9,0.3,1.3,0.5c3.7,1.9,7.4,3.9,11.6,4.8c3,0.6,6.1,1,9.1,1.6c0.7,0.1,1.4,0.5,2,0.9
	c3.3,2.7,7.2,3,11.2,2.6c2.6-0.3,4.8-1.2,6.7-2.9c0.5-0.4,1.3-0.6,2-0.7c3.4-0.7,6.9-1.3,10.4-1.9c3.8-0.7,7.3-2.1,10.2-4.8
	c0.7-0.7,1.2-1.5,1.8-2.3c0.2-0.2,0.4-0.5,0.7-0.7c0.8,1.4,0.1,3.2-0.9,3.9c-2.4,1.7-4.9,3.3-7.4,4.9c-0.2,0.1-0.4,0.3-0.5,0.5
	c0.5-0.2,1.1-0.4,1.6-0.5c0.1,0.1,0.1,0.1,0.2,0.2c-0.3,0.3-0.5,0.7-0.8,1c-2.8,2.9-6.5,3.9-10.3,4.7c-3.8,0.8-7.7,1.7-11.6,1.9
	c-4.2,0.3-8.5,0.1-12.7-0.4c-3.7-0.5-7.4-1.4-11-2.7c-3.4-1.2-6.8-2.7-9.4-5.3C108.8,119,108.7,118.8,108.5,118.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M469.2,107.5c1.4,0.4,2.8,0.6,4.2,1.1c1.5,0.5,2.9,1.2,4.3,2c1.6,1,2,2.4,1.3,4.1c-0.2,0.4-0.7,0.9-1.1,1.1
	c-3.2,1.6-6.6,2.5-10.1,3c-2.9,0.4-5.8,1-8.7,1.4c-1.2,0.2-2.4,0-4,0c1-2.9,1.9-5.7,3.1-8.3c1.2-2.6,2.6-5.1,3.9-7.7
	c0.5-0.9,0.1-1.4-0.9-1.5c-1.5-0.2-3-0.3-4.5-0.5c-0.8-0.1-1.4,0.1-2.1,0.8c-1.6,1.6-3.6,2.9-5.4,4.2c-1.7,1.2-3.6,2.2-5.4,3.3
	c0,0.1,0,0.3,0,0.4c0.9,0.4,1.8,0.7,2.7,1.1c1.7,0.8,2.5,0.7,3.9-0.4c0.6-0.5,1.2-0.8,2.1-1.4c-0.2,0.8-0.2,1.4-0.4,1.9
	c-0.9,2.4-1.8,4.9-2.9,7.3c-0.2,0.4-1.2,0.7-1.8,0.6c-5.1-0.7-10.2-1.6-15.3-2.4c-2.6-0.4-5.1-1.3-7.5-2.5c-1-0.5-1-0.9-0.3-1.6
	c2.3-2.2,4.7-4.1,7.6-5.4c0.4-0.2,0.9-0.5,1.2-0.8c-4,1.6-8.3,2.7-10.9,6.6c-1.9-0.9-2.6-2-2.5-3.6c0.1-1.2,0.8-1.8,1.6-2.3
	c4.4-3,9.2-5.3,14.4-6.5c6.9-1.6,13.8-2.4,20.8-2.1c2.9,0.1,5.8,0.7,8.7,1.2c2.6,0.5,5.3,1,7.8,1.9c3.7,1.4,7.6,2.2,10.7,4.8
	c1.8,1.5,2.7,3.7,0,5.4c-0.9,0.6-1.8,1.1-2.8,1.7c-0.3-1-0.3-2-0.9-2.5c-1.4-1.2-3-2.4-4.6-3.1c-1.8-0.8-3.7-0.9-5.6-1.4
	c-0.2,0-0.4,0-0.6,0C469.3,107.4,469.2,107.4,469.2,107.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M448.1,121.6c-0.2,0.5-0.3,0.7-0.4,1c-0.2,0.7,0,1.4,0.8,1.4c1.6,0.1,3.2,0.1,4.8,0.1c0.2,0,0.6-0.5,0.7-0.8
	c0.6-1.8,0.8-1.8,2.8-1.8c2.5-0.1,5-0.4,7.5-0.8c2.6-0.4,5.2-0.9,7.8-1.5c2.3-0.6,4.5-1.3,6.7-2.2c2.5-1,5-2.3,7.4-3.5
	c0.5,1.7,0.3,1.6-1.5,3.2c-0.9,0.8-2.1,1.3-3.2,1.8c-1.8,0.8-3.7,1.5-5.6,2.4c0.6-0.1,1.2-0.3,1.8-0.4c0.3,0,0.7,0.2,0.8,0.4
	c0.1,0.1,0,0.6-0.2,0.8c-2.1,1.9-4.8,2.8-7.5,3.4c-4.3,0.9-8.6,1.6-13,2.2c-4.4,0.6-8.8,0.4-13.1-0.5c-4.8-1-9.6-1.7-14.1-3.6
	c-2-0.9-4.3-1.9-5.6-3.5c-1.4-1.7-3.3-2.4-4.8-3.8c-0.8-0.8-1.4-1.7-2.1-2.6c-0.1-0.1,0-0.5,0.1-0.7c0.2,0,0.5,0,0.6,0.1
	c1.6,2.5,4.3,3.4,6.8,4.5c3.4,1.5,6.9,2.4,10.5,2.9c3.4,0.5,6.7,1,10.1,1.5C446.8,121.5,447.4,121.5,448.1,121.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M287.2,118.7c-3.4,0.1-6.5-0.6-9.6-1.7c-2.2-0.8-4.3-1.8-6.5-2.7c-1.2-0.5-1.5-1.5-1.3-2.8
	c0.4-2,1.6-3.4,3.1-4.5c1.8-1.4,3.8-2.4,5.6-3.5c-0.8-0.3-3.9,0.8-6.5,2.7c-2.1,1.5-4,3.2-4,6.3c-0.6-0.4-1.1-0.7-1.5-1
	c-2.1-1.9-1.8-2.3-0.4-4.5c2.4-3.7,6.3-5.1,10-6.7c4.7-2,9.7-2.9,14.9-2.9c3.3,0,6.5-0.3,9.8,0c6.3,0.5,12.3,2.2,18.2,4.6
	c2.3,0.9,4.6,1.7,6.5,3.4c0.8,0.7,1.6,1.5,2.2,2.3c1.4,1.8,0.6,4.8-1.3,5.9c-0.4,0.2-0.8,0.4-1.4,0.7c0.5-2,0.6-3.7-0.8-5.3
	c-1.4-1.5-3.1-2.4-5.1-2.9c1.3,1.2,2.6,2.3,3.7,3.5c2.6,2.9,0.3,6-2.3,6.8c-2.1,0.6-4.2,1.3-6.3,1.7c-3.5,0.6-7.1,0.9-10.6,1.4
	c0-0.1-0.1-0.3-0.1-0.4c0.4-0.6,0.8-1.2,1.2-1.9c1.2-2.3,1.5-4.6-0.2-6.9c-2.3-3.1-5.2-4.6-9.1-4c-0.7,0.1-1.3,0.4-2.2,0.6
	c0.2-0.4,0.2-0.8,0.4-1c0.9-1.1,1.9-2.1,3.3-2.5c1.2-0.3,1.9,0.4,2.3,1.2c0.6,1.2,1.6,1.5,2.6,1.4c1.2-0.1,2.2-0.9,2.7-2.1
	c0.5-1.2-0.2-2.2-1.1-2.9c-1.5-1.3-3.4-1.5-5.3-1.6c-2.4-0.2-4.7,0.3-6.6,1.8c-1.1,0.9-2.2,1.9-3,3.1c-1.9,2.7-3.3,5.6-3.1,9
	c0.1,1.5,0.7,2.9,1.2,4.3C286.7,117.9,287,118.2,287.2,118.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M328.9,111.2c1.1,0.8,1.1,1.8,0.3,3.1c-1.3,2.1-3.2,3.6-5.4,4.7c-0.6,0.3-1.3,0.6-1.9,1.1c0.2,0,0.5,0,0.7,0
	c0,0.1,0.1,0.1,0.1,0.2c-0.9,0.6-1.7,1.4-2.7,1.8c-4.5,2-9.3,3.3-14.2,4c-2.8,0.4-5.6,0.2-8.5,0.1c-3.2-0.2-6.4-0.3-9.6-0.9
	c-2.6-0.5-5.1-1.5-7.6-2.4c-2.7-1-5.4-1.9-7.6-3.7c-0.3-0.2-0.6-0.5-1.1-1c0.8,0.1,1.2,0.1,1.7,0.2c-3.4-1.3-6.3-3.2-8.1-6.5
	c0.9,0.6,1.9,1.3,2.9,1.9c2.2,1.2,4.3,2.4,6.6,3.3c2.4,1,4.7,1.7,7.2,2.1c2,0.4,4,0.6,6,0.9c0.8,0.1,1.6,0.5,2.4,0.7
	c2.1,0.5,4.2,1,6.3,1.4c1.6,0.3,3.1-0.3,4.5-1.1c0.9-0.5,2.2-0.7,3.3-0.7c5.6-0.3,11.2-0.8,16.5-2.7c2.4-0.8,4.8-1.6,6.7-3.5
	c0.4-0.4,0.6-1.1,0.9-1.6C328.5,112,328.7,111.6,328.9,111.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M26.3,44.1c-1.6-1.1-3.1-2-4.4-3c-1.5-1.1-1.3-1.8,0.3-2.8c3-1.9,6.3-3.1,9.8-3.5c1.1-0.1,2.2-0.7,3.2-0.7
	c3.9,0,7.8-0.8,11.7-0.8c3.3,0,6.5,0.1,9.8,0.3c3.2,0.2,6.4,0.5,9.7,0.8c4.4,0.4,8.6,1.3,12.8,2.6c1.6,0.5,3.3,0.6,4.6,1.7
	c0.8,0.6,1.6,1.3,2.6,2c-1.7,2.9-4.6,4-7.1,6c0.4,0,0.7,0,0.9-0.1c0.3,0,0.5-0.1,0.9-0.3c0.2,2.1,0.7,4.1,0.7,6.1
	c0,3.3-0.7,6.5-1.8,9.6c-0.7,2-1.8,3.8-3.2,5.4c-3.4,3.9-7.7,6.3-12.6,7.6c-2.7,0.7-5.6,0.8-8.4,1c-5.7,0.4-11.2-0.4-16.7-1.8
	c-4.1-1-8.1-2.7-10.8-6.2c-1.8-2.3-3.4-4.8-3.4-7.9c0-3.6,0.1-7.1,0-10.7c0-1.6-0.3-3.1-0.3-4.7c0-0.2,0.3-0.6,0.6-0.7
	C25.4,43.8,25.7,43.9,26.3,44.1z M68.7,70.7c0.6-0.3,1.3-0.4,1.8-0.8c0.9-0.7,1.8-1.5,2.7-2.3c1.1-0.9,1.4-2,0.8-2.6
	c-0.9-1.1-2.5-1.2-3.5-0.2c-0.5,0.5-1,0.9-1.5,1.4c-0.5,0.5-0.9,0.5-1.3,0c-0.2-0.2-0.4-0.3-0.7-0.5c-1.8-1-1.9-1.2-0.5-2.9
	c0.3-0.3,0.6-0.6,1-0.8c2.3-1.7,4.3-3.6,5.7-6.1c1.3-2.2,0.8-4.1-1.3-5.6c-1.6-1.2-3.3-1.2-5.2-1c-3,0.3-5.1,1.9-5.8,4.8
	c-0.3,1.1,0.1,1.7,1.1,1.7c0.9,0,1.9-0.1,2.8-0.2c0.2,0,0.6-0.5,0.6-0.6c-0.3-1.4,0.9-1.6,1.7-1.9c0.5-0.2,1.3,0.3,1.8,0.7
	c0.2,0.2,0.2,1,0,1.4c-0.5,0.7-1.1,1.5-1.9,2c-2.5,1.7-5.2,3.3-6.1,6.5c-0.5,1.5-1,3-1.3,4.6c-0.3,1.5,0.3,2.2,1.8,2.5
	c1.2,0.2,1.6-0.1,2.1-1.7c0.1-0.3,0.4-0.8,0.5-0.8c0.3,0,0.6,0.3,0.9,0.5C65.9,69.4,66.9,70.7,68.7,70.7z M28.7,37.9
	c-0.7,0.2-1.3,0.2-1.7,0.4c-0.5,0.3-1.2,0.8-1.2,1.3c0,0.4,0.5,1.2,1,1.4c1.9,0.9,3.7,1.8,5.7,2.3c2.6,0.6,5.3,0.9,7.9,1.3
	c2.7,0.4,5.4,0.8,8.2,1.1c1.6,0.2,3.2,0.2,4.9,0.3c1.8,0.1,3.6,0.4,5.4,0.4c3.4-0.1,6.9-0.1,10.3-0.5c3.1-0.4,6.2-1.2,9.3-1.9
	c0.5-0.1,1-0.4,1.5-0.7c1.1-0.7,1.3-1.7,0.6-2.9c-0.1,1.7-1.2,2-2.4,2.3c-1.8,0.4-3.6,1-5.4,1.3c-2.5,0.5-5,1-7.5,1.1
	c-3.4,0.1-6.7,0-10.1-0.2c-3.1-0.1-6.3-0.2-9.4-0.5c-2.9-0.3-5.8-0.9-8.8-1.3c-3.5-0.5-6.7-1.8-10.1-3.3
	C27.5,39.2,28,38.6,28.7,37.9z M42.4,57.9c0.6-0.7,1.1-1.3,1.6-1.9c1.5-1.8,1.5-4.7-0.1-6.2c-2-1.8-4.4-2-7-1.9
	c-1.8,0.1-3.3,0.6-4.3,2.3c-0.7,1-1,2-0.5,3.1c0.6,1.1,1.3,2.2,2.2,3.2c0.5,0.6,0.3,1-0.1,1.4c-0.9,1-1.7,2-2.5,3.1
	c-2.2,3.1-1.6,7,2.1,8.8c2.2,1.1,4.5,1.7,6.9,0.9c3.2-1,4.8-3.4,4.5-7C45,61.5,43.7,59.7,42.4,57.9z M57.3,61.2
	c-0.1-1.1-0.1-2-0.4-2.8c-0.1-0.4-0.8-0.9-1.2-1c-1.7-0.1-3.4,0.1-5,0.1c-2.7,0-2.7-0.1-2.8,2.7c0,0.9,0.3,1.2,1.2,1.2
	c2,0,4.1,0,6.1,0C55.8,61.4,56.5,61.3,57.3,61.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M83,52.7c-0.1-1.3-0.2-2.3-0.3-3.2c0.6-0.5,1-1,1.5-1.1c1.2-0.3,2.6-0.8,3.7-0.6c3.9,0.8,7.1,6.9,4,10.9
	c-0.7,0.9-1.8,1.5-2.9,2c-1.3,0.6-2.8,1.1-4.2,1.5c-1.2,0.3-2,0.7-2,2.2c0,1.1-1.6,1.9-2.6,1.2c-0.2-0.1-0.2-0.6-0.1-0.8
	c0.5-1.2,1-2.4,1.6-3.6c0.1-0.2,0.4-0.3,0.7-0.4c1.2-0.3,2.5-0.6,3.7-0.9c2-0.6,3.2-2,3.6-4c0.3-1.6,0.1-3.2-1.3-4.4
	c-1.4-1.2-2.2-1.3-3.9-0.1C83.9,51.8,83.6,52.2,83,52.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M38.1,76.8c0.2-0.6,0.4-1.1,0.6-1.7c1.4,0.2,2.8,0.5,4.3,0.7c3,0.4,5.9,0.9,8.9,1.1c4,0.2,8,0.2,12-0.7
	c0.5-0.1,1,0.3,1.6,0.4c-0.4,0.5-0.6,1.2-1.1,1.5c-0.6,0.4-1.4,0.5-2.1,0.5c-2.7,0.2-5.5,0.4-8.2,0.5c-2.4,0.1-4.9,0.2-7.3,0
	c-2.4-0.2-4.8-0.8-7.2-1.4C39.2,77.7,38.7,77.2,38.1,76.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M108.8,44.3c-1.4-0.4-2.7-0.8-4.1-1.4c-1.5-0.6-3-1.1-3.8-2.6c-0.7-1.3-0.5-2.2,0.8-3c2.4-1.5,5.2-2.1,7.9-2.8
	c4.5-1.1,9.1-1.7,13.7-1.8c4.5-0.1,9-0.1,13.4,0c3.8,0.1,7.6,0.3,11.4,0.6c2,0.2,3.9,0.6,5.9,1c2.8,0.7,5.7,1.5,8.4,2.3
	c0.7,0.2,1.4,0.8,1.7,1.4c0.7,1.3,0.4,2-0.7,2.9c-0.8,0.7-1.7,1.3-2.6,1.8c-0.9,0.5-1.9,0.8-2.9,1.4c1.3-0.2,2.6-0.4,4-0.6
	c-0.1,0.5-0.1,0.9-0.3,1.3c-1,2-1.1,4.1-1.2,6.3c0,0.5,0.4,1.1,0.6,1.7c0.7,2.3,1.1,4.6,0.1,7c-1.1,2.6-2,5.3-4.1,7.1
	c-2.1,1.8-4.2,3.7-6.6,5c-3.4,1.8-7,3-10.9,3.5c-5.4,0.7-10.6-0.1-15.9-0.5c-4.9-0.4-9.3-2.2-13.6-4.2c-2.4-1.1-4.4-2.7-5.9-4.9
	c-1.8-2.6-2.2-5.6-1.5-8.8c0.4-2.1,0.9-4.1,1.5-6.1c0.6-2.1,0.7-4.2,0.1-6.3c-0.2-0.7,0-0.7,0.6-0.6c1.4,0.2,2.7,0.4,4.1,0.6
	C108.8,44.4,108.8,44.4,108.8,44.3z M114.6,63.5c0.7,5.2,5.3,7.1,9.7,5.4c2.6-1,3.8-3.3,4.5-5.9c0.8-2.8,0.6-5.7-0.1-8.5
	c-0.3-1.3-1.2-2.9-2.3-3.6c-2.5-1.7-5.2-0.8-7.3,1.1c-1.5,1.4-2.5,3.2-3.7,4.7c0.3-2.1,0.7-4.3,1-6.5c-1.5-0.6-2.9-1.2-4.4-1.8
	c-0.7-0.3-1.1,0-1.5,0.7c-0.4,0.9-0.9,1.8-1.5,2.4c-1.4,1.4-3,2.7-4.5,4c-0.2,0.2-0.3,0.3-0.7,0.7c1.1,0.7,2,1.8,3.1,2
	c0.9,0.2,1.9-0.8,2.9-1.2c-0.1,1.5-0.2,3.1-0.4,4.6c-0.2,1.4-0.6,2.8-0.8,4.1c-0.1,0.7-0.5,1.6,0.6,1.9c1.1,0.3,2.3,0.4,3.4,0.6
	c1,0.1,1.3-0.4,1.4-1.3C114.3,65.8,114.5,64.7,114.6,63.5z M150.9,52.3c0.5-0.3,0.7-0.5,0.9-0.5c0.7-0.1,1.4-0.2,2.1-0.2
	c0.9,0,1.9-0.1,2.1-1c0.2-1,0-2.2-0.1-3.3c0-0.1-0.6-0.2-0.9-0.2c-2.1,0-4.1,0-6.2,0c-2.2-0.1-3.1,0.2-3.3,2.8
	c-0.1,1.4-0.3,2.8-0.5,4.2c-0.1,1.1-0.1,2.1-0.1,3.2c0,0.3,0.4,0.7,0.6,0.8c0.9,0.2,1.8,0.3,2.7,0.4c0.7,0,1.2-0.2,1.2-1.1
	c0-0.3,0.6-1,0.8-1c0.9,0.3,1.9,0.5,2.5,1.1c1.1,1.1,0.8,3.7-0.3,4.6c-1.7,1.4-4,1.4-5.4,0c-1.1-1.2-2.1-1.1-3,0.3
	c-0.3,0.5-0.6,1.1-0.9,1.7c-0.7,1.5-0.4,2.4,1.1,2.9c1.5,0.5,3,0.8,4.5,1.1c0.7,0.1,1.5,0.2,2.2,0.1c3.5-0.5,5.6-2.9,6.9-5.9
	c1.5-3.6,0.5-7.9-4.1-9.2C153.1,52.8,152.2,52.6,150.9,52.3z M105.1,37.7c-1.8,0.6-2.1,1.3-0.7,2.8c0.5,0.6,1.4,0.9,2.2,1.2
	c1,0.3,2,0.5,3,0.6c2.6,0.4,5.1,0.7,7.7,1c2.5,0.2,5.1,0.4,7.6,0.5c2.4,0.1,4.9,0.3,7.3,0.3c3.8-0.1,7.6-0.3,11.4-0.4
	c2.1-0.1,4.3-0.2,6.4-0.5c1.9-0.3,3.8-0.7,5.6-1.2c1.2-0.3,2.4-0.7,3.6-1.3c1.2-0.5,1.6-1.4,1.2-2.1c-0.5-0.9-2.1-1.8-3.2-1.8
	c0.3,0.2,0.7,0.3,1,0.5c1.4,0.9,1.4,1.9,0,2.7c-0.7,0.4-1.5,0.7-2.4,0.8c-6.4,0.6-12.8,1.2-19.2,1.7c-2.1,0.2-4.1,0.2-6.2,0.1
	c-3.9-0.1-7.7-0.3-11.6-0.5c-1.9-0.1-3.9-0.4-5.8-0.7c-2.2-0.3-4.4-0.7-6.6-1.1c-0.7-0.1-1.5-0.3-1.8-0.8
	C104.6,39,105,38.2,105.1,37.7z M131.7,61.3c2.3,0,4.5,0,6.7,0c0.9,0,1.9,0,2.8-0.2c0.4-0.1,1.1-0.8,1-1c-0.2-1.1,0.3-2.4-0.9-3.1
	c-0.1-0.1-0.3-0.1-0.5-0.1c-2.4,0-4.7,0-7.1-0.1c-0.6,0-1.2,0-1.8,0C131.9,58.3,131.8,59.7,131.7,61.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M145,76.3c-0.3,0.4-0.5,0.9-0.9,1.2c-2.2,1.7-4.8,1.9-7.4,2.2c-1.8,0.2-3.6,0.4-5.4,0.4
	c-3.6-0.2-7.1-0.4-10.5-1.7c-1-0.4-2.1-0.7-3.2-1.1c-1.4-0.5-1.7-0.9-1.3-2.5c9.4,2.3,18.9,3.1,28.5,1.1
	C144.8,76.1,144.9,76.2,145,76.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M162.1,48.5c0.1-1.9,1.1-2.9,3.1-3c1.5,0,3,0.1,4.5,0.5c0.8,0.2,1.5,1.1,1.9,1.8c1.1,2.3,1.3,4.8-0.3,6.8
	c-1,1.2-2.6,2-4,2.8c-1.5,0.9-2.4,2.4-2,4.1c0.1,0.4,0,0.9-0.1,1.4c-0.8-0.1-1.6-0.1-2.4-0.3c-0.3-0.1-0.5-0.6-0.6-0.9
	c0.4-1.3,1-2.4,1.1-3.6c0.2-1.5,1.2-1.9,2.3-2.4c1.1-0.6,2.1-1.3,3-2.1c1.5-1.3,0.9-3.7-0.3-5.1c-1.1-1.4-3-1.8-4.6-0.9
	C163.1,48,162.6,48.3,162.1,48.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M313.5,44.3c1.2-0.2,2.4-0.4,3.7-0.6c0.3-0.1,0.6-0.3,0.9-0.3c0.5,0.1,1.1,0.3,0.6,1c-2.1,3.1-1.6,6.2-0.4,9.5
	c0.5,1.4,0.4,2.9,0.5,4.4c0.1,2.2-0.4,4.3-1.3,6.3c-1.2,2.8-3.2,5.1-5.4,7.2c-1.6,1.5-3.3,3.1-5.4,3.7c-2.9,0.8-5.9,1.4-8.9,1.9
	c-2,0.4-4,0.6-6,0.7c-2.5,0.1-4.9,0-7.4-0.4c-4.1-0.6-8.2-1.2-12.3-2.2c-2.1-0.6-3.9-2.1-5.9-3.2c-2.8-1.6-5-3.8-6.4-6.6
	c-1-1.9-1.2-4.1-1-6.3c0.3-3.3,1.5-6.4,2.8-9.4c0.8-1.7,0.6-3.4-0.1-5.1c-0.1-0.3-0.2-0.7-0.1-0.8c0.2-0.2,0.6-0.2,0.8-0.1
	c2,0.3,4.1,0.6,6.1,0.8c0.4,0,0.8,0,1.2-0.3c-0.7-0.1-1.5-0.2-2.2-0.3c-2.7-0.2-5.3-0.8-7.7-2.2c-0.2-0.1-0.5-0.3-0.7-0.5
	c-1.6-1.6-1.6-2.4,0.1-4.2c2.7-3,6.3-4.1,10.1-4.5c6.2-0.6,12.4-1.1,18.7-1.4c4.6-0.3,9.3-0.2,13.9,0.6c4.3,0.7,8.6,1.4,12.9,2.3
	c2.1,0.4,4.2,1.1,6.1,2.5c1.7,1.3,1.1,3.4-0.3,4.2c-1.9,1-3.9,1.7-5.9,2.5c-0.4,0.2-0.8,0.3-1.2,0.4C313.5,44,313.5,44.2,313.5,44.3
	z M304.1,51.3c2-0.1,4-0.2,5.9-0.3c0.3,0,0.7,0,0.8-0.2c0.7-1,0.7-2.2,0.3-3.3c-0.3-0.9-1.1-1-2-1c-2.9,0-5.8,0-8.7,0
	c-2,0-2.3,0.3-2.4,2.3c-0.1,1.8-0.2,3.6-0.3,5.4c-0.1,1.4-0.4,2.8-0.4,4.1c0,0.3,0.4,0.9,0.7,1c0.9,0.2,1.8,0.2,2.7,0.2
	c0.4,0,0.9-0.4,1-0.7c0.6-1.9,1.3-2.6,3-2.4c1.3,0.1,2.9,1.8,2.8,3.1c-0.1,1.3-0.6,2.5-1,3.7c-0.3,1-1.1,1.6-2.1,1.9
	c-2,0.8-3.9,0.4-5.7-0.6c-0.8-0.4-1.4-0.4-2,0.2c-0.6,0.7-1.2,1.4-1.7,2.1c-0.5,0.6-0.2,1.8,0.4,2.2c2.2,1.3,4.7,1.8,7.2,1.6
	c4.6-0.4,8.1-2.6,9.9-7c1-2.6,0.8-5-0.7-7.5c-1.5-2.5-3.8-3.4-6.5-3.5C304.4,52.8,303.9,52.4,304.1,51.3z M274.6,48
	c-2.8,0.2-5.2,1-7.4,3c-3.6,3.4-4.4,7.7-4.1,12.2c0.2,3,2,5.4,4.9,6.8c1.9,1,5.1,0.9,6.8-0.5c1.4-1.2,2.9-2.5,3.1-4.5
	c0.1-1,0.4-1.9,0.5-2.9c0.5-4.2-2.5-7.1-6.6-6.5c-0.3,0-1.1-0.4-1.1-0.7c-0.1-1,1.6-2.6,2.6-2.4c0.8,0.1,1.7,0.2,2.3,0.7
	c0.9,0.6,1.6,0.5,2.4,0c0.9-0.6,1.4-1.4,1.3-2.5c0-0.2-0.1-0.5-0.3-0.6C277.9,48.8,276.6,47.8,274.6,48z M262.6,36.7
	c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3,0.7-1,1.4-0.9,2.1c0,0.5,0.9,1,1.4,1.4c0.4,0.3,0.9,0.4,1.4,0.4c6.7,1.3,13.5,2,20.4,1.9
	c4.3-0.1,8.6,0,12.9-0.1c4.1-0.2,8.2-0.4,12.3-0.9c2.1-0.2,4.2-0.8,6.3-1.4c0.5-0.1,1.1-0.7,1.2-1.2c0.1-0.4-0.4-1.1-0.7-1.5
	c-0.2-0.3-0.6-0.4-1.1-0.6c1,1.4,1,2.1-0.2,2.4c-1.1,0.3-2.1,0.7-3.2,0.8c-4.3,0.4-8.7,0.8-13.1,1c-5.6,0.2-11.1,0.1-16.7,0.1
	c-2.6,0-5.2-0.1-7.7-0.3c-3.3-0.3-6.5-0.8-9.8-1.3C263.2,39.1,262.3,38.8,262.6,36.7z M288.1,56.9C288.1,56.9,288.1,56.9,288.1,56.9
	c-1.4,0-2.8,0.1-4.2,0.2c-1.8,0.2-1.8,0.2-1.7,2c0,0.4,0,0.8,0,1.2c0,1.1,0.5,1.5,1.6,1.4c0.8-0.1,1.6-0.2,2.4-0.2
	c1.7,0,3.3,0.1,5,0c0.5,0,1.3-0.3,1.4-0.6c0.3-1,0.3-2,0.3-3c0-0.7-0.4-1.1-1.3-1.1C290.5,57,289.3,56.9,288.1,56.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M273.3,78.6c0-0.8,0.7-1.4,1.4-1.2c4.4,1.6,8.9,1.5,13.4,1.5c2.8,0,5.6,0,8.3-0.1c1.5-0.1,2.9-0.4,4.4-0.6
	c0.4-0.1,0.9-0.2,1.3-0.1c0.5,0.2,1,0.5,1.5,0.8c-0.4,0.4-0.6,1.1-1.1,1.2c-1.1,0.3-2.2,0.5-3.4,0.7c-2.3,0.3-4.7,0.6-7,0.6
	c-4.4-0.1-8.9-0.2-13.3-0.6c-1.7-0.1-3.3-0.9-4.9-1.4c-0.3-0.1-0.7-0.3-1-0.4C273.1,78.9,273.2,78.7,273.3,78.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M320.3,56.1c0.8-0.4,1.6-0.8,2.3-1.2c1.6-0.9,2.1-3.5,1.1-5c-0.9-1.3-2.1-1.2-3.4-1c-0.5,0.1-0.9,0.2-1.5,0.4
	c0-0.5,0.1-0.9,0.1-1.3c0.1-2.2,0.9-2.9,3-2.5c1.1,0.2,2.3,0.5,3.3,1c2.1,1,2.2,3.1,2.1,5c-0.1,2-1.2,3.6-2.7,4.9
	c-0.3,0.3-0.7,0.5-1.1,0.7c-1.3,0.9-1.5,1.9-0.4,3.2c0.4,0.4,0.8,0.8,1.3,1.2c0.5,0.5,0.4,0.9-0.2,1.3c-0.9,0.6-2,0.8-2.9,0.3
	c-0.9-0.5-1.3-1.4-1-2.7C320.6,59,320.3,57.5,320.3,56.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M394.3,44.2c1.1,0,1.8,0,2.7,0c-0.4,1-0.9,1.9-1.2,2.8c-1,2.7-0.5,5.4,0.1,8.1c0.3,1.4,0.8,2.7,1,4.1
	c0.4,2.8-0.8,5.1-2.5,7.1c-1.6,1.8-3.3,3.6-5.3,4.9c-1.9,1.2-4.1,1.9-6.3,2.6c-4.1,1.2-8.4,1.4-12.7,1c-2.3-0.2-4.6-0.1-6.9-0.3
	c-2.3-0.2-4.6-0.4-6.8-1c-3.4-0.8-6.8-1.8-10.1-2.9c-1.9-0.7-3.7-1.8-5-3.5c-0.5-0.7-1.3-1.3-1.8-2c-2-2.5-2.2-5.3-1.4-8.3
	c0.5-2,1.4-3.9,2-5.8c0.8-2.2,1.3-4.5,0.3-6.8c-0.1-0.3-0.2-0.7-0.4-1.1c0.5-0.1,0.9-0.1,1.4-0.2c-1-0.5-2-0.9-3-1.4
	c-0.8-0.5-1.6-1-2.4-1.6c-0.8-0.7-0.7-2.2,0.3-2.9c1-0.7,2-1.2,3.1-1.7c4.6-2.1,9.5-2.9,14.4-3.2c3.9-0.2,7.8-0.2,11.7-0.2
	c3.6,0,7.3,0.2,10.9,0.2c4.4,0.1,8.6,0.9,12.9,2c2.5,0.6,5,1.4,7.5,2.3c1.2,0.4,2.2,1.2,3.2,2c1.3,1,1.1,2.4-0.2,3.3
	c-1.4,1.1-3,1.7-4.7,2C394.9,43.7,394.8,43.9,394.3,44.2z M352.7,61.7c0.4,1.8-0.4,2.4-1.8,2.4c-1.1,0-2.3-0.3-3.3-0.7
	c-1.3-0.5-2.8-0.2-3.4,1c-0.7,1.3-0.3,2.7,1,3.5c2.3,1.4,4.7,2.1,7.3,1.4c2.1-0.6,4.1-1.2,5.2-3.4c1.5-3.1,1.6-6.5,1.9-9.9
	c0.2-2.5-0.7-4.6-2.2-6.7c-2.7-3.8-6.8-3.6-9.6-2c-1.7,1-2.8,2.4-3.8,4c-2.5,3.6-2.2,7.2,2.2,9.5C348.3,61.9,350.5,61.8,352.7,61.7z
	 M392.4,52.9c-0.2-0.6-0.3-1-0.3-1.3c-0.2-1.9-1.7-3.6-3.6-3.8c-2.4-0.2-4.8,0.2-6.9,1.5c-4.4,2.7-7.2,6.5-7.6,11.8
	c-0.4,5.3,3.3,8.1,8,8.4c3.4,0.2,5.7-1.8,7.7-4.2c1-1.2,1.4-2.8,1.4-4.4c-0.1-2.7-4-5.3-6.7-5.1c-0.8,0.1-1.6,0.1-2.9,0.2
	c0.7-1.1,1-2.1,1.6-2.8c1.2-1.2,2.3-1,3.3,0.3c0.3,0.4,1.1,0.9,1.5,0.8C389.5,54,391,53.4,392.4,52.9z M341.5,37.2
	c-1.2,0.6-1.6,1.9-0.6,2.6c1,0.7,2.1,1.4,3.3,1.6c3.5,0.6,7.1,1,10.6,1.5c3.2,0.4,6.4,0.7,9.6,0.9c2.6,0.2,5.1,0.2,7.7,0.2
	c3,0,5.9,0.1,8.9-0.2c3-0.3,6.1-0.8,9-1.3c2.4-0.5,4.8-0.8,6.9-2.2c0.9-0.6,0.9-1.4,0-2c-0.4-0.3-0.9-0.3-1.7-0.6
	c1.6,1.6,1.6,1.9-0.3,2.5c-2.5,0.8-5.1,1.6-7.7,2.1c-6.5,1.3-13,1.3-19.5,1c-4.5-0.2-9-0.8-13.5-1.4c-3.3-0.4-6.6-0.9-9.9-1.5
	C342.2,39.9,341.4,38.9,341.5,37.2z M367.3,60.8c0,0.1,0,0.1,0,0.2c0.7-0.1,1.7,0.1,1.9-0.3c0.5-0.9,0.8-2,0.9-3.1
	c0-0.3-1.2-0.9-1.9-0.9c-1.2-0.1-2.4,0.1-3.6,0.1c-2.5,0.1-2.5,0.1-3,2.4c-0.1,0.3-0.4,0.7-0.3,0.9c0.2,0.4,0.5,1,0.8,1
	C363.9,61,365.6,60.9,367.3,60.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M405.4,50.7c-0.3,1.1-0.5,2.3-1,3.2c-0.4,0.7-1.4,1.2-2.1,1.8c-1.8,1.4-1.9,2.6-0.1,4.1
	c0.8,0.7,0.8,1.2-0.1,1.5c-0.7,0.2-1.6,0.1-2.1-0.3c-0.9-0.7-1.6-1.8-1.6-3.1c0-0.4,0-0.7-0.1-1.1c-0.3-1,0.1-1.4,1-1.8
	c1.2-0.6,2.4-1.5,3.4-2.6c0.9-1,0.5-2.6-0.6-3.8c-0.5-0.5-2.8-0.5-3.5,0.1c-0.5,0.4-1.1,1-1.6,0.3c-0.3-0.5-0.4-1.5-0.1-2
	c0.7-1.6,2.3-2.2,4.5-1.4C403.9,46.4,405.5,47,405.4,50.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M351.9,74c3,0.5,5.9,1.1,8.8,1.5c2.3,0.4,4.7,0.7,7.1,0.8c4.6,0.1,9.3,0.7,13.9-0.5c0.2-0.1,0.4-0.1,0.6-0.1
	c0.3,0.1,0.5,0.3,0.8,0.4c-0.2,0.2-0.4,0.5-0.6,0.6c-1.4,0.4-2.8,0.8-4.2,1c-3.4,0.3-6.7,0.5-10.1,0.6c-2.6,0-5.3-0.1-7.9-0.5
	c-2.5-0.3-5-0.9-7.5-1.4C351.3,76.2,350.9,75.2,351.9,74z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M469.6,46.1c2.1-0.2,3.9-0.4,5.7-0.6c-0.7,2.8-1.4,5.4-0.9,8.1c0.2,1.2,0.8,2.4,1.1,3.7c0.8,2.9,0.3,5.6-1,8.3
	c-2.8,5.7-7.6,8.9-13.5,10.6c-1.4,0.4-3,0.3-4.4,0.6c-5.4,1.1-10.8,0.3-16.2-0.1c-3.5-0.2-6.9-1.2-10.3-1.8c-4.6-0.7-8-3.5-10.9-6.7
	c-2.1-2.3-2.9-5.3-2.7-8.5c0.1-2.5,0.1-5,1-7.3c0.4-1,0.8-2,1.1-3c0.5-1.7,0.4-3.3-0.6-5.1c1.3-0.1,2.5-0.1,3.9-0.2
	c-0.7-0.3-1.2-0.6-1.6-0.7c-1.7-0.7-3.5-1.2-5.1-2.1c-1.6-0.9-1.6-1.7,0.1-2.7c1.8-1.1,3.9-1.6,6.1-1.8c1.9-0.2,3.8-0.7,5.8-1
	c1-0.2,2-0.2,3.1-0.2c2.7-0.2,5.3-0.5,8-0.5c4.4-0.1,8.9-0.2,13.3,0c4.3,0.2,8.6,0.4,12.8,1.1c3.7,0.6,7.5,0.9,10.9,2.7
	c1,0.5,2.4,0.7,2.8,2.1c0.3,1.1-0.1,2.4-1.1,2.9c-2,1-3.9,2.4-6.4,2.2c-0.2,0-0.3,0.1-0.5,0.1C470.1,45.8,470,45.8,469.6,46.1z
	 M464.1,46.6c-1,1.4-2,2.8-3,4.2c-1.6,2.1-3.3,4.2-4.8,6.4c-1.7,2.5-3.3,5-4.9,7.5c-0.6,1-0.3,1.5,0.8,1.5c2.8,0.1,5.6,0.1,8.5,0.2
	c0.6,0,1.3-0.1,1.3,0.8c0,0.8,0,1.6-0.1,2.4c-0.2,1.9-0.2,1.9,1.5,2.5c0.1,0,0.2,0.1,0.2,0.1c2.7,0.2,3.2,0.9,4.1-2.3
	c0.1-0.5,0.4-1.1,0.4-1.6c0-1.1,0.5-1.2,1.4-1.3c0.7,0,1.8-0.1,2-0.5c0.5-1,0.9-2.3,0.8-3.4c0-0.9-1.1-1-2-0.9
	c-0.1,0-0.3-0.1-0.4-0.2c0-0.4,0.1-0.7,0.1-1c0.3-1.4,0.1-1.8-1.3-2.1c-1.1-0.2-2.2-0.1-3.3-0.1c-1.2,0-1.8,0.6-1.7,1.9
	c0,0.4-0.5,1-0.9,1.1c-0.6,0.1-1.3-0.1-2.2-0.2c2.2-4.6,5.3-8.2,8.3-11.9c0.4-0.5,0.4-1.3-0.3-1.5C467.3,47.7,465.7,47.2,464.1,46.6
	z M427.5,56.9c-1.2,1.3-2.7,2.6-3.8,4.1c-1.7,2.2-1.4,5.1,0.5,6.8c2.7,2.4,5.9,3.7,9.6,3.2c1.6-0.2,3.1-0.7,4.2-2.1
	c1.2-1.5,1.9-3.1,1.8-5.1c-0.2-2.4-0.6-4.6-2.4-6.4c-0.5-0.5-0.9-1.1-1.4-1.6c0.5-0.5,1-0.9,1.5-1.3c1.7-1.4,2.2-3.5,1.1-5.4
	c-0.3-0.6-0.9-1.1-1.5-1.5c-0.4-0.3-0.9-0.4-1.3-0.7c-1.8-1.5-4-1.8-6.1-1.2c-3.1,0.9-5.8,2.4-6.4,6.2c-0.2,1-0.1,1.7,0.6,2.3
	C425.1,55.1,426.4,56,427.5,56.9z M473.8,40.8c-1.2,0.3-2.3,0.8-3.4,0.9c-4.6,0.4-9.2,0.8-13.9,1c-4.1,0.2-8.1,0.2-12.2,0.1
	c-4.7-0.1-9.4-0.3-14.1-0.7c-3-0.3-6-0.9-9-1.5c-1-0.2-2.1-0.4-2.1-2.2c-0.5,0.4-1,0.7-1.1,1c-0.1,0.3,0.2,0.8,0.4,1
	c1,0.5,2.1,1.2,3.2,1.4c2.4,0.5,4.8,1,7.3,1.3c4.4,0.5,8.9,0.9,13.3,1.1c4,0.2,8,0.1,12,0c2.9-0.1,5.8-0.4,8.7-0.6
	c2.2-0.2,4.5-0.3,6.7-0.6c1.6-0.2,3.2-0.7,4.8-1.1c0.4-0.1,0.9-0.6,0.9-1c0-0.3-0.4-0.9-0.8-1c-1.4-0.6-2.9-1.1-4.3-1.6
	C471.3,39.2,473,39,473.8,40.8z M441.6,57.2c0.1,0.9,0.3,1.7,0.4,2.5c0,0.7,0,1.3,1,1.2c0.8-0.1,1.6-0.1,2.4-0.1
	c2,0.1,3.9,0.2,5.9,0.2c0.2,0,0.5-0.2,0.6-0.4c0.3-0.9,0.7-1.8,0.8-2.8c0-0.3-0.6-1-1.1-1c-1.7-0.2-3.4-0.4-5-0.4
	C444.9,56.5,443.1,55.9,441.6,57.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M447.8,80.8c-4.5-0.3-8.6-0.5-12.6-0.9c-1.3-0.1-2.6-0.5-3.8-0.8c-0.7-0.2-1.5-0.6-1.3-1.6c0.2-1,1-1.5,2-1.3
	c2.1,0.3,4.2,0.6,6.3,0.8c2.8,0.3,5.7,0.7,8.5,0.9c3.1,0.1,6.3,0,9.4-0.1c0.1,0,0.2,0,0.4,0c0.9,0,2.1-0.1,2.2,0.9
	c0.1,0.9-1.1,0.9-1.8,1C453.8,80.1,450.6,80.5,447.8,80.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M477.4,60.8c-0.1-1.2-0.2-2.4-0.3-3.7c0-0.2,0.2-0.6,0.4-0.7c0.4-0.3,0.9-0.5,1.3-0.7c1.4-0.6,2-1.9,1.5-3.4
	c-0.7-2-1.8-2.5-3.8-1.9c-0.3,0.1-0.6,0-0.8-0.1c-0.1-0.1,0-0.5,0-0.7c0.2-1.2,0.5-2.3,2-2.3c0.8,0,1.5,0.3,2.2,0.7
	c4.1,1.8,4,7.4,0.6,9.6c-2.1,1.4-2.1,2-0.6,3.9c0.7,1,0.6,1.5-0.5,2c-1.1,0.5-2-0.2-2.2-1.6C477.3,61.5,477.3,61.1,477.4,60.8
	C477.3,60.8,477.3,60.8,477.4,60.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M190.3,43.6c-1.4-0.3-2.9-0.5-4.3-0.9c-1.6-0.5-3.3-1-4.8-1.7c-2.1-1-1.9-2.8-0.1-4.2c2-1.5,4.3-2.3,6.7-2.8
	c3.8-0.7,7.7-1.3,11.5-1.7c3.2-0.3,6.4-0.4,9.6-0.4c3.9,0,7.7,0.1,11.6,0.4c3.4,0.3,6.7,0.9,10,1.6c2.8,0.6,5.6,1.4,8.4,2.2
	c1.4,0.4,2.4,1.6,2.5,3c0.1,1.6-1.3,2.5-2.5,3.2c-1.3,0.7-2.8,1-4.3,1.5c-0.3,0.1-0.6,0.2-0.8,0.5c0.7-0.2,1.4-0.4,2.1-0.5
	c1-0.2,1.5,0.1,1.4,1.1c-0.4,2.5,0.4,4.7,1.4,6.9c1.6,3.8,1.7,7.7-0.1,11.6c-1.6,3.4-4.2,5.7-7.1,7.8c-4.3,3.1-9.2,4.7-14.6,4.7
	c-2.8,0-5.6,0.3-8.4,0.2c-2.5-0.1-5-0.5-7.5-0.7c-3.1-0.3-6.1-0.8-9-2c-2.8-1.1-5.4-2.7-7.6-4.9c-4.5-4.4-4.1-9.5-2.4-14.8
	c0.9-2.7,2-5.2,1.6-8.1c0-0.3-0.1-0.6-0.1-0.9c-0.2-1.3-0.1-1.5,1.3-1.4c1.5,0.1,2.9,0.2,4.4,0.3c0.3,0,0.7,0,1,0
	C190.3,43.8,190.3,43.7,190.3,43.6z M200.4,52.4c-0.4,0.7-0.6,1.3-0.9,1.9c-0.5,1.1-1.1,2.2-1.5,3.3c-1.3,3.3-0.5,3.3-4.5,3.1
	c-0.6,0-1.1,0-1.2,0.8c-0.1,0.9-0.5,1.8-0.7,2.6c-0.1,0.3,0.2,0.9,0.4,1c0.8,0.3,1.6,0.4,2.5,0.6c-0.3,1.4-0.6,2.8-1,4.2
	c-0.4,1.4-0.1,1.9,1.4,1.9c0.8,0,1.7,0.1,2.5,0.1c1.4,0,1.8-0.4,2-1.8c0.1-0.7,0.4-1.4,0.4-2.1c0.1-1.3,0.8-1.5,2-1.4
	c3,0.4,3,0.3,4.1-2.4c0.4-1,0.1-1.5-1-1.7c-0.7-0.2-1.5-0.3-2.3-0.5c0.7-2.1,1.2-4.1,2-6.1c0.7-2,1.7-4,2.4-6
	c0.1-0.4-0.2-1.2-0.4-1.3c-1.1-0.3-2.2-0.4-3.2-0.5c-1.8-0.2-3.7-0.4-5.5-0.7c-2-0.2-3.9-0.5-5.9-0.7c-1.5-0.1-2,0.7-1.5,2.1
	c0.1,0.3,0.2,0.6,0.2,0.9c0.1,1.6,0.2,3.2,0.2,4.8c0,0.8,0.2,1.3,1.2,1c0.6-0.2,1.1-0.3,1.7-0.4c2.2-0.4,2.2-0.4,2.4-2.5
	c0.1-0.7,0.4-1,1.1-0.9C198.4,51.9,199.3,52.1,200.4,52.4z M184.4,36.5c-0.6,0.4-1,0.6-1.2,0.9c-0.6,0.6-1.2,1.3-0.5,2.2
	c0.5,0.5,1.1,1,1.8,1.1c2.5,0.6,5,1.2,7.5,1.5c3.3,0.5,6.6,0.7,9.9,1c1.5,0.1,3.1,0.2,4.6,0.3c4,0.3,8,0.8,11.9,0.7
	c4-0.1,8.1-0.7,12.1-1.3c1.8-0.2,3.6-0.7,5.2-1.5c1.9-1,2.7-3.2,0.5-4.1c0.8,1.3,0.2,2.2-0.7,2.9c-0.4,0.3-1,0.6-1.5,0.6
	c-2.8,0.5-5.6,1.2-8.4,1.4c-3.6,0.3-7.3,0.4-10.9,0.3c-3.9-0.1-7.7-0.3-11.6-0.6c-3.9-0.3-7.9-0.7-11.8-1.1c-2-0.2-4-0.5-6-0.8
	c-1.4-0.2-1.9-1.5-1.2-2.7C184.1,37.1,184.2,37,184.4,36.5z M224.9,69.8c2.1,0.1,3.7-0.9,5.3-2.1c2.3-1.7,3.3-4.2,3.9-6.9
	c0.7-3,1.3-5.9-1.2-8.5c-1.5-1.5-3.3-2.7-5.3-2.3c-3.1,0.7-5.4,2.6-6.9,5.5c-1.2,2.3-1.2,4.7-1.8,7.1C218,65.9,221,70.2,224.9,69.8z
	 M216.8,57.6c0.1-0.1,0.2-0.2,0.2-0.2c-0.4-0.3-0.7-0.8-1.1-0.8c-1.9-0.1-3.9-0.1-5.9-0.1c-0.9,0-2-0.2-2.6,0.2
	c-0.6,0.5-0.6,1.7-0.9,2.6c-0.3,1.2-0.2,1.4,1,1.4c2.1,0,4.1,0.2,6.2,0.1c0.7,0,1.6,0.8,2.1-0.2c0.3-0.7,0.5-1.5,0.8-2.2
	C216.8,58.1,216.7,57.8,216.8,57.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M195.4,76c2.7,0.4,5.2,0.8,7.8,1.1c3.7,0.3,7.4,0.9,11.2,0.3c2.2-0.4,4.5-0.4,6.7-0.6c0.7-0.1,1.4,0,1.5,0.9
	c0.1,0.9-0.2,1.5-1.1,1.7c-0.9,0.2-1.9,0.4-2.8,0.5c-5,0.3-10.1,0.8-15.1,0c-2.2-0.3-4.4-0.6-6.6-1.1
	C195.4,78.5,194.9,77.5,195.4,76z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M248,51.3c-0.1,2.8-0.9,4.6-3.1,5.6c-0.8,0.4-1.5,0.9-2.2,1.5c-0.9,0.9,0,1.7,0.2,2.6c0.1,0.5,0.3,1,0.4,1.6
	c-0.5,0.1-1.2,0.5-1.5,0.3c-0.6-0.3-1.1-0.7-1-1.7c0.2-1.3,0.2-2.6,0-3.9c-0.2-1.1,0.4-1.4,1.2-1.8c0.6-0.3,1.3-0.7,1.7-1.1
	c1.3-1.3,1.5-3.6,0.6-5.1c-0.9-1.4-3.2-2.1-4.9-1.5c-0.6,0.2-1.3,0.4-1.4-0.3c-0.1-0.5,0.3-1.5,0.7-1.6c1.4-0.5,2.8-0.9,4.3-0.9
	c1.8,0,3.2,1,3.9,2.6C247.5,48.9,247.7,50.4,248,51.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M34,103.5c-4,0.9-8.1,1.3-10.6,5.8c-1-1-2-1.9-2.9-3c-1-1.2-0.7-2.5,1.3-3.9c1.7-1.1,3.6-2,5.6-2.5
	c3.4-0.9,6.8-1.7,10.2-2.2c6.5-0.8,13-1,19.6-0.2c4.4,0.5,8.8,0.9,13.2,1.5c3.8,0.5,7.5,1.4,10.9,3.2c1.5,0.8,3,1.4,4.4,2.2
	c3.7,2.3,2.5,7.4-0.9,8.7c0-1.9-0.8-3.4-2.4-4.4c-1.5-0.9-3.1-1.5-4.6-2.2c1.4,1,2.9,1.9,4.1,3.1c1.1,1,1.8,2.4,1.3,4
	c-0.1,0.4-0.4,0.9-0.8,1.1c-3.7,1.9-7.4,3.7-11.6,4.2c-2.7,0.4-5.5,0.4-8.2,0.5c-2.7,0.1-5.5,0.1-8.2,0c-2.6-0.1-5.2-0.3-7.7-1
	c-2.5-0.7-5-1.1-7.5-1.7c-3.8-0.9-7.4-2.3-10.8-4.3c-0.9-0.6-1.9-1-2.8-1.6c-1.1-0.7-1.2-1.4-0.4-2.5c1.3-1.6,3-2.6,4.8-3.3
	c1.3-0.5,2.7-0.9,4-1.3C34.1,103.7,34.1,103.6,34,103.5z M51.7,103.8c1,0,1.8,0.1,2.7,0c1-0.2,1.1,0.2,0.8,1c-0.4,1-0.8,2.1-1.3,3.1
	c-0.6,1.4-0.9,1.4-2.4,1.1c-0.9-0.2-2-0.4-2.5,0.7c-0.4,0.9-0.8,1.8-1.2,2.7c-0.3,0.7-0.2,1.1,0.6,1.2c0.5,0.1,1.1,0.2,1.6,0.2
	c0.9,0,1.4,0.2,1,1.2c-0.2,0.5-0.3,1-0.5,1.5c-0.3,1.1-0.2,1.3,1,1.4c0.9,0,1.8,0.1,2.6,0.2c1.8,0.2,2.1,0.1,2.5-1.7
	c0.3-1.4,0.6-1.6,2-1.4c1.1,0.1,2.4,0.7,3.2,0.3c0.8-0.4,1.2-1.8,1.7-2.8c0.6-1.1,0.4-1.8-0.8-2.2c-0.8-0.3-1.7-0.5-2.7-0.8
	c0.7-2.6,1.9-4.8,3.3-7c0.5-0.8,0.8-1.6,1.2-2.4c-0.9-0.3-1.8-0.7-2.7-0.7c-2.3-0.2-4.6-0.2-6.9-0.3c-2.9-0.1-5.7-0.2-8.6-0.3
	c-0.8,0-1.4,0.3-1.2,1.2c0.2,1.2,0.4,2.3,0.7,3.5c0.8,2.8,0.8,2.8,3.7,2.7C51.7,106.2,51.7,106.2,51.7,103.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M20.1,107.8c0.3,0.1,0.7,0.1,0.9,0.3c2.4,2.4,5.1,4.2,8.1,5.9c5.1,2.9,10.8,3.8,16.3,5c1.9,0.4,3.8,0.9,5.8,1.1
	c2.9,0.3,5.9,0.5,8.8,0.5c3,0,6,0.1,8.9-0.3c4.3-0.5,8.5-1.6,12.3-3.7c1.4-0.8,2.9-1.5,4.5-2.1c-0.5,0.5-0.9,1.2-1.5,1.6
	c-3,2.1-5.8,4.4-9,6.2c-2.1,1.1-4.6,1.7-7,2c-5.4,0.8-10.8,0.2-16.2-0.4c-3.6-0.4-7.3-0.9-10.8-1.9c-3.7-1.2-7.2-3.1-10.7-4.7
	c-3.5-1.5-6-4.4-8.8-6.9c-0.7-0.7-1.3-1.5-1.9-2.3C19.9,108.1,20,108,20.1,107.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M295.6,117.3c-0.5-0.1-0.9-0.2-1.4-0.3c-1.4-0.4-2.3-1.5-2.3-2.5c0-1,1.3-2.6,2.4-2.8c1.1-0.2,2.3-0.3,3.4-0.3
	c0.9,0,1.7,1.2,1.6,2.4C299.3,115.7,297.5,117.3,295.6,117.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M38.5,61.3c0.5,1.1,1,2.1,1.3,3.2c0.1,0.6-0.2,1.6-0.6,1.8c-0.8,0.4-1.8,0.5-2.6,0.3c-0.2-0.1-0.5-1.5-0.3-2.1
	C36.7,63.3,36.9,61.9,38.5,61.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M37.4,52.5c0.2-0.1,0.7-0.7,1-0.6c0.8,0.2,2,0.2,1.9,1.3c0,0.5-0.8,1.2-1.3,1.4C38.2,54.8,37.3,53.7,37.4,52.5z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M124.3,57.4c-0.2,1.9-0.4,3.8-0.8,5.6c-0.2,0.7-0.9,1.4-1.6,1.8c-0.7,0.4-1.5,0-1.7-0.9
	c-0.2-1.1-0.4-2.3-0.2-3.4c0.4-1.6,1.1-3,1.8-4.5c0.2-0.5,0.9-0.8,1.4-1.2c0.4,0.6,0.8,1.3,1.1,1.9C124.4,57,124.3,57.2,124.3,57.4
	C124.3,57.4,124.3,57.4,124.3,57.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M271.3,59.4c1.4,0,2.5,1.9,2,3.1c-0.3,0.6-0.8,1.2-1.2,1.7c-0.6,0.8-1.4,0.8-2.2,0.4c-0.9-0.5-1.3-1.3-0.9-2.3
	c0.3-0.9,0.8-1.8,1.3-2.5C270.5,59.5,271.1,59.5,271.3,59.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M354.3,54.5c-0.2,1.5-0.6,2.5-1.8,3.1c-1.2,0.6-2.5,0.8-3.6,0c-0.4-0.3-0.5-1.2-0.5-1.8
	c0.1-1.5,0.5-2.8,1.7-3.8c1.2-1,2.7-0.8,3.5,0.6C354,53.2,354.1,54,354.3,54.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M382.8,65.3c-1.3-0.3-2.5-0.4-2.7-1.7c-0.3-1.4,0.8-3.5,2.3-4c1.3-0.4,2.4,0,3,1.2c0.5,1,0.3,2.4-0.6,3.1
	C384.2,64.5,383.5,64.8,382.8,65.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M434.4,63.1c-0.1,1.9-1.2,3.1-3.3,3.1c-2.1-0.1-2.7-1-2-3c0.5-1.4,1.5-2.5,2.8-3.1c0.3-0.1,1,0,1.2,0.3
	C433.7,61.4,434.1,62.5,434.4,63.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M430.5,53c-0.7-0.6-1.4-1-1.5-1.6c-0.1-0.4,0.3-1.1,0.7-1.5c1-1,2.3-0.6,3.5-0.5c0.7,0.1,0.9,1.3,0.3,1.8
	C432.6,51.9,431.6,52.3,430.5,53z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M229.5,57.2c-0.4,2.4-1.1,4.5-2.4,6.3c-0.2,0.3-0.5,0.7-0.9,0.8c-0.6,0.2-1.4,0.5-1.7,0.2
	c-0.4-0.4-0.7-1.2-0.6-1.8c0.1-2.1,0.7-4.1,2-5.8c0.1-0.2,0.3-0.4,0.4-0.6c0.5-0.6,1.2-1.3,2-0.8C228.8,55.8,229.1,56.6,229.5,57.2z
	" />
      </SvgIcon>
    )
  }
};