import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_57_1 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 468.5 129.4"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M412,117.4c-3.1,0.2-5.5,0.4-7.9,0.5c-6,0.1-12-0.5-17.4-3.3c-2.1-1.1-4.2-2.4-6.1-3.9c-2.9-2.3-3.6-5.7-4-9.1
	c0-0.2,0.1-0.4,0-0.6c-1-2.2,0.2-4,0.8-5.9c0.7-2.3,1.1-4.7,1.7-7.1c0.6-2.2,1.2-4.5,1.9-6.7c0.1-0.4,0.8-0.7,1.2-0.8
	c3.1-0.4,6.2-0.9,9.1-2.4c1.5-0.8,3-1.6,4.4-2.5c2.1-1.4,3.2-3.6,4.5-5.6c0.4-0.6,0.7-0.9,1.5-0.9c7.7,0.1,15.3,0.3,23,0.3
	c3.7,0,7.3-0.3,11-0.2c0.9,0,1.9,0.9,2.7,1.6c2,1.5,4,2.9,5.8,4.7c1.3,1.3,2.4,2.9,3.4,4.6c2,3.4,1.5,7,0.5,10.5
	c-1.3,5.1-3.9,9.6-7.6,13.4c-2.8,2.8-6,5-9.6,6.8c-2.5,1.3-4.9,2.7-7.4,3.9C419.5,116.3,415.5,117.3,412,117.4z M396.5,92.3
	c0.3,1.5,0.4,2.7,0.7,3.9c0.4,1.7,2,3.1,3.3,3.2c1.4,0.1,3-1.1,3.6-2.7c1.1-2.8,1-5.6-0.2-8.4c-0.6-1.5-1.7-2-3.2-2.1
	c-1.5,0-2.6,0.8-3.1,2.2C397.1,89.6,396.8,91.1,396.5,92.3z M432.6,87.2c-0.7,0.4-1.2,0.6-1.3,0.9c-0.3,0.8-0.6,1.6-0.7,2.4
	c-0.1,1.7-0.2,3.4,0,5.1c0.1,0.9,0.7,1.8,1.3,2.5c0.9,1.2,2.6,1.5,3.8,0.7c0.7-0.4,1.3-1.1,1.7-1.8c1.4-2.6,1.1-5.4,0.4-8.1
	c-0.3-1.1-1-2.2-2.3-2.7C433.9,85.6,432.6,86.2,432.6,87.2z M425.5,88.4c0.8,0.1,1.5,0.2,2.2,0.1c0.3,0,0.7-0.6,0.7-0.9
	c0-0.5-0.1-1.3-0.4-1.4c-0.5-0.2-1.2-0.1-1.8,0.1c-2.3,0.6-3.8,2.2-4.5,4.4c-0.4,1.4-0.6,3.1-0.3,4.6c0.2,1.1,0.9,2.4,1.8,3.2
	c2.1,1.8,4.4,0.6,5.5-1.1c0.9-1.4,0.8-2.9,0.5-4.4c-0.4-1.8-2.8-3-4.2-2.2C423.8,89.6,425.9,89.4,425.5,88.4z M393.5,93.1
	c0.1-0.3,0.3-0.8,0.3-1.4c0.1-1.4-0.1-2.8,0-4.2c0.1-1.2-0.8-1.3-1.5-1.2c-0.7,0.2-1.4,0.7-1.9,1.3c-1.2,1.8-2.3,3.6-3.4,5.5
	c-0.4,0.7-0.5,1.4-0.9,2.5c1.6,0,2.9,0.1,4.2,0.1c0.7,0,1.2,0.1,1,1c-0.1,0.4,0,0.8,0,1.2c0,0.3,0.1,0.8,0.3,0.9
	c0.3,0.2,0.7,0.1,1.1,0.1c0.3-0.1,0.6-0.3,0.9-0.4c0-0.9,0-1.7,0-2.6c0.7-0.2,1.8-0.5,1.5-1.5C395.1,93.9,394.1,93.5,393.5,93.1z
	 M414,94.1c0.9,0,1.7,0,2.5,0c0.7,0,1.1-0.2,1.1-1c0-0.9-0.3-1.3-1.2-1.2c-0.5,0.1-1.1,0.1-1.5,0c-0.3-0.1-0.8-0.5-0.8-0.8
	c-0.1-0.8,0-1.6,0-2.4c0-0.7-0.3-1-1.1-1.1c-1.3-0.1-1.1,0.8-1.1,1.6c0,2.6,0,2.7-2.6,2.7c-0.9,0-1,0.5-1,1.2c0,0.8,0.3,1.1,1.1,1
	c0.8,0,1.7,0,2.5,0c0,1.3,0,2.3,0,3.3c0,0.8,0.4,1,1.1,1c0.7,0,1.1-0.1,1.1-1C414,96.4,414,95.3,414,94.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M387.3,7.7c0.3,0,0.6,0.1,0.8,0c4.3-0.4,8.4,0.5,12.3,1.9c3.4,1.2,6.8,2.6,9.1,5.4c2.8,3.4,3.9,7.2,2.4,11.6
	c-1.5,4.4-4.5,7.7-7.6,10.9c-3.4,3.5-6.9,6.9-10.4,10.3c-0.7,0.7-1.4,1.4-2.2,2c-0.3,0.2-0.8,0.3-1,0.2c-4-1.6-8.1-1.2-12.1-0.5
	c-2.7,0.4-5.2,1.7-7.8,2.5c-0.4,0.1-1.1,0-1.4-0.2c-2.2-2-5-3.1-7.5-4.6c-2.3-1.4-4.6-2.7-6.1-5c-1.2-1.9-2.9-3.6-3.9-5.5
	c-1-1.8-1.6-3.8-2.1-5.8c-0.8-2.6-1-5.3-0.4-8c0.4-1.8,1.5-3.1,2.7-4.2c4.6-4,9.9-6.7,15.8-8c4.5-1,9-2.1,13.6-2.8
	C383.4,7.4,385.4,7.6,387.3,7.7C387.3,7.6,387.3,7.7,387.3,7.7z M357.9,21.3c0-0.1,0-0.1,0-0.2c-0.1,0-0.3-0.1-0.4,0
	c-2.9,0.4-4.2,2.6-3.1,5.3c0.3,0.7,0.4,1.3-0.2,2c-0.8,1.1-0.9,2.2-0.3,3.6c0.6,1.4,1.5,2.1,2.9,2.3c1.6,0.2,3.2-0.3,4-1.6
	c0.8-1.2,1.4-2.8,0.2-4.2c-0.9-1-0.6-1.7-0.1-2.6c0.8-1.4,0.2-3.4-1.2-4c-0.7-0.3-1.6-0.2-2.3-0.3C357.5,21.5,357.7,21.4,357.9,21.3
	z M402.8,27.3c-0.4-1.4-0.7-3-1.3-4.4c-0.8-1.8-3-2.4-4.5-1.2c-0.9,0.6-1.7,1.6-2,2.6c-0.9,2.6-0.9,5.3,0.3,7.8c0.5,1,1.2,2,2.4,2.1
	c1.8,0.1,3.2-0.3,4.1-2.4C402.3,30.5,402.4,29,402.8,27.3z M370.8,27.6c-0.1,0-0.2,0-0.3-0.1c0-0.5,0-1,0-1.6
	c-0.1-2.1-1.3-3.9-2.9-4.2c-3-0.7-4.3,0.5-4.9,3.3c-0.1,0.4-0.2,0.9-0.3,1.3c-0.4,2.3-0.1,4.6,1.2,6.5c0.7,1.1,1.8,1.7,3.2,1.5
	c1.3-0.2,2.3-0.9,2.8-2.2C370.2,30.6,370.5,29.1,370.8,27.6z M388.8,21.7c0,0-0.1-0.3-0.2-0.7c-0.7,0.4-1.3,0.7-2,1
	c-1,0.5-0.7,1.1-0.3,1.9c0.5,0.9,0.9,0.3,1.4,0.1c0.7-0.2,1.8-0.5,2.3-0.1c0.8,0.5,0.8,1.6,0.4,2.5c-0.1,0.3-0.2,0.5-0.4,0.7
	c-1.3,1.6-2.6,3.2-3.8,4.8c-0.3,0.4-0.3,1.1-0.2,1.6c0.1,0.2,0.7,0.5,1,0.5c1.8,0.1,3.6,0,5.4,0c0.9,0,1.1-0.3,1.1-1.1
	c-0.1-0.8-0.2-1.4-1.1-1.4c-0.8,0-1.6,0-2.5,0c1.1-1.6,2.1-3,2.8-4.4c1.3-2.4,0-5.5-2.4-6C389.8,21.2,389.4,21.5,388.8,21.7z
	 M374.6,29.1c0.3,0,0.8,0,1.3,0c1.8,0.1,1.8,0.1,1.8,1.9c0,0.8-0.1,1.5,0.7,2c0.3,0.2,0.7,0.4,1.1,0.3c0.2,0,0.4-0.5,0.5-0.8
	c0.1-0.5,0-1,0-1.4c0-2.1,0-2.1,2.1-2c0,0,0.1,0,0.1,0c0.5-0.1,1.3,0.3,1.3-0.7c0-0.9-0.2-1.6-1.4-1.5c-0.7,0-1.4,0-2.2,0
	c0-1.1,0-2.1,0-3.1c0-0.8-0.4-1-1.1-1c-0.7,0-1.2,0.1-1.1,1c0,1,0,2.1,0,3.1c-0.9,0.1-1.8,0.1-2.6,0.1c-0.8,0-1.2,0.6-0.9,1.4
	C374.4,28.5,374.4,28.7,374.6,29.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M368.4,53.5c-2,1.7-3.5,3.7-4.5,6c-1.2,2.8-2.1,5.7-1.4,8.8c0.2,1.1-0.2,1.4-1.2,1.6c-4.3,0.6-8.6,1.4-12.9,1.9
	c-3.8,0.4-7.7,0.6-11.6,0.8c-2.9,0.2-5.9,0.3-8.9,0.4c-3.4,0.1-6.8,0.1-10.2,0c-3.7-0.2-7.1-1.1-9.7-3.9c-1.1-1.2-1.7-2.5-2-4.1
	c-0.6-3.6-0.5-7.2,0.1-10.8c1-6.2,3.9-11.4,8-16.1c2-2.3,4.2-4.4,6.7-6c1.6-1,3.5-1.6,5.3-2.2c3.9-1.3,7.8-1.3,11.6,0.6
	c2.7,1.4,5.3,2.9,8,4.2c2.4,1.2,4.4,2.8,5.8,5.2c0.8,1.3,1.7,2.5,2.6,3.7c2,2.7,4.9,4.1,7.7,5.7C364.3,50.5,366.5,51.6,368.4,53.5z
	 M327.3,56c-0.3-1.4-0.4-2.9-0.8-4.3c-0.5-1.4-1.5-2.5-3.1-2.6c-1.5-0.1-2.5,0.7-3.3,2c-1.6,2.8-1.4,5.7-0.4,8.6
	c0.6,1.8,2.1,2.7,3.6,2.6c1.5-0.1,2.8-1.3,3.3-3C326.9,58.3,327.1,57.2,327.3,56z M359.1,55.7c0-1.9,0-1.9-1.4-4.7
	c-1.1-2.1-2.9-2.4-4.7-0.9c-1.1,0.9-1.6,2.1-1.8,3.4c-0.4,2-0.2,4,0.4,6c0.5,1.5,1.4,2.6,2.9,2.9c1.5,0.3,2.7-0.4,3.6-1.8
	C358.9,59,359.1,57.4,359.1,55.7z M316,51.8c-0.9,2-1.8,3.6-2.3,5.4c-0.5,1.7-1.6,3.1-2.1,4.8c2.1,0.6,2.4,0.4,3.2-1.4
	c0.4-0.8,0.4-1.6,0.9-2.4c1.2-2.2,2-4.5,3-6.9c0.3-0.8,0.2-1.8-0.7-1.8c-2.2-0.2-4.5-0.1-6.8-0.1c0,0.9,0,1.5,0,2.4
	C312.7,51.8,314.2,51.8,316,51.8z M336.4,50.9c-1.9-0.2-2.2,0-2.1,1.6c0,0.4,0.1,0.7,0.1,1.1c0,0.9-0.1,1.7-1.3,1.3
	c-0.4-0.1-0.9,0-1.3,0c-0.8,0-1,0.5-1.1,1.2c-0.1,0.8,0.4,1,1.1,1c0.6,0,1.1,0,1.7,0c0.5,0.1,1.2-0.1,1,1c-0.1,0.4,0,0.9-0.1,1.4
	c-0.2,1.7,0.4,2.5,2.1,1.7c0-0.5,0-1,0-1.5c0.1-2.4,0.1-2.4,2.6-2.5c0.8,0,1.2-0.2,1.2-1.1c0-0.9-0.3-1.2-1.2-1.2
	c-0.6,0-1.2,0.1-1.8,0c-0.2,0-0.6-0.4-0.6-0.6C336.4,53.2,336.4,52.1,336.4,50.9z M345.1,52c0.1,3.2,0,6.2,0.1,9.2
	c0.1,0,0.2,0,0.3,0c0-2.3,0-4.5,0-6.8c0.2,2.3,0.3,4.5,0.2,6.8c0,0.7,0.3,0.9,0.9,0.9c0.7,0,0.9-0.2,0.9-0.9c0-3.6,0-7.3,0-10.9
	c0-0.6-0.3-1-0.9-0.9c-0.7,0.2-1.4,0.4-2.1,0.7c-1.7,0.7-1.9,1.4-0.8,3.1C344,52.9,344.4,52.7,345.1,52z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M363.4,71.6c3.1,6,8.5,8.4,15.2,8.8c-0.5,2.2-1,4.3-1.5,6.4c-0.8,2.9-1.4,5.9-2.4,8.8c-1.1,3.2-2.4,6.4-3.7,9.6
	c-1,2.4-2.9,3.9-5.2,5.1c-5.6,2.8-11.4,3.7-17.6,2.5c-2.4-0.5-4.9-0.6-7.2-1.3c-5.2-1.4-10.3-3.1-14.8-6.3c-1.5-1.1-3.2-1.9-4.6-3.1
	c-4.2-3.6-7.3-8-7.2-13.7c0.1-4.7,2.2-8.4,6.7-10.5c1.7-0.8,3.6-1.3,5.4-2c4.9-2.1,10.2-0.9,15.3-1.6c5.3-0.7,10.5-1.2,15.8-1.9
	C359.5,72.2,361.3,71.9,363.4,71.6z M368.9,87.5c-0.1-0.4,0-0.7-0.2-0.8c-1.7-1.2-4.2-0.5-5.1,1.4c-0.5,1.1-0.9,2.2-1,3.3
	c-0.3,2.7,0,5.3,2.2,7.1c0.3-0.1,0.6-0.1,0.7,0c0.1,0.1,0.1,0.4,0.2,0.7c0.4,0,0.9,0.1,1.4,0c2-0.4,3-1.9,3.2-3.7
	c0.3-1.9,0.2-3.9,0-5.9C370.4,88.7,370.2,87.5,368.9,87.5z M339.2,92.8c-0.4-1.7-0.7-3.3-1.2-4.8c-0.5-1.2-1.6-1.9-2.9-1.9
	c-1.2,0-2.3,0.4-2.9,1.5c-1.3,2-1.6,4.3-1.3,6.6c0.3,1.8,0.7,3.5,2.5,4.5c1.5,0.8,3.9,0.2,4.5-1.3C338.4,95.9,338.7,94.4,339.2,92.8
	z M354.3,96.4c-0.7,1-0.7,1.9,0.2,2.3c2.4,1,4.6,0.7,6.1-1.6c1.8-2.7,0.4-5.8-2.8-6.4c-0.6-0.1-0.9-0.3-0.9-1c0-0.7,0.3-1,1-1
	c0.8,0,1.5,0.1,2.3,0c0.4-0.1,0.9-0.5,1-0.9c0.1-0.6,0.3-1.4-0.7-1.4c-1.5-0.1-3-0.2-4.5-0.1c-0.3,0-0.8,0.7-0.9,1.2
	c-0.3,1.7-0.4,3.5-0.7,5.4c1,0,2-0.1,2.9,0c1,0.1,1.6,0.7,1.6,1.8c0,1.1-0.3,2-1.6,2C356.4,96.8,355.5,96.5,354.3,96.4z M324.4,92.4
	c1.1-0.5,1.4,0.5,2,1.1c0.8,0.8,0.8,1.6,0.3,2.5c-0.5,0.9-1.4,1.1-2.4,0.9c-0.3-0.1-0.6-0.3-0.9-0.3c-0.5,0.1-1.1,0.3-1.4,0.7
	c-0.4,0.5-0.2,1.2,0.5,1.4c0.9,0.3,1.7,0.5,2.6,0.8c0-0.1,0-0.2,0-0.3c0.4-0.2,0.8-0.4,1.2-0.6c0.7-0.3,1.6-0.4,2.1-0.9
	c1.1-1.3,1.4-3,0.6-4.6c-0.8-1.7-2-2.2-3.8-2c-0.2-0.6-0.3-1.3-0.5-2.1c1.5,0,2.8,0,4.3,0c0.1-0.6,0.2-1.1,0.1-1.6
	c0-0.3-0.3-0.9-0.5-0.9c-1.8-0.1-3.6,0-5.4,0c0.1,0.6,0.2,1,0.1,1.3c-0.2,1.2-0.6,2.5-0.7,3.7c-0.1,1.3,0.9,1.8,2.1,1.2
	C324.6,92.5,324.5,92.4,324.4,92.4z M348.1,87.8c-2.4-0.5-2-0.3-2,1.6c0,0,0,0.1,0,0.1c0,2.7-0.2,2.3-2.3,2.3
	c-0.8,0-1.4,0.1-1.3,1.1c0,1,0.5,1.1,1.3,1.1c0.4,0,0.9,0,1.3,0c0.8-0.1,1,0.2,1,0.9c0,0.8-0.1,1.6,0.1,2.4c0.1,0.4,0.7,0.7,1.1,1.1
	c0.3-0.4,0.7-0.7,0.8-1.1c0.1-0.8,0.1-1.6,0.1-2.4c0-0.6,0.2-0.9,0.9-0.9c0.9,0,1.8-0.1,2.7-0.1c0.2-2.1,0.2-2.1-1.7-2.1
	c-0.2,0-0.5,0-0.7,0c-0.9,0.1-1.2-0.4-1.2-1.2C348.1,89.7,348.1,88.8,348.1,87.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M400.8,67.1c-0.7-10.1-0.6-8.5-7.8-16.6c0.6-0.5,1.2-1.1,1.8-1.6c4.1-4,8.2-8,12.2-12.1
	c1.8-1.9,3.3-4.1,5.1-6.1c1-1.1,2.2-2.2,3.4-3.1c3-2.2,6.3-3.9,10.2-4.2c3.7-0.2,7.1,0.6,10.4,2.2c3.7,1.7,6.9,4.2,9.6,7.2
	c3.1,3.3,5.3,7.2,6.4,11.8c0.9,3.9,0.6,7.6-0.4,11.3c-0.8,3-1.8,5.8-3.6,8.3c-0.8,1.2-2.1,1.5-3.3,1.9c-3.8,1.5-7.8,1.6-11.9,1.3
	c-2.2-0.1-4.5,0.2-6.7,0.3c-3.7,0.1-7.4,0.3-11.2,0.2C410.4,67.9,405.6,67.4,400.8,67.1z M440.6,51.2c0.4,1.1,1,2.5,1.3,3.9
	c0.3,1.4,1.2,2,2.3,2.3c1.9,0.5,3.5-0.4,4.2-2.3c0.9-2.5,0.9-5,0.2-7.5c-0.6-2-1.9-3.1-3.6-3c-1.6,0-3,1.2-3.5,3
	C441.1,48.5,440.9,49.7,440.6,51.2z M408.2,50.8c0,0.7-0.1,1.3,0,1.8c0.5,1.4,0.9,2.8,1.7,4c0.7,1,2,1.2,3.2,0.8
	c1.6-0.5,2.5-1.8,2.9-3.4c0.4-1.9,0.7-3.8-0.1-5.7c-0.4-1-0.6-2-1.2-2.9c-0.6-0.9-1.5-1-2.8-0.9c-1.1,0.1-1.8,0.5-2.2,1.2
	C408.9,47.3,407.8,48.9,408.2,50.8z M440.2,49.5c-0.1,0-0.2-0.1-0.4-0.1c0-0.4,0-0.8-0.1-1.2c-0.3-2.4-2.1-4.1-4.1-3.8
	c-2.7,0.5-4.6,3.7-3.4,6.3c0.7,1.4,1.8,2.5,3.6,2.6c0.3,0,0.6,0.3,0.8,0.4c-0.2,0.2-0.4,0.5-0.6,0.7c-0.7,0.6-1.5,1-2.5,0.8
	c-0.8-0.1-0.9,0.4-0.9,1c0,0.6-0.1,1.3,0.9,1.4c2,0.1,4.5-1.2,5.3-3C439.5,52.9,439.8,51.2,440.2,49.5z M429,50.3
	c-0.2,0-0.4-0.1-0.5-0.1c-0.9,0-2.1,0.2-2.6-0.2c-0.5-0.4-0.3-1.7-0.3-2.6c0-0.7-0.3-1-1-1c-0.7,0-1.3,0.1-1.2,1
	c0.1,0.7,0.1,1.5,0,2.1c-0.1,0.4-0.7,0.7-1.1,1c-0.3-1-1-0.3-1.5-0.4c-0.8-0.1-1,0.3-1,1.1c0,0.8,0.3,1,1.1,1c0.8,0,1.9-0.3,2.3,0.1
	c0.4,0.5,0.2,1.6,0.3,2.4c0.1,1.6,0.3,1.8,2.1,1.5c-0.3-0.1-0.6-0.2-0.5-0.2c0.2-0.6,0.4-1.1,0.6-1.7c0.1-0.3,0-0.7,0-1.1
	c-0.1-0.7,0.2-1.2,1-1c0.3,0,0.6,0,0.8,0C429.3,52.3,429.3,52.3,429,50.3z M403.1,47.6c0,0.5,0.1,0.9,0.1,1.3c0,2.4,0,4.9,0,7.3
	c0,1,0.5,1.2,1.3,1.2c0.8,0,1.2-0.2,1.2-1.2c-0.1-3.3,0-6.5,0-9.8c0-1.7-0.5-2.1-2.2-1.6c-0.7,0.3-1.4,0.7-2.1,1.2
	c-0.2,0.1-0.3,0.6-0.2,0.8c0.1,0.5,0.3,0.9,0.4,1.5C402,48.1,402.4,47.9,403.1,47.6z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M248.5,116.2c-4.7-0.4-9.7-0.5-14.6-1.3c-3.3-0.5-6.4-2-9.6-3.2c-3.8-1.4-6.3-4.2-8-7.9c-1.3-2.6-1.5-5.4-2-8.2
	c-0.1-0.7,0.3-1.5,0.5-2.2c1.6-4.2,3.3-8.4,4.9-12.5c0.5-1.3,1.2-2.5,1.8-3.7c0.3-0.6,0.7-0.9,1.5-0.7c1.6,0.5,3.3,0.9,5,1.1
	c3.1,0.5,6.1,0.1,8.9-1.1c2.6-1.1,4.8-3,6.6-5.3c0.5-0.6,0.8-1,1.7-0.9c3.6,0.3,7.2,0.5,10.8,0.9c0.8,0.1,1.6,0.6,2.5,0.8
	c1.5,0.3,3.1,0.4,4.6,0.9c2,0.7,4.2,1.1,5.8,2.6c0.9,0.8,2,1.4,3,2.1c2,1.4,4.2,2.6,5.9,4.3c1.7,1.6,3.1,3.6,4.4,5.5
	c0.8,1.2,1.4,2.7,1.9,4.1c0.3,0.9,0.2,2,0.1,2.9c-0.6,5.8-4,9.7-8.6,12.9c-3.8,2.7-7.9,4.4-12.3,5.8c-2.9,0.9-5.7,1.8-8.6,2.5
	C252.8,116.1,250.7,116.1,248.5,116.2z M271.2,91.3c-0.2-0.4-0.3-0.9-0.6-1.1c-1.3-1-4-0.4-4.7,1.1c-1.3,2.8-1.7,5.6-0.7,8.5
	c0.4,1,1.3,2.2,2.2,2.7c1.7,0.9,3.5,0.1,4.4-1.7c1.6-3.2,1.3-6.5-0.2-9.7C271.4,91.2,271.2,91.3,271.2,91.3z M234.3,100.6
	c0.7,2.3,2.7,2.8,4.5,1.3c1-0.9,1.6-1.9,1.9-3.1c0.6-2.4,0.6-4.8-0.5-7c-1.5-2.9-4.4-2.7-6-0.7c-1.8,2.3-1.8,7.5-0.1,9.9
	C234.1,100.9,234.2,100.7,234.3,100.6z M256.9,93.3c0.6-0.4,1-0.9,1.5-1.1c0.6-0.1,1.6-0.2,1.9,0.2c0.4,0.4,1.2,1.1,0.5,2
	c-1.1,1.3-2,2.7-3,4.2c-0.7,1.1-2.2,1.8-1.9,3.7c0.5,0.1,1.1,0.2,1.7,0.3c1.5,0.1,3,0,4.4,0.1c1.4,0.1,1.6,0,1.5-1.4
	c0-0.3-0.1-0.6-0.1-1.1c-1.2,0-2.3,0-3.7,0c0.5-0.7,0.8-1.1,1.1-1.5c0.7-1.2,1.6-2.3,2.1-3.6c1.2-3-0.9-5.7-4.2-5.3
	C256.6,90,255.9,91.2,256.9,93.3z M224.4,102.5c2.2,0.5,2.4,0.4,3.2-1.5c1.3-2.9,2.5-5.8,3.7-8.7c0.2-0.5,0.3-1.2,0.2-1.7
	c0-0.3-0.4-0.8-0.7-0.8c-2.3-0.1-4.5,0-6.8,0c-0.2,2.2-0.1,2.3,1.8,2.4c0.5,0,1,0,1.6,0c1,0,1.2,0.3,0.8,1.2
	C227,96.4,225.7,99.4,224.4,102.5z M253.6,95.6c-0.8-0.1-1.6-0.2-2.4-0.1c-0.8,0-1.2-0.3-1.1-1.1c0-0.7-0.1-1.4,0-2.1
	c0.1-1.1-0.5-1.1-1.2-1c-1,0.1-0.9,0.9-0.9,1.6c0,2.5,0,2.5-2.5,2.6c-0.8,0-1.2,0.2-1.1,1.1c0,0.9,0.3,1.2,1.2,1.2
	c0.9-0.1,1.8,0,2.4,0c0,1.1,0,2,0,2.8c0,0.7-0.1,1.3,1,1.3c1,0,1.3-0.4,1.2-1.3c-0.1-0.6,0-1.1-0.1-1.7c-0.1-0.9,0.1-1.3,1.1-1.2
	c0.3,0,0.6,0,1,0C253.8,97.6,253.8,97.6,253.6,95.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M211.6,67.3c1.5,4,4.4,6.7,8.6,8.1c-0.6,1.2-1.3,2.2-1.7,3.3c-1.7,4-3.5,8-4.9,12.1c-1.3,3.6-2.3,7.3-4.6,10.5
	c-1.4,1.9-2.6,4-4.3,5.7c-2,2.1-4.6,3.3-7.5,3.5c-4.5,0.4-8.9,0.2-13.3-0.8c-1.3-0.3-2.5-0.8-3.7-1.3c-4.1-1.7-8-4-11.6-6.5
	c-4-2.8-7.4-6.1-10-10.3c-1.1-1.7-1.9-3.6-2.7-5.4c-1-2.3-0.4-4.5,0.7-6.6c1.9-3.6,4.3-6.7,7.6-9c1.2-0.8,2.6-1.5,3.9-2
	c2.6-1,5.1-2,7.8-2.6c1.6-0.4,3.4,0,5.1,0c1.2,0,2.5,0.1,3.7,0.2c5,0.3,10.1,0.7,15.1,0.9c2.8,0.1,5.6,0.1,8.4,0.2
	C209.3,67.3,210.3,67.3,211.6,67.3z M168.6,83.1c-0.1-0.5-0.1-0.7-0.1-0.9c0.2-1.2-0.3-2.2-1.3-2.8c-1.4-0.9-3.5-0.6-4.8,0.6
	c-0.9,0.9-1.2,2.7-0.4,4.1c0.4,0.7,0.5,1.2,0,1.8c-1.3,1.4-0.5,3-0.2,4.4c0.4,1.5,2.7,2,5,1.4c1.1-0.3,2.4-1.9,2.4-3
	c0-0.3-0.5-0.9-0.4-0.9c1-0.6,0.1-1.1-0.1-1.5c-0.6-0.8-0.9-1.6-0.1-2.4C168.6,83.6,168.6,83.1,168.6,83.1z M170.7,86.6
	c0.2,0.9,0.4,1.6,0.6,2.3c0.2,0.7,0.9,1.2,1.2,1.7c0.4,0.7,0.9,1.1,1.6,1.1c0.8,0,1.7,0,2.4-0.3c0.6-0.2,1.2-0.9,1.4-1.5
	c0.5-1.4,0.8-2.9,1-4.4c0.1-1.1-0.1-2.2-0.3-3.3c-0.2-1.9-1.3-3-3.1-3.5c-1.8-0.4-3.4,0.7-4,2.9C170.9,83.4,170.9,85.1,170.7,86.6z
	 M204.3,89c0.1,1.9,0.6,2.8,1.7,2.9c2.5,0.2,4.2-1,4.5-3.5c0.2-2,0.2-4,0-6c-0.2-1.8-1.6-3.2-2.9-3.4c-1.8-0.3-3.3,0.5-4.1,2.4
	c-1,2.6-1.1,5.3-0.1,7.9c0.1,0.2,0.3,0.3,0.5,0.6C204,89.5,204.1,89.3,204.3,89z M183,86.8c0.8,0.1,2.3-0.2,2.7,0.3
	c0.5,0.7,0.2,2.1,0.3,3.1c0,0.3,0.3,0.9,0.5,0.9c0.5,0,1.1-0.1,1.4-0.3c0.3-0.2,0.2-0.8,0.2-1.2c0-0.8-0.3-1.9,0.2-2.4
	c0.4-0.4,1.5-0.2,2.3-0.3c0.1,0,0.3,0,0.4,0c0.3-0.2,0.7-0.4,0.8-0.7c0.3-1.1,0-1.4-1.2-1.3c-2.4,0.1-2.4,0.1-2.4-2.3
	c0-0.4,0-0.8,0-1.2c0-0.6-0.2-0.9-0.9-1c-1.3-0.1-1.3,0.5-1.3,1.5c0,2.7,0,2.8-2.6,2.9c-0.1,0-0.3,0-0.3,0c-0.2,0.4-0.4,0.7-0.4,1.1
	C182.5,86.1,182.8,86.3,183,86.8z M196.7,82.1c0,0.5,0.1,0.9,0.1,1.3c0,2.5,0,4.9-0.1,7.4c0,0.9,0.4,1.1,1.2,1
	c0.6,0,1.5,0.2,1.5-0.9c0-3.6,0-7.3,0-10.9c0-1-0.7-1.5-1.7-1.1c-0.7,0.3-1.3,0.6-1.9,1c-1.2,0.7-1.3,1.6-0.3,2.7
	C195.9,82.6,196.2,82.4,196.7,82.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M217,51.1c-4.3,3.8-6.6,8.4-6.4,14.3c-1.4,0-2.9,0-4.3,0c-4.8-0.2-9.7-0.3-14.5-0.5c-3.5-0.2-7.1-0.3-10.6-0.8
	c-5.2-0.6-10.5-1.3-15.3-3.6c-1.8-0.8-3.5-1.7-5-3c-1.1-0.9-1.9-2.3-2.4-3.6c-0.8-1.9-1.3-4-1-6.2c0.4-2.7,0.5-5.4,0.8-8.1
	c0.1-0.6,0.2-1.2,0.5-1.7c1.5-2.4,2.8-4.9,4.5-7.2c1.6-2.1,3.5-4.1,5.4-5.8c1.1-1,2.7-1.6,4.1-2.4c3.3-2,7-2.5,10.8-2.5
	c4.4,0.1,8.2,2.1,11.8,4.3c1.3,0.8,2.6,1.6,3.9,2.5c0.5,0.3,1.1,0.9,1.2,1.4c1.1,4.1,3.5,7.5,5.8,11c0.5,0.7,1,1.5,1.4,2.3
	c1.6,3.1,4.2,5.2,6.7,7.3C215.3,49.6,216.2,50.4,217,51.1z M176.9,47.3c-0.2-1.4-0.3-2.8-0.7-4.1c-0.4-1.5-1.7-2.4-3.1-2.6
	c-1.3-0.2-2.4,0.4-3.2,1.7c-1.6,2.4-1.8,7.3-0.2,9.7c0.8,1.2,1.8,2,3.3,1.8c1.6-0.2,2.7-1.2,3.1-2.8
	C176.5,49.8,176.7,48.6,176.9,47.3z M207.3,47c-0.1,0-0.1,0-0.2,0c0-0.2,0-0.5,0-0.7c-0.3-1.3-0.5-2.8-1.1-4
	c-0.7-1.4-1.9-1.7-3.6-1.3c-0.8,0.2-1.5,0.5-1.9,1.1c-2,3-2,6.2-0.7,9.4c0.6,1.5,2.2,2.5,3.4,2.3c1.4-0.1,2.9-1.4,3.3-2.9
	C206.9,49.5,207.1,48.2,207.3,47z M190.6,50.9c-0.7,1.8,0,2.8,2.1,2.8c0.2,0,0.4,0,0.6,0c3-0.2,4.7-2.4,4.2-5.2
	c-0.3-1.9-1.5-3-3.4-3.4c-0.3-0.1-0.8-0.5-0.9-0.8c0-0.5-0.1-1.2,0.8-1.1c0.8,0.1,1.5-0.1,2.3,0c1.2,0.1,0.9-0.9,1.1-1.5
	c0.1-0.7-0.2-1-1-1c-1.3,0-2.6,0.1-4,0c-0.9,0-1.2,0.5-1.3,1.2c-0.2,1.8-0.4,3.6-0.5,5.2c1.2,0.2,2.5,0.1,3.5,0.5
	c1.6,0.7,0.7,2.1,0.6,3.5C193.4,51.2,192.1,51.1,190.6,50.9z M160.7,43.9c0.5-0.3,0.8-0.5,1.1-0.6c0.4-0.1,0.8-0.2,1.2-0.3
	c1.1-0.1,1.6,0.6,1.3,1.6c-0.3,0.9-1.1,1-1.9,0.9c-0.9,0-1.3,0.3-1.2,1.3c0.1,0.9,0.3,1.3,1.2,1.2c0.4,0,0.7,0,1.1,0.1
	c1,0.2,1.4,1,1.2,1.9c-0.1,0.9-0.5,1.5-1.5,1.4c-0.5-0.1-1-0.2-1.5-0.2c-0.7,0-1.5-0.2-1.8,0.8c-0.3,1,0.4,1.2,1.1,1.5
	c2.4,1,5.1,0.2,6.1-2.7c0.3-0.8,0.4-1.7-0.3-2.6c-0.6-0.7-1-1.5-0.3-2.5c0.7-1.2,0.7-2.4-0.1-3.7c-0.7-1-2.7-1.7-3.7-1.1
	c-0.6,0.3-1.2,0.4-1.8,0.7c-0.3,0.1-0.5,0.6-0.5,0.9C160.3,42.8,160.5,43.3,160.7,43.9z M182.4,46.3c-0.8,0-1.7,0-2.5,0
	c-0.5,0-1.1-0.1-1.1,0.7c0,0.7,0.1,1.4,1,1.5c0.5,0,1,0,1.4,0c0.8,0,1.1,0.3,1.1,1.1c0,1,0,2,0,3.2c0.9-0.2,1.5-0.4,2.1-0.6
	c0.5-1.4-0.6-3,0.2-3.9c1.2,0,2.1,0.3,2.8,0c0.4-0.1,0.6-1,1-1.7c-1.1-0.2-1.8-0.3-2.4-0.3c-1.5,0-1.5,0.1-1.4-1.5c0-0.5,0-1,0-1.6
	c0.1-1-0.6-1.1-1.3-1.1c-0.3,0-0.6,0.2-0.9,0.3c0,0.6,0,1.2,0,1.8C182.4,44.9,182.4,45.5,182.4,46.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M245.2,68.5c0.3-1.1,0.7-2.3,0.8-3.5c0.1-1.2,0-2.5-0.1-3.8c-0.3-3.4-1.4-6.4-3.7-8.9c-0.7-0.8-0.6-1.3,0.2-2
	c4.1-3.7,8.2-7.3,12.1-11.2c1.9-1.9,3.6-4.1,4.8-6.6c0.9-1.8,2.8-3.1,4.3-4.5c4-3.4,8.3-2.5,12.8-1.1c4.4,1.3,8,4,11.6,6.9
	c3.4,2.8,5.8,6.4,7.8,10.2c1.4,2.7,2.5,5.4,3,8.4c0.5,3-0.1,5.7-1.8,8.2c-2.4,3.6-5.6,6-9.8,7.6c-3.6,1.4-7.2,2.5-11,3.1
	c-2.8,0.4-5.6,0.6-8.5,0.8c-0.9,0.1-1.9-0.2-2.8-0.4c-2.4-0.5-4.9-1.1-7.3-1.5c-3.1-0.5-6.1-0.7-9.2-1.1
	C247.3,68.9,246.2,68.7,245.2,68.5z M287.9,52.4c0.4,1.6,0.8,3.1,1.3,4.6c0.5,1.4,2.6,2.3,3.7,1.9c1.8-0.6,2.7-2,3.1-3.6
	c0.5-2.2,0.5-4.5-0.2-6.7c-0.7-2.1-2.8-3.6-4.6-2.5c-0.8,0.5-1.7,1.2-2.1,2C288.5,49.5,288.3,50.9,287.9,52.4z M263.8,51.7
	c-0.3-1-0.7-2.2-1-3.4c-0.4-1.7-1.8-2.3-3.2-2.4c-1.5-0.1-2.6,0.9-3.3,2.3c-1.2,2.5-0.9,5-0.4,7.5c0.3,1.7,1.4,3.3,3.4,3.3
	c0.9,0,1.9-0.5,2.6-1.1c0.6-0.5,0.9-1.5,1.2-2.3C263.5,54.4,263.6,53.2,263.8,51.7z M250.7,48.3c0.1,0,0.5,0.1,0.8,0.1
	c0.4,0,0.7-0.1,1-0.2c1-0.1,1.3-0.6,1.1-1.8c-0.1-1-0.9-0.7-1.4-0.6c-0.6,0.1-1.2,0.4-2.2,0.8c0.7,0.2,1,0.3,1.4,0.4
	c-0.8,0.1-1.5,0.1-2.1,0.3c-0.5,0.1-1.1,0.4-1.3,0.8c-0.5,0.7-0.9,1.5-1.2,2.4c-0.7,2.2-0.8,4.5,0.5,6.6c0.9,1.6,3.2,2.4,4.4,1.6
	c0.9-0.6,1.7-1.4,2.4-2.2c0.8-1.1,0.6-3.5-0.3-4.7c-0.1,0.1-0.1,0.3-0.2,0.4c-0.4-2.6-2.4-2.2-4.2-2.1
	C249.9,49.5,250.3,48.9,250.7,48.3z M285.4,53.1c0-1.9,0-3.7,0-5.5c0-1.5-0.7-2-2.1-1.4c-0.4,0.2-0.7,0.6-1,1c-1.3,2-2.5,4-3.7,6
	c-0.3,0.5-0.5,1.3-0.3,1.7c0.2,0.4,1,0.7,1.6,0.7c1,0.1,2.1,0,3.2,0c0,0.9,0,1.6,0,2.3c0,0.7,0.3,0.8,0.9,0.9c1,0.1,1.5-0.3,1.4-1.4
	c-0.1-1.4,0-1.4,1.1-1.8c0.1,0,0.2-0.2,0.3-0.3C287.2,54.2,286.7,53.3,285.4,53.1z M270.9,57.9c1.9,0.3,2,0.2,2.1-1.5
	c0-0.8-0.3-1.9,0.1-2.3c0.5-0.5,1.5-0.3,2.4-0.4c0.8-0.1,1.1-0.6,1.1-1.4c0-0.9-0.5-0.8-1.1-0.8c-0.4,0-0.9,0-1.3,0
	c-0.9,0.1-1.2-0.3-1.2-1.2c0-0.9,0-1.8,0-2.7c-0.8,0-1.5,0-2.4,0c0.3,1,0.5,1.8,0.7,2.6c0,0.3-0.1,0.8-0.3,0.9
	c-0.6,0.2-1.3,0.3-2,0.4c-0.7,0.1-1.8-0.4-1.8,1.1c0,1.4,0.9,1.1,1.7,1.1c0.7,0,1.4,0.1,2.1,0.1C270.9,55.3,270.9,56.6,270.9,57.9z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M218.6,50.3c-1-1-1.9-2-2.9-2.7c-3.2-2.4-5.8-5.3-7.7-8.8c-1.2-2.2-2.7-4.2-3.8-6.5c-1-2.1-1.7-4.4-2.5-6.7
	c-0.2-0.5-0.2-1.1-0.1-1.6c1-3.6,2.6-6.7,6.3-8.4c5.3-2.3,10.7-3.8,16.5-4.2c6.4-0.4,12.9-0.4,19.3,0.6c2.5,0.4,4.9,1.4,7.3,2.2
	c1.5,0.5,3,1.3,4.5,1.9c2.9,1.1,5.9,5.9,4.9,10.1c-0.5,2.1-1.4,4-2.7,5.8c-0.8,1.1-1.3,2.3-2,3.4c-2.1,3.1-4.7,5.6-7.4,8
	c-2.3,2-4.7,3.9-6.9,5.9c-1,0.9-2,1.1-3.3,0.7c-2.1-0.6-4.2-1.3-6.3-1.7c-3-0.6-5.9,0-8.7,0.7C221.3,49.3,219.9,49.9,218.6,50.3z
	 M224.5,24.7c-0.1-0.9-0.2-1.7-0.3-2.6c-0.5-2.4-1.9-3.8-3.5-3.8c-2.1,0-3.7,1.5-4,3.6c-0.2,1.7-0.3,3.4-0.2,5.1
	c0.1,0.9,0.5,1.9,1,2.7c0.4,0.6,1.1,1.1,1.7,1.5c0.9,0.6,3.1-0.2,3.8-1.2C224.2,28.5,224.5,26.7,224.5,24.7z M250.9,18.4
	c-1.6-0.1-2.6,0.6-3.3,1.9c-1.5,2.7-1.3,5.5-0.5,8.3c0.2,0.7,0.7,1.6,1.3,1.9c0.9,0.5,2.2,1,3,0.7c1-0.3,2.1-1.3,2.5-2.3
	c1.1-2.9,1.2-5.9-0.2-8.8C253.2,19.1,252.3,18.3,250.9,18.4z M214.7,28.1c0-0.3-0.1-0.5-0.1-0.7c0.3-0.8,0.5-1.5-0.8-1.7
	c-0.2,0-0.4-0.6-0.4-0.9c0-1.7,0-3.4-0.1-5.1c0-0.4-0.3-1.1-0.5-1.1c-0.6-0.1-1.6-0.1-1.8,0.3c-1.6,2.4-3.2,4.8-4.6,7.3
	c-0.7,1.2-0.1,1.9,1.3,1.9c1.1,0,2.1,0,3.3,0c0,0.9,0,1.6,0,2.4c0,0.7,0.4,1,1.2,1c0.8,0,1.2-0.2,1.3-1.1c0-0.4,0-0.8,0-1.2
	C213.3,28.2,213.6,27.8,214.7,28.1z M241.1,22.5c-0.2-1.5-0.1-1.6,1.4-1.6c0.6,0,1.1,0,1.7,0c0.9,0,0.9-0.6,0.9-1.2
	c0-0.7,0-1.2-0.9-1.2c-1.4,0-2.8,0-4.2,0c-0.7,0-1,0.3-1,1c-0.1,1.3-0.3,2.5-0.5,3.8c-0.2,1.7-0.2,1.8,1.6,1.8c2.1,0,3,1,2.5,2.8
	c-0.1,0.5-0.5,1-0.8,1.5c-1.2-0.3-2.3-0.6-3.5-0.9c-1,1.8-0.9,2,1,2.7c0.5,0.2,1.1,0.2,1.7,0.2c2.8,0,4.7-2.4,4.2-5.2
	C244.8,23.7,242.8,23.4,241.1,22.5z M229.9,30.3c2.2,0.2,2.2,0.2,2.2-1.8c0-0.4,0-0.8,0-1.2c-0.1-0.7,0.3-1,1-1c0.8,0,1.6,0,2.4,0
	c0.2-1.9-0.1-2.2-1.7-2.2c-1.6,0-1.6,0-1.7-1.6c-0.1-0.8-0.2-1.7-0.3-2.6c-0.5,0.1-1.1,0.2-1.8,0.3c0,0.4,0,0.9,0,1.4
	c0,2.7,0,2.7-2.6,2.6c-0.8,0-1,0.2-1,0.9c0,0.4,0,0.8,0.1,1.3c0.9,0,1.7,0,2.5,0c0.8,0,1.1,0.2,1,1
	C229.9,28.4,229.9,29.4,229.9,30.3z" />
                <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M93.4,114.3c-7.2,0.3-12.1-0.1-16.9-1.3c-2.6-0.6-5.1-1.5-7.2-3.3c-2.2-1.8-3.4-4.4-3.4-7.1
	c-0.1-3.3,0.1-6.7,0.8-10c0.4-1.8,1.8-3.5,2.8-5.1c2.5-3.8,4.5-7.8,6-12.1c0.2-0.5,0.4-1.1,0.7-1.5c0.4-0.6,0.9-0.7,1.7-0.6
	c4.8,0.7,9.6,1,13.6-2.8c0.3-0.3,1-0.5,1.3-0.3c2.5,1.5,4.9,3,7.3,4.6c2,1.2,4.1,1.9,6.5,2.2c2.3,0.3,4.5,1.2,6.7,2
	c6.1,2.4,10.5,6.6,12.2,13c1.8,6.6,0,10.1-5.3,14.4c-3.1,2.5-7,3.6-10.8,4.8C103.5,113.2,97.4,114.3,93.4,114.3z M116.1,101.6
	c0.5-0.2,1.1-0.3,1.6-0.5c1.6-0.8,1.9-2.5,2.3-3.9c0.5-1.8,0.2-3.6-0.2-5.4c-0.4-1.5-1.6-3-2.8-3.2c-1.8-0.3-3.4,0.6-4.2,2.3
	c-1.1,2.4-0.9,4.9-0.4,7.3C112.8,100.5,114.2,101.5,116.1,101.6z M87.7,95.1c-0.1,0-0.2-0.1-0.3-0.1c0-0.6,0.1-1.2,0-1.8
	c-0.1-0.6-0.3-1.1-0.4-1.7c-0.3-1.5-1.5-2.9-2.7-3c-1.5-0.1-2.9,0.2-3.8,1.7c-1.4,2.4-1.6,5.1-0.8,7.7c0.3,1,0.8,2.1,1.6,2.8
	c1.8,1.6,3.8,1.1,5.1-1.2C87.1,98.3,87.3,96.6,87.7,95.1z M109.2,95.8c0-1.8-0.1-3.6,0-5.4c0.1-1.8-0.4-1.9-2.1-1.6
	c-0.5,0.1-0.9,0.8-1.3,1.3c-0.9,1.4-1.8,2.9-2.7,4.4c-0.4,0.7-0.9,1.3-1.3,2c-0.3,0.7-0.3,1.4,0.7,1.4c1,0.1,2.1,0.2,3.1,0.1
	c0.7,0,1,0.3,1,0.9c0,0.3,0,0.6,0,1c0,1.4,0.2,1.6,1.6,1.4c1.4-0.1,0.9-1.3,0.8-1.9c-0.1-1.1,0.1-1.8,1.5-1.6
	C110.7,96.9,110.7,95.9,109.2,95.8z M71.5,92c0.6-0.3,1.1-0.7,1.7-0.9c1.3-0.5,2.3,0.2,2.1,1.6c-0.1,1-0.6,2.1-1.2,2.9
	c-1,1.4-2.3,2.5-3.3,3.9c-0.3,0.4-0.4,1.2-0.4,1.7c0,0.1,0.8,0.4,1.2,0.4c1.8,0,3.7,0,5.5,0c1,0,1.1-0.5,1.1-1.3
	c0-0.8-0.2-1.2-1.1-1.2c-0.9,0-1.7,0-2.9,0c0.8-1.1,1.5-1.9,2.1-2.7c1-1.4,1.5-3,1.4-4.8c-0.1-1.4-1.3-1.8-2.1-2.5
	c-0.9-0.8-2.9-0.6-4.1,0.2C70.4,90,70.8,90.9,71.5,92z M96.7,96.5c0.9,0,1.7,0,2.4,0c1,0.1,1.2-0.5,1.2-1.4c0-1-0.7-0.8-1.2-0.8
	c-0.4,0-0.9,0-1.3,0.1c-0.7,0.1-1.1-0.2-1.1-1c0.1-0.8,0-1.7,0-2.5c0-0.2-0.2-0.4-0.3-0.4c-0.5-0.2-1-0.4-1.5-0.3
	c-0.2,0-0.3,0.8-0.4,1.2c-0.1,0.9,0.3,2-0.1,2.5c-0.4,0.5-1.6,0.4-2.5,0.5c-0.9,0.1-1,0.7-0.9,1.3c0,0.3,0.6,0.7,1,0.8
	c0.5,0.1,1.1,0.1,1.7,0c0.7-0.1,0.9,0.2,0.9,0.9c0,0.8,0,1.7,0,2.5c0.1,0.8,0.4,1.1,1.2,0.7c0.3-0.1,0.5-0.2,0.9-0.3
	C96.7,99,96.7,97.7,96.7,96.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M92.8,51.3c3-3.1,6.4-5.3,10-7.2c2.7-1.4,5.6-2.5,8.5-3.8c2.9-1.2,5.9-1.6,9-1.9c3.2-0.4,6.4-1.1,9.6-1.3
	c3.2-0.2,6.3,0.9,9.2,2.3c4,2,6.7,5.3,8.8,9c1.7,3,2.8,6.2,2.8,9.7c0,1.8-0.7,3.7-1.1,5.5c-0.1,0.6-0.4,1.2-0.5,1.8
	c-0.6,3-2.2,5.4-4.3,7.5c-0.7,0.7-1.4,1.5-2.1,2.3c-3.7,4-8.5,5.3-13.7,4.7c-4.8-0.6-9.5-1.5-14.3-2.4c-2.2-0.4-4.4-1.4-6.6-2
	c-1.5-0.5-3.2-0.6-4.6-1.3c-2-0.9-3.8-2.2-5.6-3.3c-0.9-0.5-1.8-1.1-2.8-1.6c-0.9-0.5-1.1-1-0.5-2c1.5-2.4,2.8-4.9,2.6-7.9
	c-0.1-1.5-0.4-2.9-1.4-4.1C94.6,54,93.7,52.6,92.8,51.3z M115.9,59c-0.2-1.2-0.4-2.4-0.7-3.5c-0.4-1.6-1.5-2.7-3.2-3
	c-1.5-0.2-3.1,1.1-3.7,2.9c-1,2.8-0.8,5.6,0.4,8.4c0.8,2,4.4,2.8,5.9,0.4C115.5,62.6,115.8,60.9,115.9,59z M144.1,63.7
	c1.7-0.1,1.7-1.3,1.8-2.4c0-0.3,0-0.6,0-0.8c0.5-1.9,0.2-3.8-0.6-5.6c-1.1-2.5-3.7-2.9-5.7-1.1c-1.5,1.3-1.4,3-1.6,4.7
	c-0.2,1.6,0.1,3.2,0.7,4.8c0.5,1.3,1.2,2.2,2.5,2.5c1.4,0.3,2.8-0.4,3.6-1.6C144.7,64,144.5,63.9,144.1,63.7z M129.6,59.5
	c1,0,1.9,0,2.7,0c0.8,0,1.5,0.3,1.6,1.1c0,0.7,0,1.9-0.4,2.1c-0.7,0.4-1.8,0.4-2.7,0.3c-0.7-0.1-1.1-0.5-1.5,0.5
	c-0.3,0.9-0.1,1.5,0.7,1.7c1,0.2,2.1,0.4,3.1,0.3c1.9-0.2,3.5-2.2,3.5-4.2c0-1.9-1.5-4.2-3.5-4.3c-0.6,0-1.1-0.2-0.9-0.9
	c0.2-0.4,0.6-0.9,1-1c0.7-0.2,1.4-0.1,2.1,0c0.7,0,1-0.3,1-1c0-1-0.5-1.5-1.5-1.4c-1.2,0.1-2.5,0.4-3.6,0.2c-0.7-0.1-0.9-0.1-1,0.5
	C130,55.4,129.8,57.4,129.6,59.5z M101,58.4c-0.2,0.3-0.4,0.6-0.7,0.9c0.3,0.2,0.6,0.5,1,0.6c0.3,0.1,0.7,0,1.1,0.1
	c1.3,0.3,1.8,1.5,1.1,2.7c-0.6,0.8-1.6,1-2.7,0.5c-0.5-0.2-1-0.4-1.5-0.6c-0.7,2.2-0.7,2.3,0.7,2.8c0.3,0.1,0.7,0.1,1,0.2
	c1.5,0.4,3.8-0.4,4.6-1.5c0.8-1,1.1-3.3-0.1-4.7c-0.5-0.6-0.6-1.2-0.2-2c1-1.9,0.2-4.1-1.7-4.6c-0.9-0.3-2.1-0.1-3,0.1
	c-1.9,0.5-2,1.4-0.6,2.9c0.3-0.2,0.6-0.5,0.9-0.5c0.7-0.1,1.4-0.2,2,0c0.2,0.1,0.5,1.1,0.3,1.3c-0.5,0.4-1.1,0.7-1.8,0.9
	c-0.4,0.1-0.8,0.2-1.1,0.2c0,0.1,0,0.2,0,0.3C100.6,58.3,100.9,58.4,101,58.4z M121.2,54.4c0,1-0.1,2.1,0,3c0.1,1-0.3,1.1-1,1.1
	c-0.7,0-1.3,0.1-2,0.2c-0.8,0.2-0.6,0.9-0.5,1.4c0.1,0.3,0.6,0.6,0.9,0.6c0.8,0.1,1.6,0,2.6,0c0,0.9,0,1.8,0,2.7
	c0,0.8-0.1,1.5,1.1,1.5c1.2,0,1.1-0.8,1.1-1.5c0-0.8,0-1.7,0-2.6c1.2,0,2.3,0,3.4,0c0.1-0.6,0.3-1.3,0.1-1.6
	c-0.2-0.4-0.9-0.5-1.4-0.6c-2.2-0.3-2.2-0.3-2.1-2.6C123.4,54.1,123.2,53.9,121.2,54.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M112.3,26.9c0.5,2.4-0.8,4.1-1.8,6c-2.6,4.9-6.3,8.6-11.1,11.4c-2.5,1.5-4.8,3.4-7.1,5.2
	c-0.7,0.6-1.1,0.6-2,0.1c-1.4-0.8-2.9-1.3-4.4-1.7c-3.1-0.8-6.2-0.6-9.1,1c-0.9,0.5-1.6,0.4-2.3-0.3c-3.7-3.8-8.4-6.3-12.7-9.3
	c-1.1-0.7-2.1-1.5-3.1-2.2c-0.2-0.2-0.5-0.4-0.6-0.6c-1-3.4-2.9-6.7-2.9-10.3c0.1-2.6,0.9-5.2,1.6-7.8c1-3.8,4.1-5.5,7.4-6.9
	c4.6-1.9,9.4-2.3,14.3-2.1c3.7,0.2,7.5,0,11.2,0.5c4.9,0.7,9.7,1.8,13.9,4.5c3.5,2.2,6.5,4.8,8,8.7c0.4,1.2,0.6,2.4,0.9,3.7
	C112.5,26.9,112.4,26.9,112.3,26.9z M71.7,32.9c0.2-0.1,0.9-0.2,1.2-0.6c0.7-1.1,1.6-2.2,1.9-3.5c0.6-2.1,0.2-4.3-0.3-6.4
	c-0.3-1.2-2.1-2.6-3.3-2.7c-1.1-0.1-2.9,0.9-3.4,2.1c-1.1,2.7-1.3,5.4-0.4,8.2C68,31.8,69.6,32.9,71.7,32.9z M105.5,31
	c0.1-0.1,0.6-0.2,0.7-0.4c0.3-0.5,0.4-1,0.5-1.6c0.7-2.5,0.4-4.9-0.6-7.3c-0.5-1.3-1.6-1.5-3.2-1.7c-1.4-0.1-2.3,0.4-2.8,1.4
	c-1.6,3.1-1.8,6.3-0.3,9.5c0.9,1.8,3.3,2.3,4.8,1.5C105,32.1,105.2,31.5,105.5,31z M90.3,32c1.3,0.8,2.7,1,4.1,0.7
	c2.8-0.6,4.1-4,2.3-6.2c-0.5-0.7-0.5-1.1-0.1-1.6c0.6-1,0.8-2.1,0.2-3.2c-0.8-1.5-1.4-1.5-3.5-1.7c-1.4-0.1-2.1,0.4-3,0.9
	c0.3,0.8,0.5,1.4,0.7,2c0.7-0.3,1.4-0.6,2.2-0.8c1-0.2,1.5,0.5,1.3,1.4c-0.3,1.2-1.2,1.1-2.1,1.1c-0.8,0-1,0.3-1,1.1
	c0.1,0.7-0.1,1.5,1,1.4c0.8-0.1,1.5-0.1,2-0.2c0.1,0.4,0.2,0.7,0.3,0.9c0.8,1.4,0.1,2.7-1.5,2.6c-0.8,0-1.7-0.4-2.7-0.6
	C90.4,30.5,90.3,31.2,90.3,32z M61.1,33.1c0.7-0.3,1.2-0.6,1.8-0.7c2.5-0.6,3.2-2.7,2-5.3c-0.4-1-0.7-1.7-0.1-2.6
	c0.8-1.2,0.3-2.3-0.4-3.3c-0.7-1-1.8-1.3-2.9-1.3c-0.6,0-1.3,0.1-1.8,0.3c-0.5,0.3-1,0.8-1.2,1.3c-0.1,0.3,0.2,0.9,0.4,1.3
	c0.1,0.1,0.7-0.3,1.1-0.3c0.7-0.1,1.4-0.1,2,0c0.2,0,0.5,0.8,0.4,1.1c-0.1,0.4-0.6,0.8-0.9,1.1c-0.2,0.2-0.6,0-0.9,0.1
	c-0.4,0.1-1,0.1-1,0.3c-0.1,0.5-0.1,1.2,0.2,1.4c0.5,0.4,1.2,0.5,1.8,0.7c1,0.3,1.6,1.1,1.4,2.2c-0.2,1-1,1.2-1.8,1.1
	c-0.6-0.1-1.2-0.3-1.9-0.3c-0.3,0-0.9,0.2-0.9,0.4c-0.1,0.5-0.9,1.1,0,1.5C59.3,32.5,60.2,32.7,61.1,33.1z M82.1,21.7
	c0.1,0.7,0.3,1.3,0.4,1.9c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1,0-0.1,0-0.2,0c0,0.8,0,1.6,0,2.4c-0.9,0-1.6-0.1-2.4,0
	c-0.4,0.1-0.9,0.6-1,1c-0.2,0.8,0.2,1.3,1.1,1.2c0.4-0.1,0.9-0.1,1.3-0.1c0.8-0.1,1.1,0.2,1,1c-0.1,0.8-0.1,1.6,0,2.4
	c0,0.3,0.5,0.8,0.7,0.8c0.5,0,1.3-0.3,1.4-0.6c0.2-1.1,0.1-2.2,0.1-3.4c0.3,0,0.7-0.1,1.1-0.1c0.6,0,1.2-0.1,1.8-0.1
	c1,0,0.7-0.8,0.8-1.3c0.1-0.6-0.2-0.9-0.8-0.8c-0.9,0.1-1.7,0.2-2.7,0.4c-0.1-1.4-0.2-3-0.3-4.7C83.4,21.4,82.8,21.5,82.1,21.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M73.2,50.4c-1.3,1.9-2.8,3.5-3.7,5.5c-0.9,1.9-1.3,4.1-1.7,6.1c-0.2,0.9,0,1.8,0,2.7c0,1-0.8,0.8-1.3,0.8
	c-3.1,0.3-6.1,0.5-9.2,0.8c-5.7,0.6-11.5,0.2-17.2,0c-2.9-0.1-5.7-0.3-8.6-0.5c-2.1-0.2-4.2-0.5-6.3-0.8c-2-0.3-4.1-0.4-6-0.8
	c-2.2-0.5-3.8-1.8-5.1-3.7c-3.2-4.9-3.1-10-1.1-15.3c1.3-3.4,3.2-6.4,5.6-9.1c1.8-2,3.5-4.1,6-5.2c5-2.2,10.2-2.4,15.4-0.8
	c5.5,1.8,10.6,4.1,15.4,7.3c3.2,2.1,6.1,4.4,9.4,6.4c3,1.8,5.7,3.9,8.2,6.3C73.2,50.3,73.3,50.5,73.2,50.4z M63.7,50.9
	c-0.3-1-0.4-2.1-0.8-2.8c-0.5-0.9-1.3-1.8-2.2-2.2c-1.6-0.7-3.6,0.2-4.2,1.8c-1.2,3.1-1.5,6.4,0.2,9.4c0.7,1.4,2.4,2.1,4,1.5
	c1.6-0.6,2.4-1.9,2.7-3.5C63.6,53.7,63.6,52.3,63.7,50.9z M29.6,46.3c-1.9-1-3.9-0.5-4.8,1.2c-0.9,1.6-1.4,3.4-1.1,5.2
	c0.2,1.5-0.2,3.1,1.3,4.3c0.4,0.3,0.5,1.2,1.2,1.5c2,1,4.2-0.2,4.9-2.2c0.9-2.6,1-5.4,0-8c-0.3-0.8-0.7-1.8-2.1-1.6
	C29.2,46.5,29.4,46.4,29.6,46.3z M21.3,58.6c0.1-0.8,0.1-1.6,0.3-2.4c0.1-0.3,0.4-0.5,0.6-0.8c0.3-0.3,0.7-0.5,0.8-0.9
	c0.2-0.5,0.1-1.1-0.7-1.3c-0.3-0.1-0.7-0.6-0.7-1c-0.1-1.8-0.1-3.6-0.2-5.4c0-0.4-0.3-1-0.5-1c-0.9-0.1-1.8-0.3-2.4,0.9
	c-1,1.9-2.3,3.6-3.4,5.5c-0.4,0.7-0.7,1.5-0.8,2.3c-0.2,0.6,0.2,0.9,0.9,0.9c1,0,1.9,0,2.9,0c0.6,0,1,0.2,1,0.9
	c0,0.8,0.1,1.5,0.1,2.3C19.9,58.6,20.5,58.6,21.3,58.6z M47.4,48.3c0.5,0.1,0.8,0.3,1.1,0.3c0.4-0.1,0.9-0.5,1.2-0.4
	c0.6,0.1,1.1,0.5,1.7,0.8c-0.3,0.5-0.6,1.1-1,1.5c-0.1,0.2-0.6,0-0.8,0.1c-0.4,0.2-0.9,0.5-1.3,0.7c0.3,0.3,0.6,0.6,0.9,0.8
	c-0.1,0-0.4,0.1-0.8,0.2c0,0.1,0,0.3,0,0.4c0.5,0.1,0.9,0.3,1.4,0.3c1.2,0,1.8,0.6,1.8,1.8c0,1.2-0.4,1.6-1.6,1.6
	c-0.9,0-1.7-0.2-2.8-0.4c-0.2,0.5-0.3,1.2-0.5,1.8c0.6,0.1,1.1,0.1,1.2,0.3c0.4,1,1.3,0.7,2,0.7c2.1,0.1,3.5-0.9,4.3-2.8
	c0.6-1.4,0.1-2.6-0.8-3.8c-0.2-0.3-0.2-0.9,0-1.2c0.8-1.5,0.8-2.9-0.3-4.3c-0.8-1-2.8-1.3-4.3-0.7C47,46.6,47,46.7,47.4,48.3z
	 M41,47.4c-1.9-0.5-2.1-0.4-2.1,1.4c0,0.5,0,1,0.1,1.4c0.2,0.8-0.2,1.1-0.9,1.2c-0.7,0-1.4-0.1-2,0.1c-0.3,0.1-0.7,0.5-0.8,0.8
	c-0.2,1.3,0.6,1.7,2.4,1.4c0.5-0.1,1,0,1.2,0c0,1.2-0.1,2.1,0.1,3c0.1,0.4,0.7,0.9,1.1,1c0.6,0.1,1.1-0.2,1-1.1c-0.1-1,0-2,0-3
	c1,0,1.7,0,2.4,0c0.9,0.1,1.2-0.3,1.2-1.2c0-1-0.4-1.2-1.2-1.1c-0.4,0-0.9,0-1.3,0c-0.8,0.1-1.1-0.3-1.1-1.1
	C41,49.4,41,48.5,41,47.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M74.6,72.4c-1.1,2.8-1.9,5.5-3.1,8.1c-1.4,2.7-3.1,5.3-4.8,7.9c-1.6,2.5-3.4,4.9-5.1,7.4
	c-2.2,3.3-4.8,6-8.5,7.6c-2.1,0.9-4.3,1.1-6.6,0.8c-1-0.1-1.9-0.3-2.9-0.3c-2.8,0-5.3-0.6-7.8-1.9c-2.4-1.2-5.1-1.9-7.5-3.1
	c-3.5-1.8-6.9-3.8-9.4-7c-1.7-2.3-3-4.8-2.7-7.7c0.2-1.5,1-3.1,1.8-4.5c1.8-3.2,4.3-5.7,7.7-7.3c1.7-0.8,3.5-1.4,5.4-2
	c2.2-0.7,4.4-1.5,6.7-1.8c2.8-0.4,5.6-0.2,8.5-0.3c3.8-0.1,7.6,0,11.4-0.2c3.4-0.2,6.7-0.7,10.1-1c0.3,0,0.9,0.1,1.1,0.3
	c1.4,2.2,3.7,3.4,5.7,4.9C74.6,72.3,74.6,72.4,74.6,72.4z M30.3,84.7c-0.1-0.7-0.2-1.8-0.6-2.9c-0.6-2.2-2.1-3.3-4.1-3
	c-1.6,0.3-2.7,1.5-3.1,3c-0.7,2.6-0.8,5.1,0.4,7.7c0.7,1.4,1.4,2.2,2.8,2.4c1.4,0.2,2.4-0.6,3.2-1.7C30.1,88.7,30.2,86.9,30.3,84.7z
	 M62.5,84.9c-0.5-1.6-0.7-3.2-1.4-4.4c-1.5-2.4-4.2-2.3-5.7-0.2c-1.9,2.6-1.7,5.6-0.8,8.6c0.2,0.8,0.8,1.8,1.4,2.2
	c2.3,1.4,3.8,1,5-1.2C61.8,88.4,62,86.6,62.5,84.9z M45.8,88.7c-0.8,1.3-0.9,2-0.3,2.4c1.6,1.1,4.6,0.7,6.1-0.8
	c1.4-1.5,1.5-4.1,0-5.7c-0.6-0.7-1.6-1.1-2.5-1.4c-0.6-0.2-0.8-0.4-0.7-1.1c0.2-1.3,1.2-0.9,1.9-1c0.4-0.1,0.8-0.1,1.3-0.2
	c0-0.1,0-0.2,0-0.3c0.2,0.2,0.4,0.4,0.8,0.9c0.1-0.9,0.2-1.6,0.2-2.5c-2.1-0.3-4.2-0.5-6.2,0.3c-0.2,2.1-0.5,4.1-0.7,6.2
	c1.7-0.1,3.4-0.7,4.3,1.1c0.3,0.6,0.1,1.8-0.3,2.2C48.5,90,47.1,89.4,45.8,88.7z M37.4,86.4c0,1.2,0,2.3,0,3.4
	c0,0.7,0.3,1.1,1.1,1.1c0.7,0,1-0.4,0.9-1.1c0-0.7,0-1.4,0-2c-0.1-1.1,0.6-1.4,1.5-1.3c0.3,0,0.6,0.1,0.9,0.1c0.8,0.1,1.4,0,1.4-1.1
	c0-1.1-0.5-1.2-1.4-1.1c-0.4,0-0.8,0.1-1.2,0.1c-0.8,0-1.4-0.2-1.3-1.2c0.1-0.7,0.1-1.4,0-2.1c-0.1-0.3-0.4-0.7-0.7-0.8
	c-1.3-0.3-1.3,0.6-1.3,1.5c0,2.5,0,2.5-2.5,2.6c-0.2,0-0.4-0.1-0.5,0c-0.2,0.4-0.6,0.8-0.6,1.2c0,0.3,0.4,0.9,0.7,0.9
	C35.4,86.6,36.3,86.5,37.4,86.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M400.9,97.4c-2-1.8-1.8-3.9-1.6-5.8c0.1-0.9-0.3-2,0.7-2.7c0.6-0.5,1.2-0.8,1.6,0c0.4,0.7,0.7,1.5,0.7,2.3
	c0.1,1.3,0,2.6-0.2,3.8C401.9,95.8,401.4,96.5,400.9,97.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M432.6,92.7c0.2-1.1,0.4-2.3,0.7-3.4c0.1-0.4,0.6-0.9,0.9-0.9c0.4,0,0.9,0.5,1.1,0.9c0.8,2.2,0.7,4.4,0,6.6
	c-0.1,0.4-0.8,1-1,1c-0.4-0.1-0.9-0.6-1.1-1.1C432.9,94.8,432.8,93.8,432.6,92.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M426.9,96.2c-0.9,0.3-1.7,1.4-2.9,0.6c0.2,0,0.4,0.1,0.7,0.1c-0.3-0.7-0.8-1.2-0.9-1.8c-0.1-0.7,0-1.6,0.4-2
	c0.3-0.4,1.5-0.6,1.8-0.4c0.5,0.5,0.7,1.3,0.9,2C427,95.2,426.9,95.6,426.9,96.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M389.7,93.3c0.4-0.6,0.7-1.2,1.1-1.7c0.1-0.1,0.7,0.1,0.7,0.1c0,0.5,0.1,1.2-0.2,1.6c-0.2,0.3-0.9,0.2-1.3,0.3
	C389.9,93.5,389.8,93.4,389.7,93.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M357.1,32.1c-0.2-0.3-1-0.7-1.2-1.4c-0.3-0.8,0.8-2,1.5-2c0.7,0,1.8,1.2,1.7,1.9
	C359,31.4,358.6,32.1,357.1,32.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M357.8,23.3c0.5,0.4,1.1,0.6,1.4,1.1c0.2,0.5-1.1,2-1.7,1.9c-0.8-0.2-1.1-0.8-1.1-1.6
	C356.4,23.8,356.8,23.4,357.8,23.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M396.7,27.9c0.3-1.4,0.5-2.6,0.9-3.7c0.1-0.3,0.6-0.6,1-0.7c0.3,0,0.7,0.3,0.9,0.6c1,2.4,0.9,4.9-0.1,7.3
	c-0.4,0.8-1.2,0.9-1.5,0.1C397.2,30.3,397,29,396.7,27.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M368.3,28.1c-0.2,0.9-0.4,1.9-0.7,2.9c-0.1,0.4-0.6,0.8-0.9,0.9c-0.4,0-1-0.4-1-0.7c-0.3-1.7-0.4-3.4-0.5-5.2
	c0-0.4,0.1-0.9,0.3-1.3c0.3-0.5,0.7-1.1,1.1-1.1c0.4,0,1.1,0.6,1.2,1C368,25.7,368.1,26.8,368.3,28.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M323.7,60.3c-1.4-0.2-2-1.1-2-2.3c0-1.7,0-3.4,0.3-5.1c0.1-0.5,0.8-0.9,1.2-1.3c0.4,0.4,1,0.8,1.1,1.3
	C324.9,55.4,324.9,57.9,323.7,60.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M356.7,55.6c-0.3,1.3-0.4,2.5-0.7,3.5c-0.1,0.4-0.6,0.8-1,0.8c-0.4,0-1.1-0.4-1.1-0.7c-0.4-2.2-1-4.5,0-6.6
	c0.2-0.4,0.8-1,1-0.9c0.4,0.1,1,0.6,1.1,1C356.4,53.7,356.6,54.8,356.7,55.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M365.2,92.7c0.1-1,0.1-2,0.3-3c0.1-0.5,0.7-0.9,1.1-1.4c0.4,0.4,1,0.7,1.2,1.2c0.7,2.1,0.6,4.2,0,6.2
	c-0.1,0.4-0.8,1.1-1.1,1c-0.5-0.1-1.1-0.6-1.2-1.1C365.3,94.7,365.3,93.7,365.2,92.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M336.1,96.5c-1.7,0.6-2.5,0.3-2.6-1.6c-0.1-1.6,0.1-3.3,0.1-5c0-0.9,0.6-1.8,1.4-1.5c0.5,0.2,1.1,1.2,1.2,1.9
	c0.1,1.8-0.1,3.6-0.2,5.4C336,96,336,96.2,336.1,96.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M443.3,50.6c0.1-1.2,0-2.2,0.3-3c0.1-0.4,0.8-0.8,1.3-0.8c0.4,0,1,0.4,1.2,0.7c0.3,0.7,0.4,1.5,0.4,2.3
	c0,1.3,0.1,2.6-0.2,3.8c-0.1,0.5-0.8,1-1.3,1.5c-0.4-0.4-1.1-0.8-1.2-1.4C443.5,52.7,443.4,51.4,443.3,50.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M414,51.2c-0.3,1.1-0.5,2.2-0.8,3.3c-0.1,0.3-0.6,0.7-1,0.7c-0.3,0-0.8-0.4-0.9-0.7c-0.7-2.3-0.9-4.5,0-6.8
	c0.1-0.4,0.7-0.9,1-0.8c0.4,0.1,0.9,0.5,1,0.9C413.6,48.8,413.7,49.9,414,51.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M435.4,46.6c1.7,0.6,2.2,1.3,2.1,2.9c0,0.9-0.6,1.2-1.3,1.3c-0.7,0.1-1.3,0-1.6-0.8
	C434.3,49,434.4,48.3,435.4,46.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M268.8,101.1c-0.8-1.1-1.6-1.8-1.6-3.1c0.1-1.4,0.1-2.8,0.2-4.2c0-0.5,0.3-0.9,0.5-1.3c0.6-0.9,1.3-0.9,1.7,0.1
	c0.4,1,0.7,2.1,0.6,3.1c-0.1,1.4-0.4,2.9-0.7,4.3C269.5,100.3,269.1,100.6,268.8,101.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M237.9,100.8c-0.6,0-1-0.1-1.6-0.1c-0.2-0.6-0.6-1.3-0.8-2.1c-0.4-1.9-0.4-3.8,0.3-5.7c0.2-0.4,0.7-1,0.9-1
	c0.5,0.2,1.1,0.6,1.2,1c0.5,2.3,0.7,4.6-0.1,6.8C237.7,100.1,237.9,100.5,237.9,100.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M164.2,86c0.7,0.4,1.5,0.7,2.1,1.2c0.7,0.7,0.4,1.6-0.2,2.1c-0.4,0.4-1.4,0.5-1.8,0.2c-0.5-0.3-0.7-1.2-0.7-1.8
	C163.6,87.2,163.9,86.7,164.2,86z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M163.4,82.2c0.5-0.5,0.9-0.9,1.3-1.3c1.5,0.5,1.9,1,1.4,2.1c-0.4,1-1.2,1.1-1.9,0.4c-0.1-0.1-0.1-0.2-0.2-0.3
	C163.7,82.7,163.5,82.4,163.4,82.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M176.6,85.3c-0.3,1.2-0.4,2.5-0.8,3.7c-0.4,1-1.4,1-1.9,0c-1-2.4-0.9-4.8,0-7.2c0.1-0.3,0.6-0.7,0.9-0.6
	c0.4,0,0.9,0.4,1,0.7C176.2,83,176.3,84.1,176.6,85.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M208.3,82.2c0,1.1,0,2.1,0,3.2c0,1.1-0.2,2.1-0.3,3.1c0,0.9-0.7,1.1-1.2,1.1c-0.3,0-1-0.5-1-0.9
	c-0.2-1.7-0.4-3.4-0.4-5.1c0-0.6-0.2-1.3,0.5-1.9c0.7-0.6,1.3-1,1.9,0C207.9,81.9,208.1,82,208.3,82.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M173.2,42.5c1.3,1.8,1.1,3.9,1,5.9c0,0.7-0.2,1.4-0.4,2c-0.1,0.4-0.6,0.9-0.9,0.9c-0.4,0-1-0.4-1.1-0.8
	c-0.3-1-0.7-2.1-0.6-3.1C171.3,45.5,171.1,43.5,173.2,42.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M202.7,42.3c0.9,0,1.5,0.6,1.5,1.6c0.2,1.8,0.2,3.7,0.3,5.6c0,0.3-0.1,0.8-0.3,1c-0.3,0.4-0.7,0.8-1.1,0.8
	c-0.4,0-1-0.5-1.1-0.9c-0.5-2.4-0.7-4.8,0.5-7.1C202.5,43,202.6,42.7,202.7,42.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M291.6,48.1c1.7,0.1,2.3,0.7,2.3,2.3c0,1.8,0,3.7,0,5.5c-0.2,0-0.4-0.1-0.5,0c-0.4,0.2-1,0.8-1.2,0.7
	c-0.4-0.3-0.9-0.8-1-1.3C290.5,52.9,290.6,50.5,291.6,48.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M258.9,48.1c1.4-0.3,2,0.6,2,1.8c0.1,1.8,0,3.6-0.2,5.5c0,0.4-0.7,0.8-1.1,1.2c-0.4-0.4-0.9-0.7-1.1-1.1
	c-0.8-2.1-0.8-4.2,0-6.3C258.6,48.9,258.7,48.5,258.9,48.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M252.4,54.7c-0.5,0.6-0.8,1.4-1.4,1.8c-0.3,0.2-1.4-0.2-1.5-0.5c-0.3-0.9-0.4-1.9-0.4-2.8c0-0.8,0.7-0.9,1.4-1
	C251.6,52.2,252.2,53.1,252.4,54.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M282.5,50.8c0.8,2.1,0.4,2.6-1.3,2.3C281.7,52.3,282.1,51.5,282.5,50.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M218.9,21.5c0.2,0,0.3,0,0.5-0.1c1.4-0.9,2.2-0.6,2.3,1c0.1,1.9,0,3.8-0.2,5.7c0,0.4-0.7,0.9-1.1,1
	c-0.3,0-1-0.5-1-0.9c-0.2-1.7-0.3-3.5-0.4-5.2C218.9,22.5,218.9,22,218.9,21.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M252.3,25.5c-0.1,0.6-0.2,1.5-0.4,2.4c-0.2,0.6-0.5,1.5-1.3,1.2c-0.6-0.2-1.1-1-1.2-1.6
	c-0.2-1.4-0.2-2.9-0.2-4.4c0-0.2,0-0.4,0.1-0.6c0.4-0.7,0.5-1.9,1.6-1.8c1,0.1,1.1,1.2,1.3,1.9C252.2,23.5,252.2,24.3,252.3,25.5z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M209.4,26c-0.4-1.3,0.6-1.9,1.3-2.7C211.3,24.9,211,25.5,209.4,26z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M117.7,95.2c-0.1,1-0.2,2-0.5,2.8c-0.2,0.5-0.8,0.8-1.2,1.2c-0.4-0.5-1.1-0.9-1.1-1.4c-0.2-1.6-0.2-3.1-0.2-4.7
	c0-0.6,0.1-1.2,0.4-1.7c0.2-0.3,0.9-0.7,1.3-0.5c0.4,0.2,0.9,0.7,1,1.2C117.6,93.1,117.6,94.2,117.7,95.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M84.7,90.8c0,2.5,0,4.6,0,6.6c0,0.4-0.1,0.9-0.4,1.2C84,99,83.4,99.3,83,99.3c-0.4-0.1-0.8-0.7-0.9-1.1
	c-0.2-1.5-0.4-3.1-0.3-4.6c0-0.7,0.3-1.6,0.7-2.2C83,91,83.9,91,84.7,90.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M106.6,93.8c0.1,0.2,0.1,0.3,0.1,0.4c-0.1,0.6,0.4,1.5-0.7,1.7c-0.3,0-0.6-0.2-0.9-0.3
	C105.5,94.9,105.4,93.9,106.6,93.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M113.4,59.3c-0.2,1.1-0.4,2.2-0.8,3.3c-0.1,0.4-0.7,0.8-1,0.8c-0.3,0-0.9-0.5-1-0.8c-0.7-2.1-0.8-4.2-0.1-6.3
	c0.2-0.5,0.7-0.9,1.1-1.3c0.4,0.4,0.9,0.8,1.1,1.2C113.1,57.1,113.2,58.2,113.4,59.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M143.7,59.2c-0.2,1.1-0.4,2.2-0.7,3.3c-0.1,0.4-0.7,0.8-1,0.8c-0.3,0-0.9-0.5-1-0.8c-0.7-2.1-1-4.2-0.1-6.3
	c0.2-0.5,0.7-1,1.2-1.1c0.2-0.1,0.9,0.6,1,1C143.4,57.1,143.5,58.1,143.7,59.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M70.8,21.9c0.5,0.2,1,0.4,1.6,0.7c0,1.3,0,3,0,4.6c0,0.8-0.1,1.6-0.4,2.3c-0.1,0.4-0.6,0.8-0.9,0.8
	c-0.3,0.1-0.9-0.2-1.1-0.5C68.9,28,69.3,23.6,70.8,21.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M104.5,26.4c-0.2,1.1-0.3,2.2-0.6,3.2c-0.1,0.3-0.7,0.8-1,0.7c-0.4-0.1-1-0.5-1.1-0.8c-0.6-2-0.8-4.1,0-6.2
	c0.2-0.5,0.8-0.9,1.2-1.3c0.4,0.4,0.9,0.8,1.1,1.2C104.4,24.3,104.4,25.4,104.5,26.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M58,52.2c0.2-1,0.4-2.1,0.7-3.2c0.1-0.4,0.7-0.8,1.1-0.9c0.3,0,0.8,0.5,1,0.8c0.7,2,0.8,4,0.2,6
	c-0.2,0.6-0.8,1-1.2,1.5c-0.5-0.5-1.1-0.9-1.3-1.5C58.1,54.1,58.1,53.2,58,52.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M29.4,52.8c-0.3,0.9-0.4,2-0.9,3c-0.5,1-1.5,0.9-1.9-0.1c-0.8-2.3-0.8-4.6,0.1-6.9c0.2-0.4,0.7-0.8,1.1-0.8
	c0.3,0,0.8,0.6,0.9,0.9C29.1,50.2,29.2,51.4,29.4,52.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }}
              d="M19.1,51c-0.1,0.7,0,1.4-0.2,2c0,0.1-0.8,0.1-1.6,0.1c0.5-1,0.9-1.7,1.3-2.3C18.8,50.9,19,51,19.1,51z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M27.9,84.3c-0.2,1.4-0.3,2.8-0.6,4.1c-0.1,0.4-0.7,1-1.1,1c-0.3,0-0.9-0.6-1.1-1c-0.7-2.1-0.5-4.2,0-6.3
	c0.1-0.4,1-1.1,1.2-1c0.6,0.3,1.1,0.9,1.4,1.5c0.2,0.5,0,1.1,0,1.7C27.8,84.3,27.9,84.3,27.9,84.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M57.8,89.8c-0.3-0.4-0.7-0.8-0.8-1.2c-0.6-2.2-0.7-4.3,0-6.5c0.4-1.2,1-1.3,2-0.4c0.1,0.1,0.2,0.2,0.3,0.2
	c0.1,0,0.1-0.1,0.3-0.2c0,1.9,0,3.6,0,5.4C59.6,88.3,59.1,89,57.8,89.8z" />
      </SvgIcon>
    )
  }
};