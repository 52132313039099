import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_32_1 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 510.2 145"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M11.2,43.3c0.1-3,0.2-5.4,0.4-7.8c0-0.7,0.3-1.4,0.5-2.1c2.6-6.3,6.4-11.7,11.8-15.9c0.8-0.6,1.8-1.2,2.8-1.5
	c1.8-0.7,3.7-1.4,5.6-1.8c7-1.3,13.5-0.3,18.7,5.1c1.8,1.8,3.5,3.7,4.8,5.9c2.8,4.7,3.3,9.7,1.7,15c-1.4,4.6-2.9,9.1-5.4,13.4
	c-1.9,3.3-4.3,6.1-7.1,8.6c-3.5,3.2-7.3,5.6-11.6,7.5c-1.5,0.7-3.1,0.7-4.7,0.4c-0.3,0-0.6-0.1-0.8-0.3c-1.5-1.2-3.2-2.3-4.5-3.7
	c-2.4-2.4-4.7-5-6.9-7.6c-2.3-2.8-3.4-6.3-4.3-9.8C11.8,46.8,11.4,44.7,11.2,43.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M28,74.5c-0.6,2.7-1.2,5-3.1,6.7c-0.3,0.2-1.1,0-1.6-0.3c-1-0.6-1.9-1.3-2.8-2c-0.8-0.6-0.7-1.4-0.1-1.9
	c1.5-1.1,3-2.1,4.6-3.1c0.4-0.3,1-0.3,1.5-0.5c-1.2,1.4-2.3,2.7-3.5,4c0.1,0.1,0.2,0.2,0.3,0.3C25.3,77.5,25.9,75.2,28,74.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M435.2,22.9c2.5,0.3,5,0.4,7.4,1c2.9,0.7,5.5,2,7.2,4.6c1.2,1.9,3,3.6,3.7,5.7c1,2.6,1.6,5.5,1.8,8.4
	c0.6,6.4-0.9,12.5-3.4,18.3c-1.6,3.6-3.1,7.5-6.1,10.2c-2.5,2.3-5.4,4.2-8.1,6.3c-0.6,0.5-1.3,0.9-2,1.3c-2,1.4-5.4,1.6-7.9-0.6
	c-3.3-2.9-6.1-6.3-8.4-10.1c-0.7-1.2-1.5-2.4-2.2-3.6c-2.1-3.9-2.8-8.3-3.7-12.6c-0.7-3.6,0.2-7.1,0.7-10.7c0.7-4.9,3.3-8.7,6.7-12
	c3-2.9,6.4-5,10.5-5.8c1.1-0.2,2.3-0.2,3.5-0.2C435.2,23,435.2,23,435.2,22.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M436.6,90.9c-0.5-1.2-0.9-2.1-1.3-3.2c1.1,0.5,1.6,1.9,2.9,1.1c-1.2-1.1-2.2-2-3.3-2.9
	c-0.3-0.3-0.7-0.5-0.9-0.8c-0.2-0.3-0.2-0.8-0.3-1.2c0.4,0,0.9-0.1,1.3,0c1.2,0.6,2.4,1.2,3.5,1.9c1.2,0.6,1.5,1.7,0.6,2.7
	C438.5,89.3,437.6,90,436.6,90.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M433.6,81.7c-0.6,1.4-1.8,1.2-2.9,1.1c-0.3,0-0.7-0.5-0.7-0.8c0-0.3,0.2-0.8,0.5-1
	C431.3,80.4,432.8,80.8,433.6,81.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M407.3,38.2c0,4.1-1.1,8-2.8,11.9c-1.9,4.2-4.7,7.7-7.6,11.2c-3.2,3.9-7.2,6.6-11.3,9.3c-1.4,1-3.1,1.5-4.8,1.1
	c-0.9-0.2-1.7-0.9-2.5-1.6c-3.5-2.9-6.4-6.2-8.9-10c-1.8-2.7-3.4-5.5-4.6-8.5c-1.2-2.7-1.8-5.6-2.1-8.5c-0.5-4.9,0.6-9.7,2.6-14.2
	c1-2.2,2.6-3.9,4.2-5.6c2-2.2,4.2-4,6.8-5.2c4.3-2,8.8-3.3,13.7-2.4c2.7,0.5,5.4,0.8,7.7,2.3c3.3,2.2,6.4,4.6,7.6,8.8
	C406.3,30.5,407.5,34.1,407.3,38.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M381.4,75.2c-1.1,1.2-2.2,2.4-3.4,3.7c2.3,0.9,2.8-1.7,4.6-2.2c-0.3,0.9-0.3,1.7-0.7,2.1
	c-1.1,1.2-2.3,2.2-3.5,3.2c-0.2,0.2-0.8,0.1-1.1,0c-0.4-0.2-0.8-0.5-1.2-0.8c-1.5-1.3-1.5-2.3,0.2-3.3c1.6-1,3.2-1.9,4.8-2.8
	C381.3,75.1,381.4,75.2,381.4,75.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M381.8,73.1c0.6-0.1,1-0.3,1.5-0.3c0.2,0,0.5,0.2,0.8,0.3c-0.1,0.3-0.2,0.8-0.5,0.8
	C382.8,74.2,382.1,74.3,381.8,73.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M311.2,64.7c0-6.3,1.2-12.3,5.2-17.3c1.9-2.4,3.6-4.8,6.2-6.6c2.8-1.9,5.8-3.1,9.2-3.3c3-0.1,6.1,0,9.1,0.1
	c2.5,0.1,4.5,1.5,6.6,2.7c5.2,3.2,8.9,7.6,10,13.8c1,5.9,0.4,11.7-1.3,17.4c-1.2,4.1-3.1,7.9-6.3,10.9c-2.3,2.2-4.3,4.8-6.7,7
	c-1.8,1.7-3.9,3-5.9,4.4c-1,0.7-2.1,1.3-3.1,2c-0.8,0.5-1.5,0.5-2.2,0c-5.7-4-10.9-8.4-14.7-14.3c-2.2-3.5-4.2-7.1-5.1-11.1
	C311.6,68.5,311.5,66.5,311.2,64.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M336.2,102.3c1.3,0.2,2.2,0.4,3,0.5c0-0.1,0.1-0.2,0.1-0.4c-0.8-0.4-1.6-0.7-2.3-1.1c-0.6-0.4-1.2-0.8-1.7-1.3
	c-0.2-0.2-0.2-0.7-0.1-1c0.1-0.2,0.6-0.5,0.9-0.4c2,0.7,4,1.4,5.9,2.2c0.9,0.3,1.1,1.1,0.6,1.9c-0.7,1.1-1.6,2.2-2.4,3.2
	c-0.4,0.5-0.9,0.5-1.4-0.1C338,104.7,337.2,103.6,336.2,102.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }}
              d="M333.8,97.1c-0.1,1.1-1,1.6-2.2,1.4c-0.9-0.1-1.2-0.6-0.9-1.4C331.7,97.1,332.8,97.1,333.8,97.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M308.4,44.4c0.4,4.9-2.2,8.9-4,13.2c-2,4.7-5.8,7.9-9.1,11.4c-1.3,1.4-2.7,2.6-4.1,3.9
	c-0.7,0.6-1.5,1.1-2.4,1.5c-0.5,0.2-1.2,0.3-1.7,0.2c-4.4-1.2-8-3.8-10.9-7.2c-4.1-4.8-7.1-10.2-8-16.7c-0.6-4.2-0.1-8,1.6-11.8
	c2.1-4.5,5.1-8.1,9.4-10.7c2.5-1.5,5.3-1.9,8.2-2c4.4-0.2,8.7,0.7,12.4,2.9c3.3,1.9,6.3,4.5,7.6,8.4c0.7,2.2,0.9,4.6,1.4,6.9
	C308.7,44.3,308.5,44.3,308.4,44.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M289.8,77.8c1,1,1.7,2.3,3.2,2.3c-0.5-0.3-1-0.6-1.4-1c-0.3-0.4-0.4-0.9-0.6-1.4c0.5-0.2,1-0.6,1.4-0.5
	c1,0.2,2,0.6,2.9,1.1c1.1,0.6,1.3,1.5,0.6,2.4c-0.5,0.7-1.1,1.3-1.8,1.8c-0.2,0.2-0.9,0.1-1.2,0c-0.7-0.5-1.4-1-2-1.6
	c-0.7-0.7-1.3-1.4-2-2.2C289.2,78.3,289.5,78.1,289.8,77.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M287.7,77.5c-0.8,0-1.6-0.1-1.6-1.1c0-1.2,1.1-1.2,1.9-1.1c0.5,0,1.1,0.5,1.3,0.9
	C289.6,76.8,288.6,77.5,287.7,77.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M108.8,46.9c-0.2,2.6-0.2,5.2-0.5,7.8c-0.1,1.2-0.7,2.5-1,3.7c-1.2,3.7-2.8,7.1-5.1,10.2
	c-1.1,1.5-2.5,2.9-3.9,4.2c-2.2,2-4.3,4.2-6.7,5.8c-2.5,1.6-5.4,2.6-8.1,4c-1.7,0.9-3.3,0.1-5,0.3c-0.2,0-0.4-0.2-0.6-0.3
	c-5.5-4.6-9.3-10.2-11.1-17.2c-0.4-1.5-1.1-3-1.6-4.5c-1.4-4.4-1.5-8.9-1-13.5c0.4-3.2,1.5-6.1,2.8-9.1c2.8-6.2,7.9-9.5,14-11.6
	c1.4-0.5,2.8-1,4.3-1.2c1.4-0.2,2.9-0.4,4.3-0.1c3.2,0.7,6.5,1.5,9.5,2.7c3.4,1.4,5.8,3.9,7.2,7.4C107.8,39.2,109.1,42.8,108.8,46.9
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M77,87.3c-0.5,0.9-1,1.8-1.7,2.9c2-0.3,2.2-2.3,4-3c-0.9,2.6-1.8,4.7-3.5,6.5c-0.1,0.1-0.5,0.3-0.6,0.2
	c-1-0.5-2.1-1-3-1.8c-0.6-0.5-0.4-1.3,0.2-1.8c1.5-1.1,3-2.1,4.4-3.2C76.9,87.2,77,87.2,77,87.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1}
              fill={this.state.color17 ? this.state.color17 : 'transparent'} onClick={() => {
          this.setState({ color17: color, opacity17: opacity });
        }} d="M78.3,84.7c0.4-0.2,0.8-0.7,1.1-0.6c0.6,0.1,1.1,0.6,1.7,0.9c-0.1,0.2-0.1,0.3-0.2,0.5c-0.8,0-1.6-0.1-2.4-0.1
	C78.4,85.1,78.3,84.9,78.3,84.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M262.4,65.7c-0.1,4.8-1,9.3-3.7,13.3c-0.1,0.2-0.3,0.4-0.3,0.6c-2.5,6.6-7.7,10.3-13.5,13.7
	c-2.5,1.4-5.2,2.2-7.7,3.4c-1,0.5-2.3,0.7-3.4,0.9c-0.4,0.1-1-0.2-1.3-0.4c-3.3-2.7-6.6-5.5-9.1-9c-2.8-3.9-5-8.1-5.4-13
	c-0.3-4.4-0.3-8.7,0.8-13c0.3-1.3,0.4-2.6,0.8-3.8c1.1-3.1,3-5.6,5.2-7.9c1.4-1.5,2.9-3.1,4.6-4.1c2.1-1.2,4.2-2.5,6.8-2.8
	c4.1-0.4,8.2-0.5,12.1,1.1c5.6,2.3,9.7,6.1,11.5,12c0.5,1.4,1.1,2.8,1.5,4.3C261.9,62.5,262.1,64.1,262.4,65.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M231.7,100.3c0.1,0.3,0.3,0.6,0.4,0.9c-1.2,0.2-2.4,0.5-3.6,0.7c0,0.1,0,0.2,0,0.2c0.3,0,0.6-0.1,0.9-0.1
	c0.6,0.1,1.5,0.2,1.7,0.5c0.3,0.6,0.3,1.6,0,2.1c-0.9,1.3-2.1,2.5-3.2,3.8c-0.4-0.5-0.6-0.7-0.8-1c-0.7-0.8-1.4-1.6-2.1-2.4
	c-0.8-0.8-1.4-2.3-1-3c0.6-1,1.6-1,2.7-1C228.3,101.1,229.9,100.6,231.7,100.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }} d="M232.8,99.7c0.3-0.3,0.5-0.9,0.8-1c0.3-0.1,0.8,0.4,1.1,0.7c0.2,0.2,0.5,0.6,0.5,0.9c0,0.5-0.2,1-0.3,1.4
	c-0.4-0.1-1-0.1-1.3-0.4C233.2,100.9,233.1,100.3,232.8,99.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }} d="M172.5,36c0.2-1.6,0.4-4.2,1.1-6.8c0.7-2.3,1.7-4.5,3-6.4c1.8-2.7,3.9-5.1,6.7-7c2.8-1.8,5.8-2.6,9-3.3
	c4.6-0.9,8.7,0.8,12.7,2.5c2.8,1.2,4.9,3.3,6.5,6c1.8,3.2,2.6,6.6,3.1,10.2c0.8,6-1.1,11.2-3.7,16.4c-1,1.9-1.8,4-3,5.8
	c-2.9,4.2-6.7,7.4-11,10.1c-2,1.3-4.2,2.3-6.4,3.2c-0.7,0.3-2,0.1-2.7-0.4c-2-1.4-4-2.9-5.7-4.7c-2-2.2-3.9-4.6-5.5-7.2
	c-2-3.2-2.9-6.8-3.3-10.6C173.2,41.7,172.9,39.4,172.5,36z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }} d="M188.6,68.5c-0.6,0.5-1,0.8-1.4,1.1c0,0,0,0.1,0.1,0.1c0.8-0.2,1.6-0.4,2.3-0.6c0.1,0.1,0.2,0.3,0.2,0.4
	c-0.9,1.9-2,3.6-3.8,4.8c-0.2,0.1-0.5,0.1-0.7,0c-0.4-0.2-0.7-0.4-1-0.7c-1.2-1.2-2.2-2.1-0.1-3.6c1-0.7,2.1-1.2,3.1-1.7
	C187.7,68.2,188.1,68.4,188.6,68.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity23 ? this.state.opacity23 : 1}
              fill={this.state.color23 ? this.state.color23 : 'transparent'} onClick={() => {
          this.setState({ color23: color, opacity23: opacity });
        }} d="M140.1,22.5c5.1,0.1,9.7,2,14.4,4c3.7,1.5,5.4,4.6,7.4,7.5c4.1,6,4.3,12.6,2.1,19.3c-1.1,3.3-2.9,6.2-4.5,9.2
	c-2.5,4.7-6.4,8-10.7,10.8c-2.6,1.7-5.4,3-8.1,4.4c-1.5,0.8-3,1-4.5,0c-1.3-0.8-2.7-1.5-4-2.3c-3.2-2-5.7-4.7-8-7.6
	c-3.3-4.1-5.5-8.7-6-14c-0.3-3.2-1-6.3-0.1-9.5c0.8-3.1,1.2-6.3,2.8-9.1c2.7-4.9,6.3-8.7,11.6-10.7c0.8-0.3,1.6-0.7,2.5-1
	c1.7-0.5,3.4-0.9,5.1-1.3C140.1,22.3,140.1,22.4,140.1,22.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }} d="M140.8,83.4c0.9,0.8,1.9,1.7,2.9,2.5c0.1-0.1,0.1-0.1,0.2-0.2c-1.3-1.5-2.6-3-3.9-4.5c0.6-0.9,1.1-1,2.1-0.7
	c1.9,0.6,3.3,1.8,4.7,3.2c1.1,1.1,1.1,2.2-0.1,3.2c-0.9,0.7-1.9,1.4-2.8,2c-0.6,0.4-1.3,0.3-1.6-0.5
	C141.8,86.8,141.3,85.1,140.8,83.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1}
              fill={this.state.color25 ? this.state.color25 : 'transparent'} onClick={() => {
          this.setState({ color25: color, opacity25: opacity });
        }}
              d="M139.2,79.8c-0.4,0.5-0.6,0.9-0.9,1c-0.9,0.3-1.9-0.1-2.8-1.4C136.7,79.6,137.7,79.7,139.2,79.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1}
              fill={this.state.color26 ? this.state.color26 : 'transparent'} onClick={() => {
          this.setState({ color26: color, opacity26: opacity });
        }} d="M474,67.3c-1,0.3-1.8-0.2-2.4-1.1c-3.3-3.9-6.3-7.9-8-12.8c-0.6-1.5-1.4-3-1.9-4.5c-0.5-1.6-0.9-3.2-1-4.8
	c-0.3-3.3,0-6.6,1-9.8c0.3-0.9,0.7-1.8,1.3-2.7c1.7-2.6,3-5.4,5.5-7.4c3.3-2.6,7.2-3.5,11.3-3.8c5.9-0.3,10.4,2.3,14,6.7
	c1.5,1.8,2.3,4,2.8,6.4c1.4,6.9,0.3,13.5-3.4,19.4c-1.2,2-2.9,3.8-4.7,5.3c-2.9,2.5-6,4.9-9.1,7.2C477.5,66.9,476.1,67.2,474,67.3z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M465.1,74.1c-0.3-0.6-0.6-1.1-0.8-1.7c-0.3-0.7-0.2-1.3,0.6-1.8c1.7-1.1,3.5-0.6,5.2-0.7c0.9-0.1,1.6,1.6,1,2.4
	c-0.8,1.1-1.8,2.1-2.7,3.1c-0.5,0.6-1.2,0.5-1.8-0.1C466.1,74.9,465.5,74.5,465.1,74.1C465,74.1,465.1,74.1,465.1,74.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity28 ? this.state.opacity28 : 1}
              fill={this.state.color28 ? this.state.color28 : 'transparent'} onClick={() => {
          this.setState({ color28: color, opacity28: opacity });
        }} d="M473.5,70.2c-0.2,0.2-0.6,0.6-1.1,0.7c-0.7,0.1-1.8-1.1-1.6-1.9c0.1-0.4,0.6-0.9,1-1
	C472.6,67.9,473.6,69.1,473.5,70.2z" />
      </SvgIcon>
    )
  }
};