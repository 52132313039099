import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_28_2 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 190.3 287.7"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M102.5,256.2H78.4c-4.5,0-8.2,3.7-8.2,8.2v4.3c0,4.5,3.7,8.2,8.2,8.2h24.1c4.5,0,8.2-3.7,8.2-8.2v-4.3
	C110.6,259.9,107,256.2,102.5,256.2z M84.7,270.8h-6.8v-1.4l1.2-1.1c2.1-1.9,3.1-3,3.2-4.1c0-0.8-0.5-1.4-1.6-1.4
	c-0.8,0-1.6,0.4-2.1,0.8L78,262c0.7-0.5,1.9-1,3.1-1c2.2,0,3.4,1.3,3.4,3c0,1.6-1.2,2.9-2.6,4.2l-0.9,0.7v0h3.6V270.8z M93.2,266.3
	h-2.6v2.8h-1.5v-2.8h-2.6v-1.4h2.6v-2.7h1.5v2.7h2.6V266.3z M98.2,270.9c-1.2,0-2.3-0.3-2.9-0.7l0.5-1.7c0.4,0.2,1.3,0.6,2.2,0.6
	c1.2,0,1.8-0.6,1.8-1.3c0-1-0.9-1.4-1.9-1.4H97v-1.6h0.9c0.8,0,1.7-0.3,1.7-1.1c0-0.6-0.5-1-1.4-1c-0.8,0-1.6,0.3-2,0.6l-0.5-1.6
	c0.6-0.4,1.7-0.7,3-0.7c2,0,3.2,1.1,3.2,2.4c0,1-0.6,1.8-1.8,2.2v0c1.2,0.2,2.1,1.1,2.1,2.4C102.2,269.7,100.7,270.9,98.2,270.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M43.9,24.1H24c-3.5,0-6.3,2.8-6.3,6.3v4.1c0,3.5,2.8,6.3,6.3,6.3h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1
	C50.2,26.9,47.4,24.1,43.9,24.1z M27.8,29.1l-3.4,7.1h-1.1l3.4-6.9v0h-3.8v-0.9h4.9V29.1z M36.3,33.4h-2.7v2.8h-0.8v-2.8h-2.7v-0.7
	h2.7v-2.8h0.8v2.8h2.7V33.4z M41.9,36.2h-1v-6.8h0l-1.4,0.7l-0.2-0.8l1.7-0.9h0.9V36.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M65.1,32.5c-0.9,0.3-1.4,0.9-1.4,1.6c0,0.8,0.6,1.5,1.6,1.5c0.9,0,1.6-0.6,1.6-1.4
	C66.8,33.2,66.1,32.8,65.1,32.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M83.1,24.2H63.2c-3.5,0-6.3,2.8-6.3,6.3v4.1c0,3.5,2.8,6.3,6.3,6.3h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1
	C89.4,27,86.6,24.2,83.1,24.2z M65.2,36.3c-1.6,0-2.6-1-2.6-2.1c0-1,0.6-1.7,1.5-2.1l0,0c-0.9-0.4-1.2-1.1-1.2-1.8
	c0-1.2,1-2.1,2.4-2.1c1.5,0,2.3,0.9,2.3,1.9c0,0.7-0.3,1.4-1.3,1.8v0c1,0.4,1.6,1.1,1.6,2C67.9,35.4,66.7,36.3,65.2,36.3z
	 M76.3,33.4h-2.7v2.8h-0.8v-2.8h-2.7v-0.7h2.7v-2.8h0.8v2.8h2.7V33.4z M81.9,36.2h-1v-6.8h0l-1.4,0.7l-0.2-0.8l1.7-0.9h0.9V36.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M65.4,31.7c0.7-0.2,1.2-0.7,1.2-1.4c0-0.6-0.4-1.3-1.3-1.3c-0.9,0-1.4,0.6-1.4,1.2
	C63.9,31.1,64.5,31.5,65.4,31.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M123.6,24.2h-19.9c-3.5,0-6.3,2.8-6.3,6.3v4.1c0,3.5,2.8,6.3,6.3,6.3h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1
	C129.8,27,127,24.2,123.6,24.2z M108.5,29.1l-3.4,7.1H104l3.4-6.9v0h-3.8v-0.9h4.9V29.1z M117,33.4h-2.7v2.8h-0.8v-2.8h-2.7v-0.7
	h2.7v-2.8h0.8v2.8h2.7V33.4z M121.3,36.3c-0.9,0-1.6-0.3-2-0.5l0.3-0.8c0.3,0.2,1,0.5,1.7,0.5c1.3,0,1.8-0.9,1.8-1.5
	c0-1.1-1-1.6-2-1.6h-0.6v-0.8h0.6c0.8,0,1.7-0.4,1.7-1.3c0-0.6-0.4-1.2-1.4-1.2c-0.6,0-1.2,0.3-1.6,0.5l-0.3-0.8
	c0.4-0.3,1.2-0.6,2.1-0.6c1.5,0,2.2,0.9,2.2,1.9c0,0.8-0.5,1.5-1.4,1.8v0c1,0.2,1.7,0.9,1.7,2C124.1,35.2,123.2,36.3,121.3,36.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M142.7,40.8h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1c0-3.5-2.8-6.3-6.3-6.3h-19.9c-3.5,0-6.3,2.8-6.3,6.3v4.1
	C136.4,38,139.2,40.8,142.7,40.8z M159.4,29.5c0.6-0.6,1.5-1,2.5-1.2c0.3,0,0.6-0.1,0.8-0.1v0.9c-0.2,0-0.5,0-0.8,0.1
	c-1.7,0.3-2.5,1.5-2.7,2.8h0c0.4-0.5,1-0.9,1.9-0.9c1.4,0,2.4,1,2.4,2.5c0,1.4-1,2.8-2.6,2.8c-1.7,0-2.7-1.3-2.7-3.3
	C158.1,31.5,158.6,30.3,159.4,29.5z M149.7,32.6h2.7v-2.8h0.8v2.8h2.7v0.7h-2.7v2.8h-0.8v-2.8h-2.7V32.6z M142.4,35.6l0.8-0.8
	c2-1.9,2.9-2.9,2.9-4.1c0-0.8-0.4-1.5-1.5-1.5c-0.7,0-1.3,0.4-1.7,0.7l-0.3-0.7c0.5-0.4,1.3-0.8,2.2-0.8c1.7,0,2.4,1.2,2.4,2.3
	c0,1.4-1,2.6-2.7,4.2l-0.6,0.6v0h3.5v0.9h-5V35.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7: 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M160.9,35.5c0.9,0,1.5-0.8,1.5-1.9c0-1.1-0.6-1.8-1.6-1.8c-0.6,0-1.2,0.4-1.5,1c-0.1,0.1-0.1,0.3-0.1,0.5
	C159.1,34.6,159.7,35.5,160.9,35.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M162.8,47.1h-19.9c-3.5,0-6.3,2.8-6.3,6.3v4.1c0,3.5,2.8,6.3,6.3,6.3h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1
	C169.1,49.9,166.3,47.1,162.8,47.1z M146.5,59.2h-5v-0.6l0.8-0.8c2-1.9,2.9-2.9,2.9-4.1c0-0.8-0.4-1.5-1.5-1.5
	c-0.7,0-1.3,0.4-1.7,0.7l-0.3-0.7c0.5-0.4,1.3-0.8,2.2-0.8c1.7,0,2.4,1.2,2.4,2.3c0,1.4-1,2.6-2.7,4.2l-0.6,0.6v0h3.5V59.2z
	 M155.1,56.4h-2.7v2.8h-0.8v-2.8h-2.7v-0.7h2.7v-2.8h0.8v2.8h2.7V56.4z M162.5,52.1l-3.4,7.1H158l3.4-6.9v0h-3.8v-0.9h4.9V52.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M123.6,46.9h-19.9c-3.5,0-6.3,2.8-6.3,6.3v4.1c0,3.5,2.8,6.3,6.3,6.3h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1
	C129.8,49.7,127,46.9,123.6,46.9z M104.1,59.3c-0.9,0-1.6-0.2-1.9-0.5l0.3-0.8c0.3,0.2,1,0.4,1.7,0.4c1,0,1.8-0.6,1.8-1.7
	c0-1-0.7-1.7-2.2-1.7c-0.5,0-0.8,0-1.1,0.1l0.5-3.7h3.7v0.9h-3l-0.3,2c0.2,0,0.3,0,0.6,0c0.6,0,1.2,0.1,1.7,0.4c0.6,0.3,1.1,1,1.1,2
	C107,58.2,105.8,59.3,104.1,59.3z M115.8,56.4h-2.7v2.8h-0.8v-2.8h-2.7v-0.7h2.7v-2.8h0.8v2.8h2.7V56.4z M120,59.3
	c-0.9,0-1.6-0.3-2-0.5l0.3-0.8c0.3,0.2,1,0.5,1.7,0.5c1.3,0,1.8-0.9,1.8-1.5c0-1.1-1-1.6-2-1.6h-0.6v-0.8h0.6c0.8,0,1.7-0.4,1.7-1.3
	c0-0.6-0.4-1.2-1.4-1.2c-0.6,0-1.2,0.3-1.6,0.5l-0.3-0.8c0.4-0.3,1.2-0.6,2.1-0.6c1.5,0,2.2,0.9,2.2,1.9c0,0.8-0.5,1.5-1.4,1.8v0
	c1,0.2,1.7,0.9,1.7,2C122.9,58.2,121.9,59.3,120,59.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M83.1,47.1H63.2c-3.5,0-6.3,2.8-6.3,6.3v4.1c0,3.5,2.8,6.3,6.3,6.3h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1
	C89.4,49.9,86.6,47.1,83.1,47.1z M67.5,57.1h-1.1v2.1h-1v-2.1h-3.6v-0.7l3.5-5h1.1v4.8h1.1V57.1z M75.7,56.4H73v2.8h-0.8v-2.8h-2.7
	v-0.7h2.7v-2.8H73v2.8h2.7V56.4z M83.4,57.1h-1.1v2.1h-1v-2.1h-3.6v-0.7l3.5-5h1.1v4.8h1.1V57.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M64.8,53.6l-1.9,2.7v0h2.6v-2.6c0-0.4,0-0.8,0-1.2h0C65.2,52.9,65,53.2,64.8,53.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M80.6,53.6l-1.9,2.7v0h2.6v-2.6c0-0.4,0-0.8,0-1.2h0C81,52.9,80.8,53.2,80.6,53.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M41.5,53.6l-1.9,2.7v0h2.6v-2.6c0-0.4,0-0.8,0-1.2h0C41.9,52.9,41.7,53.2,41.5,53.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M43.9,47.1H24c-3.5,0-6.3,2.8-6.3,6.3v4.1c0,3.5,2.8,6.3,6.3,6.3h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1
	C50.2,49.9,47.4,47.1,43.9,47.1z M25,59.3c-0.9,0-1.6-0.2-1.9-0.5l0.3-0.8c0.3,0.2,1,0.4,1.7,0.4c1,0,1.8-0.6,1.8-1.7
	c0-1-0.7-1.7-2.2-1.7c-0.5,0-0.8,0-1.1,0.1l0.5-3.7h3.7v0.9h-3l-0.3,2c0.2,0,0.3,0,0.6,0c0.6,0,1.2,0.1,1.7,0.4c0.6,0.3,1.1,1,1.1,2
	C27.9,58.2,26.7,59.3,25,59.3z M36.6,56.4h-2.7v2.8h-0.8v-2.8h-2.7v-0.7h2.7v-2.8h0.8v2.8h2.7V56.4z M44.3,57.1h-1.1v2.1h-1v-2.1
	h-3.6v-0.7l3.5-5h1.1v4.8h1.1V57.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M43.9,71H24c-3.5,0-6.3,2.8-6.3,6.3v4.1c0,3.5,2.8,6.3,6.3,6.3h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1
	C50.2,73.8,47.4,71,43.9,71z M25.7,83.3c-1.7,0-2.7-1.3-2.7-3.3c0-1.5,0.5-2.7,1.3-3.5c0.6-0.6,1.5-1,2.5-1.2c0.3,0,0.6-0.1,0.8-0.1
	v0.9c-0.2,0-0.5,0-0.8,0.1C25,76.5,24.2,77.7,24,79h0c0.4-0.5,1-0.9,1.9-0.9c1.4,0,2.4,1,2.4,2.5C28.3,82,27.3,83.3,25.7,83.3z
	 M36.6,80.4h-2.7v2.8h-0.8v-2.8h-2.7v-0.7h2.7v-2.8h0.8v2.8h2.7V80.4z M40.9,83.3c-0.9,0-1.6-0.3-2-0.5l0.3-0.8
	c0.3,0.2,1,0.5,1.7,0.5c1.3,0,1.8-0.9,1.8-1.5c0-1.1-1-1.6-2-1.6H40v-0.8h0.6c0.8,0,1.7-0.4,1.7-1.3c0-0.6-0.4-1.2-1.4-1.2
	c-0.6,0-1.2,0.3-1.6,0.5l-0.3-0.8c0.4-0.3,1.2-0.6,2.1-0.6c1.5,0,2.2,0.9,2.2,1.9c0,0.8-0.5,1.5-1.4,1.8v0c1,0.2,1.7,0.9,1.7,2
	C43.7,82.2,42.8,83.3,40.9,83.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M25.6,78.9c-0.6,0-1.2,0.4-1.5,1C24,79.9,24,80.1,24,80.3c0,1.3,0.6,2.2,1.7,2.2c0.9,0,1.5-0.8,1.5-1.9
	C27.2,79.5,26.6,78.9,25.6,78.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M43.9,95.2H24c-3.5,0-6.3,2.8-6.3,6.3v4.1c0,3.5,2.8,6.3,6.3,6.3h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1
	C50.2,98,47.4,95.2,43.9,95.2z M25,106.8c-0.9,0-1.6-0.3-2-0.5l0.3-0.8c0.3,0.2,1,0.5,1.7,0.5c1.3,0,1.8-0.9,1.8-1.5
	c0-1.1-1-1.6-2-1.6h-0.6v-0.8h0.6c0.8,0,1.7-0.4,1.7-1.3c0-0.6-0.4-1.2-1.4-1.2c-0.6,0-1.2,0.3-1.6,0.5l-0.3-0.8
	c0.4-0.3,1.2-0.6,2.1-0.6c1.5,0,2.2,0.9,2.2,1.9c0,0.8-0.5,1.5-1.4,1.8v0c1,0.2,1.7,0.9,1.7,2C27.9,105.7,26.9,106.8,25,106.8z
	 M36.6,103.9h-2.7v2.8h-0.8v-2.8h-2.7v-0.7h2.7v-2.8h0.8v2.8h2.7V103.9z M40.9,106.8c-0.9,0-1.6-0.3-2-0.5l0.3-0.8
	c0.3,0.2,1,0.5,1.7,0.5c1.3,0,1.8-0.9,1.8-1.5c0-1.1-1-1.6-2-1.6H40v-0.8h0.6c0.8,0,1.7-0.4,1.7-1.3c0-0.6-0.4-1.2-1.4-1.2
	c-0.6,0-1.2,0.3-1.6,0.5l-0.3-0.8c0.4-0.3,1.2-0.6,2.1-0.6c1.5,0,2.2,0.9,2.2,1.9c0,0.8-0.5,1.5-1.4,1.8v0c1,0.2,1.7,0.9,1.7,2
	C43.7,105.7,42.8,106.8,40.9,106.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M83.1,95.2H63.2c-3.5,0-6.3,2.8-6.3,6.3v4.1c0,3.5,2.8,6.3,6.3,6.3h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1
	C89.4,98,86.6,95.2,83.1,95.2z M67.5,104.6h-1.1v2.1h-1v-2.1h-3.6v-0.7l3.5-5h1.1v4.8h1.1V104.6z M75.7,103.9H73v2.8h-0.8v-2.8h-2.7
	v-0.7h2.7v-2.8H73v2.8h2.7V103.9z M83,106.7h-5v-0.6l0.8-0.8c2-1.9,2.9-2.9,2.9-4.1c0-0.8-0.4-1.5-1.5-1.5c-0.7,0-1.3,0.4-1.7,0.7
	l-0.3-0.7c0.5-0.4,1.3-0.8,2.2-0.8c1.7,0,2.4,1.2,2.4,2.3c0,1.4-1,2.6-2.7,4.2l-0.6,0.6v0H83V106.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M64.8,101.1l-1.9,2.7v0h2.6v-2.6c0-0.4,0-0.8,0-1.2h0C65.2,100.4,65,100.7,64.8,101.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }} d="M83.1,71H63.2c-3.5,0-6.3,2.8-6.3,6.3v4.1c0,3.5,2.8,6.3,6.3,6.3h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1
	C89.4,73.8,86.6,71,83.1,71z M64.8,82.9h-1v-6.8h0l-1.4,0.7L62.2,76l1.7-0.9h0.9V82.9z M75,80.1h-2.7v2.8h-0.8v-2.8h-2.7v-0.7h2.7
	v-2.8h0.8v2.8H75V80.1z M79.2,83c-0.9,0-1.6-0.2-1.9-0.5l0.3-0.8c0.3,0.2,1,0.4,1.7,0.4c1,0,1.8-0.6,1.8-1.7c0-1-0.7-1.7-2.2-1.7
	c-0.5,0-0.8,0-1.1,0.1l0.5-3.7H82V76h-3l-0.3,2c0.2,0,0.3,0,0.6,0c0.6,0,1.2,0.1,1.7,0.4c0.6,0.3,1.1,1,1.1,2
	C82.1,81.9,80.9,83,79.2,83z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }} d="M123.5,94.9h-19.9c-3.5,0-6.3,2.8-6.3,6.3v4.1c0,3.5,2.8,6.3,6.3,6.3h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1
	C129.8,97.7,127,94.9,123.5,94.9z M105,106.8c-0.9,0-1.6-0.3-2-0.5l0.3-0.8c0.3,0.2,1,0.5,1.7,0.5c1.3,0,1.8-0.9,1.8-1.5
	c0-1.1-1-1.6-2-1.6h-0.6v-0.8h0.6c0.8,0,1.7-0.4,1.7-1.3c0-0.6-0.4-1.2-1.4-1.2c-0.6,0-1.2,0.3-1.6,0.5l-0.3-0.8
	c0.4-0.3,1.2-0.6,2.1-0.6c1.5,0,2.2,0.9,2.2,1.9c0,0.8-0.5,1.5-1.4,1.8v0c1,0.2,1.7,0.9,1.7,2C107.9,105.7,106.9,106.8,105,106.8z
	 M116.2,103.9h-2.7v2.8h-0.8v-2.8H110v-0.7h2.7v-2.8h0.8v2.8h2.7V103.9z M123.8,104.6h-1.1v2.1h-1v-2.1h-3.6v-0.7l3.5-5h1.1v4.8h1.1
	V104.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }}
              d="M121.1,101.1l-1.9,2.7v0h2.6v-2.6c0-0.4,0-0.8,0-1.2h0C121.5,100.4,121.3,100.7,121.1,101.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }} d="M162.5,94.9h-19.9c-3.5,0-6.3,2.8-6.3,6.3v4.1c0,3.5,2.8,6.3,6.3,6.3h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1
	C168.8,97.7,166,94.9,162.5,94.9z M144.2,106.8c-1.7,0-2.7-1.3-2.7-3.3c0-1.5,0.5-2.7,1.3-3.5c0.6-0.6,1.5-1,2.5-1.2
	c0.3,0,0.6-0.1,0.8-0.1v0.9c-0.2,0-0.5,0-0.8,0.1c-1.7,0.3-2.5,1.5-2.7,2.8h0c0.4-0.5,1-0.9,1.9-0.9c1.4,0,2.4,1,2.4,2.5
	C146.8,105.5,145.8,106.8,144.2,106.8z M155.1,103.9h-2.7v2.8h-0.8v-2.8h-2.7v-0.7h2.7v-2.8h0.8v2.8h2.7V103.9z M159.4,106.8
	c-0.9,0-1.6-0.3-2-0.5l0.3-0.8c0.3,0.2,1,0.5,1.7,0.5c1.3,0,1.8-0.9,1.8-1.5c0-1.1-1-1.6-2-1.6h-0.6v-0.8h0.6c0.8,0,1.7-0.4,1.7-1.3
	c0-0.6-0.4-1.2-1.4-1.2c-0.6,0-1.2,0.3-1.6,0.5l-0.3-0.8c0.4-0.3,1.2-0.6,2.1-0.6c1.5,0,2.2,0.9,2.2,1.9c0,0.8-0.5,1.5-1.4,1.8v0
	c1,0.2,1.7,0.9,1.7,2C162.2,105.7,161.2,106.8,159.4,106.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }} d="M144.1,102.4c-0.6,0-1.2,0.4-1.5,1c-0.1,0.1-0.1,0.3-0.1,0.5c0,1.3,0.6,2.2,1.7,2.2c0.9,0,1.5-0.8,1.5-1.9
	C145.7,103,145.1,102.4,144.1,102.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1}
              fill={this.state.color26 ? this.state.color26 : 'transparent'} onClick={() => {
          this.setState({ color26: color, opacity26: opacity });
        }} d="M162.5,71h-19.9c-3.5,0-6.3,2.8-6.3,6.3v4.1c0,3.5,2.8,6.3,6.3,6.3h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1
	C168.8,73.8,166,71,162.5,71z M144.9,82.9h-1v-6.8h0l-1.4,0.7l-0.2-0.8l1.7-0.9h0.9V82.9z M155.1,80.1h-2.7v2.8h-0.8v-2.8h-2.7v-0.7
	h2.7v-2.8h0.8v2.8h2.7V80.1z M159.3,83c-0.9,0-1.6-0.2-1.9-0.5l0.3-0.8c0.3,0.2,1,0.4,1.7,0.4c1,0,1.8-0.6,1.8-1.7
	c0-1-0.7-1.7-2.2-1.7c-0.5,0-0.8,0-1.1,0.1l0.5-3.7h3.7V76h-3l-0.3,2c0.2,0,0.3,0,0.6,0c0.6,0,1.2,0.1,1.7,0.4c0.6,0.3,1.1,1,1.1,2
	C162.2,81.9,161,83,159.3,83z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M120.6,75.8c-0.9,0-1.6,1.1-1.6,3.3c0,2,0.6,3.2,1.6,3.2c1.1,0,1.6-1.3,1.6-3.3
	C122.2,77.1,121.7,75.8,120.6,75.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M123.6,71h-19.9c-3.5,0-6.3,2.8-6.3,6.3v4.1c0,3.5,2.8,6.3,6.3,6.3h19.9c3.5,0,6.3-2.8,6.3-6.3v-4.1
	C129.8,73.8,127,71,123.6,71z M104.8,83c-1.7,0-2.7-1.3-2.7-3.3c0-1.5,0.5-2.7,1.3-3.5c0.6-0.6,1.5-1,2.5-1.2c0.3,0,0.6-0.1,0.8-0.1
	v0.9c-0.2,0-0.5,0-0.8,0.1c-1.7,0.3-2.5,1.5-2.7,2.8h0c0.4-0.5,1-0.9,1.9-0.9c1.4,0,2.4,1,2.4,2.5C107.4,81.7,106.5,83,104.8,83z
	 M115.8,80.1h-2.7v2.8h-0.8v-2.8h-2.7v-0.7h2.7v-2.8h0.8v2.8h2.7V80.1z M120.5,83c-1.5,0-2.6-1.4-2.6-4c0-2.6,1.1-4.1,2.7-4.1
	c1.6,0,2.6,1.5,2.6,3.9C123.3,81.6,122.3,83,120.5,83z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M104.7,78.6c-0.6,0-1.2,0.4-1.5,1c-0.1,0.1-0.1,0.3-0.1,0.5c0,1.3,0.6,2.2,1.7,2.2c0.9,0,1.5-0.8,1.5-1.9
	C106.4,79.2,105.7,78.6,104.7,78.6z" />
      </SvgIcon>
    )
  }
};