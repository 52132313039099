import React, { Component } from "react";
import { SvgIcon } from "@material-ui/core";

export default class Redo extends Component<any, any> {

  render() {
    const st0 = "#064B5E";
    const { disabled } = this.props;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"-333.5 535.1 82.6 34.1"}
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        <g id="XMLID_388_">
          <path id="XMLID_217_" fill={st0} d="M-251.1,552.7c-2.3-3-4.6-6-6.8-9c-2.2-2.9-4.3-6.1-7-8.5c-0.3-0.3-0.8-0.1-1.1,0.4
		c-0.6,1.6-1.4,3-2.2,4.5c-4-2.7-9.3-3.7-14.2-4c-17.7-0.9-37.3,10.9-49.8,30.6c-0.4,0.5-0.7,1-1,1.6c-0.1,0.1-0.1,0.2-0.2,0.3
		c-0.2,0.4,0.1,0.8,0.4,0.4c0-0.1,0.1-0.2,0.2-0.2c2-2.8,4.8-4.8,7.2-7.1c2.3-2.2,4.6-4.3,7.1-6.1c4.8-3.6,9.8-6.4,14.9-8.2
		c5.3-1.9,10.7-2.8,15.8-2.6c5.3,0.2,9.9,1.9,14.7,3.6c-1,2.1-2,4.3-2.9,6.4c-0.2,0.5-0.1,1.1,0.4,1.1c7.9-0.1,15.8-0.8,23.8-1.5
		C-251.1,554.2-250.6,553.3-251.1,552.7z" />
        </g>
      </SvgIcon>
    )
  }
};
