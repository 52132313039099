import  { Component } from "react";
import _ from "lodash";

export default class SVG extends Component<any, any> {
  state: any = null;
  firstLoad: boolean = false;

  constructor(props) {
    super(props);
    this.state = { ...props.value };
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (prevProps.value !== this.props.value) {
      this.setState({ ...this.props.value }, () => {
        this.firstLoad = true;
      });
    }
    if (this.firstLoad && !_.isEqual(prevState, this.state)) {
      this.props.onPartClick({ ...this.state });
    }
  }
}