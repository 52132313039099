import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_9_1 extends SVG {

  render() {
    let { color, opacity = false } = this.props;
    const borderColor = '#6B6D6D';
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 405.4 181.9"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M220.8,118.8c0,10.4,0,20.6,0,30.9c-9.2,1.3-18.3,2.5-27.7,3.8c0-37.8,0-75.6,0-113.5c32.7-3,65.5-6,98.5-9
	c0,0.8,0,1.5,0,2.2c0,6.9,0,13.8,0,20.6c0,0.6-0.3,1.4-0.7,1.9c-1.8,2.2-3.8,4.3-5.9,6.7c2.3,0.5,4.4,1,6.6,1.4c0,0.3,0.1,0.6,0.1,1
	c0,13.2,0.1,26.5,0.1,39.7c0,11.4,0,22.8,0.1,34.2c0,1-0.2,1.3-1.3,1.5c-4.4,0.5-8.8,1.2-13.3,1.8c-0.6,0.1-1.2,0.1-1.9,0.2
	c0-0.5-0.1-0.9-0.1-1.3c0-6.8-0.1-13.7-0.1-20.5c0-1.1-0.4-1.5-1.4-1.7c-2.9-0.6-5.8-1.2-8.6-1.9c-0.5-0.1-1.1-0.6-1.4-1.1
	c-5.6-9.7-11.2-19.5-16.9-29.3c-0.2-0.3-0.4-0.7-0.7-1.2c-1.1,2.1-2.1,4.1-3.2,6.2c-4.2,8.3-8.5,16.6-12.7,24.9
	c-0.5,0.9-1.1,1.2-2,1.4C225.9,118,223.4,118.4,220.8,118.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M355.1,62.8c0,0.5,0,0.9,0,1.4c0,19.4,0,38.9,0,58.3c0,0.7-0.1,1.5-0.4,2.2c-2.1,5.9-2.1,11.8,0,17.7
	c0.3,0.7,0.4,1.5,0.4,2.3c0,2.9-0.1,5.8,0,8.8c0.1,3-1.1,5.2-3.4,7c-3.3,2.6-7.1,3.9-11.1,4.9c-7,1.8-14,2-21.2,1.5
	c-5.2-0.4-10.3-1.4-15.1-3.4c-2.4-1-4.6-2.2-6.4-4.1c-1.4-1.5-2.2-3.2-2.2-5.3c0-29.3,0-58.6,0-87.9c0-0.5,0-0.9,0-1.6
	C315.7,66.6,335.4,67,355.1,62.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M192.6,40.1c0,37.8,0,75.5,0,113.3c-1.7-0.5-3.4-1-5.1-1.5c-11.3-3.3-22.5-6.6-33.8-9.8
	c-0.8-0.2-1.1-0.5-1.1-1.4c0-13.3-0.1-26.6-0.1-39.9c0-9.4,0-18.7,0-28.1c0-12.1,0-24.2-0.1-36.3c0-0.4,0-0.9,0-1.5
	C165.9,36.6,179.2,38.3,192.6,40.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M378.9,108.2c14.1,0,25.4,11.4,25.3,25.7c-0.1,13.6-11,25-25.4,25c-14.4,0-25.5-11.7-25.3-25.7
	C353.7,119.1,365.2,108.2,378.9,108.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M363.4,59.6c-3.1,1-5.8,2.1-8.6,2.8c-5.6,1.5-11.4,2.2-17.2,2.7c-7.4,0.6-14.8,0.8-22.1,0.6
	c-9.4-0.3-18.9-1-28.1-3c-0.5-0.1-1-0.2-1.7-0.4c12.5-14.1,24.8-28,37.3-42.1C336.3,33.3,349.7,46.3,363.4,59.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M248.3,155.2c0-10.9,0-21.7,0-32.6c8.7-1.1,17.6-2.2,26.7-3.4c0,2.1,0,4.1,0,6c0,7.2,0.1,14.5,0.1,21.7
	c0,0,0,0.1,0,0.1c0,0.9,0.3,2.1-0.1,2.6c-0.5,0.6-1.7,0.6-2.5,0.7c-7.8,1.6-15.7,3.2-23.5,4.8C248.8,155.3,248.7,155.3,248.3,155.2z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M247.7,155.2c-8.8-1.8-17.7-3.7-26.6-5.5c0-10.2,0-20.2,0-30.5c8.8,1.1,17.6,2.1,26.6,3.2
	C247.7,133.4,247.7,144.2,247.7,155.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity33 ? this.state.opacity33 : 1}
              fill={this.state.color33 ? this.state.color33 : 'transparent'} onClick={() => {
          this.setState({ color33: color, opacity33: opacity });
        }}
              d="M229.7,118.3c5.6-10.9,11-21.6,16.6-32.5c6.2,10.8,12.3,21.5,18.6,32.3C253.1,120,241.5,119.9,229.7,118.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M288.8,31c-1.3,0.1-2.5,0.3-3.8,0.4c-5.8,0.5-11.5,1-17.3,1.5c-7.4,0.7-14.8,1.4-22.3,2.1
	c-5.8,0.5-11.5,1-17.3,1.5c-6.6,0.6-13.1,1.2-19.6,1.9c-5.2,0.5-10.3,1-15.5,1.3c-2,0.1-4.1-0.4-6.2-0.7c-4-0.5-8.1-1-12.1-1.6
	c-4.7-0.6-9.3-1.2-14-1.8c-1.5-0.2-2.9-0.4-4.4-0.6c-0.4-0.1-0.8-0.2-1.3-0.5c10.8-1,21.6-2,32.3-3c0.1,0.1,0.2,0.2,0.2,0.3
	c-0.1,1.4,0.6,1.7,1.9,1.9c7,0.9,14,2,21,2.9c1.9,0.2,3.9-0.2,5.8-0.4c4-0.4,8-0.7,12-1.1c4.1-0.4,8.1-0.7,12.2-1.1
	c1.2-0.1,2.4-0.2,3.6-0.3c0-2.5,0-4.9,0-7.3c3.2-0.4,6.2-0.8,9.3-0.3c5.2,0.8,10.3,1.4,15.5,2.1c5.1,0.7,10.2,1.4,15.3,2.1
	c1.5,0.2,3,0.4,4.5,0.6C288.9,30.8,288.9,30.9,288.8,31z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M243.9,33.3c-10.9,1-21.8,2-32.8,3c-0.1-0.3-0.1-0.4-0.1-0.6c-0.1-4-0.2-7.9-0.3-11.9c0-1,0.4-1.2,1.2-1.3
	c4.4-0.3,8.9-0.6,13.3-1c5.3-0.4,10.5-0.9,15.8-1.4c0.9-0.1,1.8,0,2.8,0C243.9,24.5,243.9,28.8,243.9,33.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M188.1,33.1c0-4.5,0-8.9,0-13.3c3.1,0.4,6.1,0.8,9.2,1.2c3.8,0.5,7.6,1.1,11.5,1.5c1.1,0.1,1.6,0.3,1.6,1.6
	c0,3.8,0.3,7.6,0.4,11.5c0,0.2-0.1,0.4-0.1,0.7C203.1,35.2,195.7,34.2,188.1,33.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity35 ? this.state.opacity35 : 1}
              fill={this.state.color35 ? this.state.color35 : 'transparent'} onClick={() => {
          this.setState({ color35: color, opacity35: opacity });
        }}
              d="M213.9,21c0.3-6.5,0.6-12.7,0.9-19.3c5.5,6,10.7,11.8,16.2,17.8C225.1,20.1,219.6,20.5,213.9,21z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity35 ? this.state.opacity35 : 1}
              fill={this.state.color35 ? this.state.color35 : 'transparent'} onClick={() => {
          this.setState({ color35: color, opacity35: opacity });
        }} d="M213.6,21c-4.3-0.5-8.5-1-12.9-1.5c4.6-5.8,9-11.4,13.4-17c0.1,0,0.2,0.1,0.4,0.1
	C214.1,8.6,213.8,14.7,213.6,21z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M273.1,119.1c-1.7,0.2-3.4,0.5-5.1,0.7c-5.1,0.6-10.3,1.1-15.4,1.9c-3.8,0.6-7.5,0.1-11.3-0.3
	c-4.2-0.5-8.5-1.1-12.7-1.6c-1.9-0.2-3.7-0.5-5.6-1c1.4-0.2,2.8-0.5,4.2-0.7c0.4-0.1,0.8-0.1,1.2-0.2c0.2,0,0.6-0.1,0.7,0
	c1.6,1.7,3.7,1.2,5.6,1.3c5.9,0.4,11.7,0.6,17.6,0.5c4.2-0.1,8.5-0.7,12.8-1.1c-0.1-0.6-0.2-1-0.3-1.6c2.8,0.6,5.5,1.2,8.2,1.8
	C273.1,118.9,273.1,119,273.1,119.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M231.5,19.8c-0.5-0.6-0.8-1.1-1.3-1.8c3.8,0.6,7.4,1.1,10.9,1.6c0,0.1,0,0.2,0,0.3c-2.9,0.2-5.7,0.5-8.6,0.7
	c-4.6,0.4-9.2,0.8-13.8,1.1c-2.3,0.2-4.5,0.5-6.8,0.5c-2.6,0-5.2-0.4-7.8-0.7c-3.9-0.5-7.9-1.1-11.8-1.7c-0.3,0-0.7-0.1-1-0.4
	c3-0.2,6.1-0.5,9.2-0.7c0,0.4-0.1,0.6-0.1,1c2.4,0.3,4.7,0.6,7,0.9c1.8,0.2,3.6,0.5,5.5,0.6c1.5,0.1,3,0,4.5-0.2
	C222,20.7,226.6,20.2,231.5,19.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M47.3,92.3c17.1,1.7,34.1,3.4,51,5.1c0,18.4,0,36.7,0,55c-4.4-0.9-8.7-1.7-13-2.5c-1.1-0.2-2.1-0.4-3.2-0.6
	c-2.1-0.4-3.9-1-5.6-2.4c-5.6-4.7-12-5.5-18.9-3.1c-0.7,0.2-1.5,0.3-2.3,0.2c-2.4-0.4-4.7-0.9-7-1.3c-0.9-0.1-1.1-0.5-1.1-1.3
	c0-4.4,0-8.7,0-13.1c0-11.5,0-23,0-34.4C47.3,93.4,47.3,93,47.3,92.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M133,121c-2.5,3.8-4.8,7.3-7.1,10.9c-3.6,5.4-7.1,10.9-10.7,16.3c-0.3,0.4-0.7,0.9-1.2,1
	c-5,1.1-10,2.1-15.2,3.1c0-18.4,0-36.6,0-55c17-2.1,34.2-4.1,51.4-6.2c0,16.9,0,33.8,0,50.8c-0.9,0.2-1.8,0.5-2.8,0.5
	c-0.4,0-0.9-0.4-1.1-0.8c-4.2-6.5-8.4-13.1-12.6-19.7C133.5,121.8,133.3,121.5,133,121z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M133.4,122.2c10.6,16.6,21.2,33.2,31.9,49.9c-12.1,2.9-24.1,5.7-36.2,8.5c1.3-19.6,2.6-39,4-58.4
	C133.2,122.3,133.3,122.3,133.4,122.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M82.9,161.1c0,10.1-8.2,18.2-18.3,18.2c-9.9,0-18.1-8.2-18.1-18.2c-0.1-10,8.2-18.4,18.2-18.3
	C75.5,142.9,83.1,151.8,82.9,161.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M138.6,68c-14.6,1.9-29.3,3.7-44.2,5.6c-0.2-6.2-0.3-12.2-0.5-18.4c14.9-1.7,29.7-3.4,44.7-5.2
	C138.6,56.1,138.6,61.9,138.6,68z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M128.8,180.6c-8.4-4.8-16.7-9.5-25-14.3c9.6-14.7,19.1-29.3,28.6-43.8c0.1,0,0.2,0.1,0.4,0.1
	C131.5,141.9,130.2,161.1,128.8,180.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M93.6,55.2c0.2,6.1,0.4,12.1,0.5,18.4c-2-0.2-3.9-0.4-5.8-0.6c-4.4-0.5-8.8-1.1-13.2-1.6
	c-3.4-0.4-6.7-0.8-10.1-1.2c-0.3,0-0.7-0.5-0.7-0.8c-0.1-0.9-0.1-1.8-0.1-2.6c-0.1-4.2-0.2-8.5-0.3-12.7c0-0.4,0-0.8,0-1.3
	C73.9,53.5,83.7,54.3,93.6,55.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M99.4,92.7c0-2.5,0-4.9,0-7.2c-0.1-3.6-0.1-7.3-0.2-10.9c0-1,0.2-1.3,1.3-1.5c5.3-0.6,10.7-1.4,16-2.1
	c0.9-0.1,1.7-0.2,2.8-0.3c0.2,6.4,0.4,12.8,0.6,19.3C113,90.9,106.4,91.8,99.4,92.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M147.4,91.2c-1.8,0.2-3.7,0.5-5.5,0.7c-4.4,0.5-8.9,1.1-13.3,1.6c-5,0.6-10.1,1.2-15.1,1.8
	c-4.3,0.5-8.6,1.2-12.9,1.6c-2,0.2-4.1-0.1-6.2-0.2c-3.8-0.3-7.5-0.7-11.3-1.1c-4.5-0.5-9-0.9-13.6-1.4c-3.5-0.4-7.1-0.7-10.6-1
	c-2.6-0.3-5.2-0.5-7.9-0.8c-0.3,0-0.5-0.2-0.8-0.4c9.9-1.2,19.9-2.4,30-3.6c0.1,0.8,0.1,1.5,0.2,2.3c1.8,0.2,3.7,0.5,5.6,0.7
	c4.3,0.5,8.5,1.1,12.8,1.5c1.4,0.1,2.8-0.1,4.1-0.3c5.4-0.7,10.7-1.4,16.1-2.1c0.7-0.1,0.9-0.3,0.9-1c0-0.4,0-0.7,0-1.3
	c9.2,0.9,18.3,1.8,27.4,2.7C147.4,91,147.4,91.1,147.4,91.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M99.5,52.4c0.3-13.6,0.5-26.9,0.8-40.2c0.1,0,0.2-0.1,0.3-0.1c5.3,12.8,10.5,25.6,15.8,38.5
	C110.8,51.2,105.3,51.8,99.5,52.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M80.2,72.2c3.2,0.4,6.3,0.9,9.3,1.1c3,0.3,6.1,0.3,9.4,0.4c0,6.1,0,12.4,0,18.8c-2-0.2-4.2-0.4-6.3-0.7
	c-3.7-0.4-7.3-1-11-1.4c-0.8-0.1-1-0.3-1-1.1c-0.1-5-0.2-10-0.3-15C80.3,73.8,80.3,73.1,80.2,72.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M99.2,52.4c-5-0.4-9.9-0.9-14.9-1.3C89.4,38,94.5,25,99.6,12c0.1,0,0.2,0,0.4,0.1
	C99.7,25.5,99.5,38.9,99.2,52.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M116.9,50.8c-0.4-1.2-0.9-2.2-1.4-3.6c7,0.9,13.8,1.7,20.5,2.5c0,0.1,0,0.2,0,0.3c-1,0.1-2,0.3-3,0.4
	c-7.3,0.8-14.6,1.6-21.9,2.5c-5.6,0.6-11.2,1.4-16.9,1.9c-2,0.2-4-0.2-6.1-0.3c-3.3-0.3-6.6-0.6-9.9-0.9c-3.9-0.3-7.8-0.7-11.6-1
	c0-0.1,0-0.3,0-0.4c5.9-1,11.9-2,18-3c-0.3,0.9-0.5,1.5-0.7,2.3c1.3,0.1,2.5,0.3,3.8,0.4c3.8,0.3,7.6,0.8,11.4,0.9
	c2.3,0.1,4.6-0.4,6.9-0.7c3.4-0.4,6.9-0.8,10.3-1.2C116.5,50.9,116.7,50.8,116.9,50.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity29 ? this.state.opacity29 : 1}
              fill={this.state.color29 ? this.state.color29 : 'transparent'} onClick={() => {
          this.setState({ color29: color, opacity29: opacity });
        }} d="M45,98.1c-14.3,4-28.6,4-43.1,1C2,98.7,2.1,98.3,2.3,98c6.7-14.7,13.5-29.3,20.2-44c0.1-0.2,0.1-0.4,0.3-0.5
	c0.2-0.2,0.4-0.4,0.6-0.6c0.2,0.2,0.5,0.4,0.6,0.6c1.6,3.5,3.3,6.9,4.9,10.4C34.1,74.9,39.3,86,44.6,97.1
	C44.7,97.4,44.8,97.7,45,98.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M45.1,98.7c0,0.5,0,0.9,0,1.3c0,13.7,0,27.4,0,41.1c0,1-0.3,1.4-1.2,1.6c-6.8,1.8-13.5,3.7-20.4,5.6
	c0-0.5-0.1-0.9-0.1-1.3c0-14,0-27.9,0-41.9c0-1,0.3-1.4,1.3-1.6c6.6-1.6,13.2-3.2,19.8-4.8C44.7,98.7,44.8,98.7,45.1,98.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M23,148.3c-7.2-1.9-14.2-3.7-21.3-5.6c0-14.4,0-28.7,0-43.2c1.7,0.3,3.4,0.7,5.1,1c5.2,1,10.3,2.1,15.5,3.1
	c0.7,0.1,0.8,0.4,0.8,1c0,14.4,0,28.7,0,43.1C23.1,147.9,23,148,23,148.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1}
              fill={this.state.color32 ? this.state.color32 : 'transparent'} onClick={() => {
          this.setState({ color32: color, opacity32: opacity });
        }} d="M23.2,52.4c-4.6,0-8.3-3.7-8.4-8.2c0-4.6,3.7-8.4,8.3-8.4c4.5,0,8.3,3.8,8.3,8.3C31.5,48.7,27.8,52.3,23.2,52.4
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M33.5,101c-3.3,0.8-6.5,1.8-9.8,2.3c-1.3,0.2-2.8-0.4-4.2-0.6c-2.3-0.5-4.6-0.9-6.9-1.5
	C19.6,101.7,26.5,101.7,33.5,101z" />
      </SvgIcon>
    )
  }
};