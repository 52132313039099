import React, {Component} from 'react';
import {Stage, Layer, Image, Line, Rect, Text} from "react-konva";
import URLImage from "./URLImage";
import _ from 'lodash';

export default class KonvaStage extends Component<any> {
  width = 1032;
  height = 1600;
  state = {
    lines: [],
    hRect: 30
  }
  myRef;

  constructor (props) {
    super (props);
    this.myRef = React.createRef();
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.action === 'line') {
      this.getImageDataArray ();
    } else if (this.props.action === 'shape') {
      this.getShapes ();
    } else if ( ! _.isEqual (this.props.remoteFields, prevProps.remoteFields)) {
      this.setState ({
        lines: this.props.remoteFields
      })
    }
    if (this.props.onChange) {
      this.props.onChange ([...this.state.lines]);
    }
  }

  isColorRight = (item) => {
    const isColorRight = Math.abs (item.r - item.g) < 5 && Math.abs (item.r - item.b) < 5 && Math.abs (item.g - item.b) < 5;
    return true;
  }

  getPixels = (isTablePixels?) => {
    const data = this.myRef.current.children[0].canvas.context.getImageData (0, 0, this.width, this.height).data;
    let counter = 0;
    const pixels = [];
    const tablePixels = [[]];
    let row = 0;
    let column = 0;
    let tableColumn = 0;
    let pixel;
    data.forEach ((item, index) => {
      if (counter === 3) {
        pixel = {
          r: data[index - 3],
          g: data[index - 2],
          b: data[index - 1],
          x: column,
          y: row,
          white: data[index - 3] === 255 && data[index - 2] === 255 && data[index - 1] === 255
        }
        pixels.push (pixel);
        tablePixels[tableColumn].push (pixel);
        counter = 0;
        column ++;
        if (column === this.width) {
          tablePixels.push ([]);
          tableColumn ++;
          column = 0;
          row ++;
        }
      } else {
        counter ++;
      }
    })
    return isTablePixels ? tablePixels : pixels;
  }

  getHLines = (pixels) => {
    const {points, minSizeLine} = this.props;
    const top = points.length === 2 ? points[0].y : 0;
    const bottom = points.length === 2 ? points[1].y : this.height;
    const left = points.length === 2 ? points[0].x : 0;
    const right = points.length === 2 ? points[1].x : this.width;
    const hLines = [];
    let line = [];
    let color;
    let row = 0;
    pixels.forEach ((item, index) => {
      if (item.x >= left && item.x <= right && item.y >= top && item.y <= bottom) {
        const isColorRight = this.isColorRight (item);
        if ( ! item.white && ! line.length && isColorRight) {
          line.push (item.x);
          line.push (item.y);
          color = `${item.r}_${item.g}_${item.b}`;
          row = item.y;
        } else if ( ! item.white && line.length && row === item.y && color === `${item.r}_${item.g}_${item.b}`) {

        } else if (line.length) {
          if (item.white || (row !== item.y || color !== `${item.r}_${item.g}_${item.b}`)) {
            if (item.x - line[0] > minSizeLine) {
              line.push (item.x, row);
              hLines.push ([...line]);
            }
            line = null;
            line = [];
            if ( ! item.white) {
              line.push (item.x);
              line.push (item.y);
              color = `${item.r}_${item.g}_${item.b}`;
              row = item.y;
            }
          }
        }
      }
    })
    const filterHLines = this.getFilterGroupHLines (this.getGroupHLines ([...hLines]));
    return filterHLines;
  }

  getImageDataArray = () => {
    const {lines, hRect} = this.state;
    const pixels = this.getPixels();
    const filterHLines = this.getHLines (pixels);
    const hLines = filterHLines.map ((item) => {
      return {
        x: item[0],
        y: item[1] - hRect,
        width: item[2] - item[0],
        height: hRect,
        result: '',
        resultCorrection: {
          size: 's',
          pos: this.props.resultPos === 'default' ? undefined : this.props.resultPos
        },
        value: '',
        textAlign: 'left',
        stageWidth: this.width
      }
    })
    this.setState ({
      lines: [...lines, ...hLines]
    })
  }

  getGroupHLines = (hLines, newHLines = [], groupLines = []) => {
    if (hLines.length) {
      newHLines.push ([...hLines[0]]);
      for (let i = 1 ; i < hLines.length ; i ++) {
        if (Math.abs (hLines[0][0] - hLines[i][0]) < 3 && Math.abs (hLines[0][2] - hLines[i][2]) < 3 && Math.abs (hLines[i][1] - hLines[0][1]) <= 10) {
          newHLines.push ([...hLines[i]]);
          hLines.splice (i, 1);
          i --;
        }
      }
      hLines.splice (0, 1);
      groupLines.push ([...newHLines]);
      if (hLines.length > 0) {
        groupLines = this.getGroupHLines (hLines, [], groupLines);
      }
    }
    return groupLines;
  }

  getFilterGroupHLines = (groupHLines) => {
    const filterHLines = [];
    groupHLines.forEach ((item, index) => {
      if (item.length < 6) {
        const num = parseInt (String(item.length / 2));
        filterHLines.push (item[num]);
      }
    })
    return filterHLines;
  }


  getEdgePoints = (x, y, pixels, whiteFill = true) => {
    let pixel = null;
    let top = y - 1;
    while ( ! pixel) {
      if (whiteFill) {
        if ( ! pixels[top][x].white) {
          pixel = pixels[top][x];
        } else {
          top --;
        }
      } else {
        if (pixels[top][x].white) {
          pixel = pixels[top][x];
        } else {
          top --;
        }
      }
    }
    if (!whiteFill) {
      top += 2;
    }
    pixel = null;
    let bottom = y + 1;
    while ( ! pixel) {
      if (whiteFill) {
        if ( ! pixels[bottom][x].white) {
          pixel = pixels[bottom][x];
        } else {
          bottom ++;
        }
      } else {
        if (pixels[bottom][x].white) {
          pixel = pixels[bottom][x];
        } else {
          bottom ++;
        }
      }
    }
    if (!whiteFill) {
      bottom -= 2;
    }
    pixel = null;
    let left = x - 1;
    while ( ! pixel) {
      if (whiteFill) {
        if ( ! pixels[y][left].white) {
          pixel = pixels[y][left];
        } else {
          left --;
        }
      } else {
        if (pixels[y][left].white) {
          pixel = pixels[y][left];
        } else {
          left --;
        }
      }
    }
    if (!whiteFill) {
      left += 2;
    }
    pixel = null;
    let right = x + 1;
    while ( ! pixel) {
      if (whiteFill) {
        if ( ! pixels[y][right].white) {
          pixel = pixels[y][right];
        } else {
          right ++;
        }
      } else {
        if (pixels[y][right].white) {
          pixel = pixels[y][right];
        } else {
          right ++;
        }
      }
    }
    if (!whiteFill) {
      right -= 2;
    }
    return {
      top,
      bottom,
      left,
      right
    }
  }

  getShapes = () => {
    const {points, resultPos} = this.props;
    const stateObj = {
      lines: [...this.state.lines]
    }
    const pixels = this.getPixels (true);
    points.forEach ((item) => {
      let x = item.x;
      let y = item.y;
      let edgeObj = this.getEdgePoints (x, y, pixels);
      if (edgeObj.right - edgeObj.left <= 2 && edgeObj.bottom - edgeObj.top <= 2) {
        edgeObj = this.getEdgePoints (x, y, pixels, false);
      }
      x = parseInt (String((edgeObj.left + edgeObj.right) / 2));
      y = parseInt (String((edgeObj.top + edgeObj.bottom) / 2));
      edgeObj = this.getEdgePoints (x, y, pixels);
      if (edgeObj.right - edgeObj.left <= 2 && edgeObj.bottom - edgeObj.top <= 2) {
        edgeObj = this.getEdgePoints (x, y, pixels, false);
      }
      stateObj.lines.push ({
        x: edgeObj.left,
        y: edgeObj.top,
        width: edgeObj.right - edgeObj.left,
        height: edgeObj.bottom - edgeObj.top,
        result: '',
        resultCorrection: {
          pos: resultPos === 'default' ? 'i' : resultPos,
          size: 's'
        },
        value: '',
        textAlign: 'center',
        stageWidth: this.width
      })
    })
    this.setState (stateObj);
  }

  render () {
    return (
      <Stage
        ref={this.myRef}
        width={this.width}
        height={this.height}
        onClick={(e) => {
          this.props.onClick (e);
        }}
      >
        <Layer>
          <URLImage
            src={this.props.img}
            x={0}
            y={0}
            width={this.width}
            height={this.height}
          />
          {this.state.lines.map ((item, index) => {
            return (
              <Rect
                x={item.x}
                y={item.y}
                width={item.width}
                height={item.height}
                stroke={'red'}
                strokeWidth={1}
              />
            )
          })}
          {this.state.lines.map ((item, index) => {
            return (
              <Text
                x={item.x}
                y={item.y}
                width={item.width}
                height={item.height}
                text={item.result}
                align={item.textAlign}
                verticalAlign={'middle'}
              />
            )
          })}
        </Layer>
      </Stage>
    );
  }
}