import React, { Component } from 'react'
import ReactPlayer from 'react-player'

export default class HelpVideo extends Component<any, any> {
  render() {
    const {url} = this.props;
    return (
      // Render a YouTube video player
      <ReactPlayer
        url={url}
        width={"100%"}
        height={"100%"}
        playing={true}
        controls={true}
      />
    )
  }
}