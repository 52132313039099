import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_6_1 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 241 169.2"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M20.7,21.5c4.1,0,8,0,11.9,0c1.1,0,1.6,0.4,1.5,1.5c-0.3,3.5-0.8,6.9-0.9,10.4c-0.1,2.2,0.1,4.4,0.2,6.7
	c0,0.5,0,1,0,1.6c0,2.4,0,4.9,0,7.3c0,0.8-0.2,1.6-0.3,2.5c0,0.5,0,1,0,1.4c0.1,1,0.5,2.1,0.2,3c-0.5,1.7,0.1,3.3,0.2,4.9
	c0.1,1.2-0.1,2.4-0.1,3.7c0,0.6,0.2,1.2,0.2,1.9c0,1.9-0.2,3.8-0.2,5.7c0,1.7,0.1,3.4,0.1,5.1c0,0.1,0.1,0.2,0.1,0.4
	c0,2,0.1,4.1,0,6.1C33.4,87.7,33,91.9,33,96c-0.1,6.1,0,12.2,0,18.4c0,1.7-0.3,3.4-0.2,5.1c0.1,2.4,0.4,4.7,0.6,7.1
	c0.2,2.7,0.5,5.5,0.2,8.2c-0.4,4-0.6,7.9-0.7,11.9c0,1.3-0.7,2.5-0.7,3.8c-0.1,1.2,0.3,2.4,0.5,3.6c0.2,1.3,0.5,2.6,0.7,4
	c0,0.3-0.4,0.8-0.6,0.8c-3.6,0.2-7.2,0.3-10.8,0.4c-1.9,0-2.3-0.3-1.8-2.1c0.3-1.4-0.2-2.5-0.2-3.8c0.1-2.7,0.4-5.5,0.4-8.2
	c0-1.9-0.2-3.9-0.3-5.8c-0.1-2-0.2-4.1-0.2-6.1c0.1-2.9,0.3-5.9,0.5-8.8c0.1-1.1,0.4-2.3,0.4-3.4c0.1-3.5,0-7,0.4-10.5
	c0.3-2.8,0.1-5.5-0.4-8.2c-0.3-1.7-0.1-3.6-0.1-5.4c0.1-2.8,0.4-5.5,0.4-8.3c0-5.9-0.2-11.8-0.3-17.6c0-3.2-0.2-6.4,0.3-9.4
	c0.7-4.3-0.5-8.6,0-12.9c0.1-0.9,0.3-1.7,0.3-2.6c-0.2-3.9-0.5-7.8-0.6-11.7c-0.1-3.4,0.1-6.9,0.1-10.3
	C20.9,23.4,20.8,22.5,20.7,21.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M122.8,132.8c0,1,0.2,2,0,2.9c-1,3.7-0.4,7.4-0.7,11.1c-0.1,1.2-0.7,2.5-0.7,3.7c-0.1,1.2,0.3,2.5,0.5,3.7
	c0.2,1.3,0.5,2.6,0.7,4c0,0.2-0.4,0.8-0.7,0.8c-3.5,0.2-6.9,0.3-10.4,0.4c-2,0-2.3-0.3-1.9-2.1c0.3-1.3-0.2-2.4-0.1-3.6
	c0.1-2.8,0.4-5.6,0.4-8.4c0-1.9-0.2-3.8-0.2-5.7c-0.1-2-0.2-4.1-0.2-6.1c0.1-2.9,0.3-5.8,0.5-8.7c0.1-1.1,0.3-2.3,0.4-3.4
	c0.1-3.5,0-7.1,0.4-10.6c0.3-2.8,0-5.5-0.4-8.3c-0.3-1.7-0.1-3.5-0.1-5.2c0.1-2.8,0.4-5.5,0.4-8.3c0-6.6-0.2-13.1-0.3-19.7
	c0-2.5-0.2-5.1,0.3-7.5c0.8-4.3-0.5-8.6,0-12.9c0.1-0.9,0.3-1.9,0.3-2.8c-0.2-3.8-0.5-7.5-0.6-11.3c-0.1-3.5,0.1-7,0.1-10.4
	c0,0,0-0.1,0-0.1c0-0.8-0.3-1.9,0.1-2.4c0.3-0.4,1.5-0.3,2.3-0.3c3.2,0,6.4,0.1,9.6,0.1c0.8,0,0.9,0.5,0.9,1.1
	c-0.2,2.7-0.3,5.3-0.5,8c-0.2,2.7-0.4,5.5-0.5,8.2c0,1.3,0.5,2.7,0.3,4c-0.3,2,0,3.9,0,5.9c0,0.9-0.3,1.8-0.3,2.7
	c0,2.4-0.3,4.8,0.1,7.2c0.3,2.1,0.6,4.1,0.3,6.1c-0.1,0.7,0.3,1.6,0.2,2.3c0,2.8-0.2,5.6-0.2,8.4c0,2.2,0.5,4.4,0.4,6.6
	c0,2.3-0.3,4.7-0.5,7c-0.1,0.7-0.3,1.3-0.3,2c0,8.7,0,17.4,0,26.2c0,2.7-0.1,5.4,0.1,8.1c0.1,2.5,0.5,5,0.8,7.5
	C123,132.7,122.9,132.7,122.8,132.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M206.8,21.5c3.9,0,7.7,0,11.4,0c0.8,0,1.2,0.4,1.1,1.2c-0.3,3.5-0.6,7-0.8,10.5c-0.1,2.3,0.1,4.5,0.1,6.8
	c0,0,0,0.1,0,0.1c-0.1,3.7-0.1,7.5-0.2,11.2c0,0.6,0,1.1,0,1.7c0.1,0.9,0.2,1.9,0.2,2.8c0.1,1.8,0.1,3.5,0.1,5.3
	c0,3.7-0.1,7.4-0.1,11.1c0,2.2,0.1,4.4,0.2,6.7c0,2.8,0.3,5.6,0,8.4c-1.1,9.8-0.2,19.6-0.5,29.4c-0.1,2.9-0.1,5.7,0,8.6
	c0.1,3.5,0.7,7,0.4,10.4c-0.4,3.7-0.4,7.3-0.5,10.9c0,0.4-0.2,0.8-0.3,1.2c-0.6,2.3-0.6,4.6,0.2,6.9c0.4,1.1,0.4,2.4,0.5,3.6
	c0,0.2-0.4,0.6-0.6,0.6c-3.3,0.2-6.5,0.3-9.8,0.4c-2.1,0-2.5-0.4-2-2.5c0.2-0.9,0.1-1.7-0.1-2.5c-0.2-0.8-0.2-1.6-0.2-2.4
	c0.2-3.6,0.5-7.1,0.6-10.7c0-2-0.5-3.9-0.5-5.9c0.1-3.7,0-7.5,0.6-11.2c0.7-4.5,0.2-9,0.6-13.5c0.3-3,0-5.8-0.5-8.7
	c-0.3-1.5-0.1-3.1,0-4.7c0.1-2.8,0.4-5.6,0.4-8.4c0-6.5-0.2-13-0.3-19.6c0-2.6,0.2-5.1,0.3-7.7c0.1-4.3,0-8.6,0.1-12.9
	c0-1,0.3-2,0.2-3.1c-0.1-3.6-0.5-7.2-0.5-10.8c-0.1-3.6,0.1-7.2,0.1-10.8C207,23.3,206.8,22.4,206.8,21.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M206,45.5c-0.2,1.1-0.4,2.6-0.6,4.2c-0.1,1-0.8,1.3-1.8,1.4c-1.4,0-2.8,0.2-4.1,0.2c-0.5,0-1-0.4-1.6-0.4
	c-3.5-0.1-7-0.1-10.5-0.1c-3.6,0-7.2-0.1-10.8-0.1c-2.9,0-5.9,0.2-8.8,0.2c-3.9,0-7.7-0.1-11.6-0.2c-4.3-0.1-8.6-0.3-12.8-0.2
	c-5.5,0.1-11,0.4-16.5,0.7c-2.4,0.1-2.6,0.2-2.8-2.1c-0.2-2.7-0.1-5.3-0.1-8c0-0.7,0.4-0.8,1-0.9c2.2-0.1,4.5-0.1,6.7-0.4
	c4.2-0.7,8.4,0.1,12.6-0.3c3.1-0.3,6.3-0.2,9.4-0.1c3.8,0.1,7.6,0.4,11.5,0.6c2.3,0.1,4.6-0.1,6.9-0.1c6.5,0,13,0,19.5-0.1
	c2.2,0,4.5-0.2,6.7-0.2c2.1,0,4.2,0,6.3,0c0.9,0,1.1,0.5,1.1,1.2C205.8,42.1,205.9,43.5,206,45.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M123.6,122.5c2.8-0.2,5.4-0.1,7.9-0.5c4.2-0.7,8.5,0,12.7-0.3c2.8-0.2,5.7-0.1,8.5-0.1
	c3.8,0.1,7.5,0.3,11.2,0.4c2.7,0.1,5.4,0,8,0c5.4,0,10.8,0.1,16.2,0c3.5,0,7-0.3,10.5-0.3c2.2-0.1,4.4,0,6.7,0
	c-0.2,2.8-0.4,5.4-0.7,7.9c0,0.5-0.2,1-0.2,1.5c-0.2,2.3-0.2,2.3-2.5,2.4c-2,0.1-4.1,0.5-6.1,0.1c-5-0.9-10-0.2-15-0.4
	c-5.1-0.2-10.1,0-15.2-0.1c-7.4-0.1-14.9-0.4-22.3-0.4c-5.6,0-11.2,0.4-16.7,0.6c-1.9,0.1-2,0.2-2.2-1.8c-0.3-2.6-0.6-5.2-0.9-7.8
	C123.6,123.4,123.6,123,123.6,122.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M42.1,38.5c2.6-0.1,5.2-0.3,7.8-0.3c1.8,0,3.6,0.5,5.4,0.5c3.1,0.1,6.2,0,9.3,0.2c3.1,0.2,6.1,0.7,9.2,0.9
	c1.1,0.1,2.2-0.2,3.2-0.2c2.8,0,5.7,0.3,8.5,0.2c3.8-0.1,7.6-0.4,11.4-0.5c3.6-0.1,7.2-0.1,10.8-0.1c0.8,0,1.4,0.5,1.4,1.4
	c-0.1,3-0.2,6-0.3,9.1c0,0.9-0.6,1.2-1.4,1.2c-2.1,0-4.2,0-6.3,0c-5.8-0.1-11.6-0.1-17.4-0.2c-3.9-0.1-7.7-0.5-11.6-0.5
	c-4.8,0-9.6,0.2-14.3,0.4c-1.3,0-2.6,0.2-3.9,0.2c-1.5,0-3.1,0-4.6-0.1c-3.3-0.2-6.6-0.4-9.9-0.6c-1,0-2,0.1-3,0.2
	c-1,0.1-1.3-0.4-1.4-1.3c-0.4-2.5-0.2-5.1-0.2-7.6c0.1-2.8,0-2.9,2.9-3.2c0.5-0.1,1-0.1,1.4-0.1c1,0,2.1,0,3.1,0
	C42.1,38.3,42.1,38.4,42.1,38.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M34.1,121.3c3-0.7,5.8-0.9,8.6-0.7c2.5,0.2,5-0.2,7.5-0.2c1,0,2,0.2,2.9,0.3c0.7,0.1,1.4,0.3,2,0.2
	c4.4-0.5,8.7,0.3,13,0.6c4,0.3,8.1,0.3,12.2,0.4c3.8,0.1,7.6,0.1,11.4,0c4.8-0.1,9.7-0.3,14.5-0.4c2.7-0.1,2.6,0,2.3,2.6
	c-0.3,2.6-0.5,5.2-0.7,7.9c-0.1,1-0.5,1.2-1.3,1.2c-2.8,0-5.6,0.2-8.3,0.2c-3.6,0-7.1-0.1-10.7-0.2c-3-0.1-6.1-0.3-9.1-0.4
	c-2.3-0.1-4.6-0.3-6.9-0.2c-5.4,0.1-10.8,0.3-16.1,0.4c-3.7,0.1-7.5,0.1-11.2,0.1c-1.1,0-2.2-0.3-3.3-0.4c-1.4-0.1-2.9,0-4.3-0.1
	c-0.4,0-1-0.4-1.1-0.6C34.9,128.5,34.5,124.9,34.1,121.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M206,82.6c-0.2,3.4-0.4,6.5-0.6,9.7c-0.1,0.9-0.7,1-1.3,1c-2,0-4.1,0-6.1,0c-6,0-12-0.2-18-0.1
	c-9,0-18.1,0.2-27.1,0.2c-7.1,0-14.2,0-21.4-0.1c-2.1,0-4.1-0.1-6.2-0.2c-1.4,0-1.9-0.7-1.7-2.1c0.1-0.7,0-1.4,0.1-2.1
	c0.1-1.6,0.2-3.2,0.5-4.7c0-0.3,0.7-0.7,1.1-0.7c2.1-0.2,4.2-0.1,6.3-0.5c4.2-0.8,8.4-0.2,12.6-0.3c7-0.1,14,0.4,21.1,0.4
	c6.1,0.1,12.2,0,18.2-0.1c5.2-0.1,10.4-0.2,15.6-0.3C201.2,82.5,203.4,82.6,206,82.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M109.3,87.1c-0.2,1.7-0.4,3.5-0.5,5.3c-0.1,1.1-0.7,1.5-1.6,1.4c-5.4-0.2-10.7-0.5-16.1-0.7
	c-3.3-0.1-6.5-0.2-9.8-0.3c-3-0.1-6-0.2-9-0.3c-1.9,0-3.8,0-5.7,0c-2.1,0-4.3,0-6.4,0c-2.3,0-4.6-0.1-6.9-0.1
	c-0.8,0-1.6-0.2-2.5-0.2c-4.7-0.1-9.4-0.1-14.1-0.2c-2,0-2,0-1.8-2c0-0.1,0-0.3,0-0.4c-0.8-1.9-0.1-3.7,0.1-5.6
	c0-0.2,0.1-0.4,0.1-0.6c-0.6-1.6,0.5-1.7,1.5-1.9c1.5-0.2,3-0.2,4.5-0.3c3.1-0.1,6.2-0.1,9.3-0.1c0.9,0,1.8,0.2,2.7,0.3
	c0.7,0.1,1.4,0.3,2,0.2c6.2-0.6,12.2,0.7,18.3,1.1c0.8,0.1,1.5,0,2.3,0c2.8,0,5.5,0,8.3,0c4.2-0.1,8.5-0.3,12.7-0.4
	c3.7-0.1,7.4-0.1,11.1-0.1c0.9,0,1.3,0.3,1.3,1.3C109.1,84.9,109.2,85.9,109.3,87.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M227.4,121.1c1.9-0.1,3.7-0.2,5.6-0.4c1.1-0.1,1.6,0.4,2.1,1.4c1,2,0,3.8,0,5.6c0,1.3-0.2,2.5-0.3,3.8
	c-0.1,1.4-0.5,1.9-1.9,2.1c-1.3,0.1-2.7,0.1-4.1,0c-2.4-0.1-4.8-0.3-7.2-0.4c-0.6,0-1-0.2-1-0.8c-0.3-3.4-0.6-6.7-1-10.1
	c-0.1-0.7,0.2-0.9,0.9-1c2.3-0.1,4.6-0.3,6.9-0.4C227.4,121,227.4,121,227.4,121.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M3.6,44.6c0-1.2,0-2.3,0-3.5c0-2,0.8-2.6,2.8-2.6c2.5,0,5.1,0,7.6,0c1.4,0,2.7-0.1,4.1-0.2
	c0.7-0.1,1,0.2,1.2,0.9c0.7,3.2,0.5,6.3,0.1,9.5c0,0.4-0.6,0.8-1,0.9c-1.4,0.2-2.8,0.2-4.2,0.4c-2.8,0.3-5.5,0.6-8.3,1
	c-1.3,0.2-2-0.1-2.1-1.4C3.7,47.9,3.6,46.3,3.6,44.6C3.5,44.6,3.5,44.6,3.6,44.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M227.4,38.8c1.8-0.1,3.7-0.2,5.5-0.4c1.4-0.1,1.5,0.3,2.2,1.5c1,1.8,0.1,3.5,0,5.3c-0.1,1.6-0.3,3.2-0.4,4.8
	c0,0.8-0.4,0.9-1.1,0.9c-2.3-0.1-4.6-0.2-6.9-0.2c-1.7,0.1-3.4,0-5.2,0.1c-0.8,0-1.2-0.3-1.3-1c-0.2-1.7-0.4-3.5-0.4-5.2
	c-0.1-1.4,0.1-2.7,0.1-4.1c0-1,0.3-1.4,1.3-1.5c2.1-0.1,4.1-0.2,6.2-0.3C227.4,38.7,227.4,38.7,227.4,38.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M3.6,126.8c0-1.1,0-2.2,0-3.4c0.1-2,0.8-2.6,2.8-2.6c3.1,0,6.3,0,9.4-0.1c0.9,0,1.8-0.1,2.7-0.2
	c0.7,0,1,0.3,0.9,1c-0.4,3.1-0.8,6.2-1.2,9.4c-0.1,0.8-0.4,1.1-1.2,1.1c-3.4,0.3-6.7,0.6-10.1,0.9c0,0-0.1,0-0.1,0
	c-2.8,0.5-3.1,0.3-3.3-2.6C3.6,129.2,3.6,128,3.6,126.8C3.6,126.8,3.6,126.8,3.6,126.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M3.9,93c-0.1-0.8-0.3-1.7-0.3-2.6c-0.1-2.1-0.1-4.1,0-6.2c0-1.8,0.8-2.5,2.8-2.5c3.9-0.1,7.7-0.2,11.6-0.2
	c1.5,0,1.7,0.1,1.7,1.6c-0.1,2.9-0.2,5.8-0.4,8.7c0,0.8-0.6,1.1-1.3,1.1c-1.4,0.1-2.8,0-4.1,0.1c-1.8,0.2-3.6-0.3-5.4-0.2
	C7,92.8,5.6,92.9,3.9,93z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M234.7,93.2c-1,0-2,0-3,0c-3.4,0-6.7,0-10.1,0c-1.6,0-2.1-0.7-1.9-2.2c0.1-0.8,0-1.6,0.1-2.3
	c0.1-1.4,0.2-2.8,0.3-4.2c0-0.7,0.1-1.3,0.1-2.2c1.1-0.1,2.3-0.3,3.5-0.4c3.2-0.1,6.3-0.2,9.5-0.3c1,0,1.5,0.4,1.9,1.3
	c0.7,1.5,0.4,2.9,0.2,4.4C235,89.3,234.8,91.3,234.7,93.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }}
              d="M146.3,93.7c2,0,4.1,0,6.1,0c0,0,0,0.1,0,0.1c-2.1,0-4.1,0-6.2,0C146.3,93.8,146.3,93.7,146.3,93.7z" />
      </SvgIcon>
    )
  }
};