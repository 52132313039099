import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_5_2 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 251 105.1"}
        style={{ width: "100%", height: "100%" }}
      >
        <path d="M98.9,71c0.9-0.1,1.8-0.4,2.7-0.4c2.6-0.2,5-0.7,7.2-2.1c0.6-0.4,1.5-0.6,2.2-0.9c0.1,0.1,0.1,0.2,0.2,0.3
	c-0.2,0.2-0.3,0.5-0.5,0.7c-0.3,0.5-0.7,1-0.8,1.5c-0.5,2.7-0.9,5.5-1.3,8.3c-0.1,0.6,0.1,1.3,0.4,1.9c0.5,0.9,1.1,1.8,1.7,2.7
	c0.8,1.1,1.6,2.3,2.4,3.4c0.1-0.1,0.2-0.1,0.3-0.2c-1.1-1.7-2.2-3.3-3.3-5c-0.6-0.8-1.4-1.5-1.1-2.6c0.8-2.9,1.5-5.7,2.4-8.6
	c0.3-0.9,0.7-1.9,2.1-1.8c0.3,0,0.7-0.3,1-0.4c-0.5-0.3-1-0.5-1.4-0.7c0.6-0.3,1.1-0.4,1.5-0.7c1.1-0.9,2.2-1.8,3.1-2.9
	c0.3-0.4,0.2-1.2,0.3-1.8c0.7,0.1,1,0.5,1.1,1.1c0.3,2.2,0.5,4.4,1,6.5c0.5,2,0.5,4.2,2.1,5.7c0.7,0.6,1.5,1.2,2.4,1.5
	c0.3,0.1,0.9-0.3,1.4-0.4c-0.3-0.4-0.5-0.9-0.9-1c-0.5-0.2-1.1-0.2-1.7-0.3c-1.1-0.1-1.9-0.7-2.1-1.8c-0.3-1.2-0.4-2.4-0.5-3.6
	c-0.1-1.5-0.2-3.1-0.3-4.6c0-0.7-0.2-1.5-0.2-2.2c0-0.7,0-1.4,0-2c-0.1-0.6-0.4-0.8-0.9-0.3c-0.5,0.5-0.9,1-1.4,1.5
	c-0.8,1-1.6,2-2.4,3c-1.2,1.5-2.6,2.2-4.6,2.2c-1,0-2,0.5-3.1,0.8c-2.6,0.9-5.3,1.8-7.9,2.7C99.6,70.5,99.3,70.8,98.9,71
	c-0.4,0.5-1.1,0.8-1.3,1.3c-1.2,2.3-2.4,4.7-3.6,7.1c-0.2,0.5-0.4,1-0.6,1.5c1.6-2.5,2.9-5,4.3-7.5C98.1,72.6,98.5,71.8,98.9,71z
	 M18.2,58c-0.5,0-1,0-1.6,0c0.2-0.5,0.3-0.9,0.4-1.3c-2,1.4-4.2,2.5-4.4,5.4c-0.2,2.3-0.5,4.4,0.1,6.7c0.7,2.6,3.8,3.8,6.1,2.3
	c1.4-1,2-2.5,2.6-4c0.9-2.3,1.7-4.6,1.3-7.2c-0.1,0.3-0.1,0.6-0.1,0.9c0,2.1-0.9,3.9-2.4,5.3c-0.8,0.8-1.9,1.1-2.9,0.7
	c-1-0.4-1.6-1.3-1.6-2.4c-0.1-2.1,0.4-4,1.9-5.5C17.8,58.6,17.9,58.3,18.2,58c1.3-0.3,2.7-0.7,3.9,0.7c-0.5-1.5-1.7-2.6-2.8-2.4
	C18.8,56.5,18.5,57.4,18.2,58z M113.7,40.3c-0.2,2.6-0.4,5-0.6,7.6c2.7-0.9,4.9-0.3,6.4,2c1.4,2.3,0.9,4.6-0.1,6.8
	c0.4,0.5,0.7,0.9,1,1.2c0.3,0.3,0.7,0.6,1,0.6c2-0.1,3.9,0.4,5.5,1.6c2.8,2,4.4,4.8,5.5,8c0.3,1,0.7,1.3,1.7,1.3
	c0.3,0,0.9,0.3,0.9,0.6c0.1,0.4-0.2,0.9-0.4,1.4c-0.1,0.4-0.6,0.9-0.5,1.2c0.5,2.5,0.7,4.9-0.1,7.4c0.1,0.1,0.2,0.2,0.3,0.2
	c2.8,0.7,5.5,1.5,8.3,2.2c3.5,1,7.1,1.1,10.7,0.7c4.7-0.6,8.8-2.6,12.6-5.3c2.1-1.5,3.4-3.7,4.7-5.8c0.8-1.3,0.7-2.4,0.2-3.6
	c-1.2-3-3-5.5-5.7-7.4c-2.1-1.4-4.1-2.9-6.7-3.4c-2.1-0.4-4.3-0.8-6.5-1.1c-1.7-0.2-3.4,0.1-4.5,1.6c-0.5,0.6-1.2,1.2-1.9,1.6
	c-2.6,1.3-5.2,2.5-7.9,3.7c-0.4,0.2-0.9,0.2-1.4,0.3c1.3-0.9,2.7-1.6,4.2-2.2c3-1.2,5.6-3.1,7.4-5.5c3.2-4.3,6.4-8.8,7.2-14.4
	c0.4-2.6,0.7-5.2-0.8-7.6c-1.1-1.8-2.3-3.6-3.5-5.5c-1.6-2.6-2.1-5.2-1.2-8.2c1-3,2.9-5.7,3.3-9c0.2-2.3-0.4-5-3.2-5.5
	c-1.1-0.2-2.2-0.4-3.3-0.4c-2.5,0.1-5,0.2-7.4,0.5c-1.3,0.2-2.6,0.7-3.7,1.3c-1.9,1-3.6,2.2-5.5,3.2c-2.4,1.3-4.3,3.1-6,5.3
	c-3.9,5.1-6.6,10.8-8.4,16.9c-0.3,0.9-0.8,2-0.1,2.9c0.9,1.1,2,2.1,3.1,3c0.8,0.6,0.5,1,0,1.5C117.4,40.9,115.7,41.1,113.7,40.3z
	 M170.5,74.9c1.3,0.3,2.5,0.6,3.7,0.7c2.3,0.1,4.5-0.5,6.7-1.5c0.9-0.4,1.4-0.9,1.6-1.8c0.7-4.1,3.6-6.7,6.5-9.2
	c2.4-2.1,5.3-3.3,8.3-4.3c2.7-0.9,5.6-1.1,8.4-1c0.3,0,0.7-0.1,1-0.3c1.1-0.8,2.2-1.7,3.2-2.6c2.5-2.4,5.1-4.7,7.2-7.3
	c2.2-2.7,3.8-5.8,5.7-8.7c0.4-0.6,0.8-1.1,1.1-1.7c0.5-0.7,1-1.4,1.3-2.2c1.1-3.3,2.1-6.6,1.5-10.2c-0.4-2.1-0.6-4.2-1.3-6.2
	c-1.1-3-2.5-5.9-3.8-8.9c-0.6-1.4-1.7-2.4-3-3.1c-1.3-0.6-2.5-0.4-3,0.6c-0.5,1-1,2-1.3,3c-0.8,2.2-2.1,4.1-3.8,5.5
	c-2,1.6-4.4,2.8-6.7,4c-3.7,1.9-7.5,3.7-10.3,7c-1.9,2.3-3.9,4.5-5,7.4c-0.5,1.3-1.1,2.5-1.6,3.8c-0.3,0.6-0.6,1.1-1.4,0.5
	c-0.4-0.3-1-0.6-1.5-0.6c-1.7,0.2-3.4,0.5-5,1c-4.3,1.4-7.8,3.9-10.8,7.3c-2.8,3.1-4.7,6.7-5.8,10.7c-0.2,0.6-0.2,1.1,0.6,1.5
	c2.4,1.4,4.6,2.9,6.4,5.1c1.4,1.6,2.3,3.4,2.9,5.4c0.4,1.3,0.4,2.7-0.6,3.9C171.2,73.4,171,74,170.5,74.9z M67.9,48.1
	c-0.8-0.7-1.8-1.1-2.3-1.8c-0.5-0.7-1-1.9-0.7-2.6c1.3-2.9,3.1-5.6,5.8-7.5c0.6-0.5,1-1,0.5-1.8c-0.8-1.4-0.9-2.9-0.6-4.4
	c0.4-1.9,0.5-3.7,0.2-5.6c-0.6-4.1-2.4-6-7.2-6.1c-3.8-0.1-7.4,0.9-10.9,2.3c-4.2,1.6-7.3,4.6-10.1,8.1c-4.3,5.5-7,11.6-8.2,18.5
	c-0.4,2.2-0.7,4.5-1.2,6.7c-0.6,2.5-1.8,4.7-4.2,6.5c3.6,0.5,5.7,2.4,6.2,5.9c0.2,0,0.3,0.1,0.5,0c3.2-0.8,6.4-0.3,9.2,1.1
	c3.2,1.5,6.2,3.6,9.3,5.4c2.3,1.4,4.4,3.2,6.8,4.5c4,2.1,8.5,2.2,12.9,1.9c1.1-0.1,2.3-0.5,3.3-0.7c-0.1-0.8-0.3-1.4-0.2-2
	c0.2-1,0.5-1.9,0.7-2.8c1-3.6,1.2-7.3,0.6-11c-1-5.6-3.9-9.8-9.5-12c-0.2,0.8-0.4,1.5-0.8,2.5C66.8,51.2,67.6,49.6,67.9,48.1z
	 M99.4,42.1c-1.4-1.2-2.7-2.3-4-3.3c-1.7-1.2-3.4-2.4-5.2-3.4c-2.7-1.5-5.7-2.3-8.8-2c-1.6,0.2-3.1,0.6-4.6,1
	c-5,1.2-8.4,4.2-10.4,8.9c-0.9,2.2-0.7,2.5,1.3,3.6c0.4,0.2,0.8,0.5,1.3,0.6c5.3,1.7,8.3,5.5,10.3,10.4c0.5,1.3,0.7,2.8,1.3,4.1
	c1.4,3,2.9,6,4.5,8.9c2,3.7,5.7,4.7,9.2,5.9c0.8-1.7,1.5-3.4,2.3-4.9c0.4-0.7,1.1-1.3,1.8-1.6c2.6-1.1,5.3-2.1,8.1-3.1
	c-0.7-0.2-1.3-0.3-1.8-0.5c-2.3-1.1-3.8-3.2-4.9-5.3c-1.8-3.1-0.4-6.1,1.3-8.8c0.9-1.4,1-1.6-0.1-3c-0.3,1-0.5,2-0.9,2.9
	c-0.2,0.4-0.7,1-1.1,1c-0.4,0-0.9-0.6-1.2-1c-0.8-1.4-0.5-2.9-0.4-4.4C97.5,45.9,97.5,43.8,99.4,42.1z M32.8,49.3
	c-1.6-0.3-2.8-0.6-4.3-1c0.2,0.5,0.4,0.7,0.5,1c-1.3,0.3-1.7-0.7-2.2-1.4c-1.7-2.2-2.4-4.8-2.8-7.4c-0.7-4.6-2.5-8.7-4.9-12.5
	c-2.1-3.3-5.3-4.4-8.5-2.4c-0.9,0.5-1.7,1.2-2.5,1.8c-4.9,3.8-6.4,9-5.7,14.9c0.4,3.1,1.9,5.8,3.9,8.2c1,1.3,2,2.6,3.8,2.9
	c0.4,0.1,0.8,0.5,1,0.9c0.9,1.2,1.6,2.5,2.4,3.6c1.1-0.6,2.1-1.2,3.1-1.8c0.2-0.1,0.5-0.3,0.5-0.5c0.2-1.6,0.8-3.4,0.5-4.9
	c-0.4-1.7-1.5-3.3-2.5-5.3c0.7-0.3,1.3-0.9,2.1-1c1-0.1,1.5,0.7,1.8,1.6c1.2,3.2,1.1,6.3,0.1,9.2c1.3,0.7,2.7,1.1,3.6,2
	c0.8,1,1,2.5,1.4,3.6c1-0.2,2.2-0.5,3.4-0.8c0.3-0.1,0.6-0.3,0.8-0.4c1.6-1.1,2.7-2.5,3.1-4.3C31.8,53.4,32.3,51.5,32.8,49.3z
	 M108,74.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5,0.3-1.2,0.6-1.4,1c-0.8,1.8-2.3,3.3-2,5.4c0,0.2,0,0.5-0.1,0.7c-0.8,2.1-0.9,4.2-0.8,6.4
	c0.1,2.2,0.3,4.4,0.6,6.5c0.4,2.5,1.5,3.1,3.8,1.8c2-1.2,4-2.3,5.7-3.8c2.1-1.8,3.9-3.9,5.8-5.9c0.4-0.4,0.5-0.9,0.1-1.6
	c-0.3-0.5-0.4-1.3-0.3-1.9c0.3-2.1,0.5-4.2,1-6.3c0.2-1-0.2-1.2-0.9-1.5c-2.4-0.7-4.5-1.9-6.2-3.7c-0.3-0.3-0.6-0.6-0.9-0.9
	c-1.8,2.9-1.3,6.2-2.3,8.7c1.4,2.2,2.7,4.2,3.9,6.2c0.4,0.7,0.6,1.9,1.2,2.1c1.2,0.5,0.7,1.2,0.5,1.9c-0.3,1.3-1.2,2.1-2.2,2.8
	c-0.5,0.3-0.9,0.9-1.5,0.2c-0.5-0.7-0.1-1.3,0.3-1.8c0.5-0.6,1.1-1,1.6-1.5c0.5-0.5,0.6-0.8,0.1-1.5c-1.6-1.9-3.1-4-4.5-6
	c-0.3-0.4-0.3-1-0.7-1.2c-0.9-0.4-0.9-1.2-0.8-1.9C107.7,77.4,107.9,76.1,108,74.8z M205.9,58.4c-1.7,0.1-3.1,0.2-4.6,0.4
	c-5.1,0.7-9.5,2.8-13.3,6.4c-3.4,3.2-5.1,7.2-5.9,11.7c-0.2,1,0,2.2,1.1,2.8c1.3,0.8,2.3,0.8,3.3-0.3c1.1-1.1,1.9-2.5,3-3.8
	c-1.2-0.6-2-1-2.9-1.4c0-0.1,0.1-0.2,0.1-0.3c1,0.3,2,0.7,3.1,0.9c1.5,0.4,3.8-0.5,4.3-1.5c0.4-0.9,0.8-1.9,1.4-2.7
	c0.8-1.1,1.5-2.5,2.6-3.2c1.2-0.8,2.8-1,4.2-1.3c1.4-0.3,2.8-0.5,4.3-0.8C205,63.1,204.8,61,205.9,58.4z M114,59.3
	c-1.7,0-3.2-0.8-3.5-2.5c-0.4-2.3-0.6-4.7-0.7-7.1c-0.2-3.4-0.2-6.8-0.2-10.3c0-1.2,0.8-2.5,1.7-2.7c1.2-0.2,1.8,0.4,2.6,1.8
	c0.4,0.7,1.4,1.3,2.3,1.6c0.3,0.1,0.9-0.4,1.4-0.7c0.1-0.1,0.1-0.4,0-0.5c-0.9-0.9-1.8-1.9-2.9-2.7c-2-1.4-4.7-0.4-5.4,2
	c-0.6,2.2-0.2,4.5-0.1,6.7c0.1,1.8,0.2,3.6,0.1,5.4c0,0.4-0.4,0.8-0.6,1.1c-0.1,0-0.2-0.1-0.3-0.1c0-0.9,0-1.7,0-2.7
	c-1.5-0.1-2.6,0.7-3.7,1.5c-0.2,0.1-0.2,0.6-0.2,0.9c0.1,0.7,0.3,1.3,0.5,1.9c-0.1,0.1-0.3,0.1-0.4,0.2c-1-1-1.3-2.5-1.8-3.8
	c-0.5-1.4-0.8-2.8-1.2-4.2c-0.3,1-0.5,1.9-0.3,2.8c0.2,1,1,1.8,1.3,2.7c0.5,1.2,0.9,2.5,1.3,3.8c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.4-0.2-0.8-0.5-1.2-0.7c-0.9,2.3-1.4,6.9,0.2,9.6c2,3.4,5.2,3.3,7.5,1.4C112.2,63.6,113.3,61.7,114,59.3z M248.8,36.8
	c-0.1-0.2-0.2-0.3-0.3-0.5c-0.6,0-1.3,0.1-1.9,0.1c-3.3-0.2-6.5-0.3-9.7,0.2c-4.4,0.7-8.7,1.9-12.8,3.5c-0.3,0.1-0.5,0.3-0.7,0.6
	c-0.7,0.9-1.3,1.8-1.9,2.7c-2.7,4-5,8.4-9.3,11.2c0.4,0,0.7,0,0.9-0.2c0.7-0.6,1.4-1.2,2.1-1.8c1.7-1.4,3.3-2.8,5.5-3.3
	c0.3-0.1,0.6-0.3,0.7-0.6c0.5-1,0.9-1.9,1.3-2.9c1-2.6,3-4,5.8-3.9c2.1,0.1,4,0.8,5.8,1.9c0.5,0.3,1.5,0.3,0.9,1.4
	c3.4-0.4,8.8-3.1,11.2-5.5C247.1,38.9,247.9,37.8,248.8,36.8z M23.9,61.8c-0.3,1.2-0.4,2.2-0.7,3.1c-0.3,1-0.6,2-0.9,2.9
	c-0.6,1.5,0,3.6,1.4,4.5c2,1.3,3.9,0.9,5.6-0.7c1-0.9,2.1-1.8,3.2-2.7c0.6-0.5,1.6-0.7,1.7-1.3c0.2-0.7-0.3-1.6-0.5-2.4
	c-0.1-0.4-0.4-0.9-0.6-1.3c-1-2.1-2.7-3.3-5.1-3C26.6,61.1,25.2,61.6,23.9,61.8z M46.9,76.8c1.4-1,2.7-2.1,4.5-2.1
	c-0.5,0.3-0.9,0.7-1.4,1.1c0.2,0.3,0.5,0.5,0.7,0.8c0.6,0.8,1.4,1.4,2.4,1.2c1-0.3,1.6-1.1,1.9-2.1c0.1-0.3,0-0.9-0.2-1
	c-3.7-2.3-7.3-4.7-11.1-6.7c-1.8-0.9-4-0.9-6.1-1.2c-0.8-0.1-1.7,0.3-2.5,0.5c0,0.1,0.1,0.2,0.1,0.4c0.8-0.1,1.5-0.3,2.5-0.5
	c0,1,0.1,2,0,2.9c-0.1,0.9,0.3,1,0.9,1.2C42.1,72,45.2,73.3,46.9,76.8z M125.9,77.4c0.3,0.5,0.9,1.3,1.2,2.1
	c0.5,1.2,1.3,1.9,2.6,1.9c1.4,0,2.4-0.5,2.8-1.9c0.3-1.2,0.4-2.4,0.5-3.7c0.1-0.9,0.2-1.7,0.2-2.6c0.1-0.8-0.1-1.1-1.1-0.8
	c-2.3,0.7-4.2,0-5.7-2.1c-0.7-1-1.5-1.9-2.2-2.9c-0.5-0.7-1.2-0.6-1.9-0.5c-0.3,0.1-0.7,0.3-0.7,0.5c0,1.6,0,3.2,0.2,4.8
	c0.1,1,0.7,1.9,2.1,1.7c0.6-0.1,1.3,0.2,1.9,0.4c0.7,0.2,1.3,0.8,1.1,1.6C126.8,76.5,126.2,76.9,125.9,77.4z M228.2,60
	c-1,0.3-2.1,0.9-3.1,0.9c-1.2,0-2.5-0.4-3.4-1c-0.9-0.6-1.5-1.6-1.9-2.6c-0.8-1.8,0.3-3.3,1.1-5c-0.9,0.2-1.8,0.3-2.5,0.8
	c-1.8,1.3-3,2.9-3.3,5.3c-0.3,2.2,1,4.6,3.2,4.8c1.8,0.2,3.5,0.6,5.1,1.4c1.3,0.7,2.7,0.4,3.8-0.5C228.4,63.1,228.3,61.5,228.2,60z
	 M121.2,59.5c0.1,1.7,0.2,3.3,0.3,4.9c2.3-0.3,2.9-0.2,3.8,1.1c1.3,1.8,2.5,3.6,3.6,5.3c1-0.5,2-0.9,3-1.4c-0.3-0.4-0.7-0.9-0.9-1.5
	c-1.1-2.6-2.5-5-4.7-6.9C124.9,59.7,123.1,59.3,121.2,59.5z M111.5,50.2c-0.3,0.6-0.6,1-0.6,1.5c-0.2,2,0.2,3.9,1,5.7
	c0.2,0.5,0.9,0.9,1.4,1.2c2.2,0.9,4.4-1.1,4.9-2.6c0.3-0.8,0.3-1.8,0.5-2.6c0.3-1.6-0.2-3-1.3-4c-0.6-0.6-1.5-0.8-2.3-1.2
	c0,0.1-0.1,0.2-0.1,0.3c1.2,1,1.3,1.3,0.6,2.7c-0.5,1.1-1.5,1.8-2.4,1.7C112,52.5,111.7,52,111.5,50.2z M227.8,56.2
	c-0.1-0.5-0.2-1.5-0.6-2.4c-0.6-1.5-2.6-2.7-3.9-2.2c-2.3,1-3.5,4.5-2.3,6.7c0.7,1.3,3,2.4,4.2,2C226.3,60.1,227.8,58,227.8,56.2z
	 M16.7,62.7c-0.1,0.3-0.3,0.6-0.3,1c-0.2,1,0.4,2.2,1.3,2.6c1,0.5,1.5-0.3,2-0.8c1.3-1.4,1.7-3.1,1.9-4.8c0.2-1.1-0.3-1.9-1.2-2.2
	c-1-0.3-1.8,0.2-2.5,1.2c0.5-0.2,0.7-0.3,0.9-0.3c0.7-0.1,1.2-0.1,1,1C19.4,61.8,18.7,62.5,16.7,62.7z M101.7,76.9
	c-0.5-2.7,0.8-4.3,2.6-5.7c-1.3,0.1-2.4,0.4-3.5,0.3c-0.9,0-1.2,0.5-1.5,1c-0.6,1.1-1.2,2.2-1.8,3.3c-0.2,0.4-0.4,0.9-0.7,1.6
	C98.6,77.2,100.1,77.1,101.7,76.9z M157.3,85c-0.4,0.2-0.6,0.3-0.7,0.4c-1.6,1.4-3.2,2.9-5.5,3.2c-0.7,0.1-1.3,0.6-1.8,1
	c-0.3,0.3-0.6,0.9-0.6,1.3c0.1,0.4,0.6,1.1,1,1.1c0.7,0.1,1.6,0,2.3-0.3C154.9,90.5,155.8,87.8,157.3,85z M23.2,78.2
	c0.1,0.1,0.2,0.2,0.2,0.3c2.1-1.7,4.2-3.3,6.1-4.9c-0.7,2.1-1.4,4.3-2.1,6.4c0.1,0,0.2,0.1,0.3,0.1c0.7-1.8,1.4-3.7,2.2-5.5
	c0.1-0.3,0.4-0.6,0.6-0.9c1.3,1.4,2.3,3,4.2,3.7c0-0.9,0-1.7,0-2.3c-1.1-0.6-2-1-3-1.6c-0.6-0.3-1.2-0.9-1.7-0.9
	c-1,0.2-2.1,0.6-2.8,1.2C25.8,75.1,24.5,76.7,23.2,78.2z M30,71.7c0.1,0.1,0.1,0.2,0.2,0.3c1.8-0.8,3.5-1.7,5.3-2.5
	c0.3-0.1,0.6-0.1,0.9-0.2c0,0.3,0.1,0.6,0,0.9c-0.2,0.7-0.6,1.5-0.6,2.2c-0.1,1.1-0.1,2.3,0,3.5c0.1,1.5,0.4,3,0.6,4.8
	c0.3-0.5,0.5-0.6,0.5-0.7c0-3.8,0-7.6,0-11.4c0-0.2-0.5-0.6-0.6-0.5C33.8,68.6,31.7,69.7,30,71.7z M178.2,86.3
	c-0.1,0-0.3-0.1-0.4-0.1c-0.8,1.2-1.7,2.4-2.5,3.7c-0.5,0.9-1,1.8-1.4,2.7c-0.3,0.8,0.3,1.7,1,1.4c0.8-0.3,2-0.9,2.2-1.5
	C177.6,90.5,177.8,88.4,178.2,86.3z M118.9,57.4c-2.3,1.2-4.3,2.6-4.6,5.3c0,0.1-0.1,0.2-0.2,0.3c-0.9,1-1.8,2.1-2.8,3.1
	c0.1,0.1,0.1,0.2,0.2,0.2c0.9-0.6,2-1.1,2.8-1.9c1.2-1.1,2.1-2.5,3.2-3.7c0.7-0.7,1.5-1.3,2.3-2C119.5,58.5,119.1,57.9,118.9,57.4z
	 M111.6,47.7c0.3-2.5,0.5-4.9,1.1-7.1c0.3-1.2,0.1-1.9-0.7-2.6c-0.2-0.2-0.6-0.3-0.9-0.4c-0.1,0.3-0.4,0.6-0.4,0.9
	c0,3.1,0,6.2,0,9.3C111.1,47.7,111.4,47.7,111.6,47.7z M98.4,52.4c0.2,0,0.4,0,0.6,0.1c0.4-1.9,0.9-3.8,1.3-5.7
	c0.2-0.9,0-1.8,1.5-2.1c-0.6-0.7-1-1.7-1.4-1.7c-1-0.1-1.3,1-1.5,1.8c-0.3,1.2-0.6,2.4-0.6,3.6C98.2,49.8,98.4,51.1,98.4,52.4z
	 M224,47.7c0.1,0.1,0.2,0.2,0.3,0.3c2.6-1.7,5.6-1.7,8.7-2.2c-1.3-0.6-2.5-1.1-3.7-1.6c-1-0.4-2-0.4-2.8,0.4
	C225.6,45.5,224.9,46.6,224,47.7z M224.7,50.7c0.1,0.2,0.1,0.3,0.2,0.5c0.4-0.1,0.8-0.2,1.2-0.4c1-0.6,2-1.3,3-1.9
	c2-1.2,5.8-0.7,5.8,2.7c0,0.9,0.1,1.9,1.3,2.2c1-0.9,1-1,0.6-2.1c-0.2-0.5-0.4-0.9-0.7-1.4c-1.1-2-3.3-3.3-5.1-2.6
	C228.7,48.4,226.7,49.6,224.7,50.7z M189,79.9c1,0.6,2.1,0.5,3.3,0c0.3-0.1,0.7-0.5,0.8-0.8c0.4-2,1.9-3.4,2.8-5.1
	c1-1.9,2.1-3.8,3.1-5.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.6,0.8-1.4,1.6-1.9,2.4c-1.4,2.5-2.7,5-4,7.6c-0.3,0.6-0.6,0.8-1.3,0.9
	C190.6,79.1,189.9,79.6,189,79.9z M222.1,48.9c0.1,0.1,0.2,0.1,0.3,0.2c0.4-0.8,0.9-1.5,1.2-2.3c1.1-2.7,3.3-4.7,7.2-2.6
	c0.6,0.3,1.4,0.5,2.1,0.7c0.2,0.1,0.5-0.1,0.8-0.1c-0.1-0.2-0.2-0.6-0.4-0.7c-2.1-1.2-4.3-1.9-6.7-1.3
	C223.5,43.7,223.5,46.9,222.1,48.9z M113.9,69.7c0.1,0.3,0,0.6,0.2,0.8c1.6,1.7,3.1,3.4,5.4,4c0.9,0.2,1.9,0.6,1.8,1.9
	c-0.1,0.8-0.2,1.6-0.2,2.4c1.3-1.7,1-3.4-0.6-4.8c0,0,0-0.1-0.1-0.1c-0.5-0.4-0.9-0.9-1.4-1.1C117.1,71.9,115.3,71.2,113.9,69.7z
	 M106.7,70.6c-2.1,0.5-3.9,2.9-4,5.8c-0.1,2.2,0.7,4.5-0.2,6.7c-0.2,0.5-0.3,1-0.5,1.5c0.1,0,0.3,0.1,0.4,0.1c0.4-1.5,0.9-2.9,1-4.4
	c0.2-1.7,0-3.4,0.1-5.2c0-0.4,0.2-0.9,0.4-1.3C104.9,72.6,106,71.4,106.7,70.6z M214.6,55.7c0.8-0.9,1.4-1.9,2.2-2.5
	c0.8-0.7,2-1,2.9-1.6c0.5-0.3,0.9-0.9,1.3-1.4c-0.1-0.1-0.2-0.2-0.4-0.4c-2.8,1.2-5,3.2-7.3,5.1C213.7,55.3,214.1,55.5,214.6,55.7z
	 M207.8,66c-2.9,0.3-5.8,0.6-8.5,1.7C202.3,67.9,205.3,68.2,207.8,66z M37.7,72.8c0,0.2,0,0.3-0.1,0.5c2.2,0.6,4.5,1.2,6.7,1.8
	c0-0.1,0.1-0.2,0.1-0.3c-1.8-1.5-3.9-2.6-6.4-2.9c0.2,0.3,0.4,0.5,0.6,0.8C38.3,72.7,38,72.8,37.7,72.8z M11.1,56.1
	c-0.4-1.4-1.5-2.3-2.7-2.3c-2.2,0-4.3,2.8-3.9,5.3c0.5-0.8,0.9-1.4,1.3-2.1c1.2-2.1,2.9-3.2,5-1.1C10.8,56,11,56,11.1,56.1z
	 M18.9,91.1c0.1,0.1,2-0.5,2.3-1.2c0.6-1.4,1-3,1-4.5c0.1-2.1-0.3-4.2-0.5-6.3c-0.1,0.4-0.4,0.8-0.4,1.3c0,0.9,0.2,1.9,0.1,2.8
	c0,1.5-0.2,3.1-0.2,4.6c0,0.9,0,1.6-1,2C19.8,90.1,19.4,90.7,18.9,91.1z M43.1,84.7c0.1,0,0.2-0.1,0.3-0.1c0-0.1,0.1-0.3,0-0.4
	c-0.6-2.2-1.2-4.4-1.9-6.5c-0.2-0.8-0.6-1.6-1.1-2.2c-0.7-0.7-1.7-1-2.6-1.6c-0.3,0.9,0,1.2,0.9,1.5c0.9,0.3,1.8,1,2.3,1.8
	c0.5,0.8,0.3,1.9,0.6,2.8C42,81.6,42.5,83.2,43.1,84.7z M19.2,52c-0.4-2.2-0.7-4.2-1.2-6.2c-0.2-0.7-0.7-1.1-1.6-0.7
	c-0.8,0.4-0.4,0.9-0.1,1.5C17.4,48.3,18.2,50,19.2,52z M104.4,75.4c3.4-1.1,4.1-1.8,4.5-4.8C107.3,72.1,105.3,73.1,104.4,75.4z
	 M74,52.5c0.1-0.1,0.2-0.2,0.3-0.3c-1.6-1.3-3.2-2.6-5.1-4c-0.2,0.9-0.3,1.4-0.4,1.8C70.7,50.8,72.4,51.7,74,52.5z M45.3,77.8
	c-1.4-2.5-1.7-2.7-3.7-2.6c0.4,0.9,0.7,1.8,1.1,2.8C43.5,78,44.3,77.9,45.3,77.8z M37.8,75.9c-0.3,2.3,0.4,3.1,2.5,3.2
	C40.3,77,39.8,76.4,37.8,75.9z M113.9,48.3c-0.6,1.1-1.2,1.8-1.5,2.7c-0.1,0.2,0.6,0.9,1.1,1c0.5,0.2,1.6-1.5,1.5-2.4
	C114.9,49.3,114.4,49,113.9,48.3z M213.6,67.5c-0.1,0.1-0.2,0.2-0.3,0.3c1.2,1,2.5,1.9,3.7,2.9c-0.3,0-0.6,0-1.2,0
	c0.4,0.5,0.6,0.8,0.9,1.1c0.2-0.2,0.3-0.4,0.4-0.5c0.8-1.2,0.8-1.5-0.4-2.2C215.7,68.5,214.6,68,213.6,67.5z M114.6,68.7
	c0.9,1.5,3.2,2.9,5.5,3.4C118.4,70.4,116.5,69.5,114.6,68.7z M133.9,70.2c-1.6-0.2-3,0.1-4.2,1.5C131.4,72,132.8,71.5,133.9,70.2z
	 M87.6,82.4c0,0.1,0.1,0.3,0.1,0.4c1.7,0.6,4.2,0,4.7-1.2C90.7,81.9,89.2,82.2,87.6,82.4z M118.5,67.6c-1,0.2-1.8,0.4-2.7,0.7
	c1.2,0.7,2.3,1.4,3.5,2.1C119,69.3,118.8,68.4,118.5,67.6z M121.6,66.6c2.4-1.4,3.2,0.6,4.3,1.8c-1.7-3.1-2.2-3.6-4.3-3.1
	C121.6,65.6,121.6,66,121.6,66.6z M53.2,85.6c-1.1-0.6-2.1-1.1-3.1-1.6C51.6,86.8,52.1,87,53.2,85.6z M124.2,86.4
	c-0.6-1.2-1.2-1.4-3-1C121.5,86.6,122.7,87,124.2,86.4z M123.4,77.2c-0.3,0.1-0.9,0.1-0.9,0.3c-0.4,1.6-0.7,3.3-1.1,5
	c0.1,0,0.2,0,0.2,0.1C122.2,80.8,122.8,79.1,123.4,77.2z M97.9,87.4c2,0.8,2.4-1,3.6-1.7c-0.1-0.1-0.1-0.2-0.2-0.3
	C100.2,86.1,99.1,86.8,97.9,87.4z M48.4,82.5c0.1,0,0.2-0.1,0.3-0.1c-1-2.1-2-4.2-3-6.2c-0.1,0.1-0.3,0.1-0.4,0.2
	C46.3,78.3,47.3,80.4,48.4,82.5z M114.7,67.2c0,0.1,0.1,0.3,0.1,0.4c1.2-0.3,2.4-0.5,3.9-0.9c-0.3-0.3-0.6-0.8-0.7-0.7
	C116.9,66.4,115.8,66.8,114.7,67.2z M45.6,88c0.7-1,0.3-1.7-1.7-2.6C43.6,86.7,44.9,87,45.6,88z M34.7,72.8
	c-0.9-0.1-1.7-0.2-2.5-0.3c0,0.1-0.1,0.3-0.1,0.4c0.8,0.4,1.6,0.8,2.6,1.3C34.7,73.5,34.7,73.2,34.7,72.8z M100.7,61.5
	c0-2.1,0-4,0-6C99.8,59.2,99.8,60.2,100.7,61.5z M37.1,81.3c-0.1,0.1-0.3,0.3-0.4,0.4c0.7,0.8,1.4,1.6,2.2,2.4
	c0.2-0.2,0.3-0.3,0.5-0.5C38.6,82.8,37.8,82,37.1,81.3z M206.5,85c0.1,0.1,0.3,0.3,0.4,0.4c1.2-0.3,1.5-1.4,2.1-2.4
	c-0.1-0.1-0.1-0.2-0.2-0.2C208,83.6,207.2,84.3,206.5,85z M10.9,64.9c0.1,0,0.3,0,0.4,0c0.2-1.4,0.4-2.7,0.6-4.1
	c-0.1,0-0.3,0-0.4-0.1C11.3,62.1,11.1,63.5,10.9,64.9z M17.3,61.8c0.2,0.1,0.4,0.3,0.6,0.4c0.4-0.7,0.9-1.5,1.3-2.2
	c-0.2-0.1-0.3-0.2-0.5-0.3C18.2,60.5,17.8,61.2,17.3,61.8z M212.8,75.1c1.6-0.7,2.3-1.5,2.1-2.2C214.2,73.7,213.5,74.4,212.8,75.1z
	 M110.5,50c0.5-0.6,0.9-1,1.3-1.5C110.5,47.9,110.3,48.1,110.5,50z M212.4,78.7c-0.3-0.2-0.7-0.3-1.1-0.5c-0.1,0.3-0.1,0.6-0.2,0.9
	c-0.1,0-0.1,0-0.2,0c-0.1-0.6-0.4-1.2-0.3-1.8c0.2-0.7,0.7-1.4,1.2-2c0.8-0.9,1.7-1.7,2.4-2.7c0.2-0.3,0-1-0.2-1.5
	c-0.3-0.8-0.8-1.4-1.1-2.2c1,2.1,3.3,3.5,3.1,6.3c1-1,1.1-1.8,0.3-3c-1-1.5-2.1-2.8-3.2-4.2c-0.3-0.4-0.7-0.7-1.1-1.2
	c1.6,0,3.2-1.1,3.7-2.5c0.2-0.4,0.1-1.1-0.2-1.5c-1.3-1.8-1.9-3.8-1.3-6c0.1-0.3-0.3-1-0.6-1.1c-1.3-0.4-2.6-0.7-3.7,0.5
	c-0.3,0.3-0.9,0.3-1.3,0.6c-1.8,1.1-3.6,5-1.6,7.6c0.7,0.9,1.5,1.3,2.7,0.8c0,0.2,0,0.3,0.1,0.5c-1,0.7-2,1.4-3,2.1
	c-1.4,0.9-2.9,1.8-4.3,2.7c-0.5,0.4-0.9,0.9-1.5,1.2c-1.1,0.6-1.3,1.4-1,2.5c0.7,2.3,1.5,4.5,2.4,6.8c0.2,0.5,0.6,0.9,0.9,1.3
	c-0.2,0.4-0.4,0.8-0.7,1.1c-0.8,0.8-1.6,1.6-2.4,2.4c0.1,0.1,0.2,0.2,0.4,0.4c1.2-0.6,2-1.6,2.8-2.8c0.2-0.2,0-0.7,0-1
	c-1-3-1.9-6-2.9-9.1c3.7-2,7.6-3.7,9.9-7.6c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0.4-0.2,0.8-0.4,1.2c-1.2,2-2.4,4-3.6,6
	c-1.1,1.8-1,2.4,0.3,4.2c0,0,0.1,0,0.1-0.1c-0.3-2.1,0.1-3.9,1.4-5.7c1-1.3,1.6-3,2.4-4.5c0.1-0.2,0.3-0.4,0.5-0.7
	c0.8,0.8,0,1.1-0.3,1.6c-0.8,1.4-1.3,2.9-2.2,4.1c-1.8,2.2-1.6,4.5-0.8,6.9c0.3,0.9,0.6,1.8,0.9,2.7c0.6,1.7,0.3,3-1.1,4
	c-0.4,0.3-1.2,0.1-1.8,0.1c0-0.5-0.1-1.1,0.2-1.5c0.4-0.7,1.1-1.2,1.6-1.8c0.2-0.3,0.5-0.8,0.4-1.2c-0.6-1.5-1.2-3.1-2-4.6
	c-0.8-1.6-1-3,0.1-4.5c0.6-0.8,1.1-1.8,1.7-2.8c-2.2,1.2-4.2,2.4-6.2,3.5c-0.7,0.4-0.5,0.9-0.3,1.5c0.7,2.1,1.4,4.2,2.1,6.3
	c0.2,0.6,0.4,1.3,0.7,2.2c-0.6,0.7-1.4,1.8-2.2,2.7c-0.3,0.3-0.7,0.6-1.1,0.6c-0.4,0-0.8-0.2-1.1-0.5c-0.1-0.1,0-0.7,0.2-0.9
	c0.6-0.8,1.3-1.5,1.8-2.3c0.2-0.3,0.4-0.8,0.3-1.1c-0.8-2.5-1.8-5-2.6-7.6c-0.3-0.8-0.3-1.7,0.8-2.4c1.3-0.7,2.2-2,3.5-3.2
	c-1.3,0-2.5,0-3.8,0c-0.2,0-0.5,0.4-0.7,0.6c-1.6,2.7-3.1,5.4-4.7,8c-0.4,0.7-0.8,1.3,0,1.8c-1.8,2.2-4.2,1.7-6.4,1.6
	c-0.1-0.2-0.1-0.3-0.2-0.5c0.7-0.4,1.3-0.9,2-1.1c1.1-0.3,2-0.5,2.3-1.8c0.2-0.7,0.8-1.3,1.2-1.9c-2.1-0.3-2.9,0.2-3.9,1.8
	c-0.7,1-1.5,2.1-2.4,2.9c-1.2,1.1-2.7,0.9-4,0.2c-1.3-0.7-2.1-1.7-2-3.3c0-0.2,0-0.4,0-0.8c-1.8,2-3.2,3.8-2.8,6.6
	c0.4,3.2,0.9,6.5-0.3,9.7c-0.4,1.2-1.2,1.8-2.3,2.2c-0.9,0.3-2,0-2.3-0.9c-0.3-0.7-0.4-1.8-0.1-2.4c1-2,2.3-3.9,3.6-5.7
	c0.9-1.3,1.2-2.7,0.9-4.1c-0.3-1.1,0.1-2,0.6-2.8c0.9-1.4,2-2.7,3-4c-0.1,0-0.4,0.1-0.7,0.2c-0.3,0.1-0.6,0.3-0.9,0.5
	c-2.9,1.3-5.9,1-8.9,0.2c-0.6-0.2-0.8,0-1.2,0.5c-1.3,1.8-3.2,3.1-5.1,4.2c-1,0.6-2.1,1.1-3.2,1.5c-1.1,0.4-1.7,1.1-2.2,2
	c-1.2,2.2-2.3,4.4-3.7,6.4c-1.1,1.6-2.8,2.6-4.9,2.5c-0.6,0-1.3-0.3-1.8-0.7c-1.4-1.1-1-2.7,0.6-3.6c2.1-1.1,4.1-2.2,6.1-3.3
	c0.6-0.4,1.1-1,1.6-1.6c-3,0.8-6,1-9,0.7c-2.4-0.3-4.7-0.7-7-1.2c-2.3-0.5-4.5-1.3-6.8-1.8c-0.4-0.1-0.9,0-1.2,0.2
	c-1.6,1.3-5.7,1.3-6.8-1.3c-0.4-0.9-1-1.8-1.7-2.8c-0.9,2.7-1.7,5-2.6,7.4c0.5,0.1,1.2,0.1,1.9,0.3c0.8,0.2,1.6,0.5,1.5,1.5
	c-0.2,1-1.1,1-1.8,0.9c-1-0.1-2-0.5-3.1-0.8c-0.9,1.1-1.7,2.5-2.8,3.6c-1.8,1.7-3.7,3.4-5.7,4.8c-1.4,1-3.1,1.6-4.7,2.4
	c-2,1-3.7-0.4-4.2-2.2c-0.7-2.7-1.1-5.5-0.7-8.3c0-0.2,0-0.4,0.1-1c-1,1.3-1.8,2.5-3.4,2.4c-0.9-0.1-1.7-0.3-2.7-0.7
	c1.4-0.8,2.7-1.7,4.1-2.4c1-0.4,1.6-0.8,1.5-2c-0.1-1.9,0-3.8,0-5.9c-1.2,0.2-2.4,0.7-3.3,0.4c-2.3-0.7-3,0.7-3.6,2.3
	c-0.8,2-4.4,4-6.5,3.5c-0.7-0.2-1.4-0.7-2.1-1.1c0-0.2,0-0.4,0.1-0.5c1.1-0.3,2.3-0.7,3.4-0.8c2.6-0.2,3.6-1,4.2-3.8
	c-6.8-1.1-11.3-5-13.8-11.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.9-0.1,1.9-0.2,2.8c-0.4,2.4-0.8,4.7-1.3,7.1c-0.2,0.8-0.7,1.9,0.4,2.1
	c0.7,0.2,1.7-0.4,2.4-0.9c0.7-0.5,1.2-1.4,1.9-1.9c0.5-0.4,1.2-0.5,1.9-0.7c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.3-0.1,0.6-0.3,0.8
	c-2.3,1.9-4.8,3.2-7.8,3.4c-2.2,0.2-4.5,0.6-6.7,0.6c-4.7,0-9.2-1.1-13.2-3.9c-0.3-0.2-0.6-0.4-1-0.6c-2,3.7-3.2,3.8-6.7,0.7
	c-0.7,0.4-1.4,0.9-2.2,1.3c1.6,4.9,3.1,6.7,6.6,7.6c0.1,0.6,0.2,1.1,0.3,1.8c-0.8,0.1-1.6,0.4-2.2,0.1c-0.9-0.5-1.7-1.2-2.3-2.1
	c-1.3-1.9-2.5-4-3.8-6c-0.7-1.1-1.7-0.2-2.6-0.2c-0.9,0.1-0.4,0.8-0.3,1.3c0.3,1.4,0.6,2.8,1,4.1c0.1,0.5,0.6,0.8,1,1.2
	c0.9,0.8,2.1,1.3,1.7,2.8c-2.2,0.2-3.1-0.4-3.9-2.6c-0.6-1.8-1.4-3.5-2.1-5.2c-0.1-0.2-0.4-0.5-0.6-0.6c-0.8-0.3-1.6-0.5-2.5-0.7
	c-0.6,1.3-0.4,2.1,0.9,2.8c1.3,0.7,1.6,1.9,1.2,2.9c-1.7-0.3-2.9-1.2-3.6-2.7c-0.5-1.1-0.6-2.3-1-3.4c-0.1-0.3-0.4-0.7-0.8-0.9
	c-1.3-0.6-2.6-1.1-3.3-2.4c0-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.2,0-0.3,0c-0.7,1.7-1.4,3.4-2,5.2c-0.2,0.6,0,1.5,0.2,2.1
	c1.1,2.4,2.4,4.7,3.6,7.1c0.2,0.5,0.3,1.1,0.1,1.6c-0.5,1.5-1.3,2.8-2.7,3.8c0-0.6-0.1-1.1-0.1-1.5c0.3,0.1,0.7,0.3,0.8,0.2
	c1-0.8,1.5-2.8,0.9-3.6l0,0c-0.5-1.1-1-2.1-1.5-3.2c-0.9-1.7-1.8-3.3-2.7-5c-0.1-0.2-0.1-0.5-0.2-0.7c0.4-1.8,0.7-3.6,1.1-5.4
	c-1.6,1-3.2,1.9-4.8,3c-0.3,0.2-0.6,0.9-0.6,1.3c0,1.3,0.2,2.5,0.2,3.8c0,1.7-0.1,3.5-0.2,5.2c0,0.1-0.1,0.3-0.2,0.4
	c-0.7,1.3-2.8,2.3-4.8,1.9c0.9-0.8,1.5-1.4,2.3-1.9c0.6-0.4,0.8-0.7,0.8-1.4c0-2.1,0.5-4.2,0.1-6.2c-0.4-2.2,0.5-3.4,2-4.6
	c1-0.8,1.9-1.7,2.9-2.6c-0.1-0.1-0.2-0.3-0.3-0.4c-1.6,0.5-3.3,0.7-4.7,1.4c-1,0.6-1.7,1.8-2.3,2.9c-1.5,2.4-2.8,4.9-4.2,7.3
	c-1.1,1.8-3.1,2.5-5.4,1.6c0.5-0.2,0.8-0.3,1.2-0.4c-0.2,0.7,0.4,0.8,0.8,0.7c0.8-0.4,1.8-0.8,2.3-1.5c1.6-2.6,3.2-5.2,4.6-8
	c1-2.1,2.5-3.1,4.7-3.5c1-0.2,2-0.6,3-0.9c0-0.1-0.1-0.3-0.1-0.4c-0.8,0.1-1.7,0.2-2.4,0.5c-1.5,0.6-3.4,0.9-4.3,2
	c-1.6,2-3,4.5-3.9,6.9c-1,2.4-2.5,4-4.6,4.2c2.1-0.6,3.2-2.5,4-4.4c0.8-2.2,2.1-4.3,3.4-6.3c1.1-1.8,3.2-2.1,5-2.7
	c0.4-0.1,0.9-0.3,1.3-0.4c-0.8-1.2-1.6-2.2-2.2-3.1c-1.2,0.6-2.4,1.4-3.8,1.8c-3,1-4.7-0.9-5.7-3.4c-0.3-0.8-0.6-1.6-1-2.3
	c-1.1-2.2-0.7-5.9,1.7-6.9c0.1-0.1,0.3-0.2,0.4-0.3c-1.1-1.1-2-2.3-3.2-3.3c-1.2-1-2.5-0.5-3.3,1.1c-0.3,0.7-0.7,1.3-1.1,2
	c-0.4,0.6-0.9,0.8-1.5,0.3c-0.5-0.4-0.6-0.8-0.3-1.4c0.4-0.7,0.4-1.6,0.9-2.1c0.8-0.9,1.8-1.6,2.6-2.4c-2.4-2.5-4.6-5.4-5.3-9.1
	c-1.2-6,0-11.4,4.2-15.9c1.4-1.5,3.3-2.7,5.2-3.6c3-1.4,6.9,0.1,8.8,3.1c2.6,3.9,4.2,8.2,5.1,12.8c0.4,1.9,1.1,3.8,1.7,5.7
	c0.3,1.1,1,1.8,2.3,1.9c1.2,0.1,2.3,0.4,3.6,0.7c0.4-1.5,0.7-2.9,1.1-4.4c1.6-7,4.9-13,9.6-18.4c4.1-4.8,9.6-6.9,15.6-8
	c2.7-0.5,5.4-0.6,8.1,0.4c2.4,0.9,3.8,2.5,4.4,5c0.5,2.6,0.6,5.1,0.1,7.8c-0.2,1.1,0.2,2.4,0.3,3.7c1.8-0.6,3.3-1.1,4.8-1.5
	c5.5-1.5,10.8-1,15.7,2.2c2.4,1.6,4.9,3,6.8,5.2c1.3,1.5,3.1,2.5,3.5,4.8c0.2,1.2,1.7,2.2,2.6,3.3c0.8-0.3,1.8-0.7,2.8-1.1
	c-0.1-1.4-0.3-3-0.4-4.5c-0.2-2-0.2-3.9,0.7-5.7c0.8-1.5,2-2.5,3.8-2.5c1,0,1.2-0.4,1.4-1.3c1.1-4.7,2.7-9.2,5.3-13.3
	c1.4-2.2,2.7-4.4,4.3-6.3c1.2-1.5,2.8-2.7,4.4-3.9c2.5-1.9,5.2-3.6,8.2-4.4c3.7-1,7.4-1.7,11.3-1.1c1,0.2,2,0.1,2.9,0.4
	c3.4,1,4.7,4.1,3.9,7.2c-0.6,2.4-1.7,4.8-2.6,7.1c-1.5,3.7-1.4,7.1,1.2,10.3c1.3,1.6,2.4,3.3,3.2,5.2c0.5,1.2,0.5,2.7,0.5,4.1
	c0,1.4-0.1,2.8-0.3,4.1c-0.6,3.2-2.2,5.9-3.9,8.7c-0.7,1.2-1.6,2.3-2.4,3.4c-0.1,0.1-0.1,0.3-0.1,0.5c4-0.4,7.8,0.2,11.4,1.8
	c0.8-2,1.3-4.1,2.3-6c1.2-2.1,2.6-4.1,4.1-6.1c1.9-2.5,4.3-4.5,7.1-5.8c2.1-1,4.3-1.6,6.6-2.2c1.3-0.3,2.6,0,4-0.1
	c0.4,0,1-0.6,1.1-1c0.6-2.8,2.1-5.1,3.7-7.3c3.3-4.4,7.6-7.4,12.6-9.7c2.3-1.1,4.5-2.3,6.5-3.8c1.5-1.2,2.6-2.8,3.4-4.7
	c0.5-1.1,1-2.2,1.6-3.3c0.9-2,2.5-2.4,4.5-1.5c2.6,1.3,4,3.5,5,6.1c0.7,1.8,1.6,3.6,2.4,5.4c1.2,2.6,2,5.4,1.7,8.3
	c-0.2,2.2-0.2,4.5-0.7,6.6c-0.5,2.1-1.7,4-2.5,6c-0.1,0.2-0.1,0.4-0.3,0.8c0.5-0.2,0.7-0.2,1-0.3c4.2-1.8,8.7-3,13.2-3.3
	c3.1-0.2,6.2,0,9.3,0c0.7,0,1.3-0.1,2.1-0.1c0.6,0.7,0.3,1.5-0.2,2.2c-2.7,3.7-6.4,6.1-10.7,7.2c-3.4,0.9-6.9,1.2-10.3,2
	c-1.6,0.4-3.3,1-4.7,1.9c-0.8,0.5-1.3,1.7-1.9,2.6c0.1,0.1,0.2,0.2,0.2,0.3c0.7-0.4,1.3-0.9,2-1.3c2.1-1.4,4-2.9,6.5-3.4
	c2.2-0.5,4.7,1.1,5.6,2.5c0.4,0.7,1,1.4,1.1,2.2c0.1,0.7-0.3,1.4-0.4,2.2c-0.7-0.2-1.5-0.2-1.9-0.6c-0.6-0.7-1.1-1.6-1.2-2.4
	c-0.3-1.9-1.5-2.8-3.3-2.1c-1.5,0.7-2.9,1.6-4.5,2.5c1.7,1.4,2.6,3.2,2.7,5.2c0.1,2.2,0.3,4.5,0,6.6c-0.3,2.2-2.9,3.4-4.9,2.4
	c-1.5-0.8-3.2-1-4.8-1.1c-0.7-0.1-1.4-0.2-2-0.4c-0.7,1.1-1.5,2.2-2.2,3.3c0.7,0.4,1.5,0.8,2.2,1.3c1.5,1,1.5,1.8,0.6,3.3
	c-0.2,0.3-0.4,0.9-0.3,1.2c0.4,0.9,0.1,1.7-0.4,2.5c-1.2,1.8-2.3,3.7-3.5,5.6c1.1,0.6,2.2,1.2,3.3,1.9c-1,0.6-2.2,0.5-3-0.2
	c-0.7-0.6-1.4-1.1-1-2.2c0.2-0.5,0-1,0-1.5c0.2,0.2,0.4,0.5,0.8,0.9c0.8-2,1.5-3.8,2.3-5.6c-0.1,0-0.2-0.1-0.3-0.1
	c-1.4,1.1-2.8,2.1-4.2,3.2C211.6,78,212,78.3,212.4,78.7z"/>
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M209.8,65.2c0,0.2,0,0.3,0.1,0.5c-1,0.7-2,1.4-3,2.1c-1.4,0.9-2.9,1.8-4.3,2.7c-0.5,0.4-0.9,0.9-1.5,1.2
	c-1.1,0.6-1.3,1.4-1,2.5c0.7,2.3,1.5,4.5,2.4,6.8c0.2,0.5,0.6,0.9,0.9,1.3c-1-3-1.9-6-2.9-9c3.7-2,7.6-3.7,9.9-7.6
	c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0.4-0.2,0.8-0.4,1.2c-1.2,2-2.4,4-3.6,6c-1.1,1.8-1,2.4,0.3,4.2c0,0,0.1,0,0.1-0.1
	c-0.3-2.1,0.1-3.9,1.4-5.7c1-1.3,1.6-3,2.4-4.5c0.1-0.2,0.3-0.4,0.5-0.7c0.3,0.3,0.4,0.5,0.3,0.7c0,0.1,0.1,0.1,0.1,0.2
	c0.1,0.4,0.5,0.6,0.7,0.9c0.2,0.3,0.3,0.5,0.4,0.8c0.1,0.3,0.2,0.6,0.3,0.9c1.1,1.7,2.8,3.1,2.6,5.4c1-1,1.1-1.8,0.3-3
	c-1-1.5-2.1-2.8-3.2-4.2c-0.3-0.4-0.7-0.7-1.1-1.2c1.6,0,3.2-1.1,3.7-2.5c0.2-0.4,0.1-1.1-0.2-1.5c-1.3-1.8-1.9-3.8-1.3-6
	c0.1-0.3-0.3-1-0.6-1.1c-1.3-0.4-2.6-0.7-3.7,0.5c-0.3,0.3-0.9,0.3-1.3,0.6c-1.8,1.1-3.6,5-1.6,7.6
	C207.8,65.3,208.6,65.8,209.8,65.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M10.8,86.8c2.1-0.2,2.9-1.5,3.9-3.9c1-2.5,2.3-4.9,3.9-6.9c0.9-1.1,2.8-1.5,4.3-2c0.7-0.3,1.6-0.3,2.4-0.5
	c0,0.1,0.1,0.3,0.1,0.4c-1,0.3-2,0.7-3,0.9c-2.2,0.4-3.7,1.4-4.7,3.5c-1.4,2.7-2.9,5.4-4.6,8c-0.4,0.7-1.5,1.1-2.3,1.5
	c-0.3,0.2-1,0.1-0.8-0.7C10.2,87.1,10.7,86.9,10.8,86.8z" />
        <path d="M27.1,81.1c0.1,0.2,0.1,0.5,0.2,0.7c0.3,0.9,0.4,1.9,0.8,2.8c1,1.9,2.2,3.6,3.4,5.4l0,0c-0.1,0.2-0.2,0.3-0.4,0.6
	c-2-2.4-3.5-5-4.4-7.9C26.6,82.3,27,81.7,27.1,81.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M113.7,40.3c2,0.9,3.7,0.7,4.6-0.3c0.5-0.5,0.7-0.9,0-1.5c-1.1-0.9-2.2-1.9-3.1-3c-0.7-0.9-0.2-2,0.1-2.9
	c1.8-6.1,4.5-11.8,8.4-16.9c1.6-2.1,3.5-4,6-5.3c1.9-1,3.6-2.2,5.5-3.2c1.2-0.6,2.5-1.1,3.7-1.3c2.5-0.3,5-0.4,7.4-0.5
	c1.1,0,2.2,0.2,3.3,0.4c2.9,0.5,3.5,3.2,3.2,5.5c-0.3,3.3-2.3,5.9-3.3,9c-1,3-0.4,5.7,1.2,8.2c1.2,1.8,2.4,3.6,3.5,5.5
	c1.4,2.4,1.1,5,0.8,7.6c-0.8,5.6-3.9,10.1-7.2,14.4c-1.8,2.5-4.5,4.3-7.4,5.5c-1.4,0.6-2.8,1.3-4.2,2.2c0.5-0.1,1-0.1,1.4-0.3
	c2.6-1.2,5.3-2.4,7.9-3.7c0.7-0.4,1.4-0.9,1.9-1.6c1.2-1.6,2.8-1.9,4.5-1.6c2.2,0.3,4.3,0.7,6.5,1.1c2.6,0.5,4.6,2,6.7,3.4
	c2.7,1.8,4.5,4.4,5.7,7.4c0.5,1.2,0.6,2.3-0.2,3.6c-1.3,2.2-2.6,4.3-4.7,5.8c-3.8,2.7-7.9,4.7-12.6,5.3c-3.6,0.5-7.2,0.3-10.7-0.7
	c-2.8-0.8-5.5-1.5-8.3-2.2c-0.1,0-0.1-0.1-0.3-0.2c0.8-2.4,0.6-4.9,0.1-7.4c-0.1-0.4,0.3-0.8,0.5-1.2c0.2-0.4,0.4-0.9,0.4-1.4
	c0-0.2-0.6-0.6-0.9-0.6c-1,0.1-1.4-0.3-1.7-1.3c-1.1-3.2-2.7-6-5.5-8c-1.6-1.2-3.5-1.7-5.5-1.6c-0.3,0-0.7-0.3-1-0.6
	c-0.4-0.3-0.6-0.7-1-1.2c1-2.2,1.6-4.6,0.1-6.8c-1.5-2.3-3.7-3-6.4-2C113.3,45.3,113.5,42.9,113.7,40.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M170.5,74.9c0.5-0.9,0.8-1.6,1.2-2.1c1-1.2,0.9-2.6,0.6-3.9c-0.6-2-1.5-3.7-2.9-5.4c-1.8-2.1-4-3.7-6.4-5.1
	c-0.8-0.4-0.8-0.9-0.6-1.5c1.1-4,3-7.6,5.8-10.7c3-3.4,6.5-5.9,10.8-7.3c1.6-0.5,3.3-0.7,5-1c0.5-0.1,1,0.3,1.5,0.6
	c0.8,0.6,1.1,0.1,1.4-0.5c0.6-1.3,1.2-2.5,1.6-3.8c1-2.9,3-5.1,5-7.4c2.8-3.3,6.6-5.1,10.3-7c2.3-1.2,4.6-2.4,6.7-4
	c1.8-1.4,3-3.3,3.8-5.5c0.4-1,0.9-2,1.3-3c0.5-1,1.7-1.3,3-0.6c1.3,0.7,2.4,1.6,3,3.1c1.3,3,2.7,5.9,3.8,8.9c0.7,2,0.9,4.1,1.3,6.2
	c0.6,3.5-0.4,6.9-1.5,10.2c-0.3,0.8-0.9,1.5-1.3,2.2c-0.4,0.6-0.7,1.1-1.1,1.7c-1.9,2.9-3.5,6-5.7,8.7c-2.1,2.7-4.8,4.9-7.2,7.3
	c-1,1-2.1,1.8-3.2,2.6c-0.2,0.2-0.7,0.3-1,0.3c-2.9-0.1-5.7,0.1-8.4,1c-3,1-5.9,2.2-8.3,4.3c-2.9,2.5-5.7,5.2-6.5,9.2
	c-0.2,0.9-0.7,1.4-1.6,1.8c-2.1,0.9-4.3,1.6-6.7,1.5C173,75.5,171.8,75.2,170.5,74.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M67.9,48.1c-0.3,1.5-1.1,3.1,0.1,5.1c0.3-1,0.5-1.8,0.8-2.5c5.6,2.1,8.5,6.4,9.5,12c0.7,3.7,0.4,7.4-0.6,11
	c-0.3,0.9-0.6,1.9-0.7,2.8c-0.1,0.6,0.1,1.2,0.2,2c-1,0.2-2.1,0.6-3.3,0.7c-4.4,0.3-8.9,0.2-12.9-1.9c-2.4-1.3-4.5-3-6.8-4.5
	c-3-1.9-6-3.9-9.3-5.4c-2.9-1.3-6-1.8-9.2-1.1c-0.1,0-0.3,0-0.5,0c-0.5-3.5-2.6-5.4-6.2-5.9c2.3-1.7,3.6-3.9,4.2-6.5
	c0.5-2.2,0.9-4.5,1.2-6.7c1.2-6.8,3.9-13,8.2-18.5c2.7-3.5,5.9-6.4,10.1-8.1c3.5-1.4,7.1-2.4,10.9-2.3c4.9,0.1,6.6,2,7.2,6.1
	c0.3,1.9,0.2,3.8-0.2,5.6c-0.3,1.5-0.2,3,0.6,4.4c0.5,0.8,0.2,1.3-0.5,1.8c-2.7,1.9-4.5,4.6-5.8,7.5c-0.3,0.6,0.2,1.9,0.7,2.6
	C66.1,47,67.1,47.4,67.9,48.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M99.4,42.1c-1.9,1.7-2,3.9-2.1,6c-0.1,1.5-0.4,3,0.4,4.4c0.3,0.4,0.8,1,1.2,1c0.4,0,0.9-0.5,1.1-1
	c0.4-0.9,0.6-1.9,0.9-2.9c1,1.4,0.9,1.7,0.1,3c-1.7,2.7-3.1,5.7-1.3,8.8c1.2,2.1,2.6,4.2,4.9,5.3c0.5,0.2,1.1,0.3,1.8,0.5
	c-2.8,1.1-5.5,2-8.1,3.1c-0.7,0.3-1.4,1-1.8,1.6c-0.9,1.5-1.5,3.2-2.3,4.9c-3.5-1.2-7.2-2.2-9.2-5.9c-1.6-2.9-3.1-5.9-4.5-8.9
	c-0.6-1.3-0.8-2.8-1.3-4.1c-2-4.9-5-8.8-10.3-10.4c-0.4-0.1-0.9-0.4-1.3-0.6c-2.1-1.2-2.2-1.4-1.3-3.6c2-4.7,5.4-7.7,10.4-8.9
	c1.5-0.4,3.1-0.8,4.6-1c3.1-0.3,6.1,0.5,8.8,2c1.8,1,3.5,2.2,5.2,3.4C96.8,39.7,98,40.9,99.4,42.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M32.8,49.3c-0.5,2.2-1,4.1-1.5,6.1c-0.5,1.9-1.6,3.3-3.1,4.3c-0.3,0.2-0.5,0.4-0.8,0.4
	c-1.2,0.3-2.3,0.5-3.4,0.8c-0.4-1.2-0.5-2.7-1.4-3.6c-0.8-1-2.3-1.4-3.6-2c1-2.9,1.1-6-0.1-9.2c-0.3-0.9-0.8-1.7-1.8-1.6
	c-0.7,0.1-1.4,0.7-2.1,1c1,2,2.1,3.6,2.5,5.3c0.3,1.5-0.3,3.3-0.5,4.9c0,0.2-0.3,0.3-0.5,0.5c-1,0.6-2,1.2-3.1,1.8
	c-0.7-1.1-1.5-2.3-2.4-3.6c-0.3-0.4-0.6-0.8-1-0.9c-1.8-0.3-2.7-1.6-3.8-2.9c-2-2.4-3.5-5.1-3.9-8.2c-0.7-6,0.8-11.1,5.7-14.9
	c0.8-0.6,1.6-1.3,2.5-1.8c3.2-2,6.4-1,8.5,2.4c2.4,3.8,4.2,8,4.9,12.5c0.4,2.6,1.1,5.3,2.8,7.4c0.5,0.7,0.9,1.6,2.2,1.4
	c-0.2-0.3-0.3-0.5-0.5-1C30,48.7,31.3,48.9,32.8,49.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M108,74.8c-0.1,1.3-0.3,2.7-0.4,4c-0.1,0.8-0.1,1.5,0.8,1.9c0.3,0.2,0.4,0.8,0.7,1.2c1.5,2,3,4,4.5,6
	c0.5,0.6,0.4,1-0.1,1.5c-0.5,0.5-1.1,0.9-1.6,1.5c-0.4,0.5-0.9,1.1-0.3,1.8c0.6,0.7,1,0.1,1.5-0.2c1.1-0.6,1.9-1.5,2.2-2.8
	c0.2-0.7,0.7-1.4-0.5-1.9c-0.6-0.2-0.7-1.4-1.2-2.1c-1.2-2-2.5-4-3.9-6.2c1-2.5,0.6-5.9,2.3-8.7c0.3,0.4,0.6,0.7,0.9,0.9
	c1.7,1.8,3.8,3.1,6.2,3.7c0.8,0.2,1.2,0.4,0.9,1.5c-0.5,2.1-0.8,4.2-1,6.3c-0.1,0.6,0,1.5,0.3,1.9c0.5,0.7,0.3,1.2-0.1,1.6
	c-1.9,2-3.7,4.1-5.8,5.9c-1.7,1.5-3.7,2.7-5.7,3.8c-2.2,1.3-3.4,0.7-3.8-1.8c-0.3-2.2-0.5-4.3-0.6-6.5c-0.1-2.2,0-4.3,0.8-6.4
	c0.1-0.2,0.1-0.5,0.1-0.7c-0.3-2.2,1.2-3.7,2-5.4c0.2-0.4,0.9-0.7,1.4-1C107.8,74.6,107.9,74.7,108,74.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M205.9,58.4c-1.1,2.5-1,4.6,0.9,6.7c-1.6,0.3-3,0.5-4.3,0.8c-1.4,0.3-3,0.5-4.2,1.3c-1.1,0.7-1.8,2.1-2.6,3.2
	c-0.6,0.8-1,1.8-1.4,2.7c-0.5,1.1-2.8,1.9-4.3,1.5c-1-0.3-2.1-0.6-3.1-0.9c0,0.1-0.1,0.2-0.1,0.3c0.9,0.4,1.7,0.8,2.9,1.4
	c-1,1.3-1.9,2.7-3,3.8c-1.1,1.1-2.1,1.1-3.3,0.3c-1.1-0.6-1.3-1.8-1.1-2.8c0.8-4.5,2.4-8.5,5.9-11.7c3.8-3.6,8.1-5.7,13.3-6.4
	C202.8,58.6,204.3,58.6,205.9,58.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M114,59.3c-0.7,2.3-1.8,4.2-3.7,5.8c-2.3,1.9-5.5,2-7.5-1.4c-1.6-2.7-1.1-7.3-0.2-9.6c0.4,0.2,0.8,0.5,1.2,0.7
	c0.1-0.1,0.2-0.1,0.3-0.2c-0.4-1.3-0.8-2.6-1.3-3.8c-0.4-0.9-1.1-1.8-1.3-2.7c-0.2-0.9,0.1-1.9,0.3-2.8c0.4,1.4,0.7,2.8,1.2,4.2
	c0.5,1.3,0.8,2.8,1.8,3.8c0.1-0.1,0.3-0.1,0.4-0.2c-0.2-0.6-0.4-1.3-0.5-1.9c-0.1-0.3,0-0.8,0.2-0.9c1.1-0.8,2.1-1.6,3.7-1.5
	c0,0.9,0,1.8,0,2.7c0.1,0,0.2,0.1,0.3,0.1c0.2-0.4,0.5-0.8,0.6-1.1c0-1.8,0-3.6-0.1-5.4c-0.1-2.2-0.6-4.5,0.1-6.7
	c0.7-2.4,3.3-3.4,5.4-2c1.1,0.7,2,1.8,2.9,2.7c0.1,0.1,0.1,0.5,0,0.5c-0.5,0.3-1.1,0.8-1.4,0.7c-0.8-0.4-1.8-0.9-2.3-1.6
	c-0.8-1.4-1.5-2.1-2.6-1.8c-1,0.2-1.8,1.5-1.7,2.7c0.1,3.4,0.1,6.9,0.2,10.3c0.1,2.4,0.3,4.7,0.7,7.1
	C110.8,58.6,112.2,59.3,114,59.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M248.8,36.8c-0.9,1-1.7,2-2.7,3c-2.4,2.4-7.8,5.1-11.2,5.5c0.6-1.1-0.4-1.1-0.9-1.4c-1.8-1.1-3.7-1.8-5.8-1.9
	c-2.8-0.1-4.8,1.3-5.8,3.9c-0.4,1-0.8,2-1.3,2.9c-0.1,0.2-0.4,0.5-0.7,0.6c-2.2,0.5-3.9,1.9-5.5,3.3c-0.7,0.6-1.4,1.2-2.1,1.8
	c-0.2,0.2-0.6,0.2-0.9,0.2c4.3-2.8,6.6-7.2,9.3-11.2c0.6-0.9,1.2-1.8,1.9-2.7c0.2-0.2,0.4-0.5,0.7-0.6c4.2-1.6,8.4-2.8,12.8-3.5
	c3.3-0.5,6.5-0.4,9.7-0.2c0.6,0,1.3-0.1,1.9-0.1C248.6,36.5,248.7,36.6,248.8,36.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M23.9,61.8c1.2-0.3,2.6-0.7,4-0.8c2.4-0.2,4.1,0.9,5.1,3c0.2,0.4,0.4,0.8,0.6,1.3c0.2,0.8,0.7,1.7,0.5,2.4
	c-0.1,0.5-1.2,0.8-1.7,1.3c-1.1,0.9-2.2,1.7-3.2,2.7c-1.7,1.6-3.6,2-5.6,0.7c-1.4-0.9-2-3-1.4-4.5c0.4-1,0.7-1.9,0.9-2.9
	C23.5,64,23.7,63,23.9,61.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M46.9,76.8c-1.7-3.5-4.8-4.8-8.2-5.5c-0.6-0.1-1.1-0.3-0.9-1.2c0.1-0.9,0-1.9,0-2.9c-1,0.2-1.8,0.3-2.5,0.5
	c0-0.1-0.1-0.2-0.1-0.4c0.8-0.2,1.7-0.6,2.5-0.5c2.1,0.2,4.3,0.3,6.1,1.2c3.8,2,7.4,4.4,11.1,6.7c0.2,0.1,0.3,0.7,0.2,1
	c-0.2,1-0.8,1.8-1.9,2.1c-1.1,0.3-1.8-0.4-2.4-1.2c-0.2-0.3-0.5-0.5-0.7-0.8c0.5-0.4,1-0.7,1.4-1.1C49.6,74.7,48.3,75.8,46.9,76.8z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M125.9,77.4c0.4-0.5,0.9-0.9,1.1-1.4c0.3-0.8-0.3-1.3-1.1-1.6c-0.6-0.2-1.3-0.5-1.9-0.4
	c-1.4,0.2-1.9-0.7-2.1-1.7c-0.2-1.6-0.2-3.2-0.2-4.8c0-0.2,0.4-0.4,0.7-0.5c0.7-0.2,1.4-0.3,1.9,0.5c0.7,1,1.4,2,2.2,2.9
	c1.5,2,3.4,2.8,5.7,2.1c1-0.3,1.2,0,1.1,0.8c-0.1,0.9-0.1,1.7-0.2,2.6c-0.1,1.2-0.2,2.5-0.5,3.7c-0.4,1.3-1.4,1.9-2.8,1.9
	c-1.3,0-2.1-0.7-2.6-1.9C126.7,78.6,126.2,77.9,125.9,77.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M228.2,60c0.1,1.5,0.1,3.1-1.1,4.2c-1,0.9-2.5,1.2-3.8,0.5c-1.6-0.9-3.3-1.2-5.1-1.4c-2.2-0.2-3.5-2.6-3.2-4.8
	c0.3-2.4,1.5-4,3.3-5.3c0.7-0.5,1.6-0.6,2.5-0.8c-0.8,1.6-1.9,3.2-1.1,5c0.4,1,1.1,2,1.9,2.6c1,0.6,2.3,1,3.4,1
	C226.1,60.9,227.2,60.3,228.2,60z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M18.1,58c-0.2,0.3-0.4,0.6-0.6,0.9c-1.5,1.6-2,3.4-1.9,5.5c0,1.2,0.6,2,1.6,2.4c1.1,0.4,2.1,0.1,2.9-0.7
	c1.5-1.4,2.4-3.2,2.4-5.3c0-0.3,0-0.6,0.1-0.9c0.4,2.5-0.5,4.9-1.3,7.2c-0.6,1.5-1.1,3-2.6,4c-2.3,1.5-5.4,0.3-6.1-2.3
	c-0.6-2.2-0.3-4.4-0.1-6.7c0.2-2.9,2.4-4,4.4-5.4c-0.1,0.4-0.3,0.8-0.4,1.3C17.1,58,17.7,58,18.1,58L18.1,58z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1}
              fill={this.state.color17 ? this.state.color17 : 'transparent'} onClick={() => {
          this.setState({ color17: color, opacity17: opacity });
        }} d="M121.2,59.5c1.9-0.2,3.7,0.2,5.2,1.5c2.2,1.9,3.5,4.3,4.7,6.9c0.2,0.6,0.6,1,0.9,1.5c-1,0.5-2,0.9-3,1.4
	c-1.1-1.6-2.4-3.5-3.6-5.3c-0.9-1.2-1.4-1.4-3.8-1.1C121.4,62.8,121.3,61.2,121.2,59.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M111.5,50.2c0.1,1.9,0.5,2.4,1.7,2.6c0.9,0.2,1.9-0.6,2.4-1.7c0.7-1.5,0.6-1.8-0.6-2.7c0-0.1,0.1-0.2,0.1-0.3
	c0.8,0.4,1.7,0.7,2.3,1.2c1.1,1,1.7,2.4,1.3,4c-0.2,0.9-0.2,1.8-0.5,2.6c-0.6,1.6-2.7,3.5-4.9,2.6c-0.5-0.2-1.2-0.7-1.4-1.2
	c-0.9-1.8-1.2-3.7-1-5.7C111,51.2,111.3,50.8,111.5,50.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M227.8,56.2c0,1.8-1.4,3.9-2.5,4.2c-1.2,0.3-3.4-0.8-4.2-2c-1.3-2.2-0.1-5.8,2.3-6.7c1.2-0.5,3.3,0.6,3.9,2.2
	C227.5,54.7,227.7,55.7,227.8,56.2z M224.3,52.6c-1.6,1.6-1.4,3.6,0.4,4.3c0.4,0.2,1.1,0,1.5-0.2c0.3-0.2,0.4-0.8,0.4-1.2
	C226.6,54,225.4,53.4,224.3,52.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }} d="M118,61.6c-0.1,0.6,0,1.4-0.3,1.8c-0.9,1.1-2,2-3.1,2.9c-0.4,0.3-0.9,0.4-1.5,0.7c0.4,0.2,0.9,0.5,1.4,0.7
	c-0.3,0.1-0.7,0.4-1,0.4c-1.4-0.2-1.8,0.8-2.1,1.8c-0.9,2.8-1.6,5.7-2.4,8.6c-0.3,1.1,0.5,1.8,1.1,2.6c1.1,1.7,2.2,3.3,3.3,5
	c-0.1,0.1-0.2,0.1-0.3,0.2c-0.8-1.1-1.6-2.2-2.4-3.4c-0.6-0.9-1.2-1.7-1.7-2.7c-0.3-0.6-0.4-1.3-0.4-1.9c0.4-2.8,0.8-5.5,1.3-8.3
	c0.1-0.5,0.5-1,0.8-1.5c0.1-0.2,0.3-0.5,0.5-0.7c-0.1-0.1-0.1-0.2-0.2-0.3c-0.7,0.3-1.6,0.4-2.2,0.9c-2.2,1.4-4.7,1.9-7.2,2.1
	c-0.9,0.1-1.8,0.3-2.7,0.4l0,0c0.4-0.2,0.7-0.6,1.1-0.7c2.6-0.9,5.2-1.8,7.9-2.7c1-0.3,2-0.8,3.1-0.8c2,0,3.4-0.8,4.6-2.2
	C116.4,63.6,117.2,62.6,118,61.6L118,61.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }} d="M16.7,62.7c2-0.2,2.7-0.9,3.1-2.4c0.2-1.1-0.3-1-1-1c-0.2,0-0.4,0.1-0.9,0.3c0.8-1,1.6-1.6,2.5-1.2
	c0.9,0.3,1.4,1.1,1.2,2.2c-0.3,1.8-0.6,3.5-1.9,4.8c-0.6,0.6-1,1.3-2,0.8c-0.9-0.4-1.5-1.6-1.3-2.6C16.4,63.3,16.6,63,16.7,62.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }} d="M101.7,76.9c-1.5,0.2-3.1,0.4-4.9,0.6c0.3-0.7,0.5-1.2,0.7-1.6c0.6-1.1,1.1-2.2,1.8-3.3c0.3-0.6,0.7-1.1,1.5-1
	c1.1,0,2.2-0.2,3.5-0.3C102.5,72.6,101.2,74.2,101.7,76.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity23 ? this.state.opacity23 : 1}
              fill={this.state.color23 ? this.state.color23 : 'transparent'} onClick={() => {
          this.setState({ color23: color, opacity23: opacity });
        }} d="M157.3,85c-1.5,2.7-2.4,5.5-5.3,6.7c-0.7,0.3-1.5,0.4-2.3,0.3c-0.4,0-0.9-0.7-1-1.1c-0.1-0.4,0.2-1,0.6-1.3
	c0.5-0.4,1.2-0.9,1.8-1c2.3-0.4,3.9-1.8,5.5-3.2C156.7,85.3,156.9,85.3,157.3,85z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }} d="M23.2,78.2c1.3-1.5,2.6-3.1,4-4.4c0.7-0.7,1.8-1,2.8-1.2c0.5-0.1,1.1,0.5,1.7,0.9c1,0.5,1.9,1,3,1.6
	c0,0.6,0,1.4,0,2.3c-1.9-0.7-2.8-2.2-4.2-3.7c-0.2,0.3-0.5,0.6-0.6,0.9c-0.7,1.8-1.4,3.7-2.2,5.5c-0.1,0-0.2-0.1-0.3-0.1
	c0.7-2.1,1.4-4.3,2.1-6.4c-2,1.6-4.1,3.3-6.1,4.9C23.3,78.4,23.3,78.3,23.2,78.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1}
              fill={this.state.color25 ? this.state.color25 : 'transparent'} onClick={() => {
          this.setState({ color25: color, opacity25: opacity });
        }} d="M118,61.6c0.5-0.5,0.9-1,1.4-1.5c0.5-0.5,0.8-0.3,0.9,0.3c0.1,0.7,0,1.4,0,2c0,0.7,0.2,1.5,0.2,2.2
	c0.1,1.5,0.1,3.1,0.3,4.6c0.1,1.2,0.3,2.4,0.5,3.6c0.2,1.1,1,1.6,2.1,1.8c0.6,0.1,1.2,0.1,1.7,0.3c0.4,0.2,0.6,0.7,0.9,1
	c-0.5,0.2-1.1,0.6-1.4,0.4c-0.9-0.4-1.7-0.9-2.4-1.5c-1.7-1.5-1.7-3.7-2.1-5.7c-0.5-2.1-0.7-4.3-1-6.5C118.9,62,118.7,61.6,118,61.6
	C118,61.6,118,61.6,118,61.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1}
              fill={this.state.color26 ? this.state.color26 : 'transparent'} onClick={() => {
          this.setState({ color26: color, opacity26: opacity });
        }} d="M30,71.7c1.7-1.9,3.8-3,6.3-3.6c0.2,0,0.6,0.3,0.6,0.5c0,3.8,0,7.6,0,11.4c0,0.1-0.2,0.3-0.5,0.7
	c-0.2-1.8-0.5-3.3-0.6-4.8c-0.1-1.2-0.1-2.3,0-3.5c0.1-0.7,0.5-1.5,0.6-2.2c0.1-0.3,0-0.6,0-0.9c-0.3,0.1-0.7,0.1-0.9,0.2
	c-1.8,0.8-3.6,1.7-5.3,2.5C30.2,71.9,30.1,71.8,30,71.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M178.2,86.3c-0.4,2.1-0.5,4.2-1.1,6.2c-0.2,0.7-1.3,1.2-2.2,1.5c-0.7,0.3-1.3-0.6-1-1.4
	c0.4-0.9,0.8-1.9,1.4-2.7c0.8-1.3,1.7-2.5,2.5-3.7C177.9,86.2,178,86.3,178.2,86.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity28 ? this.state.opacity28 : 1}
              fill={this.state.color28 ? this.state.color28 : 'transparent'} onClick={() => {
          this.setState({ color28: color, opacity28: opacity });
        }} d="M118.9,57.4c0.3,0.5,0.6,1,0.8,1.3c-0.9,0.8-1.7,1.3-2.3,2c-1.1,1.2-2,2.5-3.2,3.7c-0.8,0.8-1.8,1.3-2.8,1.9
	c-0.1-0.1-0.1-0.2-0.2-0.2c0.9-1,1.8-2.1,2.8-3.1c0.1-0.1,0.2-0.2,0.2-0.3C114.6,60,116.6,58.6,118.9,57.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity29 ? this.state.opacity29 : 1}
              fill={this.state.color29 ? this.state.color29 : 'transparent'} onClick={() => {
          this.setState({ color29: color, opacity29: opacity });
        }} d="M111.6,47.7c-0.2,0-0.5,0-0.9,0c0-3.1,0-6.2,0-9.3c0-0.3,0.2-0.6,0.4-0.9c0.3,0.1,0.7,0.2,0.9,0.4
	c0.8,0.7,1,1.4,0.7,2.6C112,42.8,111.9,45.2,111.6,47.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity30 ? this.state.opacity30 : 1}
              fill={this.state.color30 ? this.state.color30 : 'transparent'} onClick={() => {
          this.setState({ color30: color, opacity30: opacity });
        }} d="M98.4,52.4c-0.1-1.3-0.2-2.7-0.2-4c0.1-1.2,0.3-2.4,0.6-3.6c0.2-0.8,0.4-1.9,1.5-1.8c0.5,0,0.9,1,1.4,1.7
	c-1.5,0.3-1.3,1.2-1.5,2.1c-0.4,1.9-0.9,3.8-1.3,5.7C98.8,52.5,98.6,52.5,98.4,52.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity31 ? this.state.opacity31 : 1}
              fill={this.state.color31 ? this.state.color31 : 'transparent'} onClick={() => {
          this.setState({ color31: color, opacity31: opacity });
        }} d="M224,47.7c0.8-1.1,1.6-2.2,2.5-3.2c0.7-0.8,1.8-0.8,2.8-0.4c1.2,0.5,2.3,1,3.7,1.6c-3.1,0.5-6.1,0.6-8.7,2.2
	C224.2,47.9,224.1,47.8,224,47.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity32 ? this.state.opacity32 : 1}
              fill={this.state.color32 ? this.state.color32 : 'transparent'} onClick={() => {
          this.setState({ color32: color, opacity32: opacity });
        }} d="M224.7,50.7c2.1-1,4-2.2,6.2-3.1c1.8-0.7,3.9,0.6,5.1,2.6c0.2,0.4,0.5,0.9,0.7,1.4c0.4,1.1,0.4,1.2-0.6,2.1
	c-1.1-0.3-1.3-1.3-1.3-2.2c0-3.4-3.8-4-5.8-2.7c-1,0.6-2,1.3-3,1.9c-0.3,0.2-0.8,0.3-1.2,0.4C224.8,51,224.7,50.8,224.7,50.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity33 ? this.state.opacity33 : 1}
              fill={this.state.color33 ? this.state.color33 : 'transparent'} onClick={() => {
          this.setState({ color33: color, opacity33: opacity });
        }} d="M189,79.9c0.9-0.4,1.6-0.8,2.4-0.9c0.7-0.1,1-0.3,1.3-0.9c1.3-2.5,2.6-5.1,4-7.6c0.5-0.9,1.3-1.6,1.9-2.4
	c0.1,0.1,0.2,0.1,0.3,0.2c-1,1.9-2.1,3.8-3.1,5.7c-0.9,1.7-2.4,3.1-2.8,5.1c-0.1,0.3-0.5,0.6-0.8,0.8C191,80.5,189.9,80.5,189,79.9z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity34 ? this.state.opacity34 : 1}
              fill={this.state.color34 ? this.state.color34 : 'transparent'} onClick={() => {
          this.setState({ color34: color, opacity34: opacity });
        }} d="M222.1,48.9c1.4-2.1,1.4-5.2,4.6-6c2.4-0.6,4.6,0.1,6.7,1.3c0.2,0.1,0.2,0.4,0.4,0.7c-0.3,0-0.5,0.2-0.8,0.1
	c-0.7-0.2-1.5-0.4-2.1-0.7c-3.9-2.1-6.1-0.1-7.2,2.6c-0.3,0.8-0.8,1.5-1.2,2.3C222.3,49.1,222.2,49,222.1,48.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity35 ? this.state.opacity35 : 1}
              fill={this.state.color35 ? this.state.color35 : 'transparent'} onClick={() => {
          this.setState({ color35: color, opacity35: opacity });
        }} d="M113.9,69.7c1.4,1.6,3.2,2.3,4.9,3c0.5,0.2,1,0.8,1.4,1.1c0,0,0,0.1,0.1,0.1c1.7,1.4,1.9,3,0.6,4.8
	c0.1-0.7,0.2-1.6,0.2-2.4c0.1-1.2-0.8-1.6-1.8-1.9c-2.3-0.6-3.9-2.4-5.4-4C113.9,70.2,113.9,69.9,113.9,69.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity36 ? this.state.opacity36 : 1}
              fill={this.state.color36 ? this.state.color36 : 'transparent'} onClick={() => {
          this.setState({ color36: color, opacity36: opacity });
        }} d="M106.7,70.6c-0.7,0.8-1.8,2-2.8,3.3c-0.3,0.3-0.4,0.8-0.4,1.3c0,1.7,0.1,3.5-0.1,5.2c-0.2,1.5-0.7,2.9-1,4.4
	c-0.1,0-0.3-0.1-0.4-0.1c0.2-0.5,0.3-1,0.5-1.5c0.9-2.2,0.1-4.5,0.2-6.7C102.8,73.5,104.6,71.1,106.7,70.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity37 ? this.state.opacity37 : 1}
              fill={this.state.color37 ? this.state.color37 : 'transparent'} onClick={() => {
          this.setState({ color37: color, opacity37: opacity });
        }} d="M214.6,55.7c-0.5-0.2-0.8-0.4-1.3-0.7c2.3-2,4.6-3.9,7.3-5.1c0.1,0.1,0.2,0.2,0.4,0.4c-0.4,0.5-0.8,1-1.3,1.4
	c-0.9,0.6-2,0.9-2.9,1.6C216,53.8,215.3,54.8,214.6,55.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity38 ? this.state.opacity38 : 1}
              fill={this.state.color38 ? this.state.color38 : 'transparent'} onClick={() => {
          this.setState({ color38: color, opacity38: opacity });
        }} d="M207.8,66c-2.5,2.2-5.5,1.9-8.5,1.7C202,66.6,204.9,66.3,207.8,66z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity39 ? this.state.opacity39 : 1}
              fill={this.state.color39 ? this.state.color39 : 'transparent'} onClick={() => {
          this.setState({ color39: color, opacity39: opacity });
        }} d="M37.7,72.8c0.3-0.1,0.6-0.1,1-0.2c-0.2-0.3-0.4-0.5-0.6-0.8c2.4,0.4,4.5,1.4,6.4,2.9c0,0.1-0.1,0.2-0.1,0.3
	c-2.2-0.6-4.5-1.2-6.7-1.8C37.7,73.1,37.7,73,37.7,72.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity40 ? this.state.opacity40 : 1}
              fill={this.state.color40 ? this.state.color40 : 'transparent'} onClick={() => {
          this.setState({ color40: color, opacity40: opacity });
        }} d="M11.1,56.1C11,56,10.8,56,10.7,55.8c-2.1-2.1-3.8-1-5,1.1c-0.4,0.7-0.8,1.3-1.3,2.1c-0.4-2.5,1.7-5.3,3.9-5.3
	C9.7,53.8,10.8,54.7,11.1,56.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity41 ? this.state.opacity41 : 1}
              fill={this.state.color41 ? this.state.color41 : 'transparent'} onClick={() => {
          this.setState({ color41: color, opacity41: opacity });
        }} d="M18.9,91.1c0.5-0.4,0.9-1,1.4-1.2c1-0.4,1-1.1,1-2c0-1.5,0.1-3.1,0.2-4.6c0-0.9-0.2-1.9-0.1-2.8
	c0-0.5,0.3-1,0.4-1.3c0.2,2.1,0.5,4.2,0.5,6.3c0,1.5-0.4,3.1-1,4.5C20.9,90.6,19,91.2,18.9,91.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity42 ? this.state.opacity42 : 1}
              fill={this.state.color42 ? this.state.color42 : 'transparent'} onClick={() => {
          this.setState({ color42: color, opacity42: opacity });
        }} d="M43.1,84.7c-0.5-1.5-1.1-3-1.5-4.6c-0.3-0.9-0.1-2.1-0.6-2.8c-0.5-0.8-1.4-1.5-2.3-1.8
	c-0.9-0.3-1.2-0.6-0.9-1.5c0.9,0.5,1.9,0.9,2.6,1.6c0.6,0.5,0.9,1.4,1.1,2.2c0.7,2.2,1.3,4.4,1.9,6.5c0,0.1,0,0.3,0,0.4
	C43.3,84.6,43.2,84.7,43.1,84.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity43 ? this.state.opacity43 : 1}
              fill={this.state.color43 ? this.state.color43 : 'transparent'} onClick={() => {
          this.setState({ color43: color, opacity43: opacity });
        }}
              d="M19.2,52c-1-2-1.9-3.7-2.8-5.4c-0.3-0.5-0.7-1,0.1-1.5c0.8-0.4,1.4,0,1.6,0.7C18.5,47.8,18.8,49.8,19.2,52z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity44 ? this.state.opacity44 : 1}
              fill={this.state.color44 ? this.state.color44 : 'transparent'} onClick={() => {
          this.setState({ color44: color, opacity44: opacity });
        }} d="M104.4,75.4c0.9-2.3,2.9-3.3,4.5-4.8C108.4,73.6,107.8,74.3,104.4,75.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity45 ? this.state.opacity45 : 1}
              fill={this.state.color45 ? this.state.color45 : 'transparent'} onClick={() => {
          this.setState({ color45: color, opacity45: opacity });
        }} d="M74,52.5c-1.7-0.8-3.4-1.7-5.2-2.6c0.1-0.4,0.2-0.9,0.4-1.8c1.8,1.5,3.5,2.7,5.1,4C74.2,52.3,74.1,52.4,74,52.5
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity46 ? this.state.opacity46 : 1}
              fill={this.state.color46 ? this.state.color46 : 'transparent'} onClick={() => {
          this.setState({ color46: color, opacity46: opacity });
        }}
              d="M45.3,77.8c-1,0.1-1.8,0.1-2.6,0.2c-0.4-1-0.8-1.9-1.1-2.8C43.5,75.1,43.9,75.3,45.3,77.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity47 ? this.state.opacity47 : 1}
              fill={this.state.color47 ? this.state.color47 : 'transparent'} onClick={() => {
          this.setState({ color47: color, opacity47: opacity });
        }} d="M37.8,75.9c2,0.5,2.5,1.2,2.5,3.2C38.1,79,37.5,78.2,37.8,75.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity48 ? this.state.opacity48 : 1}
              fill={this.state.color48 ? this.state.color48 : 'transparent'} onClick={() => {
          this.setState({ color48: color, opacity48: opacity });
        }} d="M113.9,48.3c0.5,0.7,1,1,1,1.4c0.1,0.8-0.9,2.5-1.5,2.4c-0.4-0.1-1.1-0.8-1.1-1
	C112.7,50.1,113.3,49.4,113.9,48.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity49 ? this.state.opacity49 : 1}
              fill={this.state.color49 ? this.state.color49 : 'transparent'} onClick={() => {
          this.setState({ color49: color, opacity49: opacity });
        }} d="M213.6,67.5c1.1,0.5,2.1,0.9,3.2,1.5c1.2,0.7,1.3,1,0.4,2.2c-0.1,0.2-0.2,0.3-0.4,0.5c-0.3-0.3-0.5-0.7-0.9-1.1
	c0.5,0,0.9,0,1.2,0c-1.2-1-2.5-1.9-3.7-2.9C213.4,67.7,213.5,67.6,213.6,67.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity50 ? this.state.opacity50 : 1}
              fill={this.state.color50 ? this.state.color50 : 'transparent'} onClick={() => {
          this.setState({ color50: color, opacity50: opacity });
        }} d="M98.9,71c-0.4,0.8-0.8,1.6-1.2,2.4c-1.4,2.5-2.8,5.1-4.3,7.5c0.2-0.5,0.4-1,0.6-1.5c1.2-2.4,2.3-4.8,3.6-7.1
	C97.8,71.8,98.4,71.5,98.9,71L98.9,71z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity51 ? this.state.opacity51 : 1}
              fill={this.state.color51 ? this.state.color51 : 'transparent'} onClick={() => {
          this.setState({ color51: color, opacity51: opacity });
        }} d="M114.6,68.7c1.9,0.8,3.8,1.7,5.5,3.4C117.7,71.7,115.5,70.3,114.6,68.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity52 ? this.state.opacity52 : 1}
              fill={this.state.color52 ? this.state.color52 : 'transparent'} onClick={() => {
          this.setState({ color52: color, opacity52: opacity });
        }}
              d="M31.5,90c-1.1-1.8-2.3-3.6-3.4-5.4c-0.5-0.8-0.6-1.8-0.8-2.8c0.9,1.7,1.8,3.3,2.7,5C30.5,87.9,31,89,31.5,90z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1}
              fill={this.state.color53 ? this.state.color53 : 'transparent'} onClick={() => {
          this.setState({ color53: color, opacity53: opacity });
        }} d="M133.9,70.2c-1.1,1.3-2.5,1.8-4.2,1.5C130.8,70.3,132.3,70,133.9,70.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity54 ? this.state.opacity54 : 1}
              fill={this.state.color54 ? this.state.color54 : 'transparent'} onClick={() => {
          this.setState({ color54: color, opacity54: opacity });
        }}
              d="M87.6,82.4c1.6-0.3,3.1-0.5,4.7-0.8c-0.5,1.2-2.9,1.9-4.7,1.2C87.6,82.7,87.6,82.6,87.6,82.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity55 ? this.state.opacity55 : 1}
              fill={this.state.color55 ? this.state.color55 : 'transparent'} onClick={() => {
          this.setState({ color55: color, opacity55: opacity });
        }}
              d="M118.5,67.6c0.2,0.9,0.5,1.7,0.8,2.8c-1.2-0.8-2.3-1.4-3.5-2.1C116.7,68,117.6,67.8,118.5,67.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity56 ? this.state.opacity56 : 1}
              fill={this.state.color56 ? this.state.color56 : 'transparent'} onClick={() => {
          this.setState({ color56: color, opacity56: opacity });
        }} d="M18.2,58c0.4-0.6,0.7-1.5,1.1-1.6c1.1-0.2,2.3,0.8,2.8,2.4C20.8,57.3,19.5,57.7,18.2,58
	C18.1,58,18.2,58,18.2,58z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity57 ? this.state.opacity57 : 1}
              fill={this.state.color57 ? this.state.color57 : 'transparent'} onClick={() => {
          this.setState({ color57: color, opacity57: opacity });
        }} d="M121.6,66.6c0-0.6,0-1,0-1.4c2.1-0.5,2.6,0.1,4.3,3.1C124.8,67.1,124,65.2,121.6,66.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity58 ? this.state.opacity58 : 1}
              fill={this.state.color58 ? this.state.color58 : 'transparent'} onClick={() => {
          this.setState({ color58: color, opacity58: opacity });
        }} d="M53.2,85.6c-1.1,1.4-1.6,1.1-3.1-1.6C51.1,84.6,52.1,85.1,53.2,85.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity59 ? this.state.opacity59 : 1}
              fill={this.state.color59 ? this.state.color59 : 'transparent'} onClick={() => {
          this.setState({ color59: color, opacity59: opacity });
        }} d="M124.2,86.4c-1.6,0.6-2.7,0.2-3-1C123.1,85,123.7,85.2,124.2,86.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity60 ? this.state.opacity60 : 1}
              fill={this.state.color60 ? this.state.color60 : 'transparent'} onClick={() => {
          this.setState({ color60: color, opacity60: opacity });
        }} d="M123.4,77.2c-0.6,1.9-1.2,3.6-1.8,5.3c-0.1,0-0.2,0-0.2-0.1c0.3-1.7,0.7-3.3,1.1-5
	C122.5,77.3,123.1,77.3,123.4,77.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity61 ? this.state.opacity61 : 1}
              fill={this.state.color61 ? this.state.color61 : 'transparent'} onClick={() => {
          this.setState({ color61: color, opacity61: opacity });
        }} d="M203.4,82.2c0,0.3,0.2,0.8,0,1c-0.7,1.1-1.6,2.1-2.8,2.8c-0.1-0.1-0.2-0.2-0.4-0.4c0.8-0.8,1.6-1.6,2.4-2.4
	C203,83,203.2,82.6,203.4,82.2L203.4,82.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity62 ? this.state.opacity62 : 1}
              fill={this.state.color62 ? this.state.color62 : 'transparent'} onClick={() => {
          this.setState({ color62: color, opacity62: opacity });
        }}
              d="M97.9,87.4c1.1-0.7,2.2-1.3,3.4-2c0.1,0.1,0.1,0.2,0.2,0.3C100.3,86.4,100,88.2,97.9,87.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity63 ? this.state.opacity63 : 1}
              fill={this.state.color63 ? this.state.color63 : 'transparent'} onClick={() => {
          this.setState({ color63: color, opacity63: opacity });
        }} d="M48.4,82.5c-1.1-2.1-2.1-4.1-3.2-6.2c0.1-0.1,0.3-0.1,0.4-0.2c1,2.1,2,4.2,3,6.2C48.6,82.4,48.5,82.4,48.4,82.5
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity64 ? this.state.opacity64 : 1}
              fill={this.state.color64 ? this.state.color64 : 'transparent'} onClick={() => {
          this.setState({ color64: color, opacity64: opacity });
        }} d="M114.7,67.2c1.1-0.4,2.2-0.8,3.3-1.2c0.1,0,0.4,0.4,0.7,0.7c-1.4,0.3-2.6,0.6-3.9,0.9
	C114.7,67.5,114.7,67.4,114.7,67.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity65 ? this.state.opacity65 : 1}
              fill={this.state.color65 ? this.state.color65 : 'transparent'} onClick={() => {
          this.setState({ color65: color, opacity65: opacity });
        }} d="M45.6,88c-0.6-0.9-1.9-1.2-1.7-2.6C45.8,86.3,46.3,87,45.6,88z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity66 ? this.state.opacity66 : 1}
              fill={this.state.color66 ? this.state.color66 : 'transparent'} onClick={() => {
          this.setState({ color66: color, opacity66: opacity });
        }}
              d="M34.7,72.8c0,0.4,0,0.8,0,1.4c-1-0.5-1.8-0.9-2.6-1.3c0-0.1,0.1-0.3,0.1-0.4C33,72.6,33.8,72.7,34.7,72.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity67 ? this.state.opacity67 : 1}
              fill={this.state.color67 ? this.state.color67 : 'transparent'} onClick={() => {
          this.setState({ color67: color, opacity67: opacity });
        }} d="M100.7,61.5c-0.9-1.3-0.9-2.3,0-6C100.7,57.5,100.7,59.4,100.7,61.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity68 ? this.state.opacity68 : 1}
              fill={this.state.color68 ? this.state.color68 : 'transparent'} onClick={() => {
          this.setState({ color68: color, opacity68: opacity });
        }} d="M37.1,81.3c0.7,0.8,1.5,1.6,2.2,2.4c-0.2,0.2-0.3,0.3-0.5,0.5c-0.7-0.8-1.4-1.6-2.2-2.4
	C36.8,81.5,36.9,81.4,37.1,81.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity69 ? this.state.opacity69 : 1}
              fill={this.state.color69 ? this.state.color69 : 'transparent'} onClick={() => {
          this.setState({ color69: color, opacity69: opacity });
        }} d="M206.5,85c0.8-0.7,1.5-1.4,2.3-2.1c0.1,0.1,0.1,0.2,0.2,0.2c-0.6,0.9-0.9,2-2.1,2.4
	C206.7,85.3,206.6,85.2,206.5,85z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity70 ? this.state.opacity70 : 1}
              fill={this.state.color70 ? this.state.color70 : 'transparent'} onClick={() => {
          this.setState({ color70: color, opacity70: opacity });
        }} d="M10.9,64.9c0.2-1.4,0.4-2.8,0.6-4.1c0.1,0,0.3,0,0.4,0.1c-0.2,1.4-0.4,2.7-0.6,4.1C11.2,64.9,11,64.9,10.9,64.9
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity71 ? this.state.opacity71 : 1}
              fill={this.state.color71 ? this.state.color71 : 'transparent'} onClick={() => {
          this.setState({ color71: color, opacity71: opacity });
        }} d="M17.3,61.8c0.5-0.7,0.9-1.4,1.4-2.1c0.2,0.1,0.3,0.2,0.5,0.3c-0.4,0.7-0.9,1.5-1.3,2.2
	C17.7,62.1,17.5,62,17.3,61.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity72 ? this.state.opacity72 : 1}
              fill={this.state.color72 ? this.state.color72 : 'transparent'} onClick={() => {
          this.setState({ color72: color, opacity72: opacity });
        }} d="M212.8,75.1c0.7-0.7,1.4-1.4,2.1-2.2C215.1,73.6,214.4,74.5,212.8,75.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity73 ? this.state.opacity73 : 1}
              fill={this.state.color73 ? this.state.color73 : 'transparent'} onClick={() => {
          this.setState({ color73: color, opacity73: opacity });
        }} d="M110.5,50c-0.2-1.9,0-2.1,1.3-1.5C111.4,49,111,49.5,110.5,50z" />
        <path d="M224.3,52.6c1.1,0.8,2.3,1.4,2.3,3c0,0.4-0.1,1-0.4,1.2c-0.4,0.2-1.1,0.4-1.5,0.2C222.9,56.2,222.7,54.1,224.3,52.6z
	 M225.3,56.1c0.2-0.2,0.7-0.7,0.7-0.8c-0.4-0.6-0.9-1.2-1.4-1.7c-0.2,0.4-0.5,0.7-0.6,1.1C223.8,55.5,224.1,56,225.3,56.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity74 ? this.state.opacity74 : 1}
              fill={this.state.color74 ? this.state.color74 : 'transparent'} onClick={() => {
          this.setState({ color74: color, opacity74: opacity });
        }} d="M225.3,56.1c-1.1-0.1-1.5-0.6-1.3-1.4c0.1-0.4,0.4-0.8,0.6-1.1c0.5,0.6,1,1.1,1.4,1.7
	C226,55.4,225.4,55.9,225.3,56.1z" />
        <path className="st1" d="M30.9,89.7c0,0-0.9,2.4-0.6,4.3" />
      </SvgIcon>
    )
  }
};