import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_5_5 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 194.2 184.3"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M22,120.4c-1.2-0.5-2.2,0-2.7,0.6c-1.5,1.8-3,3.6-4.3,5.5c-0.7,1-1.1,2.2-1.6,3.3c-0.5,1.1-0.8,2.2-1.3,3.5
	c-0.4-0.1-0.9-0.2-1.2-0.4c-0.2-0.2-0.5-0.8-0.4-1c1.4-2.6,2.7-5.3,4.3-7.8c0.9-1.4,2.1-2.4,3.3-3.6c1.2-1.3,2.5-2.5,3.8-3.7
	c0.3-0.3,0.6-0.4,1-0.6c3.2-1.8,6.7-1.5,10.2-1.1c2.1,0.2,4.3,0.2,6.4,0.5c3.6,0.4,8.2,3.3,8.7,7.3c0.2,1.4,0.2,2.9-0.1,4.3
	c-0.7,3.4-1.7,6.8-2.6,10.3c-0.1,0.3-0.3,0.5-0.5,0.8c-3.8-2.6-7.8-2.5-12-1.1c0-2,0.7-3.7,1.5-5.5c1-2.1,2.1-4.2,3-6.3
	c0.2-0.5,0-1.1-0.2-1.7c-1.1,2.1-2.1,4.2-3.2,6.3c-1.2,2.1-2.1,4.3-2.2,6.7c0,0.9,0.3,1.8,0.4,2.7c0.1,1.2,0.2,2.4,0.3,3.6
	c0.1,3.2,1.4,4.8,4.5,5.3c1.3,0.2,2.7,0.5,4,0.4c1.3,0,2.6-0.3,4.1-0.6c-0.9,1.6-1.7,3.1-2.7,4.4c-3.5,4.8-8.2,7.8-13.6,9.9
	c-1.1,0.4-2.2,0.6-3.4,0.7c-4.2,0.3-8.4,0.2-12.6-0.8c-1.4-0.3-2.8-1.2-4-2.1c-0.6-0.5-1-1.6-1.1-2.5c-0.5-5.1-0.2-10,3.1-14.3
	c1.5-1.9,1.5-4.4,2.1-6.7c0.6-2.2,1.2-4.4,2.1-6.5c0.6-1.7,1.5-3.2,2.5-4.8c0.9-1.4,1.9-2.7,3-4C20.8,121,21.3,120.8,22,120.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M158.8,37.4c2.6-2.6,4.3-5.9,7.8-7.6c-0.5,2.2-0.3,4,0.9,5.7c1.2,1.7,2.6,3.1,5,2.9c-1.7,3.3-4.1,5.5-7.4,7.1
	c0-0.8,0-1.6,0-2.6c-2.2,3.7-3.5,7.3-3.8,11.3c-0.4,4,1.2,7.6,2.9,11.1c1.2,2.4,2.1,4.9,2,7.6c-2.5-2.3-5.5-1.9-8.5-1.6
	c-2.8,0.3-5.3,1.5-7.7,2.8c-2.8,1.6-5.6,1.6-8.4,0c-0.8-0.4-1.7-0.6-2.6-1c0.3-1.1,0.7-2.2,0.8-3.3c0.1-1.1,0-2.2,0-3.3
	c0-0.6-0.2-1.2,0.1-1.7c1.1-2.3,0.8-4.6,0.1-6.9c-0.3-1.2-0.6-2.4-1-3.5c-0.2-0.5-0.5-0.8-1-1.1c0.5,2.4,1.1,4.7,1.6,7.1
	c-0.1,0.1-0.2,0.1-0.3,0.2c-2.6-1.8-5.3-2.5-8.5-0.6c0.2-1.8,0.2-3.5,0.7-5c1.2-3.5,3.1-6.5,6.2-8.7c0.7-0.5,1.3-1,2-1.4
	c0.5-0.3,0.9-0.2,1.2,0.5c0.4,1.1,0.7,2,0.5,3.2c-0.3,2,2.3,3.7,4.6,3.1c2.4-0.7,4-2.2,5.2-4.3c0.3-0.4,0.4-1.1,0.2-1.6
	c-0.8-2.9,1.2-6.8,4.9-8.3C157.2,37.1,158,36.4,158.8,37.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity3 ? this.state.opacity3 : 1}
              fill={this.state.color3 ? this.state.color3 : 'transparent'} onClick={() => {
          this.setState({ color3: color, opacity3: opacity });
        }} d="M39.4,169.6c-0.2-1.8,0.1-2,1.6-2.3c1.5-0.3,3-0.9,4.5-1.3c4.5-1.2,7.7-4.1,10.5-7.6c2-2.6,3.8-5.3,5.2-8.2
	c0.2-0.5,0.6-0.9,1-1.5c-1.5,0.1-2.2,1-2.9,2c-1.6,2.4-3.2,4.9-4.9,7.2c-3,3.9-6.9,6.4-11.6,7.7c-2.4,0.6-4.7,0.9-7.1,0.9
	c-2.6,0-5.3,0.2-7.9,0.2c-2.8,0-5.4-0.6-7.8-2c-0.2-0.1-0.4-0.3-0.6-0.7c9,0.9,16.3-2.5,22.4-8.8c0.9,1.4-0.2,3.2,1.1,4.8
	c0.5-1.8,0.6-3.4,0.2-5.1c-0.1-0.4-0.1-0.8,0-1.1c1.2-2,2.8-3.8,3.5-6c0.5-1.6,1.7-2.1,2.7-3c0.5-0.5,0.8-1.3,1.2-1.9
	c0.1-0.2,0.3-0.5,0.5-0.7c1.7-1.1,3.5-2.1,5.3-3.1c2,2.8,0.9,6.6,2.4,9.8c0.8-3.6,0.1-7.1-1.6-10.4c2.1-0.8,4-1.4,6.1-1
	c2,0.4,3.2,2.1,3.8,4.7c0.7,2.9,0.2,5.7-0.9,8.4c-0.4,0.9-1,1.7-1.5,2.5c-2.4,3.5-4.9,6.8-8,9.7c-1.6,1.5-3.6,2.7-5.5,4
	c-0.3,0.2-0.7,0.2-1.5,0.4c0.6,0.5,1.1,0.9,1.4,1.2c2-1.4,3.9-2.7,6.1-4.2c0.1,1,0.2,1.9,0.3,2.7C51.7,169.4,45.7,170.1,39.4,169.6z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity4 ? this.state.opacity4 : 1}
              fill={this.state.color4 ? this.state.color4 : 'transparent'} onClick={() => {
          this.setState({ color4: color, opacity4: opacity });
        }} d="M164.9,92.9c-0.8-2-0.8-4.4-2.9-5.8l0.3-0.2c0.6,0.5,1.2,0.9,1.9,1.5c0.3-1.9,0.6-3.7,0.9-5.5
	c0.4-2.8,0.2-5.6-0.6-8.4c-0.5-1.8-1.6-2.7-3.3-2.5c-1.4,0.1-2.9,0.2-4.2,0.6c-2.1,0.7-4.1,1.5-6.1,2.5c-3,1.5-5.9,1.6-8.9,0.2
	c-0.8-0.3-1.5-0.7-2.3-0.9c-0.7-0.2-1.2-0.1-0.9,0.9c0.2,0.6,0.4,1.2,0.6,1.9c1.6,0.4,1.6,0.3,1.6,0.4c0.7,0.3,1.4,0.8,1.8,1.6
	c0.5,0.8,0.9,1.6,1.2,2.4c0.5,1.1,1.2,1.8,2.1,2.2c1.6,0.2,1.5,0.1,1.6,0.2c0,0,0,0,0.1,0c-0.2-1.3-0.5-2.7-0.7-4
	c1.1-0.1,1.2,0.6,1.4,1.2c0.4,1.3,0.6,2.6,1.1,3.9c0.6,1.7,1.4,3.2,3,4.6c0.2-1.1,0.3-2,0.5-3c1.4,0.7,0.9,1.9,0.7,2.8
	c-0.7,3.6-1.4,7.3-1.1,11c0.1,1.4,0.4,2.9,0.7,4.3c0.1,0,0.2,0,0.3,0c0.2-0.4,0.3-0.8,0.5-1.2c0.7-0.9,1.2-2.2,2.1-2.6
	c1.7-0.9,3.1-1.9,4.5-3.3c1.3-1.3,3.2-2,4.9-2.9C165.4,94.3,165.2,93.6,164.9,92.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity5 ? this.state.opacity5 : 1}
              fill={this.state.color5 ? this.state.color5 : 'transparent'} onClick={() => {
          this.setState({ color5: color, opacity5: opacity });
        }} d="M29.4,89.5c-0.2,0.3-0.4,0.7-0.6,1.1c0.3,0.3,0.6,0.6,0.9,1c1.7-1.4,3.5-2.5,5.7-2.7c1.7-0.2,3.4-0.5,5.1-0.5
	c1.7,0,3.5,0.2,5.1,0.8c2,0.9,2.6,3,3,5c0.1,0.8-0.2,1.7-0.3,2.4c-1.7-0.4-3.1-0.8-4.5-1.1c2.1,2.4,1.5,4.9,0.2,7.2
	c-0.9-2.5-3.2-2.2-5.1-2.8c-1.7,2-1.6,4.5,0.5,7.3c-1.1,1.2-2.2,2.6-3.5,3.8c-0.3,0.3-1.1,0.4-1.6,0.3c-2-0.3-4-0.7-6-1
	c-1.3-0.2-2.9-2.2-2.8-3.9c0.1-1.2,0.5-2.5,0.8-3.9c-0.5-0.1-1.1-0.2-1.9-0.4c0-1.2,0.7-2.2,1.6-3.1c-0.9-0.6-0.9-0.6-3.4,0.4
	c0.5-0.7,0.8-1.3,1.3-1.8c0.7-0.7,1.5-1.3,2.1-2c0.2-0.2,0.1-0.8-0.1-0.9c-0.4-0.3-0.9-0.3-1.4-0.4c0-0.1-0.1-0.2-0.1-0.3
	c0.8-0.3,1.6-0.6,2.3-1c-0.7-1-0.5-1.5,0.8-2c0.4-0.2,0.8-0.7,1.2-1c0.2-0.2,0.5-0.4,0.8-0.6C29.3,89.3,29.4,89.4,29.4,89.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity6 ? this.state.opacity6 : 1}
              fill={this.state.color6 ? this.state.color6 : 'transparent'} onClick={() => {
          this.setState({ color6: color, opacity6: opacity });
        }} d="M152.3,29.5c1.3,0.2,1.4,0.7,1,1.5c-0.5,0.9-1.3,0.9-2.6,0.1c0.3,3.5,0,7-2.9,9.4c-3.8,3.2-8.6,4-12.8,0.8
	c-2.6-2-3-7.2-1.2-9.1c1.3,0.6,2.6,1.4,3.9,1.8c0.6,0.2,1.5-0.3,2.2-0.4c0-0.2,0-0.3,0-0.5c-0.5,0-1-0.1-1.5-0.1
	c-0.6,0-1.2,0-1.8-0.1c0.3-0.6,0.4-1.3,0.8-1.9c0.5-0.8,1.1-1.5,1.6-2.4c0.7-1.2,0.3-2.4-0.8-3c0.1,0.6,0.2,1.3,0.3,2.2
	c-0.6-0.3-0.9-0.4-1.1-0.6c-0.5-0.3-0.9-0.7-1.3-1c0.5-0.4,0.9-0.8,1.5-1.1c0.3-0.2,0.6-0.3,1-0.4c0-0.1,0-0.2,0-0.3
	c-0.6-0.1-1.2-0.1-1.8-0.2c-0.5-2.5,0.7-4.1,3.9-4.5c2-0.3,4,0,5.8,0.8c2.3,1.1,2.9,2.9,1.9,5.2c-0.5,1.1-0.5,2.5-0.6,3.7
	c0,0.3,0.7,1.1,1,1c0.6-0.1,1.3-0.4,1.7-0.9c0.3-0.4,0.2-1.1,0.2-1.6c0-1.7,0.8-3,2.2-3.8c0.5-0.3,1.4-0.4,1.8-0.2
	c0.8,0.5,0.9,1.8,0.3,2.6c-0.4,0.6-1,1-1.5,1.5C153.1,28.4,152.8,28.8,152.3,29.5z M142.6,33.3c0.6-0.1,1.5-0.2,2.4-0.4
	c0.3-0.1,0.7-0.4,0.7-0.6c-0.1-1.6-0.1-3.3-1.2-4.6c-0.9-1-2.3-1.2-3.1-0.4c-0.8,1-1.5,2-1.6,3.4C139.7,32.5,140.3,33.3,142.6,33.3z
	 M136,36.7c0.7,0.6,1.5,1,1.9,1.5c1.4,2.1,4,2.8,5.5,0.3c0.2-0.4,0.9-0.5,1.6-0.9c-0.4-0.4-0.8-1-1.2-1.5
	C141.1,37.9,138.6,36.7,136,36.7z M146.7,26.4c-0.7-1.5-2.9-2.2-5.4-1.9C143.1,25.1,144.8,25.8,146.7,26.4z M152.9,25.6
	c-0.4,0.5-0.9,1-1.3,1.6c-0.2,0.2-0.3,0.8-0.3,0.8c1.6,0.5-0.3,1,0.1,1.7c0.3-0.4,0.7-0.7,0.8-1.1C152.5,27.7,152.7,26.8,152.9,25.6
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity7 ? this.state.opacity7 : 1}
              fill={this.state.color7 ? this.state.color7 : 'transparent'} onClick={() => {
          this.setState({ color7: color, opacity7: opacity });
        }} d="M180.8,12.8c-0.2-1.2-0.3-1.3-3.1-2.2c1.1-2.1,0-5,2.6-6.7c1,1.5,0.1,2.8,0.2,4c-0.3,0.1-0.6,0.1-0.9,0.2
	c0,0.1,0,0.2,0,0.2c2.1,0.9,3.5,2.6,4.8,4.6c0.6-2,2.1-0.6,3.2-1.5c-0.3,1.4-0.8,2.3-2.1,2.2c-0.8,0-1.2,0.3-1.4,1.2
	c-0.7,3-2.3,4.6-5.1,5.4c0.1,0.3,0.4,0.6,0.3,0.8c-0.9,3-1.8,6.1-2.8,9.1c-0.2,0.5-0.4,1-0.8,1.5c-1,1.4-1.8,2.8-2.4,4.4
	c-0.7,1.8-2.3,2.4-3.9,0.9c-2.2-2-2.9-4.6-1.7-6.8c0.2-0.4,0.9-0.5,1.2-1c0.7-1.2,1.3-2.5,2-3.7c1.2-2.1,2.5-4.2,3.8-6.3
	c0.6-1,1.2-1.9,1.7-2.7c-2-1.7-3.8-3.2-5.9-4.9c1.1-0.4,1.9-0.9,2.7-0.9c0.6,0,1.3,0.5,2,0.8c0.4,0.2,0.9,0.5,1.2,0.4
	c1.4-0.5,2.6-0.1,3.6,0.9c0.1,0.1,0.3,0.2,0.4,0.3C180.7,12.9,180.8,12.8,180.8,12.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity8 ? this.state.opacity8 : 1}
              fill={this.state.color8 ? this.state.color8 : 'transparent'} onClick={() => {
          this.setState({ color8: color, opacity8: opacity });
        }} d="M50.1,135.4c-0.9,0-1.8,0-2.9,0c0.4-1.2,0.6-2.3,1-3.2c0.3-0.8,1.3-0.9,2-0.2c0.7,0.6,1.2,0.6,2,0.4
	c1.3-0.4,1.7-0.1,2.2,1.2c0.2,0.6,0.7,1,1,1.5c0.3,0.7,0.6,1.4,0.9,2.2c-1-0.3-1.6-0.5-2.3-0.6c-0.4,0-0.8,0.3-1.1,0.6
	c-0.3,0.4-0.4,0.9-0.5,1.4c-0.2,0.9-0.3,1.7-1.5,1.9c-0.6,0.1-1.1,0.8-1.5,1.4c-1.9,3.2-4.8,4.9-8.3,5.6c-2.2,0.4-4.3,0-6.3-0.9
	c-0.6-0.3-1-1.5-1.2-2.3c-0.2-1.1-0.1-2.3-0.1-3.4c0-0.3,0-0.5-0.1-0.8c-0.3-1.6,0.3-2.5,2-2.8c2.2-0.4,4.4-0.7,6.6,0
	c0.6,0.2,1.2,0.6,1.8,0.9c0.6,0.3,0.9,0.7,0.6,1.5c-0.1,0.4,0,0.9,0.1,1.4c0.1,0,0.3,0.1,0.4,0.1c0.3-0.5,0.7-0.9,0.9-1.4
	c0.3-0.7,0.4-1.4,0.6-2.1c0.3-0.9,0.8-1.4,1.7-1.6c0.7-0.1,1.4-0.3,2-0.4C50.1,135.7,50.1,135.6,50.1,135.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity9 ? this.state.opacity9 : 1}
              fill={this.state.color9 ? this.state.color9 : 'transparent'} onClick={() => {
          this.setState({ color9: color, opacity9: opacity });
        }} d="M138.3,76.9c-1,0-1.8,0-2.5,0c-1,0-1.2,0.3-0.7,1.1c0.4,0.7,1,1.4,1.5,2.1c-1,1.4-2.2,1.2-2.8-0.4
	c-0.3-0.7-0.6-1.1-1.4-0.6c0.3,0.4,0.5,0.8,0.8,1.1c0.2,0.3,0.5,0.6,0.8,0.9c-1.1,1.7-1.7,1.7-2.8,0c-0.3-0.4-0.7-0.7-1.1-1.1
	c-0.1,0.1-0.2,0.2-0.3,0.3c0.7,0.9,1.3,1.7,2,2.6c-0.8,0.5-1.6,0.3-2.1-0.2c-1.4-1.2-1.8-4.5-0.9-6.4c0.7-1.5,3-2.8,4.8-2.6
	c0.6,0.1,1.2,0.3,1.8,0.5c0-0.1,0.1-0.3,0.1-0.4c-1.2-0.3-2.3-0.5-3.6-0.8c-1.2-3-0.1-5.7,2-8.1c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.5,0.2-1.1,0.4-1.6,0.7c-1.3-1.7-0.2-5.3,2.5-5.6c2.2-0.3,4.3,0.4,5,2.3c0.3,0.8-0.1,1.8-0.1,2.7c0,0.3-0.2,0.5-0.1,0.8
	c0.7,2.8-0.3,5.3-1.2,7.9c-0.2,0.6-0.2,1,0.1,1.5C138,75.3,138.1,76.1,138.3,76.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity10 ? this.state.opacity10 : 1}
              fill={this.state.color10 ? this.state.color10 : 'transparent'} onClick={() => {
          this.setState({ color10: color, opacity10: opacity });
        }} d="M151.8,35.9c0-1.1,0-2.3,0-3.4c2.2,0,2.8-1,2-3.5c1.1-0.8,2.2-1.7,2.1-3.3c0-1.2-0.5-2.1-1.7-2.7
	c-1.1-0.5-2,0.1-2.7,0.7c-0.6,0.5-1,1.2-1.3,1.8c-0.4,1.1-0.5,2.2-0.8,3.4c-0.1,0.3-0.4,0.6-0.6,0.8c-0.2-0.1-0.4-0.2-0.5-0.3
	c0.3-1.2,0.6-2.4,1-3.5c0.7-1.9-0.1-4.5-2.1-5.6c-3.5-1.9-7.1-2.4-10.8-0.3c-0.7,0.4-1.3,0.4-1.9-0.1c-0.8-0.7-0.9-1.3-0.6-2.4
	c1,0.4,2.1,0.7,3.1,1.1c0.1-0.1,0.2-0.2,0.2-0.3c-1.6-1.5-1.1-3.2-0.4-5c0.9,1.4,1.2,3.1,3.5,3c-1.1-1-0.7-1.6,0.3-2.2
	c0.3,0.5,0.7,1,1.2,1.7c0.5-2.4,1.6-4.1,3.2-5.8c0.2,1.7-0.3,3.4,0.6,4.8c0.4-0.5,0.8-0.9,1.1-1.3c0.1,0.1,0.1,0.1,0.2,0.2
	c0,0.7-0.1,1.3-0.1,2c0.1,0,0.2,0,0.3,0c0.4-0.6,0.8-1.3,1.2-1.9c0.1,0.1,0.2,0.1,0.3,0.2c0,0.8-1.2,1.5,0,2.3
	c0.6-0.5,1.2-1,2.2-1.7c-0.1,0.6-0.2,1-0.2,1.3c-0.1,0.9,0.3,1.3,1.3,1.1c0.8-0.2,1.6-0.4,2.5-0.5c0.8-0.1,1.3,0.2,1,1.2
	c-0.1,0.3,0.2,1.1,0.4,1.2c1.8,0.7,2.1,2.2,2.2,3.9c0.2,2.4-1,4.4-2.1,6.5c-0.7,1.2-1.2,2.6-1.9,3.8c-0.6,1.1-1.4,2-2,3
	C152,36,151.9,35.9,151.8,35.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity11 ? this.state.opacity11 : 1}
              fill={this.state.color11 ? this.state.color11 : 'transparent'} onClick={() => {
          this.setState({ color11: color, opacity11: opacity });
        }} d="M168.9,96.2c0.8,0.1,1.6,0.1,2.3,0.4c1.6,0.5,2.1,2,1.5,3.6c-1.1,2.6-2.5,4.9-4.9,6.3c-2.8,1.6-4.6,4.2-7,6.1
	c-1.7,1.4-3.5,1.2-5.4,0.6c-0.8-0.3-1-0.8-0.4-1.5c1-1.1,1.9-2.4,3-3.4c1.4-1.3,2-2.9,2.7-4.6c0.9-2.4,2.6-4.3,4.6-6
	C166.3,96.7,167.4,96.1,168.9,96.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity12 ? this.state.opacity12 : 1}
              fill={this.state.color12 ? this.state.color12 : 'transparent'} onClick={() => {
          this.setState({ color12: color, opacity12: opacity });
        }} d="M41.7,115.2c0.1-1.2,0.3-2.1,0.4-3c-0.1,0-0.2,0-0.3-0.1c-0.4,0.9-0.8,1.8-1.2,2.7c-1.4-1-2.8-1.9-4.1-2.8
	c1.5-1.1,3.1-2.3,3.6-4.4c0.9-0.1,2.1,0.7,2.4-0.9c-0.1-0.1-0.2-0.1-0.3-0.1c-2.2,0.8-3,0.4-3.4-1.9c-0.2-0.9-0.1-1.8,0.2-2.7
	c0.3-1.1,1.4-1.1,2.3-1.1c1.1,0,1.9,0.7,2,1.7c0.1,0.9,0.6,1.1,1.2,1c0.4-0.1,0.7-0.5,0.9-0.9c1-2.1,0.9-4.1-0.2-6.2
	c1.3-0.3,1.8-0.1,1.9,1.1c0.1,1.3-0.1,2.5-0.2,3.8c-0.1,0.7,0.2,1,0.6,1.5c0.5,0.7,0.8,1.7,0.9,2.6c0.6,3.6-0.7,6.8-2.7,9.7
	c-0.7,1-1.4,1.5-2.6,0.6C42.6,115.5,42,115.4,41.7,115.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity13 ? this.state.opacity13 : 1}
              fill={this.state.color13 ? this.state.color13 : 'transparent'} onClick={() => {
          this.setState({ color13: color, opacity13: opacity });
        }} d="M39.7,170.6c1.9,0,3.7,0,5.6,0c3.9,0.1,7.6-0.6,11.1-2.1c0.5-0.2,1.1-0.4,1.4-0.8c0.6-0.6,1.1-0.9,1.9-0.6
	c1,0.4,1.9,0.9,2.2,2c0.3,0.9,0.3,1.5-0.7,2.1c-2.4,1.6-5,2.1-7.7,2.4c-4,0.4-8,0.3-12-0.5C40,172.9,39.6,172.3,39.7,170.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity14 ? this.state.opacity14 : 1}
              fill={this.state.color14 ? this.state.color14 : 'transparent'} onClick={() => {
          this.setState({ color14: color, opacity14: opacity });
        }} d="M150.6,38.4c0.3,0.8,0.5,1.3,0.7,1.9c0.1,0.3,0,0.7,0,1c-0.2,1.2-0.8,2.3-0.7,3.4c0.3,2.5-1.1,3.9-2.8,5.3
	c-1.1,0.9-2.3,1.4-3.8,1.1c-1.4-0.3-2-1.2-1.5-2.6c0.3,0.4,0.5,0.6,0.8,0.9c0.2,0.3,0.4,0.5,0.6,0.8c0.1-0.1,0.2-0.1,0.3-0.2
	c-0.5-0.9-1-1.8-1.5-2.6c-0.2-0.3-0.4-0.5-0.5-0.8c-0.8-1.6-0.6-1.8,1.1-2.3c2.6-0.7,4.9-1.9,6.4-4.3C150,39.5,150.3,39,150.6,38.4z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity15 ? this.state.opacity15 : 1}
              fill={this.state.color15 ? this.state.color15 : 'transparent'} onClick={() => {
          this.setState({ color15: color, opacity15: opacity });
        }} d="M111.6,98.3c0.1,0.1,0.3,0,0.5,0c0.5-0.3,1-0.6,1.6-0.9c-0.4-1.4-1.2-2.6-1-3.7c0.4-2.5-1.2-3.8-2.3-5.4
	c-0.6-0.8-1.8-0.9-2.6-0.4c-0.5,0.3-1.1,1.1-1.1,1.6c0.2,1.7,0.7,3.3,1.2,5C108.4,96.4,110.4,96.9,111.6,98.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity16 ? this.state.opacity16 : 1}
              fill={this.state.color16 ? this.state.color16 : 'transparent'} onClick={() => {
          this.setState({ color16: color, opacity16: opacity });
        }} d="M38.7,114.5c-1.7-0.1-3.4-0.3-5.2-0.4c-1.9-0.1-3.7-0.3-5.6-0.4c-0.8,0-1-0.3-0.7-1.1c0.4-1,0.9-1.6,2.1-1.6
	c3.5,0.1,6.6,1.1,9.5,3.2C38.7,114.3,38.7,114.4,38.7,114.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity17 ? this.state.opacity17 : 1}
              fill={this.state.color17 ? this.state.color17 : 'transparent'} onClick={() => {
          this.setState({ color17: color, opacity17: opacity });
        }} d="M153.5,111c-0.4-2.1-0.1-3.2,0.9-3.3c-0.1-1.3,0.7-3,2-3.5c1.3-0.6,2.1-1.5,2.9-2.7c1.2-1.9,2.3-4,5-4.9
	c-0.6,0.6-1,1-1.3,1.4c-0.4,0.4-0.9,0.8-1.2,1.3c-0.9,1.3-2,2.6-2.5,4c-0.8,2.1-2,4-3.7,5.4C154.9,109.3,154.3,110.1,153.5,111z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity18 ? this.state.opacity18 : 1}
              fill={this.state.color18 ? this.state.color18 : 'transparent'} onClick={() => {
          this.setState({ color18: color, opacity18: opacity });
        }} d="M39.9,173.5c7.4,1.6,14.8,2,22-0.8c-0.8,1.1-2,1.5-3.1,1.9c-3.4,1-6.9,1.6-10.5,1.3c-2.4-0.2-4.8-0.6-7.2-1
	C40.3,174.8,39.7,174.4,39.9,173.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity19 ? this.state.opacity19 : 1}
              fill={this.state.color19 ? this.state.color19 : 'transparent'} onClick={() => {
          this.setState({ color19: color, opacity19: opacity });
        }} d="M137.5,82.6c-0.2,0.1-0.4,0.1-0.5,0c-1.3,0.1-2.4,0.2-3.5,0.3c-0.3,0-0.6,0.2-0.8,0.3c-0.4,0.2-0.9,0.5-1.3,0.5
	c-0.6,0-1.1-0.1-1.8-0.3c0.1,0.1,0.1,0.2,0.1,0.3c-0.1-0.1-0.1-0.2-0.1-0.3c-1.1-0.8-1.4-1.3-1.7-2.4c-0.3-1-0.6-3.9-0.7-4.4
	c-0.3,0.1-0.7,0.2-1,0.4c-0.6,0.4-1.1,0.9-1.6,1.4c-0.9,0.8-2,1.5-2.5,2.5c-0.9,2-1.5,4.1-2.3,6.2c-0.1,0.3-0.5,0.5-0.9,0.9
	c0-0.9,0.1-1.4,0.1-2.1c-2.7,1.3-3.9,3.8-5.1,6.3c-0.7,1.5-0.4,3.2,0.8,4.5c0.2-0.1,0.3-0.2,0.5-0.3c0-0.1,0.1-0.2,0.2-0.3
	c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0.2-0.1,0.4-0.1,0.6-0.3c0,0,0,0,0.1,0c3.2-2.1,6.4-4.2,9.6-6.3
	c3.6-2.1,4.2-2.5,6-3.6C133.7,84.8,135.6,83.7,137.5,82.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity20 ? this.state.opacity20 : 1}
              fill={this.state.color20 ? this.state.color20 : 'transparent'} onClick={() => {
          this.setState({ color20: color, opacity20: opacity });
        }} d="M181.2,8.5c0.3-0.9,0.6-1.9,1.1-2.9c0.3-0.5,0.8-0.9,1.2-1.3c0.1,0.6,0.3,1.2,0.2,1.7c-0.2,0.8-0.6,1.6-0.8,2.5
	c-0.1,0.2,0,0.5,0,0.8c0.3-0.1,0.6-0.1,0.8-0.3c0.6-0.5,1.2-1.1,1.9-1.8c0.4,1.7,0,2.4-1.6,3.7C183.1,10.1,182.2,9.3,181.2,8.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity21 ? this.state.opacity21 : 1}
              fill={this.state.color21 ? this.state.color21 : 'transparent'} onClick={() => {
          this.setState({ color21: color, opacity21: opacity });
        }} d="M109.9,99.7c0.4-0.2,0.7-0.5,1.1-0.7c0,0,0.1-0.1,0.1-0.1c-1.1-1-2.2-1.8-3.1-2.8c-0.8-1-1.3-2.3-2-3.5
	c0,2.5,1,4.5,2.7,6.2C109.3,99.5,109.6,99.7,109.9,99.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity22 ? this.state.opacity22 : 1}
              fill={this.state.color22 ? this.state.color22 : 'transparent'} onClick={() => {
          this.setState({ color22: color, opacity22: opacity });
        }} d="M10.5,140.2c-1-1.9-0.9-5.4,0.1-6.4c0.8,0.4,1.1,0.8,0.8,1.8c-0.3,1-0.4,2.2-0.6,3.3
	C10.8,139.3,10.6,139.7,10.5,140.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity23 ? this.state.opacity23 : 1}
              fill={this.state.color23 ? this.state.color23 : 'transparent'} onClick={() => {
          this.setState({ color23: color, opacity23: opacity });
        }} d="M137,29.7c-0.2-0.4-0.4-0.7-0.6-1c-0.3,0.2-0.7,0.3-0.7,0.5c-0.2,0.5-0.2,1-0.2,1.6c0,0.4,0.1,0.9,0.2,1.3
	c-0.2,0-0.4,0.1-0.6,0.1c-0.1-0.5-0.3-1-0.3-1.5c0.1-0.7,0.3-1.4,0.6-2c0.3-0.7,0.8-1.4,1.7-0.8C138.2,28.4,137.5,29,137,29.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity24 ? this.state.opacity24 : 1}
              fill={this.state.color24 ? this.state.color24 : 'transparent'} onClick={() => {
          this.setState({ color24: color, opacity24: opacity });
        }} d="M48.5,102.6c1.8,0.8,1.9,1.1,0.9,2.3C49,104.1,48.8,103.4,48.5,102.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity25 ? this.state.opacity25 : 1}
              fill={this.state.color25 ? this.state.color25 : 'transparent'} onClick={() => {
          this.setState({ color25: color, opacity25: opacity });
        }} d="M164.7,72.7c0.5,0.8,1,1.7,1.5,2.5c-0.1,0.1-0.3,0.2-0.4,0.2c-0.5-0.8-0.9-1.7-1.4-2.5
	C164.5,72.9,164.6,72.8,164.7,72.7z" />
        <path d="M142.6,33.3c-2.2,0-2.9-0.7-2.8-2.6c0-1.4,0.7-2.4,1.6-3.4c0.7-0.8,2.2-0.6,3.1,0.4c1.1,1.3,1,3,1.2,4.6
	c0,0.2-0.4,0.5-0.7,0.6C144,33.1,143.1,33.2,142.6,33.3z M141.9,32.5c0.6,0,1.3,0,1.9-0.2c0.2,0,0.7-0.4,0.7-0.5
	c-0.2-1.3-0.4-2.7-0.9-3.9c-0.4-0.9-1.6-0.8-2.1,0.1c-0.5,0.8-0.8,1.8-1,2.7C140.3,32.2,140.6,32.5,141.9,32.5z" />
        <path d="M136,36.7c2.6,0,5.1,1.2,7.7-0.6c0.4,0.5,0.9,1.1,1.2,1.5c-0.7,0.4-1.3,0.5-1.6,0.9c-1.5,2.6-4.1,1.8-5.5-0.3
	C137.5,37.7,136.7,37.3,136,36.7z M142.1,37.9c-1.3-0.1-2.5-0.2-3.8-0.3C139.6,40,140.5,40,142.1,37.9z" />
        <path d="M146.7,26.4c-1.9-0.7-3.6-1.3-5.4-1.9C143.7,24.2,146,25,146.7,26.4z" />
        <path d="M152.9,25.6c-0.2,1.1-0.4,2.1-0.7,3c-0.1,0.4-0.5,0.7-0.8,1.1c-0.5-0.7,1.5-1.1-0.1-1.7c0,0,0.1-0.6,0.3-0.8
	C152,26.7,152.5,26.2,152.9,25.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity26 ? this.state.opacity26 : 1}
              fill={this.state.color26 ? this.state.color26 : 'transparent'} onClick={() => {
          this.setState({ color26: color, opacity26: opacity });
        }} d="M141.9,32.5c-1.4,0-1.7-0.3-1.4-1.7c0.2-0.9,0.5-1.9,1-2.7c0.5-0.9,1.8-1,2.1-0.1c0.5,1.2,0.6,2.6,0.9,3.9
	c0,0.1-0.4,0.4-0.7,0.5C143.2,32.4,142.6,32.4,141.9,32.5z M142.3,31.4c0.2-0.6,0.4-1.3,0.5-1.9c0-0.2-0.3-0.6-0.5-0.7
	c-0.2,0-0.6,0.2-0.7,0.5C141.1,30,141.2,30.7,142.3,31.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity27 ? this.state.opacity27 : 1}
              fill={this.state.color27 ? this.state.color27 : 'transparent'} onClick={() => {
          this.setState({ color27: color, opacity27: opacity });
        }} d="M142.1,37.9c-1.5,2.1-2.4,2.1-3.8-0.3C139.5,37.7,140.7,37.8,142.1,37.9z" />
      </SvgIcon>
    )
  }
};