import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_7_3 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 195.1 151"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M128.7,44.8c-0.3-4.1-0.6-8.4-0.8-12.7c-0.1-2.2,0.1-4.4,0-6.6c-0.3-4.2-0.9-8.3-1.3-12.5
	c-0.2-1.9-0.3-3.9-0.6-5.8c-0.1-0.7-0.6-1.4-0.9-2.2c7.5,0.6,15-0.1,22.5-0.7c3.2-0.2,6.4-0.4,9.6-0.6c2.2-0.1,3.1,0.6,3.5,2.8
	c0.2,0.9,0.2,1.8,0.3,2.7c0.1,2.3,0.2,4.7,0.3,7c0.2,4.7,0.2,9.4,0.5,14c0.2,2.5,0.7,5,1,7.6c0.1,0.4,0.1,0.8,0.2,1.2
	c-0.7,0-1.4,0-2,0c-5,0.1-9.9,0-14.9,0.2c-3.7,0.2-7.4,1-10.8,2.5C133.1,42.8,131,43.8,128.7,44.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M127.1,48.6c0.5-1.5,1.6-2.7,2.9-3.3c3.8-1.9,7.7-3.5,12-4.3c6.8-1.3,13.7-1,20.6-0.6c2.8,0.1,5.7,0.6,8.5,0.9
	c0.8,0.1,1.6,0,2.4,0.1c0.4,0,0.9-0.1,1.3,0c4.1,1.2,8.2,2.5,12.2,3.8c0.9,0.3,1.8,0.9,2.6,1.4c0.6,0.4,1.1,0.9,0.9,1.8
	c-0.1,0.4-0.1,1-0.1,1.4c-0.1,1.2-0.8,1.9-1.9,2c-9.1,0.9-18.1,1.7-27.2,2.6c-3.7,0.4-7.4,0.8-11.1,1.3c-0.2,0-0.5,0-0.7-0.1
	c-3-0.9-6.1-1.9-9.1-2.7c-3.5-1-7.1-1.9-10.6-3C128.9,49.4,128,49,127.1,48.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M191.1,52.5c0,1.7,0,3.1,0,4.6c0,0.9-0.7,1.4-1.6,1.5c-7.3,0.9-14.6,1.8-21.9,2.6c-1.8,0.2-3.7,0.1-5.6,0.3
	c-2.5,0.3-5,0.9-7.5,1.2c-1.3,0.2-2.7,0.1-4.1,0.1c-0.2,0-0.6-0.5-0.6-0.7c-0.1-1.9,0-3.8,0-5.8C163.7,55.1,177.3,53.8,191.1,52.5z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M153.6,63.6c0,2.2,0,4.3,0,6.4c-0.1,3.9-0.2,7.8-0.3,11.7c-0.3,9.2-0.7,18.3-1.1,27.5c0,1-0.1,1.9-0.1,2.9
	c0,3.8,0,7.7,0.1,11.5c0.1,5.3,0.3,10.5,0.4,15.8c0,2.5-0.2,5.1-0.5,7.6c-0.2,1.8-0.5,1.9-2.6,1.7c0-0.6,0-1.2,0-1.8
	c0-6.3-0.1-12.7-0.1-19c0-5.5,0.2-11,0.3-16.5c0.1-6.7,0.4-13.4,0.5-20.1c0.1-6.6,0.1-13.2,0.2-19.8c0-2.1,0-4.2,0-6.3
	c0-0.8,0.3-1.1,1.1-1.2C152.2,63.9,153,63.7,153.6,63.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M186.8,60.2c1-0.1,1.9-0.2,3-0.3c0,2.6,0,5.2,0,7.8c0,3.6,0.2,7.2,0.2,10.8c0,1.2-0.2,2.5-0.2,3.7
	c-0.1,6.7-0.2,13.5-0.2,20.2c0,4.9,0.1,9.8,0,14.7c-0.1,5.2-0.5,10.4-0.6,15.6c-0.1,2.2,0,4.4,0.2,6.6c0.1,1-0.5,1.2-1.3,1.3
	c-0.9,0.2-1.2-0.4-1.3-1.1c-0.2-1.4-0.4-2.8-0.4-4.2c0-2,0.3-4,0.4-5.9c0-0.1,0-0.2,0-0.4c0.2-5.8,0.4-11.6,0.5-17.3
	c0.1-7.6,0.2-15.2,0.2-22.8c0-5.1,0-10.2-0.1-15.2c-0.1-3.9-0.4-7.7-0.5-11.6C186.8,61.6,186.8,61,186.8,60.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M146.7,63.3c0.9,0.4,1.6,0.6,2.3,1.1c0.2,0.1,0.3,0.6,0.3,0.9c0,5.3,0.1,10.6,0.1,15.9
	c-0.1,6.3-0.4,12.5-0.5,18.8c-0.1,5.3,0,10.5-0.1,15.8c-0.1,6.6-0.4,13.2-0.5,19.8c-0.1,3.7,0.1,7.3,0.1,11c0,0.2,0.1,0.5,0,0.7
	c-0.2,0.4-0.5,0.7-0.7,1c-0.3-0.3-0.8-0.7-0.9-1c0-3.4,0-6.8,0-10.2c0-2.4-0.1-4.8-0.1-7.2c-0.1-3.4-0.2-6.9-0.2-10.3
	c0-4.5,0.2-9,0.2-13.4c0-3.6,0-7.3-0.1-10.9c-0.1-5.1-0.4-10.3-0.4-15.4c0-5.2,0.1-10.3,0.2-15.5C146.6,64.1,146.6,63.8,146.7,63.3z
	" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M125.5,133.8c-1.8-0.6-1.7-0.7-1.6-2.3c0.4-4.4,0.6-8.9,0.9-13.4c0.1-2.1,0.4-4.2,0.5-6.3
	c0.3-4.7,0.6-9.4,0.9-14.1c0.3-5.1,0.8-10.3,1-15.4c0.1-3.5-0.2-7-0.4-10.5c0-0.7-0.1-1.4-0.1-2c0-4.4,0-8.9,0-13.3
	c2,0,2.4,0.5,2.4,2c-0.1,5.5-0.2,10.9-0.2,16.4c0,5.8,0,11.7-0.1,17.5c0,1.7-0.5,3.5-0.7,5.2c-0.3,3.1-0.5,6.3-0.8,9.4
	c-0.2,3.1-0.5,6.1-0.7,9.2c-0.2,3.1-0.1,6.2-0.3,9.2c-0.2,2.7-0.5,5.3-0.8,8C125.7,133.6,125.6,133.7,125.5,133.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M148.8,62.8c-1.6-0.6-2.8-1.1-4.1-1.6c-1.7-0.5-3.5-0.9-5.1-1.5c-2.1-0.7-4.1-1.6-6.1-2.4
	c-1.8-0.7-3.7-1.3-5.5-2.1c-0.9-0.4-1.8-0.9-1.8-2.1c0-0.3-0.2-0.5-0.2-0.8c0-0.7-0.1-2,0.2-2.1c0.8-0.3,1.8-0.4,2.6-0.2
	c2.8,0.7,5.5,1.6,8.2,2.5c2,0.6,4,1.2,6,1.8c1.7,0.5,3.4,1.2,5,1.8c0.3,0.1,0.6,0.5,0.6,0.7C148.8,58.7,148.8,60.6,148.8,62.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M126.3,134.5c1.1-15.3,2.1-30.3,3.1-45.4c1.6,0.1,1.9,0.5,1.9,2.4c-0.1,3.4,0,6.8-0.2,10.2
	c-0.3,4.8-0.8,9.6-1.2,14.3c-0.3,3.1-0.4,6.2-0.8,9.3c-0.3,2.7-0.7,5.4-1,8.1C128,134.7,126.9,134,126.3,134.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M185.7,61.4c0.1,2.5,0.2,4.9,0.3,7.4c0.2,4.4,0.4,8.8,0.5,13.2c0.1,6.3-0.1,12.5-0.1,18.8c0,1.3,0.1,2.6,0,4
	c0,1.6-0.2,1.7-2.1,1.5c-0.1-1.7-0.1-3.4-0.2-5.2c-0.1-3.2-0.2-6.3-0.2-9.5c0-3.6,0-7.3,0.1-10.9c0-2.4,0-4.9,0-7.3
	c0-3.5-0.2-6.9-0.1-10.4c0-0.6,0.8-1.2,1.3-1.8C185.3,61.3,185.5,61.3,185.7,61.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M123.2,5.3c1.4,0.8,1.8,1.7,1.9,2.9c0.3,3.3,0.9,6.5,1.2,9.8c0.4,4,0.7,8.1,0.9,12.1c0.2,3.5,0.3,7.1,0.5,10.6
	c0,1.4-0.1,2.9-0.1,4.3c0,1.3-0.8,1.9-1.7,2.6c-1.2-7.1-0.4-14.2-0.9-21.2C124.6,19.5,123.8,12.6,123.2,5.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M186.2,107.9c0,0.7,0,1.1,0,1.5c-10.9,1.8-21.7,3.6-32.7,5.5c-0.1-0.7-0.1-1.5-0.2-2.3c2.3-0.4,4.5-0.7,6.7-1.1
	c2.6-0.5,5.1-1.2,7.7-1.7c2.2-0.4,4.4-0.7,6.6-1c3.1-0.4,6.2-0.8,9.4-1C184.5,107.6,185.4,107.8,186.2,107.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M158.4,110.5c0.8-9,1.6-17.8,2.3-26.7c2.2,0.1,2.4,0.4,2.3,2.4c-0.1,1.3,0,2.6-0.2,3.8
	c-0.3,2.6-0.8,5.2-1.1,7.8c-0.4,3.6-0.6,7.3-0.9,11C160.7,110.7,160.7,110.7,158.4,110.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M145.9,93.8c-2.2-0.7-4.4-1.4-6.5-2.2c-3-1.1-5.9-2.3-8.9-3.5c-0.9-0.4-1.1-2.9-0.2-3.4c0.2-0.2,0.7-0.1,1,0
	c4.1,1.8,8.2,3.7,12.3,5.5C145.9,91.2,146,91.4,145.9,93.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M185.9,140.2c-1.3,0-1.6-0.5-1.6-1.5c0-6,0-12.1,0.1-18.1c0-2.8,0.2-5.6,0.3-8.4c0-0.4,0.1-0.8,0.2-1.3
	c0.3-0.1,0.7-0.2,1.2-0.3c0,1.7,0,3.2,0,4.8c0,6.4-0.1,12.8-0.1,19.3C185.9,136.5,185.9,138.4,185.9,140.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M182.6,89.9c-1.8-0.5-3.7-1-5.5-1.6c-2.8-0.9-5.5-1.8-8.3-2.8c-2.6-0.9-5.1-2-7.6-3.1c-0.4-0.2-0.5-0.9-0.9-1.7
	c0.6,0,0.8,0,1,0.1c3.3,1.1,6.5,2.2,9.8,3.3c3.5,1.2,7.1,2.4,10.6,3.7C182.5,88.2,183.2,88.6,182.6,89.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M130.3,59.1c0.5,0.8,0.8,1.4,1.2,2c0.2-0.2,0.4-0.3,0.5-0.5c-0.1,3-0.7,6-0.3,8.9c0.5,4.2-0.5,8.4-0.2,12.5
	c0,0.2,0,0.4,0,0.6c-0.2,0.4-0.5,0.7-0.8,1c-0.3-0.4-0.7-0.7-0.8-1.1c-0.1-0.7,0.1-1.3,0.1-2c0-3.6-0.1-7.3-0.1-10.9
	c0-3,0.2-6.1,0.3-9.1C130.1,60.1,130.2,59.6,130.3,59.1z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M157.6,97.4c0.4-9.2,0.8-18.5,1.2-27.8c0.3-0.1,0.7-0.1,1.2-0.2c-0.1,1-0.3,2-0.3,3c0,4.3,0,8.5,0,12.8
	c-0.1,3.6-0.3,7.3-0.5,10.9c0,0.2-0.1,0.4-0.2,0.6c-0.3,0.4-0.6,0.7-0.9,1C157.9,97.6,157.7,97.5,157.6,97.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M160.6,79.8c0.2-3.4,0.4-6.8,0.5-10.1c0-0.3,0.1-0.6,0.1-0.8c0.1-1.5,0.3-1.6,1.8-1.5c0,1.2,0,2.4,0,3.7
	c-0.1,2.4-0.1,4.7-0.2,7.1C162.7,79.7,162.6,79.8,160.6,79.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M145.7,100.7c0.1,0.8,0.1,1.3-0.7,1.4c-4.2,0.6-8.3,1.3-12.5,2c-0.1,0-0.2,0-0.4-0.1c-0.3-0.9-0.2-1.4,1-1.6
	c3.7-0.5,7.3-1.1,11-1.6C144.6,100.7,145.2,100.7,145.7,100.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M159.4,115.2c-0.3,4.3-0.7,8.5-0.9,12.7c-0.1,1-0.7,1-1.6,1.1c0.4-3.8,0.7-7.5,1.1-11.2c0.1-0.5,0.2-1,0.2-1.5
	C158.2,115.3,158.6,115,159.4,115.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M155.2,125.9c0.2-2.9,0.5-5.8,0.7-8.6c0-0.4,0.1-0.7,0.2-1c0.2-0.3,0.5-0.5,0.7-0.7c0.1,0.3,0.3,0.6,0.3,0.9
	c-0.2,2.1-0.5,4.3-0.6,6.4c-0.1,1.7-0.1,3.4-0.2,5.1c0,0.4-0.3,0.8-0.5,1.2c-0.2-0.4-0.6-0.7-0.7-1.2
	C155.1,127.3,155.2,126.5,155.2,125.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }} d="M158.4,101.5c-0.1,1.6-0.2,3.2-0.3,4.9c-0.1,1-0.2,2-0.3,3.1c-0.1,0.8,0,1.7-1.4,1.4c0.1-0.8,0.1-1.6,0.2-2.4
	c0.2-2.1,0.3-4.3,0.6-6.4c0-0.3,0.5-0.6,0.7-0.9C158.1,101.3,158.3,101.4,158.4,101.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity1 ? this.state.opacity1 : 1}
              fill={this.state.color1 ? this.state.color1 : 'transparent'} onClick={() => {
          this.setState({ color1: color, opacity1: opacity });
        }}
              d="M158.7,98.7c-0.3,0.9-0.3,0.9-4.9,1.5c0.1-0.3,0.1-0.8,0.2-0.8C155.5,99.2,157,99,158.7,98.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M7.7,51.8c2.7-0.3,5.2-0.6,7.7-0.8c0-0.1,0-0.2,0-0.3c-1.8,0-3.6-0.1-5.4,0c-1.6,0.1-3.1,0.2-4.5-0.7
	c-0.3-0.2-0.6-0.3-0.9-0.3c-0.2,0-0.5,0.2-0.5,0.4c0,0.1,0.2,0.5,0.3,0.5c2.3,0.3,2.6,2.2,3,3.8c0.6,2.2,1.2,4.4,1.1,6.8
	c-0.1,2,0,4,0.1,5.9c0.1,1.9,0.5,3.8,0.7,5.7C9.5,74.9,9.5,77,9.7,79c0.2,2.8,0.3,5.6,0.5,8.4c0.1,1.2-0.1,2.1-1.2,2.9
	c-0.7,0.5-1.1,1.3-1.7,2.2c-0.1-1.3-0.2-2.6-0.3-3.8c-0.4-5.5-0.8-11-1.3-16.5c-0.2-2.6-0.6-5.2-0.8-7.9c-0.2-2.8-0.4-5.6-0.6-8.3
	c-0.1-1.6-0.3-3.1-0.6-4.6c-0.3-1.7,0.2-2.5,2-2.6c2.1-0.1,4.3,0,6.5,0c3.2-0.1,6.5-0.1,9.7-0.2c5.1-0.1,10.2-0.3,15.3-0.4
	c0.7,0,1.4,0.2,2,0.5c0.4,0.2,1,0.6,1.1,1.1c0.6,2.7,1.2,5.4,1.5,8.1c0.2,2.4,0,4.8,0.1,7.1c0.1,3.6,0.3,7.2,0.6,10.7
	c0.2,2.9,0.7,5.8,1.1,8.9c-0.9,0-1.7,0-2.5,0c-6-0.3-11.9-0.3-17.8,0.5c-3.6,0.5-7.2,1-10.4,3.1c-0.4,0.3-0.9,0.5-1.6,0.8
	c-0.2-3-0.5-5.9-0.5-8.8c0-3.6,0-7.2-0.8-10.7c-0.4-1.8-0.3-3.7-0.4-5.5C9.4,60.1,8.9,56.5,7.8,53C7.7,52.6,7.7,52.3,7.7,51.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M8.5,92.8c0.5-0.6,1-1.3,1.6-1.7c1.7-1.1,3.4-2.2,5.2-2.9c2-0.8,4.2-1.3,6.4-1.7c2.3-0.4,4.6-0.7,6.9-0.7
	c5.3,0,10.6-0.2,15.8,0.2c5.6,0.5,11.1,1.7,16.7,2.7c2.5,0.4,5,1.1,7.5,1.6c1.6,0.3,2.3,2.7,1.8,4c-0.1,0.2-0.5,0.4-0.8,0.5
	c-4.4,0.3-8.8,0.7-13.3,0.9c-2.3,0.1-4.6-0.2-6.9-0.3c-4.4-0.1-8.7,0-13.1-0.1c-2,0-4,0-6-0.1c-0.8-0.1-1.6-0.8-2.4-0.9
	c-3.4-0.2-6.8-0.3-10.2-0.5c-3-0.2-6-0.5-9-0.8C8.7,93,8.6,92.9,8.5,92.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M28.4,95.4c0.1,2.1,0.2,4.2,0.4,6.5c0.4-2,0.7-3.7,1-5.5c0.2,0,0.4-0.1,0.6-0.1c4.9-0.1,9.7-0.2,14.6-0.2
	c2.9,0,5.8,0.3,8.7,0.4c2.3,0,4.5-0.1,6.8-0.2c3-0.1,5.9-0.3,8.9-0.4c1,0,1.4,0.8,1.6,1.5c0.2,0.9,0.1,1.8,0.2,2.8
	c-1,0.1-1.9,0.2-2.8,0.3c-6.1,0.5-12.2,1.1-18.2,1.5c-4.7,0.3-9.5,0.4-14.2,0.5c-2.6,0.1-5.2,0.1-7.7,0c-1,0-2-0.5-3-0.6
	c-4.8-0.7-9.7-1.4-14.6-2c-0.9-0.1-1.7-0.2-2.6-0.4c-0.3-0.1-0.6-0.5-0.7-0.8c-0.1-1-0.2-2.1-0.2-3.1c0-0.9,0.7-1,1.3-1
	c1.9,0.1,3.8,0.2,5.7,0.3c3.4,0.2,6.9,0.3,10.3,0.4C25.7,95.1,26.9,95.2,28.4,95.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity1: opacity });
        }} d="M29.4,103.8c1.5,0.1,2.8,0.2,4.2,0.3c-0.3,12.7-0.6,25.3-1,38.1c-0.4,0-0.8,0.1-1.2,0.1c-0.4,0-0.8,0-1.3,0
	C29.8,129.5,29.6,116.8,29.4,103.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M67.5,138.5c0-1.2,0-2.3,0-3.4c0.1-2.4,0.2-4.7,0.2-7.1c0-1.1-0.4-2.3-0.4-3.4c0.1-3.7,0.4-7.4,0.5-11.1
	c0.1-3.7,0-7.3,0-11c0-0.2,0-0.5,0.1-0.8c0.9-0.1,1.8-0.1,2.7-0.2c0,1,0,2,0,2.9c0,1,0,2.1,0,3.1c0,0.9,0.1,1.8,0.1,2.6
	c-0.1,2.7-0.3,5.3-0.4,8c-0.3,6.5-0.5,12.9-0.7,19.4C69.3,138.8,68.4,138.5,67.5,138.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M29,141.8c-1.2-0.2-1.1-0.9-1.2-1.6c-0.2-2.1-0.2-4.2-0.5-6.3c-1-6.8-0.6-13.6-0.7-20.3c0-3.2-0.1-6.4-0.1-9.6
	c0-0.2,0-0.3,0.1-0.6c0.6,0.1,1.1,0.2,1.7,0.3C28.5,116.5,28.7,129.1,29,141.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M64.5,121.9c-0.1-2.7-0.2-5.2-0.2-7.7c0-1.6-0.1-3.3-0.1-4.9c0-1.7,0-3.5,0.3-5.2c0.1-0.7,1.1-1.3,1.6-1.9
	c0.1,0.1,0.2,0.1,0.3,0.2c0,0.4,0.1,0.8,0.1,1.2c0,5.7,0,11.5,0,17.2C66.5,122.2,65.4,121.5,64.5,121.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M63,115.6c-4.5,0.1-9,0-13.5-1c-2.6-0.6-5.4-0.7-8.1-0.9c-0.8-0.1-1.4-0.4-1.4-1.3c0-0.9,0.6-1.3,1.4-0.9
	c0.3,0.1,0.6,0.3,0.9,0.3c3.3,0.4,6.6,0.7,9.9,1.1c3.3,0.4,6.6,1,9.9,1.5C62.9,114.6,63.4,114.9,63,115.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M6.8,134.5c-0.8,0-1.1-0.3-1-1c1.7-9.4,2.4-18.9,1.9-28.5c-0.1-1.4,0-2.9,0-4.3c0.8-0.2,1.1-0.1,1.2,0.8
	c0.1,4.1,0.6,8.2,0.2,12.2C8.5,120.4,8.6,127.3,7,134C6.9,134.1,6.9,134.3,6.8,134.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M66.5,123.3c-0.4,0.1-0.7,0.3-1.1,0.3c-4,0.3-8,0.7-12.1,0.9c-3.2,0.2-6.4,0.3-9.6,0.6c-2.5,0.2-5,0.6-7.4,0.9
	c-0.6,0.1-1.3-0.1-1.9-0.2c-0.2,0-0.4-0.5-0.4-0.7c0-0.1,0.4-0.4,0.6-0.4c3.5-0.3,7.1-0.6,10.6-0.8c4-0.2,8.1-0.4,12.1-0.6
	c2.8-0.1,5.6-0.3,8.4-0.4c0.2,0,0.4,0,0.6,0.1C66.4,123,66.5,123.2,66.5,123.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M25.4,115.1c0.3,1.7,0.1,1.8-1.3,1.7c-2.7-0.3-5.4-0.4-8.1-0.7c-1.7-0.1-3.4-0.3-5.1-0.5
	c-0.3,0-0.6-0.4-0.7-0.7c-0.1-0.2,0.3-0.6,0.5-0.7c1.5-1,3.1-0.3,4.6-0.1c2.4,0.3,4.9,0.6,7.3,0.8C23.6,115.1,24.5,115.1,25.4,115.1
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M11.6,116.8c-0.8,5.6-1.6,11.2-2.5,16.7c-0.1,0.5-0.4,0.9-0.7,1.4c-0.1,0-0.2-0.1-0.4-0.1
	c0-0.4,0.1-0.8,0.1-1.2c0.5-4.5,0.9-9,1.4-13.5c0.1-0.8,0.2-1.5,0.3-2.3C10,116.6,10.1,116.6,11.6,116.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M39.8,109.2c-0.3,2.6-0.7,5.2-1,7.8c0,0.4,0,0.7-0.2,1.1c-0.1,0.3-0.5,0.5-0.7,0.8c-0.3-0.3-0.6-0.5-0.8-0.8
	c-0.1-0.3,0-0.7,0-1.1c0.2-1.7,0.5-3.5,0.7-5.2c0.1-1-0.2-2-0.2-3.1c0-0.4,0-0.8,0.2-1.2c0.2-0.4,0.6-0.6,0.9-0.9
	c0.3,0.3,0.7,0.6,0.8,1c0.1,0.5,0,1,0,1.5C39.6,109.2,39.7,109.2,39.8,109.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M10.2,102.6c2.1,0.2,2.1,0.5,1.9,2.6c-0.3,2-0.2,4-0.3,6c0,1-0.2,1.7-1.6,1.8C10.2,109.6,10.2,106.2,10.2,102.6
	z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }}
              d="M65.6,138.2c-0.7-4.4-0.3-8.9-1-13.4c0.7,0,1.2,0,1.6,0c0,4.5,0,8.9,0,13.3C66.1,138.2,65.9,138.2,65.6,138.2z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M39,123.4c0.3-2.5,0.6-4.9,0.9-7.3c0.2-1.6,0.5-1.8,2.1-1.3c-0.4,2-0.9,4-1.3,6c-0.1,0.5-0.2,1.1-0.3,1.7
	C40.4,123.4,39.9,123.6,39,123.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M40.7,109c0-0.5,0.1-1,0.1-1.4c0.2-1.1,1.8-2,2.7-1.3c0.3,0.2,0.4,0.9,0.4,1.3c-0.1,0.8-0.4,1.5-0.5,2.3
	c-0.2,1.2-0.5,1.4-2.1,1.1c-0.9-0.1-0.7-0.8-0.6-1.4C40.7,109.4,40.7,109.2,40.7,109C40.7,109,40.7,109,40.7,109z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M11.8,122.2c0.9-0.1,1.9-0.3,2.8-0.4c3.3-0.4,6.7-0.7,10-1.1c0.7-0.1,1.1,0.1,0.8,1c-4.5,0.3-9,0.6-13.5,0.8
	C11.8,122.4,11.8,122.3,11.8,122.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M40,126.6c-0.4,1.8-0.6,3.5-1.1,5.1c-0.1,0.5-1,0.7-1.6,1c-0.1-0.1-0.2-0.2-0.3-0.3c0.2-1,0.5-2,0.7-2.9
	c0.1-0.5,0.4-1.1,0.4-1.6C38.2,126.6,38.8,126.3,40,126.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M37.1,126.9c-0.1,1.5-0.2,3.1-0.5,4.6c0,0.3-0.5,0.5-0.8,0.8c-0.2-0.3-0.5-0.6-0.4-0.8c0.2-1.4,0.6-2.8,0.9-4.2
	c0.1-0.2,0.3-0.4,0.5-0.6C36.9,126.7,37,126.8,37.1,126.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M37.8,120.5c-1.1,0.2-2.2,0.4-3.2,0.6c-0.1,0-0.3-0.3-0.3-0.4c0.1-0.2,0.3-0.4,0.5-0.4c1-0.1,2-0.1,2.9-0.2
	C37.8,120.2,37.8,120.3,37.8,120.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity2 ? this.state.opacity2 : 1}
              fill={this.state.color2 ? this.state.color2 : 'transparent'} onClick={() => {
          this.setState({ color2: color, opacity2: opacity });
        }} d="M37.1,123.4c0.1-0.6,0.1-1.1,0.2-1.7c0.1,0,0.2-0.1,0.3-0.1c0.1,0.3,0.4,0.6,0.4,1c0,0.4-0.3,0.7-0.4,1
	C37.4,123.6,37.2,123.5,37.1,123.4z" />
      </SvgIcon>
    )
  }
};