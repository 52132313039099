import ArrowDrawable from "./ArrowDrawable";
import { Arc } from "react-konva";
import React from "react";

export default class ArcDrawable extends ArrowDrawable {
  drawableType = 'ArcDrawable';
  radius = 0;
  startRotation = 0;
  rotation = null;
  angle = 0;

  constructor(startx, starty, color, weight, radius, rotation, opacity) {
    super(startx, starty, color, weight, opacity);
    this.radius = radius;
    this.startRotation = rotation;
  }

  registerMovement(rotation) {
    if (this.rotation === null) {
      this.rotation = this.startRotation;
    }
    let diffAngle = rotation - this.rotation;
    if (Math.abs(this.rotation) > 90 && Math.abs(rotation) > 90) {
      if (this.rotation > 0 && rotation < 0) {
        diffAngle += 360;
      } else if (this.rotation < 0 && rotation > 0) {
        diffAngle -= 360;
      }
    }
    this.angle += diffAngle;
    this.rotation = rotation;
  }

  render() {
    let arcRotation = this.startRotation;
    let angle = this.angle;
    if (this.angle < 0) {
      arcRotation = this.rotation;
      angle = -this.angle;
    }
    if (angle > 360) {
      angle = 360;
    }
    return (
      <Arc
        x={this.startx}
        y={this.starty}
        innerRadius={this.radius}
        outerRadius={this.radius}
        fill="transparent"
        stroke={this.color}
        strokeWidth={this.weight}
        angle={angle}
        rotation={arcRotation}
        opacity={this.opacity}
      />
    );
  }
}