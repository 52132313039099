import React from "react";
import { SvgIcon } from "@material-ui/core";
import SVG from "./SVG";

export default class SVG_33_2 extends SVG {

  render() {
    let { color, borderColor = "black", opacity = false } = this.props;
    opacity = opacity ? 0.5 : 1;
    return (
      <SvgIcon
        {
          ...this.props
        }
        viewBox={"0 0 499 510.2"}
        style={{ width: "100%", height: "100%" }}
      >
        <path stroke={borderColor} fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1}
              fill={this.state.color53 ? this.state.color53 : 'transparent'} onClick={() => {
          this.setState({ color53: color, opacity53: opacity });
        }} d="M145.2,145.5c-0.3,3.9,0.9,7.3,3.1,10.4c1.9,2.7,3.7,5.4,5.2,8.3c1.6,3.2,4.1,4,7.3,3.5
	c0.8-0.1,1.6-0.3,2.4-0.5c2.6-0.6,5.2-1.3,8.2-2.1c0-1.4,0.1-3,0.2-4.7c0-0.7,0.2-1.4,0.2-2.1c0.3-3.9,0.9-7.7,0-11.6
	c-0.2-0.7,0.1-1.6,0.1-2.3c0-0.5,0.2-1,0.2-1.4c-0.3-3.5,0.1-6.9,0.2-10.4c0.1-1.9-0.3-3.8-0.5-5.7c-0.1-1-0.2-2-0.3-2.9
	c-0.1,0-0.3,0-0.4,0c-0.3,0-0.5,0-0.8-0.1c-0.3,0.1-0.6,0.1-1.1,0.1c-0.6-0.1-1.2-0.1-1.9-0.2c-1.3,3.4-0.3,6.9-0.7,10.3
	c-2.7-3.1-0.7-6.8-1.5-10.2h-3.2c-0.1,1.2-0.4,2.4-0.4,3.5c0,6.3,0.7,12.5,1.9,18.7c0.1,0.5,0.2,0.9,0.2,1.4c0,0.1-0.1,0.2-0.3,0.5
	c-1.5-1.6-2.2-3.5-2.6-5.5c-0.8-5.3-1.4-10.6-0.9-16c0.1-0.8-0.1-1.7-0.1-2.7c-0.5-0.1-0.9-0.1-1.3-0.1c-3-0.1-3.3,0.2-3.4,3.3
	c0,0.7-0.2,1.4-0.3,2.1c-0.1,0.6-0.3,1.2-0.5,2.2c-1.3-2.7-0.1-5.1-0.5-7.6h-7.6c-1.6,3.1-1.9,6.4-1.7,9.8c0.1,1.7-0.1,3.5-0.2,5.2
	c-0.1,1.9-0.8,3.9,0.6,5.7C145.1,144.8,145.2,145.2,145.2,145.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity63 ? this.state.opacity63 : 1}
              fill={this.state.color63 ? this.state.color63 : 'transparent'} onClick={() => {
          this.setState({ color63: color, opacity63: opacity });
        }} d="M339.4,369.4c-0.3-0.3-0.3-0.7-0.3-1.2c0.4-4.9,0.2-9.8-0.3-14.8c-0.2-1.9,0.3-4,0-5.9c-0.5-3.5,0.4-7,0.2-10.5
	c-0.1-1.3,0.2-2.7,0.2-4c0.1-1.5,0.1-3,0.2-4.5c0-0.6-0.1-1.3,0.1-1.9c1.1-3.3,0.7-6.7,0.1-10c-0.3-1.5,0.1-3,0-4.5
	c-0.2-2.2-0.3-2.3-2.6-2.7c-1.3-0.2-2.5-0.4-3.8-0.5c-0.6,0-1.2,0.4-1.9,0.6c-0.6-1.5-2.1-0.8-3.5-1.1c-0.3,3.3-0.9,6.3-0.2,9.4
	c0.6,2.7,0.7,5.5,1,8.3c0.1,0.6-0.1,1.3-0.3,2c-1.3-6.5-1.6-13.1-2.7-19.6c-1.5,0.1-2.7,0.2-4.2,0.2c1.5,6.3,1.9,12.7,1.6,19.1
	c-0.2,0-0.4,0-0.5,0c-0.2-2.8-0.2-5.6-0.5-8.4c-0.4-3.1-1.1-6.3-1.8-9.4c-0.1-0.5-0.9-1.3-1.4-1.3c-3-0.1-6-0.1-9.4-0.1
	c0.2,0.9,0.3,1.9,0.7,2.6c0.4,0.8,1.1,1.4,1.4,2.5c-0.6-0.1-1.2-0.3-2.1-0.4c0,2.2,0,4.4,0,6.6c0,1.3,0.1,2.5,0.2,3.8
	c0.3,3.5,0.9,7,0.9,10.4c0,4.6,1,8.9,2.8,13.1c0.3,0.6,0.4,1.4,0.5,2.1c0.6,3.1,1.3,6.2,1.9,9.4c0.8,3.7,1.5,7.5,2.3,11.5
	c2.4,0.1,5,0.3,7.5,0.2c3.1-0.2,6.1-0.8,9.3-0.1c1.5,0.3,3.2,0.2,4.7,0C339.5,370.1,339.4,369.8,339.4,369.4z M332.4,322.7v-9.3
	C332.8,316.6,333.6,319.6,332.4,322.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity59 ? this.state.opacity59 : 1}
              fill={this.state.color59 ? this.state.color59 : 'transparent'} onClick={() => {
          this.setState({ color59: color, opacity59: opacity });
        }} d="M330.5,228.9c-0.8-5.5-1.7-10.9,0.9-16.5c1,1.5,1.3,2.9,1.2,4.3c-0.3,5.8,1,11.4,2.8,16.8
	c0.4,1.2,0.9,2.6,2.7,3.3c-1.8-4-3.9-7.6-4-11.8c0-4.1-1.6-8.2,0.4-12.3c1.8,0,3.6,0,5.7,0c0.2,0.7,0.6,1.5,0.6,2.3
	c0.1,2.3,0,4.6,0.1,6.9c0.3,6.3-0.3,12.5-0.6,18.8c-0.2,3-0.2,6-0.4,9.1c0,0.9,0,1.7,0,2.8c-2.9-0.8-5.3,0.4-7.8,1
	c-1.7,0.4-2.9,0.2-4.1-1c-1.6-1.6-3.4-3.1-5-4.8c-1.9-1.8-3.7-3.7-5.5-5.6c-1.3-1.3-2.2-2.9-2.4-4.8c-0.5-4.9-0.8-9.8-1.3-14.7
	c-0.3-2.9-0.2-5.7,0.6-8.7c3-0.2,6.1-0.5,9.3-0.8c0.3,1.8,0.7,3.6,1,5.4c0.1,0,0.3,0,0.4,0c0-0.8,0-1.6,0-2.4
	c-0.1-2.7,0.2-3,2.9-3.1c0.2,0,0.5,0,0.8,0.1C329.4,218.4,328,223.9,330.5,228.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity59 ? this.state.opacity59 : 1}
              fill={this.state.color59 ? this.state.color59 : 'transparent'} onClick={() => {
          this.setState({ color59: color, opacity59: opacity });
        }} d="M291.5,227.5c0.6,0.2,1.1,0.3,1.3-0.5c0.2-1.1,0.5-2.2,0.5-3.3c0.2-3.3,0.4-6.6,0.6-9.9c1.7-0.3,2-0.1,2.1,1.4
	c0.1,2.1,0.1,4.3,0.1,6.4c0,0.4,0.1,0.7,0.3,1.1c1-2.9,0.9-5.8,0.4-8.8c2.4-0.8,4.6-0.3,7.2-0.2c0,2.8-0.1,5.5,0,8.2
	c0.2,4.1-1.2,7.8-2.1,11.7c-1.3,5.4-4.1,10.1-8.4,13.5c-2.6,2-5.9,3.1-8.9,4.6c-2.5,1.1-5.2,0.9-7.9,1.9c0.9-13.5,0.1-26.7,0.5-40.1
	c2-0.2,4.1-0.6,6.3-0.3c2.5,0.4,5,0.3,7.9,0.5C293,218.2,292.8,222.9,291.5,227.5c-0.1,0.8-0.3,1.7-0.3,2.6c0,0.4,0.2,0.9,0.5,1.8
	C292.3,230,292.6,228.7,291.5,227.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity59 ? this.state.opacity59 : 1}
              fill={this.state.color59 ? this.state.color59 : 'transparent'} onClick={() => {
          this.setState({ color59: color, opacity59: opacity });
        }} d="M282.8,258.5c1.7,3.1,3.4,6.1,5.2,9.5c-4.8,0.2-9.1-1-13.2-0.7c0.3-2,0.6-3.7,0.8-5.5c0.3-2.1,0.9-2.7,3-2.8
	c0.6,0,1.3,0.2,1.9,0.1C281.1,259,281.7,258.8,282.8,258.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity59 ? this.state.opacity59 : 1}
              fill={this.state.color59 ? this.state.color59 : 'transparent'} onClick={() => {
          this.setState({ color59: color, opacity59: opacity });
        }}
              d="M339.2,267.8c-3.6,0-7.3,0-10.9,0c0.7-4.7,3.9-8.6,11-9.2C339.8,260.4,339.8,265.7,339.2,267.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity59 ? this.state.opacity59 : 1}
              fill={this.state.color59 ? this.state.color59 : 'transparent'} onClick={() => {
          this.setState({ color59: color, opacity59: opacity });
        }} d="M339.8,254.2c0.1,0.8,0.1,1.4,0.2,2.2c-1.9,0.6-3.6,1.2-5.5,1.7c-1.1,0.3-2.3,0.3-3.5,0.3c-0.4,0-1-0.7-1-1.1
	c0-0.4,0.5-0.8,0.8-1.2c0-0.1,0.1-0.1,0.2-0.1c2.6-0.6,5.2-1.2,7.8-1.8C339,254,339.4,254.1,339.8,254.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity59 ? this.state.opacity59 : 1}
              fill={this.state.color59 ? this.state.color59 : 'transparent'} onClick={() => {
          this.setState({ color59: color, opacity59: opacity });
        }} d="M276.4,257.9c-0.2-1.9,0-2.3,1.2-2.6c1.6-0.3,3.3-0.6,4.9-1c0.7-0.2,1.4-0.3,1.5,0.5c0.1,0.4-0.3,1.1-0.6,1.3
	c-1.3,0.8-2.6,1.5-4.2,1.4C278.2,257.5,277.3,257.7,276.4,257.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity64 ? this.state.opacity64 : 1}
              fill={this.state.color64 ? this.state.color64 : 'transparent'} onClick={() => {
          this.setState({ color64: color, opacity64: opacity });
        }} d="M366.3,372.4c-3.3-0.1-6.6-0.3-10.1-0.4c0.2-1.7,0.5-3.4,0.6-5.2c0.2-3.3,0.4-6.7,0.4-10c0-2.3-0.1-4.6-0.2-6.9
	c-0.2-4.5-0.4-8.9-0.6-13.4c-0.1-2.9-0.3-5.9-0.4-8.8c-0.1-3.7-0.2-7.3-0.2-11c0-1.7,0-3.5,1.2-5c0.2-0.3,0.1-0.9,0.1-1.6
	c5.4,0.2,10.6,0.5,16.1,0.7c0,3.9,0,7.4,0,10.9c0.2,0,0.3,0.1,0.5,0.1c0.4-1.7,1-3.4,1.3-5.1c0.3-1.8,0.4-3.6,0.7-5.7
	c2.2,0,4.4,0,6.9,0c-0.2,2.6-0.4,5.2-0.6,7.8c-0.1,2-0.4,4-0.4,5.9c0,4.4-1.1,8.4-3,12.4c-1.8,3.9-4.7,7-6.8,10.6
	c-0.2,0.3-0.5,0.6-0.8,0.8c-1,0.7-2.1,1.1-3,1.9c-2,1.7-4.4,2.1-6.9,1.9c-1-0.1-2,0-3.5,0c1.7,1.8,3.6,1.4,5.2,1.8
	c0.8,0.1,1.6-0.1,2.4,0.1c0.5,0.1,1.2,0.5,1.4,1c0.4,0.7-0.1,1.5-0.9,1.7c-0.9,0.3-1.8,0.3-2.8,0.3c-1.6,0-3.1-0.6-5,0.7
	c1.2,0.3,1.9,0.5,2.7,0.7c3.3,0.6,3.4,0.7,4,3.9c0.5,2.7,1,5.5,1.5,8.2C366.4,371.1,366.3,371.6,366.3,372.4z M368.4,313.5
	c0.5,1.9-1.7,3.6-0.5,5.8C369.3,316.5,369.4,315.6,368.4,313.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity64 ? this.state.opacity64 : 1}
              fill={this.state.color64 ? this.state.color64 : 'transparent'} onClick={() => {
          this.setState({ color64: color, opacity64: opacity });
        }} d="M411.1,311.3c0.2,1.7-0.1,3-0.2,4.4c-0.3,2.8-1,5.6-0.3,8.5c0.1,0.6,0,1.2,0,1.9c0,1.8,1,2.9,2.7,3.4
	c-0.4-1.7-1.1-3.5-1.2-5.3c-0.2-3.4-0.6-6.8,0.4-10.2c0.1-0.4,0.2-0.8,0.4-1.1c0.8-1.6,1.7-1.7,3.2-0.5c0.2,0.1,0.4,0.2,0.6,0.4
	c0.6-0.3,1.3-0.6,1.9-0.9c0.1-0.1,0.3,0,0.5,0c1.1,2.4,0.8,5,0.5,7.4c-0.8,5-0.2,10-0.6,15c-0.2,3.1,0.3,6.2,0.5,9.3
	c0.1,1.8,0,3.6,0,5.5c-4.1-0.1-7.9-0.1-11.5,1.4c-1.2,0.5-2.4,0.2-3.5-0.3c-1.5-0.8-3.1-1.7-4.4-2.8c-2.6-2.2-5.6-4.1-7.4-7.1
	c-1-1.6-1.9-3.3-2.5-5.1c-2.3-6.5-3.2-13.3-3.1-20.2c0-1.2,0.3-2.5,0.6-4c2.5,0,4.8-0.4,7,0.1c3.5,0.7,7,0.3,10.5,0.4
	c0.7,2-1.5,3.5-1,5c0.6,1.7-1.2,3.1,0,4.6c1.1-0.4,1.4-1.3,1.4-2.4c0.1-1.8,0.6-3.5,1.3-5.3c0.6-1.6,1.5-2.1,3-2
	C410.2,311.3,410.6,311.3,411.1,311.3z M410.4,338.7c-0.7-0.7-1.7-1.3-2-2.1c-1.4-3.8-2.5-7.7-2.5-11.8c0-1,0.2-2.2-0.7-3.3
	c-0.3,0.6-0.8,0.9-0.8,1.3c0,4.2,0.8,8.3,1.7,12.4c0.3,1.5,1.3,2.7,2.7,3.5C409.3,339,410,339.4,410.4,338.7z M398.2,312.8
	c0.1,1,0.3,1.9,0.4,2.8C398.8,314.6,400.2,313.6,398.2,312.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity64 ? this.state.opacity64 : 1}
              fill={this.state.color64 ? this.state.color64 : 'transparent'} onClick={() => {
          this.setState({ color64: color, opacity64: opacity });
        }} d="M419,354.3c-0.2,6.7-0.3,12.8-0.5,18.9c-1.8,0.6-8.5,0.4-10.4-0.3c0.2-2.4,0.2-4.8,1.3-7.1
	c1.4-3.1,2.5-6.3,3.9-9.8C414.9,355.5,416.7,355,419,354.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity64 ? this.state.opacity64 : 1}
              fill={this.state.color64 ? this.state.color64 : 'transparent'} onClick={() => {
          this.setState({ color64: color, opacity64: opacity });
        }} d="M418.7,351.8c-2.3,1.9-7.9,2.8-9.8,1.6C410.7,351.3,415.5,350.5,418.7,351.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity60 ? this.state.opacity60 : 1}
              fill={this.state.color60 ? this.state.color60 : 'transparent'} onClick={() => {
          this.setState({ color60: color, opacity60: opacity });
        }} d="M422.3,238.8c-3,1.1-6.1,1.1-9.2,1.2c-5.4,0.2-10.1-1.8-14.3-4.7c-2.8-1.9-4.4-5-5.5-8.2
	c-1.3-3.5-0.8-7.3-1.4-10.9c-0.1-0.8-0.1-1.6-0.2-2.6c1.2-0.1,2.3-0.2,3.4-0.2c2.6,0.1,5.2,0.4,7.9,0.5c1.2,0.1,2.4,0.1,3.6,0
	c1.2-0.1,1.6,0.5,1.6,1.5c0,1.6,0.2,3.2,0.1,4.8c-0.2,2.5,0.5,4.9,1.3,7.2c0.7,1.9,0.9,2.2,3.4,3.8c-1.7-2.8-3.3-5.3-3.6-8.2
	c-0.2-2.8,0-5.7,0-8.7c4.4-1.6,8.9,0.6,13.3-0.9c1.2,0.9,1,2.2,0.9,3.5c-0.3,3.5-0.9,7-0.8,10.5c0.1,3.3,0,6.5-0.1,9.8
	C422.5,237.8,422.4,238.3,422.3,238.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity60 ? this.state.opacity60 : 1}
              fill={this.state.color60 ? this.state.color60 : 'transparent'} onClick={() => {
          this.setState({ color60: color, opacity60: opacity });
        }} d="M363.1,212.4c2.5,0,4.9-0.1,7.4,0c1.7,0,3.3,0.1,5,0.3c1.8,0.2,2.3,0.7,2.6,2.5c0.2,1.5,0.3,3,0.4,4.6
	c1.9-2.2-0.2-4.7,0.7-6.8c5-0.5,4.4,0.1,4.4,3.8c0,1.2-0.2,2.4-0.3,3.6c-0.2,1.2-0.4,2.3-0.5,3.5c1.6-3.4,1.8-7,1.7-10.7
	c1.6,0.1,3.1,0.2,4.5,0.2c0.8,1.6,0.6,3.3,0.4,4.9c-0.3,2.8-0.9,5.6-1.6,8.3c-0.2,0.9-1,1.8-1.6,2.6c-3,3.9-6.7,7.2-11.7,8.3
	c-3.2,0.7-6.6,0.9-9.9,1c-1.8,0.1-1.9-0.4-2-2.2c-0.1-2.5-0.4-4.9-0.5-7.4c-0.2-4.6-0.1-9.2,0.5-13.7
	C362.7,214.5,362.9,213.7,363.1,212.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity60 ? this.state.opacity60 : 1}
              fill={this.state.color60 ? this.state.color60 : 'transparent'} onClick={() => {
          this.setState({ color60: color, opacity60: opacity });
        }} d="M412.9,256.8c1.6-3.7,3.2-7.4,2.4-11.8c1.8,0,3.4-0.1,5,0c1.1,0,1.7,0.6,1.6,1.9c-0.4,4.8-0.8,9.7-1.2,14.5
	c-0.3,3.3-0.5,6.6-0.8,10.2c-5.1-0.4-9.9-0.7-14.8-1.1c-0.3-4.2-0.7-8.4-0.9-12.6c-0.2-3.7,0.6-7.3,2-10.7c0.5-1.1,1.1-1.8,2.5-1.7
	c0.9,0.1,1.9-0.3,2.8-0.3c1.8,0,2.2,0.3,2.2,2.1c0,2-0.3,4-0.5,5.9C413.2,254.5,413,255.6,412.9,256.8z M417.7,248.7
	c-0.2,0-0.3,0-0.5,0.1c0,2.1,0,4.2,0,6.3C417.7,252.9,418.4,250.8,417.7,248.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity60 ? this.state.opacity60 : 1}
              fill={this.state.color60 ? this.state.color60 : 'transparent'} onClick={() => {
          this.setState({ color60: color, opacity60: opacity });
        }} d="M375.1,269.3c-4.8-0.3-9.3-0.6-14.1-1c0.3-2.7,0.6-5.4,0.8-8.1c0.2-2.5,0.4-5.1,0.6-7.6
	c0.1-2.7,0.2-5.4,0.3-8.2c2.6-0.6,5.1-1.2,7.2,0.9c0.3,0.3,0.8,0.6,1,1c0.9,1.9,2,3.8,2.5,5.9c0.6,2.2,0.6,4.5,0.8,6.8
	c0.2,1.9,0.4,3.8,0.5,5.7C374.8,266.2,374.9,267.6,375.1,269.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity60 ? this.state.opacity60 : 1}
              fill={this.state.color60 ? this.state.color60 : 'transparent'} onClick={() => {
          this.setState({ color60: color, opacity60: opacity });
        }} d="M405.6,240.7c5.8,1.9,11.1,1.5,16.5,0.6c0.3,1.6-0.5,1.9-1.6,1.9c-2.9,0.1-5.7,0.1-8.6,0.2
	c-2,0.1-4.1,0.2-6.4,0.3C405.6,242.7,405.6,241.9,405.6,240.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity60 ? this.state.opacity60 : 1}
              fill={this.state.color60 ? this.state.color60 : 'transparent'} onClick={() => {
          this.setState({ color60: color, opacity60: opacity });
        }} d="M368.4,241.4c-1.7,0.3-3.1,0.6-4.4,0.7c-0.3,0-0.7-0.4-1-0.6c0.2-0.3,0.4-0.8,0.6-0.8
	C365.1,240.7,366.6,240.3,368.4,241.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity61? this.state.opacity61 : 1}
              fill={this.state.color61 ? this.state.color61 : 'transparent'} onClick={() => {
          this.setState({ color61: color, opacity61: opacity });
        }} d="M163.6,307.5c-0.8,6.4,0.2,12.5,0.7,18.6c0.2,0,0.3,0,0.5,0c0-0.6,0.2-1.3,0.1-1.9c-0.2-2.6-0.3-5.2-0.7-7.8
	c-0.4-2.5,0.8-4.7,1.3-7c0.1-0.5,0.3-1.1,0.5-1.8c0.8-0.1,1.7-0.4,2.7-0.4c1.6,0,2.2,0.6,2.6,2.2c0.5,2.2,0.7,4.3,0.6,6.6
	c-0.4,7.4-0.5,14.8-1.6,22.1c-0.4,2.7-0.8,5.3-1.3,8c-3.4,0.9-6.9,1-10.2,0.7c-1.7-0.2-3.2-1.6-4.8-2.6c-3-1.8-4.8-4.9-7.4-7.1
	c-0.6-0.5-1.1-1.6-1.2-2.5c-0.4-4.1-0.6-8.3-0.8-12.4c-0.1-1.4-0.2-2.8-0.3-4.3c0-0.9,0-1.7,0.1-2.6c0.1-0.9,0.4-1.7-0.4-2.5
	c-0.2-0.2-0.4-0.8-0.3-1.1c0.6-1.5,1.3-3,1.8-4.4C151.6,307.5,157.5,307.5,163.6,307.5z M156.6,316.4c1.2-0.9,1.1-2.1,0.6-3.4
	C157,314.2,156.8,315.3,156.6,316.4c0.2,1.5,0.4,2.9,0.7,4.3c0.2-1.1,0.3-2.2,0.2-3.2C157.3,317.1,156.8,316.8,156.6,316.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity61 ? this.state.opacity61 : 1}
              fill={this.state.color61 ? this.state.color61 : 'transparent'} onClick={() => {
          this.setState({ color61: color, opacity61: opacity });
        }} d="M111.5,345c-0.2-1.9-0.4-3.5-0.4-5.1c0-2.1,0.1-4.1,0.3-6.2c0.4-4.5,0.8-9,1.2-13.5c0.2-2.4,0.5-4.7,0.6-7.1
	c0.1-2.3,0-4.7,0-7.1c1.4,0,3.1,0,4.7,0c1.7,0,3.4,0,5,0.1c1.6,0.1,3.2,0.2,4.7,0.5c1.5,0.4,3.2-0.6,4.9,0.7c0,2.2,0,4.7,0,7.1
	c1.3-2.3,0.8-4.8,1-7.4c2.2,0,4.3,0,6.7,0c-0.2,1.8-0.3,3.3-0.5,4.8c-0.6,3.5-1.3,7-2,10.5c-0.5,2.2-1.1,4.3-1.7,6.4
	c-0.3,0.8-0.9,1.5-1.4,2.2c-0.4,0.5-0.7,1-1.1,1.5c-0.9,1.2-1.8,2.4-2.7,3.6c-0.8,1-1.7,2-2.6,2.8c-2,1.6-3.9,3.8-6.3,4.6
	c-2.9,0.9-6,1.9-9.2,1.4C112.4,344.9,112,345,111.5,345z M127.9,309.7c-0.2,1.9-0.3,3.7-0.5,5.6
	C128.6,313.5,128.9,311.6,127.9,309.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity61 ? this.state.opacity61 : 1}
              fill={this.state.color61 ? this.state.color61 : 'transparent'} onClick={() => {
          this.setState({ color61: color, opacity61: opacity });
        }} d="M121.9,347.2c-1.5,1.5-3.3,1.5-5,1.7c-1.7,0.2-3.4,0.6-5.2,1c0.4,1,1.1,1.1,1.8,1.1c1.6-0.1,3.2-0.1,4.7-0.3
	c3-0.3,4.2,0.5,4.9,3.2c0.6,2.5,1.3,5.1,2.1,7.5c0.8,2.5,1,5,0.2,7.4c-4.7,0-9.4,0-14,0c-1-1.4-1-2.7-1-4.1
	c0.2-3.6,0.4-7.2,0.5-10.7c0.1-1.9,0-3.8,0-5.7c0-0.6,0.1-1.2,0.1-1.9c1.2,0.3,2.2,0.6,3.2,0.7c0.8,0.1,1.8,0.1,2.5-0.3
	C118.5,345.8,120.1,345.9,121.9,347.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity61 ? this.state.opacity61 : 1}
              fill={this.state.color61 ? this.state.color61 : 'transparent'} onClick={() => {
          this.setState({ color61: color, opacity61: opacity });
        }} d="M170,369c-4.6,0.3-8.9,0.8-13.7-0.1c0.5-1.3,1-2.2,1.4-3.2c0.8-2.1,1.5-4.2,2.4-6.2c0.9-2,1.9-4,3.1-5.9
	c0.3-0.6,1.3-0.9,2-1c1.1-0.2,2.2,0,3.7,0c0.2,2.1,0.5,4.1,0.7,6.2c0.1,0.9,0.2,1.9,0.4,2.8C170.5,364,170.4,366.5,170,369z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity61 ? this.state.opacity61 : 1}
              fill={this.state.color61 ? this.state.color61 : 'transparent'} onClick={() => {
          this.setState({ color61: color, opacity61: opacity });
        }} d="M168.4,350.8c-1.9,0.1-3.7,0.2-5.5,0.3c-0.5,0-1,0-1.4-0.2c-0.3-0.2-0.7-0.8-0.7-1.1c0-0.4,0.5-0.9,0.9-0.9
	c2-0.1,4.1-0.2,6.1-0.1C168.6,348.7,168.7,349.2,168.4,350.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity54 ? this.state.opacity54 : 1}
              fill={this.state.color54 ? this.state.color54 : 'transparent'} onClick={() => {
          this.setState({ color54: color, opacity54: opacity });
        }} d="M242.8,122.3c1.3,0,2.1,0,3.1,0c0,0.9,0.1,1.7,0.1,2.5c-0.2,5.7-0.4,11.5,0.4,17.2c0.1,0.6,0.2,1.3,0.4,1.9
	c0.3,0.7,0.8,1.4,1.2,2.1c0.2-0.1,0.3-0.1,0.5-0.2c0-0.3,0.1-0.7,0-1c-1-4.7-1.5-9.4-1.3-14.2c0-0.6-0.1-1.3-0.1-1.9
	c-0.1-1.3,0-2.5,0.7-3.6c0.5-0.8,0.7-1.7,1.1-2.7c2,0,3.9,0,6,0c1.4,1.8,1.8,3.8,1.4,6.2c-0.2,1,0,2,0,3.1c0,4.1-0.1,8.2-0.6,12.3
	c-0.4,3,0,6.2,0.1,9.3c0,1.2,0,2.4-0.2,3.5c-0.1,0.5-0.7,1.2-1.2,1.3c-3.2,0.8-6.3,1.5-9.3,3c-0.6,0.3-1.9-0.1-2.7-0.6
	c-2.2-1.4-4.3-3-6.5-4.5c-2.3-1.6-4.3-3.5-6-5.8c-1.2-1.6-2.4-3.2-3.6-4.8c-0.5-0.6-0.9-1.3-1.1-2.1c-0.8-3-1.7-5.9-1.2-9.2
	c0.2-1.4,0-3-0.5-4.2c-1.1-2.3-0.6-4.7-0.7-7c4.1,0,8.2,0,12.2,0c1.1,0,2.2,0,3.3,0c1.1,0,1.5,0.6,1.4,1.7c-0.2,1.6-0.4,3.1-0.6,4.7
	c-0.3,2.3,0.2,4.7,1.6,7.1c0.1-2.7-0.1-4.9,0.2-7.1C241.3,126.7,242.2,124.5,242.8,122.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity54 ? this.state.opacity54 : 1}
              fill={this.state.color54 ? this.state.color54 : 'transparent'} onClick={() => {
          this.setState({ color54: color, opacity54: opacity });
        }} d="M207.8,123.4c-0.3,3.7-3.1,6.9-2.5,11.1c2.7-3.6,3.3-7.8,4.6-12c3.2,0,6.5,0,9.9,0c0.7,1.7,0.2,3.7,0,5.5
	c-0.2,2.7-0.3,5.6-2.2,7.9c-0.3,3.4-2.9,5.8-4.3,8.7c-1.7,3.5-4.6,6.3-8.3,8c-0.9,0.4-1.7,0.9-2.5,1.4c-2.4,1.9-5,1.8-7.6,0.7
	c-0.5-5.4-1.4-23,0.8-31.8c1.1,0,2.4-0.2,3.6-0.1c1.7,0.2,2.3,1.1,2,2.8c-0.3,2.1-0.7,4.2-1.1,6.3c2.3-1,2.6-3.6,3.5-9
	C205.7,122.3,206.2,122.4,207.8,123.4z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity54 ? this.state.opacity54 : 1}
              fill={this.state.color54 ? this.state.color54 : 'transparent'} onClick={() => {
          this.setState({ color54: color, opacity54: opacity });
        }} d="M194.2,156.2c2.7,0.6,5.1,0.2,6.9,2c-1.1,2.4-3.7,1.7-5.4,2.5c1.7,1.1,3.4,2.2,5.1,3.3c0.3,0.2,0.7,0.4,1,0.7
	c2.9,3.5,5.7,7,8.5,10.5c0.5,0.6,0.7,1.4,1.2,2.4c-6.1,0.2-12,0.4-18,0.7c0.3-2.6,0.6-5,0.8-7.4c0.2-2.4,0.4-4.7,0.4-7.1
	C194.8,161.3,194.5,158.9,194.2,156.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity54 ? this.state.opacity54 : 1}
              fill={this.state.color54 ? this.state.color54 : 'transparent'} onClick={() => {
          this.setState({ color54: color, opacity54: opacity });
        }} d="M254.7,176.2c-3.7,0.3-7,0.8-10.3,0.4c-1-2-0.4-3.8,0.1-5.5c0.5-1.7,1.4-3.2,2.2-4.7c0.2-0.4,0.8-0.7,1.3-0.8
	c2.3-0.6,4.6-1.2,7.1-1.9C255.1,167.9,254.9,171.9,254.7,176.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity54 ? this.state.opacity54 : 1}
              fill={this.state.color54 ? this.state.color54 : 'transparent'} onClick={() => {
          this.setState({ color54: color, opacity54: opacity });
        }} d="M255.5,161.6c-3.3,0.8-6.4,1.6-9.9,2.4c0.3-0.8,0.3-1.3,0.5-1.4c2.9-1.3,5.8-2.4,9.2-2.4
	C255.4,160.7,255.4,161.1,255.5,161.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity63 ? this.state.opacity63 : 1}
              fill={this.state.color63 ? this.state.color63 : 'transparent'} onClick={() => {
          this.setState({ color63: color, opacity63: opacity });
        }} d="M292.4,321.9c1.6-0.6,1.6-2.2,1.9-3.5c0.7-3.2,1.2-6.5,1.8-9.9c2.8,0,5.6,0,8.7,0c-0.5,0.8-0.8,1.4-1.2,2.1
	c0.8,0.9,1,1.8,0.5,3c-0.8,1.9-1.2,3.9-1,6.1c0.1,1.3-0.5,2.6-1,3.9c-1.4,3.6-2.8,7.1-4.3,10.6c-0.6,1.3-1.7,2.4-2.6,3.6
	c-0.4,0.5-0.9,0.9-1.2,1.5c-2.7,4.1-6.1,7-11.4,6.7c-1.4-0.1-2.8,0.2-4.5,0.3c0-1.5,0-2.7-0.1-4c-0.3-3.6-0.8-7.3-1-10.9
	c-0.1-2,0.2-4,0.1-5.9c-0.1-2.3-0.4-4.6-0.6-6.9c0-0.3-0.3-0.6-0.3-0.9c0.1-2.5,0.2-5,0.5-7.5c0.2-1.6,0.7-2,2.4-2.1
	c1.7-0.1,3.3-0.3,5-0.3c2.5-0.1,3.3,0.8,3.2,3.3c-0.1,1.8-0.3,3.6-0.4,5.5c0,0.1,0.1,0.1,0.2,0.3c2-2.5,1.2-5.6,1.4-8.4
	c2,0,3.9,0,5.9,0c0.4,3,0.2,5.8-0.5,8.5c-0.4,1.6-1,3.1-1.5,4.7C292.1,321.5,292.3,321.7,292.4,321.9c-1.5,0.6-0.4,2.1-1.1,3
	C292.6,324.2,292.6,323.1,292.4,321.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity63 ? this.state.opacity63 : 1}
              fill={this.state.color63 ? this.state.color63 : 'transparent'} onClick={() => {
          this.setState({ color63: color, opacity63: opacity });
        }} d="M277.9,351.9c0.9,0.1,1.5,0.3,2,0.2c2.2-0.5,3.8,0.8,5.5,1.8c1.6,1,1.6,3.1,2,4.7c0.6,2.3,0.7,4.7,1.1,7
	c0.2,1.4,0.5,2.8,0.9,4.5c-4-0.2-7.6-0.5-11.5-0.7C277.9,363.5,277.9,357.8,277.9,351.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity63 ? this.state.opacity63 : 1}
              fill={this.state.color63 ? this.state.color63 : 'transparent'} onClick={() => {
          this.setState({ color63: color, opacity63: opacity });
        }} d="M278.1,350.3c0-1,0-1.5,0-2c0.2-0.1,0.3-0.2,0.4-0.2c1.8-0.1,3.6-0.1,5.4-0.2c1.7-0.1,3.3,0,4.7,1.3
	c-0.2,1.2-1.1,1.5-2,1.5C283.8,350.6,281,350.4,278.1,350.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity56 ? this.state.opacity56 : 1}
              fill={this.state.color56 ? this.state.color56: 'transparent'} onClick={() => {
          this.setState({ color56: color, opacity56: opacity });
        }} d="M413.4,124.7c0-3,0-5.9,0-9.1c1.9,0,3.7-0.7,4.9,1.2c1.2,0,2.3,0,3.6,0c0.9,0.7,1.1,1.7,1.1,2.9
	c-0.1,1.7-0.1,3.4-0.4,5.1c-0.6,4.3-0.7,8.7-0.9,13.1c-0.1,3.1,0.1,6.2-1.1,9.2c-1.3,3.1-1.1,6.2-1.1,9.5c0,5-0.3,10-0.5,15.1
	c-7.5,0-15.1,0-22.5,0c-0.5-4.6-1.1-9-1.4-13.4c-0.1-2,0.1-4,0.4-5.9c0.3-2.3,0.1-4.3-0.9-6.5c-1-2.1-1.3-4.5-1.7-6.9
	c-0.5-3.3-0.7-6.6-1-9.9c-0.1-0.9,0.2-1.9,0.1-2.8c-0.1-2.4-0.4-4.7-0.6-7.1c-0.1-0.7,0-1.4,0-2.4c3.4,0,6.7,0,10.3,0
	c1.2,4.3,0.4,8.8,1.1,13.2c0.6-4,0.9-8-0.3-12.1c0.3-0.5,0.6-1,1-1.6c1,0,2-0.1,3.1-0.1c0.8,1,1,2.1,1,3.3c0,2.4,0.1,4.8,0.2,7.2
	c0,0.3,0.2,0.7,0.7,1c0.2-2.2,0.4-4.4,0.6-6.6c0.1-1.3,0.1-2.5,1.5-3.2c-0.4-0.5-0.7-0.9-1.2-1.7c1-0.2,1.7-0.3,2.8-0.5
	c0.3,3.2,0.6,6.1,0.9,9.1C413.1,124.6,413.2,124.6,413.4,124.7z M413,126.5c-0.8,2.6-0.6,5.2-0.3,7.9
	C413.6,131.7,413.3,129.1,413,126.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity56 ? this.state.opacity56 : 1}
              fill={this.state.color56 ? this.state.color56 : 'transparent'} onClick={() => {
          this.setState({ color56: color, opacity56: opacity });
        }} d="M362.4,117.2c2.6-0.1,5.1-0.2,7.6-0.2c1.7,0,1.9,0.3,2,2c0.2,3.6,0,7.3-1.4,10.7c1.6-1.1,2.1-2.8,2.5-4.5
	c0.5-1.9,0.5-3.9,0.7-5.9c0.1-1.6,0.3-1.8,2.3-1.8c1.1,2.2,1.1,4.6,0.6,6.9c-0.6,2.6-1.3,5.1-1.9,7.6c0,0.2,0.1,0.4,0.3,1.1
	c3.2-5.1,3.3-10.4,3.2-16c3.3-0.2,6.4-0.4,9.5-0.4c0.4,0,1,0.6,1.1,1c0.1,1.2,0.2,2.4,0,3.6c-1,4.2-2.3,8.3-3.2,12.4
	c-0.4,1.7-1.1,3.1-1.8,4.6c-1.2,2.3-2.1,4.6-3.7,6.6c-0.3,0.3-0.4,0.7-0.7,1c-3.6,3.2-7,6.7-11.2,9.2c-1.4,0.8-2.9,1.3-4.5,1.1
	c-1.2-0.2-2.1-0.6-2.1-2.1c0.1-1.9,0.1-3.8,0.2-5.7c0-1.3,0.1-2.5,0.1-3.8c0.1-4.8,0.2-9.7,0.3-14.5c0.1-2.7,0.3-5.4,0.4-8.1
	c0-0.9-0.3-1.9-0.3-2.8C362,118.7,362.2,118.1,362.4,117.2z M374.5,133.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0.3-0.3,0.6-0.4,0.9
	c0.1,0,0.2,0.1,0.3,0.1C374.2,134.3,374.3,134,374.5,133.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity56 ? this.state.opacity56 : 1}
              fill={this.state.color56 ? this.state.color56 : 'transparent'} onClick={() => {
          this.setState({ color56: color, opacity56: opacity });
        }} d="M361,172.7c-1.2-3.6,0.3-7.1-0.6-10.6c1.1-0.1,1.9-0.2,2.8-0.3c0.8-0.1,1.5,0.1,2.1,0.7
	c0.8,0.8,1.8,1.3,2.7,1.9c1.5,1.1,3.1,2.1,4.6,3.3c0.5,0.4,0.7,1,1,1.6c0.5,1.1,1,2.3,1.4,3.3C370.5,172.7,365.8,172.7,361,172.7z"
        />
        <path stroke={borderColor} fillOpacity={this.state.opacity56 ? this.state.opacity56 : 1}
              fill={this.state.color56 ? this.state.color56 : 'transparent'} onClick={() => {
          this.setState({ color56: color, opacity56: opacity });
        }} d="M367.4,159.5c-0.6,0.3-1.1,0.8-1.7,0.9c-1.2,0.1-2.4,0-3.5-0.1c-0.8-0.1-1.4-0.4-1.3-1.3c0.1-1,0.8-1.1,1.5-0.9
	c1.6,0.3,3.2,0.7,4.8,1C367.2,159.2,367.3,159.3,367.4,159.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1}
              fill={this.state.color53 ? this.state.color53 : 'transparent'} onClick={() => {
          this.setState({ color53: color, opacity53: opacity });
        }} d="M124.8,167.2c-3.7,0.2-7.6,2-11.7,0.2c0.1-2.2,0.2-4.4,0.3-6.6c0.1-3.7,0.4-7.3,0.4-11
	c-0.1-3.1-0.4-6.2-0.9-9.3c-0.7-4.2-0.2-8.4,0.1-12.6c0-0.7,0.4-1.3,0.7-2c0.2-0.5,0.4-1.1,0.6-1.7c0.8-0.1,1.6-0.2,2.6-0.4
	c1,2.2,0.8,4.6,0.8,6.9c0,2.9,0.1,5.8,0.6,8.7c0.6-5.3,2.1-10.4,1.8-16.1c1.3,0.1,2.5,0.1,3.8,0.2c0,3.9,0,7.7,0,11.3
	c1.6-1.7,1.7-6.6,1.3-10.9c1.7-0.8,5.1-0.9,8.7-0.3c0,2.2,0,4.5,0,6.8c2-1.9-0.1-4.8,1.4-6.9c2.5,0,5.1,0,7.9,0
	c-0.3,1.3-0.6,2.5-0.8,3.7c-0.1,0.9-0.3,1.9-0.2,2.8c0.7,3.7,0.6,7.5,0,11.1c-0.3,1.5-1,2.8-1.4,4.3c-1.4,5-3.4,9.8-7,13.7
	c-1.1,1.2-2.5,2.3-3.7,3.4C128.4,164.2,126.7,165.6,124.8,167.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1}
              fill={this.state.color53 ? this.state.color53 : 'transparent'} onClick={() => {
          this.setState({ color53: color, opacity53: opacity });
        }} d="M126.3,171.7c1.4,3,4.2,5.2,4.2,9c-6.2,0.3-12.2,1-18.5,0.6c0-2.4-0.8-4.7-0.1-6.9
	C116.8,173.5,121.4,172.7,126.3,171.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1}
              fill={this.state.color53 ? this.state.color53 : 'transparent'} onClick={() => {
          this.setState({ color53: color, opacity53: opacity });
        }} d="M154.9,179c0.4-2.7,2.2-4.2,3.7-5.9c0.3-0.3,0.9-0.3,1.3-0.4c3.7-0.8,7.4-1.7,11.4-2.6c0.1,2.9,0.2,5.7-0.2,8.8
	c-2.7,0-5.4,0-8.1,0C160.4,179,157.8,179.8,154.9,179z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1}
              fill={this.state.color53 ? this.state.color53 : 'transparent'} onClick={() => {
          this.setState({ color53: color, opacity53: opacity });
        }} d="M112.5,172.2c0.2-1.8,1.3-2.8,2.4-2.2c2.4,1.3,4.3-0.3,6.4-0.7c2-0.4,4-0.7,6.1-1.1c0.2,0,0.4,0.2,0.7,0.3
	c-0.3,1.5-1.7,1.3-2.6,1.6c-1.3,0.5-2.7,0.8-4.1,1.1C118.5,171.9,115.6,172.7,112.5,172.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity53 ? this.state.opacity53 : 1}
              fill={this.state.color53 ? this.state.color53 : 'transparent'} onClick={() => {
          this.setState({ color53: color, opacity53: opacity });
        }} d="M156.9,170.2c5-1.1,9.6-2.1,14.2-3.1C165.9,170.5,159.4,171.9,156.9,170.2z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity62 ? this.state.opacity62 : 1}
              fill={this.state.color62 ? this.state.color62 : 'transparent'} onClick={() => {
          this.setState({ color62: color, opacity62: opacity });
        }} d="M243.6,320.5c0.3-0.3,0.7-0.6,0.8-0.9c0.4-3,0.7-6,1-9c0-0.3,0.1-0.6,0.2-0.9c0.1-0.8,0.6-1.2,1.3-1
	c0.4,0.1,0.9,0.7,1,1.1c0.2,2.1,0.1,4.3,0.2,6.5c0.1,3.5,0.3,7,0.5,10.5c0.1,1.1,0.2,2.4,1.8,3.5c-2.1-7.3-1.4-14.4-1.7-21.5
	c2.1,0,4,0,5.9,0c0.5,0.5,1.1,1,1.6,1.4c-0.4,1-0.9,1.8-0.9,2.6c0,6.5,0,13,0.1,19.5c0,2.7,0.4,5.4,0.5,8.1c0,0.8-0.2,1.6-0.3,2.4
	c-0.2,3.7-0.4,7.3-0.5,11c-0.1,4.9,0,9.9,0,15c-6.9,0.6-13.7,0.2-20.6,0.3c-1.4-3.4-3.1-6.6-4.2-9.9c-0.8-2.5-1-5.3-1.4-8
	c-0.4-2.3-0.8-4.5-1.1-6.8c-0.2-1.7-0.4-3.5-0.5-5.2c-0.3-7-1.1-14-0.5-21c0.1-1.9-0.2-3.8-0.4-5.7c-0.1-1.3-0.4-2.6,0.6-3.5
	c3.1,0,6.1,0,9.1,0c1.9,2.6,1.6,5.6,2,8.8c1.5-3,0.3-5.8,1-8.6c1.5,0,3,0,4.6,0c0.1,1.1,0.4,2.1,0.4,3.2
	C243.9,315,243.7,317.8,243.6,320.5c0.2,5.6,0.7,11.1,1.6,16.7c0.2-0.9,0.1-1.9,0.1-2.8c-0.3-3-0.6-6-0.9-8.9
	C244.1,323.8,243.8,322.2,243.6,320.5z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity62 ? this.state.opacity62 : 1}
              fill={this.state.color62 ? this.state.color62 : 'transparent'} onClick={() => {
          this.setState({ color62: color, opacity62: opacity });
        }} d="M198.6,336.8c0-0.8,0.1-1.6,0.1-2.4c0.3-5.2,0.7-10.3,0.9-15.5c0.1-2.7-0.2-5.4-0.3-8.1
	c-0.1-2.2,0.3-2.6,2.6-2.7c0.9,0,1.7,0,2.7,0c0.2,2.5,0.4,4.7,0.5,6.9c0.1,0,0.3,0,0.4,0c0.1-2.2,0.3-4.4,0.4-6.7
	c1.5,0.2,2.7,0.4,4.2,0.6c0,2.8,0,5.5,0,8.2c0.2,0,0.3,0,0.5,0c0.4-2.7,0.8-5.5,1.2-8.4c3,0,5.9,0,8.9,0c-0.1,4.4,0.4,8.5-0.7,12.6
	c-0.2,0.7-0.2,1.4,0,2.1c0.8,3.5,0.5,7.1,0.5,10.7c0,2.3,0.4,4.7,0.1,7c-0.6,4.9-0.6,9.8-2.6,14.5c-1,2.3-1.2,4.9-1.5,7.5
	c-0.2,2-0.5,3.9-1.6,6c-7.1-0.3-14.4-0.6-21.6-0.9c-0.4-4.9-0.8-9.4-0.4-14.1c0.5-5.9,0.5-11.9,0.8-17.9c0.2-3.3,0.6-6.6,0.6-10
	c0-3.4-0.3-6.8-0.5-10.2c-0.1-1.6-0.2-3.3-0.3-5.1c1-0.1,1.6-0.1,2.7-0.2c-0.7-0.7-1.1-1.2-1.9-2c1.5-0.1,2.7-0.2,4.1-0.4
	c0.1,5,0.3,9.7,0.3,14.4c-0.1,4.7-0.4,9.4-0.7,14C198.2,336.7,198.4,336.7,198.6,336.8z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity57 ? this.state.opacity57 : 1}
              fill={this.state.color57 ? this.state.color57 : 'transparent'} onClick={() => {
          this.setState({ color57: color, opacity57: opacity });
        }} d="M150.6,214.6c1.9,0,3.2,0,4.6,0c0,3.9-0.2,7.7,0,11.5c0.2,3.8,0.8,7.5,1.3,11.3c0.2,0,0.4,0,0.6,0
	c0.1-0.6,0.2-1.3,0.2-1.9c-0.2-2.1-0.6-4.1-0.7-6.2c-0.1-2.8-0.2-5.7,0-8.6c0.1-1.9,0.4-3.7,0.7-5.7c1.8,0,3.5,0,5.3,0
	c0,4,0,7.9,0,12c0.9-1.4,0.9-1.4,1-5.7c0-2,0-4,0-6c2.1-0.5,3.9-0.6,5.9-0.1c0.1,0.8,0.3,1.6,0.2,2.4c-0.2,2.7-0.6,5.4-0.8,8
	c-0.1,1.8,0,3.6,0.1,5.4c0.1,1.7,0.3,3.3,0.5,5c0.5,4.9,0.4,9.7,0.2,14.6c-0.1,3.8,0,7.7,0,11.5c0,1.3-0.1,2.7-0.2,4
	c-7.7,0.4-15.3,0.8-22.7,1.2c-1.4-2-1.7-3.9-1.5-6.2c0.3-4.6,0.1-9.2-0.6-13.8c-0.5-3.6-0.4-7.3-0.6-11c-0.1-1.7-0.5-3.5-0.8-5.2
	c-0.5-2.8-1.4-5.5-0.9-8.4c0.4-2-0.1-4.1-0.6-6.2c0.4-0.6,0.8-1.1,1.3-1.7c2.2,0,4.3,0,6.7,0c0.2,1.6,0.5,3.1,0.5,4.7
	c0.1,6.9,0.6,13.8,1.6,20.7c0.3,1.8,1,3.6,1.7,5.4C151.2,235.3,151.3,225,150.6,214.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity57 ? this.state.opacity57 : 1}
              fill={this.state.color57 ? this.state.color57 : 'transparent'} onClick={() => {
          this.setState({ color57: color, opacity57: opacity });
        }} d="M126.2,214.6c1.1,4.5,0.9,8.6,1.2,12.8c1.7-4,0-8.5,1.5-12.6c3-0.8,6.1-0.2,9.3-0.3c0.7,2.1-0.4,4.6,1.3,6
	c-0.5,2.3-1,4.3-1.4,6.4c-0.2,1.1-0.2,2.2-0.6,3.2c-0.9,2.5-0.6,5.1-0.6,7.6c0,2.9,0.4,5.7-1,8.4c-0.4,0.7-0.1,2.2-0.6,2.5
	c-1.3,0.8-0.6,1.8-0.8,2.7c-0.5,3.1-1.2,6.2-1.9,9.4c0,0.2-0.1,0.5-0.3,0.6c-1.3,0.7-0.9,1.8-0.9,2.8c0,1.1-0.5,2.1-1,3.1
	c-0.1,0.3-0.7,0.6-1.1,0.7c-0.8,0.1-1.6,0-2.4,0c-3.2,0-6.4-0.1-9.6-0.1c-1.6,0-3.1,0-4.9,0c0.4-5.1,0.8-10,1-14.9
	c0.2-3,0.3-6.1,0.1-9.1c-0.3-4.4-0.4-8.9,0.1-13.3c0.3-2.8,0.3-5.5-0.3-8.3c-0.4-1.6-0.1-3.3-0.1-5c0.1-1.3,0.7-1.9,2.1-2.1
	c2.3-0.2,4.5-0.6,6.9-0.1C123.5,215.3,124.8,214.8,126.2,214.6z M122.9,218.7c-0.8,5.1-0.6,8.3,0.5,10
	C123.5,225.4,123.7,222,122.9,218.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity55 ? this.state.opacity55 : 1}
              fill={this.state.color55 ? this.state.color55 : 'transparent'} onClick={() => {
          this.setState({ color55: color, opacity55: opacity });
        }} d="M279.8,120.9c1.8-0.2,3.3-0.4,4.8-0.5c0.9-0.1,1.4,0.3,1.6,1.3c0.3,2.7,0.8,5.5,1.2,8.3
	c1.2-1.4,0.6-3.1,0.9-4.6c0.3-1.6,0.3-3.3,0.4-4.9c4.4-0.9,5-0.4,4.6,3.7c-0.3,2.6-0.9,5.2-1.1,7.8c-0.2,2.3,0,4.7,0,7
	c0-2,1-3.9,0.8-6c-0.1-1.5,0.8-3.1,1.1-4.7c0.3-1.8,0.6-3.6,0.8-5.4c0.3-2.9,0.6-3.2,3.3-2.9c0.2,0,0.5,0,1,0.1c0.5,3,1.1,6,1.7,9.1
	c0.2,0,0.3,0,0.5,0c0.1-2.9,0.3-5.9,0.4-8.9c2.1-0.1,4.1-0.3,6.2-0.4c1.1-0.1,1.6,0.3,1.6,1.5c-0.1,3.4-0.1,6.8-0.1,10.2
	c0,1.4-0.1,2.9,0.3,4.3c0.4,1.3,0.2,2.5-0.1,3.7c-0.7,2.6-0.4,5.2-0.4,7.8c0,3.3-0.5,6.6-0.8,10c-0.2,2.4-0.3,4.8-0.5,7.1
	c-0.1,1.1-0.3,2.2-0.6,3.3c-0.3,1.5-0.7,3-1,4.4c-0.3,1.3-0.9,2-2,1.8c-3.5-0.8-7,0.5-10.4,0.3c-1.9-0.1-3.8-0.2-5.7-0.1
	c-2.7,0.1-5.4,0.2-8.1,0.3c-0.3,0-0.6,0-1.3,0.1c2.5-3.7,2.1-7.7,2.5-11.5c0.6-5.9,0-11.7-0.7-17.6c-0.3-2.7-0.3-5.4-0.4-8.1
	c-0.1-4.1-0.3-8.3-0.4-12.4C279.8,123.7,279.8,122.4,279.8,120.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity55 ? this.state.opacity55 : 1}
              fill={this.state.color55 ? this.state.color55 : 'transparent'} onClick={() => {
          this.setState({ color55: color, opacity55: opacity });
        }} d="M313,119.9c1.5-0.1,3-0.2,4.5-0.4c0.9-0.1,1.8-0.5,2.7-0.5c0.8,0,1.6,0.3,2.5,0.5c1.2,3.6,1.6,7.3,1.6,11.1
	c-0.1,3,0.5,5.9,1.6,8.6c0.3,0.7,0.7,1.3,1.3,1.9c-0.6-3.7-1.5-7.3-1.9-11c-0.4-3.6-0.2-7.3-0.3-11c1-0.1,1.7-0.1,2.7-0.2
	c0.3,1,0.6,2.1,0.7,3.1c0.5,4,0.9,7.9,1.4,11.9c0,0.3,0.2,0.6,0.4,1.2c0.5-1,0.8-1.6,0.7-2.5c-0.4-2.9-0.8-5.8-1-8.7
	c-0.1-1.5,0.2-3.1,0.4-4.9c0.8-0.2,1.7-0.4,3-0.7c0.2,3,0.3,5.7,0.4,8.4c0.2,0,0.3,0,0.5,0c0.3-1.7,0.5-3.3,0.8-5
	c0.1-0.5,0.3-1.1,0.3-1.6c0.2-1.4,1.1-1.8,2.4-1.6c0.5,0.1,1.1,0.1,1.9,0.1c0.3,1.1,0.6,2.2,0.8,3.3c0.8,4.5,0.5,9,0.4,13.6
	c-0.1,4.7-0.2,9.4-0.2,14.1c0,3.7-0.2,7.5,0.3,11.2c0.5,4.1,0,8-0.1,12.3c-2.8,0-5.5-0.1-8.1,0c-2.8,0.1-5.7,0.2-8.5,0.5
	c-1.6,0.2-2.6-0.3-3.2-1.8c-0.4-1-1.5-1.7-1.2-3c0.1-0.2-0.2-0.7-0.4-0.8c-1.3-0.6-1.2-1.9-1.1-2.9c0-1.3,0-2.5-0.7-3.7
	c-0.1-0.1-0.1-0.3-0.1-0.5c-0.3-3-0.6-6-1.1-9c-0.8-5.5-0.9-11-2.4-16.5c-1.2-4.3-1.5-8.8-0.7-13.2
	C313.4,121.6,313.1,120.9,313,119.9z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity58 ? this.state.opacity58 : 1}
              fill={this.state.color58 ? this.state.color58 : 'transparent'} onClick={() => {
          this.setState({ color58: color, opacity58: opacity });
        }} d="M233.1,213.6c1.2,1.7-0.2,3.7,1.4,5c0.5-1.7,0.9-3.3,1.4-5c1.2,0.1,2.3,0.2,3.5,0.2c0,1.1,0.1,2.2,0,3.3
	c-0.5,3.7-0.5,7.5,0.1,11.2c0.3,1.7,1,3.2,1.6,4.9c0.2-0.1,0.3-0.1,0.5-0.2c-0.4-1.2-0.7-2.3-1.1-3.5c-0.7-2.6-1-5.3-0.7-8
	c0.2-1.6,0.1-3.2,0.3-4.7c0.3-2.2,1.8-3.1,4.2-2.6c-0.2,3.1-0.4,6.2-0.6,9.3c0.2,0,0.4,0,0.6,0c0.1-0.5,0.1-1,0.2-1.5
	c0.3-2.2,0.5-4.4,0.8-6.6c0.1-1.2,0.6-1.8,2-1.7c2.2,0.1,4.4-0.1,6.9-0.1c0.1,0.6,0.4,1.4,0.4,2.2c0,5.6-0.2,11.2,0.1,16.9
	c0.2,5.1-0.8,10.1-0.3,15.1c0.2,1.7-0.2,3.5-0.3,5.2c-0.1,1.2-0.5,1.8-1.8,2.1c-3.5,0.8-6.9,1.8-10.4,2.7c-0.4,0.1-1,0.3-1.4,0.1
	c-3.6-1.5-7.3-3.1-10.1-5.8c-1.3-1.3-2.4-3-3.1-4.7c-1.3-3.2-2-6.7-2.1-10.2c0-2.8-0.4-5.7-0.5-8.5c-0.1-1.9,0-3.8,0.1-5.7
	c0.1-2.5,0.3-4.9,0.5-7.4c0.1-0.8,0.4-1.4,1.3-1.4C228.7,213.9,230.9,213.8,233.1,213.6z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity58 ? this.state.opacity58 : 1}
              fill={this.state.color58 ? this.state.color58 : 'transparent'} onClick={() => {
          this.setState({ color58: color, opacity58: opacity });
        }} d="M213,214.3c1.9,0.1,3.5,0.1,5.1,0.2c2.5,0.2,3.8,1.6,3.7,4.1c-0.1,3.1-0.2,6.2-0.4,9.3c-0.1,1-0.5,2-0.8,2.9
	c-0.1,0.7-0.3,1.4-0.2,2.1c0.5,2.5,0.5,4.9,0.1,7.4c-0.2,1-0.7,2.1-1.4,2.9c-1.2,1.4-1.9,2.9-2.5,4.5c-0.6,1.6-1.7,2.7-2.9,3.9
	c-2.8,2.8-6.2,4.1-9.7,5.4c-0.6,0.2-1.3,0.4-1.9,0.3c-2.1-0.4-4.1,0-6.1,0.7c-0.3,0.1-0.6,0-1,0c0-1.2,0-2.3,0-3.4
	c-0.2-6.9-0.1-13.7-0.6-20.6c-0.2-2.9,0.2-6-1.1-8.9c-0.2-0.4,0.6-1.2,0.6-1.8c0-3.1,1.2-5.9,2.2-8.8c2.2-0.9,4.4-0.3,6.6-0.1
	c1.2,0.1,1.6,0.8,1.5,2.2c-0.1,1.4-0.3,2.9-0.4,4.3c-0.1,1,0,2.1,0.1,3.1c1.3-3,0.8-6.3,2-9.4c1,0,2,0,3.1,0
	c0.6,6.1-0.9,11.7-2.2,17.4c2.6-5.5,2.5-11.7,4.6-17.6c1.3,1.3,1.3,2.5,1.1,3.9c-0.5,3.2-0.9,6.4-1.2,9.7
	C213.3,223.5,214,217.1,213,214.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity58 ? this.state.opacity58 : 1}
              fill={this.state.color58 ? this.state.color58 : 'transparent'} onClick={() => {
          this.setState({ color58: color, opacity58: opacity });
        }} d="M252.9,259.3c0.5,2.6-1.1,5.1,1.6,7c-3.4-0.2-6.5-0.4-9.8-0.6c0-1.7,0.9-3.1,2.2-3.8
	C248.8,260.8,250.9,260.2,252.9,259.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity58 ? this.state.opacity58 : 1}
              fill={this.state.color58 ? this.state.color58 : 'transparent'} onClick={() => {
          this.setState({ color58: color, opacity58: opacity });
        }} d="M243.7,260.3c-0.4-0.9-0.2-1.5,0.8-1.8c2.9-0.8,5.7-1.6,9.2-2.6c-0.4,1-0.5,1.7-0.8,1.9
	c-2.7,1.2-5.4,2.6-8.5,2.7C244.2,260.5,243.9,260.4,243.7,260.3z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity58 ? this.state.opacity58 : 1}
              fill={this.state.color58 ? this.state.color58 : 'transparent'} onClick={() => {
          this.setState({ color58: color, opacity58: opacity });
        }}
              d="M200.9,266.7c-2.7,0-5,0-7.4,0c0.7-2.2,1.3-2.7,4.3-2.8C199.5,263.9,200.3,265,200.9,266.7z" />
        <path stroke={borderColor} fillOpacity={this.state.opacity58 ? this.state.opacity58 : 1}
              fill={this.state.color58 ? this.state.color58 : 'transparent'} onClick={() => {
          this.setState({ color58: color, opacity58: opacity });
        }} d="M200.9,260.4c-1.5,0.1-3,0.2-4,1.6c-0.2,0.3-1,0.4-1.5,0.4c-0.3,0-0.8-0.3-0.9-0.6c-0.1-0.3,0-0.9,0.3-1
	c1.9-0.8,3.7-2,5.8-1C200.7,259.9,200.7,260.1,200.9,260.4z" />
      </SvgIcon>
    )
  }
};